import React from "react";
import "../../../css/ViewTreatmentForm.css";
import useTranslation from "../../customHooks/translations";

export default function PediaKneeSatisfaction({ pediaSatisfaction, AllData }) {
  const translations = useTranslation();
  console.log("====================================");
  console.log(AllData.evlPediaPain, typeof AllData.evlPediaPain, "sdfdfefsgr");
  console.log("====================================");
  return (
    <>
      <div className="whitebox padding-none">
        <div className="treatment-content">
          <div className="treatment-row-header">
            5. {translations.Paediatric_Knee_Satisfaction}
          </div>
          <div className="treatment-row">
            <div className="treatment-table">
              <table cellpadding="0" cellspacing="0">
                <thead>
                  <tr>
                    <th></th>
                    <th>{translations.Right}</th>
                    <th>{translations.Left}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{translations.patient_adult_knee_satisfaction_q1} :</td>
                    <td>
                      {AllData.evlPediaPain === "3" ||
                      AllData.evlPediaPain === "1" ? (
                        <>
                          {pediaSatisfaction.overallR === "1"
                            ? translations.patient_adult_knee_satisfaction_q1_a1
                            : pediaSatisfaction.overallR === "2"
                            ? translations.patient_adult_knee_satisfaction_q1_a2
                            : pediaSatisfaction.overallR === "3"
                            ? translations.patient_adult_knee_satisfaction_q1_a3
                            : pediaSatisfaction.overallR === "4"
                            ? translations.patient_adult_knee_satisfaction_q1_a4
                            : ""}
                        </>
                      ) : (
                        ""
                      )}
                    </td>
                    <td>
                      {AllData.evlPediaPain === "3" ||
                      AllData.evlPediaPain === "2" ? (
                        <>
                          {pediaSatisfaction.overallL === "1"
                            ? translations.patient_adult_knee_satisfaction_q1_a1
                            : pediaSatisfaction.overallL === "2"
                            ? translations.patient_adult_knee_satisfaction_q1_a2
                            : pediaSatisfaction.overallL === "3"
                            ? translations.patient_adult_knee_satisfaction_q1_a3
                            : pediaSatisfaction.overallL === "4"
                            ? translations.patient_adult_knee_satisfaction_q1_a4
                            : ""}
                        </>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>{translations.patient_adult_knee_satisfaction_q2} :</td>{" "}
                    <td>
                      {AllData.evlPediaPain === "3" ||
                      AllData.evlPediaPain === "1" ? (
                        <>
                          {pediaSatisfaction.improvingPainR === "1"
                            ? translations.patient_adult_knee_satisfaction_q2_a1
                            : pediaSatisfaction.improvingPainR === "2"
                            ? translations.patient_adult_knee_satisfaction_q2_a2
                            : pediaSatisfaction.improvingPainR === "3"
                            ? translations.patient_adult_knee_satisfaction_q2_a3
                            : pediaSatisfaction.improvingPainR === "4"
                            ? translations.patient_adult_knee_satisfaction_q2_a4
                            : ""}{" "}
                        </>
                      ) : (
                        ""
                      )}
                    </td>
                    <td>
                      {AllData.evlPediaPain === "3" ||
                      AllData.evlPediaPain === "2" ? (
                        <>
                          {pediaSatisfaction.improvingPainL === "1"
                            ? translations.patient_adult_knee_satisfaction_q2_a1
                            : pediaSatisfaction.improvingPainL === "2"
                            ? translations.patient_adult_knee_satisfaction_q2_a2
                            : pediaSatisfaction.improvingPainL === "3"
                            ? translations.patient_adult_knee_satisfaction_q2_a3
                            : pediaSatisfaction.improvingPainL === "4"
                            ? translations.patient_adult_knee_satisfaction_q2_a4
                            : ""}{" "}
                        </>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>{translations.patient_adult_knee_satisfaction_q3} :</td>
                    <td>
                      {AllData.evlPediaPain === "3" ||
                      AllData.evlPediaPain === "1" ? (
                        <>
                          {pediaSatisfaction.improvingHomeR === "1"
                            ? translations.patient_adult_knee_satisfaction_q3_a1
                            : pediaSatisfaction.improvingHomeR === "2"
                            ? translations.patient_adult_knee_satisfaction_q3_a2
                            : pediaSatisfaction.improvingHomeR === "3"
                            ? translations.patient_adult_knee_satisfaction_q3_a3
                            : pediaSatisfaction.improvingHomeR === "4"
                            ? translations.patient_adult_knee_satisfaction_q3_a4
                            : ""}
                        </>
                      ) : (
                        ""
                      )}
                    </td>
                    <td>
                      {AllData.evlPediaPain === "3" ||
                      AllData.evlPediaPain === "2" ? (
                        <>
                          {pediaSatisfaction.improvingHomeL === "1"
                            ? translations.patient_adult_knee_satisfaction_q3_a1
                            : pediaSatisfaction.improvingHomeL === "2"
                            ? translations.patient_adult_knee_satisfaction_q3_a2
                            : pediaSatisfaction.improvingHomeL === "3"
                            ? translations.patient_adult_knee_satisfaction_q3_a3
                            : pediaSatisfaction.improvingHomeL === "4"
                            ? translations.patient_adult_knee_satisfaction_q3_a4
                            : ""}
                        </>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>{translations.patient_adult_knee_satisfaction_q4} :</td>
                    <td>
                      {AllData.evlPediaPain === "3" ||
                      AllData.evlPediaPain === "1" ? (
                        <>
                          {pediaSatisfaction.improvingActivitiesR === "1"
                            ? translations.patient_adult_knee_satisfaction_q4_a1
                            : pediaSatisfaction.improvingActivitiesR === "2"
                            ? translations.patient_adult_knee_satisfaction_q4_a2
                            : pediaSatisfaction.improvingActivitiesR === "3"
                            ? translations.patient_adult_knee_satisfaction_q4_a3
                            : pediaSatisfaction.improvingActivitiesR === "4"
                            ? translations.patient_adult_knee_satisfaction_q4_a4
                            : ""}
                        </>
                      ) : (
                        ""
                      )}
                    </td>
                    <td>
                      {AllData.evlPediaPain === "3" ||
                      AllData.evlPediaPain === "2" ? (
                        <>
                          {pediaSatisfaction.improvingActivitiesL === "1"
                            ? translations.patient_adult_knee_satisfaction_q4_a1
                            : pediaSatisfaction.improvingActivitiesL === "2"
                            ? translations.patient_adult_knee_satisfaction_q4_a2
                            : pediaSatisfaction.improvingActivitiesL === "3"
                            ? translations.patient_adult_knee_satisfaction_q4_a3
                            : pediaSatisfaction.improvingActivitiesL === "4"
                            ? translations.patient_adult_knee_satisfaction_q4_a4
                            : ""}
                        </>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
