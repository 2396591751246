import React from "react";
import "../../../css/ViewTreatmentForm.css";
import { UserDateFormat } from "../../../utilities/DateFormate";

export default function HipDoctorFeedback({ doctorefeedback, AllData,profileAllData }) {
  return (
    <div className="whitebox padding-none">
      <div className="treatment-header">
        <h2>Hip Doctor Feedback Form</h2>
        <div className="treatment-header-right">
          <p>Assessment Date : {UserDateFormat (AllData.assessment_date,profileAllData.ing_date_formate)}</p>
        </div>

      </div>

      <div className="treatment-content">
        <div className="treatment-row">
          <div className="treatment-table">
            <table cellpadding="0" cellspacing="0">
              <thead>
                <tr>
                  <th></th>
                  {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}

                  {/* ) : (
                                        ""
                                    )} */}
                  {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}
                  <th>Right Hip</th>
                  <th>Left Hip</th>
                  {/* // ) : (
                                    //     ""
                                    // )} */}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Gait:</td>
                  {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}
                  <td>
                    {doctorefeedback.ir1Right === "1"
                      ? "Normal"
                      : doctorefeedback.ir1Right === "2"
                        ? "Abnormal"
                        : ""}
                  </td>

                  {/* ) : (
                                        ""
                                    )} */}
                  {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}

                  <td>
                    {doctorefeedback.ir1Left === "1"
                      ? "Normal"
                      : doctorefeedback.ir1Left === "2"
                        ? "Abnormal"
                        : ""}
                  </td>
                  {/* // ) : (
                                    //     ""
                                    // )} */}
                </tr>
               
                <tr>
                  <td>Gait abnormality:</td>
                  {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}
                  {doctorefeedback.ir1Right === "2" && (
                    <td>
                    {doctorefeedback.ir2Right === "1"
                      ? "Antalgic"
                      : doctorefeedback.ir2Right === "2"
                        ? "Trendelenburg"
                        : doctorefeedback.ir2Right === "3"
                          ? "Rigid"
                          : doctorefeedback.ir2Right === "4"
                            ? "Supratentorial"
                            : doctorefeedback.ir2Right === "5"
                              ? "Limping"
                              : doctorefeedback.ir2Right === "6"
                                ? "Tiptoe gait"
                                : doctorefeedback.ir2Right === "7"
                                  ? "Trendelenberg gait"
                                  : doctorefeedback.ir2Right === "8"
                                    ? "Waddling gait"
                                    : doctorefeedback.ir2Right === "9"
                                      ? "In-toeing gait"
                                      : doctorefeedback.ir2Right === "10"
                                        ? "Out-toeing gait"
                                        : doctorefeedback.ir2Right === "11"
                                          ? "Crouch gait"
                                          : doctorefeedback.ir2Right === "12"
                                            ? "Others"
                                            : ""}

                    {doctorefeedback.ir2Right === "12" && (
                      <p>{doctorefeedback.otherR}</p>
                    )}
                  </td>
                  )}
                  



                  {/* ) : (
                                        ""
                                    )} */}

                  {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}
                  {doctorefeedback.ir1Left === "2" && (
                  <td>
                    {doctorefeedback.ir2Left === "1"
                      ? "Antalgic"
                      : doctorefeedback.ir2Left === "2"
                        ? "Trendelenburg"
                        : doctorefeedback.ir2Left === "3"
                          ? "Rigid"
                          : doctorefeedback.ir2Left === "4"
                            ? "Supratentorial"
                            : doctorefeedback.ir2Left === "5"
                              ? "Limping"
                              : doctorefeedback.ir2Left === "6"
                                ? "Tiptoe gait"
                                : doctorefeedback.ir2Left === "7"
                                  ? "Trendelenberg gait"
                                  : doctorefeedback.ir2Left === "8"
                                    ? "Waddling gait"
                                    : doctorefeedback.ir2Left === "9"
                                      ? "In-toeing gait"
                                      : doctorefeedback.ir2Left === "10"
                                        ? "Out-toeing gait"
                                        : doctorefeedback.ir2Left === "11"
                                          ? "Crouch gait"
                                          : doctorefeedback.ir2Left === "12"
                                            ? "Others"
                                            : ""}

                    {doctorefeedback.ir2Left === "12" && (
                      <p>{doctorefeedback.otherL}</p>
                    )}
                  </td>
                  )}
                  {/* // ) : (
                                    //     ""
                                    // )} */}
                </tr>
                <tr>
                  <td>Pelvic tilt:</td>
                  {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}
                  <td>
                    {doctorefeedback.bearHugRightVal === "0"
                      ? "Negative"
                      : doctorefeedback.bearHugRightVal === "1"
                        ? "Positive"
                        : ""}
                  </td>

                  {/* ) : (
                                        ""
                                    )} */}
                  {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}
                  <td>
                    {doctorefeedback.bearHugLeftVal === "0"
                      ? "Negative"
                      : doctorefeedback.bearHugLeftVal === "1"
                        ? "Negative"
                        : ""}
                  </td>


                  {/* // ) : (
                                    //     ""
                                    // )} */}
                </tr>

                <tr>
                  <td>True length :</td>
                  {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}
                  <td>{doctorefeedback.limbTL !== "" ? `${doctorefeedback.limbTL +" cm"}`:""} </td>

                  {/* ) : (
                                        ""
                                    )} */}
                  {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}
                  <td>{doctorefeedback.limbTRL !== "" ? `${doctorefeedback.limbTRL +" cm"}`:""}</td>

                  {/* // ) : (
                                    //     ""
                                    // )} */}
                </tr>

                <tr >
                  <td>Apparent length :</td>
                  {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}
                  <td>{doctorefeedback.limbAR !== "" ? `${doctorefeedback.limbAR +" cm"}`:""}</td>
                  {/* ) : (
                                        ""
                                    )} */}
                  {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}
                  <td>{doctorefeedback.limbRAR !== "" ? `${doctorefeedback.limbRAR +" cm"}`:""}</td>


                  {/* // ) : (
                                    //     ""
                                    // )} */}
                </tr>

                <tr>
                  <td>Fixed contracture :</td>
                  {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}
                  <td>
                    {doctorefeedback.ir4Right === "0"
                      ? "Present"
                      : doctorefeedback.ir4Right === "1"
                        ? "Absent"
                        : ""}
                  </td>


                  {/* ) : (
                                        ""
                                    )} */}
                  {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}
                  <td>
                    {doctorefeedback.ir4Left === "0"
                      ? "Present"
                      : doctorefeedback.ir4Left === "1"
                        ? "Absent"
                        : ""}
                  </td>

                  {/* // ) : (
                                    //     ""
                                    // )} */}
                </tr>
                <tr>
                  <td>Specify :</td>

                  <td>
                    {doctorefeedback.ir3Right === "0" &&
                      doctorefeedback.rightpspecify}
                    {doctorefeedback.ir3Right === "1" &&
                      doctorefeedback.rightaspecify}
                  </td>

                  <td>
                    {doctorefeedback.ir4Left === "0" &&
                      doctorefeedback.leftpspecify}
                    {doctorefeedback.ir4Left === "1" &&
                      doctorefeedback.leftaspecify}
                  </td>

                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="treatment-header ps-0">
          <h2>Range of motion</h2>
        </div>

        <div className="treatment-row mt-3">
          {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}
          <div className="treatment-col">
            <label>Right Flexion:</label>
            {doctorefeedback.RightFlexionR !== null && (
              <p>Active: {doctorefeedback.RightFlexionR} Degrees</p>
            )}

            {doctorefeedback.RightFlexionL !== null && (
              <p>Passive: {doctorefeedback.RightFlexionL} Degrees</p>
            )}
          </div>
          {/* ) : (
                        ""
                    )} */}
          {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}

          <div className="treatment-col">
            <label>Left Flexion:</label>
            {doctorefeedback.LeftFlexionR !== null && (
              <p>Active: {doctorefeedback.LeftFlexionR} Degrees</p>
            )}
            {doctorefeedback.LeftFlexionL !== null && (
              <p>Passive: {doctorefeedback.LeftFlexionL} Degrees</p>
            )}
          </div>
          {/* ) : (
                        ""
                    )} */}
        </div>
        <div className="treatment-row mt-3">
          {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}
          <div className="treatment-col">
            <label>Right Extension:</label>
            {doctorefeedback.RightExtensionR !== null && (
              <p>Active: {doctorefeedback.RightExtensionR} Degrees</p>
            )}
            {doctorefeedback.RightExtensionL !== null && (
              <p>Passive: {doctorefeedback.RightExtensionL} Degrees</p>
            )}
          </div>


          {/* ) : (
                        ""
                    )} */}
          {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}
          <div className="treatment-col">
            <label>Left Extension:</label>
            {doctorefeedback.LeftExtensionR !== null && (
              <p>Active: {doctorefeedback.LeftExtensionR} Degrees</p>
            )}
            {doctorefeedback.LeftExtensionL !== null && (
              <p>Passive: {doctorefeedback.LeftExtensionL} Degrees</p>
            )}
          </div>


          {/* ) : (
                        ""
                    )} */}
        </div>
        <div className="treatment-row mt-3">
          {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}
          <div className="treatment-col">
            <label>Right Abduction:</label>
            {doctorefeedback.RightAbductionR !== null && (
              <p>Active: {doctorefeedback.RightAbductionR} Degrees</p>
            )}
            {doctorefeedback.RightAbductionL !== null && (
              <p>Passive: {doctorefeedback.RightAbductionL} Degrees</p>
            )}
          </div>

          {/* ) : (
                        ""
                    )} */}
          {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}
          <div className="treatment-col">
            <label>Left Abduction:</label>
            {doctorefeedback.LeftAbductionR !== null && (
              <p>Active: {doctorefeedback.LeftAbductionR} Degrees</p>
            )}
            {doctorefeedback.LeftAbductionL !== null && (
              <p>Passive: {doctorefeedback.LeftAbductionL} Degrees</p>
            )}
          </div>
          {/* ) : (
                        ""
                    )} */}
        </div>
        <div className="treatment-row mt-3">
          {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}
          <div className="treatment-col">
            <label>Right Adduction:</label>
            {doctorefeedback.RightAdductionR !== null && (
              <p>Active: {doctorefeedback.RightAdductionR} Degrees</p>
            )}
            {doctorefeedback.RightAdductionL !== null && (
              <p>Passive: {doctorefeedback.RightAdductionL} Degrees</p>
            )}
          </div>
          {/* ) : (
                        ""
                    )} */}
          {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}

          <div className="treatment-col">
            <label>Left Adduction:</label>
            {doctorefeedback.LeftAdductionR !== null && (
              <p>Active: {doctorefeedback.LeftAdductionR} Degrees</p>
            )}
            {doctorefeedback.LeftAdductionL !== null && (
              <p>Passive: {doctorefeedback.LeftAdductionL} Degrees</p>
            )}
          </div>
          {/* ) : (
                        ""
                    )} */}
        </div>
        <div className="treatment-row mt-3">
          {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}
          <div className="treatment-col">
            <label>Right ER in flexion:</label>
            {doctorefeedback.RightERR !== null && (
              <p>Active: {doctorefeedback.RightERR} Degrees</p>
            )}
            {doctorefeedback.RightERL !== null && (
              <p>Passive: {doctorefeedback.RightERL} Degrees</p>
            )}
          </div>
          {/* ) : (
                        ""
                    )} */}
          {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}

          <div className="treatment-col">
            <label>Left ER in flexion:</label>
            {doctorefeedback.LeftERR !== null && (
              <p>Active: {doctorefeedback.LeftERR} Degrees</p>
            )}
            {doctorefeedback.LeftERL !== null && (
              <p>Passive: {doctorefeedback.LeftERL} Degrees</p>
            )}
          </div>
          {/* ) : (
                        ""
                    )} */}
        </div>
        <div className="treatment-row mt-3">
          {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}
          <div className="treatment-col">
            <label>Right ER in extension:</label>
            {doctorefeedback.RightERExR !== null && (
              <p>Active: {doctorefeedback.RightERExR} Degrees</p>
            )}
            {doctorefeedback.RightERExL !== null && (
              <p>Passive: {doctorefeedback.RightERExL} Degrees</p>
            )}
          </div>
          {/* ) : (
                        ""
                    )} */}
          {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}

          <div className="treatment-col">
            <label>Left ER in extension:</label>
            {doctorefeedback.LeftERExR !== null && (
              <p>Active: {doctorefeedback.LeftERExR} Degrees</p>
            )}
            {doctorefeedback.LeftERExL !== null && (
              <p>Passive: {doctorefeedback.LeftERExL} Degrees</p>
            )}
          </div>
          {/* ) : (
                        ""
                    )} */}
        </div>
        <div className="treatment-row mt-3">
          {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}
          <div className="treatment-col">
            <label>Right IR in flexion:</label>
            {doctorefeedback.RightIRFlexR !== null && (
              <p>Active: {doctorefeedback.RightIRFlexR} Degrees</p>
            )}
            {doctorefeedback.RightIRFlexL !== null && (
              <p>Passive: {doctorefeedback.RightIRFlexL} Degrees</p>
            )}
          </div>

          {/* ) : (
                        ""
                    )} */}
          {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}
          <div className="treatment-col">
            <label>Left IR in flexion:</label>
            {doctorefeedback.LeftIRFlexR !== null && (
              <p>Active: {doctorefeedback.LeftIRFlexR} Degrees</p>
            )}
            {doctorefeedback.LeftIRFlexL !== null && (
              <p>Passive: {doctorefeedback.LeftIRFlexL} Degrees</p>
            )}
          </div>
          {/* ) : (
                        ""
                    )} */}
        </div>
        <div className="treatment-row mt-3">
          {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}
          <div className="treatment-col">
            <label>Right IR in extension:</label>
            {doctorefeedback.RightIRExR !== null && (
              <p>Active: {doctorefeedback.RightIRExR} Degrees</p>
            )}
            {doctorefeedback.RightIRExL !== null && (
              <p>Passive: {doctorefeedback.RightIRExL} Degrees</p>
            )}
          </div>
          {/* ) : (
                        ""
                    )} */}
          {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}

          <div className="treatment-col ">
            <label>Left IR in extension:</label>
            {doctorefeedback.LeftIRExR !== null && (
              <p>Active: {doctorefeedback.LeftIRExR} Degrees</p>
            )}
            {doctorefeedback.LeftIRExL !== null && (
              <p>Passive: {doctorefeedback.LeftIRExL} Degrees</p>
            )}
          </div>
          {/* ) : (
                        ""
                    )} */}
        </div>
        <div className="treatment-row mt-3">
          {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}
          <div className="treatment-col">
            <label>Right Abductor Power:</label>
            {doctorefeedback.subscapularisPowerR !== null && (
              <p>{doctorefeedback.subscapularisPowerR} Kgs</p>
            )}
          </div>

          {/* ) : (
                        ""
                    )} */}
          {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}
          <div className="treatment-col">
            <label>Left Abductor Power:</label>
            {doctorefeedback.subscapularisPowerL !== null && (
              <p>{doctorefeedback.subscapularisPowerL} Kgs</p>
            )}
          </div>
          {/* ) : (
                        ""
                    )} */}
        </div>

        <div className="treatment-header ps-0">
          <h2>Tenderness</h2>
        </div>
        <div className="treatment-row">
          <div className="treatment-table">
            <table cellpadding="0" cellspacing="0">
              <thead>
                <tr>
                  <th></th>
                  {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}
                  <th>Right Hip</th>


                  {/* ) : (
                                        ""
                                    )} */}
                  {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}
                  <th>Left Hip</th>
                  {/* // ) : (
                                    //     ""
                                    // )} */}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Greater trochanter:</td>
                  {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}
                  <td>
                    {doctorefeedback.greaterRightVal === "0"
                      ? "Negative"
                      : doctorefeedback.greaterRightVal === "1"
                        ? "Positive"
                        : ""}
                  </td>

                  {/* ) : (
                                        ""
                                    )} */}
                  {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}
                  <td>
                    {doctorefeedback.greaterLeftVal === "0"
                      ? "Negative"
                      : doctorefeedback.greaterLeftVal === "1"
                        ? "Positive"
                        : ""}
                  </td>

                  {/* // ) : (
                                    //     ""
                                    // )} */}
                </tr>
                <tr>
                  <td>Inguinal:</td>
                  {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}

                  <td>
                    {doctorefeedback.inguinalRightVal === "0"
                      ? "Negative"
                      : doctorefeedback.inguinalRightVal === "1"
                        ? "Positive"
                        : ""}
                  </td>


                  {/* ) : (
                                        ""
                                    )} */}
                  {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}
                  <td>
                    {doctorefeedback.inguinalLeftVal === "0"
                      ? "Negative"
                      : doctorefeedback.inguinalLeftVal === "1"
                        ? "Positive"
                        : ""}
                  </td>
                  {/* // ) : (
                                    //     ""
                                    // )} */}
                </tr>
                <tr>
                  <td>Others</td>
                  {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}
                  <td>{doctorefeedback.others_r}</td>
                  {/* ) : (
                                        ""
                                    )} */}
                  {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}
                  <td>{doctorefeedback.others_l}</td>
                  {/* // ) : (
                                    //     ""
                                    // )} */}
                </tr>
                {/* <tr>
                                    <td>Log roll test:</td>
                                    // {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? (
                                        <td>{doctorefeedback.LogRightVal === "0" ? "Negative" : "Positive"}</td>
                                    ) : (
                                        ""
                                    )}
                                    // {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? (
                                        <td>{doctorefeedback.LogLeftVal === "0" ? "Negative" : "Positive"}</td>
                                    ) : (
                                        ""
                                    )}
                                </tr>
                                <tr>
                                    <td>Galeazzi’s sign:</td>
                                    // {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? (
                                        <td>{doctorefeedback.GaleazziRightVal === "0" ? "Negative" : "Positive"}</td>
                                    ) : (
                                        ""
                                    )}
                                    // {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? (
                                        <td>{doctorefeedback.GaleazziLeftVal === "0" ? "Negative" : "Positive"}</td>
                                    ) : (
                                        ""
                                    )}
                                </tr>
                                <tr>
                                    <td>Trendelenburg sign:</td>
                                    // {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? (
                                        <td>{doctorefeedback.TrendelenburgRightVal === "0" ? "Negative" : "Positive"}</td>
                                    ) : (
                                        ""
                                    )}
                                    // {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? (
                                        <td>{doctorefeedback.TrendelenburgLeftVal === "0" ? "Negative" : "Positive"}</td>
                                    ) : (
                                        ""
                                    )}
                                </tr>
                                <tr>
                                    <td>Thomas test:</td>
                                    // {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? (
                                        <td>{doctorefeedback.ThomasRightVal === "0" ? "Negative" : "Positive"}</td>
                                    ) : (
                                        ""
                                    )}
                                    // {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? (
                                        <td>{doctorefeedback.ThomasLeftVal === "0" ? "Negative" : "Positive"}</td>
                                    ) : (
                                        ""
                                    )}
                                </tr>
                                <tr>
                                    <td>FADIR/Impingement test:</td>
                                    // {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? (
                                        <td>{doctorefeedback.FADIRRightVal === "0" ? "Negative" : "Positive"}</td>
                                    ) : (
                                        ""
                                    )}
                                    // {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? (
                                        <td>{doctorefeedback.FADIRLeftVal === "0" ? "Negative" : "Positive"}</td>
                                    ) : (
                                        ""
                                    )}
                                </tr>
                                <tr>
                                    <td>Patrick/FABER test:</td>
                                    // {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? (
                                        <td>{doctorefeedback.PatrickRightVal === "0" ? "Negative" : "Positive"}</td>
                                    ) : (
                                        ""
                                    )}
                                    // {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? (
                                        <td>{doctorefeedback.PatrickLeftVal === "0" ? "Negative" : "Positive"}</td>
                                    ) : (
                                        ""
                                    )}
                                </tr>
                                <tr>
                                    <td>Ober test:</td>
                                    // {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? (
                                        <td>{doctorefeedback.OberRightVal === "0" ? "Negative" : "Positive"}</td>
                                    ) : (
                                        ""
                                    )}
                                    // {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? (
                                        <td>{doctorefeedback.OberLeftVal === "0" ? "Negative" : "Positive"}</td>
                                    ) : (
                                        ""
                                    )}
                                </tr>
                                <tr>
                                    <td>Straight leg raise:</td>
                                    // {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? (
                                        <td>{doctorefeedback.StraightRightVal === "0" ? "Negative" : "Positive"}</td>
                                    ) : (
                                        ""
                                    )}
                                    // {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? (
                                        <td>{doctorefeedback.StraightLeftVal === "0" ? "Negative" : "Positive"}</td>
                                    ) : (
                                        ""
                                    )}
                                </tr>
                                <tr>
                                    <td>Barlow:</td>
                                    // {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? (
                                        <td>{doctorefeedback.BarlowRightVal === "0" ? "Negative" : "Positive"}</td>
                                    ) : (
                                        ""
                                    )}
                                    // {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? (
                                        <td>{doctorefeedback.BarlowLeftVal === "0" ? "Negative" : "Positive"}</td>
                                    ) : (
                                        ""
                                    )}
                                </tr>
                                <tr>
                                    <td>Ortolani:</td>
                                    // {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? (
                                        <td>{doctorefeedback.OrtolaniRightVal === "0" ? "Negative" : "Positive"}</td>
                                    ) : (
                                        ""
                                    )}
                                    // {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? (
                                        <td>{doctorefeedback.OrtolaniLeftVal === "0" ? "Negative" : "Positive"}</td>
                                    ) : (
                                        ""
                                    )}
                                </tr>
                                <tr>
                                    <td>Asymmetric skin creases:</td>
                                    // {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? (
                                        <td>{doctorefeedback.AsymmetricRightVal === "0" ? "Negative" : "Positive"}</td>
                                    ) : (
                                        ""
                                    )}
                                    // {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? (
                                        <td>{doctorefeedback.AsymmetricLeftVal === "0" ? "Negative" : "Positive"}</td>
                                    ) : (
                                        ""
                                    )}
                                </tr> */}
              </tbody>
            </table>
          </div>
        </div>

        <div className="treatment-header ps-0">
          <h2>Special tests</h2>
        </div>
        <div className="treatment-row">
          <div className="treatment-table">
            <table cellpadding="0" cellspacing="0">
              <thead>
                <tr>
                  <th></th>
                  {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}
                  <th>Right Hip</th>

                  {/* ) : (
                                        ""
                                    )} */}
                  {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}
                  <th>Left Hip</th>
                  {/* // ) : (
                                    //     ""
                                    // )} */}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Log roll test:</td>
                  {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}
                  <td>
                    {doctorefeedback.LogRightVal === "0"
                      ? "Negative"
                      : doctorefeedback.LogRightVal === "1"
                        ? "Positive"
                        : ""}
                  </td>
                  {/* ) : (
                                        ""
                                    )} */}
                  {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}
                  <td>
                    {doctorefeedback.LogLeftVal === "0"
                      ? "Negative"
                      : doctorefeedback.LogLeftVal === "1"
                        ? "Positive"
                        : ""}
                  </td>
                  {/* // ) : (
                                    //     ""
                                    // )} */}
                </tr>
                <tr>
                  <td>Galeazzi’s sign:</td>
                  {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}
                  <td>
                    {doctorefeedback.GaleazziRightVal === "0"
                      ? "Negative"
                      : doctorefeedback.GaleazziRightVal === "1"
                        ? "Positive"
                        : ""}
                  </td>

                  {/* ) : (
                                        ""
                                    )} */}
                  {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}
                  <td>
                    {doctorefeedback.GaleazziLeftVal === "0"
                      ? "Negative"
                      : doctorefeedback.GaleazziLeftVal === "1"
                        ? "Positive"
                        : ""}
                  </td>
                  {/* // ) : (
                                    //     ""
                                    // )} */}
                </tr>
                <tr>
                  <td>Trendelenburg sign:</td>
                  {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}
                  <td>
                    {doctorefeedback.TrendelenburgRightVal === "0"
                      ? "Negative"
                      : doctorefeedback.TrendelenburgRightVal === "1"
                        ? "Positive"
                        : ""}
                  </td>

                  {/* ) : (
                                        ""
                                    )} */}
                  {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}
                  <td>
                    {doctorefeedback.TrendelenburgLeftVal === "0"
                      ? "Negative"
                      : doctorefeedback.TrendelenburgLeftVal === "1"
                        ? "Positive"
                        : ""}
                  </td>

                  {/* // ) : (
                                    //     ""
                                    // )} */}
                </tr>
                <tr>
                  <td>Thomas test:</td>
                  {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}
                  <td>
                    {doctorefeedback.ThomasRightVal === "0"
                      ? "Negative"
                      : doctorefeedback.ThomasRightVal === "1"
                        ? "Positive"
                        : ""}
                  </td>

                  {/* ) : (
                                        ""
                                    )} */}
                  {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}
                  <td>
                    {doctorefeedback.ThomasLeftVal === "0"
                      ? "Negative"
                      : doctorefeedback.ThomasLeftVal === "1"
                        ? "Positive"
                        : ""}
                  </td>

                  {/* // ) : (
                                    //     ""
                                    // )} */}
                </tr>
                <tr>
                  <td>FADIR/Impingement test:</td>
                  {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}
                  <td>
                    {doctorefeedback.FADIRRightVal === "0"
                      ? "Negative"
                      : doctorefeedback.FADIRRightVal === "1"
                        ? "Positive"
                        : ""}
                  </td>

                  {/* ) : (
                                        ""
                                    )} */}
                  {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}
                  <td>
                    {doctorefeedback.FADIRLeftVal === "0"
                      ? "Negative"
                      : doctorefeedback.FADIRLeftVal === "1"
                        ? "Positive"
                        : ""}
                  </td>

                  {/* // ) : (
                                    //     ""
                                    // )} */}
                </tr>
                <tr>
                  <td>Patrick/FABER test:</td>
                  {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}

                  {/* ) : (
                                        ""
                                    )} */}
                  {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}
                  <td>
                    {doctorefeedback.PatrickRightVal === "0"
                      ? "Negative"
                      : doctorefeedback.PatrickRightVal === "1"
                        ? "Positive"
                        : ""}
                  </td>
                  <td>
                    {doctorefeedback.PatrickLeftVal === "0"
                      ? "Negative"
                      : doctorefeedback.PatrickLeftVal === "1"
                        ? "Positive"
                        : ""}
                  </td>

                  {/* // ) : (
                                    //     ""
                                    // )} */}
                </tr>
                <tr>
                  <td>Ober test:</td>
                  {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}

                  {/* ) : (
                                        ""
                                    )} */}
                  {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}
                  <td>
                    {doctorefeedback.OberRightVal === "0"
                      ? "Negative"
                      : doctorefeedback.OberRightVal === "1"
                        ? "Positive"
                        : ""}
                  </td>
                  <td>
                    {doctorefeedback.OberLeftVal === "0"
                      ? "Negative"
                      : doctorefeedback.OberLeftVal === "1"
                        ? "Positive"
                        : ""}
                  </td>

                  {/* // ) : (
                                    //     ""
                                    // )} */}
                </tr>
                <tr>
                  <td>Straight leg raise:</td>
                  {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}
                  <td>
                    {doctorefeedback.StraightRightVal === "0"
                      ? "Negative"
                      : doctorefeedback.StraightRightVal === "1"
                        ? "Positive"
                        : ""}
                  </td>
                  {/* ) : (
                                        ""
                                    )} */}
                  {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}

                  <td>
                    {doctorefeedback.StraightLeftVal === "0"
                      ? "Negative"
                      : doctorefeedback.StraightLeftVal === "1"
                        ? "Positive"
                        : ""}
                  </td>

                  {/* // ) : (
                                    //     ""
                                    // )} */}
                </tr>
                <tr>
                  <td>Barlow:</td>
                  {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}
                  <td>
                    {doctorefeedback.BarlowRightVal === "0"
                      ? "Negative"
                      : doctorefeedback.BarlowRightVal === "1"
                        ? "Positive"
                        : ""}
                  </td>
                  {/* ) : (
                                        ""
                                    )} */}

                  {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}
                  <td>
                    {doctorefeedback.BarlowLeftVal === "0"
                      ? "Negative"
                      : doctorefeedback.BarlowLeftVal === "1"
                        ? "Positive"
                        : ""}
                  </td>


                  {/* // ) : (
                                    //     ""
                                    // )} */}
                </tr>
                <tr>
                  <td>Ortolani:</td>
                  {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}
                  <td>
                    {doctorefeedback.OrtolaniRightVal === "0"
                      ? "Negative"
                      : doctorefeedback.OrtolaniRightVal === "1"
                        ? "Positive"
                        : ""}
                  </td>
                  {/* ) : (
                                        ""
                                    )} */}
                  {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}
                  <td>
                    {doctorefeedback.OrtolaniLeftVal === "0"
                      ? "Negative"
                      : doctorefeedback.OrtolaniLeftVal === "1"
                        ? "Positive"
                        : ""}
                  </td>


                  {/* // ) : (
                                    //     ""
                                    // )} */}
                </tr>
                <tr>
                  <td>Asymmetric skin creases:</td>
                  {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}
                  <td>
                    {doctorefeedback.AsymmetricRightVal === "0"
                      ? "Negative"
                      : doctorefeedback.AsymmetricRightVal === "1"
                        ? "Positive"
                        : ""}
                  </td>

                  <td>
                    {doctorefeedback.AsymmetricLeftVal === "0"
                      ? "Negative"
                      : doctorefeedback.AsymmetricLeftVal === "1"
                        ? "Positive"
                        : ""}
                  </td>

                </tr>
                <tr>
                  <td></td>

                  <td>
                    {" "}
                    {doctorefeedback.AsymmetricRightVal === "1" &&
                      doctorefeedback.asymmetricR === "1"
                      ? "Inguina"
                      : doctorefeedback.asymmetricR === "2"
                        ? "Gluteal"
                        : doctorefeedback.asymmetricR === "3"
                          ? "Thigh"
                          : doctorefeedback.asymmetricR === "4"
                            ? "Popliteal"
                            : doctorefeedback.asymmetricR === "5"
                              ? "Others"
                              : ""}
                  </td>

                  <td>
                    {" "}
                    {doctorefeedback.AsymmetricLeftVal === "1"
                      ? doctorefeedback.asymmetricL === "1"
                        ? "Inguina"
                        : doctorefeedback.asymmetricL === "2"
                          ? "Gluteal"
                          : doctorefeedback.asymmetricL === "3"
                            ? "Thigh"
                            : doctorefeedback.asymmetricL === "4"
                              ? "Popliteal"
                              : doctorefeedback.asymmetricL === "5"
                                ? "Others"
                                : doctorefeedback.asymmetricOtherL
                      : ""}
                  </td>

                </tr>

                <tr>
                  <td></td>

                  <td>
                    {doctorefeedback.asymmetricR === "5" &&
                      doctorefeedback.asymmetricOtherR}
                  </td>

                  <td>
                    {doctorefeedback.asymmetricL === "5" &&
                      doctorefeedback.asymmetricOtherL}
                  </td>

                </tr>

                <tr>
                  <td>Others</td>
                  {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}

                  {/* ) : (
                                        ""
                                    )} */}
                  {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}
                  <td>{doctorefeedback.others2_r}</td>
                  <td>{doctorefeedback.others2_l}</td>
                  {/* // ) : (
                                    //     ""
                                    // )} */}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
