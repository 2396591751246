import React from "react";
import "../../../css/ViewTreatmentForm.css";
import useTranslation from "../../customHooks/translations";
import { UserDateFormat } from "../../../utilities/DateFormate";
function PersonalInformation({ profileAllData, AllData }) {
  const translations = useTranslation();
  return (
    <>
      <div className="whitebox padding-none">
        <div className="treatment-header">
          <h2>Personal Information</h2>
          <div className="treatment-header-right">
            <p>Assessment Date : {UserDateFormat(AllData.assessment_date,profileAllData.ing_date_formate) }</p>
          </div>
        </div>

        <div className="treatment-content">
          <div className="treatment-row">
            <div className="treatment-col">
              <label>Name:</label>
              {profileAllData.ing_firstname} {profileAllData.ing_lastname}
            </div>

            <div className="treatment-col">
              <label>Date Of Birth:</label>
              {profileAllData.ing_dob}
            </div>
          </div>

          <div className="treatment-row">
            <div className="treatment-col">
              <label>Sex:</label>
              {/* {profileAllData.ing_gender === "2" ? "Female" : "Male"} */}
              {profileAllData.ing_gender === "1"
                ? "Male"
                : profileAllData.ing_gender === "2"
                  ? "Female"
                  : profileAllData.ing_gender === "3"
                    ? "Do not want to disclose" 
                    : "" }
            </div>
            <div className="treatment-col">
              <label>{translations.patient_Treatment_Category}:</label>
              {translations.General}
             
            </div>
            
            {/* <div className="treatment-col">
              <label>Dominant Hand:</label>
              Bilateral
            </div> */}
          </div>

          <div className="treatment-row">
          <div className="treatment-col">
              <label>Initial Assessment:</label>
              {AllData.initial_assessment === 1 ? "Yes" : "No"}
            </div>
          </div>

        

         
        </div>
      </div>
    </>
  );
}

export default PersonalInformation;
