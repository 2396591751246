import React from 'react'
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import backIcon from "../../../../images/back.png";
import nextWhiteIcon from "../../../../images/next-white.png";
import nextIcon from "../../../../images/next.png";
// import RangeSlider from '../../../custom/range-slider/RangeSlider';
import useAxios from "../../../../axiosinstance";
import useTranslation from "../../../customHooks/translations"
import RangeSlider from "../../../custom/range-slider/RangeSlider";
import {
  updateElbowSatisficationFieldScore,
  updateElbowSatisficationData,
  updateElbowScores,
  updateElbowPainData,
  updateElbowFunctionData,
  updateElbowQOLData,
  updateElbowFeedbackScores,
  updateElbowSymptomsData,
  updateAllData

} from "../../../../redux/actions/elbowAction";

import ElbowTab from '../../tab-section/ElbowTab';
import { useSearchParams } from "react-router-dom";
import CustomToaster from "../../../custom/toaster/CustomToaster"
import { LeftSide, LeftTrans, RightSide, RigthTrans } from '../../../../utilities/RightLeftFormat';
import NameCustom from '../../../custom/name/NameCustom';

export default function ElbowSatisfaction() {
  const translation = useTranslation()

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { rId } = useParams();
  const [searchParams] = useSearchParams();
  let doctorEnable = searchParams.get("d");
  const axiosinstance = useAxios();
  const [rateElbowSatisfactionL, setRateElbowSatisfactionL] = useState("0");
  const [rateElbowSatisfactionR, setRateElbowSatisfactionR] = useState("0");
  const ElbowSatisfaction = useSelector((state) => state.elbowScore.satisfactionScore[0]);
  const json = useSelector((state) => state.elbowScore);
  const [chk, setChk] = useState(false);
  const [updateTotal, setUpdateTotal] = useState(false);

  const [applicableR, setapplicableR] = useState(false)
  const [applicableL, setapplicableL] = useState(false)
  const [doctorStatus, setDoctorStatus] = useState(false);
  const save = () => {
    axiosinstance.current
      .get(`extapp/forms/all_forms?resource_id=${rId}&type=22`)
      .then((res) => {
        dispatch(updateElbowPainData(res.data.data2[0].painScore));
        dispatch(updateElbowSymptomsData(res.data.data2[0].symptomsScore));
        dispatch(updateElbowScores(res.data.data2[0].elbowPain));
        dispatch(updateElbowFunctionData(res.data.data2[0].functionScore));
        dispatch(updateElbowQOLData(res.data.data2[0].qolScore));
        dispatch(
          updateElbowSatisficationData(res.data.data2[0].satisfactionScore)
        );
        dispatch(updateElbowFeedbackScores(res.data.data2[0].doctorFeedback));
        setDoctorStatus(res.data.doctorStatus);
        setChk(true);
      }).catch((err) => {

        navigate('/un-authorized-access');

      });
  };
  useEffect(() => {
    save();
  }, []);
  useEffect(() => {
    setRateElbowSatisfactionL(ElbowSatisfaction.rateElbowSatisfactionL)
    setRateElbowSatisfactionR(ElbowSatisfaction.rateElbowSatisfactionR)
    setapplicableR(ElbowSatisfaction.applicableR)
    setapplicableL(ElbowSatisfaction.applicableL)
  }, [ElbowSatisfaction]);
  const updateDate = () => {
    axiosinstance.current
      .patch(
        `extapp/forms/update_all_form_type?resource_id=${rId}&action=${doctorEnable === "true" ? "1" : "0"}`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res);
      });
  };
  useEffect(() => {
    if (chk) {
      updateDate();
    }
  }, [ElbowSatisfaction, updateTotal]);


  const handleapplicableR = (e) => {
    setapplicableR(e)
    handleRateElbowSatisfactionR(0)
    // setOverallR("0")
    dispatch(updateElbowSatisficationFieldScore({
      "key": 'applicableR',
      "value": e
    }))
    dispatch(updateElbowSatisficationFieldScore({
      "key": 'rateElbowSatisfactionR',
      "value": ""
    }))
  }

  const handleapplicableL = (e) => {
    handleRateElbowSatisfactionL(0)
    setapplicableL(e)
    // setOverallL("0")
    dispatch(updateElbowSatisficationFieldScore({
      "key": 'applicableL',
      "value": e
    }))
    dispatch(updateElbowSatisficationFieldScore({
      "key": 'rateElbowSatisfactionL',
      "value": ""
    }))
  }


  const handleRateElbowSatisfactionL = (e) => {
    dispatch(updateElbowSatisficationFieldScore({
      "key": 'rateElbowSatisfactionL',
      "value": e
    }))
    setRateElbowSatisfactionL(e);
  };
  const handleRateElbowSatisfactionR = (e) => {
    dispatch(updateElbowSatisficationFieldScore({
      "key": 'rateElbowSatisfactionR',
      "value": e
    }))
    setRateElbowSatisfactionR(e);
  };
  const continueRedirect = (e) => {
    dispatch(updateElbowSatisficationData(
      [{
        rateElbowSatisfactionL,
        rateElbowSatisfactionR
      }]
    ))
    navigate("/elbow-complete/" + rId);
  };
  const evlElbowPain = useSelector((state) => state.elbowScore.elbowPain);
  const SubmitForm = (id) => {
    axiosinstance.current.patch(`extapp/forms/update_scoring?resource_id=${id}`, {
      patient_scoring_date: new Date(),
      status: 1,
    });
  };
  let akpt = searchParams.get("akpt");
  const [msg, setMsg] = useState('');
  const [msgType, setmsgType] = useState('');
  const handleSubmit = () => {
    updateAllData(json, dispatch);
    setUpdateTotal(!updateTotal);
    setMsg(translation.update)
    SubmitForm(rId)
    setmsgType('success')
   setTimeout(() => {
     let view_circular=  sessionStorage.getItem("view_circular")
      // window.location.href = process.env.REACT_APP_DOCTOR_URL + "patient-view/" + akpt
      // window.location.href = process.env.REACT_APP_DOCTOR_URL +`view-management-plan/${akpt}/${window.btoa(
      //   rId
      // )}/?org=${window.btoa(sessionStorage.getItem("orgid")||'')}&formType=${
      //   window.btoa('22') || ""
      // }&view_circular=${view_circular==='false'?'false':'true'}`
       window.location.href = process.env.REACT_APP_DOCTOR_URL +`patient-details/${akpt}?formid=22`
      setMsg('')
      setmsgType('success')
    }, 2000);
  };
  const handleNext = () => {
    navigate(`/elbow-feedback/${rId}?d=true&akpt=${akpt}`);

  }




  return (
    <div className="pain_screen mb_100">
      <div class="d-flex justify-content-end">
        <NameCustom />
      </div>
      <div className="panel">
        <div className="panel-body p_25">
          {doctorEnable === "true" && <ElbowTab rId={rId} />}
          <CustomToaster msg={msg} msgType={msgType} />

          <h4 className="ph_title f_40">{translation.patient_elbow_satisfaction}</h4>
          <div className="row mb_40">
            {evlElbowPain === "1" || evlElbowPain === "3" ? (
              <div className={`mb_20 ${evlElbowPain === "3" ? "col-sm-6 col-12" : ""}` + RightSide()}>
                <div className='mt-5 satis'>
                  <label class="form-check-label not-applicable2">
                    <input className="check_" type="checkbox" id="radio1" name="radio1" value={applicableR} checked={applicableR} style={{ marginRight: 10 }} onChange={(e) => handleapplicableR(e.target.checked)}></input>

                    {translation.patient_elbow_satisfaction_na}
                  </label>
                </div>

                <RangeSlider
                  id="rateKneeFunctionR"
                  colorcode="1"
                  min="0"
                  max="10"
                  step="1"
                  type="text"
                  leftTitle={translation.patient_elbow_satisfaction_q1_a1}
                  rightTitle={translation.patient_elbow_satisfaction_q1_a2}
                  label={translation.patient_elbow_satisfaction_q1 + RigthTrans(evlElbowPain, translation)}
                  value={rateElbowSatisfactionR}
                  onChange={handleRateElbowSatisfactionR}
                  view={applicableR === true ? true : false}


                />
              </div>

            ) : (
              " "
            )}

            {evlElbowPain === "2" || evlElbowPain === "3" ? (
              <div className={`mb_20 ${evlElbowPain === "3" ? "col-sm-6 col-12" : ""}` + LeftSide()}>
                <div className='mt-5 satis'>
                  <label class="form-check-label not-applicable2">
                    <input className="check_" type="checkbox" id="radio2" name="radio2" value={applicableL} checked={applicableL} style={{ marginRight: 10 }} onChange={(e) => handleapplicableL(e.target.checked)}></input>

                    {translation.patient_elbow_satisfaction_na}
                  </label>
                </div>
                <RangeSlider
                  id="rateKneeFunctionR"
                  colorcode="1"
                  min="0"
                  max="10"
                  step="1"
                  type="text"
                  leftTitle={translation.patient_elbow_satisfaction_q1_a1}
                  rightTitle={translation.patient_elbow_satisfaction_q1_a2}
                  label={translation.patient_elbow_satisfaction_q1 + LeftTrans(evlElbowPain, translation)}
                  value={rateElbowSatisfactionL}
                  onChange={handleRateElbowSatisfactionL}
                  view={applicableL === true ? true : false}


                />

              </div>
            ) : (
              " "
            )}
          </div>

          {doctorEnable === "true" && (
            <>
              {doctorStatus ? (
                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6">
                  </div>
                  <div className="col-md-6 col-sm-6 text-end">
                    <button
                      className="next_btn btn_fill me-3"
                      onClick={() => handleNext()}
                    >
                      {translation.next_button}
                      <span>
                        <img src={nextIcon} alt="" />
                        {/* <img src={nextWhiteIcon} alt="" /> */}
                      </span>
                    </button>
                    <button
                      className="next_btn btn_fill"
                      onClick={() => handleSubmit()}
                    >
                      {translation.submit}
                      <span>
                        <img src={nextIcon} alt="" />
                        {/* <img src={nextWhiteIcon} alt="" /> */}
                      </span>
                    </button>
                  </div>
                </div>
              ) : (
                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6">
                  </div>
                  <div className="col-md-6 col-sm-6 text-end">
                    <button
                      className="next_btn btn_fill"
                      onClick={() => handleSubmit()}
                    >
                      {"Submit"}
                      <span>
                        <img src={nextIcon} alt="" />
                        {/* <img src={nextWhiteIcon} alt="" /> */}
                      </span>
                    </button>
                  </div>
                </div>
              )}
            </>
          )}

          {doctorEnable !== "true" && (
            <div className="row mb_30 button_box">
              <div className="col-md-6 col-sm-6 col-6">
                <Link to={"/elbow-qol/" + rId} className="assm_back_btn">
                  <img src={backIcon} alt="" />
                  <span>{translation.back_button}</span>
                </Link>
              </div>
              <div className="col-md-6 col-sm-6 col-6 text-end">
                <button
                  className="next_btn btn_fill"
                  onClick={continueRedirect}
                >
                  {translation.next_button}
                  <span>
                    <img src={nextIcon} alt="" />
                    <img src={nextWhiteIcon} alt="" />
                  </span>
                </button>
              </div>
            </div>
          )}


        </div>
      </div>
    </div>
  )
}