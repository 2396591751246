import React from "react";
import { Link } from "react-router-dom";
import pprofileIcon from "../../../images/profile-img.png";
import breadcrumbStyles from "./HeaderInnerBreadcrumb.module.css";

function HeaderInnerBreadcrumb() {
  return (
    <>
   
      {/* <div className={`${breadcrumbStyles.inner_breadcrmb}`}>
        <div className="row">
          <div className="col-md-6 col-sm-6">
            <ul className={`${breadcrumbStyles.breadcrumb}`}>
              <li><Link to="/">Start</Link></li>
              <li className={`${breadcrumbStyles.active}`}>Flexion</li>
            </ul>
          </div>
          <div className="col-md-6 col-sm-6 text-end">
          <div className={`${breadcrumbStyles.form_loginuser}`}>
            <div className={`${breadcrumbStyles.user_image}`}>
              <img src={pprofileIcon} alt="" />
            </div>
            Test Name
        </div>
          </div>
        </div>
      </div> */}
    </>
  );
}

export default HeaderInnerBreadcrumb;
