
import React, { useState, useEffect, useMemo, useCallback } from "react";
import RadioSwitch from "../../../custom/radio-switch/RadioSwitch";
import useTranslation from "../../../customHooks/translations";
import debounce from "lodash.debounce";
import { useDispatch, useSelector } from "react-redux";
import InputField from "../../../custom/inputfield/InputField";
import Select from "react-select";
import Checkbox from "../../../custom/checkbox/Checkbox";

import {
  updateHistoryComplaintFieldsScore,
  updateHistoryComplaintScore,
  updateSocialComplaintFieldsScore,
  updateSocialComplaintScore,
  updatePastComplaintFieldsScore,
  updatePastComplaintScore,
  updateStatus
} from "../../../../redux/actions/GeneralAction";

const PastIII = ({ Past }) => {
  const dispatch = useDispatch();
  const debouncedDispatch = useCallback(
    debounce((key, value) => {
      dispatch(updatePastComplaintFieldsScore({ key, value }));
    }, 100), // Adjust the debounce delay as needed
    [dispatch]
  );
  const translation = useTranslation();


  useEffect(() => {
    setDrop1(Past.knowAllergie)
    setDrop3(Past.allergicLatex);
    setDrop4(Past.bloodPressure);
    setDrop42(Past.drop42);
    setDrop5(Past.heartIllness);
    setDrop6(Past.dropIllness);
    setDrop43(Past.drop43);
    setDrop44(Past.drop44);
    setseriousradio(Past.seriousradio);
    setDrop7(Past.cardiologist);
    setseriousradio2(Past.seriousradio2)
    setDrop8(Past.vascularDisease)
    setDrop9(Past.drop9)
    setDrop10(Past.drop10)
    setDrop503(Past.drop503)
    setseriousradio3(Past.seriousradio3)
    setDropLung(Past.droplung)
    setCheck_Value10(Past.checkAsth)
    setCheck_Value11(Past.checkCOPD)
    setCheck_Value12(Past.checkHigh)
    setCheck_Value13(Past.checkCystic)
    setCheck_Value14(Past.checkPulmo)
    setCheck_Value15(Past.checkTB)
    setCheck_Value16(Past.checkSarco)
    setCheckOther(Past.checkOther)
    setDrop511(Past.drop511)
    setDrop512(Past.drop512)
    setDrop510(Past.drop510)
    setDrop11(Past.drop11)
    setDrop12(Past.drop12)
    setDrop99(Past.drop99)
    setDrop13(Past.drop13)
    setDrop14(Past.drop14)
    setDrop15(Past.drop15)
    setDrop16(Past.drop16)
    setCheck_Value80(Past.checkvalue80)
    setCheck_Value81(Past.checkvalue81)
    setCheck_Value82(Past.checkvalue82)
    setCheck_Value50(Past.checkvalue50)
    setDrop50(Past.drop50)

  }, [Past])

  const [knowAllergie, setDrop1] = useState("");

  const handle_switch1 = (e) => {
    // dispatch(
    //   updatePastComplaintFieldsScore({
    //     key: "knowAllergie",
    //     value: e,
    //   })
    // );
    setDrop1(e);
    debouncedDispatch("knowAllergie", e)
  };




  const [reaction, setDrop2] = useState("");


  const handle_switch2 = (e) => {
    // dispatch(
    //   updatePastComplaintFieldsScore({
    //     key: "reaction",
    //     value: e,
    //   })
    // );
    setDrop2(e);
    debouncedDispatch("reaction", e)
  };

  const [allergicLatex, setDrop3] = useState("0");
  const handle_switch3 = (e) => {
    // dispatch(
    //   updatePastComplaintFieldsScore({
    //     key: "allergicLatex",
    //     value: e,
    //   })
    // );
    setDrop3(e);
    debouncedDispatch("allergicLatex", e)
  };

  const [bloodPressure, setDrop4] = useState("0");
  const handle_switch4 = (e) => {
    // dispatch(
    //   updatePastComplaintFieldsScore({
    //     key: "bloodPressure",
    //     value: e,
    //   })
    // );
    setDrop4(e);
    debouncedDispatch("bloodPressure", e)
  };

  const [drop42, setDrop42] = useState("");
  const handle_switch42 = (e) => {
    // dispatch(
    //   updatePastComplaintFieldsScore({
    //     key: "drop42",
    //     value: e,
    //   })
    // );
    setDrop42(e);
    debouncedDispatch("drop42", e)
  };

  const [heartIllness, setDrop5] = useState("0");
  const handle_switch5 = (e) => {
    // dispatch(
    //   updatePastComplaintFieldsScore({
    //     key: "heartIllness",
    //     value: e,
    //   })
    // );
    setDrop5(e);
    debouncedDispatch("heartIllness", e)
  };

  const [dropIllness, setDrop6] = useState("0");
  const handle_drop1 = (e) => {
    // dispatch(
    //   updatePastComplaintFieldsScore({
    //     key: "dropIllness",
    //     value: e,
    //   })
    // );
    setDrop6(e);
    debouncedDispatch("dropIllness", e)
  };

  const [drop43, setDrop43] = useState("");
  const handle_switch43 = (e) => {
    // dispatch(
    //   updatePastComplaintFieldsScore({
    //     key: "drop43",
    //     value: e,
    //   })
    // );
    setDrop43(e);
    debouncedDispatch("drop43", e)
  };

  const [drop44, setDrop44] = useState("");
  const handle_switch44 = (e) => {
    // dispatch(
    //   updatePastComplaintFieldsScore({
    //     key: "drop44",
    //     value: e,
    //   })
    // );
    setDrop44(e);
    debouncedDispatch("drop44", e)
  };

  const [seriousradio, setseriousradio] = useState("")
  const handleseriousradio = (e) => {
    // dispatch(
    //   updatePastComplaintFieldsScore({
    //     key: "seriousradio",
    //     value: e,
    //   }))
    setseriousradio(e)
    debouncedDispatch("seriousradio", e)
  }

  const [cardiologist, setDrop7] = useState("");
  const handle_switch7 = (e) => {
    // dispatch(
    //   updatePastComplaintFieldsScore({
    //     key: "cardiologist",
    //     value: e,
    //   })
    // );
    debouncedDispatch("cardiologist", e)
    setDrop7(e);
  };


  const [seriousradio2, setseriousradio2] = useState("")
  const handleseriousradio2 = (e) => {
    // dispatch(
    //   updatePastComplaintFieldsScore({
    //     key: "seriousradio2",
    //     value: e,
    //   }))
    setseriousradio2(e)
    debouncedDispatch("seriousradio2", e)
  }

  const [vascularDisease, setDrop8] = useState("");
  const handle_switch8 = (e) => {
    // dispatch(
    //   updatePastComplaintFieldsScore({
    //     key: "vascularDisease",
    //     value: e,
    //   })
    // );
    setDrop8(e);
    debouncedDispatch("vascularDisease", e)
  };

  const [drop9, setDrop9] = useState("0");
  const handle_switch9 = (e) => {
    // dispatch(
    //   updatePastComplaintFieldsScore({
    //     key: "drop9",
    //     value: e,
    //   })
    // );
    setDrop9(e);
    debouncedDispatch("drop9", e)
  };

  const [drop10, setDrop10] = useState([]);
  const handle_switch10 = (e) => {
    // dispatch(
    //   updatePastComplaintFieldsScore({
    //     key: "drop10",
    //     value: e,
    //   })
    // );
    setDrop10(e);
    debouncedDispatch("drop10", e)
  };

  const [drop503, setDrop503] = useState(false);
  const handle_switch503 = (e) => {
    // dispatch(
    //   updatePastComplaintFieldsScore({
    //     key: "drop503",
    //     value: e,
    //   })
    // );
    setDrop503(e);
    debouncedDispatch("drop503", e)
  };

  const [seriousradio3, setseriousradio3] = useState("")
  const handleseriousradio3 = (e) => {
    // dispatch(
    //   updatePastComplaintFieldsScore({
    //     key: "seriousradio3",
    //     value: e,
    //   }))
    setseriousradio3(e)
    debouncedDispatch("seriousradio3", e)
  }

  const [droplung, setDropLung] = useState("");
  const handle_switchlung = (e) => {
    // dispatch(
    //   updatePastComplaintFieldsScore({
    //     key: "droplung",
    //     value: e,
    //   })
    // );
    setDropLung(e);
    debouncedDispatch("droplung", e)
  };

  const [checkAsth, setCheck_Value10] = useState(false);
  const handle_checkAsth = (e) => {
    // dispatch(
    //   updatePastComplaintFieldsScore({
    //     key: "checkAsth",
    //     value: e,
    //   })
    // );
    setCheck_Value10(e);
    debouncedDispatch("checkAsth", e)
  };

  const [checkCOPD, setCheck_Value11] = useState(false);
  const handle_checkCOPD = (e) => {
    // dispatch(
    //   updatePastComplaintFieldsScore({
    //     key: "checkCOPD",
    //     value: e,
    //   })
    // );
    setCheck_Value11(e);
    debouncedDispatch("checkCOPD", e)
  };

  const [checkHigh, setCheck_Value12] = useState(false);
  const handle_checkHigh = (e) => {
    // dispatch(
    //   updatePastComplaintFieldsScore({
    //     key: "checkHigh",
    //     value: e,
    //   })
    // );
    setCheck_Value12(e);
    debouncedDispatch("checkHigh", e)
  };

  const [checkCystic, setCheck_Value13] = useState(false);
  const handle_checkCystic = (e) => {
    // dispatch(
    //   updatePastComplaintFieldsScore({
    //     key: "checkCystic",
    //     value: e,
    //   })
    // );
    setCheck_Value13(e);
    debouncedDispatch("checkCystic", e)
  };

  const [checkPulmo, setCheck_Value14] = useState(false);
  const handle_checkPulmo = (e) => {
    // dispatch(
    //   updatePastComplaintFieldsScore({
    //     key: "checkPulmo",
    //     value: e,
    //   })
    // );
    setCheck_Value14(e);
    debouncedDispatch("checkPulmo", e)
  };

  const [checkTB, setCheck_Value15] = useState(false);
  const handle_checkTB = (e) => {
    // dispatch(
    //   updatePastComplaintFieldsScore({
    //     key: "checkTB",
    //     value: e,
    //   })
    // );
    setCheck_Value15(e);
    debouncedDispatch("checkTB", e)
  };

  const [checkSarco, setCheck_Value16] = useState(false);
  const handle_checkSarco = (e) => {
    // dispatch(
    //   updatePastComplaintFieldsScore({
    //     key: "checkSarco",
    //     value: e,
    //   })
    // );
    setCheck_Value16(e);
    debouncedDispatch("checkSarco", e)
  };

  const [checkOther, setCheckOther] = useState(false);
  const handle_checkOther = (e) => {
    // dispatch(
    //   updatePastComplaintFieldsScore({
    //     key: "checkOther",
    //     value: e,
    //   })
    // );
    setCheckOther(e);
    debouncedDispatch("checkOther", e)
  };

  const [drop511, setDrop511] = useState(false);
  const handle_switch511 = (e) => {
    // dispatch(
    //   updatePastComplaintFieldsScore({
    //     key: "drop511",
    //     value: e,
    //   })
    // );
    setDrop511(e);
    debouncedDispatch("drop511", e)
  };

  const [drop512, setDrop512] = useState(false);
  const handle_switch512 = (e) => {
    // dispatch(
    //   updatePastComplaintFieldsScore({
    //     key: "drop512",
    //     value: e,
    //   })
    // );
    setDrop512(e);
    debouncedDispatch("drop512", e)
  };

  const [drop510, setDrop510] = useState(false);
  const handle_switch510 = (e) => {
    // dispatch(
    //   updatePastComplaintFieldsScore({
    //     key: "drop510",
    //     value: e,
    //   })
    // );
    setDrop510(e);
    debouncedDispatch("drop510", e)
  };

  const [drop11, setDrop11] = useState("0");
  const handle_switch11 = (e) => {
    // dispatch(
    //   updatePastComplaintFieldsScore({
    //     key: "drop11",
    //     value: e,
    //   })
    // );
    setDrop11(e);
    debouncedDispatch("drop11", e)
  };

  const [drop12, setDrop12] = useState("");
  const handle_switch12 = (e) => {
    // dispatch(
    //   updatePastComplaintFieldsScore({
    //     key: "drop12",
    //     value: e,
    //   })
    // );
    setDrop12(e);
    debouncedDispatch("drop12", e)
  }

  const [drop99, setDrop99] = useState("");
  const handle_switch99 = (e) => {
    // dispatch(
    //   updatePastComplaintFieldsScore({
    //     key: "drop99",
    //     value: e,
    //   })
    // );
    setDrop99(e);
    debouncedDispatch("drop99", e)
  };

  const [drop13, setDrop13] = useState("");
  const handle_switch13 = (e) => {
    // dispatch(
    //   updatePastComplaintFieldsScore({
    //     key: "drop13",
    //     value: e,
    //   })
    // );
    setDrop13(e);
    debouncedDispatch("drop13", e)
  };
  const [drop14, setDrop14] = useState("");
  const handle_switch14 = (e) => {
    //   dispatch(
    //     updatePastComplaintFieldsScore({
    //       key: "drop14",
    //       value: e,
    //     })
    //   );
    setDrop14(e);
    debouncedDispatch("drop14", e)
  };
  const [drop15, setDrop15] = useState("");
  const handle_switch15 = (e) => {
    // dispatch(
    //   updatePastComplaintFieldsScore({
    //     key: "drop15",
    //     value: e,
    //   })
    // );
    setDrop15(e);
    debouncedDispatch("drop15", e)
  };
  const [drop16, setDrop16] = useState("");
  const handle_drop16 = (e) => {
    // dispatch(
    //   updatePastComplaintFieldsScore({
    //     key: "drop16",
    //     value: e,
    //   })
    // );
    setDrop16(e);
    debouncedDispatch("drop16", e)
  };


  const [checkvalue80, setCheck_Value80] = useState(false);
  const handle_check80 = (e) => {
    // dispatch(
    //   updatePastComplaintFieldsScore({
    //     key: "checkvalue80",
    //     value: e,
    //   })
    // );
    setCheck_Value80(e);
    debouncedDispatch("checkvalue80", e)
  };
  const [checkvalue81, setCheck_Value81] = useState(false);
  const handle_check81 = (e) => {
    // dispatch(
    //   updatePastComplaintFieldsScore({
    //     key: "checkvalue81",
    //     value: e,
    //   })
    // );
    setCheck_Value81(e);
    debouncedDispatch("checkvalue81", e)
  };
  const [checkvalue82, setCheck_Value82] = useState(false);
  const handle_check82 = (e) => {
    // dispatch(
    //   updatePastComplaintFieldsScore({
    //     key: "checkvalue82",
    //     value: e,
    //   })
    // );
    setCheck_Value82(e);
    debouncedDispatch("checkvalue82", e)
  };

  const [checkvalue50, setCheck_Value50] = useState(false);
  const handle_check50 = (e) => {
    // dispatch(
    //   updatePastComplaintFieldsScore({
    //     key: "checkvalue50",
    //     value: e,
    //   })
    // );
    setCheck_Value50(e);
    debouncedDispatch("checkvalue50", e)
  };

  const [drop50, setDrop50] = useState(false);
  const handle_switch55 = (e) => {
    // dispatch(
    //   updatePastComplaintFieldsScore({
    //     key: "drop50",
    //     value: e,
    //   })
    // );
    setDrop50(e);
    debouncedDispatch("drop50", e)
  };

  return (
    <div>

      <RadioSwitch
        labelLeft={translation.patient_general_past_history_q1_a1}
        labelRight={translation.patient_general_past_history_q1_a2}
        idLeft={"1"}
        idRight={"2"}
        name={"unaffected_shoulder1"}
        title={translation.patient_general_past_history_q1}
        styleClass=""
        value={knowAllergie}
        onChange={handle_switch1}
        placeholder="select"
      />

      {knowAllergie === "1" && (
        <InputField
          label=" "
          value={reaction}
          type="textarea"
          placeholder={translation.patient_general_past_history_placeholder}
          rows="4"
          cols="20"
          onChange={handle_switch2}
          styleClass="h_150"
        />
      )}

      <RadioSwitch
        labelLeft={translation.patient_general_past_history_q2_a1}
        labelRight={translation.patient_general_past_history_q2_a2}
        idLeft={"3"}
        idRight={"4"}
        name={"unaffected_shoulder2"}
        title={translation.patient_general_past_history_q2}
        styleClass=""
        value={allergicLatex}
        onChange={handle_switch3}
        placeholder="select"
      />

      <RadioSwitch
        labelLeft={translation.patient_general_past_history_q3_a1}
        labelRight={translation.patient_general_past_history_q3_a2}
        idLeft={"5"}
        idRight={"6"}
        name={"unaffected_shoulder3"}
        title={translation.patient_general_past_history_q3}
        styleClass=""
        value={bloodPressure}
        onChange={handle_switch4}
        placeholder="select"
      />
      {bloodPressure === "1" && (
        <InputField
          value={drop42}
          type="textarea"
          placeholder={translation.patient_general_past_history_q3_a2_placeholder}
          rows="4"
          cols="20"
          onChange={handle_switch42}
          styleClass="h_150"
        />
      )}


      <RadioSwitch
        labelLeft={translation.patient_general_past_history_q4_a1}
        labelRight={translation.patient_general_past_history_q4_a2}
        idLeft={"7"}
        idRight={"8"}
        name={"unaffected_shoulder4"}
        title={translation.patient_general_past_history_q4}
        styleClass=""
        value={heartIllness}
        onChange={handle_switch5}
        placeholder="select"
      />
      {heartIllness === "1" && (
        <>
          <Select

            isMulti
            name="weekday"
            options={[

              {
                value: "1",
                label: translation.patient_general_past_history_q4_a2_o2,
              },
              {
                value: "2",
                label: translation.patient_general_past_history_q4_a2_o3,
              },
              {
                value: "3",
                label: translation.patient_general_past_history_q4_a2_o4,
              },
              {
                value: "4",
                label: translation.patient_general_past_history_q4_a2_o5,
              },
              {
                value: "5",
                label: translation.patient_general_past_history_q4_a2_o6,
              },
              {
                value: "6",
                label: translation.patient_general_past_history_q4_a2_o7,
              },
              {
                value: "7",
                label: translation.patient_general_past_history_q4_a2_o8,
              },
              {
                value: "8",
                label: translation.patient_general_past_history_q4_a2_o9,
              },
              {
                value: "9",
                label: translation.patient_general_past_history_q4_a2_o10,
              },
            ]}
            onChange={handle_drop1}
            value={dropIllness}
            placeholder={<div>{translation.patient_general_past_history_q4_a2_o1}</div>}
            // className="form-control form-select"
            classNamePrefix="select"
          // placeholder={<div>Type to search</div>}
          />
          <div className="mt-4">
            {Array.isArray(dropIllness) && dropIllness.filter((item) => item.value === "7")[0] && (
              <InputField
                value={drop43}
                type="textarea"
                placeholder={translation.patient_general_past_history_q4_a2_o8_q1_placeholder}
                rows="4"
                cols="20"
                onChange={handle_switch43}
                styleClass="h_150"
              />
            )}
            {Array.isArray(dropIllness) && dropIllness.filter((item) => item.value === "9")[0] && (
              <InputField
                value={drop44}
                type="textarea"
                placeholder={translation.patient_general_past_history_q4_a2_o10_q1_placeholder}
                rows="4"
                cols="20"
                onChange={handle_switch44}
                styleClass="h_150"
              />
            )}
          </div>
        </>

      )}

      <RadioSwitch
        labelLeft={translation.patient_general_past_history_q5_a1}
        labelRight={translation.patient_general_past_history_q5_a2}
        idLeft={"seriousradio741"}
        idRight={"seriousradio751"}
        name={"arthritisseriousradio75"}
        title={translation.patient_general_past_history_q5}
        styleClass=""
        formGroupClass="mt-2"
        value={seriousradio}
        onChange={handleseriousradio}
        placeholder="select"
      />
      {seriousradio === "1" && (
        <InputField
          value={cardiologist}
          type="textarea"
          placeholder={translation.patient_general_past_history_q5_a2_placeholder}
          rows="4"
          cols="20"
          onChange={handle_switch7}
          styleClass="h_150"
        />
      )}

      <RadioSwitch
        labelLeft={translation.patient_general_past_history_q6_a1}
        labelRight={translation.patient_general_past_history_q6_a2}
        idLeft={"seriousradio2741"}
        idRight={"seriousradio2751"}
        name={"arthritisseriousradio275"}
        title={translation.patient_general_past_history_q6}
        styleClass=""
        value={seriousradio2}
        onChange={handleseriousradio2}
        placeholder="select"
      />
      {seriousradio2 === "1" && (
        <InputField
          value={vascularDisease}
          type="textarea"
          placeholder={translation.patient_general_past_history_q6_a2_placeholder}
          rows="4"
          cols="20"
          onChange={handle_switch8}
          styleClass="h_150"
        />
      )}
      <RadioSwitch
        labelLeft={translation.patient_general_past_history_q7_a1}
        labelRight={translation.patient_general_past_history_q7_a2}
        idLeft={"9"}
        idRight={"10"}
        name={"unaffected_shoulder5"}
        title={translation.patient_general_past_history_q7}
        styleClass=""
        value={drop9}
        onChange={handle_switch9}
        placeholder="select"
      />
      {drop9 === "1" && (
        <>
          <label>{translation.patient_general_past_history_q7_a2_q1}</label>
          <Select
            value={drop10}
            isMulti
            name="weekday"
            options={[
              {
                value: "4",
                label: translation.patient_general_past_history_q7_a2_q1_a1,
              },
              {
                value: "3",
                label: translation.patient_general_past_history_q7_a2_q1_a2,
              },
              {
                value: "2",
                label: translation.patient_general_past_history_q7_a2_q1_a3,
              },
              {
                value: "1",
                label: translation.patient_general_past_history_q7_a2_q1_a4,
              },
              {
                value: "0",
                label: translation.patient_general_past_history_q7_a2_q1_a5,
              },
            ]}
            onChange={handle_switch10}
            // className="form-control form-select"
            placeholder={<div>{translation.patient_general_past_history_q7_a2_q1_a5_placeholder}</div>}
            classNamePrefix="select"
          />
          {/* <Dropdown
                            data={[
                              {
                                value: "4",
                                label: translation.patient_general_past_history_q7_a2_q1_a1,
                              },
                              {
                                value: "3",
                                label: translation.patient_general_past_history_q7_a2_q1_a2,
                              },
                              {
                                value: "2",
                                label: translation.patient_general_past_history_q7_a2_q1_a3,
                              },
                              {
                                value: "1",
                                label: translation.patient_general_past_history_q7_a2_q1_a4,
                              },
                              {
                                value: "0",
                                label: translation.patient_general_past_history_q7_a2_q1_a5,
                              },
                            ]}
                            label={translation.patient_general_past_history_q7_a2_q1}
                            styleClass=""
                            value={drop10}
                            onChange={handle_switch10}
                          /> */}
          {Array.isArray(drop10) && drop10.filter((item) => item.value === "0")[0] && (
            <div className="mt-4">
              <InputField
                value={drop503}
                type="textarea"
                placeholder={translation.patient_general_past_history_q7_a2_q1_a5_placeholder}
                rows="4"
                cols="20"
                onChange={handle_switch503}
              // styleClass="h_150"
              />
            </div>
          )}
        </>


      )}

      <div className="mt-4">

        <RadioSwitch
          labelLeft={translation.patient_general_past_history_q8_a1}
          labelRight={translation.patient_general_past_history_q8_a2}
          idLeft={"seriousradio3741"}
          idRight={"seriousradio3751"}
          name={"arthritisseriousradio375"}
          title={translation.patient_general_past_history_q8}
          styleClass=""
          value={seriousradio3}
          onChange={handleseriousradio3}
          placeholder="select"
        />

        {seriousradio3 === "1" && (
          <>
            <Select

              isMulti
              name="weekday"
              options={[
                {
                  value: "0",
                  label: translation.patient_general_past_history_q8_a2_o1,
                },
                {
                  value: "1",
                  label: translation.patient_general_past_history_q8_a2_o2,
                },
                {
                  value: "2",
                  label: translation.patient_general_past_history_q8_a2_o3,
                },
              ]}
              onChange={handle_switchlung}
              value={droplung}
              // className="form-control form-select"
              placeholder={<div>{translation.patient_general_past_history_q4_a2_o1}</div>}

            />

            <div className="mt-4">
              {Array.isArray(droplung) && droplung.filter((item) => item.value === "1")[0] && (
                <div className="row col-12">
                  <div className="col-6">
                    <Checkbox
                      label={translation.patient_general_past_history_q8_a2_o2_o1}
                      selected={checkAsth}
                      value={checkAsth}
                      onChange={handle_checkAsth}
                      styleClass=""
                    />
                  </div>
                  <div className="col-6">
                    <Checkbox
                      label={translation.patient_general_past_history_q8_a2_o2_o2}
                      selected={checkCOPD}
                      value={checkCOPD}
                      onChange={handle_checkCOPD}
                      styleClass=""
                    />
                  </div>
                  <div className="col-6">
                    <Checkbox
                      label={translation.patient_general_past_history_q8_a2_o2_o3}
                      selected={checkHigh}
                      value={checkHigh}
                      onChange={handle_checkHigh}
                      styleClass=""
                    />
                  </div>
                  <div className="col-6">
                    <Checkbox
                      label={translation.patient_general_past_history_q8_a2_o2_o4}
                      selected={checkCystic}
                      value={checkCystic}
                      onChange={handle_checkCystic}
                      styleClass=""
                    />
                  </div>
                  <div className="col-6">
                    <Checkbox
                      label={translation.patient_general_past_history_q8_a2_o2_o5}
                      selected={checkPulmo}
                      value={checkPulmo}
                      onChange={handle_checkPulmo}
                      styleClass=""
                    />
                  </div>
                  <div className="col-6">
                    <Checkbox
                      label={translation.patient_general_past_history_q8_a2_o2_o6}
                      selected={checkTB}
                      value={checkTB}
                      onChange={handle_checkTB}
                      styleClass=""
                    />
                  </div>
                  <div className="col-6">
                    <Checkbox
                      label={translation.patient_general_past_history_q8_a2_o2_o7}
                      selected={checkSarco}
                      value={checkSarco}
                      onChange={handle_checkSarco}
                      styleClass=""
                    />
                  </div>

                  <div className="col-6">
                    <Checkbox
                      label={translation.patient_general_past_history_q8_a2_o2_o8}
                      selected={checkOther}
                      value={checkOther}
                      onChange={handle_checkOther}
                      styleClass=""
                    />
                  </div>

                  {checkHigh && (
                    <InputField
                      value={drop511}
                      type="textarea"
                      placeholder={translation.patient_general_past_history_q8_a2_o3_placehold}
                      rows="4"
                      cols="20"
                      onChange={handle_switch511}
                    />
                  )}

                  {checkOther && (
                    <InputField
                      value={drop512}
                      type="textarea"
                      placeholder={translation.patient_general_past_history_q8_a2_o2_o8_placeholder}
                      rows="4"
                      cols="20"
                      onChange={handle_switch512}
                    />
                  )}
                </div>
              )}
              {Array.isArray(droplung) && droplung.filter((item) => item.value === "2")[0] && (
                <InputField
                  value={drop510}
                  type="textarea"
                  placeholder={translation.patient_general_past_history_q8_a2_o3_placehold}
                  rows="4"
                  cols="20"
                  onChange={handle_switch510}
                />
              )}
            </div>
          </>
        )}

        {/* ------------------any trouble with breadthing ----------------- */}

      </div>

      <RadioSwitch
        labelLeft={translation.patient_general_past_history_q9_a1}
        labelRight={translation.patient_general_past_history_q9_a2}
        idLeft={"13"}
        idRight={"14"}
        name={"unaffected_shoulder6"}
        title={translation.patient_general_past_history_q9}
        styleClass=""
        formGroupClass="mt-2"
        value={drop11}
        onChange={handle_switch11}
        placeholder="select"
      />

      <RadioSwitch
        labelLeft={translation.patient_general_past_history_q10_a1}
        labelRight={translation.patient_general_past_history_q10_a2}
        idLeft={"98"}
        idRight={"99"}
        name={"unaffected_shoulder99"}
        title={translation.patient_general_past_history_q10}
        styleClass=""
        value={drop12}
        onChange={handle_switch12}
        placeholder="select"
      />


      <RadioSwitch
        labelLeft={translation.patient_general_past_history_q11_a1}
        labelRight={translation.patient_general_past_history_q11_a2}
        idLeft={"96"}
        idRight={"97"}
        name={"unaffected_shoulder98"}
        title={translation.patient_general_past_history_q11}
        styleClass=""
        value={drop99}
        onChange={handle_switch99}

      />

      {drop99 === "1" && (
        <InputField
          value={drop13}
          type="textarea"
          placeholder={translation.patient_general_past_history_q11_a2_placeholder}
          rows="4"
          cols="20"
          onChange={handle_switch13}
        // styleClass="h_150"
        />
      )}

      <RadioSwitch
        labelLeft={translation.patient_general_past_history_q12_a1}
        labelRight={translation.patient_general_past_history_q12_a2}
        idLeft={"92"}
        idRight={"93"}
        name={"unaffected_shoulder96"}
        title={translation.patient_general_past_history_q12}
        styleClass=""
        value={drop14}
        onChange={handle_switch14}

      />

      {drop14 === "1" && (
        <RadioSwitch
          labelLeft={translation.patient_general_past_history_q12_a2_q1_a1}
          labelRight={translation.patient_general_past_history_q12_a2_q1_a2}
          idLeft={"94"}
          idRight={"95"}
          name={"unaffected_shoulder97"}
          title={translation.patient_general_past_history_q12_a2_q1}
          styleClass=""
          value={drop15}
          onChange={handle_switch15}

        />
      )}

      <RadioSwitch
        labelLeft={translation.patient_general_past_history_q13_a1}
        labelRight={translation.patient_general_past_history_q13_a2}
        idLeft={"81"}
        idRight={"80"}
        name={"unaffected_shoulder80"}
        title={translation.patient_general_past_history_q13}
        styleClass=""
        value={drop16}
        onChange={handle_drop16}
        placeholder="select"
      />
      <div className="mt-3">
        {drop16 === "1" && (
          <>
            <Checkbox
              label={translation.patient_general_past_history_q13_a2_o2}
              selected={checkvalue80}
              value={checkvalue80}
              onChange={handle_check80}
              styleClass=""
            />
            <Checkbox
              label={translation.patient_general_past_history_q13_a2_o3}
              selected={checkvalue81}
              value={checkvalue81}
              onChange={handle_check81}
              styleClass=""
            />
            <Checkbox
              label={translation.patient_general_past_history_q13_a2_o4}
              selected={checkvalue82}
              value={checkvalue82}
              onChange={handle_check82}
              styleClass=""
            />
            <Checkbox
              label={translation.patient_general_past_history_q13_a2_o5}
              selected={checkvalue50}
              value={checkvalue50}
              onChange={handle_check50}
              styleClass=""
            />

            {checkvalue50 && (
              <InputField
                value={drop50}
                type="textarea"
                placeholder={translation.patient_general_past_history_q13_a2_o5_placeholder}
                rows="4"
                cols="20"
                onChange={handle_switch55}

              />
            )}

          </>
        )}
      </div>
    </div>
  )
}

export default PastIII;