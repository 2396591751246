import React from "react";
import "../../../css/ViewTreatmentForm.css";
import { UserDateFormat } from "../../../utilities/DateFormate";

function ClinicalExamination({ doctorefeedback, AllData,profileAllData }) {
  
  console.log("dataaaa",doctorefeedback.er2_right);
  return (
    <>
      <div className="whitebox padding-none">
        <div className="treatment-header">
          <h2>Shoulder Scoring Clinical Examination</h2>
          <div className="treatment-header-right">
            <p>Assessment Date: {UserDateFormat (AllData.assessment_date,profileAllData.ing_date_formate)}</p>
          </div>
        </div>

        <div className="treatment-content">
          <div className="treatment-row">
            <div className="treatment-table bt-none">
              <table cellpadding="0" cellspacing="0">
                <thead>
                  <tr>
                    <th></th>
                   
                    <th>Right Shoulder</th>
                    <th>Left Shoulder</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Active Forward Flexion</td>
                   
                    <td>{doctorefeedback.active_forward_degree_right} degrees</td>
                    <td>{doctorefeedback.active_forward_degree_left} degrees</td>
                    
                  </tr>

                  <tr>
                    <td>Passive Forward Flexion</td>
                   
                    <td>{doctorefeedback.prom_forward_right} degrees</td>
                    <td>{doctorefeedback.prom_forward_left} degrees</td>
                    
                  </tr>

                  <tr>
                    <td>Strength of Forward Flexion</td>
                    
                  
                    <td>Power Grade {doctorefeedback.active_forward_grade_right}</td>
                    <td>Power Grade {doctorefeedback.active_forward_grade_left}</td>
                  </tr>

                  <tr>
                    <td>Active Lateral Elevation</td>
                    <td>{doctorefeedback.lateralelevation_right} degrees</td>
                    <td>{doctorefeedback.lateralelevation_left} degrees</td>
                    
                    
                    
                    
                  </tr>
                  <tr>
                    <td>Passive Lateral Elevation</td>
                    <td>{doctorefeedback.prom_lateral_right} degrees</td>
                    <td>{doctorefeedback.prom_lateral_left} degrees</td>
                    
                    
                  </tr>

                  <tr>
                    <td>Strength of Lateral Elevation</td>
                    <td>{doctorefeedback.strengh_lateralelevation_right} kg</td>
                    <td>{doctorefeedback.strengh_lateralelevation_left} kg</td>
                    
                    
                  </tr>

                  <tr>
                    <td>Active ER1</td>
                    <td>{doctorefeedback.er1_right} degrees</td>
                    <td>{doctorefeedback.er1_left} degrees</td>
                    
                    
                  </tr>
                  <tr>
                    <td>Passive ER1</td>
                    <td>{doctorefeedback.prom_er1_right} degrees</td>
                    <td>{doctorefeedback.prom_er1_left} degrees</td>
                    
                    
                  </tr>
                  <tr>
                    <td>Active ER2</td>
                    <td>{doctorefeedback.er3_right} degrees</td>
                    <td>{doctorefeedback.er3_left} degrees</td>
                    
                    
                  </tr>
                  <tr>
                    <td>Passive ER2</td>
                    <td>{doctorefeedback.prom_er2_right} degrees</td>
                    <td>{doctorefeedback.prom_er2_left} degrees</td>
                    
                    
                  </tr>

                  <tr>
                    <td>ER2</td>
                    <td>{doctorefeedback.er2_right === "1" ? "Hand behind head, elbow forward" : doctorefeedback.er2_right === "2" ? "Hand behind head, elbow back" : doctorefeedback.er2_right === "3" ? "Hand to top of head, elbow forward" : doctorefeedback.er2_right === "4" ? "Hand to top of head, elbow back" : doctorefeedback.er2_right === "5" ? "Full elevation" : "-"}</td>
                    <td>{doctorefeedback.er2_left === "1" ? "Hand behind head, elbow forward" : doctorefeedback.er2_left === "2" ? "Hand behind head, elbow back" : doctorefeedback.er2_left === "3" ? "Hand to top of head, elbow forward" : doctorefeedback.er2_left === "4" ? "Hand to top of head, elbow back" : doctorefeedback.er2_left === "5" ? "Full elevation" : "-"}</td>

                    
                  </tr>

                  <tr>
                    <td>Arm Position</td>
                    <td>{doctorefeedback.armposition_right === "1" ? "Waist" : doctorefeedback.armposition_right === "2" ? "Xiphoid" : doctorefeedback.armposition_right === "3" ? "Neck" : doctorefeedback.armposition_right === "4" ? "Top of head" : doctorefeedback.armposition_right === "5" ? "Above head" : "-"}</td>
                    <td>{doctorefeedback.armposition_left === "1" ? "Waist" : doctorefeedback.armposition_left === "2" ? "Xiphoid" : doctorefeedback.armposition_left === "3" ? "Neck" : doctorefeedback.armposition_left === "4" ? "Top of head" : doctorefeedback.armposition_left === "5" ? "Above head" : "-"}</td>

                    
                  </tr>

                  <tr>
                    <td>IR1</td>
                    <td>{doctorefeedback.ir1_right === "1" ? "Lateral Thigh" : doctorefeedback.ir1_right === "2" ? "Buttock" : doctorefeedback.ir1_right === "3" ? "Lumbosacral Junction" : doctorefeedback.ir1_right === "4" ? "Waist (L3)" : doctorefeedback.ir1_right === "5" ? "T12 Vertebra" :  doctorefeedback.ir1_right === "6" ? "Interscapular (T7)" : "-"}</td>
                    <td>{doctorefeedback.ir1_left === "1" ? "Lateral Thigh" : doctorefeedback.ir1_left === "2" ? "Buttock" : doctorefeedback.ir1_left === "3" ? "Lumbosacral Junction" : doctorefeedback.ir1_left === "4" ? "Waist (L3)" : doctorefeedback.ir1_left === "5" ? "T12 Vertebra" : doctorefeedback.ir1_left === "6" ? "Interscapular (T7)" : "-"}</td>

                    
                  </tr>

                  <tr>
                    <td>Active IR2</td>
                    
                    <td>{doctorefeedback.ir2_right === "" ? "-" : doctorefeedback.ir2_right } degrees</td>
                    <td>{doctorefeedback.ir2_left === "" ? "-" : doctorefeedback.ir2_left } degrees</td>
                    
                  </tr>
                  <tr>
                    <td>Passive IR2</td>
                    
                    <td>{doctorefeedback.ir2_Paright === "" ? "-" : doctorefeedback.ir2_Paright } degrees</td>
                    <td>{doctorefeedback.ir2_Paleft === "" ? "-" : doctorefeedback.ir2_Paleft } degrees</td>
                    
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="treatment-row">
            <div className="treatment-col">
              <label>Note: </label>
              <p>{doctorefeedback.treatment_notes === "" ? "-" : doctorefeedback.treatment_notes}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ClinicalExamination;
