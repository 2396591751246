import React from 'react'
import useTranslation from '../../customHooks/translations';

export default function ViewHistory({ historyData }) {
  const translations=useTranslation();
  console.log(historyData,"historyData");
  return (
    <div className="whitebox padding-none">
      <div className="treatment-header">
        <h2>{translations.patient_general_history}</h2>
      </div>
      <div className="treatment-content">
        <div className="treatment-table border-0">
          <div className="treatment-col">
            <label>{translations.patient_general_history_q1}:</label>
           <pre> <p className='p_description'>{historyData.complaint}</p></pre>
          </div>
          <div className="treatment-col">
            <label>{translations.patient_general_history_q2}</label>
            <p>{new Date(historyData.startDate).toLocaleDateString()}</p>
          </div>
          <div className="treatment-col">
            <label>{translations.patient_general_history_q3}</label>
            <p>
              {historyData.drop1 &&
                historyData.drop1
                  .map((item, index) => <span key={index}>{item.label}</span>)
                  .map((jsxElement) => jsxElement.props.children)
                  .join(", ")}
            </p>
          </div>
          <div className="">
            <label className='text-dark'>{translations.patient_general_history_q4}</label>
            <pre> <p className='p_description'>{historyData.Investigation}</p></pre>
          </div>
        </div>
      </div>
    </div>
  )
}
