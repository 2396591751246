import React from "react";
import akunahLogo from "../../../images/akunah-hr-logo.svg";
import Button from "../../custom/button/Button";
import assmentHeadStyles from "./AssessmentHeader.module.css";

function AssessmentHeader() {
  return (
    <>
      {/* <div className={`${assmentHeadStyles.assessment_header}`}>
        <div className="row">
          <div className="col-md-6 col-sm-6">
            <img src={akunahLogo} alt="" />
          </div>
          <div className="col-md-6 col-sm-6 text-end">
            <Button
              border=""
              color=""
              minHeight="55px"
              height=""
              onClick={ () => console.log("btn working")}
              radius="50px"
              width="142px"
              fontSize="24px"
              buttonStyle="btn_outline"
              value="Logout"
            />
          </div>
        </div>
      </div> */}
    </>
  );
}

export default AssessmentHeader;
