import React from "react";
import useTranslation from "../../customHooks/translations";

export default function FootAnkleSatisfaction({
  FootsatisfactionScore,
  AllData,
}) {
  const translations = useTranslation();
  return (
    <div className="whitebox padding-none">
      <div className="treatment-content">
        <div className="treatment-row-header">
          6. {translations.patient_foot_ankle_satisfaction}
        </div>
        <div className="treatment-row">
          <div className="treatment-table">
            <table cellpadding="0" cellspacing="0">
              <thead>
                <tr>
                  <th></th>
                  {AllData.anklePain === "1" || AllData.anklePain === "3" ? (
                    <th>{translations.Right_Foot_and_Ankle}</th>
                  ) : (
                    ""
                  )}
                  {AllData.anklePain === "2" || AllData.anklePain === "3" ? (
                    <th>{translations.Left_Foot_and_Ankle}</th>
                  ) : (
                    ""
                  )}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{translations.patient_foot_ankle_satisfaction_q1} :</td>
                  {AllData.anklePain === "1" || AllData.anklePain === "3" ? (
                    <td>{FootsatisfactionScore.symptomR}</td>
                  ) : (
                    ""
                  )}
                  {AllData.anklePain === "2" || AllData.anklePain === "3" ? (
                    <td>{FootsatisfactionScore.symptomL}</td>
                  ) : (
                    ""
                  )}
                </tr>
                <tr>
                  <td>{translations.patient_foot_ankle_satisfaction_q2} :</td>
                  {AllData.anklePain === "1" || AllData.anklePain === "3" ? (
                    <td>{FootsatisfactionScore.improveR}</td>
                  ) : (
                    ""
                  )}
                  {AllData.anklePain === "2" || AllData.anklePain === "3" ? (
                    <td>{FootsatisfactionScore.improveL}</td>
                  ) : (
                    ""
                  )}
                </tr>
                <tr>
                  <td>{translations.patient_foot_ankle_satisfaction_q3}:</td>
                  {AllData.anklePain === "1" || AllData.anklePain === "3" ? (
                    <td>{FootsatisfactionScore.forgetR}</td>
                  ) : (
                    ""
                  )}
                  {AllData.anklePain === "2" || AllData.anklePain === "3" ? (
                    <td>{FootsatisfactionScore.forgetL}</td>
                  ) : (
                    ""
                  )}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
