import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AssessmentDegreeWidget from "../../../custom/assessment-widgets/AssessmentDegreeWidget";
import AssessmentHeader from "../../../custom/assessment-header/AssessmentHeader";
import HeaderInnerBreadcrumb from "../../../custom/heade-inner-breadcrumb/HeaderInnerBreadcrumb";
import ShoulderRangeSlider from "../../../custom/score-range-slider/ShoulderRangeSlider";
import AssessmentKgKgWidget from "../../../custom/assessment-widgets/AssessmentKgKgWidget";
import RadioButton from "../../../custom/radio-button/RadioButton";
import InputField from "../../../custom/inputfield/InputField";
import nextWhiteIcon from "../../../../images/next-white.png";
import nextIcon from "../../../../images/next.png";
import CustomToaster from "../../../custom/toaster/CustomToaster";
import {
  updateFeedbackFunctionFieldScore,
  updateFeedbackFunctionScores,
  updateSACSFunctionScores,
  updateShoulderFunctionScores,
  updateShoulder,
  updateShoulderPainScores,
  updateWOSIFunctionScores,
  updateSatisfactionFunctionScores,
  updateTotalScores,
} from "../../../../redux/actions/shoulderAction";
import useTransition from "../../../customHooks/doctorTranslation";
import useAxios from "../../../../axiosinstance";
import { useSearchParams } from "react-router-dom";
import Tab from "../../tab-section/ShoulderTab";
import { useDispatch, useSelector } from "react-redux";
import { findScoreStatus } from "../../../../utilities/FindScoreStatus";
import AssessmentNagetiveWidget from "../../../custom/assessment-widgets/AssessmentNagetiveWidget";
import DoctorNameCustom from "../../../custom/name/DoctorNameCustom";
import LoadingSpinner from "../../../custom/loader/LoadingSpinner";

function PatientAssessmentStep1() {
  const dispatch = useDispatch();
  const { rId } = useParams();
  const [searchParams] = useSearchParams();
  let doctorEnable = searchParams.get("d");
  const queryParams = new URLSearchParams(window.location.search);
  const dId = queryParams.get("doctor");
  const pId = queryParams.get("patient");
  const axiosinstance = useAxios();
  const [chk, setChk] = useState(false);
  const translation = useTransition();
  let isdoctorForm = queryParams.get("is_doctor_form");
   useEffect(() => {
  if(doctorEnable === "true"){
    let doctordata=localStorage.getItem("isdoctorForm")
    localStorage.setItem("isdoctorForm", doctordata)
  }else{
    localStorage.setItem("isdoctorForm", isdoctorForm)
  }

  }, [isdoctorForm])
  const docFeedback = useSelector(
    (state) => state.shoulderScore.doctorFeedback[0]
  );
  const docFeedback1 = useSelector(
    (state) => state.shoulderScore.doctorFeedback
  );
  const [activeForwardGradeValueR, setActiveForwardGradeValueR] = useState(0);
  const [activeForwardGradeValueL, setActiveForwardGradeValueL] = useState(0);
  const [activeForwardDegreeR, setActiveForwardDegreeR] = useState(0);
  const [activeForwardDegreeL, setActiveForwardDegreeL] = useState(0);
  const [promForwardDegreeR, setPromForwardDegreeR] = useState(0);
  const [promForwardDegreeL, setPromForwardDegreeL] = useState(0);
  const [lateralElevationDegreeR, setLateralElevationDegreeR] = useState(0);
  const [lateralElevationDegreeL, setLateralElevationDegreeL] = useState(0);
  const [promLateralDegreeR, setPromLateralDegreeR] = useState(0);
  const [promLateralDegreeL, setPromLateralDegreeL] = useState(0);
  const [strenghLateralElevationR, setStrenghLateralElevationR] = useState(0);
  const [strenghLateralElevationL, setStrenghLateralElevationL] = useState(0);
  const [er1R, setEr1R] = useState(0);
  const [er1L, setEr1L] = useState(0);
  const [er2R, setEr2R] = useState(0);
  const [er2L, setEr2L] = useState(0);
  const [promEr1DegreeR, setPromEr1DegreeR] = useState(0);
  const [promEr1DegreeL, setPromEr1DegreeL] = useState(0);
  const [promEr2DegreeR, setPromEr2DegreeR] = useState(0);
  const [promEr2DegreeL, setPromEr2DegreeL] = useState(0);
  const [er2Right, setEr2Right] = useState("");
  const [er2Left, setEr2Left] = useState("");
  const [armPositionR, setArmPositionR] = useState("");
  const [armPositionL, setArmPositionL] = useState("");
  const [ir1Right, setIr1Right] = useState("");
  const [ir1Left, setIr1Left] = useState("");
  const [ir2RightDegreeR, setIr2RightDegreeR] = useState(0);
  const [ir2RightDegreeL, setIr2RightDegreeL] = useState(0);
  const [ir2PaRightDegreeR, setIr2PaRightDegreeR] = useState(0);
  const [ir2PaRightDegreeL, setIr2PaRightDegreeL] = useState(0);
  const [note, setNote] = useState("");
  const [doctorInital, setdoctorInital] = useState("");
  const numOfChars = note.replace(/\s/g, "").length;
  const [numChracter, setnumChracter] = useState(500);
  useEffect(() => {
    // setnumChracter(500-numOfChars)
    setnumChracter(500 - note.length);
  }, [note]);

  const json = useSelector((state) => state.shoulderScore);

  const [painSco] = useSelector((state) => state.shoulderScore.painScore);
  const [funScore] = useSelector((state) => state.shoulderScore.functionScore);
  const [wosiScore] = useSelector((state) => state.shoulderScore.wosiScore);
  const [sacsScore] = useSelector((state) => state.shoulderScore.sacsScore);
  const [safisScore] = useSelector((state) => state.shoulderScore.safisScore);
  function sumAll(...numbers) {
    let total = 0;
    for (const number of numbers) {
      total += number;
    }
    return total;
  }
  const [docdata, setDocdata] = useState([]);

  const save = async () => {
    await axiosinstance.current
      .get(`extapp/forms/all_forms?resource_id=${rId}&type=4`)
      .then((res) => {
        dispatch(updateShoulder(res.data.data2[0].shoulserScore));
        dispatch(updateShoulderPainScores(res.data.data2[0].painScore));
        dispatch(updateShoulderFunctionScores(res.data.data2[0].functionScore));
        dispatch(updateWOSIFunctionScores(res.data.data2[0].wosiScore));
        dispatch(updateSACSFunctionScores(res.data.data2[0].sacsScore));
        dispatch(
          updateSatisfactionFunctionScores(res.data.data2[0].safisScore)
        );
        dispatch(updateTotalScores(res.data.data2[0].TotalScores));
        dispatch(
          updateFeedbackFunctionScores(res.data.data2[0].doctorFeedback)
          // updateFeedbackFunctionScores(res.data.data2[0].doctorFeedback)
        );
        setDocdata(res.data.data2[0].doctorFeedback[0]);
        setdoctorInital(res.data.data2[0].doctor_scoring_date);
        // setActiveForwardDegreeR(res.data.data2[0].doctorFeedback[0].prom_forward_right)
        // if (res.data.status) {

        setChk(true);
        // }
      });
  };

  useEffect(() => {
    save();
  }, []);
  // console.log(typeof doctorInital, "doctorInital")
let doctor_id=sessionStorage.getItem("doctor_id")
  const [Scores, setScores] = useState([]);
  const GetScores = () => {
    axiosinstance.current
      .get(`/extapp/doctors/getSpecialityScoresSettingsOfUser/${dId?dId:doctor_id}?type_id=4`)
      .then((res) => {
        setScores(res.data.data);
      });
  };
  useEffect(() => {
    if (chk) {
      GetScores();
    }
  }, [chk]);
  const ConstantStatus = findScoreStatus("Constant Score", Scores);
  const SimpleStatus = findScoreStatus("Simple Shoulder Test", Scores);
  const UCLAStatus = findScoreStatus("UCLA Score", Scores);

  useEffect(() => {
    setActiveForwardGradeValueR(docFeedback.active_forward_grade_right);
    setActiveForwardGradeValueL(docFeedback.active_forward_grade_left);
    setActiveForwardDegreeR(docFeedback.active_forward_degree_right);
    setActiveForwardDegreeL(docFeedback.active_forward_degree_left);
    setLateralElevationDegreeR(docFeedback.lateralelevation_right);
    setLateralElevationDegreeL(docFeedback.lateralelevation_left);
    setStrenghLateralElevationR(docFeedback.strengh_lateralelevation_right);
    setStrenghLateralElevationL(docFeedback.strengh_lateralelevation_left);

    setPromLateralDegreeR(docFeedback.prom_lateral_right);
    setPromLateralDegreeL(docFeedback.prom_lateral_left);
    setPromForwardDegreeR(docFeedback.prom_forward_right);
    setPromForwardDegreeL(docFeedback.prom_forward_left);
    setEr1R(docFeedback.er1_right);
    setEr1L(docFeedback.er1_left);
    setEr2R(docFeedback.er3_right);
    setEr2L(docFeedback.er3_left);
    setPromEr1DegreeR(docFeedback.prom_er1_right);
    setPromEr1DegreeL(docFeedback.prom_er1_left);
    setPromEr2DegreeR(docFeedback.prom_er2_right);
    setPromEr2DegreeL(docFeedback.prom_er2_left);
    setEr2Right(docFeedback.er2_right);
    setEr2Left(docFeedback.er2_left);
    setArmPositionR(docFeedback.armposition_right);
    setArmPositionL(docFeedback.armposition_left);
    setIr1Right(docFeedback.ir1_right);
    setIr1Left(docFeedback.ir1_left);
    setIr2RightDegreeR(docFeedback.ir2_right);
    setIr2RightDegreeL(docFeedback.ir2_left);
    setIr2PaRightDegreeR(docFeedback.ir2_Paright);
    setIr2PaRightDegreeL(docFeedback.ir2_Paleft);
    setNote(docFeedback.treatment_notes);
  }, [docFeedback]);

  // useEffect(() => {
  //   if (doctorInital === "0") {
  //     if ([UCLAStatus].every(ele => ele === true)) {

  //       dispatch(
  //         updateFeedbackFunctionFieldScore({
  //           key: "active_forward_grade_right",
  //           value: "0",
  //         })
  //       );
  //       dispatch(
  //         updateFeedbackFunctionFieldScore({
  //           key: "active_forward_grade_left",
  //           value: "0",
  //         })
  //       );

  //     }

  //     if ([UCLAStatus, ConstantStatus].some(ele => ele === true)) {
  //       dispatch(
  //         updateFeedbackFunctionFieldScore({
  //           key: "active_forward_degree_right",
  //           value: "0",
  //         })
  //       );
  //       dispatch(
  //         updateFeedbackFunctionFieldScore({
  //           key: "active_forward_degree_left",
  //           value: "0",
  //         })
  //       );
  //     }

  //     if (ConstantStatus === true) {

  //       dispatch(
  //         updateFeedbackFunctionFieldScore({
  //           key: "lateralelevation_right",
  //           value: "0",
  //         })
  //       );
  //       dispatch(
  //         updateFeedbackFunctionFieldScore({
  //           key: "lateralelevation_left",
  //           value: "0",
  //         })
  //       );
  //       dispatch(
  //         updateFeedbackFunctionFieldScore({
  //           key: "strengh_lateralelevation_right",
  //           value: "0",
  //         })
  //       );
  //       dispatch(
  //         updateFeedbackFunctionFieldScore({
  //           key: "strengh_lateralelevation_left",
  //           value: "0",
  //         })
  //       );
  //     }
  //   }
  // }, [UCLAStatus])

  useEffect(() => {
    if (docdata.active_forward_grade_right !== "") {
      setActiveForwardGradeValueR(docdata.active_forward_grade_right);
    }
    if (docdata.active_forward_grade_left !== "") {
      setActiveForwardGradeValueL(docdata.active_forward_grade_left);
    }
    if (docdata.active_forward_degree_right !== "") {
      setActiveForwardDegreeR(docdata.active_forward_degree_right);
    }
    if (docdata.active_forward_degree_left !== "") {
      setActiveForwardDegreeL(docdata.active_forward_degree_left);
    }
    if (docdata.lateralelevation_right !== "") {
      setLateralElevationDegreeR(docdata.lateralelevation_right);
    }
    if (docdata.lateralelevation_left !== "") {
      setLateralElevationDegreeL(docdata.lateralelevation_left);
    }
    if (docdata.strengh_lateralelevation_right !== "") {
      setStrenghLateralElevationR(docdata.strengh_lateralelevation_right);
    }
    if (docdata.strengh_lateralelevation_left !== "") {
      setStrenghLateralElevationL(docdata.strengh_lateralelevation_left);
    }
  }, [docdata]);
  // const updateDate = () => {
  //   axiosinstance.current
  //     .patch(
  //       `extapp/forms/update_all_form_type?resource_id=${rId}&action=${doctorEnable === "true"?"1":"0"}`,
  //       json,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       console.log(res);
  //     });
  // };
  // useEffect(() => {
  //   if (chk) {
  //     updateDate();
  //   }
  // }, [docFeedback]);

  const activeForwardGradechangeR = (e) => {
    // const { value } = e;
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "active_forward_grade_right",
        value: e,
      })
    );
    setActiveForwardGradeValueR(e);
  };
  const activeForwardGradechangeL = (e) => {
    // const { value } = e;
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "active_forward_grade_left",
        value: e,
      })
    );
    setActiveForwardGradeValueL(e);
  };

  const activeForwardDegreeChangeR = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "active_forward_degree_right",
        value: e,
      })
    );
    setActiveForwardDegreeR(e);
  };
  const activeForwardDegreeChangeL = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "active_forward_degree_left",
        value: e,
      })
    );
    setActiveForwardDegreeL(e);
  };
  const promForwardDegreeChangeR = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "prom_forward_right",
        value: e,
      })
    );
    setPromForwardDegreeR(e);
  };
  const promForwardDegreeChangeL = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "prom_forward_left",
        value: e,
      })
    );
    setPromForwardDegreeL(e);
  };
  const lateralElevationDegreeChangeR = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "lateralelevation_right",
        value: e,
      })
    );
    setLateralElevationDegreeR(e);
  };
  const lateralElevationDegreeChangeL = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "lateralelevation_left",
        value: e,
      })
    );
    setLateralElevationDegreeL(e);
  };
  const promLateralDegreeChangeR = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "prom_lateral_right",
        value: e,
      })
    );
    setPromLateralDegreeR(e);
  };
  const promLateralDegreeChangeL = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "prom_lateral_left",
        value: e,
      })
    );
    setPromLateralDegreeL(e);
  };
  const strenghLateralElevationDegreeChangeR = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "strengh_lateralelevation_right",
        value: e,
      })
    );
    setStrenghLateralElevationR(e);
  };
  const strenghLateralElevationDegreeChangeL = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "strengh_lateralelevation_left",
        value: e,
      })
    );
    setStrenghLateralElevationL(e);
  };
  const er1ChangeR = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "er1_right",
        value: e,
      })
    );
    setEr1R(e);
  };
  const er1ChangeL = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "er1_left",
        value: e,
      })
    );
    setEr1L(e);
  };
  const er2ChangeR1 = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "er3_right",
        value: e,
      })
    );
    setEr2R(e);
  };
  const er2ChangeL1 = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "er3_left",
        value: e,
      })
    );
    setEr2L(e);
  };
  const promEr1DegreeChangeR = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "prom_er1_right",
        value: e,
      })
    );
    setPromEr1DegreeR(e);
  };
  const promEr1DegreeChangeL = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "prom_er1_left",
        value: e,
      })
    );
    setPromEr1DegreeL(e);
  };
  const promEr2DegreeChangeR = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "prom_er2_right",
        value: e,
      })
    );
    setPromEr2DegreeR(e);
  };
  const promEr2DegreeChangeL = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "prom_er2_left",
        value: e,
      })
    );
    setPromEr2DegreeL(e);
  };
  const er2ChangeR = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "er2_right",
        value: e,
      })
    );
    setEr2Right(e);
  };
  const er2ChangeL = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "er2_left",
        value: e,
      })
    );
    setEr2Left(e);
  };
  const armPositionchangeR = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "armposition_right",
        value: e,
      })
    );
    setArmPositionR(e);
  };
  const armPositionchangeL = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "armposition_left",
        value: e,
      })
    );
    setArmPositionL(e);
  };
  const ir1ChangeR = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "ir1_right",
        value: e,
      })
    );
    setIr1Right(e);
  };
  const ir1ChangeL = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "ir1_left",
        value: e,
      })
    );
    setIr1Left(e);
  };
  const ir2RightDegreeChangeR = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "ir2_right",
        value: e,
      })
    );
    setIr2RightDegreeR(e);
  };
  const ir2RightDegreeChangeL = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "ir2_left",
        value: e,
      })
    );
    setIr2RightDegreeL(e);
  };
  const ir2PaRightDegreeChangeR = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "ir2_Paright",
        value: e,
      })
    );
    setIr2PaRightDegreeR(e);
  };
  const ir2PaRightDegreeChangeL = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "ir2_Paleft",
        value: e,
      })
    );
    setIr2PaRightDegreeL(e);
  };
  const updateNote = (e) => {
    dispatch(
      updateFeedbackFunctionFieldScore({
        key: "treatment_notes",
        value: e,
      })
    );
    setNote(e);

    // setNote(e);
  };

  const navigate = useNavigate();
  const [validator, setValidators] = useState(false);
  const [validatorL, setValidatorsL] = useState(false);
  const [validatorArsR, setvalidatorArsR] = useState(false);
  const [validatorArsL, setvalidatorArsL] = useState(false);
  const [validatorArmL, setvalidatorArmL] = useState(false);
  const [validatorArmR, setvalidatorArmR] = useState(false);
  const [validatorPositionR, setvalidatorPositionR] = useState(false);
  const [validatorPositionL, setvalidatorPositionL] = useState(false);
  const [validatort, setvalidatort] = useState(false);
  // useEffect(()=>{
  //   if(activeForwardDegreeR ){
  //     setValidators(false)

  //   }if(activeForwardDegreeL){
  //     setValidatorsL(false)
  //   }if(lateralElevationDegreeR){
  //     setValidators(false)
  //   }if(lateralElevationDegreeL){
  //     setValidators(false)
  //   }
  // })

  //Constant Score

  const describe_pain_right1 =
    painSco.describe_pain_right === "0"
      ? 15
      : painSco.describe_pain_right === "1"
        ? 14
        : painSco.describe_pain_right === "2"
          ? 13
          : painSco.describe_pain_right === "3"
            ? 12
            : painSco.describe_pain_right === "4"
              ? 11
              : painSco.describe_pain_right === "5"
                ? 10
                : painSco.describe_pain_right === "6"
                  ? 9
                  : painSco.describe_pain_right === "7"
                    ? 8
                    : painSco.describe_pain_right === "8"
                      ? 7
                      : painSco.describe_pain_right === "9"
                        ? 6
                        : painSco.describe_pain_right === "10"
                          ? 5
                          : painSco.describe_pain_right === "11"
                            ? 4
                            : painSco.describe_pain_right === "12"
                              ? 3
                              : painSco.describe_pain_right === "13"
                                ? 2
                                : painSco.describe_pain_right === "14"
                                  ? 1
                                  : painSco.describe_pain_right === "15"
                                    ? 0 : 0;

  const describe_pain_left1 =
    painSco.describe_pain_left === "0"
      ? 15
      : painSco.describe_pain_left === "1"
        ? 14
        : painSco.describe_pain_left === "2"
          ? 13
          : painSco.describe_pain_left === "3"
            ? 12
            : painSco.describe_pain_left === "4"
              ? 11
              : painSco.describe_pain_left === "5"
                ? 10
                : painSco.describe_pain_left === "6"
                  ? 9
                  : painSco.describe_pain_left === "7"
                    ? 8
                    : painSco.describe_pain_left === "8"
                      ? 7
                      : painSco.describe_pain_left === "9"
                        ? 6
                        : painSco.describe_pain_left === "10"
                          ? 5
                          : painSco.describe_pain_left === "11"
                            ? 4
                            : painSco.describe_pain_left === "12"
                              ? 3
                              : painSco.describe_pain_left === "13"
                                ? 2
                                : painSco.describe_pain_left === "14"
                                  ? 1
                                  : painSco.describe_pain_left === "15"
                                    ? 0 : 0;

  const constshoulder_pain_night_right =
    painSco.shoulder_pain_night_right === "0"
      ? 2 :
      painSco.shoulder_pain_night_right === "1"
        ? 1
        : painSco.shoulder_pain_night_right === "2"
          ? 0 : 0;

  const constshoulder_pain_night_left =
    painSco.shoulder_pain_night_left === "0"
      ? 2 :
      painSco.shoulder_pain_night_left === "1"
        ? 1
        : painSco.shoulder_pain_night_left === "2"
          ? 0 : 0;

  const const_normal_right = Number(funScore.normal_right)

  const const_normal_left = Number(funScore.normal_left)

  const const_recreational_right = Number(funScore.recreational_right)

  const const_recreational_left = Number(funScore.recreational_left)



  const armposition_right =
    docFeedback.armposition_right === "1"
      ? 2
      : docFeedback.armposition_right === "2"
        ? 4
        : docFeedback.armposition_right === "3"
          ? 6
          : docFeedback.armposition_right === "4"
            ? 8
            : docFeedback.armposition_right === "5"
              ? 10
              : 0;

  const armposition_left =
    docFeedback.armposition_left === "1"
      ? 2
      : docFeedback.armposition_left === "2"
        ? 4
        : docFeedback.armposition_left === "3"
          ? 6
          : docFeedback.armposition_left === "4"
            ? 8
            : docFeedback.armposition_left === "5"
              ? 10
              : 0;

  const prom_forward_right =
    docFeedback.active_forward_degree_right <= 30
      ? 0
      : docFeedback.active_forward_degree_right === 31 ||
        docFeedback.active_forward_degree_right < 61
        ? 2
        : docFeedback.active_forward_degree_right === 61 ||
          docFeedback.active_forward_degree_right < 91
          ? 4
          : docFeedback.active_forward_degree_right === 91 ||
            docFeedback.active_forward_degree_right < 121
            ? 6
            : docFeedback.active_forward_degree_right === 121 ||
              docFeedback.active_forward_degree_right < 151
              ? 8
              : docFeedback.active_forward_degree_right === 151 ||
                docFeedback.active_forward_degree_right < 181
                ? 10
                : 0;

  const prom_forward_left =
    docFeedback.active_forward_degree_left <= 30
      ? 0
      : docFeedback.active_forward_degree_left === 31 ||
        docFeedback.active_forward_degree_left < 61
        ? 2
        : docFeedback.active_forward_degree_left === 61 ||
          docFeedback.active_forward_degree_left < 91
          ? 4
          : docFeedback.active_forward_degree_left === 91 ||
            docFeedback.active_forward_degree_left < 121
            ? 6
            : docFeedback.active_forward_degree_left === 121 ||
              docFeedback.active_forward_degree_left < 151
              ? 8
              : docFeedback.active_forward_degree_left === 151 ||
                docFeedback.active_forward_degree_left < 181
                ? 10
                : 0;

  const lateralelevation_right =
    docFeedback.lateralelevation_right <= 30
      ? 0
      : docFeedback.lateralelevation_right === 31 ||
        docFeedback.lateralelevation_right < 61
        ? 2
        : docFeedback.lateralelevation_right === 61 ||
          docFeedback.lateralelevation_right < 91
          ? 4
          : docFeedback.lateralelevation_right === 91 ||
            docFeedback.lateralelevation_right < 121
            ? 6
            : docFeedback.lateralelevation_right === 121 ||
              docFeedback.lateralelevation_right < 151
              ? 8
              : docFeedback.lateralelevation_right === 151 ||
                docFeedback.lateralelevation_right < 181
                ? 10
                : 0;

  const lateralelevation_left =
    docFeedback.lateralelevation_left <= 30
      ? 0
      : docFeedback.lateralelevation_left === 31 ||
        docFeedback.lateralelevation_left < 61
        ? 2
        : docFeedback.lateralelevation_left === 61 ||
          docFeedback.lateralelevation_left < 91
          ? 4
          : docFeedback.lateralelevation_left === 91 ||
            docFeedback.lateralelevation_left < 121
            ? 6
            : docFeedback.lateralelevation_left === 121 ||
              docFeedback.lateralelevation_left < 151
              ? 8
              : docFeedback.lateralelevation_left === 151 ||
                docFeedback.lateralelevation_left < 181
                ? 10
                : 0;

  const ir1_right =
    docFeedback.ir1_right === "1"
      ? 0
      : docFeedback.ir1_right === "2"
        ? 2
        : docFeedback.ir1_right === "3"
          ? 4
          : docFeedback.ir1_right === "4"
            ? 6
            : docFeedback.ir1_right === "5"
              ? 8
              : docFeedback.ir1_right === "6"
                ? 10
                : 0;

  const ir1_left =
    docFeedback.ir1_left === "1"
      ? 0
      : docFeedback.ir1_left === "2"
        ? 2
        : docFeedback.ir1_left === "3"
          ? 4
          : docFeedback.ir1_left === "4"
            ? 6
            : docFeedback.ir1_left === "5"
              ? 8
              : docFeedback.ir1_left === "6"
                ? 10
                : 0;

  const er2_right =
    docFeedback.er2_right === "1"
      ? 2
      : docFeedback.er2_right === "2"
        ? 4
        : docFeedback.er2_right === "3"
          ? 6
          : docFeedback.er2_right === "4"
            ? 8
            : docFeedback.er2_right === "5"
              ? 10
              : 0;

  const er2_left =
    docFeedback.er2_left === "1"
      ? 2
      : docFeedback.er2_left === "2"
        ? 4
        : docFeedback.er2_left === "3"
          ? 6
          : docFeedback.er2_left === "4"
            ? 8
            : docFeedback.er2_left === "5"
              ? 10
              : 0;

  const lateralelevatioRight_cal = docFeedback.strengh_lateralelevation_right;
  let lateral_cal = Math.round(lateralelevatioRight_cal * 2.204)
  const lateralelevatioRight = lateral_cal < 25 ? lateral_cal : 25

  // const lateralelevatioRight =
  //   docFeedback.strengh_lateralelevation_right === 0 ||
  //   docFeedback.strengh_lateralelevation_right < 0.23
  //     ? 0
  //     : docFeedback.strengh_lateralelevation_right === 0.23 ||
  //       docFeedback.strengh_lateralelevation_right < 1.59
  //     ? 2
  //     : docFeedback.strengh_lateralelevation_right === 1.59 ||
  //       docFeedback.strengh_lateralelevation_right < 2.95
  //     ? 5
  //     : docFeedback.strengh_lateralelevation_right === 2.95 ||
  //       docFeedback.strengh_lateralelevation_right < 4.32
  //     ? 8
  //     : docFeedback.strengh_lateralelevation_right === 4.32 ||
  //       docFeedback.strengh_lateralelevation_right < 5.68
  //     ? 11
  //     : docFeedback.strengh_lateralelevation_right === 5.68 ||
  //       docFeedback.strengh_lateralelevation_right < 7.04
  //     ? 14
  //     : docFeedback.strengh_lateralelevation_right === 7.04 ||
  //       docFeedback.strengh_lateralelevation_right < 8.4
  //     ? 17
  //     : docFeedback.strengh_lateralelevation_right === 8.4 ||
  //       docFeedback.strengh_lateralelevation_right < 9.76
  //     ? 20
  //     : docFeedback.strengh_lateralelevation_right === 9.76 ||
  //       docFeedback.strengh_lateralelevation_right < 11.12
  //     ? 23
  //     : docFeedback.strengh_lateralelevation_right >= 11.12
  //     ? 25
  //     : 0;


  const lateralelevatioLeft_cal = docFeedback.strengh_lateralelevation_left;
  let lateral_cal_left = Math.round(lateralelevatioLeft_cal * 2.204)
  const lateralelevatioLeft = lateral_cal_left < 25 ? lateral_cal_left : 25

  // const lateralelevatioLeft =
  //   docFeedback.strengh_lateralelevation_left === 0 ||
  //   docFeedback.strengh_lateralelevation_left < 0.23
  //     ? 0
  //     : docFeedback.strengh_lateralelevation_left === 0.23 ||
  //       docFeedback.strengh_lateralelevation_left < 1.59
  //     ? 2
  //     : docFeedback.strengh_lateralelevation_left === 1.59 ||
  //       docFeedback.strengh_lateralelevation_left < 2.95
  //     ? 5
  //     : docFeedback.strengh_lateralelevation_left === 2.95 ||
  //       docFeedback.strengh_lateralelevation_left < 4.32
  //     ? 8
  //     : docFeedback.strengh_lateralelevation_left === 4.32 ||
  //       docFeedback.strengh_lateralelevation_left < 5.68
  //     ? 11
  //     : docFeedback.strengh_lateralelevation_left === 5.68 ||
  //       docFeedback.strengh_lateralelevation_left < 7.04
  //     ? 14
  //     : docFeedback.strengh_lateralelevation_left === 7.04 ||
  //       docFeedback.strengh_lateralelevation_left < 8.4
  //     ? 17
  //     : docFeedback.strengh_lateralelevation_left === 8.4 ||
  //       docFeedback.strengh_lateralelevation_left < 9.76
  //     ? 20
  //     : docFeedback.strengh_lateralelevation_left === 9.76 ||
  //       docFeedback.strengh_lateralelevation_left < 11.12
  //     ? 23
  //     : docFeedback.strengh_lateralelevation_left >= 11.12
  //     ? 25
  //     : 0;

  const constant_score_right = sumAll(
    const_recreational_right,
    const_normal_right,
    constshoulder_pain_night_right,
    describe_pain_right1,

    armposition_right,
    prom_forward_right,
    lateralelevation_right,
    ir1_right,
    er2_right,
    lateralelevatioRight
  );

  console.log(
    const_recreational_right,
    const_normal_right,
    constshoulder_pain_night_right,
    describe_pain_right1,

    armposition_right,
    prom_forward_right,
    lateralelevation_right,
    ir1_right,
    er2_right,
    lateralelevatioRight,"constant_score_right",constant_score_right
  );

  // const constant_score_right = 6;

  const constant_score_Left = sumAll(
    const_recreational_left,
    const_normal_left,
    constshoulder_pain_night_left,
    describe_pain_left1,
    armposition_left,
    prom_forward_left,
    lateralelevation_left,
    ir1_left,
    er2_left,
    lateralelevatioLeft
  );

  console.log(
    const_recreational_left,
    const_normal_left,
    constshoulder_pain_night_left,
    describe_pain_left1,
    armposition_left,
    prom_forward_left,
    lateralelevation_left,
    ir1_left,
    er2_left,
    lateralelevatioLeft,"constant_score_Left",constant_score_Left
  );

  // const constant_score_Left = 6;

  // UCLA Score

  //UCLA SCORE CALCULATION
  const PaindescribesR =
    painSco.best_statment_pain_right === "0"
      ? 10
      : painSco.best_statment_pain_right === "1"
        ? 8
        : painSco.best_statment_pain_right === "2"
          ? 6
          : painSco.best_statment_pain_right === "3"
            ? 4
            : painSco.best_statment_pain_right === "4"
              ? 2
              : painSco.best_statment_pain_right === "5"
                ? 1
                : 0;

  const PaindescribesL =
    painSco.best_statment_pain_left === "0"
      ? 10
      : painSco.best_statment_pain_left === "1"
        ? 8
        : painSco.best_statment_pain_left === "2"
          ? 6
          : painSco.best_statment_pain_left === "3"
            ? 4
            : painSco.best_statment_pain_left === "4"
              ? 2
              : painSco.best_statment_pain_left === "5"
                ? 1
                : 0;

  const PainaffshoulderR =
    funScore.affected_shoulder_right === "0"
      ? 10
      : funScore.affected_shoulder_right === "1"
        ? 8
        : funScore.affected_shoulder_right === "2"
          ? 6
          : funScore.affected_shoulder_right === "3"
            ? 4
            : funScore.affected_shoulder_right === "4"
              ? 2
              : funScore.affected_shoulder_right === "5"
                ? 1
                : 0;

  const PainaffshoulderL =
    funScore.affected_shoulder_left === "0"
      ? 10
      : funScore.affected_shoulder_left === "1"
        ? 8
        : funScore.affected_shoulder_left === "2"
          ? 6
          : funScore.affected_shoulder_left === "3"
            ? 4
            : funScore.affected_shoulder_left === "4"
              ? 2
              : funScore.affected_shoulder_left === "5"
                ? 1
                : 0;

  const SatisfactionL =
    safisScore.satisfaction_left === "0"
      ? 0
      : safisScore.satisfaction_left === "1"
        ? 5
        : 0;

  const SatisfactionR =
    safisScore.satisfaction_right === "0"
      ? 0
      : safisScore.satisfaction_right === "1"
        ? 5
        : 0;

  // const ucla_score_right = sumAll(

  // )
  // const ucla_score_left = sumAll(

  // )

  const UCLAprom_forward_right =
    docFeedback.active_forward_degree_right < 30
      ? 0
      : docFeedback.active_forward_degree_right >= 30 &&
        docFeedback.active_forward_degree_right <= 44
        ? 1
        : docFeedback.active_forward_degree_right >= 45 &&
          docFeedback.active_forward_degree_right <= 89
          ? 2
          : docFeedback.active_forward_degree_right >= 90 &&
            docFeedback.active_forward_degree_right <= 119
            ? 3
            : docFeedback.active_forward_degree_right >= 120 &&
              docFeedback.active_forward_degree_right <= 149
              ? 4
              : docFeedback.active_forward_degree_right >= 150
                ? 5
                : 0;

  const UCLAprom_forward_left =
    docFeedback.active_forward_degree_left < 30
      ? 0
      : docFeedback.active_forward_degree_left >= 30 &&
        docFeedback.active_forward_degree_left <= 44
        ? 1
        : docFeedback.active_forward_degree_left >= 45 &&
          docFeedback.active_forward_degree_left <= 89
          ? 2
          : docFeedback.active_forward_degree_left >= 90 &&
            docFeedback.active_forward_degree_left <= 119
            ? 3
            : docFeedback.active_forward_degree_left >= 120 &&
              docFeedback.active_forward_degree_left <= 149
              ? 4
              : docFeedback.active_forward_degree_left >= 150
                ? 5
                : 0;

  const active_forward_grade_right =
    docFeedback.active_forward_grade_right === "0"
      ? 0
      : docFeedback.active_forward_grade_right === "1"
        ? 1
        : docFeedback.active_forward_grade_right === "2"
          ? 2
          : docFeedback.active_forward_grade_right === "3"
            ? 3
            : docFeedback.active_forward_grade_right === "4"
              ? 4
              : docFeedback.active_forward_grade_right === "5"
                ? 5
                : 0;

  const active_forward_grade_left =
    docFeedback.active_forward_grade_left === "0"
      ? 0
      : docFeedback.active_forward_grade_left === "1"
        ? 1
        : docFeedback.active_forward_grade_left === "2"
          ? 2
          : docFeedback.active_forward_grade_left === "3"
            ? 3
            : docFeedback.active_forward_grade_left === "4"
              ? 4
              : docFeedback.active_forward_grade_left === "5"
                ? 5
                : 0;

  const ucla_score_right = sumAll(
    UCLAprom_forward_right,
    active_forward_grade_right,
    PaindescribesR,
    PainaffshoulderR,
    SatisfactionR
  );
console.log("ucla_score_right",UCLAprom_forward_right,
active_forward_grade_right,
PaindescribesR,
PainaffshoulderR,
SatisfactionR,ucla_score_right );

  // const ucla_score_right = 5;
  const ucla_score_left = sumAll(
    UCLAprom_forward_left,
    active_forward_grade_left,
    PaindescribesL,
    PainaffshoulderL,
    SatisfactionL
  );
console.log("ucla_score_left",ucla_score_left,UCLAprom_forward_left,
active_forward_grade_left,
PaindescribesL,
PainaffshoulderL,
SatisfactionL);
  // const ucla_score_left = 5;

  //SST
  let totalNum = 0;
  if (painSco.best_statment_pain_right !== 0) {
    totalNum = totalNum + 1;
  }
  if (painSco.shoulder_pain_night_right !== 0) {
    totalNum = totalNum + 1;
  }
  if (funScore.lift05_right !== 0) {
    totalNum = totalNum + 1;
  }
  if (funScore.lift45_right !== 0) {
    totalNum = totalNum + 1;
  }
  if (funScore.lift10_right !== 0) {
    totalNum = totalNum + 1;
  }
  if (funScore.throwunder_hand_right !== 0) {
    totalNum = totalNum + 1;
  }
  if (funScore.throwover_hand_right !== 0) {
    totalNum = totalNum + 1;
  }
  if (funScore.wash_back_right !== 0) {
    totalNum = totalNum + 1;
  }
  if (funScore.fullusual_work_right !== 0) {
    totalNum = totalNum + 1;
  } else {
    totalNum = 0;
  }

  let totalNumL = 0;
  if (painSco.best_statment_pain_left !== 0) {
    totalNumL = totalNumL + 1;
  }
  if (painSco.shoulder_pain_night_left !== 0) {
    totalNumL = totalNumL + 1;
  }
  if (funScore.lift05_left !== 0) {
    totalNumL = totalNumL + 1;
  }
  if (funScore.lift45_left !== 0) {
    totalNumL = totalNumL + 1;
  }
  if (funScore.lift10_left !== 0) {
    totalNumL = totalNumL + 1;
  }
  if (funScore.throwunder_hand_left !== 0) {
    totalNumL = totalNumL + 1;
  }
  if (funScore.throwover_hand_left !== 0) {
    totalNumL = totalNumL + 1;
  }
  if (funScore.wash_back_left !== 0) {
    totalNumL = totalNumL + 1;
  }
  if (funScore.fullusual_work_left !== 0) {
    totalNumL = totalNumL + 1;
  } else {
    totalNumL = 0;
  }

  const sster2_right =
    docFeedback.er2_right === "1"
      ? 0
      : docFeedback.er2_right === "2"
        ? 0
        : docFeedback.er2_right === "3"
          ? 0
          : docFeedback.er2_right === "4"
            ? 1
            : docFeedback.er2_right === "5"
              ? 1
              : 0;

  const sster2_left =
    docFeedback.er2_left === "1"
      ? 0
      : docFeedback.er2_left === "2"
        ? 0
        : docFeedback.er2_left === "3"
          ? 0
          : docFeedback.er2_left === "4"
            ? 1
            : docFeedback.er2_left === "5"
              ? 1
              : 0;

  const sstarmposition_right =
    docFeedback.armposition_right === "1"
      ? 0
      : docFeedback.armposition_right === "2"
        ? 0
        : docFeedback.armposition_right === "3"
          ? 1
          : docFeedback.armposition_right === "4"
            ? 1
            : docFeedback.armposition_right === "5"
              ? 1
              : 0;

  const sstarmposition_left =
    docFeedback.armposition_left === "1"
      ? 0
      : docFeedback.armposition_left === "2"
        ? 0
        : docFeedback.armposition_left === "3"
          ? 1
          : docFeedback.armposition_left === "4"
            ? 1
            : docFeedback.armposition_left === "5"
              ? 1
              : 0;

  const sstir1_right =
    docFeedback.ir1_right === "1"
      ? 0
      : docFeedback.ir1_right === "2"
        ? 0
        : docFeedback.ir1_right === "3"
          ? 0
          : docFeedback.ir1_right === "4"
            ? 1
            : docFeedback.ir1_right === "5"
              ? 1
              : docFeedback.ir1_right === "6"
                ? 1
                : 0;
  const sstir1_left =
    docFeedback.ir1_left === "1"
      ? 0
      : docFeedback.ir1_left === "2"
        ? 0
        : docFeedback.ir1_left === "3"
          ? 0
          : docFeedback.ir1_left === "4"
            ? 1
            : docFeedback.ir1_left === "5"
              ? 1
              : docFeedback.ir1_left === "6"
                ? 1
                : 0;

  const SstdescribesR =
    painSco.best_statment_pain_right === "0"
      ? 1
      : painSco.best_statment_pain_right === "1"
        ? 1
        : painSco.best_statment_pain_right === "2"
          ? 0
          : painSco.best_statment_pain_right === "3"
            ? 0
            : painSco.best_statment_pain_right === "4"
              ? 0
              : painSco.best_statment_pain_right === "5"
                ? 0
                : 0;

  const SstdescribesL =
    painSco.best_statment_pain_left === "0"
      ? 1
      : painSco.best_statment_pain_left === "1"
        ? 1
        : painSco.best_statment_pain_left === "2"
          ? 0
          : painSco.best_statment_pain_left === "3"
            ? 0
            : painSco.best_statment_pain_left === "4"
              ? 0
              : painSco.best_statment_pain_left === "5"
                ? 0
                : 0;

  const shoulder_pain_night_left =
    painSco.shoulder_pain_night_left === "0"
      ? 1 : 0;

  const shoulder_pain_night_right =
    painSco.shoulder_pain_night_right === "0"
      ? 1 : 0;

  const lift05_right =
    funScore.lift05_right === "0"
      ? 0
      : funScore.lift05_right === "1"
        ? 0
        : funScore.lift05_right === "2"
          ? 0
          : funScore.lift05_right === "3"
            ? 1
            : 0;

  const lift45_right =
    funScore.lift45_right === "0"
      ? 0
      : funScore.lift45_right === "1"
        ? 0
        : funScore.lift45_right === "2"
          ? 0
          : funScore.lift45_right === "3"
            ? 1
            : 0;

  const lift10_right =
    funScore.lift10_right === "0"
      ? 0
      : funScore.lift10_right === "1"
        ? 0
        : funScore.lift10_right === "2"
          ? 0
          : funScore.lift10_right === "3"
            ? 1
            : 0;

  const throwunder_hand_right =
    funScore.throwunder_hand_right === "0"
      ? 0
      : funScore.throwunder_hand_right === "1"
        ? 0
        : funScore.throwunder_hand_right === "2"
          ? 0
          : funScore.throwunder_hand_right === "3"
            ? 1
            : 0;

  const throwover_hand_right =
    funScore.throwover_hand_right === "0"
      ? 0
      : funScore.throwover_hand_right === "1"
        ? 0
        : funScore.throwover_hand_right === "2"
          ? 0
          : funScore.throwover_hand_right === "3"
            ? 1
            : 0;

  const wash_back_right =
    funScore.wash_back_right === "0"
      ? 0
      : funScore.wash_back_right === "1"
        ? 0
        : funScore.wash_back_right === "2"
          ? 0
          : funScore.wash_back_right === "3"
            ? 1
            : 0;

  const fullusual_work_right =
    funScore.fullusual_work_right === "0"
      ? 0
      : funScore.fullusual_work_right === "1"
        ? 0
        : funScore.fullusual_work_right === "2"
          ? 0
          : funScore.fullusual_work_right === "3"
            ? 1
            : 0;

  const lift05_left =
    funScore.lift05_left === "0"
      ? 0
      : funScore.lift05_left === "1"
        ? 0
        : funScore.lift05_left === "2"
          ? 0
          : funScore.lift05_left === "3"
            ? 1
            : 0;

  const lift45_left =
    funScore.lift45_left === "0"
      ? 0
      : funScore.lift45_left === "1"
        ? 0
        : funScore.lift45_left === "2"
          ? 0
          : funScore.lift45_left === "3"
            ? 1
            : 0;

  const lift10_left =
    funScore.lift10_left === "0"
      ? 0
      : funScore.lift10_left === "1"
        ? 0
        : funScore.lift10_left === "2"
          ? 0
          : funScore.lift10_left === "3"
            ? 1
            : 0;

  const throwunder_hand_left =
    funScore.throwunder_hand_left === "0"
      ? 0
      : funScore.throwunder_hand_left === "1"
        ? 0
        : funScore.throwunder_hand_left === "2"
          ? 0
          : funScore.throwunder_hand_left === "3"
            ? 1
            : 0;

  const throwover_hand_left =
    funScore.throwover_hand_left === "0"
      ? 0
      : funScore.throwover_hand_left === "1"
        ? 0
        : funScore.throwover_hand_left === "2"
          ? 0
          : funScore.throwover_hand_left === "3"
            ? 1
            : 0;

  const wash_back_left =
    funScore.wash_back_left === "0"
      ? 0
      : funScore.wash_back_left === "1"
        ? 0
        : funScore.wash_back_left === "2"
          ? 0
          : funScore.wash_back_left === "3"
            ? 1
            : 0;

  const fullusual_work_left =
    funScore.fullusual_work_left === "0"
      ? 0
      : funScore.fullusual_work_left === "1"
        ? 0
        : funScore.fullusual_work_left === "2"
          ? 0
          : funScore.fullusual_work_left === "3"
            ? 1
            : 0;

  const SSTR = sumAll(
    SstdescribesR,
    shoulder_pain_night_right,
    lift05_right,
    lift45_right,
    lift10_right,
    throwunder_hand_right,
    throwover_hand_right,
    wash_back_right,
    fullusual_work_right,
    sster2_right,
    sstarmposition_right,
    sstir1_right
  );
  // const SSTcR = Math.floor(((SSTR / totalNum) * 100))
  const SSTcR = ((SSTR / 12) * 100).toFixed();
  let sst_score_right;
  if (SSTcR === "NaN") {
    sst_score_right = null;
  } else {
    sst_score_right = SSTcR;
  }
  const SSTL = sumAll(
    SstdescribesL,
    shoulder_pain_night_left,
    lift05_left,
    lift45_left,
    lift10_left,
    throwunder_hand_left,
    throwover_hand_left,
    wash_back_left,
    fullusual_work_left,
    sster2_left,
    sstarmposition_left,
    sstir1_left
  );
  // const SSTcL = Math.floor(((SSTL / totalNumL) * 100))
  const SSTcL = ((SSTL / 12) * 100).toFixed();
  let sst_score_left;
  if (SSTcL === "NaN") {
    sst_score_left = null;
  } else {
    sst_score_left = SSTcL;
  }

  const [constant_scoreright, setconstant_scoreright] =
    useState(constant_score_right);
  const [constant_scoreLeft, setconstant_scoreLeft] =
    useState(constant_score_Left);
  const [ucla_scoreleft, setucla_scoreleft] = useState(ucla_score_left);
  const [ucla_scoreright, setucla_scoreright] = useState(ucla_score_right);
  const [sst_scoreright, setsst_scoreright] = useState(sst_score_right);
  const [sst_scoreleft, setsst_scoreleft] = useState(sst_score_left);
  const json1 = {
    constant_score_right: constant_scoreright,
    constant_score_Left: constant_scoreLeft,
    ucla_score_left: ucla_scoreleft,
    ucla_score_right: ucla_scoreright,
    sst_score_right: sst_scoreright,
    sst_score_left: sst_scoreleft,
  };

  useEffect(() => {
    if (
      docFeedback.active_forward_grade_left === "" ||
      docFeedback.active_forward_degree_right === "" ||
      docFeedback.active_forward_grade_right === "" ||
      docFeedback.active_forward_grade_left === ""
    ) {
      setucla_scoreleft("");
      setucla_scoreright("");
    } else {
      setucla_scoreleft(ucla_score_left);
      setucla_scoreright(ucla_score_right);
    }
    if (
      docFeedback.er2_right === "" ||
      docFeedback.er2_left === "" ||
      docFeedback.armposition_right === "" ||
      docFeedback.armposition_left === "" ||
      docFeedback.ir1_right === "" ||
      docFeedback.ir1_left === ""
    ) {
      setsst_scoreright("");
      setsst_scoreleft("");
    } else {
      setsst_scoreright(sst_score_right);
      setsst_scoreleft(sst_score_left);
    }
    if (
      docFeedback.armposition_left === "" ||
      docFeedback.armposition_right === "" ||
      docFeedback.active_forward_degree_left === "" ||
      docFeedback.active_forward_degree_right === "" ||
      docFeedback.lateralelevation_right === "" ||
      docFeedback.lateralelevation_left === "" ||
      docFeedback.ir1_right === "" ||
      docFeedback.ir1_left === "" ||
      docFeedback.er2_right === "" ||
      docFeedback.er2_left === "" ||
      docFeedback.strengh_lateralelevation_right === "" ||
      docFeedback.strengh_lateralelevation_left === ""
    ) {
      setconstant_scoreright("");
      setconstant_scoreLeft("");
    } else {
      setconstant_scoreright(constant_score_right);
      setconstant_scoreLeft(constant_score_Left);
    }
  }, [docFeedback]);

  const updateDate1 = async () => {
    const response = await axiosinstance.current.put(
      `extapp/forms/update_total_scores?resource_id=${rId}`,
      json1,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  };
  let akpt = searchParams.get("akpt");
  const [msg, setMsg] = useState("");
  const [msgType, setmsgType] = useState("");
  const updateDate = async () => {
    const response = axiosinstance.current.put(
      `extapp/forms/update_doctor_feedback?resource_id=${rId}&action=1&ak_dc=${dId}&edit=1`,
      docFeedback1,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response) {
      setMsg(translation.update);
      setmsgType("success");
      setTimeout(() => {
        window.location.href = process.env.REACT_APP_DOCTOR_URL + "patient-view/" +akpt
        setMsg("");
        setmsgType("success");
      }, 2000);
    }
  };
  const handleNext = () => {
    navigate(`/shoulder-treatment-form/step-2/${rId}?d=true&akpt=${akpt}&is_doctor_form=true`);

  }
  const updateDate2 = async () => {
    const response = await axiosinstance.current.put(
      `extapp/forms/update_doctor_feedback?resource_id=${rId}&action=0&ak_dc=${dId}&edit=1`,
      docFeedback1,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200) {
      let doctordata=localStorage.getItem("isdoctorForm")
     
      navigate(
        "/shoulder-treatment-form/step-2/" +
        rId +
        `?doctor=${dId}&patient=${pId}&is_doctor_form=true#subscapularis`
      );
    }
  };
  useEffect(()=>{
    const updateDate2 = async () => {
    const response = await axiosinstance.current.put(
      `extapp/forms/update_doctor_feedback?resource_id=${rId}&action=0&ak_dc=${dId}`,
      docFeedback1,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200) {
     
    }
    }
    if(chk){
      if(doctorEnable !== "true"){
    updateDate2()
    updateDate1()
    }
    }
  },[docFeedback1,json1])
  useEffect(() => {
    if (er2Right) {
      setvalidatorArsR(false);
    }
    if (er2Left) {
      setvalidatorArsL(false);
    }
    if (ir1Right) {
      setvalidatorArmR(false);
    }
    if (ir1Left) {
      setvalidatorArmL(false);
    }
    if (armPositionR) {
      setvalidatorPositionR(false);
    }
    if (armPositionL) {
      setvalidatorPositionL(false);
    }
  }, [er2Right, er2Left, ir1Right, ir1Left, armPositionR, armPositionL]);
  const continueRedirect = (e) => {
    if (SimpleStatus && ConstantStatus && UCLAStatus) {
      // Validation for all checkboxes selected
      if (
        isNaN(activeForwardDegreeR) ||
        activeForwardDegreeR === "" ||
        isNaN(activeForwardDegreeL) ||
        activeForwardDegreeL === "" ||
        isNaN(activeForwardGradeValueR) ||
        activeForwardGradeValueR === "" ||
        isNaN(activeForwardGradeValueL) ||
        activeForwardGradeValueL === "" ||
        isNaN(lateralElevationDegreeR) ||
        isNaN(lateralElevationDegreeL) ||
        lateralElevationDegreeL === "" ||
        lateralElevationDegreeR === "" ||
        isNaN(strenghLateralElevationR) ||
        isNaN(strenghLateralElevationL) ||
        strenghLateralElevationR === "" ||
        strenghLateralElevationL === "" ||
        er2Right === "" ||
        er2Left === "" ||
        armPositionR === "" ||
        armPositionL === "" ||
        ir1Right === "" ||
        ir1Left === ""
      ) {
        // Set validation flags
        window.scrollTo(0, 0);
        setValidatorsL(true);
        setValidators(true);
        setvalidatorArsR(true);
        setvalidatorArsL(true);
        setValidators(true);
        setValidatorsL(true);
        setvalidatorArsR(true);
        setvalidatorArsL(true);
        setvalidatorPositionR(true);
        setvalidatorPositionL(true);
        setvalidatorArmR(true);
        setvalidatorArmL(true);
      } else {
        // All checkboxes selected and all fields are valid
        // Do something
        // alert("1");
        updateDate1();
        updateDate2();
        return;
      }
    } else if (UCLAStatus) {
      // Validation for UCLAStatus
      if (
        isNaN(activeForwardDegreeR) ||
        activeForwardDegreeR === "" ||
        isNaN(activeForwardDegreeL) ||
        activeForwardDegreeL === "" ||
        isNaN(activeForwardGradeValueR) ||
        activeForwardGradeValueR ===""||
        isNaN(activeForwardGradeValueL)
      ) {
        // Set validation flags
        window.scrollTo(0, 0);
        setValidators(true);
        setValidatorsL(true);
      } else {
        // UCLAStatus validation passed
        // Do something
        // alert("23");
        updateDate1();
        updateDate2();
      }
    } else if (SimpleStatus) {
      // Validation for SimpleStatus
      if (
        er2Right === "" ||
        er2Left === "" ||
        armPositionR === "" ||
        armPositionL === "" ||
        ir1Right === "" ||
        ir1Left === ""
      ) {
        // Set validation flags
        window.scrollTo(0, 0);
        setvalidatorArsR(true);
        setvalidatorArsL(true);
        setvalidatorPositionR(true);
        setvalidatorPositionL(true);
        setvalidatorArmR(true);
        setvalidatorArmL(true);
      } else {
        // SimpleStatus validation passed
        if (ConstantStatus) {
          // Additional validation if ConstantStatus is also true
          if (
            isNaN(activeForwardDegreeR) ||
            activeForwardDegreeR === "" ||
            isNaN(activeForwardDegreeL) ||
            activeForwardDegreeL === "" ||
            isNaN(lateralElevationDegreeR) ||
            isNaN(lateralElevationDegreeL) ||
            isNaN(strenghLateralElevationR) ||
            isNaN(strenghLateralElevationL) ||
            er2Right === "" ||
            er2Left === "" ||
            armPositionR === "" ||
            armPositionL === "" ||
            ir1Right === "" ||
            ir1Left === ""
          ) {
            // Set validation flags
            window.scrollTo(0, 0);
            setValidatorsL(true);
            setValidators(true);
            setvalidatorArsR(true);
            setvalidatorArsL(true);
            setValidators(true);
            setValidatorsL(true);
            setvalidatorArsR(true);
            setvalidatorArsL(true);
            setvalidatorPositionR(true);
            setvalidatorPositionL(true);
            setvalidatorArmR(true);
            setvalidatorArmL(true);
          } else {
            // All checkboxes selected and all fields are valid
            // Do something
            // alert("3");
            updateDate1();
            updateDate2();
            return;
          }
        } else if (UCLAStatus) {
          // Additional validation if only UCLAStatus is true
          if (
            isNaN(activeForwardDegreeR) ||
            activeForwardDegreeR === "" ||
            isNaN(activeForwardDegreeL) ||
            activeForwardDegreeL === "" ||
            isNaN(activeForwardGradeValueR) ||
            activeForwardGradeValueR === ""||
            isNaN(activeForwardGradeValueL)
          ) {
            // Set validation flags
            window.scrollTo(0, 0);
            setValidators(true);
            setValidatorsL(true);
          } else {
            // UCLAStatus validation passed
            // Do something
            // alert("2");
            updateDate1();
            updateDate2();
          }
        } else {
          // No other checkbox is selected
          // Do something
          updateDate1();
          updateDate2();
          // alert("3");
        }
      }
    } else {
      updateDate1();
      updateDate2();
    }
  };



  const continueRedirecttrue = () => {
    if (SimpleStatus && ConstantStatus && UCLAStatus) {
      // Validation for all checkboxes selected
      if (
        isNaN(activeForwardDegreeR) ||
        activeForwardDegreeR === "" ||
        isNaN(activeForwardDegreeL) ||
        activeForwardDegreeL === "" ||
        isNaN(activeForwardGradeValueR) ||
        activeForwardGradeValueR === "" ||
        isNaN(activeForwardGradeValueL) ||
        activeForwardGradeValueL === "" ||
        isNaN(lateralElevationDegreeR) ||
        isNaN(lateralElevationDegreeL) ||
        lateralElevationDegreeL === "" ||
        lateralElevationDegreeR === "" ||
        isNaN(strenghLateralElevationR) ||
        isNaN(strenghLateralElevationL) ||
        strenghLateralElevationR === "" ||
        strenghLateralElevationL === "" ||
        er2Right === "" ||
        er2Left === "" ||
        armPositionR === "" ||
        armPositionL === "" ||
        ir1Right === "" ||
        ir1Left === ""
      ) {
        // Set validation flags
        window.scrollTo(0, 0);
        setValidatorsL(true);
        setValidators(true);
        setvalidatorArsR(true);
        setvalidatorArsL(true);
        setValidators(true);
        setValidatorsL(true);
        setvalidatorArsR(true);
        setvalidatorArsL(true);
        setvalidatorPositionR(true);
        setvalidatorPositionL(true);
        setvalidatorArmR(true);
        setvalidatorArmL(true);
      } else {
        // All checkboxes selected and all fields are valid
        // Do something
        // alert("1");
        updateDate1();
        updateDate();
        return;
      }
    } else if (UCLAStatus) {
      // Validation for UCLAStatus
      if (
        isNaN(activeForwardDegreeR) ||
        activeForwardDegreeR === "" ||
        isNaN(activeForwardDegreeL) ||
        activeForwardDegreeL === "" ||
        isNaN(activeForwardGradeValueR) ||
        activeForwardGradeValueR ==="" ||
        isNaN(activeForwardGradeValueL)
      ) {
        window.scrollTo(0, 0);
        // Set validation flags
        setValidators(true);
        setValidatorsL(true);
      } else {
        // UCLAStatus validation passed
        // Do something
        // alert("23");
        updateDate1();
        updateDate();
      }
    } else if (SimpleStatus) {
      // Validation for SimpleStatus
      if (
        er2Right === "" ||
        er2Left === "" ||
        armPositionR === "" ||
        armPositionL === "" ||
        ir1Right === "" ||
        ir1Left === ""
      ) {
        // Set validation flags
        window.scrollTo(0, 0);
        setvalidatorArsR(true);
        setvalidatorArsL(true);
        setvalidatorPositionR(true);
        setvalidatorPositionL(true);
        setvalidatorArmR(true);
        setvalidatorArmL(true);
      } else {
        // SimpleStatus validation passed
        if (ConstantStatus) {
          // Additional validation if ConstantStatus is also true
          if (
            isNaN(activeForwardDegreeR) ||
            activeForwardDegreeR === "" ||
            isNaN(activeForwardDegreeL) ||
            activeForwardDegreeL === "" ||
            isNaN(lateralElevationDegreeR) ||
            isNaN(lateralElevationDegreeL) ||
            isNaN(strenghLateralElevationR) ||
            isNaN(strenghLateralElevationL) ||
            er2Right === "" ||
            er2Left === "" ||
            armPositionR === "" ||
            armPositionL === "" ||
            ir1Right === "" ||
            ir1Left === ""
          ) {
            // Set validation flags
            window.scrollTo(0, 0);
            setValidatorsL(true);
            setValidators(true);
            setvalidatorArsR(true);
            setvalidatorArsL(true);
            setValidators(true);
            setValidatorsL(true);
            setvalidatorArsR(true);
            setvalidatorArsL(true);
            setvalidatorPositionR(true);
            setvalidatorPositionL(true);
            setvalidatorArmR(true);
            setvalidatorArmL(true);
          } else {
            // All checkboxes selected and all fields are valid
            // Do something
            // alert("3");
            updateDate1();
            updateDate();
            return;
          }
        } else if (UCLAStatus) {
          // Additional validation if only UCLAStatus is true
          if (
            isNaN(activeForwardDegreeR) ||
            activeForwardDegreeR === "" ||
            isNaN(activeForwardDegreeL) ||
            activeForwardDegreeL === "" ||
            isNaN(activeForwardGradeValueR) ||
            activeForwardGradeValueR === ""||
            isNaN(activeForwardGradeValueL)
          ) {
            // Set validation flags
            window.scrollTo(0, 0);
            setValidators(true);
            setValidatorsL(true);
          } else {
            // UCLAStatus validation passed
            // Do something
            // alert("2");
            updateDate1();
            updateDate();
          }
        } else {
          // No other checkbox is selected
          // Do something
          updateDate1();
          updateDate();
          // alert("3");
        }
      }
    } else {
      updateDate1();
      updateDate();
    }

    // if (
    //   activeForwardDegreeR === 0 || isNaN(activeForwardDegreeR) || activeForwardDegreeR === null ||
    //   activeForwardDegreeL === 0 || isNaN(activeForwardDegreeL) || activeForwardDegreeL === null ||
    //   lateralElevationDegreeR === 0 || isNaN(lateralElevationDegreeR) || lateralElevationDegreeR === null ||
    //   lateralElevationDegreeL === 0 || isNaN(lateralElevationDegreeL) || lateralElevationDegreeL === null ||
    //   strenghLateralElevationR === 0 || isNaN(strenghLateralElevationR) || strenghLateralElevationR === null ||
    //   strenghLateralElevationL === 0 || isNaN(strenghLateralElevationL) || strenghLateralElevationL === null ||
    //   er2Right === "" ||
    //   er2Left == "" ||
    //   armPositionR === "" ||
    //   armPositionL == "" ||
    //   ir1Right === "" ||
    //   ir1Left == ""
    // ) {
    //   setValidators(true);
    //   setValidatorsL(true);
    //   setvalidatorArsR(true);
    //   setvalidatorArsL(true);
    //   setvalidatorPositionR(true);
    //   setvalidatorPositionL(true);
    //   setvalidatorArmR(true);
    //   setvalidatorArmL(true);
    //   setvalidatort(true);
    // } else {
    // updateDate1();
    // updateDate();
    // }
  };

  // console.log(activeForwardDegreeR, "....................................................");
  //  useEffect(()=>{
  // if(docFeedback.er2_right==="4"){
  //   setEr2Right("4")
  // }
  //  },[docFeedback.er2_right])

  const [activeForwardGradeRight, setActiveForwardGradeRight] = useState({
    min: 1,
    max: 6,
    label: "Right",
    
  });
  const [activeForwardGradeLeft, setActiveForwardGradeLeft] = useState({
    min: 1,
    max: 6,
    // label: `Left`+(UCLAStatus ? "*" : ""),
    // label: `Left${UCLAStatus ? '*' : ''}`
    label: "Left"

  });

  useEffect(()=>{
    if(UCLAStatus){
      setActiveForwardGradeRight({
        min: 1,
        max: 6,
        label: "Right*",
      })

    }
    if(UCLAStatus){
      setActiveForwardGradeLeft({
        min: 1,
        max: 6,
        label: "Left*",
      })}
  },[UCLAStatus])

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  return (
    <>
    {isLoading ? (
      <LoadingSpinner />
    ) : (
    <>
      <div class="d-flex justify-content-end">
        <DoctorNameCustom />
      </div>
      <div className="pain_screen mb-5">
        <CustomToaster msg={msg} msgType={msgType} />
        <AssessmentHeader />
        <HeaderInnerBreadcrumb />
        {doctorEnable === "true" && <Tab rId={rId} />}
      </div>

      <div className="patient_assessment_form mb_100 bg_white pd_10 pt_50 content_fx_w">
        <div className="row mb_30">
          <div className="col-md-12 col-sm-12">
            <div className="text-center">
              {/* {validatort == true && (
                <span className="text-danger text-center">
                  Please ensure you’ve completed all of the mandatory questions
                  before submitting the assessment.
                </span>
              )} */}
            </div>
          </div>

          <div className="col-md-6 col-sm-6">
            <AssessmentDegreeWidget
              title={translation.Forward_Flexion}
              validator={validator}
              validatorL={validatorL}
              onChangeR={activeForwardDegreeChangeR}
              onChangeL={activeForwardDegreeChangeL}
              valueR={activeForwardDegreeR}
              valueL={activeForwardDegreeL}
              labelR={
                translation.Right_ArS +
                ([ConstantStatus, UCLAStatus].some((ele) => ele === true)
                  ? "*"
                  : "")
              }
              labelL={
                translation.Left_ArS +
                ([ConstantStatus, UCLAStatus].some((ele) => ele === true)
                  ? "*"
                  : "")
              }
              nameR={translation.Forward_Flexion_Right}
              nameL={translation.Forward_Flexion_Left}
              viewValidation={[ConstantStatus, UCLAStatus].some(
                (ele) => ele === true
              )}
            />
          </div>
          <div className="col-md-6 col-sm-6">
            <AssessmentDegreeWidget
              valueR={promForwardDegreeR}
              valueL={promForwardDegreeL}
              title={translation.Passive_Forward_Elevation}
              onChangeR={promForwardDegreeChangeR}
              onChangeL={promForwardDegreeChangeL}
              labelL={translation.Left_Arm}
              labelR={translation.Right_Arm}
              nameR={translation.Passive_Forward_Elevation_Right}
              nameL={translation.Passive_Forward_Elevation_Left}
            />
          </div>
        </div>
        <div className="input_tab_box mb_30">
          <div className="row">
            <h4 className="range_sl_title">
              {translation.Strength_Forward_Flexion }
            </h4>

            <div className="col-md-6 col-sm-6">
              <ShoulderRangeSlider
                {...activeForwardGradeRight}
                classes=""
                onChange={activeForwardGradechangeR}
                value={activeForwardGradeValueR}
                validator={validator}
              />
            </div>
            <div className="col-md-6 col-sm-6">
              <ShoulderRangeSlider
                {...activeForwardGradeLeft}
                classes=""
                onChange={activeForwardGradechangeL}
                value={activeForwardGradeValueL}
                validator={validatorL}
              />
            </div>
          </div>
        </div>
        <div className="row mb_30">
          <div className="col-md-6 col-sm-6">
            <AssessmentDegreeWidget
              valueR={lateralElevationDegreeR}
              valueL={lateralElevationDegreeL}
              title={translation.Lateral_Elevation}
              validator={validator}
              validatorL={validatorL}
              onChangeR={lateralElevationDegreeChangeR}
              onChangeL={lateralElevationDegreeChangeL}
              labelL={
                translation.Left_ArS +
                ([ConstantStatus].some((ele) => ele === true) ? "*" : "")
              }
              labelR={
                translation.Right_ArS +
                ([ConstantStatus].some((ele) => ele === true) ? "*" : "")
              }
              nameR={translation.Lateral_Elevation_Right}
              nameL={translation.Lateral_Elevation_Left}
              viewValidation={ConstantStatus}
            />
          </div>
          <div className="col-md-6 col-sm-6">
            <AssessmentDegreeWidget
              valueR={promLateralDegreeR}
              valueL={promLateralDegreeL}
              title={translation.Passive_Lateral_Elevation}
              onChangeR={promLateralDegreeChangeR}
              onChangeL={promLateralDegreeChangeL}
              labelL={translation.Left_Arm}
              labelR={translation.Right_Arm}
              nameR={translation.Passive_Lateral_Elevation_Right}
              nameL={translation.Passive_Lateral_Elevation_Left}
            />
          </div>
        </div>
        <div className="row mb_30">
          <div className="col-md-6 col-sm-6">
            {/* <h4 >Strength of Lateral Elevation</h4> */}
            <AssessmentKgKgWidget
              validator={validator}
              validatorL={validatorL}
              power={strenghLateralElevationR}
              kg={strenghLateralElevationL}
              rightArmkgAction="true"
              leftArmkgAction="true"
              onChangeP={strenghLateralElevationDegreeChangeR}
              onChangeK={strenghLateralElevationDegreeChangeL}
              title="Strength of Lateral Elevation"
              labelR={
                translation.Right_ArS +
                ([ConstantStatus].some((ele) => ele === true) ? "*" : "")
              }
              labelL={
                translation.Left_ArS +
                ([ConstantStatus].some((ele) => ele === true) ? "*" : "")
              }
              // labelL={translation.Strength_Lateral_Elevation_Right}
              // labelR ={translation.Strength_Lateral_Elevation_Left}
              nameR="tricepsStrenthR"
              nameL="tricepsStrenthL"
              viewValidation={ConstantStatus}
            />
            {/* <AssessmentKgKgWidget
              valueR={strenghLateralElevationR}
              valueL={strenghLateralElevationL}
              title={translation.Strength_Lateral_Elevation}
              validator={validator}
              validatorL={validatorL}
              onChangeR={strenghLateralElevationDegreeChangeR}
              onChangeL={strenghLateralElevationDegreeChangeL}
              labelL={translation.Right_ArS}
              labelR ={translation.Left_ArS}
              nameR={translation.Strength_Lateral_Elevation_Right}
              nameL={translation.Strength_Lateral_Elevation_Left}
            /> */}
          </div>
          <div className="col-md-6 col-sm-6"></div>
        </div>
        <div className="row mb_30">
          <div className="col-md-6 col-sm-6">
            <AssessmentDegreeWidget
              valueR={er1R}
              valueL={er1L}
              title={translation.External_Rotation_ER1}
              onChangeR={er1ChangeR}
              onChangeL={er1ChangeL}
              labelR="Right"
              labelL="Left"
              nameR="er1 Right"
              nameL="er1 Left"
              validationfor="negative"
            />
          </div>
          <div className="col-md-6 col-sm-6">
            <AssessmentDegreeWidget
              valueR={promEr1DegreeR}
              valueL={promEr1DegreeL}
              title={translation.Passive_External_Rotation_ER1}
              onChangeR={promEr1DegreeChangeR}
              onChangeL={promEr1DegreeChangeL}
              labelR="Right"
              labelL="Left"
              nameR="Passive External Rotation (ER1) Right"
              nameL="Passive External Rotation (ER1) Left"
              validationfor="negative"
            />
          </div>
        </div>
        <div className="row mb_30">
          <div className="col-md-6 col-sm-6">
            <AssessmentDegreeWidget
              valueR={er2R}
              valueL={er2L}
              title={"Active External Rotation at 90 abduction (ER2)"}
              onChangeR={er2ChangeR1}
              onChangeL={er2ChangeL1}
              labelR="Right"
              labelL="Left"
              nameR="er2 Right"
              nameL="er2 Left"
              validationfor="negative"
            />
          </div>
          <div className="col-md-6 col-sm-6">
            <AssessmentDegreeWidget
              valueR={promEr2DegreeR}
              valueL={promEr2DegreeL}
              title={"Passive External Rotation at 90 abduction (ER2)"}
              onChangeR={promEr2DegreeChangeR}
              onChangeL={promEr2DegreeChangeL}
              labelR="Right"
              labelL="Left"
              nameR="Passive External Rotation (ER1) Right"
              nameL="Passive External Rotation (ER1) Left"
              validationfor="negative"
            />
          </div>
        </div>

        <div className="input_tab_box mb_30 mx_760">
          <div className="row">
            <h4 className="range_sl_title">
              {translation.External_Rotation_ER2}
            </h4>

            <div className="col-md-6 col-sm-6">
              <RadioButton
                labelTitle={
                  translation.Right_ArS +
                  ([SimpleStatus, ConstantStatus].some((ele) => ele === true)
                    ? "*"
                    : "")
                }
                label={translation.Hand_behind_Head_Elbow_Forward}
                selected={er2Right === "1" ? true : false}
                onChange={er2ChangeR}
                styleClass="custom_radio_box large"
                value="1"
                id="er2Right1"
                name="er2Right"
              />
              <RadioButton
                label={translation.Hand_behind_Head_Elbow_Back}
                selected={er2Right === "2" ? true : false}
                onChange={er2ChangeR}
                styleClass="custom_radio_box large"
                value="2"
                id="er2Right2"
                name="er2Right"
              />
              <RadioButton
                label={translation.Hand_top_Head_Elbow_Forward}
                selected={er2Right === "3" ? true : false}
                onChange={er2ChangeR}
                styleClass="custom_radio_box large"
                value="3"
                id="er2Right3"
                name="er2Right"
              />
              <RadioButton
                label={translation.Hand_top_Head_Elbow_Back}
                selected={er2Right === "4" ? true : false}
                onChange={er2ChangeR}
                styleClass="custom_radio_box large"
                value="4"
                id="er2Right4"
                name="er2Right"
              />
              <RadioButton
                label={translation.Full_Elevation}
                selected={er2Right === "5" ? true : false}
                onChange={er2ChangeR}
                styleClass="custom_radio_box large"
                value="5"
                id="er2Right5"
                name="er2Right"
              />

              {er2Right == "" && validatorArsR == true ? (
                <span className="text-danger">This field is required</span>
              ) : null}
            </div>
            <div className="col-md-6 col-sm-6">
              <RadioButton
                labelTitle={
                  translation.Left_ArS +
                  ([SimpleStatus, ConstantStatus].some((ele) => ele === true)
                    ? "*"
                    : "")
                }
                label={translation.Hand_behind_head_elbow_forward}
                selected={er2Left === "1"}
                onChange={er2ChangeL}
                styleClass="custom_radio_box large"
                value="1"
                id="er2Left1"
                name="er2Left"
              />
              <RadioButton
                label={translation.Hand_behind_head_elbow_back}
                selected={er2Left === "2"}
                onChange={er2ChangeL}
                styleClass="custom_radio_box large"
                value="2"
                id="er2Left2"
                name="er2Left"
              />
              <RadioButton
                label={translation.Hand_top_head_elbow_forward}
                selected={er2Left === "3"}
                onChange={er2ChangeL}
                styleClass="custom_radio_box large"
                value="3"
                id="er2Left3"
                name="er2Left"
              />
              <RadioButton
                label={translation.Hand_top_head_elbow_back}
                selected={er2Left === "4"}
                onChange={er2ChangeL}
                styleClass="custom_radio_box large"
                value="4"
                id="er2Left4"
                name="er2Left"
              />
              <RadioButton
                label={translation.Full_elevation}
                selected={er2Left === "5"}
                onChange={er2ChangeL}
                styleClass="custom_radio_box large"
                value="5"
                id="er2Left5"
                name="er2Left"
              />
              {er2Left == "" && validatorArsL == true && (
                <span className="text-danger">This field is required</span>
              )}
            </div>
          </div>
        </div>
        <div className="input_tab_box mb_30 mx_760">
          <div className="row">
            <h4 className="range_sl_title">{translation.Arm_Position}</h4>

            <div className="col-md-6 col-sm-6">
              <RadioButton
                labelTitle={
                  translation.Right_ArS +
                  ([SimpleStatus, ConstantStatus].some((ele) => ele === true)
                    ? "*"
                    : "")
                }
                label={translation.Waist}
                selected={armPositionR === "1" ? true : false}
                onChange={armPositionchangeR}
                styleClass="custom_radio_box large"
                value="1"
                id="armPositionRight1"
                name="armPositionRight"
              />
              <RadioButton
                label={translation.Xiphoid}
                selected={armPositionR === "2" ? true : false}
                onChange={armPositionchangeR}
                styleClass="custom_radio_box large"
                value="2"
                id="armPositionRight2"
                name="armPositionRight"
              />
              <RadioButton
                label={translation.Neck}
                selected={armPositionR === "3" ? true : false}
                onChange={armPositionchangeR}
                styleClass="custom_radio_box large"
                value="3"
                id="armPositionRight3"
                name="armPositionRight"
              />
              <RadioButton
                label={translation.Top_of_head}
                selected={armPositionR === "4" ? true : false}
                onChange={armPositionchangeR}
                styleClass="custom_radio_box large"
                value="4"
                id="armPositionRight4"
                name="armPositionRight"
              />
              <RadioButton
                label={translation.Above_head}
                selected={armPositionR === "5" ? true : false}
                onChange={armPositionchangeR}
                styleClass="custom_radio_box large"
                value="5"
                id="armPositionRight5"
                name="armPositionRight"
              />
              {armPositionR == "" && validatorPositionR == true && (
                <span className="text-danger">This field is required</span>
              )}
            </div>
            <div className="col-md-6 col-sm-6">
              <RadioButton
                labelTitle={
                  translation.Left_ArS +
                  ([SimpleStatus, ConstantStatus].some((ele) => ele === true)
                    ? "*"
                    : "")
                }
                label={translation.Waist}
                selected={armPositionL === "1"}
                onChange={armPositionchangeL}
                styleClass="custom_radio_box large"
                value="1"
                id="armPositionLeft1"
                name="armPositionLeft"
              />
              <RadioButton
                label={translation.Xiphoid}
                selected={armPositionL === "2"}
                onChange={armPositionchangeL}
                styleClass="custom_radio_box large"
                value="2"
                id="armPositionLeft2"
                name="armPositionLeft"
              />
              <RadioButton
                label={translation.Neck}
                selected={armPositionL === "3"}
                onChange={armPositionchangeL}
                styleClass="custom_radio_box large"
                value="3"
                id="armPositionLeft3"
                name="armPositionLeft"
              />
              <RadioButton
                label={translation.Top_of_head}
                selected={armPositionL === "4"}
                onChange={armPositionchangeL}
                styleClass="custom_radio_box large"
                value="4"
                id="armPositionLeft4"
                name="armPositionLeft"
              />
              <RadioButton
                label={translation.Above_head}
                selected={armPositionL === "5"}
                onChange={armPositionchangeL}
                styleClass="custom_radio_box large"
                value="5"
                id="armPositionLeft5"
                name="armPositionLeft"
              />
              {armPositionL == "" && validatorPositionL == true && (
                <span className="text-danger">This field is required</span>
              )}
            </div>
          </div>
        </div>
        <div className="input_tab_box mb_30 mx_760">
          <div className="row">
            <h4 className="range_sl_title">
              {translation.Internal_Rotation_IR1}
            </h4>

            <div className="col-md-6 col-sm-6">
              <RadioButton
                labelTitle={
                  translation.Right_ArS +
                  ([SimpleStatus, ConstantStatus].some((ele) => ele === true)
                    ? "*"
                    : "")
                }
                label={translation.Lateral_Thigh}
                selected={ir1Right === "1"}
                onChange={ir1ChangeR}
                styleClass="custom_radio_box large"
                value="1"
                id="ir1Right1"
                name="ir1Right"
              />
              <RadioButton
                label={translation.Buttock}
                selected={ir1Right === "2"}
                onChange={ir1ChangeR}
                styleClass="custom_radio_box large"
                value="2"
                id="ir1Right2"
                name="ir1Right"
              />
              <RadioButton
                label={translation.Lumbosacral_Junction}
                selected={ir1Right === "3"}
                onChange={ir1ChangeR}
                styleClass="custom_radio_box large"
                value="3"
                id="ir1Right3"
                name="ir1Right"
              />
              <RadioButton
                label={translation.Waist_L3}
                selected={ir1Right === "4"}
                onChange={ir1ChangeR}
                styleClass="custom_radio_box large"
                value="4"
                id="ir1Right4"
                name="ir1Right"
              />
              <RadioButton
                label={translation.T12_Vertebra}
                selected={ir1Right === "5"}
                onChange={ir1ChangeR}
                styleClass="custom_radio_box large"
                value="5"
                id="ir1Right5"
                name="ir1Right"
              />
              <RadioButton
                label={translation.Interscapular_T7}
                selected={ir1Right === "6"}
                onChange={ir1ChangeR}
                styleClass="custom_radio_box large"
                value="6"
                id="ir1Right6"
                name="ir1Right"
              />
              {ir1Right == "" && validatorArmR == true && (
                <span className="text-danger">This field is required</span>
              )}
            </div>
            <div className="col-md-6 col-sm-6">
              <RadioButton
                labelTitle={
                  translation.Left_ArS +
                  ([SimpleStatus, ConstantStatus].some((ele) => ele === true)
                    ? "*"
                    : "")
                }
                label={translation.Lateral_Thigh}
                selected={ir1Left === "1"}
                onChange={ir1ChangeL}
                styleClass="custom_radio_box large"
                value="1"
                id="ir1Left1"
                name="ir1Left"
              />
              <RadioButton
                label={translation.Buttock}
                selected={ir1Left === "2"}
                onChange={ir1ChangeL}
                styleClass="custom_radio_box large"
                value="2"
                id="ir1Left2"
                name="ir1Left"
              />
              <RadioButton
                label={translation.Lumbosacral_Junction}
                selected={ir1Left === "3"}
                onChange={ir1ChangeL}
                styleClass="custom_radio_box large"
                value="3"
                id="ir1Left3"
                name="ir1Left"
              />
              <RadioButton
                label={translation.Waist_L3}
                selected={ir1Left === "4"}
                onChange={ir1ChangeL}
                styleClass="custom_radio_box large"
                value="4"
                id="ir1Left4"
                name="ir1Left"
              />
              <RadioButton
                label={translation.T12_Vertebra}
                selected={ir1Left === "5"}
                onChange={ir1ChangeL}
                styleClass="custom_radio_box large"
                value="5"
                id="ir1Left5"
                name="ir1Left"
              />
              <RadioButton
                label={translation.Interscapular_T7}
                selected={ir1Left === "6"}
                onChange={ir1ChangeL}
                styleClass="custom_radio_box large"
                value="6"
                id="ir1Left6"
                name="ir1Left"
              />
              {ir1Left == "" && validatorArmL == true && (
                <span className="text-danger">This field is required</span>
              )}
            </div>
          </div>
        </div>
        <div className="row mb_30">
          <div className="col-md-6 col-sm-6">
            <AssessmentDegreeWidget
              valueR={ir2RightDegreeR}
              valueL={ir2RightDegreeL}
              title={"Active Internal Rotation at 90 Abduction (IR2)"}
              onChangeR={ir2RightDegreeChangeR}
              onChangeL={ir2RightDegreeChangeL}
              labelL="Left"
              labelR="Right"
              nameR="Internal Rotation (IR2) Right"
              nameL="Internal Rotation (IR2) Left"
            />
          </div>
          <div className="col-md-6 col-sm-6">
            <AssessmentDegreeWidget
              valueR={ir2PaRightDegreeR}
              valueL={ir2PaRightDegreeL}
              title={"Passive Internal Rotation at 90 Abduction (IR2)"}
              onChangeR={ir2PaRightDegreeChangeR}
              onChangeL={ir2PaRightDegreeChangeL}
              labelL="Left"
              labelR="Right"
              nameR="Internal Rotation (IR2) Right"
              nameL="Internal Rotation (IR2) Left"
            />
          </div>
        </div>
        <div className="row mb_30">
          <div className="col-md-12 col-sm-10">
            <h6 className="label_title">{translation.Note}</h6>
            <InputField
              label=""
              value={note}
              type="textarea"
              placeholder="Type notes here "
              rows="4"
              cols="20"
              styleClass="h_150"
              onChange={updateNote}
              maxLength={500}
            // validators={[

            //   {
            //     check: Validators.maxTextArea,
            //     message: "Only 500 Character Allow",
            //   },

            // ]}
            />
            <p className="char_limit_txt">
              <span id="task-mainChars">{500 - note.length}</span>{" "}
              {translation.characters_remaining}
            </p>
          </div>
        </div>
        {doctorEnable !== "true" && (
          <div className="row mb_30 button_box">
            <div className="col-md-6 col-sm-6">
              {/* <Link to="/patient-list" className="assm_back_btn">
            <img src={backIcon} alt="" />
            <span>Back</span>
          </Link> */}
            </div>
            <div className="col-md-6 col-sm-6 text-end">
              <button className="next_btn btn_fill" onClick={continueRedirect}>
                {translation.next_button}
                <span>
                  <img src={nextIcon} alt="" />
                  <img src={nextWhiteIcon} alt="" />
                </span>
              </button>
            </div>
          </div>
        )}
        {doctorEnable === "true" && (
          <div className="row mb_30 button_box">
            <div className="col-md-6 col-sm-6">
            </div>
            <div className="col-md-6 col-sm-6 text-end">
                    <button
                      className="next_btn btn_fill me-3"
                      onClick={() => handleNext()}
                    >
                      {translation.next_button}
                      <span>
                        <img src={nextIcon} alt="" />
                        {/* <img src={nextWhiteIcon} alt="" /> */}
                      </span>
                    </button>
                    <button
                      className="next_btn btn_fill"
                      onClick={() => continueRedirecttrue()}
                    >
                      {translation.submit}
                      <span>
                        <img src={nextIcon} alt="" />
                        {/* <img src={nextWhiteIcon} alt="" /> */}
                      </span>
                    </button>
                  </div>
          </div>
        )}
      </div>
    </>
    )}
  </>
  );
}

export default PatientAssessmentStep1;
