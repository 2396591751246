import React from "react";
import "../../../css/ViewTreatmentForm.css";
import useTranslation from "../../customHooks/translations";

export default function PediaKneeFunction({
  pediaFunction,
  AllData,
  adultNorwich,
}) {
  const translations = useTranslation();
  
  return (
    <>
      <div className="whitebox padding-none">
        <div className="treatment-content">
          <div className="treatment-row-header">
            3. {translations.patient_pedia_knee_function}
          </div>
          <div className="treatment-row">
            <div className="treatment-table">
              <table cellpadding="0" cellspacing="0">
                <thead>
                  <tr>
                    <th></th>
                    <th>{translations.Right}</th>
                    <th>{translations.Left}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{translations.patient_pedia_knee_function_q1} :</td>
                    <td>{pediaFunction.goUpStairsR}</td>
                    <td>{pediaFunction.goUpStairsL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_pedia_knee_function_q2} :</td>
                    <td>{pediaFunction.goDownStairsR}</td>
                    <td>{pediaFunction.goDownStairsL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_pedia_knee_function_q3} :</td>
                    <td>{pediaFunction.kneelFrontR}</td>
                    <td>{pediaFunction.kneelFrontL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_pedia_knee_function_q4} :</td>
                    <td>{pediaFunction.straighteningR}</td>
                    <td>{pediaFunction.straighteningL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_pedia_knee_function_q5} :</td>
                    <td>{pediaFunction.bendingKneeR}</td>
                    <td>{pediaFunction.bendingKneeL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_pedia_knee_function_q6} :</td>
                    <td>{pediaFunction.TwistDurPlayInMorningtR}</td>
                    <td>{pediaFunction.TwistDurPlayInMorningtL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_pedia_knee_function_q7} :</td>
                    <td>{pediaFunction.moveDayAfterR}</td>
                    <td>{pediaFunction.moveDayAfterL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_pedia_knee_function_q8} :</td>
                    <td>{pediaFunction.squatBasketballR}</td>
                    <td>{pediaFunction.squatBasketballL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_pedia_knee_function_q9} :</td>
                    <td>{pediaFunction.sitOnChairWithKneeBentR}</td>
                    <td>{pediaFunction.sitOnChairWithKneeBentL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_pedia_knee_function_q10} :</td>
                    <td>{pediaFunction.getUpFromChairR}</td>
                    <td>{pediaFunction.getUpFromChairL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_pedia_knee_function_q11} :</td>
                    <td>{pediaFunction.bendingToFloorR}</td>
                    <td>{pediaFunction.bendingToFloorL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_pedia_knee_function_q12} :</td>
                    <td>{pediaFunction.runDurPlayR}</td>
                    <td>{pediaFunction.runDurPlayL}</td>
                  </tr>

                  <tr>
                    <td>{translations.patient_pedia_knee_function_q13} :</td>
                    <td>{pediaFunction.jumpDurPlayR}</td>
                    <td>{pediaFunction.jumpDurPlayL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_pedia_knee_function_q14} :</td>
                    <td>{pediaFunction.startAndStopMoveR}</td>
                    <td>{pediaFunction.startAndStopMoveL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_pedia_knee_function_q15} :</td>
                    <td>{pediaFunction.twistDurPlayR}</td>
                    <td>{pediaFunction.twistDurPlayL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_pedia_knee_function_q16} :</td>
                    <td>{pediaFunction.keepBalWalkR}</td>
                    <td>{pediaFunction.keepBalWalkL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_pedia_knee_function_q17} :</td>
                    <td>{pediaFunction.playSportsR}</td>
                    <td>{pediaFunction.playSportsL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_pedia_knee_function_q18} :</td>
                    <td>{pediaFunction.getOutCarR}</td>
                    <td>{pediaFunction.getOutCarL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_pedia_knee_function_q19} :</td>
                    <td>{pediaFunction.getOutBedR}</td>
                    <td>{pediaFunction.getOutBedL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_pedia_knee_function_q20} :</td>
                    <td>{pediaFunction.changePositionLyingR}</td>
                    <td>{pediaFunction.changePositionLyingL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_pedia_knee_function_q21} :</td>
                    <td>{pediaFunction.gettingBathR}</td>
                    <td>{pediaFunction.gettingBathL}</td>
                  </tr>

                  <tr>
                    <td>{translations.patient_pedia_knee_function_q22} :</td>
                    <td>{pediaFunction.carryHeavyBagsR}</td>
                    <td>{pediaFunction.carryHeavyBagsL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_pedia_knee_function_q23} :</td>
                    <td>{pediaFunction.doLightChoresR}</td>
                    <td>{pediaFunction.doLightChoresL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_pedia_knee_function_q24} :</td>
                    <td>{pediaFunction.gettingToSchoolR}</td>
                    <td>{pediaFunction.gettingToSchoolL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_pedia_knee_function_q25} :</td>
                    <td>{pediaFunction.doThingsFriendsR}</td>
                    <td>{pediaFunction.doThingsFriendsL}</td>
                  </tr>

                  {(AllData.initial_assessment === 1 || String(AllData.initial_assessment) === "undefined") &&(

                    <>
                      <tr>
                        <td>
                          {translations.patient_pedia_knee_function_q26} :
                        </td>
                        <td>{pediaFunction.howKneeWork_beforeR}</td>
                        <td>{pediaFunction.howKneeWork_beforeL}</td>
                      </tr>
                     
                    </>
                  )}
                
                    <tr>
                      <td>{translations.patient_pedia_knee_function_q27} :</td>
                      <td>{pediaFunction.howKneeWorkR}</td>
                      <td>{pediaFunction.howKneeWorkL}</td>
                    </tr>
                 
                </tbody>
              </table>
            </div>
          </div>

          <div className="">
            <div className="treatment-row">
              <div className="treatment-table">
                <table cellpadding="0" cellspacing="0">
                  <tbody>
                    <tr>
                      <td>{translations.patient_pedia_knee_function_q28} :</td>
                      <td>
                        {pediaFunction.patellarInstability === "0"
                          ? translations.patient_pedia_knee_function_q28_a1
                          : pediaFunction.patellarInstability === "1"
                          ? translations.patient_pedia_knee_function_q28_a2
                          : ""}
                      </td>
                      <td>
                        {pediaFunction.patellarInstabilityLeft === "0"
                          ? translations.patient_pedia_knee_function_q28_a1
                          : pediaFunction.patellarInstabilityLeft === "1"
                          ? translations.patient_pedia_knee_function_q28_a2
                          : ""}
                      </td>
                    </tr>
                  </tbody>
                </table>
                {/* <label>Diagnosis of Patellar Instability:</label>
                            <p>{pediaFunction.patellarInstability === "0" ? "No" : pediaFunction.patellarInstability === "1" ? "Yes" : ""}</p> */}
              </div>
            </div>
          </div>

          {pediaFunction.patellarInstability === "1" ||
          pediaFunction.patellarInstabilityLeft === "1" ? (
            <>
              <div className="treatment-row-header bd-none mb-0">
                {translations.patient_pedia_knee_instability}
              </div>
              <div className="treatment-row">
                <div className="treatment-table ">
                  <table cellspacing="0" cellpadding="0">
                    <thead>
                      <tr>
                        <th></th>
                        <th>{translations.Right}</th>
                        <th>{translations.Left}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {translations.patient_pedia_knee_instability_q1} :
                        </td>
                        {adultNorwich.twcheck && (
                          <>
                            <td>
                              {
                                translations.patient_pedia_knee_instability_q1_do_not_do
                              }
                            </td>
                            <td>
                              {
                                translations.patient_pedia_knee_instability_q1_do_not_do
                              }
                            </td>
                          </>
                        )}
                        {!adultNorwich.twcheck && (
                          <>
                            <td> {adultNorwich.twistingR}</td>
                            <td> {adultNorwich.twistingL}</td>
                          </>
                        )}
                      </tr>

                      <tr>
                        <td>
                          {translations.patient_pedia_knee_instability_q2} :
                        </td>
                        {adultNorwich.directioncheck && (
                          <>
                            <td>
                              {
                                translations.patient_pedia_knee_instability_q2_do_not_do
                              }
                            </td>
                            <td>
                              {
                                translations.patient_pedia_knee_instability_q2_do_not_do
                              }
                            </td>
                          </>
                        )}
                        {!adultNorwich.directioncheck && (
                          <>
                            <td> {adultNorwich.changeDirectionR}</td>
                            <td> {adultNorwich.changeDirectionL}</td>
                          </>
                        )}
                      </tr>

                      <tr>
                        <td>
                          {translations.patient_pedia_knee_instability_q3} :
                        </td>
                        {adultNorwich.straightcheck && (
                          <>
                            <td>
                              {
                                translations.patient_pedia_knee_instability_q3_do_not_do
                              }
                            </td>
                            <td>
                              {
                                translations.patient_pedia_knee_instability_q3_do_not_do
                              }
                            </td>
                          </>
                        )}
                        {!adultNorwich.straightcheck && (
                          <>
                            <td> {adultNorwich.runningStraightlineR}</td>
                            <td> {adultNorwich.runningStraightlineL}</td>
                          </>
                        )}
                      </tr>

                      <tr>
                        <td>
                          {translations.patient_pedia_knee_instability_q4} :
                        </td>
                        {adultNorwich.slipperycheck && (
                          <>
                            <td>
                              {
                                translations.patient_pedia_knee_instability_q4_do_not_do
                              }
                            </td>
                            <td>
                              {
                                translations.patient_pedia_knee_instability_q4_do_not_do
                              }
                            </td>
                          </>
                        )}
                        {!adultNorwich.slipperycheck && (
                          <>
                            <td> {adultNorwich.walkingSlipperyR}</td>
                            <td> {adultNorwich.walkingSlipperyL}</td>
                          </>
                        )}
                      </tr>

                      <tr>
                        <td>
                          {translations.patient_pedia_knee_instability_q5} :
                        </td>
                        {adultNorwich.sidewayscheck && (
                          <>
                            <td>
                              {
                                translations.patient_pedia_knee_instability_q5_do_not_do
                              }
                            </td>
                            <td>
                              {
                                translations.patient_pedia_knee_instability_q5_do_not_do
                              }
                            </td>
                          </>
                        )}
                        {!adultNorwich.sidewayscheck && (
                          <>
                            <td> {adultNorwich.runningSidewaysR}</td>
                            <td> {adultNorwich.runningSidewaysL}</td>
                          </>
                        )}
                      </tr>

                      <tr>
                        <td>
                          {translations.patient_pedia_knee_instability_q6} :
                        </td>
                        {adultNorwich.Hoppingcheck && (
                          <>
                            <td>
                              {
                                translations.patient_pedia_knee_instability_q6_do_not_do
                              }
                            </td>
                            <td>
                              {
                                translations.patient_pedia_knee_instability_q6_do_not_do
                              }
                            </td>
                          </>
                        )}
                        {!adultNorwich.Hoppingcheck && (
                          <>
                            <td> {adultNorwich.hoppingR}</td>
                            <td> {adultNorwich.hoppingL}</td>
                          </>
                        )}
                      </tr>

                      <tr>
                        <td>
                          {translations.patient_pedia_knee_instability_q7} :
                        </td>
                        {adultNorwich.Jumpingcheck && (
                          <>
                            <td>
                              {
                                translations.patient_pedia_knee_instability_q7_do_not_do
                              }
                            </td>
                            <td>
                              {
                                translations.patient_pedia_knee_instability_q7_do_not_do
                              }
                            </td>
                          </>
                        )}
                        {!adultNorwich.Jumpingcheck && (
                          <>
                            <td> {adultNorwich.jumpingR}</td>
                            <td> {adultNorwich.jumpingL}</td>
                          </>
                        )}
                      </tr>

                      <tr>
                        <td>
                          {translations.patient_pedia_knee_instability_q8} :
                        </td>
                        {adultNorwich.scaleLabelcheck && (
                          <>
                            <td>
                              {
                                translations.patient_pedia_knee_instability_q8_do_not_do
                              }
                            </td>
                            <td>
                              {
                                translations.patient_pedia_knee_instability_q8_do_not_do
                              }
                            </td>
                          </>
                        )}
                        {!adultNorwich.scaleLabelcheck && (
                          <>
                            <td> {adultNorwich.runningOnEvenSurfaceR}</td>
                            <td> {adultNorwich.runningOnEvenSurfaceL}</td>
                          </>
                        )}
                      </tr>

                      <tr>
                        <td>
                          {translations.patient_pedia_knee_instability_q9} :
                        </td>
                        {adultNorwich.downcheck && (
                          <>
                            <td>
                              {
                                translations.patient_pedia_knee_instability_q9_do_not_do
                              }
                            </td>
                            <td>
                              {
                                translations.patient_pedia_knee_instability_q9_do_not_do
                              }
                            </td>
                          </>
                        )}
                        {!adultNorwich.downcheck && (
                          <>
                            <td> {adultNorwich.goingDownStairsR}</td>
                            <td> {adultNorwich.goingDownStairsL}</td>
                          </>
                        )}
                      </tr>

                      <tr>
                        <td>
                          {translations.patient_pedia_knee_instability_q10} :
                        </td>
                        {adultNorwich.Squattingcheck && (
                          <>
                            <td>
                              {
                                translations.patient_pedia_knee_instability_q10_do_not_do
                              }
                            </td>
                            <td>
                              {
                                translations.patient_pedia_knee_instability_q10_do_not_do
                              }
                            </td>
                          </>
                        )}
                        {!adultNorwich.Squattingcheck && (
                          <>
                            <td> {adultNorwich.squattingR}</td>
                            <td> {adultNorwich.squattingL}</td>
                          </>
                        )}
                      </tr>

                      <tr>
                        <td>
                          {translations.patient_pedia_knee_instability_q11} :
                        </td>
                        {adultNorwich.Kneelingcheck && (
                          <>
                            <td>
                              {
                                translations.patient_pedia_knee_instability_q11_do_not_do
                              }
                            </td>
                            <td>
                              {
                                translations.patient_pedia_knee_instability_q11_do_not_do
                              }
                            </td>
                          </>
                        )}
                        {!adultNorwich.Kneelingcheck && (
                          <>
                            <td> {adultNorwich.kneelingR}</td>
                            <td> {adultNorwich.kneelingL}</td>
                          </>
                        )}
                      </tr>

                      <tr>
                        <td>
                          {translations.patient_pedia_knee_instability_q12} :
                        </td>
                        {adultNorwich.Surfacescheck && (
                          <>
                            <td>
                              {
                                translations.patient_pedia_knee_instability_q12_do_not_do
                              }
                            </td>
                            <td>
                              {
                                translations.patient_pedia_knee_instability_q12_do_not_do
                              }
                            </td>
                          </>
                        )}
                        {!adultNorwich.Surfacescheck && (
                          <>
                            <td> {adultNorwich.walkingUnevenSurfacesR}</td>
                            <td> {adultNorwich.walkingUnevenSurfacesL}</td>
                          </>
                        )}
                      </tr>

                      <tr>
                        <td>
                          {translations.patient_pedia_knee_instability_q13} :
                        </td>
                        {adultNorwich.Climbingcheck && (
                          <>
                            <td>
                              {
                                translations.patient_pedia_knee_instability_q13_do_not_do
                              }
                            </td>
                            <td>
                              {
                                translations.patient_pedia_knee_instability_q13_do_not_do
                              }
                            </td>
                          </>
                        )}
                        {!adultNorwich.Climbingcheck && (
                          <>
                            <td> {adultNorwich.climbingStairsR}</td>
                            <td> {adultNorwich.climbingStairsL}</td>
                          </>
                        )}
                      </tr>

                      <tr>
                        <td>
                          {translations.patient_pedia_knee_instability_q14} :
                        </td>
                        {adultNorwich.Steppingcheck && (
                          <>
                            <td>
                              {
                                translations.patient_pedia_knee_instability_q14_do_not_do
                              }
                            </td>
                            <td>
                              {
                                translations.patient_pedia_knee_instability_q14_do_not_do
                              }
                            </td>
                          </>
                        )}
                        {!adultNorwich.Steppingcheck && (
                          <>
                            <td> {adultNorwich.steppingOverHighR}</td>
                            <td> {adultNorwich.steppingOverHighL}</td>
                          </>
                        )}
                      </tr>

                      <tr>
                        <td>
                          {translations.patient_pedia_knee_instability_q15} :
                        </td>
                        {adultNorwich.Crossingcheck && (
                          <>
                            <td>
                              {
                                translations.patient_pedia_knee_instability_q15_do_not_do
                              }
                            </td>
                            <td>
                              {
                                translations.patient_pedia_knee_instability_q15_do_not_do
                              }
                            </td>
                          </>
                        )}
                        {!adultNorwich.Crossingcheck && (
                          <>
                            <td> {adultNorwich.crossingWhenSittingR}</td>
                            <td> {adultNorwich.crossingWhenSittingL}</td>
                          </>
                        )}
                      </tr>

                      <tr>
                        <td>
                          {translations.patient_pedia_knee_instability_q16} :
                        </td>
                        {adultNorwich.walkingEvencheck && (
                          <>
                            <td>
                              {
                                translations.patient_pedia_knee_instability_q16_do_not_do
                              }
                            </td>
                            <td>
                              {
                                translations.patient_pedia_knee_instability_q16_do_not_do
                              }
                            </td>
                          </>
                        )}
                        {!adultNorwich.walkingEvencheck && (
                          <>
                            <td> {adultNorwich.walkingEvenSurfaceR}</td>
                            <td> {adultNorwich.walkingEvenSurfaceL}</td>
                          </>
                        )}
                      </tr>

                      <tr>
                        <td>
                          {translations.patient_pedia_knee_instability_q17} :
                        </td>
                        {adultNorwich.IntoCarcheck && (
                          <>
                            <td>
                              {
                                translations.patient_pedia_knee_instability_q17_do_not_do
                              }
                            </td>
                            <td>
                              {
                                translations.patient_pedia_knee_instability_q17_do_not_do
                              }
                            </td>
                          </>
                        )}
                        {!adultNorwich.IntoCarcheck && (
                          <>
                            <td> {adultNorwich.gettingIntoCarR}</td>
                            <td> {adultNorwich.gettingIntoCarL}</td>
                          </>
                        )}
                      </tr>

                      <tr>
                        <td>
                          {translations.patient_pedia_knee_instability_q18} :
                        </td>
                        {adultNorwich.heavycheck && (
                          <>
                            <td>
                              {
                                translations.patient_pedia_knee_instability_q18_do_not_do
                              }
                            </td>
                            <td>
                              {
                                translations.patient_pedia_knee_instability_q18_do_not_do
                              }
                            </td>
                          </>
                        )}
                        {!adultNorwich.heavycheck && (
                          <>
                            <td> {adultNorwich.supermarketAisleR}</td>
                            <td> {adultNorwich.supermarketAisleL}</td>
                          </>
                        )}
                      </tr>

                      <tr>
                        <td>
                          {translations.patient_pedia_knee_instability_q19}:
                        </td>
                        {adultNorwich.turningcheck && (
                          <>
                            <td>
                              {
                                translations.patient_pedia_knee_instability_q19_do_not_do
                              }
                            </td>
                            <td>
                              {
                                translations.patient_pedia_knee_instability_q19_do_not_do
                              }
                            </td>
                          </>
                        )}
                        {!adultNorwich.turningcheck && (
                          <>
                            <td> {adultNorwich.turningYourShoulderR}</td>
                            <td> {adultNorwich.turningYourShoulderL}</td>
                          </>
                        )}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
}
