import React, { useState, useEffect,useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import backIcon from "../../../../images/back.png";
import nextWhiteIcon from "../../../../images/next-white.png";
import nextIcon from "../../../../images/next.png";
import LoadingSpinner from "../../../custom/loader/LoadingSpinner";
import InputField from "../../../custom/inputfield/InputField";
import Dropdown from "../../../custom/dropdown/Dropdown";
import { Validators } from "../../../../utilities/Validator";
// import RadioSwitch from "../../../custom/radio-switch/RadioSwitch";
import { useDispatch,useSelector } from "react-redux";
import useTranslation from "../../../customHooks/translations"

import { updateFootAreafootankle } from "../../../../redux/actions/footAnkle";

export default function FootPersonalData() {

  const translation = useTranslation()
  const dispatch = useDispatch();
  const footAnkleArea = useSelector(state => state.footAnkle.footAnkleArea);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [sex, setSex] = useState("");
  const [area, setArea] = useState(footAnkleArea);
  const areaRef = useRef();

  const continueRedirect = (e) => {
    if(!area){
        areaRef.current.errUpdate()
    }else{

        navigate("/foot-ankle-pain");
    }
    
  };
  const handleFirstName = (e) => {
    setFirstName(e);
  };
  const handleLastName = (e) => {
    setLastName(e);
  };
  const handleSex = (e) => {
    setSex(e);
  };
  const handleEmail = (e) => {
    setEmail(e);
  };

  const handleAffected = (e) => {
    setArea(e);
    dispatch(updateFootAreafootankle(e));
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="pain_screen mb_100">
            <div className="panel">
              <div className="panel-body p_25">
                <h4 className="ph_title f_40">{translation.Personal_Data}</h4>
                <div className="">
                  <div className="panel">
                    <form>
                      <div className="panel-body p_25">
                        <div className="row">
                          <div className="col-sm-5 col-md-5 mb_20">
                            <InputField
                              label={translation.First_Name}
                              value={firstName}
                              name="firstName"
                              type="text"
                              placeholder={translation.First_Name}
                              onChange={handleFirstName}
                              validators={[
                                {
                                  check: Validators.required,
                                  message: "This field is required",
                                },
                              ]}
                              styleClass="br_10 mxw_100"
                            />
                          </div>
                          <div className="col-sm-5 col-md-5 mb_20">
                            <InputField
                              label={translation.Last_Name}
                              value={lastName}
                              type="text"
                              placeholder={translation.Last_Name}
                              onChange={handleLastName}
                              styleClass="br_10 mxw_100"
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-5 col-md-5 mb_20">
                            <Form.Group className="mb-3" controlId="fromDate">
                              <Form.Label>{translation.Date_of_Birth}</Form.Label>
                              <DatePicker
                                className="form-control mb_0 br_10"
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                validators={[
                                  {
                                    check: Validators.required,
                                    message: "This field is required",
                                  },
                                ]}
                                styleClass="br_10 mxw_100"
                              />
                            </Form.Group>
                          </div>
                          <div className="col-sm-5 col-md-5 mb_20">
                            <Dropdown
                              data={[
                                { value: "0", label: "Select" },
                                { value: "male", label: "Male" },
                                { value: "female", label: "Female" },
                              ]}
                              label={translation.Sex}
                              value={sex}
                              onChange={handleSex}
                              validators={[
                                {
                                  check: Validators.required,
                                  message: "This field is required",
                                },
                              ]}
                              styleClass="br_10 mxw_100"
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-10 col-md-10 mb_20">
                            <InputField
                              label={translation.Email}
                              value={email}
                              type="text"
                              placeholder="your-email@gmail.com"
                              onChange={handleEmail}
                              styleClass="br_10 mxw_100"
                              validators={[
                                {
                                  check: Validators.required,
                                  message: "This field is required",
                                },
                              ]}
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-sm-5 col-md-5 mb_20">
                            <Dropdown
                              data={[
                                {
                                  value: "",
                                  label: "Area of foot/ankle affected?",
                                },
                                { value: "1", label: "Big toe or lesser toes" },
                                { value: "2", label: "Midfoot" },
                                { value: "3", label: "Hindfoot/ankle" },
                              ]}
                              label={translation.Area_of_foot_ankle}
                              value={area}
                              ref={areaRef}
                              onChange={handleAffected}
                              validators={[
                                {
                                  check: Validators.required,
                                  message: "This field is required",
                                },
                              ]}
                              styleClass="br_10 mxw_100"
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6 col-6">
                    <Link to="/" className="assm_back_btn">
                      <img src={backIcon} alt="" />
                      <span>{translation.back_button}</span>
                    </Link>
                  </div>
                  <div className="col-md-6 col-sm-6 col-6 text-end">
                    <button
                      className="next_btn btn_fill"
                      onClick={continueRedirect}
                    >
                      {translation.next_button}
                      <span>
                        <img src={nextIcon} alt="" />
                        <img src={nextWhiteIcon} alt="" />
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
