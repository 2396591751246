import React, { useMemo, useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import LoadingSpinner from "../../../../custom/loader/LoadingSpinner";
import ResultCircleProgress from "../../../../custom/result-circle-progress/ResultCircleProgress";
import Button from "../../../../custom/button/Button";
import { useSelector, useDispatch } from "react-redux";
import useAxios from "../../../../../axiosinstance";
import Share from "../../../Share";
import {
  updatePediaTotalScores,
  updatePediaScores,
  updatePediaSatisfactionScore,
  updatePediaPainData,
  updatePediaSymtomsScore,
  updatePediaFunctionScore,
  updatePediaQolScore,
  updatePediaKneeNorwichInstaScore,
  updatePediaKneeFeedbackScores,
} from "../../../../../redux/actions/pediaKnee";
import useTransition from "../../../../customHooks/translations";
import { updateAdultKneeFeedback } from "../../../../../redux/actions/index";
import NameCustom from "../../../../custom/name/NameCustom";
import SubmitModel from "../../../SubmissionModel/SubmitModel"
function PediaKneeResult() {
  const translation = useTransition();
  const navigate = useNavigate();
  const evlPediaPain = useSelector((state) => state.pediaKnee.evlPediaPain);
  const dispatch = useDispatch();
  let type = 20;
  const { rId } = useParams();
  const axiosInstance = useAxios();
  const [isLoading, setIsLoading] = useState(true);
  const [chk, setChk] = useState(false);
  const [totalScore] = useSelector((state) => state.pediaKnee.TotalScores);
  const [status, setStatus] = useState("");

  const [isdoctorform, setisdoctorform] = useState(Number());
  const save = () => {
    axiosInstance.current
      .get(`extapp/forms/all_forms?resource_id=${rId}&type=20`)
      .then((res) => {
        dispatch(updatePediaScores(res.data.data2[0].evlPediaPain));
        dispatch(updatePediaPainData(res.data.data2[0].painScore));
        dispatch(updatePediaSymtomsScore(res.data.data2[0].symtomsScore));
        dispatch(updatePediaFunctionScore(res.data.data2[0].functionScore));
        dispatch(updatePediaQolScore(res.data.data2[0].qolScore));
        dispatch(
          updatePediaSatisfactionScore(res.data.data2[0].satisfactionScore)
        );
        dispatch(
          updatePediaKneeNorwichInstaScore(res.data.data2[0].norwichInstability)
        );
        dispatch(updateAdultKneeFeedback(res.data.data2[0].doctorFeedback));
        dispatch(updatePediaTotalScores(res.data.data2[0].TotalScores));
        setChk(true);
        setisdoctorform(res.data.data2[0].is_doctor_form);
        setStatus(res.data.data2[0].status);
      })
      .catch((err) => {
        navigate("/un-authorized-access");
      });
  };

  useEffect(() => {
    save();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  const continueRedirect2 = (e) => {
    setDisplayConfirmationModal(true)
    //   window.location.href = process.env.REACT_APP_DOCTOR_URL + "patient-list";
    // } else {
    //   window.location.href = process.env.REACT_APP_PATIENT_URL;
    // }
  };

  // const continueRedirect2 = (e) => {
  //   window.open(process.env.REACT_APP_PATIENT_URL, "_blank");

  // };

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="result_screen mb_100">
          <div className="row justify-content-center">
            <div className="col-xxl-8 col-lg-10 col-md-12 col-sm-12">
              <div className="panel">
                <div className="panel-body p_25">
                  <div className="row">
                    <div className="col-xxl-8 col-lg-7 col-md-6 col-sm-6">
                      <h3 className="text-start f_22 ">
                        {translation.patient_pedia_knee_results}
                      </h3>
                      <p className="answer_txt">
                        {translation.patient_pedia_knee_results_text1}
                      </p>
                    </div>
                    <div className="col-xxl-4 col-lg-5 col-md-6 col-sm-6">
                      <NameCustom />
                    </div>
                  </div>
                  <div className="row">
                    {status === "1" ? (
                      <div className="pb_50 text-center">
                      
                        <Button
                          onClick={continueRedirect2}
                          value={translation.Sign_out}
                          buttonStyle="btn_fill result_submit_btn"
                        />

                      </div>
                    ) : status === "2" ? (
                      <div className="pb_50 text-center">
                       
                        <Share type={type} rId={rId} />
                      </div>
                    ) : null}
                  </div>

                  <div className="row result_scorebox">

                    {evlPediaPain === "2" || evlPediaPain === "3" ? (
                      <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                        <ResultCircleProgress
                          min_score={0}
                          reverse={false}
                          title={translation.patient_pedia_knee_results_function}
                          type={translation.patient_pedia_knee_results_self_evaluation}
                          arm={translation.Left_Pedi_Knee}
                          value={totalScore.IKDCTL}
                          total="100"
                          date="22/09/2022"
                          color="#ff0000"
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    {evlPediaPain === "1" || evlPediaPain === "3" ? (
                      <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                        <ResultCircleProgress
                          min_score={0}
                          reverse={false}
                          title={translation.patient_pedia_knee_results_function}
                          type={translation.patient_pedia_knee_results_self_evaluation}
                          arm={translation.Right_Pedi_Knee}
                          value={totalScore.IKDCTR}
                          total="100"
                          date="22/09/2022"
                          color="#ff0000"
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    {evlPediaPain === "1" || evlPediaPain === "3" ? (
                      <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                        <ResultCircleProgress
                          min_score={0}
                          reverse={false}
                          title={translation.Pain_Subscale}
                          type={translation.patient_pedia_knee_results_self_evaluation}
                          arm={translation.Right_Pedi_Knee}
                          value={totalScore.painSubScaleTR}
                          total="100"
                          date="22/09/2022"
                          color="#78b776"
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    {evlPediaPain === "2" || evlPediaPain === "3" ? (
                      <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                        <ResultCircleProgress
                          min_score={0}
                          reverse={false}
                          title={translation.Pain_Subscale}
                          type={translation.patient_pedia_knee_results_self_evaluation}
                          arm={translation.Left_Pedi_Knee}
                          value={totalScore.painSubScaleTL}
                          total="100"
                          date="22/09/2022"
                          color="#78b776"
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    {evlPediaPain === "1" || evlPediaPain === "3" ? (
                      <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                        <ResultCircleProgress
                          min_score={0}
                          reverse={false}
                          title={translation.Symptoms_Subscale}
                          type={translation.patient_pedia_knee_results_self_evaluation}
                          arm={translation.Right_Pedi_Knee}
                          value={totalScore.symptomsSubscaleTR}
                          total="100"
                          date="22/09/2022"
                          color="#006DFF"
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    {evlPediaPain === "2" || evlPediaPain === "3" ? (
                      <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                        <ResultCircleProgress
                          min_score={0}
                          reverse={false}
                          title={translation.Symptoms_Subscale}
                          type={translation.patient_pedia_knee_results_self_evaluation}
                          arm={translation.Left_Pedi_Knee}
                          value={totalScore.symptomsSubscaleTL}
                          total="100"
                          date="22/09/2022"
                          color="#006DFF"
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    {evlPediaPain === "1" || evlPediaPain === "3" ? (
                      <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                        <ResultCircleProgress
                          min_score={0}
                          reverse={false}
                          title={translation.KOOS_C_ADL}
                          type={translation.patient_pedia_knee_results_self_evaluation}
                          arm={translation.Right_Pedi_Knee}
                          value={totalScore.koosAdlTR}
                          total="100"
                          date="22/09/2022"
                          color="#006DFF"
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    {evlPediaPain === "2" || evlPediaPain === "3" ? (
                      <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                        <ResultCircleProgress
                          min_score={0}
                          reverse={false}
                          title={translation.KOOS_C_ADL}
                          type={translation.patient_pedia_knee_results_self_evaluation}
                          arm={translation.Left_Pedi_Knee}
                          value={totalScore.koosAdlTL}
                          total="100"
                          date="22/09/2022"
                          color="#006DFF"
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    {evlPediaPain === "1" || evlPediaPain === "3" ? (
                      <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                        <ResultCircleProgress
                          min_score={0}
                          reverse={false}
                          title={translation.koosPlay}
                          type={translation.patient_pedia_knee_results_self_evaluation}
                          arm={translation.Right_Pedi_Knee}
                          value={totalScore.koosPlayTR}
                          total="100"
                          date="22/09/2022"
                          color="#ff0000"
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    {evlPediaPain === "2" || evlPediaPain === "3" ? (
                      <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                        <ResultCircleProgress
                          min_score={0}
                          reverse={false}
                          title={translation.koosPlay}
                          type={translation.patient_pedia_knee_results_self_evaluation}
                          arm={translation.Left_Pedi_Knee}
                          value={totalScore.koosPlayTL}
                          total="100"
                          date="22/09/2022"
                          color="#ff0000"
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    {evlPediaPain === "1" || evlPediaPain === "3" ? (
                      <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                        <ResultCircleProgress
                          min_score={0}
                          reverse={false}
                          title={translation.KOOS_C_QOL}
                          type={translation.patient_pedia_knee_results_self_evaluation}
                          arm={translation.Right_Pedi_Knee}
                          value={totalScore.koosQOLTR}
                          total="100"
                          date="22/09/2022"
                          color="#78b776"
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    {evlPediaPain === "2" || evlPediaPain === "3" ? (
                      <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                        <ResultCircleProgress
                          min_score={0}
                          reverse={false}
                          title={translation.KOOS_C_QOL}
                          type={translation.patient_pedia_knee_results_self_evaluation}
                          arm={translation.Left_Pedi_Knee}
                          value={totalScore.koosQOLTL}
                          total="100"
                          date="22/09/2022"
                          color="#78b776"
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    {evlPediaPain === "2" || evlPediaPain === "3" ? (
                      <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                        <ResultCircleProgress
                          min_score={0}
                          reverse={false}
                          title={translation.patient_pedia_knee_results_pain}
                          type={translation.patient_pedia_knee_results_self_evaluation}
                          arm={translation.Left_Pedi_Knee}
                          persent={totalScore.vasL * 10}
                          value={totalScore.vasL}
                          total="10"
                          date="22/09/2022"
                          color="#006DFF"
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    {evlPediaPain === "1" || evlPediaPain === "3" ? (
                      <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                        <ResultCircleProgress
                          min_score={0}
                          reverse={false}
                          title={translation.patient_pedia_knee_results_pain}
                          type={translation.patient_pedia_knee_results_self_evaluation}
                          arm={translation.Right_Pedi_Knee}
                          persent={totalScore.vasR * 10}
                          value={totalScore.vasR}
                          total="10"
                          date="22/09/2022"
                          color="#006DFF"
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    {evlPediaPain === "1" || evlPediaPain === "3" ? (
                      <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                        <ResultCircleProgress
                          min_score={0}
                          reverse={false}
                          title={translation.SANE}
                          type={translation.patient_pedia_knee_results_self_evaluation}
                          arm={translation.Right_Pedi_Knee}
                          value={totalScore.saneR}
                          total="100"
                          date="22/09/2022"
                          color="#006DFF"
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    {evlPediaPain === "2" || evlPediaPain === "3" ? (
                      <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                        <ResultCircleProgress
                          min_score={0}
                          reverse={false}
                          title={translation.SANE}
                          type={translation.patient_pedia_knee_results_self_evaluation}
                          arm={translation.Left_Pedi_Knee}
                          value={totalScore.saneL}
                          total="100"
                          date="22/09/2022"
                          color="#006DFF"
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>


                </div>
              </div>
            </div>
          </div>

        </div>
      )}
      <SubmitModel
        showModal={displayConfirmationModal} patientID={window.btoa(sessionStorage.getItem("patient_akid"))}

      />
    </>
  );
}

export default PediaKneeResult;
