import React from "react";
import "../../../css/ViewTreatmentForm.css";
import useTranslation from "../../customHooks/translations";

export default function HipSatisfaction({ satisfactionData, AllData }) {
  const translations = useTranslation();
  return (
    <>
      <div className="whitebox padding-none">
        <div className="treatment-content">
          <div className="treatment-row-header">
            5. {translations.patient_hip_satisfaction}
          </div>
          <div className="treatment-row">
            <div className="treatment-table">
              <table cellpadding="0" cellspacing="0">
                <thead>
                  <tr>
                    <th></th>
                    {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}

                    <th>{translations.Hip_Function_R}</th>
                    {/* ) : (
                                            ""
                                        )} */}
                    {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}
                    <th>{translations.Hip_Function_L}</th>
                    {/* // ) : (
                                        //     ""
                                        // )} */}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{translations.patient_hip_satisfaction_q1} :</td>
                    {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}

                    <td>
                      {" "}
                      {satisfactionData.overallR === "1"
                        ? translations.patient_hip_satisfaction_q1_a1
                        : satisfactionData.overallR === "2"
                        ? translations.patient_hip_satisfaction_q1_a2
                        : satisfactionData.overallR === "3"
                        ? translations.patient_hip_satisfaction_q1_a3
                        : satisfactionData.overallR === "4"
                        ? translations.patient_hip_satisfaction_q1_a4
                        : ""}
                    </td>
                    {/* // ) : (
                                        //     ""
                                        // )} */}
                    {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}
                    <td>
                      {" "}
                      {satisfactionData.overallL === "1"
                        ? translations.patient_hip_satisfaction_q1_a1
                        : satisfactionData.overallL === "2"
                        ? translations.patient_hip_satisfaction_q1_a2
                        : satisfactionData.overallL === "3"
                        ? translations.patient_hip_satisfaction_q1_a3
                        : satisfactionData.overallL === "4"
                        ? translations.patient_hip_satisfaction_q1_a4
                        : ""}
                    </td>
                    {/* ) : (
                                            ""
                                        )} */}
                  </tr>
                  <tr>
                    <td>{translations.patient_hip_satisfaction_q2} :</td>
                    {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}
                    <td>
                      {" "}
                      {satisfactionData.improvePainR === "1"
                        ? translations.patient_hip_satisfaction_q2_a1
                        : satisfactionData.improvePainR === "2"
                        ? translations.patient_hip_satisfaction_q2_a2
                        : satisfactionData.improvePainR === "3"
                        ? translations.patient_hip_satisfaction_q2_a3
                        : satisfactionData.improvePainR === "4"
                        ? translations.patient_hip_satisfaction_q2_a4
                        : ""}
                    </td>
                    {/* // ) : (
                                        //     ""
                                        // )} */}
                    {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}

                    <td>
                      {" "}
                      {satisfactionData.improvePainL === "1"
                        ? translations.patient_hip_satisfaction_q2_a1
                        : satisfactionData.improvePainL === "2"
                        ? translations.patient_hip_satisfaction_q2_a2
                        : satisfactionData.improvePainL === "3"
                        ? translations.patient_hip_satisfaction_q2_a3
                        : satisfactionData.improvePainL === "4"
                        ? translations.patient_hip_satisfaction_q2_a4
                        : ""}
                    </td>
                    {/* ) : (
                                            ""
                                        )} */}
                  </tr>
                  <tr>
                    <td>{translations.patient_hip_satisfaction_q3} :</td>
                    {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}
                    <td>
                      {" "}
                      {satisfactionData.improveHomeR === "1"
                        ? translations.patient_hip_satisfaction_q3_a1
                        : satisfactionData.improveHomeR === "2"
                        ? translations.patient_hip_satisfaction_q3_a2
                        : satisfactionData.improveHomeR === "3"
                        ? translations.patient_hip_satisfaction_q3_a3
                        : satisfactionData.improveHomeR === "4"
                        ? translations.patient_hip_satisfaction_q3_a4
                        : ""}
                    </td>
                    {/* // ) : (
                                        //     ""
                                        // )} */}
                    {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}

                    <td>
                      {" "}
                      {satisfactionData.improveHomeL === "1"
                        ? translations.patient_hip_satisfaction_q3_a1
                        : satisfactionData.improveHomeL === "2"
                        ? translations.patient_hip_satisfaction_q3_a2
                        : satisfactionData.improveHomeL === "3"
                        ? translations.patient_hip_satisfaction_q3_a3
                        : satisfactionData.improveHomeL === "4"
                        ? translations.patient_hip_satisfaction_q3_a4
                        : ""}
                    </td>
                    {/* ) : (
                                            ""
                                        )} */}
                  </tr>
                  <tr>
                    <td>{translations.patient_hip_satisfaction_q4} :</td>
                    {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}
                    <td>
                      {" "}
                      {satisfactionData.improveRecrR === "1"
                        ? translations.patient_hip_satisfaction_q4_a1
                        : satisfactionData.improveRecrR === "2"
                        ? translations.patient_hip_satisfaction_q4_a2
                        : satisfactionData.improveRecrR === "3"
                        ? translations.patient_hip_satisfaction_q4_a3
                        : satisfactionData.improveRecrR === "4"
                        ? translations.patient_hip_satisfaction_q4_a4
                        : ""}
                    </td>
                    {/* // ) : (
                                        //     ""
                                        // )} */}
                    {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}

                    <td>
                      {" "}
                      {satisfactionData.improveRecrL === "1"
                        ? translations.patient_hip_satisfaction_q4_a1
                        : satisfactionData.improveRecrL === "2"
                        ? translations.patient_hip_satisfaction_q4_a2
                        : satisfactionData.improveRecrL === "3"
                        ? translations.patient_hip_satisfaction_q4_a3
                        : satisfactionData.improveRecrL === "4"
                        ? translations.patient_hip_satisfaction_q4_a4
                        : ""}
                    </td>
                    {/* ) : (
                                            ""
                                        )} */}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
