import React, { useState, useEffect } from "react";
import UramScoreRangeSlider from "./uram-score-range-slider/UramScoreRangeSlider";
import { useDispatch, useSelector } from "react-redux";
import backIcon from "../../../../images/back.png";
import nextWhiteIcon from "../../../../images/next-white.png";
import nextIcon from "../../../../images/next.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  updateHandWristUramFieldScores,
  updateHandWristUramScores,
  updateHandWristbctqScores,
  updateHandWristsatisfactionScores,
  updateHandWristPainScores,
  updateHandWristSymptomsScores,
  updateHandWristfunctionScores,
  updateHandWristqolScores,
  updateHandWristFeedbackScores,
  updateHandWristScores,
  updateAllData,
} from "../../../../redux/actions/handWrist";
import useAxios from "../../../../axiosinstance";
import useTranslation from "../../../customHooks/translations";
import HandWristTab from "../../tab-section/HandWristTab";
import { useSearchParams } from "react-router-dom";
import CustomToaster from "../../../custom/toaster/CustomToaster";
import {
  LeftSide,
  LeftTrans,
  RightSide,
  RigthTrans,
} from "../../../../utilities/RightLeftFormat";
import NameCustom from "../../../custom/name/NameCustom";
import LoadingSpinner from "../../../custom/loader/LoadingSpinner";
export default function HandWristUram() {
  const translation = useTranslation();
  const { rId } = useParams();
  const [searchParams] = useSearchParams();
  let doctorEnable = searchParams.get("d");
  const axiosinstance = useAxios();
  const evaluated = useSelector((state) => state.handWrist.evaluated);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [flannelR, setflannelR] = useState("0");
  const [flannelL, setflannelL] = useState("0");
  const [faceR, setfaceR] = useState("0");
  const [faceL, setfaceL] = useState("0");
  const [bottleR, setbottleR] = useState("0");
  const [bottleL, setbottleL] = useState("0");
  const [shakeR, setshakeR] = useState("0");
  const [shakeL, setshakeL] = useState("0");
  const [strokeR, setstrokeR] = useState("0");
  const [strokeL, setstrokeL] = useState("0");
  const [clapR, setclapR] = useState("0");
  const [clapL, setclapL] = useState("0");
  const [spreadR, setspreadR] = useState("0");
  const [spreadL, setspreadL] = useState("0");
  const [leeanR, setleeanR] = useState("0");
  const [leeanL, setleeanL] = useState("0");
  const [smallR, setsmallR] = useState("0");
  const [smallL, setsmallL] = useState("0");
  const [chk, setChk] = useState(false);
  const [updateTotal, setUpdateTotal] = useState(false);

  const [syndrome, setSyndrome] = useState("0");

  const [doctorStatus, setDoctorStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const save = () => {
    axiosinstance.current
      .get(`extapp/forms/all_forms?resource_id=${rId}&type=23`)
      .then((res) => {
        setSyndrome(res.data.data2[0].isSyndrome);

        dispatch(updateHandWristPainScores(res.data.data2[0].painScore));
        dispatch(
          updateHandWristSymptomsScores(res.data.data2[0].symptomsScore)
        );
        dispatch(updateHandWristScores(res.data.data2[0].evaluated));
        dispatch(
          updateHandWristfunctionScores(res.data.data2[0].functionScore)
        );
        dispatch(updateHandWristqolScores(res.data.data2[0].qolScore));
        dispatch(updateHandWristUramScores(res.data.data2[0].uramScore));
        dispatch(updateHandWristbctqScores(res.data.data2[0].bctq));

        dispatch(
          updateHandWristsatisfactionScores(res.data.data2[0].satisfactionScore)
        );
        dispatch(
          updateHandWristFeedbackScores(res.data.data2[0].doctorFeedback)
        );
        setDoctorStatus(res.data.doctorStatus);
        setChk(true);
      })
      .catch((err) => {
        navigate("/un-authorized-access");
      });
  };

  let akpt = searchParams.get("akpt");
  const [msg, setMsg] = useState("");
  const [msgType, setmsgType] = useState("");
  const handleSubmit = () => {
    updateAllData(json, dispatch);
    setUpdateTotal(!updateTotal);
    setMsg(translation.update);
    setmsgType("success");
    setmsgType("success");
    setTimeout(() => {
    let view_circular=  sessionStorage.getItem("view_circular")
     // window.location.href = process.env.REACT_APP_DOCTOR_URL + "patient-view/" + akpt
    //  window.location.href = process.env.REACT_APP_DOCTOR_URL +`view-management-plan/${akpt}/${window.btoa(
    //    rId
    //  )}/?org=${window.btoa(sessionStorage.getItem("orgid")||'')}&formType=${
    //    window.btoa('23') || ""
    //  }&view_circular=${view_circular==='false'?'false':'true'}`
    window.location.href = process.env.REACT_APP_DOCTOR_URL +`patient-details/${akpt}?formid=23`
     setMsg('')
     setmsgType('success')
   }, 2000);
  };
  const handleNext = () => {
    // redirect to feedback form
    // navigate(`/hand-wrist-feedback/${rId}?d=true&akpt=${akpt}`);
    navigate(`/hand-wrist-qol/${rId}?d=true&akpt=${akpt}`);
  };
  useEffect(() => {
    save();
    // window.scrollTo(0, 0);
  }, []);
  const HandUram = useSelector((state) => state.handWrist.uramScore[0]);
  const json = useSelector((state) => state.handWrist);

  useEffect(() => {
    setflannelR(HandUram.flannelR);
    setflannelL(HandUram.flannelL);
    setfaceR(HandUram.faceR);
    setfaceL(HandUram.faceL);
    setbottleR(HandUram.bottleR);
    setbottleL(HandUram.bottleL);
    setshakeR(HandUram.shakeR);
    setshakeL(HandUram.shakeL);
    setstrokeR(HandUram.strokeR);
    setstrokeL(HandUram.strokeL);
    setclapR(HandUram.clapR);
    setclapL(HandUram.clapL);
    setspreadR(HandUram.spreadR);
    setspreadL(HandUram.spreadL);
    setleeanR(HandUram.leeanR);
    setleeanL(HandUram.leeanL);
    setsmallR(HandUram.smallR);
    setsmallL(HandUram.smallL);
  }, [HandUram]);

  const updateDate = () => {
    axiosinstance.current
      .patch(
        `extapp/forms/update_all_form_type?resource_id=${rId}&action=${
          doctorEnable === "true" ? "1" : "0"
        }`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res);
      });
  };
  useEffect(() => {
    if (chk) {
      updateDate();
    }
  }, [HandUram, updateTotal]);
  const handleflannelR = (e) => {
    dispatch(
      updateHandWristUramFieldScores({
        key: "flannelR",
        value: e,
      })
    );
    setflannelR(e);
  };
  const handleflannelL = (e) => {
    dispatch(
      updateHandWristUramFieldScores({
        key: "flannelL",
        value: e,
      })
    );
    setflannelL(e);
  };
  const handlefaceR = (e) => {
    dispatch(
      updateHandWristUramFieldScores({
        key: "faceR",
        value: e,
      })
    );
    setfaceR(e);
  };
  const handlefaceL = (e) => {
    dispatch(
      updateHandWristUramFieldScores({
        key: "faceL",
        value: e,
      })
    );
    setfaceL(e);
  };
  const handlebottleR = (e) => {
    dispatch(
      updateHandWristUramFieldScores({
        key: "bottleR",
        value: e,
      })
    );
    setbottleR(e);
  };
  const handlebottleL = (e) => {
    dispatch(
      updateHandWristUramFieldScores({
        key: "bottleL",
        value: e,
      })
    );
    setbottleL(e);
  };
  const handleshakeR = (e) => {
    dispatch(
      updateHandWristUramFieldScores({
        key: "shakeR",
        value: e,
      })
    );
    setshakeR(e);
  };
  const handleshakeL = (e) => {
    dispatch(
      updateHandWristUramFieldScores({
        key: "shakeL",
        value: e,
      })
    );
    setshakeL(e);
  };
  const handlestrokeR = (e) => {
    dispatch(
      updateHandWristUramFieldScores({
        key: "strokeR",
        value: e,
      })
    );
    setstrokeR(e);
  };
  const handlestrokeL = (e) => {
    dispatch(
      updateHandWristUramFieldScores({
        key: "strokeL",
        value: e,
      })
    );
    setstrokeL(e);
  };
  const handleclapR = (e) => {
    dispatch(
      updateHandWristUramFieldScores({
        key: "clapR",
        value: e,
      })
    );
    setclapR(e);
  };
  const handleclapL = (e) => {
    dispatch(
      updateHandWristUramFieldScores({
        key: "clapL",
        value: e,
      })
    );
    setclapL(e);
  };
  const handlespreadR = (e) => {
    dispatch(
      updateHandWristUramFieldScores({
        key: "spreadR",
        value: e,
      })
    );
    setspreadR(e);
  };
  const handlespreadL = (e) => {
    dispatch(
      updateHandWristUramFieldScores({
        key: "spreadL",
        value: e,
      })
    );
    setspreadL(e);
  };
  const handleleeanR = (e) => {
    dispatch(
      updateHandWristUramFieldScores({
        key: "leeanR",
        value: e,
      })
    );
    setleeanR(e);
  };
  const handleleeanL = (e) => {
    dispatch(
      updateHandWristUramFieldScores({
        key: "leeanL",
        value: e,
      })
    );
    setleeanL(e);
  };
  const handlesmallR = (e) => {
    dispatch(
      updateHandWristUramFieldScores({
        key: "smallR",
        value: e,
      })
    );
    setsmallR(e);
  };
  const handlesmallL = (e) => {
    dispatch(
      updateHandWristUramFieldScores({
        key: "smallL",
        value: e,
      })
    );
    setsmallL(e);
  };

  const continueRedirect = () => {
    navigate("/hand-wrist-qol/" + rId);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
  return (
    <>
    {isLoading ? (
      <LoadingSpinner />
    ) : (
    <div className="pain_screen mb_100">
      <div class="d-flex justify-content-end">
        <NameCustom />
      </div>
      <div className="panel">
        <div className="panel-body p_25">
          {doctorEnable === "true" && <HandWristTab rId={rId} />}
          <CustomToaster msg={msg} msgType={msgType} />

          <h4 className="ph_title f_40">{translation.patient_hand_uram}</h4>
          <h4>{translation.patient_hand_uram_canyou}</h4>
          <div className="function_formtop">
            <div className="row">
              {evaluated === "1" || evaluated === "3" ? (
                <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                  <UramScoreRangeSlider
                    label={
                      translation.patient_hand_uram_q1 +
                      RigthTrans(evaluated, translation)
                    }
                    classes=""
                    value={flannelR}
                    onChange={handleflannelR}
                    sliderData={[
                      {
                        score: "0",
                        label: translation.patient_hand_uram_q1_a1,
                      },
                      {
                        score: "1",
                        label: translation.patient_hand_uram_q1_a2,
                      },
                      {
                        score: "2",
                        label: translation.patient_hand_uram_q1_a3,
                      },
                      {
                        score: "3",
                        label: translation.patient_hand_uram_q1_a4,
                      },
                      {
                        score: "4",
                        label: translation.patient_hand_uram_q1_a5,
                      },
                      {
                        score: "5",
                        label: translation.patient_hand_uram_q1_a6,
                      },
                    ]}
                  />
                </div>
              ) : (
                ""
              )}
              {evaluated === "2" || evaluated === "3" ? (
                <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                  <UramScoreRangeSlider
                    label={
                      translation.patient_hand_uram_q1 +
                      LeftTrans(evaluated, translation)
                    }
                    classes=""
                    value={flannelL}
                    onChange={handleflannelL}
                    sliderData={[
                      {
                        score: "0",
                        label: translation.patient_hand_uram_q1_a1,
                      },
                      {
                        score: "1",
                        label: translation.patient_hand_uram_q1_a2,
                      },
                      {
                        score: "2",
                        label: translation.patient_hand_uram_q1_a3,
                      },
                      {
                        score: "3",
                        label: translation.patient_hand_uram_q1_a4,
                      },
                      {
                        score: "4",
                        label: translation.patient_hand_uram_q1_a5,
                      },
                      {
                        score: "5",
                        label: translation.patient_hand_uram_q1_a6,
                      },
                    ]}
                  />
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="row">
              {evaluated === "1" || evaluated === "3" ? (
                <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                  <UramScoreRangeSlider
                    label={
                      translation.patient_hand_uram_q2 +
                      RigthTrans(evaluated, translation)
                    }
                    classes=""
                    value={faceR}
                    onChange={handlefaceR}
                    sliderData={[
                      {
                        score: "0",
                        label: translation.patient_hand_uram_q2_a1,
                      },
                      {
                        score: "1",
                        label: translation.patient_hand_uram_q2_a2,
                      },
                      {
                        score: "2",
                        label: translation.patient_hand_uram_q2_a3,
                      },
                      {
                        score: "3",
                        label: translation.patient_hand_uram_q2_a4,
                      },
                      {
                        score: "4",
                        label: translation.patient_hand_uram_q2_a5,
                      },
                      {
                        score: "5",
                        label: translation.patient_hand_uram_q2_a6,
                      },
                    ]}
                  />
                </div>
              ) : (
                ""
              )}
              {evaluated === "2" || evaluated === "3" ? (
                <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                  <UramScoreRangeSlider
                    label={
                      translation.patient_hand_uram_q2 +
                      LeftTrans(evaluated, translation)
                    }
                    classes=""
                    value={faceL}
                    onChange={handlefaceL}
                    sliderData={[
                      {
                        score: "0",
                        label: translation.patient_hand_uram_q2_a1,
                      },
                      {
                        score: "1",
                        label: translation.patient_hand_uram_q2_a2,
                      },
                      {
                        score: "2",
                        label: translation.patient_hand_uram_q2_a3,
                      },
                      {
                        score: "3",
                        label: translation.patient_hand_uram_q2_a4,
                      },
                      {
                        score: "4",
                        label: translation.patient_hand_uram_q2_a5,
                      },
                      {
                        score: "5",
                        label: translation.patient_hand_uram_q2_a6,
                      },
                    ]}
                  />
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="row">
              {evaluated === "1" || evaluated === "3" ? (
                <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                  <UramScoreRangeSlider
                    label={
                      translation.patient_hand_uram_q3 +
                      RigthTrans(evaluated, translation)
                    }
                    classes=""
                    value={bottleR}
                    onChange={handlebottleR}
                    sliderData={[
                      {
                        score: "0",
                        label: translation.patient_hand_uram_q3_a1,
                      },
                      {
                        score: "1",
                        label: translation.patient_hand_uram_q3_a2,
                      },
                      {
                        score: "2",
                        label: translation.patient_hand_uram_q3_a3,
                      },
                      {
                        score: "3",
                        label: translation.patient_hand_uram_q3_a4,
                      },
                      {
                        score: "4",
                        label: translation.patient_hand_uram_q3_a5,
                      },
                      {
                        score: "5",
                        label: translation.patient_hand_uram_q3_a6,
                      },
                    ]}
                  />
                </div>
              ) : (
                ""
              )}
              {evaluated === "2" || evaluated === "3" ? (
                <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                  <UramScoreRangeSlider
                    label={
                      translation.patient_hand_uram_q3 +
                      LeftTrans(evaluated, translation)
                    }
                    classes=""
                    value={bottleL}
                    onChange={handlebottleL}
                    sliderData={[
                      {
                        score: "0",
                        label: translation.patient_hand_uram_q3_a1,
                      },
                      {
                        score: "1",
                        label: translation.patient_hand_uram_q3_a2,
                      },
                      {
                        score: "2",
                        label: translation.patient_hand_uram_q3_a3,
                      },
                      {
                        score: "3",
                        label: translation.patient_hand_uram_q3_a4,
                      },
                      {
                        score: "4",
                        label: translation.patient_hand_uram_q3_a5,
                      },
                      {
                        score: "5",
                        label: translation.patient_hand_uram_q3_a6,
                      },
                    ]}
                  />
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="row">
              {evaluated === "1" || evaluated === "3" ? (
                <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                  <UramScoreRangeSlider
                    label={
                      translation.patient_hand_uram_q4 +
                      RigthTrans(evaluated, translation)
                    }
                    classes=""
                    value={shakeR}
                    onChange={handleshakeR}
                    sliderData={[
                      {
                        score: "0",
                        label: translation.patient_hand_uram_q4_a1,
                      },
                      {
                        score: "1",
                        label: translation.patient_hand_uram_q4_a2,
                      },
                      {
                        score: "2",
                        label: translation.patient_hand_uram_q4_a3,
                      },
                      {
                        score: "3",
                        label: translation.patient_hand_uram_q4_a4,
                      },
                      {
                        score: "4",
                        label: translation.patient_hand_uram_q4_a5,
                      },
                      {
                        score: "5",
                        label: translation.patient_hand_uram_q4_a6,
                      },
                    ]}
                  />
                </div>
              ) : (
                ""
              )}
              {evaluated === "2" || evaluated === "3" ? (
                <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                  <UramScoreRangeSlider
                    label={
                      translation.patient_hand_uram_q4 +
                      LeftTrans(evaluated, translation)
                    }
                    classes=""
                    value={shakeL}
                    onChange={handleshakeL}
                    sliderData={[
                      {
                        score: "0",
                        label: translation.patient_hand_uram_q4_a1,
                      },
                      {
                        score: "1",
                        label: translation.patient_hand_uram_q4_a2,
                      },
                      {
                        score: "2",
                        label: translation.patient_hand_uram_q4_a3,
                      },
                      {
                        score: "3",
                        label: translation.patient_hand_uram_q4_a4,
                      },
                      {
                        score: "4",
                        label: translation.patient_hand_uram_q4_a5,
                      },
                      {
                        score: "5",
                        label: translation.patient_hand_uram_q4_a6,
                      },
                    ]}
                  />
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="row">
              {evaluated === "1" || evaluated === "3" ? (
                <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                  <UramScoreRangeSlider
                    label={
                      translation.patient_hand_uram_q5 +
                      RigthTrans(evaluated, translation)
                    }
                    classes=""
                    value={strokeR}
                    onChange={handlestrokeR}
                    sliderData={[
                      {
                        score: "0",
                        label: translation.patient_hand_uram_q5_a1,
                      },
                      {
                        score: "1",
                        label: translation.patient_hand_uram_q5_a2,
                      },
                      {
                        score: "2",
                        label: translation.patient_hand_uram_q5_a3,
                      },
                      {
                        score: "3",
                        label: translation.patient_hand_uram_q5_a4,
                      },
                      {
                        score: "4",
                        label: translation.patient_hand_uram_q5_a5,
                      },
                      {
                        score: "5",
                        label: translation.patient_hand_uram_q5_a6,
                      },
                    ]}
                  />
                </div>
              ) : (
                ""
              )}
              {evaluated === "2" || evaluated === "3" ? (
                <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                  <UramScoreRangeSlider
                    label={
                      translation.patient_hand_uram_q5 +
                      LeftTrans(evaluated, translation)
                    }
                    classes=""
                    value={strokeL}
                    onChange={handlestrokeL}
                    sliderData={[
                      {
                        score: "0",
                        label: translation.patient_hand_uram_q5_a1,
                      },
                      {
                        score: "1",
                        label: translation.patient_hand_uram_q5_a2,
                      },
                      {
                        score: "2",
                        label: translation.patient_hand_uram_q5_a3,
                      },
                      {
                        score: "3",
                        label: translation.patient_hand_uram_q5_a4,
                      },
                      {
                        score: "4",
                        label: translation.patient_hand_uram_q5_a5,
                      },
                      {
                        score: "5",
                        label: translation.patient_hand_uram_q5_a6,
                      },
                    ]}
                  />
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="row">
              {evaluated === "1" || evaluated === "3" ? (
                <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                  <UramScoreRangeSlider
                    label={
                      translation.patient_hand_uram_q6 +
                      RigthTrans(evaluated, translation)
                    }
                    classes=""
                    value={clapR}
                    onChange={handleclapR}
                    sliderData={[
                      {
                        score: "0",
                        label: translation.patient_hand_uram_q6_a1,
                      },
                      {
                        score: "1",
                        label: translation.patient_hand_uram_q6_a2,
                      },
                      {
                        score: "2",
                        label: translation.patient_hand_uram_q6_a3,
                      },
                      {
                        score: "3",
                        label: translation.patient_hand_uram_q6_a4,
                      },
                      {
                        score: "4",
                        label: translation.patient_hand_uram_q6_a5,
                      },
                      {
                        score: "5",
                        label: translation.patient_hand_uram_q6_a6,
                      },
                    ]}
                  />
                </div>
              ) : (
                ""
              )}
              {evaluated === "2" || evaluated === "3" ? (
                <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                  <UramScoreRangeSlider
                    label={
                      translation.patient_hand_uram_q6 +
                      LeftTrans(evaluated, translation)
                    }
                    classes=""
                    value={clapL}
                    onChange={handleclapL}
                    sliderData={[
                      {
                        score: "0",
                        label: translation.patient_hand_uram_q6_a1,
                      },
                      {
                        score: "1",
                        label: translation.patient_hand_uram_q6_a2,
                      },
                      {
                        score: "2",
                        label: translation.patient_hand_uram_q6_a3,
                      },
                      {
                        score: "3",
                        label: translation.patient_hand_uram_q6_a4,
                      },
                      {
                        score: "4",
                        label: translation.patient_hand_uram_q6_a5,
                      },
                      {
                        score: "5",
                        label: translation.patient_hand_uram_q6_a6,
                      },
                    ]}
                  />
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="row">
              {evaluated === "1" || evaluated === "3" ? (
                <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                  <UramScoreRangeSlider
                    label={
                      translation.patient_hand_uram_q7 +
                      RigthTrans(evaluated, translation)
                    }
                    classes=""
                    value={spreadR}
                    onChange={handlespreadR}
                    sliderData={[
                      {
                        score: "0",
                        label: translation.patient_hand_uram_q7_a1,
                      },
                      {
                        score: "1",
                        label: translation.patient_hand_uram_q7_a2,
                      },
                      {
                        score: "2",
                        label: translation.patient_hand_uram_q7_a3,
                      },
                      {
                        score: "3",
                        label: translation.patient_hand_uram_q7_a4,
                      },
                      {
                        score: "4",
                        label: translation.patient_hand_uram_q7_a5,
                      },
                      {
                        score: "5",
                        label: translation.patient_hand_uram_q7_a6,
                      },
                    ]}
                  />
                </div>
              ) : (
                ""
              )}
              {evaluated === "2" || evaluated === "3" ? (
                <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                  <UramScoreRangeSlider
                    label={
                      translation.patient_hand_uram_q7 +
                      LeftTrans(evaluated, translation)
                    }
                    classes=""
                    value={spreadL}
                    onChange={handlespreadL}
                    sliderData={[
                      {
                        score: "0",
                        label: translation.patient_hand_uram_q7_a1,
                      },
                      {
                        score: "1",
                        label: translation.patient_hand_uram_q7_a2,
                      },
                      {
                        score: "2",
                        label: translation.patient_hand_uram_q7_a3,
                      },
                      {
                        score: "3",
                        label: translation.patient_hand_uram_q7_a4,
                      },
                      {
                        score: "4",
                        label: translation.patient_hand_uram_q7_a5,
                      },
                      {
                        score: "5",
                        label: translation.patient_hand_uram_q7_a6,
                      },
                    ]}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="row">
              {evaluated === "1" || evaluated === "3" ? (
                <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                  <UramScoreRangeSlider
                    label={
                      translation.patient_hand_uram_q8 +
                      RigthTrans(evaluated, translation)
                    }
                    classes=""
                    value={leeanR}
                    onChange={handleleeanR}
                    sliderData={[
                      {
                        score: "0",
                        label: translation.patient_hand_uram_q8_a1,
                      },
                      {
                        score: "1",
                        label: translation.patient_hand_uram_q8_a2,
                      },
                      {
                        score: "2",
                        label: translation.patient_hand_uram_q8_a3,
                      },
                      {
                        score: "3",
                        label: translation.patient_hand_uram_q8_a4,
                      },
                      {
                        score: "4",
                        label: translation.patient_hand_uram_q8_a5,
                      },
                      {
                        score: "5",
                        label: translation.patient_hand_uram_q8_a6,
                      },
                    ]}
                  />
                </div>
              ) : (
                ""
              )}
              {evaluated === "2" || evaluated === "3" ? (
                <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                  <UramScoreRangeSlider
                    label={
                      translation.patient_hand_uram_q8 +
                      LeftTrans(evaluated, translation)
                    }
                    classes=""
                    value={leeanL}
                    onChange={handleleeanL}
                    sliderData={[
                      {
                        score: "0",
                        label: translation.patient_hand_uram_q8_a1,
                      },
                      {
                        score: "1",
                        label: translation.patient_hand_uram_q8_a2,
                      },
                      {
                        score: "2",
                        label: translation.patient_hand_uram_q8_a3,
                      },
                      {
                        score: "3",
                        label: translation.patient_hand_uram_q8_a4,
                      },
                      {
                        score: "4",
                        label: translation.patient_hand_uram_q8_a5,
                      },
                      {
                        score: "5",
                        label: translation.patient_hand_uram_q8_a6,
                      },
                    ]}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="row">
              {evaluated === "1" || evaluated === "3" ? (
                <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                  <UramScoreRangeSlider
                    label={
                      translation.patient_hand_uram_q9 +
                      RigthTrans(evaluated, translation)
                    }
                    classes=""
                    value={smallR}
                    onChange={handlesmallR}
                    sliderData={[
                      {
                        score: "0",
                        label: translation.patient_hand_uram_q9_a1,
                      },
                      {
                        score: "1",
                        label: translation.patient_hand_uram_q9_a2,
                      },
                      {
                        score: "2",
                        label: translation.patient_hand_uram_q9_a3,
                      },
                      {
                        score: "3",
                        label: translation.patient_hand_uram_q9_a4,
                      },
                      {
                        score: "4",
                        label: translation.patient_hand_uram_q9_a5,
                      },
                      {
                        score: "5",
                        label: translation.patient_hand_uram_q9_a6,
                      },
                    ]}
                  />
                </div>
              ) : (
                ""
              )}
              {evaluated === "2" || evaluated === "3" ? (
                <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                  <UramScoreRangeSlider
                    label={
                      translation.patient_hand_uram_q9 +
                      LeftTrans(evaluated, translation)
                    }
                    classes=""
                    value={smallL}
                    onChange={handlesmallL}
                    sliderData={[
                      {
                        score: "0",
                        label: translation.patient_hand_uram_q9_a1,
                      },
                      {
                        score: "1",
                        label: translation.patient_hand_uram_q9_a2,
                      },
                      {
                        score: "2",
                        label: translation.patient_hand_uram_q9_a3,
                      },
                      {
                        score: "3",
                        label: translation.patient_hand_uram_q9_a4,
                      },
                      {
                        score: "4",
                        label: translation.patient_hand_uram_q9_a5,
                      },
                      {
                        score: "5",
                        label: translation.patient_hand_uram_q9_a6,
                      },
                    ]}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          {/* {doctorEnable === "true" && (
            <>
              {doctorStatus ? (
                <>
                  <div className="row mb_30 button_box">
                    <div className="col-md-6 col-sm-6"></div>
                    <div className="col-md-6 col-sm-6 text-end">
                      <button
                        className="next_btn btn_fill me-3"
                        onClick={() => handleNext()}
                      >
                        {translation.next_button}
                        <span>
                          <img src={nextIcon} alt="" />
                          
                        </span>
                      </button>
                      <button
                        className="next_btn btn_fill"
                        onClick={() => handleSubmit()}
                      >
                        {translation.submit}
                        <span>
                          <img src={nextIcon} alt="" />
                          
                        </span>
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6"></div>
                  <div className="col-md-6 col-sm-6 text-end">
                    <button
                      className="next_btn btn_fill"
                      onClick={() => handleSubmit()}
                    >
                      {"Submit"}
                      <span>
                        <img src={nextIcon} alt="" />
                        
                      </span>
                    </button>
                  </div>
                </div>
              )}
            </>
          )} */}

          {doctorEnable === "true" && (
            <div className="row mb_30 button_box">
              <div className="col-md-6 col-sm-6"></div>
              <div className="col-md-6 col-sm-6 text-end">
              <button
                      className="next_btn btn_fill me-3"
                      onClick={() => handleNext()}
                    >
                      {translation.next_button}
                      <span>
                        <img src={nextIcon} alt="" />
                        {/* <img src={nextWhiteIcon} alt="" /> */}
                      </span>
                    </button>
                <button
                  className="next_btn btn_fill"
                  onClick={() => handleSubmit()}
                >
                  {translation.submit}
                  <span>
                    <img src={nextIcon} alt="" />
                    {/* <img src={nextWhiteIcon} alt="" /> */}
                  </span>
                </button>
              </div>
            </div>
          )}

          {doctorEnable !== "true" && (
            <div className="row mb_30 button_box">
              <div className="col-md-6 col-sm-6 col-6">
                {syndrome === "2" ? (
                  <Link
                    to={"/hand-wrist-bctq/" + rId}
                    className="assm_back_btn"
                  >
                    <img src={backIcon} alt="" />
                    <span>{translation.back_button}</span>
                  </Link>
                ) : (
                  <Link
                    to={"/hand-wrist-function/" + rId}
                    className="assm_back_btn"
                  >
                    <img src={backIcon} alt="" />
                    <span>{translation.back_button}</span>
                  </Link>
                )}
              </div>
              <div className="col-md-6 col-sm-6 col-6 text-end">
                <button
                  className="next_btn btn_fill"
                  onClick={continueRedirect}
                >
                  {translation.next_button}
                  <span>
                    <img src={nextIcon} alt="" />
                    <img src={nextWhiteIcon} alt="" />
                  </span>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )}
     </>
  );
}
