import React from "react";
import "../../../css/ViewTreatmentForm.css";
import useTranslation from "../../customHooks/translations";

export default function ElbowQOL({ elbowQOL, AllData }) {
  const translations = useTranslation();
  return (
    <>
      <div className="whitebox padding-none">
        <div className="treatment-content">
          <div className="treatment-row-header">
            4. {translations.patient_elbow_quality_of_life}
          </div>
          <div className="treatment-row">
            <div className="treatment-col">
              <label>
                {translations.patient_elbow_quality_of_life_q1} (
                {translations.Right})
              </label>
              <p>
                {elbowQOL.elbowlifeR === "4"
                  ? translations.patient_elbow_quality_of_life_q1_a5
                  : elbowQOL.elbowlifeR === "3"
                  ? translations.patient_elbow_quality_of_life_q1_a4
                  : elbowQOL.elbowlifeR === "2"
                  ? translations.patient_elbow_quality_of_life_q1_a3
                  : elbowQOL.elbowlifeR === "1"
                  ? translations.patient_elbow_quality_of_life_q1_a2
                  : elbowQOL.elbowlifeR === "0"
                  ? translations.patient_elbow_quality_of_life_q1_a1
                  : ""}
              </p>
            </div>
            <div className="treatment-col">
              <label>
                {translations.patient_elbow_quality_of_life_q1} (
                {translations.Left})
              </label>
              <p>
                {elbowQOL.elbowlifeL === "4"
                  ? translations.patient_elbow_quality_of_life_q1_a5
                  : elbowQOL.elbowlifeL === "3"
                  ? translations.patient_elbow_quality_of_life_q1_a4
                  : elbowQOL.elbowlifeL === "2"
                  ? translations.patient_elbow_quality_of_life_q1_a3
                  : elbowQOL.elbowlifeL === "1"
                  ? translations.patient_elbow_quality_of_life_q1_a2
                  : elbowQOL.elbowlifeL === "0"
                  ? translations.patient_elbow_quality_of_life_q1_a1
                  : ""}
              </p>
            </div>
          </div>
          <div className="treatment-row">
            <div className="treatment-col">
              <label>
                {translations.patient_elbow_quality_of_life_q2} (
                {translations.Right})
              </label>
              <p>
                {elbowQOL.elbowDuringR === "4"
                  ? translations.patient_elbow_quality_of_life_q2_a5
                  : elbowQOL.elbowDuringR === "3"
                  ? translations.patient_elbow_quality_of_life_q2_a4
                  : elbowQOL.elbowDuringR === "2"
                  ? translations.patient_elbow_quality_of_life_q2_a3
                  : elbowQOL.elbowDuringR === "1"
                  ? translations.patient_elbow_quality_of_life_q2_a2
                  : elbowQOL.elbowDuringR === "0"
                  ? translations.patient_elbow_quality_of_life_q2_a1
                  : ""}
              </p>
            </div>
            <div className="treatment-col">
              <label>
                {translations.patient_elbow_quality_of_life_q2} (
                {translations.Left})
              </label>
              <p>
                {elbowQOL.elbowDuringL === "4"
                  ? translations.patient_elbow_quality_of_life_q2_a5
                  : elbowQOL.elbowDuringL === "3"
                  ? translations.patient_elbow_quality_of_life_q2_a4
                  : elbowQOL.elbowDuringL === "2"
                  ? translations.patient_elbow_quality_of_life_q2_a3
                  : elbowQOL.elbowDuringL === "1"
                  ? translations.patient_elbow_quality_of_life_q2_a2
                  : elbowQOL.elbowDuringL === "0"
                  ? translations.patient_elbow_quality_of_life_q2_a1
                  : ""}
              </p>
            </div>
          </div>
          <div className="treatment-row">
            <div className="treatment-table">
              <table cellpadding="0" cellspacing="0">
                {/* <thead>
                                    <tr>
                                        <th></th>
                                        {AllData.elbowPain === "1" || AllData.elbowPain === "3" ? (
                                            <th>Right Elbow</th>
                                        ) : (
                                            ""
                                        )}
                                        {AllData.elbowPain === "2" || AllData.elbowPain === "3" ? (
                                            <th>left Elbow</th>
                                        ) : (
                                            ""
                                        )}
                                    </tr>
                                </thead> */}
                <tbody>
                  <tr>
                    <td>{translations.patient_elbow_quality_of_life_q3} </td>

                    <td>{elbowQOL.problemL}</td>
                    <td>{elbowQOL.problemR}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_elbow_quality_of_life_q4}</td>

                    <td>{elbowQOL.washingL}</td>
                    <td>{elbowQOL.washingR}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_elbow_quality_of_life_q5} </td>

                    <td>{elbowQOL.activitiesL}</td>
                    <td>{elbowQOL.activitiesR}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_elbow_quality_of_life_q6}</td>

                    <td>{elbowQOL.discomfortL}</td>
                    <td>{elbowQOL.discomfortR}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_elbow_quality_of_life_q7}</td>

                    <td>{elbowQOL.anxietyL}</td>
                    <td>{elbowQOL.anxietyR}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_elbow_quality_of_life_q8}</td>

                    <td>{elbowQOL.percentageL}</td>
                    <td>{elbowQOL.percentage}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {/* <div className="treatment-row">
                        <div className="treatment-col">
                            <label>How would you rate your elbow today as a percentage of normal?</label>
                            <p>{elbowQOL.percentage}</p>
                        </div>

                    </div> */}
        </div>
      </div>
    </>
  );
}
