import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import vertMenuStyles from "./FormVerticalMenu.module.css";

function FormVerticalMenu({ setGetId }) {
  return (
    <>
      <Navbar
        bg="light"
        className={`scrollbar_vert ${vertMenuStyles.vm_sidebar}`}
      >
        <Nav
          className={`me-auto my-2 my-lg-0 ${vertMenuStyles.vm_sidebar_inner}`}
          navbarScroll
        >
          <Nav.Link
            className={`${vertMenuStyles.vm_item}`}
            onClick={() => setGetId("acl")}
            // href="#acl"
          >
            ACL
          </Nav.Link>
          <Nav.Link
            className={`${vertMenuStyles.vm_item}`}
            onClick={() => setGetId("pcl")}
            // href="#pcl"
          >
            PCL
          </Nav.Link>
          <Nav.Link
            className={`${vertMenuStyles.vm_item}`}
            onClick={() => setGetId("plc")}
            // href="#plc"
          >
            PLC
          </Nav.Link>
          <Nav.Link
            className={`${vertMenuStyles.vm_item}`}
            onClick={() => setGetId("pmc")}
            // href="#pmc"
          >
            PMC{" "}
          </Nav.Link>
          <Nav.Link
            className={`${vertMenuStyles.vm_item}`}
            onClick={() => setGetId("collaterals")}
            // href="#collaterals"
          >
            Collaterals
          </Nav.Link>
          <Nav.Link
            className={`${vertMenuStyles.vm_item}`}
            onClick={() => setGetId("meniscus")}
            // href="#meniscus"
          >
            Meniscus
          </Nav.Link>
          <Nav.Link
            className={`${vertMenuStyles.vm_item}`}
            onClick={() => setGetId("patella")}
            // href="#patella"
          >
            Patella
          </Nav.Link>
          <Nav.Link
            className={`${vertMenuStyles.vm_item}`}
            onClick={() => setGetId("ExtensorMechanism")}
            // href="#ExtensorMechanism"
          >
            Extensor Mechanism
          </Nav.Link>
          <Nav.Link
            className={`${vertMenuStyles.vm_item}`}
            onClick={() => setGetId("others")}
            // href="#others"
          >
            Other tests
          </Nav.Link>
        </Nav>
      </Navbar>
    </>
  );
}

export default FormVerticalMenu;
