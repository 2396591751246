import React from 'react'
import "../../../css/ViewTreatmentForm.css";
import useTranslation from '../../customHooks/translations';

export default function ElbowSatisfaction({ elbowSatisfaction }) {
    const translations=useTranslation();
  return (
    <>
            <div className="whitebox padding-none">
                <div className="treatment-content">
                    <div className="treatment-row-header">5. {translations.patient_elbow_satisfaction} </div>
                    <div className="treatment-row">
                        <div className="treatment-col">
                            <label>{translations.patient_elbow_satisfaction_q1} ({translations.Right})</label>
                            <p>{elbowSatisfaction.applicableR===true ? translations.patient_elbow_satisfaction_na: elbowSatisfaction.rateElbowSatisfactionR}</p>
                        </div>
                        <div className="treatment-col">
                            <label>{translations.patient_elbow_satisfaction_q1} ({translations.Left})</label>
                            <p>{elbowSatisfaction.applicableL===true ? translations.patient_elbow_satisfaction_na: elbowSatisfaction.rateElbowSatisfactionL}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
  )
}
