import React, { useState, useEffect } from "react";
import AssessmentHeader from "../../../custom/assessment-header/AssessmentHeader";
import HeaderInnerBreadcrumb from "../../../custom/heade-inner-breadcrumb/HeaderInnerBreadcrumb";
import KneeScoreRangeSlider from "../../../custom/knee-score-range-slider/KneeScoreRangeSlider";
import ElbowFeedbackScoreRangeSlider from "../../../custom/elbow-doctorfeedback-score-range-slider/ElbowFeedbackScoreRangeSlider";
import FormVerticalMenu from "./form-vertical-menu/FormVerticalMenu";
import RadioButton from "../../../custom/radio-button/RadioButton";
import RadioSwitch from "../../../custom/radio-switch/RadioSwitch";
import Checkbox from "../../../custom/checkbox/Checkbox";
import FullDropdown from "../../../custom/dropdown/FullDropdown";
import InputField from "../../../custom/inputfield/InputField";
import AssessmentDegreeWidget from "../../../custom/assessment-widgets/AssessmentDegreeWidget";
import AssessmentPowerKgWidget from "../../../custom/assessment-widgets/AssessmentPowerKgWidget";
import backIcon from "../../../../images/back.png";
import nextWhiteIcon from "../../../../images/next-white.png";
import nextIcon from "../../../../images/next.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useAxios from "../../../../axiosinstance";
import useTranslation from "../../../customHooks/doctorTranslation";
import ElbowTab from "../../tab-section/ElbowTab";
import { useSearchParams } from "react-router-dom";
import CustomToaster from "../../../custom/toaster/CustomToaster";
import AssessmentKgKgWidget from "../../../custom/assessment-widgets/AssessmentKgKgWidget";
import SubmitModel from "../../SubmissionModel/SubmitModel"
import {
  updateElbowScores,
  updateElbowPainFieldScore,
  updateElbowPainData,
  updateElbowFunctionData,
  updateElbowQOLData,
  updateElbowSatisficationData,
  updateElbowFeedbackScores,
  updateElbowSymptomsData,
  updateAllData,
  updateElbowFeedback,
} from "../../../../redux/actions/elbowAction";
import { findScoreStatus } from "../../../../utilities/FindScoreStatus";
import DoctorNameCustom from "../../../custom/name/DoctorNameCustom";
import Promsschudle from "../../SubmissionModel/Promsschudle";
import LoadingSpinner from "../../../custom/loader/LoadingSpinner";

const ElbowFeedbackStep2 = () => {
  const translation = useTranslation();
  const dispatch = useDispatch();
  const { rId } = useParams();
  const [searchParams] = useSearchParams();
  let doctorEnable = searchParams.get("d");
  const queryParams = new URLSearchParams(window.location.search);
  const dId = queryParams.get("doctor");
  const pId = queryParams.get("patient");
  const axiosInstance = useAxios();
  const navigate = useNavigate();
  const [ValgusR, setValgusR] = useState("0");
  const [ValgusL, setValgusL] = useState("0");
  const [VarusR, setVarusR] = useState("0");
  const [VarusL, setVarusL] = useState("0");
  const [PosterolateralR, setPosterolateralR] = useState("0");
  const [PosterolateralL, setPosterolateralL] = useState("0");
  const [flexionlR, setFlexionlR] = useState("0");
  const [flexionlL, setFlexionlL] = useState("0");

  const [ExtensionR, setExtensionR] = useState("0");
  const [ExtensionL, setExtensionL] = useState("0");
  const [PronationR, setPronationR] = useState("0");
  const [PronationL, setPronationL] = useState("0");
  const [SupinationR, setSupinationR] = useState("0");
  const [SupinationL, setSupinationL] = useState("0");
  const [strengthR, setstrengthR] = useState("");
  const [strengthL, setstrengthL] = useState("");
  const [elbowPainR, setElbowPainR] = useState("0");
  const [elbowPainL, setElbowPainL] = useState("0");

  const [OtherR, setOtherR] = useState("0");
  const [OtherL, setOtherL] = useState("0");
  const [otherText, setOtherText] = useState("");

  const [otherFindingsR, setOtherFindingsR] = useState("");
  const [otherFindingsL, setOtherFindingsL] = useState("");

  const [ImpingementR, setImpingementR] = useState("0");
  const [ImpingementL, setImpingementL] = useState("0");
  const [ImpingementPainR, setImpingementPainR] = useState("0");
  const [ImpingementPainL, setImpingementPainL] = useState("0");
  const [wristR, setWristR] = useState("");
  const [wristL, setWristL] = useState("");
  const [wristfR, setWristfR] = useState("");
  const [wristfL, setWristfL] = useState("");
  const [fingerR, setFingerR] = useState("");
  const [fingerL, setFingerL] = useState("");
  const [fingerFR, setFingerFR] = useState("");
  const [fingerFL, setFingerFL] = useState("");
  const [wpronationR, setWpronationR] = useState("");
  const [wpronationL, setWpronationL] = useState("");
  const [wSupinationR, setWSupinationR] = useState("");
  const [wSupinationL, setWSupinationL] = useState("");

  const [TinelsL, setTinelsL] = useState("");
  const [TinelsR, setTinelsR] = useState("");
  const [StretchL, setStretchL] = useState("");
  const [StretchR, setStretchR] = useState("");
  const [FromentL, setFromentL] = useState("");
  const [FromentR, setFromentR] = useState("");
  const [JeanneL, setJeanneL] = useState("");
  const [JeanneR, setJeanneR] = useState("");
  const [WartenbergL, setWartenbergL] = useState("");
  const [WartenbergR, setWartenbergR] = useState("");
  const [MasseL, setMasseL] = useState("");
  const [MasseR, setMasseR] = useState("");
  const [ir4Right, setIr4Right] = useState("");
  const [ir4Left, setIr4Left] = useState("");
  const [rightpspecify, setrightpspecify] = useState("");
  const [leftpspecify, setleftpspecify] = useState("");
  const [rightaspecify, setrightaspecify] = useState("");
  const [leftaspecify, setleftaspecify] = useState("");
  const [painSco] = useSelector((state) => state.elbowScore.painScore);
  const [functionScore] = useSelector(
    (state) => state.elbowScore.functionScore
  );
  let doctordata=localStorage.getItem("isdoctorForm")
  const [OtherjointsLimitsL, setOtherjointsLimitsL] = useState();
  const [OtherjointsLimitwL, setOtherjointsLimitwL] = useState();
  const [OtherjointsLimitsR, setOtherjointsLimitsR] = useState();
  const [OtherjointsLimitwR, setOtherjointsLimitwR] = useState();

  const [shoulderinputL, setshoulderinputL] = useState("");
  const [WristinputL, setWristinputL] = useState("");
  const [shoulderinputR, setshoulderinputR] = useState("");
  const [wristinputR, setwristinputR] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  let isdoctorForm = queryParams.get("is_doctor_form");
  useEffect(() => {
    if(doctorEnable === "true"){
      let doctordata=localStorage.getItem("isdoctorForm")
      localStorage.setItem("isdoctorForm", doctordata)
    }else{
      localStorage.setItem("isdoctorForm", isdoctorForm)
    }
  
    }, [isdoctorForm])
  const [chk, setChk] = useState(false);
  const docFeedback = useSelector(
    (state) => state.elbowScore.doctorFeedback[0]
  );
  const save = () => {
    axiosInstance.current
      .get(`extapp/forms/all_forms?resource_id=${rId}&type=22`)
      .then((res) => {
        console.log(res.data.data2);
        dispatch(updateElbowPainData(res.data.data2[0].painScore));
        dispatch(updateElbowSymptomsData(res.data.data2[0].symptomsScore));
        dispatch(updateElbowScores(res.data.data2[0].elbowPain));
        dispatch(updateElbowFunctionData(res.data.data2[0].functionScore));
        dispatch(updateElbowQOLData(res.data.data2[0].qolScore));
        dispatch(
          updateElbowSatisficationData(res.data.data2[0].satisfactionScore)
        );
        dispatch(updateElbowFeedback(res.data.data2[0].doctorFeedback));
        setChk(true);
      });
  };

  useEffect(() => {
    save();
  }, []);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
  useEffect(() => {
    setValgusR(docFeedback.ValgusR);
    setValgusL(docFeedback.ValgusL);
    setVarusR(docFeedback.VarusR);
    setVarusL(docFeedback.VarusL);
    setPosterolateralR(docFeedback.PosterolateralR);
    setPosterolateralL(docFeedback.PosterolateralL);
    setFlexionlR(docFeedback.flexionlR);
    setFlexionlL(docFeedback.flexionlL);
    setExtensionR(docFeedback.ExtensionR);
    setExtensionL(docFeedback.ExtensionL);
    setPronationR(docFeedback.PronationR);
    setPronationL(docFeedback.PronationL);
    setSupinationR(docFeedback.SupinationR);
    setSupinationL(docFeedback.SupinationL);
    setstrengthR(docFeedback.strengthR);
    setstrengthL(docFeedback.strengthL);
    setElbowPainR(docFeedback.elbowPainR);
    setElbowPainL(docFeedback.elbowPainL);
    setOtherR(docFeedback.OtherR);
    setOtherL(docFeedback.OtherL);
    setOtherFindingsR(docFeedback.otherFindingsR);
    setOtherFindingsL(docFeedback.otherFindingsL);
    setImpingementR(docFeedback.ImpingementR);
    setImpingementL(docFeedback.ImpingementL);
    setImpingementPainR(docFeedback.ImpingementPainR);
    setImpingementPainL(docFeedback.ImpingementPainL);
    setWristR(docFeedback.wristR);
    setWristL(docFeedback.wristL);
    setWristfR(docFeedback.wristfR);
    setWristfL(docFeedback.wristfL);
    setFingerR(docFeedback.fingerR);
    setFingerL(docFeedback.fingerL);
    setFingerFR(docFeedback.fingerFR);
    setFingerFL(docFeedback.fingerFL);
    setWpronationR(docFeedback.wpronationR);
    setWpronationL(docFeedback.wpronationL);
    setWSupinationR(docFeedback.wSupinationR);
    setWSupinationL(docFeedback.wSupinationL);
    setTinelsL(docFeedback.TinelsL);
    setTinelsR(docFeedback.TinelsR);
    setStretchL(docFeedback.StretchL);
    setStretchR(docFeedback.StretchR);
    setFromentL(docFeedback.FromentL);
    setFromentR(docFeedback.FromentR);
    setJeanneL(docFeedback.JeanneL);
    setJeanneR(docFeedback.JeanneR);
    setWartenbergL(docFeedback.WartenbergL);
    setWartenbergR(docFeedback.WartenbergR);
    setMasseL(docFeedback.MasseL);
    setMasseR(docFeedback.MasseR);
    setIr4Right(docFeedback.ir4Right);
    setIr4Left(docFeedback.ir4Left);
    setrightpspecify(docFeedback.rightpspecify);
    setleftpspecify(docFeedback.leftpspecify);
    setrightaspecify(docFeedback.rightaspecify);
    setleftaspecify(docFeedback.leftaspecify);

    setOtherjointsLimitsL(docFeedback.OtherjointsLimitsL);
    setOtherjointsLimitwL(docFeedback.OtherjointsLimitwL);
    setOtherjointsLimitsR(docFeedback.OtherjointsLimitsR);
    setOtherjointsLimitwR(docFeedback.OtherjointsLimitwR);
    setshoulderinputL(docFeedback.shoulderinputL);
    setWristinputL(docFeedback.WristinputL);
    setshoulderinputR(docFeedback.shoulderinputR);
    setwristinputR(docFeedback.wristinputR);
  }, [docFeedback]);
  console.log(docFeedback);
  function sumAll(...numbers) {
    let total = 0;
    for (const number of numbers) {
      total += number;
    }
    return total;
  }

  // MEPS SCORING //                                              <<=======================

  const MEPSElbowPainR =
    painSco.decElbowPainR === "4"
      ? 45
      : painSco.decElbowPainR === "3"
      ? 30
      : painSco.decElbowPainR === "2"
      ? 15
      : painSco.decElbowPainR === "1"
      ? 15
      : painSco.decElbowPainR === "0"
      ? 0
      : 0;

  const MEPSElbowPainL =
    painSco.decElbowPainL === "4"
      ? 45
      : painSco.decElbowPainL === "3"
      ? 30
      : painSco.decElbowPainL === "2"
      ? 15
      : painSco.decElbowPainL === "1"
      ? 15
      : painSco.decElbowPainL === "0"
      ? 0
      : 0;

  const MEPSfunHygienegR =
    functionScore.funHygienegR === "4"
      ? 0
      : functionScore.funHygienegR === "3"
      ? 5
      : functionScore.funHygienegR === "2"
      ? 5
      : functionScore.funHygienegR === "1"
      ? 5
      : functionScore.funHygienegR === "0"
      ? 5
      : 0;
  const MEPSfunHygienegL =
    functionScore.funHygienegL === "4"
      ? 0
      : functionScore.funHygienegL === "3"
      ? 5
      : functionScore.funHygienegL === "2"
      ? 5
      : functionScore.funHygienegL === "1"
      ? 5
      : functionScore.funHygienegL === "0"
      ? 5
      : 0;

  const MEPSfunUtensilR =
    functionScore.funUtensilR === "4"
      ? 0
      : functionScore.funUtensilR === "3"
      ? 5
      : functionScore.funUtensilR === "2"
      ? 5
      : functionScore.funUtensilR === "1"
      ? 5
      : functionScore.funUtensilR === "0"
      ? 5
      : 0;

  const MEPSFfunUtensilL =
    functionScore.funUtensilL === "4"
      ? 0
      : functionScore.funUtensilL === "3"
      ? 5
      : functionScore.funUtensilL === "2"
      ? 5
      : functionScore.funUtensilL === "1"
      ? 5
      : functionScore.funUtensilL === "0"
      ? 5
      : 0;

  const MEPSfunShirtR =
    functionScore.funShirtR === "4"
      ? 0
      : functionScore.funShirtR === "3"
      ? 5
      : functionScore.funShirtR === "2"
      ? 5
      : functionScore.funShirtR === "1"
      ? 5
      : functionScore.funShirtR === "0"
      ? 5
      : 0;
  const MEPSfunShirtL =
    functionScore.funShirtL === "4"
      ? 0
      : functionScore.funShirtL === "3"
      ? 5
      : functionScore.funShirtL === "2"
      ? 5
      : functionScore.funShirtL === "1"
      ? 5
      : functionScore.funShirtL === "0"
      ? 5
      : 0;

  const MEPSfunHairR =
    functionScore.funHairR === "4"
      ? 0
      : functionScore.funHairR === "3"
      ? 5
      : functionScore.funHairR === "2"
      ? 5
      : functionScore.funHairR === "1"
      ? 5
      : functionScore.funHairR === "0"
      ? 5
      : 0;
  const MEPSfunHairL =
    functionScore.funHairL === "4"
      ? 0
      : functionScore.funHairL === "3"
      ? 5
      : functionScore.funHairL === "2"
      ? 5
      : functionScore.funHairL === "1"
      ? 5
      : functionScore.funHairL === "0"
      ? 5
      : 0;
  const MEPSfunPutShoesR =
    functionScore.funPutShoesR === "4"
      ? 0
      : functionScore.funPutShoesR === "3"
      ? 5
      : functionScore.funPutShoesR === "2"
      ? 5
      : functionScore.funPutShoesR === "1"
      ? 5
      : functionScore.funPutShoesR === "0"
      ? 5
      : 0;

  const MEPSfunPutShoesL =
    functionScore.funPutShoesL === "4"
      ? 0
      : functionScore.funPutShoesL === "3"
      ? 5
      : functionScore.funPutShoesL === "2"
      ? 5
      : functionScore.funPutShoesL === "1"
      ? 5
      : functionScore.funPutShoesL === "0"
      ? 5
      : 0;

  const getFlexion = (active) => {
    if (active > 100) {
      return 20;
    } else if (active >= 50 && active <= 100) {
      return 15;
    } else if (active < 50) {
      return 5;
    } else {
      return 0;
    }
  };

  const MotionFlexRR = getFlexion(docFeedback.RightFlexionR);
  const MotionFlexLR = getFlexion(docFeedback.LeftFlexionR);
  // const MotionFlexRR = docFeedback.RightFlexionR > 100 ? 20 : docFeedback.RightFlexionR > 50 && docFeedback.RightFlexionR < 100 ? 15 : docFeedback.RightFlexionR < 50 ? 5 : 0;
  // const MotionFlexLR = docFeedback.LeftFlexionR > 100 ? 20 : docFeedback.LeftFlexionR > 50 && docFeedback.LeftFlexionR < 100 ? 15 : docFeedback.LeftFlexionR  < 50 ? 5 : 0;

  const StabilityValgusR =
    docFeedback.ValgusR === "0"
      ? 10
      : docFeedback.ValgusR === "1"
      ? 10
      : docFeedback.ValgusR === "2"
      ? 5
      : 0;
  const StabilityValgusL =
    docFeedback.ValgusL === "0"
      ? 10
      : docFeedback.ValgusL === "1"
      ? 10
      : docFeedback.ValgusL === "2"
      ? 5
      : 0;

  const StabilityVarusR =
    docFeedback.VarusR === "0"
      ? 10
      : docFeedback.VarusR === "1"
      ? 10
      : docFeedback.VarusR === "2"
      ? 5
      : 0;
  const StabilityVarusL =
    docFeedback.VarusL === "0"
      ? 10
      : docFeedback.VarusL === "1"
      ? 10
      : docFeedback.VarusL === "2"
      ? 5
      : 0;

  const StabilityPosterolateralR =
    docFeedback.PosterolateralR === "0"
      ? 10
      : docFeedback.PosterolateralR === "1"
      ? 10
      : docFeedback.PosterolateralR === "2"
      ? 5
      : 0;
  const StabilityPosterolateralL =
    docFeedback.PosterolateralL === "0"
      ? 10
      : docFeedback.PosterolateralL === "1"
      ? 10
      : docFeedback.PosterolateralL === "2"
      ? 5
      : 0;

  const minR = Math.min(
    StabilityValgusR,
    StabilityVarusR,
    StabilityPosterolateralR
  );
  const minL = Math.min(
    StabilityVarusL,
    StabilityValgusL,
    StabilityPosterolateralL
  );

  const MepsRFSum = sumAll(
    MEPSElbowPainR,
    MEPSfunHygienegR,
    MEPSfunUtensilR,
    MEPSfunShirtR,
    MEPSfunHairR,
    MEPSfunPutShoesR,
    MotionFlexRR,
    minR
  );

  const MepsLFSum = sumAll(
    MEPSElbowPainL,
    MEPSfunHygienegL,
    MEPSFfunUtensilL,
    MEPSfunShirtL,
    MEPSfunHairL,
    MEPSfunPutShoesL,
    MotionFlexLR,
    minL
  );

  const json1 = {
    MepsRFSum,
    MepsLFSum,
  };
  let doctor_id=sessionStorage.getItem("doctor_id")
  const [Scores, setScores] = useState([]);
  const GetScores = () => {
    axiosInstance.current
      .get(
        `/extapp/doctors/getSpecialityScoresSettingsOfUser/${dId?dId:doctor_id}?type_id=22`
      )
      .then((res) => {
        setScores(res.data.data);
      });
  };
  useEffect(() => {
    if (chk) {
      GetScores();
    }
  }, [chk]);
  let MEPSstatus = findScoreStatus("MEPS", Scores);

  const updateDate1 = async () => {
    const response = await axiosInstance.current.put(
      `extapp/forms/update_total_scores?resource_id=${rId}`,
      json1,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  };
  const [displayConfirmationModal, setDisplayConfirmationModal] =
  useState(false);
  const json = useSelector((state) => state.elbowScore.doctorFeedback);

  // --
  const [msg, setMsg] = useState('');
  const [msgType, setmsgType] = useState('');
  const updateDate = async () => {
    let response = await axiosInstance.current.put(
      `extapp/forms/update_doctor_feedback?resource_id=${rId}&action=1&ak_dc=${dId}&edit=1`,
      json,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
      setMsg(translation.update)
      setmsgType('success')
      setTimeout(() => {
        setMsg('')
        setmsgType('success')
      }, 2000);
      updateDate1();
      // window.open(process.env.REACT_APP_DOCTOR_URL + "patient-list", "_blank");
      // window.close();
      setDisplayConfirmationModal(true)
    }
  };
  const handleValgusR = (e) => {
    setValgusR(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "ValgusR",
        value: e,
      })
    );
  };

  const handleValgusL = (e) => {
    setValgusL(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "ValgusL",
        value: e,
      })
    );
  };
  const handleVarusR = (e) => {
    setVarusR(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "VarusR",
        value: e,
      })
    );
  };
  const handleVarusL = (e) => {
    setVarusL(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "VarusL",
        value: e,
      })
    );
  };
  const handlePosterolateralR = (e) => {
    setPosterolateralR(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "PosterolateralR",
        value: e,
      })
    );
  };
  const handlePosterolateralL = (e) => {
    setPosterolateralL(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "PosterolateralL",
        value: e,
      })
    );
  };
  const handleFlexionlR = (e) => {
    setFlexionlR(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "flexionlR",
        value: e,
      })
    );
  };
  const handleFlexionlL = (e) => {
    setFlexionlL(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "flexionlL",
        value: e,
      })
    );
  };

  const handleExtensionR = (e) => {
    setExtensionR(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "ExtensionR",
        value: e,
      })
    );
  };
  const handleExtensionL = (e) => {
    setExtensionL(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "ExtensionL",
        value: e,
      })
    );
  };
  const handlePronationR = (e) => {
    setPronationR(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "PronationR",
        value: e,
      })
    );
  };
  const handlePronationL = (e) => {
    setPronationL(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "PronationL",
        value: e,
      })
    );
  };

  const handleSupinationR = (e) => {
    setSupinationR(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "SupinationR",
        value: e,
      })
    );
  };
  const handleSupinationL = (e) => {
    setSupinationL(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "SupinationL",
        value: e,
      })
    );
  };
  const handlestrengthR = (e) => {
    setstrengthR(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "strengthR",
        value: e,
      })
    );
  };
  const handlestrengthL = (e) => {
    setstrengthL(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "strengthL",
        value: e,
      })
    );
  };
  const handlehandleElbowPainR = (e) => {
    setElbowPainR(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "elbowPainR",
        value: e,
      })
    );
  };
  const handlehandleElbowPainL = (e) => {
    setElbowPainL(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "elbowPainL",
        value: e,
      })
    );
  };

  const handleOtherR = (e) => {
    setOtherR(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "OtherR",
        value: e,
      })
    );
  };
  const handleOtherL = (e) => {
    setOtherL(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "OtherL",
        value: e,
      })
    );
  };

  const handleImpingementR = (e) => {
    setImpingementR(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "ImpingementR",
        value: e,
      })
    );
  };
  const handleImpingementL = (e) => {
    setImpingementL(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "ImpingementL",
        value: e,
      })
    );
  };
  const handleImpingementPainR = (e) => {
    setImpingementPainR(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "ImpingementPainR",
        value: e,
      })
    );
  };
  const handleImpingementPainL = (e) => {
    setImpingementPainL(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "ImpingementPainL",
        value: e,
      })
    );
  };
  const handleWristR = (e) => {
    setWristR(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "wristR",
        value: e,
      })
    );
  };
  const handleWristL = (e) => {
    setWristL(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "wristL",
        value: e,
      })
    );
  };
  const handleWristfR = (e) => {
    setWristfR(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "wristfR",
        value: e,
      })
    );
  };
  const handleWristfL = (e) => {
    setWristfL(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "wristfL",
        value: e,
      })
    );
  };
  const handleFingerR = (e) => {
    setFingerR(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "fingerR",
        value: e,
      })
    );
  };
  const handleFingerL = (e) => {
    setFingerL(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "fingerL",
        value: e,
      })
    );
  };
  const handleFingerFR = (e) => {
    setFingerFR(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "fingerFR",
        value: e,
      })
    );
  };
  const handleFingerFL = (e) => {
    setFingerFL(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "fingerFL",
        value: e,
      })
    );
  };
  const handleWpronationR = (e) => {
    setWpronationR(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "wpronationR",
        value: e,
      })
    );
  };
  const handleWpronationL = (e) => {
    setWpronationL(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "wpronationL",
        value: e,
      })
    );
  };
  const handleWSupinationR = (e) => {
    setWSupinationR(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "wSupinationR",
        value: e,
      })
    );
  };
  const handleWSupinationL = (e) => {
    setWSupinationL(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "wSupinationL",
        value: e,
      })
    );
  };

  const handleTinelsR = (e) => {
    setTinelsR(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "TinelsR",
        value: e,
      })
    );
  };
  const handleTinelsL = (e) => {
    setTinelsL(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "TinelsL",
        value: e,
      })
    );
  };
  const handleStretchR = (e) => {
    setStretchR(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "StretchR",
        value: e,
      })
    );
  };
  const handleStretchL = (e) => {
    setStretchL(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "StretchL",
        value: e,
      })
    );
  };
  const handleFromentR = (e) => {
    setFromentR(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "FromentR",
        value: e,
      })
    );
  };
  const handleFromentL = (e) => {
    setFromentL(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "FromentL",
        value: e,
      })
    );
  };
  const handleJeanneR = (e) => {
    setJeanneR(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "JeanneR",
        value: e,
      })
    );
  };
  const handleJeanneL = (e) => {
    setJeanneL(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "JeanneL",
        value: e,
      })
    );
  };
  const handleWartenbergR = (e) => {
    setWartenbergR(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "WartenbergR",
        value: e,
      })
    );
  };
  const handleWartenbergL = (e) => {
    setWartenbergL(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "WartenbergL",
        value: e,
      })
    );
  };
  const handleMasseR = (e) => {
    setMasseR(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "MasseR",
        value: e,
      })
    );
  };
  const handleMasseL = (e) => {
    setMasseL(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "MasseL",
        value: e,
      })
    );
  };

  const ir4ChangeR = (e) => {
    setIr4Right(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "ir4Right",
        value: e,
      })
    );
  };
  const ir4ChangeL = (e) => {
    setIr4Left(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "ir4Left",
        value: e,
      })
    );
  };

  const handlerightpspecify = (e) => {
    setrightpspecify(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "rightpspecify",
        value: e,
      })
    );
  };

  const handlerightaspecify = (e) => {
    setrightaspecify(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "rightaspecify",
        value: e,
      })
    );
  };

  const handleleftpspecify = (e) => {
    setleftpspecify(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "leftpspecify",
        value: e,
      })
    );
  };
  const handleleftaspecify = (e) => {
    setleftaspecify(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "leftaspecify",
        value: e,
      })
    );
  };

  const handleleOtherFindingsR = (e) => {
    setOtherFindingsR(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "otherFindingsR",
        value: e,
      })
    );
  };

  const handleleOtherFindingsL = (e) => {
    setOtherFindingsL(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "otherFindingsL",
        value: e,
      })
    );
  };

  // useEffect(() => {
  //   if(ir4Left==="1"){
  //   dispatch(
  //     updateElbowFeedbackScores({
  //       key: "rightpspecify",
  //       value: "",
  //     })
  //   );
  //   dispatch(
  //     updateElbowFeedbackScores({
  //       key: "rightaspecify",
  //       value: "",
  //     })
  //   );
  //   }
  // }, [ir4Left])

  // useEffect(() => {
  //   if(ir4Right==="1"){
  //   dispatch(
  //     updateElbowFeedbackScores({
  //       key: "leftpspecify",
  //       value: "",
  //     })
  //   );
  //   dispatch(
  //     updateElbowFeedbackScores({
  //       key: "leftaspecify",
  //       value: "",
  //     })
  //   );
  //   }
  // }, [ir4Right])

  // Others Limiting checkbox
  const handleOtherjointsLimitsL = (e) => {
    setOtherjointsLimitsL(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "OtherjointsLimitsL",
        value: e,
      })
    );
  };

  const handleOtherjointsLimitwL = (e) => {
    setOtherjointsLimitwL(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "OtherjointsLimitwL",
        value: e,
      })
    );
  };

  const handleOtherjointsLimitsR = (e) => {
    setOtherjointsLimitsR(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "OtherjointsLimitsR",
        value: e,
      })
    );
  };

  const handleOtherjointsLimitwR = (e) => {
    setOtherjointsLimitwR(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "OtherjointsLimitwR",
        value: e,
      })
    );
  };

  // Others limiting input fields

  const handleshoulderinputL = (e) => {
    setshoulderinputL(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "shoulderinputL",
        value: e,
      })
    );
  };

  const handleWristinputL = (e) => {
    setWristinputL(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "WristinputL",
        value: e,
      })
    );
  };

  const handleshoulderinputR = (e) => {
    setshoulderinputR(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "shoulderinputR",
        value: e,
      })
    );
  };

  const handlelewristinputR = (e) => {
    setwristinputR(e);
    dispatch(
      updateElbowFeedbackScores({
        key: "wristinputR",
        value: e,
      })
    );
  };


  useEffect(()=>{
    const updateDate2 = async () => {
    const response = await axiosInstance.current.put(
      `extapp/forms/update_doctor_feedback?resource_id=${rId}&action=0&ak_dc=${dId}`,
      json,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200) {

    }
    }
    if(chk){
      if(doctorEnable !== "true"){
    updateDate2()
    updateDate1()
    }
    }
  },[json,json1])

  const [error, setError] = useState(false);
  const handleSubmit = () => {
    if (MEPSstatus) {
      if (ValgusL === "") {
        setError(true);
        window.scrollTo(0, 0);
      }
      if (ValgusR === "") {
        setError(true);
        window.scrollTo(0, 0);
      }
      if (VarusL === "") {
        setError(true);
        window.scrollTo(0, 0);
      }
      if (VarusR === "") {
        setError(true);
        window.scrollTo(0, 0);
      }
      if (PosterolateralL === "") {
        setError(true);
        window.scrollTo(0, 0);
      }
      if (PosterolateralR === "") {
        setError(true);
        window.scrollTo(0, 0);
      } else if (
        ValgusL !== "" &&
        ValgusR !== "" &&
        VarusL !== "" &&
        VarusR !== "" &&
        PosterolateralL !== "" &&
        PosterolateralR !== ""
      ) {
        updateDate();
        updateDate1();
      }
      return;
    } else {
      updateDate();
      updateDate1();
    }
    // updateDate();
    // updateDate1();
  };
  // -----
  const [getId, setGetId] = useState("");
  useEffect(() => {
    // Get the Gait section element by its ID
    const gaitSection = document.getElementById(getId);
    console.log(gaitSection, "gaitSection");
    // Scroll to the Gait section
    if (gaitSection) {
      const yOffset = gaitSection.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: yOffset - window.innerHeight / 10,
        behavior: "smooth",
      });
    }
    console.log(getId, "getId");
  }, [getId]);

  return (
    <>
    {isLoading ? (
      <LoadingSpinner/>
    ):
    (
      <>



      <div class="d-flex justify-content-end">
        <DoctorNameCustom />
      </div>
      <div className="patient_assessment_form bg_white pd_10 pt_50">
        {doctorEnable === "true" && <ElbowTab rId={rId} />}
        <CustomToaster msg={msg} msgType={msgType} />
        
     
      </div>
      <div className="patient_assessment_form mb_100 bg_white pd_10 pt_50 content_fx_w">
        {/* <AssessmentHeader /> */}
        <HeaderInnerBreadcrumb />

        <div className="row">
          <div className="col-2 ">
            <div
              className="sticky "
              style={{
                position: "sticky",
                top: "75px",
              }}
            >
              <FormVerticalMenu setGetId={setGetId} />
            </div>
          </div>

          <div className="col-10">
            <div className="input_tab_box mb_30" id="stability">
              <h4 className="range_sl_title" >
                {translation.STABILITY}
              </h4>
              <div className="">
                <div className="row">
                  <div className="col-md-6 col-sm-6 function_head">
                    {/* <label>
                   {translation.rate_the_severity_of_the_following_STABILITY_in_the}
                  </label>{" "} */}
                    <br />
                    <span>
                      (<strong>0</strong> = {translation.no_instability}{" "}
                      <strong>1</strong> =
                      {translation.mild_laxity_with_good_endpoint}
                      <strong> 2</strong> ={" "}
                      {translation.moderate_laxity_endpoint};{" "}
                      <strong> 3</strong> = {translation.gross_instability} )
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-6 mb_10">
                    <KneeScoreRangeSlider
                      maxnum="3"
                      label={translation.Valgus_R + (MEPSstatus ? "*" : "")}
                      classes=""
                      value={ValgusR}
                      onChange={handleValgusR}
                      sliderData={[
                        { score: "0", label: translation.no_instability },
                        {
                          score: "1",
                          label: translation.mild_laxity_with_good_endpoint,
                        },
                        {
                          score: "2",
                          label: translation.moderate_laxity_endpoint,
                        },
                        { score: "3", label: translation.gross_instability },
                      ]}
                      error={error}
                    />
                    {/* {ValgusR === "" && (
                      <>
                        {error && (

                          <span className="text-danger">This field is required</span>
                        )}
                      </>
                    )} */}
                  </div>
                  <div className="col-md-6 col-sm-6 mb_10">
                    <KneeScoreRangeSlider
                      maxnum="3"
                      label={translation.Valgus_L + (MEPSstatus ? "*" : "")}
                      classes=""
                      value={ValgusL}
                      onChange={handleValgusL}
                      sliderData={[
                        { score: "0", label: translation.no_instability },
                        {
                          score: "1",
                          label: translation.mild_laxity_with_good_endpoint,
                        },
                        {
                          score: "2",
                          label: translation.moderate_laxity_endpoint,
                        },
                        { score: "3", label: translation.gross_instability },
                      ]}
                      error={error}
                    />
                    {/* {ValgusL === "" && (
                      <>
                        {error && (

                          <span className="text-danger">This field is required</span>
                        )}
                      </>
                    )} */}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-6 mb_10">
                    <KneeScoreRangeSlider
                      maxnum="3"
                      label={translation.Varus_R + (MEPSstatus ? "*" : "")}
                      classes=""
                      value={VarusR}
                      onChange={handleVarusR}
                      sliderData={[
                        { score: "0", label: translation.no_instability },
                        {
                          score: "1",
                          label: translation.mild_laxity_with_good_endpoint,
                        },
                        {
                          score: "2",
                          label: translation.moderate_laxity_endpoint,
                        },
                        { score: "3", label: translation.gross_instability },
                      ]}
                      error={error}
                    />
                    {/* {VarusR === "" && (
                      <>
                        {error && (

                          <span className="text-danger">This field is required</span>
                        )}
                      </>
                    )} */}
                  </div>
                  <div className="col-md-6 col-sm-6 mb_10">
                    <KneeScoreRangeSlider
                      maxnum="3"
                      label={translation.Varus_L + (MEPSstatus ? "*" : "")}
                      classes=""
                      value={VarusL}
                      onChange={handleVarusL}
                      sliderData={[
                        { score: "0", label: translation.no_instability },
                        {
                          score: "1",
                          label: translation.mild_laxity_with_good_endpoint,
                        },
                        {
                          score: "2",
                          label: translation.moderate_laxity_endpoint,
                        },
                        { score: "3", label: translation.gross_instability },
                      ]}
                      error={error}
                    />
                    {/* {VarusL === "" && (
                      <>
                        {error && (

                          <span className="text-danger">This field is required</span>
                        )}
                      </>
                    )} */}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 col-sm-6 mb_10">
                    <KneeScoreRangeSlider
                      maxnum="3"
                      label={
                        translation.Posterolateral_rotatory_R +
                        (MEPSstatus ? "*" : "")
                      }
                      classes=""
                      value={PosterolateralR}
                      onChange={handlePosterolateralR}
                      sliderData={[
                        { score: "0", label: translation.no_instability },
                        {
                          score: "1",
                          label: translation.mild_laxity_with_good_endpoint,
                        },
                        {
                          score: "2",
                          label: translation.moderate_laxity_endpoint,
                        },
                        { score: "3", label: translation.gross_instability },
                      ]}
                      error={error}
                    />
                    {/* {PosterolateralR === "" && (
                      <>
                        {error && (

                          <span className="text-danger">This field is required</span>
                        )}
                      </>
                    )} */}
                  </div>
                  <div className="col-md-6 col-sm-6 mb_10">
                    <KneeScoreRangeSlider
                      maxnum="3"
                      label={
                        translation.Posterolateral_rotatory_L +
                        (MEPSstatus ? "*" : "")
                      }
                      classes=""
                      value={PosterolateralL}
                      onChange={handlePosterolateralL}
                      sliderData={[
                        { score: "0", label: translation.no_instability },
                        {
                          score: "1",
                          label: translation.mild_laxity_with_good_endpoint,
                        },
                        {
                          score: "2",
                          label: translation.moderate_laxity_endpoint,
                        },
                        { score: "3", label: translation.gross_instability },
                      ]}
                      error={error}
                    />
                    {/* {PosterolateralL === "" && (
                      <>
                        {error && (

                          <span className="text-danger">This field is required</span>
                        )}
                      </>
                    )} */}
                  </div>
                </div>
              </div>
            </div>

            <div className="input_tab_box mb_30" id="strength">
              <h4 className="range_sl_title" >
                {translation.STRENGTH}
              </h4>
              <div className="">
                <div className="row">
                  <div className="col-md-12 col-sm-12 function_head">
                    <label className="text-capitalize">
                      {/* {translation.Please_rate_the_severity_of_the_following_STRENGTH_in_the_last_week} */}
                    </label>{" "}
                    <br />
                    <span>
                      (<strong>0</strong> = {translation.no_contraction}{" "}
                      <strong>1</strong> ={translation.flicker}
                      <strong> 2</strong> ={" "}
                      {translation.movement_gravity_eliminated}
                      <strong> 3</strong> ={" "}
                      {translation.movement_against_gravity} <strong>4</strong>{" "}
                      = {translation.movement_some_resistance}{" "}
                      <strong>4</strong> = {translation.normal_power} )
                    </span>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 col-sm-6 mb_10">
                    <RadioSwitch
                      labelLeft="No"
                      labelRight="Yes"
                      idLeft="PainRNo"
                      idRight="PainRYes"
                      name="elbowPainR"
                      title={translation.Testing_affected_by_pain_R}
                      styleClass=""
                      value={elbowPainR}
                      onChange={handlehandleElbowPainR}
                      placeholder="select"
                    />
                  </div>
                  <div className="col-md-6 col-sm-6 mb_10">
                    <RadioSwitch
                      labelLeft="No"
                      labelRight="Yes"
                      idLeft="PainLNo"
                      idRight="PainLYes"
                      name="elbowPainL"
                      title={translation.Testing_affected_by_pain_L}
                      styleClass=""
                      value={elbowPainL}
                      onChange={handlehandleElbowPainL}
                      placeholder="select"
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 col-sm-6 mb_10">
                    <ElbowFeedbackScoreRangeSlider
                      label={translation.Flexion_R}
                      classes=""
                      value={flexionlR}
                      onChange={handleFlexionlR}
                      sliderData={[
                        { score: "0", label: translation.no_contraction },
                        { score: "1", label: translation.flicker },
                        {
                          score: "2",
                          label: translation.movement_gravity_eliminated,
                        },
                        {
                          score: "3",
                          label: translation.movement_against_gravity,
                        },
                        {
                          score: "4",
                          label: translation.movement_some_resistance,
                        },
                        { score: "5", label: translation.normal_power },
                      ]}
                    />
                  </div>
                  <div className="col-md-6 col-sm-6 mb_10">
                    <ElbowFeedbackScoreRangeSlider
                      label={translation.Flexion_L}
                      classes=""
                      value={flexionlL}
                      onChange={handleFlexionlL}
                      sliderData={[
                        { score: "0", label: translation.no_contraction },
                        { score: "1", label: translation.flicker },
                        {
                          score: "2",
                          label: translation.movement_gravity_eliminated,
                        },
                        {
                          score: "3",
                          label: translation.movement_against_gravity,
                        },
                        {
                          score: "4",
                          label: translation.movement_some_resistance,
                        },
                        { score: "5", label: translation.normal_power },
                      ]}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-6 mb_10">
                    <ElbowFeedbackScoreRangeSlider
                      label={translation.Extension_R}
                      classes=""
                      value={ExtensionR}
                      onChange={handleExtensionR}
                      sliderData={[
                        { score: "0", label: translation.no_contraction },
                        { score: "1", label: translation.flicker },
                        {
                          score: "2",
                          label: translation.movement_gravity_eliminated,
                        },
                        {
                          score: "3",
                          label: translation.movement_against_gravity,
                        },
                        {
                          score: "4",
                          label: translation.movement_some_resistance,
                        },
                        { score: "5", label: translation.normal_power },
                      ]}
                    />
                  </div>
                  <div className="col-md-6 col-sm-6 mb_10">
                    <ElbowFeedbackScoreRangeSlider
                      label={translation.Extension_L}
                      classes=""
                      value={ExtensionL}
                      onChange={handleExtensionL}
                      sliderData={[
                        { score: "0", label: translation.no_contraction },
                        { score: "1", label: translation.flicker },
                        {
                          score: "2",
                          label: translation.movement_gravity_eliminated,
                        },
                        {
                          score: "3",
                          label: translation.movement_against_gravity,
                        },
                        {
                          score: "4",
                          label: translation.movement_some_resistance,
                        },
                        { score: "5", label: translation.normal_power },
                      ]}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-6 mb_10">
                    <ElbowFeedbackScoreRangeSlider
                      label={translation.Pronation_R}
                      classes=""
                      value={PronationR}
                      onChange={handlePronationR}
                      sliderData={[
                        { score: "0", label: translation.no_contraction },
                        { score: "1", label: translation.flicker },
                        {
                          score: "2",
                          label: translation.movement_gravity_eliminated,
                        },
                        {
                          score: "3",
                          label: translation.movement_against_gravity,
                        },
                        {
                          score: "4",
                          label: translation.movement_some_resistance,
                        },
                        { score: "5", label: translation.normal_power },
                      ]}
                    />
                  </div>
                  <div className="col-md-6 col-sm-6 mb_10">
                    <ElbowFeedbackScoreRangeSlider
                      label={translation.Pronation_L}
                      classes=""
                      value={PronationL}
                      onChange={handlePronationL}
                      sliderData={[
                        { score: "0", label: translation.no_contraction },
                        { score: "1", label: translation.flicker },
                        {
                          score: "2",
                          label: translation.movement_gravity_eliminated,
                        },
                        {
                          score: "3",
                          label: translation.movement_against_gravity,
                        },
                        {
                          score: "4",
                          label: translation.movement_some_resistance,
                        },
                        { score: "5", label: translation.normal_power },
                      ]}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-6 mb_10">
                    <ElbowFeedbackScoreRangeSlider
                      label={translation.Supination_R}
                      classes=""
                      value={SupinationR}
                      onChange={handleSupinationR}
                      sliderData={[
                        { score: "0", label: translation.no_contraction },
                        { score: "1", label: translation.flicker },
                        {
                          score: "2",
                          label: translation.movement_gravity_eliminated,
                        },
                        {
                          score: "3",
                          label: translation.movement_against_gravity,
                        },
                        {
                          score: "4",
                          label: translation.movement_some_resistance,
                        },
                        { score: "5", label: translation.normal_power },
                      ]}
                    />
                  </div>
                  <div className="col-md-6 col-sm-6 mb_10">
                    <ElbowFeedbackScoreRangeSlider
                      label={translation.Supination_L}
                      classes=""
                      value={SupinationL}
                      onChange={handleSupinationL}
                      sliderData={[
                        { score: "0", label: translation.no_contraction },
                        { score: "1", label: translation.flicker },
                        {
                          score: "2",
                          label: translation.movement_gravity_eliminated,
                        },
                        {
                          score: "3",
                          label: translation.movement_against_gravity,
                        },
                        {
                          score: "4",
                          label: translation.movement_some_resistance,
                        },
                        { score: "5", label: translation.normal_power },
                      ]}
                    />
                  </div>
                </div>

                <div className="row mb_30 mt-5">
                  <div className="col-md-6 col-sm-6">
                    <div className="col-md-12 col-sm-12">
                      <h4 className="range_sl_title mb-4" id="signs">
                        {translation.Grip_Length}
                      </h4>
                      <AssessmentKgKgWidget
                        label={translation.Right_strength}
                        onChangeK={handlestrengthL}
                        onChangeP={handlestrengthR}
                        kg={strengthL}
                        power={strengthR}
                        leftArmkgAction="1"
                        rightArmkgAction="2"
                        // labelL={translation.Left_strength}
                        labelL="Left Hand"
                        // labelR={translation.Right_strength}
                        labelR="Right Hand"
                        nameL="strengthKgR"
                        nameR="strengthKgR"
                      />
                      {/* <AssessmentKgWidget
                      kgAction="true"
                      onChangeK={handlestrengthR}
                      label={translation.Right_strength}
                      nameL="strengthKgR"
                    />
                     */}
                    </div>
                  </div>
                  {/* <div className="col-md-6 col-sm-6 ">
                  <div className="col-md-6 col-sm-6 ">
                    <AssessmentPowerKgWidget
                      kgAction="true"
                      onChangeK={handlestrengthL}
                      label={translation.Left_strength}
                      nameL="strengthKgR"
                    />
                  </div>
                </div> */}
                </div>
              </div>
            </div>

            <div className="input_tab_box mb_30" id="signs">
              <h4 className="range_sl_title" >
                {translation.Nerve_Assessment}
              </h4>

              <div className="row">
                <div className="col-md-6 col-sm-6 mb_10">
                  <RadioSwitch
                    labelLeft="Negative"
                    labelRight="Positive"
                    idLeft="TinelsR"
                    idRight="TinelsL"
                    name="TinelsR"
                    title={translation.Ulnar_nerve_tinels_R}
                    styleClass=""
                    value={TinelsR}
                    onChange={handleTinelsR}
                    placeholder="select"
                  />
                </div>
                <div className="col-md-6 col-sm-6 mb_10">
                  <RadioSwitch
                    labelLeft="Negative"
                    labelRight="Positive"
                    idLeft="TinelsLL"
                    idRight="TinelsLR"
                    name="TinelsLL"
                    title={translation.Ulnar_nerve_tinels_L}
                    styleClass=""
                    value={TinelsL}
                    onChange={handleTinelsL}
                    placeholder="select"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 col-sm-6 mb_10">
                  <RadioSwitch
                    labelLeft="Negative"
                    labelRight="Positive"
                    idLeft="StretchR"
                    idRight="StretchL"
                    name="StretchR"
                    title={translation.Cubital_tunnel_stretch_test_R}
                    styleClass=""
                    value={StretchR}
                    onChange={handleStretchR}
                    placeholder="select"
                  />
                </div>
                <div className="col-md-6 col-sm-6 mb_10">
                  <RadioSwitch
                    labelLeft="Negative"
                    labelRight="Positive"
                    idLeft="StretchLL"
                    idRight="StretchLR"
                    name="StretchLL"
                    title={translation.Cubital_tunnel_stretch_test_L}
                    styleClass=""
                    value={StretchL}
                    onChange={handleStretchL}
                    placeholder="select"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 col-sm-6 mb_10">
                  <RadioSwitch
                    labelLeft="Negative"
                    labelRight="Positive"
                    idLeft="FromentR"
                    idRight="FromentL"
                    name="FromentR"
                    title={translation.Froment_sign_R}
                    styleClass=""
                    value={FromentR}
                    onChange={handleFromentR}
                    placeholder="select"
                  />
                </div>
                <div className="col-md-6 col-sm-6 mb_10">
                  <RadioSwitch
                    labelLeft="Negative"
                    labelRight="Positive"
                    idLeft="FromentLL"
                    idRight="FromentLR"
                    name="FromentLL"
                    title={translation.Froment_sign_L}
                    styleClass=""
                    value={FromentL}
                    onChange={handleFromentL}
                    placeholder="select"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 col-sm-6 mb_10">
                  <RadioSwitch
                    labelLeft="Negative"
                    labelRight="Positive"
                    idLeft="JeanneR"
                    idRight="JeanneL"
                    name="JeanneR"
                    title={translation.Jeanne_sign_R}
                    styleClass=""
                    value={JeanneR}
                    onChange={handleJeanneR}
                    placeholder="select"
                  />
                </div>
                <div className="col-md-6 col-sm-6 mb_10">
                  <RadioSwitch
                    labelLeft="Negative"
                    labelRight="Positive"
                    idLeft="JeanneLL"
                    idRight="JeanneLR"
                    name="JeanneLL"
                    title={translation.Jeanne_sign_L}
                    styleClass=""
                    value={JeanneL}
                    onChange={handleJeanneL}
                    placeholder="select"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 col-sm-6 mb_10">
                  <RadioSwitch
                    labelLeft="Negative"
                    labelRight="Positive"
                    idLeft="WartenbergR"
                    idRight="WartenbergL"
                    name="WartenbergR"
                    title={translation.Wartenberg_sign_R}
                    styleClass=""
                    value={WartenbergR}
                    onChange={handleWartenbergR}
                    placeholder="select"
                  />
                </div>
                <div className="col-md-6 col-sm-6 mb_10">
                  <RadioSwitch
                    labelLeft="Negative"
                    labelRight="Positive"
                    idLeft="WartenbergLL"
                    idRight="WartenbergLR"
                    name="WartenbergLL"
                    title={translation.Wartenberg_sign_L}
                    styleClass=""
                    value={WartenbergL}
                    onChange={handleWartenbergL}
                    placeholder="select"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 col-sm-6 mb_10">
                  <RadioSwitch
                    labelLeft="Negative"
                    labelRight="Positive"
                    idLeft="MasseR"
                    idRight="MasseL"
                    name="MasseR"
                    title={translation.Masse_sign_R}
                    styleClass=""
                    value={MasseR}
                    onChange={handleMasseR}
                    placeholder="select"
                  />
                </div>
                <div className="col-md-6 col-sm-6 mb_10">
                  <RadioSwitch
                    labelLeft="Negative"
                    labelRight="Positive"
                    idLeft="MasseLL"
                    idRight="MasseLR"
                    name="MasseLL"
                    title={translation.Masse_sign_L}
                    styleClass=""
                    value={MasseL}
                    onChange={handleMasseL}
                    placeholder="select"
                  />
                </div>
              </div>
            </div>
            <div className="input_tab_box mb_30">
              <h4 className="range_sl_title" id="signs">
                {translation.Other_Special_tests}
              </h4>
              <div className="">
                <div className="row">
                  <div className="col-md-12 col-sm-12 function_head">
                    {/* <label className="text-capitalize">
                   {translation.Please_rate_the_severity_of_the_following_STRENGTH_in_the_last_week}
                  </label>{" "} */}
                    <br />
                    <span>
                      (<strong>0</strong> = none <strong>1</strong> = mild
                      <strong> 2</strong> = moderate
                      <strong> 3</strong> =severe )
                    </span>
                  </div>
                </div>

                <div className="row">
                  {/* {otherText !== "" && (
                    <>
                      <div className="col-md-6 col-sm-6 mb_10">
                        <KneeScoreRangeSlider
                          label={translation.Right}
                          classes=""
                          value={OtherR}
                          onChange={handleOtherR}
                          sliderData={[
                            { score: "0", label: "none" },
                            { score: "1", label: "mild" },
                            { score: "2", label: "moderate" },
                            { score: "3", label: "severe" },
                          ]}
                        />
                      </div>
                      <div className="col-md-6 col-sm-6 mb_10">
                        <KneeScoreRangeSlider
                          label={translation.left}
                          classes=""
                          value={OtherL}
                          onChange={handleOtherL}
                          sliderData={[
                            { score: "0", label: "none" },
                            { score: "1", label: "mild" },
                            { score: "2", label: "moderate" },
                            { score: "3", label: "severe" },
                          ]}
                        />
                      </div>
                    </> */}
                  {/* )} */}
                </div>

                <div className="row">
                  <div className="col-md-6 col-sm-6 mb_10">
                    <KneeScoreRangeSlider
                      maxnum="3"
                      label={translation.Impingement_pain_in_flexion_R}
                      classes=""
                      value={ImpingementR}
                      onChange={handleImpingementR}
                      sliderData={[
                        { score: "0", label: "none" },
                        { score: "1", label: "mild" },
                        { score: "2", label: "moderate" },
                        { score: "3", label: "severe" },
                      ]}
                    />
                  </div>
                  <div className="col-md-6 col-sm-6 mb_10">
                    <KneeScoreRangeSlider
                      maxnum="3"
                      label={translation.Impingement_pain_in_flexion_L}
                      classes=""
                      value={ImpingementL}
                      onChange={handleImpingementL}
                      sliderData={[
                        { score: "0", label: "none" },
                        { score: "1", label: "mild" },
                        { score: "2", label: "moderate" },
                        { score: "3", label: "severe" },
                      ]}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-6 mb_10">
                    <KneeScoreRangeSlider
                      maxnum="3"
                      label={translation.Impingement_pain_in_extension_R}
                      classes=""
                      value={ImpingementPainR}
                      onChange={handleImpingementPainR}
                      sliderData={[
                        { score: "0", label: "none" },
                        { score: "1", label: "mild" },
                        { score: "2", label: "moderate" },
                        { score: "3", label: "severe" },
                      ]}
                    />
                  </div>
                  <div className="col-md-6 col-sm-6 mb_10">
                    <KneeScoreRangeSlider
                      maxnum="3"
                      label={translation.Impingement_pain_in_extension_L}
                      classes=""
                      value={ImpingementPainL}
                      onChange={handleImpingementPainL}
                      sliderData={[
                        { score: "0", label: "none" },
                        { score: "1", label: "mild" },
                        { score: "2", label: "moderate" },
                        { score: "3", label: "severe" },
                      ]}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 col-sm-6 mb_10">
                    <RadioSwitch
                      labelLeft="Negative"
                      labelRight="Positive"
                      idLeft="wristR"
                      idRight="wristL"
                      name="wristR"
                      title={translation.Pain_on_resisted_wrist_extension_R}
                      styleClass=""
                      value={wristR}
                      onChange={handleWristR}
                      placeholder="select"
                    />
                  </div>
                  <div className="col-md-6 col-sm-6 mb_10">
                    <RadioSwitch
                      labelLeft="Negative"
                      labelRight="Positive"
                      idLeft="wristLL"
                      idRight="wristLR"
                      name="wristLL"
                      title={translation.Pain_on_resisted_wrist_extension_L}
                      styleClass=""
                      value={wristL}
                      onChange={handleWristL}
                      placeholder="select"
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 col-sm-6 mb_10">
                    <RadioSwitch
                      labelLeft="Negative"
                      labelRight="Positive"
                      idLeft="wristfR"
                      idRight="wristfL"
                      name="wristfR"
                      title={translation.Pain_on_resisted_wrist_flexion_R}
                      styleClass=""
                      value={wristfR}
                      onChange={handleWristfR}
                      placeholder="select"
                    />
                  </div>
                  <div className="col-md-6 col-sm-6 mb_10">
                    <RadioSwitch
                      labelLeft="Negative"
                      labelRight="Positive"
                      idLeft="wristfLL"
                      idRight="wristfLR"
                      name="wristfLL"
                      title={translation.Pain_on_resisted_wrist_flexion_L}
                      styleClass=""
                      value={wristfL}
                      onChange={handleWristfL}
                      placeholder="select"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-6 mb_10">
                    <RadioSwitch
                      labelLeft="Negative"
                      labelRight="Positive"
                      idLeft="fingerR"
                      idRight="fingerL"
                      name="fingerR"
                      title={
                        translation.Pain_on_resisted_long_finger_extension_R
                      }
                      styleClass=""
                      value={fingerR}
                      onChange={handleFingerR}
                      placeholder="select"
                    />
                  </div>
                  <div className="col-md-6 col-sm-6 mb_10">
                    <RadioSwitch
                      labelLeft="Negative"
                      labelRight="Positive"
                      idLeft="fingerLL"
                      idRight="fingerLR"
                      name="fingerLL"
                      title={
                        translation.Pain_on_resisted_long_finger_extension_L
                      }
                      styleClass=""
                      value={fingerL}
                      onChange={handleFingerL}
                      placeholder="select"
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 col-sm-6 mb_10">
                    <RadioSwitch
                      labelLeft="Negative"
                      labelRight="Positive"
                      idLeft="FingerR"
                      idRight="FingerL"
                      name="FingerR"
                      title={translation.Pain_on_resisted_long_finger_flexion_R}
                      styleClass=""
                      value={fingerFR}
                      onChange={handleFingerFR}
                      placeholder="select"
                    />
                  </div>
                  <div className="col-md-6 col-sm-6 mb_10">
                    <RadioSwitch
                      labelLeft="Negative"
                      labelRight="Positive"
                      idLeft="FingerLL"
                      idRight="FingerLR"
                      name="FingerLL"
                      title={translation.Pain_on_resisted_long_finger_flexion_L}
                      styleClass=""
                      value={fingerFL}
                      onChange={handleFingerFL}
                      placeholder="select"
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 col-sm-6 mb_10">
                    <RadioSwitch
                      labelLeft="Negative"
                      labelRight="Positive"
                      idLeft="pronationR"
                      idRight="pronationL"
                      name="pronationR"
                      title={translation.Pain_on_resisted_wrist_pronation_R}
                      styleClass=""
                      value={wpronationR}
                      onChange={handleWpronationR}
                      placeholder="select"
                    />
                  </div>
                  <div className="col-md-6 col-sm-6 mb_10">
                    <RadioSwitch
                      labelLeft="Negative"
                      labelRight="Positive"
                      idLeft="pronationLL"
                      idRight="pronationLR"
                      name="pronationLL"
                      title={translation.Pain_on_resisted_wrist_pronation_L}
                      styleClass=""
                      value={wpronationL}
                      onChange={handleWpronationL}
                      placeholder="select"
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 col-sm-6 mb_10">
                    <RadioSwitch
                      labelLeft="Negative"
                      labelRight="Positive"
                      idLeft="SupinationR"
                      idRight="SupinationL"
                      name="SupinationR"
                      title={translation.Pain_on_resisted_wrist_supination_R}
                      styleClass=""
                      value={wSupinationR}
                      onChange={handleWSupinationR}
                      placeholder="select"
                    />
                  </div>
                  <div className="col-md-6 col-sm-6 mb_10">
                    <RadioSwitch
                      labelLeft="Negative"
                      labelRight="Positive"
                      idLeft="SupinationLL"
                      idRight="SupinationLR"
                      name="SupinationLL"
                      title={translation.Pain_on_resisted_wrist_supination_L}
                      styleClass=""
                      value={wSupinationL}
                      onChange={handleWSupinationL}
                      placeholder="select"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="input_tab_box mb_30">
              <div className="row mb_30  ">
                <h4 className="range_sl_title">
                  {translation.Other_joints_limiting_activity}
                </h4>
                <div className="col-md-6 col-sm-6">
                  <div className="row ">
                    <label className="large-text">Right</label>
                    <div className="col-md-12 col-sm-12">
                      <Checkbox
                        label="Shoulder"
                        onChange={handleOtherjointsLimitsL}
                        selected={OtherjointsLimitsL}
                        styleClass="custom_check_box tick f_400 large mb_10"
                        id="shoulderleft"
                      />
                    </div>
                    <div className="col-md-12 col-sm-12">
                      <Checkbox
                        label="Wrist"
                        onChange={handleOtherjointsLimitwL}
                        selected={OtherjointsLimitwL}
                        styleClass="custom_check_box tick f_400 large mb_10"
                        id="Wristleft"
                      />
                    </div>
                  </div>
                  <div className="col-md-12 col-sm-12 ">
                    {OtherjointsLimitsL ||OtherjointsLimitwL ? (
                      <>
                        {/* <h4 className="range_sl_title">{translation.Specify}</h4> */}
                        {/* <label>Specify</label> */}
                        <InputField
                          label=""
                          value={shoulderinputL}
                          type="textarea"
                          placeholder="Describe"
                          styleClass="mxw_100"
                          onChange={handleshoulderinputL}
                        />
                      </>
                    ):""}
                  </div>
                  {/* <div className="col-md-12 col-sm-12 ">
                    {OtherjointsLimitwL && (
                      <>
                        <h4 className="range_sl_title">{translation.Specify}</h4>
                        <label>Specify</label>
                        <InputField
                          label=""
                          value={WristinputL}
                          type="textarea"
                          placeholder="Describe"
                          styleClass="mxw_100"
                          onChange={handleWristinputL}
                        />
                      </>
                    )}
                  </div> */}
                </div>

                <div className="col-md-6 col-sm-6">
                  <div className="row">
                    <label>Left</label>
                    <div className="col-md-12 col-sm-12">
                      <Checkbox
                        label="Shoulder"
                        onChange={handleOtherjointsLimitsR}
                        selected={OtherjointsLimitsR}
                        styleClass="custom_check_box tick f_400 large mb_10"
                        id="shoulderright"
                      />
                    </div>
                    <div className="col-md-12 col-sm-12">
                      <Checkbox
                        label="Wrist"
                        onChange={handleOtherjointsLimitwR}
                        selected={OtherjointsLimitwR}
                        styleClass="custom_check_box tick f_400 large mb_10"
                        id="wristright"
                      />
                    </div>
                  </div>

                  <div className="col-md-12 col-sm-12">
                    {OtherjointsLimitsR || OtherjointsLimitwR ? (
                      <>
                        {/* <h4 className="range_sl_title">{translation.Specify}</h4> */}
                        {/* <label>Specify</label> */}
                        <InputField
                          label=""
                          value={shoulderinputR}
                          type="textarea"
                          placeholder="Describe"
                          styleClass="mxw_1 00"
                          onChange={handleshoulderinputR}
                        />
                      </>
                    ):""}
                  </div>

                  {/* <div className="col-md-12 col-sm-12">
                    {OtherjointsLimitwR && (
                      <>
                        <h4 className="range_sl_title">{translation.Specify}</h4>
                        <label>Specify</label>
                        <InputField
                          label=""
                          value={wristinputR}
                          type="textarea"
                          placeholder="Describe"
                          styleClass="mxw_100"
                          onChange={handlelewristinputR}
                        />
                      </>
                    )}
                  </div> */}
                </div>
              </div>
            </div>

            <div className="input_tab_box mb_30">
              <div className="row mb_30  ">
                <h4 className="range_sl_title">{translation.Other_findings}</h4>

                <div className="col-md-6 col-sm-6">
                  <label>{translation.Right}</label>
                  <InputField
                    label=""
                    value={otherFindingsR === "0" ? "" : otherFindingsR}
                    type="textarea"
                    placeholder="Describe"
                    styleClass="mxw_100"
                    onChange={handleleOtherFindingsR}
                  />{" "}
                </div>
                <div className="col-md-6 col-sm-6">
                  <label>{translation.Left}</label>
                  <InputField
                    label=""
                    value={otherFindingsL === "0" ? "" : otherFindingsL}
                    type="textarea"
                    placeholder="Describe"
                    styleClass="mxw_100"
                    onChange={handleleOtherFindingsL}
                  />{" "}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mb_30 button_box">
          <div className="col-md-6 col-sm-6">
            
            {doctorEnable !== "true" && (
              
              <Link
                to={"/elbow-feedback/" + rId + `?doctor=${dId}&patient=${pId}&is_doctor_form=${doctordata}`}
                className="assm_back_btn"
              >
                <img src={backIcon} alt="" />
                <span>{translation.back_button}</span>
              </Link>
            )}
          </div>
          <div className="col-md-6 col-sm-6 text-end">
            <button
              className="next_btn btn_fill"
              onClick={() => handleSubmit()}
            >
              {translation.submit}
              <span>
                <img src={nextIcon} alt="" />
                <img src={nextWhiteIcon} alt="" />
              </span>
            </button>
          </div>
        </div>
      </div>
      {/* < SubmitModel
     showModal={displayConfirmationModal}
     
     /> */}
      <Promsschudle
     openModal={displayConfirmationModal}
     setDisplayConfirmationModal={setDisplayConfirmationModal}
     type={"22"}
     patientuserID={pId}
     />
      </>
    )}
    </>
  );
};

export default ElbowFeedbackStep2;
