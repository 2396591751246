 export function findScoreStatus(scoreName, scoresArray) {
  if(scoresArray.length !==0){
    for (let i = 0; i < scoresArray.length; i++) {
      const score = scoresArray[i];
      if (score.score_name === scoreName) {
        return score.status;
      }
    }
  }
    return null; 
  }


  export  function returnStrings(arr) {
    let stringArray = [];
    for (let i = 0; i < arr.length; i++) {
      if (typeof arr[i] === "string" && arr[i].trim() !== "") {
        stringArray.push(arr[i]);
      }
    }
    return stringArray.join(", ");
  }