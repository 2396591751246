import React, { useMemo, useState, useEffect } from "react";
import Button from "../custom/button/Button";
import useTransition from "../customHooks/translations";
import upload from "../../images/document-Upload.svg"
import uploadWhite from "../../images/document-UploadWhite.svg"
function Share({ type, rId }) {
  const translation = useTransition();
  const continueRedirect1 = (e) => {
    window.open(
      process.env.REACT_APP_PATIENT_URL + `doctor-code?type=${type}&rId=${rId}`
    );
    setTimeout(() => {
      window.close();
    }, 1000);
  };
  const continueRedirect = (e) => {
    // if (goback === "true") {
    //   window.open(process.env.REACT_APP_DOCTOR_URL + 'patient-list', "_blank");
    //   localStorage.removeItem("URL")

    //   window.close()

    // } else {
    window.open(process.env.REACT_APP_PATIENT_URL, "_blank");
    localStorage.removeItem("URL");

    window.close();

    // }
  };

  return (
    <>

      <div className="row justify-content-center">
        <div className="col-xl-12 col-md-12 col-sm-12 col-12">
          <div className="pt_50 pb_50 text-center border-1 border_code ">
            <div>
              <img src={upload} alt="Share" style={{ width: "50px" }} />
            </div>
            <h4 lassName="mb_20 f_600">
              {translation.Share_results_with_your_doctor}
            </h4>
            {/* <p className="mb_20 f_18">
              {translation.If_you_would_like_to_share_your_results_with}
              <br></br>
              {translation.your_doctor_select_Share_NOW_below}
            </p> */}
            <div className="d-flex justify-content-center">
              <Button
                onClick={continueRedirect}
                value={translation.Share_Later}
                height="45px"
                buttonStyle="btn_outline ms-4 me-4 share_btn"
              />

              <button
                onClick={()=>continueRedirect1()}
                height="45px"
                className="btn_fill share_btn img_white"
              >
                <img
                  src={upload}
                  alt="img"
                  className="mr_15 img_blue "
                />{" "}
                <img
                  src={uploadWhite}
                  alt="img"
                  className="mr_15 img_hover_view"
                />
                {translation.Share_Now}
              </button>
            </div>



          </div>
        </div>
      </div>

    </>
  );
}

export default Share;
