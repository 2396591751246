import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import ActiveIcon from "./Active.png";
import useTranslation from "../../customHooks/translations";
import { useLocation, useParams } from "react-router-dom";
import useAxios from "../../../axiosinstance";

const DeleteConfirmation = ({
  showModal,
  hideModal,
  patientID,
  value,
  confirmModal,
  id,
  type,
  message,
  checkboxValues
}) => {
  const translation = useTranslation();
  const queryParams = new URLSearchParams(window.location.search);
  const pId = queryParams.get("patient");

  const { ptId,rId } = useParams();
  const location = useLocation();
  const { pathname } = location;
  const axiosInstance = useAxios();
  const splitLocation = pathname.split("/");
  const [data, setData] = useState([]);
 console.log(rId,"rId")
  let goback = localStorage.getItem("isdoctorForm");
  // useEffect (()=> {
    const getType = async () => {
      try {
        const response = await axiosInstance.current.get(
          `extapp/forms/get_form_type?resource_id=${rId}`
        );
       if (response.status===200){
        setData(response.data.form_type)
      } 
    }catch(err){
  
    }
  }

  // },[])
  useEffect(()=>{
    getType()
  },[])
 

console.log(checkboxValues?.length,"checkboxValues",checkboxValues);

  const handleContinue = () => {
    if (goback === "true") {
      // window.location.href=process.env.REACT_APP_DOCTOR_URL + 'patient-view/' + pId
      // if(ptId)
      // window.close()
      if (splitLocation[1] === "medical-social") {
        window.location.href =
          process.env.REACT_APP_DOCTOR_URL + "patient-view/" + ptId + `?formid=${data}`;
      } else {
        if(splitLocation[1].search("feedback")!==-1 || splitLocation[1].search('treatment')!==-1){
          if(checkboxValues){
            window.location.href =
            process.env.REACT_APP_DOCTOR_URL + "patient-details/" + patientID +`?formid=${data}`;
            }else{
               window.location.href =process.env.REACT_APP_DOCTOR_URL + "patient-details/" + patientID +`?formid=${data}`;
            // process.env.REACT_APP_DOCTOR_URL + "patient-view/" + patientID + `?formid=${data}`;
            }
         
        }else{
          if(checkboxValues){
            window.location.href =
            process.env.REACT_APP_DOCTOR_URL + "patient-details/" + patientID +`?formid=${data}`;
            }else{
            
               window.location.href =process.env.REACT_APP_DOCTOR_URL + "patient-details/" + patientID +`?formid=${data}`;
            // process.env.REACT_APP_DOCTOR_URL + "patient-view/" + patientID + `?formid=${data}`;
            }
        }
       
      }
      localStorage.removeItem("URL");
      localStorage.removeItem("formlink");
    } else {
      // window.open(process.env.REACT_APP_PATIENT_URL, "_blank");
      window.location.href = process.env.REACT_APP_PATIENT_URL;
      localStorage.removeItem("URL");
      localStorage.removeItem("formlink");
      // window.close()
    }
  };
  if (showModal) {
    setTimeout(() => {
      handleContinue();
    }, 7000);
  }

  return (
    <Modal
      show={showModal}
      onHide={hideModal}
      className={`delete_modal_main`}
      centered
    >
      <Modal.Body>
        <div className="d-flex">
          <div className="text-center d-flex" style={{ alignItems: "center" }}>
            <img className="me-3" src={ActiveIcon} />
          </div>
          <div>
            <h4
              className="center_title"
              style={{ textAlign: "left", lineHeight: "21px" }}
            >
              <b>
                {goback === "true"
                  ? translation.Your_form_submission_was_successful_Clinician
                  : translation.Your_form_submission_was_successful_Patient}
                <br></br>
                {translation.If_this_doesnt_happen_within}
                <a
                  style={{ color: "#0d6efd", cursor: "pointer" }}
                  onClick={() => handleContinue()}
                >
                  {" "}
                  {translation.click_here}
                </a>
                .
              </b>
            </h4>
          </div>
        </div>
      </Modal.Body>
      {/* <Modal.Footer className="text-center">
      
      {/* <Button variant="default" className="btn_outline"
          onClick={() => handleContinue()}
        >
        click here
        </Button> */}
      {/* </Modal.Footer> */}
    </Modal>
  );
};

export default DeleteConfirmation;
