import React from "react";
import "../../../css/ViewTreatmentForm.css";
import useTranslation from "../../customHooks/translations";

export default function HipQOL({ qOLData }) {
  const translations = useTranslation();
  return (
    <>
      <div className="whitebox padding-none">
        <div className="treatment-content">
          <div className="treatment-row-header">
            4. {translations.patient_hip_quality_of_life}
          </div>

          <div className="treatment-row">
            <div className="treatment-col">
              <label>
                {translations.patient_hip_quality_of_life_q3}(
                {translations.Right})
              </label>
              <p>{qOLData.concerned}</p>
            </div>
            <div className="treatment-col">
              <label>
                {translations.patient_hip_quality_of_life_q3}(
                {translations.Left})
              </label>

              <p>{qOLData.concernedL}</p>
            </div>
          </div>

          <div className="treatment-row">
            <div className="treatment-col">
              <label>
                {translations.patient_hip_quality_of_life_q5}(
                {translations.Right})
              </label>
              <p>{qOLData.distraction}</p>
            </div>
            <div className="treatment-col">
              <label>
                {translations.patient_hip_quality_of_life_q5}(
                {translations.Left})
              </label>

              <p>{qOLData.distractionL}</p>
            </div>
          </div>
          <div className="treatment-row">
            <div className="treatment-col">
              <label>
                {translations.patient_hip_quality_of_life_q1}(
                {translations.Right})
              </label>
              <p>{qOLData.aware}</p>
            </div>
            <div className="treatment-col">
              <label>
                {translations.patient_hip_quality_of_life_q1}(
                {translations.Left})
              </label>
              <p>{qOLData.awareL}</p>
            </div>
          </div>
          <div className="treatment-row">
            <div className="treatment-col">
              <label>
                {translations.patient_hip_quality_of_life_q2}(
                {translations.Right})
              </label>
              <p>{qOLData.modified}</p>
            </div>
            <div className="treatment-col">
              <label>
                {translations.patient_hip_quality_of_life_q2}(
                {translations.Left})
              </label>

              <p>{qOLData.modifiedL}</p>
            </div>
          </div>

          <div className="treatment-row">
            <div className="treatment-col">
              <label>
                {translations.patient_hip_quality_of_life_q4}(
                {translations.Right})
              </label>
              <p>{qOLData.troubled}</p>
            </div>
            <div className="treatment-col">
              <label>
                {translations.patient_hip_quality_of_life_q4}(
                {translations.Left})
              </label>

              <p>{qOLData.troubledL}</p>
            </div>
          </div>

          <div className="treatment-row">
            <div className="treatment-col">
              <label>
                {translations.patient_hip_quality_of_life_q6}(
                {translations.Right})
              </label>
              <p>{qOLData.difficulty}</p>
            </div>
            <div className="treatment-col">
              <label>
                {translations.patient_hip_quality_of_life_q6}(
                {translations.Left})
              </label>

              <p>{qOLData.difficultyL}</p>
            </div>
          </div>
          <div className="treatment-row">
            <div className="treatment-col">
              <label>
                {translations.patient_adult_knee_quality_of_life_q5}
              </label>
              <p>{qOLData.depressed}</p>
            </div>
            {/* <div className="treatment-col">
                            <label>How anxious/depressed are you today?(Left)</label>
                            <p>{qOLData.depressedL}</p>
                        </div> */}
          </div>
          <div className="treatment-row">
            <div className="treatment-col">
              <label>
                {translations.patient_adult_knee_quality_of_life_q6}{" "}
                {translations.patient_adult_knee_quality_of_life_q6_instruction}
              </label>
              <p>{qOLData.health}</p>
            </div>
            {/* <div className="treatment-col">
                            <label>How good/bad is your health today? (100 means best health, 0 means worst health you can imagine)(Left)</label>
                            <p>{qOLData.healthL}</p>
                        </div> */}
          </div>
        </div>
      </div>
    </>
  );
}
