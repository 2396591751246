import React, { useState, useEffect } from "react";
import "./NotFound.css";
// import logo from "../../../images/left-logo.svg";
// import leftlogo from "../../../images/akunah-hr-logo.svg";
import { Link, useNavigate } from "react-router-dom";
import useTranslation from "../../customHooks/translations";
import Modal from "react-bootstrap/Modal";
import Button from "../button/Button";
import { useKeycloak } from "@react-keycloak/web";
const NotFoundPage = () => {
  const Navigate = useNavigate();
  const { keycloak, initialized } = useKeycloak();
  let goback = localStorage.getItem("isdoctorForm");
  const handelChange = () => {
    if (goback === "true") {
      window.open(process.env.REACT_APP_DOCTOR_URL, "_blank");
      window.close();
    } else {
      window.open(process.env.REACT_APP_PATIENT_URL, "_blank");
      window.close();
    }
  };
  const [showModalForm, setshowModalForm] = useState(false);
  const hideModalsForm = () => {
    setshowModalForm(false);
  };
  let locationurl = window.location.href;
  let myUrl = localStorage.getItem("URL");
  useEffect(() => {
    if (myUrl) {
      setshowModalForm(true);
    }
  }, [myUrl]);

  const handleContinue = () => {
    if (goback === "true") {
      window.open(process.env.REACT_APP_DOCTOR_URL, "_blank");
      localStorage.removeItem("URL");
      setshowModalForm(false);
      window.close();
    } else {
      window.open(process.env.REACT_APP_PATIENT_URL, "_blank");
      localStorage.removeItem("URL");
      setshowModalForm(false);
      window.close();
    }
  };
  console.log(goback, "gobackgoback");
  const handleClose = () => {
    window.location.href = myUrl;
    {
      localStorage.removeItem("URL");
      setshowModalForm(false);
    }
  };
  const translation = useTranslation();
  return (
    // <div className={pageNotFoundStyles.not__found}>
    //   <img
    //     src={arrowCollapse}
    //     alt="404"
    //     className={pageNotFoundStyles.notf_img}
    //   />
    //   <div className="w_350">
    //     <div className="row">
    //       <div className="col-md-12 col-sm-12">
    //         <button
    //           className="btn_fill w_100 br_10 f_600 mt_40"
    //           onClick={() => handelChange()}
    //         >
    //           Back to Login
    //         </button>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <>
      <div className="">
        <div className="col-md-12 col-sm-12 col-12">
          <section className="main-page">
            <div className="mt-1">
              {!showModalForm ? (
                <div className="body-content inner-body-content">
                  <div className="error-page">
                    <h4>{translation.Oops_error}</h4>
                    <a
                      href=""
                      className="outline-btn"
                      onClick={() => handelChange()}
                    >
                      {translation.go_to_home}
                    </a>
                  </div>
                </div>
              ) : (
                ""
              )}

              {/* FOOTER START */}
              {/* <div className="footer">
         
            <a href="">
              <span />
            </a>
          </div>{" "} */}
            </div>
            <div className="text-center">
              <div
                style={{
                  position: "absolute",
                  bottom: "0px",
                  fontFamily: "Poppins",
                  color: "#9b9595",
                  fontWeight: "400",
                  width: "97%",
                }}
              >
                <p>App version : {process.env.REACT_APP_VERSION}</p>
              </div>
            </div>
          </section>
        </div>
      </div>

      <Modal
        show={showModalForm}
        // onHide={hideModalsForm}
        className={`delete_modal_main`}
        centered
      >
        <Modal.Body>
          <div className="text-center">
            {/* <img src={DeleteIcon} className="delete_pp_icon" alt="icon" /> */}
            <h4 className="center_title">{translation.patient_form_popup}</h4>
          </div>
        </Modal.Body>
        <Modal.Footer className="text-center">
          <Button
            variant="default"
            className="btn_outline"
            onClick={() => handleContinue()}
            value={translation.No}
            // disabled={isApiPending}
          ></Button>
          <Button
            variant="default"
            buttonStyle="btn_green"
            // color="green"
            onClick={() => handleClose()}
            value={translation.Yes}
            // disabled={isApiPending}
          ></Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default NotFoundPage;
