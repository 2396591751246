import React, { useState, useEffect } from "react";
import LoadingSpinner from "../custom/loader/LoadingSpinner";
import { useNavigate, useParams } from "react-router-dom";
import InputField from "../custom/inputfield/InputField";
import "./conssent.css";
import useAxios from "../../axiosinstance";
import useTranslation from "../customHooks/translations"
import akunah_img from "../../images/akunah.png";

// import SignaturePad from "react-signature-pad-wrapper";
const ConsentForm_view = () => {
  const translation = useTranslation()
  const axiosInstance = useAxios();
  const navigate = useNavigate();
  const { rId, pId } = useParams();
  // const formIds = window.atob(formId);
  const [AllData, setAllData] = useState("");
  const [profileData, setProfileData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [signType, setsignType] = useState("0");
  const [fullName, setName] = useState(null);
  const [relation, setRelation] = useState(null);
  const [signText, setsignText] = useState(null);
  const [consentCheck, setConsentCheck] = useState("");
  const [status, setStatus] = useState(false);

  //   const signaturePadRef =(e)=>{
  // console.log(e)
  //   }

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
  useEffect(() => {
    const checkConsent = async () => {
      const response = await axiosInstance.current.get(
        `extapp/forms/get_user_details?res_id=${window.atob(rId)}`
      );
      // console.log(response);
      localStorage.setItem("cons_doctor_id",response.data.data.id)
      setConsentCheck(response.data.data);
    };
    checkConsent();
  }, []);

  // const save = () => {
  //   // console.log(signaturePadRef.getTrimmedCanvas().toDataURL("image/png"))
  //   const dd = signaturePadRef.current.toDataURL();
  //   const data = signaturePadRef.current.toData();
  //   signaturePadRef.current.clear();
  // };

  const chooseSign = (e) => {
    setsignType(e);
  };

  const handleSignText = (e) => {
    setsignText(e);
  };
  const handleName = (e) => {
    setName(e);
  };
  const handleRelation = (e) => {
    setRelation(e);
  };

  const save = () => {
    axiosInstance.current
      .get(`extapp/forms/all_forms?resource_id=${window.atob(rId)}&type=14&akpt=${window.atob(pId)}`)
      .then((res) => {
        setAllData(res.data.data2[0]);
        setProfileData(res.data.data1);
      });
  };
  useEffect(() => {
    save();
  }, []);

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="container-fluid">
          <div className="row">
            <div className="col-xxl-12 col-lg-12 col-md-12 col-sm-12">
              <div className="complete_screen mb_100">
                <div className="panel">
                  <div className="panel-body p_25">
                    <div className="">
                      <div className="inner-content-box">
                        <div className="d-flex justify-content-between mb-4">
                          <div className="consent_txt">
                            <h2>{translation.Patient_Consent}</h2>
                          </div>
                          <div className="">
                            <div>
                              <img src={akunah_img} width='200' alt=''></img>
                            </div>
                          </div>
                        </div>
                        <form
                          className="formbox patient-information-form"
                          method="post"
                          id="consentform"
                          action=""
                        >
                          <div className="input-row">
                            <div className="input-box input-box-fwidth input-box-text">
                              <div className="formfill">
                                <div className="form-fieldtext fieldtext-name">
                                  <span className="left_label_txt">
                                    {translation.Patient_Last_Name}
                                  </span>
                                  <span className="rt_input_txt">
                                    {profileData.ing_lastname}
                                  </span>
                                </div>

                                <div className="form-fieldtext fieldtext-name">
                                  <span className="left_label_txt">
                                    {translation.Patient_First_Name}
                                  </span>
                                  <span className="rt_input_txt">
                                    {profileData.ing_firstname}
                                  </span>
                                </div>

                                <div className="form-fieldtext fieldtext-date">
                                  <span className="left_label_txt">
                                    {translation.Patient_Date_of_Birth}
                                  </span>
                                  <span className="right_dt">
                                    {" "}
                                    {profileData.ing_dob}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="input-box-text text-center mt_40 ">
                              <h3 className="dt__cs__ftext">
                                <strong className="d__block">
                                  {translation.information}
                                </strong>
                              </h3>
                            </div>
                            <br />
                            <div className="input-box input-box-fwidth input-box-text">
                              <div className="pt__info__consent__ppup">
                                <p className="dt__cs__ftext">
                                  {translation.I_am_informedDDD}{" "}
                                  <span className="dc_name">{consentCheck.name}</span>,
                                  {translation.Clinician}
                                </p>
                                <p className="dt__cs__ftext">{translation.For_that}</p>
                                <p className="dt__cs__ftext">{translation.Akunah_softawre}</p>
                                <p className="dt__cs__ftext">{translation.the_clinic}</p>
                                <p className="dt__cs__ftext">{translation.oportunnity}</p>

                                <p className="dt__cs__ftext"> <strong className="d__block">{translation.akunah_approch}</strong>
                                  {translation.akunah_approch1}
                                </p>
                                <p className="dt__cs__ftext">
                                  {translation.akunah_approch2}
                                </p>
                                <p className="dt__cs__ftext">
                                  {translation.akunah_approch3}
                                  "<strong>{translation.akunah_approch4}</strong>" ).
                                  {translation.akunah_approch5}
                                  "<strong>{translation.akunah_approch6}</strong>" ).
                                </p>
                                <p className="dt__cs__ftext">
                                  {translation.akunah_approch7}
                                  <a className="text-primary" href="https://akunah.com/termsofservices" target="_blank">{translation.terms_} </a>
                                </p>
                                <p className="dt__cs__ftext">
                                  {translation.akunah_approch8}
                                </p>
                                <p className="dt__cs__ftext">
                                  <strong className="d__block">
                                    {translation.Data_Collection_and_Processing}
                                  </strong>
                                  {translation.I_am_informed}
                                  <br />
                                  <br />
                                  <span className="ml-15 text_dec_none d__block">
                                    {translation.Assessment_and_evaluation}
                                  </span>
                                  <br />
                                  <span className="ml-15 text_dec_none d__block">
                                    {translation.Previous_surgery}
                                  </span>
                                  <br />
                                  <span className="ml-15 text_dec_none d__block">
                                    {translation.Health_and_medical_history}
                                  </span>
                                </p>
                                <p className="dt__cs__ftext">
                                  {translation.I_have_been_assured}
                                </p>
                                <p className="dt__cs__ftext">{translation.My_data}</p>

                                <p className="dt__cs__ftext">{translation.your_data}</p>
                                <p className="dt__cs__ftext">
                                  <strong className="d__block">
                                    {translation.Data_Access_and_Storage}
                                  </strong>
                                  {translation.Personal_data_provided}
                                  <br />
                                  <br />

                                </p>
                                <p className="dt__cs__ftext">{translation.data1}</p>
                                <p className="dt__cs__ftext">{translation.data2}</p>
                                <p className="dt__cs__ftext">{translation.data3}</p>
                                <p className="dt__cs__ftext">{translation.data4}</p>

                                <p className="dt__cs__ftext">
                                  <i>
                                    {translation.Personal_Data}
                                  </i>
                                </p>
                                <p>{translation.p_data1}</p>
                                <p>
                                  <i>
                                    {translation.Deidentified_Data}
                                  </i>
                                </p>
                                <p>{translation.d_txt1}</p>

                                <p className="dt__cs__ftext">
                                  <strong className="d__block">
                                    {translation.Patient_Rights}
                                  </strong>
                                  {translation.I_understand_that}
                                </p>
                                <p className="dt__cs__ftext">
                                  {translation.I_understand_that_1txt}
                                </p>
                                <p className="dt__cs__ftext">
                                  {translation.I_understand_that_2txt}
                                </p>
                                <p className="dt__cs__ftext">
                                  {translation.I_understand_that_3txt}
                                </p>
                                <p className="dt__cs__ftext">
                                  {translation.I_understand_that_4txt}
                                </p>
                                <p className="dt__cs__ftext">
                                  {translation.I_understand_that_5txt}
                                </p>

                                <p className="dt__cs__ftext mt-4">
                                  <strong className="d__block text-center">
                                    {translation.Contact}
                                  </strong>
                                  {translation.I_am_able_to_contact}{" "}
                                </p>
                                <p className="dt__cs__ftext mt-4">
                                  {translation.or_by_post}
                                </p>

                                <p className="dt__cs__ftext mt-4">
                                  {translation.or_by_post2}
                                  <span className="dc_name">{consentCheck.name}</span>,
                                  {translation.or_by_post2_1}
                                </p>
                                <p className="dt__cs__ftext mt-4">
                                  {translation.or_by_post3}
                                </p>
                                <p className="dt__cs__ftext mt-4">
                                  {translation.or_by_post4}
                                </p>
                                <p className="dt__cs__ftext mt-4">
                                  {translation.or_by_post5}
                                </p>
                                <p className="dt__cs__ftext mt-4">
                                  {translation.or_by_post6}
                                  <a className="text-primary" href="https://info@akunah.com" target="_blank"> {translation.info_akunah} </a>


                                  {translation.or_by_post7}
                                </p>
                                {/* <p className="dt__cs__ftext">
                            <strong className="d__block">
                              {translation.Agreement}
                            </strong>
                            {translation.agree_that_I_have_read}
                          </p> */}
                              </div>
                            </div>


                          </div>
                          <div className="input-row">
                            <div className="input-box input-tab-boxin input-box-fwidth">
                              <div className="input-box input-box-fwidth">
                                <InputField
                                  disabled
                                  label="Name (if consent on behalf of patient)"
                                  name="nameOnBehalf"
                                  value={AllData.name}
                                  type="input"
                                  placeholder="Full name"
                                  onChange={handleName}
                                  styleClass=" mxw_100 fill-here trim"
                                  formGroupClass="label_back"
                                />
                              </div>
                              <div className="input-box input-box-fwidth">
                                <InputField
                                  disabled
                                  label="Relationship to patient, if applicable"
                                  name="nameOnBehalf"
                                  value={AllData.relationship}
                                  type="input"
                                  placeholder="Relationship to patient"
                                  onChange={handleRelation}
                                  styleClass=" mxw_100 fill-here trim"
                                  formGroupClass="label_back"
                                />
                              </div>

                              <div className="note-box">
                                <p>
                                  NOTE: If the patient is less than 18 years of age,
                                  this must be signed by their parent or legal
                                  guardian.
                                </p>
                                <p>
                                  NOTE: If the patient is deceased, this must be
                                  signed by their next of kin.
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="input-row sign-type signup__sign_type">
                            <div className="input-box mt-20">
                              <label>Sign</label>
                            </div>
                          </div>
                          {AllData.sign_type === "2" && (
                            <img className="img-fluid" src={AllData.sign_image}></img>
                          )}
                          {AllData.sign_type === "1" && (
                            <p>{AllData.sign}</p>
                          )}
                          <div className="input-row consent__btm_date">
                            <div className="input-box input-box-fwidth input-box-mbhalf">
                              <label>Date*:</label>
                              <div className="date-inputbox">
                                {new Date(AllData.date).toLocaleDateString()}
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>

                    {/* <button onClick={save} className="signButtons">
                submit
              </button> */}
                    {/* <div className="row mb_30 button_box">
                <div className="col-md-6 col-sm-6">
                  <Link to="/" className="assm_back_btn">
                    <img src={backIcon} alt="" />
                    <span>Back</span>
                  </Link>
                </div>
                <div className="col-md-6 col-sm-6 text-end">
                  <button className="next_btn btn_fill" onClick={continueRed}>
                    Next
                    <span>
                      <img src={nextIcon} alt="" />
                      <img src={nextWhiteIcon} alt="" />
                    </span>
                  </button>
                </div>
              </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ConsentForm_view;
