import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Tabs, Tab, Badge } from "react-bootstrap";
import "./Tab.css";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import useAxios from "../../../axiosinstance";
export default function HipTab({ rId }) {
  const instabilityACjoint = useSelector(
    (state) => state.shoulderScore.functionScore[0].instability_acjoint
  );
  const feel_unstable = useSelector(
    (state) => state.shoulderScore.functionScore[0].feel_unstable
  );

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(window.location.search);
  const pId = queryParams.get("akpt");
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const [key, setKey] = useState(splitLocation[1]);
  const is_doctor_form = queryParams.get("is_doctor_form");
  // useEffect(()=>{
  //     localStorage.setItem("isdoctorForm",is_doctor_form)
  // },[is_doctor_form])
  const axiosinstance = useAxios();
  const [patientStatus, setPatientStatus] = useState(false);
  const [doctorStatus, setDoctorStatus] = useState(false);
  const [ptid, setPtId] = useState("");
  const getTab = () => {
    axiosinstance.current
      .get(`extapp/forms/all_forms?resource_id=${rId}&type=6`)
      .then((res) => {
        console.log(res.data.doctorStatus, res.data.status, "responce");
        setPatientStatus(res.data.status);
        setDoctorStatus(res.data.doctorStatus);
        setPtId(res.data.data2[0].ak_pt);
        if (res.data.status) {
          // handleTabSelect("pain");
        } else if (res.data.doctorStatus) {
          if (splitLocation[1] !== "hip-feedback-step-2") {
          handleTabSelect("doctor-feedback-form/step-1");
          }
        } else {
          navigate("/un-authorized-access");
        }
      })
      .catch((err) => {
        // navigate('/un-authorized-access');
      });
  };
  useEffect(() => {
    getTab();
  }, []);
  let doctordata=localStorage.getItem("isdoctorForm")
  let akDc = sessionStorage.getItem("akDc");
  let akOu = sessionStorage.getItem("akOu");
  const handleTabSelect = (selectedTab) => {
    setKey(selectedTab);
    switch (selectedTab) {
      case "personal":
        navigate(
          `/personal-data-edit/${window.btoa(
            ptid
          )}/${akDc}/${akOu}/${window.btoa(
            6
          )}?is_doctor_form=true&doctorvalue=${0}&doctor=true&resource_id=${rId}`
        );
        break;
      case "pain":
        navigate(`/hip-pain/${rId}?d=true&akpt=${window.btoa(ptid)}&is_doctor_form=${doctordata}`);
        break;
      case "symptoms":
        navigate(`/hip-symptoms/${rId}?d=true&akpt=${window.btoa(ptid)}&is_doctor_form=${doctordata}`);
        break;

      case "function":
        navigate(`/hip-function/${rId}?d=true&akpt=${window.btoa(ptid)}&is_doctor_form=${doctordata}`);
        break;
      case "qol":
        navigate(`/hip-quality-of-life/${rId}?d=true&akpt=${window.btoa(ptid)}&is_doctor_form=${doctordata}`);
        break;
      case "satisfaction":
        navigate(`/hip-satisfaction/${rId}?d=true&akpt=${window.btoa(ptid)}&is_doctor_form=${doctordata}`);
        break;

      case "doctor-feedback-form/step-1":
        navigate(`/hip-feedback/${rId}?d=true&akpt=${window.btoa(ptid)}&is_doctor_form=${doctordata}`);
        break;

      case "doctor-feedback-form/step-2":
        navigate(`/hip-feedback-step-2/${rId}?d=true&akpt=${window.btoa(ptid)}&is_doctor_form=${doctordata}`);
        break;

      default:
        break;
    }
  };
  return (
    <>
      <div className="col-md-12">
        <Tabs
          id="doctor-reg-tabs"
          activeKey={key}
          // onSelect={handleSelect}
          className="doctor_reg_tabs mb-1"
          onSelect={(e) => handleTabSelect(e)}
        >
        {patientStatus && (
            <Tab
              eventKey="personal"
              title={<React.Fragment>Personal Information</React.Fragment>}
            ></Tab>
          )}
          {patientStatus && (
          <Tab
            eventKey="pain"
            title={<React.Fragment>Pain</React.Fragment>}
          ></Tab>)}
          {patientStatus && (
            <Tab
              eventKey="symptoms"
              title={<React.Fragment>Symptoms</React.Fragment>}
            ></Tab>)}
          {patientStatus && (
            <Tab
              eventKey="function"
              title={<React.Fragment>Function</React.Fragment>}
            ></Tab>)}
          {patientStatus && (
            <Tab
              eventKey="qol"
              title={<React.Fragment>Quality of Life</React.Fragment>}
            ></Tab>)}

          {patientStatus && (
            <Tab
              eventKey="satisfaction"
              title={<React.Fragment>Satisfaction</React.Fragment>}
            ></Tab>)}
          {doctorStatus && (
            <Tab
              eventKey="doctor-feedback-form/step-1"
              title={<React.Fragment>Patient-Assessment-Step 1</React.Fragment>}
            ></Tab>
          )}
          {doctorStatus && (
            <Tab
              eventKey="doctor-feedback-form/step-2"
              title={<React.Fragment>Patient-Assessment-Step 2</React.Fragment>}
            ></Tab>
          )}
        </Tabs>
      </div>
    </>
  );
}
