import React, { useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import LoadingSpinner from "../../../custom/loader/LoadingSpinner";
import ResultCircleProgress from "../../../custom/result-circle-progress/ResultCircleProgress";
import Button from "../../../custom/button/Button";
import useTranslation from "../../../customHooks/translations";
import useAxios from "../../../../axiosinstance";
import Share from "../../Share";
import {
  updateFootSatisTotalscrore,
  updateFootAnklePainData,
  updateFootSymptomsData,
  updateFootAnkle,
  updateFootInstabilityData,
  updateFootQolData,
  updateFootFunctionData,
  updateFootSatisfactionData,
  updateFootFeedbackfootankle,
  updateFootComberlandData,
} from "../../../../redux/actions/index";
import NameCustom from "../../../custom/name/NameCustom";
import SubmitModel from "../../SubmissionModel/SubmitModel"

function FootAnkleResult() {
  const { rId } = useParams();
  const dispatch = useDispatch();
  const axiosinstance = useAxios();
  const translation = useTranslation();
  let type = 21;
  const [isLoading, setIsLoading] = useState(true);
  const anklePain = useSelector((state) => state.footAnkle.anklePain);
  const [totalScore] = useSelector((state) => state.footAnkle.TotalScores);
  const [dominant, setDominant] = useState("");
  const navigate = useNavigate();
  const [showCumberlandR] = useSelector(
    (state) => state.footAnkle.instabilityScore[0].ankleDiagnosedR
  );
  const Footfunction = useSelector((state) => state.footAnkle.functionScore[0]);
  const [initialAssesment, setInitialAssesment] = useState(0);
  const [status, setStatus] = useState("");
  // let prior = Math.max(
  //   Footfunction.topLevelR,
  //   Footfunction.lowLevelR,
  //   Footfunction.recreatR,
  //   Footfunction.idoSpotR,
  //   Footfunction.noSpotR,
  //   Footfunction.topLevelL,
  //   Footfunction.lowLevelL,
  //   Footfunction.recreatL,
  //   Footfunction.idoSpotL,
  //   Footfunction.noSpotL
  // );
  const [isdoctorform, setisdoctorform] = useState(Number())
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  const continueRedirect2 = (e) => {
    setDisplayConfirmationModal(true)
    // if (isdoctorform === 1) {

    //   window.location.href = process.env.REACT_APP_DOCTOR_URL + "patient-list";
    // } else {

    //   window.location.href = process.env.REACT_APP_PATIENT_URL;
    // }
  };

  // const continueRedirect2 = (e) => {
  //   window.open(process.env.REACT_APP_PATIENT_URL, "_blank");

  // };

  const save = () => {
    axiosinstance.current
      .get(`extapp/forms/all_forms?resource_id=${rId}&type=21`)
      .then((res) => {
        setDominant(res.data.data2[0].dominant);
        setInitialAssesment(res.data.data2[0].initial_assessment);
        dispatch(updateFootAnklePainData(res.data.data2[0].painScore));
        dispatch(updateFootSymptomsData(res.data.data2[0].symptomsScore));
        dispatch(updateFootAnkle(res.data.data2[0].anklePain));
        dispatch(updateFootInstabilityData(res.data.data2[0].instabilityScore));
        dispatch(updateFootComberlandData(res.data.data2[0].comberland));
        dispatch(updateFootQolData(res.data.data2[0].qolScore));
        dispatch(updateFootFunctionData(res.data.data2[0].functionScore));
        dispatch(
          updateFootSatisfactionData(res.data.data2[0].satisfactionScore)
        );
        dispatch(updateFootFeedbackfootankle(res.data.data2[0].doctorFeedback));
        dispatch(updateFootSatisTotalscrore(res.data.data2[0].TotalScores));
        setisdoctorform(res.data.data2[0].is_doctor_form)
        setStatus(res.data.data2[0].status);
      })

      .catch((err) => {
        navigate("/un-authorized-access");
      });
  };

  useEffect(() => {
    save();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
  const date = new Date();
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  let EQDWalking = Math.max(totalScore.EQDWalkingR, totalScore.EQDWalkingL);
  let EqdPersonalCare = Math.max(
    totalScore.EqdPersonalCareL,
    totalScore.EqdPersonalCareR
  );
  let EqdActivities = Math.max(
    totalScore.EqdActivitiesL,
    totalScore.EqdActivitiesR
  );
  let Eqdsanxious = Math.max(totalScore.EqdsanxiousL, totalScore.EqdsanxiousR);
  let EQPain = Math.max(totalScore.EQPainL, totalScore.EQPain);

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="result_screen mb_100">
          <div className="row justify-content-center">
            <div className="col-xxl-8 col-lg-10 col-md-12 col-sm-12">
              <div className="panel">
                <div className="panel-body p_25">
                  <div className="row">
                    <div className="col-xxl-8 col-lg-7 col-md-6 col-sm-6">
                      <h3 className="text-start f_22 ">
                        {translation.patient_foot_ankle_results}
                      </h3>
                      <p className="answer_txt">
                        {translation.patient_foot_ankle_results_text1}
                      </p>
                    </div>
                    <div className="col-xxl-4 col-lg-5 col-md-6 col-sm-6">
                      <NameCustom />
                    </div>
                  </div>
                  <div className="row">
                    {status === "1" ? (
                      <div className="pb_50 text-center">
                        <Button
                          onClick={continueRedirect2}
                          value={translation.Sign_out}
                          buttonStyle="btn_fill result_submit_btn"
                        />

                      </div>
                    ) : status === "2" ? (
                      <div className="pb_50 text-center">
                        <Share
                          type={type}
                          rId={rId}
                        />
                      </div>

                    ) : null}
                  </div>

                  <div className="row result_scorebox">
                    {/* {dominant === '1' && <>

                                    {anklePain === "1" || anklePain === "3" ? (
                                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                            <ResultCircleProgress min_score={0}  reverse={false} title={translation.HalluxScale} type={translation.patient_foot_ankle_results_self_evaluation} arm="Right Foot/ankle" value={totalScore.HalluxScaleR} total="100" date={`${day}/${month}/${year}`} color="#006DFF" />
                                        </div>
                                    ) : ""}

                                    {anklePain === "2" || anklePain === "3" ? (
                                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                            <ResultCircleProgress min_score={0}  reverse={false} title={translation.HalluxScale} type={translation.patient_foot_ankle_results_self_evaluation} arm="Left Foot/ankle" value={totalScore.HalluxScaleL} total="100" date="22/09/2022" color="#006DFF" />
                                        </div>
                                    ) : ""}

                                    {anklePain === "1" || anklePain === "3" ? (
                                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                            <ResultCircleProgress min_score={0}  reverse={false} title={translation.LesserToeScale} type={translation.patient_foot_ankle_results_self_evaluation} arm="Right Foot/ankle" value={totalScore.LesserToeScaleR} total="100" date={`${day}/${month}/${year}`} color="#006DFF" />
                                        </div>
                                    ) : ""}

                                    {anklePain === "2" || anklePain === "3" ? (
                                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                            <ResultCircleProgress min_score={0}  reverse={false} title={translation.LesserToeScale} type={translation.patient_foot_ankle_results_self_evaluation} arm="Left Foot/ankle" value={totalScore.LesserToeScaleL} total="100" date="22/09/2022" color="#006DFF" />
                                        </div>
                                    ) : ""}


                                    {anklePain === "1" || anklePain === "3" ? (
                                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                            <ResultCircleProgress min_score={0}  reverse={false} title={translation.FAAM_ADL_SCORE} type={translation.patient_foot_ankle_results_self_evaluation} arm="Right Foot/ankle" value={totalScore.FAAMADRScrore} total="100" date={`${day}/${month}/${year}`} color="#006DFF" />
                                        </div>
                                    ) : ""}


                                    {anklePain === "2" || anklePain === "3" ? (
                                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                            <ResultCircleProgress min_score={0}  reverse={false} title={translation.FAAM_ADL_SCORE} type={translation.patient_foot_ankle_results_self_evaluation} arm="Left Foot/ankle" value={totalScore.FAAMADLScrore} total="100" date="22/09/2022" color="#006DFF" />
                                        </div>
                                    ) : ""}


                                    {anklePain === "1" || anklePain === "3" ? (
                                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                            <ResultCircleProgress min_score={0}  reverse={false} title={translation.FAAM_SPORTS} type={translation.patient_foot_ankle_results_self_evaluation} arm="Right Foot/ankle" value={totalScore.FAAMSRScrore} total="100" date="22/09/2022" color="#ff0000" />
                                        </div>
                                    ) : ""}
                                    {anklePain === "2" || anklePain === "3" ? (
                                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                            <ResultCircleProgress min_score={0}  reverse={false} title={translation.FAAM_SPORTS} type={translation.patient_foot_ankle_results_self_evaluation} arm="Left Foot/ankle" value={totalScore.FAAMSLScrore} total="100" date="22/09/2022" color="#ff0000" />
                                        </div>
                                    ) : ""}

                                    {anklePain === "1" || anklePain === "3" ? (
                                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                            <ResultCircleProgress min_score={0}  reverse={false} title={translation.FADI_SCORE} type={translation.patient_foot_ankle_results_self_evaluation} arm="Right Foot/ankle" value={totalScore.TotalFADIRS} total="100" date="22/09/2022" color="#78b776" />
                                        </div>
                                    ) : ""}
                                    {anklePain === "2" || anklePain === "3" ? (
                                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                            <ResultCircleProgress min_score={0}  reverse={false} title={translation.FADI_SCORE} type={translation.patient_foot_ankle_results_self_evaluation} arm="Left Foot/ankle" value={totalScore.TotalFADILS} total="100" date="22/09/2022" color="#78b776" />
                                        </div>
                                    ) : ""}

                                    {anklePain === "1" || anklePain === "3" ? (
                                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                            <ResultCircleProgress min_score={0}  reverse={false} title={translation.FADI_SPORTS} type={translation.patient_foot_ankle_results_self_evaluation} arm="Right Foot/ankle" value={totalScore.FADISPORTRS} total="100" date="22/09/2022" color="#006DFF" />
                                        </div>
                                    ) : ""}
                                    {anklePain === "2" || anklePain === "3" ? (
                                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                            <ResultCircleProgress min_score={0}  reverse={false} title={translation.FADI_SPORTS} type={translation.patient_foot_ankle_results_self_evaluation} arm="Left Foot/ankle" value={totalScore.FADISPORTLS} total="100" date="22/09/2022" color="#006DFF" />
                                        </div>
                                    ) : ""}

                                    {anklePain === "1" || anklePain === "3" ? (
                                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                            <ResultCircleProgress min_score={0}  reverse={false} title={translation.FAOS_PAIN} type={translation.patient_foot_ankle_results_self_evaluation} arm="Right Foot/ankle" value={totalScore.FAOSTPAINR} total="100" date="26/10/2022" color="#ff0000" />
                                        </div>
                                    ) : ""}
                                    {anklePain === "2" || anklePain === "3" ? (
                                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                            <ResultCircleProgress min_score={0}  reverse={false} title={translation.FAOS_PAIN} type={translation.patient_foot_ankle_results_self_evaluation} arm="Left Foot/ankle" value={totalScore.FAOSTPAINL} total="100" date="26/10/2022" color="#ff0000" />
                                        </div>
                                    ) : ""}

                                    {anklePain === "1" || anklePain === "3" ? (
                                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                            <ResultCircleProgress min_score={0}  reverse={false} title={translation.FAOS_SYMPTOMS} type={translation.patient_foot_ankle_results_self_evaluation} arm="Right Foot/ankle" value={totalScore.FAOSTSYMPTOMSR} total="100" date="22/09/2022" color="#78b776" />
                                        </div>
                                    ) : ""}
                                    {anklePain === "2" || anklePain === "3" ? (
                                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                            <ResultCircleProgress min_score={0}  reverse={false} title={translation.FAOS_SYMPTOMS} type={translation.patient_foot_ankle_results_self_evaluation} arm="Left Foot/ankle" value={totalScore.FAOSTSYMPTOMSL} total="100" date="22/09/2022" color="#78b776" />
                                        </div>
                                    ) : ""}

                                    {anklePain === "1" || anklePain === "3" ? (
                                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                            <ResultCircleProgress min_score={0}  reverse={false} title={translation.FAOS_ADL} type={translation.patient_foot_ankle_results_self_evaluation} arm="Right Foot/ankle" value={totalScore.FAOSTADLR} total="100" date="26/10/2022" color="#006DFF" />
                                        </div>
                                    ) : ""}
                                    {anklePain === "2" || anklePain === "3" ? (
                                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                            <ResultCircleProgress min_score={0}  reverse={false} title={translation.FAOS_ADL} type={translation.patient_foot_ankle_results_self_evaluation} arm="Left Foot/ankle" value={totalScore.FAOSTADLL} total="100" date="26/10/2022" color="#006DFF" />
                                        </div>
                                    ) : ""}


                                    {anklePain === "1" || anklePain === "3" ? (
                                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                            <ResultCircleProgress min_score={0}  reverse={false} title={translation.FAOS_SPORT} type={translation.patient_foot_ankle_results_self_evaluation} arm="Right Foot/ankle" value={totalScore.FAOSTSPORTR} total="100" date="26/10/2022" color="#ff0000" />
                                        </div>
                                    ) : ""}
                                    {anklePain === "2" || anklePain === "3" ? (
                                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                            <ResultCircleProgress min_score={0}  reverse={false} title={translation.FAOS_SPORT} type={translation.patient_foot_ankle_results_self_evaluation} arm="Left Foot/ankle" value={totalScore.FAOSTSPORTL} total="100" date="26/10/2022" color="#ff0000" />
                                        </div>
                                    ) : ""}

                                    {anklePain === "1" || anklePain === "3" ? (
                                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                            <ResultCircleProgress min_score={0}  reverse={false} title={translation.FAOS_QOL} type={translation.patient_foot_ankle_results_self_evaluation} arm="Right Foot/ankle" value={totalScore.FAOSTQOLR} total="100" date="26/10/2022" color="#78b776" />
                                        </div>
                                    ) : ""}
                                    {anklePain === "2" || anklePain === "3" ? (
                                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                            <ResultCircleProgress min_score={0}  reverse={false} title={translation.FAOS_QOL} type={translation.patient_foot_ankle_results_self_evaluation} arm="Left Foot/ankle" value={totalScore.FAOSTQOLL} total="100" date="26/10/2022" color="#78b776" />
                                        </div>
                                    ) : ""}

                                    {anklePain === "1" || anklePain === "3" ? (
                                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                            <ResultCircleProgress min_score={0}  reverse={false} title={translation.VAS_PAIN} type={translation.patient_foot_ankle_results_self_evaluation} arm="Right Foot/ankle" persent={totalScore.VasPainRating * 10} value={totalScore.VasPainRating} total="10" date="26/10/2022" color="#78b776" />
                                        </div>
                                    ) : ""}
                                    {anklePain === "2" || anklePain === "3" ? (
                                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                            <ResultCircleProgress min_score={0}  reverse={false} title={translation.VAS_PAIN} type={translation.patient_foot_ankle_results_self_evaluation} arm="Left Foot/ankle" persent={totalScore.VasPainRatingL * 10} value={totalScore.VasPainRatingL} total="10" date="26/10/2022" color="#78b776" />
                                        </div>
                                    ) : ""}

                                    {initialAssesment == 0 && (
                                        <>
                                            <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                                <ResultCircleProgress min_score={0}  reverse={false} title={translation.SATISFACTION} type={translation.patient_foot_ankle_results_self_evaluation} arm="Right Foot/ankle" value={totalScore.TotalSTAISFACTION} total="100" date="26/10/2022" color="#006DFF" />
                                            </div>
                                            <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                                <ResultCircleProgress min_score={0}  reverse={false} title={translation.SATISFACTION} type={translation.patient_foot_ankle_results_self_evaluation} arm="Left Foot/ankle" value={totalScore.TotalSATISFACTIONL} total="100" date="26/10/2022" color="#006DFF" />
                                            </div>
                                        </>
                                    )}


                                    {anklePain === "1" || anklePain === "3" ? (
                                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                            <ResultCircleProgress min_score={0}  reverse={false} title={translation.EQ_VAS} type={translation.patient_foot_ankle_results_self_evaluation} arm="Foot/ankle" value={totalScore.HealthToday} total="100" date="26/10/2022" color="#78b776" />
                                        </div>
                                    ) : ""}
                                    {anklePain === "2" || anklePain === "3" ? (
                                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                            <ResultCircleProgress min_score={0}  reverse={false} title={translation.EQ_VAS} type={translation.patient_foot_ankle_results_self_evaluation} arm="Foot/ankle" value={totalScore.HealthTodayL} total="100" date="26/10/2022" color="#78b776" />
                                        </div>
                                    ) : ""}
                                    <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                        <ResultCircleProgress min_score={0}  reverse={false} title={translation.SANE} type={translation.patient_foot_ankle_results_self_evaluation} arm="Foot/ankle" value={totalScore.FootPercent} total="100" date="26/10/2022" color="#006DFF" />
                                    </div>
                                    <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                        <ResultCircleProgress min_score={0}  reverse={false} title={translation.SANE} type={translation.patient_foot_ankle_results_self_evaluation} arm="Foot/ankle" value={totalScore.FootPercentL} total="100" date="26/10/2022" color="#006DFF" />
                                    </div>
                                    {showCumberlandR === "1" && (
                                        <>
                                            {anklePain === "1" || anklePain === "3" ? (
                                                <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                                    <ResultCircleProgress min_score={0}  reverse={false} title={translation.CAIT} type="Right Foot/ankle" arm="Right Foot/ankle" value={totalScore.CumberlandRT} total="100" date="26/10/2022" color="#006DFF" />
                                                </div>
                                            ) : ""}
                                            {anklePain === "2" || anklePain === "3" ? (
                                                <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                                    <ResultCircleProgress min_score={0}  reverse={false} title={translation.CAIT} type="Left Foot/ankle" arm="Left Foot/ankle" value={totalScore.CumberlandLT} total="100" date="26/10/2022" color="#006DFF" />
                                                </div>
                                            ) : ""}

                                        </>
                                    )}
                                </>}

                                {dominant === '2' && <>

                                    {anklePain === "1" || anklePain === "3" ? (
                                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                            <ResultCircleProgress min_score={0}  reverse={false} title={translation.MidfootScale} type={translation.patient_foot_ankle_results_self_evaluation} arm="Right Foot/ankle" value={totalScore.MidfootScaleR} total="100" date={`${day}/${month}/${year}`} color="#006DFF" />
                                        </div>
                                    ) : ""}

                                    {anklePain === "2" || anklePain === "3" ? (
                                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                            <ResultCircleProgress min_score={0}  reverse={false} title={translation.MidfootScale} type={translation.patient_foot_ankle_results_self_evaluation} arm="Left Foot/ankle" value={totalScore.MidfootScaleL} total="100" date="22/09/2022" color="#006DFF" />
                                        </div>
                                    ) : ""}

                                    {anklePain === "1" || anklePain === "3" ? (
                                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                            <ResultCircleProgress min_score={0}  reverse={false} title={translation.FAAM_ADL_SCORE} type={translation.patient_foot_ankle_results_self_evaluation} arm="Right Foot/ankle" value={totalScore.FAAMADRScrore} total="100" date={`${day}/${month}/${year}`} color="#006DFF" />
                                        </div>
                                    ) : ""}


                                    {anklePain === "2" || anklePain === "3" ? (
                                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                            <ResultCircleProgress min_score={0}  reverse={false} title={translation.FAAM_ADL_SCORE} type={translation.patient_foot_ankle_results_self_evaluation} arm="Left Foot/ankle" value={totalScore.FAAMADLScrore} total="100" date="22/09/2022" color="#006DFF" />
                                        </div>
                                    ) : ""}


                                    {anklePain === "1" || anklePain === "3" ? (
                                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                            <ResultCircleProgress min_score={0}  reverse={false} title={translation.FAAM_SPORTS} type={translation.patient_foot_ankle_results_self_evaluation} arm="Right Foot/ankle" value={totalScore.FAAMSRScrore} total="100" date="22/09/2022" color="#ff0000" />
                                        </div>
                                    ) : ""}
                                    {anklePain === "2" || anklePain === "3" ? (
                                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                            <ResultCircleProgress min_score={0}  reverse={false} title={translation.FAAM_SPORTS} type={translation.patient_foot_ankle_results_self_evaluation} arm="Left Foot/ankle" value={totalScore.FAAMSLScrore} total="100" date="22/09/2022" color="#ff0000" />
                                        </div>
                                    ) : ""}

                                    {anklePain === "1" || anklePain === "3" ? (
                                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                            <ResultCircleProgress min_score={0}  reverse={false} title={translation.FADI_SCORE} type={translation.patient_foot_ankle_results_self_evaluation} arm="Right Foot/ankle" value={totalScore.TotalFADIRS} total="100" date="22/09/2022" color="#78b776" />
                                        </div>
                                    ) : ""}
                                    {anklePain === "2" || anklePain === "3" ? (
                                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                            <ResultCircleProgress min_score={0}  reverse={false} title={translation.FADI_SCORE} type={translation.patient_foot_ankle_results_self_evaluation} arm="Left Foot/ankle" value={totalScore.TotalFADILS} total="100" date="22/09/2022" color="#78b776" />
                                        </div>
                                    ) : ""}

                                    {anklePain === "1" || anklePain === "3" ? (
                                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                            <ResultCircleProgress min_score={0}  reverse={false} title={translation.FADI_SPORTS} type={translation.patient_foot_ankle_results_self_evaluation} arm="Right Foot/ankle" value={totalScore.FADISPORTRS} total="100" date="22/09/2022" color="#006DFF" />
                                        </div>
                                    ) : ""}
                                    {anklePain === "2" || anklePain === "3" ? (
                                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                            <ResultCircleProgress min_score={0}  reverse={false} title={translation.FADI_SPORTS} type={translation.patient_foot_ankle_results_self_evaluation} arm="Left Foot/ankle" value={totalScore.FADISPORTLS} total="100" date="22/09/2022" color="#006DFF" />
                                        </div>
                                    ) : ""}

                                    {anklePain === "1" || anklePain === "3" ? (
                                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                            <ResultCircleProgress min_score={0}  reverse={false} title={translation.FAOS_PAIN} type={translation.patient_foot_ankle_results_self_evaluation} arm="Right Foot/ankle" value={totalScore.FAOSTPAINR} total="100" date="26/10/2022" color="#ff0000" />
                                        </div>
                                    ) : ""}
                                    {anklePain === "2" || anklePain === "3" ? (
                                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                            <ResultCircleProgress min_score={0}  reverse={false} title={translation.FAOS_PAIN} type={translation.patient_foot_ankle_results_self_evaluation} arm="Left Foot/ankle" value={totalScore.FAOSTPAINL} total="100" date="26/10/2022" color="#ff0000" />
                                        </div>
                                    ) : ""}

                                    {anklePain === "1" || anklePain === "3" ? (
                                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                            <ResultCircleProgress min_score={0}  reverse={false} title={translation.FAOS_SYMPTOMS} type={translation.patient_foot_ankle_results_self_evaluation} arm="Right Foot/ankle" value={totalScore.FAOSTSYMPTOMSR} total="100" date="22/09/2022" color="#78b776" />
                                        </div>
                                    ) : ""}
                                    {anklePain === "2" || anklePain === "3" ? (
                                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                            <ResultCircleProgress min_score={0}  reverse={false} title={translation.FAOS_SYMPTOMS} type={translation.patient_foot_ankle_results_self_evaluation} arm="Left Foot/ankle" value={totalScore.FAOSTSYMPTOMSL} total="100" date="22/09/2022" color="#78b776" />
                                        </div>
                                    ) : ""}

                                    {anklePain === "1" || anklePain === "3" ? (
                                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                            <ResultCircleProgress min_score={0}  reverse={false} title={translation.FAOS_ADL} type={translation.patient_foot_ankle_results_self_evaluation} arm="Right Foot/ankle" value={totalScore.FAOSTADLR} total="100" date="26/10/2022" color="#006DFF" />
                                        </div>
                                    ) : ""}
                                    {anklePain === "2" || anklePain === "3" ? (
                                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                            <ResultCircleProgress min_score={0}  reverse={false} title={translation.FAOS_ADL} type={translation.patient_foot_ankle_results_self_evaluation} arm="Left Foot/ankle" value={totalScore.FAOSTADLL} total="100" date="26/10/2022" color="#006DFF" />
                                        </div>
                                    ) : ""}


                                    {anklePain === "1" || anklePain === "3" ? (
                                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                            <ResultCircleProgress min_score={0}  reverse={false} title={translation.FAOS_SPORT} type={translation.patient_foot_ankle_results_self_evaluation} arm="Right Foot/ankle" value={totalScore.FAOSTSPORTR} total="100" date="26/10/2022" color="#ff0000" />
                                        </div>
                                    ) : ""}
                                    {anklePain === "2" || anklePain === "3" ? (
                                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                            <ResultCircleProgress min_score={0}  reverse={false} title={translation.FAOS_SPORT} type={translation.patient_foot_ankle_results_self_evaluation} arm="Left Foot/ankle" value={totalScore.FAOSTSPORTL} total="100" date="26/10/2022" color="#ff0000" />
                                        </div>
                                    ) : ""}

                                    {anklePain === "1" || anklePain === "3" ? (
                                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                            <ResultCircleProgress min_score={0}  reverse={false} title={translation.FAOS_QOL} type={translation.patient_foot_ankle_results_self_evaluation} arm="Right Foot/ankle" value={totalScore.FAOSTQOLR} total="100" date="26/10/2022" color="#78b776" />
                                        </div>
                                    ) : ""}
                                    {anklePain === "2" || anklePain === "3" ? (
                                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                            <ResultCircleProgress min_score={0}  reverse={false} title={translation.FAOS_QOL} type={translation.patient_foot_ankle_results_self_evaluation} arm="Left Foot/ankle" value={totalScore.FAOSTQOLL} total="100" date="26/10/2022" color="#78b776" />
                                        </div>
                                    ) : ""}


                                    <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                        <ResultCircleProgress min_score={0}  reverse={false} title={translation.VAS_PAIN} type={translation.patient_foot_ankle_results_self_evaluation} arm="Right Foot/ankle" persent={totalScore.VasPainRating * 10} value={totalScore.VasPainRating} total="10" date="26/10/2022" color="#78b776" />
                                    </div>
                                    <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                        <ResultCircleProgress min_score={0}  reverse={false} title={translation.VAS_PAIN} type={translation.patient_foot_ankle_results_self_evaluation} arm="Left Foot/ankle" persent={totalScore.VasPainRatingL * 10} value={totalScore.VasPainRatingL} total="10" date="26/10/2022" color="#78b776" />
                                    </div>

                                    {initialAssesment == 0 && (
                                        <>
                                            <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                                <ResultCircleProgress min_score={0}  reverse={false} title={translation.SATISFACTION} type={translation.patient_foot_ankle_results_self_evaluation} arm="Right Foot/ankle" value={totalScore.TotalSTAISFACTION} total="100" date="26/10/2022" color="#006DFF" />
                                            </div>
                                            <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                                <ResultCircleProgress min_score={0}  reverse={false} title={translation.SATISFACTION} type={translation.patient_foot_ankle_results_self_evaluation} arm="Left Foot/ankle" value={totalScore.TotalSATISFACTIONL} total="100" date="26/10/2022" color="#006DFF" />
                                            </div>
                                        </>
                                    )}


                                    <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                        <ResultCircleProgress min_score={0}  reverse={false} title={translation.EQ_VAS} type={translation.patient_foot_ankle_results_self_evaluation} arm="Foot/ankle" persent={totalScore.HealthToday} value={totalScore.HealthToday} total="100" date="26/10/2022" color="#78b776" />
                                    </div>
                                    <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                        <ResultCircleProgress min_score={0}  reverse={false} title={translation.SANE} type={translation.patient_foot_ankle_results_self_evaluation} arm="Foot/ankle" persent={totalScore.FootPercent} value={totalScore.FootPercent} total="100" date="26/10/2022" color="#006DFF" />
                                    </div>

                                    {showCumberlandR === "1" && (
                                        <>
                                            {anklePain === "1" || anklePain === "3" ? (
                                                <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                                    <ResultCircleProgress min_score={0}  reverse={false} title={translation.CAIT} type="Right Foot/ankle" arm="Right Foot/ankle" value={totalScore.CumberlandRT} total="100" date="26/10/2022" color="#006DFF" />
                                                </div>
                                            ) : ""}
                                            {anklePain === "2" || anklePain === "3" ? (
                                                <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                                    <ResultCircleProgress min_score={0}  reverse={false} title={translation.CAIT} type="Left Foot/ankle" arm="Left Foot/ankle" value={totalScore.CumberlandLT} total="100" date="26/10/2022" color="#006DFF" />
                                                </div>
                                            ) : ""}

                                        </>
                                    )}
                                </>}

                                {dominant === '3' && <>

                                    {anklePain === "1" || anklePain === "3" ? (
                                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                            <ResultCircleProgress min_score={0}  reverse={false} title={translation.HalluxScale} type={translation.patient_foot_ankle_results_self_evaluation} arm="Right Foot/ankle" value={totalScore.HalluxScaleR} total="100" date={`${day}/${month}/${year}`} color="#006DFF" />
                                        </div>
                                    ) : ""}

                                    {anklePain === "2" || anklePain === "3" ? (
                                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                            <ResultCircleProgress min_score={0}  reverse={false} title={translation.HalluxScale} type={translation.patient_foot_ankle_results_self_evaluation} arm="Left Foot/ankle" value={totalScore.HalluxScaleL} total="100" date="22/09/2022" color="#006DFF" />
                                        </div>
                                    ) : ""}

                                    {anklePain === "1" || anklePain === "3" ? (
                                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                            <ResultCircleProgress min_score={0}  reverse={false} title={translation.LesserToeScale} type={translation.patient_foot_ankle_results_self_evaluation} arm="Right Foot/ankle" value={totalScore.LesserToeScaleR} total="100" date={`${day}/${month}/${year}`} color="#006DFF" />
                                        </div>
                                    ) : ""}

                                    {anklePain === "2" || anklePain === "3" ? (
                                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                            <ResultCircleProgress min_score={0}  reverse={false} title={translation.LesserToeScale} type={translation.patient_foot_ankle_results_self_evaluation} arm="Left Foot/ankle" value={totalScore.LesserToeScaleL} total="100" date="22/09/2022" color="#006DFF" />
                                        </div>
                                    ) : ""}

                                    {anklePain === "1" || anklePain === "3" ? (
                                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                            <ResultCircleProgress min_score={0}  reverse={false} title={translation.MidfootScale} type={translation.patient_foot_ankle_results_self_evaluation} arm="Right Foot/ankle" value={totalScore.MidfootScaleR} total="100" date={`${day}/${month}/${year}`} color="#006DFF" />
                                        </div>
                                    ) : ""}

                                    {anklePain === "2" || anklePain === "3" ? (
                                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                            <ResultCircleProgress min_score={0}  reverse={false} title={translation.MidfootScale} type={translation.patient_foot_ankle_results_self_evaluation} arm="Left Foot/ankle" value={totalScore.MidfootScaleL} total="100" date="22/09/2022" color="#006DFF" />
                                        </div>
                                    ) : ""}


                                    {anklePain === "1" || anklePain === "3" ? (
                                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                            <ResultCircleProgress min_score={0}  reverse={false} title={translation.Hindfoot} type={translation.patient_foot_ankle_results_self_evaluation} arm="Right Foot/ankle" value={totalScore.HindfootScaleR} total="100" date={`${day}/${month}/${year}`} color="#006DFF" />
                                        </div>
                                    ) : ""}

                                    {anklePain === "2" || anklePain === "3" ? (
                                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                            <ResultCircleProgress min_score={0}  reverse={false} title={translation.Hindfoot} type={translation.patient_foot_ankle_results_self_evaluation} arm="Left Foot/ankle" value={totalScore.HindfootScaleL} total="100" date="22/09/2022" color="#006DFF" />
                                        </div>
                                    ) : ""}

                                    {anklePain === "1" || anklePain === "3" ? (
                                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                            <ResultCircleProgress min_score={0}  reverse={false} title={translation.FAAM_ADL_SCORE} type={translation.patient_foot_ankle_results_self_evaluation} arm="Right Foot/ankle" value={totalScore.FAAMADRScrore} total="100" date={`${day}/${month}/${year}`} color="#006DFF" />
                                        </div>
                                    ) : ""}


                                    {anklePain === "2" || anklePain === "3" ? (
                                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                            <ResultCircleProgress min_score={0}  reverse={false} title={translation.FAAM_ADL_SCORE} type={translation.patient_foot_ankle_results_self_evaluation} arm="Left Foot/ankle" value={totalScore.FAAMADLScrore} total="100" date="22/09/2022" color="#006DFF" />
                                        </div>
                                    ) : ""}


                                    {anklePain === "1" || anklePain === "3" ? (
                                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                            <ResultCircleProgress min_score={0}  reverse={false} title={translation.FAAM_SPORTS} type={translation.patient_foot_ankle_results_self_evaluation} arm="Right Foot/ankle" value={totalScore.FAAMSRScrore} total="100" date="22/09/2022" color="#ff0000" />
                                        </div>
                                    ) : ""}
                                    {anklePain === "2" || anklePain === "3" ? (
                                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                            <ResultCircleProgress min_score={0}  reverse={false} title={translation.FAAM_SPORTS} type={translation.patient_foot_ankle_results_self_evaluation} arm="Left Foot/ankle" value={totalScore.FAAMSLScrore} total="100" date="22/09/2022" color="#ff0000" />
                                        </div>
                                    ) : ""}

                                    {anklePain === "1" || anklePain === "3" ? (
                                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                            <ResultCircleProgress min_score={0}  reverse={false} title={translation.FADI_SCORE} type={translation.patient_foot_ankle_results_self_evaluation} arm="Right Foot/ankle" value={totalScore.TotalFADIRS} total="100" date="22/09/2022" color="#78b776" />
                                        </div>
                                    ) : ""}
                                    {anklePain === "2" || anklePain === "3" ? (
                                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                            <ResultCircleProgress min_score={0}  reverse={false} title={translation.FADI_SCORE} type={translation.patient_foot_ankle_results_self_evaluation} arm="Left Foot/ankle" value={totalScore.TotalFADILS} total="100" date="22/09/2022" color="#78b776" />
                                        </div>
                                    ) : ""}

                                    {anklePain === "1" || anklePain === "3" ? (
                                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                            <ResultCircleProgress min_score={0}  reverse={false} title={translation.FADI_SPORTS} type={translation.patient_foot_ankle_results_self_evaluation} arm="Right Foot/ankle" value={totalScore.FADISPORTRS} total="100" date="22/09/2022" color="#006DFF" />
                                        </div>
                                    ) : ""}
                                    {anklePain === "2" || anklePain === "3" ? (
                                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                            <ResultCircleProgress min_score={0}  reverse={false} title={translation.FADI_SPORTS} type={translation.patient_foot_ankle_results_self_evaluation} arm="Left Foot/ankle" value={totalScore.FADISPORTLS} total="100" date="22/09/2022" color="#006DFF" />
                                        </div>
                                    ) : ""}

                                    {anklePain === "1" || anklePain === "3" ? (
                                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                            <ResultCircleProgress min_score={0}  reverse={false} title={translation.FAOS_PAIN} type={translation.patient_foot_ankle_results_self_evaluation} arm="Right Foot/ankle" value={totalScore.FAOSTPAINR} total="100" date="26/10/2022" color="#ff0000" />
                                        </div>
                                    ) : ""}
                                    {anklePain === "2" || anklePain === "3" ? (
                                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                            <ResultCircleProgress min_score={0}  reverse={false} title={translation.FAOS_PAIN} type={translation.patient_foot_ankle_results_self_evaluation} arm="Left Foot/ankle" value={totalScore.FAOSTPAINL} total="100" date="26/10/2022" color="#ff0000" />
                                        </div>
                                    ) : ""}

                                    {anklePain === "1" || anklePain === "3" ? (
                                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                            <ResultCircleProgress min_score={0}  reverse={false} title={translation.FAOS_SYMPTOMS} type={translation.patient_foot_ankle_results_self_evaluation} arm="Right Foot/ankle" value={totalScore.FAOSTSYMPTOMSR} total="100" date="22/09/2022" color="#78b776" />
                                        </div>
                                    ) : ""}
                                    {anklePain === "2" || anklePain === "3" ? (
                                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                            <ResultCircleProgress min_score={0}  reverse={false} title={translation.FAOS_SYMPTOMS} type={translation.patient_foot_ankle_results_self_evaluation} arm="Left Foot/ankle" value={totalScore.FAOSTSYMPTOMSL} total="100" date="22/09/2022" color="#78b776" />
                                        </div>
                                    ) : ""}

                                    {anklePain === "1" || anklePain === "3" ? (
                                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                            <ResultCircleProgress min_score={0}  reverse={false} title={translation.FAOS_ADL} type={translation.patient_foot_ankle_results_self_evaluation} arm="Right Foot/ankle" value={totalScore.FAOSTADLR} total="100" date="26/10/2022" color="#006DFF" />
                                        </div>
                                    ) : ""}
                                    {anklePain === "2" || anklePain === "3" ? (
                                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                            <ResultCircleProgress min_score={0}  reverse={false} title={translation.FAOS_ADL} type={translation.patient_foot_ankle_results_self_evaluation} arm="Left Foot/ankle" value={totalScore.FAOSTADLL} total="100" date="26/10/2022" color="#006DFF" />
                                        </div>
                                    ) : ""}


                                    {anklePain === "1" || anklePain === "3" ? (
                                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                            <ResultCircleProgress min_score={0}  reverse={false} title={translation.FAOS_SPORT} type={translation.patient_foot_ankle_results_self_evaluation} arm="Right Foot/ankle" value={totalScore.FAOSTSPORTR} total="100" date="26/10/2022" color="#ff0000" />
                                        </div>
                                    ) : ""}
                                    {anklePain === "2" || anklePain === "3" ? (
                                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                            <ResultCircleProgress min_score={0}  reverse={false} title={translation.FAOS_SPORT} type={translation.patient_foot_ankle_results_self_evaluation} arm="Left Foot/ankle" value={totalScore.FAOSTSPORTL} total="100" date="26/10/2022" color="#ff0000" />
                                        </div>
                                    ) : ""}

                                    {anklePain === "1" || anklePain === "3" ? (
                                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                            <ResultCircleProgress min_score={0}  reverse={false} title={translation.FAOS_QOL} type={translation.patient_foot_ankle_results_self_evaluation} arm="Right Foot/ankle" value={totalScore.FAOSTQOLR} total="100" date="26/10/2022" color="#78b776" />
                                        </div>
                                    ) : ""}
                                    {anklePain === "2" || anklePain === "3" ? (
                                        <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                            <ResultCircleProgress min_score={0}  reverse={false} title={translation.FAOS_QOL} type={translation.patient_foot_ankle_results_self_evaluation} arm="Left Foot/ankle" value={totalScore.FAOSTQOLL} total="100" date="26/10/2022" color="#78b776" />
                                        </div>
                                    ) : ""}


                                    <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                        <ResultCircleProgress min_score={0}  reverse={false} title={translation.VAS_PAIN} type={translation.patient_foot_ankle_results_self_evaluation} arm="Right Foot/ankle" persent={totalScore.VasPainRating * 10} value={totalScore.VasPainRating} total="10" date="26/10/2022" color="#78b776" />
                                    </div>

                                    <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                        <ResultCircleProgress min_score={0}  reverse={false} title={translation.VAS_PAIN} type={translation.patient_foot_ankle_results_self_evaluation} arm="Left Foot/ankle" persent={totalScore.VasPainRatingL * 10} value={totalScore.VasPainRatingL} total="10" date="26/10/2022" color="#78b776" />
                                    </div>


                                    {initialAssesment == 0 && (
                                        <>
                                            <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                                <ResultCircleProgress min_score={0}  reverse={false} title={translation.SATISFACTION} type={translation.patient_foot_ankle_results_self_evaluation} arm="Right Foot/ankle" value={totalScore.TotalSTAISFACTION} total="100" date="26/10/2022" color="#006DFF" />
                                            </div>
                                            <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                                <ResultCircleProgress min_score={0}  reverse={false} title={translation.SATISFACTION} type={translation.patient_foot_ankle_results_self_evaluation} arm="Left Foot/ankle" value={totalScore.TotalSATISFACTIONL} total="100" date="26/10/2022" color="#006DFF" />
                                            </div>
                                        </>
                                    )}


                                    <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                        <ResultCircleProgress min_score={0}  reverse={false} title={translation.EQ_VAS} type={translation.patient_foot_ankle_results_self_evaluation} arm="Foot/ankle" value={totalScore.HealthToday} total="100" date="26/10/2022" color="#78b776" />
                                    </div>

                                    <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                        <ResultCircleProgress min_score={0}  reverse={false} title={translation.SANE} type={translation.patient_foot_ankle_results_self_evaluation} arm="Foot/ankle" value={totalScore.FootPercent} total="100" date="26/10/2022" color="#006DFF" />
                                    </div>

                                    <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                        <ResultCircleProgress min_score={0}  reverse={false} title={translation.SANE} type={translation.patient_foot_ankle_results_self_evaluation} arm="Foot/ankle" value={totalScore.FootPercentL} total="100" date="26/10/2022" color="#006DFF" />
                                    </div>

                                    {showCumberlandR === "1" && (
                                        <>
                                            {anklePain === "1" || anklePain === "3" ? (
                                                <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                                    <ResultCircleProgress min_score={0}  reverse={false} title={translation.CAIT} type="Right Foot/ankle" arm="Right Foot/ankle" value={totalScore.CumberlandRT} total="100" date="26/10/2022" color="#006DFF" />
                                                </div>
                                            ) : ""}
                                            {anklePain === "2" || anklePain === "3" ? (
                                                <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                                    <ResultCircleProgress min_score={0}  reverse={false} title={translation.CAIT} type="Left Foot/ankle" arm="Left Foot/ankle" value={totalScore.CumberlandLT} total="100" date="26/10/2022" color="#006DFF" />
                                                </div>
                                            ) : ""}

                                        </>
                                    )}
                                </>} */}

                    {/* //////////////////////////////////////////////\{        FINAL   SCORE        }/\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\ */}

                    {/* {anklePain === "1" || anklePain === "3" ? (
                                    <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                        <ResultCircleProgress min_score={0}  reverse={false} title={translation.HalluxScale} type={translation.patient_foot_ankle_results_self_evaluation} arm="Right Foot/ankle" value={totalScore.HalluxScaleR} total="100" date={`${day}/${month}/${year}`} color="#006DFF" />
                                    </div>
                                ) : ""}

                                {anklePain === "2" || anklePain === "3" ? (
                                    <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                        <ResultCircleProgress min_score={0}  reverse={false} title={translation.HalluxScale} type={translation.patient_foot_ankle_results_self_evaluation} arm="Left Foot/ankle" value={totalScore.HalluxScaleL} total="100" date="22/09/2022" color="#006DFF" />
                                    </div>
                                ) : ""}

                                {anklePain === "1" || anklePain === "3" ? (
                                    <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                        <ResultCircleProgress min_score={0}  reverse={false} title={translation.LesserToeScale} type={translation.patient_foot_ankle_results_self_evaluation} arm="Right Foot/ankle" value={totalScore.LesserToeScaleR} total="100" date={`${day}/${month}/${year}`} color="#006DFF" />
                                    </div>
                                ) : ""}

                                {anklePain === "2" || anklePain === "3" ? (
                                    <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                        <ResultCircleProgress min_score={0}  reverse={false} title={translation.LesserToeScale} type={translation.patient_foot_ankle_results_self_evaluation} arm="Left Foot/ankle" value={totalScore.LesserToeScaleL} total="100" date="22/09/2022" color="#006DFF" />
                                    </div>
                                ) : ""}

                                {anklePain === "1" || anklePain === "3" ? (
                                    <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                        <ResultCircleProgress min_score={0}  reverse={false} title={translation.MidfootScale} type={translation.patient_foot_ankle_results_self_evaluation} arm="Right Foot/ankle" value={totalScore.MidfootScaleR} total="100" date={`${day}/${month}/${year}`} color="#006DFF" />
                                    </div>
                                ) : ""}

                                {anklePain === "2" || anklePain === "3" ? (
                                    <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                        <ResultCircleProgress min_score={0}  reverse={false} title={translation.MidfootScale} type={translation.patient_foot_ankle_results_self_evaluation} arm="Left Foot/ankle" value={totalScore.MidfootScaleL} total="100" date="22/09/2022" color="#006DFF" />
                                    </div>
                                ) : ""}

                                {anklePain === "1" || anklePain === "3" ? (
                                    <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                        <ResultCircleProgress min_score={0}  reverse={false} title={translation.Hindfoot} type={translation.patient_foot_ankle_results_self_evaluation} arm="Right Foot/ankle" value={totalScore.HindfootScaleR} total="100" date={`${day}/${month}/${year}`} color="#006DFF" />
                                    </div>
                                ) : ""}

                                {anklePain === "2" || anklePain === "3" ? (
                                    <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                                        <ResultCircleProgress min_score={0}  reverse={false} title={translation.Hindfoot} type={translation.patient_foot_ankle_results_self_evaluation} arm="Left Foot/ankle" value={totalScore.HindfootScaleL} total="100" date="22/09/2022" color="#006DFF" />
                                    </div>
                                ) : ""} */}
                    {anklePain === "2" || anklePain === "3" ? (
                      <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                        <ResultCircleProgress min_score={0} reverse={false}
                          title={translation.patient_foot_ankle_results_function}
                          type={translation.patient_foot_ankle_results_self_evaluation}
                          arm={translation.left_foot_ankle}
                          value={totalScore.FAAMADLScrore}
                          total="100"
                          date="22/09/2022"
                          color="#006DFF"
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    {anklePain === "1" || anklePain === "3" ? (
                      <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                        <ResultCircleProgress min_score={0} reverse={false}
                          title={translation.patient_foot_ankle_results_function}
                          type={translation.patient_foot_ankle_results_self_evaluation}
                          arm={translation.right_foot_ankle}
                          value={totalScore.FAAMADRScrore}
                          total="100"
                          date={`${day}/${month}/${year}`}
                          color="#006DFF"
                        />
                      </div>
                    ) : (
                      ""
                    )}



                    {anklePain === "1" || anklePain === "3" ? (
                      <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                        <ResultCircleProgress min_score={0} reverse={false}
                          title={translation.FAAM_SPORTS}
                          type={translation.patient_foot_ankle_results_self_evaluation}
                          arm={translation.right_foot_ankle}
                          value={totalScore.FAAMSRScrore}
                          total="100"
                          date="22/09/2022"
                          color="#ff0000"
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    {anklePain === "2" || anklePain === "3" ? (
                      <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                        <ResultCircleProgress min_score={0} reverse={false}
                          title={translation.FAAM_SPORTS}
                          type={translation.patient_foot_ankle_results_self_evaluation}
                          arm={translation.left_foot_ankle}
                          value={totalScore.FAAMSLScrore}
                          total="100"
                          date="22/09/2022"
                          color="#ff0000"
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    {anklePain === "1" || anklePain === "3" ? (
                      <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                        <ResultCircleProgress min_score={0} reverse={false}
                          title={translation.FADI_SCORE}
                          type={translation.patient_foot_ankle_results_self_evaluation}
                          arm={translation.right_foot_ankle}
                          value={totalScore.TotalFADIRS}
                          total="100"
                          date="22/09/2022"
                          color="#78b776"
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    {anklePain === "2" || anklePain === "3" ? (
                      <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                        <ResultCircleProgress min_score={0} reverse={false}
                          title={translation.FADI_SCORE}
                          type={translation.patient_foot_ankle_results_self_evaluation}
                          arm={translation.left_foot_ankle}
                          value={totalScore.TotalFADILS}
                          total="100"
                          date="22/09/2022"
                          color="#78b776"
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    {anklePain === "1" || anklePain === "3" ? (
                      <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                        <ResultCircleProgress min_score={0} reverse={false}
                          title={translation.FADI_SPORTS}
                          type={translation.patient_foot_ankle_results_self_evaluation}
                          arm={translation.right_foot_ankle}
                          value={totalScore.FADISPORTRS}
                          total="100"
                          date="22/09/2022"
                          color="#006DFF"
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    {anklePain === "2" || anklePain === "3" ? (
                      <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                        <ResultCircleProgress min_score={0} reverse={false}
                          title={translation.FADI_SPORTS}
                          type={translation.patient_foot_ankle_results_self_evaluation}
                          arm={translation.left_foot_ankle}
                          value={totalScore.FADISPORTLS}
                          total="100"
                          date="22/09/2022"
                          color="#006DFF"
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    {anklePain === "1" || anklePain === "3" ? (
                      <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                        <ResultCircleProgress min_score={0} reverse={false}
                          title={translation.FAOS_PAIN}
                          type={translation.patient_foot_ankle_results_self_evaluation}
                          arm={translation.right_foot_ankle}
                          value={totalScore.FAOSTPAINR}
                          total="100"
                          date="26/10/2022"
                          color="#ff0000"
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    {anklePain === "2" || anklePain === "3" ? (
                      <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                        <ResultCircleProgress min_score={0} reverse={false}
                          title={translation.FAOS_PAIN}
                          type={translation.patient_foot_ankle_results_self_evaluation}
                          arm={translation.left_foot_ankle}
                          value={totalScore.FAOSTPAINL}
                          total="100"
                          date="26/10/2022"
                          color="#ff0000"
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    {anklePain === "1" || anklePain === "3" ? (
                      <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                        <ResultCircleProgress min_score={0} reverse={false}
                          title={translation.FAOS_SYMPTOMS}
                          type={translation.patient_foot_ankle_results_self_evaluation}
                          arm={translation.right_foot_ankle}
                          value={totalScore.FAOSTSYMPTOMSR}
                          total="100"
                          date="22/09/2022"
                          color="#78b776"
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    {anklePain === "2" || anklePain === "3" ? (
                      <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                        <ResultCircleProgress min_score={0} reverse={false}
                          title={translation.FAOS_SYMPTOMS}
                          type={translation.patient_foot_ankle_results_self_evaluation}
                          arm={translation.left_foot_ankle}
                          value={totalScore.FAOSTSYMPTOMSL}
                          total="100"
                          date="22/09/2022"
                          color="#78b776"
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    {anklePain === "1" || anklePain === "3" ? (
                      <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                        <ResultCircleProgress min_score={0} reverse={false}
                          title={translation.FAOS_ADL}
                          type={translation.patient_foot_ankle_results_self_evaluation}
                          arm={translation.right_foot_ankle}
                          value={totalScore.FAOSTADLR}
                          total="100"
                          date="26/10/2022"
                          color="#006DFF"
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    {anklePain === "2" || anklePain === "3" ? (
                      <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                        <ResultCircleProgress min_score={0} reverse={false}
                          title={translation.FAOS_ADL}
                          type={translation.patient_foot_ankle_results_self_evaluation}
                          arm={translation.left_foot_ankle}
                          value={totalScore.FAOSTADLL}
                          total="100"
                          date="26/10/2022"
                          color="#006DFF"
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    {anklePain === "1" || anklePain === "3" ? (
                      <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                        <ResultCircleProgress min_score={0} reverse={false}
                          title={translation.FAOS_SPORT}
                          type={translation.patient_foot_ankle_results_self_evaluation}
                          arm={translation.right_foot_ankle}
                          value={totalScore.FAOSTSPORTR}
                          total="100"
                          date="26/10/2022"
                          color="#ff0000"
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    {anklePain === "2" || anklePain === "3" ? (
                      <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                        <ResultCircleProgress min_score={0} reverse={false}
                          title={translation.FAOS_SPORT}
                          type={translation.patient_foot_ankle_results_self_evaluation}
                          arm={translation.left_foot_ankle}
                          value={totalScore.FAOSTSPORTL}
                          total="100"
                          date="26/10/2022"
                          color="#ff0000"
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    {anklePain === "1" || anklePain === "3" ? (
                      <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                        <ResultCircleProgress min_score={0} reverse={false}
                          title={translation.FAOS_QOL}
                          type={translation.patient_foot_ankle_results_self_evaluation}
                          arm={translation.right_foot_ankle}
                          value={totalScore.FAOSTQOLR}
                          total="100"
                          date="26/10/2022"
                          color="#78b776"
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    {anklePain === "2" || anklePain === "3" ? (
                      <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                        <ResultCircleProgress min_score={0} reverse={false}
                          title={translation.FAOS_QOL}
                          type={translation.patient_foot_ankle_results_self_evaluation}
                          arm={translation.left_foot_ankle}
                          value={totalScore.FAOSTQOLL}
                          total="100"
                          date="26/10/2022"
                          color="#78b776"
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    {anklePain === "2" || anklePain === "3" ? (
                      <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 ">
                        <ResultCircleProgress min_score={0} reverse={false}
                          title={translation.patient_foot_ankle_results_pain}
                          type={translation.patient_foot_ankle_results_self_evaluation}
                          arm={translation.left_foot_ankle}
                          persent={totalScore.VasPainRatingL * 10}
                          value={totalScore.VasPainRatingL}
                          total="10"
                          date="26/10/2022"
                          color="#78b776"
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    {anklePain === "1" || anklePain === "3" ? (
                      <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 ">
                        <ResultCircleProgress min_score={0} reverse={false}
                          title={translation.patient_foot_ankle_results_pain}
                          type={translation.patient_foot_ankle_results_self_evaluation}
                          arm={translation.right_foot_ankle}
                          persent={totalScore.VasPainRating * 10}
                          value={totalScore.VasPainRating}
                          total="10"
                          date="26/10/2022"
                          color="#78b776"
                        />
                      </div>
                    ) : (
                      ""
                    )}




                    <>
                      <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                        <ResultCircleProgress min_score={0} reverse={false}
                          title={translation.SATISFACTION}
                          type={translation.patient_foot_ankle_results_self_evaluation}
                          arm={translation.right_foot_ankle}
                          value={totalScore.TotalSTAISFACTION}
                          total="100"
                          date="26/10/2022"
                          color="#006DFF"
                        />
                      </div>
                      <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                        <ResultCircleProgress min_score={0} reverse={false}
                          title={translation.SATISFACTION}
                          type={translation.patient_foot_ankle_results_self_evaluation}
                          arm={translation.left_foot_ankle}
                          value={totalScore.TotalSATISFACTIONL}
                          total="100"
                          date="26/10/2022"
                          color="#006DFF"
                        />
                      </div>
                    </>


                    {/* <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                  <ResultCircleProgress min_score={0} reverse={false}
                    title={translation.patient_foot_ankle_results_overall_health_perception}
                    type={translation.patient_foot_ankle_results_self_evaluation}
                    // arm={translation.Foot_ankle}
                    value={totalScore.HealthToday}
                    total="100"
                    date="26/10/2022"
                    color="#78b776"

                  />
                </div> */}

                    {anklePain === "1" || anklePain === "3" ? (
                      <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                        <ResultCircleProgress min_score={0} reverse={false}
                          title={translation.SANE}
                          type={translation.patient_foot_ankle_results_self_evaluation}
                          arm={translation.Foot_ankle}
                          value={totalScore.FootPercent}
                          total="100"
                          date="26/10/2022"
                          color="#006DFF"

                        />
                      </div>
                    ) : (
                      ""
                    )}

                    {anklePain === "2" || anklePain === "3" ? (
                      <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                        <ResultCircleProgress min_score={0} reverse={false}
                          title={translation.SANE}
                          type={translation.patient_foot_ankle_results_self_evaluation}
                          arm={translation.Foot_ankle}
                          value={totalScore.FootPercentL}
                          total="100"
                          date="26/10/2022"
                          color="#006DFF"

                        />
                      </div>
                    ) : (
                      ""
                    )}

                    {anklePain === "2" || anklePain === "3" ? (
                      <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                        <ResultCircleProgress min_score={0} reverse={false}
                          title={translation.patient_foot_ankle_results_feeling_of_instability}
                          type={translation.patient_foot_ankle_results_self_evaluation}
                          arm={translation.left_foot_ankle}
                          value={totalScore.CumberlandLT}
                          total="30"
                          date="26/10/2022"
                          color="#006DFF"
                        />
                      </div>
                    ) : (
                      ""
                    )}

                    {anklePain === "1" || anklePain === "3" ? (
                      <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6">
                        <ResultCircleProgress min_score={0} reverse={false}
                          title={translation.patient_foot_ankle_results_feeling_of_instability}
                          type={translation.patient_foot_ankle_results_self_evaluation}
                          arm={translation.right_foot_ankle}
                          value={totalScore.CumberlandRT}
                          total="30"
                          date="26/10/2022"
                          color="#006DFF"
                        />
                      </div>
                    ) : (
                      ""
                    )}




                    <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                      <ResultCircleProgress min_score={1} reverse={true}
                        title={translation.EQ_5D_5L_Mobility}
                        hide={"1"}
                        value={`${translation.Level} ${EQDWalking}`}
                        date="26/10/2022"
                        color="#006DFF"
                      />
                    </div>

                    <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                      <ResultCircleProgress min_score={1} reverse={true}
                        title={translation.EQ_5D_5L_SELF_CARE}
                        hide={"1"}
                        value={`${translation.Level} ${EqdPersonalCare}`}
                        date="26/10/2022"
                        color="#ff0000"
                      />
                    </div>

                    <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                      <ResultCircleProgress min_score={1} reverse={true}
                        title={translation.EQ_5D_5L_Usual_Activities}
                        hide={"1"}
                        value={`${translation.Level} ${EqdActivities}`}
                        date="26/10/2022"
                        color="#78b776"
                      />
                    </div>

                    <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                      <ResultCircleProgress min_score={1} reverse={true}
                        title={translation.EQ_5D_5L_Anxiety}
                        hide={"1"}
                        value={`${translation.Level} ${Eqdsanxious}`}
                        date="26/10/2022"
                        color="#006DFF"
                      />
                    </div>

                    <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                      <ResultCircleProgress min_score={1} reverse={true}
                        title={translation.EQ_5D_5L_Pain_Discomfort}
                        hide={"1"}
                        value={`${translation.Level} ${EQPain}`}
                        date="26/10/2022"
                        color="#ff0000"
                      />
                    </div>

                    {/* <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                  <ResultCircleProgress min_score={0}  reverse={false}
                    title={translation.Ankle_Activity_Prior}
                    type={translation.patient_foot_ankle_results_self_evaluation}
                    arm="Foot/ankle"
                    persent={prior}
                    value={prior}
                    total="5"
                    date="26/10/2022"
                    color="#ff0000"
                  />
                </div> */}

                    {/*
                                <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 d-none">
                                    <ResultCircleProgress min_score={0}  reverse={false} title={translation.Ankle_Activity_Current} type={translation.patient_foot_ankle_results_self_evaluation} arm="Foot/ankle" persent={EQPain * 20} value={} total="Level 5" date="26/10/2022" color="#ff0000" />
                                </div>
                                */}

                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      )}
      <SubmitModel
        showModal={displayConfirmationModal} patientID={window.btoa(sessionStorage.getItem("patient_akid"))}

      />
    </>

  );
}

export default FootAnkleResult;
