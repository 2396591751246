import React from "react";
import useTranslation from "../../customHooks/translations";

export default function HandWristSatisfaction({ handSatisfaction, AllData }) {
  const translations = useTranslation();
  console.log(handSatisfaction.motionR, "motionR");
  return (
    <>
      <div className="whitebox padding-none">
        <div className="treatment-content">
          <div className="treatment-row-header">
            7. {translations.patient_hand_satisfaction}
          </div>
          <div className="treatment-row">
            <div className="treatment-table">
              <table cellpadding="0" cellspacing="0">
                <thead>
                  <tr>
                    <th></th>
                    <th>{translations.Right_Hand_nd_Wrist}</th>
                    <th>{translations.Left_Hand_nd_Wrist}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{translations.patient_hand_satisfaction_q1}(s):</td>
                    <td>
                      {handSatisfaction.motionR == "0"
                        ? translations.patient_hand_satisfaction_q1_a1
                        : handSatisfaction.motionR == "1"
                        ? translations.patient_hand_satisfaction_q1_a2
                        : handSatisfaction.motionR == "2"
                        ? translations.patient_hand_satisfaction_q1_a3
                        : handSatisfaction.motionR == "3"
                        ? translations.patient_hand_satisfaction_q1_a4
                        : handSatisfaction.motionR == "4"
                        ? translations.patient_hand_satisfaction_q1_a5
                        : ""}
                    </td>
                    <td>
                      {handSatisfaction.motionL == "0"
                        ? translations.patient_hand_satisfaction_q1_a1
                        : handSatisfaction.motionL == "1"
                        ? translations.patient_hand_satisfaction_q1_a2
                        : handSatisfaction.motionL == "2"
                        ? translations.patient_hand_satisfaction_q1_a3
                        : handSatisfaction.motionL == "3"
                        ? translations.patient_hand_satisfaction_q1_a4
                        : handSatisfaction.motionL == "4"
                        ? translations.patient_hand_satisfaction_q1_a5
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <td>{translations.patient_hand_satisfaction_q2}(s):</td>
                    <td>
                      {handSatisfaction.wristR == "0"
                        ? translations.patient_hand_satisfaction_q1_a1
                        : handSatisfaction.wristR == "1"
                        ? translations.patient_hand_satisfaction_q1_a2
                        : handSatisfaction.wristR == "2"
                        ? translations.patient_hand_satisfaction_q1_a3
                        : handSatisfaction.wristR == "3"
                        ? translations.patient_hand_satisfaction_q1_a4
                        : handSatisfaction.wristR == "4"
                        ? translations.patient_hand_satisfaction_q1_a5
                        : ""}
                    </td>
                    <td>
                      {handSatisfaction.wristL == "0"
                        ? translations.patient_hand_satisfaction_q1_a1
                        : handSatisfaction.wristL == "1"
                        ? translations.patient_hand_satisfaction_q1_a2
                        : handSatisfaction.wristL == "2"
                        ? translations.patient_hand_satisfaction_q1_a3
                        : handSatisfaction.wristL == "3"
                        ? translations.patient_hand_satisfaction_q1_a4
                        : handSatisfaction.wristL == "4"
                        ? translations.patient_hand_satisfaction_q1_a5
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <td>{translations.patient_hand_satisfaction_q3}(s):</td>
                    <td>
                      {handSatisfaction.handR == "0"
                        ? translations.patient_hand_satisfaction_q3_a1
                        : handSatisfaction.handR == "1"
                        ? translations.patient_hand_satisfaction_q3_a2
                        : handSatisfaction.handR == "2"
                        ? translations.patient_hand_satisfaction_q3_a3
                        : handSatisfaction.handR == "3"
                        ? translations.patient_hand_satisfaction_q3_a4
                        : handSatisfaction.handR == "4"
                        ? translations.patient_hand_satisfaction_q3_a5
                        : ""}
                    </td>
                    <td>
                      {handSatisfaction.handL == "0"
                        ? translations.patient_hand_satisfaction_q3_a1
                        : handSatisfaction.handL == "1"
                        ? translations.patient_hand_satisfaction_q3_a2
                        : handSatisfaction.handL == "2"
                        ? translations.patient_hand_satisfaction_q3_a3
                        : handSatisfaction.handL == "3"
                        ? translations.patient_hand_satisfaction_q3_a4
                        : handSatisfaction.handL == "4"
                        ? translations.patient_hand_satisfaction_q3_a5
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <td>{translations.patient_hand_satisfaction_q4}(s):</td>
                    <td>
                      {handSatisfaction.applicableR ? "Not applicable" : ""}
                      {/* {handSatisfaction.applicableR?"Not applicable": handSatisfaction.wristsurR} */}

                      {handSatisfaction.wristsurR == "0"
                        ? translations.patient_hand_satisfaction_q4_a1
                        : handSatisfaction.wristsurR == "1"
                        ? translations.patient_hand_satisfaction_q4_a2
                        : handSatisfaction.wristsurR == "2"
                        ? translations.patient_hand_satisfaction_q4_a3
                        : handSatisfaction.wristsurR == "3"
                        ? translations.patient_hand_satisfaction_q4_a4
                        : handSatisfaction.wristsurR == "4"
                        ? translations.patient_hand_satisfaction_q4_a5
                        : ""}
                    </td>
                    <td>
                      {handSatisfaction.applicableL
                        ? translations.patient_hand_satisfaction_q4_na
                        : ""}
                      {/* {handSatisfaction.applicableL?"Not applicable":handSatisfaction.wristsurL} */}

                      {handSatisfaction.wristsurL == "0"
                        ? translations.patient_hand_satisfaction_q4_a1
                        : handSatisfaction.wristsurL == "1"
                        ? translations.patient_hand_satisfaction_q4_a2
                        : handSatisfaction.wristsurL == "2"
                        ? translations.patient_hand_satisfaction_q4_a3
                        : handSatisfaction.wristsurL == "3"
                        ? translations.patient_hand_satisfaction_q4_a4
                        : handSatisfaction.wristsurL == "4"
                        ? translations.patient_hand_satisfaction_q4_a5
                        : ""}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
