import React from "react";
import "../../../css/ViewTreatmentForm.css";
import useTranslation from "../../customHooks/translations";
export default function PediaKneeQOL({ pediaQOL, AllData }) {
  const translations = useTranslation();
  return (
    <>
      <div className="whitebox padding-none">
        <div className="treatment-content">
          <div className="treatment-row-header">
            4. {translations.patient_pedia_knee_quality_of_life}
          </div>
          <div className="treatment-row">
            <div className="treatment-table">
              <table cellpadding="0" cellspacing="0">
                <thead>
                  <tr>
                    <th></th>
                    <th>{translations.Right}</th>
                    <th>{translations.Left}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {translations.patient_pedia_knee_quality_of_life_q1} :
                    </td>

                    <td>
                      {pediaQOL.howOftenR === "0"
                        ? translations.patient_pedia_knee_quality_of_life_q1_a1
                        : pediaQOL.howOftenR === "1"
                        ? translations.patient_pedia_knee_quality_of_life_q1_a2
                        : pediaQOL.howOftenR === "2"
                        ? translations.patient_pedia_knee_quality_of_life_q1_a3
                        : pediaQOL.howOftenR === "3"
                        ? translations.patient_pedia_knee_quality_of_life_q1_a4
                        : pediaQOL.howOftenR === "4"
                        ? translations.patient_pedia_knee_quality_of_life_q1_a5
                        : ""}
                    </td>
                    <td>
                      {pediaQOL.howOftenL === "0"
                        ? translations.patient_pedia_knee_quality_of_life_q1_a1
                        : pediaQOL.howOftenL === "1"
                        ? translations.patient_pedia_knee_quality_of_life_q1_a2
                        : pediaQOL.howOftenL === "2"
                        ? translations.patient_pedia_knee_quality_of_life_q1_a3
                        : pediaQOL.howOftenL === "3"
                        ? translations.patient_pedia_knee_quality_of_life_q1_a4
                        : pediaQOL.howOftenL === "4"
                        ? translations.patient_pedia_knee_quality_of_life_q1_a5
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <td>{translations.patient_pedia_knee_problem_q2} :</td>

                    <td>
                      {pediaQOL.lifestyleR === "0"
                        ? translations.patient_pedia_knee_problem_q2_a1
                        : pediaQOL.lifestyleR === "1"
                        ? translations.patient_pedia_knee_problem_q2_a2
                        : pediaQOL.lifestyleR === "2"
                        ? translations.patient_pedia_knee_problem_q2_a3
                        : pediaQOL.lifestyleR === "3"
                        ? translations.patient_pedia_knee_problem_q2_a4
                        : pediaQOL.lifestyleR === "4"
                        ? translations.patient_pedia_knee_problem_q2_a5
                        : ""}
                    </td>
                    <td>
                      {pediaQOL.lifestyleL === "0"
                        ? translations.patient_pedia_knee_problem_q2_a1
                        : pediaQOL.lifestyleL === "1"
                        ? translations.patient_pedia_knee_problem_q2_a2
                        : pediaQOL.lifestyleL === "2"
                        ? translations.patient_pedia_knee_problem_q2_a3
                        : pediaQOL.lifestyleL === "3"
                        ? translations.patient_pedia_knee_problem_q2_a4
                        : pediaQOL.lifestyleL === "4"
                        ? translations.patient_pedia_knee_problem_q2_a5
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <td>{translations.patient_pedia_knee_problem_q3} :</td>

                    <td>
                      {pediaQOL.HowMuchInjuredR === "0"
                        ? translations.patient_pedia_knee_problem_q3_a1
                        : pediaQOL.HowMuchInjuredR === "1"
                        ? translations.patient_pedia_knee_problem_q3_a2
                        : pediaQOL.HowMuchInjuredR === "2"
                        ? translations.patient_pedia_knee_problem_q3_a3
                        : pediaQOL.HowMuchInjuredR === "3"
                        ? translations.patient_pedia_knee_problem_q3_a4
                        : pediaQOL.HowMuchInjuredR === "4"
                        ? translations.patient_pedia_knee_problem_q3_a5
                        : ""}
                    </td>
                    <td>
                      {pediaQOL.HowMuchInjuredL === "0"
                        ? translations.patient_pedia_knee_problem_q3_a1
                        : pediaQOL.HowMuchInjuredL === "1"
                        ? translations.patient_pedia_knee_problem_q3_a2
                        : pediaQOL.HowMuchInjuredL === "2"
                        ? translations.patient_pedia_knee_problem_q3_a3
                        : pediaQOL.HowMuchInjuredL === "3"
                        ? translations.patient_pedia_knee_problem_q3_a4
                        : pediaQOL.HowMuchInjuredL === "4"
                        ? translations.patient_pedia_knee_problem_q3_a5
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <td>{translations.patient_pedia_knee_problem_q4} :</td>

                    <td>
                      {pediaQOL.difficultyR === "0"
                        ? translations.patient_pedia_knee_problem_q4_a1
                        : pediaQOL.difficultyR === "1"
                        ? translations.patient_pedia_knee_problem_q4_a2
                        : pediaQOL.difficultyR === "2"
                        ? translations.patient_pedia_knee_problem_q4_a3
                        : pediaQOL.difficultyR === "3"
                        ? translations.patient_pedia_knee_problem_q4_a4
                        : pediaQOL.difficultyR === "4"
                        ? translations.patient_pedia_knee_problem_q4_a5
                        : ""}
                    </td>
                    <td>
                      {pediaQOL.difficultyL === "0"
                        ? translations.patient_pedia_knee_problem_q4_a1
                        : pediaQOL.difficultyL === "1"
                        ? translations.patient_pedia_knee_problem_q4_a2
                        : pediaQOL.difficultyL === "2"
                        ? translations.patient_pedia_knee_problem_q4_a3
                        : pediaQOL.difficultyL === "3"
                        ? translations.patient_pedia_knee_problem_q4_a4
                        : pediaQOL.difficultyL === "4"
                        ? translations.patient_pedia_knee_problem_q4_a5
                        : ""}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
