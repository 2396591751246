import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import useTranslation from "../../../customHooks/translations"
// import { validateInput } from "../../../utilities/Validator";
import scrangeStyles from "./KneeScoreRangeSlider.module.css";

function KneeScoreRangeSlider({
  classes,
  label,
  onChange,
  onMouseUp,
  value,
  validators,
  sliderData,
  maximun,
  minimum,
  onClick,
  ...sliderProps
}) {
  const translation = useTranslation()
  const [countR, setCountR] = useState(0);
  const handleChange = (event) => {
    const { value } = event.target;
    onChange(value);
  };
  // const handleClick = (event) => {
  //   const { value } = event.target;
  //   onClick && onClick(value);
  // };
  // useEffect(()=>{
  //   handleClick(value)
  // },[value])
  const [sliderDatas, setsliderDatas] = useState([
    { score: "0", label: translation.no_contraction },
    { score: "1", label: translation.no_contraction },
    { score: "2", label: translation.movement_gravity_eliminated },
    { score: "3", label:  translation.movement_against_gravity },
    { score: "4", label: translation.movement_some_resistance },
    { score: "5", label: translation.normal_power },
  ])
  useEffect(() => {
    if (sliderData) {
      setsliderDatas(sliderData)
    }
  }, [sliderData])

  return (
    <>
      <div className={`score_range_slider ${scrangeStyles.score_range_slider}`}>
        <label>{label}</label>
        <div className={`${scrangeStyles.tabslider_content}`}>
          <div className={`${scrangeStyles.range}`}>
            <input
              {...sliderProps}
              min={maximun ? maximun : "0"}
              max={minimum ? minimum : "4"}
              steps="1"
              type="range"
              value={value}
              className={`slider ${classes}`}
              id="myRange"
              onChange={handleChange}
              onClick={handleChange}
              
            />
          </div>
          <ul className={` range_labels ${scrangeStyles.range_labels}`}>
            {sliderDatas.map((data) =>
              <li className={`${value === data.score ? "active" : ""}`} key={data.score}>
                {data.score} <span>{data.label}</span>
              </li>
            )}

          </ul>
        </div>
      </div>
    </>
  );
}

KneeScoreRangeSlider.propTypes = {
  validators: PropTypes.array,
  validators: PropTypes.array,
};

KneeScoreRangeSlider.defaultProps = {
  validators: [],
  label: "",
  value: "",
  // sliderData: [
  //   { score: "0", label: 'no contraction'},
  //   { score: "1", label: 'flicker'},
  //   { score: "2", label: 'movement with gravity eliminated'},
  //   { score: "3", label: 'movement against gravity; '},
  //   { score: "4", label: 'movement with some resistance'},
  //   { score: "5", label: 'normal power'},

  // ]
};

export default KneeScoreRangeSlider;
