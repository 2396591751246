import React, { useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import backIcon from "../../../../../images/back.png";
import nextWhiteIcon from "../../../../../images/next-white.png";
import nextIcon from "../../../../../images/next.png";
import LoadingSpinner from "../../../../custom/loader/LoadingSpinner";
import {
  updateAdultKneeSatisfactionScore,
  updateAdultKneeSatisfactionFieldScore,
  updateAdultKneeQolScore,
  updateAdultKneeSymptomsScore,
  updateAdultKneeScores,
  updateAdultKneePainScores,
  updateAdultKneeFunctionScore,
  updateAdultKneeNorwichInstaScore,
  updateAllData,
  updateAdultKneeFeedback
} from "../../../../../redux/actions/index";
import useAxios from "../../../../../axiosinstance";
import useTransition from '../../../../customHooks/translations';
import AdultkneeTab from '../../../tab-section/AdultkneeTab';
import { useSearchParams } from "react-router-dom";
import CustomToaster from '../../../../custom/toaster/CustomToaster'
import HipScoreRangeSlider from "../../../../custom/hip-score-range-slider/HipScoreRangeSlider";
import { LeftSide, LeftTrans, RightSide, RigthTrans } from "../../../../../utilities/RightLeftFormat";
import NameCustom from "../../../../custom/name/NameCustom";



function AdultKneeSatisfaction() {
  const translation = useTransition();
  let lg = sessionStorage.getItem("language");
  const { rId } = useParams();
  const [searchParams] = useSearchParams();
  let doctorEnable = searchParams.get("d");
  const axiosinstance = useAxios();
  const [isLoading, setIsLoading] = useState(true);
  const [overallR, setOverallR] = useState("4");
  const [overallL, setOverallL] = useState("4");
  const [improvingPainR, setImprovingPainR] = useState("4");
  const [improvingPainL, setImprovingPainL] = useState("4");
  const [improvingHomeR, setImprovingHomeR] = useState("4");
  const [improvingHomeL, setImprovingHomeL] = useState("4");
  const [improvingActivitiesR, setImprovingActivitiesR] = useState("4");
  const [improvingActivitiesL, setImprovingActivitiesL] = useState("4");
  const [applicable, setapplicable] = useState(false)

  const [applicableR, setapplicableR] = useState(false)
  const [applicableL, setapplicableL] = useState(false)

  const [applicable1R, setapplicable1R] = useState(false)
  const [applicable1L, setapplicable1L] = useState(false)



  const [applicable2R, setapplicable2R] = useState(false)
  const [applicable2L, setapplicable2L] = useState(false)



  const [applicable3R, setapplicable3R] = useState(false)
  const [applicable3L, setapplicable3L] = useState(false)




  const evlAdultKnee = useSelector(
    (state) => state.adultKneeScore.evlAdultKnee
  );
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [chk, setChk] = useState(false);
  const [updateTotal, setUpdateTotal] = useState(false);
  const [doctorStatus, setDoctorStatus] = useState(false);


  const save = () => {
    axiosinstance.current
      .get(`extapp/forms/all_forms?resource_id=${rId}&type=5`)
      .then((res) => {
        console.log(res.data.data2);
        dispatch(updateAdultKneePainScores(res.data.data2[0].painScore));
        dispatch(updateAdultKneeSymptomsScore(res.data.data2[0].symptomsScore));
        dispatch(updateAdultKneeScores(res.data.data2[0].evlAdultKnee));
        dispatch(updateAdultKneeQolScore(res.data.data2[0].qolScore));
        dispatch(updateAdultKneeFunctionScore(res.data.data2[0].functionScore));
        dispatch(updateAdultKneeNorwichInstaScore(res.data.data2[0].norwichInstability));
        dispatch(
          updateAdultKneeSatisfactionScore(res.data.data2[0].satisfactionScore)
        );
        dispatch(
          updateAdultKneeFeedback(res.data.data2[0].doctorFeedback)
        );
        setDoctorStatus(res.data.doctorStatus);
        setChk(true);
      }).catch((err) => {

        navigate('/un-authorized-access');

      });
  };

  useEffect(() => {
    save();
  }, []);
  const KneesatisfactionScore = useSelector((state) => state.adultKneeScore.satisfactionScore[0]);
  const json = useSelector((state) => state.adultKneeScore);
  useEffect(() => {
    setOverallR(KneesatisfactionScore.overallR)
    setOverallL(KneesatisfactionScore.overallL)
    setImprovingPainR(KneesatisfactionScore.improvingPainR)
    setImprovingPainL(KneesatisfactionScore.improvingPainL)
    setImprovingHomeR(KneesatisfactionScore.improvingHomeR)
    setImprovingHomeL(KneesatisfactionScore.improvingHomeL)
    setImprovingActivitiesR(KneesatisfactionScore.improvingActivitiesR)
    setImprovingActivitiesL(KneesatisfactionScore.improvingActivitiesL)
    setapplicableR(KneesatisfactionScore.applicableR)
    setapplicableL(KneesatisfactionScore.applicableL)
    setapplicable1R(KneesatisfactionScore.applicable1R)
    setapplicable1L(KneesatisfactionScore.applicable1L)
    setapplicable2R(KneesatisfactionScore.applicable2R)
    setapplicable2L(KneesatisfactionScore.applicable2L)
    setapplicable3R(KneesatisfactionScore.applicable3R)
    setapplicable3L(KneesatisfactionScore.applicable3L)

  }, [KneesatisfactionScore])

  const updateDate = () => {
    axiosinstance.current
      .patch(
        `extapp/forms/update_all_form_type?resource_id=${rId}&action=${doctorEnable === "true"?"1":"0"}`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res);
      });
  };
  useEffect(() => {
    if (chk) {
      updateDate();
    }
  }, [KneesatisfactionScore, updateTotal]);


  // const Checkbox = ({ fnClick, fnChange, title = "", checked = false }) => (
  //   <label>
  //     <input
  //       onClick={e => {
  //         if (fnClick !== undefined) fnClick(e.target.checked);
  //       }}
  //       onChange={e => {
  //         if (fnChange !== undefined) fnChange(e.target.checked);
  //       }}
  //       type="checkbox"
  //       checked={checked}
  //     />
  //     {" Checkbox " + title}
  //   </label>
  // );

  // const Filtros = () => {
  //   const initialState = {
  //     click: false,
  //     change: false
  //   };
  // // }
  // const [state, setState] = useState(false);
  // const clearFilter = () => setState(true);

  const handleOverallR = (e) => {
    setOverallR(e);
    dispatch(updateAdultKneeSatisfactionFieldScore({
      "key": 'overallR',
      "value": e
    }))
  };
  const handleOverallL = (e) => {
    setOverallL(e);
    dispatch(updateAdultKneeSatisfactionFieldScore({
      "key": 'overallL',
      "value": e
    }))
  };
  const handleImprovingPainR = (e) => {
    setImprovingPainR(e);
    dispatch(updateAdultKneeSatisfactionFieldScore({
      "key": 'improvingPainR',
      "value": e
    }))
  };
  const handleImprovingPainL = (e) => {
    setImprovingPainL(e);
    dispatch(updateAdultKneeSatisfactionFieldScore({
      "key": 'improvingPainL',
      "value": e
    }))
  };
  const handleImprovingHomeR = (e) => {
    setImprovingHomeR(e);
    dispatch(updateAdultKneeSatisfactionFieldScore({
      "key": 'improvingHomeR',
      "value": e
    }))
  };
  const handleImprovingHomeL = (e) => {
    setImprovingHomeL(e);
    dispatch(updateAdultKneeSatisfactionFieldScore({
      "key": 'improvingHomeL',
      "value": e
    }))
  };
  const handleImprovingActivitiesR = (e) => {
    setImprovingActivitiesR(e);
    dispatch(updateAdultKneeSatisfactionFieldScore({
      "key": 'improvingActivitiesR',
      "value": e
    }))
  };
  const handleImprovingActivitiesL = (e) => {
    setImprovingActivitiesL(e);
    dispatch(updateAdultKneeSatisfactionFieldScore({
      "key": 'improvingActivitiesL',
      "value": e
    }))
  };
  const handleapplicable = (e) => {
    setapplicable(e)
    // dispatch(updateAdultKneeSatisfactionFieldScore({
    //   "key": 'applicable',
    //   "value": e
    // }))
  }
  const handleChange = (e) => {
    setapplicable(false)
  }



  const handleapplicableR = (e) => {
    setapplicableR(e)
    // setOverallR("0")
    dispatch(updateAdultKneeSatisfactionFieldScore({
      "key": 'applicableR',
      "value": e
    }))
    dispatch(updateAdultKneeSatisfactionFieldScore({
      "key": 'overallR',
      "value": ""
    }))
  }

  const handleapplicableL = (e) => {
    setapplicableL(e)
    // setOverallL("0")
    dispatch(updateAdultKneeSatisfactionFieldScore({
      "key": 'applicableL',
      "value": e
    }))
    dispatch(updateAdultKneeSatisfactionFieldScore({
      "key": 'overallL',
      "value": ""
    }))
  }


  const handleapplicable1R = (e) => {
    setapplicable1R(e)
    // setImprovePainR("0")
    dispatch(updateAdultKneeSatisfactionFieldScore({
      "key": 'applicable1R',
      "value": e
    }))
    dispatch(updateAdultKneeSatisfactionFieldScore({
      "key": 'improvingPainR',
      "value": ""
    }))
  }

  const handleapplicable1L = (e) => {
    setapplicable1L(e)
    // setImprovePainL("0")
    dispatch(updateAdultKneeSatisfactionFieldScore({
      "key": 'applicable1L',
      "value": e
    }))
    dispatch(updateAdultKneeSatisfactionFieldScore({
      "key": 'improvingPainL',
      "value": ""
    }))
  }


  const handleapplicable2R = (e) => {
    setapplicable2R(e)
    // setImproveHomeR("0")
    dispatch(updateAdultKneeSatisfactionFieldScore({
      "key": 'applicable2R',
      "value": e
    }))
    dispatch(updateAdultKneeSatisfactionFieldScore({
      "key": 'improvingHomeR',
      "value": ""
    }))

  }

  const handleapplicable2L = (e) => {
    setapplicable2L(e)
    // setImproveHomeL("0")
    dispatch(updateAdultKneeSatisfactionFieldScore({
      "key": 'applicable2L',
      "value": e
    }))
    dispatch(updateAdultKneeSatisfactionFieldScore({
      "key": 'improvingHomeL',
      "value": ""
    }))
  }


  const handleapplicable3R = (e) => {
    setapplicable3R(e)
    // setImproveRecrR("0")
    dispatch(updateAdultKneeSatisfactionFieldScore({
      "key": 'applicable3R',
      "value": e
    }))
    dispatch(updateAdultKneeSatisfactionFieldScore({
      "key": 'improvingActivitiesR',
      "value": ""
    }))
  }



  const handleapplicable3L = (e) => {
    setapplicable3L(e)
    // setImproveRecrL("0")
    dispatch(updateAdultKneeSatisfactionFieldScore({
      "key": 'applicable3L',
      "value": e
    }))
    dispatch(updateAdultKneeSatisfactionFieldScore({
      "key": 'improvingActivitiesL',
      "value": ""
    }))
  }

  const continueRedirect = (e) => {
    dispatch(updateAdultKneeSatisfactionScore(
      [{
        "overallR": overallR,
        "overallL": overallL,
        "improvingPainR": improvingPainR,
        "improvingPainL": improvingPainL,
        "improvingHomeR": improvingHomeR,
        "improvingHomeL": improvingHomeL,
        "improvingActivitiesR": improvingActivitiesR,
        "improvingActivitiesL": improvingActivitiesL,
        "applicable": applicable,
      }]
    ));
    navigate("/adult-knee-complete/" + rId);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  const [msg, setMsg] = useState('');
  const [msgType, setmsgType] = useState('');
  const SubmitForm = (id) => {
    axiosinstance.current.patch(`extapp/forms/update_scoring?resource_id=${id}`, {
      patient_scoring_date: new Date(),
      status: 1,
    });
  };

  let akpt = searchParams.get("akpt");
  const handleSubmit = () => {
    updateAllData(json, dispatch);
    setUpdateTotal(!updateTotal);
    setMsg(translation.update)
    setmsgType('success')
    SubmitForm(rId)
    setTimeout(() => {
     let view_circular=  sessionStorage.getItem("view_circular")
      // window.location.href = process.env.REACT_APP_DOCTOR_URL + "patient-view/" + akpt
      // window.location.href = process.env.REACT_APP_DOCTOR_URL +`view-management-plan/${akpt}/${window.btoa(
      //   rId
      // )}/?org=${window.btoa(sessionStorage.getItem("orgid")||'')}&formType=${
      //   window.btoa('5') || ""
      // }&view_circular=${view_circular==='false'?'false':'true'}`
       window.location.href = process.env.REACT_APP_DOCTOR_URL +`patient-details/${akpt}?formid=5`
      setMsg('')
      setmsgType('success')
    }, 2000);
  };
  const handleNext = () => {
    navigate(`/knee-feedback/${rId}?d=true&akpt=${akpt}`);

  }


  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="adault_knee_satisfaction_screen mb_100">
           <div class="d-flex justify-content-end">
            <NameCustom />
          </div>
          <div className="panel">
            <div className="panel-body p_25">
              {doctorEnable === "true" && <AdultkneeTab rId={rId} />}
              <CustomToaster msg={msg} msgType={msgType} />
            
              <h4 className="ph_title f_40 mb_180">{translation.patient_adult_knee_satisfaction}</h4>
              <div className="col-md-6 col-sm-6 function_head">
                <label>
                  {translation.patient_adult_knee_satisfaction_instructions}
                </label> <br />
                <span dir={lg === "ara" ? "rtl" : " "}>
                  (<strong>1</strong> = {translation.patient_adult_knee_satisfaction_instructions_1}, <strong>2</strong> = {translation.patient_adult_knee_satisfaction_instructions_2},
                  <strong> 3</strong> = {translation.patient_adult_knee_satisfaction_instructions_3}, <strong>4</strong> = {translation.patient_adult_knee_satisfaction_instructions_4})
                </span>
              </div>
              <div className="row mb_20 pt_20">
                {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                  <div className={"col-md-6 col-sm-6" + RightSide()}>
                    <div className="mb_20 satis">
                      <label class="form-check-label not-applicable4">
                        <input className="check_" type="checkbox" id="radio1" name="radio1" value={applicableR} checked={applicableR} style={{ marginRight: 10 }} onChange={(e) => handleapplicableR(e.target.checked)}></input>

                        {translation.patient_adult_knee_satisfaction_q1_not_applicable}
                      </label>

                      <HipScoreRangeSlider
                        maximun="1"
                        minimum="4"
                        label={translation.patient_adult_knee_satisfaction_q1 + RigthTrans(evlAdultKnee, translation)}
                        styleClass="satis_drp"
                        value={overallR}
                        placeholder={translation.none}
                        view={applicableR}
                        onChange={handleOverallR}
                        disabled={applicableR === true ? true : false}
                        sliderData={[
                          { score: "1", label: translation.patient_adult_knee_satisfaction_q1_a1 },
                          { score: "2", label: translation.patient_adult_knee_satisfaction_q1_a2 },
                          { score: "3", label: translation.patient_adult_knee_satisfaction_q1_a3 },
                          { score: "4", label: translation.patient_adult_knee_satisfaction_q1_a4 },

                        ]}
                      />

                    </div>
                  </div>
                ) : ""}
                {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                  <div className={"col-md-6 col-sm-6" + LeftSide()}>
                    <div className="mb_20 satis">
                      <label class="form-check-label not-applicable4">
                        <input className="check_" type="checkbox" id="radio2" name="radio2" value={applicableL} checked={applicableL} style={{ marginRight: 10 }} onChange={(e) => handleapplicableL(e.target.checked)}></input>
                        {translation.patient_adult_knee_satisfaction_q1_not_applicable}
                      </label>
                      <HipScoreRangeSlider
                        maximun="1"
                        minimum="4"
                        label={translation.patient_adult_knee_satisfaction_q1 + LeftTrans(evlAdultKnee, translation)}
                        styleClass="satis_drp"
                        value={overallL}
                        placeholder={translation.none}
                        onChange={handleOverallL}
                        view={applicableL}
                        disabled={applicableL === true ? true : false}
                        sliderData={[
                          { score: "1", label: translation.patient_adult_knee_satisfaction_q1_a1 },
                          { score: "2", label: translation.patient_adult_knee_satisfaction_q1_a2 },
                          { score: "3", label: translation.patient_adult_knee_satisfaction_q1_a3 },
                          { score: "4", label: translation.patient_adult_knee_satisfaction_q1_a4 },

                        ]}
                      />
                    </div>
                  </div>
                ) : ""}
              </div>
              <div className="row mb_20">
                {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                  <div className={"col-md-6 col-sm-6" + RightSide()}>
                    <div className="mb_20 satis">
                      <label class="form-check-label not-applicable4">
                        <input className="check_" type="checkbox" id="radio3" name="radio3" value={applicable1R} checked={applicable1R} style={{ marginRight: 10 }} onChange={(e) => handleapplicable1R(e.target.checked)}></input>
                        {translation.patient_adult_knee_satisfaction_q2_not_applicable}
                      </label>
                      <HipScoreRangeSlider
                        maximun="1"
                        minimum="4"
                        label={translation.patient_adult_knee_satisfaction_q2 + RigthTrans(evlAdultKnee, translation)}
                        styleClass="satis_drp"
                        value={improvingPainR}
                        view={applicable1R}
                        placeholder={translation.none}
                        onChange={handleImprovingPainR}
                        disabled={applicable1R === true ? true : false}
                        sliderData={[
                          { score: "1", label: translation.patient_adult_knee_satisfaction_q2_a1 },
                          { score: "2", label: translation.patient_adult_knee_satisfaction_q2_a2 },
                          { score: "3", label: translation.patient_adult_knee_satisfaction_q2_a3 },
                          { score: "4", label: translation.patient_adult_knee_satisfaction_q2_a4 },

                        ]}
                      />
                    </div>
                  </div>
                ) : ""}
                {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                  <div className={"col-md-6 col-sm-6" + LeftSide()}>
                    <div className="mb_20 satis">
                      <label class="form-check-label not-applicable4">
                        <input className="check_" type="checkbox" id="radio4" name="radio4" value={applicable1L} checked={applicable1L} style={{ marginRight: 10 }} onChange={(e) => handleapplicable1L(e.target.checked)}></input>

                        {translation.patient_adult_knee_satisfaction_q2_not_applicable}
                      </label>
                      <HipScoreRangeSlider
                        maximun="1"
                        minimum="4"
                        label={translation.patient_adult_knee_satisfaction_q2 + LeftTrans(evlAdultKnee, translation)}
                        styleClass="satis_drp"
                        value={improvingPainL}
                        placeholder={translation.none}
                        view={applicable1L}
                        onChange={handleImprovingPainL}
                        disabled={applicable1L === true ? true : false}
                        sliderData={[
                          { score: "1", label: translation.patient_adult_knee_satisfaction_q2_a1 },
                          { score: "2", label: translation.patient_adult_knee_satisfaction_q2_a2 },
                          { score: "3", label: translation.patient_adult_knee_satisfaction_q2_a3 },
                          { score: "4", label: translation.patient_adult_knee_satisfaction_q2_a4 },

                        ]}
                      />
                    </div>
                  </div>
                ) : ""}
              </div>
              <div className="row mb_20">
                {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                  <div className={"col-md-6 col-sm-6" + RightSide()}>
                    <div className="mb_20 satis">
                      <label class="form-check-label not-applicable4">
                        <input className="check_" type="checkbox" id="radio5" name="radio5" value={applicable2R} checked={applicable2R} style={{ marginRight: 10 }} onChange={(e) => handleapplicable2R(e.target.checked)}></input>

                        {translation.patient_adult_knee_satisfaction_q3_not_applicable}
                      </label>
                      <HipScoreRangeSlider
                        maximun="1"
                        minimum="4"
                        label={translation.patient_adult_knee_satisfaction_q3 + RigthTrans(evlAdultKnee, translation)}
                        styleClass="satis_drp"
                        value={improvingHomeR}
                        view={applicable2R}
                        placeholder={translation.none}
                        onChange={handleImprovingHomeR}
                        disabled={applicable2R === true ? true : false}
                        sliderData={[
                          { score: "1", label: translation.patient_adult_knee_satisfaction_q3_a1 },
                          { score: "2", label: translation.patient_adult_knee_satisfaction_q3_a2 },
                          { score: "3", label: translation.patient_adult_knee_satisfaction_q3_a3 },
                          { score: "4", label: translation.patient_adult_knee_satisfaction_q3_a4 },

                        ]}
                      />


                    </div>
                  </div>
                ) : ""}
                {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                  <div className={"col-md-6 col-sm-6" + LeftSide()}>
                    <div className="mb_20 satis">
                      <label class="form-check-label not-applicable4">
                        <input className="check_" type="checkbox" id="radio6" name="radio6" value={applicable2L} checked={applicable2L} style={{ marginRight: 10 }} onChange={(e) => handleapplicable2L(e.target.checked)}></input>

                        {translation.patient_adult_knee_satisfaction_q3_not_applicable}
                      </label>
                      <HipScoreRangeSlider
                        maximun="1"
                        minimum="4"
                        label={translation.patient_adult_knee_satisfaction_q3 + LeftTrans(evlAdultKnee, translation)}
                        styleClass="satis_drp"
                        value={improvingHomeL}
                        placeholder={translation.none}
                        view={applicable2L}
                        onChange={handleImprovingHomeL}
                        disabled={applicable2L === true ? true : false}
                        sliderData={[
                          { score: "1", label: translation.patient_adult_knee_satisfaction_q3_a1 },
                          { score: "2", label: translation.patient_adult_knee_satisfaction_q3_a2 },
                          { score: "3", label: translation.patient_adult_knee_satisfaction_q3_a3 },
                          { score: "4", label: translation.patient_adult_knee_satisfaction_q3_a4 },

                        ]}
                      />

                    </div>
                  </div>
                ) : ""}
              </div>
              <div className="row mb_20">
                {evlAdultKnee === "1" || evlAdultKnee === "3" ? (
                  <div className={"col-md-6 col-sm-6" + RightSide()}>
                    <div className="mb_20 satis">
                      <label class="form-check-label not-applicable4">
                        <input className="check_" type="checkbox" id="radio7" name="radio7" value={applicable3R} checked={applicable3R} style={{ marginRight: 10 }} onChange={(e) => handleapplicable3R(e.target.checked)}></input>

                        {translation.patient_adult_knee_satisfaction_q4_not_applicable}
                      </label>
                      <HipScoreRangeSlider
                        maximun="1"
                        minimum="4"
                        label={translation.patient_adult_knee_satisfaction_q4 + RigthTrans(evlAdultKnee, translation)}
                        styleClass="satis_drp"
                        view={applicable3R}
                        value={improvingActivitiesR}
                        placeholder={translation.none}
                        onChange={handleImprovingActivitiesR}
                        disabled={applicable3R === true ? true : false}
                        sliderData={[
                          { score: "1", label: translation.patient_adult_knee_satisfaction_q4_a1 },
                          { score: "2", label: translation.patient_adult_knee_satisfaction_q4_a2 },
                          { score: "3", label: translation.patient_adult_knee_satisfaction_q4_a3 },
                          { score: "4", label: translation.patient_adult_knee_satisfaction_q4_a4 },

                        ]}
                      />
                    </div>
                  </div>
                ) : ""}
                {evlAdultKnee === "2" || evlAdultKnee === "3" ? (
                  <div className={"col-md-6 col-sm-6" + LeftSide()}>
                    <div className="mb_20 satis">
                      <label class="form-check-label not-applicable4">
                        <input className="check_" type="checkbox" id="radio8" name="radio8" value={applicable3L} checked={applicable3L} style={{ marginRight: 10 }} onChange={(e) => handleapplicable3L(e.target.checked)}></input>

                        {translation.patient_adult_knee_satisfaction_q4_not_applicable}
                      </label><HipScoreRangeSlider
                        maximun="1"
                        minimum="4"
                        label={translation.patient_adult_knee_satisfaction_q4 + LeftTrans(evlAdultKnee, translation)}
                        styleClass="satis_drp"
                        view={applicable3L}
                        value={improvingActivitiesL}
                        placeholder={translation.none}
                        onChange={handleImprovingActivitiesL}
                        disabled={applicable3L === true ? true : false}
                        sliderData={[
                          { score: "1", label: translation.patient_adult_knee_satisfaction_q4_a1 },
                          { score: "2", label: translation.patient_adult_knee_satisfaction_q4_a2 },
                          { score: "3", label: translation.patient_adult_knee_satisfaction_q4_a3 },
                          { score: "4", label: translation.patient_adult_knee_satisfaction_q4_a4 },

                        ]}
                      />
                    </div>
                  </div>
                ) : ""}
              </div>
              {/* <input type="checkbox"
               onChange={e => handleChange(e)}
               defaultChecked={applicable}/> */}
              {/* <Checkboxnew
        // title="Click"
        fnClick={v => setState({ click: v })}
        styleClass="custom_check_box"
        label="{translation.Not_applicable}"
        checked={state.click}
        id="radio2"
      />
               <button onClick={() => clearFilter()}>Limpiar</button>
                */}
              {/* <Checkbox
               label="{translation.Not_applicable}"
               name="applicable"
               selected={applicable===true?true:false}
               value={applicable}
               onChange={handleapplicable}
               styleClass="custom_check_box"
               id="radio"
              /> */}


              {doctorEnable === "true" && (
                <>
                {doctorStatus ? (
                  <>
                    <div className="row mb_30 button_box">
                      <div className="col-md-6 col-sm-6"></div>
                      <div className="col-md-6 col-sm-6 text-end">
                        <button
                          className="next_btn btn_fill me-3"
                          onClick={() => handleNext()}
                        >
                          {translation.next_button}
                          <span>
                            <img src={nextIcon} alt="" />
                            {/* <img src={nextWhiteIcon} alt="" /> */}
                          </span>
                        </button>
                        <button
                          className="next_btn btn_fill"
                          onClick={() => handleSubmit()}
                        >
                          {translation.submit}
                          <span>
                            <img src={nextIcon} alt="" />
                            {/* <img src={nextWhiteIcon} alt="" /> */}
                          </span>
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="row mb_30 button_box">
                    <div className="col-md-6 col-sm-6"></div>
                    <div className="col-md-6 col-sm-6 text-end">
                      <button
                        className="next_btn btn_fill"
                        onClick={() => handleSubmit()}
                      >
                        {"Submit"}
                        <span>
                          <img src={nextIcon} alt="" />
                          {/* <img src={nextWhiteIcon} alt="" /> */}
                        </span>
                      </button>
                    </div>
                  </div>
                )}
              </>
              )}

              {doctorEnable !== "true" && (
                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6 col-6">
                    <Link to={"/adult-knee-quality-of-life/" + rId} className="assm_back_btn">
                      <img src={backIcon} alt="" />
                      <span>{translation.back_button}</span>
                    </Link>
                  </div>
                  <div className="col-md-6 col-sm-6 col-6 text-end">
                    <button
                      className="next_btn btn_fill"
                      onClick={continueRedirect}
                    >
                      {translation.next_button}
                      <span>
                        <img src={nextIcon} alt="" />
                        <img src={nextWhiteIcon} alt="" />
                      </span>
                    </button>
                  </div>
                </div>
              )}

            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default AdultKneeSatisfaction;
