import React, { useState, useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import HeaderInnerBreadcrumb from "../../../custom/heade-inner-breadcrumb/HeaderInnerBreadcrumb";
import FullDropdown from "../../../custom/dropdown/FullDropdown";
import InputField from "../../../custom/inputfield/InputField";
import RadioSwitch from "../../../custom/radio-switch/RadioSwitch";
import CreatableSelect from "react-select/creatable";
import nextWhiteIcon from "../../../../images/next-white.png";
import nextIcon from "../../../../images/next.png";
import { useDispatch, useSelector } from "react-redux";
import RadioButton from "../../../custom/radio-button/RadioButton";
import useTranslation from "../../../customHooks/doctorTranslation";
import FootAnkleTab from "../../tab-section/FootAnkleTab";
import { useSearchParams } from "react-router-dom";
import CustomToaster from "../../../custom/toaster/CustomToaster";
import ElbowFeedbackScoreRangeSlider from "../../../custom/elbow-doctorfeedback-score-range-slider/ElbowFeedbackScoreRangeSlider";
import KneeDegree from "../../../custom/assessment-widgets/KneeDegree";
import useAxios from "../../../../axiosinstance";
import Footformvertical1 from "./Footformvertical1";
import SubmitModel from "../../SubmissionModel/SubmitModel";
import {
  updateFootFunctionData,
  updateFootAnkle,
  updateFootAnklePainData,
  updateFootQolData,
  updateFootSatisfactionData,
  updateFootInstabilityData,
  updateFootSymptomsData,
  updateFootFeedbackfootankle,
  updateFootComberlandData,
  updateFootFeedbackfootankleField,
  updateFootSatisTotalscrore,
} from "../../../../redux/actions/footAnkle";
import Checkbox from "../../../custom/checkbox/Checkbox";
import DropdownOption from "../../../custom/dropdown/DropdownOption";
import DoctorNameCustom from "../../../custom/name/DoctorNameCustom";
import AssessmentPointDegree from "../../../custom/assessment-widgets/AssessmentPointDegree";

import Accordion from "react-bootstrap/Accordion";
import assessmentStyles from "../../shoulder-forms/doctorefeedback/PatientAssessment.module.css";
import Promsschudle from "../../SubmissionModel/Promsschudle";
import LoadingSpinner from "../../../custom/loader/LoadingSpinner";

export default function Doctorfeedback() {
  const translation = useTranslation();
  const dispatch = useDispatch();
  const [functionSco] = useSelector((state) => state.footAnkle.functionScore);
  const [painSco] = useSelector((state) => state.footAnkle.painScore);
  const axiosinstance = useAxios();
  const { rId } = useParams();
  const [searchParams] = useSearchParams();
  let doctorEnable = searchParams.get("d");
  const queryParams = new URLSearchParams(window.location.search);
  const dId = queryParams.get("doctor");
  const pId = queryParams.get("patient");
  let isdoctorForm = queryParams.get("is_doctor_form");
  useEffect(() => {
    if (doctorEnable === "true") {
      let doctordata = localStorage.getItem("isdoctorForm");
      localStorage.setItem("isdoctorForm", doctordata);
    } else {
      localStorage.setItem("isdoctorForm", isdoctorForm);
    }
  }, [isdoctorForm]);
  const [RightGAIT, setRightGAIT] = useState("1");
  const [LeftGAIT, setLeftGAIT] = useState("1");
  const [RightABNORMAL, setRightABNORMAL] = useState("");
  const [LeftABNORMAL, setLeftABNORMAL] = useState("");
  const [RABNORMALF, setRABNORMALF] = useState("");
  const [LABNORMALF, setLABNORMALF] = useState("");
  const [RightALIGNMENT, setRightALIGNMENT] = useState("");
  const [LeftALIGNMENT, setLeftALIGNMENT] = useState("");
  const [ir4Right, setIr4Right] = useState("");
  const [ir4Left, setIr4Left] = useState("");
  const [RDEFORMITY, setRDEFORMITY] = useState("");
  const [LDEFORMITY, setLDEFORMITY] = useState("");
  const [value, setValue] = useState([]);
  const [value2, setValue2] = useState([]);
  const [error, setError] = useState("");
  const [show, setShow] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [options, setOptions] = useState([
    { value: "0", label: "Pes planus" },
    { value: "1", label: "Pes cavus" },
    { value: "2", label: "Hindfoot valgus" },
    { value: "3", label: "Hindfoot varus" },
    { value: "4", label: "Hallux valgus" },
    { value: "5", label: "Hallux varus" },
    { value: "6", label: "Mallet toe" },
    { value: "7", label: "Hammer toe" },
    { value: "8", label: "Claw toe" },
    { value: "9", label: "Cross over toes" },
    { value: "10", label: "Foot drop" },
    { value: "11", label: "Others" },
  ]);
  const [contraR, setContraR] = useState("");
  const [contraL, setContraL] = useState("");
  const [specifyR, setSpecifyR] = useState("");
  const [specifyL, setSpecifyL] = useState("");
  const [callusR, setCallusR] = useState("");
  const [callusL, setCallusL] = useState("");
  const [footR, setFootR] = useState("0");
  const [footL, setFootL] = useState("0");
  const [footcallusR, setfootcallusR] = useState("1");
  const [footcallusL, setfootcallusL] = useState("1");
  const [EvaluatedR, setEvaluatedR] = useState("");
  const [EvaluatedL, setEvaluatedL] = useState("");
  const [RightForefoot, setRightForefoot] = useState("");
  const [LeftForefoot, setLeftForefoot] = useState("");

  const [AnkleR, setAnkleR] = useState("");
  const [AnkleL, setAnkleL] = useState("");
  const [MotionR, setMotionR] = useState("");
  const [MotionRtext, setMotionRtext] = useState("");
  const [MotionLRtext, setMotionLRtext] = useState("");
  const [MotionLLtext, setMotionLLtext] = useState("");
  const [MotionL, setMotionL] = useState("");
  const [MotionLtext, setMotionLtext] = useState("");
  const [Motion3R, setMotion3R] = useState("");
  const [Motion3Rtext, setMotion3Rtext] = useState("");
  const [Motion3RRtext, setMotion3RRtext] = useState("");
  const [Motion3RLtext, setMotion3RLtext] = useState("");
  const [Motion4R, setMotion4R] = useState("");
  const [Motion4Rtext, setMotion4Rtext] = useState("");
  const [StabilityR, setStabilityR] = useState("");
  const [StabilityL, setStabilityL] = useState("");
  const [Stability2R, setStability2R] = useState("");
  const [Stability2L, setStability2L] = useState("");
  const [Stability3R, setStability3R] = useState("");
  const [Stability3L, setStability3L] = useState("");
  const [TalarR, setTalarR] = useState("");
  const [TalarL, setTalarL] = useState("");
  const [Talar2R, setTalar2R] = useState("");
  const [Talar2L, setTalar2L] = useState("");
  const [SyndesmosisR, setSyndesmosisR] = useState("");
  const [SyndesmosisL, setSyndesmosisL] = useState("");
  const [Syndesmosis1R, setSyndesmosis1R] = useState("");
  const [Syndesmosis1L, setSyndesmosis1L] = useState("");
  const [ImpingementR, setImpingementR] = useState("");
  const [ImpingementL, setImpingementL] = useState("");
  const [ImpingementdropR, setImpingementdropR] = useState("");
  const [ImpingementLdrop, setImpingementLdrop] = useState("");
  const [Impingement1R, setImpingement1R] = useState("");
  const [Impingement1L, setImpingement1L] = useState("");
  const [Impingement3R, setImpingement3R] = useState("");
  const [Impingement3L, setImpingement3L] = useState("");
  const [Impingement4R, setImpingement4R] = useState("");
  const [Impingement4L, setImpingement4L] = useState("");
  const [AchillesR, setAchillesR] = useState("");
  const [AchillesL, setAchillesL] = useState("");
  const [Achilles2R, setAchilles2R] = useState("");
  const [Achilles2L, setAchilles2L] = useState("");
  const [Achilles4R, setAchilles4R] = useState("");
  const [Achilles4L, setAchilles4L] = useState("");
  const [Achilles6L, setAchilles6L] = useState("");
  const [Achilles6R, setAchilles6R] = useState("");
  const [Pal1Rtext, setPal1Rtext] = useState("");
  const [Pal1Ltext, setPal1Ltext] = useState("");
  const [Pal1RtextL, setPal1RtextL] = useState("");
  const [Pal1LtextL, setPal1LtextL] = useState("");
  const [DeformityR, setDeformityR] = useState("");
  const [DeformityL, setDeformityL] = useState("");
  const [Deformity2R, setDeformity2R] = useState("");
  const [Deformity2L, setDeformity2L] = useState("");
  const [Deformity4R, setDeformity4R] = useState("");
  const [Deformity4L, setDeformity4L] = useState("");
  const [Deformity6R, setDeformity6R] = useState("");
  const [Deformity6L, setDeformity6L] = useState("");
  const [Deformity8R, setDeformity8R] = useState("");
  const [Deformity8L, setDeformity8L] = useState("");
  const [MidfootL, setMidfootL] = useState("");
  const [MidfootR, setMidfootR] = useState("");

  const [AlignmentR, setAlignmentR] = useState("0");
  const [AlignmentL, setAlignmentL] = useState("0");
  const [AlignmentradioL, setAlignmentradioL] = useState("");
  const [Alignmentdrop, setAlignmentdrop] = useState("");
  const [Alignmen1L, setAlignmen1L] = useState("");
  const [Alignmen1drop, setAlignmen1drop] = useState("");
  const [AlignmeninputR, setAlignmeninputR] = useState("");
  const [AlignmeninputL, setAlignmeninputL] = useState("");
  const [Alignmen2R, setAlignmen2R] = useState("");
  const [Alignmen2L, setAlignmen2L] = useState("");
  const [LesserR, setLesserR] = useState("");
  const [LesserR2, setLesserR2] = useState("");
  const [LesserL, setLesserL] = useState("");
  const [LesserL2, setLesserL2] = useState("");
  const [LesserradioL, setLesserradioL] = useState("");
  const [LesserradioL2, setLesserradioL2] = useState("");
  const [LesserrLdrop, setLesserrLdrop] = useState("");
  const [LesserrLdrop2, setLesserrLdrop2] = useState("");
  const [LesserradioR, setLesserradioR] = useState("");
  const [LesserradioR2, setLesserradioR2] = useState("");
  const [LesserrRdrop, setLesserrRdrop] = useState("");
  const [LesserrRdrop2, setLesserrRdrop2] = useState("");
  const [LesserrSpecifyR, setLesserrSpecifyR] = useState(0);
  const [LesserrSpecifyL, setLesserrSpecifyL] = useState(0);
  const [LesserrDegreeR, setLesserrDegreeR] = useState(0);
  const [LesserrDegreeL, setLesserrDegreeL] = useState(0);
  const [SpecifyLradio, setSpecifyLradio] = useState("");
  const [SpecifyLradio2, setSpecifyLradio2] = useState("");
  const [SpecifyRradio, setSpecifyRradio] = useState("");
  const [SpecifyRradio2, setSpecifyRradio2] = useState("");
  const [LigamentousR, setLigamentousR] = useState("");
  const [LigamentousL, setLigamentousL] = useState("");
  const [LigamentousRtext, setLigamentousRtext] = useState("");
  const [LigamentousLtext, setLigamentousLtext] = useState("");
  const [AlignmeninputIPJOINTR, setAlignmeninputIPJOINTR] = useState(0);
  const [AlignmeninputIPJOINTL, setAlignmeninputIPJOINTL] = useState(0);
  const [AnkleHR, setAnkleHR] = useState(false);
  const [AnkleHL, setAnkleHL] = useState(false);
  const [MidfootHR, setMidfootHR] = useState(false);
  const [MidfootHL, setMidfootHL] = useState(false);
  const [ForefootR, setForefootR] = useState(false);
  const [ForefootL, setForefootL] = useState(false);

  const [LesserL23rd, setLesserL23rd] = useState("");
  const [LesserR23rd, setLesserR23rd] = useState("");
  const [LesserradioL23rd, setLesserradioL23rd] = useState("");
  const [LesserrLdrop23rd, setLesserrLdrop23rd] = useState("");
  const [LesserradioR23rd, setLesserradioR23rd] = useState("");
  const [LesserrRdrop23rd, setLesserrRdrop23rd] = useState("");
  const [LesserrDegreeL3rd, setLesserrDegreeL3rd] = useState("");
  const [LesserrDegreeR3rd, setLesserrDegreeR3rd] = useState("");
  const [SpecifyLradio23rd, setSpecifyLradio23rd] = useState("");
  const [SpecifyRradio23rd, setSpecifyRradio23rd] = useState("");

  const [LesserL23rd2, setLesserL23rd2] = useState("");
  const [LesserR23rd2, setLesserR23rd2] = useState("");
  const [LesserradioL23rd2, setLesserradioL23rd2] = useState("");
  const [LesserrLdrop23rd2, setLesserrLdrop23rd2] = useState("");
  const [LesserradioR23rd2, setLesserradioR23rd2] = useState("");
  const [LesserrRdrop23rd2, setLesserrRdrop23rd2] = useState("");
  const [LesserrDegreeL3rd2, setLesserrDegreeL3rd2] = useState("");
  const [LesserrDegreeR3rd2, setLesserrDegreeR3rd2] = useState("");
  const [SpecifyLradio23rd2, setSpecifyLradio23rd2] = useState("");
  const [SpecifyRradio23rd2, setSpecifyRradio23rd2] = useState("");

  const [LesserL24th, setLesserL24th] = useState("");
  const [LesserR24th, setLesserR24th] = useState("");
  const [LesserradioL24th, setLesserradioL24th] = useState("");
  const [LesserrLdrop24th, setLesserrLdrop24th] = useState("");
  const [LesserradioR24th, setLesserradioR24th] = useState("");
  const [LesserrRdrop24th, setLesserrRdrop24th] = useState("");
  const [LesserrDegreeL4th, setLesserrDegreeL4th] = useState("");
  const [LesserrDegreeR4th, setLesserrDegreeR4th] = useState("");
  const [SpecifyLradio24th, setSpecifyLradio24th] = useState("");
  const [SpecifyRradio24th, setSpecifyRradio24th] = useState("");

  const [LesserL24th2, setLesserL24th2] = useState("");
  const [LesserR24th2, setLesserR24th2] = useState("");
  const [LesserradioL24th2, setLesserradioL24th2] = useState("");
  const [LesserrLdrop24th2, setLesserrLdrop24th2] = useState("");
  const [LesserradioR24th2, setLesserradioR24th2] = useState("");
  const [LesserrRdrop24th2, setLesserrRdrop24th2] = useState("");
  const [LesserrDegreeL4th2, setLesserrDegreeL4th2] = useState("");
  const [LesserrDegreeR4th2, setLesserrDegreeR4th2] = useState("");
  const [SpecifyLradio24th2, setSpecifyLradio24th2] = useState("");
  const [SpecifyRradio24th2, setSpecifyRradio24th2] = useState("");

  const [LesserL25th, setLesserL25th] = useState("");
  const [LesserR25th, setLesserR25th] = useState("");
  const [LesserradioL25th, setLesserradioL25th] = useState("");
  const [LesserrLdrop25th, setLesserrLdrop25th] = useState("");
  const [LesserradioR25th, setLesserradioR25th] = useState("");
  const [LesserrRdrop25th, setLesserrRdrop25th] = useState("");
  const [LesserrDegreeL5th, setLesserrDegreeL5th] = useState("");
  const [LesserrDegreeR5th, setLesserrDegreeR5th] = useState("");
  const [SpecifyLradio25th, setSpecifyLradio25th] = useState("");
  const [SpecifyRradio25th, setSpecifyRradio25th] = useState("");

  const [LesserL25th2, setLesserL25th2] = useState("");
  const [LesserR25th2, setLesserR25th2] = useState("");
  const [LesserradioL25th2, setLesserradioL25th2] = useState("");
  const [LesserrLdrop25th2, setLesserrLdrop25th2] = useState("");
  const [LesserradioR25th2, setLesserradioR25th2] = useState("");
  const [LesserrRdrop25th2, setLesserrRdrop25th2] = useState("");
  const [LesserrDegreeL5th2, setLesserrDegreeL5th2] = useState("");
  const [LesserrDegreeR5th2, setLesserrDegreeR5th2] = useState("");
  const [SpecifyLradio25th2, setSpecifyLradio25th2] = useState("");
  const [SpecifyRradio25th2, setSpecifyRradio25th2] = useState("");

  const [Toe2nd, setToe2nd] = useState(false);
  const [Toe3rd, setToe3rd] = useState(false);
  const [Toe4th, setToe4th] = useState(false);
  const [Toe5th, setToe5th] = useState(false);
  const [Hallux, setHallux] = useState(false);

  const [activeKey, setActiveKey] = useState(null);
  const [activeKeyhalx, setActiveKeyhalax] = useState(null);
  const [activeKeytoe1, setactiveKeytoe1] = useState(null);
  const [activeKeytoe2, setactiveKeytoe2] = useState(null);
  const [activeKeytoe3, setactiveKeytoe3] = useState(null);
  const [activeKeytoe4, setactiveKeytoe4] = useState(null);
  const [activeKeyankle, setActiveKeyankle] = useState(null);
  const [activeKeyMid, setactiveKeyMid] = useState(null);

  const [Motion2Rtext, setMotion2Rtext] = useState("");
  const [Motion2Ltext, setMotion2Ltext] = useState("");
  const [Motion2LRtext, setMotion2LRtext] = useState("");
  const [Motion2LLtext, setMotion2LLtext] = useState("");
  const [Motion23Rtext, setMotion23Rtext] = useState("");
  const [Motion24Rtext, setMotion24Rtext] = useState("");
  const [Motion23RRtext, setMotion23RRtext] = useState("");
  const [Motion23RLtext, setMotion23RLtext] = useState("");
  //2nd toe
  const [Motion2ndRtext, setMotion2ndRtext] = useState("");
  const [Motion2ndLtext, setMotion2ndLtext] = useState("");
  const [Motion2ndLRtext, setMotion2ndLRtext] = useState("");
  const [Motion2ndLLtext, setMotion2ndLLtext] = useState("");
  const [Motion2nRtext, setMotion2nRtext] = useState("");
  const [Motion2nlRtext, setMotion2nlRtext] = useState("");
  const [Motion2ndRRtext, setMotion2ndRRtext] = useState("");
  const [Motion2ndRLtext, setMotion2ndRLtext] = useState("");

  //3rd toe
  const [Motion3ndRtext, setMotion3ndRtext] = useState("");
  const [Motion3ndLtext, setMotion3ndLtext] = useState("");
  const [Motion3ndLRtext, setMotion3ndLRtext] = useState("");
  const [Motion3ndLLtext, setMotion3ndLLtext] = useState("");
  const [Motion3nRtext, setMotion3nRtext] = useState("");
  const [Motion3nlRtext, setMotion3nlRtext] = useState("");
  const [Motion3ndRRtext, setMotion3ndRRtext] = useState("");
  const [Motion3ndRLtext, setMotion3ndRLtext] = useState("");

  //4th toe
  const [Motion4ndRtext, setMotion4ndRtext] = useState("");
  const [Motion4ndLtext, setMotion4ndLtext] = useState("");
  const [Motion4ndLRtext, setMotion4ndLRtext] = useState("");
  const [Motion4ndLLtext, setMotion4ndLLtext] = useState("");
  const [Motion4nRtext, setMotion4nRtext] = useState("");
  const [Motion4nlRtext, setMotion4nlRtext] = useState("");
  const [Motion4ndRRtext, setMotion4ndRRtext] = useState("");
  const [Motion4ndRLtext, setMotion4ndRLtext] = useState("");

  //5th toe
  const [Motion5ndRtext, setMotion5ndRtext] = useState("");
  const [Motion5ndLtext, setMotion5ndLtext] = useState("");
  const [Motion5ndLRtext, setMotion5ndLRtext] = useState("");
  const [Motion5ndLLtext, setMotion5ndLLtext] = useState("");
  const [Motion5nRtext, setMotion5nRtext] = useState("");
  const [Motion5nlRtext, setMotion5nlRtext] = useState("");
  const [Motion5ndRRtext, setMotion5ndRRtext] = useState("");
  const [Motion5ndRLtext, setMotion5ndRLtext] = useState("");

   // --
   const [msg, setMsg] = useState('');
   const [msgType, setmsgType] = useState('');

  const [chk, setChk] = useState(false);
  const navigate = useNavigate();
  const docFeedback = useSelector((state) => state.footAnkle.doctorFeedback[0]);
  const docFeedback1 = useSelector((state) => state.footAnkle.doctorFeedback);

  const save = () => {
    axiosinstance.current
      .get(`extapp/forms/all_forms?resource_id=${rId}&type=21`)
      .then((res) => {
        dispatch(updateFootAnklePainData(res.data.data2[0].painScore));
        dispatch(updateFootSymptomsData(res.data.data2[0].symptomsScore));
        dispatch(updateFootAnkle(res.data.data2[0].anklePain));
        dispatch(updateFootInstabilityData(res.data.data2[0].instabilityScore));
        dispatch(updateFootComberlandData(res.data.data2[0].comberland));
        dispatch(updateFootQolData(res.data.data2[0].qolScore));
        dispatch(updateFootFunctionData(res.data.data2[0].functionScore));
        dispatch(
          updateFootSatisfactionData(res.data.data2[0].satisfactionScore)
        );
        dispatch(updateFootSatisTotalscrore(res.data.data2[0].TotalScores));
        dispatch(updateFootFeedbackfootankle(res.data.data2[0].doctorFeedback));
        setChk(true);
      });
  };

  useEffect(() => {
    save();
  }, []);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
  useEffect(() => {
    setRightGAIT(docFeedback.RightGAIT);
    setLeftGAIT(docFeedback.LeftGAIT);
    setRightABNORMAL(docFeedback.RightABNORMAL);
    setLeftABNORMAL(docFeedback.LeftABNORMAL);
    setRABNORMALF(docFeedback.RABNORMALF);
    setLABNORMALF(docFeedback.LABNORMALF);
    setRightALIGNMENT(docFeedback.RightALIGNMENT);
    setLeftALIGNMENT(docFeedback.LeftALIGNMENT);
    setIr4Right(docFeedback.ir4Right);
    setIr4Left(docFeedback.ir4Left);
    setRDEFORMITY(docFeedback.RDEFORMITY);
    setLDEFORMITY(docFeedback.LDEFORMITY);
    setValue(docFeedback.value);
    setValue2(docFeedback.value2);
    setContraR(docFeedback.ContraR);
    setContraL(docFeedback.ContraL);
    setSpecifyR(docFeedback.SpecifyR);
    setSpecifyL(docFeedback.SpecifyL);
    setCallusR(docFeedback.CallusR);
    setCallusL(docFeedback.CallusL);
    setFootR(docFeedback.FootR);
    setFootL(docFeedback.FootL);
    setfootcallusR(docFeedback.footcallusR);
    setfootcallusL(docFeedback.footcallusL);
    setEvaluatedR(docFeedback.EvaluatedR);
    setEvaluatedL(docFeedback.EvaluatedL);
    setRightForefoot(docFeedback.RightForefoot);
    setLeftForefoot(docFeedback.LeftForefoot);
    setAnkleR(docFeedback.AnkleR);
    setAnkleL(docFeedback.AnkleL);
    setMotionR(docFeedback.MotionR);

    setMotionRtext(docFeedback.MotionRtext);
    setMotionLRtext(docFeedback.MotionLRtext);
    setMotionLLtext(docFeedback.MotionLLtext);
    setMotionL(docFeedback.MotionL);
    setMotionLtext(docFeedback.MotionLtext);
    setMotion3R(docFeedback.Motion3R);
    setMotion3Rtext(docFeedback.Motion3Rtext);
    setMotion3RRtext(docFeedback.Motion3RRtext);
    setMotion3RLtext(docFeedback.Motion3RLtext);

    setMotion2Rtext(docFeedback.Motion2Rtext);
    setMotion2Ltext(docFeedback.Motion2Ltext);
    setMotion2LRtext(docFeedback.Motion2LRtext);
    setMotion2LLtext(docFeedback.Motion2LLtext);
    setMotion23Rtext(docFeedback.Motion23Rtext);
    setMotion24Rtext(docFeedback.Motion24Rtext);
    setMotion23RRtext(docFeedback.Motion23RRtext);
    setMotion23RLtext(docFeedback.Motion23RLtext);

    setMotion2ndRtext(docFeedback.Motion2ndRtext);
    setMotion2ndLtext(docFeedback.Motion2ndLtext);
    setMotion2ndLRtext(docFeedback.Motion2ndLRtext);
    setMotion2ndLLtext(docFeedback.Motion2ndLLtext);
    setMotion2nRtext(docFeedback.Motion2nRtext);
    setMotion2nlRtext(docFeedback.Motion2nlRtext);
    setMotion2ndRRtext(docFeedback.Motion2ndRRtext);
    setMotion2ndRLtext(docFeedback.Motion2ndRLtext);

    setMotion3ndRtext(docFeedback.Motion3ndRtext);
    setMotion3ndLtext(docFeedback.Motion3ndLtext);
    setMotion3ndLRtext(docFeedback.Motion3ndLRtext);
    setMotion3ndLLtext(docFeedback.Motion3ndLLtext);
    setMotion3nRtext(docFeedback.Motion3nRtext);
    setMotion3nlRtext(docFeedback.Motion3nlRtext);
    setMotion3ndRRtext(docFeedback.Motion3ndRRtext);
    setMotion3ndRLtext(docFeedback.Motion3ndRLtext);

    setMotion4ndRtext(docFeedback.Motion4ndRtext);
    setMotion4ndLtext(docFeedback.Motion4ndLtext);
    setMotion4ndLRtext(docFeedback.Motion4ndLRtext);
    setMotion4ndLLtext(docFeedback.Motion4ndLLtext);
    setMotion4nRtext(docFeedback.Motion4nRtext);
    setMotion4nlRtext(docFeedback.Motion4nlRtext);
    setMotion4ndRRtext(docFeedback.Motion4ndRRtext);
    setMotion4ndRLtext(docFeedback.Motion4ndRLtext);

    setMotion5ndRtext(docFeedback.Motion5ndRtext);
    setMotion5ndLtext(docFeedback.Motion5ndLtext);
    setMotion5ndLRtext(docFeedback.Motion5ndLRtext);
    setMotion5ndLLtext(docFeedback.Motion5ndLLtext);
    setMotion5nRtext(docFeedback.Motion5nRtext);
    setMotion5nlRtext(docFeedback.Motion5nlRtext);
    setMotion5ndRRtext(docFeedback.Motion5ndRRtext);
    setMotion5ndRLtext(docFeedback.Motion5ndRLtext);

    setMotion4R(docFeedback.Motion4R);
    setMotion4Rtext(docFeedback.Motion4Rtext);
    setStabilityR(docFeedback.StabilityR);
    setStabilityL(docFeedback.StabilityL);
    setStability2R(docFeedback.Stability2R);
    setStability2L(docFeedback.Stability2L);
    setStability3R(docFeedback.Stability3R);
    setStability3L(docFeedback.Stability3L);
    setTalarR(docFeedback.TalarR);
    setTalarL(docFeedback.TalarL);
    setTalar2R(docFeedback.Talar2R);
    setTalar2L(docFeedback.Talar2L);
    setSyndesmosisR(docFeedback.SyndesmosisR);
    setSyndesmosisL(docFeedback.SyndesmosisL);
    setSyndesmosis1R(docFeedback.Syndesmosis1R);
    setSyndesmosis1L(docFeedback.Syndesmosis1L);
    setImpingementR(docFeedback.ImpingementR);
    setImpingementL(docFeedback.ImpingementL);
    setImpingementdropR(docFeedback.ImpingementdropR);
    setImpingementLdrop(docFeedback.ImpingementLdrop);
    setImpingement1R(docFeedback.Impingement1R);
    setImpingement1L(docFeedback.Impingement1L);
    setImpingement3R(docFeedback.Impingement3R);
    setImpingement3L(docFeedback.Impingement3L);
    setImpingement4R(docFeedback.Impingement4R);
    setImpingement4L(docFeedback.Impingement4L);
    setAchillesR(docFeedback.AchillesR);
    setAchillesL(docFeedback.AchillesL);
    setAchilles2R(docFeedback.Achilles2R);
    setAchilles2L(docFeedback.Achilles2L);
    setAchilles4R(docFeedback.Achilles4R);
    setAchilles4L(docFeedback.Achilles4L);
    setAchilles6R(docFeedback.Achilles6R);
    setAchilles6L(docFeedback.Achilles6L);
    setPal1Rtext(docFeedback.Pal1Rtext);
    setPal1Ltext(docFeedback.Pal1Ltext);
    setPal1RtextL(docFeedback.Pal1RtextL);
    setPal1LtextL(docFeedback.Pal1LtextL);
    setDeformityR(docFeedback.DeformityR);
    setDeformityL(docFeedback.DeformityL);
    setDeformity2R(docFeedback.Deformity2R);
    setDeformity2L(docFeedback.Deformity2L);
    setDeformity4R(docFeedback.Deformity4R);
    setDeformity4L(docFeedback.Deformity4L);
    setDeformity6R(docFeedback.Deformity6R);
    setDeformity6L(docFeedback.Deformity6L);
    setDeformity8R(docFeedback.Deformity8R);
    setDeformity8L(docFeedback.Deformity8L);
    setMidfootL(docFeedback.MidfootL);
    setMidfootR(docFeedback.MidfootR);
    setAlignmentR(docFeedback.AlignmentR);
    setAlignmentL(docFeedback.AlignmentL);
    setAlignmentradioL(docFeedback.AlignmentradioL);
    setAlignmentdrop(docFeedback.Alignmentdrop);
    setAlignmen1L(docFeedback.Alignmen1L);
    setAlignmen1drop(docFeedback.Alignmen1drop);
    setAlignmeninputR(docFeedback.AlignmeninputR);
    setAlignmeninputL(docFeedback.AlignmeninputL);
    setAlignmen2R(docFeedback.Alignmen2R);
    setAlignmen2L(docFeedback.Alignmen2L);
    setLesserR(docFeedback.LesserR);
    setLesserR2(docFeedback.LesserR2);
    setLesserL(docFeedback.LesserL);
    setLesserL2(docFeedback.LesserL2);
    setLesserradioL(docFeedback.LesserradioL);
    setLesserradioL2(docFeedback.LesserradioL2);
    setLesserrLdrop(docFeedback.LesserrLdrop);
    setLesserrLdrop2(docFeedback.LesserrLdrop2);
    setLesserradioR(docFeedback.LesserradioR);
    setLesserradioR2(docFeedback.LesserradioR2);
    setLesserrRdrop(docFeedback.LesserrRdrop);
    setLesserrRdrop2(docFeedback.LesserrRdrop2);
    setLesserrSpecifyR(docFeedback.LesserrSpecifyR);
    setLesserrSpecifyL(docFeedback.LesserrSpecifyL);
    setLesserrDegreeR(docFeedback.LesserrDegreeR);
    setLesserrDegreeL(docFeedback.LesserrDegreeL);
    setSpecifyLradio(docFeedback.SpecifyLradio);
    setSpecifyLradio2(docFeedback.SpecifyLradio2);
    setSpecifyRradio(docFeedback.SpecifyRradio);
    setSpecifyRradio2(docFeedback.SpecifyRradio2);
    setLigamentousR(docFeedback.LigamentousR);
    setLigamentousL(docFeedback.LigamentousL);
    setLigamentousRtext(docFeedback.LigamentousRtext);
    setLigamentousLtext(docFeedback.LigamentousLtext);
    setAlignmeninputIPJOINTR(docFeedback.AlignmeninputIPJOINTR);
    setAlignmeninputIPJOINTL(docFeedback.AlignmeninputIPJOINTL);
    setAnkleHR(docFeedback.AnkleHR);
    setAnkleHL(docFeedback.AnkleHL);
    setMidfootHR(docFeedback.MidfootHR);
    setMidfootHL(docFeedback.MidfootHL);
    setForefootR(docFeedback.ForefootR);
    setForefootL(docFeedback.ForefootL);

    setLesserL23rd(docFeedback.LesserL23rd);
    setLesserR23rd(docFeedback.LesserR23rd);
    setLesserradioL23rd(docFeedback.LesserradioL23rd);
    setLesserrLdrop23rd(docFeedback.LesserrLdrop23rd);
    setLesserradioR23rd(docFeedback.LesserradioR23rd);
    setLesserrRdrop23rd(docFeedback.LesserrRdrop23rd);
    setLesserrDegreeL3rd(docFeedback.LesserrDegreeL3rd);
    setLesserrDegreeR3rd(docFeedback.LesserrDegreeR3rd);
    setSpecifyLradio23rd(docFeedback.SpecifyLradio23rd);
    setSpecifyRradio23rd(docFeedback.SpecifyRradio23rd);

    setLesserL23rd2(docFeedback.LesserL23rd2);
    setLesserR23rd2(docFeedback.LesserR23rd2);
    setLesserradioL23rd2(docFeedback.LesserradioL23rd2);
    setLesserrLdrop23rd2(docFeedback.LesserrLdrop23rd2);
    setLesserradioR23rd2(docFeedback.LesserradioR23rd2);
    setLesserrRdrop23rd2(docFeedback.LesserrRdrop23rd2);
    setLesserrDegreeL3rd2(docFeedback.LesserrDegreeL3rd2);
    setLesserrDegreeR3rd2(docFeedback.LesserrDegreeR3rd2);
    setSpecifyLradio23rd2(docFeedback.SpecifyLradio23rd2);
    setSpecifyRradio23rd2(docFeedback.SpecifyRradio23rd2);

    setLesserL24th(docFeedback.LesserL24th);
    setLesserR24th(docFeedback.LesserR24th);
    setLesserradioL24th(docFeedback.LesserradioL24th);
    setLesserrLdrop24th(docFeedback.LesserrLdrop24th);
    setLesserradioR24th(docFeedback.LesserradioR24th);
    setLesserrRdrop24th(docFeedback.LesserrRdrop24th);
    setLesserrDegreeL4th(docFeedback.LesserrDegreeL4th);
    setLesserrDegreeR4th(docFeedback.LesserrDegreeR4th);
    setSpecifyLradio24th(docFeedback.SpecifyLradio24th);
    setSpecifyRradio24th(docFeedback.SpecifyRradio24th);

    setLesserL24th2(docFeedback.LesserL24th2);
    setLesserR24th2(docFeedback.LesserR24th2);
    setLesserradioL24th2(docFeedback.LesserradioL24th2);
    setLesserrLdrop24th2(docFeedback.LesserrLdrop24th2);
    setLesserradioR24th2(docFeedback.LesserradioR24th2);
    setLesserrRdrop24th2(docFeedback.LesserrRdrop24th2);
    setLesserrDegreeL4th2(docFeedback.LesserrDegreeL4th2);
    setLesserrDegreeR4th2(docFeedback.LesserrDegreeR4th2);
    setSpecifyLradio24th2(docFeedback.SpecifyLradio24th2);
    setSpecifyRradio24th2(docFeedback.SpecifyRradio24th2);

    setLesserL25th(docFeedback.LesserL25th);
    setLesserR25th(docFeedback.LesserR25th);
    setLesserradioL25th(docFeedback.LesserradioL25th);
    setLesserrLdrop25th(docFeedback.LesserrLdrop25th);
    setLesserradioR25th(docFeedback.LesserradioR25th);
    setLesserrRdrop25th(docFeedback.LesserrRdrop25th);
    setLesserrDegreeL5th(docFeedback.LesserrDegreeL5th);
    setLesserrDegreeR5th(docFeedback.LesserrDegreeR5th);
    setSpecifyLradio25th(docFeedback.SpecifyLradio25th);
    setSpecifyRradio25th(docFeedback.SpecifyRradio25th);

    setLesserL25th2(docFeedback.LesserL25th2);
    setLesserR25th2(docFeedback.LesserR25th2);
    setLesserradioL25th2(docFeedback.LesserradioL25th2);
    setLesserrLdrop25th2(docFeedback.LesserrLdrop25th2);
    setLesserradioR25th2(docFeedback.LesserradioR25th2);
    setLesserrRdrop25th2(docFeedback.LesserrRdrop25th2);
    setLesserrDegreeL5th2(docFeedback.LesserrDegreeL5th2);
    setLesserrDegreeR5th2(docFeedback.LesserrDegreeR5th2);
    setSpecifyLradio25th2(docFeedback.SpecifyLradio25th2);
    setSpecifyRradio25th2(docFeedback.SpecifyRradio25th2);

    setToe2nd(docFeedback.Toe2nd);
    setToe3rd(docFeedback.Toe3rd);
    setToe4th(docFeedback.Toe4th);
    setToe5th(docFeedback.Toe5th);
    setHallux(docFeedback.Hallux);
  }, [docFeedback]);

  function sumAll(...numbers) {
    let total = 0;
    for (const number of numbers) {
      total += number;
    }
    return total;
  }

  // AOFAS Ankle-Hindfoot

  const AofasHindfoot =
    functionSco.footActivityR === "3"
      ? 0
      : functionSco.footActivityR === "2"
      ? 4
      : functionSco.footActivityR === "1"
      ? 7
      : functionSco.footActivityR === "0"
      ? 10
      : 0;
  const AofasHindfootL =
    functionSco.footActivityL === "3"
      ? 0
      : functionSco.footActivityL === "2"
      ? 4
      : functionSco.footActivityL === "1"
      ? 7
      : functionSco.footActivityL === "0"
      ? 10
      : 0;

  const AofasHindfootb =
    painSco.descripR === "3"
      ? 0
      : painSco.descripR === "2"
      ? 20
      : painSco.descripR === "1"
      ? 30
      : painSco.descripR === "0"
      ? 40
      : 0;

  const AofasHindfootbL =
    painSco.descripL === "3"
      ? 0
      : painSco.descripL === "2"
      ? 20
      : painSco.descripL === "1"
      ? 30
      : painSco.descripL === "0"
      ? 40
      : 0;

  const AofasHindwalkingR =
    functionSco.walkingR === "2"
      ? 0
      : functionSco.walkingR === "1"
      ? 3
      : functionSco.walkingR === "0"
      ? 5
      : 0;
  const AofasHindwalkingL =
    functionSco.walkingL === "2"
      ? 0
      : functionSco.walkingL === "1"
      ? 3
      : functionSco.walkingL === "0"
      ? 5
      : 0;

  const AofasHindmaxwalkingR =
    functionSco.maxWalkingR === "0"
      ? 5
      : functionSco.maxWalkingR === "1"
      ? 4
      : functionSco.maxWalkingR === "2"
      ? 2
      : functionSco.maxWalkingR === "3"
      ? 0
      : 0;

  const AofasHindmaxwalkingL =
    functionSco.maxWalkingL === "0"
      ? 5
      : functionSco.maxWalkingL === "1"
      ? 4
      : functionSco.maxWalkingL === "2"
      ? 2
      : functionSco.maxWalkingL === "3"
      ? 0
      : 0;
  const TotalEvaluatedR =
    docFeedback.RightGAIT === "1"
      ? 8
      : docFeedback.RightGAIT === "2"
      ? 8
      : docFeedback.RightGAIT === "3"
      ? 4
      : docFeedback.RightGAIT === "4"
      ? 0
      : 0;

  const TotalEvaluatedL =
    docFeedback.LeftGAIT === "1"
      ? 8
      : docFeedback.LeftGAIT === "2"
      ? 8
      : docFeedback.LeftGAIT === "3"
      ? 4
      : docFeedback.LeftGAIT === "4"
      ? 0
      : 0;

  const TotalAnkleR =
    docFeedback.AnkleR === "1"
      ? 10
      : docFeedback.AnkleR === "2"
      ? 5
      : docFeedback.AnkleR === "3"
      ? 0
      : 0;

  const TotalAnkleL =
    docFeedback.AnkleL === "1"
      ? 10
      : docFeedback.AnkleL === "2"
      ? 5
      : docFeedback.AnkleL === "3"
      ? 0
      : 0;

  const TotalMotionRtext =
    docFeedback.MotionRtext < 15
      ? 0
      : docFeedback.MotionRtext >= 15 && docFeedback.MotionRtext <= 29
      ? 4
      : docFeedback.MotionRtext >= 30
      ? 8
      : 0;

  const TotalMotionLtext =
    docFeedback.MotionLRtext < 15
      ? 0
      : docFeedback.MotionLRtext >= 15 && docFeedback.MotionLRtext <= 29
      ? 4
      : docFeedback.MotionLRtext >= 30
      ? 8
      : 0;

  const TotalMotion3Rtext =
    docFeedback.Motion3Rtext < 12.5
      ? 0
      : docFeedback.Motion3Rtext >= 12.5 && docFeedback.Motion3Rtext <= 37.4
      ? 3
      : docFeedback.Motion3Rtext >= 37.5
      ? 6
      : 0;
  const TotalMotion4Rtext =
    docFeedback.Motion3RRtext < 12.5
      ? 0
      : docFeedback.Motion3RRtext >= 12.5 && docFeedback.Motion3RRtext <= 37.4
      ? 3
      : docFeedback.Motion3RRtext >= 37.5
      ? 6
      : 0;

  const TotalStabilityR =
    docFeedback.StabilityR === "0" ? 8 : docFeedback.StabilityR === "1" ? 0 : 0;
  const TotalStabilityL =
    docFeedback.StabilityL === "0" ? 8 : docFeedback.StabilityL === "1" ? 0 : 0;

  const HindfootScaleR = sumAll(
    AofasHindfoot,
    AofasHindfootb,
    AofasHindwalkingR,
    AofasHindmaxwalkingR,
    TotalEvaluatedR,
    TotalAnkleR,
    TotalMotionRtext,
    TotalMotion3Rtext,
    TotalStabilityR
  );

  const HindfootScaleL = sumAll(
    AofasHindfootL,
    AofasHindfootbL,
    AofasHindwalkingL,
    AofasHindmaxwalkingL,
    TotalEvaluatedL,
    TotalAnkleL,
    TotalMotionLtext,
    TotalMotion4Rtext,
    TotalStabilityL
  );
  // AOFAS Midfoot  =======================================================

  const AofasMidfootdescripR =
    painSco.descripR === "0"
      ? 40
      : painSco.descripR === "1"
      ? 30
      : painSco.descripR === "2"
      ? 20
      : painSco.descripR === "3"
      ? 0
      : 0;
  const AofasMidfootdescripL =
    painSco.descripL === "0"
      ? 40
      : painSco.descripL === "1"
      ? 30
      : painSco.descripL === "2"
      ? 20
      : painSco.descripL === "3"
      ? 0
      : 0;

  const AofasMidfootfootActivityR =
    functionSco.footActivityR === "3"
      ? 0
      : functionSco.footActivityR === "2"
      ? 4
      : functionSco.footActivityR === "1"
      ? 7
      : functionSco.footActivityR === "0"
      ? 10
      : 0;
  const AofasMidfootfootActivityL =
    functionSco.footActivityL === "3"
      ? 0
      : functionSco.footActivityL === "2"
      ? 4
      : functionSco.footActivityL === "1"
      ? 7
      : functionSco.footActivityL === "0"
      ? 10
      : 0;
  const AofasMidfootfootwearR =
    functionSco.footwearR === "0"
      ? 5
      : functionSco.footwearR === "1"
      ? 3
      : functionSco.footwearR === "2"
      ? 0
      : 0;
  const AofasMidfootfootwearL =
    functionSco.footwearL === "0"
      ? 5
      : functionSco.footwearL === "1"
      ? 3
      : functionSco.footwearL === "2"
      ? 0
      : 0;

  const AofasMidfootwalkingR =
    functionSco.walkingR === "2"
      ? 0
      : functionSco.walkingR === "1"
      ? 5
      : functionSco.walkingR === "0"
      ? 10
      : 0;
  const AofasMidfootwalkingL =
    functionSco.walkingL === "2"
      ? 0
      : functionSco.walkingL === "1"
      ? 5
      : functionSco.walkingL === "0"
      ? 10
      : 0;
  const AofasMidfootmaxwalkingR =
    functionSco.maxWalkingR === "3"
      ? 0
      : functionSco.maxWalkingR === "2"
      ? 4
      : functionSco.maxWalkingR === "1"
      ? 7
      : functionSco.maxWalkingR === "0"
      ? 10
      : 0;

  const AofasMidfootmaxwalkingL =
    functionSco.maxWalkingL === "3"
      ? 0
      : functionSco.maxWalkingL === "2"
      ? 4
      : functionSco.maxWalkingL === "1"
      ? 7
      : functionSco.maxWalkingL === "0"
      ? 10
      : 0;

  const AOFASSEvaluatedR =
    docFeedback.RightGAIT === "1"
      ? 10
      : docFeedback.RightGAIT === "2"
      ? 10
      : docFeedback.RightGAIT === "3"
      ? 5
      : docFeedback.RightGAIT === "4"
      ? 0
      : 0;
  const AOFASSEvaluatedL =
    docFeedback.LeftGAIT === "1"
      ? 10
      : docFeedback.LeftGAIT === "2"
      ? 10
      : docFeedback.LeftGAIT === "3"
      ? 5
      : docFeedback.LeftGAIT === "4"
      ? 0
      : 0;

  const midMidfootR =
    docFeedback.MidfootR === "1"
      ? 15
      : docFeedback.MidfootR === "2"
      ? 8
      : docFeedback.MidfootR === "0"
      ? 0
      : 0;
  const midMidfootL =
    docFeedback.MidfootL === "1"
      ? 15
      : docFeedback.MidfootL === "2"
      ? 8
      : docFeedback.MidfootL === "0"
      ? 0
      : 0;

  const MidfootScaleR = sumAll(
    AofasMidfootdescripR,
    AofasMidfootfootActivityR,
    AofasMidfootfootwearR,
    AofasMidfootwalkingR,
    AofasMidfootmaxwalkingR,
    // AOFASEvaluatedR,
    AOFASSEvaluatedR,
    midMidfootR
  );
  console.log(
    "Midfoot Right ",
    AofasMidfootdescripR,
    AofasMidfootfootActivityR,
    AofasMidfootfootwearR,
    AofasMidfootwalkingR,
    AofasMidfootmaxwalkingR,
    // AOFASEvaluatedR,
    AOFASSEvaluatedR,
    midMidfootR
  );
  const MidfootScaleL = sumAll(
    AofasMidfootdescripL,
    AofasMidfootfootActivityL,
    AofasMidfootfootwearL,
    AofasMidfootwalkingL,
    AofasMidfootmaxwalkingL,
    // AOFASEvaluatedL,
    AOFASSEvaluatedL,
    midMidfootL
  );
  console.log(
    "Midfoot Left ",
    AofasMidfootdescripL,
    AofasMidfootfootActivityL,
    AofasMidfootfootwearL,
    AofasMidfootwalkingL,
    AofasMidfootmaxwalkingL,
    // AOFASEvaluatedL,
    AOFASSEvaluatedL,
    midMidfootL
  );
  // AOFAS Hallux   ==================================================

  const AohalluxdescripR =
    painSco.descripR === "0"
      ? 40
      : painSco.descripR === "1"
      ? 30
      : painSco.descripR === "2"
      ? 20
      : painSco.descripR === "3"
      ? 0
      : 0;
  const AohalluxdescripL =
    painSco.descripL === "0"
      ? 40
      : painSco.descripL === "1"
      ? 30
      : painSco.descripL === "2"
      ? 20
      : painSco.descripL === "3"
      ? 0
      : 0;
  const AohalluxfootActivityR =
    functionSco.footActivityR === "3"
      ? 0
      : functionSco.footActivityR === "2"
      ? 4
      : functionSco.footActivityR === "1"
      ? 7
      : functionSco.footActivityR === "0"
      ? 10
      : 0;

  const AohalluxfootActivityL =
    functionSco.footActivityL === "3"
      ? 0
      : functionSco.footActivityL === "2"
      ? 4
      : functionSco.footActivityL === "1"
      ? 7
      : functionSco.footActivityL === "0"
      ? 10
      : 0;
  const AohalluxfootwearR =
    functionSco.footwearR === "2"
      ? 0
      : functionSco.footwearR === "1"
      ? 5
      : functionSco.footwearR === "0"
      ? 10
      : 0;

  const AohalluxfootwearL =
    functionSco.footwearL === "2"
      ? 0
      : functionSco.footwearL === "1"
      ? 5
      : functionSco.footwearL === "0"
      ? 10
      : 0;

  const AOFASAlignmentR =
    docFeedback.AlignmentR === "1"
      ? 15
      : docFeedback.AlignmentR === "2"
      ? 8
      : docFeedback.AlignmentR === "3"
      ? 0
      : 0;
  const AOFASAlignmentL =
    docFeedback.AlignmentL === "1"
      ? 15
      : docFeedback.AlignmentL === "2"
      ? 8
      : docFeedback.AlignmentL === "3"
      ? 0
      : 0;

  let AOFASAlignmentradioR;
  if (docFeedback.AlignmentradioL === "0") {
    AOFASAlignmentradioR = 5;
  } else {
    if (docFeedback.Alignmentdrop === "1") {
      AOFASAlignmentradioR = 5;
    } else {
      AOFASAlignmentradioR = 0;
    }
  }

  let AOFASAlignmentradioL;
  if (docFeedback.Alignmen1L === "0") {
    AOFASAlignmentradioL = 5;
  } else {
    if (docFeedback.Alignmen1drop === "1") {
      AOFASAlignmentradioL = 5;
    } else {
      AOFASAlignmentradioL = 0;
    }
  }

  const TotalAlignmeninputR =
    docFeedback.Motion2Rtext < 30
      ? 0
      : docFeedback.Motion2Rtext >= 30 && docFeedback.Motion2Rtext <= 74
      ? 5
      : docFeedback.Motion2Rtext >= 75
      ? 10
      : 0;

  const TotalAlignmeninputL =
    docFeedback.Motion2LRtext < 30
      ? 0
      : docFeedback.Motion2LRtext >= 30 && docFeedback.Motion2LRtext <= 74
      ? 5
      : docFeedback.Motion2LRtext >= 75
      ? 10
      : 0;

  const TotalAlignmeninputIPJOINTR = docFeedback.Motion23Rtext >= 10 ? 5 : 0;

  const TotalAlignmeninputIPJOINTL = docFeedback.Motion23RRtext >= 10 ? 5 : 0;

  const AOFASAlignmen2R = docFeedback.Alignmen2R === "0" ? 5 : 0;
  const AOFASAlignmen2L = docFeedback.Alignmen2L === "0" ? 5 : 0;

  const HalluxScaleR = sumAll(
    AohalluxdescripR,
    AohalluxfootActivityR,
    AohalluxfootwearR,
    AOFASAlignmentR,
    AOFASAlignmentradioR,
    TotalAlignmeninputR,
    TotalAlignmeninputIPJOINTR,
    AOFASAlignmen2R
  );
  console.log(AohalluxdescripR,
    AohalluxfootActivityR,
    AohalluxfootwearR,
    AOFASAlignmentR,
    AOFASAlignmentradioR,
    TotalAlignmeninputR,
    TotalAlignmeninputIPJOINTR,
    AOFASAlignmen2R,"Right");
    console.log(HalluxScaleR,"TOtalR");

  const HalluxScaleL = sumAll(
    AohalluxdescripL,
    AohalluxfootActivityL,
    AohalluxfootwearL,
    AOFASAlignmentL,
    AOFASAlignmentradioL,
    TotalAlignmeninputL,
    TotalAlignmeninputIPJOINTL,
    AOFASAlignmen2L
  );
  console.log(AohalluxdescripL,
    AohalluxfootActivityL,
    AohalluxfootwearL,
    AOFASAlignmentL,
    AOFASAlignmentradioL,
    TotalAlignmeninputL,
    TotalAlignmeninputIPJOINTL,
    AOFASAlignmen2L,"left");
    console.log(HalluxScaleL,"totalL");

  ////////////////////////////////////////////////////////////////////////////////////////////////
  ///  AOFAS LesserToe

  const AolesserdescripR =
    painSco.descripR === "0"
      ? 40
      : painSco.descripR === "1"
      ? 30
      : painSco.descripR === "2"
      ? 20
      : painSco.descripR === "3"
      ? 0
      : 0;
  const AolesserdescripL =
    painSco.descripL === "0"
      ? 40
      : painSco.descripL === "1"
      ? 30
      : painSco.descripL === "2"
      ? 20
      : painSco.descripL === "3"
      ? 0
      : 0;

  const AolesserfootActivityR =
    functionSco.footActivityR === "3"
      ? 0
      : functionSco.footActivityR === "2"
      ? 4
      : functionSco.footActivityR === "1"
      ? 7
      : functionSco.footActivityR === "0"
      ? 10
      : 0;
  const AolesserfootActivityL =
    functionSco.footActivityL === "3"
      ? 0
      : functionSco.footActivityL === "2"
      ? 4
      : functionSco.footActivityL === "1"
      ? 7
      : functionSco.footActivityL === "0"
      ? 10
      : 0;

  const AolesserfootwearR =
    functionSco.footwearR === "0"
      ? 10
      : functionSco.footwearR === "1"
      ? 5
      : functionSco.footwearR === "2"
      ? 0
      : 0;
  const AolesserfootwearL =
    functionSco.footwearL === "0"
      ? 10
      : functionSco.footwearL === "1"
      ? 5
      : functionSco.footwearL === "2"
      ? 0
      : 0;

  const Alignmentvalue = (data) => {
    const TotalAlignmen2R =
      data === "1" ? 15 : data === "2" ? 8 : data === "3" ? 0 : 0;
    return TotalAlignmen2R;
  };

  const Callusvalue = (data) => {
    const TotalAlignmen2R = data === "0" ? 5 : 0;
    return TotalAlignmen2R;
  };

  const CallusPresentvalue = (data) => {
    const TotalAlignmen2R =
      data === "Asymptomatic" ? 5 : data === "Symptomatic" ? 0 : 0;
    return TotalAlignmen2R;
  };

  const Motionvalue = (data) => {
    let TotalMotion2ndRtext =
      data >= 30 && data <= 74 ? 5 : data >= 75 ? 10 : 0;
    return TotalMotion2ndRtext;
  };

  const IpJoinvalue = (data) => {
    let TotalMotion2nRtext = data >= 10 ? 5 : data < 10 ? 0 : 0;
    return TotalMotion2nRtext;
  };

  const Stabilityvalue = (data) => {
    let TotalSpecifyRradio2 = data === "0" ? 5 : data === "1" ? 0 : 0;
    return TotalSpecifyRradio2;
  };

  //2nd toe
  let TotalAlignmen2R = Alignmentvalue(docFeedback.LesserR2);
  let TotalAlignmen2L = Alignmentvalue(docFeedback.LesserL2);
  let TotalLesserradioR2 = Callusvalue(docFeedback.LesserradioR2);
  let TotalLesserradioL2 = Callusvalue(docFeedback.LesserradioL2);
  let TotalLesserrRdrop2 = CallusPresentvalue(docFeedback.LesserrRdrop2);
  let TotalLesserrLdrop2 = CallusPresentvalue(docFeedback.LesserrLdrop2);

  let TotalMotion2ndRtext = Motionvalue(docFeedback.Motion2ndRtext);

  // let TotalMotion2ndLtext = Motionvalue(docFeedback.Motion2ndLtext)

  let TotalMotion2ndLRtext = Motionvalue(docFeedback.Motion2ndLRtext);

  let TotalMotion2ndLLtext = Motionvalue(docFeedback.Motion2ndLLtext);
  let TotalMotion2nRtext = IpJoinvalue(docFeedback.Motion2nRtext);
  // let TotalMotion2nlRtext = IpJoinvalue(docFeedback.Motion2nlRtext)
  let TotalMotion2ndRRtext = IpJoinvalue(docFeedback.Motion2ndRRtext);
  let TotalMotion2ndRLtext = IpJoinvalue(docFeedback.Motion2ndRLtext);
  let TotalSpecifyRradio2 = Stabilityvalue(docFeedback.SpecifyRradio2);
  let TotalSpecifyLradio2 = Stabilityvalue(docFeedback.SpecifyLradio2);

  const LesserToeScaleR2ndToe = sumAll(
    AolesserdescripR,
    AolesserfootActivityR,
    AolesserfootwearR,

    TotalAlignmen2R,
    TotalLesserradioR2,
    TotalLesserrRdrop2,
    TotalMotion2ndRtext,
    TotalMotion2nRtext,
    TotalSpecifyRradio2
  );

  const LesserToeScaleL2ndToe = sumAll(
    AolesserdescripL,
    AolesserfootActivityL,
    AolesserfootwearL,

    TotalAlignmen2L,
    TotalLesserradioL2,
    TotalLesserrLdrop2,
    TotalMotion2ndLRtext,
    TotalMotion2ndRRtext,
    TotalSpecifyLradio2
  );

  //3rd toe
  let TotalAlignmen3R = Alignmentvalue(docFeedback.LesserR23rd);
  let TotalAlignmen3L = Alignmentvalue(docFeedback.LesserL23rd);
  let TotalLesserradioR3 = Callusvalue(docFeedback.LesserradioR23rd);
  let TotalLesserradioL3 = Callusvalue(docFeedback.LesserradioL23rd);
  let TotalLesserrRdrop23rd = CallusPresentvalue(docFeedback.LesserrRdrop23rd);
  let TotalLesserrLdrop23rd = CallusPresentvalue(docFeedback.LesserrLdrop23rd);

  let TotalMotion3ndRtext = Motionvalue(docFeedback.Motion3ndRtext);
  let TotalMotion3ndLtext = Motionvalue(docFeedback.Motion3ndLtext);
  let TotalMotion3ndLRtext = Motionvalue(docFeedback.Motion3ndLRtext);
  let TotalMotion3ndLLtext = Motionvalue(docFeedback.Motion3ndLLtext);

  let TotalMotion3nRtext = IpJoinvalue(docFeedback.Motion3nRtext);
  let TotalMotion3nlRtext = IpJoinvalue(docFeedback.Motion3nlRtext);
  let TotalMotion3ndRRtext = IpJoinvalue(docFeedback.Motion3ndRRtext);
  let TotalMotion3ndRLtext = IpJoinvalue(docFeedback.Motion3ndRLtext);

  let TotalSpecifyRradio23rd = Stabilityvalue(docFeedback.SpecifyRradio23rd);

  let TotalSpecifyLradio23rd = Stabilityvalue(docFeedback.SpecifyLradio23rd);

  const LesserToeScaleR3rdToe = sumAll(
    AolesserdescripR,
    AolesserfootActivityR,
    AolesserfootwearR,

    TotalAlignmen3R,
    TotalLesserradioR3,
    TotalLesserrRdrop23rd,
    TotalMotion3ndRtext,
    TotalMotion3nRtext,
    TotalSpecifyRradio23rd
  );

  const LesserToeScaleL3rdToe = sumAll(
    AolesserdescripL,
    AolesserfootActivityL,
    AolesserfootwearL,

    TotalAlignmen3L,
    TotalLesserradioL3,
    TotalLesserrLdrop23rd,
    TotalMotion3ndLRtext,
    TotalMotion3ndRRtext,
    TotalSpecifyLradio23rd
  );

  //4th toe
  let TotalLesserR24th = Alignmentvalue(docFeedback.LesserR24th);
  let TotalLesserL24th = Alignmentvalue(docFeedback.LesserL24th);

  let TotalLesserradioR24th = Callusvalue(docFeedback.LesserradioR24th);
  let TotalLesserradioL24th = Callusvalue(docFeedback.LesserradioL24th);
  let TotalLesserrRdrop24th = CallusPresentvalue(docFeedback.LesserrRdrop24th);
  let TotalLesserrLdrop24th = CallusPresentvalue(docFeedback.LesserrLdrop24th);

  let TotalMotion4ndRtext = Motionvalue(docFeedback.Motion4ndRtext);
  let TotalMotion4ndLtext = Motionvalue(docFeedback.Motion4ndLtext);
  let TotalMotion4ndLRtext = Motionvalue(docFeedback.Motion4ndLRtext);
  let TotalMotion4ndLLtext = Motionvalue(docFeedback.Motion4ndLLtext);

  let TotalMotion4nRtext = IpJoinvalue(docFeedback.Motion4nRtext);
  let TotalMotion4nlRtext = IpJoinvalue(docFeedback.Motion4nlRtext);
  let TotalMotion4ndRRtext = IpJoinvalue(docFeedback.Motion4ndRRtext);
  let TotalMotion4ndRLtext = IpJoinvalue(docFeedback.Motion4ndRLtext);

  let TotalSpecifyRradio24th = Stabilityvalue(docFeedback.SpecifyRradio24th);

  let TotalSpecifyLradio24th = Stabilityvalue(docFeedback.SpecifyLradio24th);

  const LesserToeScaleR4thToe = sumAll(
    AolesserdescripR,
    AolesserfootActivityR,
    AolesserfootwearR,

    TotalLesserR24th,
    TotalLesserradioR24th,
    TotalLesserrRdrop24th,
    TotalMotion4ndRtext,
    TotalMotion4nRtext,
    TotalSpecifyRradio24th
  );

  const LesserToeScaleL4thToe = sumAll(
    AolesserdescripL,
    AolesserfootActivityL,
    AolesserfootwearL,

    TotalLesserL24th,
    TotalLesserradioL24th,
    TotalLesserrLdrop24th,
    TotalMotion4ndLRtext,
    TotalMotion4ndRRtext,
    TotalSpecifyLradio24th
  );
  //5th toe
  let TotalLesserR25th = Alignmentvalue(docFeedback.LesserR25th);
  let TotalLesserL25th = Alignmentvalue(docFeedback.LesserL25th);
  let TotalLesserradioR25th = Callusvalue(docFeedback.LesserradioR25th);
  let TotalLesserradioL25th = Callusvalue(docFeedback.LesserradioL25th);
  let TotalLesserrRdrop25th = CallusPresentvalue(docFeedback.LesserrRdrop25th);
  let TotalLesserrLdrop25th = CallusPresentvalue(docFeedback.LesserrLdrop25th);

  let TotalMotion5ndRtext = Motionvalue(docFeedback.Motion5ndRtext);
  let TotalMotion5ndLtext = Motionvalue(docFeedback.Motion5ndLtext);
  let TotalMotion5ndLRtext = Motionvalue(docFeedback.Motion5ndLRtext);
  let TotalMotion5ndLLtext = Motionvalue(docFeedback.Motion5ndLLtext);

  let TotalMotion5nRtext = IpJoinvalue(docFeedback.Motion5nRtext);
  let TotalMotion5nlRtext = IpJoinvalue(docFeedback.Motion5nlRtext);
  let TotalMotion5ndRRtext = IpJoinvalue(docFeedback.Motion5ndRRtext);
  let TotalMotion5ndRLtext = IpJoinvalue(docFeedback.Motion5ndRLtext);
  let TotalSpecifyRradio25th = Stabilityvalue(docFeedback.SpecifyRradio25th);

  let TotalSpecifyLradio25th = Stabilityvalue(docFeedback.SpecifyLradio25th);

  const LesserToeScaleR5thToe = sumAll(
    AolesserdescripR,
    AolesserfootActivityR,
    AolesserfootwearR,

    TotalLesserR25th,
    TotalLesserradioR25th,
    TotalLesserrRdrop25th,
    TotalMotion5ndRtext,
    TotalMotion5nRtext,
    TotalSpecifyRradio25th
  );
  const LesserToeScaleL5thToe = sumAll(
    AolesserdescripL,
    AolesserfootActivityL,
    AolesserfootwearL,

    TotalLesserL25th,
    TotalLesserradioL25th,
    TotalLesserrLdrop25th,
    TotalMotion5ndLRtext,
    TotalMotion5ndRRtext,
    TotalSpecifyLradio25th
  );

  const arrdataL = [];
  const arrdataR = [];

  if (Toe2nd === true) {
    arrdataR.push(Number(LesserToeScaleR2ndToe));
    arrdataL.push(Number(LesserToeScaleL2ndToe));
  }
  if (Toe3rd === true) {
    arrdataR.push(Number(LesserToeScaleR3rdToe));
    arrdataL.push(Number(LesserToeScaleL3rdToe));
  }
  if (Toe4th === true) {
    arrdataR.push(Number(LesserToeScaleR4thToe));
    arrdataL.push(Number(LesserToeScaleL4thToe));
  }
  if (Toe5th === true) {
    arrdataR.push(Number(LesserToeScaleR5thToe));
    arrdataL.push(Number(LesserToeScaleL5thToe));
  }

  const LesserToeScaleR = Math.min(...arrdataR);
  const LesserToeScaleL = Math.min(...arrdataL);

  const json1 = {
    HindfootScaleR,
    HindfootScaleL,
    MidfootScaleR,
    MidfootScaleL,
    HalluxScaleR,
    HalluxScaleL,
    LesserToeScaleR,
    LesserToeScaleL,
  };

  const updateDate1 = async () => {
    await axiosinstance.current.put(
      `extapp/forms/update_total_scores?resource_id=${rId}`,
      json1,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  };
  const updateDate = async () => {
    const response = await axiosinstance.current.put(
      `extapp/forms/update_doctor_feedback?resource_id=${rId}&action=1&ak_dc=${dId}`,
      docFeedback1,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
      console.log(response);
    }
  };

  const [errmessage, setErrmessage] = useState(false);

  const handleAccordionToggle = (eventKey) => {
    setActiveKey(eventKey);
  };
  const handleAccordionToggle2 = (eventKey) => {
    setActiveKeyhalax(eventKey);
  };

  const handleAccordionToggleToe1 = (eventKey) => {
    setactiveKeytoe1(eventKey);
  };
  const handleActiveKeyankle = (e) => {
    setActiveKeyankle(e);
  };
  const handleAccordionToggleToe2 = (eventKey) => {
    setactiveKeytoe2(eventKey);
  };
  const handleAccordionToggleToe3 = (eventKey) => {
    setactiveKeytoe3(eventKey);
  };
  const handleAccordionToggleToe4 = (eventKey) => {
    setactiveKeytoe4(eventKey);
  };
  const handleAccordionMid = (eventKey) => {
    setactiveKeyMid(eventKey);
  };

  const [validatorL, setvalidatorL] = useState(false);
  const [validator, setvalidator] = useState(false);
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  const continueRedirect = async () => {
    try {
      // Reset error message and validators
      setErrmessage(false);
      setvalidator(false);
      setvalidatorL(false);
      // //alert("1")
      // if (Toe2nd) {
      //   // //alert("2")
      //   // Add similar validation here
      //   // ...
      //   if (LesserR2 === "" ||
      //     LesserL2 === "" ||
      //     LesserradioR2 === "" ||
      //     LesserradioL2 === "" ||
      //     SpecifyRradio2 === "" ||
      //     SpecifyLradio2 === "" ||
      //     Motion2ndRtext === "" ||
      //     // Motion2ndLtext === "" ||
      //     Motion2ndLRtext === "" ||
      //     // Motion2ndLLtext === "" ||
      //     Motion2nRtext === "" ||
      //     // Motion2nlRtext === "" ||
      //     Motion2ndRRtext === ""
      //     // Motion2ndRLtext === ""
      //   ) {
      //     setErrmessage(true);
      //     // setvalidatorL(true)
      //     setvalidator(true)
      //     window.scrollTo(0, 0);
      //     //alert('1')
      //     return;
      //   }
      // }

      if (Toe2nd) {
        let errorOccurred = false;

        if (
          LesserR2 === "" ||
          LesserL2 === "" ||
          LesserradioR2 === "" ||
          LesserradioL2 === "" ||
          SpecifyRradio2 === "" ||
          SpecifyLradio2 === "" ||
          Motion2ndRtext === "" ||
          Motion2ndRtext === undefined ||
          Motion2ndLRtext === undefined ||
          Motion2nRtext === undefined ||
          Motion2ndRRtext === undefined ||
          Motion2ndLRtext === "" ||
          Motion2nRtext === "" ||
          Motion2ndRRtext === ""
        ) {
          setErrmessage(true);
          setvalidator(true);
          window.scrollTo(0, 0);
          errorOccurred = true;
        }

        if (errorOccurred) {
          return;
        }
      }

      if (AnkleHL) {
        let errorOccurred = false;

        // Check if variables are properly set and conditions are met

        if (AnkleR === "" || AnkleL === "") {
          setErrmessage(true);
          setvalidator(true);
          window.scrollTo(0, 0);
          errorOccurred = true;
          if (errorOccurred) {
            return;
          }
        }

        if (
          MotionRtext === "" ||
          MotionLRtext === "" ||
          Motion3Rtext === "" ||
          Motion3RRtext === "" ||
          Motion3RRtext === undefined
        ) {
          setErrmessage(true);
          setvalidator(true);
          window.scrollTo(0, 0);
          errorOccurred = true;
          if (errorOccurred) {
            return;
          }
        }

        if (
          StabilityR === "" ||
          StabilityL === "" ||
          StabilityR === undefined ||
          StabilityL === undefined
        ) {
          setErrmessage(true);
          setvalidator(true);
          window.scrollTo(0, 0);
          errorOccurred = true;
          if (errorOccurred) {
            return;
          }
        }

        // Check if the error occurred flag is triggered
        console.log("Error occurred:", errorOccurred);

        if (errorOccurred) {
          return;
        }
      }

      // if (Hallux) {
      //   // //alert("4")
      //   if (AlignmentR === "0" || AlignmentL === "0" || AlignmentradioL === "" || Alignmen1L === "" || Alignmen2R === "" || Alignmen2L === "") {

      //     setErrmessage(true);
      //     //alert('3')
      //     window.scrollTo(0, 0);

      //   } if (Motion23Rtext === "" || Motion23RRtext === "" || Motion23RRtext === "") {
      //     setvalidator(true)
      //     // setvalidatorL(true)

      //     return;
      //   }
      // }

      if (Hallux) {
        let errorOccurred = false;

        if (
          AlignmentR === "" ||
          AlignmentR === undefined ||
          AlignmentL === undefined ||
          AlignmentL === "" ||
          AlignmentradioL === "" ||
          AlignmentradioL === undefined ||
          Alignmen1L === "" ||
          Alignmen1L === undefined ||
          Alignmen2R === "" ||
          Alignmen2R === undefined ||
          Alignmen2L === "" ||
          Alignmen2L === undefined
        ) {
          setErrmessage(true);
          setvalidator(true);
          window.scrollTo(0, 0);
          errorOccurred = true;

          return;
        }

        if (
          Motion2Rtext === "" ||
          Motion2Rtext === undefined ||
          Motion2LRtext === "" ||
          Motion2LRtext === undefined ||
          Motion23Rtext === "" ||
          Motion23Rtext === undefined ||
          Motion23RRtext === "" ||
          Motion23RRtext === undefined
        ) {
          setvalidator(true);
          setErrmessage(true);
          window.scrollTo(0, 0);
          errorOccurred = true;

          return;
          // alert(4);
        }

        // if (errorOccurred) {
        //   alert(5);
        //     return;
        // }
      }

      if (Toe3rd) {
        // Add similar validation here
        // ...
        // //alert("5")

        if (
          LesserR23rd === "" ||
          LesserR23rd === undefined ||
          LesserL23rd === "" ||
          LesserL23rd === undefined ||
          LesserradioR23rd === "" ||
          LesserradioR23rd === undefined ||
          LesserradioL23rd === "" ||
          LesserradioL23rd === undefined ||
          Motion3ndRtext === "" ||
          Motion3ndRtext === undefined ||
          Motion3ndLRtext === "" ||
          Motion3ndLRtext === undefined ||
          Motion3nRtext === "" ||
          Motion3nRtext === undefined ||
          Motion3ndRRtext === "" ||
          Motion3ndRRtext === undefined ||
          SpecifyRradio23rd === "" ||
          SpecifyRradio23rd === undefined ||
          SpecifyLradio23rd === "" ||
          SpecifyLradio23rd === undefined
        ) {
          setErrmessage(true);
          // setvalidatorL(true)
          setvalidator(true);
          //alert('4')
          window.scrollTo(0, 0);
          return;
        }
      }
      if (Toe5th) {
        let errorOccurred = false;

        if (
          LesserR25th === "" ||
          LesserL25th === "" ||
          LesserradioR25th === "" ||
          LesserradioL25th === "" ||
          SpecifyRradio25th === "" ||
          SpecifyLradio25th === "" ||
          Motion5ndRtext === "" ||
          Motion5ndLRtext === "" ||
          Motion5nRtext === "" ||
          Motion5ndRRtext === ""
        ) {
          setErrmessage(true);
          setvalidator(true);
          window.scrollTo(0, 0);
          errorOccurred = true;
        }

        if (errorOccurred) {
          return;
        }
      }

      // if (Toe4th) {
      //   // Add similar validation here
      //   // ...
      //   // //alert("6")
      //   if (LesserR24th === "" || LesserL24th === "" || LesserradioR24th === "" || LesserradioL24th === "" || SpecifyRradio24th === "" || SpecifyLradio24th === ""
      //   || Motion4ndRtext === "" || Motion4ndLRtext === ""|| Motion4nRtext === ""|| Motion4ndRRtext === "" )

      //   {
      //     setErrmessage(true);
      //     // setvalidatorL(true)
      //     setvalidator(true)
      //     //alert('5')
      //     window.scrollTo(0, 0);
      //     return;
      //   }
      // }
      if (Toe4th) {
        let errorOccurred = false;

        if (
          LesserR24th === "" ||
          LesserL24th === "" ||
          LesserradioR24th === "" ||
          LesserradioL24th === "" ||
          SpecifyRradio24th === "" ||
          SpecifyLradio24th === "" ||
          Motion4ndRtext === "" ||
          Motion4ndLRtext === "" ||
          Motion4nRtext === "" ||
          Motion4ndRRtext === "" ||
          Motion4ndRtext === undefined ||
          Motion4ndLRtext === undefined ||
          Motion4nRtext === undefined ||
          Motion4ndRRtext === undefined
        ) {
          setErrmessage(true);
          setvalidator(true);
          window.scrollTo(0, 0);
          errorOccurred = true;
        }

        if (errorOccurred) {
          return;
        }
      }

      if (MidfootHL) {
        if (MidfootR === "" || MidfootL === "") {
          // setvalidator(true);
          setErrmessage(true);
          window.scrollTo(0, 0);
          //alert('7')
        }
        // if (MidfootL === "") {
        //   setvalidator(true);
        //   //alert('8')
        //   window.scrollTo(0, 0);
        // }
        else {
          updateDate1();
          const response = await axiosinstance.current.put(
            `extapp/forms/update_doctor_feedback?resource_id=${rId}&action=1&ak_dc=${dId}&edit=1`,
            docFeedback1,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          if (response.status === 200) {
            // window.location.href =
            //   process.env.REACT_APP_DOCTOR_URL + "patient-list";
            setDisplayConfirmationModal(true);
          }
        }
        return;
      } else {
        //alert("hfhg")
        updateDate1();
        const response = await axiosinstance.current.put(
          `extapp/forms/update_doctor_feedback?resource_id=${rId}&action=1&ak_dc=${dId}&edit=1`,
          docFeedback1,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 200) {
          setDisplayConfirmationModal(true);
          // window.location.href = process.env.REACT_APP_DOCTOR_URL + "patient-list";
        }
      }

      if (AnkleHL) {
        if (AnkleR === "") {
          setErrmessage(true);
          window.scrollTo(0, 0);
        }
        if (AnkleL === "") {
          setErrmessage(true);
          window.scrollTo(0, 0);
        }
        if (MotionRtext === "") {
          setvalidator(true);
          window.scrollTo(0, 0);
        }
        // if (MotionLtext === "") {
        //   setvalidatorL(true);
        //   window.scrollTo(0, 0);
        // }
        if (MotionLRtext === "") {
          setvalidator(true);
        }
        // if (MotionLLtext === "") {
        //   setvalidatorL(true);
        // }

        // if (Motion3Rtext === "") {
        //   setvalidatorL(true);
        // }
        // if (Motion4Rtext === "") {
        //   setvalidatorL(true);
        // }
        // if (Motion3RRtext === "") {
        //   setvalidatorL(true);
        // }
        // if (Motion3RLtext === "") {
        //   setvalidatorL(true);
        // }
        if (
          StabilityR === "" &&
          StabilityL === ""
          // Stability2R === "" &&
          // Stability2L === "" &&
          // Stability3R === "" &&
          // Stability3L === "" &&
          // TalarR === "" &&
          // TalarL === "" && Talar2R === "" &&
          // Talar2L === ""
        ) {
          setErrmessage(true);
          //alert('6')
          window.scrollTo(0, 0);
        }
        // if (
        //   SyndesmosisR === "" &&
        //   SyndesmosisL === "" &&
        //   Syndesmosis1R === "" &&
        //   Syndesmosis1L === ""
        // ) {
        //   setErrmessage(true);
        //   window.scrollTo(0, 0);
        // }
        // if (
        //   ImpingementR === "" &&
        //   ImpingementL === "" &&
        //   Impingement1R === "" &&
        //   Impingement1L === "" &&
        //   Impingement3R === "" &&
        //   Impingement3L === "" &&
        //   Impingement4R === "" &&
        //   Impingement4L === ""
        // ) {
        //   setErrmessage(true);
        //   window.scrollTo(0, 0);
        // }
        // if (
        //   AchillesR === "" &&
        //   AchillesL === "" &&
        //   Achilles2R === "" &&
        //   Achilles2L === "" &&
        //   Achilles4R === "" &&
        //   Achilles4L === "" &&
        //   Achilles6R === "" &&
        //   Achilles6L === ""
        // ) {
        //   setErrmessage(true);
        //   window.scrollTo(0, 0);
        // }
        // if (
        //   DeformityR === "" &&
        //   DeformityL === "" &&
        //   Deformity2R === "" &&
        //   Deformity2L === "" &&
        //   Deformity4R === "" &&
        //   Deformity4L === "" &&
        //   Deformity6R === "" &&
        //   Deformity6L === "" &&
        //   Deformity8R === "" &&
        //   Deformity8L === ""
        // ) {
        //   setErrmessage(true);
        //   window.scrollTo(0, 0);
        //   //alert('10')
        // }
        else {
          updateDate1();
          const response = await axiosinstance.current.put(
            `extapp/forms/update_doctor_feedback?resource_id=${rId}&action=1&ak_dc=${dId}&edit=1`,
            docFeedback1,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          if (response.status === 200) {
            setDisplayConfirmationModal(true);
            // window.location.href =
            //   process.env.REACT_APP_DOCTOR_URL + "patient-list";
          }
          return;
        }
      }
      if ((AnkleHL || MidfootHL) && (RightGAIT === "0" || LeftGAIT === "0")) {
        setErrmessage(true);
        window.scrollTo(0, 0);
        //alert('2')
        return;
      }

      // If none of the conditions matched, proceed with the update
    } catch (error) {
      // Handle errors here, e.g., show an error message to the user
      console.error("Error:", error);
    }
  };

  // const handleSubmit = (e) => {
  //   // updateDate1();
  //   // updateDate();
  //   // setMsg(translation.update);
  //   // setmsgType("success");
  //   // setTimeout(() => {
  //   //   setMsg("");
  //   //   setmsgType("success");
  //   // }, 2000);

  //     if (AnkleHL || MidfootHL) {
  //       if (RightGAIT === "0") {
  //         setErrmessage(true);
  //         window.scrollTo(0, 0);
  //       }
  //       if (RightABNORMAL === "0") {
  //         setErrmessage(true);
  //         window.scrollTo(0, 0);
  //       }
  //       if (LeftGAIT === "0") {
  //         setErrmessage(true);
  //         window.scrollTo(0, 0);
  //       }
  //       if (LeftABNORMAL === "0") {
  //         setErrmessage(true);
  //         window.scrollTo(0, 0);
  //       } else {
  //         updateDate1();
  //         const response = await axiosinstance.current.put(
  //           `extapp/forms/update_doctor_feedback?resource_id=${rId}&action=1&ak_dc=${dId}`,
  //           docFeedback1,
  //           {
  //             headers: {
  //               "Content-Type": "application/json",
  //             },
  //           }
  //         );
  //         if (response.status === 200) {
  //           window.location.href =
  //             process.env.REACT_APP_DOCTOR_URL + "patient-list";
  //         }
  //       }
  //       return;
  //     }
  //     if (Hallux) {
  //       if (AlignmentR === "0") {
  //         setErrmessage(true);
  //         window.scrollTo(0, 0);
  //       }
  //       if (AlignmentL === "0") {
  //         setErrmessage(true);
  //         window.scrollTo(0, 0);
  //       }
  //       if (AlignmentradioL === "") {
  //         setErrmessage(true);
  //         window.scrollTo(0, 0);
  //       }
  //       if (Alignmen1L === "") {
  //         setErrmessage(true);
  //         window.scrollTo(0, 0);
  //       }
  //       if (Alignmen2R === "") {
  //         setErrmessage(true);
  //         window.scrollTo(0, 0);
  //       }
  //       if (Alignmen2L === "") {
  //         setErrmessage(true);
  //         window.scrollTo(0, 0);
  //       } else {
  //         updateDate1();
  //         const response = await axiosinstance.current.put(
  //           `extapp/forms/update_doctor_feedback?resource_id=${rId}&action=1&ak_dc=${dId}`,
  //           docFeedback1,
  //           {
  //             headers: {
  //               "Content-Type": "application/json",
  //             },
  //           }
  //         );
  //         if (response.status === 200) {
  //           window.location.href =
  //             process.env.REACT_APP_DOCTOR_URL + "patient-list";
  //         }
  //       }
  //       return;
  //     }
  //     if (Toe2nd) {
  //       if (LesserR2 === "") {
  //         setErrmessage(true);
  //         window.scrollTo(0, 0);
  //       }
  //       if (LesserL2 === "") {
  //         setErrmessage(true);
  //         window.scrollTo(0, 0);
  //       }
  //       if (LesserradioR2 === "") {
  //         setErrmessage(true);
  //         window.scrollTo(0, 0);
  //       }
  //       if (LesserradioL2 === "") {
  //         setErrmessage(true);
  //         window.scrollTo(0, 0);
  //       }
  //       if (SpecifyRradio2 === "") {
  //         setErrmessage(true);
  //         window.scrollTo(0, 0);
  //       }
  //       if (SpecifyLradio2 === "") {
  //         setErrmessage(true);
  //         window.scrollTo(0, 0);
  //       } if (Motion2ndRtext === "") {
  //         setvalidatorL(true)
  //       } if (Motion2ndLtext === "") {
  //         setvalidator(true)
  //       } if (Motion2ndLRtext === "") {
  //         setvalidatorL(true)
  //       } if (Motion2ndLLtext === "") {
  //         setvalidator(true)
  //       } if (Motion2nRtext === "") {
  //         setvalidatorL(true)
  //       } if (Motion2nlRtext === "") {
  //         setvalidator(true)
  //       } if (Motion2ndRRtext === "") {
  //         setvalidatorL(true)
  //       } if (Motion2ndRLtext === "") {
  //         setvalidator(true)
  //       } else {
  //         updateDate1();
  //         const response = await axiosinstance.current.put(
  //           `extapp/forms/update_doctor_feedback?resource_id=${rId}&action=1&ak_dc=${dId}`,
  //           docFeedback1,
  //           {
  //             headers: {
  //               "Content-Type": "application/json",
  //             },
  //           }
  //         );
  //         if (response.status === 200) {
  //           window.location.href =
  //             process.env.REACT_APP_DOCTOR_URL + "patient-list";
  //         }
  //       }
  //       return;
  //     }
  //     if (Toe3rd) {
  //       if (LesserR23rd === "") {
  //         setErrmessage(true);
  //         window.scrollTo(0, 0);
  //       }
  //       if (LesserL23rd === "") {
  //         setErrmessage(true);
  //         window.scrollTo(0, 0);
  //       }
  //       if (LesserradioR23rd === "") {
  //         setErrmessage(true);
  //         window.scrollTo(0, 0);
  //       }
  //       if (SpecifyRradio23rd === "") {
  //         setErrmessage(true);
  //         window.scrollTo(0, 0);
  //       }
  //       if (SpecifyLradio23rd === "") {
  //         setErrmessage(true);
  //         window.scrollTo(0, 0);
  //       }
  //       if (LesserradioL23rd === "") {
  //         setErrmessage(true);
  //         window.scrollTo(0, 0);
  //       } if (Motion3ndRtext === "") {
  //         window.scrollTo(0, 0);
  //         setvalidatorL(true)
  //       } if (Motion3ndLtext === "") {
  //         window.scrollTo(0, 0);
  //         setvalidator(true)
  //       } if (Motion3ndLRtext === "") {
  //         window.scrollTo(0, 0);
  //         setvalidatorL(true)
  //       } if (Motion3ndLLtext === "") {
  //         window.scrollTo(0, 0);
  //         setvalidator(true)
  //       } if (Motion3nRtext === "") {
  //         window.scrollTo(0, 0);
  //         setvalidatorL(true)
  //       } if (Motion3nlRtext === "") {
  //         window.scrollTo(0, 0);
  //         setvalidator(true)
  //       } if (Motion3ndRRtext === "") {
  //         window.scrollTo(0, 0);
  //         setvalidatorL(true)
  //       } if (Motion3ndRLtext === "") {
  //         window.scrollTo(0, 0);
  //         setvalidator(true)
  //       } else {
  //         updateDate1();
  //         const response = await axiosinstance.current.put(
  //           `extapp/forms/update_doctor_feedback?resource_id=${rId}&action=1&ak_dc=${dId}`,
  //           docFeedback1,
  //           {
  //             headers: {
  //               "Content-Type": "application/json",
  //             },
  //           }
  //         );
  //         if (response.status === 200) {
  //           window.location.href =
  //             process.env.REACT_APP_DOCTOR_URL + "patient-list";
  //         }
  //       }
  //       return;
  //     }
  //     if (Toe4th) {
  //       if (LesserR24th === "") {
  //         setErrmessage(true);
  //         window.scrollTo(0, 0);
  //       }
  //       if (LesserL24th === "") {
  //         setErrmessage(true);
  //         window.scrollTo(0, 0);
  //       }
  //       if (LesserradioR24th === "") {
  //         setErrmessage(true);
  //         window.scrollTo(0, 0);
  //       }
  //       if (LesserradioL24th === "") {
  //         setErrmessage(true);
  //         window.scrollTo(0, 0);
  //       }
  //       if (SpecifyRradio24th === "") {
  //         setErrmessage(true);
  //         window.scrollTo(0, 0);
  //       }
  //       if (SpecifyLradio24th === "") {
  //         setErrmessage(true);
  //         window.scrollTo(0, 0);
  //       } if (Motion4ndRtext === "") {
  //         window.scrollTo(0, 0);
  //         setvalidatorL(true)
  //       } if (Motion4ndLtext === "") {
  //         window.scrollTo(0, 0);
  //         setvalidator(true)
  //       } if (Motion4ndLRtext === "") {
  //         window.scrollTo(0, 0);
  //         setvalidatorL(true)
  //       } if (Motion4ndLLtext === "") {
  //         window.scrollTo(0, 0);
  //         setvalidator(true)
  //       } if (Motion4nRtext === "") {
  //         window.scrollTo(0, 0);
  //         setvalidatorL(true)
  //       } if (Motion4nlRtext === "") {
  //         window.scrollTo(0, 0);
  //         setvalidator(true)
  //       } if (Motion4ndRRtext === "") {
  //         window.scrollTo(0, 0);
  //         setvalidatorL(true)
  //       } if (Motion4ndRLtext === "") {
  //         window.scrollTo(0, 0);
  //         setvalidator(true)
  //       } else {
  //         updateDate1();
  //         const response = await axiosinstance.current.put(
  //           `extapp/forms/update_doctor_feedback?resource_id=${rId}&action=1&ak_dc=${dId}`,
  //           docFeedback1,
  //           {
  //             headers: {
  //               "Content-Type": "application/json",
  //             },
  //           }
  //         );
  //         if (response.status === 200) {
  //           window.location.href =
  //             process.env.REACT_APP_DOCTOR_URL + "patient-list";
  //         }
  //       }
  //       return;
  //     }
  //     if (Toe5th) {
  //       if (LesserR25th === "") {
  //         setErrmessage(true);
  //         window.scrollTo(0, 0);
  //       }
  //       if (LesserL25th === "") {
  //         setErrmessage(true);
  //         window.scrollTo(0, 0);
  //       }
  //       if (LesserradioR25th === "") {
  //         setErrmessage(true);
  //         window.scrollTo(0, 0);
  //       }
  //       if (LesserradioL25th === "") {
  //         setErrmessage(true);
  //         window.scrollTo(0, 0);
  //       }
  //       if (SpecifyRradio25th === "") {
  //         setErrmessage(true);
  //         window.scrollTo(0, 0);
  //       }
  //       if (SpecifyLradio25th === "") {
  //         setErrmessage(true);
  //         window.scrollTo(0, 0);
  //       } if (Motion5ndRtext === "") {
  //         window.scrollTo(0, 0);
  //         setvalidatorL(true)
  //       } if (Motion5ndLtext === "") {
  //         window.scrollTo(0, 0);
  //         setvalidator(true)
  //       } if (Motion5ndLRtext === "") {
  //         window.scrollTo(0, 0);
  //         setvalidatorL(true)
  //       } if (Motion5ndLLtext === "") {
  //         window.scrollTo(0, 0);
  //         setvalidator(true)
  //       } if (Motion5nRtext === "") {
  //         window.scrollTo(0, 0);
  //         setvalidatorL(true)
  //       } if (Motion5nlRtext === "") {
  //         window.scrollTo(0, 0);
  //         setvalidator(true)
  //       } if (Motion5ndRRtext === "") {
  //         window.scrollTo(0, 0);
  //         setvalidatorL(true)
  //       } if (Motion5ndRLtext === "") {
  //         window.scrollTo(0, 0);
  //         setvalidator(true)
  //       } else {
  //         updateDate1();
  //         const response = await axiosinstance.current.put(
  //           `extapp/forms/update_doctor_feedback?resource_id=${rId}&action=1&ak_dc=${dId}`,
  //           docFeedback1,
  //           {
  //             headers: {
  //               "Content-Type": "application/json",
  //             },
  //           }
  //         );
  //         if (response.status === 200) {
  //           window.location.href =
  //             process.env.REACT_APP_DOCTOR_URL + "patient-list";
  //         }
  //       }
  //       return;
  //     }
  //     if (AnkleHL) {
  //       if (AnkleR === "") {
  //         setErrmessage(true);
  //         window.scrollTo(0, 0);
  //       }
  //       if (AnkleL === "") {
  //         setErrmessage(true);
  //         window.scrollTo(0, 0);
  //       }
  //       if (MotionRtext === "") {
  //         setvalidator(true);
  //         window.scrollTo(0, 0);
  //       }
  //       if (MotionLtext === "") {
  //         setvalidatorL(true);
  //         window.scrollTo(0, 0);
  //       }
  //       if (MotionLRtext === "") {
  //         setvalidator(true);
  //       }
  //       if (MotionLLtext === "") {
  //         setvalidatorL(true);
  //       }
  //       if (
  //         StabilityR === "" &&
  //         StabilityL === "" &&
  //         Stability2R === "" &&
  //         Stability2L === "" &&
  //         Stability3R === "" &&
  //         Stability3L === "" &&
  //         TalarR === "" &&
  //         TalarL === "" && Talar2R === "" &&
  //         Talar2L === ""
  //       ) {
  //         setErrmessage(true);
  //         window.scrollTo(0, 0);
  //       }
  //       if (
  //         SyndesmosisR === "" &&
  //         SyndesmosisL === "" &&
  //         Syndesmosis1R === "" &&
  //         Syndesmosis1L === ""
  //       ) {
  //         setErrmessage(true);
  //         window.scrollTo(0, 0);
  //       }
  //       if (
  //         ImpingementR === "" &&
  //         ImpingementL === "" &&
  //         Impingement1R === "" &&
  //         Impingement1L === "" &&
  //         Impingement3R === "" &&
  //         Impingement3L === "" &&
  //         Impingement4R === "" &&
  //         Impingement4L === ""
  //       ) {
  //         setErrmessage(true);
  //         window.scrollTo(0, 0);
  //       }
  //       if (
  //         AchillesR === "" &&
  //         AchillesL === "" &&
  //         Achilles2R === "" &&
  //         Achilles2L === "" &&
  //         Achilles4R === "" &&
  //         Achilles4L === "" &&
  //         Achilles6R === "" &&
  //         Achilles6L === ""
  //       ) {
  //         setErrmessage(true);
  //         window.scrollTo(0, 0);
  //       }
  //       if (
  //         DeformityR === "" &&
  //         DeformityL === "" &&
  //         Deformity2R === "" &&
  //         Deformity2L === "" &&
  //         Deformity4R === "" &&
  //         Deformity4L === "" &&
  //         Deformity6R === "" &&
  //         Deformity6L === "" &&
  //         Deformity8R === "" &&
  //         Deformity8L === ""
  //       ) {
  //         setErrmessage(true);
  //         window.scrollTo(0, 0);
  //       } else {
  //         updateDate1();
  //         const response = await axiosinstance.current.put(
  //           `extapp/forms/update_doctor_feedback?resource_id=${rId}&action=1&ak_dc=${dId}`,
  //           docFeedback1,
  //           {
  //             headers: {
  //               "Content-Type": "application/json",
  //             },
  //           }
  //         );
  //         if (response.status === 200) {
  //           window.location.href =
  //             process.env.REACT_APP_DOCTOR_URL + "patient-list";
  //         }
  //       }
  //       return;
  //     }
  //     if (MidfootHL) {
  //       if (MidfootR === "") {
  //         setvalidator(true);
  //         window.scrollTo(0, 0);
  //       }
  //       if (MidfootL === "") {
  //         setvalidator(true);
  //         window.scrollTo(0, 0);
  //       } else {
  //         updateDate1();
  //         const response = await axiosinstance.current.put(
  //           `extapp/forms/update_doctor_feedback?resource_id=${rId}&action=1&ak_dc=${dId}`,
  //           docFeedback1,
  //           {
  //             headers: {
  //               "Content-Type": "application/json",
  //             },
  //           }
  //         );
  //         if (response.status === 200) {
  //           window.location.href =
  //             process.env.REACT_APP_DOCTOR_URL + "patient-list";
  //         }
  //       }
  //       return;
  //     } else {
  //       updateDate1();
  //       const response = await axiosinstance.current.put(
  //         `extapp/forms/update_doctor_feedback?resource_id=${rId}&action=1&ak_dc=${dId}`,
  //         docFeedback1,
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //         }
  //       );
  //       if (response.status === 200) {
  //         window.location.href =
  //           process.env.REACT_APP_DOCTOR_URL + "patient-list";
  //       }
  //     }
  //   };
  const handleSubmit = async () => {
    try {
      // Reset error message and validators
      setErrmessage(false);
      setvalidator(false);
      setvalidatorL(false);
      // //alert("1")
      // if (Toe2nd) {

      //   if (LesserR2 === "" ||
      //     LesserL2 === "" ||
      //     LesserradioR2 === "" ||
      //     LesserradioL2 === "" ||
      //     SpecifyRradio2 === "" ||
      //     SpecifyLradio2 === "" ||
      //     Motion2ndRtext === "" ||
      //     // Motion2ndLtext === "" ||
      //     Motion2ndLRtext === "" ||
      //     // Motion2ndLLtext === "" ||
      //     Motion2nRtext === "" ||
      //     // Motion2nlRtext === "" ||
      //     Motion2ndRRtext === ""
      //     // Motion2ndRLtext === ""
      //   ) {
      //     setErrmessage(true);
      //     // setvalidatorL(true)
      //     setvalidator(true)
      //     window.scrollTo(0, 0);
      //     //alert('1')
      //     return;
      //   }
      // }

      if (Toe2nd) {
        let errorOccurred = false;

        if (
          LesserR2 === "" ||
          LesserL2 === "" ||
          LesserradioR2 === "" ||
          LesserradioL2 === "" ||
          SpecifyRradio2 === "" ||
          SpecifyLradio2 === "" ||
          Motion2ndRtext === "" ||
          Motion2ndRtext === undefined ||
          Motion2ndLRtext === undefined ||
          Motion2nRtext === undefined ||
          Motion2ndRRtext === undefined ||
          Motion2ndLRtext === "" ||
          Motion2nRtext === "" ||
          Motion2ndRRtext === ""
        ) {
          setErrmessage(true);
          setvalidator(true);
          window.scrollTo(0, 0);
          errorOccurred = true;
        }

        if (errorOccurred) {
          return;
        }
      }

      //
      if (AnkleHL) {
        let errorOccurred = false;

        // Check if variables are properly set and conditions are met

        if (AnkleR === "" || AnkleL === "") {
          setErrmessage(true);
          setvalidator(true);
          window.scrollTo(0, 0);
          errorOccurred = true;
          if (errorOccurred) {
            return;
          }
        }

        if (
          MotionRtext === "" ||
          MotionLRtext === "" ||
          Motion3Rtext === "" ||
          Motion3RRtext === "" ||
          Motion3RRtext === undefined
        ) {
          setErrmessage(true);
          setvalidator(true);
          window.scrollTo(0, 0);
          errorOccurred = true;
          if (errorOccurred) {
            return;
          }
        }

        if (
          StabilityR === "" ||
          StabilityL === "" ||
          StabilityR === undefined ||
          StabilityL === undefined
        ) {
          setErrmessage(true);
          setvalidator(true);
          window.scrollTo(0, 0);
          errorOccurred = true;
          if (errorOccurred) {
            return;
          }
        }

        // Check if the error occurred flag is triggered
        console.log("Error occurred:", errorOccurred);

        if (errorOccurred) {
          return;
        }
      }

      if (Hallux) {
        let errorOccurred = false;

        if (
          AlignmentR === "" ||
          AlignmentR === undefined ||
          AlignmentL === undefined ||
          AlignmentL === "" ||
          AlignmentradioL === "" ||
          AlignmentradioL === undefined ||
          Alignmen1L === "" ||
          Alignmen1L === undefined ||
          Alignmen2R === "" ||
          Alignmen2R === undefined ||
          Alignmen2L === "" ||
          Alignmen2L === undefined
        ) {
          setErrmessage(true);
          setvalidator(true);
          window.scrollTo(0, 0);
          errorOccurred = true;
          return;
        }

        if (
          Motion2Rtext === "" ||
          Motion2Rtext === undefined ||
          Motion2LRtext === "" ||
          Motion2LRtext === undefined ||
          Motion23Rtext === "" ||
          Motion23Rtext === undefined ||
          Motion23RRtext === "" ||
          Motion23RRtext === undefined
        ) {
          setvalidator(true);
          setErrmessage(true);
          window.scrollTo(0, 0);
          // errorOccurred = true;

          return;
          // alert(4);
        }

        // if (errorOccurred) {
        //   alert(5);
        //     return;
        // }
      }

      if (Toe3rd) {
        // Add similar validation here
        // ...
        // //alert("5")

        if (
          LesserR23rd === "" ||
          LesserL23rd === "" ||
          LesserradioR23rd === "" ||
          SpecifyRradio23rd === "" ||
          Motion3ndRtext === "" ||
          Motion3ndLRtext === "" ||
          Motion3nRtext === "" ||
          Motion3ndRRtext === "" ||
          SpecifyRradio23rd === "" ||
          SpecifyLradio23rd === ""
        ) {
          setErrmessage(true);
          // setvalidatorL(true)
          setvalidator(true);
          //alert('4')
          window.scrollTo(0, 0);
          return;
        }
      }
      if (Toe4th) {
        let errorOccurred = false;

        if (
          LesserR24th === "" ||
          LesserL24th === "" ||
          LesserradioR24th === "" ||
          LesserradioL24th === "" ||
          SpecifyRradio24th === "" ||
          SpecifyLradio24th === "" ||
          Motion4ndRtext === "" ||
          Motion4ndLRtext === "" ||
          Motion4nRtext === "" ||
          Motion4ndRRtext === ""
        ) {
          setErrmessage(true);
          setvalidator(true);
          window.scrollTo(0, 0);
          errorOccurred = true;
        }

        if (errorOccurred) {
          return;
        }
      }

      if (Toe5th) {
        let errorOccurred = false;

        if (
          LesserR25th === "" ||
          LesserL25th === "" ||
          LesserradioR25th === "" ||
          LesserradioL25th === "" ||
          SpecifyRradio25th === "" ||
          SpecifyLradio25th === "" ||
          Motion5ndRtext === "" ||
          Motion5ndLRtext === "" ||
          Motion5nRtext === "" ||
          Motion5ndRRtext === ""
        ) {
          setErrmessage(true);
          setvalidator(true);
          window.scrollTo(0, 0);
          errorOccurred = true;
        }

        if (errorOccurred) {
          return;
        }
      }

      if (MidfootHL) {
        if (MidfootR === "" || MidfootL === "") {
          setvalidator(true);
          window.scrollTo(0, 0);
          //alert('7')
        }
        // if (MidfootL === "") {
        //   setvalidator(true);
        //   //alert('8')
        //   window.scrollTo(0, 0);
        // }
        else {
          updateDate1();
          const response = await axiosinstance.current.put(
            `extapp/forms/update_doctor_feedback?resource_id=${rId}&action=1&ak_dc=${dId}&edit=1`,
            docFeedback1,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          if (response.status === 200) {
            setDisplayConfirmationModal(true);
            // window.location.href =
            //   process.env.REACT_APP_DOCTOR_URL + "patient-list";
          }
        }
        return;
      } else {
        //alert("hfhg")
        updateDate1();
        const response = await axiosinstance.current.put(
          `extapp/forms/update_doctor_feedback?resource_id=${rId}&action=1&ak_dc=${dId}&edit=1`,
          docFeedback1,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 200) {
          setMsg(translation.update)
      setmsgType('success')
      setTimeout(() => {
        setMsg('')
        setmsgType('success')
      }, 2000);
          setDisplayConfirmationModal(true);
          // window.location.href = process.env.REACT_APP_DOCTOR_URL + "patient-list";
        }
      }

      if (AnkleHL) {
        if (AnkleR === "") {
          setErrmessage(true);
          window.scrollTo(0, 0);
        }
        if (AnkleL === "") {
          setErrmessage(true);
          window.scrollTo(0, 0);
        }
        if (MotionRtext === "") {
          setvalidator(true);
          window.scrollTo(0, 0);
        }
        if (MotionLtext === "") {
          setvalidatorL(true);
          window.scrollTo(0, 0);
        }
        if (MotionLRtext === "") {
          setvalidator(true);
        }
        if (MotionLLtext === "") {
          setvalidatorL(true);
        }

        if (Motion3Rtext === "") {
          setvalidatorL(true);
        }
        if (Motion4Rtext === "") {
          setvalidatorL(true);
        }
        if (Motion3RRtext === "") {
          setvalidatorL(true);
        }
        if (Motion3RLtext === "") {
          setvalidatorL(true);
        }
        if (
          StabilityR === "" ||
          StabilityL === "" ||
          StabilityL === undefined
          // Stability2R === "" &&
          // Stability2L === "" &&
          // Stability3R === "" &&
          // Stability3L === "" &&
          // TalarR === "" &&
          // TalarL === "" && Talar2R === "" &&
          // Talar2L === ""
        ) {
          setErrmessage(true);
          //alert('6')
          window.scrollTo(0, 0);
        } else {
          updateDate1();
          const response = await axiosinstance.current.put(
            `extapp/forms/update_doctor_feedback?resource_id=${rId}&action=1&ak_dc=${dId}&edit=1`,
            docFeedback1,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          if (response.status === 200) {
            setDisplayConfirmationModal(true);
            // window.location.href =
            //   process.env.REACT_APP_DOCTOR_URL + "patient-list";
          }
          return;
        }
      }
      if ((AnkleHL || MidfootHL) && (RightGAIT === "0" || LeftGAIT === "0")) {
        setErrmessage(true);
        window.scrollTo(0, 0);
        //alert('2')
        return;
      }


      // If none of the conditions matched, proceed with the update
    } catch (error) {
      // Handle errors here, e.g., show an error message to the user
      console.error("Error:", error);
    }
  };


  useEffect(() => {
    const updateDate2 = async () => {
      const response = await axiosinstance.current.put(
        `extapp/forms/update_doctor_feedback?resource_id=${rId}&action=0&ak_dc=${dId}`,
        docFeedback1,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
      }
    };
    if (chk) {
      if (doctorEnable !== "true") {
        updateDate2();
        updateDate1();
      }
    }
  }, [docFeedback1, json1]);

  const handleFlexionlR = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "LigamentousR",
        value: e,
      })
    );
    setLigamentousR(e);
  };
  const handleFlexionlL = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "LigamentousL",
        value: e,
      })
    );
    setLigamentousL(e);
  };
  const handleLigamentousRtext = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "LigamentousRtext",
        value: e,
      })
    );
    setLigamentousRtext(e);
  };
  const handleLigamentousLtext = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "LigamentousLtext",
        value: e,
      })
    );
    setLigamentousLtext(e);
  };

  const handleAlignmentR = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "AlignmentR",
        value: e,
      })
    );
    setAlignmentR(e);
  };
  const handleAlignmentL = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "AlignmentL",
        value: e,
      })
    );
    setAlignmentL(e);
  };
  const handleAlignmentradioL = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "AlignmentradioL",
        value: e,
      })
    );
    setAlignmentradioL(e);
  };
  const handleAlignmentdrop = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Alignmentdrop",
        value: e,
      })
    );
    setAlignmentdrop(e);
  };
  const handleAlignmen1L = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Alignmen1L",
        value: e,
      })
    );
    setAlignmen1L(e);
  };
  const handleAlignmen1drop = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Alignmen1drop",
        value: e,
      })
    );
    setAlignmen1drop(e);
  };
  const handlehandleAlignmeninputR = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "AlignmeninputR",
        value: e,
      })
    );
    setAlignmeninputR(e);
  };
  const handlehandleAlignmeninputIPJOINTR = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "AlignmeninputIPJOINTR",
        value: e,
      })
    );
    setAlignmeninputIPJOINTR(e);
  };
  const handlehandleAlignmeninputIPJOINTL = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "AlignmeninputIPJOINTL",
        value: e,
      })
    );
    setAlignmeninputIPJOINTL(e);
  };
  const handlehandleAlignmeninputL = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "AlignmeninputL",
        value: e,
      })
    );
    setAlignmeninputL(e);
  };
  const handleAlignmen2R = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Alignmen2R",
        value: e,
      })
    );
    setAlignmen2R(e);
  };
  const handleAlignmen2L = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Alignmen2L",
        value: e,
      })
    );
    setAlignmen2L(e);
  };
  const handleLesserR = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "LesserR",
        value: e,
      })
    );
    setLesserR(e);
  };
  const handleLesserR2 = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "LesserR2",
        value: e,
      })
    );
    setLesserR2(e);
  };
  const handleLesserL = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "LesserL",
        value: e,
      })
    );
    setLesserL(e);
  };
  const handleLesserL2 = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "LesserL2",
        value: e,
      })
    );
    setLesserL2(e);
  };
  const handleLesserradioL = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "LesserradioL",
        value: e,
      })
    );
    setLesserradioL(e);
  };
  const handleLesserradioL2 = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "LesserradioL2",
        value: e,
      })
    );
    setLesserradioL2(e);
  };
  const handleLesserrLdrop = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "LesserrLdrop",
        value: e,
      })
    );
    setLesserrLdrop(e);
  };
  const handleLesserrLdrop2 = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "LesserrLdrop2",
        value: e,
      })
    );
    setLesserrLdrop2(e);
  };
  const handleLesserradioR = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "LesserradioR",
        value: e,
      })
    );
    setLesserradioR(e);
  };
  const handleLesserradioR2 = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "LesserradioR2",
        value: e,
      })
    );
    setLesserradioR2(e);
  };
  const handleLesserrRdrop = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "LesserrRdrop",
        value: e,
      })
    );
    setLesserrRdrop(e);
  };
  const handleLesserrRdrop2 = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "LesserrRdrop2",
        value: e,
      })
    );
    setLesserrRdrop2(e);
  };
  const handleLesserrSpecifyR = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "LesserrSpecifyR",
        value: e,
      })
    );
    setLesserrSpecifyR(e);
  };
  const handleLesserrSpecifyL = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "LesserrSpecifyL",
        value: e,
      })
    );
    setLesserrSpecifyL(e);
  };
  const handleLesserrDegreeR = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "LesserrDegreeR",
        value: e,
      })
    );
    setLesserrDegreeR(e);
  };
  const handleLesserrDegreeL = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "LesserrDegreeL",
        value: e,
      })
    );
    setLesserrDegreeL(e);
  };
  const handleSpecifyLradio = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "SpecifyLradio",
        value: e,
      })
    );
    setSpecifyLradio(e);
  };
  const handleSpecifyLradio2 = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "SpecifyLradio2",
        value: e,
      })
    );
    setSpecifyLradio2(e);
  };
  const handleSpecifyRradio = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "SpecifyRradio",
        value: e,
      })
    );
    setSpecifyRradio(e);
  };
  const handleSpecifyRradio2 = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "SpecifyRradio2",
        value: e,
      })
    );
    setSpecifyRradio2(e);
  };

  const handleAnkleR = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "AnkleR",
        value: e,
      })
    );
    setAnkleR(e);
  };
  const handleAnkleL = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "AnkleL",
        value: e,
      })
    );
    setAnkleL(e);
  };
  const handleMotionR = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "MotionR",
        value: e,
      })
    );
    setMotionR(e);
  };
  const handleMotionRtext = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "MotionRtext",
        value: e,
      })
    );
    setMotionRtext(e);
  };
  const handleMotionLRtext = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "MotionLRtext",
        value: e,
      })
    );
    setMotionLRtext(e);
  };
  const handleMotionLLtext = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "MotionLLtext",
        value: e,
      })
    );
    setMotionLLtext(e);
  };
  const handleMotionL = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "MotionL",
        value: e,
      })
    );
    setMotionL(e);
  };
  const handleMotionLtext = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "MotionLtext",
        value: e,
      })
    );
    setMotionLtext(e);
  };
  const handleMotion3R = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Motion3R",
        value: e,
      })
    );
    setMotion3R(e);
  };
  const handleMotion3Rtext = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Motion3Rtext",
        value: e,
      })
    );
    setMotion3Rtext(e);
  };
  const handleMotion3RRtext = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Motion3RRtext",
        value: e,
      })
    );
    setMotion3RRtext(e);
  };
  const handleMotion3RLtext = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Motion3RLtext",
        value: e,
      })
    );
    setMotion3RLtext(e);
  };
  const handleMotion4R = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Motion4R",
        value: e,
      })
    );
    setMotion4R(e);
  };
  const handleMotion4Rtext = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Motion4Rtext",
        value: e,
      })
    );
    setMotion4Rtext(e);
  };
  const handleStabilityR = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "StabilityR",
        value: e,
      })
    );
    setStabilityR(e);
  };
  const handleStabilityL = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "StabilityL",
        value: e,
      })
    );
    setStabilityL(e);
  };
  const handleStability2R = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Stability2R",
        value: e,
      })
    );
    setStability2R(e);
  };
  const handleStability2L = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Stability2L",
        value: e,
      })
    );
    setStability2L(e);
  };
  const handleStability3R = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Stability3R",
        value: e,
      })
    );
    setStability3R(e);
  };
  const handleStability3L = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Stability3L",
        value: e,
      })
    );
    setStability3L(e);
  };
  const handleTalarR = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "TalarR",
        value: e,
      })
    );
    setTalarR(e);
  };
  const handleTalarL = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "TalarL",
        value: e,
      })
    );
    setTalarL(e);
  };
  const handleTalar2R = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Talar2R",
        value: e,
      })
    );
    setTalar2R(e);
  };
  const handleTalar2L = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Talar2L",
        value: e,
      })
    );
    setTalar2L(e);
  };
  const handleSyndesmosisR = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "SyndesmosisR",
        value: e,
      })
    );
    setSyndesmosisR(e);
  };
  const handleSyndesmosisL = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "SyndesmosisL",
        value: e,
      })
    );
    setSyndesmosisL(e);
  };
  const handleSyndesmosis1R = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Syndesmosis1R",
        value: e,
      })
    );
    setSyndesmosis1R(e);
  };
  const handleSyndesmosis1L = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Syndesmosis1L",
        value: e,
      })
    );
    setSyndesmosis1L(e);
  };
  const handleImpingementR = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "ImpingementR",
        value: e,
      })
    );
    setImpingementR(e);
  };
  const handleImpingementdropR = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "ImpingementdropR",
        value: e,
      })
    );
    setImpingementdropR(e);
  };
  const handleImpingementL = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "ImpingementL",
        value: e,
      })
    );
    setImpingementL(e);
  };
  const handleImpingementLdrop = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "ImpingementLdrop",
        value: e,
      })
    );
    setImpingementLdrop(e);
  };
  const handleImpingement1R = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Impingement1R",
        value: e,
      })
    );
    setImpingement1R(e);
  };
  const handleImpingement1L = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Impingement1L",
        value: e,
      })
    );
    setImpingement1L(e);
  };
  const handleImpingement3R = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Impingement3R",
        value: e,
      })
    );
    setImpingement3R(e);
  };
  const handleImpingement3L = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Impingement3L",
        value: e,
      })
    );
    setImpingement3L(e);
  };
  const handleImpingement4R = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Impingement4R",
        value: e,
      })
    );
    setImpingement4R(e);
  };
  const handleImpingement4L = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Impingement4L",
        value: e,
      })
    );
    setImpingement4L(e);
  };
  const handleAchillesR = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "AchillesR",
        value: e,
      })
    );
    setAchillesR(e);
  };
  const handleAchillesL = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "AchillesL",
        value: e,
      })
    );
    setAchillesL(e);
  };
  const handleAchilles2R = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Achilles2R",
        value: e,
      })
    );
    setAchilles2R(e);
  };
  const handleAchilles2L = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Achilles2L",
        value: e,
      })
    );
    setAchilles2L(e);
  };

  const handleAchilles4R = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Achilles4R",
        value: e,
      })
    );
    setAchilles4R(e);
  };
  const handleAchilles4L = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Achilles4L",
        value: e,
      })
    );
    setAchilles4L(e);
  };

  const handleAchilles6L = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Achilles6L",
        value: e,
      })
    );
    setAchilles6L(e);
  };
  const handleAchilles6R = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Achilles6R",
        value: e,
      })
    );
    setAchilles6R(e);
  };

  const handlePal1Ltext = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Pal1Ltext",
        value: e,
      })
    );
    setPal1Ltext(e);
  };
  const handlePal1Rtext = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Pal1Rtext",
        value: e,
      })
    );
    setPal1Rtext(e);
  };

  const handlePal1LtextL = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Pal1LtextL",
        value: e,
      })
    );
    setPal1LtextL(e);
  };
  const handlePal1RtextL = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Pal1RtextL",
        value: e,
      })
    );
    setPal1RtextL(e);
  };

  const handleDeformityR = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "DeformityR",
        value: e,
      })
    );
    setDeformityR(e);
  };
  const handleDeformityL = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "DeformityL",
        value: e,
      })
    );
    setDeformityL(e);
  };
  const handleDeformity2R = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Deformity2R",
        value: e,
      })
    );
    setDeformity2R(e);
  };
  const handleDeformity2L = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Deformity2L",
        value: e,
      })
    );
    setDeformity2L(e);
  };
  const handleDeformity4R = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Deformity4R",
        value: e,
      })
    );
    setDeformity4R(e);
  };
  const handleDeformity4L = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Deformity4L",
        value: e,
      })
    );
    setDeformity4L(e);
  };
  const handleDeformity6R = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Deformity6R",
        value: e,
      })
    );
    setDeformity6R(e);
  };
  const handleDeformity6L = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Deformity6L",
        value: e,
      })
    );
    setDeformity6L(e);
  };
  const handleDeformity8R = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Deformity8R",
        value: e,
      })
    );
    setDeformity8R(e);
  };
  const handleDeformity8L = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Deformity8L",
        value: e,
      })
    );
    setDeformity8L(e);
  };
  const handleMidfootL = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "MidfootL",
        value: e,
      })
    );
    setMidfootL(e);
  };
  const handleMidfootR = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "MidfootR",
        value: e,
      })
    );
    setMidfootR(e);
  };

  const handleEvaluatedR = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "EvaluatedR",
        value: e,
      })
    );
    setEvaluatedR(e);
  };
  const handleEvaluatedL = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "EvaluatedL",
        value: e,
      })
    );
    setEvaluatedL(e);
  };

  const handleRightForefoot = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "RightForefoot",
        value: e,
      })
    );
    setRightForefoot(e);
  };
  const handleLeftForefoot = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "LeftForefoot",
        value: e,
      })
    );
    setLeftForefoot(e);
  };
  const handleRDEFORMITY = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "RDEFORMITY",
        value: e,
      })
    );
    setRDEFORMITY(e);
  };
  const handleLDEFORMITY = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "LDEFORMITY",
        value: e,
      })
    );
    setLDEFORMITY(e);
  };
  const handleChange = useCallback((inputValue) => {
    setValue(inputValue);
    dispatch(
      updateFootFeedbackfootankleField({
        key: "value",
        value: inputValue,
      })
    );

    // seterrorSurgeon(false);
  }, []);
  const handleCreate = useCallback(
    (inputValue) => {
      const newValue = { value: inputValue.toLowerCase(), label: inputValue };
      setOptions([...options, newValue]);
      setValue(newValue);
    },
    [options]
  );

  const handleChange2 = useCallback((inputValue) => {
    setValue2(inputValue);
    dispatch(
      updateFootFeedbackfootankleField({
        key: "value2",
        value: inputValue,
      })
    );
    // seterrorSurgeon(false);
  }, []);
  const handleCreate2 = useCallback(
    (inputValue) => {
      const newValue = { value: inputValue.toLowerCase(), label: inputValue };
      setOptions([...options, newValue]);
      setValue2(newValue);
    },
    [options]
  );

  let x = value.filter((i) => i.value === "11");
  let y = value2.filter((i) => i.value === "11");

  const handleRightGAIT = (e) => {
    setRightGAIT(e);
    dispatch(
      updateFootFeedbackfootankleField({
        key: "RightGAIT",
        value: e,
      })
    );
  };

  const handleLeftGAIT = (e) => {
    setLeftGAIT(e);
    dispatch(
      updateFootFeedbackfootankleField({
        key: "LeftGAIT",
        value: e,
      })
    );
  };
  const handleRightABNORMAL = (e) => {
    setRightABNORMAL(e);
    dispatch(
      updateFootFeedbackfootankleField({
        key: "RightABNORMAL",
        value: e,
      })
    );
  };
  const handleLeftABNORMAL = (e) => {
    setLeftABNORMAL(e);
    dispatch(
      updateFootFeedbackfootankleField({
        key: "LeftABNORMAL",
        value: e,
      })
    );
  };
  const handleRABNORMALF = (e) => {
    setRABNORMALF(e);
    dispatch(
      updateFootFeedbackfootankleField({
        key: "RABNORMALF",
        value: e,
      })
    );
  };
  const handleLABNORMALF = (e) => {
    setLABNORMALF(e);
    dispatch(
      updateFootFeedbackfootankleField({
        key: "LABNORMALF",
        value: e,
      })
    );
  };
  const handleRightALIGNMENT = (e) => {
    setRightALIGNMENT(e);
    dispatch(
      updateFootFeedbackfootankleField({
        key: "RightALIGNMENT",
        value: e,
      })
    );
  };
  const handleLeftALIGNMENT = (e) => {
    setLeftALIGNMENT(e);
    dispatch(
      updateFootFeedbackfootankleField({
        key: "LeftALIGNMENT",
        value: e,
      })
    );
  };
  const ir4ChangeR = (e) => {
    setIr4Right(e);
    dispatch(
      updateFootFeedbackfootankleField({
        key: "ir4Right",
        value: e,
      })
    );
  };
  const ir4ChangeL = (e) => {
    setIr4Left(e);
    dispatch(
      updateFootFeedbackfootankleField({
        key: "ir4Left",
        value: e,
      })
    );
  };
  const handleContraR = (e) => {
    setContraR(e);
    dispatch(
      updateFootFeedbackfootankleField({
        key: "ContraR",
        value: e,
      })
    );
  };
  const handleContraL = (e) => {
    setContraL(e);
    dispatch(
      updateFootFeedbackfootankleField({
        key: "ContraL",
        value: e,
      })
    );
  };
  const handleSpecifyR = (e) => {
    setSpecifyR(e);
    dispatch(
      updateFootFeedbackfootankleField({
        key: "SpecifyR",
        value: e,
      })
    );
  };
  const handleSpecifyL = (e) => {
    setSpecifyL(e);
    dispatch(
      updateFootFeedbackfootankleField({
        key: "SpecifyL",
        value: e,
      })
    );
  };
  const handleCallusR = (e) => {
    setCallusR(e);
    dispatch(
      updateFootFeedbackfootankleField({
        key: "CallusR",
        value: e,
      })
    );
  };
  const handleCallusL = (e) => {
    setCallusL(e);
    dispatch(
      updateFootFeedbackfootankleField({
        key: "CallusL",
        value: e,
      })
    );
  };
  const footPositionchangeR = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "FootR",
        value: e,
      })
    );
    setFootR(e);
  };
  const footPositionchangeL = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "FootL",
        value: e,
      })
    );
    setFootL(e);
  };
  const footCallusonchangeR = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "footcallusR",
        value: e,
      })
    );
    setfootcallusR(e);
  };
  const footCallusonchangeL = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "footcallusL",
        value: e,
      })
    );
    setfootcallusL(e);
  };

  const handlerAnkleHR = (e) => {
    setShow(false);
    setAnkleHR(e);
    dispatch(
      updateFootFeedbackfootankleField({
        key: "AnkleHR",
        value: e,
      })
    );
  };

  const handlerAnkleHL = (e) => {
    setActiveKeyankle("9");
    setActiveKey("1");
    setShow(false);

    setAnkleHL(e);
    dispatch(
      updateFootFeedbackfootankleField({
        key: "AnkleHL",
        value: e,
      })
    );
  };
  const handlerMidfootHR = (e) => {
    setMidfootHR(e);
    setShow(false);

    dispatch(
      updateFootFeedbackfootankleField({
        key: "MidfootHR",
        value: e,
      })
    );
  };
  const handlerMidfootHL = (e) => {
    setActiveKey("1");
    setactiveKeyMid("10");
    setMidfootHL(e);
    setShow(false);

    dispatch(
      updateFootFeedbackfootankleField({
        key: "MidfootHL",
        value: e,
      })
    );
  };
  const handlerForefootR = (e) => {
    setForefootR(e);
    dispatch(
      updateFootFeedbackfootankleField({
        key: "ForefootR",
        value: e,
      })
    );
  };
  const handlerForefootL = (e) => {
    setForefootL(e);
    dispatch(
      updateFootFeedbackfootankleField({
        key: "ForefootL",
        value: e,
      })
    );
  };

  const handleLesserL23rd = (e) => {
    setLesserL23rd(e);
    dispatch(
      updateFootFeedbackfootankleField({
        key: "LesserL23rd",
        value: e,
      })
    );
  };
  const handleLesserR23rd = (e) => {
    setLesserR23rd(e);
    dispatch(
      updateFootFeedbackfootankleField({
        key: "LesserR23rd",
        value: e,
      })
    );
  };
  const handleLesserradioL23rd = (e) => {
    setLesserradioL23rd(e);
    dispatch(
      updateFootFeedbackfootankleField({
        key: "LesserradioL23rd",
        value: e,
      })
    );
  };
  const handleLesserrLdrop23rd = (e) => {
    setLesserrLdrop23rd(e);
    dispatch(
      updateFootFeedbackfootankleField({
        key: "LesserrLdrop23rd",
        value: e,
      })
    );
  };
  const handleLesserradioR23rd = (e) => {
    setLesserradioR23rd(e);
    dispatch(
      updateFootFeedbackfootankleField({
        key: "LesserradioR23rd",
        value: e,
      })
    );
  };
  const handleLesserrRdrop23rd = (e) => {
    setLesserrRdrop23rd(e);
    dispatch(
      updateFootFeedbackfootankleField({
        key: "LesserrRdrop23rd",
        value: e,
      })
    );
  };
  const handleLesserrDegreeL3rd = (e) => {
    setLesserrDegreeL3rd(e);
    dispatch(
      updateFootFeedbackfootankleField({
        key: "LesserrDegreeL3rd",
        value: e,
      })
    );
  };
  const handleLesserrDegreeR3rd = (e) => {
    setLesserrDegreeR3rd(e);
    dispatch(
      updateFootFeedbackfootankleField({
        key: "LesserrDegreeR3rd",
        value: e,
      })
    );
  };
  const handleSpecifyLradio23rd = (e) => {
    setSpecifyLradio23rd(e);
    dispatch(
      updateFootFeedbackfootankleField({
        key: "SpecifyLradio23rd",
        value: e,
      })
    );
  };
  const handleSpecifyRradio23rd = (e) => {
    setSpecifyRradio23rd(e);
    dispatch(
      updateFootFeedbackfootankleField({
        key: "SpecifyRradio23rd",
        value: e,
      })
    );
  };

  const handleLesserL23rd2 = (e) => {
    setLesserL23rd2(e);
    dispatch(
      updateFootFeedbackfootankleField({
        key: "LesserL23rd2",
        value: e,
      })
    );
  };
  const handleLesserR23rd2 = (e) => {
    setLesserR23rd2(e);
    dispatch(
      updateFootFeedbackfootankleField({
        key: "LesserR23rd2",
        value: e,
      })
    );
  };
  const handleLesserradioL23rd2 = (e) => {
    setLesserradioL23rd2(e);
    dispatch(
      updateFootFeedbackfootankleField({
        key: "LesserradioL23rd2",
        value: e,
      })
    );
  };
  const handleLesserrLdrop23rd2 = (e) => {
    setLesserrLdrop23rd2(e);
    dispatch(
      updateFootFeedbackfootankleField({
        key: "LesserrLdrop23rd2",
        value: e,
      })
    );
  };
  const handleLesserradioR23rd2 = (e) => {
    setLesserradioR23rd2(e);
    dispatch(
      updateFootFeedbackfootankleField({
        key: "LesserradioR23rd2",
        value: e,
      })
    );
  };
  const handleLesserrRdrop23rd2 = (e) => {
    setLesserrRdrop23rd2(e);
    dispatch(
      updateFootFeedbackfootankleField({
        key: "LesserrRdrop23rd2",
        value: e,
      })
    );
  };
  const handleLesserrDegreeL3rd2 = (e) => {
    setLesserrDegreeL3rd2(e);
    dispatch(
      updateFootFeedbackfootankleField({
        key: "LesserrDegreeL3rd2",
        value: e,
      })
    );
  };
  const handleLesserrDegreeR3rd2 = (e) => {
    setLesserrDegreeR3rd2(e);
    dispatch(
      updateFootFeedbackfootankleField({
        key: "LesserrDegreeR3rd2",
        value: e,
      })
    );
  };
  const handleSpecifyLradio23rd2 = (e) => {
    setSpecifyLradio23rd2(e);
    dispatch(
      updateFootFeedbackfootankleField({
        key: "SpecifyLradio23rd2",
        value: e,
      })
    );
  };
  const handleSpecifyRradio23rd2 = (e) => {
    setSpecifyRradio23rd2(e);
    dispatch(
      updateFootFeedbackfootankleField({
        key: "SpecifyRradio23rd2",
        value: e,
      })
    );
  };

  const handleLesserL24th = (e) => {
    setLesserL24th(e);
    dispatch(
      updateFootFeedbackfootankleField({
        key: "LesserL24th",
        value: e,
      })
    );
  };
  const handleLesserR24th = (e) => {
    setLesserR24th(e);
    dispatch(
      updateFootFeedbackfootankleField({
        key: "LesserR24th",
        value: e,
      })
    );
  };
  const handleLesserradioL24th = (e) => {
    setLesserradioL24th(e);
    dispatch(
      updateFootFeedbackfootankleField({
        key: "LesserradioL24th",
        value: e,
      })
    );
  };
  const handleLesserrLdrop24th = (e) => {
    setLesserrLdrop24th(e);
    dispatch(
      updateFootFeedbackfootankleField({
        key: "LesserrLdrop24th",
        value: e,
      })
    );
  };
  const handleLesserradioR24th = (e) => {
    setLesserradioR24th(e);
    dispatch(
      updateFootFeedbackfootankleField({
        key: "LesserradioR24th",
        value: e,
      })
    );
  };
  const handleLesserrRdrop24th = (e) => {
    setLesserrRdrop24th(e);
    dispatch(
      updateFootFeedbackfootankleField({
        key: "LesserrRdrop24th",
        value: e,
      })
    );
  };
  const handleLesserrDegreeL4th = (e) => {
    setLesserrDegreeL4th(e);
    dispatch(
      updateFootFeedbackfootankleField({
        key: "LesserrDegreeL4th",
        value: e,
      })
    );
  };
  const handleLesserrDegreeR4th = (e) => {
    setLesserrDegreeR4th(e);
    dispatch(
      updateFootFeedbackfootankleField({
        key: "LesserrDegreeR4th",
        value: e,
      })
    );
  };
  const handleSpecifyLradio24th = (e) => {
    setSpecifyLradio24th(e);
    dispatch(
      updateFootFeedbackfootankleField({
        key: "SpecifyLradio24th",
        value: e,
      })
    );
  };
  const handleSpecifyRradio24th = (e) => {
    setSpecifyRradio24th(e);
    dispatch(
      updateFootFeedbackfootankleField({
        key: "SpecifyRradio24th",
        value: e,
      })
    );
  };

  const handleLesserL24th2 = (e) => {
    setLesserL24th2(e);
    dispatch(
      updateFootFeedbackfootankleField({
        key: "LesserL24th2",
        value: e,
      })
    );
  };
  const handleLesserR24th2 = (e) => {
    setLesserR24th2(e);
    dispatch(
      updateFootFeedbackfootankleField({
        key: "LesserR24th2",
        value: e,
      })
    );
  };
  const handleLesserradioL24th2 = (e) => {
    setLesserradioL24th2(e);
    dispatch(
      updateFootFeedbackfootankleField({
        key: "LesserradioL24th2",
        value: e,
      })
    );
  };
  const handleLesserrLdrop24th2 = (e) => {
    setLesserrLdrop24th2(e);
    dispatch(
      updateFootFeedbackfootankleField({
        key: "LesserrLdrop24th2",
        value: e,
      })
    );
  };
  const handleLesserradioR24th2 = (e) => {
    setLesserradioR24th2(e);
    dispatch(
      updateFootFeedbackfootankleField({
        key: "LesserradioR24th2",
        value: e,
      })
    );
  };
  const handleLesserrRdrop24th2 = (e) => {
    setLesserrRdrop24th2(e);
    dispatch(
      updateFootFeedbackfootankleField({
        key: "LesserrRdrop24th2",
        value: e,
      })
    );
  };
  const handleLesserrDegreeL4th2 = (e) => {
    setLesserrDegreeL4th2(e);
    dispatch(
      updateFootFeedbackfootankleField({
        key: "LesserrDegreeL4th2",
        value: e,
      })
    );
  };
  const handleLesserrDegreeR4th2 = (e) => {
    setLesserrDegreeR4th2(e);
    dispatch(
      updateFootFeedbackfootankleField({
        key: "LesserrDegreeR4th2",
        value: e,
      })
    );
  };
  const handleSpecifyLradio24th2 = (e) => {
    setSpecifyLradio24th2(e);
    dispatch(
      updateFootFeedbackfootankleField({
        key: "SpecifyLradio24th2",
        value: e,
      })
    );
  };
  const handleSpecifyRradio24th2 = (e) => {
    setSpecifyRradio24th2(e);
    dispatch(
      updateFootFeedbackfootankleField({
        key: "SpecifyRradio24th2",
        value: e,
      })
    );
  };

  const handleLesserL25th = (e) => {
    setLesserL25th(e);
    dispatch(
      updateFootFeedbackfootankleField({
        key: "LesserL25th",
        value: e,
      })
    );
  };
  const handleLesserR25th = (e) => {
    setLesserR25th(e);
    dispatch(
      updateFootFeedbackfootankleField({
        key: "LesserR25th",
        value: e,
      })
    );
  };
  const handleLesserradioL25th = (e) => {
    setLesserradioL25th(e);
    dispatch(
      updateFootFeedbackfootankleField({
        key: "LesserradioL25th",
        value: e,
      })
    );
  };
  const handleLesserrLdrop25th = (e) => {
    setLesserrLdrop25th(e);
    dispatch(
      updateFootFeedbackfootankleField({
        key: "LesserrLdrop25th",
        value: e,
      })
    );
  };
  const handleLesserradioR25th = (e) => {
    setLesserradioR25th(e);
    dispatch(
      updateFootFeedbackfootankleField({
        key: "LesserradioR25th",
        value: e,
      })
    );
  };
  const handleLesserrRdrop25th = (e) => {
    setLesserrRdrop25th(e);
    dispatch(
      updateFootFeedbackfootankleField({
        key: "LesserrRdrop25th",
        value: e,
      })
    );
  };
  const handleLesserrDegreeL5th = (e) => {
    setLesserrDegreeL5th(e);
    dispatch(
      updateFootFeedbackfootankleField({
        key: "LesserrDegreeL5th",
        value: e,
      })
    );
  };
  const handleLesserrDegreeR5th = (e) => {
    setLesserrDegreeR5th(e);
    dispatch(
      updateFootFeedbackfootankleField({
        key: "LesserrDegreeR5th",
        value: e,
      })
    );
  };
  const handleSpecifyLradio25th = (e) => {
    setSpecifyLradio25th(e);
    dispatch(
      updateFootFeedbackfootankleField({
        key: "SpecifyLradio25th",
        value: e,
      })
    );
  };
  const handleSpecifyRradio25th = (e) => {
    setSpecifyRradio25th(e);
    dispatch(
      updateFootFeedbackfootankleField({
        key: "SpecifyRradio25th",
        value: e,
      })
    );
  };

  const handlerToe2nd = (e) => {
    setactiveKeytoe1("3");
    setToe2nd(e);
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Toe2nd",
        value: e,
      })
    );
  };
  const handlerToe3rd = (e) => {
    setactiveKeytoe2("4");
    setToe3rd(e);
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Toe3rd",
        value: e,
      })
    );
  };
  const handlerToe4th = (e) => {
    setactiveKeytoe3("5");
    setToe4th(e);
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Toe4th",
        value: e,
      })
    );
  };
  const handlerToe5th = (e) => {
    setactiveKeytoe4("6");
    setToe5th(e);
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Toe5th",
        value: e,
      })
    );
  };
  const handlerHallux = (e) => {
    setHallux(e);
    setActiveKeyhalax("2");
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Hallux",
        value: e,
      })
    );
  };

  const handleMotion2Rtext = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Motion2Rtext",
        value: e,
      })
    );
    setMotionRtext(e);
  };
  const handleMotion2Ltext = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Motion2Ltext",
        value: e,
      })
    );
    setMotionRtext(e);
  };
  const handleMotion2LRtext = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Motion2LRtext",
        value: e,
      })
    );
    setMotionRtext(e);
  };
  const handleMotion2LLtext = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Motion2LLtext",
        value: e,
      })
    );
    setMotionRtext(e);
  };
  const handleMotion23Rtext = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Motion23Rtext",
        value: e,
      })
    );
    setMotion23Rtext(e);
  };
  const handleMotion24Rtext = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Motion24Rtext",
        value: e,
      })
    );
    setMotion24Rtext(e);
  };
  const handleMotion23RRtext = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Motion23RRtext",
        value: e,
      })
    );
    setMotionRtext(e);
  };
  const handleMotion23RLtext = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Motion23RLtext",
        value: e,
      })
    );
    setMotionRtext(e);
  };

  //2nd toe
  const handleMotion2ndRtext = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Motion2ndRtext",
        value: e,
      })
    );
    setMotionRtext(e);
  };
  const handleMotion2ndLtext = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Motion2ndLtext",
        value: e,
      })
    );
    setMotionRtext(e);
  };

  const handleMotion2ndLRtext = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Motion2ndLRtext",
        value: e,
      })
    );
    setMotionRtext(e);
  };
  const handleMotion2ndLLtext = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Motion2ndLLtext",
        value: e,
      })
    );
    setMotionRtext(e);
  };
  const handleMotion2nRtext = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Motion2nRtext",
        value: e,
      })
    );
    setMotionRtext(e);
  };
  const handleMotion2nlRtext = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Motion2nlRtext",
        value: e,
      })
    );
    setMotionRtext(e);
  };
  const handleMotion2ndRRtext = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Motion2ndRRtext",
        value: e,
      })
    );
    setMotionRtext(e);
  };
  const handleMotion2ndRLtext = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Motion2ndRLtext",
        value: e,
      })
    );
    setMotionRtext(e);
  };

  //3rd toe
  const handleMotion3ndRtext = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Motion3ndRtext",
        value: e,
      })
    );
    setMotionRtext(e);
  };
  const handleMotion3ndLtext = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Motion3ndLtext",
        value: e,
      })
    );
    setMotionRtext(e);
  };
  const handleMotion3ndLRtext = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Motion3ndLRtext",
        value: e,
      })
    );
    setMotionRtext(e);
  };
  const handleMotion3ndLLtext = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Motion3ndLLtext",
        value: e,
      })
    );
    setMotionRtext(e);
  };
  const handleMotion3nRtext = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Motion3nRtext",
        value: e,
      })
    );
    setMotionRtext(e);
  };
  const handleMotion3nlRtext = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Motion3nlRtext",
        value: e,
      })
    );
    setMotionRtext(e);
  };
  const handleMotion3ndRRtext = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Motion3ndRRtext",
        value: e,
      })
    );
    setMotionRtext(e);
  };
  const handleMotion3ndRLtext = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Motion3ndRLtext",
        value: e,
      })
    );
    setMotionRtext(e);
  };

  //4th toe

  const handleMotion4ndRtext = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Motion4ndRtext",
        value: e,
      })
    );
    setMotionRtext(e);
  };

  const handleMotion4ndLtext = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Motion4ndLtext",
        value: e,
      })
    );
    setMotionRtext(e);
  };
  const handleMotion4ndLRtext = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Motion4ndLRtext",
        value: e,
      })
    );
    setMotionRtext(e);
  };
  const handleMotion4ndLLtext = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Motion4ndLLtext",
        value: e,
      })
    );
    setMotionRtext(e);
  };
  const handleMotion4nRtext = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Motion4nRtext",
        value: e,
      })
    );
    setMotionRtext(e);
  };
  const handleMotion4nlRtext = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Motion4nlRtext",
        value: e,
      })
    );
    setMotionRtext(e);
  };
  const handleMotion4ndRRtext = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Motion4ndRRtext",
        value: e,
      })
    );
    setMotionRtext(e);
  };
  const handleMotion4ndRLtext = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Motion4ndRLtext",
        value: e,
      })
    );
    setMotionRtext(e);
  };

  //5th toe
  const handleMotion5ndRtext = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Motion5ndRtext",
        value: e,
      })
    );
    setMotionRtext(e);
  };
  const handleMotion5ndLtext = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Motion5ndLtext",
        value: e,
      })
    );
    setMotionRtext(e);
  };
  const handleMotion5ndLRtext = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Motion5ndLRtext",
        value: e,
      })
    );
    setMotionRtext(e);
  };
  const handleMotion5ndLLtext = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Motion5ndLLtext",
        value: e,
      })
    );
    setMotionRtext(e);
  };
  const handleMotion5nRtext = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Motion5nRtext",
        value: e,
      })
    );
    setMotionRtext(e);
  };
  const handleMotion5nlRtext = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Motion5nlRtext",
        value: e,
      })
    );
    setMotionRtext(e);
  };
  const handleMotion5ndRRtext = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Motion5ndRRtext",
        value: e,
      })
    );
    setMotionRtext(e);
  };
  const handleMotion5ndRLtext = (e) => {
    dispatch(
      updateFootFeedbackfootankleField({
        key: "Motion5ndRLtext",
        value: e,
      })
    );
    setMotionRtext(e);
  };

  // const [msg, setMsg] = useState("");
  // const [msgType, setmsgType] = useState("");
  // ---
  const [getId, setGetId] = useState("");
  useEffect(() => {
    // Get the Gait section element by its ID
    const gaitSection = document.getElementById(getId);
    console.log(gaitSection, "gaitSection");
    // Scroll to the Gait section
    if (gaitSection) {
      const yOffset = gaitSection.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: yOffset - window.innerHeight / 7,
        behavior: "smooth",
      });
    }
    console.log(getId, "getId");
  }, [getId]);
  return (
    <>
    {isLoading ? (
      <LoadingSpinner/>
    ):
    (

      <>

      <div className="patient_assessment_form mb_100 bg_white pd_10 pt_50 content_fx_w">
        <div class="d-flex justify-content-end">
          <DoctorNameCustom />
        </div>
        <div className="patient_assessment_form mb_100 bg_white pd_10 pt_50">
          {doctorEnable === "true" && <FootAnkleTab rId={rId} />}
          <CustomToaster msg={msg} msgType={msgType} />
        </div>
        <div className="row">
          <div className="col-sm-2 col-md-2">
            <div
              className="sticky "
              style={{
                position: "sticky",
                top: "75px",
              }}
            >
              <Footformvertical1 setGetId={setGetId} />
            </div>
          </div>
          <div
            className={`accordion_tab_content ${assessmentStyles.accordion_tab_content}`}
          >
            <div className="col-sm-12 col-md-12">
              <div className="patient_assessment_form mb_100 bg_white pd_10 pt_50">
                {/* <AssessmentHeader /> */}

                <HeaderInnerBreadcrumb />

                <div className="input_tab_box mb_30">
                  <div className="row">
                    <h4 className="range_sl_title">{translation.Evaluated}</h4>
                    <div className="col-md-6 col-sm-6">
                      <Checkbox
                        label="Ankle/Hindfoot"
                        onChange={handlerAnkleHL}
                        selected={AnkleHL}
                        styleClass="custom_check_box tick f_400 large mb_10"
                        id="AnkleHL"
                      />
                      <Checkbox
                        label="Midfoot"
                        onChange={handlerMidfootHL}
                        selected={MidfootHL}
                        styleClass="custom_check_box tick f_400 large mb_10"
                        id="MidfootHL"
                      />
                      {/* <Checkbox
                        label="Forefoot"
                        onChange={handlerForefootL}
                        selected={ForefootL}
                        styleClass="custom_check_box tick f_400 large mb_10"
                        id="ForefootL"
                      /> */}
                      <Checkbox
                        label="Hallux"
                        onChange={handlerHallux}
                        selected={Hallux}
                        styleClass="custom_check_box tick f_400 large mb_10"
                        id="Hallux"
                      />
                    </div>
                    <div className="col-md-6 col-sm-6">
                      <Checkbox
                        label="2nd toe"
                        onChange={handlerToe2nd}
                        selected={Toe2nd}
                        styleClass="custom_check_box tick f_400 large mb_10"
                        id="Toe2nd"
                      />
                      <Checkbox
                        label="3rd toe"
                        onChange={handlerToe3rd}
                        selected={Toe3rd}
                        styleClass="custom_check_box tick f_400 large mb_10"
                        id="Toe3rd"
                      />
                      <Checkbox
                        label="4th toe"
                        onChange={handlerToe4th}
                        selected={Toe4th}
                        styleClass="custom_check_box tick f_400 large mb_10"
                        id="Toe4th"
                      />
                      <Checkbox
                        label="5th toe"
                        onChange={handlerToe5th}
                        selected={Toe5th}
                        styleClass="custom_check_box tick f_400 large mb_10"
                        id="Toe5th"
                      />
                    </div>
                  </div>
                  {show ? (
                    <span className="text-danger">
                      *Choose any one Filed from Ankle/Hindfoot, Midfoot
                    </span>
                  ) : null}
                </div>

                <div
                  id="Gait"
                  className={`accordion_tabpanel ${assessmentStyles.accordion_tabpanel}`}
                >
                  <Accordion
                    activeKey={activeKey}
                    onSelect={handleAccordionToggle}
                  >
                    <Accordion.Item eKey="1" defaultActiveKey="1" eventKey="1">
                      <Accordion.Header>Gait</Accordion.Header>
                      <Accordion.Body>
                        <div className="mb_30" id="Gait">
                          <div className="row">
                            {/* <h4 className="range_sl_title">
                              {translation.Gait_abnormality}
                            </h4> */}

                            <div className="col-md-6 col-sm-6">
                              <FullDropdown
                                data={[
                                  { value: "1", label: "None" },
                                  { value: "2", label: "Slight" },
                                  { value: "3", label: "Obvious" },
                                  { value: "4", label: "Marked" },
                                ]}
                                label={
                                  "Gait abnormality (Right)" +
                                  // translation.Foot_R +
                                  (AnkleHL || MidfootHL ? "*" : "")
                                }
                                styleClass="mxw_100"
                                value={RightGAIT}
                                // placeholder=""
                                onChange={handleRightGAIT}
                              />
                              {RightGAIT === "0" && (
                                <>
                                  {errmessage && (
                                    <span className="text-danger">
                                      This field is required
                                    </span>
                                  )}
                                </>
                              )}

                              {RightGAIT !== "1" && (
                                <>
                                  <div className="row">
                                    <h4 className="range_sl_title">
                                      {translation.ABNORMAL_GAIT}
                                    </h4>

                                    <FullDropdown
                                      data={[
                                        { value: "1", label: "Antalgic" },
                                        { value: "2", label: "Ataxic" },
                                        {
                                          value: "3",
                                          label: "Equinus / Tiptoe",
                                        },
                                        { value: "4", label: "Equinovarus" },
                                        {
                                          value: "5",
                                          label: "Hemiplegic / Circumductory",
                                        },
                                        {
                                          value: "6",
                                          label:
                                            "Rocking horse / Gluteus maximus",
                                        },
                                        { value: "7", label: "Quadriceps" },
                                        { value: "8", label: "Scissoring" },
                                        {
                                          value: "9",
                                          label: "Short leg /Equinus",
                                        },
                                        { value: "10", label: "Steppage" },
                                        {
                                          value: "11",
                                          label: "Trendelenburg / Lurching",
                                        },
                                        { value: "12", label: "Waddling" },
                                        { value: "13", label: "Others " },
                                      ]}
                                      // label={
                                      //   translation.Foot_R +
                                      //   (AnkleHL || MidfootHL ? "*" : "")
                                      // }
                                      label={translation.Foot_R}
                                      styleClass="mxw_100"
                                      value={RightABNORMAL}
                                      placeholder="None"
                                      onChange={handleRightABNORMAL}
                                    />
                                    {/* {RightABNORMAL === "0" && (
                                      <>
                                        {errmessage && (
                                          <span className="text-danger">
                                            This field is required
                                          </span>
                                        )}
                                      </>
                                    )} */}
                                    {RightABNORMAL === "13" && (
                                      <div className="col-md-6 col-sm-6">
                                        <InputField
                                          label=""
                                          value={RABNORMALF}
                                          type="text"
                                          placeholder="Other Field"
                                          styleClass="mxw_100"
                                          onChange={handleRABNORMALF}
                                        />
                                      </div>
                                    )}
                                  </div>
                                </>
                              )}
                            </div>

                            <div className="col-md-6 col-sm-6">
                              <FullDropdown
                                data={[
                                  { value: "1", label: "None" },
                                  { value: "2", label: "Slight" },
                                  { value: "3", label: "Obvious" },
                                  { value: "4", label: "Marked" },
                                ]}
                                label={
                                  "Gait abnormality (Left)" +
                                  (AnkleHL || MidfootHL ? "*" : "")
                                }
                                styleClass="mxw_100"
                                value={LeftGAIT}
                                // placeholder="None"
                                onChange={handleLeftGAIT}
                              />
                              {LeftGAIT === "0" && (
                                <>
                                  {errmessage && (
                                    <span className="text-danger">
                                      This field is required
                                    </span>
                                  )}
                                </>
                              )}
                              {LeftGAIT !== "1" && (
                                <>
                                  <div className="row">
                                    <h4 className="range_sl_title">
                                      {translation.ABNORMAL_GAIT}
                                    </h4>

                                    <FullDropdown
                                      data={[
                                        { value: "1", label: "Antalgic" },
                                        { value: "2", label: "Ataxic" },
                                        {
                                          value: "3",
                                          label: "Equinus / Tiptoe",
                                        },
                                        { value: "4", label: "Equinovarus" },
                                        {
                                          value: "5",
                                          label: "Hemiplegic / Circumductory",
                                        },
                                        {
                                          value: "6",
                                          label:
                                            "Rocking horse / Gluteus maximus",
                                        },
                                        { value: "7", label: "Quadriceps" },
                                        { value: "8", label: "Scissoring" },
                                        {
                                          value: "9",
                                          label: "Short leg /Equinus",
                                        },
                                        { value: "10", label: "Steppage" },
                                        {
                                          value: "11",
                                          label: "Trendelenburg / Lurching",
                                        },
                                        { value: "12", label: "Waddling" },
                                        { value: "13", label: "Others " },
                                      ]}
                                      // label={
                                      //   translation.Foot_L +
                                      //   (AnkleHL || MidfootHL ? "*" : "")
                                      // }
                                      label={translation.Foot_L}
                                      styleClass="mxw_100"
                                      value={LeftABNORMAL}
                                      placeholder="None"
                                      onChange={handleLeftABNORMAL}
                                    />
                                    {/* {LeftABNORMAL === "0" && (
                                      <>
                                        {errmessage && (
                                          <span className="text-danger">
                                            This field is required
                                          </span>
                                        )}
                                      </>
                                    )} */}

                                    {LeftABNORMAL === "13" && (
                                      <div className="col-md-6 col-sm-6">
                                        <InputField
                                          label=""
                                          value={LABNORMALF}
                                          type="text"
                                          placeholder="Other Field"
                                          styleClass="mxw_100"
                                          onChange={handleLABNORMALF}
                                        />
                                      </div>
                                    )}
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
                <div
                  id="Contractures"
                  className={`accordion_tabpanel ${assessmentStyles.accordion_tabpanel}`}
                >
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eKey="0">
                      <Accordion.Header>Contractures</Accordion.Header>
                      <Accordion.Body>
                        <div className="row mb_30 " id="Contractures">
                          {/* <h4 className="range_sl_title">Contractures</h4> */}
                          <div className="col-md-6 col-sm-6">
                            <RadioSwitch
                              labelLeft="Absent"
                              labelRight="Present"
                              idLeft="contraL"
                              idRight="contraR"
                              name="ContractureR"
                              title="Right foot and ankle"
                              styleClass=""
                              value={contraR}
                              onChange={handleContraR}
                              placeholder="select"
                            />
                            {contraR === "1" && (
                              <InputField
                                label=""
                                value={specifyR}
                                type="text"
                                placeholder="Specify:"
                                styleClass="mxw_100"
                                onChange={handleSpecifyR}
                              />
                            )}
                          </div>
                          <div className="col-md-6 col-sm-6">
                            <RadioSwitch
                              labelLeft="Absent"
                              labelRight="Present"
                              idLeft="contrarL"
                              idRight="contrarR"
                              name="ContractureL"
                              title="Left foot and ankle"
                              styleClass=""
                              value={contraL}
                              onChange={handleContraL}
                              placeholder="select"
                            />
                            {contraL === "1" && (
                              <InputField
                                label=""
                                value={specifyL}
                                type="text"
                                placeholder="Specify:"
                                styleClass="mxw_100"
                                onChange={handleSpecifyL}
                              />
                            )}
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>

                <div
                  id="Ankle"
                  className={`accordion_tabpanel ${assessmentStyles.accordion_tabpanel}`}
                >
                  <Accordion
                    activeKey={activeKeyankle}
                    onSelect={handleActiveKeyankle}
                  >
                    <Accordion.Item eKey="9" defaultActiveKey="9" eventKey="9">
                      <Accordion.Header>Ankle/Hindfoot</Accordion.Header>
                      <Accordion.Body>
                        <div className=" mb_30" id="Ankle">
                          <div className="row">
                            {/* <h4 className="range_sl_title" id="Ankle">
                        {translation.Ankle_Hindfoot}
                      </h4> */}
                            <div className="row">
                              <div className="col-md-6 col-sm-6">
                                <FullDropdown
                                  data={[
                                    {
                                      value: "1",
                                      label:
                                        "Good,plantigrade foot, ankle-hindfoot well aligned",
                                    },
                                    {
                                      value: "2",
                                      label:
                                        " Fair,plantigrade foot, some degree of ankle-hindfoot malalignment observed, no symptoms",
                                    },
                                    {
                                      value: "3",
                                      label:
                                        "Poor,nonplantigrade foot, severe symptomatic malalignment",
                                    },
                                  ]}
                                  label={
                                    AnkleHL
                                      ? "Alignment (Right)*"
                                      : "Alignment (Right)"
                                  }
                                  styleClass="mxw_100"
                                  value={AnkleR}
                                  placeholder="None"
                                  onChange={handleAnkleR}
                                />
                                {AnkleR === "" && AnkleHL ? (
                                  <>
                                    {errmessage && (
                                      <span className="text-danger">
                                        This field is required
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="col-md-6 col-sm-6">
                                <div className="row">
                                  <FullDropdown
                                    data={[
                                      {
                                        value: "1",
                                        label:
                                          "Good,plantigrade foot, ankle-hindfoot well aligned",
                                      },
                                      {
                                        value: "2",
                                        label:
                                          " Fair,plantigrade foot, some degree of ankle-hindfoot malalignment observed, no symptoms",
                                      },
                                      {
                                        value: "3",
                                        label:
                                          "Poor,nonplantigrade foot, severe symptomatic malalignment",
                                      },
                                    ]}
                                    label={
                                      AnkleHL
                                        ? "Alignment (Left)*"
                                        : "Alignment (Left)"
                                    }
                                    styleClass="mxw_100"
                                    value={AnkleL}
                                    placeholder="None"
                                    onChange={handleAnkleL}
                                  />
                                  {AnkleL === "" && AnkleHL ? (
                                    <>
                                      {errmessage && (
                                        <span className="text-danger">
                                          This field is required
                                        </span>
                                      )}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <h4 className="range_sl_title">
                                {/* Motion (Ankle/Hindfoot) */}
                                Motion
                                {/* {AnkleHL ? "Motion*" : "Motion"} */}
                              </h4>

                              <div className="col-md-6 col-sm-6">
                                <AssessmentPointDegree
                                  title={
                                    AnkleHL
                                      ? "Ankle Dorsiflexion-Plantarflexion Arc Right"
                                      : "Ankle Dorsiflexion-Plantarflexion Arc Right"
                                  }
                                  onChangeR={handleMotionRtext}
                                  onChangeL={handleMotionLtext}
                                  power={MotionRtext}
                                  kg={MotionLtext}
                                  labelL="Passive"
                                  labelR={"Active" + (AnkleHL ? "*" : "")}
                                  nameR="Flexion Right"
                                  nameL="Flexion Left"
                                  viewValidation={AnkleHL}
                                  validatorL={validatorL}
                                  validator={validator}
                                />
                              </div>

                              <div className="col-md-6 col-sm-6">
                                <AssessmentPointDegree
                                  title={
                                    AnkleHL
                                      ? "Ankle Dorsiflexion-Plantarflexion Arc Left"
                                      : "Ankle Dorsiflexion-Plantarflexion Arc Left"
                                  }
                                  onChangeR={handleMotionLRtext}
                                  onChangeL={handleMotionLLtext}
                                  power={MotionLRtext}
                                  kg={MotionLLtext}
                                  labelL="Passive"
                                  labelR={"Active" + (AnkleHL ? "*" : "")}
                                  nameR="Flexion Right"
                                  nameL="Flexion Left"
                                  viewValidation={AnkleHL}
                                  validatorL={validatorL}
                                  validator={validator}
                                />
                              </div>

                              <div className="col-md-6 col-sm-6 mt-4">
                                <AssessmentPointDegree
                                  title="Hindfoot Inversion-Eversion Arc Right"
                                  onChangeR={handleMotion3Rtext}
                                  onChangeL={handleMotion4Rtext}
                                  power={Motion3Rtext}
                                  kg={Motion4Rtext}
                                  labelL="Passive"
                                  labelR={"Active" + (AnkleHL ? "*" : "")}
                                  nameR="Flexion Right"
                                  nameL="Flexion Left"
                                  viewValidation={AnkleHL}
                                  validatorL={validatorL}
                                  validator={validator}
                                />
                              </div>

                              <div className="col-md-6 col-sm-6 mt-4">
                                <AssessmentPointDegree
                                  title="Hindfoot Inversion-Eversion Arc Left"
                                  onChangeR={handleMotion3RRtext}
                                  onChangeL={handleMotion3RLtext}
                                  power={Motion3RRtext}
                                  kg={Motion3RLtext}
                                  labelL="Passive"
                                  labelR={"Active" + (AnkleHL ? "*" : "")}
                                  nameR="Flexion Right"
                                  nameL="Flexion Left"
                                  viewValidation={AnkleHL}
                                  validatorL={validatorL}
                                  validator={validator}
                                />
                              </div>
                            </div>

                            <div className="row">
                              <h4 className="range_sl_title">
                                {AnkleHL ? "Stability" : "Stability"}
                              </h4>

                              <div className="col-md-6 col-sm-6">
                                <RadioSwitch
                                  labelLeft="Stable"
                                  labelRight="Unstable"
                                  idLeft="StabilityL"
                                  idRight="StabilityR"
                                  name="StabilityR"
                                  title={"AP, Varus-Valgus* (Right)"}
                                  styleClass=""
                                  value={StabilityR}
                                  onChange={handleStabilityR}
                                  placeholder="select"
                                />
                                {StabilityR === "" && AnkleHL ? (
                                  <>
                                    {errmessage && (
                                      <span className="text-danger">
                                        This field is required
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>

                              <div className="col-md-6 col-sm-6">
                                <RadioSwitch
                                  labelLeft="Stable"
                                  labelRight="Unstable"
                                  idLeft="Stability1L"
                                  idRight="Stability1R"
                                  name="Stability1R"
                                  title={"AP, Varus-Valgus* (Left)"}
                                  styleClass=""
                                  value={StabilityL}
                                  onChange={handleStabilityL}
                                  placeholder="select"
                                />
                                {StabilityL === "" && AnkleHL ? (
                                  <>
                                    {errmessage && (
                                      <span className="text-danger">
                                        This field is required
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>

                              <div className="col-md-6 col-sm-6">
                                <RadioSwitch
                                  labelLeft="Negative"
                                  labelRight="Positive"
                                  idLeft="Stability2L"
                                  idRight="Stability2R"
                                  name="Stability2R"
                                  title={"Anterior drawer test (Right)"}
                                  styleClass=""
                                  value={Stability2R}
                                  onChange={handleStability2R}
                                  placeholder="select"
                                />
                                {/* {Stability2R === "" ? (
                                  <>
                                    {errmessage && (
                                      <span className="text-danger">
                                        This field is required
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )} */}
                              </div>

                              <div className="col-md-6 col-sm-6">
                                <RadioSwitch
                                  labelLeft="Negative"
                                  labelRight="Positive"
                                  idLeft="Stability3L"
                                  idRight="Stability3R"
                                  name="Stability3R"
                                  title={"Anterior drawer test (Left)"}
                                  styleClass=""
                                  value={Stability2L}
                                  onChange={handleStability2L}
                                  placeholder="select"
                                />
                                {/* {Stability2L === "" ? (
                                  <>
                                    {errmessage && (
                                      <span className="text-danger">
                                        This field is required
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )} */}
                              </div>

                              <div className="col-md-6 col-sm-6">
                                <RadioSwitch
                                  labelLeft="Negative"
                                  labelRight="Positive"
                                  idLeft="Stability4L"
                                  idRight="Stability4R"
                                  name="Stability4R"
                                  title={"Posterior drawer test (Right)"}
                                  styleClass=""
                                  value={Stability3R}
                                  onChange={handleStability3R}
                                  placeholder="select"
                                />
                                {/* {Stability3R === "" ? (
                                  <>
                                    {errmessage && (
                                      <span className="text-danger">
                                        This field is required
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )} */}
                              </div>

                              <div className="col-md-6 col-sm-6">
                                <RadioSwitch
                                  labelLeft="Negative"
                                  labelRight="Positive"
                                  idLeft="Stability5L"
                                  idRight="Stability5R"
                                  name="Stability5R"
                                  title={"Posterior drawer test (Left)"}
                                  styleClass=""
                                  value={Stability3L}
                                  onChange={handleStability3L}
                                  placeholder="select"
                                />
                                {/* {Stability3L === "" ? (
                                  <>
                                    {errmessage && (
                                      <span className="text-danger">
                                        This field is required
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )} */}
                              </div>

                              {/* <h4 className="range_sl_title">Talar tilt test</h4> */}

                              <>
                                <div className="col-md-6 col-sm-6">
                                  <RadioSwitch
                                    labelLeft="Negative"
                                    labelRight="Positive"
                                    idLeft="Talar2L"
                                    idRight="Talar2R"
                                    name="Talar2R"
                                    title={"Talar tilt test Inversion (Right)"}
                                    styleClass=""
                                    value={TalarR}
                                    onChange={handleTalarR}
                                    placeholder="select"
                                  />
                                  {/* {TalarR === "" ? (
                                    <>
                                      {errmessage && (
                                        <span className="text-danger">
                                          This field is required
                                        </span>
                                      )}
                                    </>
                                  ) : (
                                    ""
                                  )} */}
                                </div>
                                <div className="col-md-6 col-sm-6">
                                  <RadioSwitch
                                    labelLeft="Negative"
                                    labelRight="Positive"
                                    idLeft="Talar5L"
                                    idRight="Talar5R"
                                    name="Talar5R"
                                    title={"Talar tilt test Inversion (Left)"}
                                    styleClass=""
                                    value={TalarL}
                                    onChange={handleTalarL}
                                    placeholder="select"
                                  />
                                  {/* {TalarL === "" ? (
                                    <>
                                      {errmessage && (
                                        <span className="text-danger">
                                          This field is required
                                        </span>
                                      )}
                                    </>
                                  ) : (
                                    ""
                                  )} */}
                                </div>
                                <div className="col-md-6 col-sm-6">
                                  <RadioSwitch
                                    labelLeft="Negative"
                                    labelRight="Positive"
                                    idLeft="Talar12L"
                                    idRight="Talar12R"
                                    name="Talar12R"
                                    title={"Talar tilt test Eversion (Right)"}
                                    styleClass=""
                                    value={Talar2R}
                                    onChange={handleTalar2R}
                                    placeholder="select"
                                  />
                                  {/* {Talar2R === "" ? (
                                    <>
                                      {errmessage && (
                                        <span className="text-danger">
                                          This field is required
                                        </span>
                                      )}
                                    </>
                                  ) : (
                                    ""
                                  )} */}
                                </div>
                                <div className="col-md-6 col-sm-6">
                                  <RadioSwitch
                                    labelLeft="Negative"
                                    labelRight="Positive"
                                    idLeft="Talar13L"
                                    idRight="Talar13R"
                                    name="Talar13R"
                                    title={"Talar tilt test Eversion (Left)"}
                                    styleClass=""
                                    value={Talar2L}
                                    onChange={handleTalar2L}
                                    placeholder="select"
                                  />
                                  {/* {Talar2L === "" ? (
                                    <>
                                      {errmessage && (
                                        <span className="text-danger">
                                          This field is required
                                        </span>
                                      )}
                                    </>
                                  ) : (
                                    ""
                                  )} */}
                                </div>
                              </>
                            </div>

                            <div className="row">
                              <h4 className="range_sl_title">
                                Syndesmosis
                                {/* {AnkleHL ? "Syndesmosis*" : "Syndesmosis"} */}
                              </h4>

                              <div className="col-md-6 col-sm-6">
                                <RadioSwitch
                                  labelLeft="Negative"
                                  labelRight="Positive"
                                  idLeft="SyndesmosisL"
                                  idRight="SyndesmosisR"
                                  name="SyndesmosisR1"
                                  title={
                                    "Calf compression or “squeeze” test (Right)"
                                  }
                                  styleClass=""
                                  value={SyndesmosisR}
                                  onChange={handleSyndesmosisR}
                                  placeholder="select"
                                />
                                {/* {SyndesmosisR === "" ? (
                                  <>
                                    {errmessage && (
                                      <span className="text-danger">
                                        This field is required
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )} */}
                              </div>

                              <div className="col-md-6 col-sm-6">
                                <RadioSwitch
                                  labelLeft="Negative"
                                  labelRight="Positive"
                                  idLeft="Syndesmosis1L"
                                  idRight="Syndesmosis1R"
                                  name="SyndesmosisL2"
                                  title={
                                    "Calf compression or “squeeze” test (Left)"
                                  }
                                  styleClass=""
                                  value={SyndesmosisL}
                                  onChange={handleSyndesmosisL}
                                  placeholder="select"
                                />
                                {/* {SyndesmosisL === "" ? (
                                  <>
                                    {errmessage && (
                                      <span className="text-danger">
                                        This field is required
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )} */}
                              </div>

                              <div className="col-md-6 col-sm-6">
                                <RadioSwitch
                                  labelLeft="Negative"
                                  labelRight="Positive"
                                  idLeft="SyndesmosisExternal1L"
                                  idRight="SyndesmosisExternal1R"
                                  name="SyndesmosisExternal1R"
                                  title={
                                    "External rotation stress or Kleiger’s test (Right)"
                                  }
                                  styleClass=""
                                  value={Syndesmosis1R}
                                  onChange={handleSyndesmosis1R}
                                  placeholder="select"
                                />
                                {/* {Syndesmosis1R === "" ? (
                                  <>
                                    {errmessage && (
                                      <span className="text-danger">
                                        This field is required
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )} */}
                              </div>

                              <div className="col-md-6 col-sm-6">
                                <RadioSwitch
                                  labelLeft="Negative"
                                  labelRight="Positive"
                                  idLeft="Syndesmosis2L"
                                  idRight="Syndesmosis2R"
                                  name="Syndesmosis2R"
                                  title={
                                    "External rotation stress or Kleiger’s test (Left)"
                                  }
                                  styleClass=""
                                  value={Syndesmosis1L}
                                  onChange={handleSyndesmosis1L}
                                  placeholder="select"
                                />
                                {/* {Syndesmosis1L === "" ? (
                                  <>
                                    {errmessage && (
                                      <span className="text-danger">
                                        This field is required
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )} */}
                              </div>
                            </div>
                            <div className="row">
                              <h4 className="range_sl_title">
                                Impingement
                                {/* {AnkleHL ? "Impingement*" : "Impingement"} */}
                              </h4>

                              <div className="col-md-6 col-sm-6">
                                <RadioSwitch
                                  labelLeft="Negative"
                                  labelRight="Positive"
                                  idLeft="ImpingementT1L"
                                  idRight="ImpingementT1R"
                                  name="ImpingementT1R"
                                  title={"Tenderness (Right)"}
                                  styleClass=""
                                  value={ImpingementR}
                                  onChange={handleImpingementR}
                                  placeholder="select"
                                />
                                {/* {ImpingementR === "" ? (
                                  <>
                                    {errmessage && (
                                      <span className="text-danger">
                                        This field is required
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )} */}
                                {ImpingementR === "1" && (
                                  <FullDropdown
                                    data={[
                                      // { value: "none", label: "None" },
                                      { value: "1", label: "Anteromedial" },
                                      { value: "2", label: "Anterolateral" },
                                      { value: "3", label: "Posterolateral" },
                                    ]}
                                    label={"Location"}
                                    styleClass="mxw_100"
                                    value={ImpingementdropR}
                                    placeholder="None"
                                    onChange={handleImpingementdropR}
                                  />
                                )}
                              </div>

                              <div className="col-md-6 col-sm-6">
                                <RadioSwitch
                                  labelLeft="Negative"
                                  labelRight="Positive"
                                  idLeft="ImpingementT2L"
                                  idRight="ImpingementT2R"
                                  name="ImpingementT2L"
                                  title={"Tenderness (Left)"}
                                  styleClass=""
                                  value={ImpingementL}
                                  onChange={handleImpingementL}
                                  placeholder="select"
                                />
                                {/* {ImpingementL === "" ? (
                                  <>
                                    {errmessage && (
                                      <span className="text-danger">
                                        This field is required
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )} */}
                                {/* {LeftABNORMAL === "13" && ( */}
                                {/* <div className="col-md-6 col-sm-6"> */}
                                {ImpingementL == "1" && (
                                  <FullDropdown
                                    data={[
                                      // { value: "none", label: "None" },
                                      { value: "1", label: "Anteromedial" },
                                      { value: "2", label: "Anterolateral" },
                                      { value: "3", label: "Posterolateral" },
                                    ]}
                                    label={"Location"}
                                    styleClass="mxw_100"
                                    value={ImpingementLdrop}
                                    placeholder="None"
                                    onChange={handleImpingementLdrop}
                                  />
                                )}
                                {/* </div> */}
                                {/* )} */}
                              </div>

                              <div className="col-md-6 col-sm-6">
                                <RadioSwitch
                                  labelLeft="Negative"
                                  labelRight="Positive"
                                  idLeft="Impingement1L"
                                  idRight="Impingement1R"
                                  name="Impingement1R"
                                  title={
                                    "Anterior ankle impingement test (Hyperdorsiflexion test) (Right)"
                                  }
                                  styleClass=""
                                  value={Impingement1R}
                                  onChange={handleImpingement1R}
                                  placeholder="select"
                                />
                                {/* {Impingement1R === "" ? (
                                  <>
                                    {errmessage && (
                                      <span className="text-danger">
                                        This field is required
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )} */}
                              </div>

                              <div className="col-md-6 col-sm-6">
                                <RadioSwitch
                                  labelLeft="Negative"
                                  labelRight="Positive"
                                  idLeft="Impingement2L"
                                  idRight="Impingement2R"
                                  name="Impingement2R"
                                  title={
                                    "Anterior ankle impingement test (Hyperdorsiflexion test) (Left)"
                                  }
                                  styleClass=""
                                  value={Impingement1L}
                                  onChange={handleImpingement1L}
                                  placeholder="select"
                                />
                                {/* {Impingement1L === "" ? (
                                  <>
                                    {errmessage && (
                                      <span className="text-danger">
                                        This field is required
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )} */}
                              </div>

                              <div className="col-md-6 col-sm-6">
                                <RadioSwitch
                                  labelLeft="Negative"
                                  labelRight="Positive"
                                  idLeft="Impingement3L"
                                  idRight="Impingement3R"
                                  name="Impingement3R"
                                  title={
                                    "Posterior ankle impingement test (Hyperplantarflexion test) (Right)"
                                  }
                                  styleClass=""
                                  value={Impingement3R}
                                  onChange={handleImpingement3R}
                                  placeholder="select"
                                />
                                {/* {Impingement3R === "" ? (
                                  <>
                                    {errmessage && (
                                      <span className="text-danger">
                                        This field is required
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )} */}
                              </div>

                              <div className="col-md-6 col-sm-6">
                                <RadioSwitch
                                  labelLeft="Negative"
                                  labelRight="Positive"
                                  idLeft="Impingement4L"
                                  idRight="Impingement4R"
                                  name="Impingement4R"
                                  title={
                                    "Posterior ankle impingement test (Hyperplantarflexion test) (Left)"
                                  }
                                  styleClass=""
                                  value={Impingement3L}
                                  onChange={handleImpingement3L}
                                  placeholder="select"
                                />

                                {/* {Impingement3L === "" ? (
                                  <>
                                    {errmessage && (
                                      <span className="text-danger">
                                        This field is required
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )} */}
                              </div>

                              <div className="col-md-6 col-sm-6">
                                <RadioSwitch
                                  labelLeft="Negative"
                                  labelRight="Positive"
                                  idLeft="ImpingementAn4L"
                                  idRight="ImpingementAn4R"
                                  name="AnkleAnR"
                                  title={"Ankle impingement sign (Right)"}
                                  styleClass=""
                                  value={Impingement4R}
                                  onChange={handleImpingement4R}
                                  placeholder="select"
                                />

                                {/* {Impingement4R === "" ? (
                                  <>
                                    {errmessage && (
                                      <span className="text-danger">
                                        This field is required
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )} */}
                              </div>

                              <div className="col-md-6 col-sm-6">
                                <RadioSwitch
                                  labelLeft="Negative"
                                  labelRight="Positive"
                                  idLeft="Impingement5L"
                                  idRight="Impingement5R"
                                  name="Impingement5L"
                                  title={"Ankle impingement sign (Left)"}
                                  styleClass=""
                                  value={Impingement4L}
                                  onChange={handleImpingement4L}
                                  placeholder="select"
                                />

                                {/* {Impingement4L === "" ? (
                                  <>
                                    {errmessage && (
                                      <span className="text-danger">
                                        This field is required
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )} */}
                              </div>
                            </div>

                            <div className="row">
                              <h4 className="range_sl_title">
                                Achilles tendon
                                {/* {AnkleHL
                                  ? "Achilles tendon*"
                                  : "Achilles tendon"} */}
                              </h4>

                              <div className="col-md-6 col-sm-6">
                                <RadioSwitch
                                  labelLeft="Negative"
                                  labelRight="Positive"
                                  idLeft="AchillesL"
                                  idRight="AchillesR"
                                  name="AchillesR"
                                  title={"Silfverskiold test (Right)"}
                                  styleClass=""
                                  value={AchillesR}
                                  onChange={handleAchillesR}
                                  placeholder="select"
                                />
                                {/* {AchillesR === "" ? (
                                  <>
                                    {errmessage && (
                                      <span className="text-danger">
                                        This field is required
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )} */}
                              </div>

                              <div className="col-md-6 col-sm-6">
                                <RadioSwitch
                                  labelLeft="Negative"
                                  labelRight="Positive"
                                  idLeft="Achilles1L"
                                  idRight="Achilles1R"
                                  name="Achilles1R"
                                  title={"Silfverskiold test (Left)"}
                                  styleClass=""
                                  value={AchillesL}
                                  onChange={handleAchillesL}
                                  placeholder="select"
                                />
                                {/* {AchillesL === "" ? (
                                  <>
                                    {errmessage && (
                                      <span className="text-danger">
                                        This field is required
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )} */}
                              </div>

                              <div className="col-md-6 col-sm-6">
                                <RadioSwitch
                                  labelLeft="Negative"
                                  labelRight="Positive"
                                  idLeft="Achilles2L"
                                  idRight="Achilles2R"
                                  name="Achilles2L"
                                  title={"Thompson’s test (Right)"}
                                  styleClass=""
                                  value={Achilles2R}
                                  onChange={handleAchilles2R}
                                  placeholder="select"
                                />
                                {/* {Achilles2R === "" ? (
                                  <>
                                    {errmessage && (
                                      <span className="text-danger">
                                        This field is required
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )} */}
                              </div>

                              <div className="col-md-6 col-sm-6">
                                <RadioSwitch
                                  labelLeft="Negative"
                                  labelRight="Positive"
                                  idLeft="Achilles3L"
                                  idRight="Achilles3R"
                                  name="Achilles3R"
                                  title={"Thompson’s test (Left)"}
                                  styleClass=""
                                  value={Achilles2L}
                                  onChange={handleAchilles2L}
                                  placeholder="select"
                                />
                                {/* {Achilles2L === "" ? (
                                  <>
                                    {errmessage && (
                                      <span className="text-danger">
                                        This field is required
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )} */}
                              </div>

                              <div className="col-md-6 col-sm-6">
                                <RadioSwitch
                                  labelLeft="Negative"
                                  labelRight="Positive"
                                  idLeft="Achilles5R"
                                  idRight="Achilles5L"
                                  name="Achilles5L"
                                  title={"Matles test (Right)"}
                                  styleClass=""
                                  value={Achilles4R}
                                  onChange={handleAchilles4R}
                                  placeholder="select"
                                />
                                {/* {Achilles4R === "" ? (
                                  <>
                                    {errmessage && (
                                      <span className="text-danger">
                                        This field is required
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )} */}
                              </div>

                              <div className="col-md-6 col-sm-6">
                                <RadioSwitch
                                  labelLeft="Negative"
                                  labelRight="Positive"
                                  idLeft="Achilles4L"
                                  idRight="Achilles4R"
                                  name="Achilles4L"
                                  title={"Matles test (Left)"}
                                  styleClass=""
                                  value={Achilles4L}
                                  onChange={handleAchilles4L}
                                  placeholder="select"
                                />
                                {/* {Achilles4L === "" ? (
                                  <>
                                    {errmessage && (
                                      <span className="text-danger">
                                        This field is required
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )} */}
                              </div>

                              <>
                                <div className="col-md-6 col-sm-6">
                                  <RadioSwitch
                                    labelLeft="Negative"
                                    labelRight="Positive"
                                    idLeft="Achilles7R"
                                    idRight="Achilles7L"
                                    name="Achilles7L"
                                    title={"Palpable gap (Right)"}
                                    styleClass=""
                                    value={Achilles6R}
                                    onChange={handleAchilles6R}
                                    placeholder="select"
                                  />
                                  {/* {Achilles6R === "" ? (
                                    <>
                                      {errmessage && (
                                        <span className="text-danger">
                                          This field is required
                                        </span>
                                      )}
                                    </>
                                  ) : (
                                    ""
                                  )} */}
                                  {Achilles6R === "1" && (
                                    <>
                                      <KneeDegree
                                        title="Gap measurement"
                                        valueR={Pal1LtextL}
                                        styleClass="mxw_100"
                                        onChangeR={handlePal1LtextL}
                                        max={"100"}
                                        unit="mm"
                                      />
                                      <div className="mt-2">
                                        <KneeDegree
                                          title="Distance from calcaneal insertion"
                                          valueR={Pal1RtextL}
                                          styleClass="mxw_100"
                                          onChangeR={handlePal1RtextL}
                                          max={"100"}
                                          unit="mm"
                                        />
                                      </div>
                                    </>
                                  )}
                                </div>
                              </>

                              <>
                                <div className="col-md-6 col-sm-6">
                                  <RadioSwitch
                                    labelLeft="Negative"
                                    labelRight="Positive"
                                    idLeft="Achilles6L"
                                    idRight="Achilles6R"
                                    name="Achilles6L"
                                    title={"Palpable gap (Left)"}
                                    styleClass=""
                                    value={Achilles6L}
                                    onChange={handleAchilles6L}
                                    placeholder="select"
                                  />
                                  {/* {Achilles6L === "" ? (
                                    <>
                                      {errmessage && (
                                        <span className="text-danger">
                                          This field is required
                                        </span>
                                      )}
                                    </>
                                  ) : (
                                    ""
                                  )} */}
                                  {Achilles6L === "1" && (
                                    <>
                                      <KneeDegree
                                        title="Gap measurement"
                                        valueR={Pal1Ltext}
                                        styleClass="mxw_100"
                                        onChangeR={handlePal1Ltext}
                                        max={"100"}
                                        unit="mm"
                                      />
                                      <div className="mt-2">
                                        <KneeDegree
                                          title="Distance from calcaneal insertion"
                                          valueR={Pal1Rtext}
                                          styleClass="mxw_100"
                                          onChangeR={handlePal1Rtext}
                                          max={"100"}
                                          unit="mm"
                                        />
                                      </div>
                                    </>
                                  )}
                                </div>
                              </>
                            </div>

                            <div className="row">
                              <h4 className="range_sl_title">
                                Deformity
                                {/* {AnkleHL ? "Deformity*" : "Deformity"} */}
                              </h4>

                              <div className="col-md-6 col-sm-6">
                                <RadioSwitch
                                  labelLeft="Negative"
                                  labelRight="Positive"
                                  idLeft="DeformityL"
                                  idRight="DeformityR"
                                  name="DeformityR"
                                  title={"Too many toes sign (Right)"}
                                  styleClass=""
                                  value={DeformityR}
                                  onChange={handleDeformityR}
                                  placeholder="select"
                                />
                                {/* {DeformityR === "" ? (
                                  <>
                                    {errmessage && (
                                      <span className="text-danger">
                                        This field is required
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )} */}
                              </div>

                              <div className="col-md-6 col-sm-6">
                                <RadioSwitch
                                  labelLeft="Negative"
                                  labelRight="Positive"
                                  idLeft="Deformity1L"
                                  idRight="Deformity1R"
                                  name="Deformity1R"
                                  title={"Too many toes sign (Left)"}
                                  styleClass=""
                                  value={DeformityL}
                                  onChange={handleDeformityL}
                                  placeholder="select"
                                />
                                {/* {DeformityL === "" ? (
                                  <>
                                    {errmessage && (
                                      <span className="text-danger">
                                        This field is required
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )} */}
                              </div>

                              <div className="col-md-6 col-sm-6">
                                <RadioSwitch
                                  labelLeft="Negative"
                                  labelRight="Positive"
                                  idLeft="Deformity2L"
                                  idRight="Deformity2R"
                                  name="Deformity2R"
                                  title={"Peek-a-boo heel sign (Right)"}
                                  styleClass=""
                                  value={Deformity2R}
                                  onChange={handleDeformity2R}
                                  placeholder="select"
                                />
                                {/* {Deformity2R === "" ? (
                                  <>
                                    {errmessage && (
                                      <span className="text-danger">
                                        This field is required
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )} */}
                              </div>

                              <div className="col-md-6 col-sm-6">
                                <RadioSwitch
                                  labelLeft="Negative"
                                  labelRight="Positive"
                                  idLeft="Deformity3L"
                                  idRight="Deformity3R"
                                  name="Deformity3R"
                                  title={"Peek-a-boo heel sign (Left)"}
                                  styleClass=""
                                  value={Deformity2L}
                                  onChange={handleDeformity2L}
                                  placeholder="select"
                                />
                                {/* {Deformity2L === "" ? (
                                  <>
                                    {errmessage && (
                                      <span className="text-danger">
                                        This field is required
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )} */}
                              </div>

                              <div className="col-md-6 col-sm-6">
                                <RadioSwitch
                                  labelLeft="Negative"
                                  labelRight="Positive"
                                  idLeft="Deformity4L"
                                  idRight="Deformity4R"
                                  name="Deformity4R"
                                  title={"Coleman block test (Right)"}
                                  styleClass=""
                                  value={Deformity4R}
                                  onChange={handleDeformity4R}
                                  placeholder="select"
                                />
                                {/* {Deformity4R === "" ? (
                                  <>
                                    {errmessage && (
                                      <span className="text-danger">
                                        This field is required
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )} */}
                              </div>

                              <div className="col-md-6 col-sm-6">
                                <RadioSwitch
                                  labelLeft="Negative"
                                  labelRight="Positive"
                                  idLeft="Deformity5L"
                                  idRight="Deformity5R"
                                  name="Deformity5R"
                                  title={"Coleman block test (Left)"}
                                  styleClass=""
                                  value={Deformity4L}
                                  onChange={handleDeformity4L}
                                  placeholder="select"
                                />
                                {/* {Deformity4L === "" ? (
                                  <>
                                    {errmessage && (
                                      <span className="text-danger">
                                        This field is required
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )} */}
                              </div>

                              <div className="col-md-6 col-sm-6">
                                <RadioSwitch
                                  labelLeft="Negative"
                                  labelRight="Positive"
                                  idLeft="Deformity6L"
                                  idRight="Deformity6R"
                                  name="Deformity6R"
                                  title={"Double-leg heel raise (Right)"}
                                  styleClass=""
                                  value={Deformity6R}
                                  onChange={handleDeformity6R}
                                  placeholder="select"
                                />
                                {/* {Deformity6R === "" ? (
                                  <>
                                    {errmessage && (
                                      <span className="text-danger">
                                        This field is required
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )} */}
                              </div>

                              <div className="col-md-6 col-sm-6">
                                <RadioSwitch
                                  labelLeft="Negative"
                                  labelRight="Positive"
                                  idLeft="Deformity7L"
                                  idRight="Deformity7R"
                                  name="Deformity7R"
                                  title={"Double-leg heel raise (Left)"}
                                  styleClass=""
                                  value={Deformity6L}
                                  onChange={handleDeformity6L}
                                  placeholder="select"
                                />
                                {/* {Deformity6L === "" ? (
                                  <>
                                    {errmessage && (
                                      <span className="text-danger">
                                        This field is required
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )} */}
                              </div>

                              <div className="col-md-6 col-sm-6">
                                <RadioSwitch
                                  labelLeft="Negative"
                                  labelRight="Positive"
                                  idLeft="Deformity8L"
                                  idRight="Deformity8R"
                                  name="Deformity8R"
                                  title={"Single-leg heel raise (Right)"}
                                  styleClass=""
                                  value={Deformity8R}
                                  onChange={handleDeformity8R}
                                  placeholder="select"
                                />
                                {/* {Deformity8R === "" ? (
                                  <>
                                    {errmessage && (
                                      <span className="text-danger">
                                        This field is required
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )} */}
                              </div>

                              <div className="col-md-6 col-sm-6">
                                <RadioSwitch
                                  labelLeft="Negative"
                                  labelRight="Positive"
                                  idLeft="Deformity9L"
                                  idRight="Deformity9R"
                                  name="Deformity9R"
                                  title={"Single-leg heel raise (Left)"}
                                  styleClass=""
                                  value={Deformity8L}
                                  onChange={handleDeformity8L}
                                  placeholder="select"
                                />
                                {/* {Deformity8L === "" ? (
                                  <>
                                    {errmessage && (
                                      <span className="text-danger">
                                        This field is required
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )} */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
                {/* Ankle end */}

                <div
                  id="Midfoot"
                  className={`accordion_tabpanel ${assessmentStyles.accordion_tabpanel}`}
                >
                  <Accordion
                    activeKey={activeKeyMid}
                    onSelect={handleAccordionMid}
                  >
                    <Accordion.Item
                      eKey="10"
                      defaultActiveKey="10"
                      eventKey="10"
                    >
                      <Accordion.Header>Midfoot</Accordion.Header>
                      <Accordion.Body>
                        <div className="mb_30" id="Midfoot">
                          <div className="row">
                            {/* <h4 className="range_sl_title">{"Midfoot"}</h4> */}

                            <div className="col-md-6 col-sm-6">
                              <FullDropdown
                                data={[
                                  // { value: "none", label: "None" },
                                  {
                                    value: "1",
                                    label:
                                      "Good, plantigrade foot, midfoot well aligned",
                                  },
                                  {
                                    value: "2",
                                    label:
                                      "Fair, plantigrade foot, some degree of midfoot malalignment observed, no symptoms",
                                  },
                                  {
                                    value: "3",
                                    label:
                                      "Poor, nonplantigrade foot, severe malalignment, symptoms",
                                  },
                                ]}
                                // label={translation.Foot_R}
                                label={
                                  MidfootHL
                                    ? "Alignment (Right)*"
                                    : "Alignment (Right)"
                                }
                                styleClass="mxw_100"
                                value={MidfootR}
                                placeholder="None"
                                onChange={handleMidfootR}
                              />
                              {MidfootR === "" && MidfootHL ? (
                                <>
                                  {errmessage && (
                                    <span className="text-danger">
                                      This field is required
                                    </span>
                                  )}
                                </>
                              ) : (
                                ""
                              )}
                            </div>

                            <div className="col-md-6 col-sm-6">
                              <FullDropdown
                                data={[
                                  // { value: "none", label: "None" },
                                  {
                                    value: "1",
                                    label:
                                      "Good, plantigrade foot, midfoot well aligned",
                                  },
                                  {
                                    value: "2",
                                    label:
                                      "Fair, plantigrade foot, some degree of midfoot malalignment observed, no symptoms",
                                  },
                                  {
                                    value: "3",
                                    label:
                                      "Poor, nonplantigrade foot, severe malalignment, symptoms",
                                  },
                                ]}
                                label={
                                  MidfootHL
                                    ? "Alignment (Left)*"
                                    : "Alignment (Left)"
                                }
                                styleClass="mxw_100"
                                value={MidfootL}
                                placeholder="None"
                                onChange={handleMidfootL}
                              />
                              {MidfootL === "" && MidfootHL ? (
                                <>
                                  {errmessage && (
                                    <span className="text-danger">
                                      This field is required
                                    </span>
                                  )}
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>

                {/* Midfoot end */}

                <div
                  id="Hallux"
                  className={`accordion_tabpanel ${assessmentStyles.accordion_tabpanel}`}
                >
                  <Accordion
                    activeKey={activeKeyhalx}
                    onSelect={handleAccordionToggle2}
                  >
                    <Accordion.Item eKey="2" defaultActiveKey="2" eventKey="2">
                      <Accordion.Header>Hallux</Accordion.Header>
                      <Accordion.Body>
                        <div className=" mb_30" id="Hallux">
                          <div className="row">
                            {/* <h4 className="range_sl_title">{"Hallux"}</h4> */}

                            <div className="col-md-6 col-sm-6">
                              <FullDropdown
                                data={[
                                  {
                                    value: "1",
                                    label: "Good, hallux well aligned",
                                  },
                                  {
                                    value: "2",
                                    label:
                                      "Fair, some degree of hallux malalignment observed, no symptoms",
                                  },
                                  {
                                    value: "3",
                                    label:
                                      "Poor, obvious symptomatic alignment",
                                  },
                                ]}
                                label={
                                  Hallux
                                    ? "Alignment (Right)*"
                                    : "Alignment (Right)"
                                }
                                styleClass="mxw_100"
                                value={AlignmentR}
                                placeholder="None"
                                onChange={handleAlignmentR}
                              />
                              {AlignmentR === "" && Hallux ? (
                                <>
                                  {errmessage && (
                                    <span className="text-danger">
                                      This field is required
                                    </span>
                                  )}
                                </>
                              ) : (
                                ""
                              )}
                            </div>

                            <div className="col-md-6 col-sm-6">
                              <FullDropdown
                                data={[
                                  {
                                    value: "1",
                                    label: "Good, hallux well aligned",
                                  },
                                  {
                                    value: "2",
                                    label:
                                      "Fair, some degree of hallux malalignment observed, no symptoms",
                                  },
                                  {
                                    value: "3",
                                    label:
                                      "Poor, obvious symptomatic alignment",
                                  },
                                ]}
                                label={
                                  Hallux
                                    ? "Alignment (Left)*"
                                    : "Alignment (Left)"
                                }
                                styleClass="mxw_100"
                                value={AlignmentL}
                                placeholder="None"
                                onChange={handleAlignmentL}
                              />
                              {AlignmentL === "" && Hallux ? (
                                <>
                                  {errmessage && (
                                    <span className="text-danger">
                                      This field is required
                                    </span>
                                  )}
                                </>
                              ) : (
                                ""
                              )}
                            </div>

                            <div className="col-md-6 col-sm-6">
                              <RadioSwitch
                                labelLeft="Absent"
                                labelRight="Present"
                                idLeft="AlignmentL"
                                idRight="AlignmentR"
                                name="AlignmentL"
                                title={
                                  Hallux ? "Callus (Right)*" : "Callus (Right)"
                                }
                                styleClass=""
                                value={AlignmentradioL}
                                onChange={handleAlignmentradioL}
                                placeholder="select"
                              />
                              {AlignmentradioL === "" && Hallux ? (
                                <>
                                  {errmessage && (
                                    <span className="text-danger">
                                      This field is required
                                    </span>
                                  )}
                                </>
                              ) : (
                                ""
                              )}
                              {/* {LeftABNORMAL === "13" && ( */}
                              {/* <div className="col-md-6 col-sm-6"> */}
                              {AlignmentradioL === "1" && (
                                <FullDropdown
                                  data={[
                                    //   { value: "none", label: "None" },
                                    { value: "1", label: "Asymptomatic" },
                                    { value: "2", label: "Symptomatic" },
                                  ]}
                                  label="Right Hallux"
                                  styleClass="mxw_100"
                                  value={Alignmentdrop}
                                  placeholder="None"
                                  onChange={handleAlignmentdrop}
                                />
                              )}
                              {/* </div> */}
                              {/* )} */}
                            </div>

                            <div className="col-md-6 col-sm-6">
                              <RadioSwitch
                                labelLeft="Absent"
                                labelRight="Present"
                                idLeft="Alignmen1L"
                                idRight="Alignmen1R"
                                name="Alignmen1L"
                                title={
                                  Hallux ? "Callus (Left)*" : "Callus (Left)"
                                }
                                styleClass=""
                                value={Alignmen1L}
                                onChange={handleAlignmen1L}
                                placeholder="select"
                              />
                              {Alignmen1L === "" && Hallux ? (
                                <>
                                  {errmessage && (
                                    <span className="text-danger">
                                      This field is required
                                    </span>
                                  )}
                                </>
                              ) : (
                                ""
                              )}
                              {/* {LeftABNORMAL === "13" && ( */}
                              {/* <div className="col-md-6 col-sm-6"> */}
                              {Alignmen1L === "1" && (
                                <FullDropdown
                                  data={[
                                    //   { value: "none", label: "None" },
                                    { value: "1", label: "Asymptomatic" },
                                    { value: "2", label: "Symptomatic" },
                                  ]}
                                  label="Left Hallux"
                                  styleClass="mxw_100"
                                  value={Alignmen1drop}
                                  placeholder="None"
                                  onChange={handleAlignmen1drop}
                                />
                              )}
                              {/* </div> */}
                              {/* )} */}
                            </div>

                            {/* <>
                              <div className="col-md-6 col-sm-6 mt-3">
                                <KneeDegree
                                  powerAction="true"
                                  power={AlignmeninputL}
                                  onChangeR={handlehandleAlignmeninputL}
                                  label={
                                    Hallux
                                      ? "MTP Joint (Dorsiflexion plus Plantarflexion)*"
                                      : "MTP Joint (Dorsiflexion plus Plantarflexion)"
                                  }
                                  nameL="Alignmeninput1L"
                                />
                              </div>
                              <div className="col-md-6 col-sm-6 mb-3 mt-3">
                                <KneeDegree
                                  powerAction="true"
                                  power={AlignmeninputR}
                                  onChangeR={handlehandleAlignmeninputR}
                                  label={
                                    Hallux
                                      ? "MTP Joint (Dorsiflexion plus Plantarflexion)*"
                                      : "MTP Joint (Dorsiflexion plus Plantarflexion)"
                                  }
                                  nameL="Alignmeninput1R"
                                />
                              </div>
                            </>

                            <>
                              <div className="col-md-6 col-sm-6 mt-3">
                                <KneeDegree
                                  powerAction="true"
                                  power={AlignmeninputIPJOINTL}
                                  onChangeR={handlehandleAlignmeninputIPJOINTL}
                                  label={
                                    Hallux
                                      ? "IP Joint (Plantarflexion)*"
                                      : "IP Joint (Plantarflexion)"
                                  }
                                  nameL="Alignmeninput2L"
                                />
                              </div>
                              <div className="col-md-6 col-sm-6 mt-3">
                                <KneeDegree
                                  powerAction="true"
                                  power={AlignmeninputIPJOINTR}
                                  onChangeR={handlehandleAlignmeninputIPJOINTR}
                                  label={
                                    Hallux
                                      ? "IP Joint (Plantarflexion)*"
                                      : "IP Joint (Plantarflexion)"
                                  }
                                  nameL="Alignmeninput2R"
                                />
                              </div>
                            </> */}
                            <div className="row">
                              <h4 className="range_sl_title">
                                Motion
                                {/* {Hallux ? "Motion*" : "Motion"} */}
                              </h4>

                              <div className="col-md-6 col-sm-6">
                                <AssessmentPointDegree
                                  title={
                                    Hallux
                                      ? "MTP Joint (Dorsiflexion plus Plantarflexion) Right"
                                      : "MTP Joint (Dorsiflexion plus Plantarflexion) Right"
                                  }
                                  onChangeR={handleMotion2Rtext}
                                  onChangeL={handleMotion2Ltext}
                                  power={Motion2Rtext}
                                  kg={Motion2Ltext}
                                  labelL="Passive"
                                  labelR={"Active" + (Hallux ? "*" : "")}
                                  nameR="Flexion Right"
                                  nameL="Flexion Left"
                                  viewValidation={Hallux}
                                  validatorL={validatorL}
                                  validator={validator}
                                />
                              </div>

                              <div className="col-md-6 col-sm-6">
                                <AssessmentPointDegree
                                  title={
                                    Hallux
                                      ? "MTP Joint (Dorsiflexion plus Plantarflexion) Left"
                                      : "MTP Joint (Dorsiflexion plus Plantarflexion) Left"
                                  }
                                  onChangeR={handleMotion2LRtext}
                                  onChangeL={handleMotion2LLtext}
                                  power={Motion2LRtext}
                                  kg={Motion2LLtext}
                                  labelL="Passive"
                                  labelR={"Active" + (Hallux ? "*" : "")}
                                  nameR="Flexion Right"
                                  nameL="Flexion Left"
                                  viewValidation={Hallux}
                                  validatorL={validatorL}
                                  validator={validator}
                                />
                              </div>

                              <div className="col-md-6 col-sm-6 mt-4">
                                <AssessmentPointDegree
                                  title="IP Joint (Plantarflexion) Right"
                                  onChangeR={handleMotion23Rtext}
                                  onChangeL={handleMotion24Rtext}
                                  power={Motion23Rtext}
                                  kg={Motion24Rtext}
                                  labelL="Passive"
                                  labelR={"Active" + (Hallux ? "*" : "")}
                                  nameR="Flexion Right"
                                  nameL="Flexion Left"
                                  viewValidation={Hallux}
                                  validatorL={validatorL}
                                  validator={validator}
                                />
                              </div>

                              <div className="col-md-6 col-sm-6 mt-4">
                                <AssessmentPointDegree
                                  title="IP Joint (Plantarflexion) Left"
                                  onChangeR={handleMotion23RRtext}
                                  onChangeL={handleMotion23RLtext}
                                  power={Motion23RRtext}
                                  kg={Motion23RLtext}
                                  labelL="Passive"
                                  labelR={"Active" + (Hallux ? "*" : "")}
                                  nameR="Flexion Right"
                                  nameL="Flexion Left"
                                  viewValidation={Hallux}
                                  validatorL={validatorL}
                                  validator={validator}
                                />
                              </div>
                            </div>

                            <h4 className="range_sl_title">
                              {Hallux ? "Stability" : "Stability"}
                            </h4>
                            <div className="col-md-6 col-sm-6 mt-3">
                              <RadioSwitch
                                labelLeft="Stable"
                                labelRight="Unstable"
                                idLeft="Alignmen2L"
                                idRight="Alignmen2R"
                                name="Alignmen2L"
                                title={
                                  Hallux
                                    ? "MTP-IP, in all directions (Right)*"
                                    : "MTP-IP, in all directions (Right)"
                                }
                                styleClass=""
                                value={Alignmen2R}
                                onChange={handleAlignmen2R}
                                placeholder="select"
                              />
                              {Alignmen2R === "" && Hallux ? (
                                <>
                                  {errmessage && (
                                    <span className="text-danger">
                                      This field is required
                                    </span>
                                  )}
                                </>
                              ) : (
                                ""
                              )}
                            </div>

                            <div className="col-md-6 col-sm-6 mt-3">
                              <RadioSwitch
                                labelLeft="Stable"
                                labelRight="Unstable"
                                idLeft="Alignmen3L"
                                idRight="Alignmen3R"
                                name="Alignmen3L"
                                title={
                                  Hallux
                                    ? "MTP-IP, in all directions (Left)*"
                                    : "MTP-IP, in all directions (Left)"
                                }
                                styleClass=""
                                value={Alignmen2L}
                                onChange={handleAlignmen2L}
                                placeholder="select"
                              />
                              {Alignmen2L === "" && Hallux ? (
                                <>
                                  {errmessage && (
                                    <span className="text-danger">
                                      This field is required
                                    </span>
                                  )}
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>

                {/* Hallux end */}

                <div
                  id="2ndtoe"
                  className={`accordion_tabpanel ${assessmentStyles.accordion_tabpanel}`}
                >
                  <Accordion
                    activeKey={activeKeytoe1}
                    onSelect={handleAccordionToggleToe1}
                  >
                    <Accordion.Item eKey="3" defaultActiveKey="3" eventKey="3">
                      <Accordion.Header>2nd Toe</Accordion.Header>
                      <Accordion.Body>
                        <div className=" mb_30" id="2ndtoe">
                          <div className="row">
                            {/* <h4 className="range_sl_title">{"2nd Lesser Toe"}</h4> */}

                            <div className="row"></div>
                            <div className="row">
                              <div className="col-md-6 col-sm-6">
                                <FullDropdown
                                  data={[
                                    // { value: "none", label: "None" },
                                    {
                                      value: "1",
                                      label: " Good, lesser toes well aligned",
                                    },
                                    {
                                      value: "2",
                                      label:
                                        "Fair, some degree of lesser toe malalignment observed, no symptoms",
                                    },
                                    {
                                      value: "3",
                                      label:
                                        "Poor, severe malalignment, symptoms",
                                    },
                                  ]}
                                  label={
                                    Toe2nd
                                      ? "Alignment (Right)*"
                                      : "Alignment (Right)"
                                  }
                                  styleClass="mxw_100"
                                  value={LesserR2}
                                  placeholder="None"
                                  onChange={handleLesserR2}
                                />
                                {LesserR2 === "" && Toe2nd ? (
                                  <>
                                    {errmessage && (
                                      <span className="text-danger">
                                        This field is required
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="col-md-6 col-sm-6">
                                <FullDropdown
                                  data={[
                                    // { value: "none", label: "None" },
                                    {
                                      value: "1",
                                      label: "Good, lesser toes well aligned",
                                    },
                                    {
                                      value: "2",
                                      label:
                                        "Fair, some degree of lesser toe malalignment observed, no symptoms",
                                    },
                                    {
                                      value: "3",
                                      label:
                                        "Poor, severe malalignment, symptoms",
                                    },
                                  ]}
                                  label={
                                    Toe2nd
                                      ? "Alignment (Left)*"
                                      : "Alignment (Left)"
                                  }
                                  styleClass="mxw_100"
                                  value={LesserL2}
                                  placeholder="None"
                                  onChange={handleLesserL2}
                                />
                                {LesserL2 === "" && Toe2nd ? (
                                  <>
                                    {errmessage && (
                                      <span className="text-danger">
                                        This field is required
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-6 col-sm-6">
                                <RadioSwitch
                                  labelLeft="Absent"
                                  labelRight="Present"
                                  idLeft="Lesser1L2"
                                  idRight="Lesser1R2"
                                  name="Lesser1L2"
                                  title={
                                    Toe2nd
                                      ? "Callus (Right)*"
                                      : "Callus (Right)"
                                  }
                                  styleClass=""
                                  value={LesserradioR2}
                                  onChange={handleLesserradioR2}
                                  placeholder="select"
                                />
                                {LesserradioR2 === "" && Toe2nd ? (
                                  <>
                                    {errmessage && (
                                      <span className="text-danger">
                                        This field is required
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}
                                {/* {LeftABNORMAL === "13" && ( */}
                                {/* <div className="col-md-6 col-sm-6"> */}
                                {LesserradioR2 === "1" && (
                                  <FullDropdown
                                    data={[
                                      //   { value: "none", label: "None" },
                                      {
                                        value: "Asymptomatic",
                                        label: "Asymptomatic",
                                      },
                                      {
                                        value: "Symptomatic",
                                        label: "Symptomatic",
                                      },
                                    ]}
                                    label="Right 2nd Toe"
                                    styleClass="mxw_100"
                                    value={LesserrRdrop2}
                                    placeholder="None"
                                    onChange={handleLesserrRdrop2}
                                  />
                                )}
                                {/* </div> */}
                                {/* )} */}
                              </div>
                              <div className="col-md-6 col-sm-6">
                                <RadioSwitch
                                  labelLeft="Absent"
                                  labelRight="Present"
                                  idLeft="LesserradioRL2"
                                  idRight="LesserradioRR2"
                                  name="LesserradioRL2"
                                  title={
                                    Toe2nd ? "Callus (Left)*" : "Callus (Left)"
                                  }
                                  styleClass=""
                                  value={LesserradioL2}
                                  onChange={handleLesserradioL2}
                                  placeholder="select"
                                />
                                {LesserradioL2 === "" && Toe2nd ? (
                                  <>
                                    {errmessage && (
                                      <span className="text-danger">
                                        This field is required
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}
                                {/* {LeftABNORMAL === "13" && ( */}
                                {/* <div className="col-md-6 col-sm-6"> */}
                                {LesserradioL2 === "1" && (
                                  <FullDropdown
                                    data={[
                                      //   { value: "none", label: "None" },
                                      {
                                        value: "Asymptomatic",
                                        label: "Asymptomatic",
                                      },
                                      {
                                        value: "Symptomatic",
                                        label: "Symptomatic",
                                      },
                                    ]}
                                    label="Left 2nd Toe"
                                    styleClass="mxw_100"
                                    value={LesserrLdrop2}
                                    placeholder="None"
                                    onChange={handleLesserrLdrop2}
                                  />
                                )}
                              </div>
                            </div>

                            {/* <div className="row">
                              <>
                                <div className="col-md-6 col-sm-6 mt-3">
                                  <KneeDegree
                                    powerAction="true"
                                    power={LesserrDegreeR}
                                    onChangeR={handleLesserrDegreeR}
                                    label={
                                      Toe2nd
                                        ? "IP Joint (Plantarflexion)*"
                                        : "IP Joint (Plantarflexion)"
                                    }
                                    nameL="Alignmeninput2R"
                                  />
                                  {LesserrDegreeR === "" && (
                                    <>
                                      {errmessage && (
                                        <span className="text-danger">
                                          This field is required
                                        </span>
                                      )}
                                    </>
                                  )}
                                </div>
                                <div className="col-md-6 col-sm-6 mt-3">
                                  <KneeDegree
                                    powerAction="true"
                                    power={LesserrDegreeL}
                                    onChangeR={handleLesserrDegreeL}
                                    label={
                                      Toe2nd
                                        ? "IP Joint (Plantarflexion)*"
                                        : "IP Joint (Plantarflexion)"
                                    }
                                    nameL="Alignmeninput2L"
                                  />
                                  {LesserrDegreeL === "" && (
                                    <>
                                      {errmessage && (
                                        <span className="text-danger">
                                          This field is required
                                        </span>
                                      )}
                                    </>
                                  )}
                                </div>
                              </>
                            </div> */}

                            <div className="row">
                              <h4 className="range_sl_title">
                                Motion
                                {/* {Toe2nd ? "Motion*" : "Motion"} */}
                              </h4>

                              <div className="col-md-6 col-sm-6">
                                <AssessmentPointDegree
                                  title={
                                    AnkleHL
                                      ? "MTP Joint (Dorsiflexion plus Plantarflexion) Right"
                                      : "MTP Joint (Dorsiflexion plus Plantarflexion) Right"
                                  }
                                  onChangeR={handleMotion2ndRtext}
                                  onChangeL={handleMotion2ndLtext}
                                  power={Motion2ndRtext}
                                  kg={Motion2ndLtext}
                                  labelL="Passive"
                                  labelR={"Active" + (Toe2nd ? "*" : "")}
                                  nameR="Flexion Right"
                                  nameL="Flexion Left"
                                  viewValidation={Toe2nd}
                                  validatorL={validatorL}
                                  validator={validator}
                                />
                              </div>

                              <div className="col-md-6 col-sm-6">
                                <AssessmentPointDegree
                                  title={
                                    AnkleHL
                                      ? "MTP Joint (Dorsiflexion plus Plantarflexion) Left"
                                      : "MTP Joint (Dorsiflexion plus Plantarflexion) Left"
                                  }
                                  onChangeR={handleMotion2ndLRtext}
                                  onChangeL={handleMotion2ndLLtext}
                                  power={Motion2ndLRtext}
                                  kg={Motion2ndLLtext}
                                  labelL="Passive"
                                  labelR={"Active" + (Toe2nd ? "*" : "")}
                                  nameR="Flexion Right"
                                  nameL="Flexion Left"
                                  viewValidation={Toe2nd}
                                  validatorL={validatorL}
                                  validator={validator}
                                />
                              </div>

                              <div className="col-md-6 col-sm-6 mt-4">
                                <AssessmentPointDegree
                                  title="IP Joint (Plantarflexion) Right"
                                  onChangeR={handleMotion2nRtext}
                                  onChangeL={handleMotion2nlRtext}
                                  power={Motion2nRtext}
                                  kg={Motion2nlRtext}
                                  labelL="Passive"
                                  labelR={"Active" + (Toe2nd ? "*" : "")}
                                  nameR="Flexion Right"
                                  nameL="Flexion Left"
                                  viewValidation={Toe2nd}
                                  validatorL={validatorL}
                                  validator={validator}
                                />
                              </div>

                              <div className="col-md-6 col-sm-6 mt-4">
                                <AssessmentPointDegree
                                  title="IP Joint (Plantarflexion) Left"
                                  onChangeR={handleMotion2ndRRtext}
                                  onChangeL={handleMotion2ndRLtext}
                                  power={Motion2ndRRtext}
                                  kg={Motion2ndRLtext}
                                  labelL="Passive"
                                  labelR={"Active" + (Toe2nd ? "*" : "")}
                                  nameR="Flexion Right"
                                  nameL="Flexion Left"
                                  viewValidation={Toe2nd}
                                  validatorL={validatorL}
                                  validator={validator}
                                />
                              </div>
                            </div>
                            <h4 className="range_sl_title">
                              {Toe2nd ? "Stability" : "Stability"}
                            </h4>

                            <div className="row mt-3">
                              <div className="col-md-6 col-sm-6">
                                <RadioSwitch
                                  labelLeft="Stable"
                                  labelRight="Unstable"
                                  idLeft="SpecifyRradioL2"
                                  idRight="SpecifyRradioR2"
                                  name="SpecifyRradioL1"
                                  title={
                                    Toe2nd
                                      ? "MTP-IP, in all directions (Right)*"
                                      : "MTP-IP, in all directions (Right)"
                                  }
                                  styleClass=""
                                  value={SpecifyRradio2}
                                  onChange={handleSpecifyRradio2}
                                  placeholder="select"
                                />
                                {SpecifyRradio2 === "" && Toe2nd ? (
                                  <>
                                    {errmessage && (
                                      <span className="text-danger">
                                        This field is required
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="col-md-6 col-sm-6">
                                <RadioSwitch
                                  labelLeft="Stable"
                                  labelRight="Unstable"
                                  idLeft="SpecifyLradioL2"
                                  idRight="SpecifyLradioR2"
                                  name="SpecifyLradioL1"
                                  title={
                                    Toe2nd
                                      ? "MTP-IP, in all directions (Left)*"
                                      : "MTP-IP, in all directions (Left)"
                                  }
                                  styleClass=""
                                  value={SpecifyLradio2}
                                  onChange={handleSpecifyLradio2}
                                  placeholder="select"
                                />
                                {SpecifyLradio2 === "" && Toe2nd ? (
                                  <>
                                    {errmessage && (
                                      <span className="text-danger">
                                        This field is required
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>

                {/* 2nd Toe end */}

                <div
                  id="3rdtoe"
                  className={`accordion_tabpanel ${assessmentStyles.accordion_tabpanel}`}
                >
                  <Accordion
                    activeKey={activeKeytoe2}
                    onSelect={handleAccordionToggleToe2}
                  >
                    <Accordion.Item eKey="4" defaultActiveKey="4" eventKey="4">
                      <Accordion.Header>3rd Toe</Accordion.Header>
                      <Accordion.Body>
                        <div className="mb_30" id="3rdtoe">
                          <div className="row">
                            {/* <h4 className="range_sl_title">{"3rd Lesser Toe"}</h4> */}

                            <div className="row">
                              <div className="col-md-6 col-sm-6">
                                <FullDropdown
                                  data={[
                                    // { value: "none", label: "None" },
                                    {
                                      value: "1",
                                      label: " Good, lesser toes well aligned",
                                    },
                                    {
                                      value: "2",
                                      label:
                                        "Fair, some degree of lesser toe malalignment observed, no symptoms",
                                    },
                                    {
                                      value: "3",
                                      label:
                                        "Poor, severe malalignment, symptoms",
                                    },
                                  ]}
                                  label={
                                    Toe3rd
                                      ? "Alignment (Right)*"
                                      : "Alignment (Right)"
                                  }
                                  styleClass="mxw_100"
                                  value={LesserR23rd}
                                  placeholder="None"
                                  onChange={handleLesserR23rd}
                                />
                                {LesserR23rd === "" && Toe3rd ? (
                                  <>
                                    {errmessage && (
                                      <span className="text-danger">
                                        This field is required
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="col-md-6 col-sm-6">
                                <FullDropdown
                                  data={[
                                    // { value: "none", label: "None" },
                                    {
                                      value: "1",
                                      label: " Good, lesser toes well aligned",
                                    },
                                    {
                                      value: "2",
                                      label:
                                        "Fair, some degree of lesser toe malalignment observed, no symptoms",
                                    },
                                    {
                                      value: "3",
                                      label:
                                        "Poor, severe malalignment, symptoms",
                                    },
                                  ]}
                                  label={
                                    Toe3rd
                                      ? "Alignment (Left)"
                                      : "Alignment (Left)"
                                  }
                                  styleClass="mxw_100"
                                  value={LesserL23rd}
                                  placeholder="None"
                                  onChange={handleLesserL23rd}
                                />
                                {LesserL23rd === "" && Toe3rd ? (
                                  <>
                                    {errmessage && (
                                      <span className="text-danger">
                                        This field is required
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-6 col-sm-6">
                                <RadioSwitch
                                  labelLeft="Absent"
                                  labelRight="Present"
                                  idLeft="Lesser1L23rd1"
                                  idRight="Lesser1R23rd1"
                                  name="Lesser1L3rd"
                                  title={
                                    Toe3rd
                                      ? "Callus (Right)*"
                                      : "Callus (Right)"
                                  }
                                  styleClass=""
                                  value={LesserradioR23rd}
                                  onChange={handleLesserradioR23rd}
                                  placeholder="select"
                                />
                                {LesserradioR23rd === "" && Toe3rd ? (
                                  <>
                                    {errmessage && (
                                      <span className="text-danger">
                                        This field is required
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}
                                {LesserradioR23rd === "1" && (
                                  <FullDropdown
                                    data={[
                                      {
                                        value: "Asymptomatic",
                                        label: "Asymptomatic",
                                      },
                                      {
                                        value: "Symptomatic",
                                        label: "Symptomatic",
                                      },
                                    ]}
                                    label="Right 3rd Toe"
                                    styleClass="mxw_100"
                                    value={LesserrRdrop23rd}
                                    placeholder="None"
                                    onChange={handleLesserrRdrop23rd}
                                  />
                                )}
                              </div>
                              <div className="col-md-6 col-sm-6">
                                <RadioSwitch
                                  labelLeft="Absent"
                                  labelRight="Present"
                                  idLeft="LesserradioRL23rd1"
                                  idRight="LesserradioRR23rd1"
                                  name="LesserradioRL3rd"
                                  title={
                                    Toe3rd ? "Callus (Left)*" : "Callus (Left)"
                                  }
                                  styleClass=""
                                  value={LesserradioL23rd}
                                  onChange={handleLesserradioL23rd}
                                  placeholder="select"
                                />
                                {LesserradioL23rd === "" && Toe3rd ? (
                                  <>
                                    {errmessage && (
                                      <span className="text-danger">
                                        This field is required
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}
                                {LesserradioL23rd === "1" && (
                                  <FullDropdown
                                    data={[
                                      {
                                        value: "Asymptomatic",
                                        label: "Asymptomatic",
                                      },
                                      {
                                        value: "Symptomatic",
                                        label: "Symptomatic",
                                      },
                                    ]}
                                    label="Left 3rd Toe"
                                    styleClass="mxw_100"
                                    value={LesserrLdrop23rd}
                                    placeholder="None"
                                    onChange={handleLesserrLdrop23rd}
                                  />
                                )}
                              </div>
                            </div>

                            {/* <div className="row">
                              <>
                                <div className="col-md-6 col-sm-6 mt-3">
                                  <KneeDegree
                                    powerAction="true"
                                    power={LesserrDegreeR3rd}
                                    onChangeR={handleLesserrDegreeR3rd}
                                    label={
                                      Toe3rd
                                        ? "IP Joint (Plantarflexion)*"
                                        : "IP Joint (Plantarflexion)"
                                    }
                                    nameL="Alignmeninput2R"
                                  />
                                  {LesserrDegreeR3rd === "" && (
                                    <>
                                      {errmessage && (
                                        <span className="text-danger">
                                          This field is required
                                        </span>
                                      )}
                                    </>
                                  )}
                                </div>
                                <div className="col-md-6 col-sm-6 mt-3">
                                  <KneeDegree
                                    powerAction="true"
                                    power={LesserrDegreeL3rd}
                                    onChangeR={handleLesserrDegreeL3rd}
                                    label={
                                      Toe3rd
                                        ? "IP Joint (Plantarflexion)*"
                                        : "IP Joint (Plantarflexion)"
                                    }
                                    nameL="Alignmeninput2L"
                                  />
                                  {LesserrDegreeL3rd === "" && (
                                    <>
                                      {errmessage && (
                                        <span className="text-danger">
                                          This field is required
                                        </span>
                                      )}
                                    </>
                                  )}
                                </div>
                              </>
                            </div> */}

                            <div className="row">
                              <h4 className="range_sl_title">
                                Motion
                                {/* {Toe3rd ? "Motion*" : "Motion"} */}
                              </h4>

                              <div className="col-md-6 col-sm-6">
                                <AssessmentPointDegree
                                  title={
                                    Toe3rd
                                      ? "MTP Joint (Dorsiflexion plus Plantarflexion) Right"
                                      : "MTP Joint (Dorsiflexion plus Plantarflexion) Right"
                                  }
                                  onChangeR={handleMotion3ndRtext}
                                  onChangeL={handleMotion3ndLtext}
                                  power={Motion3ndRtext}
                                  kg={Motion3ndLtext}
                                  labelL="Passive"
                                  labelR={"Active" + (Toe3rd ? "*" : "")}
                                  nameR="Flexion Right"
                                  nameL="Flexion Left"
                                  viewValidation={Toe3rd}
                                  validatorL={validatorL}
                                  validator={validator}
                                />
                              </div>

                              <div className="col-md-6 col-sm-6">
                                <AssessmentPointDegree
                                  title={
                                    Toe3rd
                                      ? "MTP Joint (Dorsiflexion plus Plantarflexion) Left"
                                      : "MTP Joint (Dorsiflexion plus Plantarflexion) Left"
                                  }
                                  onChangeR={handleMotion3ndLRtext}
                                  onChangeL={handleMotion3ndLLtext}
                                  power={Motion3ndLRtext}
                                  kg={Motion3ndLLtext}
                                  labelL="Passive"
                                  labelR={"Active" + (Toe3rd ? "*" : "")}
                                  nameR="Flexion Right"
                                  nameL="Flexion Left"
                                  viewValidation={Toe3rd}
                                  validatorL={validatorL}
                                  validator={validator}
                                />
                              </div>

                              <div className="col-md-6 col-sm-6 mt-4">
                                <AssessmentPointDegree
                                  title="IP Joint (Plantarflexion) Right"
                                  onChangeR={handleMotion3nRtext}
                                  onChangeL={handleMotion3nlRtext}
                                  power={Motion3nRtext}
                                  kg={Motion3nlRtext}
                                  labelL="Passive"
                                  labelR={"Active" + (Toe3rd ? "*" : "")}
                                  nameR="Flexion Right"
                                  nameL="Flexion Left"
                                  viewValidation={Toe3rd}
                                  validatorL={validatorL}
                                  validator={validator}
                                />
                              </div>

                              <div className="col-md-6 col-sm-6 mt-4">
                                <AssessmentPointDegree
                                  title="IP Joint (Plantarflexion) Left"
                                  onChangeR={handleMotion3ndRRtext}
                                  onChangeL={handleMotion3ndRLtext}
                                  power={Motion3ndRRtext}
                                  kg={Motion3ndRLtext}
                                  labelL="Passive"
                                  labelR={"Active" + (Toe3rd ? "*" : "")}
                                  nameR="Flexion Right"
                                  nameL="Flexion Left"
                                  viewValidation={Toe3rd}
                                  validatorL={validatorL}
                                  validator={validator}
                                />
                              </div>
                            </div>
                            <h4 className="range_sl_title">
                              {Toe3rd ? "Stability" : "Stability"}
                            </h4>
                            <div className="row mt-3">
                              <div className="col-md-6 col-sm-6">
                                <RadioSwitch
                                  labelLeft="Stable"
                                  labelRight="Unstable"
                                  idLeft="SpecifyRradioL23rd1"
                                  idRight="SpecifyRradioR23rd1"
                                  name="SpecifyRradioL3rd"
                                  title={
                                    Toe3rd
                                      ? "MTP-IP, in all directions (Right)*"
                                      : "MTP-IP, in all directions (Right)"
                                  }
                                  styleClass=""
                                  value={SpecifyRradio23rd}
                                  onChange={handleSpecifyRradio23rd}
                                  placeholder="select"
                                />
                                {SpecifyRradio23rd === "" && Toe3rd ? (
                                  <>
                                    {errmessage && (
                                      <span className="text-danger">
                                        This field is required
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="col-md-6 col-sm-6">
                                <RadioSwitch
                                  labelLeft="Stable"
                                  labelRight="Unstable"
                                  idLeft="SpecifyLradioL23rd1"
                                  idRight="SpecifyLradioR23rd1"
                                  name="SpecifyLradioL3rd"
                                  title={
                                    Toe3rd
                                      ? "MTP-IP, in all directions (Left)*"
                                      : "MTP-IP, in all directions (Left)"
                                  }
                                  styleClass=""
                                  value={SpecifyLradio23rd}
                                  onChange={handleSpecifyLradio23rd}
                                  placeholder="select"
                                />
                                {SpecifyLradio23rd === "" && Toe3rd ? (
                                  <>
                                    {errmessage && (
                                      <span className="text-danger">
                                        This field is required
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>

                {/* 3rd Toe end */}

                <div
                  id="4thtoe"
                  className={`accordion_tabpanel ${assessmentStyles.accordion_tabpanel}`}
                >
                  <Accordion
                    activeKey={activeKeytoe3}
                    onSelect={handleAccordionToggleToe3}
                  >
                    <Accordion.Item eKey="5" defaultActiveKey="5" eventKey="5">
                      <Accordion.Header>4th Toe</Accordion.Header>
                      <Accordion.Body>
                        <div className="mb_30" id="4thtoe">
                          <div className="row">
                            {/* <h4 className="range_sl_title">{"4th Lesser Toe"}</h4> */}
                            <div className="row"></div>
                            <div className="row">
                              <div className="col-md-6 col-sm-6">
                                <FullDropdown
                                  data={[
                                    {
                                      value: "1",
                                      label: " Good, lesser toes well aligned",
                                    },
                                    {
                                      value: "2",
                                      label:
                                        "Fair, some degree of lesser toe malalignment observed, no symptoms",
                                    },
                                    {
                                      value: "3",
                                      label:
                                        "Poor, severe malalignment, symptoms",
                                    },
                                  ]}
                                  label={
                                    Toe4th
                                      ? "Alignment (Right)*"
                                      : "Alignment (Right)"
                                  }
                                  styleClass="mxw_100"
                                  value={LesserR24th}
                                  placeholder="None"
                                  onChange={handleLesserR24th}
                                />
                                {LesserR24th === "" && Toe4th ? (
                                  <>
                                    {errmessage && (
                                      <span className="text-danger">
                                        This field is required
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="col-md-6 col-sm-6">
                                <FullDropdown
                                  data={[
                                    // { value: "none", label: "None" },
                                    {
                                      value: "1",
                                      label: " Good, lesser toes well aligned",
                                    },
                                    {
                                      value: "2",
                                      label:
                                        "Fair, some degree of lesser toe malalignment observed, no symptoms",
                                    },
                                    {
                                      value: "3",
                                      label:
                                        "Poor, severe malalignment, symptoms",
                                    },
                                  ]}
                                  label={
                                    Toe4th
                                      ? "Alignment (Left)*"
                                      : "Alignment (Left)"
                                  }
                                  styleClass="mxw_100"
                                  value={LesserL24th}
                                  placeholder="None"
                                  onChange={handleLesserL24th}
                                />
                                {LesserL24th === "" && Toe4th ? (
                                  <>
                                    {errmessage && (
                                      <span className="text-danger">
                                        This field is required
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-6 col-sm-6">
                                <RadioSwitch
                                  labelLeft="Absent"
                                  labelRight="Present"
                                  idLeft="Lesser1L24th"
                                  idRight="Lesser1R24th"
                                  name="Lesser1L4th"
                                  title={
                                    Toe4th
                                      ? "Callus (Right)*"
                                      : "Callus (Right)"
                                  }
                                  styleClass=""
                                  value={LesserradioR24th}
                                  onChange={handleLesserradioR24th}
                                  placeholder="select"
                                />
                                {LesserradioR24th === "" && Toe4th ? (
                                  <>
                                    {errmessage && (
                                      <span className="text-danger">
                                        This field is required
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}
                                {/* {LeftABNORMAL === "13" && ( */}
                                {/* <div className="col-md-6 col-sm-6"> */}
                                {LesserradioR24th === "1" && (
                                  <FullDropdown
                                    data={[
                                      //   { value: "none", label: "None" },
                                      {
                                        value: "Asymptomatic",
                                        label: "Asymptomatic",
                                      },
                                      {
                                        value: "Symptomatic",
                                        label: "Symptomatic",
                                      },
                                    ]}
                                    label="Right 4th Toe"
                                    styleClass="mxw_100"
                                    value={LesserrRdrop24th}
                                    placeholder="None"
                                    onChange={handleLesserrRdrop24th}
                                  />
                                )}
                                {/* </div> */}
                                {/* )} */}
                              </div>
                              <div className="col-md-6 col-sm-6">
                                <RadioSwitch
                                  labelLeft="Absent"
                                  labelRight="Present"
                                  idLeft="LesserradioRL24th"
                                  idRight="LesserradioRR24th"
                                  name="LesserradioRL4th"
                                  title={
                                    Toe4th ? "Callus (Left)*" : "Callus  (Left)"
                                  }
                                  styleClass=""
                                  value={LesserradioL24th}
                                  onChange={handleLesserradioL24th}
                                  placeholder="select"
                                />
                                {LesserradioL24th === "" && Toe4th ? (
                                  <>
                                    {errmessage && (
                                      <span className="text-danger">
                                        This field is required
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}
                                {/* {LeftABNORMAL === "13" && ( */}
                                {/* <div className="col-md-6 col-sm-6"> */}
                                {LesserradioL24th === "1" && (
                                  <FullDropdown
                                    data={[
                                      //   { value: "none", label: "None" },
                                      {
                                        value: "Asymptomatic",
                                        label: "Asymptomatic",
                                      },
                                      {
                                        value: "Symptomatic",
                                        label: "Symptomatic",
                                      },
                                    ]}
                                    label="Left 4th Toe"
                                    styleClass="mxw_100"
                                    value={LesserrLdrop24th}
                                    placeholder="None"
                                    onChange={handleLesserrLdrop24th}
                                  />
                                )}
                              </div>
                            </div>

                            {/* <div className="row">
                              <>
                                <div className="col-md-6 col-sm-6 mt-3">
                                  <KneeDegree
                                    powerAction="true"
                                    power={LesserrDegreeR4th}
                                    onChangeR={handleLesserrDegreeR4th}
                                    label={
                                      Toe4th
                                        ? "IP Joint (Plantarflexion)*"
                                        : "IP Joint (Plantarflexion)"
                                    }
                                    nameL="Alignmeninput2R"
                                  />
                                  {LesserrDegreeR4th === "" && (
                                    <>
                                      {errmessage && (
                                        <span className="text-danger">
                                          This field is required
                                        </span>
                                      )}
                                    </>
                                  )}
                                </div>
                                <div className="col-md-6 col-sm-6 mt-3">
                                  <KneeDegree
                                    powerAction="true"
                                    power={LesserrDegreeL4th}
                                    onChangeR={handleLesserrDegreeL4th}
                                    label={
                                      Toe4th
                                        ? "IP Joint (Plantarflexion)*"
                                        : "IP Joint (Plantarflexion)"
                                    }
                                    nameL="Alignmeninput2L"
                                  />
                                  {LesserrDegreeL4th === "" && (
                                    <>
                                      {errmessage && (
                                        <span className="text-danger">
                                          This field is required
                                        </span>
                                      )}
                                    </>
                                  )}
                                </div>
                              </>
                            </div> */}

                            <div className="row">
                              <h4 className="range_sl_title">
                                Motion
                                {/* {Toe4th ? "Motion*" : "Motion"} */}
                              </h4>

                              <div className="col-md-6 col-sm-6">
                                <AssessmentPointDegree
                                  title={
                                    AnkleHL
                                      ? "MTP Joint (Dorsiflexion plus Plantarflexion) Right"
                                      : "MTP Joint (Dorsiflexion plus Plantarflexion) Right"
                                  }
                                  onChangeR={handleMotion4ndRtext}
                                  onChangeL={handleMotion4ndLtext}
                                  power={Motion4ndRtext}
                                  kg={Motion4ndLtext}
                                  labelL="Passive"
                                  labelR={"Active" + (Toe4th ? "*" : "")}
                                  nameR="Flexion Right"
                                  nameL="Flexion Left"
                                  viewValidation={Toe4th}
                                  validatorL={validatorL}
                                  validator={validator}
                                />
                              </div>

                              <div className="col-md-6 col-sm-6">
                                <AssessmentPointDegree
                                  title={
                                    AnkleHL
                                      ? "MTP Joint (Dorsiflexion plus Plantarflexion) Left"
                                      : "MTP Joint (Dorsiflexion plus Plantarflexion) Left"
                                  }
                                  onChangeR={handleMotion4ndLRtext}
                                  onChangeL={handleMotion4ndLLtext}
                                  power={Motion4ndLRtext}
                                  kg={Motion4ndLLtext}
                                  labelL="Passive"
                                  labelR={"Active" + (Toe4th ? "*" : "")}
                                  nameR="Flexion Right"
                                  nameL="Flexion Left"
                                  viewValidation={Toe4th}
                                  validatorL={validatorL}
                                  validator={validator}
                                />
                              </div>

                              <div className="col-md-6 col-sm-6 mt-4">
                                <AssessmentPointDegree
                                  title="IP Joint (Plantarflexion) Right"
                                  onChangeR={handleMotion4nRtext}
                                  onChangeL={handleMotion4nlRtext}
                                  power={Motion4nRtext}
                                  kg={Motion4nlRtext}
                                  labelL="Passive"
                                  labelR={"Active" + (Toe4th ? "*" : "")}
                                  nameR="Flexion Right"
                                  nameL="Flexion Left"
                                  viewValidation={Toe4th}
                                  validatorL={validatorL}
                                  validator={validator}
                                />
                              </div>

                              <div className="col-md-6 col-sm-6 mt-4">
                                <AssessmentPointDegree
                                  title="IP Joint (Plantarflexion) Left"
                                  onChangeR={handleMotion4ndRRtext}
                                  onChangeL={handleMotion4ndRLtext}
                                  power={Motion4ndRRtext}
                                  kg={Motion4ndRLtext}
                                  labelL="Passive"
                                  labelR={"Active" + (Toe4th ? "*" : "")}
                                  nameR="Flexion Right"
                                  nameL="Flexion Left"
                                  viewValidation={Toe4th}
                                  validatorL={validatorL}
                                  validator={validator}
                                />
                              </div>
                            </div>
                            <h4 className="range_sl_title">
                              {Toe4th ? "Stability" : "Stability"}
                            </h4>
                            <div className="row mt-3">
                              <div className="col-md-6 col-sm-6">
                                <RadioSwitch
                                  labelLeft="Stable"
                                  labelRight="Unstable"
                                  idLeft="SpecifyRradioL24th"
                                  idRight="SpecifyRradioR24th"
                                  name="SpecifyRradioL4th"
                                  title={
                                    Toe4th
                                      ? "MTP-IP, in all directions (Right)*"
                                      : "MTP-IP, in all directions (Right)"
                                  }
                                  styleClass=""
                                  value={SpecifyRradio24th}
                                  onChange={handleSpecifyRradio24th}
                                  placeholder="select"
                                />
                                {SpecifyRradio24th === "" && Toe4th ? (
                                  <>
                                    {errmessage && (
                                      <span className="text-danger">
                                        This field is required
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="col-md-6 col-sm-6">
                                <RadioSwitch
                                  labelLeft="Stable"
                                  labelRight="Unstable"
                                  idLeft="SpecifyLradioL24th"
                                  idRight="SpecifyLradioR24th"
                                  name="SpecifyLradioL4th"
                                  title={
                                    Toe4th
                                      ? "MTP-IP, in all directions (Left)*"
                                      : "MTP-IP, in all directions (Left)"
                                  }
                                  styleClass=""
                                  value={SpecifyLradio24th}
                                  onChange={handleSpecifyLradio24th}
                                  placeholder="select"
                                />
                                {SpecifyLradio24th === "" && Toe4th ? (
                                  <>
                                    {errmessage && (
                                      <span className="text-danger">
                                        This field is required
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>

                {/* 4th Toe end */}
                <div
                  id="5thtoe"
                  className={`accordion_tabpanel ${assessmentStyles.accordion_tabpanel}`}
                >
                  <Accordion
                    activeKey={activeKeytoe4}
                    onSelect={handleAccordionToggleToe4}
                  >
                    <Accordion.Item eKey="6" defaultActiveKey="6" eventKey="6">
                      <Accordion.Header>5th Toe</Accordion.Header>
                      <Accordion.Body>
                        <div className="mb_30" id="5thtoe">
                          <div className="row">
                            {/* <h4 className="range_sl_title">{"5th Lesser Toe"}</h4> */}
                            <div className="row"></div>
                            <div className="row">
                              <div className="col-md-6 col-sm-6">
                                <FullDropdown
                                  data={[
                                    // { value: "none", label: "None" },
                                    {
                                      value: "1",
                                      label: " Good, lesser toes well aligned",
                                    },
                                    {
                                      value: "2",
                                      label:
                                        "Fair, some degree of lesser toe malalignment observed, no symptoms",
                                    },
                                    {
                                      value: "3",
                                      label:
                                        "Poor, severe malalignment, symptoms",
                                    },
                                  ]}
                                  label={
                                    Toe5th
                                      ? "Alignment (Right)*"
                                      : "Alignment (Right)"
                                  }
                                  styleClass="mxw_100"
                                  value={LesserR25th}
                                  placeholder="None"
                                  onChange={handleLesserR25th}
                                />
                                {LesserR25th === "" && Toe5th ? (
                                  <>
                                    {errmessage && (
                                      <span className="text-danger">
                                        This field is required
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="col-md-6 col-sm-6">
                                <FullDropdown
                                  data={[
                                    // { value: "none", label: "None" },
                                    {
                                      value: "1",
                                      label: " Good, lesser toes well aligned",
                                    },
                                    {
                                      value: "2",
                                      label:
                                        "Fair, some degree of lesser toe malalignment observed, no symptoms",
                                    },
                                    {
                                      value: "3",
                                      label:
                                        "Poor, severe malalignment, symptoms",
                                    },
                                  ]}
                                  label={
                                    Toe5th
                                      ? "Alignment (Left)*"
                                      : "Alignment (Left)"
                                  }
                                  styleClass="mxw_100"
                                  value={LesserL25th}
                                  placeholder="None"
                                  onChange={handleLesserL25th}
                                />
                                {LesserL25th === "" && Toe5th ? (
                                  <>
                                    {errmessage && (
                                      <span className="text-danger">
                                        This field is required
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-6 col-sm-6">
                                <RadioSwitch
                                  labelLeft="Absent"
                                  labelRight="Present"
                                  idLeft="Lesser1L25th"
                                  idRight="Lesser1R25th"
                                  name="Lesser1L5th"
                                  title={
                                    Toe5th
                                      ? "Callus (Right)*"
                                      : "Callus (Right)"
                                  }
                                  styleClass=""
                                  value={LesserradioR25th}
                                  onChange={handleLesserradioR25th}
                                  placeholder="select"
                                />
                                {LesserradioR25th === "" && Toe5th ? (
                                  <>
                                    {errmessage && (
                                      <span className="text-danger">
                                        This field is required
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}
                                {LesserradioR25th === "1" && (
                                  <FullDropdown
                                    data={[
                                      //   { value: "none", label: "None" },
                                      {
                                        value: "Asymptomatic",
                                        label: "Asymptomatic",
                                      },
                                      {
                                        value: "Symptomatic",
                                        label: "Symptomatic",
                                      },
                                    ]}
                                    label="Right 5th Toe"
                                    styleClass="mxw_100"
                                    value={LesserrRdrop25th}
                                    placeholder="None"
                                    onChange={handleLesserrRdrop25th}
                                  />
                                )}
                                {/* </div> */}
                                {/* )} */}
                              </div>
                              <div className="col-md-6 col-sm-6">
                                <RadioSwitch
                                  labelLeft="Absent"
                                  labelRight="Present"
                                  idLeft="LesserradioRL25th"
                                  idRight="LesserradioRR25th"
                                  name="LesserradioRL5th"
                                  title={
                                    Toe5th ? "Callus (Left)*" : "Callus (Left)"
                                  }
                                  styleClass=""
                                  value={LesserradioL25th}
                                  onChange={handleLesserradioL25th}
                                  placeholder="select"
                                />
                                {LesserradioL25th === "" && Toe5th ? (
                                  <>
                                    {errmessage && (
                                      <span className="text-danger">
                                        This field is required
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}
                                {LesserradioL25th === "1" && (
                                  <FullDropdown
                                    data={[
                                      //   { value: "none", label: "None" },
                                      {
                                        value: "Asymptomatic",
                                        label: "Asymptomatic",
                                      },
                                      {
                                        value: "Symptomatic",
                                        label: "Symptomatic",
                                      },
                                    ]}
                                    label="Left 5th Toe"
                                    styleClass="mxw_100"
                                    value={LesserrLdrop25th}
                                    placeholder="None"
                                    onChange={handleLesserrLdrop25th}
                                  />
                                )}
                              </div>
                            </div>

                            {/* <div className="row">
                              <>
                                <div className="col-md-6 col-sm-6 mt-3">
                                  <KneeDegree
                                    powerAction="true"
                                    power={LesserrDegreeR5th}
                                    onChangeR={handleLesserrDegreeR5th}
                                    label={
                                      Toe5th
                                        ? "IP Joint (Plantarflexion)*"
                                        : "IP Joint (Plantarflexion)"
                                    }
                                    nameL="Alignmeninput2R"
                                  />
                                  {LesserrDegreeR5th === "" && (
                                    <>
                                      {errmessage && (
                                        <span className="text-danger">
                                          This field is required
                                        </span>
                                      )}
                                    </>
                                  )}
                                </div>
                                <div className="col-md-6 col-sm-6 mt-3">
                                  <KneeDegree
                                    powerAction="true"
                                    power={LesserrDegreeL5th}
                                    onChangeR={handleLesserrDegreeL5th}
                                    label={
                                      Toe5th
                                        ? "IP Joint (Plantarflexion)*"
                                        : "IP Joint (Plantarflexion)"
                                    }
                                    nameL="Alignmeninput2L"
                                  />
                                  {LesserrDegreeL5th === "" && (
                                    <>
                                      {errmessage && (
                                        <span className="text-danger">
                                          This field is required
                                        </span>
                                      )}
                                    </>
                                  )}
                                </div>
                              </>
                            </div> */}

                            <div className="row">
                              <h4 className="range_sl_title">
                                Motion
                                {/* {Toe5th ? "Motion*" : "Motion"} */}
                              </h4>

                              <div className="col-md-6 col-sm-6">
                                <AssessmentPointDegree
                                  title={
                                    AnkleHL
                                      ? "MTP Joint (Dorsiflexion plus Plantarflexion) Right"
                                      : "MTP Joint (Dorsiflexion plus Plantarflexion) Right"
                                  }
                                  onChangeR={handleMotion5ndRtext}
                                  onChangeL={handleMotion5ndLtext}
                                  power={Motion5ndRtext}
                                  kg={Motion5ndLtext}
                                  labelL="Passive"
                                  labelR={"Active" + (Toe5th ? "*" : "")}
                                  nameR="Flexion Right"
                                  nameL="Flexion Left"
                                  viewValidation={Toe5th}
                                  validatorL={validatorL}
                                  validator={validator}
                                />
                              </div>

                              <div className="col-md-6 col-sm-6">
                                <AssessmentPointDegree
                                  title={
                                    AnkleHL
                                      ? "MTP Joint (Dorsiflexion plus Plantarflexion) Left"
                                      : "MTP Joint (Dorsiflexion plus Plantarflexion) Left"
                                  }
                                  onChangeR={handleMotion5ndLRtext}
                                  onChangeL={handleMotion5ndLLtext}
                                  power={Motion5ndLRtext}
                                  kg={Motion5ndLLtext}
                                  labelL="Passive"
                                  labelR={"Active" + (Toe5th ? "*" : "")}
                                  nameR="Flexion Right"
                                  nameL="Flexion Left"
                                  viewValidation={Toe5th}
                                  validatorL={validatorL}
                                  validator={validator}
                                />
                              </div>

                              <div className="col-md-6 col-sm-6 mt-4">
                                <AssessmentPointDegree
                                  title="IP Joint (Plantarflexion) Right"
                                  onChangeR={handleMotion5nRtext}
                                  onChangeL={handleMotion5nlRtext}
                                  power={Motion5nRtext}
                                  kg={Motion5nlRtext}
                                  labelL="Passive"
                                  labelR={"Active" + (Toe5th ? "*" : "")}
                                  nameR="Flexion Right"
                                  nameL="Flexion Left"
                                  viewValidation={Toe5th}
                                  validatorL={validatorL}
                                  validator={validator}
                                />
                              </div>

                              <div className="col-md-6 col-sm-6 mt-4">
                                <AssessmentPointDegree
                                  title="IP Joint (Plantarflexion) Left"
                                  onChangeR={handleMotion5ndRRtext}
                                  onChangeL={handleMotion5ndRLtext}
                                  power={Motion5ndRRtext}
                                  kg={Motion5ndRLtext}
                                  labelL="Passive"
                                  labelR={"Active" + (Toe5th ? "*" : "")}
                                  nameR="Flexion Right"
                                  nameL="Flexion Left"
                                  viewValidation={Toe5th}
                                  validatorL={validatorL}
                                  validator={validator}
                                />
                              </div>
                            </div>

                            <h4 className="range_sl_title">
                              {Toe5th ? "Stability" : "Stability"}
                            </h4>
                            <div className="row mt-3">
                              <div className="col-md-6 col-sm-6">
                                <RadioSwitch
                                  labelLeft="Stable"
                                  labelRight="Unstable"
                                  idLeft="SpecifyRradioL25th"
                                  idRight="SpecifyRradioR25th"
                                  name="SpecifyRradioL5th"
                                  title={
                                    Toe5th
                                      ? "MTP-IP, in all directions (Right)*"
                                      : "MTP-IP, in all directions (Right)"
                                  }
                                  styleClass=""
                                  value={SpecifyRradio25th}
                                  onChange={handleSpecifyRradio25th}
                                  placeholder="select"
                                />
                                {SpecifyRradio25th === "" && Toe5th ? (
                                  <>
                                    {errmessage && (
                                      <span className="text-danger">
                                        This field is required
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="col-md-6 col-sm-6">
                                <RadioSwitch
                                  labelLeft="Stable"
                                  labelRight="Unstable"
                                  idLeft="SpecifyLradioL25th"
                                  idRight="SpecifyLradioR25th"
                                  name="SpecifyLradioL5th"
                                  title={
                                    Toe5th
                                      ? "MTP-IP, in all directions (Left)*"
                                      : "MTP-IP, in all directions (Left)"
                                  }
                                  styleClass=""
                                  value={SpecifyLradio25th}
                                  onChange={handleSpecifyLradio25th}
                                  placeholder="select"
                                />
                                {SpecifyLradio25th === "" && Toe5th ? (
                                  <>
                                    {errmessage && (
                                      <span className="text-danger">
                                        This field is required
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>

                <div
                  id="Deformity"
                  className={`accordion_tabpanel ${assessmentStyles.accordion_tabpanel}`}
                >
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eKey="0">
                      <Accordion.Header>Other Deformity</Accordion.Header>
                      <Accordion.Body>
                        <div className="row mb_30" id="Deformity">
                          {/* <h4 className="range_sl_title">{translation.DEFORMITY}</h4> */}

                          <div className="col-md-6 col-sm-6">
                            <RadioSwitch
                              labelLeft="Absent"
                              labelRight="Present"
                              idLeft="FixednveR"
                              idRight="FixedPveR"
                              name="FixedR"
                              title="Right foot and ankle"
                              styleClass=""
                              value={ir4Right}
                              onChange={ir4ChangeR}
                              placeholder="select"
                            />
                            {ir4Right === "1" && (
                              <>
                                <CreatableSelect
                                  isClearable
                                  isMulti
                                  value={value}
                                  options={options}
                                  onChange={handleChange}
                                  // onCreateOption={handleCreate}
                                  className="multi_select_drp"
                                />
                                {x.length === 1 && (
                                  <>
                                    <div className="col-md-6 col-sm-6">
                                      <InputField
                                        label=""
                                        value={RDEFORMITY}
                                        type="text"
                                        placeholder="Others Field"
                                        styleClass="mxw_100"
                                        onChange={handleRDEFORMITY}
                                      />
                                    </div>
                                  </>
                                )}
                              </>
                            )}
                          </div>
                          <div className="col-md-6 col-sm-6">
                            <RadioSwitch
                              labelLeft="Absent"
                              labelRight="Present"
                              idLeft="FixednveL"
                              idRight="FixedPveL"
                              name="FixedL"
                              title="Left foot and ankle"
                              styleClass=""
                              value={ir4Left}
                              onChange={ir4ChangeL}
                              placeholder="select"
                            />
                            {ir4Left === "1" && (
                              <>
                                <CreatableSelect
                                  isClearable
                                  isMulti
                                  value={value2}
                                  options={options}
                                  onChange={handleChange2}
                                  // onCreateOption={handleCreate2}
                                  className="multi_select_drp"
                                />
                                {y.length === 1 && (
                                  <>
                                    <div className="col-md-6 col-sm-6">
                                      <InputField
                                        label=""
                                        value={LDEFORMITY}
                                        type="text"
                                        placeholder="Others Field"
                                        styleClass="mxw_100"
                                        onChange={handleLDEFORMITY}
                                      />
                                    </div>
                                  </>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
                <div
                  id="Ligamentous"
                  className={`accordion_tabpanel ${assessmentStyles.accordion_tabpanel}`}
                >
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eKey="0">
                      <Accordion.Header>Ligamentous Laxity</Accordion.Header>
                      <Accordion.Body>
                        <div className="row mb_30" id="Ligamentous">
                          {/* <h4 className="range_sl_title">{"Ligamentous laxity (Beighton Score)"}</h4> */}
                          <div className="col-md-6 col-sm-6">
                            <ElbowFeedbackScoreRangeSlider
                              label={"Right Ligamentous laxity"}
                              classes=""
                              value={LigamentousR}
                              onChange={handleFlexionlR}
                              sliderData={[
                                { score: "0", label: "sign" },
                                { score: "1", label: "sign" },
                                {
                                  score: "2",
                                  label: "signs",
                                },
                                { score: "3", label: "signs" },
                                { score: "4", label: "signs" },
                                { score: "5", label: "signs" },
                              ]}
                            />
                          </div>
                          <div className="col-md-6 col-sm-6">
                            <ElbowFeedbackScoreRangeSlider
                              label={"Left Ligamentous laxity"}
                              classes=""
                              value={LigamentousL}
                              onChange={handleFlexionlL}
                              sliderData={[
                                { score: "0", label: "sign" },
                                { score: "1", label: "sign" },
                                {
                                  score: "2",
                                  label: "signs",
                                },
                                { score: "3", label: "signs" },
                                { score: "4", label: "signs" },
                                { score: "5", label: "signs" },
                              ]}
                            />
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>

                <div
                  id="Others"
                  className={`accordion_tabpanel ${assessmentStyles.accordion_tabpanel}`}
                >
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eKey="0">
                      <Accordion.Header>Others Tests</Accordion.Header>
                      <Accordion.Body>
                        <div className="row mb_30" id="Others">
                          {/* <h4 className="range_sl_title">{"Other tests"}</h4> */}
                          <div className="col-md-6 col-sm-6">
                            <InputField
                              label="Right foot and ankle"
                              value={LigamentousRtext}
                              type="textarea"
                              placeholder="Specify"
                              styleClass="mxw_100"
                              onChange={handleLigamentousRtext}
                            />
                          </div>
                          <div className="col-md-6 col-sm-6">
                            <InputField
                              label="Left foot and ankle"
                              value={LigamentousLtext}
                              type="textarea"
                              placeholder="Specify "
                              styleClass="mxw_100"
                              onChange={handleLigamentousLtext}
                            />
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
                {doctorEnable === "true" && (
                  <div className="row mb_30 button_box">
                    <div className="col-md-6 col-sm-6"></div>
                    <div className="col-md-6 col-sm-6 text-end">
                      <button
                        className="next_btn btn_fill"
                        onClick={() => handleSubmit()}
                      >
                        {translation.submit}
                        <span>
                          <img src={nextIcon} alt="" />
                          {/* <img src={nextWhiteIcon} alt="" /> */}
                        </span>
                      </button>
                    </div>
                  </div>
                )}

                {doctorEnable !== "true" && (
                  <div className="row mb_30 button_box">
                    <div className="col-md-6 col-sm-6">
                      {/* <Link to="/foot-doctor-feedback" className="assm_back_btn">
         <img src={backIcon} alt="" />
         <span>Back</span>
     </Link> */}
                    </div>

                    <div className="col-md-6 col-sm-6 text-end">
                      <button
                        type="submit"
                        className="next_btn btn_fill"
                        onClick={continueRedirect}
                      >
                        {/* {translation.next_button} */}
                        {translation.submit}
                        <span>
                          <img src={nextIcon} alt="" />
                          <img src={nextWhiteIcon} alt="" />
                        </span>
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* < SubmitModel
     showModal={displayConfirmationModal}
     
     /> */}
      <Promsschudle
        openModal={displayConfirmationModal}
        setDisplayConfirmationModal={setDisplayConfirmationModal}
        type={"21"}
        patientuserID={pId}
      />


      </>

    )}
    </>
  );
}
