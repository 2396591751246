import React, { useState, useCallback, useEffect } from 'react'
import { Link, useNavigate, useParams } from "react-router-dom";
import AssessmentHeader from '../../../custom/assessment-header/AssessmentHeader';
import HeaderInnerBreadcrumb from '../../../custom/heade-inner-breadcrumb/HeaderInnerBreadcrumb';
import FullDropdown from '../../../custom/dropdown/FullDropdown';
import InputField from "../../../custom/inputfield/InputField";
import RadioSwitch from "../../../custom/radio-switch/RadioSwitch";
import CreatableSelect from "react-select/creatable";
import backIcon from "../../../../images/back.png";
import nextWhiteIcon from "../../../../images/next-white.png";
import nextIcon from "../../../../images/next.png";
import RadioButton from "../../../custom/radio-button/RadioButton";
import { useDispatch, useSelector } from 'react-redux';
import useAxios from '../../../../axiosinstance';
import useTranslation from '../../../customHooks/doctorTranslation';
import FootAnkleTab from '../../tab-section/FootAnkleTab';
import { useSearchParams } from "react-router-dom";
import {
    updateFootFunctionScore,
    updateFootFunctionData,
    updateFootAnkle,
    updateFootAnklePainData,
    updateFootQolData,
    updateFootSatisfactionData,
    updateFootInstabilityData,
    updateFootSymptomsData,
    updateFootFeedbackfootankle,
    updateFootFeedbackfootankleField,
    updateFootComberlandData,
    updateFootSatisTotalscrore,
    updateAllData

} from "../../../../redux/actions/footAnkle";
import DoctorNameCustom from '../../../custom/name/DoctorNameCustom';


export default function DoctorfeedbackStep3() {
    const axiosInstance = useAxios()
    const translation = useTranslation();
    const { rId } = useParams();
    const [searchParams] = useSearchParams();
    let doctorEnable = searchParams.get("d");
    const queryParams = new URLSearchParams(window.location.search);
    const [functionSco] = useSelector((state) => state.footAnkle.functionScore);
    const [painSco] = useSelector((state) => state.footAnkle.painScore);
    const dId = queryParams.get("doctor");
    const pId = queryParams.get("patient");
    const dispatch = useDispatch();
    const [ankleR, setAnkleR] = useState("0");
    const [ankleL, setAnkleL] = useState("0");
    const [mtpR, setmtpR] = useState("0");
    const [mtpL, setmtpL] = useState("0");
    const [silfR, setSilfR] = useState("");
    const [silfL, setSilfL] = useState("");
    const [toesR, setToesR] = useState("");
    const [toesL, setToesL] = useState("");
    const [peekR, setPeekR] = useState("");
    const [peekL, setPeekL] = useState("");
    const [antR, setantR] = useState("");
    const [antL, setantL] = useState("");
    const [postR, setpostR] = useState("");
    const [postL, setpostL] = useState("");
    const [inversionR, setinversionR] = useState("");
    const [inversionL, setinversionL] = useState("");
    const [eversionR, seteversionR] = useState("");
    const [eversionL, seteversionL] = useState("");
    const [calfR, setcalfR] = useState("");
    const [calfL, setcalfL] = useState("");
    const [externalR, setexternalR] = useState("");
    const [externalL, setexternalL] = useState("");
    const [colemanR, setcolemanR] = useState("");
    const [colemanL, setcolemanL] = useState("");
    const [doubleR, setdoubleR] = useState("");
    const [doubleL, setdoubleL] = useState("");
    const [singleR, setsingleR] = useState("");
    const [singleL, setsingleL] = useState("");
    const [thompR, setthompR] = useState("");
    const [thompL, setthompL] = useState("");
    const [mulderR, setmulderR] = useState("");
    const [mulderL, setmulderL] = useState("");
    const [crunchR, setcrunchR] = useState("");
    const [crunchL, setcrunchL] = useState("");
    const [laxityR, setlaxityR] = useState("");
    const [laxityL, setlaxityL] = useState("");
    const [mtpipR, setmtpipR] = useState("1");
    const [mtpipL, setmtpipL] = useState("1");


    const [chk, setChk] = useState(false)

    const navigate = useNavigate();
    const docFeedback = useSelector(
        (state) => state.footAnkle.doctorFeedback[0]
    );
    const TotalScore = useSelector(
        (state) => state.footAnkle.TotalScores[0]
    );

    const save = () => {
        axiosInstance.current
            .get(`extapp/forms/all_forms?resource_id=${rId}&type=21`)
            .then((res) => {

                dispatch(updateFootAnklePainData(res.data.data2[0].painScore));
                dispatch(updateFootSymptomsData(res.data.data2[0].symptomsScore));
                dispatch(updateFootAnkle(res.data.data2[0].anklePain));
                dispatch(updateFootInstabilityData(res.data.data2[0].instabilityScore));
                dispatch(updateFootComberlandData(res.data.data2[0].comberland));
                dispatch(updateFootQolData(res.data.data2[0].qolScore));
                dispatch(updateFootFunctionData(res.data.data2[0].functionScore));
                dispatch(
                    updateFootSatisfactionData(res.data.data2[0].satisfactionScore)
                );
                dispatch(updateFootSatisTotalscrore(res.data.data2[0].TotalScores))
                dispatch(updateFootFeedbackfootankle(res.data.data2[0].doctorFeedback));
                setChk(true);
            });
    };

    useEffect(() => {
        save();
    }, []);


    useEffect(() => {
        setAnkleR(docFeedback.ankleR)
        setAnkleL(docFeedback.ankleL)
        setmtpR(docFeedback.mtpR)
        setmtpL(docFeedback.mtpL)
        setSilfR(docFeedback.silfR)
        setSilfL(docFeedback.silfL)
        setToesR(docFeedback.toesR)
        setToesL(docFeedback.toesL)
        setPeekR(docFeedback.peekR)
        setPeekL(docFeedback.peekL)
        setantR(docFeedback.antR)
        setantL(docFeedback.antL)
        setpostR(docFeedback.postR)
        setpostL(docFeedback.postL)
        setinversionR(docFeedback.inversionR)
        setinversionL(docFeedback.inversionL)
        seteversionR(docFeedback.eversionR)
        seteversionL(docFeedback.eversionL)
        setcalfR(docFeedback.calfR)
        setcalfL(docFeedback.calfL)
        setexternalR(docFeedback.externalR)
        setexternalL(docFeedback.externalL)
        setcolemanR(docFeedback.colemanR)
        setcolemanL(docFeedback.colemanL)
        setdoubleR(docFeedback.doubleR)
        setdoubleL(docFeedback.doubleL)
        setsingleR(docFeedback.singleR)
        setsingleL(docFeedback.singleL)
        setthompR(docFeedback.thompR)
        setthompL(docFeedback.thompL)
        setmulderR(docFeedback.mulderR)
        setmulderL(docFeedback.mulderL)
        setcrunchR(docFeedback.crunchR)
        setcrunchL(docFeedback.crunchL)
        setlaxityR(docFeedback.laxityR)
        setlaxityL(docFeedback.laxityL)
        setmtpipR(docFeedback.mtpipR)
        setmtpipL(docFeedback.mtpipL)
    }, [docFeedback])

    const json = useSelector((state) => state.footAnkle.doctorFeedback);


    const updateDate = async () => {
        let response = await axiosInstance.current
            .put(`extapp/forms/update_doctor_feedback?resource_id=${rId}&action=1&ak_dc=${dId}&edit=1`, json, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
        if (response.status === 200) {
            // window.open(
            //     process.env.REACT_APP_DOCTOR_URL + 'patient-list',
            //     "_blank"
            //   );
            //   window.close()

            window.location.href=process.env.REACT_APP_DOCTOR_URL + 'patient-list'
        }
    };
    useEffect(()=>{
        const updateDate2 = async () => {
        const response = await axiosInstance.current.put(
          `extapp/forms/update_doctor_feedback?resource_id=${rId}&action=0&ak_dc=${dId}`,
          json,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
    
        if (response.status === 200) {
    
        }
        }
        if(chk){
          if(doctorEnable !== "true"){
        updateDate2()
        // updateDate1()
        }
        }
      },[json])

    const handleAnkleR = (e) => {
        dispatch(updateFootFeedbackfootankleField({
            "key": 'ankleR',
            "value": e
        }))
        setAnkleR(e)
    }
    const handleAnkleL = (e) => {
        dispatch(updateFootFeedbackfootankleField({
            "key": 'ankleL',
            "value": e
        }))
        setAnkleL(e)
    }
    const handleMtpR = (e) => {
        dispatch(updateFootFeedbackfootankleField({
            "key": 'mtpR',
            "value": e
        }))
        setmtpR(e)
    }
    const handleMtpL = (e) => {
        dispatch(updateFootFeedbackfootankleField({
            "key": 'mtpL',
            "value": e
        }))
        setmtpL(e)
    }
    const handleSilfR = (e) => {
        dispatch(updateFootFeedbackfootankleField({
            "key": 'silfR',
            "value": e
        }))
        setSilfR(e)
    }
    const handleSilfL = (e) => {
        dispatch(updateFootFeedbackfootankleField({
            "key": 'silfL',
            "value": e
        }))
        setSilfL(e)
    }
    const handleToesR = (e) => {
        dispatch(updateFootFeedbackfootankleField({
            "key": 'toesR',
            "value": e
        }))
        setToesR(e)
    }
    const handleToesL = (e) => {
        dispatch(updateFootFeedbackfootankleField({
            "key": 'toesL',
            "value": e
        }))
        setToesL(e)
    }
    const handlePeekR = (e) => {
        dispatch(updateFootFeedbackfootankleField({
            "key": 'peekR',
            "value": e
        }))
        setPeekR(e)
    }
    const handlePeekL = (e) => {
        dispatch(updateFootFeedbackfootankleField({
            "key": 'peekL',
            "value": e
        }))
        setPeekL(e)
    }
    const handleAntR = (e) => {
        dispatch(updateFootFeedbackfootankleField({
            "key": 'antR',
            "value": e
        }))
        setantR(e)
    }
    const handleAntL = (e) => {
        dispatch(updateFootFeedbackfootankleField({
            "key": 'antL',
            "value": e
        }))
        setantL(e)
    }
    const handlePostR = (e) => {
        dispatch(updateFootFeedbackfootankleField({
            "key": 'postR',
            "value": e
        }))
        setpostR(e)
    }
    const handlePostL = (e) => {
        dispatch(updateFootFeedbackfootankleField({
            "key": 'postL',
            "value": e
        }))
        setpostL(e)
    }
    const handleInversionR = (e) => {
        dispatch(updateFootFeedbackfootankleField({
            "key": 'inversionR',
            "value": e
        }))
        setinversionR(e)
    }
    const handleInversionL = (e) => {
        dispatch(updateFootFeedbackfootankleField({
            "key": 'inversionL',
            "value": e
        }))
        setinversionL(e)
    }
    const handleEversionR = (e) => {
        dispatch(updateFootFeedbackfootankleField({
            "key": 'eversionR',
            "value": e
        }))
        seteversionR(e)
    }
    const handleEversionL = (e) => {
        dispatch(updateFootFeedbackfootankleField({
            "key": 'eversionL',
            "value": e
        }))
        seteversionL(e)
    }
    const handleCalfR = (e) => {
        dispatch(updateFootFeedbackfootankleField({
            "key": 'calfR',
            "value": e
        }))
        setcalfR(e)
    }
    const handleCalfL = (e) => {
        dispatch(updateFootFeedbackfootankleField({
            "key": 'calfL',
            "value": e
        }))
        setcalfL(e)
    }
    const handleExternalR = (e) => {
        dispatch(updateFootFeedbackfootankleField({
            "key": 'externalR',
            "value": e
        }))
        setexternalR(e)
    }
    const handleExternalL = (e) => {
        dispatch(updateFootFeedbackfootankleField({
            "key": 'externalL',
            "value": e
        }))
        setexternalL(e)
    }
    const handleColemanR = (e) => {
        dispatch(updateFootFeedbackfootankleField({
            "key": 'colemanR',
            "value": e
        }))
        setcolemanR(e)
    }
    const handleColemanL = (e) => {
        dispatch(updateFootFeedbackfootankleField({
            "key": 'colemanL',
            "value": e
        }))
        setcolemanL(e)
    }
    const handleDoubleR = (e) => {
        dispatch(updateFootFeedbackfootankleField({
            "key": 'doubleR',
            "value": e
        }))
        setdoubleR(e)
    }
    const handleDoubleL = (e) => {
        dispatch(updateFootFeedbackfootankleField({
            "key": 'doubleL',
            "value": e
        }))
        setdoubleL(e)
    }
    const handleSingleR = (e) => {
        dispatch(updateFootFeedbackfootankleField({
            "key": 'singleR',
            "value": e
        }))
        setsingleR(e)
    }
    const handleSingleL = (e) => {
        dispatch(updateFootFeedbackfootankleField({
            "key": 'singleL',
            "value": e
        }))
        setsingleL(e)
    }
    const handleThompR = (e) => {
        dispatch(updateFootFeedbackfootankleField({
            "key": 'thompR',
            "value": e
        }))
        setthompR(e)
    }
    const handleThompL = (e) => {
        dispatch(updateFootFeedbackfootankleField({
            "key": 'thompL',
            "value": e
        }))
        setthompL(e)
    }
    const handleMulderR = (e) => {
        dispatch(updateFootFeedbackfootankleField({
            "key": 'mulderR',
            "value": e
        }))
        setmulderR(e)
    }
    const handleMulderL = (e) => {
        dispatch(updateFootFeedbackfootankleField({
            "key": 'mulderL',
            "value": e
        }))
        setmulderL(e)
    }
    const handleCrunchR = (e) => {
        dispatch(updateFootFeedbackfootankleField({
            "key": 'crunchR',
            "value": e
        }))
        setcrunchR(e)
    }
    const handleCrunchL = (e) => {
        dispatch(updateFootFeedbackfootankleField({
            "key": 'crunchL',
            "value": e
        }))
        setcrunchL(e)
    }
    const handlelaxityR = (e) => {
        dispatch(updateFootFeedbackfootankleField({
            "key": 'laxityR',
            "value": e
        }))
        setlaxityR(e)
    }
    const handlelaxityL = (e) => {
        dispatch(updateFootFeedbackfootankleField({
            "key": 'laxityL',
            "value": e
        }))
        setlaxityL(e)
    }

    const mtpiponchangeR = (e) => {
        dispatch(updateFootFeedbackfootankleField({
            "key": 'mtpipR',
            "value": e
        }))
        setmtpipR(e)
    }
    const mtpiponchangeL = (e) => {
        dispatch(updateFootFeedbackfootankleField({
            "key": 'mtpipL',
            "value": e
        }))
        setmtpipL(e)
    }
    const handleSubmit = () => {
        // updateDate1()
        updateDate();
    };

    return (
        <>
          <div class="d-flex justify-content-end">
          <DoctorNameCustom />
        </div>
            <div className="patient_assessment_form bg_white pd_10 pt_50">
                {doctorEnable === "true" && <FootAnkleTab rId={rId} />}
            </div>
          
            <div className="patient_assessment_form mb_100 bg_white pd_10 pt_50 content_fx_w">
                {/* <AssessmentHeader /> */}
                {/* <HeaderInnerBreadcrumb /> */}
                <div className="input_tab_box mb_30">
                    <h4 className="range_sl_title">{translation.STABILITY}</h4>
                    <div className="row">
                        <div className="col-md-6 col-sm-6 mb_10">
                            <FullDropdown
                                data={[

                                    { value: "1", label: "Stable" },
                                    { value: "0", label: "Definitely unstable" },

                                ]}
                                label="Ankle-hindfoot
                            (AP, varus-valgus) (Right)"
                                styleClass="mxw_70"
                                value={ankleR}
                                placeholder="None"
                                onChange={handleAnkleR}
                            />
                        </div>
                        <div className="col-md-6 col-sm-6 mb_10">
                            <FullDropdown
                                data={[

                                    { value: "1", label: "Stable" },
                                    { value: "0", label: "Definitely unstable" },

                                ]}
                                label="Ankle-hindfoot
                            (AP, varus-valgus) (Left)"
                                styleClass="mxw_70"
                                value={ankleL}
                                placeholder="None"
                                onChange={handleAnkleL}
                            />
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-md-6 col-sm-6 mb_10">
                            <div className="col-md-6 col-sm-6">
                                <RadioButton
                                    labelTitle="Right foot"
                                    label="Hallux"
                                    selected={mtpipR === "1"}
                                    onChange={mtpiponchangeR}
                                    styleClass="custom_radio_box large"
                                    value="1"
                                    id="mtpipRight1"
                                    name="mtpipRight"
                                />
                                <RadioButton
                                    label="2nd toe"
                                    selected={mtpipR === "2"}
                                    onChange={mtpiponchangeR}
                                    styleClass="custom_radio_box large"
                                    value="2"
                                    id="mtpipRight2"
                                    name="mtpipRight"
                                />
                                <RadioButton
                                    label="3rd toe"
                                    selected={mtpipR === "3"}
                                    onChange={mtpiponchangeR}
                                    styleClass="custom_radio_box large"
                                    value="3"
                                    id="mtpipRight3"
                                    name="mtpipRight"
                                />

                                <RadioButton
                                    label="4th toe"
                                    selected={mtpipR === "4"}
                                    onChange={mtpiponchangeR}
                                    styleClass="custom_radio_box large"
                                    value="4"
                                    id="mtpipRight4"
                                    name="mtpipRight"
                                />
                                <RadioButton
                                    label="5th toe"
                                    selected={mtpipR === "5"}
                                    onChange={mtpiponchangeR}
                                    styleClass="custom_radio_box large"
                                    value="5"
                                    id="mtpipRight5"
                                    name="mtpipRight"
                                />


                            </div>
                            {mtpipR && (
                                <FullDropdown
                                    data={[
                                        { value: "2", label: "Stable" },
                                        { value: "1", label: "Definitely unstable or able to dislocate" },
                                        { value: "0", label: "Callus, symptomatic" },
                                    ]}
                                    label="MTP-IP (Right)"
                                    styleClass="mxw_70"
                                    value={mtpR}
                                    placeholder="None"
                                    onChange={handleMtpR}
                                />)}
                        </div>
                        <div className="col-md-6 col-sm-6 mb_10">
                            <div className="col-md-6 col-sm-6">
                                <RadioButton
                                    labelTitle="Left foot"
                                    label="Hallux"
                                    selected={mtpipL === "1"}
                                    onChange={mtpiponchangeL}
                                    styleClass="custom_radio_box large"
                                    value="1"
                                    id="mtpipLeft1"
                                    name="mtpipLeft"
                                />
                                <RadioButton
                                    label="2nd toe"
                                    selected={mtpipL === "2"}
                                    onChange={mtpiponchangeL}
                                    styleClass="custom_radio_box large"
                                    value="2"
                                    id="mtpipLeft2"
                                    name="mtpipLeft"
                                />
                                <RadioButton
                                    label="3rd toe"
                                    selected={mtpipL === "3"}
                                    onChange={mtpiponchangeL}
                                    styleClass="custom_radio_box large"
                                    value="3"
                                    id="mtpipLeft3"
                                    name="mtpipLeft"
                                />

                                <RadioButton
                                    label="4th toe"
                                    selected={mtpipL === "4"}
                                    onChange={mtpiponchangeL}
                                    styleClass="custom_radio_box large"
                                    value="4"
                                    id="mtpipLeft4"
                                    name="mtpipLeft"
                                />
                                <RadioButton
                                    label="5th toe"
                                    selected={mtpipL === "5"}
                                    onChange={mtpiponchangeL}
                                    styleClass="custom_radio_box large"
                                    value="5"
                                    id="mtpipLeft5"
                                    name="mtpipLeft"
                                />


                            </div>
                            {mtpipL && (
                                <FullDropdown
                                    data={[
                                        { value: "2", label: "Stable" },
                                        { value: "1", label: "Definitely unstable or able to dislocate" },
                                        { value: "0", label: "Callus, symptomatic" },
                                    ]}
                                    label="MTP-IP (Left)"
                                    styleClass="mxw_70"
                                    value={mtpL}
                                    placeholder="None"
                                    onChange={handleMtpL}
                                />)}
                        </div>

                    </div>
                </div>

                <div className="input_tab_box mb_30">
                    <h4 className="range_sl_title">{translation.SPECIAL_TESTS}</h4>
                    <div className="row">
                        <div className="col-md-6 col-sm-6 mb_10">
                            <RadioSwitch
                                labelLeft="Positive"
                                labelRight="Negative"
                                idLeft="positivesilR"
                                idRight="negativesilL"
                                name="SilfverskioldR"
                                title={translation.Silfverskiold_R}
                                styleClass=""
                                value={silfR}
                                onChange={handleSilfR}
                                placeholder="select"
                            />
                        </div>
                        <div className="col-md-6 col-sm-6 mb_10">
                            <RadioSwitch
                                labelLeft="Positive"
                                labelRight="Negative"
                                idLeft="positivesilrR"
                                idRight="negativesilrL"
                                name="SilfverskioldL"
                                title={translation.Silfverskiold_L}
                                styleClass=""
                                value={silfL}
                                onChange={handleSilfL}
                                placeholder="select"
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 col-sm-6 mb_10">
                            <RadioSwitch
                                labelLeft="Positive"
                                labelRight="Negative"
                                idLeft="positivetoesR"
                                idRight="negativetoesL"
                                name="ToesR"
                                title={translation.Too_many_toes_sign_R}
                                styleClass=""
                                value={toesR}
                                onChange={handleToesR}
                                placeholder="select"
                            />
                        </div>
                        <div className="col-md-6 col-sm-6 mb_10">
                            <RadioSwitch
                                labelLeft="Positive"
                                labelRight="Negative"
                                idLeft="positivetoesrR"
                                idRight="negativetoesrL"
                                name="ToesL"
                                title={translation.Too_many_toes_sign_L}
                                styleClass=""
                                value={toesL}
                                onChange={handleToesL}
                                placeholder="select"
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 col-sm-6 mb_10">
                            <RadioSwitch
                                labelLeft="Positive"
                                labelRight="Negative"
                                idLeft="positivepeekR"
                                idRight="negativepeekL"
                                name="PeekR"
                                title={translation.Peek_a_boo_heel_sign_R}
                                styleClass=""
                                value={peekR}
                                onChange={handlePeekR}
                                placeholder="select"
                            />
                        </div>
                        <div className="col-md-6 col-sm-6 mb_10">
                            <RadioSwitch
                                labelLeft="Positive"
                                labelRight="Negative"
                                idLeft="positivepeekrR"
                                idRight="negativepeekrL"
                                name="PeekL"
                                title={translation.Peek_a_boo_heel_sign_L}
                                styleClass=""
                                value={peekL}
                                onChange={handlePeekL}
                                placeholder="select"
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 col-sm-6 mb_10">
                            <RadioSwitch
                                labelLeft="Positive"
                                labelRight="Negative"
                                idLeft="positiveantR"
                                idRight="negativeantL"
                                name="AnteriorR"
                                title={translation.Anterior_drawer_test_R}
                                styleClass=""
                                value={antR}
                                onChange={handleAntR}
                                placeholder="select"
                            />
                        </div>
                        <div className="col-md-6 col-sm-6 mb_10">
                            <RadioSwitch
                                labelLeft="Positive"
                                labelRight="Negative"
                                idLeft="positiveantrR"
                                idRight="negativeantrL"
                                name="AnteriorL"
                                title={translation.Anterior_drawer_test_L}
                                styleClass=""
                                value={antL}
                                onChange={handleAntL}
                                placeholder="select"
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 col-sm-6 mb_10">
                            <RadioSwitch
                                labelLeft="Positive"
                                labelRight="Negative"
                                idLeft="positivepostR"
                                idRight="negativepostL"
                                name="PosteriorR"
                                title={translation.Posterior_drawer_test_R}
                                styleClass=""
                                value={postR}
                                onChange={handlePostR}
                                placeholder="select"
                            />
                        </div>
                        <div className="col-md-6 col-sm-6 mb_10">
                            <RadioSwitch
                                labelLeft="Positive"
                                labelRight="Negative"
                                idLeft="positivepostrR"
                                idRight="negativepostrL"
                                name="PosteriorL"
                                title={translation.Posterior_drawer_test_L}
                                styleClass=""
                                value={postL}
                                onChange={handlePostL}
                                placeholder="select"
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6 col-sm-6 mb_10">
                            <RadioSwitch
                                labelLeft="Positive"
                                labelRight="Negative"
                                idLeft="positiveinvR"
                                idRight="negativeinvL"
                                name="InversiontaR"
                                title={translation.Talar_tilt_test_Inversion_R}
                                styleClass=""
                                value={inversionR}
                                onChange={handleInversionR}
                                placeholder="select"
                            />
                        </div>
                        <div className="col-md-6 col-sm-6 mb_10">
                            <RadioSwitch
                                labelLeft="Positive"
                                labelRight="Negative"
                                idLeft="positiveinvrR"
                                idRight="negativeinvrL"
                                name="InversiontaL"
                                title={translation.Talar_tilt_test_Inversion_L}
                                styleClass=""
                                value={inversionL}
                                onChange={handleInversionL}
                                placeholder="select"
                            />
                        </div>
                    </div>

                    <div className='row'>
                        <div className="col-md-6 col-sm-6 mb_10">
                            <RadioSwitch
                                labelLeft="Positive"
                                labelRight="Negative"
                                idLeft="positiveevR"
                                idRight="negativeevL"
                                name="EversiontaR"
                                title={translation.Talar_tilt_test_Eversion_R}
                                styleClass=""
                                value={eversionR}
                                onChange={handleEversionR}
                                placeholder="select"
                            />
                        </div>
                        <div className="col-md-6 col-sm-6 mb_10">
                            <RadioSwitch
                                labelLeft="Positive"
                                labelRight="Negative"
                                idLeft="positiveevrR"
                                idRight="negativeevrL"
                                name="EversiontaL"
                                title={translation.Talar_tilt_test_Eversion_L}
                                styleClass=""
                                value={eversionL}
                                onChange={handleEversionL}
                                placeholder="select"
                            />
                        </div>
                    </div>

                    <div className='row'>
                        <div className="col-md-6 col-sm-6 mb_10">
                            <RadioSwitch
                                labelLeft="Positive"
                                labelRight="Negative"
                                idLeft="positivecaR"
                                idRight="negativecaL"
                                name="CalfR"
                                title={translation.Calf_compression_test_R}
                                styleClass=""
                                value={calfR}
                                onChange={handleCalfR}
                                placeholder="select"
                            />
                        </div>
                        <div className="col-md-6 col-sm-6 mb_10">
                            <RadioSwitch
                                labelLeft="Positive"
                                labelRight="Negative"
                                idLeft="positivecarR"
                                idRight="negativecarL"
                                name="CalfL"
                                title={translation.Calf_compression_test_L}
                                styleClass=""
                                value={calfL}
                                onChange={handleCalfL}
                                placeholder="select"
                            />
                        </div>
                    </div>

                    <div className='row'>
                        <div className="col-md-6 col-sm-6 mb_10">
                            <RadioSwitch
                                labelLeft="Positive"
                                labelRight="Negative"
                                idLeft="positiveextR"
                                idRight="negativeextL"
                                name="ExternalR"
                                title={translation.External_rotation_stress_test_R}
                                styleClass=""
                                value={externalR}
                                onChange={handleExternalR}
                                placeholder="select"
                            />
                        </div>
                        <div className="col-md-6 col-sm-6 mb_10">
                            <RadioSwitch
                                labelLeft="Positive"
                                labelRight="Negative"
                                idLeft="positiveextrR"
                                idRight="negativeextrL"
                                name="ExternalL"
                                title={translation.External_rotation_stress_test_L}
                                styleClass=""
                                value={externalL}
                                onChange={handleExternalL}
                                placeholder="select"
                            />
                        </div>
                    </div>

                    <div className='row'>
                        <div className="col-md-6 col-sm-6 mb_10">
                            <RadioSwitch
                                labelLeft="Positive"
                                labelRight="Negative"
                                idLeft="positivecolR"
                                idRight="negativecolL"
                                name="ColemanR"
                                title={translation.Coleman_block_test_R}
                                styleClass=""
                                value={colemanR}
                                onChange={handleColemanR}
                                placeholder="select"
                            />
                        </div>
                        <div className="col-md-6 col-sm-6 mb_10">
                            <RadioSwitch
                                labelLeft="Positive"
                                labelRight="Negative"
                                idLeft="positiveecolR"
                                idRight="negativeecolL"
                                name="ColemanL"
                                title={translation.Coleman_block_test_L}
                                styleClass=""
                                value={colemanL}
                                onChange={handleColemanL}
                                placeholder="select"
                            />
                        </div>
                    </div>

                    <div className='row'>
                        <div className="col-md-6 col-sm-6 mb_10">
                            <RadioSwitch
                                labelLeft="Positive"
                                labelRight="Negative"
                                idLeft="positivedouR"
                                idRight="negativedouL"
                                name="DoubleR"
                                title={translation.Double_leg_heel_raise_R}
                                styleClass=""
                                value={doubleR}
                                onChange={handleDoubleR}
                                placeholder="select"
                            />
                        </div>
                        <div className="col-md-6 col-sm-6 mb_10">
                            <RadioSwitch
                                labelLeft="Positive"
                                labelRight="Negative"
                                idLeft="positiveedouR"
                                idRight="negativeedouL"
                                name="DoubleL"
                                title={translation.Double_leg_heel_raise_L}
                                styleClass=""
                                value={doubleL}
                                onChange={handleDoubleL}
                                placeholder="select"
                            />
                        </div>
                    </div>

                    <div className='row'>
                        <div className="col-md-6 col-sm-6 mb_10">
                            <RadioSwitch
                                labelLeft="Positive"
                                labelRight="Negative"
                                idLeft="positivesingR"
                                idRight="negativedsing"
                                name="SingleR"
                                title={translation.Single_leg_heel_raise_R}
                                styleClass=""
                                value={singleR}
                                onChange={handleSingleR}
                                placeholder="select"
                            />
                        </div>
                        <div className="col-md-6 col-sm-6 mb_10">
                            <RadioSwitch
                                labelLeft="Positive"
                                labelRight="Negative"
                                idLeft="positiveesingR"
                                idRight="negativeesingL"
                                name="SingleL"
                                title={translation.Single_leg_heel_raise_L}
                                styleClass=""
                                value={singleL}
                                onChange={handleSingleL}
                                placeholder="select"
                            />
                        </div>
                    </div>

                    <div className='row'>
                        <div className="col-md-6 col-sm-6 mb_10">
                            <RadioSwitch
                                labelLeft="Positive"
                                labelRight="Negative"
                                idLeft="positivesposR"
                                idRight="negativeesposL"
                                name="ThompsonR"
                                title={translation.Thompson_test_R}
                                styleClass=""
                                value={thompR}
                                onChange={handleThompR}
                                placeholder="select"
                            />
                        </div>
                        <div className="col-md-6 col-sm-6 mb_10">
                            <RadioSwitch
                                labelLeft="Positive"
                                labelRight="Negative"
                                idLeft="positiveeposgR"
                                idRight="negativesposL"
                                name="ThompsonL"
                                title={translation.Thompson_test_L}
                                styleClass=""
                                value={thompL}
                                onChange={handleThompL}
                                placeholder="select"
                            />
                        </div>
                    </div>

                    <div className='row'>
                        <div className="col-md-6 col-sm-6 mb_10">
                            <RadioSwitch
                                labelLeft="Positive"
                                labelRight="Negative"
                                idLeft="positivemulR"
                                idRight="negativemulL"
                                name="MulderR"
                                title={translation.Mulder_test_R}
                                styleClass=""
                                value={mulderR}
                                onChange={handleMulderR}
                                placeholder="select"
                            />
                        </div>
                        <div className="col-md-6 col-sm-6 mb_10">
                            <RadioSwitch
                                labelLeft="Positive"
                                labelRight="Negative"
                                idLeft="positiveemulR"
                                idRight="negativeemulL"
                                name="MulderL"
                                title={translation.Mulder_test_L}
                                styleClass=""
                                value={mulderL}
                                onChange={handleMulderL}
                                placeholder="select"
                            />
                        </div>
                    </div>

                    <div className='row'>
                        <div className="col-md-6 col-sm-6 mb_10">
                            <RadioSwitch
                                labelLeft="Positive"
                                labelRight="Negative"
                                idLeft="positivemcru"
                                idRight="negativemcru"
                                name="CrunchR"
                                title={translation.Posterior_crunch_test_R}
                                styleClass=""
                                value={crunchR}
                                onChange={handleCrunchR}
                                placeholder="select"
                            />
                        </div>
                        <div className="col-md-6 col-sm-6 mb_10">
                            <RadioSwitch
                                labelLeft="Positive"
                                labelRight="Negative"
                                idLeft="positiveecruR"
                                idRight="negativeecruL"
                                name="CrunchL"
                                title={translation.Posterior_crunch_test_L}
                                styleClass=""
                                value={crunchL}
                                onChange={handleCrunchL}
                                placeholder="select"
                            />
                        </div>
                    </div>

                    <div className='row'>
                        <div className="col-md-6 col-sm-6">
                            <InputField
                                label={translation.Ligamentous_laxity_R}
                                value={laxityR}
                                type="text"
                                placeholder=""
                                styleClass="mxw_100"
                                onChange={handlelaxityR}
                            />
                        </div>
                        <div className="col-md-6 col-sm-6">
                            <InputField
                                label={translation.Ligamentous_laxity_L}
                                value={laxityL}
                                type="text"
                                placeholder=""
                                styleClass="mxw_100"
                                onChange={handlelaxityL}
                            />
                        </div>
                    </div>


                </div>
                <div className="row mb_30 button_box">
                    <div className="col-md-6 col-sm-6">
                        {doctorEnable !== "true" && (

                            <Link to={"/foot-doctor-feedback-step-2/" + rId + `?doctor=${dId}&patient=${pId}`} className="assm_back_btn">
                                <img src={backIcon} alt="" />
                                <span>{translation.back_button}</span>
                            </Link>
                        )}
                    </div>
                    <div className="col-md-6 col-sm-6 text-end">
                        <button className="next_btn btn_fill" onClick={() => handleSubmit()}>
                            {translation.submit}
                            <span>
                                <img src={nextIcon} alt="" />
                                <img src={nextWhiteIcon} alt="" />
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </>


    )
}
