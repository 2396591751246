import React, { useState } from "react";
import PropTypes from "prop-types";
import "./HandWristRangeSlider.module.css";
import happyFace from "../../../images/happy-face.png";
import sadFace from "../../../images/sad-face.png";

const HandWristRangeSlider = ({
  id,
  min,
  max,
  step,
  onChange,
  onMouseUp,
  name,
  type,
  label,
  leftTitle,
  rightTitle,
  value,
  formGroupClass
}) => {
    const [rangePercent, setRangePercent] = useState(100 * (value > 0 ? (value - min) : 0) / (max - min));

    // console.log("--range percent--", rangePercent)

    const fill = `#FF0000 0%, #FFE600 ${rangePercent/2}%, #40E0D0 ${rangePercent}%`;
    const background = "rgb(215, 220, 223)";
    const bg = `linear-gradient(90deg, ${fill} ${rangePercent}%, ${background} ${rangePercent}%)`;
    
    
    const tpPosition = `${rangePercent}%`
    
    // console.log("ptuy", tpPosition);

    const handleChange = (event) => {
        const { value } = event.target;
        const rangePercentValue = 100 * (value > 0 ? (value - min) : 0) / (max - min);
        setRangePercent(rangePercentValue);
        onChange(value);
    };

  return (
    <div className={`form-group ${formGroupClass}`}>
      {label && <label htmlFor="">{label}</label>}
      <div className="range_slider_box">
        {type === "image" && <span className="happy_face"><img src={sadFace} alt="icon" /></span>}
        {type === "text" && <span className="stable_txt">{leftTitle}</span>}

        <div className="range-slider">
          <div className="tooltip_main">
            <span className="range_tootip" style={{left:`${tpPosition}`}}>{value}</span>
          </div>
          <input
            type="range"
            id={id}
            min={min}
            max={max}
            step={step}
            // value={value} // don't set value from state
            defaultValue={value} // but instead pass state value as default value
            onChange={handleChange} // don't set state on all change as react will re-render
            // onMouseUp={handleChange} // only set state when handle is released
            className={`range-slider__range`}
            style={{background:`${bg}`}}
            />
        </div>
        {type === "image" && <span className="sad_face"><img src={happyFace} alt="icon" /></span>}
        {type === "text" && <span className="unstable_txt">{rightTitle}</span>}
        
      </div>
    </div>
  );
};

HandWristRangeSlider.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

HandWristRangeSlider.defaultProps = {
  value: "",
  name: "",
  label: "",
  id: "",
  min: "",
  max: "",
  step: "",
  onChange: "",
  onMouseUp: "",
  formGroupClass: "",
  type: "",
  leftTitle: "",
  rightTitle: "",
};

export default HandWristRangeSlider;
