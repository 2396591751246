import React from "react";
import "../../../css/ViewTreatmentForm.css";
import useTranslation from "../../customHooks/translations";

export default function AdultKneeSatisfaction({ adultSatisfaction, AllData }) {
  const translations = useTranslation();
  return (
    <>
      <div className="whitebox padding-none">
        <div className="treatment-content">
          <div className="treatment-row-header bd-none mb-0">
            5. {translations.patient_adult_knee_satisfaction}
          </div>

          <div className="treatment-row">
            <div className="treatment-table ">
              <table cellspacing="0" cellpadding="0">
                <thead>
                  <tr>
                    <th></th>
                    <th>{translations.Right_Knee}</th>
                    <th>{translations.Left_Knee}</th>
                    {/* {AllData.evlAdultKnee === "1" || AllData.evlAdultKnee === "3" ? (
                                            <th>Right Knee</th>
                                        ) : (
                                            ""
                                        )}
                                        {AllData.evlAdultKnee === "2" || AllData.evlAdultKnee === "3" ? (
                                            <th>left Knee</th>
                                        ) : (
                                            ""
                                        )} */}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{translations.patient_adult_knee_satisfaction_q1} :</td>
                    <td>
                      {adultSatisfaction.overallR === "1"
                        ? translations.patient_adult_knee_satisfaction_q1_a1
                        : adultSatisfaction.overallR === "2"
                        ? translations.patient_adult_knee_satisfaction_q1_a2
                        : adultSatisfaction.overallR === "3"
                        ? translations.patient_adult_knee_satisfaction_q1_a3
                        : adultSatisfaction.overallR === "4"
                        ? translations.patient_adult_knee_satisfaction_q1_a4
                        : ""}
                    </td>
                    <td>
                      {adultSatisfaction.overallL === "1"
                        ? translations.patient_adult_knee_satisfaction_q1_a1
                        : adultSatisfaction.overallL === "2"
                        ? translations.patient_adult_knee_satisfaction_q1_a2
                        : adultSatisfaction.overallL === "3"
                        ? translations.patient_adult_knee_satisfaction_q1_a3
                        : adultSatisfaction.overallL === "4"
                        ? translations.patient_adult_knee_satisfaction_q1_a4
                        : ""}
                    </td>
                  </tr>

                  <tr>
                    <td>{translations.patient_adult_knee_satisfaction_q2} :</td>
                    <td>
                      {adultSatisfaction.improvingPainR === "1"
                        ? translations.patient_adult_knee_satisfaction_q2_a1
                        : adultSatisfaction.improvingPainR === "2"
                        ? translations.patient_adult_knee_satisfaction_q2_a2
                        : adultSatisfaction.improvingPainR === "3"
                        ? translations.patient_adult_knee_satisfaction_q2_a3
                        : adultSatisfaction.improvingPainR === "4"
                        ? translations.patient_adult_knee_satisfaction_q2_a4
                        : ""}
                    </td>
                    <td>
                      {adultSatisfaction.improvingPainL === "1"
                        ? translations.patient_adult_knee_satisfaction_q2_a1
                        : adultSatisfaction.improvingPainL === "2"
                        ? translations.patient_adult_knee_satisfaction_q2_a2
                        : adultSatisfaction.improvingPainL === "3"
                        ? translations.patient_adult_knee_satisfaction_q2_a3
                        : adultSatisfaction.improvingPainL === "4"
                        ? translations.patient_adult_knee_satisfaction_q2_a4
                        : ""}
                    </td>
                  </tr>

                  <tr>
                    <td>{translations.patient_adult_knee_satisfaction_q3} :</td>
                    <td>
                      {adultSatisfaction.improvingHomeR === "1"
                        ? translations.patient_adult_knee_satisfaction_q3_a1
                        : adultSatisfaction.improvingHomeR === "2"
                        ? translations.patient_adult_knee_satisfaction_q3_a2
                        : adultSatisfaction.improvingHomeR === "3"
                        ? translations.patient_adult_knee_satisfaction_q3_a3
                        : adultSatisfaction.improvingHomeR === "4"
                        ? translations.patient_adult_knee_satisfaction_q3_a4
                        : ""}
                    </td>
                    <td>
                      {adultSatisfaction.improvingHomeL === "1"
                        ? translations.patient_adult_knee_satisfaction_q3_a1
                        : adultSatisfaction.improvingHomeL === "2"
                        ? translations.patient_adult_knee_satisfaction_q3_a2
                        : adultSatisfaction.improvingHomeL === "3"
                        ? translations.patient_adult_knee_satisfaction_q3_a3
                        : adultSatisfaction.improvingHomeL === "4"
                        ? translations.patient_adult_knee_satisfaction_q3_a4
                        : ""}
                    </td>
                  </tr>

                  <tr>
                    <td>{translations.patient_adult_knee_satisfaction_q4} :</td>
                    <td>
                      {adultSatisfaction.improvingActivitiesR === "1"
                        ? translations.patient_adult_knee_satisfaction_q4_a1
                        : adultSatisfaction.improvingActivitiesR === "2"
                        ? translations.patient_adult_knee_satisfaction_q4_a2
                        : adultSatisfaction.improvingActivitiesR === "3"
                        ? translations.patient_adult_knee_satisfaction_q4_a3
                        : adultSatisfaction.improvingActivitiesR === "4"
                        ? translations.patient_adult_knee_satisfaction_q4_a4
                        : ""}
                    </td>
                    <td>
                      {adultSatisfaction.improvingActivitiesL === "1"
                        ? translations.patient_adult_knee_satisfaction_q4_a1
                        : adultSatisfaction.improvingActivitiesL === "2"
                        ? translations.patient_adult_knee_satisfaction_q4_a2
                        : adultSatisfaction.improvingActivitiesL === "3"
                        ? translations.patient_adult_knee_satisfaction_q4_a3
                        : adultSatisfaction.improvingActivitiesL === "4"
                        ? translations.patient_adult_knee_satisfaction_q4_a4
                        : ""}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
