import React from "react";
import useTranslation from "../../customHooks/translations";
export default function ViewSocial({ socialData }) {
  const translations=useTranslation();
  return (
    <div className="whitebox padding-none">
      <div className="treatment-header">
        <h2>{translations.patient_general_social_history}</h2>
      </div>
      <div className="treatment-content">
        <div className="treatment-table border-0">
          <div className="treatment-col">
            <label>{translations.patient_general_social_history_q1}</label>
            <p>
              {socialData.live === "0"
                ? translations.patient_general_social_history_q1_a1
                : socialData.live === "1"
                ? translations.patient_general_social_history_q1_a2
                : ""}
            </p>
            {socialData.live == "0" ? (
              <>
                <label>{translations.patient_general_social_history_q1_a1_q1}</label>
              <pre>  <p className="p_description">{socialData.lives}</p></pre>
              </>
            ) : (
              <></>
            )}
          </div>
          <div className="treatment-col">
            <label>{translations.patient_general_social_history_q2}</label>
            <p>
              {socialData.smoke === "0"
                ? translations.patient_general_social_history_q2_a1
                : socialData.smoke === "1"
                ? translations.patient_general_social_history_q2_a2
                : ""}
            </p>
            {socialData.smoke == "1" ? (
              <>
                <label>{translations.patient_general_social_history_q2_a2_q1}</label>
                <p>{socialData.smokes}</p>
                <label>{translations.patient_general_social_history_q2_a2_q2}</label>
                <p>{socialData.smokes1}</p>
              </>
            ) : (
              <></>
            )}
          </div>
          {socialData.smoke === "0" ?(
            <div className="treatment-col">
            <label>{translations.patient_general_social_history_q3}</label>
            <p>
              {socialData.smokePast === "0"
                ? translations.patient_general_social_history_q3_a1
                : socialData.smokePast === "1"
                ? translations.patient_general_social_history_q3_a2
                : ""}
            </p>
            {socialData.smokePast === "1" ? (
              <>
                <label>{translations.patient_general_social_history_q3_a2_q1}</label>
                <p>{socialData.smokePastText}</p>
              </>
            ) : (
              <></>
            )}
          </div>
          ):(<></>)}
         
          <div className="treatment-col">
            <label>{translations.patient_general_social_history_q4}</label>
            <p>
              {socialData.alcohol === "0"
                ? translations.patient_general_social_history_q4_a1
                : socialData.alcohol === "1"
                ? translations.patient_general_social_history_q4_a2
                : ""}
            </p>
            {socialData.alcohol === "1" ? (
              <>
                <label>{translations.patient_general_What_is_your_average_alcohol_intake_per_week}</label>
                <p>{socialData.alcoholText}</p>
              </>
            ) : (
              <></>
            )}
          </div>
          <div className="">
            <label className="text-dark">
              {translations.patient_general_social_history_q5}              
            </label>
            <p>
              {socialData.drugs === "0"
                ? translations.patient_general_social_history_q5_a1
                : socialData.drugs === "1"
                ? translations.patient_general_social_history_q5_a2
                : ""}
            </p>
            {socialData.drugs === "1" && (
              <>
               <pre> <p className="p_description">{socialData.drop1}</p></pre>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
