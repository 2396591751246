import React from "react";
import "../../../css/ViewTreatmentForm.css";
import useTranslation from "../../customHooks/translations";

export default function AdultKneeQOL({ adultQOL, AllData }) {
  const translations = useTranslation();
  return (
    <>
      <div className="whitebox padding-none">
        <div className="treatment-content">
          <div className="treatment-row-header bd-none mb-0">
            4. {translations.patient_adult_knee_quality_of_life}
          </div>

          <div className="treatment-row">
            <div className="treatment-table ">
              <table cellspacing="0" cellpadding="0">
                <thead>
                  <tr>
                    <th></th>
                    <th>{translations.Right_Knee}</th>
                    <th>{translations.Left_Knee}</th>

                    {/* {AllData.evlAdultKnee === "1" || AllData.evlAdultKnee === "3" ? (
                                            <th>Right Knee</th>
                                        ) : (
                                            ""
                                        )}
                                        {AllData.evlAdultKnee === "2" || AllData.evlAdultKnee === "3" ? (
                                            <th>left Knee</th>
                                        ) : (
                                            ""
                                        )} */}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {translations.patient_adult_knee_quality_of_life_q1}
                    </td>
                    <td>
                      {adultQOL.howOftenR === "4"
                        ? translations.patient_adult_knee_quality_of_life_q1_a5
                        : adultQOL.howOftenR === "3"
                        ? translations.patient_adult_knee_quality_of_life_q1_a4
                        : adultQOL.howOftenR === "2"
                        ? translations.patient_adult_knee_quality_of_life_q1_a3
                        : adultQOL.howOftenR === "1"
                        ? translations.patient_adult_knee_quality_of_life_q1_a2
                        : translations.patient_adult_knee_quality_of_life_q1_a1}
                    </td>
                    <td>
                      {adultQOL.howOftenL === "4"
                        ? translations.patient_adult_knee_quality_of_life_q1_a5
                        : adultQOL.howOftenL === "3"
                        ? translations.patient_adult_knee_quality_of_life_q1_a4
                        : adultQOL.howOftenL === "2"
                        ? translations.patient_adult_knee_quality_of_life_q1_a3
                        : adultQOL.howOftenL === "1"
                        ? translations.patient_adult_knee_quality_of_life_q1_a2
                        : translations.patient_adult_knee_quality_of_life_q1_a1}
                    </td>
                  </tr>

                  <tr>
                    <td>
                      {translations.patient_adult_knee_quality_of_life_q2}
                    </td>
                    <td>
                      {adultQOL.lifestyleR === "4"
                        ? translations.patient_adult_knee_quality_of_life_q2_a5
                        : adultQOL.lifestyleR === "3"
                        ? translations.patient_adult_knee_quality_of_life_q2_a4
                        : adultQOL.lifestyleR === "2"
                        ? translations.patient_adult_knee_quality_of_life_q2_a3
                        : adultQOL.lifestyleR === "1"
                        ? translations.patient_adult_knee_quality_of_life_q2_a2
                        : translations.patient_adult_knee_quality_of_life_q2_a1}
                    </td>
                    <td>
                      {adultQOL.lifestyleL === "4"
                        ? translations.patient_adult_knee_quality_of_life_q2_a5
                        : adultQOL.lifestyleL === "3"
                        ? translations.patient_adult_knee_quality_of_life_q2_a4
                        : adultQOL.lifestyleL === "2"
                        ? translations.patient_adult_knee_quality_of_life_q2_a3
                        : adultQOL.lifestyleL === "1"
                        ? translations.patient_adult_knee_quality_of_life_q2_a2
                        : translations.patient_adult_knee_quality_of_life_q2_a1}
                    </td>
                  </tr>

                  <tr>
                    <td>
                      {translations.patient_adult_knee_quality_of_life_q3}
                    </td>
                    <td>
                      {adultQOL.confidenceR === "4"
                        ? translations.patient_adult_knee_quality_of_life_q3_a5
                        : adultQOL.confidenceR === "3"
                        ? translations.patient_adult_knee_quality_of_life_q3_a4
                        : adultQOL.confidenceR === "2"
                        ? translations.patient_adult_knee_quality_of_life_q3_a3
                        : adultQOL.confidenceR === "1"
                        ? translations.patient_adult_knee_quality_of_life_q3_a2
                        : translations.patient_adult_knee_quality_of_life_q3_a1}
                    </td>
                    <td>
                      {adultQOL.confidenceL === "4"
                        ? translations.patient_adult_knee_quality_of_life_q3_a5
                        : adultQOL.confidenceL === "3"
                        ? translations.patient_adult_knee_quality_of_life_q3_a4
                        : adultQOL.confidenceL === "2"
                        ? translations.patient_adult_knee_quality_of_life_q3_a3
                        : adultQOL.confidenceL === "1"
                        ? translations.patient_adult_knee_quality_of_life_q3_a2
                        : translations.patient_adult_knee_quality_of_life_q3_a1}
                    </td>
                  </tr>

                  <tr>
                    <td>
                      {translations.patient_adult_knee_quality_of_life_q4}
                    </td>
                    <td>
                      {adultQOL.difficultyR === "4"
                        ? translations.patient_adult_knee_quality_of_life_q4_a5
                        : adultQOL.difficultyR === "3"
                        ? translations.patient_adult_knee_quality_of_life_q4_a4
                        : adultQOL.difficultyR === "2"
                        ? translations.patient_adult_knee_quality_of_life_q4_a3
                        : adultQOL.difficultyR === "1"
                        ? translations.patient_adult_knee_quality_of_life_q4_a2
                        : translations.patient_adult_knee_quality_of_life_q4_a1}
                    </td>
                    <td>
                      {adultQOL.difficultyL === "4"
                        ? translations.patient_adult_knee_quality_of_life_q4_a5
                        : adultQOL.difficultyL === "3"
                        ? translations.patient_adult_knee_quality_of_life_q4_a4
                        : adultQOL.difficultyL === "2"
                        ? translations.patient_adult_knee_quality_of_life_q4_a3
                        : adultQOL.difficultyL === "1"
                        ? translations.patient_adult_knee_quality_of_life_q4_a2
                        : translations.patient_adult_knee_quality_of_life_q4_a1}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {String(AllData.age) !== "1" ? (
            <>
              <div className="treatment-row">
                <div className="treatment-col">
                  <label>
                    {translations.patient_adult_knee_quality_of_life_q5}
                  </label>
                  <p>
                    {adultQOL.anxious === "4"
                      ? translations.patient_hand_quality_of_life_q5_a5
                      : adultQOL.anxious === "3"
                      ? translations.patient_hand_quality_of_life_q5_a4
                      : adultQOL.anxious === "2"
                      ? translations.patient_hand_quality_of_life_q5_a3
                      : adultQOL.anxious === "1"
                      ? translations.patient_hand_quality_of_life_q5_a2
                      : translations.patient_hand_quality_of_life_q5_a1}
                  </p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          {String(AllData.age) === "2" ? (
            <>
              <div className="treatment-row">
                <div className="treatment-col">
                  <label>{translations.patient_hand_quality_of_life_q6} </label>
                  <p>{adultQOL.howGood}</p>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
}
