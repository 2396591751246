import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { validateInput } from "../../../utilities/Validator";
import degreeWidgetStyles from "./AssessmentDegree.module.css";

function AssessmentDegreeWidget({
  title,
  value,
  validators,
  labelR,
  labelL,
  nameR,
  nameL,
  onChange,
  onChangeR,
  onChangeL,
  valueR,
  valueL,
  validator,
  validatorL,
  viewValidation,
  validationfor
}) {
  const [countR, setCountR] = useState(valueR);
  const [countL, setCountL] = useState(valueL);
  const [errorValR, setErrorValR] = useState(false);
  const [errorValL, setErrorValL] = useState(false);
  const [errorR, setErrorR] = useState(false);
  const [errorL, setErrorL] = useState(false);
  const [errorMsgR, setErrorMsgR] = useState("");
  const [errorMsgL, setErrorMsgL] = useState("");

  useEffect(() => {
    setCountR(valueR);
    setCountL(valueL);
  }, [valueR, valueL]);

  const regex = /^\-?[1-9]\d{0,2}$/; // regex to avoid negative values and decimals

  const handleChangeR = (event) => {
 
    const { value } = event.target;
    // const newValue = value.replace(/[^0-9]/g, "") === "" ? "" : parseInt(value, 10);
    const newValue = value.replace(/^(-)?[^0-9]/g, "$1") === "" ? "" : parseInt(value, 10);


    setErrorValR(validateInput(validators, newValue));
    if (validationfor === "Extension") {
      if (value === "" || (!isNaN(newValue) && newValue >= 0 && newValue <= 90)) {
        setCountR(newValue);
        onChangeR(newValue);
        setErrorR(false);
        console.log("✅ num is between values");
      } else {
        console.log("⛔️ num is NOT between values");
        setCountR("");
        setErrorR(true);
        setErrorMsgR("Number Should be 0 to 90")
      }
    } if (validationfor === "Flexion") {
      if (value === "" || (!isNaN(newValue) && newValue >= 0 && newValue <= 100)) {
        setCountR(newValue);
        onChangeR(newValue);
        setErrorR(false);
        console.log("✅ num is between values");
      } else {
        console.log("⛔️ num is NOT between values");
        setCountR("");
        setErrorR(true);
        setErrorMsgR("Number Should be 0 to 100")
      }
    } if (validationfor === "IPExtension") {
      if (value === "" || (!isNaN(newValue) && newValue >= 0 && newValue <= 50)) {
        setCountR(newValue);
        onChangeR(newValue);
        setErrorR(false);
        console.log("✅ num is between values");
      } else {
        console.log("⛔️ num is NOT between values");
        setCountR("");
        setErrorR(true);
        setErrorMsgR("Number Should be 0 to 50")
      }
    } if (validationfor === "MCPExtension") {
      if (value === "" || (!isNaN(newValue) && newValue >= 0 && newValue <= 20)) {
        setCountR(newValue);
        onChangeR(newValue);
        setErrorR(false);
        console.log("✅ num is between values");
      } else {
        console.log("⛔️ num is NOT between values");
        setCountR("");
        setErrorR(true);
        setErrorMsgR("Number Should be 0 to 20")
      }
    } if (validationfor === "PipFlexion") {
      if (value === "" || (!isNaN(newValue) && newValue >= 0 && newValue <= 120)) {
        setCountR(newValue);
        onChangeR(newValue);
        setErrorR(false);
        console.log("✅ num is between values");
      } else {
        console.log("⛔️ num is NOT between values");
        setCountR("");
        setErrorR(true);
        setErrorMsgR("Number Should be 0 to 120")
      }
    } if (validationfor === "negative")
    // {
    //   if (value === "" || (!isNaN(newValue) && newValue >= -90 && newValue <= 180)) {
    //     setCountR(newValue);
    //     onChangeR(newValue);
    //     setErrorR(false);
    //     console.log("✅ num is between values");
    //   } else {
    //     console.log("⛔️ num is NOT between values");
    //     setCountR("");
    //     setErrorR(true);
    //     setErrorMsgR("Number Should be -90 to 180")
    //   }
    // }
    {
      if (value === "" || (!isNaN(newValue) && newValue >= -90 && newValue <= 180)) {
        setCountR(newValue);
        onChangeR(newValue);
        setErrorR(false);
        console.log("✅ num is between values");
      } else {
        console.log("⛔️ num is NOT between values");
        setCountR("");
        setErrorR(true);
        setErrorMsgR("Number Should be -90 to 180");
      }
    }
    if (validationfor === undefined) {
      if (value === "" || (!isNaN(newValue) && newValue >= 0 && newValue <= 180)) {
        setCountR(newValue);
        onChangeR(newValue);
        setErrorR(false);
        console.log("✅ num is between values");
      } else {
        console.log("⛔️ num is NOT between values");
        setCountR("");
        setErrorR(true);
        setErrorMsgR("Number Should be 0 to 180")
      }
    }
    // else{
    //   if (value === "" || (!isNaN(newValue) && newValue >= 0 && newValue <= 180)) {
    //     setCountR(newValue);
    //     onChangeR(newValue);
    //     setErrorR(false);
    //     console.log("✅ num is between values");
    //   } else {
    //     console.log("⛔️ num is NOT between values");
    //     setCountR("");
    //     setErrorR(true);
    //   }
    // }

  };

  const handleIncrementR = () => {
    if (validationfor === "Extension") {
      if (isNaN(countR) && countR === "") {
        setCountR(0);
      } else {
        setCountR(countR >= 90 ? 90 : Number(countR) + 1);
        onChangeR(countR >= 90 ? 90 : Number(countR) + 1);
      }
    } if (validationfor === "Flexion") {
      if (isNaN(countR) && countR === "") {
        setCountR(0);
      } else {
        setCountR(countR >= 100 ? 100 : Number(countR) + 1);
        onChangeR(countR >= 100 ? 100 : Number(countR) + 1);
      }
    } if (validationfor === "IPExtension") {
      if (isNaN(countR) && countR === "") {
        setCountR(0);
      } else {
        setCountR(countR >= 50 ? 50 : Number(countR) + 1);
        onChangeR(countR >= 50 ? 50 : Number(countR) + 1);
      }
    } if (validationfor === "MCPExtension") {
      if (isNaN(countR) && countR === "") {
        setCountR(0);
      } else {
        setCountR(countR >= 20 ? 20 : Number(countR) + 1);
        onChangeR(countR >= 20 ? 20 : Number(countR) + 1);
      }
    } if (validationfor === "PipFlexion") {
      if (isNaN(countR) && countR === "") {
        setCountR(0);
      } else {
        setCountR(countR >= 120 ? 120 : Number(countR) + 1);
        onChangeR(countR >= 120 ? 120 : Number(countR) + 1);
      }
    } if (validationfor === undefined || validationfor === "negative") {
      if (isNaN(countR) && countR === "") {
        setCountR(0);
      } else {
        setCountR(countR >= 180 ? 180 : Number(countR) + 1);
        onChangeR(countR >= 180 ? 180 : Number(countR) + 1);
      }
    }
    // else{
    //   if (isNaN(countR) && countR === "") {
    //     setCountR(0);
    //   } else {
    //     setCountR(countR >= 180 ? 180 : Number(countR) + 1);
    //     onChangeR(countR >= 180 ? 180 : Number(countR) + 1);
    //   }
    // }

  };

  useEffect(() => {
    if (isNaN(countR)) {
      setCountR("")
    }
    if (isNaN(countL)) {
      setCountL("")
    }
  }, [countR, countL])
  const handleDecrementR = () => {
    if (validationfor === "negative") {
      if (countR > -91) {
        setCountR(countR <= -90 ? -90 : countR - 1);
        onChangeR(countR <= -90 ? -90 : countR - 1);
      }
    } else {
      if (regex.test(countR)) {
        setCountR(countR <= 0 ? 0 : countR - 1);
        onChangeR(countR <= 0 ? 0 : countR - 1);
      }
    }
  };
  const handleChangeL = (event) => {
    const { value } = event.target;
    const newValue = value === "" ? "" : parseInt(value, 10);


    setErrorValL(validateInput(validators, newValue));
    if (validationfor === "Extension") {
      if (value === "" || (!isNaN(newValue) && newValue >= 0 && newValue <= 90)) {
        setCountL(newValue);
        onChangeL(newValue);
        setErrorL(false);
        console.log("✅ num is between values");
      } else {
        console.log("⛔️ num is NOT between values");
        setCountL("");
        setErrorL(true);
        setErrorMsgL("Number Should be 0 to 90")
      }
    } if (validationfor === "Flexion") {
      if (value === "" || (!isNaN(newValue) && newValue >= 0 && newValue <= 100)) {
        setCountL(newValue);
        onChangeL(newValue);
        setErrorL(false);
        console.log("✅ num is between values");
      } else {
        console.log("⛔️ num is NOT between values");
        setCountL("");
        setErrorL(true);
        setErrorMsgL("Number Should be 0 to 100")
      }
    } if (validationfor === "IPExtension") {
      if (value === "" || (!isNaN(newValue) && newValue >= 0 && newValue <= 50)) {
        setCountL(newValue);
        onChangeL(newValue);
        setErrorL(false);
        console.log("✅ num is between values");
      } else {
        console.log("⛔️ num is NOT between values");
        setCountL("");
        setErrorL(true);
        setErrorMsgL("Number Should be 0 to 50")
      }
    } if (validationfor === "MCPExtension") {
      if (value === "" || (!isNaN(newValue) && newValue >= 0 && newValue <= 20)) {
        setCountL(newValue);
        onChangeL(newValue);
        setErrorL(false);
        console.log("✅ num is between values");
      } else {
        console.log("⛔️ num is NOT between values");
        setCountL("");
        setErrorL(true);
        setErrorMsgL("Number Should be 0 to 20")
      }
    } if (validationfor === "PipFlexion") {
      if (value === "" || (!isNaN(newValue) && newValue >= 0 && newValue <= 120)) {
        setCountL(newValue);
        onChangeL(newValue);
        setErrorL(false);
        console.log("✅ num is between values");
      } else {
        console.log("⛔️ num is NOT between values");
        setCountL("");
        setErrorL(true);
        setErrorMsgL("Number Should be 0 to 120")
      }
    } if (validationfor === "negative") {
      if (value === "" || (!isNaN(newValue) && newValue >= -90 && newValue <= 180)) {
        setCountL(newValue);
        onChangeL(newValue);
        setErrorL(false);
        console.log("✅ num is between values");
      } else {
        console.log("⛔️ num is NOT between values");
        setCountL("");
        setErrorL(true);
        setErrorMsgL("Number Should be -90 to 180")
      }
    } if (validationfor === undefined) {
      if (value === "" || (!isNaN(newValue) && newValue >= 0 && newValue <= 180)) {
        setCountL(newValue);
        onChangeL(newValue);
        setErrorL(false);
        console.log("✅ num is between values");
      } else {
        console.log("⛔️ num is NOT between values");
        setCountL("");
        setErrorL(true);
        setErrorMsgL("Number Should be 0 to 180")
      }
    }
  };

  const handleIncrementL = () => {
    if (validationfor === "Extension") {
      if (isNaN(countL) && countL === "") {
        setCountL(0);
      } else {
        setCountL(countL >= 90 ? 90 : Number(countL) + 1);
        onChangeL(countL >= 90 ? 90 : Number(countL) + 1);
      }
    } if (validationfor === "Flexion") {
      if (isNaN(countL) && countL === "") {
        setCountL(0);
      } else {
        setCountL(countL >= 100 ? 100 : Number(countL) + 1);
        onChangeL(countL >= 100 ? 100 : Number(countL) + 1);
      }
    } if (validationfor === "IPExtension") {
      if (isNaN(countL) && countL === "") {
        setCountL(0);
      } else {
        setCountL(countL >= 50 ? 50 : Number(countL) + 1);
        onChangeL(countL >= 50 ? 50 : Number(countL) + 1);
      }
    } if (validationfor === "MCPExtension") {
      if (isNaN(countL) && countL === "") {
        setCountL(0);
      } else {
        setCountL(countL >= 20 ? 20 : Number(countL) + 1);
        onChangeL(countL >= 20 ? 20 : Number(countL) + 1);
      }
    } if (validationfor === "PipFlexion") {
      if (isNaN(countL) && countL === "") {
        setCountL(0);
      } else {
        setCountL(countL >= 120 ? 120 : Number(countL) + 1);
        onChangeL(countL >= 120 ? 120 : Number(countL) + 1);
      }
    } if (validationfor === undefined || validationfor === "negative") {
      if (isNaN(countL) && countL === "") {
        setCountL(0);
      } else {
        setCountL(countL >= 180 ? 180 : Number(countL) + 1);
        onChangeL(countL >= 180 ? 180 : Number(countL) + 1);
      }
    }
  };

  const handleDecrementL = () => {
    if (validationfor === "negative") {
      if (countL > -91) {
        setCountL(countL <= -90 ? -90 : countL - 1);
        onChangeL(countL <= -90 ? -90 : countL - 1);
      }
    } else {

      if (regex.test(countL)) {
        setCountL(countL <= 0 ? 0 : countL - 1);
        onChangeL(countL <= 0 ? 0 : countL - 1);
      }
    }
  };


  return (
    <>
      <div className={`${degreeWidgetStyles.input_box_main}`}>
        <h4 className={`${degreeWidgetStyles.title_txt}`}>{title}</h4>
        <div className={`${degreeWidgetStyles.number_box_row}`}>
          <div className={`${degreeWidgetStyles.number_box_col}`}>
            <label className={`${degreeWidgetStyles.requiredFieldRightF}`}>
              {labelR}
            </label>
            <div className={`${degreeWidgetStyles.degree_box}`}>
              <span
                className={`${degreeWidgetStyles.qty_minus}`}
                onClick={handleDecrementR}
              >
                -
              </span>
              <input
                type="number"
                max={180}
                min={0}
                step="1"
                pattern="\d*"
                className="qty trim"
                name={nameR}
                value={countR}
                placeholder="000"
                onChange={handleChangeR}
                onBlur={handleChangeR}
                onKeyDown={(e) => {
                  // Allow only numeric key presses (0-9)
                  const isNumeric = /^[0-9-]$/;
                  if (
                    !isNumeric.test(e.key) &&
                    e.key !== "Backspace" &&
                    e.key !== "Delete" &&
                    e.key !== "Tab"
                  ) {
                    console.log(e , "eeeeee")
                    e.preventDefault();
                  }
                }}
              
              
              
              />
              <span
                className={`${degreeWidgetStyles.qty_plus}`}
                onClick={handleIncrementR}
              >
                +
              </span>
            </div>
            <span className={`${degreeWidgetStyles.degree_txt}`}>Degrees</span>
            {errorR ? (
              <>
                <span className={`${degreeWidgetStyles.ValidationErrors}`}>
                  {errorMsgR}
                </span>
              </>
            ) : (
              <>
                {viewValidation && (
                  <>
                    {countR !== 0 ? (
                      <>
                        {valueR == "" || isNaN(valueR) || valueR === null ? (
                          <>
                            {validatorL === true ? (
                              <span className={`${degreeWidgetStyles.ValidationErrors}`}>
                                This field is required
                              </span>
                            ) : ""}
                          </>
                        ) : null}
                      </>
                    ) : ""}

                  </>
                )}
              </>
            )}


            {errorValL && (
              <span className="text-danger">{errorValL.message}</span>
            )}
          </div>
          <div className={`${degreeWidgetStyles.number_box_col}`}>
            <label className={`${degreeWidgetStyles.requiredFieldLeft}`}>
              {labelL}
            </label>

            <div className={`${degreeWidgetStyles.degree_box}`}>
              <span
                className={`${degreeWidgetStyles.qty_minus}`}
                onClick={handleDecrementL}
              >
                -
              </span>
              <input
                type="number"
                max={180}
                min={0}
                step="1"
                pattern="\d*"
                className="qty trim"
                name={nameL}
                value={countL}
                placeholder="000"
                onChange={handleChangeL}
                onBlur={handleChangeL}
                onKeyDown={(e) => {
                  // Allow only numeric key presses (0-9)
                  const isNumeric = /^[0-9-]$/;
                  if (
                    !isNumeric.test(e.key) &&
                    e.key !== "Backspace" &&
                    e.key !== "Delete" &&
                    e.key !== "Tab"
                  ) {
                    console.log(e , "eeeeee")
                    e.preventDefault();
                  }
                }}
              />
              <span
                className={`${degreeWidgetStyles.qty_plus}`}
                onClick={handleIncrementL}
              >
                +
              </span>
            </div>
            <span className={`${degreeWidgetStyles.degree_txt}`}>Degrees</span>
            {errorL ? (
              <>
                <span className={`${degreeWidgetStyles.ValidationErrors}`}>
                  {errorMsgL}
                </span>
              </>
            ) : (
              <>
                {viewValidation && (
                  <>
                    {countL !== 0 ? (
                      <>
                        {valueL == "" || isNaN(valueL) || valueL === null ? (
                          <>
                            {validator === true && (
                              <span className={`${degreeWidgetStyles.ValidationErrors}`}>
                                This field is required
                              </span>
                            )}
                          </>
                        ) : null}
                      </>) : ""}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

AssessmentDegreeWidget.propTypes = {
  validators: PropTypes.array,
};

AssessmentDegreeWidget.defaultProps = {
  value: "",
  title: "",
  labelR: "",
  labelL: "",
  nameR: "",
  nameL: "",
  validators: [],
};

export default AssessmentDegreeWidget;
