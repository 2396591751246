import React from "react";
import "../../../css/ViewTreatmentForm.css";
import useTranslation from "../../customHooks/translations";
import { UserDateFormat } from "../../../utilities/DateFormate";

function SacsScore({ sacsScore, AllData,functionData,profileAllData }) {
  console.log(AllData,"time")
  const translation = useTranslation();
  console.log(functionData.instability_acjoint,"===============");
  return (
    <>
    {functionData.instability_acjoint === "1" && (
      <div className="whitebox padding-none">
        <div className="treatment-header">
          <h2>{translation.patient_shoulder_sacs}</h2>
          <div className="treatment-header-right">
            <p>{translation.Assessment_Date} : {UserDateFormat(AllData.assessment_date,profileAllData.ing_date_formate)}</p>
          </div>
        </div>

        <div className="treatment-content">
          <div className="treatment-row-header bd-none mb-0">{translation.patient_shoulder_sacs_sectionA}</div>

          <div className="treatment-row">
            <div className="treatment-table ">
              <table cellspacing="0" cellpadding="0">
                <thead>
                  <tr>
                    <th></th>
                  
                    <th>{translation.Right_Arm}</th>
                    <th>{translation.Left_Arm}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      A1. {translation.patient_shoulder_sacs_a1}:
                    </td>
                  
                    <td>{sacsScore.sacs_q1_right}</td>
                    <td>{sacsScore.sacs_q1_left}</td>
                  </tr>

                  <tr>
                    <td>
                      A2. {translation.patient_shoulder_sacs_a2}:
                    </td>
                    
                    <td>{sacsScore.sacs_q2_right}</td>
                    <td>{sacsScore.sacs_q2_left}</td>
                  </tr>

                  <tr>
                    <td>
                      A3. {translation.patient_shoulder_sacs_a3}:
                    </td>
                    
                    <td>{sacsScore.sacs_q3_right}</td>
                    <td>{sacsScore.sacs_q3_left}</td>
                  </tr>

                  <tr>
                    <td>
                      A4. {translation.patient_shoulder_sacs_a4}:
                    </td>
                    
                    <td>{sacsScore.sacs_q4_right}</td>
                    <td>{sacsScore.sacs_q4_left}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="treatment-content">
          <div className="treatment-row-header bd-none mb-0">
           {translation.patient_shoulder_sacs_sectionB}
          </div>

          <div className="treatment-row">
            <div className="treatment-table">
              <table cellspacing="0" cellpadding="0">
                <thead>
                  <tr>
                    <th></th>
                    
                    <th>{translation.Right_Arm}</th>
                    <th>{translation.Left_Arm}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      B1. {translation.patient_shoulder_sacs_b1}:
                    </td>
                    
                    <td>{sacsScore.sacs_q5_right}</td>
                    <td>{sacsScore.sacs_q5_left}</td>
                  </tr>

                  <tr>
                    <td>
                      B2. {translation.patient_shoulder_sacs_b2}:
                    </td>
                    
                    <td>{sacsScore.sacs_q6_right}</td>
                    <td>{sacsScore.sacs_q6_left}</td>
                  </tr>

                  <tr>
                    <td>
                      B3. {translation.patient_shoulder_sacs_b3}:
                    </td>
                    
                    <td>{sacsScore.sacs_q7_right}</td>
                    <td>{sacsScore.sacs_q7_left}</td>
                  </tr>

                  <tr>
                    <td>
                      B4. {translation.patient_shoulder_sacs_b4}:
                    </td>
                   
                    <td>{sacsScore.sacs_q8_right}</td>
                    <td>{sacsScore.sacs_q8_left}</td>
                  </tr>

                  <tr>
                    <td>
                      B5. {translation.patient_shoulder_sacs_b5}:
                    </td>
                   
                    <td>{sacsScore.sacs_q9_right}</td>
                    <td>{sacsScore.sacs_q9_left}</td>
                  </tr>

                  <tr>
                    <td>
                      B6. {translation.patient_shoulder_sacs_b6}:
                    </td>
                   
                    <td>{sacsScore.sacs_q10_right}</td>
                    <td>{sacsScore.sacs_q10_left}</td>
                  </tr>

                  <tr>
                    <td>
                      B7. {translation.patient_shoulder_sacs_b7}:
                    </td>
                    
                    <td>{sacsScore.sacs_q11_right}</td>
                    <td>{sacsScore.sacs_q11_left}</td>
                  </tr>

                  <tr>
                    <td>
                      B8. {translation.patient_shoulder_sacs_b8}:
                    </td>
                    
                    <td>{sacsScore.check1===true ? translation.patient_shoulder_not_applicable : sacsScore.sacs_q12_right}</td>
                    <td>{sacsScore.check1===true ? translation.patient_shoulder_not_applicable : sacsScore.sacs_q12_left}</td>
                  </tr>

                  <tr>
                    <td>
                      B9. {translation.patient_shoulder_sacs_b9}:
                    </td>
                   
                    <td>{sacsScore.check3===true ? translation.patient_shoulder_not_applicable : sacsScore.sacs_q13_right}</td>
                    <td>{sacsScore.check3===true ? translation.patient_shoulder_not_applicable : sacsScore.sacs_q13_left}</td>
                  </tr>

                  <tr>
                    <td>
                      B10. {translation.patient_shoulder_sacs_b10}:
                    </td>
                    
                    <td>{sacsScore.check5===true ? translation.patient_shoulder_not_applicable : sacsScore.sacs_q14_right}</td>
                    <td>{sacsScore.check5===true ? translation.patient_shoulder_not_applicable : sacsScore.sacs_q14_left}</td>
                  </tr>

                  <tr>
                    <td>
                      B11. {translation.patient_shoulder_sacs_b11}:
                    </td>
                   
                    <td>{sacsScore.check7===true ? translation.patient_shoulder_not_applicable : sacsScore.sacs_q15_right}</td>
                    <td>{sacsScore.check7===true ? translation.patient_shoulder_not_applicable : sacsScore.sacs_q15_left}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="treatment-content">
          <div className="treatment-row-header bd-none mb-0">
            {translation.patient_shoulder_sacs_sectionC}
          </div>

          <div className="treatment-row">
            <div className="treatment-table">
              <table cellspacing="0" cellpadding="0">
                <thead>
                  <tr>
                    <th></th>
                   
                    <th>{translation.Right_Arm}</th>
                    <th>{translation.Left_Arm}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      C1. {translation.patient_shoulder_sacs_c1}:
                    </td>
                   
                    <td>{sacsScore.sacs_q16_right}</td>
                    <td>{sacsScore.sacs_q16_left}</td>
                  </tr>

                  <tr>
                    <td>
                      C2. {translation.patient_shoulder_sacs_c2}:
                    </td>
                   
                    <td>{sacsScore.sacs_q17_right}</td>
                    <td>{sacsScore.sacs_q17_left}</td>
                  </tr>

                  <tr>
                    <td>
                      C3. {translation.patient_shoulder_sacs_c3}:
                    </td>
                   
                    <td>{sacsScore.sacs_q18_right}</td>
                    <td>{sacsScore.sacs_q18_left}</td>
                  </tr>

                  <tr>
                    <td>
                      C4. {translation.patient_shoulder_sacs_c4}:
                    </td>
                   
                    <td>{sacsScore.sacs_q19_right}</td>
                    <td>{sacsScore.sacs_q19_left}</td>
                  </tr>

                  <tr>
                    <td>
                      C5. {translation.patient_shoulder_sacs_c5}:
                    </td>
                    
                    <td>{sacsScore.sacs_q20_right}</td>
                    <td>{sacsScore.sacs_q20_left}</td>
                  </tr>

                  <tr>
                    <td>
                      C6. {translation.patient_shoulder_sacs_c6}
                    </td>
                  
                    <td>{sacsScore.sacs_q21_right}</td>
                    <td>{sacsScore.sacs_q21_left}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )}
    </>
  );
}

export default SacsScore;
