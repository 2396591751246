import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import vertMenuStyles from "./FormVerticalMenu.module.css";

function FormVerticalMenu({ setGetId }) {
  return (
    <>
      <Navbar
        bg="light"
        className={`scrollbar_vert ${vertMenuStyles.vm_sidebar}`}
      >
        <Nav
          className={`me-auto my-2 my-lg-0 ${vertMenuStyles.vm_sidebar_inner}`}
          navbarScroll
        >
          <Nav.Link
            className={`${vertMenuStyles.vm_item}`}
            onClick={() => setGetId("stability")}
            // href="#stability"
          >
            STABILITY
          </Nav.Link>
          <Nav.Link
            className={`${vertMenuStyles.vm_item}`}
            onClick={() => setGetId("strength")}
            // href="#strength"
          >
            STRENGTH
          </Nav.Link>
          <Nav.Link
            className={`${vertMenuStyles.vm_item}`}
            onClick={() => setGetId("signs")}
            // href="#signs"
          >
            SIGNS
          </Nav.Link>
        </Nav>
      </Navbar>
    </>
  );
}

export default FormVerticalMenu;
