import React, { useState } from "react";
import PropTypes from "prop-types";
import "./HipRangeSlider.css";
// import happyFace from "../../../images/happy-face.png";
// import sadFace from "../../../images/sad-face.png";

const HipRangeSlider = ({
  id,
  min,
  max,
  step,
  onChange,
  onMouseUp,
  name,
  type,
  label,
  leftTitle,
  rightTitle,
  value,
  formGroupClass,
  colorcode,
  hipSlider,
  kneeSlider,
  kneeSlider_severe,

}) => {
  const [rangePercent, setRangePercent] = useState(100 * (value > 0 ? (value - min) : 0) / (max - min));

  // console.log("--range percent--", rangePercent)

  const fill = `#40E0D0 0%, #FFE600 ${rangePercent / 2}%, #FF0000 ${rangePercent}%`;

  const fill1 = `#FF0000 0%, #FFE600 ${rangePercent / 2}%, #40E0D0 ${rangePercent}%`;
  const background = "rgb(215, 220, 223)";
  const bg = `linear-gradient(90deg, ${colorcode === "1" ? fill1 : fill} ${rangePercent}%, ${background} ${rangePercent}%)`
  const tpPosition = `${rangePercent}%`
  const rtPosition = `${100-rangePercent}%`
  // console.log("ptuy", tpPosition);

  const handleChange = (event) => {
    const { value } = event.target;
    const rangePercentValue = 100 * (value > 0 ? (value - min) : 0) / (max - min);
    setRangePercent(rangePercentValue);
    onChange(value);
  };

  return (
    <div className={`form-group ${formGroupClass}`}>
      {label && <label htmlFor="">{label}</label>}
      <div className={colorcode === "1"?`range_slider_box1`:"range_slider_box"}>
        {type === "image" && <span className="happy_face"><label>0%</label></span>}
        {type === "text" && <span className="stable_txt">{leftTitle}</span>}

        <div className="range-slider">
          <div className="tooltip_main">
            <span className="range_tootip" style={{ left: `${tpPosition}` }}>{value}</span>
          </div>
          <span className="slider_color_inverse" style={{width:`${rtPosition}`}}></span>
          <span className="slider_color_front"></span>
          <input
            type="range"
            id={id}
            min={min}
            max={max}
            step={step}
            // value={value} // don't set value from state
            defaultValue={value} // but instead pass state value as default value
            onChange={handleChange} // don't set state on all change as react will re-render
            // onMouseUp={handleChange} // only set state when handle is released
            className={`range-slider__range`}
            // style={{ background: `${bg}` }}
          />
          {hipSlider && (
            <div className="tooltip_main">
              <span className="range_tootip_val" style={{ left: `${tpPosition}` }}>{value === 0 ? "none" : value === 1 ? "very mild" : value === 2 || value === 3 ? "mild" : value === 4 || value === 5 || value === 6 ? "moderate" : value === 7 || value === 8 || value === 9 || value === 10 ? "severe" : ""}</span>
            </div>
          )}
          {kneeSlider && (
            <div className="tooltip_main">
              <span className="range_tootip_val" style={{ left: `${tpPosition}` }}>{value === 0 ? "never" : value === 1 || value === 2 || value === 3 ? "monthly" :  value === 4 || value === 5 || value === 6 ? "weekly" :  value === 7 || value === 8 || value === 9 ? "daily" : value === 10 ? "constant" : ""}</span>
            </div>
          )}
          {kneeSlider_severe && (
            <div className="tooltip_main">
              <span className="range_tootip_val" style={{ left: `${tpPosition}` }}>{value === 0 ? "none" : value === 1 ? "very mild" : value === 2 || value === 3 ||  value === 4 ? "mild" : value === 5 || value === 6 ? "moderate" :  value === 7 || value === 8 || value === 9 || value === 10 ? "severe" : ""}</span>
            </div>
          )}

        </div>
        {type === "image" && <span className="sad_face"><label>100%</label></span>}
        {type === "text" && <span className="unstable_txt">{rightTitle}</span>}
        
      </div>
    </div>
  );
};

HipRangeSlider.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

HipRangeSlider.defaultProps = {
  value: "",
  name: "",
  label: "",
  id: "",
  min: "",
  max: "",
  step: "",
  onChange: "",
  onMouseUp: "",
  formGroupClass: "",
  type: "",
  leftTitle: "",
  rightTitle: "",
};

export default HipRangeSlider;
