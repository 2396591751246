import en from "./en";
import fr from "./fr";
import ara from "./ara";
import ger from "./ger";
import ita from "./ita";
import spa from "./spa";
import tha from "./thai.js";
import kor from './korean.js';
import dth from './dutch.js';
import japan from './japanese.js';
import ch from './chnSimple';
import chnt from './chnTraditional';
export default {
  en,
  fr,
  ara,
  ger,
  ita,
  spa,
  tha,
  kor,
  dth,
  japan,
  ch,
  chnt
};
