import React from "react";
import "../../../css/ViewTreatmentForm.css";
import useTranslation from "../../customHooks/translations";
import {UserDateFormat} from "../../../utilities/DateFormate"

function PersonalInformation({ profileAllData, AllData }) {
  const translations=useTranslation();
  return (
    <>
      <div className="whitebox padding-none">
        <div className="treatment-header">
          <h2>{translations.patient_personal_info}</h2>
          <div className="treatment-header-right">
            <p>{translations.Assessment_Date} : {UserDateFormat(AllData.assessment_date,profileAllData.ing_date_formate)}</p>
          </div>
        </div>

        <div className="treatment-content">
          <div className="treatment-row">
            <div className="treatment-col">
              <label>{translations.Name}:</label>
              {profileAllData.ing_firstname} {profileAllData.ing_lastname}
            </div>

            <div className="treatment-col">
              <label>{translations.Date_of_Birth}:</label>
              {profileAllData.ing_dob}
            </div>
          </div>

          <div className="treatment-row">
            <div className="treatment-col">
              <label>{translations.Sex_1}:</label>
              {/* {profileAllData.ing_gender === "2" ? "Female" : "Male"} */}
              {profileAllData.ing_gender === "1"
                ? translations.Male
                : profileAllData.ing_gender === "2"
                  ? translations.Female
                  : profileAllData.ing_gender === "3"
                    ? translations.want_to_disclose
                    : "" }
            </div>

            <div className="treatment-col">
              <label>{translations.Dominant_hand_1}:</label>
              {AllData.dominant === "1" ? translations.Right : AllData.dominant === "2" ? translations.Left : AllData.dominant === "3" ? translations.Bilateral:AllData.dominant}
            </div>

          </div>

          <div className="treatment-row">
            <div className="treatment-col">
              <label>{translations.patient_Initial_Assessment}:</label>
              {AllData.initial_assessment === 1 ? translations.Yes : translations.No}

            </div>

            <div className="treatment-col">
              <label>{translations.Evaluated_Hand_Wrist}:</label>
              {AllData.evaluated === "3"
                ? translations.Both
                : AllData.evaluated === "2"
                  ? translations.Left
                  : translations.Right}
            </div>
          </div>

          
          <div className="treatment-row">
            <div className="treatment-col">
              <label>{translations.Do_you_have_carpal_tunnel_syndrome} :</label>
              {AllData.isSyndrome === "2" ? translations.Yes : translations.No}
            </div>
            <div className="treatment-col">
              <label>{translations.Do_you_have_Dupuytren_disease} :</label>
              {AllData.isDisease === "2" ? translations.Yes : translations.No}
            </div>
          </div>
          <div className="treatment-row">
            <div className="treatment-col">
              <label>{translations.patient_Treatment_Category}:</label>
              {translations.myJourney_Hand_Wrist}
              {/* {translations.Hand_and_Wrist_Assessment} */}
            </div>

            {AllData.evaluated !== "3" && (
              <div className="treatment-col">
                <label>
                  {translations.Is_your_unaffected_Hand_Wrist_pain_free_and_allows_normal_function}:
                </label>
                {AllData.unaffected_hand === "1" ? translations.Yes : AllData.unaffected_hand === "0" ? translations.No : ""}
              </div>
            )}


          </div>
        </div>
      </div>
    </>
  );
}

export default PersonalInformation;
