import React from "react";
import "../../../css/ViewTreatmentForm.css";
import useTranslation from "../../customHooks/translations";

export default function FootAnkleSymptoms({ FootsymptomsScore, AllData }) {
  const translations = useTranslation();
  return (
    <>
      <div className="whitebox padding-none">
        <div className="treatment-content">
          <div className="treatment-row-header">
            3. {translations.patient_foot_ankle_symptoms}
          </div>
          <div className="treatment-row">
            <div className="treatment-table">
              <table cellpadding="0" cellspacing="0">
                <thead>
                  <tr>
                    <th></th>
                    <th>{translations.Right_Foot_and_Ankle}</th>
                    <th>{translations.Left_Foot_and_Ankle}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {translations.patient_foot_ankle_other_symptoms_q1}:
                    </td>
                    <td>{FootsymptomsScore.swellingR}</td>
                    <td>{FootsymptomsScore.swellingL}</td>
                  </tr>
                  <tr>
                    <td>
                      {translations.patient_foot_ankle_other_symptoms_q2}:
                    </td>
                    <td>{FootsymptomsScore.grindingR}</td>
                    <td>{FootsymptomsScore.grindingL}</td>
                  </tr>
                  <tr>
                    <td>
                      {translations.patient_foot_ankle_other_symptoms_q3} :
                    </td>
                    <td>{FootsymptomsScore.catchingR}</td>
                    <td>{FootsymptomsScore.catchingL}</td>
                  </tr>
                  <tr>
                    <td>
                      {translations.patient_foot_ankle_other_symptoms_q4}:
                    </td>
                    <td>{FootsymptomsScore.stiffnessR}</td>
                    <td>{FootsymptomsScore.stiffnessL}</td>
                  </tr>
                  <tr>
                    <td>
                      {translations.patient_foot_ankle_other_symptoms_q5} :
                    </td>
                    <td>{FootsymptomsScore.stiffnessSittingR}</td>
                    <td>{FootsymptomsScore.stiffnessSittingL}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
