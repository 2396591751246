import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import vertMenuStyles from "./FormVerticalMenu.module.css";
import useTransition from "../../../../customHooks/doctorTranslation";

function FormVerticalMenu({ setGetId }) {
  const translation = useTransition();
  return (
    <>
      <Navbar
        bg="light"
        className={`scrollbar_vert ${vertMenuStyles.vm_sidebar}`}
      >
        <Nav
          className={`me-auto my-2 my-lg-0 ${vertMenuStyles.vm_sidebar_inner}`}
          navbarScroll
        >
          <Nav.Link
            className={`${vertMenuStyles.vm_item}`}
            onClick={() => setGetId("subscapularis")}
            // href="#subscapularis"
          >
            {translation.Subscapularis}
          </Nav.Link>
          <Nav.Link
            className={`${vertMenuStyles.vm_item}`}
            onClick={() => setGetId("supraspinatus")}
            // href="#supraspinatus"
          >
            {translation.Supraspinatus}
          </Nav.Link>
          <Nav.Link
            className={`${vertMenuStyles.vm_item}`}
            onClick={() => setGetId("infraspinatus")}
            // href="#infraspinatus"
          >
            {translation.Infraspinatus}
          </Nav.Link>
          <Nav.Link
            className={`${vertMenuStyles.vm_item}`}
            onClick={() => setGetId("teres-minor")}
            // href="#teres-minor"
          >
            {translation.Teres_Minor}
          </Nav.Link>
          <Nav.Link
            className={`${vertMenuStyles.vm_item}`}
            onClick={() => setGetId("acj")}
            // href="#acj"
          >
            {translation.ACJ}
          </Nav.Link>
          <Nav.Link
            className={`${vertMenuStyles.vm_item}`}
            onClick={() => setGetId("labrum")}
            // href="#labrum"
          >
            {translation.Labrum}
          </Nav.Link>
          <Nav.Link
            className={`${vertMenuStyles.vm_item}`}
            onClick={() => setGetId("biceps")}
            // href="#biceps"
          >
            {translation.Biceps}
          </Nav.Link>
          <Nav.Link
            className={`${vertMenuStyles.vm_item}`}
            onClick={() => setGetId("triceps-strength")}
            // href="#triceps-strength"
          >
            {translation.Triceps_Strength}
          </Nav.Link>
          <Nav.Link
            className={`${vertMenuStyles.vm_item}`}
            onClick={() => setGetId("deltoid")}
            // href="#deltoid"
          >
            {translation.Deltoid}
          </Nav.Link>
          <Nav.Link
            className={`${vertMenuStyles.vm_item}`}
            onClick={() => setGetId("cspine")}
            // href="#cspine"
          >
            {translation.C_Spine}
          </Nav.Link>
          <Nav.Link
            className={`${vertMenuStyles.vm_item}`}
            onClick={() => setGetId("ssn-stretch")}
            // href="#ssn-stretch"
          >
            {translation.SSN_Stretch}
          </Nav.Link>
          <Nav.Link
            className={`${vertMenuStyles.vm_item}`}
            onClick={() => setGetId("heri")}
            // href="#heri"
          >
            {translation.HERI}
          </Nav.Link>
          <Nav.Link
            className={`${vertMenuStyles.vm_item}`}
            onClick={() => setGetId("gagey")}
            // href="#gagey"
          >
            {translation.GAGEY}
          </Nav.Link>
          <Nav.Link
            className={`${vertMenuStyles.vm_item}`}
            onClick={() => setGetId("scapula-dyskinesia")}
            // href="#scapula-dyskinesia"
          >
            {translation.Scapula_Dyskinesia}
          </Nav.Link>
          <Nav.Link
            className={`${vertMenuStyles.vm_item}`}
            onClick={() => setGetId("thoracic-outlet")}
            // href="#thoracic-outlet"
          >
            {translation.Thoracic_Outlet}
          </Nav.Link>
          <Nav.Link
            className={`${vertMenuStyles.vm_item}`}
            onClick={() => setGetId("grip-strength")}
            // href="#grip-strength"
          >
            {translation.Grip_Strength}
          </Nav.Link>
        </Nav>
      </Navbar>
    </>
  );
}

export default FormVerticalMenu;
