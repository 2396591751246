export default{
  "patient_shoulder_pain":"痛",
  "patient_shoulder_pain_q1":"您的肩膀痛吗？",
  "patient_shoulder_pain_q1_a1":"否",
  "patient_shoulder_pain_q1_a2":"是",
  "patient_shoulder_pain_q2":"以 0-15 分表示，您在过去 24 小时内进行普通活动时肩部的最高疼痛程度是多少？(0 表示无疼痛，15 表示无法忍受的疼痛）",
  "patient_shoulder_pain_q2_a1":"无法忍受的疼痛",
  "patient_shoulder_pain_q2_a2":"无疼痛",
  "patient_shoulder_pain_q3":"您的睡眠是否受到肩膀的干扰？",
  "patient_shoulder_pain_q3_a1":"不受干扰的睡眠",
  "patient_shoulder_pain_q3_a2":"偶尔干扰",
  "patient_shoulder_pain_q3_a3":"每晚干扰",
  "patient_shoulder_pain_q4":"您今天有多痛？",
  "patient_shoulder_pain_q5":"您目前是吸烟者吗？",
  "patient_shoulder_pain_q5_a1":"否",
  "patient_shoulder_pain_q5_a2":"是",
  "patient_shoulder_pain_q5_a3":"戒烟者",
  "patient_shoulder_pain_q5_a2_q1":"您每天吸多少支烟？",
  "patient_shoulder_pain_q5_a3_q1":"您戒烟多久了（几年）？",
  "patient_shoulder_pain_q5_a3_q2":"您抽烟抽了多少年？",
  "patient_shoulder_pain_q6":"您服用止痛药吗？",
  "patient_shoulder_pain_q6_a1":"否",
  "patient_shoulder_pain_q6_a2":"是",
  "patient_shoulder_pain_q6_a2_q1":"您服用强效之痛药吗 (可待因，曲马多， 吗啡）？",
  "patient_shoulder_pain_q6_a2_q1_a1":"否",
  "patient_shoulder_pain_q6_a2_q1_a2":"是",
  "patient_shoulder_pain_q6_a2_q2":"您平均每天服用多少颗？",
  "patient_shoulder_pain_q7":"以下哪一项陈述最能描述您的疼痛？",
  "patient_shoulder_pain_q7_a1":"没有任何",
  "patient_shoulder_pain_q7_a2":"偶尔和轻微",
  "patient_shoulder_pain_q7_a3":"在剧烈或特定活动时出现，偶尔需要服用阿司匹林",
  "patient_shoulder_pain_q7_a4":"在轻度活动或休息时出现，需要经常服用阿司匹林",
  "patient_shoulder_pain_q7_a5":"始终存在, 但可以忍受， 偶尔服用强效药物",
  "patient_shoulder_pain_q7_a6":"始终存在， 不能忍受，经常服用强效药物",
  "patient_shoulder_pain_q8":"您的手臂是否有针刺或麻木的感觉？",
  "patient_shoulder_pain_q8_a1":"否",
  "patient_shoulder_pain_q8_a2":"是",
  "patient_shoulder_pain_q9":"请标明哪里疼痛",
  "patient_shoulder_pain_q9_a1":"前面",
  "patient_shoulder_pain_q9_a2":"后面",
  "patient_shoulder_function":"功能",
  "patient_shoulder_function_q1":"以下哪一项陈述最能描述疼痛肩膀的功能？",
  "patient_shoulder_function_q1_a1":"普通， 没有限制",
  "patient_shoulder_function_q1_a2":"轻微限制，能在肩膀以上的水平工作",
  "patient_shoulder_function_q1_a3":"大多数家务，购物，驾驶，整理头发，穿脱衣服",
  "patient_shoulder_function_q1_a4":"执行轻微家务和大多日常生活活动",
  "patient_shoulder_function_q1_a5":"只可执行轻微活动",
  "patient_shoulder_function_q1_a6":"无法运用手臂",
  "patient_shoulder_function_instruction":"请选择哪个数字来表示您进行活动的能力",
  "patient_shoulder_function_instruction_0":"无法",
  "patient_shoulder_function_instruction_1":"艰难",
  "patient_shoulder_function_instruction_2":"有些难",
  "patient_shoulder_function_instruction_3":"不难",
  "patient_shoulder_function_note":"笔记：请回答以下与您的肩部功能有关的问题",
  "patient_shoulder_function_q2":"穿外套/衬衫",
  "patient_shoulder_function_q2_a0":"无法",
  "patient_shoulder_function_q2_a1":"艰难",
  "patient_shoulder_function_q2_a2":"有些难",
  "patient_shoulder_function_q2_a3":"不难",
  "patient_shoulder_function_q3":"侧躺靠在您疼痛的肩部睡觉",
  "patient_shoulder_function_q3_a0":"无法",
  "patient_shoulder_function_q3_a1":"艰难",
  "patient_shoulder_function_q3_a2":"有些难",
  "patient_shoulder_function_q3_a3":"不难",
  "patient_shoulder_function_q4":"洗自己背/扣自己胸罩",
  "patient_shoulder_function_q4_a0":"无法",
  "patient_shoulder_function_q4_a1":"艰难",
  "patient_shoulder_function_q4_a2":"有些难",
  "patient_shoulder_function_q4_a3":"不难",
  "patient_shoulder_function_q5":"上厕所擦屁股",
  "patient_shoulder_function_q5_a0":"无法",
  "patient_shoulder_function_q5_a1":"艰难",
  "patient_shoulder_function_q5_a2":"有些难",
  "patient_shoulder_function_q5_a3":"不难",
  "patient_shoulder_function_q6":"梳头发",
  "patient_shoulder_function_q6_a0":"无法",
  "patient_shoulder_function_q6_a1":"艰难",
  "patient_shoulder_function_q6_a2":"有些难",
  "patient_shoulder_function_q6_a3":"不难",
  "patient_shoulder_function_q7":"刮胡子/化妆",
  "patient_shoulder_function_q7_a0":"无法",
  "patient_shoulder_function_q7_a1":"艰难",
  "patient_shoulder_function_q7_a2":"有些难",
  "patient_shoulder_function_q7_a3":"不难",
  "patient_shoulder_function_q8":"刷牙",
  "patient_shoulder_function_q8_a0":"无法",
  "patient_shoulder_function_q8_a1":"艰难",
  "patient_shoulder_function_q8_a2":"有些难",
  "patient_shoulder_function_q8_a3":"不难",
  "patient_shoulder_function_q9":"用一整瓶水把玻璃杯装满",
  "patient_shoulder_function_q9_a0":"无法",
  "patient_shoulder_function_q9_a1":"艰难",
  "patient_shoulder_function_q9_a2":"有些难",
  "patient_shoulder_function_q9_a3":"不难",
  "patient_shoulder_function_q10":"喝水 （把装满的杯子举到嘴前）",
  "patient_shoulder_function_q10_a0":"无法",
  "patient_shoulder_function_q10_a1":"艰难",
  "patient_shoulder_function_q10_a2":"有些难",
  "patient_shoulder_function_q10_a3":"不难",
  "patient_shoulder_function_q11":"喝汤 （把满满的汤匙放到嘴里）",
  "patient_shoulder_function_q11_a0":"无法",
  "patient_shoulder_function_q11_a1":"艰难",
  "patient_shoulder_function_q11_a2":"有些难",
  "patient_shoulder_function_q11_a3":"不难",
  "patient_shoulder_function_q12":"握手 / 打开门",
  "patient_shoulder_function_q12_a0":"无法",
  "patient_shoulder_function_q12_a1":"艰难",
  "patient_shoulder_function_q12_a2":"有些难",
  "patient_shoulder_function_q12_a3":"不难",
  "patient_shoulder_function_q13":"用电话 （拿到耳朵的高度）",
  "patient_shoulder_function_q13_a0":"无法",
  "patient_shoulder_function_q13_a1":"艰难",
  "patient_shoulder_function_q13_a2":"有些难",
  "patient_shoulder_function_q13_a3":"不难",
  "patient_shoulder_function_q14":"手写封信 / 签文章 / 运用键盘",
  "patient_shoulder_function_q14_a0":"无法",
  "patient_shoulder_function_q14_a1":"艰难",
  "patient_shoulder_function_q14_a2":"有些难",
  "patient_shoulder_function_q14_a3":"不难",
  "patient_shoulder_function_q15":"用手拿起或触及高架上的物品",
  "patient_shoulder_function_q15_a0":"无法",
  "patient_shoulder_function_q15_a1":"艰难",
  "patient_shoulder_function_q15_a2":"有些难",
  "patient_shoulder_function_q15_a3":"不难",
  "patient_shoulder_function_q16":"将 0.5 公斤（1英磅） 的物品举高于肩膀",
  "patient_shoulder_function_q16_a0":"无法",
  "patient_shoulder_function_q16_a1":"艰难",
  "patient_shoulder_function_q16_a2":"有些难",
  "patient_shoulder_function_q16_a3":"不难",
  "patient_shoulder_function_q17":"将 4.5 公斤（10英磅）的物品举高于肩膀",
  "patient_shoulder_function_q17_a0":"无法",
  "patient_shoulder_function_q17_a1":"艰难",
  "patient_shoulder_function_q17_a2":"有些难",
  "patient_shoulder_function_q17_a3":"不难",
  "patient_shoulder_function_q18":"利用疼痛肩部一侧的手臂提 10 公斤 （22英磅）的物品",
  "patient_shoulder_function_q18_a0":"无法",
  "patient_shoulder_function_q18_a1":"艰难",
  "patient_shoulder_function_q18_a2":"有些难",
  "patient_shoulder_function_q18_a3":"不难",
  "patient_shoulder_function_q19":"低手投球 （至少 3 米）",
  "patient_shoulder_function_q19_a0":"无法",
  "patient_shoulder_function_q19_a1":"艰难",
  "patient_shoulder_function_q19_a2":"有些难",
  "patient_shoulder_function_q19_a3":"不难",
  "patient_shoulder_function_q20":"高臂投球 （至少 6 米）",
  "patient_shoulder_function_q20_a0":"无法",
  "patient_shoulder_function_q20_a1":"艰难",
  "patient_shoulder_function_q20_a2":"有些难",
  "patient_shoulder_function_q20_a3":"不难",
  "patient_shoulder_function_q21":"完整进行平日的工作",
  "patient_shoulder_function_q21_a0":"无法",
  "patient_shoulder_function_q21_a1":"艰难",
  "patient_shoulder_function_q21_a2":"有些难",
  "patient_shoulder_function_q21_a3":"不难",
  "patient_shoulder_function_q22":"您的肩部能够胜任多少正常工作？",
  "patient_shoulder_function_q22_a0":"无",
  "patient_shoulder_function_q22_a1":"全部",
  "patient_shoulder_function_q23":"完整进行爱好/运动",
  "patient_shoulder_function_q23_a0":"无法",
  "patient_shoulder_function_q23_a1":"艰难",
  "patient_shoulder_function_q23_a2":"有些难",
  "patient_shoulder_function_q23_a3":"不难",
  "patient_shoulder_function_q24":"您的肩膀能够胜任多少正常的娱乐活动？",
  "patient_shoulder_function_q24_a0":"无",
  "patient_shoulder_function_q24_a1":"全部",
  "patient_shoulder_function_q25":"请列出工作活动",
  "patient_shoulder_function_q26":"请列出爱好/运动",
  "patient_shoulder_feeling_instability":"肩关节不稳定",
  "patient_shoulder_feeling_instability_q1":"您的肩是否感觉不稳定 （好像快脱臼）？",
  "patient_shoulder_feeling_instability_q1_a1":"否",
  "patient_shoulder_feeling_instability_q1_a2":"是",
  "patient_shoulder_feeling_instability_q1_a2_q1":"请标明您的肩关节有多不稳定",
  "patient_shoulder_wosi":"安大略省西部肩关节不稳定指数",
  "patient_shoulder_wosi_instruction":"指示：请您在这个问卷调查表明您疼痛肩膀在过去的一周里经验多少症状。把滑块移动在线条上来准确对应症状的严重程度。",
  "patient_shoulder_wosi_note":"注意：将滑块向右移动得越远，表示您遇到该症状的程度就越深。将滑块向左移动得越远，表明您遇到的这种症状就越少。如果您对任何特定问题的意图有任何疑问，请询问。",
  "patient_shoulder_wosi_sectionA":"部分 A：身体症状",
  "patient_shoulder_wosi_q1":"您的肩膀在进行过头活动时有多少疼痛？",
  "patient_shoulder_wosi_q1_a1":"没有疼痛",
  "patient_shoulder_wosi_q1_a2":"极度的疼痛",
  "patient_shoulder_wosi_q2":"您的肩膀经验多少抽痛？",
  "patient_shoulder_wosi_q2_a1":"没有抽痛",
  "patient_shoulder_wosi_q2_a2":"极度的抽痛",
  "patient_shoulder_wosi_q3":"您的肩膀有缺乏力量或欺弱吗？",
  "patient_shoulder_wosi_q3_a1":"没有缺乏力量",
  "patient_shoulder_wosi_q3_a2":"极度缺乏力量",
  "patient_shoulder_wosi_q4":"您的肩膀感觉疲劳或缺乏耐力吗？",
  "patient_shoulder_wosi_q4_a1":"没有疲劳",
  "patient_shoulder_wosi_q4_a2":"极度疲劳",
  "patient_shoulder_wosi_q5":"您的肩膀发出多少咔嗒声，爆裂声， 噼啪声？",
  "patient_shoulder_wosi_q5_a1":"没有咔嗒声",
  "patient_shoulder_wosi_q5_a2":"极度咔嗒声",
  "patient_shoulder_wosi_q6":"您的肩膀僵硬吗？",
  "patient_shoulder_wosi_q6_a1":"没有僵硬",
  "patient_shoulder_wosi_q6_a2":"极度僵硬",
  "patient_shoulder_wosi_q7":"因为您的肩膀疼痛，颈部肌肉有多少不舒服？",
  "patient_shoulder_wosi_q7_a1":"没有不舒服",
  "patient_shoulder_wosi_q7_a2":"极度不舒服",
  "patient_shoulder_wosi_q8":"您的肩关节有多少不稳定？",
  "patient_shoulder_wosi_q8_a1":"没有不稳定",
  "patient_shoulder_wosi_q8_a2":"极度不稳定",
  "patient_shoulder_wosi_q9":"因为肩膀的疼痛，您运用身体别的肌肉补偿多少？",
  "patient_shoulder_wosi_q9_a1":"一点也不",
  "patient_shoulder_wosi_q9_a2":"极度",
  "patient_shoulder_wosi_q10":"您的肩膀因疼痛有失去多少活动范围？",
  "patient_shoulder_wosi_q10_a1":"没有失去",
  "patient_shoulder_wosi_q10_a2":"极度失去",
  "patient_shoulder_wosi_sectionB":"部分 B：运动 / 悠闲 / 工作",
  "patient_shoulder_wosi_q11":"您的肩膀限制了多少您参加运动或悠闲的活动？",
  "patient_shoulder_wosi_q11_a1":"没有限制",
  "patient_shoulder_wosi_q11_a2":"极度限制",
  "patient_shoulder_wosi_q12":"您的肩膀限制了多少您工作或运动所需的特定技能？",
  "patient_shoulder_wosi_q12_note":"如工作或运动因肩膀而受限制，考虑一下影响程度",
  "patient_shoulder_wosi_q12_a1":"没有影响",
  "patient_shoulder_wosi_q12_a2":"极度影响",
  "patient_shoulder_wosi_q13":"在进行活动时，您有需要保护肩臂的感觉吗？",
  "patient_shoulder_wosi_q13_a1":"一点也不",
  "patient_shoulder_wosi_q13_a2":"极度",
  "patient_shoulder_wosi_q14":"您在低于肩部水平举起重物的时候有经验任何困难吗？",
  "patient_shoulder_wosi_q14_a1":"没有困难",
  "patient_shoulder_wosi_q14_a2":"极度困难",
  "patient_shoulder_wosi_sectionC":"部分 C：生活方式",
  "patient_shoulder_wosi_q15":"您有多害怕摔倒撞到肩膀？",
  "patient_shoulder_wosi_q15_a1":"没有害怕",
  "patient_shoulder_wosi_q15_a2":"极度害怕",
  "patient_shoulder_wosi_q16":"您在维持健康水平方面遇到多少困难？",
  "patient_shoulder_wosi_q16_a1":"没有困难",
  "patient_shoulder_wosi_q16_a2":"极度困难",
  "patient_shoulder_wosi_q17":"您和家人与朋友们嬉笑打闹时有困难吗？",
  "patient_shoulder_wosi_q17_a1":"没有困难",
  "patient_shoulder_wosi_q17_a2":"极度困难",
  "patient_shoulder_wosi_q18":"您因肩膀不舒服造成入睡有困难吗？",
  "patient_shoulder_wosi_q18_a1":"没有困难",
  "patient_shoulder_wosi_q18_a2":"极度困难",
  "patient_shoulder_wosi_sectionD":"部分 D：情绪",
  "patient_shoulder_wosi_q19":"您是否时时意识自己的肩膀？",
  "patient_shoulder_wosi_q19_a1":"不了解",
  "patient_shoulder_wosi_q19_a2":"极度了解",
  "patient_shoulder_wosi_q20":"您是否担心您的肩膀变得更糟？",
  "patient_shoulder_wosi_q20_a1":"没有担心",
  "patient_shoulder_wosi_q20_a2":"极度担心",
  "patient_shoulder_wosi_q21":"您是否因您的肩膀感到挫折？",
  "patient_shoulder_wosi_q21_a1":"没有挫折",
  "patient_shoulder_wosi_q21_a2":"极度挫折",
  "patient_shoulder_ac_joint":"AC 关节",
  "patient_shoulder_ac_joint_q1":"您的AC关节有不稳定吗？",
  "patient_shoulder_ac_joint_q1_note":"注：AC 关节就是肩膀顶部连接锁骨和肩胛骨的关节。",
  "patient_shoulder_ac_joint_q1_a1":"否",
  "patient_shoulder_ac_joint_q1_a2":"是",
  "patient_shoulder_sacs":"特定的AC指数 （SACS）",
  "patient_shoulder_sacs_explanation":"说明：对于每个问题，您都会被要求对执行不同任务时的疼痛程度或困难程度进行评分。评级量表的设计使得 0 代表没有疼痛或困难，而 10 代表最严重的疼痛或困难。",
  "patient_shoulder_sacs_note":"注：2 代表程度最低的疼痛。如果您对任何特定问题的意图有疑问，请询问。",
  "patient_shoulder_sacs_sectionA":"部分 A：疼痛",
  "patient_shoulder_sacs_a1":"评估您肩膀最严重疼痛时，疼痛的等级",
  "patient_shoulder_sacs_a1_a1":"没有疼痛",
  "patient_shoulder_sacs_a1_a2":"最疼痛",
  "patient_shoulder_sacs_a2":"评估您肩膀感觉最好时，疼痛的等级",
  "patient_shoulder_sacs_a2_a1":"没有疼痛",
  "patient_shoulder_sacs_a2_a2":"最疼痛",
  "patient_shoulder_sacs_a3":"评估您肩膀被触摸或被施加压力的时候，疼痛的等级",
  "patient_shoulder_sacs_a3_note":"如：笔记电脑本包或手提包的带子",
  "patient_shoulder_sacs_a3_a1":"没有疼痛",
  "patient_shoulder_sacs_a3_a2":"极端疼痛",
  "patient_shoulder_sacs_a4":"在颈部或肩膀的感觉",
  "patient_shoulder_sacs_a4_a1":"没有疼痛",
  "patient_shoulder_sacs_a4_a2":"极端疼痛",
  "patient_shoulder_sacs_sectionB":"部分 B：功能",
  "patient_shoulder_sacs_b1":"评价您在肩膀上感受到的咔哒声和/或磨擦声的数量",
  "patient_shoulder_sacs_b1_a1":"没有咔嗒声",
  "patient_shoulder_sacs_b1_a2":"极度咔嗒声",
  "patient_shoulder_sacs_b2":"表明您的手臂在行走时是否感觉需要被支撑",
  "patient_shoulder_sacs_b2_note":"比如：把手安息在口袋里或使用吊带",
  "patient_shoulder_sacs_b2_a1":"不需要支撑",
  "patient_shoulder_sacs_b2_a2":"极度的需要支撑",
  "patient_shoulder_sacs_b3":"评估您举重或通过手臂倾斜的困难程度",
  "patient_shoulder_sacs_b3_note":"比如：俯卧撑，骑自行车，瑜伽",
  "patient_shoulder_sacs_b3_a1":"没有困难",
  "patient_shoulder_sacs_b3_a2":"极度困难 / 无法进行",
  "patient_shoulder_sacs_b4":"评估您在身边需重物超过五分钟的时间",
  "patient_shoulder_sacs_b4_note":"比如：购物袋",
  "patient_shoulder_sacs_b4_a1":"没有困难",
  "patient_shoulder_sacs_b4_a2":"极度困难 / 无法进行",
  "patient_shoulder_sacs_b5":"评估您运用受影响的手臂接触身体各处",
  "patient_shoulder_sacs_b5_note":"比如：过身接触没有受影响的肩膀",
  "patient_shoulder_sacs_b5_a1":"没有困难",
  "patient_shoulder_sacs_b5_a2":"极度困难 / 无法进行",
  "patient_shoulder_sacs_b6":"评估您推重物或过头举重物",
  "patient_shoulder_sacs_b6_note":"比如：超越 5 公斤",
  "patient_shoulder_sacs_b6_a1":"没有困难",
  "patient_shoulder_sacs_b6_a2":"极度困难 / 无法进行",
  "patient_shoulder_sacs_b7":"评估把您受影响的手臂在您面前长时间抬起来",
  "patient_shoulder_sacs_b7_note":"比如：驾车时运用方向盘",
  "patient_shoulder_sacs_b7_a1":"没有困难",
  "patient_shoulder_sacs_b7_a2":"极度困难 / 无法进行",
  "patient_shoulder_sacs_b8":"评估您进行您的职业",
  "patient_shoulder_sacs_b8_a1":"没有困难",
  "patient_shoulder_sacs_b8_a2":"极度困难 / 无法进行",
  "patient_shoulder_sacs_b8_a3":"不适用",
  "patient_shoulder_sacs_b9":"评估为了补偿受影响的肩膀，您需要调整多少职业活动",
  "patient_shoulder_sacs_b9_a1":"没有困难",
  "patient_shoulder_sacs_b9_a2":"极度困难 / 无法进行",
  "patient_shoulder_sacs_b9_a3":"不适用",
  "patient_shoulder_sacs_b10":"评估您进行主要体育活动时的困难",
  "patient_shoulder_sacs_b10_a1":"没有困难",
  "patient_shoulder_sacs_b10_a2":"极度困难 / 无法进行",
  "patient_shoulder_sacs_b10_a3":"不适用",
  "patient_shoulder_sacs_b11":"评估您为了补偿受影响肩膀的功能，调整了多少您的主要体育活动",
  "patient_shoulder_sacs_b11_a1":"没有困难",
  "patient_shoulder_sacs_b11_a2":"极度困难 / 无法进行",
  "patient_shoulder_sacs_b11_a3":"不适用",
  "patient_shoulder_sacs_sectionC":"部分 C：生活质量和满意度",
  "patient_shoulder_sacs_c1":"评估您的肩膀骨骼的外观与普通的肩膀有多少分差",
  "patient_shoulder_sacs_c1_note":"肿块的存在",
  "patient_shoulder_sacs_c1_a1":"没有差别",
  "patient_shoulder_sacs_c1_a2":"极度差别",
  "patient_shoulder_sacs_c2":"评估因肩膀问题而受压力或焦虑",
  "patient_shoulder_sacs_c2_a1":"没有受压力/焦虑",
  "patient_shoulder_sacs_c2_a2":"严重受压力/焦虑",
  "patient_shoulder_sacs_c3":"您多常意识到自己的肩部问题？",
  "patient_shoulder_sacs_c3_a1":"不时常",
  "patient_shoulder_sacs_c3_a2":"不断地",
  "patient_shoulder_sacs_c4":"可能加剧或损坏肩部",
  "patient_shoulder_sacs_c4_a1":"绝不",
  "patient_shoulder_sacs_c4_a2":"完全",
  "patient_shoulder_sacs_c5":"您对自己的肩膀又多少不高心或不满意？",
  "patient_shoulder_sacs_c5_a1":"极度高兴",
  "patient_shoulder_sacs_c5_a2":"极度不满意",
  "patient_shoulder_sacs_c6":"与您对“正常”肩部的预期（0% 表示较差，100% 表示非常好）相比，您如何评价您的肩部整体百分比",
  "patient_shoulder_satisfaction":"满意感",
  "patient_shoulder_satisfaction_q1":"对于您受影响的肩膀，现今您",
  "patient_shoulder_satisfaction_q1_a1":"满意/很满意",
  "patient_shoulder_satisfaction_q1_a2":"不满意 / 更不满意",
  "patient_shoulder_complete":"问卷结束",
  "patient_shoulder_complete_text1":"感谢您花时间来填写此表格",
  "patient_shoulder_complete_text2":"请点击提交查看您的分数",
  "patient_shoulder_complete_text3":"您的回答将被保密，任何报告都将仅使用去识别化的信息",
  "patient_shoulder_results":"结果",
  "patient_shoulder_results_text1":"以下是您的疼痛和肩部功能评分，这些评分是根据您的调查问卷计算得出的。",
  "patient_shoulder_results_pain":"疼痛",
  "patient_shoulder_results_function":"功能",
  "patient_shoulder_results_instability":"不稳定性",
  "patient_shoulder_results_selfevaluation":"肩膀自我评分",
  "patient_shoulder_results_text2":"您的医生很快就会和您见面",
  "patient_hip_pain":"疼痛",
  "patient_hip_pain_q1":"平常髋部疼痛",
  "patient_hip_pain_q1_a1":"否",
  "patient_hip_pain_q1_a2":"很轻微疼痛",
  "patient_hip_pain_q1_a3":"轻微疼痛",
  "patient_hip_pain_q1_a4":"中度疼痛",
  "patient_hip_pain_q1_a5":"重度疼痛",
  "patient_hip_pain_q2":"此时您的臀部疼痛有多严重？ （0 表示没有疼痛，10 表示您能想象到的最严重的疼痛）",
  "patient_hip_pain_q2_a1":"没有疼痛",
  "patient_hip_pain_q2_a2":"最严重的疼痛",
  "patient_hip_pain_q3":"以下哪一项最能描述您的痛苦?",
  "patient_hip_pain_q3_a1":"否 / 忽略",
  "patient_hip_pain_q3_a2":"一点点，偶尔，活动不受影响",
  "patient_hip_pain_q3_a3":"轻微疼痛，平均活动不受影响，普通活动后经验疼痛，服用阿司匹林",
  "patient_hip_pain_q3_a4":"中度疼痛，可以忍受，有时让步，偶尔服用可待因",
  "patient_hip_pain_q3_a5":"极端疼痛，严重限制",
  "patient_hip_pain_q3_a6":"完全残疾",
  "patient_hip_pain_q4":"您的髋部多常疼痛？",
  "patient_hip_pain_q4_a1":"决不",
  "patient_hip_pain_q4_a2":"每个月",
  "patient_hip_pain_q4_a3":"每周",
  "patient_hip_pain_q4_a4":"每天",
  "patient_hip_pain_q4_a5":"时常",
  "patient_hip_pain_q5":"您髋部的疼痛干扰了多少您的工作 （包括家务）？",
  "patient_hip_pain_q5_a1":"没有干扰",
  "patient_hip_pain_q5_a2":"稍微干扰",
  "patient_hip_pain_q5_a3":"中度干扰",
  "patient_hip_pain_q5_a4":"超度干扰",
  "patient_hip_pain_q5_a5":"完全干扰",
  "patient_hip_pain_instruction":"在进行以下的活动是，您经验多少髋部疼痛？",
  "patient_hip_pain_instruction_0":"没有疼痛",
  "patient_hip_pain_instruction_1":"轻微疼痛",
  "patient_hip_pain_instruction_2":"中度干扰",
  "patient_hip_pain_instruction_3":"严重疼痛",
  "patient_hip_pain_instruction_4":"极度疼痛",
  "patient_hip_pain_note":"指示：请回答一下关于您的髋部活动的的问题。",
  "patient_hip_pain_q6":"完全伸直髋部",
  "patient_hip_pain_q6_a1":"否",
  "patient_hip_pain_q6_a2":"轻微",
  "patient_hip_pain_q6_a3":"中度",
  "patient_hip_pain_q6_a4":"严重",
  "patient_hip_pain_q6_a5":"极度",
  "patient_hip_pain_q7":"完全弯下髋部",
  "patient_hip_pain_q7_a1":"否",
  "patient_hip_pain_q7_a2":"轻微",
  "patient_hip_pain_q7_a3":"中度",
  "patient_hip_pain_q7_a4":"严重",
  "patient_hip_pain_q7_a5":"极度",
  "patient_hip_pain_q8":"在平坦的表面步行",
  "patient_hip_pain_q8_a1":"否",
  "patient_hip_pain_q8_a2":"轻微",
  "patient_hip_pain_q8_a3":"中度",
  "patient_hip_pain_q8_a4":"严重",
  "patient_hip_pain_q8_a5":"极度",
  "patient_hip_pain_q9":"您步行了多久才感觉臀部疼痛变严重？ (独立或拄着拐杖）",
  "patient_hip_pain_q9_a1":"没有痛 / 超过30分钟 / 可持续行走",
  "patient_hip_pain_q9_a2":"16-30 分钟 / 6 个城市街区距离",
  "patient_hip_pain_q9_a3":"5-15 分钟 / 2-3 个城市街区距离",
  "patient_hip_pain_q9_a4":"在家里范围 / 室内",
  "patient_hip_pain_q9_a5":"无法步行 / 步行时严重疼痛 / 只能待在床或椅子上",
  "patient_hip_pain_q10":"在坚硬的表面上步行 （ 沥青, 混凝土）",
  "patient_hip_pain_q10_a1":"否",
  "patient_hip_pain_q10_a2":"轻微",
  "patient_hip_pain_q10_a3":"中度",
  "patient_hip_pain_q10_a4":"严重",
  "patient_hip_pain_q10_a5":"极度",
  "patient_hip_pain_q11":"在不均匀的表面上步行",
  "patient_hip_pain_q11_a1":"否",
  "patient_hip_pain_q11_a2":"轻微",
  "patient_hip_pain_q11_a3":"中度",
  "patient_hip_pain_q11_a4":"严重",
  "patient_hip_pain_q11_a5":"极度",
  "patient_hip_pain_q12":"上下楼梯",
  "patient_hip_pain_q12_a1":"否",
  "patient_hip_pain_q12_a2":"轻微",
  "patient_hip_pain_q12_a3":"中度",
  "patient_hip_pain_q12_a4":"严重",
  "patient_hip_pain_q12_a5":"极度",
  "patient_hip_pain_q13":"晚间在床上",
  "patient_hip_pain_q13_a1":"否",
  "patient_hip_pain_q13_a2":"轻微",
  "patient_hip_pain_q13_a3":"中度",
  "patient_hip_pain_q13_a4":"严重",
  "patient_hip_pain_q13_a5":"极度",
  "patient_hip_pain_q14":"您多常在晚上躺在床上睡觉时被臀部疼痛所困扰？",
  "patient_hip_pain_q14_a1":"否",
  "patient_hip_pain_q14_a2":"一个或两个晚上",
  "patient_hip_pain_q14_a3":"有些晚上",
  "patient_hip_pain_q14_a4":"大多晚上",
  "patient_hip_pain_q14_a5":"每天晚上",
  "patient_hip_pain_q15":"坐下或躺卧",
  "patient_hip_pain_q15_a1":"否",
  "patient_hip_pain_q15_a2":"轻微",
  "patient_hip_pain_q15_a3":"中度",
  "patient_hip_pain_q15_a4":"严重",
  "patient_hip_pain_q15_a5":"极度",
  "patient_hip_pain_q16":"站立",
  "patient_hip_pain_q16_a1":"否",
  "patient_hip_pain_q16_a2":"轻微",
  "patient_hip_pain_q16_a3":"中度",
  "patient_hip_pain_q16_a4":"严重",
  "patient_hip_pain_q16_a5":"极度",
  "patient_hip_pain_q17":"从坐在椅子起身站立",
  "patient_hip_pain_q17_a1":"否",
  "patient_hip_pain_q17_a2":"轻微",
  "patient_hip_pain_q17_a3":"中度",
  "patient_hip_pain_q17_a4":"严重",
  "patient_hip_pain_q17_a5":"极度",
  "patient_hip_pain_q18":"活动后",
  "patient_hip_pain_q18_a1":"否",
  "patient_hip_pain_q18_a2":"轻微",
  "patient_hip_pain_q18_a3":"中度",
  "patient_hip_pain_q18_a4":"严重",
  "patient_hip_pain_q18_a5":"极度",
  "patient_hip_other_symptoms":"其他症状",
  "patient_hip_other_symptoms_instruction1":"请选择最符合您臀部症状的答案。",
  "patient_hip_other_symptoms_instruction2":"如果您不确定如何回答问题，请尽可能给出最佳答案",
  "patient_hip_other_symptoms_instruction3":"您是否有以下情况",
  "patient_hip_other_symptoms_instruction_0":"否",
  "patient_hip_other_symptoms_instruction_1":"轻微",
  "patient_hip_other_symptoms_instruction_2":"中度",
  "patient_hip_other_symptoms_instruction_3":"严重",
  "patient_hip_other_symptoms_instruction_4":"极度",
  "patient_hip_other_symptoms_q1":"臀部发出摩擦声、咔嗒声或任何其他类型的噪音",
  "patient_hip_other_symptoms_q1_a1":"否",
  "patient_hip_other_symptoms_q1_a2":"偶尔",
  "patient_hip_other_symptoms_q1_a3":"有时候",
  "patient_hip_other_symptoms_q1_a4":"大多数时候",
  "patient_hip_other_symptoms_q1_a5":"经常",
  "patient_hip_other_symptoms_q2":"髋关节抓住或锁定",
  "patient_hip_other_symptoms_q2_a1":"否",
  "patient_hip_other_symptoms_q2_a2":"轻微",
  "patient_hip_other_symptoms_q2_a3":"中度",
  "patient_hip_other_symptoms_q2_a4":"严重",
  "patient_hip_other_symptoms_q2_a5":"极度",
  "patient_hip_other_symptoms_q3":"早上起床后感觉僵硬",
  "patient_hip_other_symptoms_q3_a1":"否",
  "patient_hip_other_symptoms_q3_a2":"轻度",
  "patient_hip_other_symptoms_q3_a3":"中度",
  "patient_hip_other_symptoms_q3_a4":"严重",
  "patient_hip_other_symptoms_q3_a5":"极度",
  "patient_hip_other_symptoms_q4":"坐下，躺下来，或晚上休息",
  "patient_hip_other_symptoms_q4_a1":"否",
  "patient_hip_other_symptoms_q4_a2":"轻度",
  "patient_hip_other_symptoms_q4_a3":"中度",
  "patient_hip_other_symptoms_q4_a4":"严重",
  "patient_hip_other_symptoms_q4_a5":"极度",
  "patient_hip_other_symptoms_q5":"跛行",
  "patient_hip_other_symptoms_q5_a1":"否， 偶尔/决不",
  "patient_hip_other_symptoms_q5_a2":"一点点，有时或一开始而已",
  "patient_hip_other_symptoms_q5_a3":"中度，经常，不止一开始而已",
  "patient_hip_other_symptoms_q5_a4":"严重，大多数时候",
  "patient_hip_other_symptoms_q5_a5":"一直或无法步行",
  "patient_hip_other_symptoms_q6":"突然的剧烈疼痛，刺痛",
  "patient_hip_other_symptoms_q6_a1":"没有",
  "patient_hip_other_symptoms_q6_a2":"一或两天",
  "patient_hip_other_symptoms_q6_a3":"某天",
  "patient_hip_other_symptoms_q6_a4":"大多天",
  "patient_hip_other_symptoms_q6_a5":"每天",
  "patient_hip_other_symptoms_q7":"臀部撑不住",
  "patient_hip_other_symptoms_q7_a1":"否",
  "patient_hip_other_symptoms_q7_a2":"轻度",
  "patient_hip_other_symptoms_q7_a3":"中度",
  "patient_hip_other_symptoms_q7_a4":"严重",
  "patient_hip_other_symptoms_q7_a5":"极度",
  "patient_hip_other_symptoms_q8":"减少移动",
  "patient_hip_other_symptoms_q8_a1":"否",
  "patient_hip_other_symptoms_q8_a2":"轻度",
  "patient_hip_other_symptoms_q8_a3":"中度",
  "patient_hip_other_symptoms_q8_a4":"严重",
  "patient_hip_other_symptoms_q8_a5":"极度",
  "patient_hip_other_symptoms_q9":"您步行时使用任何支撑物吗？",
  "patient_hip_other_symptoms_q9_a1":"否",
  "patient_hip_other_symptoms_q9_a2":"长途步行时用手杖/棍子",
  "patient_hip_other_symptoms_q9_a3":"大多数时候用手杖/棍子",
  "patient_hip_other_symptoms_q9_a4":"一个拐子",
  "patient_hip_other_symptoms_q9_a5":"两个拐子/手杖",
  "patient_hip_other_symptoms_q9_a6":"两个拐子 / 无法步行",
  "patient_hip_function":"功能",
  "patient_hip_function_instruction":"请指出您上周因髋关节问题而经历的以下活动的困难程度",
  "patient_hip_function_instruction_0":"否",
  "patient_hip_function_instruction_1":"轻度",
  "patient_hip_function_instruction_2":"中度",
  "patient_hip_function_instruction_3":"严重",
  "patient_hip_function_instruction_4":"极度/ 无法办到",
  "patient_hip_function_note":"注意：如果您不做某项活动，想象一下如果您必须尝试一下，您的臀部会有什么感觉",
  "patient_hip_function_q1":"下楼梯",
  "patient_hip_function_q1_a1":"否",
  "patient_hip_function_q1_a2":"轻度",
  "patient_hip_function_q1_a3":"中度",
  "patient_hip_function_q1_a4":"严重",
  "patient_hip_function_q1_a5":"极度/ 无法办到",
  "patient_hip_function_q2":"上楼梯",
  "patient_hip_function_q2_a1":"否",
  "patient_hip_function_q2_a2":"轻度",
  "patient_hip_function_q2_a3":"中度",
  "patient_hip_function_q2_a4":"严重",
  "patient_hip_function_q2_a5":"极度/ 无法办到",
  "patient_hip_function_q3":"坐着",
  "patient_hip_function_q3_a1":"否",
  "patient_hip_function_q3_a2":"轻度",
  "patient_hip_function_q3_a3":"中度",
  "patient_hip_function_q3_a4":"严重",
  "patient_hip_function_q3_a5":"极度/ 无法办到",
  "patient_hip_function_q4":"从坐着起身",
  "patient_hip_function_q4_a1":"否",
  "patient_hip_function_q4_a2":"轻度",
  "patient_hip_function_q4_a3":"中度",
  "patient_hip_function_q4_a4":"严重",
  "patient_hip_function_q4_a5":"极度/ 无法办到",
  "patient_hip_function_q5":"从地板上起身",
  "patient_hip_function_q5_a1":"否",
  "patient_hip_function_q5_a2":"轻度",
  "patient_hip_function_q5_a3":"中度",
  "patient_hip_function_q5_a4":"严重",
  "patient_hip_function_q5_a5":"极度/ 无法办到",
  "patient_hip_function_q6":"站立",
  "patient_hip_function_q6_a1":"否",
  "patient_hip_function_q6_a2":"轻度",
  "patient_hip_function_q6_a3":"中度",
  "patient_hip_function_q6_a4":"严重",
  "patient_hip_function_q6_a5":"极度/ 无法办到",
  "patient_hip_function_q7":"站立十五分钟",
  "patient_hip_function_q7_a1":"否",
  "patient_hip_function_q7_a2":"轻度",
  "patient_hip_function_q7_a3":"中度",
  "patient_hip_function_q7_a4":"严重",
  "patient_hip_function_q7_a5":"极度/ 无法办到",
  "patient_hip_function_q8":"张开双腿",
  "patient_hip_function_q8_a1":"否",
  "patient_hip_function_q8_a2":"轻度",
  "patient_hip_function_q8_a3":"中度",
  "patient_hip_function_q8_a4":"严重",
  "patient_hip_function_q8_a5":"极度/ 无法办到",
  "patient_hip_function_q9":"走路时跨出大步",
  "patient_hip_function_q9_a1":"否",
  "patient_hip_function_q9_a2":"轻度",
  "patient_hip_function_q9_a3":"中度",
  "patient_hip_function_q9_a4":"严重",
  "patient_hip_function_q9_a5":"极度/ 无法办到",
  "patient_hip_function_q10":"弯腰到地板上",
  "patient_hip_function_q10_a1":"否",
  "patient_hip_function_q10_a2":"轻度",
  "patient_hip_function_q10_a3":"中度",
  "patient_hip_function_q10_a4":"严重",
  "patient_hip_function_q10_a5":"极度/ 无法办到",
  "patient_hip_function_q11":"在平坦的表面步行",
  "patient_hip_function_q11_a1":"否",
  "patient_hip_function_q11_a2":"轻度",
  "patient_hip_function_q11_a3":"中度",
  "patient_hip_function_q11_a4":"严重",
  "patient_hip_function_q11_a5":"极度/ 无法办到",
  "patient_hip_function_q12":"凹凸不平的表面步行",
  "patient_hip_function_q12_a1":"否",
  "patient_hip_function_q12_a2":"轻度",
  "patient_hip_function_q12_a3":"中度",
  "patient_hip_function_q12_a4":"严重",
  "patient_hip_function_q12_a5":"极度/ 无法办到",
  "patient_hip_function_q13":"长途步行",
  "patient_hip_function_q13_a1":"否",
  "patient_hip_function_q13_a2":"轻度",
  "patient_hip_function_q13_a3":"中度",
  "patient_hip_function_q13_a4":"严重",
  "patient_hip_function_q13_a5":"极度/ 无法办到",
  "patient_hip_function_q14":"运动步行",
  "patient_hip_function_q14_a1":"否",
  "patient_hip_function_q14_a2":"轻度",
  "patient_hip_function_q14_a3":"中度",
  "patient_hip_function_q14_a4":"严重",
  "patient_hip_function_q14_a5":"极度/ 无法办到",
  "patient_hip_function_q15":"走上陡峭的山坡",
  "patient_hip_function_q15_a1":"否",
  "patient_hip_function_q15_a2":"轻度",
  "patient_hip_function_q15_a3":"中度",
  "patient_hip_function_q15_a4":"严重",
  "patient_hip_function_q15_a5":"极度/ 无法办到",
  "patient_hip_function_q16":"走下陡峭的山坡",
  "patient_hip_function_q16_a1":"否",
  "patient_hip_function_q16_a2":"轻度",
  "patient_hip_function_q16_a3":"中度",
  "patient_hip_function_q16_a4":"严重",
  "patient_hip_function_q16_a5":"极度/ 无法办到",
  "patient_hip_function_q17":"最初步行",
  "patient_hip_function_q17_a1":"否",
  "patient_hip_function_q17_a2":"轻度",
  "patient_hip_function_q17_a3":"中度",
  "patient_hip_function_q17_a4":"严重",
  "patient_hip_function_q17_a5":"极度/ 无法办到",
  "patient_hip_function_q18":"步行大约十分钟",
  "patient_hip_function_q18_a1":"否",
  "patient_hip_function_q18_a2":"轻度",
  "patient_hip_function_q18_a3":"中度",
  "patient_hip_function_q18_a4":"严重",
  "patient_hip_function_q18_a5":"极度/ 无法办到",
  "patient_hip_function_q19":"步行超越十五分钟",
  "patient_hip_function_q19_a1":"否",
  "patient_hip_function_q19_a2":"轻度",
  "patient_hip_function_q19_a3":"中度",
  "patient_hip_function_q19_a4":"严重",
  "patient_hip_function_q19_a5":"极度/ 无法办到",
  "patient_hip_function_q20":"走上下路边",
  "patient_hip_function_q20_a1":"否",
  "patient_hip_function_q20_a2":"轻度",
  "patient_hip_function_q20_a3":"中度",
  "patient_hip_function_q20_a4":"严重",
  "patient_hip_function_q20_a5":"极度/ 无法办到",
  "patient_hip_function_q21":"进出车或交通工具",
  "patient_hip_function_q21_a1":"否",
  "patient_hip_function_q21_a2":"轻度",
  "patient_hip_function_q21_a3":"中度",
  "patient_hip_function_q21_a4":"严重",
  "patient_hip_function_q21_a5":"极度/ 无法办到",
  "patient_hip_function_q22":"去购物",
  "patient_hip_function_q22_a1":"否",
  "patient_hip_function_q22_a2":"轻度",
  "patient_hip_function_q22_a3":"中度",
  "patient_hip_function_q22_a4":"严重",
  "patient_hip_function_q22_a5":"极度/ 无法办到",
  "patient_hip_function_q23":"穿上袜子/丝袜",
  "patient_hip_function_q23_a1":"否",
  "patient_hip_function_q23_a2":"轻度",
  "patient_hip_function_q23_a3":"中度",
  "patient_hip_function_q23_a4":"严重",
  "patient_hip_function_q23_a5":"极度/ 无法办到",
  "patient_hip_function_q24":"脱下袜子/丝袜",
  "patient_hip_function_q24_a1":"否",
  "patient_hip_function_q24_a2":"轻度",
  "patient_hip_function_q24_a3":"中度",
  "patient_hip_function_q24_a4":"严重",
  "patient_hip_function_q24_a5":"极度/ 无法办到",
  "patient_hip_function_q25":"躺在床上 （翻身，保持髋部位置）",
  "patient_hip_function_q25_a1":"否",
  "patient_hip_function_q25_a2":"轻度",
  "patient_hip_function_q25_a3":"中度",
  "patient_hip_function_q25_a4":"严重",
  "patient_hip_function_q25_a5":"极度/ 无法办到",
  "patient_hip_function_q26":"床里翻身",
  "patient_hip_function_q26_a1":"否",
  "patient_hip_function_q26_a2":"轻度",
  "patient_hip_function_q26_a3":"中度",
  "patient_hip_function_q26_a4":"严重",
  "patient_hip_function_q26_a5":"极度/ 无法办到",
  "patient_hip_function_q27":"起床",
  "patient_hip_function_q27_a1":"否",
  "patient_hip_function_q27_a2":"轻度",
  "patient_hip_function_q27_a3":"中度",
  "patient_hip_function_q27_a4":"严重",
  "patient_hip_function_q27_a5":"极度/ 无法办到",
  "patient_hip_function_q28":"进出浴缸/浴室",
  "patient_hip_function_q28_a1":"否",
  "patient_hip_function_q28_a2":"轻度",
  "patient_hip_function_q28_a3":"中度",
  "patient_hip_function_q28_a4":"严重",
  "patient_hip_function_q28_a5":"极度/ 无法办到",
  "patient_hip_function_q29":"上下马桶",
  "patient_hip_function_q29_a1":"否",
  "patient_hip_function_q29_a2":"轻度",
  "patient_hip_function_q29_a3":"中度",
  "patient_hip_function_q29_a4":"严重",
  "patient_hip_function_q29_a5":"极度/ 无法办到",
  "patient_hip_function_q30":"清洗擦干身体",
  "patient_hip_function_q30_a1":"否",
  "patient_hip_function_q30_a2":"轻度",
  "patient_hip_function_q30_a3":"中度",
  "patient_hip_function_q30_a4":"严重",
  "patient_hip_function_q30_a5":"极度/ 无法办到",
  "patient_hip_function_q31":"轻到中度的工作 (站立，步行）",
  "patient_hip_function_q31_a1":"否",
  "patient_hip_function_q31_a2":"轻度",
  "patient_hip_function_q31_a3":"中度",
  "patient_hip_function_q31_a4":"严重",
  "patient_hip_function_q31_a5":"极度/ 无法办到",
  "patient_hip_function_q32":"繁重家务 （搬运重箱子，擦地板等等）",
  "patient_hip_function_q32_a1":"否",
  "patient_hip_function_q32_a2":"轻度",
  "patient_hip_function_q32_a3":"中度",
  "patient_hip_function_q32_a4":"严重",
  "patient_hip_function_q32_a5":"极度/ 无法办到",
  "patient_hip_function_q33":"轻微家务 （煮饭，打扫除尘等等）",
  "patient_hip_function_q33_a1":"否",
  "patient_hip_function_q33_a2":"轻度",
  "patient_hip_function_q33_a3":"中度",
  "patient_hip_function_q33_a4":"严重",
  "patient_hip_function_q33_a5":"极度/ 无法办到",
  "patient_hip_function_q34":"抬起小孩子",
  "patient_hip_function_q34_a1":"否",
  "patient_hip_function_q34_a2":"轻度",
  "patient_hip_function_q34_a3":"中度",
  "patient_hip_function_q34_a4":"严重",
  "patient_hip_function_q34_a5":"极度/ 无法办到",
  "patient_hip_function_q35":"蹲着",
  "patient_hip_function_q35_a1":"否",
  "patient_hip_function_q35_a2":"轻度",
  "patient_hip_function_q35_a3":"中度",
  "patient_hip_function_q35_a4":"严重",
  "patient_hip_function_q35_a5":"极度/ 无法办到",
  "patient_hip_function_q36":"运动慢跑",
  "patient_hip_function_q36_a1":"否",
  "patient_hip_function_q36_a2":"轻度",
  "patient_hip_function_q36_a3":"中度",
  "patient_hip_function_q36_a4":"严重",
  "patient_hip_function_q36_a5":"极度/ 无法办到",
  "patient_hip_function_q37":"跑步",
  "patient_hip_function_q37_a1":"否",
  "patient_hip_function_q37_a2":"轻度",
  "patient_hip_function_q37_a3":"中度",
  "patient_hip_function_q37_a4":"严重",
  "patient_hip_function_q37_a5":"极度/ 无法办到",
  "patient_hip_function_q38":"在负重的腿上进行扭转或旋转",
  "patient_hip_function_q38_a1":"否",
  "patient_hip_function_q38_a2":"轻度",
  "patient_hip_function_q38_a3":"中度",
  "patient_hip_function_q38_a4":"严重",
  "patient_hip_function_q38_a5":"极度/ 无法办到",
  "patient_hip_function_q39":"性活动",
  "patient_hip_function_q39_a1":"否",
  "patient_hip_function_q39_a2":"轻度",
  "patient_hip_function_q39_a3":"中度",
  "patient_hip_function_q39_a4":"严重",
  "patient_hip_function_q39_a5":"极度/ 无法办到",
  "patient_hip_function_q39_not_relevant":"与我无关",
  "patient_hip_function_q40":"涉及追逐或切割的高要求运动 （足球，篮球，网球，有氧运动）",
  "patient_hip_function_q40_a1":"否",
  "patient_hip_function_q40_a2":"轻度",
  "patient_hip_function_q40_a3":"中度",
  "patient_hip_function_q40_a4":"严重",
  "patient_hip_function_q40_a5":"极度/ 无法办到",
  "patient_hip_function_q41":"低门槛运动 （高尔夫球，保龄球）",
  "patient_hip_function_q41_a1":"否",
  "patient_hip_function_q41_a2":"轻度",
  "patient_hip_function_q41_a3":"中度",
  "patient_hip_function_q41_a4":"严重",
  "patient_hip_function_q41_a5":"极度/ 无法办到",
  "patient_hip_function_q42":"悠闲活动",
  "patient_hip_function_q42_a1":"否",
  "patient_hip_function_q42_a2":"轻度",
  "patient_hip_function_q42_a3":"中度",
  "patient_hip_function_q42_a4":"严重",
  "patient_hip_function_q42_a5":"极度/ 无法办到",
  "patient_hip_function_q43":"您今天的臀部与正常臀部的百分比如何？ （0% 到 100%，100% 为正常）",
  "patient_hip_function_q44":"请注明您可以参与的最高级别的活动",
  "patient_hip_function_q44_q1":"髋部问题之前",
  "patient_hip_function_q44_q1_a1":"竞技的",
  "patient_hip_function_q44_q1_a2":"休闲活动/体适能",
  "patient_hip_function_q44_q1_a3":"没有运动，只有工作",
  "patient_hip_function_q44_q1_a4":"只做日常生活活动",
  "patient_hip_function_q44_q1_a5":"因健康问题请病假或伤残抚恤金",
  "patient_hip_function_q44_q1_a1_o1":"世界级、奥林匹克级或职业竞技运动",
  "patient_hip_function_q44_q1_a1_o2":"国家或国际级别的竞技运动，例如田径、球拍/球类运动、体操、赛艇、滑雪或武术",
  "patient_hip_function_q44_q1_a1_o3":"国内或国际级别的竞技运动，如水上运动、自行车、曲棍球、冰壶或骑马/竞技表演",
  "patient_hip_function_q44_q1_a2_o1":"每周至少 5 次的休闲运动，例如跑步、球/球拍运动、举重训练、冰壶、划船、高尔夫、瑜伽或体操",
  "patient_hip_function_q44_q1_a2_o2":"每周至少 5 次的休闲运动，例如游泳、水上运动、滑雪、曲棍球、滑旱冰、骑自行车、骑马或登山",
  "patient_hip_function_q44_q1_a2_o3":"每周至少进行两次休闲运动",
  "patient_hip_function_q44_q1_a2_o4":"每周一次或更少的休闲运动",
  "patient_hip_function_q44_q1_a2_o5":"每月少于一次的休闲运动",
  "patient_hip_function_q44_q1_a3_o1":"建筑等重体力劳动",
  "patient_hip_function_q44_q1_a3_o2":"中等重体力劳动，例如卡车驾驶",
  "patient_hip_function_q44_q1_a3_o3":"轻体力劳动比如护理等",
  "patient_hip_function_q44_q1_a3_o4":"久坐的工作，例如秘书或基于计算机的工作",
  "patient_hip_function_q44_q1_a4_o1":"日常活动，例如园艺、爬多段楼梯、搬运货物、推/拉货物、迟到时跑步的能力",
  "patient_hip_function_q44_q1_a4_o2":"日常活动，如打扫房屋、爬1或2层楼梯，或携带轻物品行走",
  "patient_hip_function_q44_q1_a4_o3":"日常活动受限（例如不爬楼梯、无法搬运物品）",
  "patient_hip_function_q44_q2":"当前状态",
  "patient_hip_function_q44_q2_a1":"竞技的",
  "patient_hip_function_q44_q2_a2":"悠闲活动/身体素质",
  "patient_hip_function_q44_q2_a3":"没有运动，只有工作",
  "patient_hip_function_q44_q2_a4":"只做日常生活活动",
  "patient_hip_function_q44_q2_a5":"因健康问题请病假或伤残抚恤金",
  "patient_hip_function_q44_q2_a1_o1":"世界级、奥林匹克级或职业竞技运动",
  "patient_hip_function_q44_q2_a1_o2":"国家或国际级别的竞技运动，例如田径、球拍/球类运动、体操、赛艇、滑雪或武术",
  "patient_hip_function_q44_q2_a1_o3":"国内或国际级别的竞技运动，如水上运动、自行车、曲棍球、冰壶或骑马/竞技表演",
  "patient_hip_function_q44_q2_a2_o1":"每周至少 5 次的休闲运动，例如跑步、球/球拍运动、举重训练、冰壶、划船、高尔夫、瑜伽或体操",
  "patient_hip_function_q44_q2_a2_o2":"每周至少 5 次的休闲运动，例如游泳、水上运动、滑雪、曲棍球、滑旱冰、骑自行车、骑马或登山",
  "patient_hip_function_q44_q2_a2_o3":"每周至少进行两次休闲运动",
  "patient_hip_function_q44_q2_a2_o4":"每周一次或更少的休闲运动",
  "patient_hip_function_q44_q2_a2_o5":"每月少于一次的休闲运动",
  "patient_hip_function_q44_q2_a3_o1":"建筑等重体力劳动",
  "patient_hip_function_q44_q2_a3_o2":"中等重体力劳动，例如卡车驾驶",
  "patient_hip_function_q44_q2_a3_o3":"轻体力劳动比如护理等",
  "patient_hip_function_q44_q2_a3_o4":"久坐的工作，例如秘书或基于计算机的工作",
  "patient_hip_function_q44_q2_a4_o1":"日常活动，例如园艺、爬多段楼梯、搬运货物、推/拉货物、迟到时跑步的能力",
  "patient_hip_function_q44_q2_a4_o2":"日常活动，如打扫房屋、爬1或2层楼梯，或携带轻物品行走",
  "patient_hip_function_q44_q2_a4_o3":"日常活动受限（例如不爬楼梯、无法搬运物品）",
  "patient_hip_quality_of_life":"生活质量",
  "patient_hip_quality_of_life_instruction":"以下问题询问您可能对髋关节问题感到的社交、情感和生活方式方面的担忧。 请想一想您大部分时间对臀部的感觉如何。",
  "patient_hip_quality_of_life_q1":"您多常意识到自己的臀部问题？",
  "patient_hip_quality_of_life_q1_a1":"决不",
  "patient_hip_quality_of_life_q1_a2":"每月",
  "patient_hip_quality_of_life_q1_a3":"每周",
  "patient_hip_quality_of_life_q1_a4":"每天",
  "patient_hip_quality_of_life_q1_a5":"不断的",
  "patient_hip_quality_of_life_q2":"您是否改变了自己的生活方式以避免可能对臀部造成损害的活动？",
  "patient_hip_quality_of_life_q2_a1":"没有",
  "patient_hip_quality_of_life_q2_a2":"稍微",
  "patient_hip_quality_of_life_q2_a3":"中度",
  "patient_hip_quality_of_life_q2_a4":"严重",
  "patient_hip_quality_of_life_q2_a5":"完全",
  "patient_hip_quality_of_life_q3":"您对自己维持理想健身水平的能力有多关心？",
  "patient_hip_quality_of_life_q3_a1":"不关心",
  "patient_hip_quality_of_life_q3_a2":"极度关心",
  "patient_hip_quality_of_life_q4":"您对自己的臀部缺乏信心有多少困扰？",
  "patient_hip_quality_of_life_q4_a1":"没有困扰",
  "patient_hip_quality_of_life_q4_a2":"轻度",
  "patient_hip_quality_of_life_q4_a3":"中度",
  "patient_hip_quality_of_life_q4_a4":"严重",
  "patient_hip_quality_of_life_q4_a5":"极其",
  "patient_hip_quality_of_life_q5":"您的髋部问题对您造成多大的干扰？",
  "patient_hip_quality_of_life_q5_a1":"没有干扰",
  "patient_hip_quality_of_life_q5_a2":"极度干扰",
  "patient_hip_quality_of_life_q6":"一般来说，您的臀部有多大的不适？",
  "patient_hip_quality_of_life_q6_a1":"否",
  "patient_hip_quality_of_life_q6_a2":"轻度",
  "patient_hip_quality_of_life_q6_a3":"中度",
  "patient_hip_quality_of_life_q6_a4":"严重",
  "patient_hip_quality_of_life_q6_a5":"极度",
  "patient_hip_quality_of_life_q7":"你今天有多焦虑/沮丧？",
  "patient_hip_quality_of_life_q7_a1":"一点也不",
  "patient_hip_quality_of_life_q7_a2":"轻微",
  "patient_hip_quality_of_life_q7_a3":"中度",
  "patient_hip_quality_of_life_q7_a4":"严重",
  "patient_hip_quality_of_life_q7_a5":"极度",
  "patient_hip_quality_of_life_q8":"您今天的健康状况如何？ （100 表示最佳健康状况，0 表示您可以想象的最差健康状况）",
  "patient_hip_quality_of_life_q8_a1":"最差健康",
  "patient_hip_quality_of_life_q8_a2":"最佳健康",
  "patient_hip_satisfaction":"满意感",
  "patient_hip_satisfaction_instructions":"您对髋关节手术/治疗的结果满意吗？",
  "patient_hip_satisfaction_instructions_1":"很不满意",
  "patient_hip_satisfaction_instructions_2":"有些不满意",
  "patient_hip_satisfaction_instructions_3":"有些满意",
  "patient_hip_satisfaction_instructions_4":"很满意",
  "patient_hip_satisfaction_q1":"总体",
  "patient_hip_satisfaction_q1_not_applicable":"不适用",
  "patient_hip_satisfaction_q1_a1":"很不满意",
  "patient_hip_satisfaction_q1_a2":"有些不满意",
  "patient_hip_satisfaction_q1_a3":"有些满意",
  "patient_hip_satisfaction_q1_a4":"很满意",
  "patient_hip_satisfaction_q2":"为了改善您的疼痛？",
  "patient_hip_satisfaction_q2_not_applicable":"不适用",
  "patient_hip_satisfaction_q2_a1":"很不满意",
  "patient_hip_satisfaction_q2_a2":"有些不满意",
  "patient_hip_satisfaction_q2_a3":"有些满意",
  "patient_hip_satisfaction_q2_a4":"很满意",
  "patient_hip_satisfaction_q3":"为了提高您做家务或庭院工作的能力？",
  "patient_hip_satisfaction_q3_not_applicable":"不适用",
  "patient_hip_satisfaction_q3_a1":"很不满意",
  "patient_hip_satisfaction_q3_a2":"有些不满意",
  "patient_hip_satisfaction_q3_a3":"有些满意",
  "patient_hip_satisfaction_q3_a4":"很满意",
  "patient_hip_satisfaction_q4":"为了提高您进行娱乐/悠闲活动的能力？",
  "patient_hip_satisfaction_q4_not_applicable":"不适用",
  "patient_hip_satisfaction_q4_a1":"很不满意",
  "patient_hip_satisfaction_q4_a2":"有些不满意",
  "patient_hip_satisfaction_q4_a3":"有些满意",
  "patient_hip_satisfaction_q4_a4":"很满意",
  "patient_hip_complete":"问卷结束",
  "patient_hip_complete_text1":"感谢您抽出宝贵时间填写此表格。",
  "patient_hip_complete_text2":"请点击提交完成并查看您的分数。",
  "patient_hip_complete_text3":"您的回答将被保密，任何报告都将仅使用去识别化的咨讯。",
  "patient_hip_results":"结果",
  "patient_hip_results_text1":"以下是您的疼痛和髋关节功能评分，这些评分是根据您的调查问卷计算得出的。",
  "patient_hip_results_pain":"疼痛",
  "patient_hip_results_function":"功能",
  "patient_hip_results_health_perception":"整体健康认知",
  "patient_hip_results_self_evaluation":"髋关节评分自我评估",
  "patient_hip_results_text2":"您的医生很快就会与您联系。",
  "":"",
  "patient_adult_knee_pain":"疼痛",
  "patient_adult_knee_pain_q1":"在没有明显膝盖疼痛的情况下，您可以进行的最高活动水平是多少？",
  "patient_adult_knee_pain_q1_a1":"非常剧烈的活动，例如篮球或足球中的跳跃或旋转",
  "patient_adult_knee_pain_q1_a2":"剧烈活动，如重体力劳动、滑雪或打网球",
  "patient_adult_knee_pain_q1_a3":"适度的活动，例如适度的体力劳动、跑步或慢跑",
  "patient_adult_knee_pain_q1_a4":"轻度活动，如散步、做家务或庭院工作",
  "patient_adult_knee_pain_q1_a5":"由于膝盖疼痛而无法进行上述任何活动",
  "patient_adult_knee_pain_q2":"您多常感觉疼痛？",
  "patient_adult_knee_pain_q2_a1":"决不",
  "patient_adult_knee_pain_q2_a2":"每月",
  "patient_adult_knee_pain_q2_a3":"每周",
  "patient_adult_knee_pain_q2_a4":"每天",
  "patient_adult_knee_pain_q2_a5":"持续",
  "patient_adult_knee_pain_q3":"以下哪项陈述最能描述您的痛苦？",
  "patient_adult_knee_pain_q3_a1":"无疼痛，膝关节正常，表现 100%",
  "patient_adult_knee_pain_q3_a2":"剧烈运动和繁重工作时偶尔疼痛，膝盖不完全正常，有一些限制，但轻微且可以忍受",
  "patient_adult_knee_pain_q3_a3":"疼痛通常由运动、轻度娱乐活动或中等强度的工作引起。 步行超过2公里、站立或轻体力劳动时偶尔会发生",
  "patient_adult_knee_pain_q3_a4":"轻度休闲运动或中度工作活动、跑步或重体力劳动、剧烈运动时偶尔疼痛",
  "patient_adult_knee_pain_q3_a5":"简单活动（例如步行 <2 公里）引起严重疼痛的问题，可通过休息缓解。无法进行运动。",
  "patient_adult_knee_pain_q3_a6":"持续",
  "patient_adult_knee_pain_q4":"如果您感到疼痛，疼痛有多严重？",
  "patient_adult_knee_pain_q4_a1":"否",
  "patient_adult_knee_pain_q4_a2":"很轻微",
  "patient_adult_knee_pain_q4_a3":"轻微",
  "patient_adult_knee_pain_q4_a4":"中度",
  "patient_adult_knee_pain_q4_a5":"严重",
  "patient_adult_knee_pain_q5":"您的膝盖疼痛对您的日常工作（包括家务）有多大影响？",
  "patient_adult_knee_pain_q5_a1":"决不",
  "patient_adult_knee_pain_q5_a2":"一点点",
  "patient_adult_knee_pain_q5_a3":"中度",
  "patient_adult_knee_pain_q5_a4":"大大地",
  "patient_adult_knee_pain_q5_a5":"完全",
  "patient_adult_knee_pain_instruction":"您在以下活动中经历过多少程度的膝盖疼痛：",
  "patient_adult_knee_pain_instruction_0":"否",
  "patient_adult_knee_pain_instruction_1":"轻微",
  "patient_adult_knee_pain_instruction_2":"中度",
  "patient_adult_knee_pain_instruction_3":"严重",
  "patient_adult_knee_pain_instruction_4":"极度",
  "patient_adult_knee_pain_q6":"扭转/旋转膝盖",
  "patient_adult_knee_pain_q6_a1":"否",
  "patient_adult_knee_pain_q6_a2":"轻微",
  "patient_adult_knee_pain_q6_a3":"中度",
  "patient_adult_knee_pain_q6_a4":"严重",
  "patient_adult_knee_pain_q6_a5":"极度",
  "patient_adult_knee_pain_q7":"膝盖完全伸直",
  "patient_adult_knee_pain_q7_a1":"否",
  "patient_adult_knee_pain_q7_a2":"轻微",
  "patient_adult_knee_pain_q7_a3":"中度",
  "patient_adult_knee_pain_q7_a4":"严重",
  "patient_adult_knee_pain_q7_a5":"极度",
  "patient_adult_knee_pain_q8":"屈膝",
  "patient_adult_knee_pain_q8_a1":"否",
  "patient_adult_knee_pain_q8_a2":"轻微",
  "patient_adult_knee_pain_q8_a3":"中度",
  "patient_adult_knee_pain_q8_a4":"严重",
  "patient_adult_knee_pain_q8_a5":"极度",
  "patient_adult_knee_pain_q9":"在平坦的表面步行",
  "patient_adult_knee_pain_q9_a1":"否",
  "patient_adult_knee_pain_q9_a2":"轻微",
  "patient_adult_knee_pain_q9_a3":"中度",
  "patient_adult_knee_pain_q9_a4":"严重",
  "patient_adult_knee_pain_q9_a5":"极度",
  "patient_adult_knee_pain_q10":"在膝盖疼痛变得严重之前，您可以行走多长时间？ （有或没有棍子）",
  "patient_adult_knee_pain_q10_a1":"超过三十分钟",
  "patient_adult_knee_pain_q10_a2":"16-30 分钟",
  "patient_adult_knee_pain_q10_a3":"5-15 分钟",
  "patient_adult_knee_pain_q10_a4":"家内而已",
  "patient_adult_knee_pain_q10_a5":"无法步行/步行的时候严重疼痛",
  "patient_adult_knee_pain_q11":"上下楼梯",
  "patient_adult_knee_pain_q11_a1":"否",
  "patient_adult_knee_pain_q11_a2":"轻微",
  "patient_adult_knee_pain_q11_a3":"中度",
  "patient_adult_knee_pain_q11_a4":"严重",
  "patient_adult_knee_pain_q11_a5":"极度",
  "patient_adult_knee_pain_q12":"晚上睡觉在床里",
  "patient_adult_knee_pain_q12_a1":"否",
  "patient_adult_knee_pain_q12_a2":"轻微",
  "patient_adult_knee_pain_q12_a3":"中度",
  "patient_adult_knee_pain_q12_a4":"严重",
  "patient_adult_knee_pain_q12_a5":"极度",
  "patient_adult_knee_pain_q13":"您多常被夜间床上膝盖疼痛所困扰？",
  "patient_adult_knee_pain_q13_a1":"否",
  "patient_adult_knee_pain_q13_a2":"一个或两个晚上",
  "patient_adult_knee_pain_q13_a3":"有些晚上",
  "patient_adult_knee_pain_q13_a4":"大多晚上",
  "patient_adult_knee_pain_q13_a5":"每天晚上",
  "patient_adult_knee_pain_q14":"坐下或躺卧",
  "patient_adult_knee_pain_q14_a1":"否",
  "patient_adult_knee_pain_q14_a2":"轻微",
  "patient_adult_knee_pain_q14_a3":"中度",
  "patient_adult_knee_pain_q14_a4":"严重",
  "patient_adult_knee_pain_q14_a5":"极度",
  "patient_adult_knee_pain_q15":"站立",
  "patient_adult_knee_pain_q15_a1":"否",
  "patient_adult_knee_pain_q15_a2":"轻微",
  "patient_adult_knee_pain_q15_a3":"中度",
  "patient_adult_knee_pain_q15_a4":"严重",
  "patient_adult_knee_pain_q15_a5":"极度",
  "patient_adult_knee_pain_q16":"从坐在椅子起身站立",
  "patient_adult_knee_pain_q16_a1":"否",
  "patient_adult_knee_pain_q16_a2":"轻微",
  "patient_adult_knee_pain_q16_a3":"中度",
  "patient_adult_knee_pain_q16_a4":"严重",
  "patient_adult_knee_pain_q16_a5":"极度",
  "patient_adult_knee_other_symptoms":"其他症状",
  "patient_adult_knee_other_symptoms_instruction1":"请选择最符合您膝盖症状的答案。",
  "patient_adult_knee_other_symptoms_instruction2":"如果您不确定如何回答问题，请尽可能给出最佳答案。",
  "patient_adult_knee_other_symptoms_instruction3":"您是否有以下情况：",
  "patient_adult_knee_other_symptoms_instruction_0":"否",
  "patient_adult_knee_other_symptoms_instruction_1":"轻微",
  "patient_adult_knee_other_symptoms_instruction_2":"中度",
  "patient_adult_knee_other_symptoms_instruction_3":"严重",
  "patient_adult_knee_other_symptoms_instruction_4":"极度",
  "patient_adult_knee_other_symptoms_q1":"肿胀",
  "patient_adult_knee_other_symptoms_q1_a1":"不曾",
  "patient_adult_knee_other_symptoms_q1_a2":"偶尔",
  "patient_adult_knee_other_symptoms_q1_a3":"有时候",
  "patient_adult_knee_other_symptoms_q1_a4":"时常",
  "patient_adult_knee_other_symptoms_q1_a5":"持续",
  "patient_adult_knee_other_symptoms_q2":"膝盖发出摩擦声、咔嗒声或任何其他类型的噪音",
  "patient_adult_knee_other_symptoms_q2_a1":"不曾",
  "patient_adult_knee_other_symptoms_q2_a2":"偶尔",
  "patient_adult_knee_other_symptoms_q2_a3":"有时候",
  "patient_adult_knee_other_symptoms_q2_a4":"时常",
  "patient_adult_knee_other_symptoms_q2_a5":"持续",
  "patient_adult_knee_other_symptoms_q3":"膝盖关节抓住或锁定",
  "patient_adult_knee_other_symptoms_q3_a1":"不曾",
  "patient_adult_knee_other_symptoms_q3_a2":"偶尔",
  "patient_adult_knee_other_symptoms_q3_a3":"有时候",
  "patient_adult_knee_other_symptoms_q3_a4":"时常",
  "patient_adult_knee_other_symptoms_q3_a5":"持续",
  "patient_adult_knee_other_symptoms_q4":"跛行",
  "patient_adult_knee_other_symptoms_q4_a1":"偶尔/不曾",
  "patient_adult_knee_other_symptoms_q4_a2":"有时候，或一刚开始的时候",
  "patient_adult_knee_other_symptoms_q4_a3":"时常，不知刚开始",
  "patient_adult_knee_other_symptoms_q4_a4":"大多的时候",
  "patient_adult_knee_other_symptoms_q4_a5":"一直",
  "patient_adult_knee_other_symptoms_q5":"在过去 4 周内或自膝盖受伤以来，您的膝盖僵硬或肿胀程度如何？",
  "patient_adult_knee_other_symptoms_q5_a1":"决不",
  "patient_adult_knee_other_symptoms_q5_a2":"轻微",
  "patient_adult_knee_other_symptoms_q5_a3":"中度",
  "patient_adult_knee_other_symptoms_q5_a4":"很",
  "patient_adult_knee_other_symptoms_q5_a5":"极其",
  "patient_adult_knee_other_symptoms_q6":"在膝盖不出现明显肿胀的情况下，您可以进行的最高活动水平是什么？",
  "patient_adult_knee_other_symptoms_q6_a1":"非常剧烈的活动，例如篮球或足球中的跳跃或旋转",
  "patient_adult_knee_other_symptoms_q6_a2":"剧烈活动，如重体力劳动、滑雪或打网球",
  "patient_adult_knee_other_symptoms_q6_a3":"适度的活动，例如适度的体力劳动、跑步或慢跑",
  "patient_adult_knee_other_symptoms_q6_a4":"轻度活动，如散步、做家务或庭院工作",
  "patient_adult_knee_other_symptoms_q6_a5":"由于膝盖肿胀而无法进行上述任何活动",
  "patient_adult_knee_other_symptoms_q7":"您的关节僵硬有多严重：",
  "patient_adult_knee_other_symptoms_q7_0":"否",
  "patient_adult_knee_other_symptoms_q7_1":"轻微",
  "patient_adult_knee_other_symptoms_q7_2":"中度",
  "patient_adult_knee_other_symptoms_q7_3":"严重",
  "patient_adult_knee_other_symptoms_q7_4":"极度",
  "patient_adult_knee_other_symptoms_q7_q1":"早上第一次醒来后？",
  "patient_adult_knee_other_symptoms_q7_q1_a1":"否",
  "patient_adult_knee_other_symptoms_q7_q1_a2":"轻微",
  "patient_adult_knee_other_symptoms_q7_q1_a3":"中度",
  "patient_adult_knee_other_symptoms_q7_q1_a4":"严重",
  "patient_adult_knee_other_symptoms_q7_q1_a5":"极度",
  "patient_adult_knee_other_symptoms_q7_q2":"当天晚些时候坐着、躺着或休息后？",
  "patient_adult_knee_other_symptoms_q7_q2_a1":"否",
  "patient_adult_knee_other_symptoms_q7_q2_a2":"轻微",
  "patient_adult_knee_other_symptoms_q7_q2_a3":"中度",
  "patient_adult_knee_other_symptoms_q7_q2_a4":"严重",
  "patient_adult_knee_other_symptoms_q7_q2_a5":"极度",
  "patient_adult_knee_other_symptoms_q8":"在膝盖没有明显撑不住的限度下，您可以进行的最高活动水平是什么？",
  "patient_adult_knee_other_symptoms_q8_a1":"没有撑不住",
  "patient_adult_knee_other_symptoms_q8_a2":"偶尔因剧烈运动或繁重工作而撑不住，可以参加所有运动，但需要一些防护或限制。偶尔在运动或其他剧烈运动期间撑不住。",
  "patient_adult_knee_other_symptoms_q8_a3":"经常在运动或其他剧烈运动期间发生。偶尔进行轻度运动或中度的工作。能够用其他身体部位做出替代动作，但剧烈活动、运动或重体力劳动受限，不能突然进行切入动作或扭转",
  "patient_adult_knee_other_symptoms_q8_a4":"膝盖撑不住限制了运动或中度的工作，在步行或轻度工作中很少发生（每年约 3 次）。日常活动中偶尔会发生",
  "patient_adult_knee_other_symptoms_q8_a5":"简单的步行活动和轻松的工作，膝盖都会撑不住。每月发生一次，需要保护。经常在日常活动中会发生",
  "patient_adult_knee_other_symptoms_q8_a6":"简单的行走活动导致严重问题，每一步膝盖都撑不住，行走时无法转身或扭转",
  "patient_adult_knee_other_symptoms_q9":"您走路时使用任何支撑吗？",
  "patient_adult_knee_other_symptoms_q9_a1":"否",
  "patient_adult_knee_other_symptoms_q9_a2":"棍子/拐杖",
  "patient_adult_knee_other_symptoms_q9_a3":"无法支撑体重",
  "patient_adult_knee_function":"功能",
  "patient_adult_knee_function_instruction":"请指出您在上周进行以下活动时因膝盖有问题而遇到的困难程度",
  "patient_adult_knee_function_instruction_0":"没有困难",
  "patient_adult_knee_function_instruction_1":"稍微困难",
  "patient_adult_knee_function_instruction_2":"中度困难",
  "patient_adult_knee_function_instruction_3":"极度困难",
  "patient_adult_knee_function_instruction_4":"无法进行活动",
  "patient_adult_knee_function_note":"注意：如果您不做某项活动，想象一下如果您必须尝试一下，您的膝盖会有什么感觉。",
  "patient_adult_knee_function_q1":"上楼梯",
  "patient_adult_knee_function_q1_a1":"没有困难",
  "patient_adult_knee_function_q1_a2":"稍微困难",
  "patient_adult_knee_function_q1_a3":"中度困难",
  "patient_adult_knee_function_q1_a4":"极度困难",
  "patient_adult_knee_function_q1_a5":"无法进行活动",
  "patient_adult_knee_function_q2":"下楼梯",
  "patient_adult_knee_function_q2_a1":"没有困难",
  "patient_adult_knee_function_q2_a2":"稍微困难",
  "patient_adult_knee_function_q2_a3":"中度困难",
  "patient_adult_knee_function_q2_a4":"极度困难",
  "patient_adult_knee_function_q2_a5":"无法进行活动",
  "patient_adult_knee_function_q3":"用膝盖前面跪着",
  "patient_adult_knee_function_q3_a1":"没有困难",
  "patient_adult_knee_function_q3_a2":"稍微困难",
  "patient_adult_knee_function_q3_a3":"中度困难",
  "patient_adult_knee_function_q3_a4":"极度困难",
  "patient_adult_knee_function_q3_a5":"无法进行活动",
  "patient_adult_knee_function_q4":"把膝盖伸直",
  "patient_adult_knee_function_q4_a1":"没有困难",
  "patient_adult_knee_function_q4_a2":"稍微困难",
  "patient_adult_knee_function_q4_a3":"中度困难",
  "patient_adult_knee_function_q4_a4":"极度困难",
  "patient_adult_knee_function_q4_a5":"无法进行活动",
  "patient_adult_knee_function_q5":"屈膝",
  "patient_adult_knee_function_q5_a1":"没有困难",
  "patient_adult_knee_function_q5_a2":"稍微困难",
  "patient_adult_knee_function_q5_a3":"中度困难",
  "patient_adult_knee_function_q5_a4":"极度困难",
  "patient_adult_knee_function_q5_a5":"无法进行活动",
  "patient_adult_knee_function_q6":"蹲下",
  "patient_adult_knee_function_q6_a1":"没有困难",
  "patient_adult_knee_function_q6_a2":"稍微困难",
  "patient_adult_knee_function_q6_a3":"中度困难",
  "patient_adult_knee_function_q6_a4":"极度困难",
  "patient_adult_knee_function_q6_a5":"无法进行活动",
  "patient_adult_knee_function_q7":"坐着的时候屈膝",
  "patient_adult_knee_function_q7_a1":"没有困难",
  "patient_adult_knee_function_q7_a2":"稍微困难",
  "patient_adult_knee_function_q7_a3":"中度困难",
  "patient_adult_knee_function_q7_a4":"极度困难",
  "patient_adult_knee_function_q7_a5":"无法进行活动",
  "patient_adult_knee_function_q8":"从坐在椅子上起来",
  "patient_adult_knee_function_q8_a1":"没有困难",
  "patient_adult_knee_function_q8_a2":"稍微困难",
  "patient_adult_knee_function_q8_a3":"中度困难",
  "patient_adult_knee_function_q8_a4":"极度困难",
  "patient_adult_knee_function_q8_a5":"无法进行活动",
  "patient_adult_knee_function_q9":"躺在床上起来",
  "patient_adult_knee_function_q9_a1":"没有困难",
  "patient_adult_knee_function_q9_a2":"稍微困难",
  "patient_adult_knee_function_q9_a3":"中度困难",
  "patient_adult_knee_function_q9_a4":"极度困难",
  "patient_adult_knee_function_q9_a5":"无法进行活动",
  "patient_adult_knee_function_q10":"躺在床上 （翻身，保持膝盖位置）",
  "patient_adult_knee_function_q10_a1":"没有困难",
  "patient_adult_knee_function_q10_a2":"稍微困难",
  "patient_adult_knee_function_q10_a3":"中度困难",
  "patient_adult_knee_function_q10_a4":"极度困难",
  "patient_adult_knee_function_q10_a5":"无法进行活动",
  "patient_adult_knee_function_q11":"弯腰到地板上 / 捡起一个物体",
  "patient_adult_knee_function_q11_a1":"没有困难",
  "patient_adult_knee_function_q11_a2":"稍微困难",
  "patient_adult_knee_function_q11_a3":"中度困难",
  "patient_adult_knee_function_q11_a4":"极度困难",
  "patient_adult_knee_function_q11_a5":"无法进行活动",
  "patient_adult_knee_function_q12":"穿上袜子/丝袜",
  "patient_adult_knee_function_q12_a1":"没有困难",
  "patient_adult_knee_function_q12_a2":"稍微困难",
  "patient_adult_knee_function_q12_a3":"中度困难",
  "patient_adult_knee_function_q12_a4":"极度困难",
  "patient_adult_knee_function_q12_a5":"无法进行活动",
  "patient_adult_knee_function_q13":"脱下袜子/丝袜",
  "patient_adult_knee_function_q13_a1":"没有困难",
  "patient_adult_knee_function_q13_a2":"稍微困难",
  "patient_adult_knee_function_q13_a3":"中度困难",
  "patient_adult_knee_function_q13_a4":"极度困难",
  "patient_adult_knee_function_q13_a5":"无法进行活动",
  "patient_adult_knee_function_q14":"站立",
  "patient_adult_knee_function_q14_a1":"没有困难",
  "patient_adult_knee_function_q14_a2":"稍微困难",
  "patient_adult_knee_function_q14_a3":"中度困难",
  "patient_adult_knee_function_q14_a4":"极度困难",
  "patient_adult_knee_function_q14_a5":"无法进行活动",
  "patient_adult_knee_function_q15":"行走",
  "patient_adult_knee_function_q15_a1":"没有困难",
  "patient_adult_knee_function_q15_a2":"稍微困难",
  "patient_adult_knee_function_q15_a3":"中度困难",
  "patient_adult_knee_function_q15_a4":"极度困难",
  "patient_adult_knee_function_q15_a5":"无法进行活动",
  "patient_adult_knee_function_q16":"向前跑",
  "patient_adult_knee_function_q16_a1":"没有困难",
  "patient_adult_knee_function_q16_a2":"稍微困难",
  "patient_adult_knee_function_q16_a3":"中度困难",
  "patient_adult_knee_function_q16_a4":"极度困难",
  "patient_adult_knee_function_q16_a5":"无法进行活动",
  "patient_adult_knee_function_q17":"用受影响的腿跳跃并落地",
  "patient_adult_knee_function_q17_a1":"没有困难",
  "patient_adult_knee_function_q17_a2":"稍微困难",
  "patient_adult_knee_function_q17_a3":"中度困难",
  "patient_adult_knee_function_q17_a4":"极度困难",
  "patient_adult_knee_function_q17_a5":"无法进行活动",
  "patient_adult_knee_function_q18":"快速停止和启动",
  "patient_adult_knee_function_q18_a1":"没有困难",
  "patient_adult_knee_function_q18_a2":"稍微困难",
  "patient_adult_knee_function_q18_a3":"中度困难",
  "patient_adult_knee_function_q18_a4":"极度困难",
  "patient_adult_knee_function_q18_a5":"无法进行活动",
  "patient_adult_knee_function_q19":"扭转/旋转受伤的膝盖",
  "patient_adult_knee_function_q19_a1":"没有困难",
  "patient_adult_knee_function_q19_a2":"稍微困难",
  "patient_adult_knee_function_q19_a3":"中度困难",
  "patient_adult_knee_function_q19_a4":"极度困难",
  "patient_adult_knee_function_q19_a5":"无法进行活动",
  "patient_adult_knee_function_q20":"上下车或使用公共交通工具",
  "patient_adult_knee_function_q20_a1":"没有困难",
  "patient_adult_knee_function_q20_a2":"稍微困难",
  "patient_adult_knee_function_q20_a3":"中度困难",
  "patient_adult_knee_function_q20_a4":"极度困难",
  "patient_adult_knee_function_q20_a5":"无法进行活动",
  "patient_adult_knee_function_q21":"进出浴缸/浴室",
  "patient_adult_knee_function_q21_a1":"没有困难",
  "patient_adult_knee_function_q21_a2":"稍微困难",
  "patient_adult_knee_function_q21_a3":"中度困难",
  "patient_adult_knee_function_q21_a4":"极度困难",
  "patient_adult_knee_function_q21_a5":"无法进行活动",
  "patient_adult_knee_function_q22":"上下马桶",
  "patient_adult_knee_function_q22_a1":"没有困难",
  "patient_adult_knee_function_q22_a2":"稍微困难",
  "patient_adult_knee_function_q22_a3":"中度困难",
  "patient_adult_knee_function_q22_a4":"极度困难",
  "patient_adult_knee_function_q22_a5":"无法进行活动",
  "patient_adult_knee_function_q23":"清洗擦干身体",
  "patient_adult_knee_function_q23_a1":"没有困难",
  "patient_adult_knee_function_q23_a2":"稍微困难",
  "patient_adult_knee_function_q23_a3":"中度困难",
  "patient_adult_knee_function_q23_a4":"极度困难",
  "patient_adult_knee_function_q23_a5":"无法进行活动",
  "patient_adult_knee_function_q24":"日常购物",
  "patient_adult_knee_function_q24_a1":"没有困难",
  "patient_adult_knee_function_q24_a2":"稍微困难",
  "patient_adult_knee_function_q24_a3":"中度困难",
  "patient_adult_knee_function_q24_a4":"极度困难",
  "patient_adult_knee_function_q24_a5":"无法进行活动",
  "patient_adult_knee_function_q25":"繁重的家务劳动（搬运重箱子、擦地板等）",
  "patient_adult_knee_function_q25_a1":"没有困难",
  "patient_adult_knee_function_q25_a2":"稍微困难",
  "patient_adult_knee_function_q25_a3":"中度困难",
  "patient_adult_knee_function_q25_a4":"极度困难",
  "patient_adult_knee_function_q25_a5":"无法进行活动",
  "patient_adult_knee_function_q26":"轻微的家务劳动（做饭、除尘等）",
  "patient_adult_knee_function_q26_a1":"没有困难",
  "patient_adult_knee_function_q26_a2":"稍微困难",
  "patient_adult_knee_function_q26_a3":"中度困难",
  "patient_adult_knee_function_q26_a4":"极度困难",
  "patient_adult_knee_function_q26_a5":"无法进行活动",
  "patient_adult_knee_function_q27":"您如何评价您膝关节当前的功能（0-10 分）？",
  "patient_adult_knee_function_q28":"10 分表示正常，0 分表示无法进行任何日常活动（可能包括运动）",
  "patient_adult_knee_function_q28_a1":"最差",
  "patient_adult_knee_function_q28_a2":"最好",
  "patient_adult_knee_function_q29":"您可以定期参加的最高级别的活动是什么？",
  "patient_adult_knee_function_q29_a1":"非常剧烈的活动，例如篮球或足球中的跳跃或旋转",
  "patient_adult_knee_function_q29_a2":"剧烈活动，如重体力劳动、滑雪或打网球",
  "patient_adult_knee_function_q29_a3":"适度的活动，例如适度的体力劳动、跑步或慢跑",
  "patient_adult_knee_function_q29_a4":"轻度活动，如散步、做家务或庭院工作",
  "patient_adult_knee_function_q29_a5":"由于膝盖而无法进行上述任何活动",
  "patient_adult_knee_function_q30":"请指定您可以参加的最高级别的活动",
  "patient_adult_knee_function_q30_q1":"膝盖受伤/膝盖问题之前",
  "patient_adult_knee_function_q30_q1_a1":"竞技",
  "patient_adult_knee_function_q30_q1_a2":"悠闲，圣体素质",
  "patient_adult_knee_function_q30_q1_a3":"没有运动，工作而已",
  "patient_adult_knee_function_q30_q1_a4":"日常生活活动",
  "patient_adult_knee_function_q30_q1_a5":"因健康问题请病假或伤残抚恤金",
  "patient_adult_knee_function_q30_q1_a1_o1":"世界级、奥林匹克级或职业竞技运动",
  "patient_adult_knee_function_q30_q1_a1_o2":"国家或国际级别的竞技运动，例如田径、球拍/球类运动、体操、赛艇、滑雪或武术",
  "patient_adult_knee_function_q30_q1_a1_o3":"国内或国际级别的竞技运动，如水上运动、自行车、曲棍球、冰壶或骑马/竞技表演",
  "patient_adult_knee_function_q30_q1_a2_o1":"每周至少 5 次的休闲运动，例如跑步、球/球拍运动、举重训练、冰壶、划船、高尔夫、瑜伽或体操",
  "patient_adult_knee_function_q30_q1_a2_o2":"每周至少 5 次的休闲运动，例如游泳、水上运动、滑雪、曲棍球、滑旱冰、骑自行车、骑马或登山",
  "patient_adult_knee_function_q30_q1_a2_o3":"每周至少进行两次休闲运动",
  "patient_adult_knee_function_q30_q1_a2_o4":"每周一次或更少的休闲运动",
  "patient_adult_knee_function_q30_q1_a2_o5":"每月少于一次的休闲运动",
  "patient_adult_knee_function_q30_q1_a3_o1":"建筑等重体力劳动",
  "patient_adult_knee_function_q30_q1_a3_o2":"中等重体力劳动，例如卡车驾驶",
  "patient_adult_knee_function_q30_q1_a3_o3":"轻体力劳动比如护理等",
  "patient_adult_knee_function_q30_q1_a3_o4":"久坐的工作，例如秘书或基于计算机的工作",
  "patient_adult_knee_function_q30_q1_a4_o1":"日常活动，例如园艺、爬多段楼梯、搬运货物、推/拉货物、迟到时跑步的能力",
  "patient_adult_knee_function_q30_q1_a4_o2":"日常活动，如打扫房屋、爬1或2层楼梯，或携带轻物品行走",
  "patient_adult_knee_function_q30_q1_a4_o3":"日常活动受限（例如不爬楼梯、无法搬运物品）",
  "patient_adult_knee_function_q30_q2":"当前状态",
  "patient_adult_knee_function_q30_q2_a1":"竞技",
  "patient_adult_knee_function_q30_q2_a2":"悠闲，圣体素质",
  "patient_adult_knee_function_q30_q2_a3":"没有运动，工作而已",
  "patient_adult_knee_function_q30_q2_a4":"日常生活活动",
  "patient_adult_knee_function_q30_q2_a5":"因健康问题请病假或伤残抚恤金",
  "patient_adult_knee_function_q30_q2_a1_o1":"世界级、奥林匹克级或职业竞技运动",
  "patient_adult_knee_function_q30_q2_a1_o2":"国家或国际级别的竞技运动，例如田径、球拍/球类运动、体操、赛艇、滑雪或武术",
  "patient_adult_knee_function_q30_q2_a1_o3":"国内或国际级别的竞技运动，如水上运动、自行车、曲棍球、冰壶或骑马/竞技表演",
  "patient_adult_knee_function_q30_q2_a2_o1":"每周至少 5 次的休闲运动，例如跑步、球/球拍运动、举重训练、冰壶、划船、高尔夫、瑜伽或体操",
  "patient_adult_knee_function_q30_q2_a2_o2":"每周至少 5 次的休闲运动，例如游泳、水上运动、滑雪、曲棍球、滑旱冰、骑自行车、骑马或登山",
  "patient_adult_knee_function_q30_q2_a2_o3":"每周至少进行两次休闲运动",
  "patient_adult_knee_function_q30_q2_a2_o4":"每周一次或更少的休闲运动",
  "patient_adult_knee_function_q30_q2_a2_o5":"每月少于一次的休闲运动",
  "patient_adult_knee_function_q30_q2_a3_o1":"建筑等重体力劳动",
  "patient_adult_knee_function_q30_q2_a3_o2":"中等重体力劳动，例如卡车驾驶",
  "patient_adult_knee_function_q30_q2_a3_o3":"轻体力劳动比如护理等",
  "patient_adult_knee_function_q30_q2_a3_o4":"久坐的工作，例如秘书或基于计算机的工作",
  "patient_adult_knee_function_q30_q2_a4_o1":"日常活动，例如园艺、爬多段楼梯、搬运货物、推/拉货物、迟到时跑步的能力",
  "patient_adult_knee_function_q30_q2_a4_o2":"日常活动，如打扫房屋、爬1或2层楼梯，或携带轻物品行走",
  "patient_adult_knee_function_q30_q2_a4_o3":"日常活动受限（例如不爬楼梯、无法搬运物品）",
  "patient_adult_knee_function_q31":"您的膝盖骨是否错位或感觉好像错位吗？",
  "patient_adult_knee_function_q31_a1":"否",
  "patient_adult_knee_function_q31_a2":"是",
  "patient_adult_knee_patellofemoral_instability":"髌股不稳",
  "patient_adult_knee_patellofemoral_instability_instruction":"请选择最能描述您进行以下各项活动时膝盖骨从关节“弹出”或感觉不稳定的频率",
  "patient_adult_knee_patellofemoral_instability_instruction_0":"不曾",
  "patient_adult_knee_patellofemoral_instability_instruction_1":"偶尔",
  "patient_adult_knee_patellofemoral_instability_instruction_2":"有时候",
  "patient_adult_knee_patellofemoral_instability_instruction_3":"经常",
  "patient_adult_knee_patellofemoral_instability_instruction_4":"持续",
  "patient_adult_knee_patellofemoral_instability_q1":"运动/比赛期间扭转/改变方向",
  "patient_adult_knee_patellofemoral_instability_q1_do_not_do":"不做",
  "patient_adult_knee_patellofemoral_instability_q1_a1":"不曾",
  "patient_adult_knee_patellofemoral_instability_q1_a2":"偶尔",
  "patient_adult_knee_patellofemoral_instability_q1_a3":"有时候",
  "patient_adult_knee_patellofemoral_instability_q1_a4":"经常",
  "patient_adult_knee_patellofemoral_instability_q1_a5":"持续",
  "patient_adult_knee_patellofemoral_instability_q2":"跑步时改变方向",
  "patient_adult_knee_patellofemoral_instability_q2_do_not_do":"不做",
  "patient_adult_knee_patellofemoral_instability_q2_a1":"不曾",
  "patient_adult_knee_patellofemoral_instability_q2_a2":"偶尔",
  "patient_adult_knee_patellofemoral_instability_q2_a3":"有时候",
  "patient_adult_knee_patellofemoral_instability_q2_a4":"经常",
  "patient_adult_knee_patellofemoral_instability_q2_a5":"持续",
  "patient_adult_knee_patellofemoral_instability_q3":"在不平坦的表面上直线运行/奔跑",
  "patient_adult_knee_patellofemoral_instability_q3_do_not_do":"不做",
  "patient_adult_knee_patellofemoral_instability_q3_a1":"不曾",
  "patient_adult_knee_patellofemoral_instability_q3_a2":"偶尔",
  "patient_adult_knee_patellofemoral_instability_q3_a3":"有时候",
  "patient_adult_knee_patellofemoral_instability_q3_a4":"经常",
  "patient_adult_knee_patellofemoral_instability_q3_a5":"持续",
  "patient_adult_knee_patellofemoral_instability_q4":"在湿滑、潮湿或结冰的表面行走",
  "patient_adult_knee_patellofemoral_instability_q4_do_not_do":"不做",
  "patient_adult_knee_patellofemoral_instability_q4_a1":"不曾",
  "patient_adult_knee_patellofemoral_instability_q4_a2":"偶尔",
  "patient_adult_knee_patellofemoral_instability_q4_a3":"有时候",
  "patient_adult_knee_patellofemoral_instability_q4_a4":"经常",
  "patient_adult_knee_patellofemoral_instability_q4_a5":"持续",
  "patient_adult_knee_patellofemoral_instability_q5":"侧身跑",
  "patient_adult_knee_patellofemoral_instability_q5_do_not_do":"不做",
  "patient_adult_knee_patellofemoral_instability_q5_a1":"不曾",
  "patient_adult_knee_patellofemoral_instability_q5_a2":"偶尔",
  "patient_adult_knee_patellofemoral_instability_q5_a3":"有时候",
  "patient_adult_knee_patellofemoral_instability_q5_a4":"经常",
  "patient_adult_knee_patellofemoral_instability_q5_a5":"持续",
  "patient_adult_knee_patellofemoral_instability_q6":"跃迁",
  "patient_adult_knee_patellofemoral_instability_q6_do_not_do":"不做",
  "patient_adult_knee_patellofemoral_instability_q6_a1":"不曾",
  "patient_adult_knee_patellofemoral_instability_q6_a2":"偶尔",
  "patient_adult_knee_patellofemoral_instability_q6_a3":"有时候",
  "patient_adult_knee_patellofemoral_instability_q6_a4":"经常",
  "patient_adult_knee_patellofemoral_instability_q6_a5":"持续",
  "patient_adult_knee_patellofemoral_instability_q7":"跳跃",
  "patient_adult_knee_patellofemoral_instability_q7_do_not_do":"不做",
  "patient_adult_knee_patellofemoral_instability_q7_a1":"不曾",
  "patient_adult_knee_patellofemoral_instability_q7_a2":"偶尔",
  "patient_adult_knee_patellofemoral_instability_q7_a3":"有时候",
  "patient_adult_knee_patellofemoral_instability_q7_a4":"经常",
  "patient_adult_knee_patellofemoral_instability_q7_a5":"持续",
  "patient_adult_knee_patellofemoral_instability_q8":"在平坦的表面上直线奔跑/跑步",
  "patient_adult_knee_patellofemoral_instability_q8_do_not_do":"不做",
  "patient_adult_knee_patellofemoral_instability_q8_a1":"不曾",
  "patient_adult_knee_patellofemoral_instability_q8_a2":"偶尔",
  "patient_adult_knee_patellofemoral_instability_q8_a3":"有时候",
  "patient_adult_knee_patellofemoral_instability_q8_a4":"经常",
  "patient_adult_knee_patellofemoral_instability_q8_a5":"持续",
  "patient_adult_knee_patellofemoral_instability_q9":"下楼梯",
  "patient_adult_knee_patellofemoral_instability_q9_do_not_do":"不做",
  "patient_adult_knee_patellofemoral_instability_q9_a1":"不曾",
  "patient_adult_knee_patellofemoral_instability_q9_a2":"偶尔",
  "patient_adult_knee_patellofemoral_instability_q9_a3":"有时候",
  "patient_adult_knee_patellofemoral_instability_q9_a4":"经常",
  "patient_adult_knee_patellofemoral_instability_q9_a5":"持续",
  "patient_adult_knee_patellofemoral_instability_q10":"蹲下",
  "patient_adult_knee_patellofemoral_instability_q10_do_not_do":"不做",
  "patient_adult_knee_patellofemoral_instability_q10_a1":"不曾",
  "patient_adult_knee_patellofemoral_instability_q10_a2":"偶尔",
  "patient_adult_knee_patellofemoral_instability_q10_a3":"有时候",
  "patient_adult_knee_patellofemoral_instability_q10_a4":"经常",
  "patient_adult_knee_patellofemoral_instability_q10_a5":"持续",
  "patient_adult_knee_patellofemoral_instability_q11":"跪下",
  "patient_adult_knee_patellofemoral_instability_q11_do_not_do":"不做",
  "patient_adult_knee_patellofemoral_instability_q11_a1":"不曾",
  "patient_adult_knee_patellofemoral_instability_q11_a2":"偶尔",
  "patient_adult_knee_patellofemoral_instability_q11_a3":"有时候",
  "patient_adult_knee_patellofemoral_instability_q11_a4":"经常",
  "patient_adult_knee_patellofemoral_instability_q11_a5":"持续",
  "patient_adult_knee_patellofemoral_instability_q12":"在不平坦的表面上直线步行",
  "patient_adult_knee_patellofemoral_instability_q12_do_not_do":"不做",
  "patient_adult_knee_patellofemoral_instability_q12_a1":"不曾",
  "patient_adult_knee_patellofemoral_instability_q12_a2":"偶尔",
  "patient_adult_knee_patellofemoral_instability_q12_a3":"有时候",
  "patient_adult_knee_patellofemoral_instability_q12_a4":"经常",
  "patient_adult_knee_patellofemoral_instability_q12_a5":"持续",
  "patient_adult_knee_patellofemoral_instability_q13":"上楼梯",
  "patient_adult_knee_patellofemoral_instability_q13_do_not_do":"不做",
  "patient_adult_knee_patellofemoral_instability_q13_a1":"不曾",
  "patient_adult_knee_patellofemoral_instability_q13_a2":"偶尔",
  "patient_adult_knee_patellofemoral_instability_q13_a3":"有时候",
  "patient_adult_knee_patellofemoral_instability_q13_a4":"经常",
  "patient_adult_knee_patellofemoral_instability_q13_a5":"持续",
  "patient_adult_knee_patellofemoral_instability_q14":"踏上或跨过高台阶",
  "patient_adult_knee_patellofemoral_instability_q14_do_not_do":"不做",
  "patient_adult_knee_patellofemoral_instability_q14_a1":"不曾",
  "patient_adult_knee_patellofemoral_instability_q14_a2":"偶尔",
  "patient_adult_knee_patellofemoral_instability_q14_a3":"有时候",
  "patient_adult_knee_patellofemoral_instability_q14_a4":"经常",
  "patient_adult_knee_patellofemoral_instability_q14_a5":"持续",
  "patient_adult_knee_patellofemoral_instability_q15":"坐着时交叉双腿",
  "patient_adult_knee_patellofemoral_instability_q15_do_not_do":"不做",
  "patient_adult_knee_patellofemoral_instability_q15_a1":"不曾",
  "patient_adult_knee_patellofemoral_instability_q15_a2":"偶尔",
  "patient_adult_knee_patellofemoral_instability_q15_a3":"有时候",
  "patient_adult_knee_patellofemoral_instability_q15_a4":"经常",
  "patient_adult_knee_patellofemoral_instability_q15_a5":"持续",
  "patient_adult_knee_patellofemoral_instability_q16":"在平坦的表面上直线步行",
  "patient_adult_knee_patellofemoral_instability_q16_do_not_do":"不做",
  "patient_adult_knee_patellofemoral_instability_q16_a1":"不曾",
  "patient_adult_knee_patellofemoral_instability_q16_a2":"偶尔",
  "patient_adult_knee_patellofemoral_instability_q16_a3":"有时候",
  "patient_adult_knee_patellofemoral_instability_q16_a4":"经常",
  "patient_adult_knee_patellofemoral_instability_q16_a5":"持续",
  "patient_adult_knee_patellofemoral_instability_q17":"进出车",
  "patient_adult_knee_patellofemoral_instability_q17_do_not_do":"不做",
  "patient_adult_knee_patellofemoral_instability_q17_a1":"不曾",
  "patient_adult_knee_patellofemoral_instability_q17_a2":"偶尔",
  "patient_adult_knee_patellofemoral_instability_q17_a3":"有时候",
  "patient_adult_knee_patellofemoral_instability_q17_a4":"经常",
  "patient_adult_knee_patellofemoral_instability_q17_a5":"持续",
  "patient_adult_knee_patellofemoral_instability_q18":"在超市过道上转动重型手推车",
  "patient_adult_knee_patellofemoral_instability_q18_do_not_do":"不做",
  "patient_adult_knee_patellofemoral_instability_q18_a1":"不曾",
  "patient_adult_knee_patellofemoral_instability_q18_a2":"偶尔",
  "patient_adult_knee_patellofemoral_instability_q18_a3":"有时候",
  "patient_adult_knee_patellofemoral_instability_q18_a4":"经常",
  "patient_adult_knee_patellofemoral_instability_q18_a5":"持续",
  "patient_adult_knee_patellofemoral_instability_q19":"转向过肩膀方向看望",
  "patient_adult_knee_patellofemoral_instability_q19_do_not_do":"不做",
  "patient_adult_knee_patellofemoral_instability_q19_a1":"不曾",
  "patient_adult_knee_patellofemoral_instability_q19_a2":"偶尔",
  "patient_adult_knee_patellofemoral_instability_q19_a3":"有时候",
  "patient_adult_knee_patellofemoral_instability_q19_a4":"经常",
  "patient_adult_knee_patellofemoral_instability_q19_a5":"持续",
  "patient_adult_knee_quality_of_life":"生活质量",
  "patient_adult_knee_quality_of_life_instruction":"以下问题询问您可能对膝盖问题感到的社交、情感和生活方式方面的担忧。 请想一想您大部分时间对膝盖问题的感受如何。",
  "patient_adult_knee_quality_of_life_q1":"您多常意识到自己的膝盖问题？",
  "patient_adult_knee_quality_of_life_q1_a1":"不曾",
  "patient_adult_knee_quality_of_life_q1_a2":"偶尔",
  "patient_adult_knee_quality_of_life_q1_a3":"有时候",
  "patient_adult_knee_quality_of_life_q1_a4":"经常",
  "patient_adult_knee_quality_of_life_q1_a5":"持续",
  "patient_adult_knee_quality_of_life_q2":"您是否改变了生活方式而避免可能对膝盖造成损害的活动？",
  "patient_adult_knee_quality_of_life_q2_a1":"一点也不",
  "patient_adult_knee_quality_of_life_q2_a2":"稍微",
  "patient_adult_knee_quality_of_life_q2_a3":"中度",
  "patient_adult_knee_quality_of_life_q2_a4":"严重",
  "patient_adult_knee_quality_of_life_q2_a5":"完全",
  "patient_adult_knee_quality_of_life_q3":"您对自己的膝盖缺乏信心有多少困扰？",
  "patient_adult_knee_quality_of_life_q3_a1":"一点也不",
  "patient_adult_knee_quality_of_life_q3_a2":"稍微",
  "patient_adult_knee_quality_of_life_q3_a3":"中度",
  "patient_adult_knee_quality_of_life_q3_a4":"严重",
  "patient_adult_knee_quality_of_life_q3_a5":"极其",
  "patient_adult_knee_quality_of_life_q4":"一般来说，您的膝盖有多少困难？",
  "patient_adult_knee_quality_of_life_q4_a1":"否",
  "patient_adult_knee_quality_of_life_q4_a2":"轻微",
  "patient_adult_knee_quality_of_life_q4_a3":"中度",
  "patient_adult_knee_quality_of_life_q4_a4":"严重",
  "patient_adult_knee_quality_of_life_q4_a5":"极度",
  "patient_adult_knee_quality_of_life_q5":"您今天有多焦虑/抑郁？",
  "patient_adult_knee_quality_of_life_q5_a1":"不焦虑/抑郁",
  "patient_adult_knee_quality_of_life_q5_a2":"一点点",
  "patient_adult_knee_quality_of_life_q5_a3":"中度",
  "patient_adult_knee_quality_of_life_q5_a4":"严重",
  "patient_adult_knee_quality_of_life_q5_a5":"极其",
  "patient_adult_knee_quality_of_life_q6":"您今天的健康状况如何？",
  "patient_adult_knee_quality_of_life_q6_instruction":"100 表示最佳健康状况，0 表示您可以想象的最差健康状况",
  "patient_adult_knee_quality_of_life_q6_instruction_a1":"最差",
  "patient_adult_knee_quality_of_life_q6_instruction_a2":"最好",
  "patient_adult_knee_satisfaction":"满意感",
  "patient_adult_knee_satisfaction_instructions":"您对膝盖手术/治疗的结果满意吗？",
  "patient_adult_knee_satisfaction_instructions_1":"很不满意",
  "patient_adult_knee_satisfaction_instructions_2":"有些不满意",
  "patient_adult_knee_satisfaction_instructions_3":"有些满意",
  "patient_adult_knee_satisfaction_instructions_4":"很满意",
  "patient_adult_knee_satisfaction_q1":"总体",
  "patient_adult_knee_satisfaction_q1_not_applicable":"不适用",
  "patient_adult_knee_satisfaction_q1_a1":"很不满意",
  "patient_adult_knee_satisfaction_q1_a2":"有些不满意",
  "patient_adult_knee_satisfaction_q1_a3":"有些满意",
  "patient_adult_knee_satisfaction_q1_a4":"很满意",
  "patient_adult_knee_satisfaction_q2":"为了改善你的疼痛？",
  "patient_adult_knee_satisfaction_q2_not_applicable":"不适用",
  "patient_adult_knee_satisfaction_q2_a1":"很不满意",
  "patient_adult_knee_satisfaction_q2_a2":"有些不满意",
  "patient_adult_knee_satisfaction_q2_a3":"有些满意",
  "patient_adult_knee_satisfaction_q2_a4":"很满意",
  "patient_adult_knee_satisfaction_q3":"为了提高您做家务或庭院工作的能力？",
  "patient_adult_knee_satisfaction_q3_not_applicable":"不适用",
  "patient_adult_knee_satisfaction_q3_a1":"很不满意",
  "patient_adult_knee_satisfaction_q3_a2":"有些不满意",
  "patient_adult_knee_satisfaction_q3_a3":"有些满意",
  "patient_adult_knee_satisfaction_q3_a4":"很满意",
  "patient_adult_knee_satisfaction_q4":"为了提高您进行娱乐活动的能力？",
  "patient_adult_knee_satisfaction_q4_not_applicable":"不适用",
  "patient_adult_knee_satisfaction_q4_a1":"很不满意",
  "patient_adult_knee_satisfaction_q4_a2":"有些不满意",
  "patient_adult_knee_satisfaction_q4_a3":"有些满意",
  "patient_adult_knee_satisfaction_q4_a4":"很满意",
  "patient_adult_knee_complete":"问卷结束",
  "patient_adult_knee_complete_text1":"感谢您抽出宝贵时间填写此表格。",
  "patient_adult_knee_complete_text2":"请点击提交完成并查看您的分数。",
  "patient_adult_knee_complete_text3":"您的回答将被保密，任何报告都将仅使用去识别化的咨讯。",
  "patient_adult_knee_results":"结果",
  "patient_adult_knee_results_text1":"以下是您的疼痛和膝关节功能评分，这些评分是根据您的调查问卷计算得出的。",
  "patient_adult_knee_results_pain":"疼痛",
  "patient_adult_knee_results_function":"功能",
  "patient_adult_knee_results_health_perception":"整体健康认知",
  "patient_adult_knee_results_self_evaluation":"膝盖评分自我评估",
  "patient_adult_knee_results_text2":"您的医生很快就会与您联系",
  "patient_adolescent_knee_pain":"疼痛",
  "patient_adolescent_knee_function_q23":"去购物",
  "patient_adolescent_knee_function_q23_a1":"没有困难",
  "patient_adolescent_knee_function_q23_a2":"一点点困难",
  "patient_adolescent_knee_function_q23_a3":"中度困难",
  "patient_adolescent_knee_function_q23_a4":"极度困难",
  "patient_adolescent_knee_function_q23_a5":"无法办到",
  "patient_pedia_knee_pain":"疼痛",
  "patient_pedia_knee_pain_q1":"在过去 4 周内或自您受伤以来，您受伤的膝盖有多少时间感到疼痛？",
  "patient_pedia_knee_pain_q1_a1":"不曾痛",
  "patient_pedia_knee_pain_q1_a2":"偶尔",
  "patient_pedia_knee_pain_q1_a3":"有时",
  "patient_pedia_knee_pain_q1_a4":"经常",
  "patient_pedia_knee_pain_q1_a5":"持续",
  "patient_pedia_knee_pain_q2":"今天你受伤的膝盖疼得有多厉害？",
  "patient_pedia_knee_pain_q2_a1":"完全不痛",
  "patient_pedia_knee_pain_q2_a2":"太痛了我无法忍受",
  "patient_pedia_knee_pain_instructions":"在过去 7 天内，您在进行以下活动时膝盖疼痛程度如何？",
  "patient_pedia_knee_pain_instructions_0":"没有痛",
  "patient_pedia_knee_pain_instructions_1":"一点点痛",
  "patient_pedia_knee_pain_instructions_2":"有些痛",
  "patient_pedia_knee_pain_instructions_3":"很多痛",
  "patient_pedia_knee_pain_instructions_4":"极度痛",
  "patient_pedia_knee_pain_note":"注意：请回答以下与您的膝盖活动相关的问题。",
  "patient_pedia_knee_pain_q3":"行走/站立/跑步时扭转/旋转膝盖",
  "patient_pedia_knee_pain_q3_a1":"没有痛",
  "patient_pedia_knee_pain_q3_a2":"一点点痛",
  "patient_pedia_knee_pain_q3_a3":"有些痛",
  "patient_pedia_knee_pain_q3_a4":"很多痛",
  "patient_pedia_knee_pain_q3_a5":"极度痛",
  "patient_pedia_knee_pain_q4":"膝盖伸直",
  "patient_pedia_knee_pain_q4_a1":"没有痛",
  "patient_pedia_knee_pain_q4_a2":"一点点痛",
  "patient_pedia_knee_pain_q4_a3":"有些痛",
  "patient_pedia_knee_pain_q4_a4":"很多痛",
  "patient_pedia_knee_pain_q4_a5":"极度痛",
  "patient_pedia_knee_pain_q5":"屈膝",
  "patient_pedia_knee_pain_q5_a1":"没有痛",
  "patient_pedia_knee_pain_q5_a2":"一点点痛",
  "patient_pedia_knee_pain_q5_a3":"有些痛",
  "patient_pedia_knee_pain_q5_a4":"很多痛",
  "patient_pedia_knee_pain_q5_a5":"极度痛",
  "patient_pedia_knee_pain_q6":"上楼梯",
  "patient_pedia_knee_pain_q6_a1":"没有痛",
  "patient_pedia_knee_pain_q6_a2":"一点点痛",
  "patient_pedia_knee_pain_q6_a3":"有些痛",
  "patient_pedia_knee_pain_q6_a4":"很多痛",
  "patient_pedia_knee_pain_q6_a5":"极度痛",
  "patient_pedia_knee_pain_q7":"下楼梯",
  "patient_pedia_knee_pain_q7_a1":"没有痛",
  "patient_pedia_knee_pain_q7_a2":"一点点痛",
  "patient_pedia_knee_pain_q7_a3":"有些痛",
  "patient_pedia_knee_pain_q7_a4":"很多痛",
  "patient_pedia_knee_pain_q7_a5":"极度痛",
  "patient_pedia_knee_pain_q8":"坐着屈膝",
  "patient_pedia_knee_pain_q8_a1":"没有痛",
  "patient_pedia_knee_pain_q8_a2":"一点点痛",
  "patient_pedia_knee_pain_q8_a3":"有些痛",
  "patient_pedia_knee_pain_q8_a4":"很多痛",
  "patient_pedia_knee_pain_q8_a5":"极度痛",
  "patient_pedia_knee_pain_q9":"双腿直立任意时间",
  "patient_pedia_knee_pain_q9_a1":"没有痛",
  "patient_pedia_knee_pain_q9_a2":"一点点痛",
  "patient_pedia_knee_pain_q9_a3":"有些痛",
  "patient_pedia_knee_pain_q9_a4":"很多痛",
  "patient_pedia_knee_pain_q9_a5":"极度痛",
  "patient_pedia_knee_other_symptoms":"其他症状",
  "patient_pedia_knee_other_symptoms_instruction1":"您多常经验以下状况：",
  "patient_pedia_knee_other_symptoms_instruction1_0":"不曾",
  "patient_pedia_knee_other_symptoms_instruction1_1":"偶尔",
  "patient_pedia_knee_other_symptoms_instruction1_2":"有时侯",
  "patient_pedia_knee_other_symptoms_instruction1_3":"经常",
  "patient_pedia_knee_other_symptoms_instruction1_4":"持续",
  "patient_pedia_knee_other_symptoms_q1":"膝盖发出任何噪音/声音",
  "patient_pedia_knee_other_symptoms_q1_a1":"不曾",
  "patient_pedia_knee_other_symptoms_q1_a2":"偶尔",
  "patient_pedia_knee_other_symptoms_q1_a3":"有时侯",
  "patient_pedia_knee_other_symptoms_q1_a4":"经常",
  "patient_pedia_knee_other_symptoms_q1_a5":"持续",
  "patient_pedia_knee_other_symptoms_q2":"膝盖被卡住或锁定在某些位置",
  "patient_pedia_knee_other_symptoms_q2_a1":"不曾",
  "patient_pedia_knee_other_symptoms_q2_a2":"偶尔",
  "patient_pedia_knee_other_symptoms_q2_a3":"有时侯",
  "patient_pedia_knee_other_symptoms_q2_a4":"经常",
  "patient_pedia_knee_other_symptoms_q2_a5":"持续",
  "patient_pedia_knee_other_symptoms_q3":"感觉膝盖被锁定（卡住），但您仍然可以移动它",
  "patient_pedia_knee_other_symptoms_q3_a1":"不曾",
  "patient_pedia_knee_other_symptoms_q3_a2":"偶尔",
  "patient_pedia_knee_other_symptoms_q3_a3":"有时侯",
  "patient_pedia_knee_other_symptoms_q3_a4":"经常",
  "patient_pedia_knee_other_symptoms_q3_a5":"持续",
  "patient_pedia_knee_other_symptoms_q4":"膝盖肿胀/浮肿",
  "patient_pedia_knee_other_symptoms_q4_a1":"不曾",
  "patient_pedia_knee_other_symptoms_q4_a2":"偶尔",
  "patient_pedia_knee_other_symptoms_q4_a3":"有时侯",
  "patient_pedia_knee_other_symptoms_q4_a4":"经常",
  "patient_pedia_knee_other_symptoms_q4_a5":"持续",
  "patient_pedia_knee_other_symptoms_q5":"您的膝盖有多浮肿（或肿胀）？",
  "patient_pedia_knee_other_symptoms_q5_a1":"不浮肿",
  "patient_pedia_knee_other_symptoms_q5_a2":"一点点浮肿",
  "patient_pedia_knee_other_symptoms_q5_a3":"有些浮肿",
  "patient_pedia_knee_other_symptoms_q5_a4":"很浮肿",
  "patient_pedia_knee_other_symptoms_q5_a5":"极度浮肿",
  "patient_pedia_knee_other_symptoms_instruction2":"如果您被要求执行以下活动，您今天最多可以做什么......",
  "patient_pedia_knee_other_symptoms_instruction2_0":"非常艰苦的活动，例如跳跃或快速转身以改变方向，例如篮球或足球",
  "patient_pedia_knee_other_symptoms_instruction2_1":"艰苦的活动，如举重，滑雪，或网球",
  "patient_pedia_knee_other_symptoms_instruction2_2":"一些艰苦的活动，例如快走或慢跑",
  "patient_pedia_knee_other_symptoms_instruction2_3":"轻度活动，例如以正常速度行走",
  "patient_pedia_knee_other_symptoms_instruction2_4":"我无法进行上面列出的任何活动",
  "patient_pedia_knee_other_symptoms_q6":"否而会使到您的膝盖很疼痛",
  "patient_pedia_knee_other_symptoms_q6_a1":"非常艰苦的活动",
  "patient_pedia_knee_other_symptoms_q6_a2":"艰苦的活动",
  "patient_pedia_knee_other_symptoms_q6_a3":"大约艰苦的活动",
  "patient_pedia_knee_other_symptoms_q6_a4":"轻度活动",
  "patient_pedia_knee_other_symptoms_q6_a5":"我无法进行上面列出的任何活动",
  "patient_pedia_knee_other_symptoms_q7":"否而会使到您的膝盖浮肿（或肿胀）",
  "patient_pedia_knee_other_symptoms_q7_a1":"非常艰苦的活动",
  "patient_pedia_knee_other_symptoms_q7_a2":"艰苦的活动",
  "patient_pedia_knee_other_symptoms_q7_a3":"大约艰苦的活动",
  "patient_pedia_knee_other_symptoms_q7_a4":"轻度活动",
  "patient_pedia_knee_other_symptoms_q7_a5":"我无法进行上面列出的任何活动",
  "patient_pedia_knee_other_symptoms_q8":"否而会使到您感觉膝盖无法支撑您的体重",
  "patient_pedia_knee_other_symptoms_q8_a1":"非常艰苦的活动",
  "patient_pedia_knee_other_symptoms_q8_a2":"艰苦的活动",
  "patient_pedia_knee_other_symptoms_q8_a3":"大约艰苦的活动",
  "patient_pedia_knee_other_symptoms_q8_a5":"我无法进行上面列出的任何活动",
  "patient_pedia_knee_other_symptoms_q9":"大多的时候",
  "patient_pedia_knee_other_symptoms_q9_a1":"非常艰苦的活动",
  "patient_pedia_knee_other_symptoms_q9_a2":"艰苦的活动",
  "patient_pedia_knee_other_symptoms_q9_a3":"大约艰苦的活动",
  "patient_pedia_knee_other_symptoms_q9_a4":"轻度活动",
  "patient_pedia_knee_other_symptoms_q9_a5":"我无法进行上面列出的任何活动",
  "patient_pedia_knee_function":"功能",
  "patient_pedia_knee_function_instruction":"由于膝盖的原因，您在执行以下操作时遇到了多少困难：",
  "patient_pedia_knee_function_instruction_0":"没有困难",
  "patient_pedia_knee_function_instruction_1":"一点点",
  "patient_pedia_knee_function_instruction_2":"一些",
  "patient_pedia_knee_function_instruction_3":"很多",
  "patient_pedia_knee_function_instruction_4":"极度困难/无法办到",
  "patient_pedia_knee_function_note":"注意：如果您不做某项活动，想象一下如果您必须尝试一下，您的膝盖会有什么感觉。",
  "patient_pedia_knee_function_q1":"上楼梯",
  "patient_pedia_knee_function_q1_a1":"没有困难",
  "patient_pedia_knee_function_q1_a2":"一点点",
  "patient_pedia_knee_function_q1_a3":"一些",
  "patient_pedia_knee_function_q1_a4":"很多",
  "patient_pedia_knee_function_q1_a5":"极度困难/无法办到",
  "patient_pedia_knee_function_q2":"下楼梯",
  "patient_pedia_knee_function_q2_a1":"没有困难",
  "patient_pedia_knee_function_q2_a2":"一点点",
  "patient_pedia_knee_function_q2_a3":"一些",
  "patient_pedia_knee_function_q2_a4":"很多",
  "patient_pedia_knee_function_q2_a5":"极度困难/无法办到",
  "patient_pedia_knee_function_q3":"在膝盖前跪下",
  "patient_pedia_knee_function_q3_a1":"没有困难",
  "patient_pedia_knee_function_q3_a2":"一点点",
  "patient_pedia_knee_function_q3_a3":"一些",
  "patient_pedia_knee_function_q3_a4":"很多",
  "patient_pedia_knee_function_q3_a5":"极度困难/无法办到",
  "patient_pedia_knee_function_q4":"完全把膝盖伸直",
  "patient_pedia_knee_function_q4_a1":"没有困难",
  "patient_pedia_knee_function_q4_a2":"一点点",
  "patient_pedia_knee_function_q4_a3":"一些",
  "patient_pedia_knee_function_q4_a4":"很多",
  "patient_pedia_knee_function_q4_a5":"极度困难/无法办到",
  "patient_pedia_knee_function_q5":"完全屈膝",
  "patient_pedia_knee_function_q5_a1":"没有困难",
  "patient_pedia_knee_function_q5_a2":"一点点",
  "patient_pedia_knee_function_q5_a3":"一些",
  "patient_pedia_knee_function_q5_a4":"很多",
  "patient_pedia_knee_function_q5_a5":"极度困难/无法办到",
  "patient_pedia_knee_function_q6":"早晨一醒过来把膝盖活动",
  "patient_pedia_knee_function_q6_a1":"没有困难",
  "patient_pedia_knee_function_q6_a2":"一点点",
  "patient_pedia_knee_function_q6_a3":"一些",
  "patient_pedia_knee_function_q6_a4":"很多",
  "patient_pedia_knee_function_q6_a5":"极度困难/无法办到",
  "patient_pedia_knee_function_q7":"傍晚旧坐后把膝盖活动一下",
  "patient_pedia_knee_function_q7_a1":"没有困难",
  "patient_pedia_knee_function_q7_a2":"一点点",
  "patient_pedia_knee_function_q7_a3":"一些",
  "patient_pedia_knee_function_q7_a4":"很多",
  "patient_pedia_knee_function_q7_a5":"极度困难/无法办到",
  "patient_pedia_knee_function_q8":"像棒球接球手一样蹲下",
  "patient_pedia_knee_function_q8_a1":"没有困难",
  "patient_pedia_knee_function_q8_a2":"一点点",
  "patient_pedia_knee_function_q8_a3":"一些",
  "patient_pedia_knee_function_q8_a4":"很多",
  "patient_pedia_knee_function_q8_a5":"极度困难/无法办到",
  "patient_pedia_knee_function_q9":"坐在椅子上，屈膝，双脚平放在地板上",
  "patient_pedia_knee_function_q9_a1":"没有困难",
  "patient_pedia_knee_function_q9_a2":"一点点",
  "patient_pedia_knee_function_q9_a3":"一些",
  "patient_pedia_knee_function_q9_a4":"很多",
  "patient_pedia_knee_function_q9_a5":"极度困难/无法办到",
  "patient_pedia_knee_function_q10":"从椅子上站起来",
  "patient_pedia_knee_function_q10_a1":"没有困难",
  "patient_pedia_knee_function_q10_a2":"一点点",
  "patient_pedia_knee_function_q10_a3":"一些",
  "patient_pedia_knee_function_q10_a4":"很多",
  "patient_pedia_knee_function_q10_a5":"极度困难/无法办到",
  "patient_pedia_knee_function_q11":"弯腰并从地板上捡起一个物体",
  "patient_pedia_knee_function_q11_a1":"没有困难",
  "patient_pedia_knee_function_q11_a2":"一点点",
  "patient_pedia_knee_function_q11_a3":"一些",
  "patient_pedia_knee_function_q11_a4":"很多",
  "patient_pedia_knee_function_q11_a5":"极度困难/无法办到",
  "patient_pedia_knee_function_q12":"在玩耍或体育活动时跑步",
  "patient_pedia_knee_function_q12_a1":"没有困难",
  "patient_pedia_knee_function_q12_a2":"一点点",
  "patient_pedia_knee_function_q12_a3":"一些",
  "patient_pedia_knee_function_q12_a4":"很多",
  "patient_pedia_knee_function_q12_a5":"极度困难/无法办到",
  "patient_pedia_knee_function_q13":"在玩耍或体育活动时跳跃",
  "patient_pedia_knee_function_q13_a1":"没有困难",
  "patient_pedia_knee_function_q13_a2":"一点点",
  "patient_pedia_knee_function_q13_a3":"一些",
  "patient_pedia_knee_function_q13_a4":"很多",
  "patient_pedia_knee_function_q13_a5":"极度困难/无法办到",
  "patient_pedia_knee_function_q14":"移动时快速开始和停止",
  "patient_pedia_knee_function_q14_a1":"没有困难",
  "patient_pedia_knee_function_q14_a2":"一点点",
  "patient_pedia_knee_function_q14_a3":"一些",
  "patient_pedia_knee_function_q14_a4":"很多",
  "patient_pedia_knee_function_q14_a5":"极度困难/无法办到",
  "patient_pedia_knee_function_q15":"在玩耍或体育活动期间扭转/旋转",
  "patient_pedia_knee_function_q15_a1":"没有困难",
  "patient_pedia_knee_function_q15_a2":"一点点",
  "patient_pedia_knee_function_q15_a3":"一些",
  "patient_pedia_knee_function_q15_a4":"很多",
  "patient_pedia_knee_function_q15_a5":"极度困难/无法办到",
  "patient_pedia_knee_function_q16":"在不平坦的地面上行走/跑步时保持平衡",
  "patient_pedia_knee_function_q16_a1":"没有困难",
  "patient_pedia_knee_function_q16_a2":"一点点",
  "patient_pedia_knee_function_q16_a3":"一些",
  "patient_pedia_knee_function_q16_a4":"很多",
  "patient_pedia_knee_function_q16_a5":"极度困难/无法办到",
  "patient_pedia_knee_function_q17":"参加运动",
  "patient_pedia_knee_function_q17_a1":"没有困难",
  "patient_pedia_knee_function_q17_a2":"一点点",
  "patient_pedia_knee_function_q17_a3":"一些",
  "patient_pedia_knee_function_q17_a4":"很多",
  "patient_pedia_knee_function_q17_a5":"极度困难/无法办到",
  "patient_pedia_knee_function_q18":"进出车",
  "patient_pedia_knee_function_q18_a1":"没有困难",
  "patient_pedia_knee_function_q18_a2":"一点点",
  "patient_pedia_knee_function_q18_a3":"一些",
  "patient_pedia_knee_function_q18_a4":"很多",
  "patient_pedia_knee_function_q18_a5":"极度困难/无法办到",
  "patient_pedia_knee_function_q19":"起床",
  "patient_pedia_knee_function_q19_a1":"没有困难",
  "patient_pedia_knee_function_q19_a2":"一点点",
  "patient_pedia_knee_function_q19_a3":"一些",
  "patient_pedia_knee_function_q19_a4":"很多",
  "patient_pedia_knee_function_q19_a5":"极度困难/无法办到",
  "patient_pedia_knee_function_q20":"在床上躺着时改变膝盖位置",
  "patient_pedia_knee_function_q20_a1":"没有困难",
  "patient_pedia_knee_function_q20_a2":"一点点",
  "patient_pedia_knee_function_q20_a3":"一些",
  "patient_pedia_knee_function_q20_a4":"很多",
  "patient_pedia_knee_function_q20_a5":"极度困难/无法办到",
  "patient_pedia_knee_function_q21":"进出浴缸/浴室",
  "patient_pedia_knee_function_q21_a1":"没有困难",
  "patient_pedia_knee_function_q21_a2":"一点点",
  "patient_pedia_knee_function_q21_a3":"一些",
  "patient_pedia_knee_function_q21_a4":"很多",
  "patient_pedia_knee_function_q21_a5":"极度困难/无法办到",
  "patient_pedia_knee_function_q22":"携带重包/背包等",
  "patient_pedia_knee_function_q22_a1":"没有困难",
  "patient_pedia_knee_function_q22_a2":"一点点",
  "patient_pedia_knee_function_q22_a3":"一些",
  "patient_pedia_knee_function_q22_a4":"很多",
  "patient_pedia_knee_function_q22_a5":"极度困难/无法办到",
  "patient_pedia_knee_function_q23":"做一些简单的家务，例如打扫房间、装满/清空洗碗机、整理床铺等",
  "patient_pedia_knee_function_q23_a1":"没有困难",
  "patient_pedia_knee_function_q23_a2":"一点点",
  "patient_pedia_knee_function_q23_a3":"一些",
  "patient_pedia_knee_function_q23_a4":"很多",
  "patient_pedia_knee_function_q23_a5":"极度困难/无法办到",
  "patient_pedia_knee_function_q24":"去学校或在学校里走动（爬楼梯、开门、搬运书本、课间休息时参与）",
  "patient_pedia_knee_function_q24_a1":"没有困难",
  "patient_pedia_knee_function_q24_a2":"一点点",
  "patient_pedia_knee_function_q24_a3":"一些",
  "patient_pedia_knee_function_q24_a4":"很多",
  "patient_pedia_knee_function_q24_a5":"极度困难/无法办到",
  "patient_pedia_knee_function_q25":"和朋友一起做事",
  "patient_pedia_knee_function_q25_a1":"没有困难",
  "patient_pedia_knee_function_q25_a2":"一点点",
  "patient_pedia_knee_function_q25_a3":"一些",
  "patient_pedia_knee_function_q25_a4":"很多",
  "patient_pedia_knee_function_q25_a5":"极度困难/无法办到",
  "patient_pedia_knee_function_q26":"在受伤之前，您的膝盖功能情况如何？",
  "patient_pedia_knee_function_q26_instruction":"10 表示正常，0 表示无法进行任何日常活动，包括运动",
  "patient_pedia_knee_function_q26_instruction_a1":"我什么也做不到",
  "patient_pedia_knee_function_q26_instruction_a2":"我可以做任何我想做的事",
  "patient_pedia_knee_function_q27":"您的膝盖现在状况如何？",
  "patient_pedia_knee_function_q27_instruction":"10 表示正常，0 表示无法进行任何日常活动，包括运动",
  "patient_pedia_knee_function_q27_instruction_a1":"我什么也做不到",
  "patient_pedia_knee_function_q27_instruction_a2":"我可以做任何我想做的事",
  "patient_pedia_knee_function_q28":"您的膝盖骨是否错位或感觉好像错位？",
  "patient_pedia_knee_function_q28_a1":"否",
  "patient_pedia_knee_function_q28_a2":"是",
  "patient_pedia_knee_instability":"髌股不稳",
  "patient_pedia_knee_instability_instruction":"请选择最能描述您进行以下各项活动时膝盖骨从关节“弹出”或感觉不稳定的频率",
  "patient_pedia_knee_instability_instruction_0":"不曾",
  "patient_pedia_knee_instability_instruction_1":"偶尔",
  "patient_pedia_knee_instability_instruction_2":"有时候",
  "patient_pedia_knee_instability_instruction_3":"经常",
  "patient_pedia_knee_instability_instruction_4":"持续",
  "patient_pedia_knee_instability_note":"注意：请回答以下与您的膝盖活动相关的问题",
  "patient_pedia_knee_instability_q1":"运动/比赛期间扭转/改变方向",
  "patient_pedia_knee_instability_q1_do_not_do":"不做",
  "patient_pedia_knee_instability_q1_a1":"不曾",
  "patient_pedia_knee_instability_q1_a2":"偶尔",
  "patient_pedia_knee_instability_q1_a3":"有时候",
  "patient_pedia_knee_instability_q1_a4":"经常",
  "patient_pedia_knee_instability_q1_a5":"持续",
  "patient_pedia_knee_instability_q2":"跑步时改变方向",
  "patient_pedia_knee_instability_q2_do_not_do":"不做",
  "patient_pedia_knee_instability_q2_a1":"不曾",
  "patient_pedia_knee_instability_q2_a2":"偶尔",
  "patient_pedia_knee_instability_q2_a3":"有时候",
  "patient_pedia_knee_instability_q2_a4":"经常",
  "patient_pedia_knee_instability_q2_a5":"持续",
  "patient_pedia_knee_instability_q3":"在不平坦的表面上直线运行/奔跑",
  "patient_pedia_knee_instability_q3_do_not_do":"不做",
  "patient_pedia_knee_instability_q3_a1":"不曾",
  "patient_pedia_knee_instability_q3_a2":"偶尔",
  "patient_pedia_knee_instability_q3_a3":"有时候",
  "patient_pedia_knee_instability_q3_a4":"经常",
  "patient_pedia_knee_instability_q3_a5":"持续",
  "patient_pedia_knee_instability_q4":"在湿滑、潮湿或结冰的表面行走",
  "patient_pedia_knee_instability_q4_do_not_do":"不做",
  "patient_pedia_knee_instability_q4_a1":"不曾",
  "patient_pedia_knee_instability_q4_a2":"偶尔",
  "patient_pedia_knee_instability_q4_a3":"有时候",
  "patient_pedia_knee_instability_q4_a4":"经常",
  "patient_pedia_knee_instability_q4_a5":"持续",
  "patient_pedia_knee_instability_q5":"侧身跑",
  "patient_pedia_knee_instability_q5_do_not_do":"不做",
  "patient_pedia_knee_instability_q5_a1":"不曾",
  "patient_pedia_knee_instability_q5_a2":"偶尔",
  "patient_pedia_knee_instability_q5_a3":"有时候",
  "patient_pedia_knee_instability_q5_a4":"经常",
  "patient_pedia_knee_instability_q5_a5":"持续",
  "patient_pedia_knee_instability_q6":"跃迁",
  "patient_pedia_knee_instability_q6_do_not_do":"不做",
  "patient_pedia_knee_instability_q6_a1":"不曾",
  "patient_pedia_knee_instability_q6_a2":"偶尔",
  "patient_pedia_knee_instability_q6_a3":"有时候",
  "patient_pedia_knee_instability_q6_a4":"经常",
  "patient_pedia_knee_instability_q6_a5":"持续",
  "patient_pedia_knee_instability_q7":"跳跃",
  "patient_pedia_knee_instability_q7_do_not_do":"不做",
  "patient_pedia_knee_instability_q7_a1":"不曾",
  "patient_pedia_knee_instability_q7_a2":"偶尔",
  "patient_pedia_knee_instability_q7_a3":"有时候",
  "patient_pedia_knee_instability_q7_a4":"经常",
  "patient_pedia_knee_instability_q7_a5":"持续",
  "patient_pedia_knee_instability_q8":"在平坦的表面上直线奔跑/跑步",
  "patient_pedia_knee_instability_q8_do_not_do":"不做",
  "patient_pedia_knee_instability_q8_a1":"不曾",
  "patient_pedia_knee_instability_q8_a2":"偶尔",
  "patient_pedia_knee_instability_q8_a3":"有时候",
  "patient_pedia_knee_instability_q8_a4":"经常",
  "patient_pedia_knee_instability_q8_a5":"持续",
  "patient_pedia_knee_instability_q9":"下楼梯",
  "patient_pedia_knee_instability_q9_do_not_do":"不做",
  "patient_pedia_knee_instability_q9_a1":"不曾",
  "patient_pedia_knee_instability_q9_a2":"偶尔",
  "patient_pedia_knee_instability_q9_a3":"有时候",
  "patient_pedia_knee_instability_q9_a4":"经常",
  "patient_pedia_knee_instability_q9_a5":"持续",
  "patient_pedia_knee_instability_q10":"蹲下",
  "patient_pedia_knee_instability_q10_do_not_do":"不做",
  "patient_pedia_knee_instability_q10_a1":"不曾",
  "patient_pedia_knee_instability_q10_a2":"偶尔",
  "patient_pedia_knee_instability_q10_a3":"有时候",
  "patient_pedia_knee_instability_q10_a4":"经常",
  "patient_pedia_knee_instability_q10_a5":"持续",
  "patient_pedia_knee_instability_q11":"跪下",
  "patient_pedia_knee_instability_q11_do_not_do":"不做",
  "patient_pedia_knee_instability_q11_a1":"不曾",
  "patient_pedia_knee_instability_q11_a2":"偶尔",
  "patient_pedia_knee_instability_q11_a3":"有时候",
  "patient_pedia_knee_instability_q11_a4":"经常",
  "patient_pedia_knee_instability_q11_a5":"持续",
  "patient_pedia_knee_instability_q12":"在不平坦的表面上直线步行",
  "patient_pedia_knee_instability_q12_do_not_do":"不做",
  "patient_pedia_knee_instability_q12_a1":"不曾",
  "patient_pedia_knee_instability_q12_a2":"偶尔",
  "patient_pedia_knee_instability_q12_a3":"有时候",
  "patient_pedia_knee_instability_q12_a4":"经常",
  "patient_pedia_knee_instability_q12_a5":"持续",
  "patient_pedia_knee_instability_q13":"上楼梯",
  "patient_pedia_knee_instability_q13_do_not_do":"不做",
  "patient_pedia_knee_instability_q13_a1":"不曾",
  "patient_pedia_knee_instability_q13_a2":"偶尔",
  "patient_pedia_knee_instability_q13_a3":"有时候",
  "patient_pedia_knee_instability_q13_a4":"经常",
  "patient_pedia_knee_instability_q13_a5":"持续",
  "patient_pedia_knee_instability_q14":"踏上或跨过高台阶",
  "patient_pedia_knee_instability_q14_do_not_do":"不做",
  "patient_pedia_knee_instability_q14_a1":"不曾",
  "patient_pedia_knee_instability_q14_a2":"偶尔",
  "patient_pedia_knee_instability_q14_a3":"有时候",
  "patient_pedia_knee_instability_q14_a4":"经常",
  "patient_pedia_knee_instability_q14_a5":"持续",
  "patient_pedia_knee_instability_q15":"坐着时交叉双腿",
  "patient_pedia_knee_instability_q15_do_not_do":"不做",
  "patient_pedia_knee_instability_q15_a1":"不曾",
  "patient_pedia_knee_instability_q15_a2":"偶尔",
  "patient_pedia_knee_instability_q15_a3":"有时候",
  "patient_pedia_knee_instability_q15_a4":"经常",
  "patient_pedia_knee_instability_q15_a5":"持续",
  "patient_pedia_knee_instability_q16":"在平坦的表面上直线步行",
  "patient_pedia_knee_instability_q16_do_not_do":"不做",
  "patient_pedia_knee_instability_q16_a1":"不曾",
  "patient_pedia_knee_instability_q16_a2":"偶尔",
  "patient_pedia_knee_instability_q16_a3":"有时候",
  "patient_pedia_knee_instability_q16_a4":"经常",
  "patient_pedia_knee_instability_q16_a5":"持续",
  "patient_pedia_knee_instability_q17":"进出车",
  "patient_pedia_knee_instability_q17_do_not_do":"不做",
  "patient_pedia_knee_instability_q17_a1":"不曾",
  "patient_pedia_knee_instability_q17_a2":"偶尔",
  "patient_pedia_knee_instability_q17_a3":"有时候",
  "patient_pedia_knee_instability_q17_a4":"经常",
  "patient_pedia_knee_instability_q17_a5":"持续",
  "patient_pedia_knee_instability_q18":"在超市过道上转动重型手推车",
  "patient_pedia_knee_instability_q18_do_not_do":"不做",
  "patient_pedia_knee_instability_q18_a1":"不曾",
  "patient_pedia_knee_instability_q18_a2":"偶尔",
  "patient_pedia_knee_instability_q18_a3":"有时候",
  "patient_pedia_knee_instability_q18_a4":"经常",
  "patient_pedia_knee_instability_q18_a5":"持续",
  "patient_pedia_knee_instability_q19":"转向过肩膀方向看望",
  "patient_pedia_knee_instability_q19_do_not_do":"不做",
  "patient_pedia_knee_instability_q19_a1":"不曾",
  "patient_pedia_knee_instability_q19_a2":"偶尔",
  "patient_pedia_knee_instability_q19_a3":"有时候",
  "patient_pedia_knee_instability_q19_a4":"经常",
  "patient_pedia_knee_instability_q19_a5":"持续",
  "patient_pedia_knee_quality_of_life":"生活质量",
  "patient_pedia_knee_quality_of_life_instruction":"您的受伤对您的生活有何影响？",
  "patient_pedia_knee_quality_of_life_q1":"您多常烦思您的膝盖问题？",
  "patient_pedia_knee_quality_of_life_q1_a1":"不曾",
  "patient_pedia_knee_quality_of_life_q1_a2":"偶尔",
  "patient_pedia_knee_quality_of_life_q1_a3":"有时候",
  "patient_pedia_knee_quality_of_life_q1_a4":"经常",
  "patient_pedia_knee_quality_of_life_q1_a5":"不断的",
  "patient_pedia_knee_problem_q2":"由于膝盖受伤，您的生活方式改变了多少？",
  "patient_pedia_knee_problem_q2_a1":"没有改变",
  "patient_pedia_knee_problem_q2_a2":"一点点",
  "patient_pedia_knee_problem_q2_a3":"一些",
  "patient_pedia_knee_problem_q2_a4":"很多",
  "patient_pedia_knee_problem_q2_a5":"太多",
  "patient_pedia_knee_problem_q3":"您对受伤的膝盖有多信任？",
  "patient_pedia_knee_problem_q3_a1":"完全",
  "patient_pedia_knee_problem_q3_a2":"很多",
  "patient_pedia_knee_problem_q3_a3":"一些",
  "patient_pedia_knee_problem_q3_a4":"一点点",
  "patient_pedia_knee_problem_q3_a5":"不信任",
  "patient_pedia_knee_problem_q4":"总的来说，您受伤的膝盖对您有多少困难？",
  "patient_pedia_knee_problem_q4_a1":"没有困难",
  "patient_pedia_knee_problem_q4_a2":"一点点",
  "patient_pedia_knee_problem_q4_a3":"一些",
  "patient_pedia_knee_problem_q4_a4":"很多",
  "patient_pedia_knee_problem_q4_a5":"极度困难",
  "patient_pedia_knee_satisfaction":"满意感",
  "patient_pedia_knee_satisfaction_instruction":"您对膝盖手术/治疗的结果满意吗？",
  "patient_pedia_knee_satisfaction_instruction_a1":"很不满意",
  "patient_pedia_knee_satisfaction_instruction_a2":"有些不满意",
  "patient_pedia_knee_satisfaction_instruction_a3":"有些满意",
  "patient_pedia_knee_satisfaction_instruction_a4":"很满意",
  "patient_pedia_knee_satisfaction_q1":"总体",
  "patient_pedia_knee_satisfaction_q1_NA":"不适用",
  "patient_pedia_knee_satisfaction_q1_a1":"很不满意",
  "patient_pedia_knee_satisfaction_q1_a2":"有些不满意",
  "patient_pedia_knee_satisfaction_q1_a3":"有些满意",
  "patient_pedia_knee_satisfaction_q1_a4":"很满意",
  "patient_pedia_knee_satisfaction_q2":"来改善您的疼痛？",
  "patient_pedia_knee_satisfaction_q2_NA":"不适用",
  "patient_pedia_knee_satisfaction_q2_a1":"很不满意",
  "patient_pedia_knee_satisfaction_q2_a2":"有些不满意",
  "patient_pedia_knee_satisfaction_q2_a3":"有些满意",
  "patient_pedia_knee_satisfaction_q2_a4":"很满意",
  "patient_pedia_knee_satisfaction_q3":"为了提高您做家务或庭院工作的能力？",
  "patient_pedia_knee_satisfaction_q3_NA":"不适用",
  "patient_pedia_knee_satisfaction_q3_a1":"很不满意",
  "patient_pedia_knee_satisfaction_q3_a2":"有些不满意",
  "patient_pedia_knee_satisfaction_q3_a3":"有些满意",
  "patient_pedia_knee_satisfaction_q3_a4":"很满意",
  "patient_pedia_knee_satisfaction_q4":"为了提高您进行娱乐活动的能力？",
  "patient_pedia_knee_satisfaction_q4_NA":"不适用",
  "patient_pedia_knee_satisfaction_q4_a1":"很不满意",
  "patient_pedia_knee_satisfaction_q4_a2":"有些不满意",
  "patient_pedia_knee_satisfaction_q4_a3":"有些满意",
  "patient_pedia_knee_satisfaction_q4_a4":"很满意",
  "patient_pedia_knee_complete":"问卷结束",
  "patient_pedia_knee_complete_text1":"感谢您抽出宝贵时间填写此表格。",
  "patient_pedia_knee_complete_text2":"请点击提交完成并查看您的分数。",
  "patient_pedia_knee_complete_text3":"您的回答将被保密，任何报告都将仅使用去识别化的咨讯。",
  "patient_pedia_knee_results":"结果",
  "patient_pedia_knee_results_text1":"以下是您的疼痛和膝关节功能评分，这些评分是根据您的调查问卷计算得出的。",
  "patient_pedia_knee_results_pain":"疼痛",
  "patient_pedia_knee_results_function":"功能",
  "patient_pedia_knee_results_self_evaluation":"膝盖评分自我评估",
  "patient_pedia_knee_results_text2":"您的医生很快就会与您联系",
  "patient_foot_ankle_pain":"疼痛",
  "patient_foot_ankle_pain_q1":"您的脚/脚踝目前的疼痛程度如何？",
  "patient_foot_ankle_pain_q1_0":"否",
  "patient_foot_ankle_pain_q1_1":"可以想象到的最严重的疼痛",
  "patient_foot_ankle_pain_q2":"对您的脚/脚踝疼痛的最佳描述是什么？",
  "patient_foot_ankle_pain_q2_a1":"否",
  "patient_foot_ankle_pain_q2_a2":"轻微，偶尔",
  "patient_foot_ankle_pain_q2_a3":"中度，天天",
  "patient_foot_ankle_pain_q2_a4":"严重，大多时都有",
  "patient_foot_ankle_pain_q3":"您多常感到脚/脚踝疼痛？",
  "patient_foot_ankle_pain_q3_a1":"不曾",
  "patient_foot_ankle_pain_q3_a2":"每月",
  "patient_foot_ankle_pain_q3_a3":"每周",
  "patient_foot_ankle_pain_q3_a4":"每天",
  "patient_foot_ankle_pain_q3_a5":"持续",
  "patient_foot_ankle_pain_instruction":"您在以下活动中经历过多少程度的脚部/踝部疼痛：",
  "patient_foot_ankle_pain_instruction_0":"否",
  "patient_foot_ankle_pain_instruction_1":"轻微",
  "patient_foot_ankle_pain_instruction_2":"中度",
  "patient_foot_ankle_pain_instruction_3":"严重",
  "patient_foot_ankle_pain_instruction_4":"极度",
  "patient_foot_ankle_pain_q4":"一般疼痛程度",
  "patient_foot_ankle_pain_q4_a1":"否",
  "patient_foot_ankle_pain_q4_a2":"轻微",
  "patient_foot_ankle_pain_q4_a3":"中度",
  "patient_foot_ankle_pain_q4_a4":"严重",
  "patient_foot_ankle_pain_q4_a5":"极度",
  "patient_foot_ankle_pain_q5":"休息时",
  "patient_foot_ankle_pain_q5_a1":"否",
  "patient_foot_ankle_pain_q5_a2":"轻微",
  "patient_foot_ankle_pain_q5_a3":"中度",
  "patient_foot_ankle_pain_q5_a4":"严重",
  "patient_foot_ankle_pain_q5_a5":"极度",
  "patient_foot_ankle_pain_q6":"正常活动",
  "patient_foot_ankle_pain_q6_a1":"否",
  "patient_foot_ankle_pain_q6_a2":"轻微",
  "patient_foot_ankle_pain_q6_a3":"中度",
  "patient_foot_ankle_pain_q6_a4":"严重",
  "patient_foot_ankle_pain_q6_a5":"极度",
  "patient_foot_ankle_pain_q7":"早晨第一件事",
  "patient_foot_ankle_pain_q7_a1":"否",
  "patient_foot_ankle_pain_q7_a2":"轻微",
  "patient_foot_ankle_pain_q7_a3":"中度",
  "patient_foot_ankle_pain_q7_a4":"严重",
  "patient_foot_ankle_pain_q7_a5":"极度",
  "patient_foot_ankle_pain_q8":"扭转/旋转脚/脚踝",
  "patient_foot_ankle_pain_q8_a1":"否",
  "patient_foot_ankle_pain_q8_a2":"轻微",
  "patient_foot_ankle_pain_q8_a3":"中度",
  "patient_foot_ankle_pain_q8_a4":"严重",
  "patient_foot_ankle_pain_q8_a5":"极度",
  "patient_foot_ankle_pain_q9":"把脚/脚踝伸直",
  "patient_foot_ankle_pain_q9_a1":"否",
  "patient_foot_ankle_pain_q9_a2":"轻微",
  "patient_foot_ankle_pain_q9_a3":"中度",
  "patient_foot_ankle_pain_q9_a4":"严重",
  "patient_foot_ankle_pain_q9_a5":"极度",
  "patient_foot_ankle_pain_q10":"完全屈足/脚踝",
  "patient_foot_ankle_pain_q10_a1":"否",
  "patient_foot_ankle_pain_q10_a2":"轻微",
  "patient_foot_ankle_pain_q10_a3":"中度",
  "patient_foot_ankle_pain_q10_a4":"严重",
  "patient_foot_ankle_pain_q10_a5":"极度",
  "patient_foot_ankle_pain_q11":"在平坦的表面步行",
  "patient_foot_ankle_pain_q11_a1":"否",
  "patient_foot_ankle_pain_q11_a2":"轻微",
  "patient_foot_ankle_pain_q11_a3":"中度",
  "patient_foot_ankle_pain_q11_a4":"严重",
  "patient_foot_ankle_pain_q11_a5":"极度",
  "patient_foot_ankle_pain_q12":"上下楼梯",
  "patient_foot_ankle_pain_q12_a1":"否",
  "patient_foot_ankle_pain_q12_a2":"轻微",
  "patient_foot_ankle_pain_q12_a3":"中度",
  "patient_foot_ankle_pain_q12_a4":"严重",
  "patient_foot_ankle_pain_q12_a5":"极度",
  "patient_foot_ankle_pain_q13":"晚间在床上",
  "patient_foot_ankle_pain_q13_a1":"否",
  "patient_foot_ankle_pain_q13_a2":"轻微",
  "patient_foot_ankle_pain_q13_a3":"中度",
  "patient_foot_ankle_pain_q13_a4":"严重",
  "patient_foot_ankle_pain_q13_a5":"极度",
  "patient_foot_ankle_pain_q14":"坐下或躺着",
  "patient_foot_ankle_pain_q14_a1":"否",
  "patient_foot_ankle_pain_q14_a2":"轻微",
  "patient_foot_ankle_pain_q14_a3":"中度",
  "patient_foot_ankle_pain_q14_a4":"严重",
  "patient_foot_ankle_pain_q14_a5":"极度",
  "patient_foot_ankle_pain_q15":"站立",
  "patient_foot_ankle_pain_q15_a1":"否",
  "patient_foot_ankle_pain_q15_a2":"轻微",
  "patient_foot_ankle_pain_q15_a3":"中度",
  "patient_foot_ankle_pain_q15_a4":"严重",
  "patient_foot_ankle_pain_q15_a5":"极度",
  "patient_foot_ankle_instability":"不稳定",
  "patient_foot_ankle_instability_q1":"您是否经历过反覆扭伤、脚踝撑不住，或感觉脚踝即将撑不住？",
  "patient_foot_ankle_instability_q1_a1":"否",
  "patient_foot_ankle_instability_q1_a2":"是",
  "patient_foot_ankle_instability_q2":"您是否曾被诊断为脚踝不稳/反覆扭伤/重复接受治疗？",
  "patient_foot_ankle_instability_q2_a1":"否",
  "patient_foot_ankle_instability_q2_a2":"是",
  "patient_foot_ankle_instability_q3":"您未受影响的脚踝是否感觉完全稳定？",
  "patient_foot_ankle_instability_q3_a1":"否",
  "patient_foot_ankle_instability_q3_a2":"是",
  "patient_foot_ankle_cumberland":"CUMBERLAND 踝关节不稳定指数",
  "patient_foot_ankle_cumberland_instruction":"请在每个问题中选择最能描述您脚踝的一项陈述。",
  "patient_foot_ankle_cumberland_q1":"我的脚踝有疼痛",
  "patient_foot_ankle_cumberland_q1_a1":"不曾",
  "patient_foot_ankle_cumberland_q1_a2":"做运动时",
  "patient_foot_ankle_cumberland_q1_a3":"不平坦的表面跑步",
  "patient_foot_ankle_cumberland_q1_a4":"平坦的表面跑步",
  "patient_foot_ankle_cumberland_q1_a5":"不平坦的表面步行",
  "patient_foot_ankle_cumberland_q1_a6":"平坦的表面步行",
  "patient_foot_ankle_cumberland_q2":"我的脚踝感觉不稳定",
  "patient_foot_ankle_cumberland_q2_a1":"不曾",
  "patient_foot_ankle_cumberland_q2_a2":"运动中有时候（不是每一次）",
  "patient_foot_ankle_cumberland_q2_a3":"运动中经常（每一次）",
  "patient_foot_ankle_cumberland_q2_a4":"日常活动中有时候",
  "patient_foot_ankle_cumberland_q2_a5":"日常活动中经常",
  "patient_foot_ankle_cumberland_q3":"当我急转弯时，我的脚踝感觉不稳定",
  "patient_foot_ankle_cumberland_q3_a1":"不曾",
  "patient_foot_ankle_cumberland_q3_a2":"有时候跑步时",
  "patient_foot_ankle_cumberland_q3_a3":"经常跑步时",
  "patient_foot_ankle_cumberland_q3_a4":"步行时",
  "patient_foot_ankle_cumberland_q4":"下楼梯时，我的脚踝感觉不稳定",
  "patient_foot_ankle_cumberland_q4_a1":"不曾",
  "patient_foot_ankle_cumberland_q4_a2":"如果我走得快的话",
  "patient_foot_ankle_cumberland_q4_a3":"偶尔",
  "patient_foot_ankle_cumberland_q4_a4":"经常",
  "patient_foot_ankle_cumberland_q5":"我在单腿站立时，脚踝感觉不稳定",
  "patient_foot_ankle_cumberland_q5_a1":"不曾",
  "patient_foot_ankle_cumberland_q5_a2":"在脚掌上",
  "patient_foot_ankle_cumberland_q5_a3":"在脚平放",
  "patient_foot_ankle_cumberland_q6":"在以下的什么时刻，我的脚踝感觉不稳定？",
  "patient_foot_ankle_cumberland_q6_a1":"不曾",
  "patient_foot_ankle_cumberland_q6_a2":"往侧边跳",
  "patient_foot_ankle_cumberland_q6_a3":"在原地跳",
  "patient_foot_ankle_cumberland_q6_a4":"小跳跃",
  "patient_foot_ankle_cumberland_q7":"在以下的什么时刻，我的脚踝感觉不稳定？",
  "patient_foot_ankle_cumberland_q7_a1":"不曾",
  "patient_foot_ankle_cumberland_q7_a2":"我在不平坦的表面奔跑",
  "patient_foot_ankle_cumberland_q7_a3":"我在不平坦的表面跑步",
  "patient_foot_ankle_cumberland_q7_a4":"我在不平坦的表面步行",
  "patient_foot_ankle_cumberland_q7_a5":"我在平坦的表面步行",
  "patient_foot_ankle_cumberland_q8":"通常，当我的脚踝开始折到（或扭到）时，我可以阻止它",
  "patient_foot_ankle_cumberland_q8_a1":"立刻",
  "patient_foot_ankle_cumberland_q8_a2":"经常",
  "patient_foot_ankle_cumberland_q8_a3":"有时候",
  "patient_foot_ankle_cumberland_q8_a4":"不曾",
  "patient_foot_ankle_cumberland_q8_a5":"我的脚踝不曾扭伤过",
  "patient_foot_ankle_cumberland_q9":"在我的脚踝发生一次典型的翻滚事件后，我的脚踝恢复了“正常”",
  "patient_foot_ankle_cumberland_q9_a1":"几乎是立刻",
  "patient_foot_ankle_cumberland_q9_a2":"少过一日天",
  "patient_foot_ankle_cumberland_q9_a3":"一至两天",
  "patient_foot_ankle_cumberland_q9_a4":"超过两天",
  "patient_foot_ankle_cumberland_q9_a5":"我的脚踝不曾扭伤过",
  "patient_foot_ankle_other_symptoms":"其他症状",
  "patient_foot_ankle_other_symptoms_instruction1":"请选择最符合您的脚/脚踝症状的答案。",
  "patient_foot_ankle_other_symptoms_instruction2":"如果您不确定如何回答问题，请尽可能给出最佳答案。",
  "patient_foot_ankle_other_symptoms_note":"注意：回答这些问题时应考虑您上周的足部/踝部症状。",
  "patient_foot_ankle_other_symptoms_instruction3":"您是否经验以下：",
  "patient_foot_ankle_other_symptoms_instruction3_0":"不曾",
  "patient_foot_ankle_other_symptoms_instruction3_1":"偶尔",
  "patient_foot_ankle_other_symptoms_instruction3_2":"有时候",
  "patient_foot_ankle_other_symptoms_instruction3_3":"经常",
  "patient_foot_ankle_other_symptoms_instruction3_4":"持续",
  "patient_foot_ankle_other_symptoms_q1":"脚/脚踝肿胀",
  "patient_foot_ankle_other_symptoms_q1_a1":"不曾",
  "patient_foot_ankle_other_symptoms_q1_a2":"偶尔",
  "patient_foot_ankle_other_symptoms_q1_a3":"有时候",
  "patient_foot_ankle_other_symptoms_q1_a4":"经常",
  "patient_foot_ankle_other_symptoms_q1_a5":"持续",
  "patient_foot_ankle_other_symptoms_q2":"脚/脚踝移动时发出摩擦声、咔哒声或听到任何类型的噪音",
  "patient_foot_ankle_other_symptoms_q2_a1":"不曾",
  "patient_foot_ankle_other_symptoms_q2_a2":"偶尔",
  "patient_foot_ankle_other_symptoms_q2_a3":"有时候",
  "patient_foot_ankle_other_symptoms_q2_a4":"经常",
  "patient_foot_ankle_other_symptoms_q2_a5":"持续",
  "patient_foot_ankle_other_symptoms_q3":"移动时抓住",
  "patient_foot_ankle_other_symptoms_q3_a1":"不曾",
  "patient_foot_ankle_other_symptoms_q3_a2":"偶尔",
  "patient_foot_ankle_other_symptoms_q3_a3":"有时候",
  "patient_foot_ankle_other_symptoms_q3_a4":"经常",
  "patient_foot_ankle_other_symptoms_q3_a5":"持续",
  "patient_foot_ankle_other_symptoms_instruction4":"您的脚/脚踝僵硬程度有多严重：",
  "patient_foot_ankle_other_symptoms_instruction4_0":"否",
  "patient_foot_ankle_other_symptoms_instruction4_1":"轻微",
  "patient_foot_ankle_other_symptoms_instruction4_2":"中度",
  "patient_foot_ankle_other_symptoms_instruction4_3":"严重",
  "patient_foot_ankle_other_symptoms_instruction4_4":"极度",
  "patient_foot_ankle_other_symptoms_q4":"早上第一次醒来后",
  "patient_foot_ankle_other_symptoms_q4_a1":"否",
  "patient_foot_ankle_other_symptoms_q4_a2":"轻微",
  "patient_foot_ankle_other_symptoms_q4_a3":"中度",
  "patient_foot_ankle_other_symptoms_q4_a4":"严重",
  "patient_foot_ankle_other_symptoms_q4_a5":"极度",
  "patient_foot_ankle_other_symptoms_q5":"当天晚些时候坐着、躺着或休息后",
  "patient_foot_ankle_other_symptoms_q5_a1":"否",
  "patient_foot_ankle_other_symptoms_q5_a2":"轻微",
  "patient_foot_ankle_other_symptoms_q5_a3":"中度",
  "patient_foot_ankle_other_symptoms_q5_a4":"严重",
  "patient_foot_ankle_other_symptoms_q5_a5":"极度",
  "patient_foot_ankle_function":"功能",
  "patient_foot_ankle_function_q1":"由于您的脚/脚踝，您在日常生活中的活动有哪些限制？",
  "patient_foot_ankle_function_q1_a1":"没有限制，没有支持",
  "patient_foot_ankle_function_q1_a2":"日常活动不受限，娱乐活动受限，无支持",
  "patient_foot_ankle_function_q1_a3":"有限的日常和娱乐活动，手杖",
  "patient_foot_ankle_function_q1_a4":"日常和娱乐活动严重受限，需使用助行器、拐杖、轮椅、支具",
  "patient_foot_ankle_function_q2":"你通常穿什么鞋？",
  "patient_foot_ankle_function_q2_a1":"时尚、传统的鞋子，无需鞋垫",
  "patient_foot_ankle_function_q2_a2":"舒适鞋、鞋垫",
  "patient_foot_ankle_function_q2_a3":"改良的鞋子或护具",
  "patient_foot_ankle_function_q3":"什么样的步行表面会让您行走困难？",
  "patient_foot_ankle_function_q3_a1":"各种表面都没有问题",
  "patient_foot_ankle_function_q3_a2":"在不平坦的地形、楼梯、斜坡、梯子上有一些困难",
  "patient_foot_ankle_function_q3_a3":"在不平坦的地形、楼梯、斜坡、梯子上有严重困难",
  "patient_foot_ankle_function_instruction":"请指出您上周因脚/脚踝而经历的困难程度",
  "patient_foot_ankle_function_instruction_0":"没有困难",
  "patient_foot_ankle_function_instruction_1":"一些困难",
  "patient_foot_ankle_function_instruction_2":"中度困难",
  "patient_foot_ankle_function_instruction_3":"极度困难",
  "patient_foot_ankle_function_instruction_4":"无法办到",
  "patient_foot_ankle_function_note":"注意：如果相关活动受到脚或脚踝以外的其他因素的限制，请在可以选择的项目中标记“不适用”。 否则，请用最能描述您在过去一周内的状况的答案来回答每个问题。",
  "patient_foot_ankle_function_q4":"把脚/脚踝伸直",
  "patient_foot_ankle_function_q4_a1":"没有困难",
  "patient_foot_ankle_function_q4_a2":"一些困难",
  "patient_foot_ankle_function_q4_a3":"中度困难",
  "patient_foot_ankle_function_q4_a4":"极度困难",
  "patient_foot_ankle_function_q4_a5":"无法办到",
  "patient_foot_ankle_function_q5":"完全屈脚/脚踝",
  "patient_foot_ankle_function_q5_a1":"没有困难",
  "patient_foot_ankle_function_q5_a2":"一些困难",
  "patient_foot_ankle_function_q5_a3":"中度困难",
  "patient_foot_ankle_function_q5_a4":"极度困难",
  "patient_foot_ankle_function_q5_a5":"无法办到",
  "patient_foot_ankle_function_q6":"下楼梯",
  "patient_foot_ankle_function_q6_a1":"没有困难",
  "patient_foot_ankle_function_q6_a2":"一些困难",
  "patient_foot_ankle_function_q6_a3":"中度困难",
  "patient_foot_ankle_function_q6_a4":"极度困难",
  "patient_foot_ankle_function_q6_a5":"无法办到",
  "patient_foot_ankle_function_q7":"上楼梯",
  "patient_foot_ankle_function_q7_a1":"没有困难",
  "patient_foot_ankle_function_q7_a2":"一些困难",
  "patient_foot_ankle_function_q7_a3":"中度困难",
  "patient_foot_ankle_function_q7_a4":"极度困难",
  "patient_foot_ankle_function_q7_a5":"无法办到",
  "patient_foot_ankle_function_q8":"从坐着站起来",
  "patient_foot_ankle_function_q8_a1":"没有困难",
  "patient_foot_ankle_function_q8_a2":"一些困难",
  "patient_foot_ankle_function_q8_a3":"中度困难",
  "patient_foot_ankle_function_q8_a4":"极度困难",
  "patient_foot_ankle_function_q8_a5":"无法办到",
  "patient_foot_ankle_function_q9":"站立",
  "patient_foot_ankle_function_q9_a1":"没有困难",
  "patient_foot_ankle_function_q9_a2":"一些困难",
  "patient_foot_ankle_function_q9_a3":"中度困难",
  "patient_foot_ankle_function_q9_a4":"极度困难",
  "patient_foot_ankle_function_q9_a5":"无法办到",
  "patient_foot_ankle_function_q10":"弯腰/捡起物体",
  "patient_foot_ankle_function_q10_a1":"没有困难",
  "patient_foot_ankle_function_q10_a2":"一些困难",
  "patient_foot_ankle_function_q10_a3":"中度困难",
  "patient_foot_ankle_function_q10_a4":"极度困难",
  "patient_foot_ankle_function_q10_a5":"无法办到",
  "patient_foot_ankle_function_q11":"在平面步行",
  "patient_foot_ankle_function_q11_a1":"没有困难",
  "patient_foot_ankle_function_q11_a2":"一些困难",
  "patient_foot_ankle_function_q11_a3":"中度困难",
  "patient_foot_ankle_function_q11_a4":"极度困难",
  "patient_foot_ankle_function_q11_a5":"无法办到",
  "patient_foot_ankle_function_q12":"您可以步行的最大距离是多少？",
  "patient_foot_ankle_function_q12_a1":"大于六城市街区距离",
  "patient_foot_ankle_function_q12_a2":"四至六城市街区距离",
  "patient_foot_ankle_function_q12_a3":"一至三个城市街区距离",
  "patient_foot_ankle_function_q12_a4":"少过一个城市街区距离",
  "patient_foot_ankle_function_q13":"在不平坦的表面步行 （不穿鞋）",
  "patient_foot_ankle_function_q13_a1":"没有困难",
  "patient_foot_ankle_function_q13_a2":"一些困难",
  "patient_foot_ankle_function_q13_a3":"中度困难",
  "patient_foot_ankle_function_q13_a4":"极度困难",
  "patient_foot_ankle_function_q13_a5":"无法办到",
  "patient_foot_ankle_function_q14":"走上山坡",
  "patient_foot_ankle_function_q14_a1":"没有困难",
  "patient_foot_ankle_function_q14_a2":"一些困难",
  "patient_foot_ankle_function_q14_a3":"中度困难",
  "patient_foot_ankle_function_q14_a4":"极度困难",
  "patient_foot_ankle_function_q14_a5":"无法办到",
  "patient_foot_ankle_function_q15":"走下山坡",
  "patient_foot_ankle_function_q15_a1":"没有困难",
  "patient_foot_ankle_function_q15_a2":"一些困难",
  "patient_foot_ankle_function_q15_a3":"中度困难",
  "patient_foot_ankle_function_q15_a4":"极度困难",
  "patient_foot_ankle_function_q15_a5":"无法办到",
  "patient_foot_ankle_function_q16":"不平坦表面步行",
  "patient_foot_ankle_function_q16_a1":"没有困难",
  "patient_foot_ankle_function_q16_a2":"一些困难",
  "patient_foot_ankle_function_q16_a3":"中度困难",
  "patient_foot_ankle_function_q16_a4":"极度困难",
  "patient_foot_ankle_function_q16_a5":"无法办到",
  "patient_foot_ankle_function_q17":"走上下路边",
  "patient_foot_ankle_function_q17_a1":"没有困难",
  "patient_foot_ankle_function_q17_a2":"一些困难",
  "patient_foot_ankle_function_q17_a3":"中度困难",
  "patient_foot_ankle_function_q17_a4":"极度困难",
  "patient_foot_ankle_function_q17_a5":"无法办到",
  "patient_foot_ankle_function_q18":"踮起脚尖",
  "patient_foot_ankle_function_q18_a1":"没有困难",
  "patient_foot_ankle_function_q18_a2":"一些困难",
  "patient_foot_ankle_function_q18_a3":"中度困难",
  "patient_foot_ankle_function_q18_a4":"极度困难",
  "patient_foot_ankle_function_q18_a5":"无法办到",
  "patient_foot_ankle_function_q19":"起头步行",
  "patient_foot_ankle_function_q19_a1":"没有困难",
  "patient_foot_ankle_function_q19_a2":"一些困难",
  "patient_foot_ankle_function_q19_a3":"中度困难",
  "patient_foot_ankle_function_q19_a4":"极度困难",
  "patient_foot_ankle_function_q19_a5":"无法办到",
  "patient_foot_ankle_function_q20":"步行五分钟时间或少于五分钟",
  "patient_foot_ankle_function_q20_a1":"没有困难",
  "patient_foot_ankle_function_q20_a2":"一些困难",
  "patient_foot_ankle_function_q20_a3":"中度困难",
  "patient_foot_ankle_function_q20_a4":"极度困难",
  "patient_foot_ankle_function_q20_a5":"无法办到",
  "patient_foot_ankle_function_q21":"步行大约十分钟",
  "patient_foot_ankle_function_q21_a1":"没有困难",
  "patient_foot_ankle_function_q21_a2":"一些困难",
  "patient_foot_ankle_function_q21_a3":"中度困难",
  "patient_foot_ankle_function_q21_a4":"极度困难",
  "patient_foot_ankle_function_q21_a5":"无法办到",
  "patient_foot_ankle_function_q22":"步行超越十五分钟",
  "patient_foot_ankle_function_q22_a1":"没有困难",
  "patient_foot_ankle_function_q22_a2":"一些困难",
  "patient_foot_ankle_function_q22_a3":"中度困难",
  "patient_foot_ankle_function_q22_a4":"极度困难",
  "patient_foot_ankle_function_q22_a5":"无法办到",
  "patient_foot_ankle_function_q23":"进出车子",
  "patient_foot_ankle_function_q23_a1":"没有困难",
  "patient_foot_ankle_function_q23_a2":"一些困难",
  "patient_foot_ankle_function_q23_a3":"中度困难",
  "patient_foot_ankle_function_q23_a4":"极度困难",
  "patient_foot_ankle_function_q23_a5":"无法办到",
  "patient_foot_ankle_function_q24":"去购物",
  "patient_foot_ankle_function_q24_a1":"没有困难",
  "patient_foot_ankle_function_q24_a2":"一些困难",
  "patient_foot_ankle_function_q24_a3":"中度困难",
  "patient_foot_ankle_function_q24_a4":"极度困难",
  "patient_foot_ankle_function_q24_a5":"无法办到",
  "patient_foot_ankle_function_q25":"穿上袜子/丝袜",
  "patient_foot_ankle_function_q25_a1":"没有困难",
  "patient_foot_ankle_function_q25_a2":"一些困难",
  "patient_foot_ankle_function_q25_a3":"中度困难",
  "patient_foot_ankle_function_q25_a4":"极度困难",
  "patient_foot_ankle_function_q25_a5":"无法办到",
  "patient_foot_ankle_function_q26":"脱下袜子/丝袜",
  "patient_foot_ankle_function_q26_a1":"没有困难",
  "patient_foot_ankle_function_q26_a2":"一些困难",
  "patient_foot_ankle_function_q26_a3":"中度困难",
  "patient_foot_ankle_function_q26_a4":"极度困难",
  "patient_foot_ankle_function_q26_a5":"无法办到",
  "patient_foot_ankle_function_q27":"从床上起身",
  "patient_foot_ankle_function_q27_a1":"没有困难",
  "patient_foot_ankle_function_q27_a2":"一些困难",
  "patient_foot_ankle_function_q27_a3":"中度困难",
  "patient_foot_ankle_function_q27_a4":"极度困难",
  "patient_foot_ankle_function_q27_a5":"无法办到",
  "patient_foot_ankle_function_q28":"躺在床上 （翻身，保持脚/脚踝位置）",
  "patient_foot_ankle_function_q28_a1":"没有困难",
  "patient_foot_ankle_function_q28_a2":"一些困难",
  "patient_foot_ankle_function_q28_a3":"中度困难",
  "patient_foot_ankle_function_q28_a4":"极度困难",
  "patient_foot_ankle_function_q28_a5":"无法办到",
  "patient_foot_ankle_function_q29":"睡觉",
  "patient_foot_ankle_function_q29_a1":"没有困难",
  "patient_foot_ankle_function_q29_a2":"一些困难",
  "patient_foot_ankle_function_q29_a3":"中度困难",
  "patient_foot_ankle_function_q29_a4":"极度困难",
  "patient_foot_ankle_function_q29_a5":"无法办到",
  "patient_foot_ankle_function_q30":"进出浴缸/浴室",
  "patient_foot_ankle_function_q30_a1":"没有困难",
  "patient_foot_ankle_function_q30_a2":"一些困难",
  "patient_foot_ankle_function_q30_a3":"中度困难",
  "patient_foot_ankle_function_q30_a4":"极度困难",
  "patient_foot_ankle_function_q30_a5":"无法办到",
  "patient_foot_ankle_function_q31":"坐下",
  "patient_foot_ankle_function_q31_a1":"没有困难",
  "patient_foot_ankle_function_q31_a2":"一些困难",
  "patient_foot_ankle_function_q31_a3":"中度困难",
  "patient_foot_ankle_function_q31_a4":"极度困难",
  "patient_foot_ankle_function_q31_a5":"无法办到",
  "patient_foot_ankle_function_q32":"上下马桶",
  "patient_foot_ankle_function_q32_a1":"没有困难",
  "patient_foot_ankle_function_q32_a2":"一些困难",
  "patient_foot_ankle_function_q32_a3":"中度困难",
  "patient_foot_ankle_function_q32_a4":"极度困难",
  "patient_foot_ankle_function_q32_a5":"无法办到",
  "patient_foot_ankle_function_q33":"做家务",
  "patient_foot_ankle_function_q33_a1":"没有困难",
  "patient_foot_ankle_function_q33_a2":"一些困难",
  "patient_foot_ankle_function_q33_a3":"中度困难",
  "patient_foot_ankle_function_q33_a4":"极度困难",
  "patient_foot_ankle_function_q33_a5":"无法办到",
  "patient_foot_ankle_function_q34":"日常活动",
  "patient_foot_ankle_function_q34_a1":"没有困难",
  "patient_foot_ankle_function_q34_a2":"一些困难",
  "patient_foot_ankle_function_q34_a3":"中度困难",
  "patient_foot_ankle_function_q34_a4":"极度困难",
  "patient_foot_ankle_function_q34_a5":"无法办到",
  "patient_foot_ankle_function_q35":"个人护理（包括自我清洗或穿衣）",
  "patient_foot_ankle_function_q35_a1":"没有困难",
  "patient_foot_ankle_function_q35_a2":"一些困难",
  "patient_foot_ankle_function_q35_a3":"中度困难",
  "patient_foot_ankle_function_q35_a4":"极度困难",
  "patient_foot_ankle_function_q35_a5":"无法办到",
  "patient_foot_ankle_function_q36":"繁重的工作（推/拉、攀爬、搬运、移动重箱、擦地板等）",
  "patient_foot_ankle_function_q36_a1":"没有困难",
  "patient_foot_ankle_function_q36_a2":"一些困难",
  "patient_foot_ankle_function_q36_a3":"中度困难",
  "patient_foot_ankle_function_q36_a4":"极度困难",
  "patient_foot_ankle_function_q36_a5":"无法办到",
  "patient_foot_ankle_function_q37":"轻微的家务劳动（做饭、除尘等）",
  "patient_foot_ankle_function_q37_a1":"没有困难",
  "patient_foot_ankle_function_q37_a2":"一些困难",
  "patient_foot_ankle_function_q37_a3":"中度困难",
  "patient_foot_ankle_function_q37_a4":"极度困难",
  "patient_foot_ankle_function_q37_a5":"无法办到",
  "patient_foot_ankle_function_q38":"轻度至中度工作（站立、行走）",
  "patient_foot_ankle_function_q38_a1":"没有困难",
  "patient_foot_ankle_function_q38_a2":"一些困难",
  "patient_foot_ankle_function_q38_a3":"中度困难",
  "patient_foot_ankle_function_q38_a4":"极度困难",
  "patient_foot_ankle_function_q38_a5":"无法办到",
  "patient_foot_ankle_function_q39":"悠闲活动",
  "patient_foot_ankle_function_q39_a1":"没有困难",
  "patient_foot_ankle_function_q39_a2":"一些困难",
  "patient_foot_ankle_function_q39_a3":"中度困难",
  "patient_foot_ankle_function_q39_a4":"极度困难",
  "patient_foot_ankle_function_q39_a5":"无法办到",
  "patient_foot_ankle_function_q40":"蹲下",
  "patient_foot_ankle_function_q40_a1":"没有困难",
  "patient_foot_ankle_function_q40_a2":"一些困难",
  "patient_foot_ankle_function_q40_a3":"中度困难",
  "patient_foot_ankle_function_q40_a4":"极度困难",
  "patient_foot_ankle_function_q40_a5":"无法办到",
  "patient_foot_ankle_function_q41":"扭转/旋转脚/脚踝",
  "patient_foot_ankle_function_q41_a1":"没有困难",
  "patient_foot_ankle_function_q41_a2":"一些困难",
  "patient_foot_ankle_function_q41_a3":"中度困难",
  "patient_foot_ankle_function_q41_a4":"极度困难",
  "patient_foot_ankle_function_q41_a5":"无法办到",
  "patient_foot_ankle_function_q42":"跪下",
  "patient_foot_ankle_function_q42_a1":"没有困难",
  "patient_foot_ankle_function_q42_a2":"一些困难",
  "patient_foot_ankle_function_q42_a3":"中度困难",
  "patient_foot_ankle_function_q42_a4":"极度困难",
  "patient_foot_ankle_function_q42_a5":"无法办到",
  "patient_foot_ankle_function_q43":"奔跑",
  "patient_foot_ankle_function_q43_not_applicable":"不适用",
  "patient_foot_ankle_function_q43_a1":"没有困难",
  "patient_foot_ankle_function_q43_a2":"一些困难",
  "patient_foot_ankle_function_q43_a3":"中度困难",
  "patient_foot_ankle_function_q43_a4":"极度困难",
  "patient_foot_ankle_function_q43_a5":"无法办到",
  "patient_foot_ankle_function_q44":"跳跃",
  "patient_foot_ankle_function_q44_not_applicable":"不适用",
  "patient_foot_ankle_function_q44_a1":"没有困难",
  "patient_foot_ankle_function_q44_a2":"一些困难",
  "patient_foot_ankle_function_q44_a3":"中度困难",
  "patient_foot_ankle_function_q44_a4":"极度困难",
  "patient_foot_ankle_function_q44_a5":"无法办到",
  "patient_foot_ankle_function_q45":"降落",
  "patient_foot_ankle_function_q45_not_applicable":"不适用",
  "patient_foot_ankle_function_q45_a1":"没有困难",
  "patient_foot_ankle_function_q45_a2":"一些困难",
  "patient_foot_ankle_function_q45_a3":"中度困难",
  "patient_foot_ankle_function_q45_a4":"极度困难",
  "patient_foot_ankle_function_q45_a5":"无法办到",
  "patient_foot_ankle_function_q46":"快速启动和停止",
  "patient_foot_ankle_function_q46_not_applicable":"不适用",
  "patient_foot_ankle_function_q46_a1":"没有困难",
  "patient_foot_ankle_function_q46_a2":"一些困难",
  "patient_foot_ankle_function_q46_a3":"中度困难",
  "patient_foot_ankle_function_q46_a4":"极度困难",
  "patient_foot_ankle_function_q46_a5":"无法办到",
  "patient_foot_ankle_function_q47":"切割/横向运动",
  "patient_foot_ankle_function_q47_not_applicable":"不适用",
  "patient_foot_ankle_function_q47_a1":"没有困难",
  "patient_foot_ankle_function_q47_a2":"一些困难",
  "patient_foot_ankle_function_q47_a3":"中度困难",
  "patient_foot_ankle_function_q47_a4":"极度困难",
  "patient_foot_ankle_function_q47_a5":"无法办到",
  "patient_foot_ankle_function_q48":"低冲击力活动",
  "patient_foot_ankle_function_q48_not_applicable":"不适用",
  "patient_foot_ankle_function_q48_a1":"没有困难",
  "patient_foot_ankle_function_q48_a2":"一些困难",
  "patient_foot_ankle_function_q48_a3":"中度困难",
  "patient_foot_ankle_function_q48_a4":"极度困难",
  "patient_foot_ankle_function_q48_a5":"无法办到",
  "patient_foot_ankle_function_q49":"能够以正常能力进行活动",
  "patient_foot_ankle_function_q49_not_applicable":"不适用",
  "patient_foot_ankle_function_q49_a1":"没有困难",
  "patient_foot_ankle_function_q49_a2":"一些困难",
  "patient_foot_ankle_function_q49_a3":"中度困难",
  "patient_foot_ankle_function_q49_a4":"极度困难",
  "patient_foot_ankle_function_q49_a5":"无法办到",
  "patient_foot_ankle_function_q50":"可以参加您喜欢的运动, 没有时间限制",
  "patient_foot_ankle_function_q50_not_applicable":"不适用",
  "patient_foot_ankle_function_q50_a1":"没有困难",
  "patient_foot_ankle_function_q50_a2":"一些困难",
  "patient_foot_ankle_function_q50_a3":"中度困难",
  "patient_foot_ankle_function_q50_a4":"极度困难",
  "patient_foot_ankle_function_q50_a5":"无法办到",
  "patient_foot_ankle_function_q51":"您今天的脚踝/足部与正常情况的百分比如何？ （0到100%，100%为正常）",
  "patient_foot_ankle_function_q51_a1":"正常",
  "patient_foot_ankle_function_q52":"您可以定期参加的最高级别的活动是什么？",
  "patient_foot_ankle_function_q52_q1":"脚或脚踝受伤/出现问题之前",
  "patient_foot_ankle_function_q52_q1_a1":"顶级运动",
  "patient_foot_ankle_function_q52_q1_a2":"普通水准的竞争性运动",
  "patient_foot_ankle_function_q52_q1_a3":"休闲运动",
  "patient_foot_ankle_function_q52_q1_a4":"无运动活动",
  "patient_foot_ankle_function_q52_q1_a5":"无运动，无工作",
  "patient_foot_ankle_function_q52_q1_a4_o7":"无法行走，因脚踝问题而残疾",
  "patient_foot_ankle_function_q52_q1_a1_title":"顶级（国际精英、职业、国家队或甲级）",
  "patient_foot_ankle_function_q52_q1_a2_title":"普通水准的竞争性运动",
  "patient_foot_ankle_function_q52_q1_a3_title":"悠闲",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o1":"有氧运动、健身",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o2":"高山滑雪和单板滑雪",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o3":"美式足球",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o4":"羽球",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o5":"棒球",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o6":"篮球",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o7":"保龄球/冰壶",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_08":"拳击",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o9":"越野跑",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o10":"骑自行车",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o11":"跳舞",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o12":"潜水",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o13":"马术",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o14":"击剑",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o15":"福乐球",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o16":"自由式滑雪板",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o17":"高尔夫球",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o18":"体操",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o19":"手球",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o20":"曲棍球",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o21":"冰球",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o22":"合球",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o23":"武术",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o24":"现代五项",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o25":"赛车运动、技术运动",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o26":"山地和爬山",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o27":"山地自行车/小轮车",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o28":"北欧滑雪",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o29":"定向运动",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o30":"跳伞",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o31":"力量举重",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o32":"艺术体操",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o33":"划船、皮划艇",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o34":"橄榄球",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o35":"帆船运动",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o36":"水肺潜水",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o37":"射击、射箭",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o38":"滑冰、直排轮滑",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o39":"足球",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o40":"垒球",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o41":"壁球",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o42":"冲浪、风帆冲浪",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o43":"乒乓球",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o44":"网球",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o45":"田径：田项目",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o46":"田径：径项目",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o47":"铁人三项",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o48":"排球",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o49":"水球和游泳",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o50":"滑水",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o51":"举重、健身",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o52":"摔角",
  "patient_foot_ankle_function_q52_q1_a4_o1":"重体力劳动",
  "patient_foot_ankle_function_q52_q1_a4_o2":"体力劳动",
  "patient_foot_ankle_function_q52_q1_a4_o3":"特殊职业：芭蕾舞演员、职业军人、特种救援人员、替身演员",
  "patient_foot_ankle_function_q52_q1_a4_o4":"能够在任何不平坦的地面上行走",
  "patient_foot_ankle_function_q52_q1_a4_o5":"日常活动不受限制",
  "patient_foot_ankle_function_q52_q1_a4_o6":"能够在平坦的地面上行走，但日常活动受到限制",
  "patient_foot_ankle_function_q52_q2":"当前状态",
  "patient_foot_ankle_function_q52_q2_a1":"顶级",
  "patient_foot_ankle_function_q52_q2_a2":"普通水准的竞争性运动",
  "patient_foot_ankle_function_q52_q2_a3":"休闲",
  "patient_foot_ankle_function_q52_q2_a4":"没有体育活动",
  "patient_foot_ankle_function_q52_q2_a5":"没有运动，没有工作",
  "patient_foot_ankle_function_q52_q2_a4_o7":"无法行走，因脚踝问题而残疾",
  "patient_foot_ankle_function_q52_q2_a1_title":"顶级（国际精英、职业、国家队或甲级）",
  "patient_foot_ankle_function_q52_q2_a2_title":"普通水准的竞争性运动",
  "patient_foot_ankle_function_q52_q2_a3_title":"休闲",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o1":"有氧运动、健身",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o2":"高山滑雪和单板滑雪",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o3":"美式足球",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o4":"羽毛球",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o5":"棒球",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o6":"篮球",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o7":"保龄球/冰壶",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o8":"拳击",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o9":"越野跑",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o10":"骑自行车",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o11":"跳舞",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o12":"潜水",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o13":"马术",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o14":"击剑",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o15":"福乐球",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o16":"自由式滑雪板",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o17":"高尔夫球",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o18":"体操",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o19":"手球",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o20":"曲棍球",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o21":"冰球",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o22":"合球",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o23":"武术",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o24":"现代五项",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o25":"赛车运动、技术运动",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o26":"山地和爬山",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o27":"山地自行车/小轮车",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o28":"北欧滑雪",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o29":"定向运动",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o30":"跳伞",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o31":"力量举重",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o32":"艺术体操",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o33":"划船、皮划艇",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o34":"橄榄球",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o35":"帆船运动",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o36":"水肺潜水",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o37":"射击、射箭",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o38":"滑冰、直排轮滑",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o39":"足球",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o40":"垒球",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o41":"壁球",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o42":"冲浪、风帆冲浪",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o43":"乒乓球",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o44":"网球",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o45":"田径：田径项目",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o46":"田径：田径项目",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o47":"铁人三项",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o48":"排球",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o49":"水球和游泳",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o50":"滑水",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o51":"举重、健身",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o52":"摔角",
  "patient_foot_ankle_function_q52_q2_a4_o1":"重体力劳动",
  "patient_foot_ankle_function_q52_q2_a4_o2":"体力劳动",
  "patient_foot_ankle_function_q52_q2_a4_o3":"特殊职业：芭蕾舞演员、职业军人、特种救援人员、替身演员",
  "patient_foot_ankle_function_q52_q2_a4_o4":"能够在任何不平坦的地面上行走",
  "patient_foot_ankle_function_q52_q2_a4_o5":"日常活动不受限制",
  "patient_foot_ankle_function_q52_q2_a4_o6":"能够在平坦的地面上行走，各项活动受到限制",
  "patient_foot_ankle_quality_of_life":"生活质量",
  "patient_foot_ankle_quality_of_life_instructions":"以下问题询问您可能对脚/脚踝问题感到的社交、情感和生活方式方面的担忧。 请想一想您大部分时间对脚/脚踝的感受如何。",
  "patient_foot_ankle_quality_of_life_q1":"您多常意识到自己的脚/脚踝问题？",
  "patient_foot_ankle_quality_of_life_q1_a1":"不曾",
  "patient_foot_ankle_quality_of_life_q1_a2":"每月",
  "patient_foot_ankle_quality_of_life_q1_a3":"每周",
  "patient_foot_ankle_quality_of_life_q1_a4":"每天",
  "patient_foot_ankle_quality_of_life_q1_a5":"持续",
  "patient_foot_ankle_quality_of_life_q2":"您是否改变了生活方式以避免对脚/脚踝造成潜在损害的活动？",
  "patient_foot_ankle_quality_of_life_q2_a1":"一点也不",
  "patient_foot_ankle_quality_of_life_q2_a2":"稍微",
  "patient_foot_ankle_quality_of_life_q2_a3":"中度",
  "patient_foot_ankle_quality_of_life_q2_a4":"严重",
  "patient_foot_ankle_quality_of_life_q2_a5":"完全",
  "patient_foot_ankle_quality_of_life_q3":"您对自己的脚/脚踝缺乏信心有多少困扰？",
  "patient_foot_ankle_quality_of_life_q3_a1":"一点也不",
  "patient_foot_ankle_quality_of_life_q3_a2":"稍微",
  "patient_foot_ankle_quality_of_life_q3_a3":"中度",
  "patient_foot_ankle_quality_of_life_q3_a4":"严重",
  "patient_foot_ankle_quality_of_life_q3_a5":"极其",
  "patient_foot_ankle_quality_of_life_q4":"一般来说，您的脚/脚踝有多少困难？",
  "patient_foot_ankle_quality_of_life_q4_a1":"否",
  "patient_foot_ankle_quality_of_life_q4_a2":"稍微",
  "patient_foot_ankle_quality_of_life_q4_a3":"中度",
  "patient_foot_ankle_quality_of_life_q4_a4":"严重",
  "patient_foot_ankle_quality_of_life_q4_a5":"极度",
  "patient_foot_ankle_quality_of_life_q5":"您今天有多焦虑/抑郁？",
  "patient_foot_ankle_quality_of_life_q5_a1":"不焦虑/抑郁",
  "patient_foot_ankle_quality_of_life_q5_a2":"轻微地",
  "patient_foot_ankle_quality_of_life_q5_a3":"中度",
  "patient_foot_ankle_quality_of_life_q5_a4":"严重",
  "patient_foot_ankle_quality_of_life_q5_a5":"极其",
  "patient_foot_ankle_quality_of_life_q6":"您今天的健康状况如何？（100 表示最好的健康状况，0 表示您可以想象的最差的健康状况）",
  "patient_foot_ankle_quality_of_life_q6_a1":"最差健康",
  "patient_foot_ankle_quality_of_life_q6_a2":"最好健康",
  "patient_foot_ankle_satisfaction":"满意感",
  "patient_foot_ankle_satisfaction_instruction":"您对足/踝部手术或治疗的结果满意吗？",
  "patient_foot_ankle_satisfaction_instruction_a1":"很不满意",
  "patient_foot_ankle_satisfaction_instruction_a2":"有些不满意",
  "patient_foot_ankle_satisfaction_instruction_a3":"有些满意",
  "patient_foot_ankle_satisfaction_instruction_a4":"很满意",
  "patient_foot_ankle_satisfaction_q1":"为了改善症状？",
  "patient_foot_ankle_satisfaction_q1_not_applicable":"不适用",
  "patient_foot_ankle_satisfaction_q1_a1":"很不满意",
  "patient_foot_ankle_satisfaction_q1_a2":"有些不满意",
  "patient_foot_ankle_satisfaction_q1_a3":"有些满意",
  "patient_foot_ankle_satisfaction_q1_a4":"很满意",
  "patient_foot_ankle_satisfaction_q2":"为了提高您进行日常活动的能力？",
  "patient_foot_ankle_satisfaction_q2_not_applicable":"不适用",
  "patient_foot_ankle_satisfaction_q2_a1":"很不满意",
  "patient_foot_ankle_satisfaction_q2_a2":"有些不满意",
  "patient_foot_ankle_satisfaction_q2_a3":"有些满意",
  "patient_foot_ankle_satisfaction_q2_a4":"很满意",
  "patient_foot_ankle_satisfaction_q3":"为了“忘记”你的脚/脚踝问题？ （对脚/脚踝问题的认识）",
  "patient_foot_ankle_satisfaction_q3_not_applicable":"不适用",
  "patient_foot_ankle_satisfaction_q3_a1":"很不满意",
  "patient_foot_ankle_satisfaction_q3_a2":"有些不满意",
  "patient_foot_ankle_satisfaction_q3_a3":"有些满意",
  "patient_foot_ankle_satisfaction_q3_a4":"很满意",
  "patient_foot_ankle_complete":"问卷结束",
  "patient_foot_ankle_complete_text1":"感谢您抽出宝贵时间填写此表格。",
  "patient_foot_ankle_complete_text2":"请点击提交完成并查看您的分数。",
  "patient_foot_ankle_complete_text3":"您的回答将被保密，任何报告都将仅使用去识别化的咨讯。",
  "patient_foot_ankle_results":"结果",
  "patient_foot_ankle_results_text1":"以下是您的疼痛和足/踝功能评分，这些评分是根据您的调查问卷计算得出的。",
  "patient_foot_ankle_results_pain":"疼痛",
  "patient_foot_ankle_results_function":"功能",
  "patient_foot_ankle_results_overall_health_perception":"整体健康认知",
  "patient_foot_ankle_results_feeling_of_instability":"不稳定的感觉",
  "patient_foot_ankle_results_self_evaluation":"足/踝部评分自我评估",
  "patient_foot_ankle_results_text2":"您的医生很快就会与您联系。",
  "patient_elbow_pain":"疼痛",
  "patient_elbow_pain_q1":"您的肘部是否感到疼痛？",
  "patient_elbow_pain_q1_a1":"否",
  "patient_elbow_pain_q1_a2":"是",
  "patient_elbow_pain_q2":"对您的肘部疼痛的最佳描述是什么？",
  "patient_elbow_pain_q2_a1":"无或可以被忽略",
  "patient_elbow_pain_q2_a2":"轻度，有活动，不使用药物/镇痛药或偶尔使用药物/止痛药",
  "patient_elbow_pain_q2_a3":"中度，有或没有活动，每天使用镇痛药",
  "patient_elbow_pain_q2_a4":"休息时或夜间适度",
  "patient_elbow_pain_q2_a5":"严重休息、持续服药、致残",
  "patient_elbow_pain_instruction":"评价您肘部的疼痛程度",
  "patient_elbow_pain_q3":"此时此刻",
  "patient_elbow_pain_q3_a1":"不痛",
  "patient_elbow_pain_q3_a2":"最严重的疼痛，难以忍受",
  "patient_elbow_pain_q4":"过去 4 周内经常疼痛",
  "patient_elbow_pain_q4_a1":"不痛",
  "patient_elbow_pain_q4_a2":"最严重的疼痛，难以忍受",
  "patient_elbow_pain_q5":"当过去 4 周内情况最糟糕时",
  "patient_elbow_pain_q5_a1":"不痛",
  "patient_elbow_pain_q5_a2":"最严重的疼痛，难以忍受",
  "patient_elbow_pain_q6":"休息时候",
  "patient_elbow_pain_q6_a1":"不痛",
  "patient_elbow_pain_q6_a2":"最严重的疼痛，难以忍受",
  "patient_elbow_pain_q7":"举起重物",
  "patient_elbow_pain_q7_a1":"不痛",
  "patient_elbow_pain_q7_a2":"最严重的疼痛，难以忍受",
  "patient_elbow_pain_q8":"当执行重复肘部动作的任务时",
  "patient_elbow_pain_q8_a1":"不痛",
  "patient_elbow_pain_q8_a2":"最严重的疼痛，难以忍受",
  "patient_elbow_pain_q9":"晚上",
  "patient_elbow_pain_q9_a1":"不痛",
  "patient_elbow_pain_q9_a2":"最严重的疼痛，难以忍受",
  "patient_elbow_pain_q10":"您有多少次被夜间床上膝盖疼痛所困扰？",
  "patient_elbow_pain_q10_a1":"一点也不",
  "patient_elbow_pain_q10_a2":"一至两晚",
  "patient_elbow_pain_q10_a3":"有些晚",
  "patient_elbow_pain_q10_a4":"大多晚",
  "patient_elbow_pain_q10_a5":"每晚",
  "patient_elbow_pain_q11":"您的肘部疼痛多常会影响您的睡眠？",
  "patient_elbow_pain_q11_a1":"一点也不",
  "patient_elbow_pain_q11_a2":"偶尔",
  "patient_elbow_pain_q11_a3":"有些时候",
  "patient_elbow_pain_q11_a4":"大多时候",
  "patient_elbow_pain_q11_a5":"每时侯",
  "patient_elbow_function":"功能",
  "patient_elbow_function_q1":"对您的肘部功能的最佳描述是什么？",
  "patient_elbow_function_q1_a1":"无限制",
  "patient_elbow_function_q1_a2":"轻微限制，日常生活活动不受限制",
  "patient_elbow_function_q1_a3":"无法举起>4.5公斤的物体",
  "patient_elbow_function_q1_a4":"适度限制日常生活活动",
  "patient_elbow_function_q1_a5":"无法梳理头发或摸头",
  "patient_elbow_function_q1_a6":"无法自己吃饭",
  "patient_elbow_function_q2":"对您可以进行的活动的最佳描述是什么？",
  "patient_elbow_function_q2_a1":"无限使用肘部",
  "patient_elbow_function_q2_a2":"限制用于娱乐",
  "patient_elbow_function_q2_a3":"仅限于家庭和就业活动",
  "patient_elbow_function_q2_a4":"能够照顾自己",
  "patient_elbow_function_q2_a5":"无法使用",
  "patient_elbow_function_q3":"你的肘部可以使用多长时间？",
  "patient_elbow_function_q3_a1":"可以进行30分钟的活动",
  "patient_elbow_function_q3_a2":"可以进行15分钟的活动",
  "patient_elbow_function_q3_a3":"可以进行5分钟的活动",
  "patient_elbow_function_q3_a4":"不能使用肘部",
  "patient_elbow_function_instruction":"请指出在上周内因肘部而进行以下活动的难度",
  "patient_elbow_function_instruction_0":"没有难度",
  "patient_elbow_function_instruction_1":"稍微",
  "patient_elbow_function_instruction_2":"中度",
  "patient_elbow_function_instruction_3":"严重",
  "patient_elbow_function_instruction_4":"无法",
  "patient_elbow_function_q4":"打开一个紧的或新的罐子",
  "patient_elbow_function_q4_a1":"没有难度",
  "patient_elbow_function_q4_a2":"稍微",
  "patient_elbow_function_q4_a3":"中度",
  "patient_elbow_function_q4_a4":"严重",
  "patient_elbow_function_q4_a5":"无法",
  "patient_elbow_function_q5":"携带购物袋或公文包",
  "patient_elbow_function_q5_a1":"没有难度",
  "patient_elbow_function_q5_a2":"稍微",
  "patient_elbow_function_q5_a3":"中度",
  "patient_elbow_function_q5_a4":"严重",
  "patient_elbow_function_q5_a5":"无法",
  "patient_elbow_function_q6":"提起家里的东西，例如倒垃圾",
  "patient_elbow_function_q6_a1":"没有难度",
  "patient_elbow_function_q6_a2":"稍微",
  "patient_elbow_function_q6_a3":"中度",
  "patient_elbow_function_q6_a4":"严重",
  "patient_elbow_function_q6_a5":"无法",
  "patient_elbow_function_q7":"洗您的背",
  "patient_elbow_function_q7_a1":"没有难度",
  "patient_elbow_function_q7_a2":"稍微",
  "patient_elbow_function_q7_a3":"中度",
  "patient_elbow_function_q7_a4":"严重",
  "patient_elbow_function_q7_a5":"无法",
  "patient_elbow_function_q8":"管理上厕所",
  "patient_elbow_function_q8_a1":"没有难度",
  "patient_elbow_function_q8_a2":"稍微",
  "patient_elbow_function_q8_a3":"中度",
  "patient_elbow_function_q8_a4":"严重",
  "patient_elbow_function_q8_a5":"无法",
  "patient_elbow_function_q9":"把自己全身洗一遍",
  "patient_elbow_function_q9_a1":"没有难度",
  "patient_elbow_function_q9_a2":"稍微",
  "patient_elbow_function_q9_a3":"中度",
  "patient_elbow_function_q9_a4":"严重",
  "patient_elbow_function_q9_a5":"无法",
  "patient_elbow_function_q10":"做好个人卫生",
  "patient_elbow_function_q10_a1":"没有难度",
  "patient_elbow_function_q10_a2":"稍微",
  "patient_elbow_function_q10_a3":"中度",
  "patient_elbow_function_q10_a4":"严重",
  "patient_elbow_function_q10_a5":"无法",
  "patient_elbow_function_q11":"用刀切食物",
  "patient_elbow_function_q11_a1":"没有难度",
  "patient_elbow_function_q11_a2":"稍微",
  "patient_elbow_function_q11_a3":"中度",
  "patient_elbow_function_q11_a4":"严重",
  "patient_elbow_function_q11_a5":"无法",
  "patient_elbow_function_q12":"用餐具吃饭",
  "patient_elbow_function_q12_a1":"没有难度",
  "patient_elbow_function_q12_a2":"稍微",
  "patient_elbow_function_q12_a3":"中度",
  "patient_elbow_function_q12_a4":"严重",
  "patient_elbow_function_q12_a5":"无法",
  "patient_elbow_function_q13":"通过手臂、肩膀或手承受一些力或冲击力的娱乐活动（例如高尔夫、锤击、网球等）",
  "patient_elbow_function_q13_a1":"没有难度",
  "patient_elbow_function_q13_a2":"稍微",
  "patient_elbow_function_q13_a3":"中度",
  "patient_elbow_function_q13_a4":"严重",
  "patient_elbow_function_q13_a5":"无法",
  "patient_elbow_function_q14":"由于手臂、肩膀或手部疼痛而睡觉",
  "patient_elbow_function_q14_a1":"没有难度",
  "patient_elbow_function_q14_a2":"稍微",
  "patient_elbow_function_q14_a3":"中度",
  "patient_elbow_function_q14_a4":"严重",
  "patient_elbow_function_q14_a5":"无法",
  "patient_elbow_function_q15":"穿上衬衫",
  "patient_elbow_function_q15_a1":"没有难度",
  "patient_elbow_function_q15_a2":"稍微",
  "patient_elbow_function_q15_a3":"中度",
  "patient_elbow_function_q15_a4":"严重",
  "patient_elbow_function_q15_a5":"无法",
  "patient_elbow_function_q16":"把衬衫上面的纽扣扣起来",
  "patient_elbow_function_q16_a1":"没有难度",
  "patient_elbow_function_q16_a2":"稍微",
  "patient_elbow_function_q16_a3":"中度",
  "patient_elbow_function_q16_a4":"严重",
  "patient_elbow_function_q16_a5":"无法",
  "patient_elbow_function_q17":"自己穿衣服",
  "patient_elbow_function_q17_a1":"没有难度",
  "patient_elbow_function_q17_a2":"稍微",
  "patient_elbow_function_q17_a3":"中度",
  "patient_elbow_function_q17_a4":"严重",
  "patient_elbow_function_q17_a5":"无法",
  "patient_elbow_function_q18":"梳头发",
  "patient_elbow_function_q18_a1":"没有难度",
  "patient_elbow_function_q18_a2":"稍微",
  "patient_elbow_function_q18_a3":"中度",
  "patient_elbow_function_q18_a4":"严重",
  "patient_elbow_function_q18_a5":"无法",
  "patient_elbow_function_q19":"穿鞋子",
  "patient_elbow_function_q19_a1":"没有难度",
  "patient_elbow_function_q19_a2":"稍微",
  "patient_elbow_function_q19_a3":"中度",
  "patient_elbow_function_q19_a4":"严重",
  "patient_elbow_function_q19_a5":"无法",
  "patient_elbow_function_q20":"系鞋带",
  "patient_elbow_function_q20_a1":"没有难度",
  "patient_elbow_function_q20_a2":"稍微",
  "patient_elbow_function_q20_a3":"中度",
  "patient_elbow_function_q20_a4":"严重",
  "patient_elbow_function_q20_a5":"无法",
  "patient_elbow_function_q21":"携带重物",
  "patient_elbow_function_q21_a1":"没有难度",
  "patient_elbow_function_q21_a2":"稍微",
  "patient_elbow_function_q21_a3":"中度",
  "patient_elbow_function_q21_a4":"严重",
  "patient_elbow_function_q21_a5":"无法",
  "patient_elbow_function_q22":"从椅子上站起来，用手臂推动",
  "patient_elbow_function_q22_a1":"没有难度",
  "patient_elbow_function_q22_a2":"稍微",
  "patient_elbow_function_q22_a3":"中度",
  "patient_elbow_function_q22_a4":"严重",
  "patient_elbow_function_q22_a5":"无法",
  "patient_elbow_function_q23":"做繁重的家务（例如洗墙、洗地板）",
  "patient_elbow_function_q23_a1":"没有难度",
  "patient_elbow_function_q23_a2":"稍微",
  "patient_elbow_function_q23_a3":"中度",
  "patient_elbow_function_q23_a4":"严重",
  "patient_elbow_function_q23_a5":"无法",
  "patient_elbow_function_q24":"转动钥匙开锁",
  "patient_elbow_function_q24_a1":"没有难度",
  "patient_elbow_function_q24_a2":"稍微",
  "patient_elbow_function_q24_a3":"中度",
  "patient_elbow_function_q24_a4":"严重",
  "patient_elbow_function_q24_a5":"无法",
  "patient_elbow_function_q25":"丢球",
  "patient_elbow_function_q25_a1":"没有难度",
  "patient_elbow_function_q25_a2":"稍微",
  "patient_elbow_function_q25_a3":"中度",
  "patient_elbow_function_q25_a4":"严重",
  "patient_elbow_function_q25_a5":"无法",
  "patient_elbow_function_q26":"由于手臂、肩膀或手部疼痛而从事日常工作（工作包括做家务，如果这是您的主要职责）",
  "patient_elbow_function_q26_a1":"没有难度",
  "patient_elbow_function_q26_a2":"稍微",
  "patient_elbow_function_q26_a3":"中度",
  "patient_elbow_function_q26_a4":"严重",
  "patient_elbow_function_q26_a5":"无法",
  "patient_elbow_function_q27":"指定平时的工作",
  "patient_elbow_function_q27_placeholder":"指定工作",
  "patient_elbow_function_i_donot_work":"我不工作",
  "patient_elbow_function_q28":"使用您常用的工作技巧",
  "patient_elbow_function_q28_a1":"没有难度",
  "patient_elbow_function_q28_a2":"稍微",
  "patient_elbow_function_q28_a3":"中度",
  "patient_elbow_function_q28_a4":"严重",
  "patient_elbow_function_q28_a5":"无法",
  "patient_elbow_function_q29":"按照您的意愿完成您的工作",
  "patient_elbow_function_q29_a1":"没有难度",
  "patient_elbow_function_q29_a2":"稍微",
  "patient_elbow_function_q29_a3":"中度",
  "patient_elbow_function_q29_a4":"严重",
  "patient_elbow_function_q29_a5":"无法",
  "patient_elbow_function_q30":"花平常的时间做你的工作",
  "patient_elbow_function_q30_a1":"没有难度",
  "patient_elbow_function_q30_a2":"稍微",
  "patient_elbow_function_q30_a3":"中度",
  "patient_elbow_function_q30_a4":"严重",
  "patient_elbow_function_q30_a5":"无法",
  "patient_elbow_function_q31":"由于手臂、肩膀或手部疼痛，演奏乐器或进行运动有困难",
  "patient_elbow_function_q31_a1":"没有难度",
  "patient_elbow_function_q31_a2":"稍微",
  "patient_elbow_function_q31_a3":"中度",
  "patient_elbow_function_q31_a4":"严重",
  "patient_elbow_function_q31_a5":"无法",
  "patient_elbow_function_q32":"指定所玩的运动或乐器",
  "patient_elbow_function_q32_placeholder":"指定所玩的运动或乐器",
  "patient_elbow_function_i_donot_play":"我不玩任何运动或乐器",
  "patient_elbow_function_q33":"使用您常用的演奏乐器或运动的技巧",
  "patient_elbow_function_q33_a1":"没有难度",
  "patient_elbow_function_q33_a2":"稍微",
  "patient_elbow_function_q33_a3":"中度",
  "patient_elbow_function_q33_a4":"严重",
  "patient_elbow_function_q33_a5":"无法",
  "patient_elbow_function_q34":"尽情演奏您的乐器或进行您想要的运动",
  "patient_elbow_function_q34_a1":"没有难度",
  "patient_elbow_function_q34_a2":"稍微",
  "patient_elbow_function_q34_a3":"中度",
  "patient_elbow_function_q34_a4":"严重",
  "patient_elbow_function_q34_a5":"无法",
  "patient_elbow_function_q35":"花平常的时间练习或演奏乐器或运动",
  "patient_elbow_function_q35_a1":"没有难度",
  "patient_elbow_function_q35_a2":"稍微",
  "patient_elbow_function_q35_a3":"中度",
  "patient_elbow_function_q35_a4":"严重",
  "patient_elbow_function_q35_a5":"无法",
  "patient_elbow_function_q36":"在过去的一周中，您的手臂、肩膀或手部问题在多大程度上干扰了您与家人、朋友、邻居或团体的正常社交/休闲活动？",
  "patient_elbow_function_q36_a1":"一点也不",
  "patient_elbow_function_q36_a2":"稍微",
  "patient_elbow_function_q36_a3":"中度",
  "patient_elbow_function_q36_a4":"相当多",
  "patient_elbow_function_q36_a5":"极其",
  "patient_elbow_function_q37":"在过去的一周中，您的工作或其他日常活动是否因手臂、肩膀或手部问题而受到限制？",
  "patient_elbow_function_q37_a1":"一点也不",
  "patient_elbow_function_q37_a2":"稍微",
  "patient_elbow_function_q37_a3":"中度",
  "patient_elbow_function_q37_a4":"相当多",
  "patient_elbow_function_q37_a5":"极其",
  "patient_elbow_symptoms":"其他症状",
  "patient_elbow_symptoms_instruction":"请评价上周以下症状的严重程度：",
  "patient_elbow_symptoms_instruction_0":"无",
  "patient_elbow_symptoms_instruction_1":"稍微",
  "patient_elbow_symptoms_instruction_2":"中度",
  "patient_elbow_symptoms_instruction_3":"严重",
  "patient_elbow_symptoms_instruction_4":"极度",
  "patient_elbow_symptoms_q1":"手臂、肩膀或手部疼痛",
  "patient_elbow_symptoms_q1_a1":"无",
  "patient_elbow_symptoms_q1_a2":"稍微",
  "patient_elbow_symptoms_q1_a3":"中度",
  "patient_elbow_symptoms_q1_a4":"严重",
  "patient_elbow_symptoms_q1_a5":"极度",
  "patient_elbow_symptoms_q2":"手臂、肩膀或手部有刺痛感（针刺感）",
  "patient_elbow_symptoms_q2_a1":"无",
  "patient_elbow_symptoms_q2_a2":"稍微",
  "patient_elbow_symptoms_q2_a3":"中度",
  "patient_elbow_symptoms_q2_a4":"严重",
  "patient_elbow_symptoms_q2_a5":"极度",
  "patient_elbow_symptoms_q3":"您今天的肘部与正常肘部的百分比如何？ （0-100%，100%为正常）",
  "patient_elbow_quality_of_life":"生活素质",
  "patient_elbow_quality_of_life_instruction":"以下问题询问您可能对肘部问题感到的社交、情感和生活方式方面的担忧。 请想一想您大部分时间对肘部的感受如何。",
  "patient_elbow_quality_of_life_q1":"在过去的4周里，您是否感觉肘部问题正在“控制您的生活”？",
  "patient_elbow_quality_of_life_q1_a1":"一点也不",
  "patient_elbow_quality_of_life_q1_a2":"偶尔",
  "patient_elbow_quality_of_life_q1_a3":"有些天",
  "patient_elbow_quality_of_life_q1_a4":"大多天",
  "patient_elbow_quality_of_life_q1_a5":"每天",
  "patient_elbow_quality_of_life_q2":"在过去 4 周内，您的肘部问题有多常“出现在您的脑海中”？",
  "patient_elbow_quality_of_life_q2_a1":"一点也不",
  "patient_elbow_quality_of_life_q2_a2":"有一点时候",
  "patient_elbow_quality_of_life_q2_a3":"有些时候",
  "patient_elbow_quality_of_life_q2_a4":"大多时候",
  "patient_elbow_quality_of_life_q2_a5":"所有的时候",
  "patient_elbow_quality_of_life_instruction1":"请对以下内容进行评分，以最准确地描述您当前的健康状况：",
  "patient_elbow_quality_of_life_instruction1_0":"没有问题",
  "patient_elbow_quality_of_life_instruction1_1":"稍微",
  "patient_elbow_quality_of_life_instruction1_2":"中度",
  "patient_elbow_quality_of_life_instruction1_3":"严重",
  "patient_elbow_quality_of_life_instruction1_4":"极度/无法",
  "patient_elbow_quality_of_life_q3":"走路有问题",
  "patient_elbow_quality_of_life_q3_a1":"没有问题",
  "patient_elbow_quality_of_life_q3_a2":"稍微",
  "patient_elbow_quality_of_life_q3_a3":"中度",
  "patient_elbow_quality_of_life_q3_a4":"严重",
  "patient_elbow_quality_of_life_q3_a5":"无法",
  "patient_elbow_quality_of_life_q4":"自己洗澡或穿衣有问题",
  "patient_elbow_quality_of_life_q4_a1":"没有问题",
  "patient_elbow_quality_of_life_q4_a2":"稍微",
  "patient_elbow_quality_of_life_q4_a3":"中度",
  "patient_elbow_quality_of_life_q4_a4":"严重",
  "patient_elbow_quality_of_life_q4_a5":"无法",
  "patient_elbow_quality_of_life_q5":"进行日常活动（例如工作、学习、家务、家庭或休闲活动）时出现问题",
  "patient_elbow_quality_of_life_q5_a1":"没有问题",
  "patient_elbow_quality_of_life_q5_a2":"稍微",
  "patient_elbow_quality_of_life_q5_a3":"中度",
  "patient_elbow_quality_of_life_q5_a4":"严重",
  "patient_elbow_quality_of_life_q5_a5":"无法",
  "patient_elbow_quality_of_life_q6":"疼痛或不舒服",
  "patient_elbow_quality_of_life_q6_a1":"无",
  "patient_elbow_quality_of_life_q6_a2":"稍微",
  "patient_elbow_quality_of_life_q6_a3":"中度",
  "patient_elbow_quality_of_life_q6_a4":"严重",
  "patient_elbow_quality_of_life_q6_a5":"极度",
  "patient_elbow_quality_of_life_q7":"焦虑/抑郁",
  "patient_elbow_quality_of_life_q7_a1":"不焦虑/抑郁",
  "patient_elbow_quality_of_life_q7_a2":"稍微焦虑/抑郁",
  "patient_elbow_quality_of_life_q7_a3":"适度焦虑/抑郁",
  "patient_elbow_quality_of_life_q7_a4":"严重焦虑/抑郁",
  "patient_elbow_quality_of_life_q7_a5":"极度焦虑/抑郁",
  "patient_elbow_quality_of_life_q8":"您今天的健康状况如何？",
  "patient_elbow_quality_of_life_q8_instruction":"100 表示最佳健康状况，0 表示您可以想象的最差健康状况",
  "patient_elbow_quality_of_life_q8_a1":"最差",
  "patient_elbow_quality_of_life_q8_a2":"最好",
  "patient_elbow_satisfaction":"满意感",
  "patient_elbow_satisfaction_q1":"您对肘部治疗效果满意吗？",
  "patient_elbow_satisfaction_na":"不适用",
  "patient_elbow_satisfaction_q1_a1":"一点都不满意",
  "patient_elbow_satisfaction_q1_a2":"很满意",
  "patient_elbow_complete":"问卷结束",
  "patient_elbow_complete_text1":"感谢您抽出宝贵时间填写此表格。",
  "patient_elbow_complete_text2":"请点击提交完成并查看您的分数。",
  "patient_elbow_complete_text3":"您的回答将被保密，任何报告都将仅使用去识别化的咨讯。",
  "patient_elbow_results":"结果",
  "patient_elbow_results_text1":"以下是您的疼痛和肘部功能评分，这些评分是根据您的调查问卷计算得出的。",
  "patient_elbow_results_function":"功能",
  "patient_elbow_results_pain":"疼痛",
  "patient_elbow_results_health_perception":"整体健康认知",
  "patient_elbow_results_self_evaluation":"肘部评分自我评估",
  "patient_elbow_results_text4":"您的医生很快就会和你联系。",
  "patient_hand_pain":"疼痛",
  "patient_hand_pain_q1":"描述过去一周您的手/手腕的疼痛",
  "patient_hand_pain_q1_a1":"无至非常温和",
  "patient_hand_pain_q1_a2":"稍微",
  "patient_hand_pain_q1_a3":"中度",
  "patient_hand_pain_q1_a4":"严重",
  "patient_hand_pain_q1_a5":"非常严重/极端",
  "patient_hand_pain_q2":"此时您的手/手腕有多痛？",
  "patient_hand_pain_q2_a1":"没有痛",
  "patient_hand_pain_q2_a2":"最痛",
  "patient_hand_pain_q3":"过去一周，您的手/手腕疼痛有多少次干扰您的日常活动（例如吃饭或洗澡）？",
  "patient_hand_pain_q3_a1":"不曾",
  "patient_hand_pain_q3_a2":"偶尔",
  "patient_hand_pain_q3_a3":"有时候",
  "patient_hand_pain_q3_a4":"经常",
  "patient_hand_pain_q3_a5":"持续",
  "patient_hand_symptoms":"别的症状",
  "patient_hand_symptoms_q1":"过去一周，您的手臂、肩膀或手部的刺痛（针刺感）有多严重？",
  "patient_hand_symptoms_q1_a1":"无",
  "patient_hand_symptoms_q1_a2":"稍微",
  "patient_hand_symptoms_q1_a3":"中度",
  "patient_hand_symptoms_q1_a4":"严重",
  "patient_hand_symptoms_q1_a5":"极度",
  "patient_hand_symptoms_q2":"过去一周您手中的感觉如何？",
  "patient_hand_symptoms_q2_a1":"很好",
  "patient_hand_symptoms_q2_a2":"好",
  "patient_hand_symptoms_q2_a3":"还可以",
  "patient_hand_symptoms_q2_a4":"差",
  "patient_hand_symptoms_q2_a5":"很差",
  "patient_hand_symptoms_q3":"我的手的外观干扰了我的正常日常活动",
  "patient_hand_symptoms_q3_a1":"强烈反对",
  "patient_hand_symptoms_q3_a2":"反对",
  "patient_hand_symptoms_q3_a3":"既不同意也不反对",
  "patient_hand_symptoms_q3_a4":"同意",
  "patient_hand_symptoms_q3_a5":"强烈同意",
  "patient_hand_symptoms_q3_a6":"您今天的手/手腕与正常情况的百分比如何？ （0-100%，100%为正常）",
  "patient_hand_symptoms_q3_a6_normal":"正常",
  "patient_hand_function":"功能",
  "patient_hand_function_instruction1":"如果您不执行某项任务，请估计您执行该任务的难度。",
  "patient_hand_function_instruction2":"如果您不确定如何回答问题，请尽可能给出最佳答案。",
  "patient_hand_function_q1":"总体而言，过去一周您的手部表现如何？",
  "patient_hand_function_q1_a1":"很好",
  "patient_hand_function_q1_a2":"好",
  "patient_hand_function_q1_a3":"还可以",
  "patient_hand_function_q1_a4":"差",
  "patient_hand_function_q1_a5":"很差",
  "patient_hand_function_q2":"您有多少次因为手/手腕问题而无法完成工作？",
  "patient_hand_function_q2_a1":"不曾",
  "patient_hand_function_q2_a2":"偶尔",
  "patient_hand_function_q2_a3":"有时候",
  "patient_hand_function_q2_a4":"经常",
  "patient_hand_function_q2_a5":"持续",
  "patient_hand_function_q3":"您在工作中是否经常因为手/手腕问题而花更长时间完成任务？",
  "patient_hand_function_q3_a1":"不曾",
  "patient_hand_function_q3_a2":"偶尔",
  "patient_hand_function_q3_a3":"有时候",
  "patient_hand_function_q3_a4":"经常",
  "patient_hand_function_q3_a5":"持续",
  "patient_hand_function_instruction":"请指出上周由于您的手/手腕问题而进行以下活动的难度",
  "patient_hand_function_instruction_0":"没有难度",
  "patient_hand_function_instruction_1":"稍微难度",
  "patient_hand_function_instruction_2":"中度困难",
  "patient_hand_function_instruction_3":"严重难度",
  "patient_hand_function_instruction_4":"极端难度/无法",
  "patient_hand_function_q4":"打开一个紧的或新的罐子",
  "patient_hand_function_q4_a1":"没有难度",
  "patient_hand_function_q4_a2":"稍微难度",
  "patient_hand_function_q4_a3":"中度困难",
  "patient_hand_function_q4_a4":"严重难度",
  "patient_hand_function_q4_a5":"极端难度/无法",
  "patient_hand_function_q5":"握住煎锅",
  "patient_hand_function_q5_a1":"没有难度",
  "patient_hand_function_q5_a2":"稍微难度",
  "patient_hand_function_q5_a3":"中度困难",
  "patient_hand_function_q5_a4":"严重难度",
  "patient_hand_function_q5_a5":"极端难度/无法",
  "patient_hand_function_q6":"扣衬衫或衬衫",
  "patient_hand_function_q6_a1":"没有难度",
  "patient_hand_function_q6_a2":"稍微难度",
  "patient_hand_function_q6_a3":"中度困难",
  "patient_hand_function_q6_a4":"严重难度",
  "patient_hand_function_q6_a5":"极端难度/无法",
  "patient_hand_function_q7":"做繁重的家务（例如洗墙、洗地板）",
  "patient_hand_function_q7_a1":"没有难度",
  "patient_hand_function_q7_a2":"稍微难度",
  "patient_hand_function_q7_a3":"中度困难",
  "patient_hand_function_q7_a4":"严重难度",
  "patient_hand_function_q7_a5":"极端难度/无法",
  "patient_hand_function_q8":"携带购物袋或公文包",
  "patient_hand_function_q8_a1":"没有难度",
  "patient_hand_function_q8_a2":"稍微难度",
  "patient_hand_function_q8_a3":"中度困难",
  "patient_hand_function_q8_a4":"严重难度",
  "patient_hand_function_q8_a5":"极端难度/无法",
  "patient_hand_function_q9":"洗你的背",
  "patient_hand_function_q9_a1":"没有难度",
  "patient_hand_function_q9_a2":"稍微难度",
  "patient_hand_function_q9_a3":"中度困难",
  "patient_hand_function_q9_a4":"严重难度",
  "patient_hand_function_q9_a5":"极端难度/无法",
  "patient_hand_function_q10":"用刀切食物",
  "patient_hand_function_q10_a1":"没有难度",
  "patient_hand_function_q10_a2":"稍微难度",
  "patient_hand_function_q10_a3":"中度困难",
  "patient_hand_function_q10_a4":"严重难度",
  "patient_hand_function_q10_a5":"极端难度/无法",
  "patient_hand_function_q11":"通过手臂、肩膀或手承受一些力或冲击力的娱乐活动（例如高尔夫、锤击、网球等）",
  "patient_hand_function_q11_a1":"没有难度",
  "patient_hand_function_q11_a2":"稍微难度",
  "patient_hand_function_q11_a3":"中度困难",
  "patient_hand_function_q11_a4":"严重难度",
  "patient_hand_function_q11_a5":"极端难度/无法",
  "patient_hand_function_q12":"由于手臂、肩膀或手部疼痛而睡觉",
  "patient_hand_function_q12_a1":"没有难度",
  "patient_hand_function_q12_a2":"稍微难度",
  "patient_hand_function_q12_a3":"中度困难",
  "patient_hand_function_q12_a4":"严重难度",
  "patient_hand_function_q12_a5":"极端难度/无法",
  "patient_hand_function_q13":"由于手臂、肩膀或手部疼痛而从事日常工作（工作包括做家务，如果这是您的主要职责）",
  "patient_hand_function_q13_a1":"没有难度",
  "patient_hand_function_q13_a2":"稍微难度",
  "patient_hand_function_q13_a3":"中度困难",
  "patient_hand_function_q13_a4":"严重难度",
  "patient_hand_function_q13_a5":"极端难度/无法",
  "patient_hand_function_q14":"指定平时的工作",
  "patient_hand_function_q14_placeholder":"指定工作",
  "patient_hand_function_do_dot_work":"我不上班",
  "patient_hand_function_q15":"使用您常用的工作技巧",
  "patient_hand_function_q15_a1":"没有难度",
  "patient_hand_function_q15_a2":"稍微难度",
  "patient_hand_function_q15_a3":"中度困难",
  "patient_hand_function_q15_a4":"严重难度",
  "patient_hand_function_q15_a5":"极端难度/无法",
  "patient_hand_function_q16":"按照您的意愿完成您的工作",
  "patient_hand_function_q16_a1":"没有难度",
  "patient_hand_function_q16_a2":"稍微难度",
  "patient_hand_function_q16_a3":"中度困难",
  "patient_hand_function_q16_a4":"严重难度",
  "patient_hand_function_q16_a5":"极端难度/无法",
  "patient_hand_function_q17":"花平常的时间做你的工作",
  "patient_hand_function_q17_a1":"没有难度",
  "patient_hand_function_q17_a2":"稍微难度",
  "patient_hand_function_q17_a3":"中度困难",
  "patient_hand_function_q17_a4":"严重难度",
  "patient_hand_function_q17_a5":"极端难度/无法",
  "patient_hand_function_q18":"由于手臂、肩膀或手部疼痛而演奏乐器或进行运动",
  "patient_hand_function_q18_a1":"没有难度",
  "patient_hand_function_q18_a2":"稍微难度",
  "patient_hand_function_q18_a3":"中度困难",
  "patient_hand_function_q18_a4":"严重难度",
  "patient_hand_function_q18_a5":"极端难度/无法",
  "patient_hand_function_q19":"指定所玩的运动或乐器",
  "patient_hand_function_q19_placeholder":"指定运动/乐器",
  "patient_hand_function_donot_play":"我不从事任何运动或乐器",
  "patient_hand_function_20":"使用您常用的演奏乐器或运动的技巧",
  "patient_hand_function_q20_a1":"没有难度",
  "patient_hand_function_q20_a2":"稍微难度",
  "patient_hand_function_q20_a3":"中度困难",
  "patient_hand_function_q20_a4":"严重难度",
  "patient_hand_function_q20_a5":"极端难度/无法",
  "patient_hand_function_q21":"尽情演奏您的乐器或进行您想要的运动",
  "patient_hand_function_q21_a1":"没有难度",
  "patient_hand_function_q21_a2":"稍微难度",
  "patient_hand_function_q21_a3":"中度困难",
  "patient_hand_function_q21_a4":"严重难度",
  "patient_hand_function_q21_a5":"极端难度/无法",
  "patient_hand_function_q22":"花平常的时间练习或演奏乐器或运动",
  "patient_hand_function_q22_a1":"没有难度",
  "patient_hand_function_q22_a2":"稍微难度",
  "patient_hand_function_q22_a3":"中度困难",
  "patient_hand_function_q22_a4":"严重难度",
  "patient_hand_function_q22_a5":"极端难度/无法",
  "patient_hand_function_q23":"在过去的一周中，您的手臂、肩膀或手部问题在多大程度上干扰了您与家人、朋友、邻居或团体的正常社交/休闲活动？",
  "patient_hand_function_q23_a1":"一点也不",
  "patient_hand_function_q23_a2":"有些",
  "patient_hand_function_q23_a3":"中度",
  "patient_hand_function_q23_a4":"相当多",
  "patient_hand_function_q23_a5":"极其",
  "patient_hand_function_q24":"在过去的一周中，您的工作或其他日常活动是否因手臂、肩膀或手部问题而受到限制？",
  "patient_hand_function_q24_a1":"一点也不",
  "patient_hand_function_q24_a2":"有些",
  "patient_hand_function_q24_a3":"中度",
  "patient_hand_function_q24_a4":"相当多",
  "patient_hand_function_q24_a5":"极其",
  "patient_hand_questionnaire":"波士顿腕管综合症问卷",
  "patient_hand_questionnaire_symptom":"症状",
  "patient_hand_questionnaire_symptom_instruction":"请选择最符合您症状的答案。",
  "patient_hand_questionnaire_symptom_instruction_0":"正常",
  "patient_hand_questionnaire_symptom_instruction_1":"稍微",
  "patient_hand_questionnaire_symptom_instruction_2":"中等",
  "patient_hand_questionnaire_symptom_instruction_3":"严重",
  "patient_hand_questionnaire_symptom_instruction_4":"很严重",
  "patient_hand_questionnaire_symptom_q1":"您晚上的手或手腕疼痛有多严重？",
  "patient_hand_questionnaire_symptom_q1_a1":"正常",
  "patient_hand_questionnaire_symptom_q1_a2":"稍微",
  "patient_hand_questionnaire_symptom_q1_a3":"中等",
  "patient_hand_questionnaire_symptom_q1_a4":"严重",
  "patient_hand_questionnaire_symptom_q1_a5":"很严重",
  "patient_hand_questionnaire_symptom_q2":"在过去两周内，一个普通的夜晚中，您有多少次因手部或手腕疼痛而醒来？",
  "patient_hand_questionnaire_symptom_q2_a1":"正常",
  "patient_hand_questionnaire_symptom_q2_a2":"一次",
  "patient_hand_questionnaire_symptom_q2_a3":"两至三次",
  "patient_hand_questionnaire_symptom_q2_a4":"四至五次",
  "patient_hand_questionnaire_symptom_q2_a5":"超过五次",
  "patient_hand_questionnaire_symptom_q3":"您白天的时候手或手腕是否通常感到疼痛？",
  "patient_hand_questionnaire_symptom_q3_a1":"没有痛",
  "patient_hand_questionnaire_symptom_q3_a2":"稍微",
  "patient_hand_questionnaire_symptom_q3_a3":"中等",
  "patient_hand_questionnaire_symptom_q3_a4":"严重",
  "patient_hand_questionnaire_symptom_q3_a5":"很严重",
  "patient_hand_questionnaire_symptom_q4":"您白天手或手腕疼痛的频率有多少？",
  "patient_hand_questionnaire_symptom_q4_a1":"正常",
  "patient_hand_questionnaire_symptom_q4_a2":"一天内有一至两次",
  "patient_hand_questionnaire_symptom_q4_a3":"一天内有三至五次",
  "patient_hand_questionnaire_symptom_q4_a4":"一天内有超过五次",
  "patient_hand_questionnaire_symptom_q4_a5":"持续地痛",
  "patient_hand_questionnaire_symptom_q5":"白天疼痛的时候平均持续多长时间？",
  "patient_hand_questionnaire_symptom_q5_a1":"正常",
  "patient_hand_questionnaire_symptom_q5_a2":"少于十分钟",
  "patient_hand_questionnaire_symptom_q5_a3":"十至六十分钟",
  "patient_hand_questionnaire_symptom_q5_a4":"超过六十分钟",
  "patient_hand_questionnaire_symptom_q5_a5":"持续不断",
  "patient_hand_questionnaire_symptom_q6":"您的手是否麻木（失去知觉）",
  "patient_hand_questionnaire_symptom_q6_a1":"正常",
  "patient_hand_questionnaire_symptom_q6_a2":"稍微",
  "patient_hand_questionnaire_symptom_q6_a3":"中等",
  "patient_hand_questionnaire_symptom_q6_a4":"严重",
  "patient_hand_questionnaire_symptom_q6_a5":"很严重",
  "patient_hand_questionnaire_symptom_q7":"您的手或手腕有无力感吗？",
  "patient_hand_questionnaire_symptom_q7_a1":"正常",
  "patient_hand_questionnaire_symptom_q7_a2":"稍微",
  "patient_hand_questionnaire_symptom_q7_a3":"中等",
  "patient_hand_questionnaire_symptom_q7_a4":"严重",
  "patient_hand_questionnaire_symptom_q7_a5":"很严重",
  "patient_hand_questionnaire_symptom_q8":"你的手有刺痛的感觉吗？",
  "patient_hand_questionnaire_symptom_q8_a1":"正常",
  "patient_hand_questionnaire_symptom_q8_a2":"稍微",
  "patient_hand_questionnaire_symptom_q8_a3":"中等",
  "patient_hand_questionnaire_symptom_q8_a4":"严重",
  "patient_hand_questionnaire_symptom_q8_a5":"很严重",
  "patient_hand_questionnaire_symptom_q9":"夜间麻木（感觉丧失）或刺痛有多严重？",
  "patient_hand_questionnaire_symptom_q9_a1":"正常",
  "patient_hand_questionnaire_symptom_q9_a2":"稍微",
  "patient_hand_questionnaire_symptom_q9_a3":"中等",
  "patient_hand_questionnaire_symptom_q9_a4":"严重",
  "patient_hand_questionnaire_symptom_q9_a5":"很严重",
  "patient_hand_questionnaire_symptom_q10":"在过去两周内的普通夜晚中，您有多少次因手部麻木或刺痛而醒来？",
  "patient_hand_questionnaire_symptom_q10_a1":"正常",
  "patient_hand_questionnaire_symptom_q10_a2":"一次",
  "patient_hand_questionnaire_symptom_q10_a3":"两至三次",
  "patient_hand_questionnaire_symptom_q10_a4":"四至五次",
  "patient_hand_questionnaire_symptom_q10_a5":"超过五次",
  "patient_hand_questionnaire_function":"功能",
  "patient_hand_questionnaire_function_instructions":"根据您的症状评估进行以下活动的难度：",
  "patient_hand_questionnaire_function_instructions_0":"没有难度",
  "patient_hand_questionnaire_function_instructions_1":"一点难度",
  "patient_hand_questionnaire_function_instructions_2":"中度困难",
  "patient_hand_questionnaire_function_instructions_3":"极大难度",
  "patient_hand_questionnaire_function_instructions_4":"无法办到",
  "patient_hand_questionnaire_function_q1":"难以抓握和使用钥匙或笔等小物体",
  "patient_hand_questionnaire_function_q1_a1":"没有难度",
  "patient_hand_questionnaire_function_q1_a2":"一点难度",
  "patient_hand_questionnaire_function_q1_a3":"中度困难",
  "patient_hand_questionnaire_function_q1_a4":"极大难度",
  "patient_hand_questionnaire_function_q1_a5":"无法办到",
  "patient_hand_questionnaire_function_q2":"手写",
  "patient_hand_questionnaire_function_q2_a1":"没有难度",
  "patient_hand_questionnaire_function_q2_a2":"一点难度",
  "patient_hand_questionnaire_function_q2_a3":"中度困难",
  "patient_hand_questionnaire_function_q2_a4":"极大难度",
  "patient_hand_questionnaire_function_q2_a5":"无法办到",
  "patient_hand_questionnaire_function_q3":"扣上衣服扣子",
  "patient_hand_questionnaire_function_q3_a1":"没有难度",
  "patient_hand_questionnaire_function_q3_a2":"一点难度",
  "patient_hand_questionnaire_function_q3_a3":"中度困难",
  "patient_hand_questionnaire_function_q3_a4":"极大难度",
  "patient_hand_questionnaire_function_q3_a5":"无法办到",
  "patient_hand_questionnaire_function_q4":"拿着一本书看书",
  "patient_hand_questionnaire_function_q4_a1":"没有难度",
  "patient_hand_questionnaire_function_q4_a2":"一点难度",
  "patient_hand_questionnaire_function_q4_a3":"中度困难",
  "patient_hand_questionnaire_function_q4_a4":"极大难度",
  "patient_hand_questionnaire_function_q4_a5":"无法办到",
  "patient_hand_questionnaire_function_q5":"握住电话手柄",
  "patient_hand_questionnaire_function_q5_a1":"没有难度",
  "patient_hand_questionnaire_function_q5_a2":"一点难度",
  "patient_hand_questionnaire_function_q5_a3":"中度困难",
  "patient_hand_questionnaire_function_q5_a4":"极大难度",
  "patient_hand_questionnaire_function_q5_a5":"无法办到",
  "patient_hand_questionnaire_function_q6":"打开罐子",
  "patient_hand_questionnaire_function_q6_a1":"没有难度",
  "patient_hand_questionnaire_function_q6_a2":"一点难度",
  "patient_hand_questionnaire_function_q6_a3":"中度困难",
  "patient_hand_questionnaire_function_q6_a4":"极大难度",
  "patient_hand_questionnaire_function_q6_a5":"无法办到",
  "patient_hand_questionnaire_function_q7":"日常家务",
  "patient_hand_questionnaire_function_q7_a1":"没有难度",
  "patient_hand_questionnaire_function_q7_a2":"一点难度",
  "patient_hand_questionnaire_function_q7_a3":"中度困难",
  "patient_hand_questionnaire_function_q7_a4":"极大难度",
  "patient_hand_questionnaire_function_q7_a5":"无法办到",
  "patient_hand_questionnaire_function_q8":"携带杂货篮",
  "patient_hand_questionnaire_function_q8_a1":"没有难度",
  "patient_hand_questionnaire_function_q8_a2":"一点难度",
  "patient_hand_questionnaire_function_q8_a3":"中度困难",
  "patient_hand_questionnaire_function_q8_a4":"极大难度",
  "patient_hand_questionnaire_function_q8_a5":"无法办到",
  "patient_hand_questionnaire_function_q9":"洗澡和穿衣",
  "patient_hand_questionnaire_function_q9_a1":"没有难度",
  "patient_hand_questionnaire_function_q9_a2":"一点难度",
  "patient_hand_questionnaire_function_q9_a3":"中度困难",
  "patient_hand_questionnaire_function_q9_a4":"极大难度",
  "patient_hand_questionnaire_function_q9_a5":"无法办到",
  "patient_hand_uram":"手部和手腕 URAM 量表",
  "patient_hand_uram_canyou":"您能...",
  "patient_hand_uram_q1":"用法兰绒清洗身体，保持手平放",
  "patient_hand_uram_q1_a1":"毫无困难",
  "patient_hand_uram_q1_a2":"几乎没有什么困难",
  "patient_hand_uram_q1_a3":"有一些困难",
  "patient_hand_uram_q1_a4":"困难重重",
  "patient_hand_uram_q1_a5":"几乎不可能",
  "patient_hand_uram_q1_a6":"不可能",
  "patient_hand_uram_q2":"洗脸",
  "patient_hand_uram_q2_a1":"毫无困难",
  "patient_hand_uram_q2_a2":"几乎没有什么困难",
  "patient_hand_uram_q2_a3":"有一些困难",
  "patient_hand_uram_q2_a4":"困难重重",
  "patient_hand_uram_q2_a5":"几乎不可能",
  "patient_hand_uram_q2_a6":"不可能",
  "patient_hand_uram_q3":"一只手握住一个瓶子",
  "patient_hand_uram_q3_a1":"毫无困难",
  "patient_hand_uram_q3_a2":"几乎没有什么困难",
  "patient_hand_uram_q3_a3":"有一些困难",
  "patient_hand_uram_q3_a4":"困难重重",
  "patient_hand_uram_q3_a5":"几乎不可能",
  "patient_hand_uram_q3_a6":"不可能",
  "patient_hand_uram_q4":"和别人握手",
  "patient_hand_uram_q4_a1":"毫无困难",
  "patient_hand_uram_q4_a2":"几乎没有什么困难",
  "patient_hand_uram_q4_a3":"有一些困难",
  "patient_hand_uram_q4_a4":"困难重重",
  "patient_hand_uram_q4_a5":"几乎不可能",
  "patient_hand_uram_q4_a6":"不可能",
  "patient_hand_uram_q5":"抚摸某物或爱抚某人",
  "patient_hand_uram_q5_a1":"毫无困难",
  "patient_hand_uram_q5_a2":"几乎没有什么困难",
  "patient_hand_uram_q5_a3":"有一些困难",
  "patient_hand_uram_q5_a4":"困难重重",
  "patient_hand_uram_q5_a5":"几乎不可能",
  "patient_hand_uram_q5_a6":"不可能",
  "patient_hand_uram_q6":"拍拍你的手",
  "patient_hand_uram_q6_a1":"毫无困难",
  "patient_hand_uram_q6_a2":"几乎没有什么困难",
  "patient_hand_uram_q6_a3":"有一些困难",
  "patient_hand_uram_q6_a4":"困难重重",
  "patient_hand_uram_q6_a5":"几乎不可能",
  "patient_hand_uram_q6_a6":"不可能",
  "patient_hand_uram_q7":"张开你的手指",
  "patient_hand_uram_q7_a1":"毫无困难",
  "patient_hand_uram_q7_a2":"几乎没有什么困难",
  "patient_hand_uram_q7_a3":"有一些困难",
  "patient_hand_uram_q7_a4":"困难重重",
  "patient_hand_uram_q7_a5":"几乎不可能",
  "patient_hand_uram_q7_a6":"不可能",
  "patient_hand_uram_q8":"靠在你的手上",
  "patient_hand_uram_q8_a1":"毫无困难",
  "patient_hand_uram_q8_a2":"几乎没有什么困难",
  "patient_hand_uram_q8_a3":"有一些困难",
  "patient_hand_uram_q8_a4":"困难重重",
  "patient_hand_uram_q8_a5":"几乎不可能",
  "patient_hand_uram_q8_a6":"不可能",
  "patient_hand_uram_q9":"用拇指和食指捡起小物体",
  "patient_hand_uram_q9_a1":"毫无困难",
  "patient_hand_uram_q9_a2":"几乎没有什么困难",
  "patient_hand_uram_q9_a3":"有一些困难",
  "patient_hand_uram_q9_a4":"困难重重",
  "patient_hand_uram_q9_a5":"几乎不可能",
  "patient_hand_uram_q9_a6":"不可能",
  "patient_hand_quality_of_life":"生活质量",
  "patient_hand_quality_of_life_instruction":"请对以下内容进行评分，以最准确地描述您当前的健康状况：",
  "patient_hand_quality_of_life_instruction_0":"没有问题",
  "patient_hand_quality_of_life_instruction_1":"稍微",
  "patient_hand_quality_of_life_instruction_2":"中度",
  "patient_hand_quality_of_life_instruction_3":"严重",
  "patient_hand_quality_of_life_instruction_4":"极度/无法",
  "patient_hand_quality_of_life_q1":"走路的问题",
  "patient_hand_quality_of_life_q1_a1":"没有问题",
  "patient_hand_quality_of_life_q1_a2":"稍微",
  "patient_hand_quality_of_life_q1_a3":"中度",
  "patient_hand_quality_of_life_q1_a4":"严重",
  "patient_hand_quality_of_life_q1_a5":"极度/无法",
  "patient_hand_quality_of_life_q2":"自己洗澡或穿衣有问题",
  "patient_hand_quality_of_life_q2_a1":"没有问题",
  "patient_hand_quality_of_life_q2_a2":"稍微",
  "patient_hand_quality_of_life_q2_a3":"中度",
  "patient_hand_quality_of_life_q2_a4":"严重",
  "patient_hand_quality_of_life_q2_a5":"极度/无法",
  "patient_hand_quality_of_life_q3":"进行日常活动（例如工作、学习、家务、家庭或休闲活动）时出现问题",
  "patient_hand_quality_of_life_q3_a1":"没有问题",
  "patient_hand_quality_of_life_q3_a2":"稍微",
  "patient_hand_quality_of_life_q3_a3":"中度",
  "patient_hand_quality_of_life_q3_a4":"严重",
  "patient_hand_quality_of_life_q3_a5":"极度/无法",
  "patient_hand_quality_of_life_q4":"疼痛或不舒服",
  "patient_hand_quality_of_life_q4_a1":"无",
  "patient_hand_quality_of_life_q4_a2":"稍微",
  "patient_hand_quality_of_life_q4_a3":"中度",
  "patient_hand_quality_of_life_q4_a4":"严重",
  "patient_hand_quality_of_life_q4_a5":"极度",
  "patient_hand_quality_of_life_q5":"焦虑/抑郁",
  "patient_hand_quality_of_life_q5_a1":"没有焦虑/抑郁",
  "patient_hand_quality_of_life_q5_a2":"有些焦虑/抑郁",
  "patient_hand_quality_of_life_q5_a3":"适度焦虑/抑郁",
  "patient_hand_quality_of_life_q5_a4":"严重焦虑/抑郁",
  "patient_hand_quality_of_life_q5_a5":"极度焦虑/抑郁",
  "patient_hand_quality_of_life_q6":"您今天的健康状况如何？ （100 表示最佳健康状况，0 表示您可以想象的最差健康状况）",
  "patient_hand_quality_of_life_q6_a1":"最差",
  "patient_hand_quality_of_life_q6_a2":"最好",
  "patient_hand_satisfaction":"满意感",
  "patient_hand_satisfaction_instrucion":"请说明您对以下方面的满意度：",
  "patient_hand_satisfaction_instrucion_0":"很不满意",
  "patient_hand_satisfaction_instrucion_1":"不满意",
  "patient_hand_satisfaction_instrucion_2":"既满意也不不满意",
  "patient_hand_satisfaction_instrucion_3":"有点满意",
  "patient_hand_satisfaction_instrucion_4":"很满意",
  "patient_hand_satisfaction_q1":"您手指的动作",
  "patient_hand_satisfaction_q1_na":"不适用",
  "patient_hand_satisfaction_q1_a1":"很不满意",
  "patient_hand_satisfaction_q1_a2":"不满意",
  "patient_hand_satisfaction_q1_a3":"既满意也不不满意",
  "patient_hand_satisfaction_q1_a4":"有点满意",
  "patient_hand_satisfaction_q1_a5":"很满意",
  "patient_hand_satisfaction_q2":"您手腕的动作",
  "patient_hand_satisfaction_q2_na":"不适用",
  "patient_hand_satisfaction_q2_a1":"很不满意",
  "patient_hand_satisfaction_q2_a2":"不满意",
  "patient_hand_satisfaction_q2_a3":"既满意也不不满意",
  "patient_hand_satisfaction_q2_a4":"有点满意",
  "patient_hand_satisfaction_q2_a5":"很满意",
  "patient_hand_satisfaction_q3":"您手的样子",
  "patient_hand_satisfaction_q3_na":"不适用",
  "patient_hand_satisfaction_q3_a1":"很不满意",
  "patient_hand_satisfaction_q3_a2":"不满意",
  "patient_hand_satisfaction_q3_a3":"既满意也不不满意",
  "patient_hand_satisfaction_q3_a4":"有点满意",
  "patient_hand_satisfaction_q3_a5":"很满意",
  "patient_hand_satisfaction_q4":"您的手/手腕治疗",
  "patient_hand_satisfaction_q4_na":"不适用",
  "patient_hand_satisfaction_q4_a1":"很不满意",
  "patient_hand_satisfaction_q4_a2":"不满意",
  "patient_hand_satisfaction_q4_a3":"既满意也不不满意",
  "patient_hand_satisfaction_q4_a4":"有点满意",
  "patient_hand_satisfaction_q4_a5":"很满意",
  "patient_hand_complete":"问卷结束",
  "patient_hand_complete_text1":"感谢您抽出宝贵时间填写此表格。",
  "patient_hand_complete_text2":"请点击提交完成并查看您的分数。",
  "patient_hand_complete_text3":"您的回答将被保密，任何报告都将仅使用去识别化的咨讯。",
  "patient_hand_result":"结果",
  "patient_hand_result_text1":"以下是您的疼痛以及手部和腕部功能评分，这些评分是根据您的调查问卷计算得出的。",
  "patient_hand_result_function":"功能",
  "patient_hand_result_pain":"疼痛",
  "patient_hand_result_health_perception":"整体健康认知",
  "patient_hand_result_self_evaluation":"手和手腕评分自我评估",
  "patient_hand_result_text2":"您的医生很快就会和你联系。",
  "patient_general_history":"目前的主诉症状/伤害/疾病史",
  "patient_general_history_q1":"主诉症状（用自己的话解释您的症状）",
  "patient_general_history_q2":"问题开始日期",
  "patient_general_history_q3":"有做过影像学检查吗？",
  "patient_general_history_q3_a1":"無",
  "patient_general_history_q3_a2":"X射线",
  "patient_general_history_q3_a3":"超声波",
  "patient_general_history_q3_a4":"核磁共振成像",
  "patient_general_history_q3_a5":"MRI 关节造影（关节内染色 MRI）",
  "patient_general_history_q3_a6":"CT",
  "patient_general_history_q3_a7":"CT 关节造影（关节内染色 CT）",
  "patient_general_history_q4":"您是否对您的病情进行了上面未列出的进一步调查？",
  "patient_general_history_q4_placeholder":"其他调查",
  "patient_general_past_history":"既往病史/当前的医疗问题",
  "patient_general_past_history_q1":"您有已知的过敏史吗？ （药物或食物）",
  "patient_general_past_history_q1_a1":"否",
  "patient_general_past_history_q1_a2":"是",
  "patient_general_past_history_placeholder":"指定过敏和反应类型",
  "patient_general_past_history_q2":"您对乳胶过敏吗？",
  "patient_general_past_history_q2_a1":"否",
  "patient_general_past_history_q2_a2":"是",
  "patient_general_past_history_q3":"您是否患有高血压或正在接受高血压治疗？",
  "patient_general_past_history_q3_a1":"否",
  "patient_general_past_history_q3_a2":"是",
  "patient_general_past_history_q3_a2_placeholder":"指定治疗高血压的药物",
  "patient_general_past_history_q4":"您目前或曾经患有任何心脏病/疾病吗？",
  "patient_general_past_history_q4_a1":"否",
  "patient_general_past_history_q4_a2":"是",
  "patient_general_past_history_q4_a2_o1":"选择条件",
  "patient_general_past_history_q4_a2_o2":"心绞痛",
  "patient_general_past_history_q4_a2_o3":"心脏病发作",
  "patient_general_past_history_q4_a2_o4":"心力衰竭（肺部积液）",
  "patient_general_past_history_q4_a2_o5":"杂音或心脏瓣膜问题",
  "patient_general_past_history_q4_a2_o6":"心悸/心跳不规则",
  "patient_general_past_history_q4_a2_o7":"植入装置（ICD、起搏器）",
  "patient_general_past_history_q4_a2_o8":"心脏手术",
  "patient_general_past_history_q4_a2_o9":"颈动脉手术",
  "patient_general_past_history_q4_a2_o10":"其他心脏病",
  "patient_general_past_history_q4_a2_o8_q1_placeholder":"指定心脏手术",
  "patient_general_past_history_q4_a2_o10_q1_placeholder":"指定其他心脏状况",
  "patient_general_past_history_q5":"您有心脏病专家吗？",
  "patient_general_past_history_q5_a1":"否",
  "patient_general_past_history_q5_a2":"是",
  "patient_general_past_history_q5_a2_placeholder":"名称与实习地址",
  "patient_general_past_history_q6":"您的腿部循环有问题（周围血管疾病）吗？",
  "patient_general_past_history_q6_a1":"否",
  "patient_general_past_history_q6_a2":"是",
  "patient_general_past_history_q6_a2_placeholder":"请形容",
  "patient_general_past_history_q7":"您上个月服用过血液稀释剂吗？",
  "patient_general_past_history_q7_a1":"否",
  "patient_general_past_history_q7_a2":"是",
  "patient_general_past_history_q7_a2_q1":"是什么？",
  "patient_general_past_history_q7_a2_q1_a1":"阿司匹林",
  "patient_general_past_history_q7_a2_q1_a2":"氯吡格雷/波立维",
  "patient_general_past_history_q7_a2_q1_a3":"利伐沙班",
  "patient_general_past_history_q7_a2_q1_a4":"埃利奎斯 (阿哌沙班)",
  "patient_general_past_history_q7_a2_q1_a5":"其他",
  "patient_general_past_history_q7_a2_q1_a5_placeholder":"指定血液稀释剂",
  "patient_general_past_history_q8":"您的肺部或胸部有任何问题吗？",
  "patient_general_past_history_q8_a1":"否",
  "patient_general_past_history_q8_a2":"是",
  "patient_general_past_history_q8_a2_o1":"咳嗽有痰？",
  "patient_general_past_history_q8_a2_o2":"你的呼吸有什么问题吗？",
  "patient_general_past_history_q8_a2_o3":"其他的",
  "patient_general_past_history_q8_a2_o2_o1":"哮喘",
  "patient_general_past_history_q8_a2_o2_o2":"肺部高血压（肺动脉高压）",
  "patient_general_past_history_q8_a2_o2_o3":"肺纤维化",
  "patient_general_past_history_q8_a2_o2_o4":"结节病",
  "patient_general_past_history_q8_a2_o2_o5":"慢性阻塞性肺病或肺气肿",
  "patient_general_past_history_q8_a2_o2_o6":"囊性纤维化",
  "patient_general_past_history_q8_a2_o2_o7":"结核病（结核病）",
  "patient_general_past_history_q8_a2_o2_o8":"其他的",
  "patient_general_past_history_q8_a2_o3_placehold":"请注明",
  "patient_general_past_history_q8_a2_o2_o8_placeholder":"请注明",
  "patient_general_past_history_q9":"您去年是否服用过类固醇（泼尼松或可的松）？",
  "patient_general_past_history_q9_a1":"否",
  "patient_general_past_history_q9_a2":"是",
  "patient_general_past_history_q10":"您使用家用氧气吗？",
  "patient_general_past_history_q10_a1":"否",
  "patient_general_past_history_q10_a2":"是",
  "patient_general_past_history_q11":"您有呼吸科专家吗？",
  "patient_general_past_history_q11_a1":"否",
  "patient_general_past_history_q11_a2":"是",
  "patient_general_past_history_q11_a2_placeholder":"名称与实习地址",
  "patient_general_past_history_q12":"您是否有过度打鼾或睡眠呼吸暂停的情况？",
  "patient_general_past_history_q12_a1":"否",
  "patient_general_past_history_q12_a2":"是",
  "patient_general_past_history_q12_a2_q1":"您的睡眠呼吸暂停是否使用 CPAP 或 BiPAP 治疗？",
  "patient_general_past_history_q12_a2_q1_a1":"否",
  "patient_general_past_history_q12_a2_q1_a2":"是",
  "patient_general_past_history_q13":"您曾经有过肝脏问题吗？",
  "patient_general_past_history_q13_a1":"否",
  "patient_general_past_history_q13_a2":"是",
  "patient_general_past_history_q13_a2_o1":"已选择",
  "patient_general_past_history_q13_a2_o2":"肝硬化",
  "patient_general_past_history_q13_a2_o3":"甲型、乙型或丙型肝炎",
  "patient_general_past_history_q13_a2_o4":"黄疸",
  "patient_general_past_history_q13_a2_o5":"其他的",
  "patient_general_past_history_q13_a2_o5_placeholder":"请注明",
  "patient_general_past_history_q14":"您是否经常出现胃灼热、溃疡或食管裂孔疝？",
  "patient_general_past_history_q14_a1":"否",
  "patient_general_past_history_q14_a2":"是",
  "patient_general_past_history_q15":"您曾经患过肾脏疾病吗？",
  "patient_general_past_history_q15_a1":"否",
  "patient_general_past_history_q15_a2":"是",
  "patient_general_past_history_q15_a2_q1":"您是透析患者吗？ 腹膜？ 血液透析",
  "patient_general_past_history_q15_a2_q1_a1":"否",
  "patient_general_past_history_q15_a2_q1_a2":"是",
  "patient_general_past_history_q15_a2_placeholder":"明确肾脏疾病",
  "patient_general_past_history_q15_a2_q1_a2_placeholder":"指定透析的类型和时间表",
  "patient_general_past_history_q16":"你有糖尿病吗？",
  "patient_general_past_history_q16_a1":"否",
  "patient_general_past_history_q16_a2":"是",
  "patient_general_past_history_q16_a2_q1":"您的糖尿病是如何控制的？ 饮食/口服药物或胰岛素？",
  "patient_general_past_history_q16_a2_q1_placeholder":"请注明",
  "patient_general_past_history_q17":"您有甲状腺问题吗？",
  "patient_general_past_history_q17_a1":"否",
  "patient_general_past_history_q17_a2":"是",
  "patient_general_past_history_q18":"您是否患有癫痫、惊厥或抽搐？",
  "patient_general_past_history_q18_a1":"否",
  "patient_general_past_history_q18_a2":"是",
  "patient_general_past_history_q18_a2_q1":"您最后一次癫痫发作是什么时候？",
  "patient_general_past_history_q18_a2_q1_placeholder":"请注明",
  "patient_general_past_history_q19":"您的神经或肌肉有问题吗？",
  "patient_general_past_history_q19_a1":"否",
  "patient_general_past_history_q19_a2":"是",
  "patient_general_past_history_q19_a2_o1":"癫痫发作",
  "patient_general_past_history_q19_a2_o1_placeholder":"什么时候 ？",
  "patient_general_past_history_q19_a2_o2":"TIA（短暂性脑缺血发作/小中风）或中风",
  "patient_general_past_history_q19_a2_o2_placeholder":"什么时候 ？",
  "patient_general_past_history_q19_a2_o3":"面部、腿部或手臂无力",
  "patient_general_past_history_q19_a2_o4":"神经系统疾病（例如：多发性硬化症、ALS、阿尔茨海默病）",
  "patient_general_past_history_q19_a2_o4_placeholder":"请注明",
  "patient_general_past_history_q19_a2_o5":"肌肉疾病（例如：重症肌无力、肌营养不良）",
  "patient_general_past_history_q19_a2_o5_placeholder":"请注明",
  "patient_general_past_history_q19_a2_o6":"听力、视力或记忆力问题",
  "patient_general_past_history_q19_a2_o7":"慢性疼痛",
  "patient_general_past_history_q19_a2_o8":"其他的",
  "patient_general_past_history_q19_a2_o8_placeholder":"请注明",
  "patient_general_past_history_q20":"你有关节炎吗？",
  "patient_general_past_history_q20_a1":"否",
  "patient_general_past_history_q20_a2":"是",
  "patient_general_past_history_q20_a2_o1":"骨关节炎",
  "patient_general_past_history_q20_a2_o2":"类风湿关节炎",
  "patient_general_past_history_q20_a2_o3":"其他的",
  "patient_general_past_history_q20_a2_o3_placeholder":"请注明",
  "patient_general_past_history_q21":"您曾经接受过精神疾病治疗吗？",
  "patient_general_past_history_q21_a1":"否",
  "patient_general_past_history_q21_a2":"是",
  "patient_general_past_history_q21_a2_o1":"严重焦虑",
  "patient_general_past_history_q21_a2_o2":"沮丧",
  "patient_general_past_history_q21_a2_o3":"其他的",
  "patient_general_past_history_q21_a2_o3_placeholder":"请注明",
  "patient_general_past_history_q22":"您是否曾被诊断出患有出血性疾病？",
  "patient_general_past_history_q22_a1":"否",
  "patient_general_past_history_q22_a2":"是",
  "patient_general_past_history_q22_a2_o1":"白血病或淋巴瘤",
  "patient_general_past_history_q22_a2_o2":"血友病",
  "patient_general_past_history_q22_a2_o3":"血块",
  "patient_general_past_history_q22_a2_o4":"贫血",
  "patient_general_past_history_q22_a2_o5":"镰状细胞性贫血症",
  "patient_general_past_history_q22_a2_o6":"其他的",
  "patient_general_past_history_q22_a2_o6_placeholder":"请注明",
  "patient_general_past_history_q23":"你贫血吗？",
  "patient_general_past_history_q23_a1":"否",
  "patient_general_past_history_q23_a2":"是",
  "patient_general_past_history_q24":"您的腿部或肺部是否曾出现过血栓？",
  "patient_general_past_history_q24_a1":"否",
  "patient_general_past_history_q24_a2":"是",
  "patient_general_past_history_q25":"您或您家人中的任何人是否遇到过以下严重问题：",
  "patient_general_past_history_q25_a1":"鼻子流血",
  "patient_general_past_history_q25_a1_o1":"否",
  "patient_general_past_history_q25_a1_o2":"是",
  "patient_general_past_history_q25_a2":"拔牙时出血",
  "patient_general_past_history_q25_a2_o1":"否",
  "patient_general_past_history_q25_a2_o2":"是",
  "patient_general_past_history_q25_a3":"手术后出血",
  "patient_general_past_history_q25_a3_o1":"否",
  "patient_general_past_history_q25_a3_o2":"是",
  "patient_general_past_history_q26":"您曾经被诊断出患有癌症吗？",
  "patient_general_past_history_q26_a1":"否",
  "patient_general_past_history_q26_a2":"是",
  "patient_general_past_history_q26_a2_placeholder1":"指定癌症类型",
  "patient_general_past_history_q26_a2_placeholder2":"什么时候？",
  "patient_general_past_history_q27":"您曾经接受过化疗或放疗吗？",
  "patient_general_past_history_q27_a1":"否",
  "patient_general_past_history_q27_a2":"是",
  "patient_general_past_history_q27_a2_placeholder1":"治疗名称",
  "patient_general_past_history_q27_a2_placeholder2":"最后一次治疗日期",
  "patient_general_past_history_q28":"您或您的亲属在麻醉方面有任何问题吗？",
  "patient_general_past_history_q28_a1":"否",
  "patient_general_past_history_q28_a2":"是",
  "patient_general_past_history_q28_a2_q1":"哪些问题？",
  "patient_general_past_history_q28_a2_q1_placeholder":"选择",
  "patient_general_past_history_q28_a2_q1_o1":"手术后严重恶心或呕吐",
  "patient_general_past_history_q28_a2_q1_o2":"恶性高热（有血缘关系的家庭或您自己）",
  "patient_general_past_history_q28_a2_q1_o3":"呼吸问题或插入麻醉呼吸管困难？",
  "patient_general_past_history_q28_a2_q1_o4":"呼吸管放置问题",
  "patient_general_past_history_q28_a2_q1_o5":"其他的",
  "patient_general_past_history_q28_a2_q1_o5_placeholder":"请注明",
  "patient_general_past_history_q29":"您有以下情况吗？",
  "patient_general_past_history_q29_q1":"牙齿、假牙、局部假牙破损或松动",
  "patient_general_past_history_q29_q1_a1":"否",
  "patient_general_past_history_q29_q1_a2":"是",
  "patient_general_past_history_q29_q2":"颈部活动时困难",
  "patient_general_past_history_q29_q2_a1":"否",
  "patient_general_past_history_q29_q2_a2":"是",
  "patient_general_past_history_q29_q3":"张嘴有问题",
  "patient_general_past_history_q29_q3_a1":"否",
  "patient_general_past_history_q29_q3_a2":"是",
  "patient_general_past_history_q30":"你可能怀孕吗？",
  "patient_general_past_history_q30_a1":"否",
  "patient_general_past_history_q30_a2":"是",
  "patient_general_past_history_q30_not_applicable":"不适用",
  "patient_general_past_history_q30_a2_q1":"末次月经",
  "patient_general_past_history_q30_a2_q1_placeholder":"末次月经",
  "patient_general_past_history_q31":"病史：请列出您患有上面未注明的任何其他疾病",
  "patient_general_past_history_q31_q1":"列出所有药物（包括非处方药、吸入器、贴剂、滴剂、维生素、矿物质、补充剂、草药）",
  "patient_general_past_history_q31_q2":"注明药物名称、剂量、频率和服用原因",
  "patient_general_past_history_q31_q2_placeholder":"药物名称、剂量、频率、服用原因",
  "patient_general_past_history_q31_q3":"剂量",
  "patient_general_past_history_q31_q4":"使用多常",
  "patient_general_past_history_q31_q5":"服用理由",
  "patient_general_past_history_q31_q6":"手术史",
  "patient_general_past_history_q31_q6_placeholder":"请注明手术和手术日期",
  "patient_general_social_history":"社会史",
  "patient_general_social_history_q1":"你独自生活吗？",
  "patient_general_social_history_q1_a1":"否",
  "patient_general_social_history_q1_a2":"是",
  "patient_general_social_history_q1_a1_q1":"你和谁一起生活？",
  "patient_general_social_history_q1_a1_q1_placeholder":"你和谁一起生活？",
  "patient_general_social_history_q2":"你抽烟吗？",
  "patient_general_social_history_q2_a1":"否",
  "patient_general_social_history_q2_a2":"是",
  "patient_general_social_history_q2_a2_q1":"每天吸多少支烟？",
  "patient_general_social_history_q2_a2_q1_placeholder":"香烟数量",
  "patient_general_social_history_q2_a2_q2":"年数？",
  "patient_general_social_history_q2_a2_q2_placeholder":"年",
  "patient_general_social_history_q3":"您过去吸烟吗？",
  "patient_general_social_history_q3_a1":"否",
  "patient_general_social_history_q3_a2":"是",
  "patient_general_social_history_q3_a2_q1":"你什么时候戒烟的？",
  "patient_general_social_history_q3_a2_q1_placeholder":"你什么时候戒烟的？",
  "patient_general_social_history_q3_a2_q2_placeholder":"年",
  "patient_general_social_history_q4":"你喝酒吗？",
  "patient_general_social_history_q4_a1":"否",
  "patient_general_social_history_q4_a2":"是",
  "patient_general_social_history_q4_a2_q1_placeholder":"每周标准饮酒量",
  "patient_general_social_history_q5":"您是否使用过或曾经使用过任何非处方药或街头（非法）毒品、大麻或阿片类药物？",
  "patient_general_social_history_q5_a1":"否",
  "patient_general_social_history_q5_a2":"是",
  "patient_general_social_history_q5_a2_placeholder":"Specify drugs",
  "patient_general_complete":"问卷结束",
  "patient_general_complete_text1":"感谢您抽出宝贵时间填写此表格。",
  "patient_general_complete_text2":"请点击提交完成并查看您的分数。",
  "patient_general_complete_text3":"您的回答将被保密，任何报告都将仅使用去识别化的咨讯。",
  "AppVersion":"应用程序版本",
  "Complete_personal_info":"完整的个人信息",
  "To_complete_the_sign_up":"要完成注册，请在下面填写您的信息。",
  "Select_Existing_Profile":"选择现有配置文件",
  "Get_In":"输入",
  "Create_new_patient":"创建一个新患者",
  "myProfile":"我的简历",
  "First_Name":"名*",
  "Last_Name":"姓*",
  "DOB":"出生日期*",
  "Sex":"性别*",
  "Address":"地址*",
  "Postcode":"邮政编码*",
  "Suburb":"市郊*",
  "Country_Code":"国家代码*",
  "Mobile_Phone":"手机*",
  "Home_Phone":"家庭电话",
  "Email":"电子邮件*",
  "Emergency_Contact":"紧急联系人*",
  "Phone":"电话*",
  "Relationship":"关系*",
  "Regular_GP_Name":"常规全科医生姓名*",
  "Practice_Name":"执业名称*",
  "Medicare_Card_Number":"医疗保险卡号码",
  "Ref":"参考号",
  "Message":"留言",
  "Expiry":"到期",
  "Private_Health_Fund":"私人医疗基金",
  "Membership_Number":"会员号码",
  "Claim_Type":"索赔类型",
  "Work_Cover":"工作罩",
  "Employer":"雇主",
  "Third_Party_Claim_Number":"第三方索赔编号",
  "Third_Party_Name":"第三方名称",
  "DVA_Concession_Card":"DVA 优惠卡",
  "Number":"数字",
  "Defence_Personnel":"国防人员",
  "I_agree":"我同意处理我的个人数据并已阅读",
  "Privacy_Policy":"隐私政策。",
  "visitClinic":"预约",
  "selectdocOrg":"选择医生和组织*",
  "doctorOrganization":"医生组织”",
  "Select_Treatment_Category":"选择治疗类别*",
  "Do_you_want_to_submit_patient_form_or_not":"您是否要提交患者表格？",
  "areyousurewanttocontinueyourform":"您之前的表格正在等待处理。 您想继续吗？",
  "myVisit":"过去的访问",
  "View_score":"查看分数",
  "upcoming_visits":"即将推出",
  "No_data_found":"没有找到数据",
  "Doctor_Name":"医生姓名",
  "Appointment_Date_Time":"预约日期和时间",
  "Clinic_Name":"诊所名称",
  "Clinic_Address":"诊所地址",
  "akmed":"阿库纳·梅德 （AKUNAH MED）",
  "Type":"打字",
  "Date_Time":"日期 & 时间",
  "Action":"动作",
  "View":"查看",
  "No_Messages":"没有留言",
  "settings":"设置",
  "Language":"语言",
  "language":"语言及地区",
  "timezone":"时区",
  "Timeformat":"时间格式",
  "List_of_my_profile":"我的个人资料",
  "next_button":"下一步",
  "back_button":"后退",
  "Continue":"继续",
  "Close":"关闭",
  "submit":"提交",
  "Sign_out":"返回个人资料",
  "Results":"结果",
  "update":"表格更新成功",
  "Personal_Data":"个人资料",
  "Date_of_Birth":"出生日期",
  "Hip_arthritis":"髋关节炎？*",
  "Area_of_foot_ankle":"脚/脚踝受影响的区域？*",
  "Diagnosis":"诊断 ？*",
  "Evaluated_Shoulder":"肩部评估",
  "Dominant_Hand":"惯用手*",
  "Age":"年龄",
  "No":"否",
  "Yes":"是",
  "visitNo":"访问否",
  "visitYes":"访问是",
  "shoulderPainR":"肩痛",
  "Is_it_initial_visit":"这是您初次访问吗",
  "for_the_problem":"对于这个问题？*",
  "Diagnosis_Procedure":"诊断/程序",
  "Procedure":"程序",
  "Evaluated_sdfs":"已评估*",
  "Please_Select_previous_visits":"请选择之前访问*",
  "Follow_Up":"跟进",
  "Assessment_Date":"评估日期",
  "Do_you_have_Dupuytren_disease":"您患有掌腱膜挛缩症吗？",
  "Do_you_have_carpal_tunnel_syndrome":"您患有腕管综合症吗？",
  "Is_your_unaffected_shoulder_pain":"您未受影响的肩膀是否无疼痛且功能正常？",
  "Is_your_unaffected_hip_pain":"您未受影响的髋部是否无疼痛且功能正常？",
  "Is_your_unaffected_knee_pain":"您未受影响的膝盖是否无疼痛且功能正常？",
  "Is_your_unaffected_elbow_pain":"您未受影响的肘部是否无疼痛且功能正常？",
  "Is_your_unaffected_foot_and_ankle_pain":"您未受影响的脚和脚踝是否无疼痛且功能正常？",
  "Is_your_unaffected_hand_pain":"您未受影响的手和手腕是否无疼痛且功能正常？",
  "Patient_Consent":"Akunah 患者信息和免费事先同意书",
  "Patient_Last_Name":"患者姓氏",
  "Patient_First_Name":"患者名字",
  "Patient_Date_of_Birth":"患者出生日期",
  "information":"信息",
  "I_am_informedDDD":"为了对您的骨科治疗进行评估、计划和结果评估，您的医生，",
  "Clinician":"（以下简称“临床医生”）使用 Akunah Medical Technology Pty Ltd 及其子公司（“Akunah”）提供的评估、结果数据收集和分析和/或术前规划服务和软件。",
  "For_that":"为此，临床医生会将您的个人数据传输给 Akunah，包括您身体的计算机断层扫描 (CT) 扫描和其他医疗数据。",
  "Akunah_softawre":"Akunah 的软件和服务可能涉及图像评估、医疗数据评估、创建个性化解剖结构 3D 模型以及关键解剖测量、手术计划和手术支持，这些信息将与临床医生共享。",
  "the_clinic":"临床医生将使用评估和术前计划来进行您的骨科治疗。",
  "oportunnity":"您还有机会通过 Akunah 的患者门户提供个人数据并访问 Akunah 的模型和计划。",
  "akunah_approch":"Akunah 的隐私和安全方法",
  "akunah_approch1":"Akunah 将从您那里收集数据以协助您的临床医生，这是我们最关心的问题。",
  "akunah_approch2":"Akunah 自愿遵守适用的欧洲数据保护法，其中包括欧盟通用数据保护条例 EU/2016/679，因为这些法律是全球颁布的最强有力的隐私法。 这些法律超出了澳大利亚、美国和其他国家颁布的隐私法。",
  "akunah_approch3":"Akunah 的“处理客户个人信息的隐私准则”描述了 Akunah 如何遵守适用的欧洲数据保护法（",
  "akunah_approch4":"隐私守则",
  "akunah_approch5":"Akunah 的“数据处理协议”描述了其与临床医生为保护和确保您的个人信息安全而做出的安排（",
  "akunah_approch6":"数据处理协议",
  "akunah_approch7":"这些政策可在 Akunah 网站上找到：",
  "terms_":"https://akunah.com/termsofservices",
  "akunah_approch8":"如果隐私准则、数据处理协议和本文件之间存在任何不一致，则第一个提到的文件的条款将适用于与本文件的任何不一致之处。",
  "Data_Collection_and_Processing":"数据收集和处理",
  "I_am_informed":"Akunah 可能会收集您的数据，包括与您的联系信息、身份验证、身份、职业和人口统计相关的信息。 Akunah 还可能收集与您的健康相关的医疗数据，例如",
  "Assessment_and_evaluation":"评估和评价数据、身体特征以及与提议的治疗和结果相关的其他信息；",
  "Previous_surgery":"• 既往手术、诊断、放射扫描、3D 模型、解剖学、解剖测量； 和",
  "Health_and_medical_history":"• 健康和病史，包括您的症状、药物以及之前对您进行的任何诊断和治疗。",
  "I_have_been_assured":"Akunah 始终以合法、公平的方式收集信息，无论是直接从患者处、由临床医生共享还是通过 Akunah 产品收集。 Akunah 将根据您的同意（如下文所述）处理这些数据，以便在必要时提供评估和术前规划服务。",
  "My_data":"此外，Akunah 可能会使用您的个人数据来遵守所有适用的法律和法规",
  "your_data":"如果提供拟议服务或法律要求，您的数据也可能会披露给第三方。",
  "Data_Access_and_Storage":"数据访问、存储和安全",
  "Personal_data_provided":"您和您的临床医生提供的数据只能由 Akunah 和第三方的工作人员访问，他们为您的治疗或康复做出贡献（例如麻醉师、物理治疗师）并且需要访问您的数据。",
  "data1":"通过 Akunah 软件输入的数据由经批准的托管提供商安全地托管在澳大利亚联邦。 这可确保适当级别的机密性和安全性，反映通过 Akunah 软件处理的数据的性质。",
  "data2":"如果您位于澳大利亚联邦境外和/或通过 Akunah 软件输入的数据可能会被位于澳大利亚联邦境外（包括欧盟或美国）的 Akunah 子公司访问，则不同的隐私标准可能适用于您的数据的处理方式 在这些国家使用和保护。 在这种情况下，您的个人数据将存储在该国家/地区。",
  "data3":"Akunah 根据所有适用的法律要求采取适当的保障措施，以确保数据保密并受到所有第三方足够水平的数据保护。 我们要求所有有权访问的人采用确保与 Akunah 采用的数据保护水平相同的标准。",
  "data4":"您的临床医生有责任根据法律要求收集、访问、分析和共享您的数据。",
  "p_data1":"Akunah 保存您的个人数据的时间长度会有所不同。 保留期限将根据以下标准确定：(i) Akunah 或临床医生使用您的个人数据的目的（他们只需要在该目的所需的时间内保留数据）； (ii) 可能规定 Akunah 必须保留您的个人数据的最短期限的适用法律或法规。",
  "Deidentified_Data":"去识别化数据",
  "d_txt1":"Akunah 可能会对您的数据进行去识别化处理，然后使用这些去识别化数据（通常与其他患者的去识别化数据汇总）用于记录目的、研究和开发、数据分析、软件/设备开发以及 Akunah 产品和服务的改进 。 这使得 Akunah 能够不断改进他们的产品和服务，造福像您这样的患者。",
  "Patient_Rights":"您的权利",
  "I_understand_that":"您可以拒绝同意向 Akunah 提供您的个人数据。 在这种情况下，请咨询您的临床医生，因为如果不使用 Akunah 的软件和服务，您的临床医生可能无法为您提供医疗服务或治疗。",
  "I_understand_that_1txt":"您有权随时以任何方式撤回您的同意，并且在适用的情况下，您有权： (i) 获取有关您个人数据处理的信息并访问此类个人数据； (ii) 对不准确的地方要求其更正； (iii) 请求限制处理您的个人数据，包括反对对您的个人数据进行某些处理并要求撤回您提供的同意； (iv) 请求删除您的个人数据； (v) 制定与您去世后处理您的数据相关的准则； (vi) 获取在上述条件下以结构化、常用和机器可读格式处理的您的个人数据的副本。 您可以通过直接向临床医生提出您的请求来行使这些权利。",
  "I_understand_that_2txt":"您的权利受到适用法律的豁免和限制。",
  "I_understand_that_3txt":"退出后，如果您在建议的手术之前行使这些权利，您的临床医生可能无法为您提供医疗服务或治疗。",
  "I_understand_that_4txt":"您还可以自由地向您居住国的监管机构投诉您个人数据的处理情况。",
  "I_understand_that_5txt":"对于去识别化数据，去识别化数据不受与个人数据相同的法律限制。 请注意，您不能撤回对访问和使用您的去识别化数据的同意或请求删除这些数据，因为 Akunah 将无法找到您的数据。 去身份化后，Akuna 无法将数据追溯到您。",
  "Contact":"同意书",
  "I_am_able_to_contact":"我已阅读并理解上面的信息部分，或者有人用我理解的语言将其读给我听。",
  "or_by_post":"我有机会提出问题，我对收到的答案感到满意。 我了解我的信息将保密。",
  "or_by_post2":"我同意我的医生",
  "or_by_post2_1":"（“临床医生”）与 Akunah 分享我的个人数据。",
  "or_by_post3":"我同意 Akunah 收集、存储、使用和共享我的个人数据和去识别化数据，如上面信息部分所述。",
  "or_by_post4":"我了解我可以随时撤回我的同意。",
  "or_by_post5":"我了解我将收到一份已签名的本文件副本以供保存。",
  "or_by_post6":"我可以通过电子邮件联系 Akunah 处理我的数据",
  "info_akunah":"info@akunah.com",
  "or_by_post7":"或邮寄至 Suite 307 Nicholson Street Specialist Centre, Level 9, 121 Newdegate Street, Greenslopes, QLD, 4120, Australia。",
  "Clear":"清除",
  "Date":"日期",
  "Signed_by_Mouse":"鼠标签名 *",
  "Name_if_consent_on_behalf_of_patient":"姓名（如果代表患者同意）",
  "Relationship_to_patient_if_applicable":"与患者的关系（如果适用）",
  "Choose_Sign_Type":"选择签名方式*",
  "Draw":"画",
  "Signed_by_Keyboard":"键盘签名 *",
  "Agreement":"协议",
  "agree_that_I_have_read":"我同意我已阅读并理解本患者同意书，并且我明确同意出于上述目的处理我的个人数据。",
  "NOTE":"笔记",
  "If_the_patient":"如果患者未满 18 岁，则必须由其父母或法定监护人签署。",
  "If_the_patient_is_deceased":"如果患者已去世，则必须由其近亲签名。",
  "patient_signature":"患者签名",
  "profile_updated":"个人资料已更新",
  "profile_not_updated":"个人资料未更新",
  "video":"视频",
  "radiology_form":"放射学表格",
  "doc_document":"文档",
  "pathology_form":"病理表格",
  "text":"文本",
  "Document_Name":"文件名称",
  "email_sent_successfully":"删除请求发送成功。",
  "messages":"留言",
  "adult_knee":"成人膝关节",
  "paediatric_knee":"小儿膝关节",
  "document":"我的文件",
  "Check_in":"报到",
  "info_icon":"填写患者调查问卷",
  "reminder":"笔记",
  "Search_with_minimum_three_characters":"至少使用三个字符进行搜索",
  "Select":"选择",
  "delete_account":"删除帐户",
  "DD_MM_YYYY":"日/月/年",
  "Female":"女性",
  "Male":"男性",
  "want_to_disclose":"不想透露",
  "enter_address":"输入地址",
  "logout":"登出",
  "full_name":"全名",
  "mmyy":"月/年",
  "health_found":"健康基金",
  "Not_Applicable":"不适用",
  "not_available":"无法使用",
  "available":"可用的",
  "shared":"共享",
  "private":"私人的",
  "add_document":"添加文档",
  "drap_and_drop":"将文件拖放到此处",
  "or_browser_files":"或浏览计算机上的文件",
  "browse_files":"浏览文件",
  "download":"下载",
  "Oops_error":"哎呀！ 未找到您请求的数据。",
  "go_to_home":"返回首页",
  "This_field_is_required":"此字段是必需的",
  "PendingForm":"待处理表格",
  "NoFollowUpRecords":"无后续记录",
  "Right":"右",
  "Both":"双",
  "Left":"左",
  "shoulder":"肩膀",
  "hip_form":"髋部",
  "Foot_And_Ankle":"脚和脚踝",
  "Hand_And_Wrist":"手和手腕",
  "General":"一般",
  "adolescent_knee":"青少年膝盖",
  "Elbow":"肘部",
  "title":"标题",
  "description":"描述",
  "enter_your_description_here":"在此输入描述",
  "enter_your_title_here":"在此输入标题",
  "progress":"待办的",
  "done":"完全的",
  "reset":"复位",
  "send":"发送",
  "Edit":"修改",
  "delete":"删除",
  "Are_you_sure_want_to_done_the_remainder":"您确定要完成此笔记吗？",
  "Confirm":"确认",
  "Update":"更新",
  "success":"成功",
  "error":"出错",
  "Are_you_sure_you_want_to_delete":"你确定你要删除？",
  "cancel":"取消",
  "Hour_Time":"24 小时",
  "Knee":"膝盖",
  "no":"否",
  "yes":"是",
  "Year":"0年，0月，0日",
  "Bilateral":"双手灵巧",
  "Left_Arm":"左肩",
  "Right_Arm":"右肩",
  "out_of":"在之外",
  "Right_Elbow":"右肘",
  "Left_Elbow":"左肘",
  "right_foot_ankle":"右脚/脚踝",
  "left_foot_ankle":"左脚/脚踝",
  "Right_hand_and_wrist":"右手和手腕",
  "Left_hand_and_wrist":"左手和手腕",
  "Hip_Function_R":"右髋",
  "Hip_Function_L":"左髋",
  "Right_Knee":"右膝",
  "Left_Knee":"左膝",
  "Right_Pedi_Knee":"右膝",
  "Left_Pedi_Knee":"左膝",
  "Scoring_Self_Evaluation":"自我评价评分",
  "presciption_form":"处方",
  "insurance_form":"保险",
  "letter_form":"信件",
  "refferal_form":"转介",
  "medical_form":"医疗",
  "are_you_sure_delete_account":"您确定要删除您的帐户吗？",
  "Adult_Knee_Pain":"成人膝盖疼痛",
  "Knee_pain_13_18_year":"膝盖疼痛",
  "day_pdata":"天",
  "days_pdata":"天",
  "month_pdata":"月",
  "months_pdata":"几个月",
  "year_pdata":"年",
  "years_pdata":"年",
  "Name":"姓名",
  "akunah_id":"Akunah ID",
  "mobile":"电话",
  "email":"电子邮件",
  "upload_a_profile":"上传图片",
  "No_special_characters_are_allowed":"不允许使用特殊字符",
  "numbers_are_not_allowed":"不允许使用数字",
  "maximum_50_character_are_allowed":"最多允许 50 个字符",
  "invalid_character":"无效字符",
  "fields_only_contain_10_character":"字段仅包含 10 个字符",
  "maximum_10_Numbers_are_allowed":"最多允许 10 个号码",
  "Only_I":"只允许使用数字",
  "invalid_home_phone_number":"家庭电话号码无效",
  "special_characters_are_not_allowed":"不允许有特殊字符",
  "maximum_digit_are_allowed":"最多允许 1 位数字",
  "invalid_date":"失效日期",
  "maximum_10_digits_are_allowed":"最多允许 10 位数字",
  "switch_profile":"个人资料切换",
  "loading":"加载中",
  "error_max_profile_limit":"您已达到使用一个电子邮件地址允许的最大帐户数",
  "error_upcoming_appointments":"未找到即将到来的约会",
  "error_past_visits":"未找到过去的访问记录",
  "error_documents":"没有找到文件",
  "country_hawaii":"(UTC-10:00) 夏威夷时间",
  "country_tahiti":"(UTC-10:00) 塔希提岛",
  "country_pitcairn":"(UTC-08:00) 皮特凯恩",
  "country_niue":"(UTC-11:00) 纽埃",
  "country_mountain":"(UTC-07:00) 山地时间",
  "country_arizona":"(UTC-07:00) 山地时间 - 亚利桑那州",
  "country_whitehorse":"(UTC-08:00) 太平洋时间 - 怀特霍斯",
  "country_chihuahua":"(UTC-07:00) 山地时间 - 奇瓦瓦州、马萨特兰",
  "country_edmonton":"(UTC-07:00) 山地时间 - 埃德蒙顿",
  "country_gambier":"(UTC-09:00) 甘比尔",
  "country_yellowknife":"(UTC-07:00) 山地时间 - 黄刀镇",
  "country_dawson":"(UTC-07:00) 山地时间 - 道森溪",
  "country_belize":"(UTC-06:00) 伯利兹",
  "country_hermosillo":"(UTC-07:00) 山地时间 - 埃莫西约",
  "country_mexico":"(UTC-06:00) 中部时间 - 墨西哥城",
  "country_regina":"(UTC-06:00) 中部时间 - 里贾纳",
  "country_tegucigalpa":"(UTC-06:00) 中部时间 - 特古西加尔巴",
  "country_centraltime":"(UTC-06:00) 中部时间",
  "country_rarotonga":"(UTC-10:00) 拉罗汤加",
  "country_pago":"(UTC-11:00) 帕果帕果",
  "country_pacific":"(UTC-08:00) 太平洋时间",
  "country_alaska":"(UTC-09:00) 阿拉斯加时间",
  "country_vancouver":"(UTC-08:00) 太平洋时间 - 温哥华",
  "country_tijuana":"(UTC-08:00) 太平洋时间 - 蒂华纳",
  "country_salvador":"(UTC-06:00) 萨尔瓦多",
  "country_costa":"(UTC-06:00) 哥斯达黎加",
  "country_winnipeg":"(UTC-06:00) 中部时间 - 温尼伯",
  "country_galapagos":"(UTC-06:00) 加拉帕戈斯群岛",
  "country_managua":"(UTC-06:00) 马那瓜",
  "country_america_cancun":"(UTC-05:00) 美洲坎昆",
  "country_guatemala":"(UTC-06:00) 危地马拉",
  "country_bogota":"(UTC-05:00) 波哥大",
  "country_easter_island":"(UTC-05:00) 复活节岛",
  "country_iqaluit":"(UTC-05:00) 东部时间 - 伊魁特",
  "country_toronto":"(UTC-05:00) 东部时间 - 多伦多",
  "country_eastern_time":"(UTC-05:00) 东部时间",
  "country_havana":"(UTC-05:00) 哈瓦那",
  "country_lima":"(UTC-05:00) 利马",
  "country_jamaica":"(UTC-05:00) 牙买加",
  "country_panama":"(UTC-05:00) 巴拿马",
  "country_port_au_prince":"(UTC-05:00) 太子港",
  "country_nassau":"(UTC-05:00) 拿骚",
  "country_rio_branco":"(UTC-05:00) 里约布兰科",
  "country_barbados":"(UTC-04:00) 巴巴多斯",
  "country_halifax":"(UTC-04:00) 大西洋时间 - 哈利法克斯",
  "country_bermuda":"(UTC-04:00) 百慕大",
  "country_boa_vista":"(UTC-04:00) 博阿维斯塔",
  "country_curacao":"(UTC-04:00) 库拉索岛",
  "country_marquesas":"(UTC-09:30) 马克萨斯",
  "country_caracas":"(UTC-04:00) 加拉加斯",
  "country_grand_turk":"(UTC-04:00) 大特克岛",
  "country_la_paz":"(UTC-04:00) 拉巴斯",
  "timezone_guyana":"(UTC-04:00) 圭亚那",
  "timezone_port_of_spain":"(UTC-04:00) 西班牙港",
  "timezone_manaus":"(UTC-04:00) 马瑙斯",
  "timezone_martinique":"(UTC-04:00) 马提尼克岛",
  "timezone_porto_velho":"(UTC-04:00) 韦柳港",
  "timezone_santo_domingo":"(UTC-04:00) 圣多明各",
  "timezone_puerto_rico":"(UTC-04:00) 波多黎各",
  "timezone_araguaina":"(UTC-03:00) 阿拉瓜伊纳",
  "timezone_belem":"(UTC-03:00) 贝伦",
  "timezone_buenos_aires":"(UTC-03:00) 布宜诺斯艾利斯",
  "timezone_newfound_time":"(UTC-03:30) 纽芬兰时间 - 圣约翰斯",
  "timezone_asuncion":"(UTC-03:00) 亚松森",
  "timezone_campo":"(UTC-03:00) 大坎普",
  "timezone_cayenne":"(UTC-03:00) 卡宴",
  "timezone_cuiaba":"(UTC-03:00) 库亚巴",
  "timezone_maceio":"(UTC-03:00) 马塞约",
  "timezone_godthab":"(UTC-03:00) 戈德哈布",
  "timezone_fortaleza":"(UTC-03:00) 福塔雷萨",
  "timezone_miquelon":"(UTC-03:00) 密克隆群岛",
  "timezone_palmer":"(UTC-03:00) 帕尔默",
  "timezone_montevideo":"(UTC-03:00) 蒙得维的亚",
  "timezone_recife":"(UTC-03:00) 累西腓",
  "timezone_rothera":"(UTC-03:00) 罗瑟拉",
  "timezone_paramaribo":"(UTC-03:00) 帕拉马里博",
  "timezone_punta_arenas":"(UTC-03:00) 蓬塔阿雷纳斯",
  "timezone_santig":"(UTC-03:00) 圣地亚哥",
  "timezone_salvador":"(UTC-03:00) 萨尔瓦多",
  "timezone_stanley":"(UTC-03:00) 斯坦利",
  "timezone_south_georgia":"(UTC-02:00) 南乔治亚岛",
  "timezone_noronha":"(UTC-02:00) 诺罗尼亚群岛",
  "timezone_sao_pulo":"(UTC-02:00) 圣保罗",
  "timezone_azores":"(UTC-01:00) 亚速尔群岛",
  "timezone_abidjan":"(UTC+00:00) 阿比让",
  "timezone_cape_verde":"(UTC-01:00) 佛得角",
  "timezone_scoresbysund":"(UTC-01:00) 斯科斯比桑德",
  "timezone_bissau":"(UTC+00:00) 比绍",
  "timezone_accra":"(UTC+00:00) 阿克拉",
  "timezone_danmarkshavn":"(UTC+00:00) 丹麦港",
  "timezone_casablanca":"(UTC+00:00) 卡萨布兰卡",
  "timezone_dublin":"(UTC+00:00) 都柏林",
  "timezone_canary_islands":"(UTC+00:00) 加那利群岛",
  "timezone_el_aaiun":"(UTC+00:00) 阿尤恩",
  "timezone_thule":"(UTC-04:00) 图勒",
  "timezone_gmt":"(UTC+00:00) GMT（无夏令时）",
  "timezone_lisbon":"(UTC+00:00) 里斯本",
  "timezone_faeroe":"(UTC+00:00) 法罗",
  "timezone_reykjavik":"(UTC+00:00) 雷克雅未克",
  "timezone_monrovia":"(UTC+00:00) 蒙罗维亚",
  "timezone_algiers":"(UTC+01:00) 阿尔及尔",
  "timezone_london":"(UTC+00:00) 伦敦",
  "timezone_amsterdam":"(UTC+01:00) 阿姆斯特丹",
  "timezone_andorra":"(UTC+01:00) 安道尔",
  "timezone_berlin":"(UTC+01:00) 柏林",
  "timezone_budapest":"(UTC+01:00) 布达佩斯",
  "timezone_ceuta":"(UTC+01:00) 休达",
  "timezone_brussels":"(UTC+01:00) 布鲁塞尔",
  "timezone_copenhagen":"(UTC+01:00) 哥本哈根",
  "timezone_gibrltar":"(UTC+01:00) 直布罗陀",
  "timezone_prague":"(UTC+01:00) 中欧时间 - 布拉格",
  "timezone_lagos":"(UTC+01:00) 拉各斯",
  "timezone_luxembourg":"(UTC+01:00) 卢森堡",
  "timezone_madrid":"(UTC+01:00) 马德里",
  "timezone_malta":"(UTC+01:00) 马耳他",
  "timezone_ndjamena":"(UTC+01:00) 恩贾梅纳",
  "timezone_paris":"(UTC+01:00) 巴黎",
  "timezone_oslo":"(UTC+01:00) 奥斯陆",
  "timezone_monaco":"(UTC+01:00) 摩纳哥",
  "timezone_tirane":"(UTC+01:00) 地拉那",
  "timezone_rome":"(UTC+01:00) 罗马",
  "timezone_vienna":"(UTC+01:00) 维也纳",
  "timezone_tunis":"(UTC+01:00) 突尼斯",
  "timezone_warsaw":"(UTC+01:00) 华沙",
  "timezone_amman":"(UTC+02:00) 安曼",
  "timezone_zurich":"(UTC+01:00) 苏黎世",
  "timezone_athens":"(UTC+02:00) 雅典",
  "timezone_burcharest":"(UTC+02:00) 布加勒斯特",
  "timezone_chisinau":"(UTC+02:00) 基希讷乌",
  "timezone_stockholm":"(UTC+01:00) 斯德哥尔摩",
  "timezone_cairo":"(UTC+02:00) 开罗",
  "timezone_gaza":"(UTC+02:00) 加沙",
  "timezone_jerusalem":"(UTC+02:00) 耶路撒冷",
  "timezone_johannesburg":"(UTC+02:00) 约翰内斯堡",
  "timezone_helsinki":"(UTC+02:00) 赫尔辛基",
  "timezone_khartoum":"(UTC+02:00) 喀土穆",
  "timezone_belgrade":"(UTC+01:00) 欧洲中部时间 - 贝尔格莱德",
  "timezone_damascus":"(UTC+02:00) 大马士革",
  "timezone_maputo":"(UTC+02:00) 马普托",
  "timezone_kaliningrad":"(UTC+02:00) 莫斯科-01 - 加里宁格勒",
  "timezone_riga":"(UTC+02:00) 里加",
  "timezone_kiev":"(UTC+02:00) 基辅",
  "timezone_nicosia":"(UTC+02:00) 尼科西亚",
  "timezone_tallinn":"(UTC+02:00) 塔林",
  "timezone_tripoli":"(UTC+02:00) 的黎波里",
  "timezone_sofia":"(UTC+02:00) 索非亚",
  "timezone_vilnius":"(UTC+02:00) 维尔纽斯",
  "timezone_istanbul":"(UTC+03:00) 伊斯坦布尔",
  "timezone_baghdad":"(UTC+03:00) 巴格达",
  "timezone_minsk":"(UTC+03:00) 明斯克",
  "timezone_windhoek":"(UTC+02:00) 温得和克",
  "timezone_nairobi":"(UTC+03:00) 内罗毕",
  "timezone_moscow":"(UTC+03:00) 莫斯科+00 - 莫斯科",
  "timezone_qatar":"(UTC+03:00) 卡塔尔",
  "timezone_beirut":"(UTC+02:00) 贝鲁特",
  "timezone_riyadh":"(UTC+03:00) 利雅得",
  "timezone_syowa":"(UTC+03:00)昭和",
  "timezone_tehran":"(UTC+03:30) 德黑兰",
  "timezone_baku":"(UTC+04:00) 巴库",
  "timezone_dubai":"(UTC+04:00) 迪拜",
  "timezone_mahe":"(UTC+04:00) 马埃岛",
  "timezone_mauritius":"(UTC+04:00) 毛里求斯",
  "timezone_tbilisi":"(UTC+04:00) 第比利斯",
  "timezone_samara":"(UTC+04:00) 莫斯科+01 - 萨马拉",
  "timezone_reunion":"(UTC+04:00) 团聚",
  "timezone_yerevan":"(UTC+04:00) 埃里温",
  "timezone_kabul":"(UTC+04:30) 喀布尔",
  "timezone_aqtau":"(UTC+05:00) 阿克套",
  "timezone_aqtobe":"(UTC+05:00) 阿克托比",
  "timezone_ashgabat":"(UTC+05:00) 阿什哈巴德",
  "timezone_dushanbe":"(UTC+05:00) 杜尚别",
  "timezone_maldives":"(UTC+05:00) 马尔代夫",
  "timezone_yekaterinburg":"(UTC+05:00) 莫斯科+02 - 叶卡捷琳堡",
  "timezone_kerguelen":"(UTC+05:00) 凯尔盖朗",
  "timezone_mawson":"(UTC+05:00) 莫森",
  "timezone_tashkent":"(UTC+05:00) 塔什干",
  "timezone_colombo":"(UTC+05:30) 科伦坡",
  "timezone_kathmandu":"(UTC+05:45) 加德满都",
  "timezone_omsk":"(UTC+06:00) 莫斯科+03 - 鄂木斯克",
  "timezone_india_standard_time":"(UTC+05:30) 印度标准时间",
  "timezone_chagos":"(UTC+06:00) 查戈斯",
  "timezone_dhaka":"(UTC+06:00) 达卡",
  "timezone_bishkek":"(UTC+06:00) 比什凯克",
  "timezone_cocos":"(UTC+06:30) 科科斯",
  "timezone_thimphu":"(UTC+06:00) 廷布",
  "timezone_almaty":"(UTC+06:00) 阿拉木图",
  "timezone_vostok":"(UTC+06:00) 东方",
  "timezone_rangoon":"(UTC+06:30) 仰光",
  "timezone_christmas":"(UTC+07:00) 圣诞节",
  "timezone_davis":"(UTC+07:00) 戴维斯",
  "timezone_karachi":"(UTC+05:00) 卡拉奇",
  "timezone_hanoi":"(UTC+07:00) 河内",
  "timezone_beijing":"(UTC+08:00) 中国时间 - 北京",
  "timezone_hongkong":"(UTC+08:00) 香港",
  "timezone_hovd":"(UTC+07:00) 霍布德",
  "timezone_jakarta":"(UTC+07:00) 雅加达",
  "timezone_kuala_lumpur":"(UTC+08:00) 吉隆坡",
  "timezone_bangkok":"(UTC+07:00) 曼谷",
  "timezone_krasnoyarsk":"(UTC+07:00) 莫斯科+04 - 克拉斯诺亚尔斯克",
  "timezone_brunei":"(UTC+08:00) 文莱",
  "timezone_macau":"(UTC+08:00) 澳门",
  "timezone_makassar":"(UTC+08:00) 望加锡",
  "timezone_choibalsan":"(UTC+08:00) 乔巴山",
  "timezone_ulaanbaatar":"(UTC+08:00) 乌兰巴托",
  "timezone_manila":"(UTC+08:00) 马尼拉",
  "timezone_pyongyang":"(UTC+08:30) 平壤",
  "timezone_singapore":"(UTC+08:00) 新加坡",
  "timezone_irkutsk":"(UTC+08:00) 莫斯科+05 - 伊尔库茨克",
  "timezone_taipei":"(UTC+08:00) 台北",
  "timezone_jayapura":"(UTC+09:00) 查亚普拉",
  "timezone_dili":"(UTC+09:00) 帝力",
  "timezone_perth":"(UTC+08:00) 西部时间 - 珀斯",
  "timezone_yakutsk":"(UTC+09:00) 莫斯科+06 - 雅库茨克",
  "timezone_palau":"(UTC+09:00) 帕劳",
  "timezone_seoul":"(UTC+09:00) 首尔",
  "timezone_tokyo":"(UTC+09:00) 东京",
  "timezone_darwin":"(UTC+09:30) 中部时间 - 达尔文",
  "timezone_dumont":"(UTC+10:00) 杜蒙·杜维尔",
  "timezone_brisbane":"(UTC+10:00) 东部时间 - 布里斯班",
  "timezone_guam":"(UTC+10:00) 关岛",
  "timezone_vladivostok":"(UTC+10:00) 莫斯科+07 - 符拉迪沃斯托克",
  "timezone_port_moresby":"(UTC+10:00) 莫尔兹比港",
  "timezone_truk":"(UTC+10:00) 特鲁克",
  "timezone_adelaide":"(UTC+10:30) 中部时间 - 阿德莱德",
  "timezone_casey":"(UTC+11:00) 东部时间 - 霍巴特",
  "timezone_melbourne_sydney":"(UTC+11:00) 东部时间 - 墨尔本、悉尼",
  "timezone_kosrae":"(UTC+11:00) 科斯雷",
  "timezone_efate":"(UTC+11:00) 埃法特",
  "timezone_guadalcanal":"(UTC+11:00) 瓜达尔卡纳尔岛",
  "timezone_ponape":"(UTC+11:00) 波纳佩",
  "timezone_norfolk":"(UTC+11:00) 诺福克",
  "timezone_magadan":"(UTC+11:00) 莫斯科+08 - 马加丹",
  "timezone_noumea":"(UTC+11:00) 努美阿",
  "timezone_majuro":"(UTC+12:00) 马朱罗",
  "timezone_funafuti":"(UTC+12:00) 富纳富提",
  "timezone_nauru":"(UTC+12:00) 瑙鲁",
  "timezone_tarawa":"(UTC+12:00) 塔拉瓦",
  "timezone_kwajalein":"(UTC+12:00) 夸贾林环礁",
  "timezone_enderbury":"(UTC+13:00) 恩德伯里",
  "timezone_wake":"(UTC+12:00) 醒来",
  "timezone_fakaofo":"(UTC+13:00) 法考福",
  "timezone_wallis":"(UTC+12:00) 沃利斯",
  "timezone_fiji":"(UTC+13:00) 斐济",
  "timezone_apia":"(UTC+14:00) 阿皮亚",
  "timezone_kiritimati":"(UTC+14:00) 基里蒂马蒂",
  "timezone_tongatapu":"(UTC+13:00) 汤加塔布岛",
  "timezone_auckland":"(UTC+13:00) 奥克兰",
  "dateFormet":"日期格式",
  "Org_success":"加入组织的请求已成功发送",
  "Org_err":"您已经加入该组织",
  "Request_Organization":"请求组织",
  "organization_requested":"要求组织",
  "join":"加入",
  "your_email":"电子邮件@address.com",
  "medical_summary":"您尚未填写完医疗摘要。",
  "medical_summary_msg":"我的医疗摘要",
  "medical_success":"医疗摘要已成功提交",
  "patient_form_popup":"您想继续填写之前的问卷吗？",
  "Please_confirm_that_your_details_are_correct":"请确认您的个人信息正确无误",
  "Welcome_to_Akunah_your_Health_Passport":"欢迎来到 Akunah，您的健康护照。",
  "Your_helth_is_in_your_hands":"您的健康掌握在自己手中。",
  "Lets_begin_your_journey":"展开您的旅程吧。",
  "Please_complete_your_profile":"请填写您的个人资料。",
  "Profile_complete":"个人资料已完成！",
  "Would_you_like_to_add_another_profile_for_a_family_member":"您想为家庭成员添加另一份个人资料吗？",
  "Alert_detected":"警报：检测到多重登录",
  "To_safeguard_your_data":"为保护您的数据隐私和安全，网页已定为超时。",
  "Kindly_close_all_other_Akunah":"请关闭所有其他 Akunah 标签，然后按 \"返回主页 \"继续。",
  "Akunah_Team_is_dedicated":"Akunah 团队致力于保护您的数据。",
  "Last_Created":"最新创建",
  "First_Created":"首次创建",
  "Alphabetically":"按字母顺序 (A-Z)",
  "Alphabetically1":"按字母顺序 (Z-A)",
  "Your_form_submission_was_successful_Clinician":"您的表格提交成功。我们现在自动重定向到临床医生应用程序",
  "Your_form_submission_was_successful_Patient":"您的表格提交成功。我们现在自动重定向到病人应用程序",
  "If_this_doesnt_happen_within":"如果在 5 秒钟内没有移动，",
  "click_here":"请点击此处",
  "Save":"保存",
  "Proceed_to_my_dashboard":"前往我的报表页",
  "myJourney":"我的历程",
  "Home":"首页",
  "No_journey":"未找到历程",
  "Share_results_with_your_doctor":"与医师分享结果",
  "If_you_would_like_to_share_your_results_with":"如果您想和医师分享结果，请选择下方的「立即分享」",
  "Link_PROMs":"连结病人回报结果评估",
  "Welcome_to_akunah":"欢迎来到Akunah",
  "Share_to_your_Doctor_using_one_of_the_3_options_below":"用以下的三个选项和医师分享",
  "Doctor_Code":"医师码",
  "Enter_the_code_that_has_been_provided_to_you_by_your":"请输入医生在电子邮件或诊所提供给您的代码。如果没有代码，请查看您最近的就诊记录，或搜寻您的医生",
  "Recent_Visits":"最近的到访",
  "Select_your_recent_doctor_or":"在此选择您",
  "organisation_here":"近期的医师或机构",
  "Search":"搜索",
  "Search_for_your_doctor_or":"搜寻您的医师或",
  "Please_confirm_that_the_following_details":"请确认以下细节正确",
  "Organisation":"机构",
  "PROMs_Specialty_Date":"病人回报结果评估专科及日期",
  "Get_started_by_completing_your":"请从完成病人回报结果评估问卷开始",
  "and_exploring_your_progress":"探索您的进展",
  "Fill_up_your_PROMs_questionnaire":"填写病人回报结果评估问卷",
  "Hip":"臀部",
  "My_Progress":"我的进展",
  "Completed":"已完成",
  "Expired":"已过期",
  "Incomplete":"未完成",
  "Specialty":"专门领域",
  "Doctor":"医师",
  "Status":"状态",
  "View_form":"观看表格",
  "Share_to_another_doctor":"和其他医师分享",
  "Edit_form":"编辑表单",
  "Answer_the_form":"回答表单",
  "Complete_the_form":"完成表单",
  "Delete_the_form":"删除表单",
  "Share_to_doctor":"与医师分享",
  "Your_account_has_been_created":"您的帐号已创建",
  "Please_click_below_to_proceed_to_your_dashboard":"请点击下方连结前往报表页",
  "If_you_would_like_to_add_another_profile_for_a_family":"如果您想为家庭成员增加个人档案，请",
  "Share":"分享",
  "Hip_Scoring_Self_Evaluation_Questionnaire":"臀部分数自我评量问卷",
  "Hand_and_Wrist_Scoring_Self_Evaluation_Questionnaire":"手和手腕分数自我评量问卷",
  "Right_Hand_nd_Wrist":"右手和手腕",
  "Left_Hand_nd_Wrist":"左手和手腕",
  "DOB_1":"出生日期",
  "Sex_1":"性别",
  "Address_1":"地址",
  "Please_Choose_PROMs_to_link":"请选择要连结的病人回报结果评估",
  "Please_select_atleast_one_form_or_close":"请选择至少一个病人回报结果评估，或关闭",
  "cant_find_appropriate_PROMs_to_link":"找不到适合的病人回报结果评估去连结吗？",
  "Request_New_PROMs":"要求新的病人回报结果评估",
  "Warning_This_patient_has_an_incomplete_patient_PROMs_from_a_recent_visit":"提醒：您最近的到访有未完成的问卷",
  "If_you_select_No_a_new_patient_PROMs_will_be_created_and_the_incomplete_PROMs_will_be_deleted":"如果选择 \"否\"，则会建立新的问卷，并删除未完成的问卷。",
  "Would_you_like_to_continue_this_form":"您想继续下去吗？",
  "Note":"备注：",
  "Expiring_in":"到期：",
  "Date_Created":"已创建日期",
  "Days":"日后",
  "Shared":"已分享",
  "Pending":"未完成",
  "Initial":"刚开始",
  "Recent":"近期",
  "Pain":"疼痛",
  "Function":"功能",
  "Share_Now":"立即分享",
  "Share_Later":"之后分享",
  "Updated":"已更新",
  "Display_More":"显示更多",
  "Address_Now":"现在处理",
  "Address_Later":"之后处理",
  "You_have_pending_questionnaires":"你有未完成的问卷",
  "Notifications":"通知",
  "Form_shared_successfully":"表格成功共享",
  "You_have_already_shared_with_same_doctor_and_organization":"您已经与同一医生和组织分享了这一点",
  "No_recent_notifications":"最近没有新通知",
  "Have_a_good_day":"祝你有美好的一天",
  "Patient_scores":"患者分数",
  "Display less":"减少显示",
  "myJourney_Shoulder":"肩膀",
  "myJourney_Shoulder_txt":"选择肩膀以查看您的肩膀历程",
  "myJourney_Elbow":"肘部",
  "myJourney_Elbow_txt":"选择肘部以查看您的肘部历程",
  "myJourney_Hand_Wrist":"手和手腕",
  "myJourney_Hand_Wrist_txt":"选择手和手腕以查看您的手和腕历程",
  "myJourney_Hip":"臀部",
  "myJourney_Hip_txt":"选择臀部以查看您的臀部历程",
  "myJourney_knee":"膝盖",
  "myJourney_knee_txt":"选择膝盖以查看您的膝盖历程",
  "myJourney_Foot_Ankle":"脚和脚踝",
  "myJourney_Foot_Ankle_txt":"选择脚和脚踝以查看您的脚和脚踝历程",
  "data_sort":"种类",
  "Visit_Clinic":"访问诊所",
  "My_Visits":"我的访问",
  "Patient_Scoring_Forms":"患者评分表格",
  "Other_findings":"其他发现",
  "Organization":"机构",
  "more":"更多",
  "mediacal_summary":"医学摘要",
  "my_consent":"我的同意",
  "Are_you_sure_you_want_to_reject":"您确定要封锁此转介吗？",
  "reject":"拒绝",
  "i_accept_consent":"我对此同意",
  "grant":"授予",
  "no_conscent_found":"未找到同意",
  "rejected":"已拒绝",
  "No_Time_Lines":"没有时间表",
  "Hide_Score":"隐藏分数",
  "Show_Score":"显示分数",
  "patient_sex":"性别",
  "Dominant_hand_1":"惯用手",
  "patient_Initial_Assessment":"初始评估",
  "patient_Treatment_Category":"治疗类别",
  "View_all":"查看全部",
  "Display_Less":"减少显示",
  "patient_general_past_Medical_history":"病史",
  "patient_general_past_Medication_name":"药物清单",
  "Evaluated_Knee":"已评估的膝盖",
  "Is_your_unaffected_Hand_Wrist_pain_free_and_allows_normal_function":"您未受影响的手和手腕是否无疼痛且功能正常？",
  "Evaluated_Hip":"已评估的臀部",
  "Evaluated_Foot_Ankle":"已评估的脚和脚踝",
  "patient_Evaluated_Elbow":"已评估的肘部",
  "Is_your_unaffected_Foot_Ankle_pain_free_and_allows_normal_function":"您未受影响的脚和脚踝是否无疼痛且功能正常？",
  "Evaluated_Hand_Wrist":"已评估的手和腕",
  "patient_shoulder_feeling_instability_q2":"请标明您的肩关节有多不稳定",
  "patient_shoulder_feeling_instability_q3":"请标明您的肩关节有多不稳定",
  "patient_Hand_BCTQ":"手和手腕BCTQ",
  "Paediatric_Knee_Satisfaction":"小儿膝盖满意度",
  "patient_foot_ankle_symptoms":"脚和脚踝的其他症状",
  "Right_Foot_and_Ankle":"右脚和右脚踝",
  "Left_Foot_and_Ankle":"左脚和左脚踝",
  "summary":"摘要",
  "Shoulder_Treatment_Form":"肩部治疗表格",
  "Adolescent_knee_Treatment_Form":"青少年膝盖治疗表格",
  "Adult_Knee_Treatment_Form":"成人膝盖治疗表格",
  "Paediatric_Knee_Treatment_Form":"小儿膝关节治疗表格",
  "Hip_Treatment_Form":"髋关节治疗表格",
  "Foot_Ankle_Treatment_Form":"脚踝治疗表格",
  "Elbow_Treatment_Form":"肘部治疗表格",
  "Hand_Wrist_Treatment_Form":"手和手腕治疗表格",
  "Upload_complete":"上传完成",
  "Select_Date_Format":"选择日期格式",
  "timezone_casey1":"(UTC+11:00) 凯西",
  "timezone_Kamchatskiy":"(UTC+12:00) 莫斯科+09 - 堪察加彼得巴甫洛夫斯克",
  "timezone_Guayaquil":"(UTC-05:00) Guayaquil",
  "requested":"请求",
  "Display_name":"显示名字",
  "Are_you_sure_you_want_to_delete_Consent":"您确定要拒绝这项同意书吗？",
  "form":"表格",
  "Requested_by":"请求者",
  "requested_hip_form":"请求臀部表格",
  "requested_shoulder_form":"请求肩部表格",
  "requested_knee_form":"请求膝盖表格",
  "requested_foot_form":"请求脚和脚踝表格",
  "requested_hand_form":"请求手和手腕表格",
  "requested_elbow_form":"请求手肘表格",
  "requested_General_form":"请求一般表格",
  "requested_Paediatric_form":"requested a Paediatric Knee form",
  "requested_Adolescent_form":"requested a Adolescent Knee form"
}