import React from "react";
import "../../../css/ViewTreatmentForm.css";
import useTranslation from "../../customHooks/translations";
import { UserDateFormat } from "../../../utilities/DateFormate";

export default function PediaKneePain({ pediaPain, AllData,profileAllData }) {
  const translations = useTranslation();
  return (
    <div className="whitebox padding-none">
      <div className="treatment-header">
        <h2>
          {translations.Paediatric_Knee_Scoring_Self_Evaluation_Questionnaire}
        </h2>
        <div className="treatment-header-right">
          <p>
            {translations.Assessment_Date} : {UserDateFormat( AllData.assessment_date,profileAllData.ing_date_formate)}
          </p>
        </div>
      </div>

      <div className="treatment-content">
        <div className="treatment-row-header">
          1. {translations.patient_pedia_knee_pain}
        </div>

        <div className="treatment-row">
          <div className="treatment-col">
            <label>
              {translations.patient_pedia_knee_pain_q1} ({translations.Right}):
            </label>
            {pediaPain.painPastFourWeeksR}
            <p>
              {pediaPain.painPastFourWeeksR === "0"
                ? translations.patient_pedia_knee_pain_q1_a1
                : pediaPain.painPastFourWeeksR === "1" ||
                  pediaPain.painPastFourWeeksR === "2" ||
                  pediaPain.painPastFourWeeksR === "3"
                ? translations.patient_pedia_knee_pain_q1_a2
                : pediaPain.painPastFourWeeksR === "4" ||
                  pediaPain.painPastFourWeeksR === "5" ||
                  pediaPain.painPastFourWeeksR === "6"
                ? translations.patient_pedia_knee_pain_q1_a3
                : pediaPain.painPastFourWeeksR === "7" ||
                  pediaPain.painPastFourWeeksR === "8" ||
                  pediaPain.painPastFourWeeksR === "9"
                ? translations.patient_pedia_knee_pain_q1_a4
                : pediaPain.painPastFourWeeksR === "10"
                ? translations.patient_pedia_knee_pain_q1_a5
                : ""}
            </p>
          </div>
          <div className="treatment-col">
            <label>
              {translations.patient_pedia_knee_pain_q1} ({translations.Left}):
            </label>
            {pediaPain.painPastFourWeeksL}
            <p>
              {pediaPain.painPastFourWeeksL === "0"
                ? translations.patient_pedia_knee_pain_q1_a1
                : pediaPain.painPastFourWeeksL === "1" ||
                  pediaPain.painPastFourWeeksL === "2" ||
                  pediaPain.painPastFourWeeksL === "3"
                ? translations.patient_pedia_knee_pain_q1_a2
                : pediaPain.painPastFourWeeksL === "4" ||
                  pediaPain.painPastFourWeeksL === "5" ||
                  pediaPain.painPastFourWeeksL === "6"
                ? translations.patient_pedia_knee_pain_q1_a3
                : pediaPain.painPastFourWeeksL === "7" ||
                  pediaPain.painPastFourWeeksL === "8" ||
                  pediaPain.painPastFourWeeksL === "9"
                ? translations.patient_pedia_knee_pain_q1_a4
                : pediaPain.painPastFourWeeksL === "10"
                ? translations.patient_pedia_knee_pain_q1_a5
                : ""}
            </p>
          </div>
        </div>
        <div className="treatment-row">
          <div className="treatment-col">
            <label>
              {translations.patient_pedia_knee_pain_q2} ({translations.Right}):
            </label>
            <p>{pediaPain.painHurtTodayR}</p>
          </div>
          <div className="treatment-col">
            <label>
              {translations.patient_pedia_knee_pain_q2} ({translations.Left}):
            </label>
            <p>{pediaPain.painHurtTodayL}</p>
          </div>
        </div>
        <div className="treatment-row">
          <div className="treatment-table">
            <table cellpadding="0" cellspacing="0">
              <thead>
                <tr>
                  <th></th>
                  <th>{translations.Right}</th>
                  <th>{translations.Left}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{translations.patient_pedia_knee_pain_q3}:</td>
                  <td>{pediaPain.twistingR}</td>
                  <td>{pediaPain.twistingL}</td>
                </tr>
                <tr>
                  <td>{translations.patient_pedia_knee_pain_q4}:</td>
                  <td>{pediaPain.straighteningR}</td>
                  <td>{pediaPain.straighteningL}</td>
                </tr>
                <tr>
                  <td>{translations.patient_pedia_knee_pain_q5} :</td>
                  <td>{pediaPain.fullyBendKneeR}</td>
                  <td>{pediaPain.fullyBendKneeL}</td>
                </tr>
                <tr>
                  <td>{translations.patient_pedia_knee_pain_q6} :</td>
                  <td>{pediaPain.walkingUpStairsR}</td>
                  <td>{pediaPain.walkingUpStairsL}</td>
                </tr>
                <tr>
                  <td>{translations.patient_pedia_knee_pain_q7}:</td>
                  <td>{pediaPain.walkingDownStairsR}</td>
                  <td>{pediaPain.walkingDownStairsL}</td>
                </tr>
                <tr>
                  <td>{translations.patient_pedia_knee_pain_q8}:</td>
                  <td>{pediaPain.sittingKneeBentR}</td>
                  <td>{pediaPain.sittingKneeBentL}</td>
                </tr>
                <tr>
                  <td>{translations.patient_pedia_knee_pain_q9}:</td>
                  <td>{pediaPain.sittingKneeBentUprightR}</td>
                  <td>{pediaPain.sittingKneeBentUprightL}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
