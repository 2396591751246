import React, { useMemo, useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import backIcon from "../../../../../images/back.png";
import nextWhiteIcon from "../../../../../images/next-white.png";
import nextIcon from "../../../../../images/next.png";
import LoadingSpinner from "../../../../custom/loader/LoadingSpinner";
import KneeScoreRangeSlider from "../../../../custom/knee-score-range-slider/KneeScoreRangeSlider";
import Dropdown from "../../../../custom/dropdown/Dropdown";
import {
  updatePediaScores,
  updatePediaSymtomsFieldScore,
  updatePediaSymtomsScore,
  updatePediaPainData,
  updatePediaFunctionScore,
  updatePediaQolScore,
  updatePediaSatisfactionScore,
  updatePediaTotalScores,
  updatePediaKneeNorwichInstaScore,
  updateAllData,
  updatePediaKneeFeedbackScores

} from "../../../../../redux/actions/pediaKnee";
import {
  updateAdultKneeFeedback

} from "../../../../../redux/actions/index";
import { useDispatch, useSelector } from "react-redux";
import useAxios from "../../../../../axiosinstance";
import useTransition from '../../../../customHooks/translations';
import PediakneeTab from '../../../tab-section/PediakneeTab';
import { useSearchParams } from "react-router-dom";
import CustomToaster from '../../../../custom/toaster/CustomToaster'
import { LeftSide, LeftTrans, RightSide, RigthTrans } from "../../../../../utilities/RightLeftFormat";
import NameCustom from "../../../../custom/name/NameCustom";


function PediaKneeSymptoms() {
  const translation = useTransition();
  const { rId } = useParams();
  const [searchParams] = useSearchParams();
  let doctorEnable = searchParams.get("d");
  const axiosinstance = useAxios();
  const [isLoading, setIsLoading] = useState(true);
  const evlPediaPain = useSelector((state) => state.pediaKnee.evlPediaPain);
  const [kneeMakingNoiseR, setKneeMakingNoiseR] = useState("0");
  const [kneeMakingNoiseL, setKneeMakingNoiseL] = useState("0");
  const [kneeGettingStuckR, setKneeGettingStuckR] = useState("0");
  const [kneeGettingStuckL, setKneeGettingStuckL] = useState("0");
  const [stillMoveR, setStillMoveR] = useState("0");
  const [stillMoveL, setStillMoveL] = useState("0");
  const [withoutHurtLotR, setWithoutHurtLotR] = useState("0");
  const [withoutHurtLotL, setWithoutHurtLotL] = useState("0");
  const [withoutPuffyR, setWithoutPuffyR] = useState("0");
  const [withoutPuffyL, setWithoutPuffyL] = useState("0");
  const [puffyKneeR, setPuffyKneeR] = useState("0");
  const [puffyKneeL, setPuffyKneeL] = useState("0");
  const [swollenKneeR, setSwollenKneeR] = useState("0");
  const [swollenKneeL, setSwollenKneeL] = useState("0");
  const [cantHoldR, setCantHoldR] = useState("0");
  const [cantHoldL, setCantHoldL] = useState("0");
  const [mostOfTimeR, setMostOfTimeR] = useState("0");
  const [mostOfTimeL, setMostOfTimeL] = useState("0");
  const [kneecapR, setkneecapR] = useState("0");
  const [kneecapL, setkneecapL] = useState("0");
  const [chk, setChk] = useState(false);
  const [updateTotal, setUpdateTotal] = useState(false);
  const save = () => {
    axiosinstance.current
      .get(`extapp/forms/all_forms?resource_id=${rId}&type=20`)
      .then((res) => {
        dispatch(updatePediaScores(res.data.data2[0].evlPediaPain));
        dispatch(updatePediaPainData(res.data.data2[0].painScore));
        dispatch(updatePediaSymtomsScore(res.data.data2[0].symtomsScore));
        dispatch(updatePediaFunctionScore(res.data.data2[0].functionScore));
        dispatch(updatePediaQolScore(res.data.data2[0].qolScore));
        dispatch(
          updatePediaSatisfactionScore(res.data.data2[0].satisfactionScore)
        );
        dispatch(
          updatePediaKneeNorwichInstaScore(res.data.data2[0].norwichInstability)
        );
        dispatch(
          updateAdultKneeFeedback(res.data.data2[0].doctorFeedback)
        );
        dispatch(updatePediaTotalScores(res.data.data2[0].TotalScores));
        setChk(true);
      }).catch((err) => {

        navigate('/un-authorized-access');

      })
  };
  useEffect(() => {
    save();
  }, []);

  const symtomsScore = useSelector((state) => state.pediaKnee.symtomsScore[0]);
  const json = useSelector((state) => state.pediaKnee);

  useEffect(() => {
    setKneeMakingNoiseR(symtomsScore.kneeMakingNoiseR);
    setKneeMakingNoiseL(symtomsScore.kneeMakingNoiseL);
    setKneeGettingStuckR(symtomsScore.kneeGettingStuckR);
    setKneeGettingStuckL(symtomsScore.kneeGettingStuckL);
    setStillMoveR(symtomsScore.stillMoveR);
    setStillMoveL(symtomsScore.stillMoveL);
    setWithoutHurtLotR(symtomsScore.withoutHurtLotR);
    setWithoutHurtLotL(symtomsScore.withoutHurtLotL);
    setWithoutPuffyR(symtomsScore.withoutPuffyR);
    setWithoutPuffyL(symtomsScore.withoutPuffyL);
    setPuffyKneeR(symtomsScore.puffyKneeR);
    setPuffyKneeL(symtomsScore.puffyKneeL);
    setSwollenKneeR(symtomsScore.swollenKneeR);
    setSwollenKneeL(symtomsScore.swollenKneeL);
    setCantHoldR(symtomsScore.cantHoldR);
    setCantHoldL(symtomsScore.cantHoldL);
    setMostOfTimeR(symtomsScore.mostOfTimeR);
    setMostOfTimeL(symtomsScore.mostOfTimeL);
  }, [symtomsScore]);

  const updateDate = () => {
    axiosinstance.current
      .patch(
        `extapp/forms/update_all_form_type?resource_id=${rId}&action=${doctorEnable === "true"?"1":"0"}`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res);
      });
  };
  useEffect(() => {
    if (chk) {
      updateDate();
    }
  }, [symtomsScore, updateTotal]);

  const sliderLabels1 = [
    { score: "0", label: translation.never },
    { score: "1", label: translation.rarely },
    { score: "2", label: translation.sometimes },
    { score: "3", label: translation.often },
    { score: "4", label: translation.always },
  ];

  const sliderLabels2 = [
    { score: "0", label: translation.Very_hard_activities },
    { score: "1", label: translation.Hard_activities },
    { score: "2", label: translation.Sort_hard_activities },
    { score: "3", label: translation.Light_activities },
    { score: "4", label: translation.I_any_the_above },
  ];

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleKneeMakingNoiseR = (e) => {
    setKneeMakingNoiseR(e);
    dispatch(
      updatePediaSymtomsFieldScore({
        key: "kneeMakingNoiseR",
        value: e,
      })
    );
  };

  const handleKneeMakingNoiseL = (e) => {
    dispatch(
      updatePediaSymtomsFieldScore({
        key: "kneeMakingNoiseL",
        value: e,
      })
    );
    setKneeMakingNoiseL(e);
  };

  const handleKneeGettingStuckR = (e) => {
    dispatch(
      updatePediaSymtomsFieldScore({
        key: "kneeGettingStuckR",
        value: e,
      })
    );
    setKneeGettingStuckR(e);
  };
  const handleKneeGettingStuckL = (e) => {
    dispatch(
      updatePediaSymtomsFieldScore({
        key: "kneeGettingStuckL",
        value: e,
      })
    );
    setKneeGettingStuckL(e);
  };

  const handleStillMoveR = (e) => {
    dispatch(
      updatePediaSymtomsFieldScore({
        key: "stillMoveR",
        value: e,
      })
    );
    setStillMoveR(e);
  };
  const handleStillMoveL = (e) => {
    dispatch(
      updatePediaSymtomsFieldScore({
        key: "stillMoveL",
        value: e,
      })
    );
    setStillMoveL(e);
  };

  const handleWithoutHurtLotR = (e) => {
    dispatch(
      updatePediaSymtomsFieldScore({
        key: "withoutHurtLotR",
        value: e,
      })
    );
    setWithoutHurtLotR(e);
  };
  const handleWithoutHurtLotL = (e) => {
    dispatch(
      updatePediaSymtomsFieldScore({
        key: "withoutHurtLotL",
        value: e,
      })
    );
    setWithoutHurtLotL(e);
  };

  const handlePuffyKneeL = (e) => {
    dispatch(
      updatePediaSymtomsFieldScore({
        key: "puffyKneeL",
        value: e,
      })
    );
    setPuffyKneeL(e);
  };
  const handlePuffyKneeR = (e) => {
    dispatch(
      updatePediaSymtomsFieldScore({
        key: "puffyKneeR",
        value: e,
      })
    );
    setPuffyKneeR(e);
  };
  const handleSwollenKneeL = (e) => {
    dispatch(
      updatePediaSymtomsFieldScore({
        key: "swollenKneeL",
        value: e,
      })
    );
    setSwollenKneeL(e);
  };
  const handleSwollenKneeR = (e) => {
    dispatch(
      updatePediaSymtomsFieldScore({
        key: "swollenKneeR",
        value: e,
      })
    );
    setSwollenKneeR(e);
  };

  const handleWithoutPuffyL = (e) => {
    dispatch(
      updatePediaSymtomsFieldScore({
        key: "withoutPuffyL",
        value: e,
      })
    );
    setWithoutPuffyL(e);
  };
  const handleWithoutPuffyR = (e) => {
    dispatch(
      updatePediaSymtomsFieldScore({
        key: "withoutPuffyR",
        value: e,
      })
    );
    setWithoutPuffyR(e);
  };

  const handleCantHoldL = (e) => {
    dispatch(
      updatePediaSymtomsFieldScore({
        key: "cantHoldL",
        value: e,
      })
    );
    setCantHoldL(e);
  };
  const handleCantHoldR = (e) => {
    dispatch(
      updatePediaSymtomsFieldScore({
        key: "cantHoldR",
        value: e,
      })
    );
    setCantHoldR(e);
  };

  const handleMostOfTimeL = (e) => {
    dispatch(
      updatePediaSymtomsFieldScore({
        key: "mostOfTimeL",
        value: e,
      })
    );
    setMostOfTimeL(e);
  };
  const handleMostOfTimeR = (e) => {
    dispatch(
      updatePediaSymtomsFieldScore({
        key: "mostOfTimeR",
        value: e,
      })
    );
    setMostOfTimeR(e);
  };

  const handleKneecapR = (e) => {
    setkneecapR(e)
    dispatch(updatePediaSymtomsFieldScore({
      "key": 'kneecapR',
      "value": e
    }))
  }

  const handleKneecapL = (e) => {
    setkneecapL(e)
    dispatch(updatePediaSymtomsFieldScore({
      "key": 'kneecapL',
      "value": e
    }))
  }
  const handleEvolutedPediaPain = (e) => {
    dispatch(updatePediaScores(e));
  };
  let doctordata =localStorage.getItem("isdoctorForm");
  let viewicon=localStorage.getItem("viewicon")
  const continueRedirect = (e) => {
    dispatch(
      updatePediaSymtomsScore([
        {
          kneeMakingNoiseR: kneeMakingNoiseR,
          kneeMakingNoiseL: kneeMakingNoiseL,
          kneeGettingStuckR: kneeGettingStuckR,
          kneeGettingStuckL: kneeGettingStuckL,
          stillMoveR: stillMoveR,
          stillMoveL: stillMoveL,
          withoutHurtLotR: withoutHurtLotR,
          withoutHurtLotL: withoutHurtLotL,
          puffyKneeL: puffyKneeL,
          puffyKneeR: puffyKneeR,
          swollenKneeL: swollenKneeL,
          swollenKneeR: swollenKneeR,
          withoutPuffyL: withoutPuffyL,
          withoutPuffyR: withoutPuffyR,
          cantHoldL: cantHoldL,
          cantHoldR: cantHoldR,
          mostOfTimeL: mostOfTimeL,
          mostOfTimeR: mostOfTimeR,
        },
      ])
    );
    // if (kneecapR > "0" || kneecapL > "0") {
    //   navigate("/patellar-instability-pedia/" + rId)
    // } else {

    navigate("/pedia-knee-function/" + rId);
    // }
  };
  let akpt = searchParams.get("akpt");

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
  const [msg, setMsg] = useState('');
  const [msgType, setmsgType] = useState('');
  const handleSubmit = () => {
    updateAllData(json, dispatch);
    setUpdateTotal(!updateTotal);
    setMsg(translation.update)
    setmsgType('success')
   setTimeout(() => {
     let view_circular=  sessionStorage.getItem("view_circular")
      // window.location.href = process.env.REACT_APP_DOCTOR_URL + "patient-view/" + akpt
      // window.location.href = process.env.REACT_APP_DOCTOR_URL +`view-management-plan/${akpt}/${window.btoa(
      //   rId
      // )}/?org=${window.btoa(sessionStorage.getItem("orgid")||'')}&formType=${
      //   window.btoa('20') || ""
      // }&view_circular=${view_circular==='false'?'false':'true'}`
      window.location.href = process.env.REACT_APP_DOCTOR_URL +`patient-details/${akpt}?formid=20`
      setMsg('')
      setmsgType('success')
    }, 2000);
  };
  const handleNext = () => {
    navigate(`/pedia-knee-function/${rId}?d=true&akpt=${akpt}`);

  }

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="pain_screen mb_100">
           <div class="d-flex justify-content-end">
            <NameCustom />
          </div>
          <div className="panel">
            <div className="panel-body p_25">
              {doctorEnable === "true" && <PediakneeTab rId={rId} />}
              <CustomToaster msg={msg} msgType={msgType} />
            
              <h4 className="ph_title f_40">{translation.patient_pedia_knee_other_symptoms}</h4>

              <div className="function_formtop">
                <div className="row">
                  <div className="col-md-6 col-sm-6 function_head">
                    <label>{translation.patient_pedia_knee_other_symptoms_instruction1}</label> <br />
                    <span>
                      (<strong>0</strong> = {translation.patient_pedia_knee_other_symptoms_instruction1_0}, <strong>1</strong> = {translation.patient_pedia_knee_other_symptoms_instruction1_1}, <strong> 2</strong> = {translation.patient_pedia_knee_other_symptoms_instruction1_2}, <strong>3</strong> = {translation.patient_pedia_knee_other_symptoms_instruction1_3}, <strong>4</strong> = {translation.patient_pedia_knee_other_symptoms_instruction1_4})
                    </span>
                  </div>

                </div>
                <div className="row">
                  {evlPediaPain === "1" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <strong className="d-inline-block mb_15">
                        {/* {translation.Right_Knee}: */}
                      </strong>
                      <KneeScoreRangeSlider
                        label={translation.patient_pedia_knee_other_symptoms_q1 + RigthTrans(evlPediaPain, translation)}
                        classes=""
                        value={kneeMakingNoiseR}
                        onChange={handleKneeMakingNoiseR}
                        // sliderData={sliderLabels1}
                        sliderData={[
                          { score: "0", label: translation.patient_pedia_knee_other_symptoms_q1_a1 },
                          { score: "1", label: translation.patient_pedia_knee_other_symptoms_q1_a2 },
                          { score: "2", label: translation.patient_pedia_knee_other_symptoms_q1_a3 },
                          { score: "3", label: translation.patient_pedia_knee_other_symptoms_q1_a4 },
                          { score: "4", label: translation.patient_pedia_knee_other_symptoms_q1_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlPediaPain === "2" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <strong className="d-inline-block mb_15">
                        {/* {translation.Left_Knee}: */}
                      </strong>
                      <KneeScoreRangeSlider
                        label={translation.patient_pedia_knee_other_symptoms_q1 + LeftTrans(evlPediaPain, translation)}
                        classes=""
                        value={kneeMakingNoiseL}
                        onChange={handleKneeMakingNoiseL}
                        // sliderData={sliderLabels1}
                        sliderData={[
                          { score: "0", label: translation.patient_pedia_knee_other_symptoms_q1_a1 },
                          { score: "1", label: translation.patient_pedia_knee_other_symptoms_q1_a2 },
                          { score: "2", label: translation.patient_pedia_knee_other_symptoms_q1_a3 },
                          { score: "3", label: translation.patient_pedia_knee_other_symptoms_q1_a4 },
                          { score: "4", label: translation.patient_pedia_knee_other_symptoms_q1_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlPediaPain === "1" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_pedia_knee_other_symptoms_q2 + RigthTrans(evlPediaPain, translation)}
                        classes=""
                        value={kneeGettingStuckR}
                        onChange={handleKneeGettingStuckR}
                        // sliderData={sliderLabels1}
                        sliderData={[
                          { score: "0", label: translation.patient_pedia_knee_other_symptoms_q2_a1 },
                          { score: "1", label: translation.patient_pedia_knee_other_symptoms_q2_a2 },
                          { score: "2", label: translation.patient_pedia_knee_other_symptoms_q2_a3 },
                          { score: "3", label: translation.patient_pedia_knee_other_symptoms_q2_a4 },
                          { score: "4", label: translation.patient_pedia_knee_other_symptoms_q2_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlPediaPain === "2" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_pedia_knee_other_symptoms_q2 + LeftTrans(evlPediaPain, translation)}
                        classes=""
                        value={kneeGettingStuckL}
                        onChange={handleKneeGettingStuckL}
                        // sliderData={sliderLabels1}
                        sliderData={[
                          { score: "0", label: translation.patient_pedia_knee_other_symptoms_q2_a1 },
                          { score: "1", label: translation.patient_pedia_knee_other_symptoms_q2_a2 },
                          { score: "2", label: translation.patient_pedia_knee_other_symptoms_q2_a3 },
                          { score: "3", label: translation.patient_pedia_knee_other_symptoms_q2_a4 },
                          { score: "4", label: translation.patient_pedia_knee_other_symptoms_q2_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlPediaPain === "1" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_pedia_knee_other_symptoms_q3 + RigthTrans(evlPediaPain, translation)}
                        classes=""
                        value={stillMoveR}
                        onChange={handleStillMoveR}
                        // sliderData={sliderLabels1}
                        sliderData={[
                          { score: "0", label: translation.patient_pedia_knee_other_symptoms_q3_a1 },
                          { score: "1", label: translation.patient_pedia_knee_other_symptoms_q3_a2 },
                          { score: "2", label: translation.patient_pedia_knee_other_symptoms_q3_a3 },
                          { score: "3", label: translation.patient_pedia_knee_other_symptoms_q3_a4 },
                          { score: "4", label: translation.patient_pedia_knee_other_symptoms_q3_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlPediaPain === "2" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_pedia_knee_other_symptoms_q3 + LeftTrans(evlPediaPain, translation)}
                        classes=""
                        value={stillMoveL}
                        onChange={handleStillMoveL}
                        // sliderData={sliderLabels1}
                        sliderData={[
                          { score: "0", label: translation.patient_pedia_knee_other_symptoms_q3_a1 },
                          { score: "1", label: translation.patient_pedia_knee_other_symptoms_q3_a2 },
                          { score: "2", label: translation.patient_pedia_knee_other_symptoms_q3_a3 },
                          { score: "3", label: translation.patient_pedia_knee_other_symptoms_q3_a4 },
                          { score: "4", label: translation.patient_pedia_knee_other_symptoms_q3_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlPediaPain === "1" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_pedia_knee_other_symptoms_q4 + RigthTrans(evlPediaPain, translation)}
                        classes=""
                        value={puffyKneeR}
                        onChange={handlePuffyKneeR}
                        // sliderData={sliderLabels1}
                        sliderData={[
                          { score: "0", label: translation.patient_pedia_knee_other_symptoms_q4_a1 },
                          { score: "1", label: translation.patient_pedia_knee_other_symptoms_q4_a2 },
                          { score: "2", label: translation.patient_pedia_knee_other_symptoms_q4_a3 },
                          { score: "3", label: translation.patient_pedia_knee_other_symptoms_q4_a4 },
                          { score: "4", label: translation.patient_pedia_knee_other_symptoms_q4_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlPediaPain === "2" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_pedia_knee_other_symptoms_q4 + LeftTrans(evlPediaPain, translation)}
                        classes=""
                        value={puffyKneeL}
                        onChange={handlePuffyKneeL}
                        // sliderData={sliderLabels1}
                        sliderData={[
                          { score: "0", label: translation.patient_pedia_knee_other_symptoms_q4_a1 },
                          { score: "1", label: translation.patient_pedia_knee_other_symptoms_q4_a2 },
                          { score: "2", label: translation.patient_pedia_knee_other_symptoms_q4_a3 },
                          { score: "3", label: translation.patient_pedia_knee_other_symptoms_q4_a4 },
                          { score: "4", label: translation.patient_pedia_knee_other_symptoms_q4_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="row">
                  {evlPediaPain === "1" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_pedia_knee_other_symptoms_q5 + RigthTrans(evlPediaPain, translation)}
                        classes=""
                        value={swollenKneeR}
                        onChange={handleSwollenKneeR}
                        sliderData={[
                          {
                            score: "0",
                            label: translation.patient_pedia_knee_other_symptoms_q5_a1,
                          },
                          {
                            score: "1",
                            label: translation.patient_pedia_knee_other_symptoms_q5_a2,
                          },
                          {
                            score: "2",
                            label: translation.patient_pedia_knee_other_symptoms_q5_a3,
                          },
                          {
                            score: "3",
                            label: translation.patient_pedia_knee_other_symptoms_q5_a4,
                          },
                          {
                            score: "4",
                            label: translation.patient_pedia_knee_other_symptoms_q5_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlPediaPain === "2" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_pedia_knee_other_symptoms_q5 + LeftTrans(evlPediaPain, translation)}
                        classes=""
                        value={swollenKneeL}
                        onChange={handleSwollenKneeL}
                        sliderData={[
                          {
                            score: "0",
                            label: translation.patient_pedia_knee_other_symptoms_q5_a1,
                          },
                          {
                            score: "1",
                            label: translation.patient_pedia_knee_other_symptoms_q5_a2,
                          },
                          {
                            score: "2",
                            label: translation.patient_pedia_knee_other_symptoms_q5_a3,
                          },
                          {
                            score: "3",
                            label: translation.patient_pedia_knee_other_symptoms_q5_a4,
                          },
                          {
                            score: "4",
                            label: translation.patient_pedia_knee_other_symptoms_q5_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                {/* <div className="row">
                  {evlPediaPain === "1" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <Dropdown
                        data={[
                          {
                            value: "4",
                            label: "Extremely puffy",
                          },
                          {
                            value: "3",
                            label: "Very puffy",
                          },
                          {
                            value: "2",
                            label: "Somewhat puffy",
                          },
                          {
                            value: "1",
                            label: "A little puffy",
                          },
                          {
                            value: "0",
                            label: "Not at all puffy",
                          },
                        ]}
                        label={evlPediaPain === "3" ? translation.How_PUFFY_SWOLLEN_knee_R : translation.How_PUFFY_SWOLLEN_knee}
                        styleClass=""
                        value={swollenKneeR}
                        placeholder="None"
                        onChange={handleSwollenKneeR}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlPediaPain === "2" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <Dropdown
                        data={[
                          {
                            value: "4",
                            label: "Extremely puffy",
                          },
                          {
                            value: "3",
                            label: "Very puffy",
                          },
                          {
                            value: "2",
                            label: "Somewhat puffy",
                          },
                          {
                            value: "1",
                            label: "A little puffy",
                          },
                          {
                            value: "0",
                            label: "Not at all puffy",
                          },
                        ]}
                        label={evlPediaPain === "3" ? translation.How_PUFFY_SWOLLEN_knee_L : translation.How_PUFFY_SWOLLEN_knee}
                        styleClass=""
                        value={swollenKneeL}
                        placeholder="None"
                        onChange={handleSwollenKneeL}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div> */}

                <br />
              </div>
              <div className="function_formtop">
                <div className="row">
                  <div className="col-md-10 col-sm-6 function_head">
                    <label>
                      {translation.patient_pedia_knee_other_symptoms_instruction2}
                    </label>
                    <br />
                    <span>
                      <strong>0 </strong> = {translation.patient_pedia_knee_other_symptoms_instruction2_0},<br/>
                      <strong> 1 </strong> = {translation.patient_pedia_knee_other_symptoms_instruction2_1},<br/>
                      <strong> 2 </strong> = {translation.patient_pedia_knee_other_symptoms_instruction2_2},<br/>
                      <strong> 3 </strong> = {translation.patient_pedia_knee_other_symptoms_instruction2_3},<br/>
                      <strong> 4 </strong> = {translation.patient_pedia_knee_other_symptoms_instruction2_4}
                    </span>
                  </div>
                  <div className="col-md-6 col-sm-6 function_note">

                  </div>
                </div>
                <div className="row">
                  {evlPediaPain === "1" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_pedia_knee_other_symptoms_q6 + RigthTrans(evlPediaPain, translation)}
                        classes=""
                        value={withoutHurtLotR}
                        onChange={handleWithoutHurtLotR}
                        // sliderData={sliderLabels2}
                        sliderData={[
                          { score: "0", label: translation.patient_pedia_knee_other_symptoms_q6_a1 },
                          { score: "1", label: translation.patient_pedia_knee_other_symptoms_q6_a2 },
                          { score: "2", label: translation.patient_pedia_knee_other_symptoms_q6_a3 },
                          { score: "3", label: translation.patient_pedia_knee_other_symptoms_q6_a4 },
                          { score: "4", label: translation.patient_pedia_knee_other_symptoms_q6_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlPediaPain === "2" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_pedia_knee_other_symptoms_q6 + LeftTrans(evlPediaPain, translation)}
                        classes=""
                        value={withoutHurtLotL}
                        onChange={handleWithoutHurtLotL}
                        // sliderData={sliderLabels2}
                        sliderData={[
                          { score: "0", label: translation.patient_pedia_knee_other_symptoms_q6_a1 },
                          { score: "1", label: translation.patient_pedia_knee_other_symptoms_q6_a2 },
                          { score: "2", label: translation.patient_pedia_knee_other_symptoms_q6_a3 },
                          { score: "3", label: translation.patient_pedia_knee_other_symptoms_q6_a4 },
                          { score: "4", label: translation.patient_pedia_knee_other_symptoms_q6_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlPediaPain === "1" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_pedia_knee_other_symptoms_q7 + RigthTrans(evlPediaPain, translation)}
                        classes=""
                        value={withoutPuffyR}
                        onChange={handleWithoutPuffyR}
                        // sliderData={sliderLabels2}
                        sliderData={[
                          { score: "0", label: translation.patient_pedia_knee_other_symptoms_q7_a1 },
                          { score: "1", label: translation.patient_pedia_knee_other_symptoms_q7_a2 },
                          { score: "2", label: translation.patient_pedia_knee_other_symptoms_q7_a3 },
                          { score: "3", label: translation.patient_pedia_knee_other_symptoms_q7_a4 },
                          { score: "4", label: translation.patient_pedia_knee_other_symptoms_q7_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlPediaPain === "2" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_pedia_knee_other_symptoms_q7 + LeftTrans(evlPediaPain, translation)}
                        classes=""
                        value={withoutPuffyL}
                        onChange={handleWithoutPuffyL}
                        // sliderData={sliderLabels2}
                        sliderData={[
                          { score: "0", label: translation.patient_pedia_knee_other_symptoms_q7_a1 },
                          { score: "1", label: translation.patient_pedia_knee_other_symptoms_q7_a2 },
                          { score: "2", label: translation.patient_pedia_knee_other_symptoms_q7_a3 },
                          { score: "3", label: translation.patient_pedia_knee_other_symptoms_q7_a4 },
                          { score: "4", label: translation.patient_pedia_knee_other_symptoms_q7_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlPediaPain === "1" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_pedia_knee_other_symptoms_q8 + RigthTrans(evlPediaPain, translation)}
                        classes=""
                        value={cantHoldR}
                        onChange={handleCantHoldR}
                        // sliderData={sliderLabels2}
                        sliderData={[
                          { score: "0", label: translation.patient_pedia_knee_other_symptoms_q8_a1 },
                          { score: "1", label: translation.patient_pedia_knee_other_symptoms_q8_a2 },
                          { score: "2", label: translation.patient_pedia_knee_other_symptoms_q8_a3 },
                          { score: "3", label: translation.patient_pedia_knee_other_symptoms_q8_a4 },
                          { score: "4", label: translation.patient_pedia_knee_other_symptoms_q8_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlPediaPain === "2" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_pedia_knee_other_symptoms_q8 + LeftTrans(evlPediaPain, translation)}
                        classes=""
                        value={cantHoldL}
                        onChange={handleCantHoldL}
                        // sliderData={sliderLabels2}
                        sliderData={[
                          { score: "0", label: translation.patient_pedia_knee_other_symptoms_q8_a1 },
                          { score: "1", label: translation.patient_pedia_knee_other_symptoms_q8_a2 },
                          { score: "2", label: translation.patient_pedia_knee_other_symptoms_q8_a3 },
                          { score: "3", label: translation.patient_pedia_knee_other_symptoms_q8_a4 },
                          { score: "4", label: translation.patient_pedia_knee_other_symptoms_q8_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="row">
                  {evlPediaPain === "1" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_pedia_knee_other_symptoms_q9 + RigthTrans(evlPediaPain, translation)}
                        classes=""
                        value={mostOfTimeR}
                        onChange={handleMostOfTimeR}
                        // sliderData={sliderLabels2}
                        sliderData={[
                          { score: "0", label: translation.patient_pedia_knee_other_symptoms_q9_a1 },
                          { score: "1", label: translation.patient_pedia_knee_other_symptoms_q9_a2 },
                          { score: "2", label: translation.patient_pedia_knee_other_symptoms_q9_a3 },
                          { score: "3", label: translation.patient_pedia_knee_other_symptoms_q9_a4 },
                          { score: "4", label: translation.patient_pedia_knee_other_symptoms_q9_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {evlPediaPain === "2" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_pedia_knee_other_symptoms_q9 + LeftTrans(evlPediaPain, translation)}
                        classes=""
                        value={mostOfTimeL}
                        onChange={handleMostOfTimeL}
                        // sliderData={sliderLabels2}
                        sliderData={[
                          { score: "0", label: translation.patient_pedia_knee_other_symptoms_q9_a1 },
                          { score: "1", label: translation.patient_pedia_knee_other_symptoms_q9_a2 },
                          { score: "2", label: translation.patient_pedia_knee_other_symptoms_q9_a3 },
                          { score: "3", label: translation.patient_pedia_knee_other_symptoms_q9_a4 },
                          { score: "4", label: translation.patient_pedia_knee_other_symptoms_q9_a5 },
                        ]}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                {/* <div className="row">
                  {evlPediaPain === "1" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={evlPediaPain === "3" ? translation.Kneecap_getting_out_of_place_or_feels_like_it_is_getting_out_of_place_R : translation.Kneecap_getting_out_of_place_or_feels_like_it_is_getting_out_of_place}
                        classes=""
                        value={kneecapR}
                        onChange={handleKneecapR}
                        sliderData={sliderLabels2}
                      />
                    </div>
                  ) : ""}
                  {evlPediaPain === "2" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={evlPediaPain === "3" ? translation.Kneecap_getting_out_of_place_or_feels_like_it_is_getting_out_of_place_L : translation.Kneecap_getting_out_of_place_or_feels_like_it_is_getting_out_of_place}
                        classes=""
                        value={kneecapL}
                        onChange={handleKneecapL}
                        sliderData={sliderLabels2}
                      />
                    </div>
                  ) : ""}
                </div> */}
              </div>
              {doctorEnable === "true" && (
                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6">
                  </div>
                  <div className="col-md-6 col-sm-6 text-end">
                  <button
                      className="next_btn btn_fill me-3"
                      onClick={() => handleNext()}
                    >
                      {translation.next_button}
                      <span>
                        <img src={nextIcon} alt="" />
                        {/* <img src={nextWhiteIcon} alt="" /> */}
                      </span>
                    </button>
                    <button
                      className="next_btn btn_fill"
                      onClick={() => handleSubmit()}
                    >
                      {translation.submit}
                      <span>
                        <img src={nextIcon} alt="" />
                        {/* <img src={nextWhiteIcon} alt="" /> */}
                      </span>
                    </button>
                  </div>
                </div>
              )}
              {doctorEnable !== "true" && (
                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6 col-6">
                    <Link
                      to={"/pedia-knee-pain/" + rId+`?is_doctor_form=${doctordata}&edit=${viewicon}
                      `}
                      className="assm_back_btn"
                    >
                      <img src={backIcon} alt="" />
                      <span>{translation.back_button}</span>
                    </Link>
                  </div>
                  <div className="col-md-6 col-sm-6 col-6 text-end">
                    <button
                      className="next_btn btn_fill"
                      onClick={continueRedirect}
                    >
                      {translation.next_button}
                      <span>
                        <img src={nextIcon} alt="" />
                        <img src={nextWhiteIcon} alt="" />
                      </span>
                    </button>
                  </div>
                </div>
              )}


            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PediaKneeSymptoms;
