import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import useTranslation from "../../customHooks/translations";
import { validateInput } from "../../../utilities/Validator";
import scrangeStyles from "./KneeScoreRangeSlider.module.css";

function KneeScoreRangeSlider({



  id,
  classes,
  label,
  onChange,
  onClick,
  onMouseUp,
  value,
  validators,
  sliderData,
  view,
  maxnum,
  minnum,
  error,
  ...sliderProps
}) {
  const translation = useTranslation();
  const [countR, setCountR] = useState(0);
  const handleChange = (event) => {
    const { value } = event.target;
    onChange(value);
  };
  const handleClick = (event) => {
    const { value } = event.target;
    onClick && onClick(value);
  }
  const [sliderDatas, setsliderDatas] = useState([
    { score: "0", label: translation.none },
    { score: "1", label: translation.mild },
    { score: "2", label: translation.moderate },
    { score: "3", label: translation.severe_h },
    { score: "4", label: translation.extreme },
  ])

  useEffect(() => {
    if (sliderData) {
      setsliderDatas(sliderData)
    }
  }, [sliderData])
  return (
    <>
      <div className={`score_range_slider ${scrangeStyles.score_range_slider}`}>
        <label className={`${scrangeStyles.labels}`}>{label}</label>

        <div className={`${scrangeStyles.tabslider_content}`}>
          <div className={`${scrangeStyles.range}`}>
            <input
              {...sliderProps}
              min={minnum ? minnum : "0"}
              max={maxnum ? maxnum : "4"}
              steps="1"
              type="range"
              value={value}
              className={`slider ${classes}`}
              id={id}
              onChange={handleChange}
              onClick={handleChange}
              disabled={view}
            />
          </div>
          <ul className={` range_labels ${scrangeStyles.range_labels}`}>
            {sliderDatas.map((data) =>
              <li className={`${value === data.score ? "active" : ""}`} key={data.score}>
                {data.score} <span>{data.label}</span>
              </li>
            )}

          </ul>
          {error && (
            <>
              {value === "" && (
                <span className="text-danger mt-2">This field is required</span>
              )}
            </>

          )}
        </div>

      </div>
    </>
  );
}

KneeScoreRangeSlider.propTypes = {
  validators: PropTypes.array,
  validators: PropTypes.array,
};

KneeScoreRangeSlider.defaultProps = {
  id: "myRange",
  validators: [],
  label: "",
  value: "",
  // sliderData: [
  //   { score: "0", label: translation.none},
  //   { score: "1", label: 'mild'},
  //   { score: "2", label: 'moderate'},
  //   { score: "3", label: 'severe'},
  //   { score: "4", label: 'extreme'},
  // ]
};

export default KneeScoreRangeSlider;
