import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import backIcon from "../../../../images/back.png";
import nextWhiteIcon from "../../../../images/next-white.png";
import nextIcon from "../../../../images/next.png";
import LoadingSpinner from "../../../custom/loader/LoadingSpinner";
import InputField from "../../../custom/inputfield/InputField";
import Dropdown from "../../../custom/dropdown/Dropdown";
import { Validators } from "../../../../utilities/Validator";
import RadioSwitch from "../../../custom/radio-switch/RadioSwitch";
import useTranslation from "../../../customHooks/translations"


export default function HipPersonalData() {
    const translation = useTranslation()
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [startDate, setStartDate] = useState(new Date());
    const [sex, setSex] = useState("");
    const [dominant, setDominant] = useState("");
    const [visit, setVisit] = useState("");
    const [evaluated, setEvaluated] = useState("");
    const [previous, setPrevious] = useState("");
    const [follow, setFollow] = useState("");
    


    const continueRedirect = (e) => {
        navigate("/hip-pain");
    };
    const handleFirstName = (e) => {
        setFirstName(e);
    };
    const handleLastName = (e) => {
        setLastName(e);
    };
    const handleSex = (e) => {
        setSex(e);
    }
    const handleEmail = (e) => {
        setEmail(e);
    };
    const handleDominant = (e) => {
        setDominant(e);
    }
    const handleVisit = (e) => {
        setVisit(e);
    }
    const handleEvaluated = (e) => {
        setEvaluated(e);
    }
    const handlePrevious = (e) => {
        setPrevious(e);
    }
    const handleFollow = (e) => {
        setFollow(e);
    }

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 1000);
    }, []);


    return (
        <>
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <>
                    <div className="pain_screen mb_100">
                        <div className="panel">
                            <div className="panel-body p_25">
                                <h4 className="ph_title f_40">{translation.Personal_Data}</h4>
                                <div className="">
                                    <div className="panel">
                                        <form>
                                            <div className="panel-body p_25">
                                                <div className="row">
                                                    <div className="col-sm-5 col-md-5 mb_20">
                                                        <InputField
                                                            label={translation.First_Name}
                                                            value={firstName}
                                                            name="firstName"
                                                            type="text"
                                                            placeholder="First Name"
                                                            onChange={handleFirstName}
                                                            validators={[
                                                                { check: Validators.required, message: 'This field is required' }
                                                            ]}
                                                            styleClass="br_10 mxw_100"
                                                        />
                                                    </div>
                                                    <div className="col-sm-5 col-md-5 mb_20">
                                                        <InputField
                                                            label={translation.Last_Name}
                                                            value={lastName}
                                                            type="text"
                                                            placeholder="Last Name"
                                                            onChange={handleLastName}
                                                            styleClass="br_10 mxw_100"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-5 col-md-5 mb_20">
                                                        <Form.Group className="mb-3" controlId="fromDate">
                                                            <Form.Label>{translation.Date_of_Birth}</Form.Label>
                                                            <DatePicker
                                                                className="form-control mb_0 br_10"
                                                                selected={startDate}
                                                                onChange={(date) => setStartDate(date)}
                                                                validators={[
                                                                    { check: Validators.required, message: 'This field is required' }
                                                                ]}
                                                                styleClass="br_10 mxw_100"
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-sm-5 col-md-5 mb_20">
                                                        <Dropdown
                                                            data={[
                                                                { value: "0", label: "Select" },
                                                                { value: "male", label: "Male" },
                                                                { value: "female", label: "Female" },
                                                            ]}
                                                            label={translation.Sex}
                                                            value={sex}
                                                            onChange={handleSex}
                                                            validators={[
                                                                { check: Validators.required, message: 'This field is required' }
                                                            ]}
                                                            styleClass="br_10 mxw_100"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-10 col-md-10 mb_20">
                                                        <InputField
                                                            label={translation.Email}
                                                            value={email}
                                                            type="text"
                                                            placeholder="your-email@gmail.com"
                                                            onChange={handleEmail}
                                                            styleClass="br_10 mxw_100"
                                                            validators={[
                                                                { check: Validators.required, message: 'This field is required' }
                                                            ]}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-5 col-md-5 mb_20">
                                                        <Dropdown
                                                            data={[
                                                                {value: "", label: "Select"},
                                                                { value: "2", label: "Don't Know" },
                                                                { value: "1", label: "No" },
                                                                { value: "0", label: "Yes" },
                                                               
                                                            ]}
                                                            label={translation.Hip_arthritis}
                                                            value={dominant}
                                                            onChange={handleDominant}
                                                            validators={[
                                                                { check: Validators.required, message: 'This field is required' }
                                                            ]}
                                                            styleClass="br_10 mxw_100"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="row mb_30 button_box">
                                    <div className="col-md-6 col-sm-6 col-6">
                                        <Link to="/" className="assm_back_btn">
                                            <img src={backIcon} alt="" />
                                            <span>{translation.back_button}</span>
                                        </Link>
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-6 text-end">
                                        <button
                                            className="next_btn btn_fill"
                                            onClick={continueRedirect}
                                        >
                                            {translation.next_button}
                                            <span>
                                                <img src={nextIcon} alt="" />
                                                <img src={nextWhiteIcon} alt="" />
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    )
}
