export default{
  "patient_shoulder_pain":"الألم",
  "patient_shoulder_pain_q1":"هل تشعر بألم في كتفك؟",
  "patient_shoulder_pain_q1_a1":"لا",
  "patient_shoulder_pain_q1_a2":"نعم",
  "patient_shoulder_pain_q2":"على مقياس من 0 إلى 15، ما هو أعلى مستوى من الألم الذي شعرت به في كتفك أثناء الأنشطة العادية خلال الـ 24 ساعة الماضية؟ (0 يعني عدم وجود ألم، 15 يعني ألم لا يطاق)",
  "patient_shoulder_pain_q2_a1":"ألم لا يطاق",
  "patient_shoulder_pain_q2_a2":"لا ألم",
  "patient_shoulder_pain_q3":"هل نومك مضطرب بسبب كتفك؟",
  "patient_shoulder_pain_q3_a1":"النوم غير المضطرب",
  "patient_shoulder_pain_q3_a2":"اضطراب في بعض الأحيان",
  "patient_shoulder_pain_q3_a3":"إزعاج كل ليلة",
  "patient_shoulder_pain_q4":"ما مدى سوء ألمك اليوم؟",
  "patient_shoulder_pain_q5":"هل أنت مدخن حالي؟",
  "patient_shoulder_pain_q5_a1":"لا",
  "patient_shoulder_pain_q5_a2":"نعم",
  "patient_shoulder_pain_q5_a3":"مدخن سابق",
  "patient_shoulder_pain_q5_a2_q1":"كم سيجارة في اليوم؟",
  "patient_shoulder_pain_q5_a3_q1":"منذ متى تركت (سنوات)؟",
  "patient_shoulder_pain_q5_a3_q2":"كم سنة دخنت؟",
  "patient_shoulder_pain_q6":"هل تتناول أدوية مسكنة للألم (مثل الباراسيتامول ، إلخ)؟",
  "patient_shoulder_pain_q6_a1":"لا",
  "patient_shoulder_pain_q6_a2":"نعم",
  "patient_shoulder_pain_q6_a2_q1":"هل تتناول مسكنات قوية للألم (مثل الكودايين ، الترامادول ، المورفين)؟",
  "patient_shoulder_pain_q6_a2_q1_a1":"لا",
  "patient_shoulder_pain_q6_a2_q1_a2":"نعم",
  "patient_shoulder_pain_q6_a2_q2":"كم عدد  الأقراص التي تتناولها كل يوم في المتوسط؟",
  "patient_shoulder_pain_q7":"أي من العبارات التالية يصف الألم بشكل أفضل",
  "patient_shoulder_pain_q7_a1":"لا شيء",
  "patient_shoulder_pain_q7_a2":"من حين لآخر وخفيف",
  "patient_shoulder_pain_q7_a3":"حاضر أثناء نشاط شديد أو معين ، ويحتاج الأسبرين في بعض الأحيان",
  "patient_shoulder_pain_q7_a4":"حاضر أثناء النشاط الخفيف ، قليلًا أو معدومًا أثناء الراحة ،ويحتاج الأسبرين بشكل متكرر",
  "patient_shoulder_pain_q7_a5":"دائما حاضر ، محتمل ،يحتاج دواء قوي  من حين لآخر",
  "patient_shoulder_pain_q7_a6":"دائما حاضر ، لا يطاق ،  يحتاج دواء قوي  بشكل متكرر",
  "patient_shoulder_pain_q8":"هل تصاب بوخز أو تنميل في ذراعك؟",
  "patient_shoulder_pain_q8_a1":"لا",
  "patient_shoulder_pain_q8_a2":"نعم",
  "patient_shoulder_pain_q9":"ضع علامة على مكان الألم",
  "patient_shoulder_pain_q9_a1":"الجهة الأمامية",
  "patient_shoulder_pain_q9_a2":"الجهة الخلفية",
  "patient_shoulder_function":"الوظيفة",
  "patient_shoulder_function_q1":"أي من العبارات التالية يصف بشكل أفضل وظيفة الكتف المصاب؟",
  "patient_shoulder_function_q1_a1":"طبيعي",
  "patient_shoulder_function_q1_a2":"تقييد طفيف فقط ، قادر على العمل فوق مستوى الكتف",
  "patient_shoulder_function_q1_a3":"معظم الأعمال المنزلية ، والتسوق ، والقيادة ، وتمشيط الشعر ، واللباس / خلع الملابس",
  "patient_shoulder_function_q1_a4":"قادرة على القيام بالأعمال المنزلية الخفيفة ومعظم أنشطة الحياة اليومية",
  "patient_shoulder_function_q1_a5":"فقط الأنشطة الخفيفة ممكنة",
  "patient_shoulder_function_q1_a6":"غير قادر على استخدام الطرف",
  "patient_shoulder_function_instruction":"حدد الرقم الذي يشير إلى قدرتك على القيام بالأنشطة التالية",
  "patient_shoulder_function_instruction_0":"غير قادر",
  "patient_shoulder_function_instruction_1":"صعب جدا",
  "patient_shoulder_function_instruction_2":"من الصعب إلى حد ما",
  "patient_shoulder_function_instruction_3":"ليس من الصعب",
  "patient_shoulder_function_note":"ملاحظة: يرجى الإجابة على الأسئلة التالية المتعلقة بوظيفة الكتف.",
  "patient_shoulder_function_q2":"ارتدِ معطفًا / قميصًا",
  "patient_shoulder_function_q2_a0":"غير قادر",
  "patient_shoulder_function_q2_a1":"صعب جدا",
  "patient_shoulder_function_q2_a2":"من الصعب إلى حد ما",
  "patient_shoulder_function_q2_a3":"ليس من الصعب",
  "patient_shoulder_function_q3":"النوم على جانبك المؤلم أو المصاب",
  "patient_shoulder_function_q3_a0":"غير قادر",
  "patient_shoulder_function_q3_a1":"صعب جدا",
  "patient_shoulder_function_q3_a2":"من الصعب إلى حد ما",
  "patient_shoulder_function_q3_a3":"ليس من الصعب",
  "patient_shoulder_function_q4":"اغسل ظهرك/اربط حمالة الصدر",
  "patient_shoulder_function_q4_a0":"غير قادر",
  "patient_shoulder_function_q4_a1":"صعب جدا",
  "patient_shoulder_function_q4_a2":"من الصعب إلى حد ما",
  "patient_shoulder_function_q4_a3":"ليس من الصعب",
  "patient_shoulder_function_q5":"إدارة استخدام المرحاض",
  "patient_shoulder_function_q5_a0":"غير قادر",
  "patient_shoulder_function_q5_a1":"صعب جدا",
  "patient_shoulder_function_q5_a2":"من الصعب إلى حد ما",
  "patient_shoulder_function_q5_a3":"ليس من الصعب",
  "patient_shoulder_function_q6":"تمشيط الشعر",
  "patient_shoulder_function_q6_a0":"غير قادر",
  "patient_shoulder_function_q6_a1":"صعب جدا",
  "patient_shoulder_function_q6_a2":"من الصعب إلى حد ما",
  "patient_shoulder_function_q6_a3":"ليس من الصعب",
  "patient_shoulder_function_q7":"الحلاقة / وضع المكياج",
  "patient_shoulder_function_q7_a0":"غير قادر",
  "patient_shoulder_function_q7_a1":"صعب جدا",
  "patient_shoulder_function_q7_a2":"من الصعب إلى حد ما",
  "patient_shoulder_function_q7_a3":"ليس من الصعب",
  "patient_shoulder_function_q8":"تنظيف الأسنان",
  "patient_shoulder_function_q8_a0":"غير قادر",
  "patient_shoulder_function_q8_a1":"صعب جدا",
  "patient_shoulder_function_q8_a2":"من الصعب إلى حد ما",
  "patient_shoulder_function_q8_a3":"ليس من الصعب",
  "patient_shoulder_function_q9":"املأ كوبًا بزجاجة ممتلئة",
  "patient_shoulder_function_q9_a0":"غير قادر",
  "patient_shoulder_function_q9_a1":"صعب جدا",
  "patient_shoulder_function_q9_a2":"من الصعب إلى حد ما",
  "patient_shoulder_function_q9_a3":"ليس من الصعب",
  "patient_shoulder_function_q10":"الشرب (أحضر كأسًا ممتلئًا إلى الفم)",
  "patient_shoulder_function_q10_a0":"غير قادر",
  "patient_shoulder_function_q10_a1":"صعب جدا",
  "patient_shoulder_function_q10_a2":"من الصعب إلى حد ما",
  "patient_shoulder_function_q10_a3":"ليس من الصعب",
  "patient_shoulder_function_q11":"تناول الحساء (ملعقة كاملة في الفم)",
  "patient_shoulder_function_q11_a0":"غير قادر",
  "patient_shoulder_function_q11_a1":"صعب جدا",
  "patient_shoulder_function_q11_a2":"من الصعب إلى حد ما",
  "patient_shoulder_function_q11_a3":"ليس من الصعب",
  "patient_shoulder_function_q12":"مصافحة يد شخص ما / فتح الباب",
  "patient_shoulder_function_q12_a0":"غير قادر",
  "patient_shoulder_function_q12_a1":"صعب جدا",
  "patient_shoulder_function_q12_a2":"من الصعب إلى حد ما",
  "patient_shoulder_function_q12_a3":"ليس من الصعب",
  "patient_shoulder_function_q13":"استخدام الهاتف (على مستوى الأذن)",
  "patient_shoulder_function_q13_a0":"غير قادر",
  "patient_shoulder_function_q13_a1":"صعب جدا",
  "patient_shoulder_function_q13_a2":"من الصعب إلى حد ما",
  "patient_shoulder_function_q13_a3":"ليس من الصعب",
  "patient_shoulder_function_q14":"كتابة حرف /  التوقيع على الورق / استخدم لوحة المفاتيح ",
  "patient_shoulder_function_q14_a0":"غير قادر",
  "patient_shoulder_function_q14_a1":"صعب جدا",
  "patient_shoulder_function_q14_a2":"من الصعب إلى حد ما",
  "patient_shoulder_function_q14_a3":"ليس من الصعب",
  "patient_shoulder_function_q15":"الوصول إلى رف مرتفع (فوق الكتف)",
  "patient_shoulder_function_q15_a0":"غير قادر",
  "patient_shoulder_function_q15_a1":"صعب جدا",
  "patient_shoulder_function_q15_a2":"من الصعب إلى حد ما",
  "patient_shoulder_function_q15_a3":"ليس من الصعب",
  "patient_shoulder_function_q16":"رفع 0.5 كجم فوق الكتف",
  "patient_shoulder_function_q16_a0":"غير قادر",
  "patient_shoulder_function_q16_a1":"صعب جدا",
  "patient_shoulder_function_q16_a2":"من الصعب إلى حد ما",
  "patient_shoulder_function_q16_a3":"ليس من الصعب",
  "patient_shoulder_function_q17":"رفع 4.5 كجم  فوق الكتف",
  "patient_shoulder_function_q17_a0":"غير قادر",
  "patient_shoulder_function_q17_a1":"صعب جدا",
  "patient_shoulder_function_q17_a2":"من الصعب إلى حد ما",
  "patient_shoulder_function_q17_a3":"ليس من الصعب",
  "patient_shoulder_function_q18":"هل يمكنك حمل 10 كجم إلى جانبك على الكتف المصاب",
  "patient_shoulder_function_q18_a0":"غير قادر",
  "patient_shoulder_function_q18_a1":"صعب جدا",
  "patient_shoulder_function_q18_a2":"من الصعب إلى حد ما",
  "patient_shoulder_function_q18_a3":"ليس من الصعب",
  "patient_shoulder_function_q19":"رمي الكرة في الأسفل (3 أمتار على الأقل)",
  "patient_shoulder_function_q19_a0":"غير قادر",
  "patient_shoulder_function_q19_a1":"صعب جدا",
  "patient_shoulder_function_q19_a2":"من الصعب إلى حد ما",
  "patient_shoulder_function_q19_a3":"ليس من الصعب",
  "patient_shoulder_function_q20":"رمي الكرة من فوق رأسك (6 أمتار على الأقل)",
  "patient_shoulder_function_q20_a0":"غير قادر",
  "patient_shoulder_function_q20_a1":"صعب جدا",
  "patient_shoulder_function_q20_a2":"من الصعب إلى حد ما",
  "patient_shoulder_function_q20_a3":"ليس من الصعب",
  "patient_shoulder_function_q21":"القيام بالعمل المعتاد كاملا",
  "patient_shoulder_function_q21_a0":"غير قادر",
  "patient_shoulder_function_q21_a1":"صعب جدا",
  "patient_shoulder_function_q21_a2":"من الصعب إلى حد ما",
  "patient_shoulder_function_q21_a3":"ليس من الصعب",
  "patient_shoulder_function_q22":"ما مقدار العمل الطبيعي الذي يسمح لك كتفك بأداءه؟",
  "patient_shoulder_function_q22_a0":"لا شيء",
  "patient_shoulder_function_q22_a1":"الجميع",
  "patient_shoulder_function_q23":"ممارس الهوايات / الرياضة المعتادة  كاملا ",
  "patient_shoulder_function_q23_a0":"غير قادر",
  "patient_shoulder_function_q23_a1":"صعب جدا",
  "patient_shoulder_function_q23_a2":"من الصعب إلى حد ما",
  "patient_shoulder_function_q23_a3":"ليس من الصعب",
  "patient_shoulder_function_q24":"ما مقدار نشاطك الترفيهي الطبيعي الذي يسمح لك كتفك بأدائه؟",
  "patient_shoulder_function_q24_a0":"لا شيء",
  "patient_shoulder_function_q24_a1":"الجميع",
  "patient_shoulder_function_q25":"يرجى تقديم قائمة بأنشطة العمل",
  "patient_shoulder_function_q26":"يرجى ذكر الهوايات / الأنشطة الرياضية",
  "patient_shoulder_feeling_instability":"الشعور بعدم الاستقرار",
  "patient_shoulder_feeling_instability_q1":"هل يشعر كتفك بعدم الاستقرار",
  "patient_shoulder_feeling_instability_q1_a1":"لا",
  "patient_shoulder_feeling_instability_q1_a2":"نعم",
  "patient_shoulder_feeling_instability_q1_a2_q1":"يرجى توضيح مدى عدم استقرار كتفك",
  "patient_shoulder_wosi":"مؤشر عدم استقرار الكتف  Western Ontario",
  "patient_shoulder_wosi_instruction":"التعليمات: يُطلب منك الإشارة في هذا الجزء من الاستبيان إلى عدد الأعراض التي عانيت منها في الأسبوع الماضي والمتعلقة بمشكلة كتفك. ما عليك سوى تحريك شريط التمرير على طول الخط بحيث يتوافق بدقة مع أعراضك.",
  "patient_shoulder_wosi_note":"ملاحظة: كلما قمت بتحريك شريط التمرير إلى اليمين أكثر ، كلما واجهت هذا العرض. كلما حرّكت شريط التمرير إلى اليسار ، كلما قل ذلك العرض. إذا كانت لديك أي أسئلة بخصوص الغرض من أي سؤال معين ، فيرجى طرحها.",
  "patient_shoulder_wosi_sectionA":"القسم أ: الأعراض الجسدية",
  "patient_shoulder_wosi_q1":" ما هي شدة الألم الذي تشعر به في كتفك عند ممارسة الأنشطة العلوية؟",
  "patient_shoulder_wosi_q1_a1":"لا ألم",
  "patient_shoulder_wosi_q1_a2":"ألم شديد",
  "patient_shoulder_wosi_q2":"ما هي شدة الألم أو الخدر الذي تشعر به في كتفك؟",
  "patient_shoulder_wosi_q2_a1":"لا يوجد ألم / الخفقان",
  "patient_shoulder_wosi_q2_a2":"آلام شديدة  / الخفقان",
  "patient_shoulder_wosi_q3":"ما مقدار الضعف أو نقص القوة الذي تشعر به في كتفك؟",
  "patient_shoulder_wosi_q3_a1":"لا ضعف",
  "patient_shoulder_wosi_q3_a2":"ضعف شديد",
  "patient_shoulder_wosi_q4":"ما مقدار التعب أو نقص القدرة على التحمل الذي تشعر به في كتفك؟",
  "patient_shoulder_wosi_q4_a1":"لا تعب",
  "patient_shoulder_wosi_q4_a2":"التعب الشديد",
  "patient_shoulder_wosi_q5":"ما مقدار الطقطقة التي تشعر بها في كتفك؟",
  "patient_shoulder_wosi_q5_a1":"لا يوجد طقطقة",
  "patient_shoulder_wosi_q5_a2":"طقطقة شديدة",
  "patient_shoulder_wosi_q6":"ما مقدار التصلب التي تشعر به في كتفك؟",
  "patient_shoulder_wosi_q6_a1":"لا تصلب",
  "patient_shoulder_wosi_q6_a2":"تصلب شديد",
  "patient_shoulder_wosi_q7":"ما مدى الانزعاج الذي تشعر به في عضلات رقبتك نتيجة لكتفك؟",
  "patient_shoulder_wosi_q7_a1":"لا إزعاج",
  "patient_shoulder_wosi_q7_a2":"الانزعاج الشديد",
  "patient_shoulder_wosi_q8":"ما مقدار الشعور بعدم الاستقرار أو الارتخاء الذي تشعر به في كتفك؟",
  "patient_shoulder_wosi_q8_a1":"لا يوجد عدم استقرار",
  "patient_shoulder_wosi_q8_a2":"عدم الاستقرار الشديد",
  "patient_shoulder_wosi_q9":"كم تعوض كتفك بالعضلات الأخرى؟",
  "patient_shoulder_wosi_q9_a1":"مُطْلَقاً",
  "patient_shoulder_wosi_q9_a2":"أقصى",
  "patient_shoulder_wosi_q10":"كم هو نقص نطاق حركة كتفك؟",
  "patient_shoulder_wosi_q10_a1":"لا يوجد نقص",
  "patient_shoulder_wosi_q10_a2":"نقص شديدة",
  "patient_shoulder_wosi_sectionB":"القسم ب: الرياضة / الاستجمام / العمل",
  "patient_shoulder_wosi_q11":"إلى أي مدى حد كتفك من المقدار الذي يمكنك المشاركة فيه في الألعاب الرياضية أو الأنشطة الترفيهية؟",
  "patient_shoulder_wosi_q11_a1":"غير محدود",
  "patient_shoulder_wosi_q11_a2":"محدودة للغاية",
  "patient_shoulder_wosi_q12":"ما مدى تأثير كتفك على قدرتك على أداء المهارات المحددة المطلوبة لرياضتك أو عملك؟",
  "patient_shoulder_wosi_q12_note":"إذا كان كتفك يؤثر على كل من الرياضة والعمل ، ماهيا المنطقة الأكثر تضررًا.",
  "patient_shoulder_wosi_q12_a1":"لم تتأثر",
  "patient_shoulder_wosi_q12_a2":"شديد التأثر",
  "patient_shoulder_wosi_q13":"ما مدى شعورك بالحاجة إلى حماية ذراعك أثناء الأنشطة؟",
  "patient_shoulder_wosi_q13_a1":"مُطْلَقاً",
  "patient_shoulder_wosi_q13_a2":"أقصى",
  "patient_shoulder_wosi_q14":"ما مدى الصعوبة التي تواجهها في رفع الأشياء الثقيلة تحت مستوى الكتف؟",
  "patient_shoulder_wosi_q14_a1":"لا توجد صعوبة",
  "patient_shoulder_wosi_q14_a2":"صعوبة بالغة",
  "patient_shoulder_wosi_sectionC":"القسم ج: أسلوب الحياة",
  "patient_shoulder_wosi_q15":"ما مدى خوفك من السقوط على كتفك؟",
  "patient_shoulder_wosi_q15_a1":"لا خوف",
  "patient_shoulder_wosi_q15_a2":"الخوف الشديد",
  "patient_shoulder_wosi_q16":"ما مدى الصعوبة التي تواجهها في الحفاظ على المستوى المطلوب من اللياقة؟",
  "patient_shoulder_wosi_q16_a1":"لا توجد صعوبة",
  "patient_shoulder_wosi_q16_a2":"صعوبة بالغة",
  "patient_shoulder_wosi_q17":"ما مدى الصعوبة التي تواجهك عند اللعب \"بالتدافع\" أو \"اللعب الخشن\" مع العائلة أو الأصدقاء؟",
  "patient_shoulder_wosi_q17_a1":"لا توجد صعوبة",
  "patient_shoulder_wosi_q17_a2":"صعوبة بالغة",
  "patient_shoulder_wosi_q18":"ما مدى صعوبة النوم لديك بسبب كتفك؟",
  "patient_shoulder_wosi_q18_a1":"لا توجد صعوبة",
  "patient_shoulder_wosi_q18_a2":"صعوبة بالغة",
  "patient_shoulder_wosi_sectionD":"القسم د: العواطف",
  "patient_shoulder_wosi_q19":"ما مدى وعيك بكتفك؟",
  "patient_shoulder_wosi_q19_a1":"غير واع",
  "patient_shoulder_wosi_q19_a2":"واع للغاية",
  "patient_shoulder_wosi_q20":"ما مدى قلقك من تفاقم كتفك؟",
  "patient_shoulder_wosi_q20_a1":"لا قلق",
  "patient_shoulder_wosi_q20_a2":"قلق للغاية",
  "patient_shoulder_wosi_q21":"ما مدى الإحباط الذي تشعر به بسبب كتفك؟",
  "patient_shoulder_wosi_q21_a1":"لا إحباط",
  "patient_shoulder_wosi_q21_a2":"محبط للغاية",
  "patient_shoulder_ac_joint":"المفصل الأخرمي الترقوي AC",
  "patient_shoulder_ac_joint_q1":"هل لديك عدم استقرار في المفصل الأخرمي الترقوي AC؟",
  "patient_shoulder_ac_joint_q1_note":"ملحوظة: المفصل الأخرمي الترقوي AC  هو المفصل الموجود في الجزء العلوي من الكتف الذي يربط بين عظمة الترقوة والطرف الأمامي للكتف.",
  "patient_shoulder_ac_joint_q1_a1":"لا",
  "patient_shoulder_ac_joint_q1_a2":"نعم",
  "patient_shoulder_sacs":"درجة AC المحددة (SACS)",
  "patient_shoulder_sacs_explanation":"التفسير: لكل سؤال ، سيُطلب منك تقييم مستوى الألم أو الصعوبة في أداء مهام مختلفة. تم تصميم مقياس التصنيف بحيث  يعكس الرقم 0  عدم وجود أي ألم أو صعوبة بينما يعكس الرقم 10 أشد قدر من الألم أو الصعوبة.",
  "patient_shoulder_sacs_note":"ملحوظة: التصنيف 2 يمثل الحد الأدنى من الألم. إذا كانت لديك أي أسئلة بخصوص الغرض من أي سؤال معين ، فيرجى طرحها.",
  "patient_shoulder_sacs_sectionA":"القسم أ: الألم",
  "patient_shoulder_sacs_a1":"قيم مستوى الألم في كتفك عندما يكون في أسوأ حالاته - الأشد.",
  "patient_shoulder_sacs_a1_a1":"لا ألم",
  "patient_shoulder_sacs_a1_a2":"أقصى ألم شديد",
  "patient_shoulder_sacs_a2":"قيم مستوى الألم في كتفك عندما يكون في أفضل حالاته.",
  "patient_shoulder_sacs_a2_a1":"لا ألم",
  "patient_shoulder_sacs_a2_a2":"أقصى ألم شديد",
  "patient_shoulder_sacs_a3":"قيم مستوى الألم عند الضغط / اللمس أو عند الضغط على الجزء العلوي من كتفك.",
  "patient_shoulder_sacs_a3_note":"على سبيل المثال. حاسوب محمول أو حقيبة اليد",
  "patient_shoulder_sacs_a3_a1":"لا ألم",
  "patient_shoulder_sacs_a3_a2":"أقصى ألم شديد",
  "patient_shoulder_sacs_a4":"قيم مستوى توتر الألم أو الإحساس بالسحب الذي تشعر به في رقبتك و / أو منطقة الكتف المصابة.",
  "patient_shoulder_sacs_a4_a1":"لا ألم",
  "patient_shoulder_sacs_a4_a2":"أقصى ألم شديد",
  "patient_shoulder_sacs_sectionB":"القسم ب: الوظيفة",
  "patient_shoulder_sacs_b1":"قيم مقدار النقر و / أو الطحن و / أو الطقطقة التي تشعر بها في كتفك.",
  "patient_shoulder_sacs_b1_a1":"لا يوجد طقطقة",
  "patient_shoulder_sacs_b1_a2":"طقطقة شديدة",
  "patient_shoulder_sacs_b2":"حدد ما إذا كانت ذراعك تشعر بالحاجة إلى الدعم عند المشي",
  "patient_shoulder_sacs_b2_note":"على سبيل المثال ، ضع ذراعك في الجيب أو باستخدام حمالة الكتف",
  "patient_shoulder_sacs_b2_a1":"لا حاجة للدعم",
  "patient_shoulder_sacs_b2_a2":"الحاجة الماسة للدعم",
  "patient_shoulder_sacs_b3":"قيم مستوى الصعوبة في حمل الوزن أو الانحناء على ذراعك",
  "patient_shoulder_sacs_b3_note":"على سبيل المثال الرفع  ,اليوجا, ركوب الدراجات",
  "patient_shoulder_sacs_b3_a1":"لا توجد صعوبة",
  "patient_shoulder_sacs_b3_a2":"صعوبة بالغة أو عدم القدرة على الأداء",
  "patient_shoulder_sacs_b4":"قيم مستوى الصعوبة في حمل الأشياء الثقيلة بجانبك لأكثر من 5 دقائق",
  "patient_shoulder_sacs_b4_note":"على سبيل المثال حقيبة تسوق",
  "patient_shoulder_sacs_b4_a1":"لا توجد صعوبة",
  "patient_shoulder_sacs_b4_a2":"صعوبة بالغة أو عدم القدرة على الأداء",
  "patient_shoulder_sacs_b5":"قيِّم مستوى الصعوبة في الوصول إلى الجانب الآخر من جسمك باستخدام ذراعك المصابة",
  "patient_shoulder_sacs_b5_note":"على سبيل المثال للوصول عبر ولمس الكتف السليم",
  "patient_shoulder_sacs_b5_a1":"لا توجد صعوبة",
  "patient_shoulder_sacs_b5_a2":"صعوبة بالغة أو عدم القدرة على الأداء",
  "patient_shoulder_sacs_b6":"قيم مستوى الصعوبة في دفع الأشياء الثقيلة أو الأوزان فوقك",
  "patient_shoulder_sacs_b6_note":"على سبيل المثال أكثر من خمسة كيلوغرامات",
  "patient_shoulder_sacs_b6_a1":"لا توجد صعوبة",
  "patient_shoulder_sacs_b6_a2":"صعوبة بالغة أو عدم القدرة على الأداء",
  "patient_shoulder_sacs_b7":"قيم مستوى صعوبة حمل ذراعك المصابة أمامك لفترة طويلة",
  "patient_shoulder_sacs_b7_note":"على سبيل المثال ، إمساك عجلة القيادة أثناء القيادة",
  "patient_shoulder_sacs_b7_a1":"لا توجد صعوبة",
  "patient_shoulder_sacs_b7_a2":"صعوبة بالغة أو عدم القدرة على الأداء",
  "patient_shoulder_sacs_b8":"قيم مستوى الصعوبة التي تواجهها في أداء مهنتك المعتادة.",
  "patient_shoulder_sacs_b8_a1":"لا توجد صعوبة",
  "patient_shoulder_sacs_b8_a2":"صعوبة بالغة أو عدم القدرة على الأداء",
  "patient_shoulder_sacs_b8_a3":"لا ينطبق",
  "patient_shoulder_sacs_b9":"قيم كم كان عليك تغيير / تعديل مهنتك المعتادة من أجل تعويض مشكلة كتفك.",
  "patient_shoulder_sacs_b9_a1":"لا توجد صعوبة",
  "patient_shoulder_sacs_b9_a2":"صعوبة بالغة أو عدم القدرة على الأداء",
  "patient_shoulder_sacs_b9_a3":"لا ينطبق",
  "patient_shoulder_sacs_b10":"قيم مستوى الصعوبة التي تواجهها في أداء نشاطك الرياضي الأساسي.",
  "patient_shoulder_sacs_b10_a1":"لا توجد صعوبة",
  "patient_shoulder_sacs_b10_a2":"صعوبة بالغة أو عدم القدرة على الأداء",
  "patient_shoulder_sacs_b10_a3":"لا ينطبق",
  "patient_shoulder_sacs_b11":"قيم كم كان عليك تعديل نشاطك الرياضي الأساسي للتعويض عن مشكلة كتفك.",
  "patient_shoulder_sacs_b11_a1":"لا توجد صعوبة",
  "patient_shoulder_sacs_b11_a2":"صعوبة بالغة أو عدم القدرة على الأداء",
  "patient_shoulder_sacs_b11_a3":"لا ينطبق",
  "patient_shoulder_sacs_sectionC":"القسم ج: جودة الحياة والرضا",
  "patient_shoulder_sacs_c1":"قيم مستوى الاختلاف في مظهر عظام أعلى كتفك مقارنةً بالكتف الطبيعي",
  "patient_shoulder_sacs_c1_note":"بما في ذلك وجود ورم",
  "patient_shoulder_sacs_c1_a1":"لا فرق",
  "patient_shoulder_sacs_c1_a2":"مختلف تمامًا",
  "patient_shoulder_sacs_c2":"قيم المستوى العام للقلق أو الإجهاد الذي تسببت فيه مشكلة كتفك.",
  "patient_shoulder_sacs_c2_a1":"لا قلق / توتر",
  "patient_shoulder_sacs_c2_a2":"مستوى شديد من القلق / التوتر",
  "patient_shoulder_sacs_c3":"كم مرة تدرك مشكلة كتفك؟",
  "patient_shoulder_sacs_c3_a1":"أبداً",
  "patient_shoulder_sacs_c3_a2":"باستمرار",
  "patient_shoulder_sacs_c4":"هل قمت بتعديل نمط حياتك لتجنب احتمال تلف أو تفاقم مشكلة كتفك؟",
  "patient_shoulder_sacs_c4_a1":"مُطْلَقاً",
  "patient_shoulder_sacs_c4_a2":"تماما",
  "patient_shoulder_sacs_c5":"ما مدى  استيائك من كتفك؟",
  "patient_shoulder_sacs_c5_a1":"سعيد جدا",
  "patient_shoulder_sacs_c5_a2":"غير سعيد للغاية",
  "patient_shoulder_sacs_c6":"كيف تقيم كتفك بشكل عام كنسبة مئوية مقارنة بتوقعاتك للكتف \"الطبيعي\" حيث يكون 0٪ ضعيفًا و 100٪ جيد جدًا؟",
  "patient_shoulder_satisfaction":"الرضا",
  "patient_shoulder_satisfaction_q1":"فيما يتعلق بكتفك المصاب ، أنت الآن",
  "patient_shoulder_satisfaction_q1_a1":"راضي و / أو أفضل",
  "patient_shoulder_satisfaction_q1_a2":"غير راض و / أو أسوأ",
  "patient_shoulder_complete":"نهاية الاستبيان",
  "patient_shoulder_complete_text1":"شكرًا لك على الوقت الذي أمضيته في ملء هذا النموذج.",
  "patient_shoulder_complete_text2":"الرجاء النقر فوق إرسال لإكمال ورؤية درجاتك.",
  "patient_shoulder_complete_text3":"سيتم التعامل مع إجاباتك بسرية ولن تستخدم أي تقارير سوى معلومات مجهولة الهوية.",
  "patient_shoulder_results":"النتائج",
  "patient_shoulder_results_text1":"فيما يلي درجات الألم ووظيفة الكتف ، والتي يتم حسابها من الاستبيان الخاص بك.",
  "patient_shoulder_results_pain":"الألم",
  "patient_shoulder_results_function":"الوظيفة",
  "patient_shoulder_results_instability":"عدم الاستقرار",
  "patient_shoulder_results_selfevaluation":"التقييم الذاتي للكتف",
  "patient_shoulder_results_text2":"سيكون الطبيب معك قريبًا",
  "patient_hip_pain":"الألم",
  "patient_hip_pain_q1":"كيف تصف بشكل عام الألم الذي تعاني منه عادةً في مفصل الورك خلال الشهر الماضي؟",
  "patient_hip_pain_q1_a1":"لا شيء",
  "patient_hip_pain_q1_a2":"خفيف جدا",
  "patient_hip_pain_q1_a3":"خفيف",
  "patient_hip_pain_q1_a4":"معتدل",
  "patient_hip_pain_q1_a5":"شديد",
  "patient_hip_pain_q2":"مدى شدة الألم في مفصل الورك الآن؟ (0 يعني بدون ألم و10 يعني أشد ألم يمكنك تخيله)",
  "patient_hip_pain_q2_a1":"لا ألم",
  "patient_hip_pain_q2_a2":"أقصى ألم شديد",
  "patient_hip_pain_q3":"أي مما يلي يصف ألمك بأفضل شكل؟",
  "patient_hip_pain_q3_a1":"لا شيء / تجاهل",
  "patient_hip_pain_q3_a2":"طفيف ، عرضي ، لا يوجد حل وسط في النشاط",
  "patient_hip_pain_q3_a3":"ألم خفيف ، ليس له تأثير على متوسط الأنشطة ، ألم بعد النشاط المعتاد ، استخدام الأسبرين",
  "patient_hip_pain_q3_a4":"معتدل ، مقبول ، إجراء تعديلات على الحركة ، في بعض الأحيان الكوديين",
  "patient_hip_pain_q3_a5":"ألم ملحوظ ، تقييد مهم للأنشطة",
  "patient_hip_pain_q3_a6":"عاجز كليا",
  "patient_hip_pain_q4":"كم مرة يحدث لديك ألم في مفصل الورك؟",
  "patient_hip_pain_q4_a1":"أبداً",
  "patient_hip_pain_q4_a2":"شهريا",
  "patient_hip_pain_q4_a3":"أسبوعيا",
  "patient_hip_pain_q4_a4":"يوميا",
  "patient_hip_pain_q4_a5":"دائمًا",
  "patient_hip_pain_q5":"مدى تأثير ألم مفصل الورك على عملك اليومي العادي (بما في ذلك الأعمال المنزلية)؟",
  "patient_hip_pain_q5_a1":"مُطْلَقاً",
  "patient_hip_pain_q5_a2":"قليلا",
  "patient_hip_pain_q5_a3":"باعتدال",
  "patient_hip_pain_q5_a4":"إلى حد كبير",
  "patient_hip_pain_q5_a5":"تماما",
  "patient_hip_pain_instruction":"ما هو مستوى ألم الورك الذي تشعر به أثناء الأنشطة التالية؟",
  "patient_hip_pain_instruction_0":"لا شيء",
  "patient_hip_pain_instruction_1":"خفيف",
  "patient_hip_pain_instruction_2":"معتدل",
  "patient_hip_pain_instruction_3":"شديد",
  "patient_hip_pain_instruction_4":"أقصى",
  "patient_hip_pain_note":"ملاحظة: يرجى الإجابة على الأسئلة التالية المتعلقة بأنشطة الورك.",
  "patient_hip_pain_q6":" تمديد الورك تمامًا",
  "patient_hip_pain_q6_a1":"لا شيء",
  "patient_hip_pain_q6_a2":"خفيف",
  "patient_hip_pain_q6_a3":"معتدل",
  "patient_hip_pain_q6_a4":"شديد",
  "patient_hip_pain_q6_a5":"أقصى",
  "patient_hip_pain_q7":"انحناء الورك تمامًا  ",
  "patient_hip_pain_q7_a1":"لا شيء",
  "patient_hip_pain_q7_a2":"خفيف",
  "patient_hip_pain_q7_a3":"معتدل",
  "patient_hip_pain_q7_a4":"شديد",
  "patient_hip_pain_q7_a5":"أقصى",
  "patient_hip_pain_q8":"المشي على سطح مستوٍ",
  "patient_hip_pain_q8_a1":"لا شيء",
  "patient_hip_pain_q8_a2":"خفيف",
  "patient_hip_pain_q8_a3":"معتدل",
  "patient_hip_pain_q8_a4":"شديد",
  "patient_hip_pain_q8_a5":"أقصى",
  "patient_hip_pain_q9":"كم من الوقت يمكنك المشي قبل أن يصبح الألم في مفصل الورك شديدًا؟ (مع أو بدون عكاز)",
  "patient_hip_pain_q9_a1":"بدون ألم / أكثر من 30 دقيقة / غير محدود",
  "patient_hip_pain_q9_a2":"16-30 دقيقة / 6 كتل",
  "patient_hip_pain_q9_a3":"5-15 دقيقة / 2-3 كتل",
  "patient_hip_pain_q9_a4":"حول المنزل / في الداخل فقط",
  "patient_hip_pain_q9_a5":"لا يستطيع المشي إطلاقا / ألم شديد عند المشي / سرير وكرسي فقط",
  "patient_hip_pain_q10":"المشي على سطح صلب",
  "patient_hip_pain_q10_a1":"لا شيء",
  "patient_hip_pain_q10_a2":"خفيف",
  "patient_hip_pain_q10_a3":"معتدل",
  "patient_hip_pain_q10_a4":"شديد",
  "patient_hip_pain_q10_a5":"أقصى",
  "patient_hip_pain_q11":"المشي على سطح غير مستوٍ",
  "patient_hip_pain_q11_a1":"لا شيء",
  "patient_hip_pain_q11_a2":"خفيف",
  "patient_hip_pain_q11_a3":"معتدل",
  "patient_hip_pain_q11_a4":"شديد",
  "patient_hip_pain_q11_a5":"أقصى",
  "patient_hip_pain_q12":"الصعود أو النزول على الدرج",
  "patient_hip_pain_q12_a1":"لا شيء",
  "patient_hip_pain_q12_a2":"خفيف",
  "patient_hip_pain_q12_a3":"معتدل",
  "patient_hip_pain_q12_a4":"شديد",
  "patient_hip_pain_q12_a5":"أقصى",
  "patient_hip_pain_q13":"في الليل أثناء النوم",
  "patient_hip_pain_q13_a1":"لا شيء",
  "patient_hip_pain_q13_a2":"خفيف",
  "patient_hip_pain_q13_a3":"معتدل",
  "patient_hip_pain_q13_a4":"شديد",
  "patient_hip_pain_q13_a5":"أقصى",
  "patient_hip_pain_q14":"كم مرة تعاني من الألم في الورك أثناء النوم في الليل؟",
  "patient_hip_pain_q14_a1":"لا ليال",
  "patient_hip_pain_q14_a2":"ليلة أو ليلتان فقط",
  "patient_hip_pain_q14_a3":"بعض الليالي",
  "patient_hip_pain_q14_a4":"معظم الليالي",
  "patient_hip_pain_q14_a5":"كل ليلة",
  "patient_hip_pain_q15":"الجلوس أو الاستلقاء",
  "patient_hip_pain_q15_a1":"لا شيء",
  "patient_hip_pain_q15_a2":"خفيف",
  "patient_hip_pain_q15_a3":"معتدل",
  "patient_hip_pain_q15_a4":"شديد",
  "patient_hip_pain_q15_a5":"أقصى",
  "patient_hip_pain_q16":"الوقوف بشكل مستقيم",
  "patient_hip_pain_q16_a1":"لا شيء",
  "patient_hip_pain_q16_a2":"خفيف",
  "patient_hip_pain_q16_a3":"معتدل",
  "patient_hip_pain_q16_a4":"شديد",
  "patient_hip_pain_q16_a5":"أقصى",
  "patient_hip_pain_q17":"الوقوف من على الكرسي",
  "patient_hip_pain_q17_a1":"لا شيء",
  "patient_hip_pain_q17_a2":"خفيف",
  "patient_hip_pain_q17_a3":"معتدل",
  "patient_hip_pain_q17_a4":"شديد",
  "patient_hip_pain_q17_a5":"أقصى",
  "patient_hip_pain_q18":"بعد النشاط",
  "patient_hip_pain_q18_a1":"لا شيء",
  "patient_hip_pain_q18_a2":"خفيف",
  "patient_hip_pain_q18_a3":"معتدل",
  "patient_hip_pain_q18_a4":"شديد",
  "patient_hip_pain_q18_a5":"أقصى",
  "patient_hip_other_symptoms":"أعراض أخرى",
  "patient_hip_other_symptoms_instruction1":"الرجاء اختيار أفضل إجابة تتوافق مع أعراض الورك لديك.",
  "patient_hip_other_symptoms_instruction2":"إذا لم تكن متأكدًا من كيفية الإجابة على أحد الأسئلة ، فيرجى تقديم أفضل إجابة ممكنة.",
  "patient_hip_other_symptoms_instruction3":"هل لديك ما يلي",
  "patient_hip_other_symptoms_instruction_0":"لا شيء",
  "patient_hip_other_symptoms_instruction_1":"خفيف",
  "patient_hip_other_symptoms_instruction_2":"معتدل",
  "patient_hip_other_symptoms_instruction_3":"شديد",
  "patient_hip_other_symptoms_instruction_4":"أقصى",
  "patient_hip_other_symptoms_q1":"صرير، نقر أو أي نوع آخر من الأصوات من الورك",
  "patient_hip_other_symptoms_q1_a1":"أبداً",
  "patient_hip_other_symptoms_q1_a2":"نادراً",
  "patient_hip_other_symptoms_q1_a3":"أحيانًا",
  "patient_hip_other_symptoms_q1_a4":"غالبًا ما",
  "patient_hip_other_symptoms_q1_a5":"دائمًا",
  "patient_hip_other_symptoms_q2":"إمساك أو قفل",
  "patient_hip_other_symptoms_q2_a1":"لا شيء",
  "patient_hip_other_symptoms_q2_a2":"خفيف",
  "patient_hip_other_symptoms_q2_a3":"معتدل",
  "patient_hip_other_symptoms_q2_a4":"شديد",
  "patient_hip_other_symptoms_q2_a5":"أقصى",
  "patient_hip_other_symptoms_q3":"التيبس بعد الاستيقاظ في الصباح ",
  "patient_hip_other_symptoms_q3_a1":"لا شيء",
  "patient_hip_other_symptoms_q3_a2":"خفيف",
  "patient_hip_other_symptoms_q3_a3":"معتدل",
  "patient_hip_other_symptoms_q3_a4":"شديد",
  "patient_hip_other_symptoms_q3_a5":"أقصى",
  "patient_hip_other_symptoms_q4":"التيبس بعد الجلوس أو الاستلقاء أو الراحة في وقت لاحق من اليوم",
  "patient_hip_other_symptoms_q4_a1":"لا شيء",
  "patient_hip_other_symptoms_q4_a2":"خفيف",
  "patient_hip_other_symptoms_q4_a3":"معتدل",
  "patient_hip_other_symptoms_q4_a4":"شديد",
  "patient_hip_other_symptoms_q4_a5":"أقصى",
  "patient_hip_other_symptoms_q5":"العرج",
  "patient_hip_other_symptoms_q5_a1":"لا شيء ، نادرًا / أبدًا",
  "patient_hip_other_symptoms_q5_a2":"طفيف ، في بعض الأحيان أو في البداية فقط",
  "patient_hip_other_symptoms_q5_a3":"معتدلة ، في كثير من الأحيان ، ليس فقط في البداية",
  "patient_hip_other_symptoms_q5_a4":"شديد ، في معظم الأوقات",
  "patient_hip_other_symptoms_q5_a5":"طوال الوقت أو غير قادر على المشي",
  "patient_hip_other_symptoms_q6":"ألم حاد ومفاجئ - \"ألم حاد\" ، \"ألم طعن\" أو \"تشنجات\" من الورك",
  "patient_hip_other_symptoms_q6_a1":"لا أيام",
  "patient_hip_other_symptoms_q6_a2":"يوم أو يومين فقط",
  "patient_hip_other_symptoms_q6_a3":"بعض أيام",
  "patient_hip_other_symptoms_q6_a4":"أغلب الأيام",
  "patient_hip_other_symptoms_q6_a5":"كل يوم",
  "patient_hip_other_symptoms_q7":"انزلاق الورك",
  "patient_hip_other_symptoms_q7_a1":"لا شيء",
  "patient_hip_other_symptoms_q7_a2":"خفيف",
  "patient_hip_other_symptoms_q7_a3":"معتدل",
  "patient_hip_other_symptoms_q7_a4":"شديد",
  "patient_hip_other_symptoms_q7_a5":"أقصى",
  "patient_hip_other_symptoms_q8":"تقليل الحركة",
  "patient_hip_other_symptoms_q8_a1":"لا شيء",
  "patient_hip_other_symptoms_q8_a2":"خفيف",
  "patient_hip_other_symptoms_q8_a3":"معتدل",
  "patient_hip_other_symptoms_q8_a4":"شديد",
  "patient_hip_other_symptoms_q8_a5":"أقصى",
  "patient_hip_other_symptoms_q9":"هل تستخدم أي دعم للمشي؟",
  "patient_hip_other_symptoms_q9_a1":"لا شيء",
  "patient_hip_other_symptoms_q9_a2":"عصا المشي / عكازة للمسافات الطويلة",
  "patient_hip_other_symptoms_q9_a3":"عصا المشي / عكازة في معظم الوقت",
  "patient_hip_other_symptoms_q9_a4":"عكاز واحد",
  "patient_hip_other_symptoms_q9_a5":"عكازان / عصيان للمشي",
  "patient_hip_other_symptoms_q9_a6":"عكازان أو غير قادر على المشي",
  "patient_hip_function":"الوظيفة",
  "patient_hip_function_instruction":"حدد درجة الصعوبة التي مررت بها أثناء القيام بالأنشطة التالية في الأسبوع الماضي بسبب الورك",
  "patient_hip_function_instruction_0":"لا شيء",
  "patient_hip_function_instruction_1":"خفيف",
  "patient_hip_function_instruction_2":"معتدل",
  "patient_hip_function_instruction_3":"شديد",
  "patient_hip_function_instruction_4":"لأقصى حد / غير قادر على القيام به",
  "patient_hip_function_note":"ملاحظة: إذا لم تقم بنشاط معين ، فتخيل كيف سيكون شعور مفصل الفخذ إذا كان عليك تجربته",
  "patient_hip_function_q1":"نزول الدرج",
  "patient_hip_function_q1_a1":"لا شيء",
  "patient_hip_function_q1_a2":"خفيف",
  "patient_hip_function_q1_a3":"معتدل",
  "patient_hip_function_q1_a4":"شديد",
  "patient_hip_function_q1_a5":"لأقصى حد / غير قادر على القيام به",
  "patient_hip_function_q2":"صعود الدرج",
  "patient_hip_function_q2_a1":"لا شيء",
  "patient_hip_function_q2_a2":"خفيف",
  "patient_hip_function_q2_a3":"معتدل",
  "patient_hip_function_q2_a4":"شديد",
  "patient_hip_function_q2_a5":"لأقصى حد / غير قادر على القيام به",
  "patient_hip_function_q3":"الجلوس",
  "patient_hip_function_q3_a1":"لا شيء",
  "patient_hip_function_q3_a2":"خفيف",
  "patient_hip_function_q3_a3":"معتدل",
  "patient_hip_function_q3_a4":"شديد",
  "patient_hip_function_q3_a5":"لأقصى حد / غير قادر على القيام به",
  "patient_hip_function_q4":"القيام  من الجلوس",
  "patient_hip_function_q4_a1":"لا شيء",
  "patient_hip_function_q4_a2":"خفيف",
  "patient_hip_function_q4_a3":"معتدل",
  "patient_hip_function_q4_a4":"شديد",
  "patient_hip_function_q4_a5":"لأقصى حد / غير قادر على القيام به",
  "patient_hip_function_q5":"الجلوس والقيام من الأرض",
  "patient_hip_function_q5_a1":"لا شيء",
  "patient_hip_function_q5_a2":"خفيف",
  "patient_hip_function_q5_a3":"معتدل",
  "patient_hip_function_q5_a4":"شديد",
  "patient_hip_function_q5_a5":"لأقصى حد / غير قادر على القيام به",
  "patient_hip_function_q6":"الوقوف",
  "patient_hip_function_q6_a1":"لا شيء",
  "patient_hip_function_q6_a2":"خفيف",
  "patient_hip_function_q6_a3":"معتدل",
  "patient_hip_function_q6_a4":"شديد",
  "patient_hip_function_q6_a5":"لأقصى حد / غير قادر على القيام به",
  "patient_hip_function_q7":"الوقوف لمدة 15 دقيقة",
  "patient_hip_function_q7_a1":"لا شيء",
  "patient_hip_function_q7_a2":"خفيف",
  "patient_hip_function_q7_a3":"معتدل",
  "patient_hip_function_q7_a4":"شديد",
  "patient_hip_function_q7_a5":"لأقصى حد / غير قادر على القيام به",
  "patient_hip_function_q8":"فتح الأرجل على نطاق واسع",
  "patient_hip_function_q8_a1":"لا شيء",
  "patient_hip_function_q8_a2":"خفيف",
  "patient_hip_function_q8_a3":"معتدل",
  "patient_hip_function_q8_a4":"شديد",
  "patient_hip_function_q8_a5":"لأقصى حد / غير قادر على القيام به",
  "patient_hip_function_q9":"الخطوات الكبيرة أثناء المشي",
  "patient_hip_function_q9_a1":"لا شيء",
  "patient_hip_function_q9_a2":"خفيف",
  "patient_hip_function_q9_a3":"معتدل",
  "patient_hip_function_q9_a4":"شديد",
  "patient_hip_function_q9_a5":"لأقصى حد / غير قادر على القيام به",
  "patient_hip_function_q10":"الانحناء لالتقاط شيء من الأرض",
  "patient_hip_function_q10_a1":"لا شيء",
  "patient_hip_function_q10_a2":"خفيف",
  "patient_hip_function_q10_a3":"معتدل",
  "patient_hip_function_q10_a4":"شديد",
  "patient_hip_function_q10_a5":"لأقصى حد / غير قادر على القيام به",
  "patient_hip_function_q11":"المشي على سطح مستوٍ",
  "patient_hip_function_q11_a1":"لا شيء",
  "patient_hip_function_q11_a2":"خفيف",
  "patient_hip_function_q11_a3":"معتدل",
  "patient_hip_function_q11_a4":"شديد",
  "patient_hip_function_q11_a5":"لأقصى حد / غير قادر على القيام به",
  "patient_hip_function_q12":"المشي على سطح غير مستوٍ",
  "patient_hip_function_q12_a1":"لا شيء",
  "patient_hip_function_q12_a2":"خفيف",
  "patient_hip_function_q12_a3":"معتدل",
  "patient_hip_function_q12_a4":"شديد",
  "patient_hip_function_q12_a5":"لأقصى حد / غير قادر على القيام به",
  "patient_hip_function_q13":"المشي لمسافات طويلة",
  "patient_hip_function_q13_a1":"لا شيء",
  "patient_hip_function_q13_a2":"خفيف",
  "patient_hip_function_q13_a3":"معتدل",
  "patient_hip_function_q13_a4":"شديد",
  "patient_hip_function_q13_a5":"لأقصى حد / غير قادر على القيام به",
  "patient_hip_function_q14":"المشي لممارسة التمارين الرياضية",
  "patient_hip_function_q14_a1":"لا شيء",
  "patient_hip_function_q14_a2":"خفيف",
  "patient_hip_function_q14_a3":"معتدل",
  "patient_hip_function_q14_a4":"شديد",
  "patient_hip_function_q14_a5":"لأقصى حد / غير قادر على القيام به",
  "patient_hip_function_q15":"المشي صعودا على التلال الشديدة الانحدار",
  "patient_hip_function_q15_a1":"لا شيء",
  "patient_hip_function_q15_a2":"خفيف",
  "patient_hip_function_q15_a3":"معتدل",
  "patient_hip_function_q15_a4":"شديد",
  "patient_hip_function_q15_a5":"لأقصى حد / غير قادر على القيام به",
  "patient_hip_function_q16":"المشي  نزولا على التلال الشديدة الانحدار",
  "patient_hip_function_q16_a1":"لا شيء",
  "patient_hip_function_q16_a2":"خفيف",
  "patient_hip_function_q16_a3":"معتدل",
  "patient_hip_function_q16_a4":"شديد",
  "patient_hip_function_q16_a5":"لأقصى حد / غير قادر على القيام به",
  "patient_hip_function_q17":"المشي في البداية",
  "patient_hip_function_q17_a1":"لا شيء",
  "patient_hip_function_q17_a2":"خفيف",
  "patient_hip_function_q17_a3":"معتدل",
  "patient_hip_function_q17_a4":"شديد",
  "patient_hip_function_q17_a5":"لأقصى حد / غير قادر على القيام به",
  "patient_hip_function_q18":"المشي لمدة 10 دقائق تقريبًا",
  "patient_hip_function_q18_a1":"لا شيء",
  "patient_hip_function_q18_a2":"خفيف",
  "patient_hip_function_q18_a3":"معتدل",
  "patient_hip_function_q18_a4":"شديد",
  "patient_hip_function_q18_a5":"لأقصى حد / غير قادر على القيام به",
  "patient_hip_function_q19":"المشي لمدة 15 دقيقة أو أكثر",
  "patient_hip_function_q19_a1":"لا شيء",
  "patient_hip_function_q19_a2":"خفيف",
  "patient_hip_function_q19_a3":"معتدل",
  "patient_hip_function_q19_a4":"شديد",
  "patient_hip_function_q19_a5":"لأقصى حد / غير قادر على القيام به",
  "patient_hip_function_q20":"الصعود والنزول من الرصيف",
  "patient_hip_function_q20_a1":"لا شيء",
  "patient_hip_function_q20_a2":"خفيف",
  "patient_hip_function_q20_a3":"معتدل",
  "patient_hip_function_q20_a4":"شديد",
  "patient_hip_function_q20_a5":"لأقصى حد / غير قادر على القيام به",
  "patient_hip_function_q21":"الدخول/الخروج من السيارة أو وسائل النقل العام (الحافلة)التي تستخدمها ",
  "patient_hip_function_q21_a1":"لا شيء",
  "patient_hip_function_q21_a2":"خفيف",
  "patient_hip_function_q21_a3":"معتدل",
  "patient_hip_function_q21_a4":"شديد",
  "patient_hip_function_q21_a5":"لأقصى حد / غير قادر على القيام به",
  "patient_hip_function_q22":"الذهاب للتسوق",
  "patient_hip_function_q22_a1":"لا شيء",
  "patient_hip_function_q22_a2":"خفيف",
  "patient_hip_function_q22_a3":"معتدل",
  "patient_hip_function_q22_a4":"شديد",
  "patient_hip_function_q22_a5":"لأقصى حد / غير قادر على القيام به",
  "patient_hip_function_q23":"ارتداء الجوارب ",
  "patient_hip_function_q23_a1":"لا شيء",
  "patient_hip_function_q23_a2":"خفيف",
  "patient_hip_function_q23_a3":"معتدل",
  "patient_hip_function_q23_a4":"شديد",
  "patient_hip_function_q23_a5":"لأقصى حد / غير قادر على القيام به",
  "patient_hip_function_q24":"خلع الجوارب / الجوارب الطويلة",
  "patient_hip_function_q24_a1":"لا شيء",
  "patient_hip_function_q24_a2":"خفيف",
  "patient_hip_function_q24_a3":"معتدل",
  "patient_hip_function_q24_a4":"شديد",
  "patient_hip_function_q24_a5":"لأقصى حد / غير قادر على القيام به",
  "patient_hip_function_q25":"الاستلقاء على السرير (التقليب ، الحفاظ على وضعية الورك)",
  "patient_hip_function_q25_a1":"لا شيء",
  "patient_hip_function_q25_a2":"خفيف",
  "patient_hip_function_q25_a3":"معتدل",
  "patient_hip_function_q25_a4":"شديد",
  "patient_hip_function_q25_a5":"لأقصى حد / غير قادر على القيام به",
  "patient_hip_function_q26":"التدحرج في السرير",
  "patient_hip_function_q26_a1":"لا شيء",
  "patient_hip_function_q26_a2":"خفيف",
  "patient_hip_function_q26_a3":"معتدل",
  "patient_hip_function_q26_a4":"شديد",
  "patient_hip_function_q26_a5":"لأقصى حد / غير قادر على القيام به",
  "patient_hip_function_q27":"النهوض من السرير",
  "patient_hip_function_q27_a1":"لا شيء",
  "patient_hip_function_q27_a2":"خفيف",
  "patient_hip_function_q27_a3":"معتدل",
  "patient_hip_function_q27_a4":"شديد",
  "patient_hip_function_q27_a5":"لأقصى حد / غير قادر على القيام به",
  "patient_hip_function_q28":"الدخول والخروج من الحوض",
  "patient_hip_function_q28_a1":"لا شيء",
  "patient_hip_function_q28_a2":"خفيف",
  "patient_hip_function_q28_a3":"معتدل",
  "patient_hip_function_q28_a4":"شديد",
  "patient_hip_function_q28_a5":"لأقصى حد / غير قادر على القيام به",
  "patient_hip_function_q29":"الجلوس والقيام من المرحاض",
  "patient_hip_function_q29_a1":"لا شيء",
  "patient_hip_function_q29_a2":"خفيف",
  "patient_hip_function_q29_a3":"معتدل",
  "patient_hip_function_q29_a4":"شديد",
  "patient_hip_function_q29_a5":"لأقصى حد / غير قادر على القيام به",
  "patient_hip_function_q30":"غسل وتجفيف نفسك (في كل مكان)",
  "patient_hip_function_q30_a1":"لا شيء",
  "patient_hip_function_q30_a2":"خفيف",
  "patient_hip_function_q30_a3":"معتدل",
  "patient_hip_function_q30_a4":"شديد",
  "patient_hip_function_q30_a5":"لأقصى حد / غير قادر على القيام به",
  "patient_hip_function_q31":"العمل الخفيف إلى المتوسط (الوقوف ، المشي)",
  "patient_hip_function_q31_a1":"لا شيء",
  "patient_hip_function_q31_a2":"خفيف",
  "patient_hip_function_q31_a3":"معتدل",
  "patient_hip_function_q31_a4":"شديد",
  "patient_hip_function_q31_a5":"لأقصى حد / غير قادر على القيام به",
  "patient_hip_function_q32":"الأعمال المنزلية الثقيلة (نقل الصناديق الثقيلة ، تنظيف الأرضيات ، إلخ)",
  "patient_hip_function_q32_a1":"لا شيء",
  "patient_hip_function_q32_a2":"خفيف",
  "patient_hip_function_q32_a3":"معتدل",
  "patient_hip_function_q32_a4":"شديد",
  "patient_hip_function_q32_a5":"لأقصى حد / غير قادر على القيام به",
  "patient_hip_function_q33":"واجبات منزلية خفيفة (طبخ ، نفض الغبار ، إلخ)",
  "patient_hip_function_q33_a1":"لا شيء",
  "patient_hip_function_q33_a2":"خفيف",
  "patient_hip_function_q33_a3":"معتدل",
  "patient_hip_function_q33_a4":"شديد",
  "patient_hip_function_q33_a5":"لأقصى حد / غير قادر على القيام به",
  "patient_hip_function_q34":"التقاط أو حمل الأطفال",
  "patient_hip_function_q34_a1":"لا شيء",
  "patient_hip_function_q34_a2":"خفيف",
  "patient_hip_function_q34_a3":"معتدل",
  "patient_hip_function_q34_a4":"شديد",
  "patient_hip_function_q34_a5":"لأقصى حد / غير قادر على القيام به",
  "patient_hip_function_q35":"القرفصاء",
  "patient_hip_function_q35_a1":"لا شيء",
  "patient_hip_function_q35_a2":"خفيف",
  "patient_hip_function_q35_a3":"معتدل",
  "patient_hip_function_q35_a4":"شديد",
  "patient_hip_function_q35_a5":"لأقصى حد / غير قادر على القيام به",
  "patient_hip_function_q36":"الركض لممارسة التمارين الرياضية",
  "patient_hip_function_q36_a1":"لا شيء",
  "patient_hip_function_q36_a2":"خفيف",
  "patient_hip_function_q36_a3":"معتدل",
  "patient_hip_function_q36_a4":"شديد",
  "patient_hip_function_q36_a5":"لأقصى حد / غير قادر على القيام به",
  "patient_hip_function_q37":"الجري",
  "patient_hip_function_q37_a1":"لا شيء",
  "patient_hip_function_q37_a2":"خفيف",
  "patient_hip_function_q37_a3":"معتدل",
  "patient_hip_function_q37_a4":"شديد",
  "patient_hip_function_q37_a5":"لأقصى حد / غير قادر على القيام به",
  "patient_hip_function_q38":" الالتواء / الدوران على الساق المحملة",
  "patient_hip_function_q38_a1":"لا شيء",
  "patient_hip_function_q38_a2":"خفيف",
  "patient_hip_function_q38_a3":"معتدل",
  "patient_hip_function_q38_a4":"شديد",
  "patient_hip_function_q38_a5":"لأقصى حد / غير قادر على القيام به",
  "patient_hip_function_q39":"النشاط الجنسي",
  "patient_hip_function_q39_a1":"لا شيء",
  "patient_hip_function_q39_a2":"خفيف",
  "patient_hip_function_q39_a3":"معتدل",
  "patient_hip_function_q39_a4":"شديد",
  "patient_hip_function_q39_a5":"لأقصى حد / غير قادر على القيام به",
  "patient_hip_function_q39_not_relevant":"هذا لا صلة له بي",
  "patient_hip_function_q40":"الرياضات التي تتطلب الكثير من الركض أو القطع (مثل كرة القدم وكرة السلة والتنس والتمارين الرياضية)",
  "patient_hip_function_q40_a1":"لا شيء",
  "patient_hip_function_q40_a2":"خفيف",
  "patient_hip_function_q40_a3":"معتدل",
  "patient_hip_function_q40_a4":"شديد",
  "patient_hip_function_q40_a5":"لأقصى حد / غير قادر على القيام به",
  "patient_hip_function_q41":"الرياضات منخفضة الطلب (مثل الجولف والبولينج)",
  "patient_hip_function_q41_a1":"لا شيء",
  "patient_hip_function_q41_a2":"خفيف",
  "patient_hip_function_q41_a3":"معتدل",
  "patient_hip_function_q41_a4":"شديد",
  "patient_hip_function_q41_a5":"لأقصى حد / غير قادر على القيام به",
  "patient_hip_function_q42":"الأنشطة الترفيهية",
  "patient_hip_function_q42_a1":"لا شيء",
  "patient_hip_function_q42_a2":"خفيف",
  "patient_hip_function_q42_a3":"معتدل",
  "patient_hip_function_q42_a4":"شديد",
  "patient_hip_function_q42_a5":"لأقصى حد / غير قادر على القيام به",
  "patient_hip_function_q43":"كيف تقيم الوركين اليوم كنسبة مئوية من الطبيعي؟ (من 0٪ إلى 100٪ مع كون 100٪ طبيعي)",
  "patient_hip_function_q44":"الرجاء تحديد أعلى مستوى من النشاط يمكنك المشاركة فيه",
  "patient_hip_function_q44_q1":"قبل إصابة الورك / مشكلة الورك",
  "patient_hip_function_q44_q1_a1":"تنافسي",
  "patient_hip_function_q44_q1_a2":"الترفيه واللياقة البدنية",
  "patient_hip_function_q44_q1_a3":"لا الرياضة ، العمل فقط",
  "patient_hip_function_q44_q1_a4":"فقط أنشطة الحياة اليومية",
  "patient_hip_function_q44_q1_a5":"الإجازة المرضية أو معاش العجز بسبب مشاكل صحية",
  "patient_hip_function_q44_q1_a1_o1":"الرياضات التنافسية التي تمارس على المستوى العالمي أو الأولمبي أو المهني",
  "patient_hip_function_q44_q1_a1_o2":"الرياضات التنافسية مثل سباقات المضمار والميدان ، ورياضات المضرب / الكرة ، والجمباز ، والتجديف ، والتزلج ، أو فنون القتال التي تُلعب على المستوى الوطني أو الدولي",
  "patient_hip_function_q44_q1_a1_o3":"الرياضات التنافسية مثل الرياضات المائية أو ركوب الدراجات أو الهوكي أو الشباك أو ركوب الخيل / مسابقات رعاة البقر التي تُلعب على المستوى الوطني أو الدولي",
  "patient_hip_function_q44_q1_a2_o1":"الرياضات الترفيهية مثل الجري أو الكرة / المضرب أو تدريب الأثقال أو الشباك أو التجديف أو الجولف أو اليوجا أو الجمباز 5 مرات على الأقل في الأسبوع",
  "patient_hip_function_q44_q1_a2_o2":"الرياضات الترفيهية مثل السباحة أو الرياضات المائية أو التزلج أو الهوكي أو التزلج على الجليد أو ركوب الدراجات أو ركوب الخيل أو تسلق الجبال 5 مرات على الأقل في الأسبوع",
  "patient_hip_function_q44_q1_a2_o3":"ممارسة الرياضة الترفيهية مرتين أسبوعياً على الأقل",
  "patient_hip_function_q44_q1_a2_o4":"الرياضات الترفيهية مرة في الأسبوع أو أقل",
  "patient_hip_function_q44_q1_a2_o5":"الرياضات الترفيهية أقل من مرة في الشهر",
  "patient_hip_function_q44_q1_a3_o1":"الأشغال الشاقة مثل البناء",
  "patient_hip_function_q44_q1_a3_o2":"الأشغال الشاقة بشكل معتدل مثل قيادة الشاحنات",
  "patient_hip_function_q44_q1_a3_o3":"العمل الخفيف مثل التمريض",
  "patient_hip_function_q44_q1_a3_o4":"عمل ساكن مثل الأعمال السكرتارية أو العمل المرتبط بالكمبيوتر.",
  "patient_hip_function_q44_q1_a4_o1":"الأنشطة اليومية مثل البستنة ، وتسلق عدة درجات من السلالم ، وحمل الأحمال ، ودفع / سحب حمولة ، والقدرة على الجري إذا تأخرت",
  "patient_hip_function_q44_q1_a4_o2":"الأنشطة اليومية مثل تنظيف المنزل ، أو تسلق مجموعة أو مجموعتين من السلالم ، أو المشي بحمولة خفيفة",
  "patient_hip_function_q44_q1_a4_o3":"الأنشطة اليومية محدودة (مثل عدم صعود السلالم ، وعدم القدرة على حمل الأحمال)",
  "patient_hip_function_q44_q2":"الوضع الحالي",
  "patient_hip_function_q44_q2_a1":"تنافسي",
  "patient_hip_function_q44_q2_a2":" الترفيه واللياقة البدنية",
  "patient_hip_function_q44_q2_a3":"لا الرياضة ، العمل فقط",
  "patient_hip_function_q44_q2_a4":"فقط أنشطة الحياة اليومية",
  "patient_hip_function_q44_q2_a5":"الإجازة المرضية أو معاش العجز بسبب مشاكل صحية",
  "patient_hip_function_q44_q2_a1_o1":"الرياضات التنافسية التي تمارس على المستوى العالمي أو الأولمبي أو المهني",
  "patient_hip_function_q44_q2_a1_o2":"الرياضات التنافسية مثل سباقات المضمار والميدان ، ورياضات المضرب / الكرة ، والجمباز ، والتجديف ، والتزلج ، أو فنون القتال التي تُلعب على المستوى الوطني أو الدولي",
  "patient_hip_function_q44_q2_a1_o3":"الرياضات التنافسية مثل الرياضات المائية أو ركوب الدراجات أو الهوكي أو الشباك أو ركوب الخيل / مسابقات رعاة البقر التي تُلعب على المستوى الوطني أو الدولي",
  "patient_hip_function_q44_q2_a2_o1":"الرياضات الترفيهية مثل الجري أو الكرة / المضرب أو تدريب الأثقال أو الشباك أو التجديف أو الجولف أو اليوجا أو الجمباز 5 مرات على الأقل في الأسبوع",
  "patient_hip_function_q44_q2_a2_o2":"الرياضات الترفيهية مثل السباحة أو الرياضات المائية أو التزلج أو الهوكي أو التزلج على الجليد أو ركوب الدراجات أو ركوب الخيل أو تسلق الجبال 5 مرات على الأقل في الأسبوع",
  "patient_hip_function_q44_q2_a2_o3":"ممارسة الرياضة الترفيهية مرتين أسبوعياً على الأقل",
  "patient_hip_function_q44_q2_a2_o4":"الرياضات الترفيهية مرة في الأسبوع أو أقل",
  "patient_hip_function_q44_q2_a2_o5":"الرياضات الترفيهية أقل من مرة في الشهر",
  "patient_hip_function_q44_q2_a3_o1":"الأشغال الشاقة مثل البناء",
  "patient_hip_function_q44_q2_a3_o2":"الأشغال الشاقة بشكل معتدل مثل قيادة الشاحنات",
  "patient_hip_function_q44_q2_a3_o3":"العمل الخفيف مثل التمريض",
  "patient_hip_function_q44_q2_a3_o4":"عمل ساكن مثل الأعمال السكرتارية أو العمل المرتبط بالكمبيوتر.",
  "patient_hip_function_q44_q2_a4_o1":"الأنشطة اليومية مثل البستنة ، وتسلق عدة درجات من السلالم ، وحمل الأحمال ، ودفع / سحب حمولة ، والقدرة على الجري إذا تأخرت",
  "patient_hip_function_q44_q2_a4_o2":"الأنشطة اليومية مثل تنظيف المنزل ، أو تسلق مجموعة أو مجموعتين من السلالم ، أو المشي بحمولة خفيفة",
  "patient_hip_function_q44_q2_a4_o3":"الأنشطة اليومية محدودة (مثل عدم صعود السلالم ، وعدم القدرة على حمل الأحمال)",
  "patient_hip_quality_of_life":"جودة الحياة",
  "patient_hip_quality_of_life_instruction":"تسأل الأسئلة التالية عن المخاوف الاجتماعية والعاطفية والمتعلقة بنمط الحياة التي قد تشعر بها فيما يتعلق بمشكلة الورك. يرجى التفكير في ما كنت تشعر به معظم الوقت فيما يتعلق بوركك.",
  "patient_hip_quality_of_life_q1":"كم مرة تعرف مشكلة الورك لديك؟",
  "patient_hip_quality_of_life_q1_a1":"أبداً",
  "patient_hip_quality_of_life_q1_a2":"شهريا",
  "patient_hip_quality_of_life_q1_a3":"أسبوعيا",
  "patient_hip_quality_of_life_q1_a4":"يوميا",
  "patient_hip_quality_of_life_q1_a5":"باستمرار",
  "patient_hip_quality_of_life_q2":"هل قمت بتعديل نمط حياتك لتجنب الأنشطة التي من المحتمل أن تكون ضارة لوركك؟",
  "patient_hip_quality_of_life_q2_a1":"مُطْلَقاً",
  "patient_hip_quality_of_life_q2_a2":"خفيف",
  "patient_hip_quality_of_life_q2_a3":"باعتدال",
  "patient_hip_quality_of_life_q2_a4":"بشدة",
  "patient_hip_quality_of_life_q2_a5":"تماما",
  "patient_hip_quality_of_life_q3":"ما مدى قلقك بشأن قدرتك على الحفاظ على المستوى المطلوب من لياقتك؟",
  "patient_hip_quality_of_life_q3_a1":"لا تقلق على الإطلاق",
  "patient_hip_quality_of_life_q3_a2":"قلق للغاية",
  "patient_hip_quality_of_life_q4":"إلى أي مدى أنت منزعج من عدم الثقة في وركك؟",
  "patient_hip_quality_of_life_q4_a1":"مُطْلَقاً",
  "patient_hip_quality_of_life_q4_a2":"خفيف",
  "patient_hip_quality_of_life_q4_a3":"باعتدال",
  "patient_hip_quality_of_life_q4_a4":"بشدة",
  "patient_hip_quality_of_life_q4_a5":"لأقصى حد",
  "patient_hip_quality_of_life_q5":"ما مقدار الإلهاء من مشكلة الورك؟",
  "patient_hip_quality_of_life_q5_a1":"لا الهاء على الإطلاق",
  "patient_hip_quality_of_life_q5_a2":"الهاء الشديد",
  "patient_hip_quality_of_life_q6":"بشكل عام، ما مدى صعوبة التي تواجهها مع الورك لديك؟",
  "patient_hip_quality_of_life_q6_a1":"لا شيء",
  "patient_hip_quality_of_life_q6_a2":"خفيف",
  "patient_hip_quality_of_life_q6_a3":"معتدل",
  "patient_hip_quality_of_life_q6_a4":"شديد",
  "patient_hip_quality_of_life_q6_a5":"أقصى",
  "patient_hip_quality_of_life_q7":"كم تعاني من القلق / الاكتئاب اليوم؟",
  "patient_hip_quality_of_life_q7_a1":"مُطْلَقاً",
  "patient_hip_quality_of_life_q7_a2":"طفيف",
  "patient_hip_quality_of_life_q7_a3":"باعتدال",
  "patient_hip_quality_of_life_q7_a4":"بشدة",
  "patient_hip_quality_of_life_q7_a5":"لأقصى حد",
  "patient_hip_quality_of_life_q8":"ما مدى جودة / سوء صحتك اليوم؟ (100 تعني أفضل صحة 0 تعني أسوأ صحة يمكنك تخيلها)",
  "patient_hip_quality_of_life_q8_a1":"أسوأ صحة",
  "patient_hip_quality_of_life_q8_a2":"أفضل صحة",
  "patient_hip_satisfaction":"الرضا",
  "patient_hip_satisfaction_instructions":"ما مدى رضاك عن نتائج جراحة / علاج الورك؟",
  "patient_hip_satisfaction_instructions_1":"غير راض تماماً",
  "patient_hip_satisfaction_instructions_2":"غير راض بعض الشيء",
  "patient_hip_satisfaction_instructions_3":"راض بعض الشيء",
  "patient_hip_satisfaction_instructions_4":"راض جدا",
  "patient_hip_satisfaction_q1":"الإجمالي",
  "patient_hip_satisfaction_q1_not_applicable":"غير قابل للتطبيق",
  "patient_hip_satisfaction_q1_a1":"غير راض تماماً",
  "patient_hip_satisfaction_q1_a2":"غير راض بعض الشيء",
  "patient_hip_satisfaction_q1_a3":"راض بعض الشيء",
  "patient_hip_satisfaction_q1_a4":"راض جدا",
  "patient_hip_satisfaction_q2":"لتحسين ألمك؟",
  "patient_hip_satisfaction_q2_not_applicable":"غير قابل للتطبيق",
  "patient_hip_satisfaction_q2_a1":"غير راض تماماً",
  "patient_hip_satisfaction_q2_a2":"غير راض بعض الشيء",
  "patient_hip_satisfaction_q2_a3":"راض بعض الشيء",
  "patient_hip_satisfaction_q2_a4":"راض جدا",
  "patient_hip_satisfaction_q3":"لتحسين قدرتك على القيام بالأعمال المنزلية أو الحديقة؟",
  "patient_hip_satisfaction_q3_not_applicable":"غير قابل للتطبيق",
  "patient_hip_satisfaction_q3_a1":"غير راض تماماً",
  "patient_hip_satisfaction_q3_a2":"غير راض بعض الشيء",
  "patient_hip_satisfaction_q3_a3":"راض بعض الشيء",
  "patient_hip_satisfaction_q3_a4":"راض جدا",
  "patient_hip_satisfaction_q4":"لتحسين قدرتك على القيام بالأنشطة الترفيهية؟",
  "patient_hip_satisfaction_q4_not_applicable":"غير قابل للتطبيق",
  "patient_hip_satisfaction_q4_a1":"غير راض تماماً",
  "patient_hip_satisfaction_q4_a2":"غير راض بعض الشيء",
  "patient_hip_satisfaction_q4_a3":"راض بعض الشيء",
  "patient_hip_satisfaction_q4_a4":"راض جدا",
  "patient_hip_complete":"نهاية الاستبيان",
  "patient_hip_complete_text1":"شكرًا لك على الوقت الذي أمضيته في ملء هذا النموذج.",
  "patient_hip_complete_text2":"الرجاء النقر فوق إرسال لإكمال ورؤية درجاتك.",
  "patient_hip_complete_text3":"سيتم التعامل مع إجاباتك بسرية ولن تستخدم أي تقارير سوى معلومات مجهولة الهوية.",
  "patient_hip_results":"النتائج",
  "patient_hip_results_text1":"فيما يلي درجات الألم ووظائف الورك ، والتي تم حسابها من الاستبيان الخاص بك.",
  "patient_hip_results_pain":"الألم",
  "patient_hip_results_function":"الوظيفة",
  "patient_hip_results_health_perception":"التصور الصحي العام",
  "patient_hip_results_self_evaluation":"درجة التقييم الذاتي للورك",
  "patient_hip_results_text2":"سيكون الطبيب معك قريبًا",
  "":"",
  "patient_adult_knee_pain":"الألم",
  "patient_adult_knee_pain_q1":"ما هو أعلى مستوى من النشاط يمكنك القيام به دون ألم شديد في الركبة؟",
  "patient_adult_knee_pain_q1_a1":"الأنشطة الشاقة للغاية مثل القفز أو التمحور كما هو الحال في كرة السلة أو كرة القدم",
  "patient_adult_knee_pain_q1_a2":"الأنشطة الشاقة مثل العمل البدني الشاق أو التزلج أو التنس",
  "patient_adult_knee_pain_q1_a3":"الأنشطة المعتدلة مثل العمل البدني المعتدل أو الجري أو الركض",
  "patient_adult_knee_pain_q1_a4":"الأنشطة الخفيفة مثل المشي أو الأعمال المنزلية أو أعمال الفناء",
  "patient_adult_knee_pain_q1_a5":"عدم القدرة على أداء أي من الأنشطة المذكورة أعلاه بسبب آلام الركبة",
  "patient_adult_knee_pain_q2":"كم مرة شعرت بالألم؟",
  "patient_adult_knee_pain_q2_a1":"أبداً",
  "patient_adult_knee_pain_q2_a2":"شهريا",
  "patient_adult_knee_pain_q2_a3":"أسبوعيا",
  "patient_adult_knee_pain_q2_a4":"يوميا",
  "patient_adult_knee_pain_q2_a5":"ثابت",
  "patient_adult_knee_pain_q3":"أي من العبارات التالية يصف الألم بشكل أفضل",
  "patient_adult_knee_pain_q3_a1":"لا يوجد ألم ، ركبة طبيعية ، تؤدي 100٪",
  "patient_adult_knee_pain_q3_a2":"ألم عرضي مصحوب بالرياضات الشاقة والعمل الشاق ، الركبة ليست طبيعية تمامًا ، بعض القيود ولكنها طفيفة ويمكن تحملها",
  "patient_adult_knee_pain_q3_a3":"الألم الناتج عن ممارسة الرياضة أو الأنشطة الترفيهية الخفيفة أو العمل المعتدل. يحدث أحيانًا مع المشي لمسافة تزيد عن 2 كم أو الوقوف أو العمل الخفيف",
  "patient_adult_knee_pain_q3_a4":"ألم عرضي مع الرياضات الترفيهية الخفيفة أو أنشطة العمل المعتدلة ، أو الجري أو العمل الشاق ، والرياضات الشاقة",
  "patient_adult_knee_pain_q3_a5":"الألم مشكلة كبيرة مع الأنشطة البسيطة مثل المشي لمسافة أقل من 2 كيلومتر، يتخفف بالراحة، وغير قادر على ممارسة الرياضة.",
  "patient_adult_knee_pain_q3_a6":"ثابت",
  "patient_adult_knee_pain_q4":"إذا كان لديك ألم، كم هو شديد؟",
  "patient_adult_knee_pain_q4_a1":"لا شيء",
  "patient_adult_knee_pain_q4_a2":"خفيف جدا",
  "patient_adult_knee_pain_q4_a3":"خفيف",
  "patient_adult_knee_pain_q4_a4":"معتدل",
  "patient_adult_knee_pain_q4_a5":"شديد",
  "patient_adult_knee_pain_q5":"إلى أي مدى أثر ألم الركبة على عملك العادي (بما في ذلك الأعمال المنزلية)؟",
  "patient_adult_knee_pain_q5_a1":"مُطْلَقاً",
  "patient_adult_knee_pain_q5_a2":"قليلا",
  "patient_adult_knee_pain_q5_a3":"باعتدال",
  "patient_adult_knee_pain_q5_a4":"إلى حد كبير",
  "patient_adult_knee_pain_q5_a5":"تماما",
  "patient_adult_knee_pain_instruction":"ما هي الكمية من ألم الركبة التي شعرت بها خلال الأنشطة التالية؟",
  "patient_adult_knee_pain_instruction_0":"لا شيء",
  "patient_adult_knee_pain_instruction_1":"خفيف",
  "patient_adult_knee_pain_instruction_2":"معتدل",
  "patient_adult_knee_pain_instruction_3":"شديد",
  "patient_adult_knee_pain_instruction_4":"أقصى",
  "patient_adult_knee_pain_q6":"لف الركبة / تدويرها",
  "patient_adult_knee_pain_q6_a1":"لا شيء",
  "patient_adult_knee_pain_q6_a2":"خفيف",
  "patient_adult_knee_pain_q6_a3":"معتدل",
  "patient_adult_knee_pain_q6_a4":"شديد",
  "patient_adult_knee_pain_q6_a5":"أقصى",
  "patient_adult_knee_pain_q7":"تمام استقامة الركبة",
  "patient_adult_knee_pain_q7_a1":"لا شيء",
  "patient_adult_knee_pain_q7_a2":"خفيف",
  "patient_adult_knee_pain_q7_a3":"معتدل",
  "patient_adult_knee_pain_q7_a4":"شديد",
  "patient_adult_knee_pain_q7_a5":"أقصى",
  "patient_adult_knee_pain_q8":"انحناء الركبة تمامًا",
  "patient_adult_knee_pain_q8_a1":"لا شيء",
  "patient_adult_knee_pain_q8_a2":"خفيف",
  "patient_adult_knee_pain_q8_a3":"معتدل",
  "patient_adult_knee_pain_q8_a4":"شديد",
  "patient_adult_knee_pain_q8_a5":"أقصى",
  "patient_adult_knee_pain_q9":"المشي على سطح مستوٍ",
  "patient_adult_knee_pain_q9_a1":"لا شيء",
  "patient_adult_knee_pain_q9_a2":"خفيف",
  "patient_adult_knee_pain_q9_a3":"معتدل",
  "patient_adult_knee_pain_q9_a4":"شديد",
  "patient_adult_knee_pain_q9_a5":"أقصى",
  "patient_adult_knee_pain_q10":"كم يمكنك المشي قبل أن يصبح ألم الركبة شديدًا؟ (مع أو بدون عصا) ",
  "patient_adult_knee_pain_q10_a1":"أكثر من 30 دقيقة",
  "patient_adult_knee_pain_q10_a2":"16-30 دقيقة",
  "patient_adult_knee_pain_q10_a3":"5-15 دقيقة",
  "patient_adult_knee_pain_q10_a4":"حول المنزل فقط",
  "patient_adult_knee_pain_q10_a5":"لا يستطيع المشي إطلاقا / ألم شديد عند المشي",
  "patient_adult_knee_pain_q11":"الصعود أو النزول على الدرج",
  "patient_adult_knee_pain_q11_a1":"لا شيء",
  "patient_adult_knee_pain_q11_a2":"خفيف",
  "patient_adult_knee_pain_q11_a3":"معتدل",
  "patient_adult_knee_pain_q11_a4":"شديد",
  "patient_adult_knee_pain_q11_a5":"أقصى",
  "patient_adult_knee_pain_q12":"في الليل أثناء النوم",
  "patient_adult_knee_pain_q12_a1":"لا شيء",
  "patient_adult_knee_pain_q12_a2":"خفيف",
  "patient_adult_knee_pain_q12_a3":"معتدل",
  "patient_adult_knee_pain_q12_a4":"شديد",
  "patient_adult_knee_pain_q12_a5":"أقصى",
  "patient_adult_knee_pain_q13":"ما مدى اضطراب ألم الركبة لديك في الليل أثناء النوم؟",
  "patient_adult_knee_pain_q13_a1":"لا ليال",
  "patient_adult_knee_pain_q13_a2":"ليلة أو ليلتان فقط",
  "patient_adult_knee_pain_q13_a3":"بعض الليالي",
  "patient_adult_knee_pain_q13_a4":"معظم الليالي",
  "patient_adult_knee_pain_q13_a5":"كل ليلة",
  "patient_adult_knee_pain_q14":"الجلوس أو الاستلقاء",
  "patient_adult_knee_pain_q14_a1":"لا شيء",
  "patient_adult_knee_pain_q14_a2":"خفيف",
  "patient_adult_knee_pain_q14_a3":"معتدل",
  "patient_adult_knee_pain_q14_a4":"شديد",
  "patient_adult_knee_pain_q14_a5":"أقصى",
  "patient_adult_knee_pain_q15":"الوقوف بشكل مستقيم",
  "patient_adult_knee_pain_q15_a1":"لا شيء",
  "patient_adult_knee_pain_q15_a2":"خفيف",
  "patient_adult_knee_pain_q15_a3":"معتدل",
  "patient_adult_knee_pain_q15_a4":"شديد",
  "patient_adult_knee_pain_q15_a5":"أقصى",
  "patient_adult_knee_pain_q16":"الوقوف من على الكرسي",
  "patient_adult_knee_pain_q16_a1":"لا شيء",
  "patient_adult_knee_pain_q16_a2":"خفيف",
  "patient_adult_knee_pain_q16_a3":"معتدل",
  "patient_adult_knee_pain_q16_a4":"شديد",
  "patient_adult_knee_pain_q16_a5":"أقصى",
  "patient_adult_knee_other_symptoms":"أعراض أخرى",
  "patient_adult_knee_other_symptoms_instruction1":"الرجاء اختيار أفضل إجابة تتناسب مع أعراض ركبتك.",
  "patient_adult_knee_other_symptoms_instruction2":"إذا لم تكن متأكدًا من كيفية الإجابة على أحد الأسئلة ، فيرجى تقديم أفضل إجابة ممكنة.",
  "patient_adult_knee_other_symptoms_instruction3":"هل لديك ما يلي",
  "patient_adult_knee_other_symptoms_instruction_0":"لا شيء",
  "patient_adult_knee_other_symptoms_instruction_1":"خفيف",
  "patient_adult_knee_other_symptoms_instruction_2":"معتدل",
  "patient_adult_knee_other_symptoms_instruction_3":"شديد",
  "patient_adult_knee_other_symptoms_instruction_4":"أقصى",
  "patient_adult_knee_other_symptoms_q1":"التورم",
  "patient_adult_knee_other_symptoms_q1_a1":"أبداً",
  "patient_adult_knee_other_symptoms_q1_a2":"نادراً",
  "patient_adult_knee_other_symptoms_q1_a3":"أحيانًا",
  "patient_adult_knee_other_symptoms_q1_a4":"غالبًا ما",
  "patient_adult_knee_other_symptoms_q1_a5":"دائمًا",
  "patient_adult_knee_other_symptoms_q2":"صرير أو تكتكة أو أي نوع آخر من الصوة عندما تتحرك الركبة",
  "patient_adult_knee_other_symptoms_q2_a1":"أبداً",
  "patient_adult_knee_other_symptoms_q2_a2":"نادراً",
  "patient_adult_knee_other_symptoms_q2_a3":"أحيانًا",
  "patient_adult_knee_other_symptoms_q2_a4":"غالبًا ما",
  "patient_adult_knee_other_symptoms_q2_a5":"دائمًا",
  "patient_adult_knee_other_symptoms_q3":"إمساك أو قفل",
  "patient_adult_knee_other_symptoms_q3_a1":"أبداً",
  "patient_adult_knee_other_symptoms_q3_a2":"نادراً",
  "patient_adult_knee_other_symptoms_q3_a3":"أحيانًا",
  "patient_adult_knee_other_symptoms_q3_a4":"غالبًا ما",
  "patient_adult_knee_other_symptoms_q3_a5":"دائمًا",
  "patient_adult_knee_other_symptoms_q4":"العرج",
  "patient_adult_knee_other_symptoms_q4_a1":"نادرا / أبدا",
  "patient_adult_knee_other_symptoms_q4_a2":"في بعض الأحيان ، أو في البداية فقط",
  "patient_adult_knee_other_symptoms_q4_a3":"في كثير من الأحيان ، ليس فقط في البداية",
  "patient_adult_knee_other_symptoms_q4_a4":"معظم الوقت",
  "patient_adult_knee_other_symptoms_q4_a5":"كل الوقت",
  "patient_adult_knee_other_symptoms_q5":"خلال الأسابيع الأربعة الماضية أو منذ إصابة ركبتك، كيف كانت ركبتك منتفخة أو متيبسة؟",
  "patient_adult_knee_other_symptoms_q5_a1":"مُطْلَقاً",
  "patient_adult_knee_other_symptoms_q5_a2":"خفيف",
  "patient_adult_knee_other_symptoms_q5_a3":"باعتدال",
  "patient_adult_knee_other_symptoms_q5_a4":"جداً",
  "patient_adult_knee_other_symptoms_q5_a5":"لأقصى حد",
  "patient_adult_knee_other_symptoms_q6":"ما هو أعلى مستوى من النشاط يمكنك القيام به دون تورم كبير في ركبتك؟",
  "patient_adult_knee_other_symptoms_q6_a1":"الأنشطة الشاقة للغاية مثل القفز أو التمحور كما هو الحال في كرة السلة أو كرة القدم",
  "patient_adult_knee_other_symptoms_q6_a2":"الأنشطة الشاقة مثل العمل البدني الشاق أو التزلج أو التنس",
  "patient_adult_knee_other_symptoms_q6_a3":"الأنشطة المعتدلة مثل العمل البدني المعتدل أو الجري أو الركض",
  "patient_adult_knee_other_symptoms_q6_a4":"الأنشطة الخفيفة مثل المشي أو الأعمال المنزلية أو أعمال الفناء",
  "patient_adult_knee_other_symptoms_q6_a5":"عدم القدرة على أداء أي من الأنشطة المذكورة أعلاه بسبب تورم الركبة",
  "patient_adult_knee_other_symptoms_q7":"ما مدى شدة تصلب مفاصلك:",
  "patient_adult_knee_other_symptoms_q7_0":"لا شيء",
  "patient_adult_knee_other_symptoms_q7_1":"خفيف",
  "patient_adult_knee_other_symptoms_q7_2":"معتدل",
  "patient_adult_knee_other_symptoms_q7_3":"شديد",
  "patient_adult_knee_other_symptoms_q7_4":"أقصى",
  "patient_adult_knee_other_symptoms_q7_q1":"مدى شدة تيبس مفصل ركبتك بعد الاستيقاظ الأول في الصباح؟",
  "patient_adult_knee_other_symptoms_q7_q1_a1":"لا شيء",
  "patient_adult_knee_other_symptoms_q7_q1_a2":"خفيف",
  "patient_adult_knee_other_symptoms_q7_q1_a3":"معتدل",
  "patient_adult_knee_other_symptoms_q7_q1_a4":"شديد",
  "patient_adult_knee_other_symptoms_q7_q1_a5":"أقصى",
  "patient_adult_knee_other_symptoms_q7_q2":"بعد الجلوس أو الاستلقاء أو الراحة في وقت لاحق من اليوم؟",
  "patient_adult_knee_other_symptoms_q7_q2_a1":"لا شيء",
  "patient_adult_knee_other_symptoms_q7_q2_a2":"خفيف",
  "patient_adult_knee_other_symptoms_q7_q2_a3":"معتدل",
  "patient_adult_knee_other_symptoms_q7_q2_a4":"شديد",
  "patient_adult_knee_other_symptoms_q7_q2_a5":"أقصى",
  "patient_adult_knee_other_symptoms_q8":"ما هو أعلى مستوى نشاط يمكنك القيام به دون حدوث تراجع في ركبتك؟",
  "patient_adult_knee_other_symptoms_q8_a1":"لا يوجد انفلات او خاوية او تسيب الركبة ",
  "patient_adult_knee_other_symptoms_q8_a2":"تحدث الانزلاق بين الحين والآخر مع ممارسة الرياضات المكثفة أو الأعمال الشاقة، يمكن المشاركة في جميع الرياضات ولكن يوجد بعض الحراسة أو القيود. نادرًا ما يحدث ذلك أثناء ممارسة الألعاب الرياضية أو الجهد الشديد الآخر.",
  "patient_adult_knee_other_symptoms_q8_a3":"يحدث ذلك بشكل متكرر أثناء ممارسة الرياضة أو الجهود الشديدة الأخرى. يحدث انزلاق أحيانًا مع ممارسة الرياضة الخفيفة أو العمل المعتدل. قادر على التعويض ولكن يحد من الأنشطة القوية أو الرياضة أو العمل الشاق ، غير قادر على القطع أو الالتواء فجأة",
  "patient_adult_knee_other_symptoms_q8_a4":"الانزلاق يحد من ممارسة الرياضة أو العمل المعتدل، ويحدث بشكل نادر أثناء المشي أو العمل الخفيف (حوالي 3 مرات في السنة). أحيانًا يحدث في الأنشطة اليومية.",
  "patient_adult_knee_other_symptoms_q8_a5":"الانزلاق يحدث أثناء أنشطة المشي البسيطة والعمل الخفيف. يحدث مرة واحدة في الشهر، ويتطلب الحذر. غالباً ما يحدث ذلك في الأنشطة اليومية.",
  "patient_adult_knee_other_symptoms_q8_a6":"مشكلة شديدة مع أنشطة المشي البسيطة، يحدث الانزلاق في كل خطوة، غير قادر على الالتفاف أو اللف أثناء المشي دون حدوث الانزلاق.",
  "patient_adult_knee_other_symptoms_q9":"هل تستخدم أي دعم للمشي؟",
  "patient_adult_knee_other_symptoms_q9_a1":"لا شيء",
  "patient_adult_knee_other_symptoms_q9_a2":"عصا أو عكاز",
  "patient_adult_knee_other_symptoms_q9_a3":"حمل الوزن مستحيل",
  "patient_adult_knee_function":"الوظيفة",
  "patient_adult_knee_function_instruction":"حدد درجة الصعوبة التي مررت بها أثناء القيام بالأنشطة التالية في الأسبوع الماضي بسبب ركبتك",
  "patient_adult_knee_function_instruction_0":"لا توجد صعوبة",
  "patient_adult_knee_function_instruction_1":"صعوبة طفيفة",
  "patient_adult_knee_function_instruction_2":"صعوبة متوسطة",
  "patient_adult_knee_function_instruction_3":"صعوبة بالغة",
  "patient_adult_knee_function_instruction_4":"غير قادر على القيام به",
  "patient_adult_knee_function_note":"ملاحظة: إذا لم تقم بنشاط معين ، فتخيل كيف ستشعر ركبتك إذا كان عليك تجربتها",
  "patient_adult_knee_function_q1":"الصعود على الدرج",
  "patient_adult_knee_function_q1_a1":"لا توجد صعوبة",
  "patient_adult_knee_function_q1_a2":"صعوبة طفيفة",
  "patient_adult_knee_function_q1_a3":"صعوبة متوسطة",
  "patient_adult_knee_function_q1_a4":"صعوبة بالغة",
  "patient_adult_knee_function_q1_a5":"غير قادر على القيام به",
  "patient_adult_knee_function_q2":"النزول على الدرج",
  "patient_adult_knee_function_q2_a1":"لا توجد صعوبة",
  "patient_adult_knee_function_q2_a2":"صعوبة طفيفة",
  "patient_adult_knee_function_q2_a3":"صعوبة متوسطة",
  "patient_adult_knee_function_q2_a4":"صعوبة بالغة",
  "patient_adult_knee_function_q2_a5":"غير قادر على القيام به",
  "patient_adult_knee_function_q3":"الركوع على مقدمة ركبتك",
  "patient_adult_knee_function_q3_a1":"لا توجد صعوبة",
  "patient_adult_knee_function_q3_a2":"صعوبة طفيفة",
  "patient_adult_knee_function_q3_a3":"صعوبة متوسطة",
  "patient_adult_knee_function_q3_a4":"صعوبة بالغة",
  "patient_adult_knee_function_q3_a5":"غير قادر على القيام به",
  "patient_adult_knee_function_q4":"تمديد ركبتك",
  "patient_adult_knee_function_q4_a1":"لا توجد صعوبة",
  "patient_adult_knee_function_q4_a2":"صعوبة طفيفة",
  "patient_adult_knee_function_q4_a3":"صعوبة متوسطة",
  "patient_adult_knee_function_q4_a4":"صعوبة بالغة",
  "patient_adult_knee_function_q4_a5":"غير قادر على القيام به",
  "patient_adult_knee_function_q5":"ثني ركبتك",
  "patient_adult_knee_function_q5_a1":"لا توجد صعوبة",
  "patient_adult_knee_function_q5_a2":"صعوبة طفيفة",
  "patient_adult_knee_function_q5_a3":"صعوبة متوسطة",
  "patient_adult_knee_function_q5_a4":"صعوبة بالغة",
  "patient_adult_knee_function_q5_a5":"غير قادر على القيام به",
  "patient_adult_knee_function_q6":"القرفصاء",
  "patient_adult_knee_function_q6_a1":"لا توجد صعوبة",
  "patient_adult_knee_function_q6_a2":"صعوبة طفيفة",
  "patient_adult_knee_function_q6_a3":"صعوبة متوسطة",
  "patient_adult_knee_function_q6_a4":"صعوبة بالغة",
  "patient_adult_knee_function_q6_a5":"غير قادر على القيام به",
  "patient_adult_knee_function_q7":"الجلوس مع إنحناء الركبة",
  "patient_adult_knee_function_q7_a1":"لا توجد صعوبة",
  "patient_adult_knee_function_q7_a2":"صعوبة طفيفة",
  "patient_adult_knee_function_q7_a3":"صعوبة متوسطة",
  "patient_adult_knee_function_q7_a4":"صعوبة بالغة",
  "patient_adult_knee_function_q7_a5":"غير قادر على القيام به",
  "patient_adult_knee_function_q8":"النهوض من كرسي",
  "patient_adult_knee_function_q8_a1":"لا توجد صعوبة",
  "patient_adult_knee_function_q8_a2":"صعوبة طفيفة",
  "patient_adult_knee_function_q8_a3":"صعوبة متوسطة",
  "patient_adult_knee_function_q8_a4":"صعوبة بالغة",
  "patient_adult_knee_function_q8_a5":"غير قادر على القيام به",
  "patient_adult_knee_function_q9":"النهوض من السرير",
  "patient_adult_knee_function_q9_a1":"لا توجد صعوبة",
  "patient_adult_knee_function_q9_a2":"صعوبة طفيفة",
  "patient_adult_knee_function_q9_a3":"صعوبة متوسطة",
  "patient_adult_knee_function_q9_a4":"صعوبة بالغة",
  "patient_adult_knee_function_q9_a5":"غير قادر على القيام به",
  "patient_adult_knee_function_q10":"الاستلقاء في السرير (التحول والحفاظ على وضع الركبة)",
  "patient_adult_knee_function_q10_a1":"لا توجد صعوبة",
  "patient_adult_knee_function_q10_a2":"صعوبة طفيفة",
  "patient_adult_knee_function_q10_a3":"صعوبة متوسطة",
  "patient_adult_knee_function_q10_a4":"صعوبة بالغة",
  "patient_adult_knee_function_q10_a5":"غير قادر على القيام به",
  "patient_adult_knee_function_q11":"الانحناء للأرض / التقاط الأشياء",
  "patient_adult_knee_function_q11_a1":"لا توجد صعوبة",
  "patient_adult_knee_function_q11_a2":"صعوبة طفيفة",
  "patient_adult_knee_function_q11_a3":"صعوبة متوسطة",
  "patient_adult_knee_function_q11_a4":"صعوبة بالغة",
  "patient_adult_knee_function_q11_a5":"غير قادر على القيام به",
  "patient_adult_knee_function_q12":"وضع الجوارب / الجوارب الطويلة",
  "patient_adult_knee_function_q12_a1":"لا توجد صعوبة",
  "patient_adult_knee_function_q12_a2":"صعوبة طفيفة",
  "patient_adult_knee_function_q12_a3":"صعوبة متوسطة",
  "patient_adult_knee_function_q12_a4":"صعوبة بالغة",
  "patient_adult_knee_function_q12_a5":"غير قادر على القيام به",
  "patient_adult_knee_function_q13":"خلع الجوارب / الجوارب الطويلة",
  "patient_adult_knee_function_q13_a1":"لا توجد صعوبة",
  "patient_adult_knee_function_q13_a2":"صعوبة طفيفة",
  "patient_adult_knee_function_q13_a3":"صعوبة متوسطة",
  "patient_adult_knee_function_q13_a4":"صعوبة بالغة",
  "patient_adult_knee_function_q13_a5":"غير قادر على القيام به",
  "patient_adult_knee_function_q14":"الوقوف",
  "patient_adult_knee_function_q14_a1":"لا توجد صعوبة",
  "patient_adult_knee_function_q14_a2":"صعوبة طفيفة",
  "patient_adult_knee_function_q14_a3":"صعوبة متوسطة",
  "patient_adult_knee_function_q14_a4":"صعوبة بالغة",
  "patient_adult_knee_function_q14_a5":"غير قادر على القيام به",
  "patient_adult_knee_function_q15":"المشي",
  "patient_adult_knee_function_q15_a1":"لا توجد صعوبة",
  "patient_adult_knee_function_q15_a2":"صعوبة طفيفة",
  "patient_adult_knee_function_q15_a3":"صعوبة متوسطة",
  "patient_adult_knee_function_q15_a4":"صعوبة بالغة",
  "patient_adult_knee_function_q15_a5":"غير قادر على القيام به",
  "patient_adult_knee_function_q16":"الجري للأمام مباشرة",
  "patient_adult_knee_function_q16_a1":"لا توجد صعوبة",
  "patient_adult_knee_function_q16_a2":"صعوبة طفيفة",
  "patient_adult_knee_function_q16_a3":"صعوبة متوسطة",
  "patient_adult_knee_function_q16_a4":"صعوبة بالغة",
  "patient_adult_knee_function_q16_a5":"غير قادر على القيام به",
  "patient_adult_knee_function_q17":"القفز والهبوط على ساقك المصابة",
  "patient_adult_knee_function_q17_a1":"لا توجد صعوبة",
  "patient_adult_knee_function_q17_a2":"صعوبة طفيفة",
  "patient_adult_knee_function_q17_a3":"صعوبة متوسطة",
  "patient_adult_knee_function_q17_a4":"صعوبة بالغة",
  "patient_adult_knee_function_q17_a5":"غير قادر على القيام به",
  "patient_adult_knee_function_q18":"التوقف والبدء بسرعة",
  "patient_adult_knee_function_q18_a1":"لا توجد صعوبة",
  "patient_adult_knee_function_q18_a2":"صعوبة طفيفة",
  "patient_adult_knee_function_q18_a3":"صعوبة متوسطة",
  "patient_adult_knee_function_q18_a4":"صعوبة بالغة",
  "patient_adult_knee_function_q18_a5":"غير قادر على القيام به",
  "patient_adult_knee_function_q19":"التدوير / الدوران على ركبتك المصابة",
  "patient_adult_knee_function_q19_a1":"لا توجد صعوبة",
  "patient_adult_knee_function_q19_a2":"صعوبة طفيفة",
  "patient_adult_knee_function_q19_a3":"صعوبة متوسطة",
  "patient_adult_knee_function_q19_a4":"صعوبة بالغة",
  "patient_adult_knee_function_q19_a5":"غير قادر على القيام به",
  "patient_adult_knee_function_q20":"الدخول والخروج من السيارة أو استخدام وسائل النقل العام",
  "patient_adult_knee_function_q20_a1":"لا توجد صعوبة",
  "patient_adult_knee_function_q20_a2":"صعوبة طفيفة",
  "patient_adult_knee_function_q20_a3":"صعوبة متوسطة",
  "patient_adult_knee_function_q20_a4":"صعوبة بالغة",
  "patient_adult_knee_function_q20_a5":"غير قادر على القيام به",
  "patient_adult_knee_function_q21":"الدخول والخروج من الحوض",
  "patient_adult_knee_function_q21_a1":"لا توجد صعوبة",
  "patient_adult_knee_function_q21_a2":"صعوبة طفيفة",
  "patient_adult_knee_function_q21_a3":"صعوبة متوسطة",
  "patient_adult_knee_function_q21_a4":"صعوبة بالغة",
  "patient_adult_knee_function_q21_a5":"غير قادر على القيام به",
  "patient_adult_knee_function_q22":"الدخول والخروج من الحمام",
  "patient_adult_knee_function_q22_a1":"لا توجد صعوبة",
  "patient_adult_knee_function_q22_a2":"صعوبة طفيفة",
  "patient_adult_knee_function_q22_a3":"صعوبة متوسطة",
  "patient_adult_knee_function_q22_a4":"صعوبة بالغة",
  "patient_adult_knee_function_q22_a5":"غير قادر على القيام به",
  "patient_adult_knee_function_q23":"غسل وتجفيف نفسك",
  "patient_adult_knee_function_q23_a1":"لا توجد صعوبة",
  "patient_adult_knee_function_q23_a2":"صعوبة طفيفة",
  "patient_adult_knee_function_q23_a3":"صعوبة متوسطة",
  "patient_adult_knee_function_q23_a4":"صعوبة بالغة",
  "patient_adult_knee_function_q23_a5":"غير قادر على القيام به",
  "patient_adult_knee_function_q24":"تسوق للأغراض المنزلية",
  "patient_adult_knee_function_q24_a1":"لا توجد صعوبة",
  "patient_adult_knee_function_q24_a2":"صعوبة طفيفة",
  "patient_adult_knee_function_q24_a3":"صعوبة متوسطة",
  "patient_adult_knee_function_q24_a4":"صعوبة بالغة",
  "patient_adult_knee_function_q24_a5":"غير قادر على القيام به",
  "patient_adult_knee_function_q25":"الأعمال المنزلية الثقيلة (نقل الصناديق الثقيلة ، تنظيف الأرضيات ، إلخ)",
  "patient_adult_knee_function_q25_a1":"لا توجد صعوبة",
  "patient_adult_knee_function_q25_a2":"صعوبة طفيفة",
  "patient_adult_knee_function_q25_a3":"صعوبة متوسطة",
  "patient_adult_knee_function_q25_a4":"صعوبة بالغة",
  "patient_adult_knee_function_q25_a5":"غير قادر على القيام به",
  "patient_adult_knee_function_q26":"الأعمال المنزلية الخفيفة (الطهي والتنظيف وما إلى و ذلك)",
  "patient_adult_knee_function_q26_a1":"لا توجد صعوبة",
  "patient_adult_knee_function_q26_a2":"صعوبة طفيفة",
  "patient_adult_knee_function_q26_a3":"صعوبة متوسطة",
  "patient_adult_knee_function_q26_a4":"صعوبة بالغة",
  "patient_adult_knee_function_q26_a5":"غير قادر على القيام به",
  "patient_adult_knee_function_q27":"كيف تقيّم وظيفة ركبتك الحالية على مقياس من 0 إلى 10؟",
  "patient_adult_knee_function_q28":"10 تكون طبيعية و 0 هي عدم القدرة على أداء أي من أنشطتك اليومية المعتادة التي قد تشمل الرياضة",
  "patient_adult_knee_function_q28_a1":"أسوأ",
  "patient_adult_knee_function_q28_a2":"أفضل",
  "patient_adult_knee_function_q29":"ما هو أعلى مستوى من النشاط يمكنك المشاركة فيه بشكل منتظم؟",
  "patient_adult_knee_function_q29_a1":"الأنشطة الشاقة للغاية مثل القفز أو التمحور كما هو الحال في كرة السلة أو كرة القدم",
  "patient_adult_knee_function_q29_a2":"الأنشطة الشاقة مثل العمل البدني الشاق أو التزلج أو التنس",
  "patient_adult_knee_function_q29_a3":"الأنشطة المعتدلة مثل العمل البدني المعتدل أو الجري أو الركض",
  "patient_adult_knee_function_q29_a4":"الأنشطة الخفيفة مثل المشي أو الأعمال المنزلية أو أعمال الفناء",
  "patient_adult_knee_function_q29_a5":"عدم القدرة على أداء أي من الأنشطة المذكورة أعلاه بسبب آلام الركبة",
  "patient_adult_knee_function_q30":"الرجاء تحديد أعلى مستوى من النشاط يمكنك المشاركة فيه",
  "patient_adult_knee_function_q30_q1":"قبل الإصابة بالركبة / مشكلة الركبة",
  "patient_adult_knee_function_q30_q1_a1":"تنافسي",
  "patient_adult_knee_function_q30_q1_a2":" الترفيه واللياقة البدنية",
  "patient_adult_knee_function_q30_q1_a3":"لا الرياضة ، العمل فقط",
  "patient_adult_knee_function_q30_q1_a4":"فقط أنشطة الحياة اليومية",
  "patient_adult_knee_function_q30_q1_a5":"الإجازة المرضية أو معاش العجز بسبب مشاكل صحية",
  "patient_adult_knee_function_q30_q1_a1_o1":"الرياضات التنافسية التي تمارس على المستوى العالمي أو الأولمبي أو المهني",
  "patient_adult_knee_function_q30_q1_a1_o2":"الرياضات التنافسية مثل سباقات المضمار والميدان ، ورياضات المضرب / الكرة ، والجمباز ، والتجديف ، والتزلج ، أو فنون القتال التي تُلعب على المستوى الوطني أو الدولي",
  "patient_adult_knee_function_q30_q1_a1_o3":"الرياضات التنافسية مثل الرياضات المائية أو ركوب الدراجات أو الهوكي أو الشباك أو ركوب الخيل / مسابقات رعاة البقر التي تُلعب على المستوى الوطني أو الدولي",
  "patient_adult_knee_function_q30_q1_a2_o1":"الرياضات الترفيهية مثل الجري أو الكرة / المضرب أو تدريب الأثقال أو الشباك أو التجديف أو الجولف أو اليوجا أو الجمباز 5 مرات على الأقل في الأسبوع",
  "patient_adult_knee_function_q30_q1_a2_o2":"الرياضات الترفيهية مثل السباحة أو الرياضات المائية أو التزلج أو الهوكي أو التزلج على الجليد أو ركوب الدراجات أو ركوب الخيل أو تسلق الجبال 5 مرات على الأقل في الأسبوع",
  "patient_adult_knee_function_q30_q1_a2_o3":"ممارسة الرياضة الترفيهية مرتين أسبوعياً على الأقل",
  "patient_adult_knee_function_q30_q1_a2_o4":"الرياضات الترفيهية مرة في الأسبوع أو أقل",
  "patient_adult_knee_function_q30_q1_a2_o5":"الرياضات الترفيهية أقل من مرة في الشهر",
  "patient_adult_knee_function_q30_q1_a3_o1":"الأشغال الشاقة مثل البناء",
  "patient_adult_knee_function_q30_q1_a3_o2":"الأشغال الشاقة بشكل معتدل مثل قيادة الشاحنات",
  "patient_adult_knee_function_q30_q1_a3_o3":"العمل الخفيف مثل التمريض",
  "patient_adult_knee_function_q30_q1_a3_o4":"عمل ساكن مثل الأعمال السكرتارية أو العمل المرتبط بالكمبيوتر.",
  "patient_adult_knee_function_q30_q1_a4_o1":"الأنشطة اليومية مثل البستنة ، وتسلق عدة درجات من السلالم ، وحمل الأحمال ، ودفع / سحب حمولة ، والقدرة على الجري إذا تأخرت",
  "patient_adult_knee_function_q30_q1_a4_o2":"الأنشطة اليومية مثل تنظيف المنزل ، أو تسلق مجموعة أو مجموعتين من السلالم ، أو المشي بحمولة خفيفة",
  "patient_adult_knee_function_q30_q1_a4_o3":"الأنشطة اليومية محدودة (مثل عدم صعود السلالم ، وعدم القدرة على حمل الأحمال)",
  "patient_adult_knee_function_q30_q2":"الوضع الحالي",
  "patient_adult_knee_function_q30_q2_a1":"تنافسي",
  "patient_adult_knee_function_q30_q2_a2":" الترفيه واللياقة البدنية",
  "patient_adult_knee_function_q30_q2_a3":"لا الرياضة ، العمل فقط",
  "patient_adult_knee_function_q30_q2_a4":"فقط أنشطة الحياة اليومية",
  "patient_adult_knee_function_q30_q2_a5":"الإجازة المرضية أو معاش العجز بسبب مشاكل صحية",
  "patient_adult_knee_function_q30_q2_a1_o1":"الرياضات التنافسية التي تمارس على المستوى العالمي أو الأولمبي أو المهني",
  "patient_adult_knee_function_q30_q2_a1_o2":"الرياضات التنافسية مثل سباقات المضمار والميدان ، ورياضات المضرب / الكرة ، والجمباز ، والتجديف ، والتزلج ، أو فنون القتال التي تُلعب على المستوى الوطني أو الدولي",
  "patient_adult_knee_function_q30_q2_a1_o3":"الرياضات التنافسية مثل الرياضات المائية أو ركوب الدراجات أو الهوكي أو الشباك أو ركوب الخيل / مسابقات رعاة البقر التي تُلعب على المستوى الوطني أو الدولي",
  "patient_adult_knee_function_q30_q2_a2_o1":"الرياضات الترفيهية مثل الجري أو الكرة / المضرب أو تدريب الأثقال أو الشباك أو التجديف أو الجولف أو اليوجا أو الجمباز 5 مرات على الأقل في الأسبوع",
  "patient_adult_knee_function_q30_q2_a2_o2":"الرياضات الترفيهية مثل السباحة أو الرياضات المائية أو التزلج أو الهوكي أو التزلج على الجليد أو ركوب الدراجات أو ركوب الخيل أو تسلق الجبال 5 مرات على الأقل في الأسبوع",
  "patient_adult_knee_function_q30_q2_a2_o3":"ممارسة الرياضة الترفيهية مرتين أسبوعياً على الأقل",
  "patient_adult_knee_function_q30_q2_a2_o4":"الرياضات الترفيهية مرة في الأسبوع أو أقل",
  "patient_adult_knee_function_q30_q2_a2_o5":"الرياضات الترفيهية أقل من مرة في الشهر",
  "patient_adult_knee_function_q30_q2_a3_o1":"الأشغال الشاقة مثل البناء",
  "patient_adult_knee_function_q30_q2_a3_o2":"الأشغال الشاقة بشكل معتدل مثل قيادة الشاحنات",
  "patient_adult_knee_function_q30_q2_a3_o3":"العمل الخفيف مثل التمريض",
  "patient_adult_knee_function_q30_q2_a3_o4":"عمل ساكن مثل الأعمال السكرتارية أو العمل المرتبط بالكمبيوتر.",
  "patient_adult_knee_function_q30_q2_a4_o1":"الأنشطة اليومية مثل البستنة ، وتسلق عدة درجات من السلالم ، وحمل الأحمال ، ودفع / سحب حمولة ، والقدرة على الجري إذا تأخرت",
  "patient_adult_knee_function_q30_q2_a4_o2":"الأنشطة اليومية مثل تنظيف المنزل ، أو تسلق مجموعة أو مجموعتين من السلالم ، أو المشي بحمولة خفيفة",
  "patient_adult_knee_function_q30_q2_a4_o3":"الأنشطة اليومية محدودة (مثل عدم صعود السلالم ، وعدم القدرة على حمل الأحمال)",
  "patient_adult_knee_function_q31":"هل تنزلق الركبة الخاصة بك أو تشعر وكأنها ستنزلق من مكانها؟",
  "patient_adult_knee_function_q31_a1":"لا",
  "patient_adult_knee_function_q31_a2":"نعم",
  "patient_adult_knee_patellofemoral_instability":"عدم استقرار الرضفة الفخذية",
  "patient_adult_knee_patellofemoral_instability_instruction":"يُرجى اختيار أفضل وصف لمدى شعورك بالركبة وكأنها \"ستخرج\" من المفصل أو تشعر بعدم الاستقرار عند القيام بكل من الأنشطة التالية",
  "patient_adult_knee_patellofemoral_instability_instruction_0":"أبداً",
  "patient_adult_knee_patellofemoral_instability_instruction_1":"نادراً",
  "patient_adult_knee_patellofemoral_instability_instruction_2":"أحيانًا",
  "patient_adult_knee_patellofemoral_instability_instruction_3":"غالبًا ما",
  "patient_adult_knee_patellofemoral_instability_instruction_4":"دائمًا",
  "patient_adult_knee_patellofemoral_instability_q1":"التواء / تغيير الاتجاه أثناء الرياضة / الألعاب",
  "patient_adult_knee_patellofemoral_instability_q1_do_not_do":"لا يتم القيام به",
  "patient_adult_knee_patellofemoral_instability_q1_a1":"أبداً",
  "patient_adult_knee_patellofemoral_instability_q1_a2":"نادراً",
  "patient_adult_knee_patellofemoral_instability_q1_a3":"أحيانًا",
  "patient_adult_knee_patellofemoral_instability_q1_a4":"غالبًا ما",
  "patient_adult_knee_patellofemoral_instability_q1_a5":"دائمًا",
  "patient_adult_knee_patellofemoral_instability_q2":"تغيير الاتجاه عند الركض",
  "patient_adult_knee_patellofemoral_instability_q2_do_not_do":"لا يتم القيام به",
  "patient_adult_knee_patellofemoral_instability_q2_a1":"أبداً",
  "patient_adult_knee_patellofemoral_instability_q2_a2":"نادراً",
  "patient_adult_knee_patellofemoral_instability_q2_a3":"أحيانًا",
  "patient_adult_knee_patellofemoral_instability_q2_a4":"غالبًا ما",
  "patient_adult_knee_patellofemoral_instability_q2_a5":"دائمًا",
  "patient_adult_knee_patellofemoral_instability_q3":"الركض في خط مستقيم على سطوح غير مستوية ",
  "patient_adult_knee_patellofemoral_instability_q3_do_not_do":"لا يتم القيام به",
  "patient_adult_knee_patellofemoral_instability_q3_a1":"أبداً",
  "patient_adult_knee_patellofemoral_instability_q3_a2":"نادراً",
  "patient_adult_knee_patellofemoral_instability_q3_a3":"أحيانًا",
  "patient_adult_knee_patellofemoral_instability_q3_a4":"غالبًا ما",
  "patient_adult_knee_patellofemoral_instability_q3_a5":"دائمًا",
  "patient_adult_knee_patellofemoral_instability_q4":"المشي على سطوح زلقة أو مبللة أو متجمدة",
  "patient_adult_knee_patellofemoral_instability_q4_do_not_do":"لا يتم القيام به",
  "patient_adult_knee_patellofemoral_instability_q4_a1":"أبداً",
  "patient_adult_knee_patellofemoral_instability_q4_a2":"نادراً",
  "patient_adult_knee_patellofemoral_instability_q4_a3":"أحيانًا",
  "patient_adult_knee_patellofemoral_instability_q4_a4":"غالبًا ما",
  "patient_adult_knee_patellofemoral_instability_q4_a5":"دائمًا",
  "patient_adult_knee_patellofemoral_instability_q5":"الجري بشكل جانبي",
  "patient_adult_knee_patellofemoral_instability_q5_do_not_do":"لا يتم القيام به",
  "patient_adult_knee_patellofemoral_instability_q5_a1":"أبداً",
  "patient_adult_knee_patellofemoral_instability_q5_a2":"نادراً",
  "patient_adult_knee_patellofemoral_instability_q5_a3":"أحيانًا",
  "patient_adult_knee_patellofemoral_instability_q5_a4":"غالبًا ما",
  "patient_adult_knee_patellofemoral_instability_q5_a5":"دائمًا",
  "patient_adult_knee_patellofemoral_instability_q6":"القفز على ساق واحدة",
  "patient_adult_knee_patellofemoral_instability_q6_do_not_do":"لا يتم القيام به",
  "patient_adult_knee_patellofemoral_instability_q6_a1":"أبداً",
  "patient_adult_knee_patellofemoral_instability_q6_a2":"نادراً",
  "patient_adult_knee_patellofemoral_instability_q6_a3":"أحيانًا",
  "patient_adult_knee_patellofemoral_instability_q6_a4":"غالبًا ما",
  "patient_adult_knee_patellofemoral_instability_q6_a5":"دائمًا",
  "patient_adult_knee_patellofemoral_instability_q7":"القفز",
  "patient_adult_knee_patellofemoral_instability_q7_do_not_do":"لا يتم القيام به",
  "patient_adult_knee_patellofemoral_instability_q7_a1":"أبداً",
  "patient_adult_knee_patellofemoral_instability_q7_a2":"نادراً",
  "patient_adult_knee_patellofemoral_instability_q7_a3":"أحيانًا",
  "patient_adult_knee_patellofemoral_instability_q7_a4":"غالبًا ما",
  "patient_adult_knee_patellofemoral_instability_q7_a5":"دائمًا",
  "patient_adult_knee_patellofemoral_instability_q8":"الجري في خط مستقيم على سطح مستوٍ",
  "patient_adult_knee_patellofemoral_instability_q8_do_not_do":"لا يتم القيام به",
  "patient_adult_knee_patellofemoral_instability_q8_a1":"أبداً",
  "patient_adult_knee_patellofemoral_instability_q8_a2":"نادراً",
  "patient_adult_knee_patellofemoral_instability_q8_a3":"أحيانًا",
  "patient_adult_knee_patellofemoral_instability_q8_a4":"غالبًا ما",
  "patient_adult_knee_patellofemoral_instability_q8_a5":"دائمًا",
  "patient_adult_knee_patellofemoral_instability_q9":"النزول على الدرج",
  "patient_adult_knee_patellofemoral_instability_q9_do_not_do":"لا يتم القيام به",
  "patient_adult_knee_patellofemoral_instability_q9_a1":"أبداً",
  "patient_adult_knee_patellofemoral_instability_q9_a2":"نادراً",
  "patient_adult_knee_patellofemoral_instability_q9_a3":"أحيانًا",
  "patient_adult_knee_patellofemoral_instability_q9_a4":"غالبًا ما",
  "patient_adult_knee_patellofemoral_instability_q9_a5":"دائمًا",
  "patient_adult_knee_patellofemoral_instability_q10":"القرفصاء",
  "patient_adult_knee_patellofemoral_instability_q10_do_not_do":"لا يتم القيام به",
  "patient_adult_knee_patellofemoral_instability_q10_a1":"أبداً",
  "patient_adult_knee_patellofemoral_instability_q10_a2":"نادراً",
  "patient_adult_knee_patellofemoral_instability_q10_a3":"أحيانًا",
  "patient_adult_knee_patellofemoral_instability_q10_a4":"غالبًا ما",
  "patient_adult_knee_patellofemoral_instability_q10_a5":"دائمًا",
  "patient_adult_knee_patellofemoral_instability_q11":"الركوع",
  "patient_adult_knee_patellofemoral_instability_q11_do_not_do":"لا يتم القيام به",
  "patient_adult_knee_patellofemoral_instability_q11_a1":"أبداً",
  "patient_adult_knee_patellofemoral_instability_q11_a2":"نادراً",
  "patient_adult_knee_patellofemoral_instability_q11_a3":"أحيانًا",
  "patient_adult_knee_patellofemoral_instability_q11_a4":"غالبًا ما",
  "patient_adult_knee_patellofemoral_instability_q11_a5":"دائمًا",
  "patient_adult_knee_patellofemoral_instability_q12":"المشي في خط مستقيم على سطح غير مستوٍ",
  "patient_adult_knee_patellofemoral_instability_q12_do_not_do":"لا يتم القيام به",
  "patient_adult_knee_patellofemoral_instability_q12_a1":"أبداً",
  "patient_adult_knee_patellofemoral_instability_q12_a2":"نادراً",
  "patient_adult_knee_patellofemoral_instability_q12_a3":"أحيانًا",
  "patient_adult_knee_patellofemoral_instability_q12_a4":"غالبًا ما",
  "patient_adult_knee_patellofemoral_instability_q12_a5":"دائمًا",
  "patient_adult_knee_patellofemoral_instability_q13":"تسلق الدرج",
  "patient_adult_knee_patellofemoral_instability_q13_do_not_do":"لا يتم القيام به",
  "patient_adult_knee_patellofemoral_instability_q13_a1":"أبداً",
  "patient_adult_knee_patellofemoral_instability_q13_a2":"نادراً",
  "patient_adult_knee_patellofemoral_instability_q13_a3":"أحيانًا",
  "patient_adult_knee_patellofemoral_instability_q13_a4":"غالبًا ما",
  "patient_adult_knee_patellofemoral_instability_q13_a5":"دائمًا",
  "patient_adult_knee_patellofemoral_instability_q14":"الصعود إلى مستوى عالٍ أو تجاوزه",
  "patient_adult_knee_patellofemoral_instability_q14_do_not_do":"لا يتم القيام به",
  "patient_adult_knee_patellofemoral_instability_q14_a1":"أبداً",
  "patient_adult_knee_patellofemoral_instability_q14_a2":"نادراً",
  "patient_adult_knee_patellofemoral_instability_q14_a3":"أحيانًا",
  "patient_adult_knee_patellofemoral_instability_q14_a4":"غالبًا ما",
  "patient_adult_knee_patellofemoral_instability_q14_a5":"دائمًا",
  "patient_adult_knee_patellofemoral_instability_q15":"تقاطع الساقين أثناء الجلوس",
  "patient_adult_knee_patellofemoral_instability_q15_do_not_do":"لا يتم القيام به",
  "patient_adult_knee_patellofemoral_instability_q15_a1":"أبداً",
  "patient_adult_knee_patellofemoral_instability_q15_a2":"نادراً",
  "patient_adult_knee_patellofemoral_instability_q15_a3":"أحيانًا",
  "patient_adult_knee_patellofemoral_instability_q15_a4":"غالبًا ما",
  "patient_adult_knee_patellofemoral_instability_q15_a5":"دائمًا",
  "patient_adult_knee_patellofemoral_instability_q16":"المشي في خط مستقيم على سطح مستوٍ",
  "patient_adult_knee_patellofemoral_instability_q16_do_not_do":"لا يتم القيام به",
  "patient_adult_knee_patellofemoral_instability_q16_a1":"أبداً",
  "patient_adult_knee_patellofemoral_instability_q16_a2":"نادراً",
  "patient_adult_knee_patellofemoral_instability_q16_a3":"أحيانًا",
  "patient_adult_knee_patellofemoral_instability_q16_a4":"غالبًا ما",
  "patient_adult_knee_patellofemoral_instability_q16_a5":"دائمًا",
  "patient_adult_knee_patellofemoral_instability_q17":"الدخول أو الخروج من السيارة",
  "patient_adult_knee_patellofemoral_instability_q17_do_not_do":"لا يتم القيام به",
  "patient_adult_knee_patellofemoral_instability_q17_a1":"أبداً",
  "patient_adult_knee_patellofemoral_instability_q17_a2":"نادراً",
  "patient_adult_knee_patellofemoral_instability_q17_a3":"أحيانًا",
  "patient_adult_knee_patellofemoral_instability_q17_a4":"غالبًا ما",
  "patient_adult_knee_patellofemoral_instability_q17_a5":"دائمًا",
  "patient_adult_knee_patellofemoral_instability_q18":"لف عربة ثقيلة في ممر سوبرماركت",
  "patient_adult_knee_patellofemoral_instability_q18_do_not_do":"لا يتم القيام به",
  "patient_adult_knee_patellofemoral_instability_q18_a1":"أبداً",
  "patient_adult_knee_patellofemoral_instability_q18_a2":"نادراً",
  "patient_adult_knee_patellofemoral_instability_q18_a3":"أحيانًا",
  "patient_adult_knee_patellofemoral_instability_q18_a4":"غالبًا ما",
  "patient_adult_knee_patellofemoral_instability_q18_a5":"دائمًا",
  "patient_adult_knee_patellofemoral_instability_q19":"تستدير  للنظر خلف الكتف ",
  "patient_adult_knee_patellofemoral_instability_q19_do_not_do":"لا يتم القيام به",
  "patient_adult_knee_patellofemoral_instability_q19_a1":"أبداً",
  "patient_adult_knee_patellofemoral_instability_q19_a2":"نادراً",
  "patient_adult_knee_patellofemoral_instability_q19_a3":"أحيانًا",
  "patient_adult_knee_patellofemoral_instability_q19_a4":"غالبًا ما",
  "patient_adult_knee_patellofemoral_instability_q19_a5":"دائمًا",
  "patient_adult_knee_quality_of_life":"جودة الحياة",
  "patient_adult_knee_quality_of_life_instruction":"تسأل الأسئلة التالية عن المخاوف الاجتماعية والعاطفية والمتعلقة بنمط الحياة التي قد تشعر بها فيما يتعلق بمشكلة ركبتك. يرجى التفكير فيما تشعر به معظم الوقت فيما يتعلق بركبتك.",
  "patient_adult_knee_quality_of_life_q1":"كم مدى وعيك بمشكلة ركبتك؟",
  "patient_adult_knee_quality_of_life_q1_a1":"أبداً",
  "patient_adult_knee_quality_of_life_q1_a2":"شهريا",
  "patient_adult_knee_quality_of_life_q1_a3":"أسبوعيا",
  "patient_adult_knee_quality_of_life_q1_a4":"يوميا",
  "patient_adult_knee_quality_of_life_q1_a5":"باستمرار",
  "patient_adult_knee_quality_of_life_q2":"هل قمت بتعديل نمط حياتك لتجنب الأنشطة التي يمكن أن تؤذي ركبتك؟",
  "patient_adult_knee_quality_of_life_q2_a1":"مُطْلَقاً",
  "patient_adult_knee_quality_of_life_q2_a2":"خفيف",
  "patient_adult_knee_quality_of_life_q2_a3":"باعتدال",
  "patient_adult_knee_quality_of_life_q2_a4":"بشدة",
  "patient_adult_knee_quality_of_life_q2_a5":"تماما",
  "patient_adult_knee_quality_of_life_q3":"كم تشعر بالقلق من عدم الثقة في ركبتك؟",
  "patient_adult_knee_quality_of_life_q3_a1":"مُطْلَقاً",
  "patient_adult_knee_quality_of_life_q3_a2":"خفيف",
  "patient_adult_knee_quality_of_life_q3_a3":"باعتدال",
  "patient_adult_knee_quality_of_life_q3_a4":"بشدة",
  "patient_adult_knee_quality_of_life_q3_a5":"لأقصى حد",
  "patient_adult_knee_quality_of_life_q4":"بشكل عام كم تعاني من صعوبة في ركبتك؟",
  "patient_adult_knee_quality_of_life_q4_a1":"لا شيء",
  "patient_adult_knee_quality_of_life_q4_a2":"خفيف",
  "patient_adult_knee_quality_of_life_q4_a3":"معتدل",
  "patient_adult_knee_quality_of_life_q4_a4":"شديد",
  "patient_adult_knee_quality_of_life_q4_a5":"أقصى",
  "patient_adult_knee_quality_of_life_q5":"كم تعاني من القلق / الاكتئاب اليوم؟",
  "patient_adult_knee_quality_of_life_q5_a1":"غير قلق / مكتئب",
  "patient_adult_knee_quality_of_life_q5_a2":"طفيف",
  "patient_adult_knee_quality_of_life_q5_a3":"باعتدال",
  "patient_adult_knee_quality_of_life_q5_a4":"بشدة",
  "patient_adult_knee_quality_of_life_q5_a5":"لأقصى حد",
  "patient_adult_knee_quality_of_life_q6":"كيف حالك الصحي اليوم؟",
  "patient_adult_knee_quality_of_life_q6_instruction":"100 يعني صحة ممتازة و 0 يعني أسوأ حالة صحية تستطيع تخيلها",
  "patient_adult_knee_quality_of_life_q6_instruction_a1":"أسوأ",
  "patient_adult_knee_quality_of_life_q6_instruction_a2":"أفضل",
  "patient_adult_knee_satisfaction":"الرضا",
  "patient_adult_knee_satisfaction_instructions":"ما مدى رضاك عن نتائج عمليتك / العلاج؟",
  "patient_adult_knee_satisfaction_instructions_1":"غير راض تماماً",
  "patient_adult_knee_satisfaction_instructions_2":"غير راض بعض الشيء",
  "patient_adult_knee_satisfaction_instructions_3":"راض بعض الشيء",
  "patient_adult_knee_satisfaction_instructions_4":"راض جدا",
  "patient_adult_knee_satisfaction_q1":"الإجمالي",
  "patient_adult_knee_satisfaction_q1_not_applicable":"غير قابل للتطبيق",
  "patient_adult_knee_satisfaction_q1_a1":"غير راض تماماً",
  "patient_adult_knee_satisfaction_q1_a2":"غير راض بعض الشيء",
  "patient_adult_knee_satisfaction_q1_a3":"راض بعض الشيء",
  "patient_adult_knee_satisfaction_q1_a4":"راض جدا",
  "patient_adult_knee_satisfaction_q2":"لتحسين ألمك؟",
  "patient_adult_knee_satisfaction_q2_not_applicable":"غير قابل للتطبيق",
  "patient_adult_knee_satisfaction_q2_a1":"غير راض تماماً",
  "patient_adult_knee_satisfaction_q2_a2":"غير راض بعض الشيء",
  "patient_adult_knee_satisfaction_q2_a3":"راض بعض الشيء",
  "patient_adult_knee_satisfaction_q2_a4":"راض جدا",
  "patient_adult_knee_satisfaction_q3":"لتحسين قدرتك على القيام بالأعمال المنزلية أو الحديقة؟",
  "patient_adult_knee_satisfaction_q3_not_applicable":"غير قابل للتطبيق",
  "patient_adult_knee_satisfaction_q3_a1":"غير راض تماماً",
  "patient_adult_knee_satisfaction_q3_a2":"غير راض بعض الشيء",
  "patient_adult_knee_satisfaction_q3_a3":"راض بعض الشيء",
  "patient_adult_knee_satisfaction_q3_a4":"راض جدا",
  "patient_adult_knee_satisfaction_q4":"لتحسين قدرتك على القيام بالأنشطة الترفيهية؟",
  "patient_adult_knee_satisfaction_q4_not_applicable":"غير قابل للتطبيق",
  "patient_adult_knee_satisfaction_q4_a1":"غير راض تماماً",
  "patient_adult_knee_satisfaction_q4_a2":"غير راض بعض الشيء",
  "patient_adult_knee_satisfaction_q4_a3":"راض بعض الشيء",
  "patient_adult_knee_satisfaction_q4_a4":"راض جدا",
  "patient_adult_knee_complete":"نهاية الاستبيان",
  "patient_adult_knee_complete_text1":"شكرًا لك على الوقت الذي أمضيته في ملء هذا النموذج.",
  "patient_adult_knee_complete_text2":"الرجاء النقر فوق إرسال لإكمال ورؤية درجاتك.",
  "patient_adult_knee_complete_text3":"سيتم التعامل مع إجاباتك بسرية ولن تستخدم أي تقارير سوى معلومات مجهولة الهوية.",
  "patient_adult_knee_results":"النتائج",
  "patient_adult_knee_results_text1":"فيما يلي درجات الألم ووظيفة الركبة ، والتي يتم حسابها من الاستبيان الخاص بك.",
  "patient_adult_knee_results_pain":"الألم",
  "patient_adult_knee_results_function":"الوظيفة",
  "patient_adult_knee_results_health_perception":"التصور الصحي العام",
  "patient_adult_knee_results_self_evaluation":"التقييم الذاتي للركبة",
  "patient_adult_knee_results_text2":"سيكون الطبيب معك قريبًا",
  "patient_adolescent_knee_pain":"الألم",
  "patient_adolescent_knee_function_q23":"الذهاب للتسوق",
  "patient_adolescent_knee_function_q23_a1":"لا توجد صعوبة",
  "patient_adolescent_knee_function_q23_a2":"صعوبة طفيفة",
  "patient_adolescent_knee_function_q23_a3":"صعوبة متوسطة",
  "patient_adolescent_knee_function_q23_a4":"صعوبة بالغة",
  "patient_adolescent_knee_function_q23_a5":"غير قادر على القيام به",
  "patient_pedia_knee_pain":"الألم",
  "patient_pedia_knee_pain_q1":"خلال الأربعة أسابيع الماضية أو منذ إصابتك، كم مرة شعرت بألم في ركبتك المصابة؟",
  "patient_pedia_knee_pain_q1_a1":"لم يؤلمني أبداً",
  "patient_pedia_knee_pain_q1_a2":"نادراً",
  "patient_pedia_knee_pain_q1_a3":"أحيانًا",
  "patient_pedia_knee_pain_q1_a4":"غالبًا ما",
  "patient_pedia_knee_pain_q1_a5":"ثابت",
  "patient_pedia_knee_pain_q2":"مدى شدة ألم ركبتك المصابة اليوم؟",
  "patient_pedia_knee_pain_q2_a1":"لا يوجد أي ألم على الإطلاق",
  "patient_pedia_knee_pain_q2_a2":"يؤلمني كثيرا لا أستطيع تحمله",
  "patient_pedia_knee_pain_instructions":"كم كانت شدة الألم في ركبتك خلال الأسبوع الماضي أثناء ممارسة الأنشطة التالية؟",
  "patient_pedia_knee_pain_instructions_0":"لا ألم",
  "patient_pedia_knee_pain_instructions_1":"ألم قليل",
  "patient_pedia_knee_pain_instructions_2":"بعض الألم",
  "patient_pedia_knee_pain_instructions_3":"كثير من الألم",
  "patient_pedia_knee_pain_instructions_4":"ألم شديد",
  "patient_pedia_knee_pain_note":"ملاحظة: يرجى الإجابة على الأسئلة التالية المتعلقة بأنشطة ركبتك.",
  "patient_pedia_knee_pain_q3":"التواء / دوران الركبة عند المشي / الوقوف / الجري",
  "patient_pedia_knee_pain_q3_a1":"لا ألم",
  "patient_pedia_knee_pain_q3_a2":"ألم قليل",
  "patient_pedia_knee_pain_q3_a3":"بعض الألم",
  "patient_pedia_knee_pain_q3_a4":"كثير من الألم",
  "patient_pedia_knee_pain_q3_a5":"ألم شديد",
  "patient_pedia_knee_pain_q4":"تمام استقامة الركبة",
  "patient_pedia_knee_pain_q4_a1":"لا ألم",
  "patient_pedia_knee_pain_q4_a2":"ألم قليل",
  "patient_pedia_knee_pain_q4_a3":"بعض الألم",
  "patient_pedia_knee_pain_q4_a4":"كثير من الألم",
  "patient_pedia_knee_pain_q4_a5":"ألم شديد",
  "patient_pedia_knee_pain_q5":"تمام انحناء الركبة",
  "patient_pedia_knee_pain_q5_a1":"لا ألم",
  "patient_pedia_knee_pain_q5_a2":"ألم قليل",
  "patient_pedia_knee_pain_q5_a3":"بعض الألم",
  "patient_pedia_knee_pain_q5_a4":"كثير من الألم",
  "patient_pedia_knee_pain_q5_a5":"ألم شديد",
  "patient_pedia_knee_pain_q6":"صعود السلالم",
  "patient_pedia_knee_pain_q6_a1":"لا ألم",
  "patient_pedia_knee_pain_q6_a2":"ألم قليل",
  "patient_pedia_knee_pain_q6_a3":"بعض الألم",
  "patient_pedia_knee_pain_q6_a4":"كثير من الألم",
  "patient_pedia_knee_pain_q6_a5":"ألم شديد",
  "patient_pedia_knee_pain_q7":"نزول السلالم",
  "patient_pedia_knee_pain_q7_a1":"لا ألم",
  "patient_pedia_knee_pain_q7_a2":"ألم قليل",
  "patient_pedia_knee_pain_q7_a3":"بعض الألم",
  "patient_pedia_knee_pain_q7_a4":"كثير من الألم",
  "patient_pedia_knee_pain_q7_a5":"ألم شديد",
  "patient_pedia_knee_pain_q8":"الجلوس مع إنحناء الركبة",
  "patient_pedia_knee_pain_q8_a1":"لا ألم",
  "patient_pedia_knee_pain_q8_a2":"ألم قليل",
  "patient_pedia_knee_pain_q8_a3":"بعض الألم",
  "patient_pedia_knee_pain_q8_a4":"كثير من الألم",
  "patient_pedia_knee_pain_q8_a5":"ألم شديد",
  "patient_pedia_knee_pain_q9":"الوقوف منتصبًا على كلا الساقين لأي فترة من الوقت",
  "patient_pedia_knee_pain_q9_a1":"لا ألم",
  "patient_pedia_knee_pain_q9_a2":"ألم قليل",
  "patient_pedia_knee_pain_q9_a3":"بعض الألم",
  "patient_pedia_knee_pain_q9_a4":"كثير من الألم",
  "patient_pedia_knee_pain_q9_a5":"ألم شديد",
  "patient_pedia_knee_other_symptoms":"أعراض أخرى",
  "patient_pedia_knee_other_symptoms_instruction1":"كم مرة شعرت بالأعراض التالية",
  "patient_pedia_knee_other_symptoms_instruction1_0":"أبداً",
  "patient_pedia_knee_other_symptoms_instruction1_1":"نادراً",
  "patient_pedia_knee_other_symptoms_instruction1_2":"أحيانًا",
  "patient_pedia_knee_other_symptoms_instruction1_3":"غالبًا ما",
  "patient_pedia_knee_other_symptoms_instruction1_4":"دائمًا",
  "patient_pedia_knee_other_symptoms_q1":"سماع أي أصوات من الركبة",
  "patient_pedia_knee_other_symptoms_q1_a1":"أبداً",
  "patient_pedia_knee_other_symptoms_q1_a2":"نادراً",
  "patient_pedia_knee_other_symptoms_q1_a3":"أحيانًا",
  "patient_pedia_knee_other_symptoms_q1_a4":"غالبًا ما",
  "patient_pedia_knee_other_symptoms_q1_a5":"دائمًا",
  "patient_pedia_knee_other_symptoms_q2":"عدم القدرة على ثني الركبة أو تحريكها من مكانها",
  "patient_pedia_knee_other_symptoms_q2_a1":"أبداً",
  "patient_pedia_knee_other_symptoms_q2_a2":"نادراً",
  "patient_pedia_knee_other_symptoms_q2_a3":"أحيانًا",
  "patient_pedia_knee_other_symptoms_q2_a4":"غالبًا ما",
  "patient_pedia_knee_other_symptoms_q2_a5":"دائمًا",
  "patient_pedia_knee_other_symptoms_q3":"الشعور بأن الركبة تتعلق أو تعلق لكن يمكنك تحريكها",
  "patient_pedia_knee_other_symptoms_q3_a1":"أبداً",
  "patient_pedia_knee_other_symptoms_q3_a2":"نادراً",
  "patient_pedia_knee_other_symptoms_q3_a3":"أحيانًا",
  "patient_pedia_knee_other_symptoms_q3_a4":"غالبًا ما",
  "patient_pedia_knee_other_symptoms_q3_a5":"دائمًا",
  "patient_pedia_knee_other_symptoms_q4":"تورّم الركبة",
  "patient_pedia_knee_other_symptoms_q4_a1":"أبداً",
  "patient_pedia_knee_other_symptoms_q4_a2":"نادراً",
  "patient_pedia_knee_other_symptoms_q4_a3":"أحيانًا",
  "patient_pedia_knee_other_symptoms_q4_a4":"غالبًا ما",
  "patient_pedia_knee_other_symptoms_q4_a5":"دائمًا",
  "patient_pedia_knee_other_symptoms_q5":"كم كانت ركبتك منتفخة؟",
  "patient_pedia_knee_other_symptoms_q5_a1":"ليس منتفخ على الإطلاق",
  "patient_pedia_knee_other_symptoms_q5_a2":"إنتفاخ طفيف",
  "patient_pedia_knee_other_symptoms_q5_a3":"منتفخ إلى حد ما",
  "patient_pedia_knee_other_symptoms_q5_a4":"منتفخ جدا",
  "patient_pedia_knee_other_symptoms_q5_a5":"منتفخ للغاية",
  "patient_pedia_knee_other_symptoms_instruction2":"إذا طُلب منك القيام بالأنشطة أدناه ، فما هو أقصى ما يمكنك فعله اليوم ...",
  "patient_pedia_knee_other_symptoms_instruction2_0":"الأنشطة الشاقة جدًا مثل القفز أو الالتفاف بسرعة لتغيير الاتجاه مثل السلة أو كرة القدم",
  "patient_pedia_knee_other_symptoms_instruction2_1":"الأنشطة الشاقة مثل الرفع الثقيل، التزلج أو التنس",
  "patient_pedia_knee_other_symptoms_instruction2_2":"الأنشطة المتوسطة الشدة مثل المشي بسرعة أو الجري",
  "patient_pedia_knee_other_symptoms_instruction2_3":"الأنشطة الخفيفة مثل المشي بسرعة عادية",
  "patient_pedia_knee_other_symptoms_instruction2_4":"لا يمكنني القيام بأي من الأنشطة المذكورة أعلاه",
  "patient_pedia_knee_other_symptoms_q6":"دون إحداث ألم شديد لركبتك",
  "patient_pedia_knee_other_symptoms_q6_a1":"أنشطة شديدة جداً",
  "patient_pedia_knee_other_symptoms_q6_a2":"أنشطة شاقة",
  "patient_pedia_knee_other_symptoms_q6_a3":"أنشطة متوسطة الصعوبة",
  "patient_pedia_knee_other_symptoms_q6_a4":"أنشطة خفيفة",
  "patient_pedia_knee_other_symptoms_q6_a5":"لا يمكنني القيام بأي من الأنشطة المذكورة",
  "patient_pedia_knee_other_symptoms_q7":"دون إحداث ألم شديد لركبتك",
  "patient_pedia_knee_other_symptoms_q7_a1":"أنشطة شديدة جداً",
  "patient_pedia_knee_other_symptoms_q7_a2":"أنشطة شاقة",
  "patient_pedia_knee_other_symptoms_q7_a3":"أنشطة متوسطة الصعوبة",
  "patient_pedia_knee_other_symptoms_q7_a4":"أنشطة خفيفة",
  "patient_pedia_knee_other_symptoms_q7_a5":"لا يمكنني القيام بأي من الأنشطة المذكورة",
  "patient_pedia_knee_other_symptoms_q8":"من دون شعور بأن ركبتك لا تستطيع حملك",
  "patient_pedia_knee_other_symptoms_q8_a1":"أنشطة شديدة جداً",
  "patient_pedia_knee_other_symptoms_q8_a2":"أنشطة شاقة",
  "patient_pedia_knee_other_symptoms_q8_a3":"أنشطة متوسطة الصعوبة",
  "patient_pedia_knee_other_symptoms_q8_a5":"لا يمكنني القيام بأي من الأنشطة المذكورة",
  "patient_pedia_knee_other_symptoms_q9":"معظم الوقت",
  "patient_pedia_knee_other_symptoms_q9_a1":"أنشطة شديدة جداً",
  "patient_pedia_knee_other_symptoms_q9_a2":"الأنشطة الشاقة",
  "patient_pedia_knee_other_symptoms_q9_a3":"نوع من الأنشطة الصعبة",
  "patient_pedia_knee_other_symptoms_q9_a4":"الأنشطة الخفيفة",
  "patient_pedia_knee_other_symptoms_q9_a5":"لا أستطيع أن أفعل أيًا مما سبق",
  "patient_pedia_knee_function":"الوظيفة",
  "patient_pedia_knee_function_instruction":"ما مدى صعوبة القيام بما يلي بسبب ركبتك:",
  "patient_pedia_knee_function_instruction_0":"لا توجد صعوبة",
  "patient_pedia_knee_function_instruction_1":"قليلًا",
  "patient_pedia_knee_function_instruction_2":"بعض الشيء",
  "patient_pedia_knee_function_instruction_3":"كثيرًا",
  "patient_pedia_knee_function_instruction_4":"صعوبة بالغة / لا تستطيع القيام بذلك",
  "patient_pedia_knee_function_note":"ملاحظة: إذا لم تقم بنشاط معين ، فتخيل كيف ستشعر ركبتك إذا كان عليك تجربتها",
  "patient_pedia_knee_function_q1":"الصعود على الدرج",
  "patient_pedia_knee_function_q1_a1":"لا توجد صعوبة",
  "patient_pedia_knee_function_q1_a2":"قليلًا",
  "patient_pedia_knee_function_q1_a3":"بعض الشيء",
  "patient_pedia_knee_function_q1_a4":"كثيرًا",
  "patient_pedia_knee_function_q1_a5":"صعوبة بالغة / لا تستطيع القيام بذلك",
  "patient_pedia_knee_function_q2":"النزول على الدرج",
  "patient_pedia_knee_function_q2_a1":"لا توجد صعوبة",
  "patient_pedia_knee_function_q2_a2":"قليلًا",
  "patient_pedia_knee_function_q2_a3":"بعض الشيء",
  "patient_pedia_knee_function_q2_a4":"كثيرًا",
  "patient_pedia_knee_function_q2_a5":"صعوبة بالغة / لا تستطيع القيام بذلك",
  "patient_pedia_knee_function_q3":"الركوع على الركبة الأمامية",
  "patient_pedia_knee_function_q3_a1":"لا توجد صعوبة",
  "patient_pedia_knee_function_q3_a2":"قليلًا",
  "patient_pedia_knee_function_q3_a3":"بعض الشيء",
  "patient_pedia_knee_function_q3_a4":"كثيرًا",
  "patient_pedia_knee_function_q3_a5":"صعوبة بالغة / لا تستطيع القيام بذلك",
  "patient_pedia_knee_function_q4":"تمد ركبتك تمامًا",
  "patient_pedia_knee_function_q4_a1":"لا توجد صعوبة",
  "patient_pedia_knee_function_q4_a2":"قليلًا",
  "patient_pedia_knee_function_q4_a3":"بعض الشيء",
  "patient_pedia_knee_function_q4_a4":"كثيرًا",
  "patient_pedia_knee_function_q4_a5":"صعوبة بالغة / لا تستطيع القيام بذلك",
  "patient_pedia_knee_function_q5":"تجعل ركبتك تنحني تمامًا ",
  "patient_pedia_knee_function_q5_a1":"لا توجد صعوبة",
  "patient_pedia_knee_function_q5_a2":"قليلًا",
  "patient_pedia_knee_function_q5_a3":"بعض الشيء",
  "patient_pedia_knee_function_q5_a4":"كثيرًا",
  "patient_pedia_knee_function_q5_a5":"صعوبة بالغة / لا تستطيع القيام بذلك",
  "patient_pedia_knee_function_q6":"تحرك ركبتك فور الاستيقاظ في الصباح",
  "patient_pedia_knee_function_q6_a1":"لا توجد صعوبة",
  "patient_pedia_knee_function_q6_a2":"قليلًا",
  "patient_pedia_knee_function_q6_a3":"بعض الشيء",
  "patient_pedia_knee_function_q6_a4":"كثيرًا",
  "patient_pedia_knee_function_q6_a5":"صعوبة بالغة / لا تستطيع القيام بذلك",
  "patient_pedia_knee_function_q7":"تحرك ركبتك في وقت متأخر من اليوم بعد أن تكون كنت في وضع الجلوس لفترة من الوقت",
  "patient_pedia_knee_function_q7_a1":"لا توجد صعوبة",
  "patient_pedia_knee_function_q7_a2":"قليلًا",
  "patient_pedia_knee_function_q7_a3":"بعض الشيء",
  "patient_pedia_knee_function_q7_a4":"كثيرًا",
  "patient_pedia_knee_function_q7_a5":"صعوبة بالغة / لا تستطيع القيام بذلك",
  "patient_pedia_knee_function_q8":"انخفاض ركبتك مثل لاعب بيسبول يلتقط الكرة",
  "patient_pedia_knee_function_q8_a1":"لا توجد صعوبة",
  "patient_pedia_knee_function_q8_a2":"قليلًا",
  "patient_pedia_knee_function_q8_a3":"بعض الشيء",
  "patient_pedia_knee_function_q8_a4":"كثيرًا",
  "patient_pedia_knee_function_q8_a5":"صعوبة بالغة / لا تستطيع القيام بذلك",
  "patient_pedia_knee_function_q9":"الجلوس على كرسي بركبة مثنية وأقدام مسطحة على الأرض",
  "patient_pedia_knee_function_q9_a1":"لا توجد صعوبة",
  "patient_pedia_knee_function_q9_a2":"قليلًا",
  "patient_pedia_knee_function_q9_a3":"بعض الشيء",
  "patient_pedia_knee_function_q9_a4":"كثيرًا",
  "patient_pedia_knee_function_q9_a5":"صعوبة بالغة / لا تستطيع القيام بذلك",
  "patient_pedia_knee_function_q10":"النهوض من كرسي",
  "patient_pedia_knee_function_q10_a1":"لا توجد صعوبة",
  "patient_pedia_knee_function_q10_a2":"قليلًا",
  "patient_pedia_knee_function_q10_a3":"بعض الشيء",
  "patient_pedia_knee_function_q10_a4":"كثيرًا",
  "patient_pedia_knee_function_q10_a5":"صعوبة بالغة / لا تستطيع القيام بذلك",
  "patient_pedia_knee_function_q11":"انحنى والتقط شيئًا من الأرض",
  "patient_pedia_knee_function_q11_a1":"لا توجد صعوبة",
  "patient_pedia_knee_function_q11_a2":"قليلًا",
  "patient_pedia_knee_function_q11_a3":"بعض الشيء",
  "patient_pedia_knee_function_q11_a4":"كثيرًا",
  "patient_pedia_knee_function_q11_a5":"صعوبة بالغة / لا تستطيع القيام بذلك",
  "patient_pedia_knee_function_q12":"الجري خلال الأنشطة الترفيهية أو الرياضية",
  "patient_pedia_knee_function_q12_a1":"لا توجد صعوبة",
  "patient_pedia_knee_function_q12_a2":"قليلًا",
  "patient_pedia_knee_function_q12_a3":"بعض الشيء",
  "patient_pedia_knee_function_q12_a4":"كثيرًا",
  "patient_pedia_knee_function_q12_a5":"صعوبة بالغة / لا تستطيع القيام بذلك",
  "patient_pedia_knee_function_q13":"القفز خلال الأنشطة الترفيهية أو الرياضية",
  "patient_pedia_knee_function_q13_a1":"لا توجد صعوبة",
  "patient_pedia_knee_function_q13_a2":"قليلًا",
  "patient_pedia_knee_function_q13_a3":"بعض الشيء",
  "patient_pedia_knee_function_q13_a4":"كثيرًا",
  "patient_pedia_knee_function_q13_a5":"صعوبة بالغة / لا تستطيع القيام بذلك",
  "patient_pedia_knee_function_q14":"البدء والتوقف عن الحركة بسرعة",
  "patient_pedia_knee_function_q14_a1":"لا توجد صعوبة",
  "patient_pedia_knee_function_q14_a2":"قليلًا",
  "patient_pedia_knee_function_q14_a3":"بعض الشيء",
  "patient_pedia_knee_function_q14_a4":"كثيرًا",
  "patient_pedia_knee_function_q14_a5":"صعوبة بالغة / لا تستطيع القيام بذلك",
  "patient_pedia_knee_function_q15":"الدوران / الدوران الزاوي خلال الأنشطة الترفيهية أو الرياضية",
  "patient_pedia_knee_function_q15_a1":"لا توجد صعوبة",
  "patient_pedia_knee_function_q15_a2":"قليلًا",
  "patient_pedia_knee_function_q15_a3":"بعض الشيء",
  "patient_pedia_knee_function_q15_a4":"كثيرًا",
  "patient_pedia_knee_function_q15_a5":"صعوبة بالغة / لا تستطيع القيام بذلك",
  "patient_pedia_knee_function_q16":"الحفاظ على توازنك عند المشي / الجري على أرض غير مستوية",
  "patient_pedia_knee_function_q16_a1":"لا توجد صعوبة",
  "patient_pedia_knee_function_q16_a2":"قليلًا",
  "patient_pedia_knee_function_q16_a3":"بعض الشيء",
  "patient_pedia_knee_function_q16_a4":"كثيرًا",
  "patient_pedia_knee_function_q16_a5":"صعوبة بالغة / لا تستطيع القيام بذلك",
  "patient_pedia_knee_function_q17":"ممارسة الرياضة",
  "patient_pedia_knee_function_q17_a1":"لا توجد صعوبة",
  "patient_pedia_knee_function_q17_a2":"قليلًا",
  "patient_pedia_knee_function_q17_a3":"بعض الشيء",
  "patient_pedia_knee_function_q17_a4":"كثيرًا",
  "patient_pedia_knee_function_q17_a5":"صعوبة بالغة / لا تستطيع القيام بذلك",
  "patient_pedia_knee_function_q18":" الدخول إلى / الخروج من السيارة",
  "patient_pedia_knee_function_q18_a1":"لا توجد صعوبة",
  "patient_pedia_knee_function_q18_a2":"قليلًا",
  "patient_pedia_knee_function_q18_a3":"بعض الشيء",
  "patient_pedia_knee_function_q18_a4":"كثيرًا",
  "patient_pedia_knee_function_q18_a5":"صعوبة بالغة / لا تستطيع القيام بذلك",
  "patient_pedia_knee_function_q19":"دخول السيارة / الخروج منها",
  "patient_pedia_knee_function_q19_a1":"لا توجد صعوبة",
  "patient_pedia_knee_function_q19_a2":"قليلًا",
  "patient_pedia_knee_function_q19_a3":"بعض الشيء",
  "patient_pedia_knee_function_q19_a4":"كثيرًا",
  "patient_pedia_knee_function_q19_a5":"صعوبة بالغة / لا تستطيع القيام بذلك",
  "patient_pedia_knee_function_q20":"تغيير وضعية الركبة عند الاستلقاء في الفراش",
  "patient_pedia_knee_function_q20_a1":"لا توجد صعوبة",
  "patient_pedia_knee_function_q20_a2":"قليلًا",
  "patient_pedia_knee_function_q20_a3":"بعض الشيء",
  "patient_pedia_knee_function_q20_a4":"كثيرًا",
  "patient_pedia_knee_function_q20_a5":"صعوبة بالغة / لا تستطيع القيام بذلك",
  "patient_pedia_knee_function_q21":"الصعود إلى / الخروج من حوض الاستحمام / الدوش",
  "patient_pedia_knee_function_q21_a1":"لا توجد صعوبة",
  "patient_pedia_knee_function_q21_a2":"قليلًا",
  "patient_pedia_knee_function_q21_a3":"بعض الشيء",
  "patient_pedia_knee_function_q21_a4":"كثيرًا",
  "patient_pedia_knee_function_q21_a5":"صعوبة بالغة / لا تستطيع القيام بذلك",
  "patient_pedia_knee_function_q22":"حمل الحقائب / الحقائب الثقيلة و حقائب الظهر وما إلى ذلك",
  "patient_pedia_knee_function_q22_a1":"لا توجد صعوبة",
  "patient_pedia_knee_function_q22_a2":"قليلًا",
  "patient_pedia_knee_function_q22_a3":"بعض الشيء",
  "patient_pedia_knee_function_q22_a4":"كثيرًا",
  "patient_pedia_knee_function_q22_a5":"صعوبة بالغة / لا تستطيع القيام بذلك",
  "patient_pedia_knee_function_q23":"انجز المهام الخفيفة مثل تنظيف غرفتك، تحميل/تفريغ غسالة الصحون، وترتيب سريرك إلخ",
  "patient_pedia_knee_function_q23_a1":"لا توجد صعوبة",
  "patient_pedia_knee_function_q23_a2":"قليلًا",
  "patient_pedia_knee_function_q23_a3":"بعض الشيء",
  "patient_pedia_knee_function_q23_a4":"كثيرًا",
  "patient_pedia_knee_function_q23_a5":"صعوبة بالغة / لا تستطيع القيام بذلك",
  "patient_pedia_knee_function_q24":"الذهاب إلى المدرسة أو المشي فيها (تسلق السلالم، فتح الأبواب، حمل الكتب، المشاركة في الاستراحة)",
  "patient_pedia_knee_function_q24_a1":"لا توجد صعوبة",
  "patient_pedia_knee_function_q24_a2":"قليلًا",
  "patient_pedia_knee_function_q24_a3":"بعض الشيء",
  "patient_pedia_knee_function_q24_a4":"كثيرًا",
  "patient_pedia_knee_function_q24_a5":"صعوبة بالغة / لا تستطيع القيام بذلك",
  "patient_pedia_knee_function_q25":"تفعل الأشياء مع الأصدقاء",
  "patient_pedia_knee_function_q25_a1":"لا توجد صعوبة",
  "patient_pedia_knee_function_q25_a2":"قليلًا",
  "patient_pedia_knee_function_q25_a3":"بعض الشيء",
  "patient_pedia_knee_function_q25_a4":"كثيرًا",
  "patient_pedia_knee_function_q25_a5":"صعوبة بالغة / لا تستطيع القيام بذلك",
  "patient_pedia_knee_function_q26":"ما مدى جودة عمل ركبتك قبل إصابتك بها؟",
  "patient_pedia_knee_function_q26_instruction":"10 تكون طبيعية و 0 هي عدم القدرة على أداء أي من أنشطتك اليومية المعتادة التي قد تشمل الرياضة",
  "patient_pedia_knee_function_q26_instruction_a1":"لم أستطع فعل أي شيء على الإطلاق",
  "patient_pedia_knee_function_q26_instruction_a2":"يمكنني فعل أي شيء أريده ",
  "patient_pedia_knee_function_q27":"ما مدى جودة عمل ركبتك الآن؟",
  "patient_pedia_knee_function_q27_instruction":"10 تكون طبيعية و 0 هي عدم القدرة على أداء أي من أنشطتك اليومية المعتادة التي قد تشمل الرياضة",
  "patient_pedia_knee_function_q27_instruction_a1":"لا أستطيع فعل أي شيء على الإطلاق",
  "patient_pedia_knee_function_q27_instruction_a2":"أنا قادر على فعل أي شيء أريد القيام به",
  "patient_pedia_knee_function_q28":"هل تنزلق الركبة الخاصة بك أو تشعر وكأنها ستنزلق من مكانها؟",
  "patient_pedia_knee_function_q28_a1":"لا",
  "patient_pedia_knee_function_q28_a2":"نعم",
  "patient_pedia_knee_instability":"عدم استقرار الرضفة الفخذية",
  "patient_pedia_knee_instability_instruction":"ما هو أفضل وصف لمدى شعور ركبتك وكأنها \"ستخرج\" من المفصل أو تشعر بعدم الاستقرار عندما تقوم بكل من الأنشطة التالية",
  "patient_pedia_knee_instability_instruction_0":"أبداً",
  "patient_pedia_knee_instability_instruction_1":"نادراً",
  "patient_pedia_knee_instability_instruction_2":"أحيانًا",
  "patient_pedia_knee_instability_instruction_3":"غالبًا ما",
  "patient_pedia_knee_instability_instruction_4":"دائمًا",
  "patient_pedia_knee_instability_note":"ملاحظة: يرجى الإجابة على الأسئلة التالية المتعلقة بأنشطة ركبتك",
  "patient_pedia_knee_instability_q1":" التواء / تغيير الاتجاه أثناء الرياضة / الألعاب",
  "patient_pedia_knee_instability_q1_do_not_do":"لا يتم القيام به",
  "patient_pedia_knee_instability_q1_a1":"أبداً",
  "patient_pedia_knee_instability_q1_a2":"نادراً",
  "patient_pedia_knee_instability_q1_a3":"أحيانًا",
  "patient_pedia_knee_instability_q1_a4":"غالبًا ما",
  "patient_pedia_knee_instability_q1_a5":"دائمًا",
  "patient_pedia_knee_instability_q2":"تغيير الاتجاه عند الركض",
  "patient_pedia_knee_instability_q2_do_not_do":"لا يتم القيام به",
  "patient_pedia_knee_instability_q2_a1":"أبداً",
  "patient_pedia_knee_instability_q2_a2":"نادراً",
  "patient_pedia_knee_instability_q2_a3":"أحيانًا",
  "patient_pedia_knee_instability_q2_a4":"غالبًا ما",
  "patient_pedia_knee_instability_q2_a5":"دائمًا",
  "patient_pedia_knee_instability_q3":"الركض في خط مستقيم على سطوح غير مستوية ",
  "patient_pedia_knee_instability_q3_do_not_do":"لا يتم القيام به",
  "patient_pedia_knee_instability_q3_a1":"أبداً",
  "patient_pedia_knee_instability_q3_a2":"نادراً",
  "patient_pedia_knee_instability_q3_a3":"أحيانًا",
  "patient_pedia_knee_instability_q3_a4":"غالبًا ما",
  "patient_pedia_knee_instability_q3_a5":"دائمًا",
  "patient_pedia_knee_instability_q4":"المشي على سطوح زلقة أو مبللة أو متجمدة",
  "patient_pedia_knee_instability_q4_do_not_do":"لا يتم القيام به",
  "patient_pedia_knee_instability_q4_a1":"أبداً",
  "patient_pedia_knee_instability_q4_a2":"نادراً",
  "patient_pedia_knee_instability_q4_a3":"أحيانًا",
  "patient_pedia_knee_instability_q4_a4":"غالبًا ما",
  "patient_pedia_knee_instability_q4_a5":"دائمًا",
  "patient_pedia_knee_instability_q5":"الجري بشكل جانبي",
  "patient_pedia_knee_instability_q5_do_not_do":"لا يتم القيام به",
  "patient_pedia_knee_instability_q5_a1":"أبداً",
  "patient_pedia_knee_instability_q5_a2":"نادراً",
  "patient_pedia_knee_instability_q5_a3":"أحيانًا",
  "patient_pedia_knee_instability_q5_a4":"غالبًا ما",
  "patient_pedia_knee_instability_q5_a5":"دائمًا",
  "patient_pedia_knee_instability_q6":"القفز على ساق واحدة",
  "patient_pedia_knee_instability_q6_do_not_do":"لا يتم القيام به",
  "patient_pedia_knee_instability_q6_a1":"أبداً",
  "patient_pedia_knee_instability_q6_a2":"نادراً",
  "patient_pedia_knee_instability_q6_a3":"أحيانًا",
  "patient_pedia_knee_instability_q6_a4":"غالبًا ما",
  "patient_pedia_knee_instability_q6_a5":"دائمًا",
  "patient_pedia_knee_instability_q7":"القفز",
  "patient_pedia_knee_instability_q7_do_not_do":"لا يتم القيام به",
  "patient_pedia_knee_instability_q7_a1":"أبداً",
  "patient_pedia_knee_instability_q7_a2":"نادراً",
  "patient_pedia_knee_instability_q7_a3":"أحيانًا",
  "patient_pedia_knee_instability_q7_a4":"غالبًا ما",
  "patient_pedia_knee_instability_q7_a5":"دائمًا",
  "patient_pedia_knee_instability_q8":"الجري في خط مستقيم على سطح مستوٍ",
  "patient_pedia_knee_instability_q8_do_not_do":"لا يتم القيام به",
  "patient_pedia_knee_instability_q8_a1":"أبداً",
  "patient_pedia_knee_instability_q8_a2":"نادراً",
  "patient_pedia_knee_instability_q8_a3":"أحيانًا",
  "patient_pedia_knee_instability_q8_a4":"غالبًا ما",
  "patient_pedia_knee_instability_q8_a5":"دائمًا",
  "patient_pedia_knee_instability_q9":"النزول على الدرج",
  "patient_pedia_knee_instability_q9_do_not_do":"لا يتم القيام به",
  "patient_pedia_knee_instability_q9_a1":"أبداً",
  "patient_pedia_knee_instability_q9_a2":"نادراً",
  "patient_pedia_knee_instability_q9_a3":"أحيانًا",
  "patient_pedia_knee_instability_q9_a4":"غالبًا ما",
  "patient_pedia_knee_instability_q9_a5":"دائمًا",
  "patient_pedia_knee_instability_q10":"القرفصاء",
  "patient_pedia_knee_instability_q10_do_not_do":"لا يتم القيام به",
  "patient_pedia_knee_instability_q10_a1":"أبداً",
  "patient_pedia_knee_instability_q10_a2":"نادراً",
  "patient_pedia_knee_instability_q10_a3":"أحيانًا",
  "patient_pedia_knee_instability_q10_a4":"غالبًا ما",
  "patient_pedia_knee_instability_q10_a5":"دائمًا",
  "patient_pedia_knee_instability_q11":"الركوع",
  "patient_pedia_knee_instability_q11_do_not_do":"لا يتم القيام به",
  "patient_pedia_knee_instability_q11_a1":"أبداً",
  "patient_pedia_knee_instability_q11_a2":"نادراً",
  "patient_pedia_knee_instability_q11_a3":"أحيانًا",
  "patient_pedia_knee_instability_q11_a4":"غالبًا ما",
  "patient_pedia_knee_instability_q11_a5":"دائمًا",
  "patient_pedia_knee_instability_q12":"المشي في خط مستقيم على سطح غير مستوٍ",
  "patient_pedia_knee_instability_q12_do_not_do":"لا يتم القيام به",
  "patient_pedia_knee_instability_q12_a1":"أبداً",
  "patient_pedia_knee_instability_q12_a2":"نادراً",
  "patient_pedia_knee_instability_q12_a3":"أحيانًا",
  "patient_pedia_knee_instability_q12_a4":"غالبًا ما",
  "patient_pedia_knee_instability_q12_a5":"دائمًا",
  "patient_pedia_knee_instability_q13":"تسلق الدرج",
  "patient_pedia_knee_instability_q13_do_not_do":"لا يتم القيام به",
  "patient_pedia_knee_instability_q13_a1":"أبداً",
  "patient_pedia_knee_instability_q13_a2":"نادراً",
  "patient_pedia_knee_instability_q13_a3":"أحيانًا",
  "patient_pedia_knee_instability_q13_a4":"غالبًا ما",
  "patient_pedia_knee_instability_q13_a5":"دائمًا",
  "patient_pedia_knee_instability_q14":"الصعود إلى مستوى عالٍ أو تجاوزه",
  "patient_pedia_knee_instability_q14_do_not_do":"لا يتم القيام به",
  "patient_pedia_knee_instability_q14_a1":"أبداً",
  "patient_pedia_knee_instability_q14_a2":"نادراً",
  "patient_pedia_knee_instability_q14_a3":"أحيانًا",
  "patient_pedia_knee_instability_q14_a4":"غالبًا ما",
  "patient_pedia_knee_instability_q14_a5":"دائمًا",
  "patient_pedia_knee_instability_q15":"تقاطع الساقين أثناء الجلوس",
  "patient_pedia_knee_instability_q15_do_not_do":"لا يتم القيام به",
  "patient_pedia_knee_instability_q15_a1":"أبداً",
  "patient_pedia_knee_instability_q15_a2":"نادراً",
  "patient_pedia_knee_instability_q15_a3":"أحيانًا",
  "patient_pedia_knee_instability_q15_a4":"غالبًا ما",
  "patient_pedia_knee_instability_q15_a5":"دائمًا",
  "patient_pedia_knee_instability_q16":"المشي في خط مستقيم على سطح مستوٍ",
  "patient_pedia_knee_instability_q16_do_not_do":"لا يتم القيام به",
  "patient_pedia_knee_instability_q16_a1":"أبداً",
  "patient_pedia_knee_instability_q16_a2":"نادراً",
  "patient_pedia_knee_instability_q16_a3":"أحيانًا",
  "patient_pedia_knee_instability_q16_a4":"غالبًا ما",
  "patient_pedia_knee_instability_q16_a5":"دائمًا",
  "patient_pedia_knee_instability_q17":"الدخول أو الخروج من السيارة",
  "patient_pedia_knee_instability_q17_do_not_do":"لا يتم القيام به",
  "patient_pedia_knee_instability_q17_a1":"أبداً",
  "patient_pedia_knee_instability_q17_a2":"نادراً",
  "patient_pedia_knee_instability_q17_a3":"أحيانًا",
  "patient_pedia_knee_instability_q17_a4":"غالبًا ما",
  "patient_pedia_knee_instability_q17_a5":"دائمًا",
  "patient_pedia_knee_instability_q18":"لف عربة ثقيلة في ممر سوبرماركت",
  "patient_pedia_knee_instability_q18_do_not_do":"لا يتم القيام به",
  "patient_pedia_knee_instability_q18_a1":"أبداً",
  "patient_pedia_knee_instability_q18_a2":"نادراً",
  "patient_pedia_knee_instability_q18_a3":"أحيانًا",
  "patient_pedia_knee_instability_q18_a4":"غالبًا ما",
  "patient_pedia_knee_instability_q18_a5":"دائمًا",
  "patient_pedia_knee_instability_q19":"تستدير  للنظر خلف الكتف ",
  "patient_pedia_knee_instability_q19_do_not_do":"لا يتم القيام به",
  "patient_pedia_knee_instability_q19_a1":"أبداً",
  "patient_pedia_knee_instability_q19_a2":"نادراً",
  "patient_pedia_knee_instability_q19_a3":"أحيانًا",
  "patient_pedia_knee_instability_q19_a4":"غالبًا ما",
  "patient_pedia_knee_instability_q19_a5":"دائمًا",
  "patient_pedia_knee_quality_of_life":"جودة الحياة",
  "patient_pedia_knee_quality_of_life_instruction":"كيف أثرت إصابتك على حياتك؟",
  "patient_pedia_knee_quality_of_life_q1":"كم مرة تفكر في مشكلة ركبتك؟",
  "patient_pedia_knee_quality_of_life_q1_a1":"أبداً",
  "patient_pedia_knee_quality_of_life_q1_a2":"نادراً",
  "patient_pedia_knee_quality_of_life_q1_a3":"أحيانًا",
  "patient_pedia_knee_quality_of_life_q1_a4":"غالبًا ما",
  "patient_pedia_knee_quality_of_life_q1_a5":"طوال الوقت (شعور بالألم دائمًا)",
  "patient_pedia_knee_problem_q2":" ما قد التغيير بنمط حياتك بسبب الإصابة في ركبتك؟",
  "patient_pedia_knee_problem_q2_a1":"مُطْلَقاً",
  "patient_pedia_knee_problem_q2_a2":"قليلًا",
  "patient_pedia_knee_problem_q2_a3":"بعض",
  "patient_pedia_knee_problem_q2_a4":"كثيرًا",
  "patient_pedia_knee_problem_q2_a5":"كثيرا جدا",
  "patient_pedia_knee_problem_q3":"كم تثق في ركبتك المصابة؟",
  "patient_pedia_knee_problem_q3_a1":"بالكامل",
  "patient_pedia_knee_problem_q3_a2":"كثيرًا",
  "patient_pedia_knee_problem_q3_a3":"بعض",
  "patient_pedia_knee_problem_q3_a4":"قليلًا",
  "patient_pedia_knee_problem_q3_a5":"مُطْلَقاً",
  "patient_pedia_knee_problem_q4":" ما قدر الصعوبة تواجهك بشأن ركبتك المصابة؟",
  "patient_pedia_knee_problem_q4_a1":"لا توجد صعوبة",
  "patient_pedia_knee_problem_q4_a2":"قليلًا",
  "patient_pedia_knee_problem_q4_a3":"بعض",
  "patient_pedia_knee_problem_q4_a4":"كثيرًا",
  "patient_pedia_knee_problem_q4_a5":"صعوبة بالغة",
  "patient_pedia_knee_satisfaction":"الرضا",
  "patient_pedia_knee_satisfaction_instruction":"ما مدى رضاك عن نتائج جراحة / علاج الركبة؟",
  "patient_pedia_knee_satisfaction_instruction_a1":"غير راض تماماً",
  "patient_pedia_knee_satisfaction_instruction_a2":"غير راض بعض الشيء",
  "patient_pedia_knee_satisfaction_instruction_a3":"راض بعض الشيء",
  "patient_pedia_knee_satisfaction_instruction_a4":"راض جدا",
  "patient_pedia_knee_satisfaction_q1":"الإجمالي",
  "patient_pedia_knee_satisfaction_q1_NA":"غير قابل للتطبيق",
  "patient_pedia_knee_satisfaction_q1_a1":"غير راض تماماً",
  "patient_pedia_knee_satisfaction_q1_a2":"غير راض بعض الشيء",
  "patient_pedia_knee_satisfaction_q1_a3":"راض بعض الشيء",
  "patient_pedia_knee_satisfaction_q1_a4":"راض جدا",
  "patient_pedia_knee_satisfaction_q2":"لتحسين ألمك؟",
  "patient_pedia_knee_satisfaction_q2_NA":"غير قابل للتطبيق",
  "patient_pedia_knee_satisfaction_q2_a1":"غير راض تماماً",
  "patient_pedia_knee_satisfaction_q2_a2":"غير راض بعض الشيء",
  "patient_pedia_knee_satisfaction_q2_a3":"راض بعض الشيء",
  "patient_pedia_knee_satisfaction_q2_a4":"راض جدا",
  "patient_pedia_knee_satisfaction_q3":"لتحسين قدرتك على القيام بالأعمال المنزلية أو الحديقة؟",
  "patient_pedia_knee_satisfaction_q3_NA":"غير قابل للتطبيق",
  "patient_pedia_knee_satisfaction_q3_a1":"غير راض تماماً",
  "patient_pedia_knee_satisfaction_q3_a2":"غير راض بعض الشيء",
  "patient_pedia_knee_satisfaction_q3_a3":"راض بعض الشيء",
  "patient_pedia_knee_satisfaction_q3_a4":"راض جدا",
  "patient_pedia_knee_satisfaction_q4":"لتحسين قدرتك على القيام بالأنشطة الترفيهية؟",
  "patient_pedia_knee_satisfaction_q4_NA":"غير قابل للتطبيق",
  "patient_pedia_knee_satisfaction_q4_a1":"غير راض تماماً",
  "patient_pedia_knee_satisfaction_q4_a2":"غير راض بعض الشيء",
  "patient_pedia_knee_satisfaction_q4_a3":"راض بعض الشيء",
  "patient_pedia_knee_satisfaction_q4_a4":"راض جدا",
  "patient_pedia_knee_complete":"نهاية الاستبيان",
  "patient_pedia_knee_complete_text1":"شكرًا لك على الوقت الذي أمضيته في ملء هذا النموذج.",
  "patient_pedia_knee_complete_text2":"الرجاء النقر فوق إرسال لإكمال ورؤية درجاتك.",
  "patient_pedia_knee_complete_text3":"سيتم التعامل مع إجاباتك بسرية ولن تستخدم أي تقارير سوى معلومات مجهولة الهوية.",
  "patient_pedia_knee_results":"النتائج",
  "patient_pedia_knee_results_text1":"فيما يلي درجات الألم ووظيفة الركبة ، والتي يتم حسابها من الاستبيان الخاص بك.",
  "patient_pedia_knee_results_pain":"الألم",
  "patient_pedia_knee_results_function":"الوظيفة",
  "patient_pedia_knee_results_self_evaluation":"التقييم الذاتي للركبة",
  "patient_pedia_knee_results_text2":"سيكون الطبيب معك قريبًا",
  "patient_foot_ankle_pain":"الألم",
  "patient_foot_ankle_pain_q1":"ما هو المستوى الحالي للألم من قدمك / كاحلك؟",
  "patient_foot_ankle_pain_q1_0":"لا شيء",
  "patient_foot_ankle_pain_q1_1":"أسوأ ألم يمكن تخيله",
  "patient_foot_ankle_pain_q2":"ما هو أفضل وصف للألم من قدمك / كاحلك؟",
  "patient_foot_ankle_pain_q2_a1":"لا شيء",
  "patient_foot_ankle_pain_q2_a2":"خفيف ، عرضي",
  "patient_foot_ankle_pain_q2_a3":"معتدل يوميا",
  "patient_foot_ankle_pain_q2_a4":"شديد ، دائمًا تقريبًا",
  "patient_foot_ankle_pain_q3":"ما مدى شعورك بألم القدم / الكاحل؟",
  "patient_foot_ankle_pain_q3_a1":"أبداً",
  "patient_foot_ankle_pain_q3_a2":"شهريا",
  "patient_foot_ankle_pain_q3_a3":"أسبوعيا",
  "patient_foot_ankle_pain_q3_a4":"يوميا",
  "patient_foot_ankle_pain_q3_a5":"دائمًا",
  "patient_foot_ankle_pain_instruction":"ما مقدار ألم القدم / الكاحل الذي تعرضت له خلال الأنشطة التالية",
  "patient_foot_ankle_pain_instruction_0":"لا شيء",
  "patient_foot_ankle_pain_instruction_1":"خفيف",
  "patient_foot_ankle_pain_instruction_2":"معتدل",
  "patient_foot_ankle_pain_instruction_3":"شديد",
  "patient_foot_ankle_pain_instruction_4":"أقصى",
  "patient_foot_ankle_pain_q4":"المستوى العام للألم",
  "patient_foot_ankle_pain_q4_a1":"لا شيء",
  "patient_foot_ankle_pain_q4_a2":"خفيف",
  "patient_foot_ankle_pain_q4_a3":"معتدل",
  "patient_foot_ankle_pain_q4_a4":"شديد",
  "patient_foot_ankle_pain_q4_a5":"أقصى",
  "patient_foot_ankle_pain_q5":"في راحه",
  "patient_foot_ankle_pain_q5_a1":"لا شيء",
  "patient_foot_ankle_pain_q5_a2":"خفيف",
  "patient_foot_ankle_pain_q5_a3":"معتدل",
  "patient_foot_ankle_pain_q5_a4":"شديد",
  "patient_foot_ankle_pain_q5_a5":"أقصى",
  "patient_foot_ankle_pain_q6":"أثناء النشاط الطبيعي",
  "patient_foot_ankle_pain_q6_a1":"لا شيء",
  "patient_foot_ankle_pain_q6_a2":"خفيف",
  "patient_foot_ankle_pain_q6_a3":"معتدل",
  "patient_foot_ankle_pain_q6_a4":"شديد",
  "patient_foot_ankle_pain_q6_a5":"أقصى",
  "patient_foot_ankle_pain_q7":"اول شيء في الصباح",
  "patient_foot_ankle_pain_q7_a1":"لا شيء",
  "patient_foot_ankle_pain_q7_a2":"خفيف",
  "patient_foot_ankle_pain_q7_a3":"معتدل",
  "patient_foot_ankle_pain_q7_a4":"شديد",
  "patient_foot_ankle_pain_q7_a5":"أقصى",
  "patient_foot_ankle_pain_q8":"الالتواء / التمحور على قدمك / كاحلك",
  "patient_foot_ankle_pain_q8_a1":"لا شيء",
  "patient_foot_ankle_pain_q8_a2":"خفيف",
  "patient_foot_ankle_pain_q8_a3":"معتدل",
  "patient_foot_ankle_pain_q8_a4":"شديد",
  "patient_foot_ankle_pain_q8_a5":"أقصى",
  "patient_foot_ankle_pain_q9":"استقامة القدم / الكاحل بشكل كامل",
  "patient_foot_ankle_pain_q9_a1":"لا شيء",
  "patient_foot_ankle_pain_q9_a2":"خفيف",
  "patient_foot_ankle_pain_q9_a3":"معتدل",
  "patient_foot_ankle_pain_q9_a4":"شديد",
  "patient_foot_ankle_pain_q9_a5":"أقصى",
  "patient_foot_ankle_pain_q10":"ثني القدم / الكاحل بالكامل",
  "patient_foot_ankle_pain_q10_a1":"لا شيء",
  "patient_foot_ankle_pain_q10_a2":"خفيف",
  "patient_foot_ankle_pain_q10_a3":"معتدل",
  "patient_foot_ankle_pain_q10_a4":"شديد",
  "patient_foot_ankle_pain_q10_a5":"أقصى",
  "patient_foot_ankle_pain_q11":"المشي على سطح مستوٍ",
  "patient_foot_ankle_pain_q11_a1":"لا شيء",
  "patient_foot_ankle_pain_q11_a2":"خفيف",
  "patient_foot_ankle_pain_q11_a3":"معتدل",
  "patient_foot_ankle_pain_q11_a4":"شديد",
  "patient_foot_ankle_pain_q11_a5":"أقصى",
  "patient_foot_ankle_pain_q12":"الصعود أو النزول على الدرج",
  "patient_foot_ankle_pain_q12_a1":"لا شيء",
  "patient_foot_ankle_pain_q12_a2":"خفيف",
  "patient_foot_ankle_pain_q12_a3":"معتدل",
  "patient_foot_ankle_pain_q12_a4":"شديد",
  "patient_foot_ankle_pain_q12_a5":"أقصى",
  "patient_foot_ankle_pain_q13":"في الليل أثناء النوم",
  "patient_foot_ankle_pain_q13_a1":"لا شيء",
  "patient_foot_ankle_pain_q13_a2":"خفيف",
  "patient_foot_ankle_pain_q13_a3":"معتدل",
  "patient_foot_ankle_pain_q13_a4":"شديد",
  "patient_foot_ankle_pain_q13_a5":"أقصى",
  "patient_foot_ankle_pain_q14":"الجلوس أو الاستلقاء",
  "patient_foot_ankle_pain_q14_a1":"لا شيء",
  "patient_foot_ankle_pain_q14_a2":"خفيف",
  "patient_foot_ankle_pain_q14_a3":"معتدل",
  "patient_foot_ankle_pain_q14_a4":"شديد",
  "patient_foot_ankle_pain_q14_a5":"أقصى",
  "patient_foot_ankle_pain_q15":"الوقوف بشكل مستقيم",
  "patient_foot_ankle_pain_q15_a1":"لا شيء",
  "patient_foot_ankle_pain_q15_a2":"خفيف",
  "patient_foot_ankle_pain_q15_a3":"معتدل",
  "patient_foot_ankle_pain_q15_a4":"شديد",
  "patient_foot_ankle_pain_q15_a5":"أقصى",
  "patient_foot_ankle_instability":"عدم الاستقرار",
  "patient_foot_ankle_instability_q1":"هل تعاني من التواءات متكررة ، أو عدم إستقرار كاحلك ، أو تشعر وكأنها ستنهار؟",
  "patient_foot_ankle_instability_q1_a1":"لا",
  "patient_foot_ankle_instability_q1_a2":"نعم",
  "patient_foot_ankle_instability_q2":"هل سبق أن تم تشخيصك / علاجك من عدم استقرار الكاحل / الالتواءات المتكررة؟",
  "patient_foot_ankle_instability_q2_a1":"لا",
  "patient_foot_ankle_instability_q2_a2":"نعم",
  "patient_foot_ankle_instability_q3":"هل يشعر كاحلك غير المصاب بالاستقرار التام؟",
  "patient_foot_ankle_instability_q3_a1":"لا",
  "patient_foot_ankle_instability_q3_a2":"نعم",
  "patient_foot_ankle_cumberland":"أداة  CUMBERLAND لعدم إستقرار الكاحل",
  "patient_foot_ankle_cumberland_instruction":"الرجاء اختيار عبارة واحدة في كل سؤال تصف كاحلك على أفضل وجه.",
  "patient_foot_ankle_cumberland_q1":"لدي ألم في كاحلي",
  "patient_foot_ankle_cumberland_q1_a1":"أبداً",
  "patient_foot_ankle_cumberland_q1_a2":"خلال الرياضة",
  "patient_foot_ankle_cumberland_q1_a3":"الركض على الأسطح غير المستوية",
  "patient_foot_ankle_cumberland_q1_a4":"الركض على الأسطح المستوية",
  "patient_foot_ankle_cumberland_q1_a5":"المشي على أسطح غير مستوية",
  "patient_foot_ankle_cumberland_q1_a6":"المشي على الأسطح المستوية",
  "patient_foot_ankle_cumberland_q2":"كاحلي يشعر بعدم الاستقرار",
  "patient_foot_ankle_cumberland_q2_a1":"أبداً",
  "patient_foot_ankle_cumberland_q2_a2":"في بعض الأحيان أثناء الرياضة (ليس في كل مرة) ",
  "patient_foot_ankle_cumberland_q2_a3":"كثيرًا أثناء الرياضة (كل مرة) ",
  "patient_foot_ankle_cumberland_q2_a4":"في بعض الأحيان خلال النشاط اليومي",
  "patient_foot_ankle_cumberland_q2_a5":"كثيرًا أثناء النشاط اليومي",
  "patient_foot_ankle_cumberland_q3":"عندما أستدير بحدة،اشعر بأن كاحلي غير مستقر",
  "patient_foot_ankle_cumberland_q3_a1":"أبداً",
  "patient_foot_ankle_cumberland_q3_a2":"في بعض الأحيان عند الجري",
  "patient_foot_ankle_cumberland_q3_a3":"في كثير من الأحيان عند الجري",
  "patient_foot_ankle_cumberland_q3_a4":"عند المشي",
  "patient_foot_ankle_cumberland_q4":"عند نزول الدرج ، أشعر بأن كاحلي غير مستقر",
  "patient_foot_ankle_cumberland_q4_a1":"أبداً",
  "patient_foot_ankle_cumberland_q4_a2":"إذا  اسرعت",
  "patient_foot_ankle_cumberland_q4_a3":"أحياناً",
  "patient_foot_ankle_cumberland_q4_a4":"دائمًا",
  "patient_foot_ankle_cumberland_q5":"أشعر بأن كاحلي غير مستقر عند الوقوف على ساق واحدة",
  "patient_foot_ankle_cumberland_q5_a1":"أبداً",
  "patient_foot_ankle_cumberland_q5_a2":"على رأس القدم",
  "patient_foot_ankle_cumberland_q5_a3":"وقدمي مسطحة",
  "patient_foot_ankle_cumberland_q6":"يشعر كاحلي بعدم الاستقرار عندما",
  "patient_foot_ankle_cumberland_q6_a1":"أبداً",
  "patient_foot_ankle_cumberland_q6_a2":"أنا أقفز  من جانب إلى آخر",
  "patient_foot_ankle_cumberland_q6_a3":"أنا أقفز على الفور",
  "patient_foot_ankle_cumberland_q6_a4":"انا اقفز",
  "patient_foot_ankle_cumberland_q7":"يشعر كاحلي بعدم الاستقرار عندما",
  "patient_foot_ankle_cumberland_q7_a1":"أبداً",
  "patient_foot_ankle_cumberland_q7_a2":"أنا أجري على أسطح غير مستوية",
  "patient_foot_ankle_cumberland_q7_a3":"أنا أركض على الأسطح غير المستوية",
  "patient_foot_ankle_cumberland_q7_a4":"أمشي على أسطح غير مستوية",
  "patient_foot_ankle_cumberland_q7_a5":"أمشي على أسطح مستوية",
  "patient_foot_ankle_cumberland_q8":"عادةً عندما أبدأ في التدحرج (أو \"الالتواء\") على كاحلي يمكنني إيقافه",
  "patient_foot_ankle_cumberland_q8_a1":"في الحال",
  "patient_foot_ankle_cumberland_q8_a2":"غالبًا ما",
  "patient_foot_ankle_cumberland_q8_a3":"أحيانًا",
  "patient_foot_ankle_cumberland_q8_a4":"أبداً",
  "patient_foot_ankle_cumberland_q8_a5":"لم أتدحرج على كاحلي أبدًا ",
  "patient_foot_ankle_cumberland_q9":"بعد وقوع تدحرج  في كاحلي يعود كاحلي إلى الوضع \"الطبيعي\"",
  "patient_foot_ankle_cumberland_q9_a1":"تقريبا مباشرة",
  "patient_foot_ankle_cumberland_q9_a2":"أقل من يوم واحد",
  "patient_foot_ankle_cumberland_q9_a3":"1-2 يوم",
  "patient_foot_ankle_cumberland_q9_a4":"أكثر من يومين",
  "patient_foot_ankle_cumberland_q9_a5":"لم أتدحرج على كاحلي أبدًا",
  "patient_foot_ankle_other_symptoms":"أعراض أخرى",
  "patient_foot_ankle_other_symptoms_instruction1":"يرجى اختيار أفضل إجابة تتناسب مع أعراض قدمك / مفصل الكاحل.",
  "patient_foot_ankle_other_symptoms_instruction2":"إذا لم تكن متأكدًا من كيفية الإجابة على أحد الأسئلة ، فيرجى تقديم أفضل إجابة ممكنة.",
  "patient_foot_ankle_other_symptoms_note":"ملحوظة: يجب الإجابة على هذه الأسئلة مع التفكير في أعراض القدم / الكاحل خلال الأسبوع الماضي.",
  "patient_foot_ankle_other_symptoms_instruction3":"هل لديك ما يلي",
  "patient_foot_ankle_other_symptoms_instruction3_0":"أبداً",
  "patient_foot_ankle_other_symptoms_instruction3_1":"نادراً",
  "patient_foot_ankle_other_symptoms_instruction3_2":"أحيانًا",
  "patient_foot_ankle_other_symptoms_instruction3_3":"غالبًا ما",
  "patient_foot_ankle_other_symptoms_instruction3_4":"دائمًا",
  "patient_foot_ankle_other_symptoms_q1":"إنتفاخ في قدمك / كاحلك",
  "patient_foot_ankle_other_symptoms_q1_a1":"أبداً",
  "patient_foot_ankle_other_symptoms_q1_a2":"نادراً",
  "patient_foot_ankle_other_symptoms_q1_a3":"أحيانًا",
  "patient_foot_ankle_other_symptoms_q1_a4":"غالبًا ما",
  "patient_foot_ankle_other_symptoms_q1_a5":"دائمًا",
  "patient_foot_ankle_other_symptoms_q2":"الطحن أو النقر أو سماع أي نوع من الضوضاء عند تحريك القدم / الكاحل",
  "patient_foot_ankle_other_symptoms_q2_a1":"أبداً",
  "patient_foot_ankle_other_symptoms_q2_a2":"نادراً",
  "patient_foot_ankle_other_symptoms_q2_a3":"أحيانًا",
  "patient_foot_ankle_other_symptoms_q2_a4":"غالبًا ما",
  "patient_foot_ankle_other_symptoms_q2_a5":"دائمًا",
  "patient_foot_ankle_other_symptoms_q3":"الاحتكاك أو العرقلة عند الحركة",
  "patient_foot_ankle_other_symptoms_q3_a1":"أبداً",
  "patient_foot_ankle_other_symptoms_q3_a2":"نادراً",
  "patient_foot_ankle_other_symptoms_q3_a3":"أحيانًا",
  "patient_foot_ankle_other_symptoms_q3_a4":"غالبًا ما",
  "patient_foot_ankle_other_symptoms_q3_a5":"دائمًا",
  "patient_foot_ankle_other_symptoms_instruction4":"ما مدى شدة تصلب القدم / الكاحل:",
  "patient_foot_ankle_other_symptoms_instruction4_0":"لا شيء",
  "patient_foot_ankle_other_symptoms_instruction4_1":"خفيف",
  "patient_foot_ankle_other_symptoms_instruction4_2":"معتدل",
  "patient_foot_ankle_other_symptoms_instruction4_3":"شديد",
  "patient_foot_ankle_other_symptoms_instruction4_4":"أقصى",
  "patient_foot_ankle_other_symptoms_q4":"بعد الاستيقاظ في الصباح",
  "patient_foot_ankle_other_symptoms_q4_a1":"لا شيء",
  "patient_foot_ankle_other_symptoms_q4_a2":"خفيف",
  "patient_foot_ankle_other_symptoms_q4_a3":"معتدل",
  "patient_foot_ankle_other_symptoms_q4_a4":"شديد",
  "patient_foot_ankle_other_symptoms_q4_a5":"أقصى",
  "patient_foot_ankle_other_symptoms_q5":"بعد الجلوس أو الاستلقاء أو الراحة في وقت لاحق من اليوم",
  "patient_foot_ankle_other_symptoms_q5_a1":"لا شيء",
  "patient_foot_ankle_other_symptoms_q5_a2":"خفيف",
  "patient_foot_ankle_other_symptoms_q5_a3":"معتدل",
  "patient_foot_ankle_other_symptoms_q5_a4":"شديد",
  "patient_foot_ankle_other_symptoms_q5_a5":"أقصى",
  "patient_foot_ankle_function":"الوظيفة",
  "patient_foot_ankle_function_q1":"ما هي القيود المفروضة على نشاطك في حياتك اليومية بسبب قدمك / كاحلك؟",
  "patient_foot_ankle_function_q1_a1":"لا قيود ولا دعم",
  "patient_foot_ankle_function_q1_a2":"لا توجد قيود على الأنشطة اليومية ، والحد من الأنشطة الترفيهية ، ولا يوجد دعم",
  "patient_foot_ankle_function_q1_a3":"محدودية الأنشطة اليومية والترفيهية ، عصا المشي",
  "patient_foot_ankle_function_q1_a4":"قيود شديدة على الأنشطة اليومية والترفيهية ، المشاية ، العكازات ، الكرسي المتحرك ، الدعامة",
  "patient_foot_ankle_function_q2":"ما الأحذية التي ترتديها عادة؟",
  "patient_foot_ankle_function_q2_a1":"أحذية تقليدية عصرية ، لا تحتاج إلى نعل داخلي",
  "patient_foot_ankle_function_q2_a2":"حذاء مريح ، نعل داخلي",
  "patient_foot_ankle_function_q2_a3":"أحذية معدلة أو دعامة أحذية",
  "patient_foot_ankle_function_q3":"ما نوع سطح المشي الذي يعطيك صعوبة في المشي؟",
  "patient_foot_ankle_function_q3_a1":"لا توجد صعوبة على أي سطح",
  "patient_foot_ankle_function_q3_a2":"بعض الصعوبة في التضاريس غير المستوية ، السلالم ، المنحدرات ، السلالم",
  "patient_foot_ankle_function_q3_a3":"صعوبة شديدة على الأراضي غير المستوية والسلالم والمنحدرات والسلالم",
  "patient_foot_ankle_function_instruction":"حدد درجة الصعوبة التي واجهتها بسبب القدم / الكاحل",
  "patient_foot_ankle_function_instruction_0":"لا توجد صعوبة",
  "patient_foot_ankle_function_instruction_1":"صعوبة طفيفة",
  "patient_foot_ankle_function_instruction_2":"صعوبة متوسطة",
  "patient_foot_ankle_function_instruction_3":"صعوبة بالغة",
  "patient_foot_ankle_function_instruction_4":"غير قادر على القيام به",
  "patient_foot_ankle_function_note":"ملاحظة: إذا كان النشاط المعني مقيدًا بشيء آخر غير قدمك أو كاحلك ، فضع علامة \"غير قابل للتطبيق\" في العناصر التي يكون هذا فيها خيارًا. بخلاف ذلك ، يرجى الإجابة على كل سؤال بإجابة واحدة تصف حالتك بشكل وثيق خلال الأسبوع الماضي.",
  "patient_foot_ankle_function_q4":"تمديد قدمك / كاحلك بالكامل",
  "patient_foot_ankle_function_q4_a1":"لا توجد صعوبة",
  "patient_foot_ankle_function_q4_a2":"صعوبة طفيفة",
  "patient_foot_ankle_function_q4_a3":"صعوبة متوسطة",
  "patient_foot_ankle_function_q4_a4":"صعوبة بالغة",
  "patient_foot_ankle_function_q4_a5":"غير قادر على القيام به",
  "patient_foot_ankle_function_q5":"ثني قدمك / كاحلك بالكامل",
  "patient_foot_ankle_function_q5_a1":"لا توجد صعوبة",
  "patient_foot_ankle_function_q5_a2":"صعوبة طفيفة",
  "patient_foot_ankle_function_q5_a3":"صعوبة متوسطة",
  "patient_foot_ankle_function_q5_a4":"صعوبة بالغة",
  "patient_foot_ankle_function_q5_a5":"غير قادر على القيام به",
  "patient_foot_ankle_function_q6":"نزول الدرج",
  "patient_foot_ankle_function_q6_a1":"لا توجد صعوبة",
  "patient_foot_ankle_function_q6_a2":"صعوبة طفيفة",
  "patient_foot_ankle_function_q6_a3":"صعوبة متوسطة",
  "patient_foot_ankle_function_q6_a4":"صعوبة بالغة",
  "patient_foot_ankle_function_q6_a5":"غير قادر على القيام به",
  "patient_foot_ankle_function_q7":"صعود الدرج",
  "patient_foot_ankle_function_q7_a1":"لا توجد صعوبة",
  "patient_foot_ankle_function_q7_a2":"صعوبة طفيفة",
  "patient_foot_ankle_function_q7_a3":"صعوبة متوسطة",
  "patient_foot_ankle_function_q7_a4":"صعوبة بالغة",
  "patient_foot_ankle_function_q7_a5":"غير قادر على القيام به",
  "patient_foot_ankle_function_q8":"القيام  من الجلوس",
  "patient_foot_ankle_function_q8_a1":"لا توجد صعوبة",
  "patient_foot_ankle_function_q8_a2":"صعوبة طفيفة",
  "patient_foot_ankle_function_q8_a3":"صعوبة متوسطة",
  "patient_foot_ankle_function_q8_a4":"صعوبة بالغة",
  "patient_foot_ankle_function_q8_a5":"غير قادر على القيام به",
  "patient_foot_ankle_function_q9":"الوقوف",
  "patient_foot_ankle_function_q9_a1":"لا توجد صعوبة",
  "patient_foot_ankle_function_q9_a2":"صعوبة طفيفة",
  "patient_foot_ankle_function_q9_a3":"صعوبة متوسطة",
  "patient_foot_ankle_function_q9_a4":"صعوبة بالغة",
  "patient_foot_ankle_function_q9_a5":"غير قادر على القيام به",
  "patient_foot_ankle_function_q10":"الانحناء للأرض / التقاط الأشياء",
  "patient_foot_ankle_function_q10_a1":"لا توجد صعوبة",
  "patient_foot_ankle_function_q10_a2":"صعوبة طفيفة",
  "patient_foot_ankle_function_q10_a3":"صعوبة متوسطة",
  "patient_foot_ankle_function_q10_a4":"صعوبة بالغة",
  "patient_foot_ankle_function_q10_a5":"غير قادر على القيام به",
  "patient_foot_ankle_function_q11":"المشي على سطح مستوٍ",
  "patient_foot_ankle_function_q11_a1":"لا توجد صعوبة",
  "patient_foot_ankle_function_q11_a2":"صعوبة طفيفة",
  "patient_foot_ankle_function_q11_a3":"صعوبة متوسطة",
  "patient_foot_ankle_function_q11_a4":"صعوبة بالغة",
  "patient_foot_ankle_function_q11_a5":"غير قادر على القيام به",
  "patient_foot_ankle_function_q12":"ما هي أقصى مسافة يمكنك مشيها؟",
  "patient_foot_ankle_function_q12_a1":"أكبر من 6 كتل",
  "patient_foot_ankle_function_q12_a2":"4-6 كتل",
  "patient_foot_ankle_function_q12_a3":"1-3 كتل",
  "patient_foot_ankle_function_q12_a4":"أقل من كتلة واحدة",
  "patient_foot_ankle_function_q13":"المشي على الأرض حتى بدون حذاء",
  "patient_foot_ankle_function_q13_a1":"لا توجد صعوبة",
  "patient_foot_ankle_function_q13_a2":"صعوبة طفيفة",
  "patient_foot_ankle_function_q13_a3":"صعوبة متوسطة",
  "patient_foot_ankle_function_q13_a4":"صعوبة بالغة",
  "patient_foot_ankle_function_q13_a5":"غير قادر على القيام به",
  "patient_foot_ankle_function_q14":"المشي لاعلى التلال",
  "patient_foot_ankle_function_q14_a1":"لا توجد صعوبة",
  "patient_foot_ankle_function_q14_a2":"صعوبة طفيفة",
  "patient_foot_ankle_function_q14_a3":"صعوبة متوسطة",
  "patient_foot_ankle_function_q14_a4":"صعوبة بالغة",
  "patient_foot_ankle_function_q14_a5":"غير قادر على القيام به",
  "patient_foot_ankle_function_q15":"المشي لاسفل التلال",
  "patient_foot_ankle_function_q15_a1":"لا توجد صعوبة",
  "patient_foot_ankle_function_q15_a2":"صعوبة طفيفة",
  "patient_foot_ankle_function_q15_a3":"صعوبة متوسطة",
  "patient_foot_ankle_function_q15_a4":"صعوبة بالغة",
  "patient_foot_ankle_function_q15_a5":"غير قادر على القيام به",
  "patient_foot_ankle_function_q16":"المشي على أرض غير مستوية",
  "patient_foot_ankle_function_q16_a1":"لا توجد صعوبة",
  "patient_foot_ankle_function_q16_a2":"صعوبة طفيفة",
  "patient_foot_ankle_function_q16_a3":"صعوبة متوسطة",
  "patient_foot_ankle_function_q16_a4":"صعوبة بالغة",
  "patient_foot_ankle_function_q16_a5":"غير قادر على القيام به",
  "patient_foot_ankle_function_q17":"الصعود والنزول من الرصيف",
  "patient_foot_ankle_function_q17_a1":"لا توجد صعوبة",
  "patient_foot_ankle_function_q17_a2":"صعوبة طفيفة",
  "patient_foot_ankle_function_q17_a3":"صعوبة متوسطة",
  "patient_foot_ankle_function_q17_a4":"صعوبة بالغة",
  "patient_foot_ankle_function_q17_a5":"غير قادر على القيام به",
  "patient_foot_ankle_function_q18":"الوصول إلى أصابع قدميك",
  "patient_foot_ankle_function_q18_a1":"لا توجد صعوبة",
  "patient_foot_ankle_function_q18_a2":"صعوبة طفيفة",
  "patient_foot_ankle_function_q18_a3":"صعوبة متوسطة",
  "patient_foot_ankle_function_q18_a4":"صعوبة بالغة",
  "patient_foot_ankle_function_q18_a5":"غير قادر على القيام به",
  "patient_foot_ankle_function_q19":"المشي في البداية",
  "patient_foot_ankle_function_q19_a1":"لا توجد صعوبة",
  "patient_foot_ankle_function_q19_a2":"صعوبة طفيفة",
  "patient_foot_ankle_function_q19_a3":"صعوبة متوسطة",
  "patient_foot_ankle_function_q19_a4":"صعوبة بالغة",
  "patient_foot_ankle_function_q19_a5":"غير قادر على القيام به",
  "patient_foot_ankle_function_q20":"المشي لمدة 5 دقائق أو أقل",
  "patient_foot_ankle_function_q20_a1":"لا توجد صعوبة",
  "patient_foot_ankle_function_q20_a2":"صعوبة طفيفة",
  "patient_foot_ankle_function_q20_a3":"صعوبة متوسطة",
  "patient_foot_ankle_function_q20_a4":"صعوبة بالغة",
  "patient_foot_ankle_function_q20_a5":"غير قادر على القيام به",
  "patient_foot_ankle_function_q21":"المشي لمدة 10 دقائق تقريبًا",
  "patient_foot_ankle_function_q21_a1":"لا توجد صعوبة",
  "patient_foot_ankle_function_q21_a2":"صعوبة طفيفة",
  "patient_foot_ankle_function_q21_a3":"صعوبة متوسطة",
  "patient_foot_ankle_function_q21_a4":"صعوبة بالغة",
  "patient_foot_ankle_function_q21_a5":"غير قادر على القيام به",
  "patient_foot_ankle_function_q22":"المشي لمدة 15 دقيقة أو أكثر",
  "patient_foot_ankle_function_q22_a1":"لا توجد صعوبة",
  "patient_foot_ankle_function_q22_a2":"صعوبة طفيفة",
  "patient_foot_ankle_function_q22_a3":"صعوبة متوسطة",
  "patient_foot_ankle_function_q22_a4":"صعوبة بالغة",
  "patient_foot_ankle_function_q22_a5":"غير قادر على القيام به",
  "patient_foot_ankle_function_q23":"الدخول / الخروج من السيارة",
  "patient_foot_ankle_function_q23_a1":"لا توجد صعوبة",
  "patient_foot_ankle_function_q23_a2":"صعوبة طفيفة",
  "patient_foot_ankle_function_q23_a3":"صعوبة متوسطة",
  "patient_foot_ankle_function_q23_a4":"صعوبة بالغة",
  "patient_foot_ankle_function_q23_a5":"غير قادر على القيام به",
  "patient_foot_ankle_function_q24":"الذهاب للتسوق",
  "patient_foot_ankle_function_q24_a1":"لا توجد صعوبة",
  "patient_foot_ankle_function_q24_a2":"صعوبة طفيفة",
  "patient_foot_ankle_function_q24_a3":"صعوبة متوسطة",
  "patient_foot_ankle_function_q24_a4":"صعوبة بالغة",
  "patient_foot_ankle_function_q24_a5":"غير قادر على القيام به",
  "patient_foot_ankle_function_q25":"وضع الجوارب / الجوارب الطويلة",
  "patient_foot_ankle_function_q25_a1":"لا توجد صعوبة",
  "patient_foot_ankle_function_q25_a2":"صعوبة طفيفة",
  "patient_foot_ankle_function_q25_a3":"صعوبة متوسطة",
  "patient_foot_ankle_function_q25_a4":"صعوبة بالغة",
  "patient_foot_ankle_function_q25_a5":"غير قادر على القيام به",
  "patient_foot_ankle_function_q26":"خلع الجوارب / الجوارب الطويلة",
  "patient_foot_ankle_function_q26_a1":"لا توجد صعوبة",
  "patient_foot_ankle_function_q26_a2":"صعوبة طفيفة",
  "patient_foot_ankle_function_q26_a3":"صعوبة متوسطة",
  "patient_foot_ankle_function_q26_a4":"صعوبة بالغة",
  "patient_foot_ankle_function_q26_a5":"غير قادر على القيام به",
  "patient_foot_ankle_function_q27":"النهوض من السرير",
  "patient_foot_ankle_function_q27_a1":"لا توجد صعوبة",
  "patient_foot_ankle_function_q27_a2":"صعوبة طفيفة",
  "patient_foot_ankle_function_q27_a3":"صعوبة متوسطة",
  "patient_foot_ankle_function_q27_a4":"صعوبة بالغة",
  "patient_foot_ankle_function_q27_a5":"غير قادر على القيام به",
  "patient_foot_ankle_function_q28":"الاستلقاء في السرير (التقلب مع الحفاظ على وضعية القدم / الكاحل)",
  "patient_foot_ankle_function_q28_a1":"لا توجد صعوبة",
  "patient_foot_ankle_function_q28_a2":"صعوبة طفيفة",
  "patient_foot_ankle_function_q28_a3":"صعوبة متوسطة",
  "patient_foot_ankle_function_q28_a4":"صعوبة بالغة",
  "patient_foot_ankle_function_q28_a5":"غير قادر على القيام به",
  "patient_foot_ankle_function_q29":"النوم",
  "patient_foot_ankle_function_q29_a1":"لا توجد صعوبة",
  "patient_foot_ankle_function_q29_a2":"صعوبة طفيفة",
  "patient_foot_ankle_function_q29_a3":"صعوبة متوسطة",
  "patient_foot_ankle_function_q29_a4":"صعوبة بالغة",
  "patient_foot_ankle_function_q29_a5":"غير قادر على القيام به",
  "patient_foot_ankle_function_q30":"الدخول والخروج من الحمام",
  "patient_foot_ankle_function_q30_a1":"لا توجد صعوبة",
  "patient_foot_ankle_function_q30_a2":"صعوبة طفيفة",
  "patient_foot_ankle_function_q30_a3":"صعوبة متوسطة",
  "patient_foot_ankle_function_q30_a4":"صعوبة بالغة",
  "patient_foot_ankle_function_q30_a5":"غير قادر على القيام به",
  "patient_foot_ankle_function_q31":"الجلوس",
  "patient_foot_ankle_function_q31_a1":"لا توجد صعوبة",
  "patient_foot_ankle_function_q31_a2":"صعوبة طفيفة",
  "patient_foot_ankle_function_q31_a3":"صعوبة متوسطة",
  "patient_foot_ankle_function_q31_a4":"صعوبة بالغة",
  "patient_foot_ankle_function_q31_a5":"غير قادر على القيام به",
  "patient_foot_ankle_function_q32":"الجلوس والقيام من المرحاض",
  "patient_foot_ankle_function_q32_a1":"لا توجد صعوبة",
  "patient_foot_ankle_function_q32_a2":"صعوبة طفيفة",
  "patient_foot_ankle_function_q32_a3":"صعوبة متوسطة",
  "patient_foot_ankle_function_q32_a4":"صعوبة بالغة",
  "patient_foot_ankle_function_q32_a5":"غير قادر على القيام به",
  "patient_foot_ankle_function_q33":"مسؤوليات المنزل",
  "patient_foot_ankle_function_q33_a1":"لا توجد صعوبة",
  "patient_foot_ankle_function_q33_a2":"صعوبة طفيفة",
  "patient_foot_ankle_function_q33_a3":"صعوبة متوسطة",
  "patient_foot_ankle_function_q33_a4":"صعوبة بالغة",
  "patient_foot_ankle_function_q33_a5":"غير قادر على القيام به",
  "patient_foot_ankle_function_q34":"أنشطة الحياة اليومية",
  "patient_foot_ankle_function_q34_a1":"لا توجد صعوبة",
  "patient_foot_ankle_function_q34_a2":"صعوبة طفيفة",
  "patient_foot_ankle_function_q34_a3":"صعوبة متوسطة",
  "patient_foot_ankle_function_q34_a4":"صعوبة بالغة",
  "patient_foot_ankle_function_q34_a5":"غير قادر على القيام به",
  "patient_foot_ankle_function_q35":"العناية الشخصية (بما في ذلك الغسيل أو ارتداء الملابس)",
  "patient_foot_ankle_function_q35_a1":"لا توجد صعوبة",
  "patient_foot_ankle_function_q35_a2":"صعوبة طفيفة",
  "patient_foot_ankle_function_q35_a3":"صعوبة متوسطة",
  "patient_foot_ankle_function_q35_a4":"صعوبة بالغة",
  "patient_foot_ankle_function_q35_a5":"غير قادر على القيام به",
  "patient_foot_ankle_function_q36":"الاعمال الشاقة (دفع / سحب تسلق ، حمل صناديق ثقيلة متحركة وتنظيف الأرضيات ، إلخ)",
  "patient_foot_ankle_function_q36_a1":"لا توجد صعوبة",
  "patient_foot_ankle_function_q36_a2":"صعوبة طفيفة",
  "patient_foot_ankle_function_q36_a3":"صعوبة متوسطة",
  "patient_foot_ankle_function_q36_a4":"صعوبة بالغة",
  "patient_foot_ankle_function_q36_a5":"غير قادر على القيام به",
  "patient_foot_ankle_function_q37":"الأعمال المنزلية الخفيفة (الطهي والتنظيف وما إلى ذلك)",
  "patient_foot_ankle_function_q37_a1":"لا توجد صعوبة",
  "patient_foot_ankle_function_q37_a2":"صعوبة طفيفة",
  "patient_foot_ankle_function_q37_a3":"صعوبة متوسطة",
  "patient_foot_ankle_function_q37_a4":"صعوبة بالغة",
  "patient_foot_ankle_function_q37_a5":"غير قادر على القيام به",
  "patient_foot_ankle_function_q38":"العمل الخفيف إلى المتوسط (الوقوف ، المشي)",
  "patient_foot_ankle_function_q38_a1":"لا توجد صعوبة",
  "patient_foot_ankle_function_q38_a2":"صعوبة طفيفة",
  "patient_foot_ankle_function_q38_a3":"صعوبة متوسطة",
  "patient_foot_ankle_function_q38_a4":"صعوبة بالغة",
  "patient_foot_ankle_function_q38_a5":"غير قادر على القيام به",
  "patient_foot_ankle_function_q39":"الأنشطة الترفيهية",
  "patient_foot_ankle_function_q39_a1":"لا توجد صعوبة",
  "patient_foot_ankle_function_q39_a2":"صعوبة طفيفة",
  "patient_foot_ankle_function_q39_a3":"صعوبة متوسطة",
  "patient_foot_ankle_function_q39_a4":"صعوبة بالغة",
  "patient_foot_ankle_function_q39_a5":"غير قادر على القيام به",
  "patient_foot_ankle_function_q40":"القرفصاء",
  "patient_foot_ankle_function_q40_a1":"لا توجد صعوبة",
  "patient_foot_ankle_function_q40_a2":"صعوبة طفيفة",
  "patient_foot_ankle_function_q40_a3":"صعوبة متوسطة",
  "patient_foot_ankle_function_q40_a4":"صعوبة بالغة",
  "patient_foot_ankle_function_q40_a5":"غير قادر على القيام به",
  "patient_foot_ankle_function_q41":"التواء / الدوران حول قدمك / كاحلك",
  "patient_foot_ankle_function_q41_a1":"لا توجد صعوبة",
  "patient_foot_ankle_function_q41_a2":"صعوبة طفيفة",
  "patient_foot_ankle_function_q41_a3":"صعوبة متوسطة",
  "patient_foot_ankle_function_q41_a4":"صعوبة بالغة",
  "patient_foot_ankle_function_q41_a5":"غير قادر على القيام به",
  "patient_foot_ankle_function_q42":"الركوع",
  "patient_foot_ankle_function_q42_a1":"لا توجد صعوبة",
  "patient_foot_ankle_function_q42_a2":"صعوبة طفيفة",
  "patient_foot_ankle_function_q42_a3":"صعوبة متوسطة",
  "patient_foot_ankle_function_q42_a4":"صعوبة بالغة",
  "patient_foot_ankle_function_q42_a5":"غير قادر على القيام به",
  "patient_foot_ankle_function_q43":"الجري",
  "patient_foot_ankle_function_q43_not_applicable":"غير قابل للتطبيق",
  "patient_foot_ankle_function_q43_a1":"لا توجد صعوبة",
  "patient_foot_ankle_function_q43_a2":"صعوبة طفيفة",
  "patient_foot_ankle_function_q43_a3":"صعوبة متوسطة",
  "patient_foot_ankle_function_q43_a4":"صعوبة بالغة",
  "patient_foot_ankle_function_q43_a5":"غير قادر على القيام به",
  "patient_foot_ankle_function_q44":"القفز",
  "patient_foot_ankle_function_q44_not_applicable":"غير قابل للتطبيق",
  "patient_foot_ankle_function_q44_a1":"لا توجد صعوبة",
  "patient_foot_ankle_function_q44_a2":"صعوبة طفيفة",
  "patient_foot_ankle_function_q44_a3":"صعوبة متوسطة",
  "patient_foot_ankle_function_q44_a4":"صعوبة بالغة",
  "patient_foot_ankle_function_q44_a5":"غير قادر على القيام به",
  "patient_foot_ankle_function_q45":"الهبوط",
  "patient_foot_ankle_function_q45_not_applicable":"غير قابل للتطبيق",
  "patient_foot_ankle_function_q45_a1":"لا توجد صعوبة",
  "patient_foot_ankle_function_q45_a2":"صعوبة طفيفة",
  "patient_foot_ankle_function_q45_a3":"صعوبة متوسطة",
  "patient_foot_ankle_function_q45_a4":"صعوبة بالغة",
  "patient_foot_ankle_function_q45_a5":"غير قادر على القيام به",
  "patient_foot_ankle_function_q46":"البدء والتوقف بسرعة",
  "patient_foot_ankle_function_q46_not_applicable":"غير قابل للتطبيق",
  "patient_foot_ankle_function_q46_a1":"لا توجد صعوبة",
  "patient_foot_ankle_function_q46_a2":"صعوبة طفيفة",
  "patient_foot_ankle_function_q46_a3":"صعوبة متوسطة",
  "patient_foot_ankle_function_q46_a4":"صعوبة بالغة",
  "patient_foot_ankle_function_q46_a5":"غير قادر على القيام به",
  "patient_foot_ankle_function_q47":"البدء والتوقف بسرعة",
  "patient_foot_ankle_function_q47_not_applicable":"غير قابل للتطبيق",
  "patient_foot_ankle_function_q47_a1":"لا توجد صعوبة",
  "patient_foot_ankle_function_q47_a2":"صعوبة طفيفة",
  "patient_foot_ankle_function_q47_a3":"صعوبة متوسطة",
  "patient_foot_ankle_function_q47_a4":"صعوبة بالغة",
  "patient_foot_ankle_function_q47_a5":"غير قادر على القيام به",
  "patient_foot_ankle_function_q48":"الأنشطة منخفضة التأثير",
  "patient_foot_ankle_function_q48_not_applicable":"غير قابل للتطبيق",
  "patient_foot_ankle_function_q48_a1":"لا توجد صعوبة",
  "patient_foot_ankle_function_q48_a2":"صعوبة طفيفة",
  "patient_foot_ankle_function_q48_a3":"صعوبة متوسطة",
  "patient_foot_ankle_function_q48_a4":"صعوبة بالغة",
  "patient_foot_ankle_function_q48_a5":"غير قادر على القيام به",
  "patient_foot_ankle_function_q49":"القدرة على أداء النشاط بأسلوبك المعتاد",
  "patient_foot_ankle_function_q49_not_applicable":"غير قابل للتطبيق",
  "patient_foot_ankle_function_q49_a1":"لا توجد صعوبة",
  "patient_foot_ankle_function_q49_a2":"صعوبة طفيفة",
  "patient_foot_ankle_function_q49_a3":"صعوبة متوسطة",
  "patient_foot_ankle_function_q49_a4":"صعوبة بالغة",
  "patient_foot_ankle_function_q49_a5":"غير قادر على القيام به",
  "patient_foot_ankle_function_q50":"القدرة على المشاركة في الرياضة التي تريدها طالما أردت",
  "patient_foot_ankle_function_q50_not_applicable":"غير قابل للتطبيق",
  "patient_foot_ankle_function_q50_a1":"لا توجد صعوبة",
  "patient_foot_ankle_function_q50_a2":"صعوبة طفيفة",
  "patient_foot_ankle_function_q50_a3":"صعوبة متوسطة",
  "patient_foot_ankle_function_q50_a4":"صعوبة بالغة",
  "patient_foot_ankle_function_q50_a5":"غير قادر على القيام به",
  "patient_foot_ankle_function_q51":"كيف تقيم كاحلك / قدمك اليوم كنسبة مئوية من الطبيعي؟ (0 إلى 100٪ , 100٪ي عني طبيعي)",
  "patient_foot_ankle_function_q51_a1":"طبيعي",
  "patient_foot_ankle_function_q52":"ما هو أعلى مستوى من النشاط يمكنك المشاركة فيه بشكل منتظم؟",
  "patient_foot_ankle_function_q52_q1":"قبل إصابة / مشكلة القدم أو الكاحل",
  "patient_foot_ankle_function_q52_q1_a1":"افضل مستوى",
  "patient_foot_ankle_function_q52_q1_a2":"مستويات تنافسية أقل",
  "patient_foot_ankle_function_q52_q1_a3":"ترفيهية",
  "patient_foot_ankle_function_q52_q1_a4":"أنا لا أمارس الرياضة ولكني أعمل",
  "patient_foot_ankle_function_q52_q1_a5":"لا رياضة ولا عمل",
  "patient_foot_ankle_function_q52_q1_a4_o7":"غير قادر على المشي ، معاق بسبب مشاكل في الكاحل",
  "patient_foot_ankle_function_q52_q1_a1_title":"المستوى الأعلى (النخبة الدولية ، المحترفين ، المنتخب الوطني ، أو الدرجة الأولى)",
  "patient_foot_ankle_function_q52_q1_a2_title":"مستويات تنافسية أقل",
  "patient_foot_ankle_function_q52_q1_a3_title":"ترفيهية",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o1":"التمارين الرياضية واللياقة البدنية",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o2":"التزلج على المنحدرات الجليدية والتزلج على الجليد",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o3":"كرة القدم الأمريكية",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o4":"تنس الريشة",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o5":"كرة القاعدة",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o6":"كرة السلة",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o7":"البولينج /الكَرْلِنْغ",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_08":"ملاكمة",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o9":"عدو ريفي",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o10":"ركوب الدراجات",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o11":"الرقص",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o12":"الغوص",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o13":"الفروسية",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o14":"مبارزة السلاح",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o15":"كرة الأرض",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o16":"التزلج على الجليد بأسلوب حر",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o17":"الجولف",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o18":"رياضة بدنية",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o19":"كرة اليد",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o20":"الهوكي",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o21":"الهوكى الجليدى",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o22":"كرة السلة (الهولندية)",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o23":"الفنون العسكرية",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o24":"الخماسي الحديث",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o25":"رياضة السيارات والرياضات الفنية",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o26":"تسلق الجبال والتلال",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o27":"ركوب الدراجات الجبلية / bmx",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o28":"التزلج الشمالي",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o29":"سباق موجه",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o30":"الهبوط بالمظلات",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o31":"رياضة القوة",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o32":"الجمباز الايقاعي",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o33":"التجديف والتجديف بالكاياك",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o34":"رغبي",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o35":"القوارب الشراعية",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o36":"غوص سكوبا",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o37":"الرماية، الرماية بالقوس والسهم",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o38":"التزلج والتزحلق على الجليد",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o39":"كرة القدم",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o40":"الكرة اللينة",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o41":"إسكواش",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o42":"ركوب القوارب الشراعية",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o43":"تنس الطاولة",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o44":"تنس",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o45":"سباقات المضمار والميدان: الأحداث الميدانية",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o46":"المسار والميدان: تتبع الأحداث",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o47":"الترياتلون",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o48":"الكرة الطائرة",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o49":"كرة الماء والسباحة",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o50":"التزلج على الماء",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o51":"كمال الأجسام ورفع الأثقال",
  "patient_foot_ankle_function_q52_q1_a1_a2_a3_o52":"مصارعة",
  "patient_foot_ankle_function_q52_q1_a4_o1":"العمل البدني الشاق",
  "patient_foot_ankle_function_q52_q1_a4_o2":"عمل بدني",
  "patient_foot_ankle_function_q52_q1_a4_o3":"المهن الخاصة: راقصة باليه ، جندي محترف ، عامل إنقاذ خاص ، البهلواني",
  "patient_foot_ankle_function_q52_q1_a4_o4":"قادرة على المشي على أي أرض غير مستوية",
  "patient_foot_ankle_function_q52_q1_a4_o5":"الأنشطة اليومية غير محدودة",
  "patient_foot_ankle_function_q52_q1_a4_o6":"قادر على المشي على أرض مستوية ، لكن الأنشطة اليومية محدودة",
  "patient_foot_ankle_function_q52_q2":"الوضع الحالي",
  "patient_foot_ankle_function_q52_q2_a1":"افضل مستوى",
  "patient_foot_ankle_function_q52_q2_a2":"مستويات تنافسية أقل",
  "patient_foot_ankle_function_q52_q2_a3":"ترفيهية",
  "patient_foot_ankle_function_q52_q2_a4":"أنا لا أمارس الرياضة ولكني أعمل",
  "patient_foot_ankle_function_q52_q2_a5":"لا رياضة ولا عمل",
  "patient_foot_ankle_function_q52_q2_a4_o7":"غير قادر على المشي ، معاق بسبب مشاكل في الكاحل",
  "patient_foot_ankle_function_q52_q2_a1_title":"المستوى الأعلى (النخبة الدولية ، المحترفين ، المنتخب الوطني ، أو الدرجة الأولى)",
  "patient_foot_ankle_function_q52_q2_a2_title":"مستويات تنافسية أقل",
  "patient_foot_ankle_function_q52_q2_a3_title":"ترفيهية",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o1":"التمارين الرياضية واللياقة البدنية",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o2":"التزلج على المنحدرات الجليدية والتزلج على الجليد",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o3":"كرة القدم الأمريكية",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o4":"تنس الريشة",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o5":"كرة القاعدة",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o6":"كرة السلة",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o7":"البولينج /الكَرْلِنْغ",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o8":"ملاكمة",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o9":"عدو ريفي",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o10":"ركوب الدراجات",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o11":"الرقص",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o12":"الغوص",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o13":"الفروسية",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o14":"مبارزة السلاح",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o15":"كرة الأرض",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o16":"التزلج على الجليد بأسلوب حر",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o17":"الجولف",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o18":"رياضة بدنية",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o19":"كرة اليد",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o20":"الهوكي",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o21":"الهوكى الجليدى",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o22":"كرة السلة (الهولندية)",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o23":"الفنون العسكرية",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o24":"الخماسي الحديث",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o25":"رياضة السيارات والرياضات الفنية",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o26":"تسلق الجبال والتلال",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o27":"ركوب الدراجات الجبلية / bmx",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o28":"التزلج الشمالي",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o29":"سباق موجه",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o30":"الهبوط بالمظلات",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o31":"رياضة القوة",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o32":"الجمباز الايقاعي",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o33":"التجديف والتجديف بالكاياك",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o34":"الرغبي",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o35":"القوارب الشراعية ",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o36":"غوص سكوبا",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o37":"الرماية، الرماية بالقوس والسهم",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o38":"التزلج والتزحلق على الجليد",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o39":"كرة القدم",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o40":"الكرة اللينة",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o41":"إسكواش",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o42":"ركوب القوارب الشراعية",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o43":"تنس الطاولة",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o44":"تنس",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o45":"سباقات المضمار والميدان: الأحداث الميدانية",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o46":"المسار والميدان: تتبع الأحداث",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o47":"الترياتلون",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o48":"الكرة الطائرة",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o49":"كرة الماء والسباحة",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o50":"التزلج على الماء",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o51":"كمال الأجسام ورفع الأثقال",
  "patient_foot_ankle_function_q52_q2_a1_a2_a3_o52":"مصارعة",
  "patient_foot_ankle_function_q52_q2_a4_o1":"العمل البدني الشاق",
  "patient_foot_ankle_function_q52_q2_a4_o2":"عمل بدني",
  "patient_foot_ankle_function_q52_q2_a4_o3":"المهن الخاصة: راقصة باليه ، جندي محترف ، عامل إنقاذ خاص ، البهلواني",
  "patient_foot_ankle_function_q52_q2_a4_o4":"قادرة على المشي على أي أرض غير مستوية",
  "patient_foot_ankle_function_q52_q2_a4_o5":"الأنشطة اليومية غير محدودة",
  "patient_foot_ankle_function_q52_q2_a4_o6":"قادرة على المشي على أرض مستوية ، كل نشاط محدود",
  "patient_foot_ankle_quality_of_life":"جودة الحياة",
  "patient_foot_ankle_quality_of_life_instructions":"الأسئلة التالية تسأل عن المخاوف الاجتماعية والعاطفية والمتعلقة بنمط الحياة التي قد تشعر بها فيما يتعلق بمشكلة قدمك / كاحلك. يرجى التفكير فيما تشعر به معظم الوقت فيما يتعلق بقدمك / كاحلك.",
  "patient_foot_ankle_quality_of_life_q1":"كم مرة تدرك مشكلة قدمك / كاحلك؟ ",
  "patient_foot_ankle_quality_of_life_q1_a1":"أبداً",
  "patient_foot_ankle_quality_of_life_q1_a2":"شهريا",
  "patient_foot_ankle_quality_of_life_q1_a3":"أسبوعيا",
  "patient_foot_ankle_quality_of_life_q1_a4":"يوميا",
  "patient_foot_ankle_quality_of_life_q1_a5":"باستمرار",
  "patient_foot_ankle_quality_of_life_q2":"هل قمت بتعديل نمط حياتك لتجنب الأنشطة التي من المحتمل أن تكون ضارة لقدمك / كاحلك؟",
  "patient_foot_ankle_quality_of_life_q2_a1":"مُطْلَقاً",
  "patient_foot_ankle_quality_of_life_q2_a2":"خفيف",
  "patient_foot_ankle_quality_of_life_q2_a3":"باعتدال",
  "patient_foot_ankle_quality_of_life_q2_a4":"بشدة",
  "patient_foot_ankle_quality_of_life_q2_a5":"تماما",
  "patient_foot_ankle_quality_of_life_q3":"ما مدى انزعاجك من عدم الثقة في قدمك / كاحلك؟",
  "patient_foot_ankle_quality_of_life_q3_a1":"مُطْلَقاً",
  "patient_foot_ankle_quality_of_life_q3_a2":"خفيف",
  "patient_foot_ankle_quality_of_life_q3_a3":"باعتدال",
  "patient_foot_ankle_quality_of_life_q3_a4":"بشدة",
  "patient_foot_ankle_quality_of_life_q3_a5":"لأقصى حد",
  "patient_foot_ankle_quality_of_life_q4":"بشكل عام ما مدى الصعوبة  التي تتعرض إليها جراء قدمك / كاحلك؟",
  "patient_foot_ankle_quality_of_life_q4_a1":"لا شيء",
  "patient_foot_ankle_quality_of_life_q4_a2":"خفيف",
  "patient_foot_ankle_quality_of_life_q4_a3":"معتدل",
  "patient_foot_ankle_quality_of_life_q4_a4":"شديد",
  "patient_foot_ankle_quality_of_life_q4_a5":"أقصى",
  "patient_foot_ankle_quality_of_life_q5":"كم تعاني من القلق / الاكتئاب اليوم؟",
  "patient_foot_ankle_quality_of_life_q5_a1":"غير قلق / مكتئب",
  "patient_foot_ankle_quality_of_life_q5_a2":"طفيف",
  "patient_foot_ankle_quality_of_life_q5_a3":"باعتدال",
  "patient_foot_ankle_quality_of_life_q5_a4":"بشدة",
  "patient_foot_ankle_quality_of_life_q5_a5":"لأقصى حد",
  "patient_foot_ankle_quality_of_life_q6":"ما مدى جودة / سوء صحتك اليوم؟ (100 تعني أفضل صحة 0 تعني أسوأ صحة يمكنك تخيلها)",
  "patient_foot_ankle_quality_of_life_q6_a1":"أسوأ صحة",
  "patient_foot_ankle_quality_of_life_q6_a2":"أفضل صحة",
  "patient_foot_ankle_satisfaction":"الرضا",
  "patient_foot_ankle_satisfaction_instruction":"ما مدى رضاك عن نتائج جراحة أو علاج القدم / الكاحل؟",
  "patient_foot_ankle_satisfaction_instruction_a1":"غير راض تماماً",
  "patient_foot_ankle_satisfaction_instruction_a2":"غير راض بعض الشيء",
  "patient_foot_ankle_satisfaction_instruction_a3":"راض بعض الشيء",
  "patient_foot_ankle_satisfaction_instruction_a4":"راض جدا",
  "patient_foot_ankle_satisfaction_q1":"لتخفيف الأعراض؟",
  "patient_foot_ankle_satisfaction_q1_not_applicable":"غير قابل للتطبيق",
  "patient_foot_ankle_satisfaction_q1_a1":"غير راض تماماً",
  "patient_foot_ankle_satisfaction_q1_a2":"غير راض بعض الشيء",
  "patient_foot_ankle_satisfaction_q1_a3":"راض بعض الشيء",
  "patient_foot_ankle_satisfaction_q1_a4":"راض جدا",
  "patient_foot_ankle_satisfaction_q2":"لتحسين قدرتك على أداء الأنشطة اليومية؟",
  "patient_foot_ankle_satisfaction_q2_not_applicable":"غير قابل للتطبيق",
  "patient_foot_ankle_satisfaction_q2_a1":"غير راض تماماً",
  "patient_foot_ankle_satisfaction_q2_a2":"غير راض بعض الشيء",
  "patient_foot_ankle_satisfaction_q2_a3":"راض بعض الشيء",
  "patient_foot_ankle_satisfaction_q2_a4":"راض جدا",
  "patient_foot_ankle_satisfaction_q3":"من أجل \"نسيان\" مشكلة قدمك / كاحلك؟ (الوعي بمشكلة القدم / الكاحل)",
  "patient_foot_ankle_satisfaction_q3_not_applicable":"غير قابل للتطبيق",
  "patient_foot_ankle_satisfaction_q3_a1":"غير راض تماماً",
  "patient_foot_ankle_satisfaction_q3_a2":"غير راض بعض الشيء",
  "patient_foot_ankle_satisfaction_q3_a3":"راض بعض الشيء",
  "patient_foot_ankle_satisfaction_q3_a4":"راض جدا",
  "patient_foot_ankle_complete":"نهاية الاستبيان",
  "patient_foot_ankle_complete_text1":"شكرًا لك على الوقت الذي أمضيته في ملء هذا النموذج.",
  "patient_foot_ankle_complete_text2":"الرجاء النقر فوق إرسال لإكمال ورؤية درجاتك.",
  "patient_foot_ankle_complete_text3":"سيتم التعامل مع إجاباتك بسرية ولن تستخدم أي تقارير سوى معلومات مجهولة الهوية.",
  "patient_foot_ankle_results":"النتائج",
  "patient_foot_ankle_results_text1":"فيما يلي درجات الألم ووظيفة القدم / الكاحل ، والتي يتم حسابها من الاستبيان الخاص بك.",
  "patient_foot_ankle_results_pain":"الألم",
  "patient_foot_ankle_results_function":"الوظيفة",
  "patient_foot_ankle_results_overall_health_perception":"التصور الصحي العام",
  "patient_foot_ankle_results_feeling_of_instability":"الشعور بعدم الاستقرار",
  "patient_foot_ankle_results_self_evaluation":"التقييم الذاتي لتسجيل القدم / الكاحل",
  "patient_foot_ankle_results_text2":"سيكون طبيبك معك قريبًا.",
  "patient_elbow_pain":"الألم",
  "patient_elbow_pain_q1":"هل تشعر بألم في مرفقك؟",
  "patient_elbow_pain_q1_a1":"لا",
  "patient_elbow_pain_q1_a2":"نعم",
  "patient_elbow_pain_q2":"ما هو أفضل وصف لألم المرفق",
  "patient_elbow_pain_q2_a1":"لا شيء أو تم تجاهله",
  "patient_elbow_pain_q2_a2":"خفيف مع النشاط ولا يستخدم الدواء أو المسكنات إلا بشكل نادر",
  "patient_elbow_pain_q2_a3":"متوسطة مع أو بدون نشاط ويستخدم المسكنات يوميًا",
  "patient_elbow_pain_q2_a4":"معتدلة في الراحة أو في الليل",
  "patient_elbow_pain_q2_a5":"شديدة في الراحة، يتم استخدام الدواء بشكل مستمر وتعوق من الحركة",
  "patient_elbow_pain_instruction":"قيم الألم في مرفقك",
  "patient_elbow_pain_q3":"في هذه اللحظة ",
  "patient_elbow_pain_q3_a1":"لا ألم",
  "patient_elbow_pain_q3_a2":"ألم لا يطاق",
  "patient_elbow_pain_q4":"ألم معتاد خلال الأسابيع الأربعة الماضية",
  "patient_elbow_pain_q4_a1":"لا ألم",
  "patient_elbow_pain_q4_a2":"ألم لا يطاق",
  "patient_elbow_pain_q5":"عندما يكون في أسوأ حالاته خلال الأسابيع الأربعة الماضية",
  "patient_elbow_pain_q5_a1":"لا ألم",
  "patient_elbow_pain_q5_a2":"ألم لا يطاق",
  "patient_elbow_pain_q6":"في راحه",
  "patient_elbow_pain_q6_a1":"لا ألم",
  "patient_elbow_pain_q6_a2":"ألم لا يطاق",
  "patient_elbow_pain_q7":"رفع جسم ثقيل",
  "patient_elbow_pain_q7_a1":"لا ألم",
  "patient_elbow_pain_q7_a2":"ألم لا يطاق",
  "patient_elbow_pain_q8":"عند القيام بمهمة تستوجب حركات متكررة للمرفق",
  "patient_elbow_pain_q8_a1":"لا ألم",
  "patient_elbow_pain_q8_a2":"ألم لا يطاق",
  "patient_elbow_pain_q9":"أثناء الليل",
  "patient_elbow_pain_q9_a1":"لا ألم",
  "patient_elbow_pain_q9_a2":"ألم لا يطاق",
  "patient_elbow_pain_q10":"كم مرة عانيت من ألم من مرفقك في السرير بالليل؟",
  "patient_elbow_pain_q10_a1":"مُطْلَقاً",
  "patient_elbow_pain_q10_a2":"1-2 ليال",
  "patient_elbow_pain_q10_a3":"بعض الليالي",
  "patient_elbow_pain_q10_a4":"معظم الليالي",
  "patient_elbow_pain_q10_a5":"كل ليلة",
  "patient_elbow_pain_q11":"كم مرة أثر فيها ألم الكوع على نومك؟",
  "patient_elbow_pain_q11_a1":"مُطْلَقاً",
  "patient_elbow_pain_q11_a2":"أحياناً",
  "patient_elbow_pain_q11_a3":"بعض من الوقت",
  "patient_elbow_pain_q11_a4":"معظم الوقت",
  "patient_elbow_pain_q11_a5":"طوال الوقت (شعور بالألم دائمًا)",
  "patient_elbow_function":"الوظيفة",
  "patient_elbow_function_q1":"ما هو أفضل وصف لوظيفة مرفقك؟",
  "patient_elbow_function_q1_a1":"بلا قيود",
  "patient_elbow_function_q1_a2":"قيود طفيفة لا قيود في أنشطة الحياة اليومية",
  "patient_elbow_function_q1_a3":"غير قادر على رفع الأشياء> 4.5 كجم",
  "patient_elbow_function_q1_a4":"قيود معتدلة في أنشطة الحياة اليومية",
  "patient_elbow_function_q1_a5":"غير قادر على تمشيط الشعر أو لمس الرأس",
  "patient_elbow_function_q1_a6":"غير قادر على إطعام النفس",
  "patient_elbow_function_q2":"ما هو أفضل وصف للنشاط اللذي يمكنك القيام به؟",
  "patient_elbow_function_q2_a1":"استخدام غير محدود للمرفق",
  "patient_elbow_function_q2_a2":"استخدام محدود للترفيه",
  "patient_elbow_function_q2_a3":"يقتصر على الأنشطة المنزلية والتوظيفية",
  "patient_elbow_function_q2_a4":"قادرة على الاعتناء بالنفس",
  "patient_elbow_function_q2_a5":"غير صالح",
  "patient_elbow_function_q3":"كم من الوقت يمكنك استخدام مرفقك؟",
  "patient_elbow_function_q3_a1":"يمكنه أداء الأنشطة لمدة 30 دقيقة",
  "patient_elbow_function_q3_a2":"يمكنه أداء الأنشطة لمدة 15 دقيقة",
  "patient_elbow_function_q3_a3":"يمكنه أداء الأنشطة لمدة 5 دقائق",
  "patient_elbow_function_q3_a4":"لا يمكن استخدام  المرفق",
  "patient_elbow_function_instruction":"حدد درجة الصعوبة للقيام بالأنشطة التالية في الأسبوع الماضي بسبب مرفقك",
  "patient_elbow_function_instruction_0":"لا توجد صعوبة",
  "patient_elbow_function_instruction_1":"خفيف",
  "patient_elbow_function_instruction_2":"معتدل",
  "patient_elbow_function_instruction_3":"شديد",
  "patient_elbow_function_instruction_4":"غير قادر",
  "patient_elbow_function_q4":"فتح غطاء مرطبان ضيق أو جديد",
  "patient_elbow_function_q4_a1":"لا توجد صعوبة",
  "patient_elbow_function_q4_a2":"خفيف",
  "patient_elbow_function_q4_a3":"معتدل",
  "patient_elbow_function_q4_a4":"شديد",
  "patient_elbow_function_q4_a5":"غير قادر",
  "patient_elbow_function_q5":"حمل حقيبة تسوق أو محفظة",
  "patient_elbow_function_q5_a1":"لا توجد صعوبة",
  "patient_elbow_function_q5_a2":"خفيف",
  "patient_elbow_function_q5_a3":"معتدل",
  "patient_elbow_function_q5_a4":"شديد",
  "patient_elbow_function_q5_a5":"غير قادر",
  "patient_elbow_function_q6":"رفع الأشياء في منزلك مثل إخراج القمامة",
  "patient_elbow_function_q6_a1":"لا توجد صعوبة",
  "patient_elbow_function_q6_a2":"خفيف",
  "patient_elbow_function_q6_a3":"معتدل",
  "patient_elbow_function_q6_a4":"شديد",
  "patient_elbow_function_q6_a5":"غير قادر",
  "patient_elbow_function_q7":"غسل الظهر",
  "patient_elbow_function_q7_a1":"لا توجد صعوبة",
  "patient_elbow_function_q7_a2":"خفيف",
  "patient_elbow_function_q7_a3":"معتدل",
  "patient_elbow_function_q7_a4":"شديد",
  "patient_elbow_function_q7_a5":"غير قادر",
  "patient_elbow_function_q8":"إدارة استخدام المرحاض",
  "patient_elbow_function_q8_a1":"لا توجد صعوبة",
  "patient_elbow_function_q8_a2":"خفيف",
  "patient_elbow_function_q8_a3":"معتدل",
  "patient_elbow_function_q8_a4":"شديد",
  "patient_elbow_function_q8_a5":"غير قادر",
  "patient_elbow_function_q9":"غسل كامل بدنك",
  "patient_elbow_function_q9_a1":"لا توجد صعوبة",
  "patient_elbow_function_q9_a2":"خفيف",
  "patient_elbow_function_q9_a3":"معتدل",
  "patient_elbow_function_q9_a4":"شديد",
  "patient_elbow_function_q9_a5":"غير قادر",
  "patient_elbow_function_q10":"القيام بالنظافة الشخصية",
  "patient_elbow_function_q10_a1":"لا توجد صعوبة",
  "patient_elbow_function_q10_a2":"خفيف",
  "patient_elbow_function_q10_a3":"معتدل",
  "patient_elbow_function_q10_a4":"شديد",
  "patient_elbow_function_q10_a5":"غير قادر",
  "patient_elbow_function_q11":"استخدام السكين لتقطيع الطعام",
  "patient_elbow_function_q11_a1":"لا توجد صعوبة",
  "patient_elbow_function_q11_a2":"خفيف",
  "patient_elbow_function_q11_a3":"معتدل",
  "patient_elbow_function_q11_a4":"شديد",
  "patient_elbow_function_q11_a5":"غير قادر",
  "patient_elbow_function_q12":"تناول الطعام باستخدام ملعقة أو سكين وشوكة",
  "patient_elbow_function_q12_a1":"لا توجد صعوبة",
  "patient_elbow_function_q12_a2":"خفيف",
  "patient_elbow_function_q12_a3":"معتدل",
  "patient_elbow_function_q12_a4":"شديد",
  "patient_elbow_function_q12_a5":"غير قادر",
  "patient_elbow_function_q13":"الأنشطة الترفيهية التي تمارس فيها بعض القوة بإستعمال كتفك ذراعك أو يدك (على سبيل المثال ، غولف، استخدام المطرقة، التنس ، إلخ)",
  "patient_elbow_function_q13_a1":"لا توجد صعوبة",
  "patient_elbow_function_q13_a2":"خفيف",
  "patient_elbow_function_q13_a3":"معتدل",
  "patient_elbow_function_q13_a4":"شديد",
  "patient_elbow_function_q13_a5":"غير قادر",
  "patient_elbow_function_q14":"النوم بسبب ألم في كتف ذراعك أو يدك",
  "patient_elbow_function_q14_a1":"لا توجد صعوبة",
  "patient_elbow_function_q14_a2":"خفيف",
  "patient_elbow_function_q14_a3":"معتدل",
  "patient_elbow_function_q14_a4":"شديد",
  "patient_elbow_function_q14_a5":"غير قادر",
  "patient_elbow_function_q15":"لبس القميص",
  "patient_elbow_function_q15_a1":"لا توجد صعوبة",
  "patient_elbow_function_q15_a2":"خفيف",
  "patient_elbow_function_q15_a3":"معتدل",
  "patient_elbow_function_q15_a4":"شديد",
  "patient_elbow_function_q15_a5":"غير قادر",
  "patient_elbow_function_q16":"إغلاق الزر العلوي للقميص ",
  "patient_elbow_function_q16_a1":"لا توجد صعوبة",
  "patient_elbow_function_q16_a2":"خفيف",
  "patient_elbow_function_q16_a3":"معتدل",
  "patient_elbow_function_q16_a4":"شديد",
  "patient_elbow_function_q16_a5":"غير قادر",
  "patient_elbow_function_q17":"تلبيس نفسك",
  "patient_elbow_function_q17_a1":"لا توجد صعوبة",
  "patient_elbow_function_q17_a2":"خفيف",
  "patient_elbow_function_q17_a3":"معتدل",
  "patient_elbow_function_q17_a4":"شديد",
  "patient_elbow_function_q17_a5":"غير قادر",
  "patient_elbow_function_q18":"تمشيط الشعر",
  "patient_elbow_function_q18_a1":"لا توجد صعوبة",
  "patient_elbow_function_q18_a2":"خفيف",
  "patient_elbow_function_q18_a3":"معتدل",
  "patient_elbow_function_q18_a4":"شديد",
  "patient_elbow_function_q18_a5":"غير قادر",
  "patient_elbow_function_q19":"لبس الحذاء",
  "patient_elbow_function_q19_a1":"لا توجد صعوبة",
  "patient_elbow_function_q19_a2":"خفيف",
  "patient_elbow_function_q19_a3":"معتدل",
  "patient_elbow_function_q19_a4":"شديد",
  "patient_elbow_function_q19_a5":"غير قادر",
  "patient_elbow_function_q20":"اربط رباط الحذاء",
  "patient_elbow_function_q20_a1":"لا توجد صعوبة",
  "patient_elbow_function_q20_a2":"خفيف",
  "patient_elbow_function_q20_a3":"معتدل",
  "patient_elbow_function_q20_a4":"شديد",
  "patient_elbow_function_q20_a5":"غير قادر",
  "patient_elbow_function_q21":"احمل شيئًا ثقيلًا",
  "patient_elbow_function_q21_a1":"لا توجد صعوبة",
  "patient_elbow_function_q21_a2":"خفيف",
  "patient_elbow_function_q21_a3":"معتدل",
  "patient_elbow_function_q21_a4":"شديد",
  "patient_elbow_function_q21_a5":"غير قادر",
  "patient_elbow_function_q22":"القيام من الكرسي دافعًا بذراعك",
  "patient_elbow_function_q22_a1":"لا توجد صعوبة",
  "patient_elbow_function_q22_a2":"خفيف",
  "patient_elbow_function_q22_a3":"معتدل",
  "patient_elbow_function_q22_a4":"شديد",
  "patient_elbow_function_q22_a5":"غير قادر",
  "patient_elbow_function_q23":"القيام بالأعمال المنزلية الشاقة (مثل غسل الأرضيات و الجدران)",
  "patient_elbow_function_q23_a1":"لا توجد صعوبة",
  "patient_elbow_function_q23_a2":"خفيف",
  "patient_elbow_function_q23_a3":"معتدل",
  "patient_elbow_function_q23_a4":"شديد",
  "patient_elbow_function_q23_a5":"غير قادر",
  "patient_elbow_function_q24":"برم المفتاح",
  "patient_elbow_function_q24_a1":"لا توجد صعوبة",
  "patient_elbow_function_q24_a2":"خفيف",
  "patient_elbow_function_q24_a3":"معتدل",
  "patient_elbow_function_q24_a4":"شديد",
  "patient_elbow_function_q24_a5":"غير قادر",
  "patient_elbow_function_q25":"رمي الكرة",
  "patient_elbow_function_q25_a1":"لا توجد صعوبة",
  "patient_elbow_function_q25_a2":"خفيف",
  "patient_elbow_function_q25_a3":"معتدل",
  "patient_elbow_function_q25_a4":"شديد",
  "patient_elbow_function_q25_a5":"غير قادر",
  "patient_elbow_function_q26":"القيام بعملك المعتاد بسبب ألم الذراع أو الكتف أو اليد  (يشمل العمل التدبير المنزلي إذا كان هذا هو دورك الرئيسي)",
  "patient_elbow_function_q26_a1":"لا توجد صعوبة",
  "patient_elbow_function_q26_a2":"خفيف",
  "patient_elbow_function_q26_a3":"معتدل",
  "patient_elbow_function_q26_a4":"شديد",
  "patient_elbow_function_q26_a5":"غير قادر",
  "patient_elbow_function_q27":"حدد العمل المعتاد",
  "patient_elbow_function_q27_placeholder":"حدد العمل",
  "patient_elbow_function_i_donot_work":"انا لا اعمل",
  "patient_elbow_function_q28":"استخدام أسلوبك المعتاد في العمل",
  "patient_elbow_function_q28_a1":"لا توجد صعوبة",
  "patient_elbow_function_q28_a2":"خفيف",
  "patient_elbow_function_q28_a3":"معتدل",
  "patient_elbow_function_q28_a4":"شديد",
  "patient_elbow_function_q28_a5":"غير قادر",
  "patient_elbow_function_q29":"القيام بعملك كما تريد",
  "patient_elbow_function_q29_a1":"لا توجد صعوبة",
  "patient_elbow_function_q29_a2":"خفيف",
  "patient_elbow_function_q29_a3":"معتدل",
  "patient_elbow_function_q29_a4":"شديد",
  "patient_elbow_function_q29_a5":"غير قادر",
  "patient_elbow_function_q30":"قضاء الوقت المعتاد في أداء عملك",
  "patient_elbow_function_q30_a1":"لا توجد صعوبة",
  "patient_elbow_function_q30_a2":"خفيف",
  "patient_elbow_function_q30_a3":"معتدل",
  "patient_elbow_function_q30_a4":"شديد",
  "patient_elbow_function_q30_a5":"غير قادر",
  "patient_elbow_function_q31":"العزف على آلتك الموسيقية أو الرياضة بسبب ألم الذراع أو الكتف أو اليد",
  "patient_elbow_function_q31_a1":"لا توجد صعوبة",
  "patient_elbow_function_q31_a2":"خفيف",
  "patient_elbow_function_q31_a3":"معتدل",
  "patient_elbow_function_q31_a4":"شديد",
  "patient_elbow_function_q31_a5":"غير قادر",
  "patient_elbow_function_q32":"حدد الرياضة أو الآلة الموسيقية التي يتم عزفها",
  "patient_elbow_function_q32_placeholder":"حدد آلة رياضية / موسيقية",
  "patient_elbow_function_i_donot_play":"انا لا اعزف على أي آلة لا أمارس أي رياضة",
  "patient_elbow_function_q33":"استخدام أسلوبك المعتاد في العزف على آلتك الموسيقية أو الرياضة",
  "patient_elbow_function_q33_a1":"لا توجد صعوبة",
  "patient_elbow_function_q33_a2":"خفيف",
  "patient_elbow_function_q33_a3":"معتدل",
  "patient_elbow_function_q33_a4":"شديد",
  "patient_elbow_function_q33_a5":"غير قادر",
  "patient_elbow_function_q34":"العزف على آلتك الموسيقية أو الرياضة كما تريد",
  "patient_elbow_function_q34_a1":"لا توجد صعوبة",
  "patient_elbow_function_q34_a2":"خفيف",
  "patient_elbow_function_q34_a3":"معتدل",
  "patient_elbow_function_q34_a4":"شديد",
  "patient_elbow_function_q34_a5":"غير قادر",
  "patient_elbow_function_q35":"قضاء وقتك المعتاد في  العزف على آلتك أو ممارسة رياضتك ",
  "patient_elbow_function_q35_a1":"لا توجد صعوبة",
  "patient_elbow_function_q35_a2":"خفيف",
  "patient_elbow_function_q35_a3":"معتدل",
  "patient_elbow_function_q35_a4":"شديد",
  "patient_elbow_function_q35_a5":"غير قادر",
  "patient_elbow_function_q36":"خلال الأسبوع الماضي إلى أي مدى تداخلت مشكلة كتف ذراعك أو يدك مع أنشطتك الاجتماعية / الترفيهية العادية مع الأصدقاء والعائلة والجيران أو المجموعات؟",
  "patient_elbow_function_q36_a1":"مُطْلَقاً",
  "patient_elbow_function_q36_a2":"طفيف",
  "patient_elbow_function_q36_a3":"باعتدال",
  "patient_elbow_function_q36_a4":"إلى حد ما",
  "patient_elbow_function_q36_a5":"لأقصى حد",
  "patient_elbow_function_q37":"خلال الأسبوع الماضي ، هل كنت مقيدًا في عملك أو الأنشطة اليومية العادية الأخرى نتيجة لمشكلة ذراعك أو كتفك أو يدك؟",
  "patient_elbow_function_q37_a1":"مُطْلَقاً",
  "patient_elbow_function_q37_a2":"طفيف",
  "patient_elbow_function_q37_a3":"باعتدال",
  "patient_elbow_function_q37_a4":"إلى حد ما",
  "patient_elbow_function_q37_a5":"لأقصى حد",
  "patient_elbow_symptoms":"أعراض أخرى",
  "patient_elbow_symptoms_instruction":"يرجى تقييم شدة الأعراض التالية في الأسبوع الماضي",
  "patient_elbow_symptoms_instruction_0":"لا شيء",
  "patient_elbow_symptoms_instruction_1":"خفيف",
  "patient_elbow_symptoms_instruction_2":"معتدل",
  "patient_elbow_symptoms_instruction_3":"شديد",
  "patient_elbow_symptoms_instruction_4":"أقصى",
  "patient_elbow_symptoms_q1":"ألم في الذراع أو الكتف أو اليد",
  "patient_elbow_symptoms_q1_a1":"لا شيء",
  "patient_elbow_symptoms_q1_a2":"خفيف",
  "patient_elbow_symptoms_q1_a3":"معتدل",
  "patient_elbow_symptoms_q1_a4":"شديد",
  "patient_elbow_symptoms_q1_a5":"أقصى",
  "patient_elbow_symptoms_q2":"وخز (دبابيس وإبر) في ذراعك أو كتفك أو يدك",
  "patient_elbow_symptoms_q2_a1":"لا شيء",
  "patient_elbow_symptoms_q2_a2":"خفيف",
  "patient_elbow_symptoms_q2_a3":"معتدل",
  "patient_elbow_symptoms_q2_a4":"شديد",
  "patient_elbow_symptoms_q2_a5":"أقصى",
  "patient_elbow_symptoms_q3":"كيف تقيم مرفقك اليوم كنسبة مئوية من الطبيعي؟ (0-100٪ مع كون 100٪ طبيعي)",
  "patient_elbow_quality_of_life":"جودة الحياة",
  "patient_elbow_quality_of_life_instruction":"تسأل الأسئلة التالية عن المخاوف الاجتماعية والعاطفية والمتعلقة بنمط الحياة التي قد تشعر بها فيما يتعلق بمشكلة مرفقك. يرجى التفكير في ما شعرت به معظم الوقت فيما يتعلق بمرفقك.",
  "patient_elbow_quality_of_life_q1":"خلال الأسابيع الأربعة الماضية ، هل شعرت أن مشكلة المرفق لديك \"تحدد حياتك\"؟ ",
  "patient_elbow_quality_of_life_q1_a1":"مُطْلَقاً",
  "patient_elbow_quality_of_life_q1_a2":"أحياناً",
  "patient_elbow_quality_of_life_q1_a3":"بعض أيام",
  "patient_elbow_quality_of_life_q1_a4":"أغلب الأيام",
  "patient_elbow_quality_of_life_q1_a5":"كل يوم",
  "patient_elbow_quality_of_life_q2":"خلال الأسابيع الأربعة الماضية ، إلى أي مدى كان مرفقك \"في ذهنك\"؟",
  "patient_elbow_quality_of_life_q2_a1":"مُطْلَقاً",
  "patient_elbow_quality_of_life_q2_a2":"القليل من الوقت",
  "patient_elbow_quality_of_life_q2_a3":"بعض من الوقت",
  "patient_elbow_quality_of_life_q2_a4":"معظم الوقت",
  "patient_elbow_quality_of_life_q2_a5":"كل الوقت",
  "patient_elbow_quality_of_life_instruction1":"يرجى تقييم ما يلي لوصف صحتك اليوم",
  "patient_elbow_quality_of_life_instruction1_0":"لا مشكلة",
  "patient_elbow_quality_of_life_instruction1_1":"طفيف",
  "patient_elbow_quality_of_life_instruction1_2":"معتدل",
  "patient_elbow_quality_of_life_instruction1_3":"شديد",
  "patient_elbow_quality_of_life_instruction1_4":"ألم شديد / غير قادر",
  "patient_elbow_quality_of_life_q3":"مشكلة في المشي",
  "patient_elbow_quality_of_life_q3_a1":"لا مشكلة",
  "patient_elbow_quality_of_life_q3_a2":"طفيف",
  "patient_elbow_quality_of_life_q3_a3":"معتدل",
  "patient_elbow_quality_of_life_q3_a4":"شديد",
  "patient_elbow_quality_of_life_q3_a5":"غير قادر",
  "patient_elbow_quality_of_life_q4":"مشكلة في الغسيل أو ارتداء الملابس بنفسك",
  "patient_elbow_quality_of_life_q4_a1":"لا مشكلة",
  "patient_elbow_quality_of_life_q4_a2":"طفيف",
  "patient_elbow_quality_of_life_q4_a3":"معتدل",
  "patient_elbow_quality_of_life_q4_a4":"شديد",
  "patient_elbow_quality_of_life_q4_a5":"غير قادر",
  "patient_elbow_quality_of_life_q5":"مشكلة في القيام بالأنشطة المعتادة (على سبيل المثال ،  الدراسة ، العمل المنزلي ، الأسرة أو الأنشطة الترفيهية)",
  "patient_elbow_quality_of_life_q5_a1":"لا مشكلة",
  "patient_elbow_quality_of_life_q5_a2":"طفيف",
  "patient_elbow_quality_of_life_q5_a3":"معتدل",
  "patient_elbow_quality_of_life_q5_a4":"شديد",
  "patient_elbow_quality_of_life_q5_a5":"غير قادر",
  "patient_elbow_quality_of_life_q6":"ألم أو انزعاج",
  "patient_elbow_quality_of_life_q6_a1":"لا شيء",
  "patient_elbow_quality_of_life_q6_a2":"طفيف",
  "patient_elbow_quality_of_life_q6_a3":"معتدل",
  "patient_elbow_quality_of_life_q6_a4":"شديد",
  "patient_elbow_quality_of_life_q6_a5":"أقصى",
  "patient_elbow_quality_of_life_q7":"القلق / الاكتئاب",
  "patient_elbow_quality_of_life_q7_a1":"غير قلق / مكتئب",
  "patient_elbow_quality_of_life_q7_a2":"قلق / مكتئب قليلاً",
  "patient_elbow_quality_of_life_q7_a3":"قلق / اكتئاب معتدل",
  "patient_elbow_quality_of_life_q7_a4":"متوتر/مكتئب بشكل شديد",
  "patient_elbow_quality_of_life_q7_a5":"متوتر/مكتئب للغاية",
  "patient_elbow_quality_of_life_q8":"ما مدى جودة أو سوء صحتك اليوم؟",
  "patient_elbow_quality_of_life_q8_instruction":"100 تعني أفضل صحة ، 0 تعني أسوأ صحة يمكنك تخيلها",
  "patient_elbow_quality_of_life_q8_a1":"أسوأ",
  "patient_elbow_quality_of_life_q8_a2":"أفضل",
  "patient_elbow_satisfaction":"الرضا",
  "patient_elbow_satisfaction_q1":"هل أنت راضٍ عن علاج مرفقك؟",
  "patient_elbow_satisfaction_na":"غير قابل للتطبيق",
  "patient_elbow_satisfaction_q1_a1":"غير راضٍ على الإطلاق",
  "patient_elbow_satisfaction_q1_a2":"راض جدا",
  "patient_elbow_complete":"نهاية الاستبيان",
  "patient_elbow_complete_text1":"شكرًا لك على الوقت الذي أمضيته في ملء هذا النموذج.",
  "patient_elbow_complete_text2":"الرجاء النقر فوق إرسال لإكمال ورؤية درجاتك.",
  "patient_elbow_complete_text3":"سيتم التعامل مع إجاباتك بسرية ولن تستخدم أي تقارير سوى معلومات مجهولة الهوية",
  "patient_elbow_results":"النتائج",
  "patient_elbow_results_text1":"فيما يلي درجات الألم ووظيفة المرفق ، والتي يتم حسابها من الاستبيان الخاص بك.",
  "patient_elbow_results_function":"الوظيفة",
  "patient_elbow_results_pain":"الألم",
  "patient_elbow_results_health_perception":"التصور الصحي العام",
  "patient_elbow_results_self_evaluation":"التقييم الذاتي لالمرفق",
  "patient_elbow_results_text4":"سيكون الطبيب معك قريبًا",
  "patient_hand_pain":"الألم",
  "patient_hand_pain_q1":"صف الألم في يدك / معصمك في الأسبوع الماضي؟",
  "patient_hand_pain_q1_a1":"لا شيء إلى معتدل جدا",
  "patient_hand_pain_q1_a2":"خفيف",
  "patient_hand_pain_q1_a3":"معتدل",
  "patient_hand_pain_q1_a4":"شديد",
  "patient_hand_pain_q1_a5":"شديد جدا / شديد",
  "patient_hand_pain_q2":"ما مدى سوء الألم في يدك / معصمك في هذه اللحظة؟",
  "patient_hand_pain_q2_a1":"لا ألم",
  "patient_hand_pain_q2_a2":"أسوأ الألم",
  "patient_hand_pain_q3":"ما كان تأثير الألم في يدك / معصمك على  أنشطتك اليومية (مثل الأكل أو الاستحمام) في الأسبوع الماضي؟ ",
  "patient_hand_pain_q3_a1":"أبداً",
  "patient_hand_pain_q3_a2":"نادراً",
  "patient_hand_pain_q3_a3":"أحيانًا",
  "patient_hand_pain_q3_a4":"غالبًا ما",
  "patient_hand_pain_q3_a5":"دائمًا",
  "patient_hand_symptoms":"أعراض أخرى",
  "patient_hand_symptoms_q1":"ما مدى شدة التنميل (الوخز) في كتفك ذراعك أو يدك في الأسبوع الماضي؟",
  "patient_hand_symptoms_q1_a1":"لا شيء",
  "patient_hand_symptoms_q1_a2":"خفيف",
  "patient_hand_symptoms_q1_a3":"معتدل",
  "patient_hand_symptoms_q1_a4":"شديد",
  "patient_hand_symptoms_q1_a5":"أقصى",
  "patient_hand_symptoms_q2":"كيف كان الإحساس (الشعور) في يدك خلال الأسبوع الماضي؟",
  "patient_hand_symptoms_q2_a1":"جيد جدًا",
  "patient_hand_symptoms_q2_a2":"جيد",
  "patient_hand_symptoms_q2_a3":"وسط",
  "patient_hand_symptoms_q2_a4":"رديئة",
  "patient_hand_symptoms_q2_a5":"رديئة جدا",
  "patient_hand_symptoms_q3":"ما مقدار تأثير شكل اليد في وظائفك اليومية؟",
  "patient_hand_symptoms_q3_a1":"لا أوافق بشدة",
  "patient_hand_symptoms_q3_a2":"غير متفق مع ذلك",
  "patient_hand_symptoms_q3_a3":"لا أوافق ولا أرفض",
  "patient_hand_symptoms_q3_a4":"موافق",
  "patient_hand_symptoms_q3_a5":"موافق بشدة",
  "patient_hand_symptoms_q3_a6":"كيف تقيم يدك / معصمك اليوم كنسبة مئوية من المعدل الطبيعي؟ (0-100٪ مع كون 100٪ طبيعي)",
  "patient_hand_symptoms_q3_a6_normal":"Normal",
  "patient_hand_function":"الوظيفة",
  "patient_hand_function_instruction1":"إذا لم تقم بمهمة معينة ، فيرجى تقدير مدى الصعوبة التي ستواجهها في تنفيذها.",
  "patient_hand_function_instruction2":"إذا لم تكن متأكدًا من كيفية الإجابة على سؤال ، فيرجى إعطائه أفضل إجابة ممكنة.",
  "patient_hand_function_q1":"بشكل عام ، ما مدى جودة عمل يدك خلال الأسبوع الماضي؟",
  "patient_hand_function_q1_a1":"جيد جدًا",
  "patient_hand_function_q1_a2":"جيد",
  "patient_hand_function_q1_a3":"وسط",
  "patient_hand_function_q1_a4":"رديئة",
  "patient_hand_function_q1_a5":"رديئة جدا",
  "patient_hand_function_q2":"كم مرة كنت غير قادر على القيام بعملك بسبب مشاكل في يدك / معصمك؟",
  "patient_hand_function_q2_a1":"أبداً",
  "patient_hand_function_q2_a2":"نادراً",
  "patient_hand_function_q2_a3":"أحيانًا",
  "patient_hand_function_q2_a4":"غالبًا ما",
  "patient_hand_function_q2_a5":"دائمًا",
  "patient_hand_function_q3":"كم مرة استغرقت وقتًا أطول في أداء المهام في عملك بسبب مشاكل في يدك / معصمك؟",
  "patient_hand_function_q3_a1":"أبداً",
  "patient_hand_function_q3_a2":"نادراً",
  "patient_hand_function_q3_a3":"أحيانًا",
  "patient_hand_function_q3_a4":"غالبًا ما",
  "patient_hand_function_q3_a5":"دائمًا",
  "patient_hand_function_instruction":"حدد درجة الصعوبة للقيام بالأنشطة التالية في الأسبوع الماضي بسبب مشاكل في يدك / معصمك",
  "patient_hand_function_instruction_0":"لا توجد صعوبة",
  "patient_hand_function_instruction_1":"صعوبة خفيفة",
  "patient_hand_function_instruction_2":"صعوبة متوسطة",
  "patient_hand_function_instruction_3":"صعوبة شديدة",
  "patient_hand_function_instruction_4":"صعوبة بالغة/غير قادر",
  "patient_hand_function_q4":"فتح غطاء مرطبان ضيق أو جديد",
  "patient_hand_function_q4_a1":"لا توجد صعوبة",
  "patient_hand_function_q4_a2":"صعوبة خفيفة",
  "patient_hand_function_q4_a3":"صعوبة متوسطة",
  "patient_hand_function_q4_a4":"صعوبة شديدة",
  "patient_hand_function_q4_a5":"صعوبة بالغة/غير قادر",
  "patient_hand_function_q5":"مسك مقلاة",
  "patient_hand_function_q5_a1":"لا توجد صعوبة",
  "patient_hand_function_q5_a2":"صعوبة خفيفة",
  "patient_hand_function_q5_a3":"صعوبة متوسطة",
  "patient_hand_function_q5_a4":"صعوبة شديدة",
  "patient_hand_function_q5_a5":"صعوبة بالغة/غير قادر",
  "patient_hand_function_q6":"إغلاق زر قميص أو بلوزة",
  "patient_hand_function_q6_a1":"لا توجد صعوبة",
  "patient_hand_function_q6_a2":"صعوبة خفيفة",
  "patient_hand_function_q6_a3":"صعوبة متوسطة",
  "patient_hand_function_q6_a4":"صعوبة شديدة",
  "patient_hand_function_q6_a5":"صعوبة بالغة/غير قادر",
  "patient_hand_function_q7":"القيام بالأعمال المنزلية الشاقة (مثل غسل الأرضيات و الجدران)",
  "patient_hand_function_q7_a1":"لا توجد صعوبة",
  "patient_hand_function_q7_a2":"صعوبة خفيفة",
  "patient_hand_function_q7_a3":"صعوبة متوسطة",
  "patient_hand_function_q7_a4":"صعوبة شديدة",
  "patient_hand_function_q7_a5":"صعوبة بالغة/غير قادر",
  "patient_hand_function_q8":"حمل حقيبة تسوق أو محفظة",
  "patient_hand_function_q8_a1":"لا توجد صعوبة",
  "patient_hand_function_q8_a2":"صعوبة خفيفة",
  "patient_hand_function_q8_a3":"صعوبة متوسطة",
  "patient_hand_function_q8_a4":"صعوبة شديدة",
  "patient_hand_function_q8_a5":"صعوبة بالغة/غير قادر",
  "patient_hand_function_q9":"غسل الظهر",
  "patient_hand_function_q9_a1":"لا توجد صعوبة",
  "patient_hand_function_q9_a2":"صعوبة خفيفة",
  "patient_hand_function_q9_a3":"صعوبة متوسطة",
  "patient_hand_function_q9_a4":"صعوبة شديدة",
  "patient_hand_function_q9_a5":"صعوبة بالغة / غير قادر",
  "patient_hand_function_q10":"استخدام السكين لتقطيع الطعام",
  "patient_hand_function_q10_a1":"لا توجد صعوبة",
  "patient_hand_function_q10_a2":"صعوبة خفيفة",
  "patient_hand_function_q10_a3":"صعوبة متوسطة",
  "patient_hand_function_q10_a4":"صعوبة شديدة",
  "patient_hand_function_q10_a5":"صعوبة بالغة/غير قادر",
  "patient_hand_function_q11":"الأنشطة الترفيهية التي تمارس فيها بعض القوة بإستعمال كتفك ذراعك أو يدك (على سبيل المثال ، غولف، استخدام المطرقة، التنس ، إلخ)",
  "patient_hand_function_q11_a1":"لا توجد صعوبة",
  "patient_hand_function_q11_a2":"صعوبة خفيفة",
  "patient_hand_function_q11_a3":"صعوبة متوسطة",
  "patient_hand_function_q11_a4":"صعوبة شديدة",
  "patient_hand_function_q11_a5":"صعوبة بالغة/غير قادر",
  "patient_hand_function_q12":"النوم بسبب ألم في كتف ذراعك أو يدك",
  "patient_hand_function_q12_a1":"لا توجد صعوبة",
  "patient_hand_function_q12_a2":"صعوبة خفيفة",
  "patient_hand_function_q12_a3":"صعوبة متوسطة",
  "patient_hand_function_q12_a4":"صعوبة شديدة",
  "patient_hand_function_q12_a5":"صعوبة بالغة/غير قادر",
  "patient_hand_function_q13":"القيام بعملك المعتاد بسبب ألم الذراع أو الكتف أو اليد (يشمل العمل التدبير المنزلي إذا كان هذا هو دورك الرئيسي)",
  "patient_hand_function_q13_a1":"لا توجد صعوبة",
  "patient_hand_function_q13_a2":"صعوبة خفيفة",
  "patient_hand_function_q13_a3":"صعوبة متوسطة",
  "patient_hand_function_q13_a4":"صعوبة شديدة",
  "patient_hand_function_q13_a5":"صعوبة بالغة/غير قادر",
  "patient_hand_function_q14":"حدد العمل المعتاد",
  "patient_hand_function_q14_placeholder":"حدد العمل",
  "patient_hand_function_do_dot_work":"انا لا اعمل",
  "patient_hand_function_q15":"استخدام أسلوبك المعتاد في العمل",
  "patient_hand_function_q15_a1":"لا توجد صعوبة",
  "patient_hand_function_q15_a2":"صعوبة خفيفة",
  "patient_hand_function_q15_a3":"صعوبة متوسطة",
  "patient_hand_function_q15_a4":"صعوبة شديدة",
  "patient_hand_function_q15_a5":"صعوبة بالغة/غير قادر",
  "patient_hand_function_q16":"القيام بعملك كما تريد",
  "patient_hand_function_q16_a1":"لا توجد صعوبة",
  "patient_hand_function_q16_a2":"صعوبة خفيفة",
  "patient_hand_function_q16_a3":"صعوبة متوسطة",
  "patient_hand_function_q16_a4":"صعوبة شديدة",
  "patient_hand_function_q16_a5":"صعوبة بالغة/غير قادر",
  "patient_hand_function_q17":"قضاء الوقت المعتاد في أداء عملك",
  "patient_hand_function_q17_a1":"لا توجد صعوبة",
  "patient_hand_function_q17_a2":"صعوبة خفيفة",
  "patient_hand_function_q17_a3":"صعوبة متوسطة",
  "patient_hand_function_q17_a4":"صعوبة شديدة",
  "patient_hand_function_q17_a5":"صعوبة بالغة/غير قادر",
  "patient_hand_function_q18":"العزف على آلتك الموسيقية أو الرياضة بسبب ألم الذراع أو الكتف أو اليد",
  "patient_hand_function_q18_a1":"لا توجد صعوبة",
  "patient_hand_function_q18_a2":"صعوبة خفيفة",
  "patient_hand_function_q18_a3":"صعوبة متوسطة",
  "patient_hand_function_q18_a4":"صعوبة شديدة",
  "patient_hand_function_q18_a5":"صعوبة بالغة/غير قادر",
  "patient_hand_function_q19":"حدد الرياضة أو الآلة الموسيقية التي يتم عزفها",
  "patient_hand_function_q19_placeholder":"حدد آلة رياضية / موسيقية",
  "patient_hand_function_donot_play":"انا لا اعزف على أي آلة لا أمارس أي رياضة",
  "patient_hand_function_20":"استخدام أسلوبك المعتاد في العزف على آلتك الموسيقية أو الرياضة",
  "patient_hand_function_q20_a1":"لا توجد صعوبة",
  "patient_hand_function_q20_a2":"صعوبة خفيفة",
  "patient_hand_function_q20_a3":"صعوبة متوسطة",
  "patient_hand_function_q20_a4":"صعوبة شديدة",
  "patient_hand_function_q20_a5":"صعوبة بالغة/غير قادر",
  "patient_hand_function_q21":"العزف على آلتك الموسيقية أو الرياضة كما تريد",
  "patient_hand_function_q21_a1":"لا توجد صعوبة",
  "patient_hand_function_q21_a2":"صعوبة خفيفة",
  "patient_hand_function_q21_a3":"صعوبة متوسطة",
  "patient_hand_function_q21_a4":"صعوبة شديدة",
  "patient_hand_function_q21_a5":"صعوبة بالغة/غير قادر",
  "patient_hand_function_q22":"قضاء وقتك المعتاد في  العزف على آلتك أو ممارسة رياضتك ",
  "patient_hand_function_q22_a1":"لا توجد صعوبة",
  "patient_hand_function_q22_a2":"صعوبة خفيفة",
  "patient_hand_function_q22_a3":"صعوبة متوسطة",
  "patient_hand_function_q22_a4":"صعوبة شديدة",
  "patient_hand_function_q22_a5":"صعوبة بالغة/غير قادر",
  "patient_hand_function_q23":"خلال الأسبوع الماضي إلى أي مدى تداخلت مشكلة كتف ذراعك أو يدك مع أنشطتك الاجتماعية / الترفيهية العادية مع الأصدقاء والعائلة والجيران أو المجموعات؟",
  "patient_hand_function_q23_a1":"مُطْلَقاً",
  "patient_hand_function_q23_a2":"طفيف",
  "patient_hand_function_q23_a3":"باعتدال",
  "patient_hand_function_q23_a4":"إلى حد ما",
  "patient_hand_function_q23_a5":"لأقصى حد",
  "patient_hand_function_q24":"خلال الأسبوع الماضي ، هل كنت مقيدًا في عملك أو في الأنشطة اليومية العادية الأخرى نتيجة لمشكلة ذراعك أو يدك؟",
  "patient_hand_function_q24_a1":"مُطْلَقاً",
  "patient_hand_function_q24_a2":"طفيف",
  "patient_hand_function_q24_a3":"باعتدال",
  "patient_hand_function_q24_a4":"إلى حد ما",
  "patient_hand_function_q24_a5":"لأقصى حد",
  "patient_hand_questionnaire":"استبيان بوسطن لمتلازمة نفق الرسغ",
  "patient_hand_questionnaire_symptom":"الأعراض",
  "patient_hand_questionnaire_symptom_instruction":"الرجاء اختيار أفضل إجابة تتناسب مع أعراضك.",
  "patient_hand_questionnaire_symptom_instruction_0":"طبيعي",
  "patient_hand_questionnaire_symptom_instruction_1":"طفيف",
  "patient_hand_questionnaire_symptom_instruction_2":"medium",
  "patient_hand_questionnaire_symptom_instruction_3":"شديد",
  "patient_hand_questionnaire_symptom_instruction_4":"بالغ الشدة",
  "patient_hand_questionnaire_symptom_q1":"ما مدى شدة ألم اليد أو المعصم الذي تشعر به في الليل؟",
  "patient_hand_questionnaire_symptom_q1_a1":"طبيعي",
  "patient_hand_questionnaire_symptom_q1_a2":"طفيف",
  "patient_hand_questionnaire_symptom_q1_a3":"متوسط",
  "patient_hand_questionnaire_symptom_q1_a4":"شديد",
  "patient_hand_questionnaire_symptom_q1_a5":"جدي جدا",
  "patient_hand_questionnaire_symptom_q2":"كم مرة أيقظك ألم اليد أو المعصم خلال ليلة عادية في الأسبوعين الماضيين؟",
  "patient_hand_questionnaire_symptom_q2_a1":"طبيعي",
  "patient_hand_questionnaire_symptom_q2_a2":"مرة واحدة",
  "patient_hand_questionnaire_symptom_q2_a3":"2-3 مرات",
  "patient_hand_questionnaire_symptom_q2_a4":"4-5 مرات",
  "patient_hand_questionnaire_symptom_q2_a5":"أكثر من 5 مرات",
  "patient_hand_questionnaire_symptom_q3":"هل تشعر عادةً بألم في يدك أو معصمك أثناء النهار؟",
  "patient_hand_questionnaire_symptom_q3_a1":"لا ألم",
  "patient_hand_questionnaire_symptom_q3_a2":"طفيف",
  "patient_hand_questionnaire_symptom_q3_a3":"متوسط",
  "patient_hand_questionnaire_symptom_q3_a4":"شديد",
  "patient_hand_questionnaire_symptom_q3_a5":"جدي جدا",
  "patient_hand_questionnaire_symptom_q4":"كم مرة تشعر بألم في اليد أو الرسغ أثناء النهار؟",
  "patient_hand_questionnaire_symptom_q4_a1":"طبيعي",
  "patient_hand_questionnaire_symptom_q4_a2":"1-2 مرات / يوم",
  "patient_hand_questionnaire_symptom_q4_a3":"3-5 مرات / يوم",
  "patient_hand_questionnaire_symptom_q4_a4":"أكثر من 5 مرات / يوم",
  "patient_hand_questionnaire_symptom_q4_a5":"مستمر",
  "patient_hand_questionnaire_symptom_q5":"ما هي المدة التي تستغرقها نوبة الألم في المتوسط أثناء النهار؟",
  "patient_hand_questionnaire_symptom_q5_a1":"طبيعي",
  "patient_hand_questionnaire_symptom_q5_a2":"أقل من 10 دقائق",
  "patient_hand_questionnaire_symptom_q5_a3":"10-60 دقيقة متواصلة",
  "patient_hand_questionnaire_symptom_q5_a4":"> 60 دقيقة",
  "patient_hand_questionnaire_symptom_q5_a5":"مستمر",
  "patient_hand_questionnaire_symptom_q6":"هل تعاني من تنميل (فقدان الإحساس) في يدك",
  "patient_hand_questionnaire_symptom_q6_a1":"طبيعي",
  "patient_hand_questionnaire_symptom_q6_a2":"طفيف",
  "patient_hand_questionnaire_symptom_q6_a3":"متوسط",
  "patient_hand_questionnaire_symptom_q6_a4":"شديد",
  "patient_hand_questionnaire_symptom_q6_a5":"بالغ الشدة",
  "patient_hand_questionnaire_symptom_q7":"هل تعاني من ضعف في يدك أو معصمك؟",
  "patient_hand_questionnaire_symptom_q7_a1":"طبيعي",
  "patient_hand_questionnaire_symptom_q7_a2":"طفيف",
  "patient_hand_questionnaire_symptom_q7_a3":"متوسط",
  "patient_hand_questionnaire_symptom_q7_a4":"شديد",
  "patient_hand_questionnaire_symptom_q7_a5":"بالغ الشدة",
  "patient_hand_questionnaire_symptom_q8":"هل تشعر بوخز في يدك؟",
  "patient_hand_questionnaire_symptom_q8_a1":"طبيعي",
  "patient_hand_questionnaire_symptom_q8_a2":"طفيف",
  "patient_hand_questionnaire_symptom_q8_a3":"متوسط",
  "patient_hand_questionnaire_symptom_q8_a4":"شديد",
  "patient_hand_questionnaire_symptom_q8_a5":"بالغ الشدة",
  "patient_hand_questionnaire_symptom_q9":"ما مدى شدة التنميل (فقدان الإحساس) أو الوخز في الليل؟",
  "patient_hand_questionnaire_symptom_q9_a1":"طبيعي",
  "patient_hand_questionnaire_symptom_q9_a2":"طفيف",
  "patient_hand_questionnaire_symptom_q9_a3":"متوسط",
  "patient_hand_questionnaire_symptom_q9_a4":"شديد",
  "patient_hand_questionnaire_symptom_q9_a5":"بالغ الشدة",
  "patient_hand_questionnaire_symptom_q10":"كم مرة أيقظك خدر اليدين أو الوخز خلال ليلة عادية خلال الأسبوعين الماضيين؟",
  "patient_hand_questionnaire_symptom_q10_a1":"طبيعي",
  "patient_hand_questionnaire_symptom_q10_a2":"مرة واحدة",
  "patient_hand_questionnaire_symptom_q10_a3":"2-3 مرات",
  "patient_hand_questionnaire_symptom_q10_a4":"4-5 مرات",
  "patient_hand_questionnaire_symptom_q10_a5":"أكثر من 5 مرات",
  "patient_hand_questionnaire_function":"الوظيفة",
  "patient_hand_questionnaire_function_instructions":"قيم درجة الصعوبة في القيام بالأنشطة التالية بسبب أعراضك:",
  "patient_hand_questionnaire_function_instructions_0":"لا توجد صعوبة",
  "patient_hand_questionnaire_function_instructions_1":"القليل من الصعوبة",
  "patient_hand_questionnaire_function_instructions_2":"صعوبة متوسطة",
  "patient_hand_questionnaire_function_instructions_3":"صعوبة شديدة",
  "patient_hand_questionnaire_function_instructions_4":"لا يمكن أن تؤدي على الإطلاق",
  "patient_hand_questionnaire_function_q1":"صعوبة في إمساك واستخدام الأشياء الصغيرة مثل المفاتيح أو الأقلام",
  "patient_hand_questionnaire_function_q1_a1":"لا توجد صعوبة",
  "patient_hand_questionnaire_function_q1_a2":"القليل من الصعوبة",
  "patient_hand_questionnaire_function_q1_a3":"معتدل",
  "patient_hand_questionnaire_function_q1_a4":"صعوبة شديدة",
  "patient_hand_questionnaire_function_q1_a5":"لا يمكن أن تؤدي على الإطلاق",
  "patient_hand_questionnaire_function_q2":"الكتابة",
  "patient_hand_questionnaire_function_q2_a1":"لا توجد صعوبة",
  "patient_hand_questionnaire_function_q2_a2":"القليل من الصعوبة",
  "patient_hand_questionnaire_function_q2_a3":"صعوبة متوسطة",
  "patient_hand_questionnaire_function_q2_a4":"صعوبة شديدة",
  "patient_hand_questionnaire_function_q2_a5":"لا يمكن أن تؤدي على الإطلاق",
  "patient_hand_questionnaire_function_q3":"إقفال أزرار الملابس",
  "patient_hand_questionnaire_function_q3_a1":"لا توجد صعوبة",
  "patient_hand_questionnaire_function_q3_a2":"القليل من الصعوبة",
  "patient_hand_questionnaire_function_q3_a3":"صعوبة متوسطة",
  "patient_hand_questionnaire_function_q3_a4":"صعوبة شديدة",
  "patient_hand_questionnaire_function_q3_a5":"لا يمكن أن تؤدي على الإطلاق",
  "patient_hand_questionnaire_function_q4":"حمل كتاب أثناء القراءة",
  "patient_hand_questionnaire_function_q4_a1":"لا توجد صعوبة",
  "patient_hand_questionnaire_function_q4_a2":"القليل من الصعوبة",
  "patient_hand_questionnaire_function_q4_a3":"صعوبة متوسطة",
  "patient_hand_questionnaire_function_q4_a4":"صعوبة شديدة",
  "patient_hand_questionnaire_function_q4_a5":"لا يمكن أن تؤدي على الإطلاق",
  "patient_hand_questionnaire_function_q5":"إمساك مقبض الهاتف",
  "patient_hand_questionnaire_function_q5_a1":"لا توجد صعوبة",
  "patient_hand_questionnaire_function_q5_a2":"القليل من الصعوبة",
  "patient_hand_questionnaire_function_q5_a3":"صعوبة متوسطة",
  "patient_hand_questionnaire_function_q5_a4":"صعوبة شديدة",
  "patient_hand_questionnaire_function_q5_a5":"لا يمكن أن تؤدي على الإطلاق",
  "patient_hand_questionnaire_function_q6":"فتح الإناء",
  "patient_hand_questionnaire_function_q6_a1":"لا توجد صعوبة",
  "patient_hand_questionnaire_function_q6_a2":"القليل من الصعوبة",
  "patient_hand_questionnaire_function_q6_a3":"صعوبة متوسطة",
  "patient_hand_questionnaire_function_q6_a4":"صعوبة شديدة",
  "patient_hand_questionnaire_function_q6_a5":"لا يمكن أن تؤدي على الإطلاق",
  "patient_hand_questionnaire_function_q7":"الأعمال المنزلية",
  "patient_hand_questionnaire_function_q7_a1":"لا توجد صعوبة",
  "patient_hand_questionnaire_function_q7_a2":"القليل من الصعوبة",
  "patient_hand_questionnaire_function_q7_a3":"صعوبة متوسطة",
  "patient_hand_questionnaire_function_q7_a4":"صعوبة شديدة",
  "patient_hand_questionnaire_function_q7_a5":"لا يمكن أن تؤدي على الإطلاق",
  "patient_hand_questionnaire_function_q8":"حمل سلة البقالة",
  "patient_hand_questionnaire_function_q8_a1":"لا توجد صعوبة",
  "patient_hand_questionnaire_function_q8_a2":"القليل من الصعوبة",
  "patient_hand_questionnaire_function_q8_a3":"صعوبة متوسطة",
  "patient_hand_questionnaire_function_q8_a4":"صعوبة شديدة",
  "patient_hand_questionnaire_function_q8_a5":"لا يمكن أن تؤدي على الإطلاق",
  "patient_hand_questionnaire_function_q9":"الاستحمام وارتداء الملابس",
  "patient_hand_questionnaire_function_q9_a1":"لا توجد صعوبة",
  "patient_hand_questionnaire_function_q9_a2":"القليل من الصعوبة",
  "patient_hand_questionnaire_function_q9_a3":"صعوبة متوسطة",
  "patient_hand_questionnaire_function_q9_a4":"صعوبة شديدة",
  "patient_hand_questionnaire_function_q9_a5":"لا يمكن أن تؤدي على الإطلاق",
  "patient_hand_uram":"مقياس  URAM لليد والمعصم",
  "patient_hand_uram_canyou":"هل تستطيع...",
  "patient_hand_uram_q1":"اغسل نفسك بقفاز الغسيل مع إبقاء يدك مسطحة",
  "patient_hand_uram_q1_a1":"بدون صعوبة",
  "patient_hand_uram_q1_a2":"بقليل من الصعوبة",
  "patient_hand_uram_q1_a3":"مع بعض الصعوبة",
  "patient_hand_uram_q1_a4":"بصعوبة كبيرة",
  "patient_hand_uram_q1_a5":"شبه مستحيل",
  "patient_hand_uram_q1_a6":"مستحيل",
  "patient_hand_uram_q2":"اغسل وجهك",
  "patient_hand_uram_q2_a1":"بدون صعوبة",
  "patient_hand_uram_q2_a2":"بقليل من الصعوبة",
  "patient_hand_uram_q2_a3":"مع بعض الصعوبة",
  "patient_hand_uram_q2_a4":"بصعوبة كبيرة",
  "patient_hand_uram_q2_a5":"شبه مستحيل",
  "patient_hand_uram_q2_a6":"مستحيل",
  "patient_hand_uram_q3":"امسك زجاجة في يد واحدة",
  "patient_hand_uram_q3_a1":"بدون صعوبة",
  "patient_hand_uram_q3_a2":"بقليل من الصعوبة",
  "patient_hand_uram_q3_a3":"مع بعض الصعوبة",
  "patient_hand_uram_q3_a4":"بصعوبة كبيرة",
  "patient_hand_uram_q3_a5":"شبه مستحيل",
  "patient_hand_uram_q3_a6":"مستحيل",
  "patient_hand_uram_q4":"مصافحة شخص ما",
  "patient_hand_uram_q4_a1":"بدون صعوبة",
  "patient_hand_uram_q4_a2":"بقليل من الصعوبة",
  "patient_hand_uram_q4_a3":"مع بعض الصعوبة",
  "patient_hand_uram_q4_a4":"بصعوبة كبيرة",
  "patient_hand_uram_q4_a5":"شبه مستحيل",
  "patient_hand_uram_q4_a6":"مستحيل",
  "patient_hand_uram_q5":"قم بضرب شيء ما أو مداعبة شخص ما",
  "patient_hand_uram_q5_a1":"بدون صعوبة",
  "patient_hand_uram_q5_a2":"بقليل من الصعوبة",
  "patient_hand_uram_q5_a3":"مع بعض الصعوبة",
  "patient_hand_uram_q5_a4":"بصعوبة كبيرة",
  "patient_hand_uram_q5_a5":"شبه مستحيل",
  "patient_hand_uram_q5_a6":"مستحيل",
  "patient_hand_uram_q6":"صفق بيديك",
  "patient_hand_uram_q6_a1":"بدون صعوبة",
  "patient_hand_uram_q6_a2":"بقليل من الصعوبة",
  "patient_hand_uram_q6_a3":"مع بعض الصعوبة",
  "patient_hand_uram_q6_a4":"بصعوبة كبيرة",
  "patient_hand_uram_q6_a5":"شبه مستحيل",
  "patient_hand_uram_q6_a6":"مستحيل",
  "patient_hand_uram_q7":"انشر أصابعك",
  "patient_hand_uram_q7_a1":"بدون صعوبة",
  "patient_hand_uram_q7_a2":"بقليل من الصعوبة",
  "patient_hand_uram_q7_a3":"مع بعض الصعوبة",
  "patient_hand_uram_q7_a4":"بصعوبة كبيرة",
  "patient_hand_uram_q7_a5":"شبه مستحيل",
  "patient_hand_uram_q7_a6":"مستحيل",
  "patient_hand_uram_q8":"استند على يدك",
  "patient_hand_uram_q8_a1":"بدون صعوبة",
  "patient_hand_uram_q8_a2":"بقليل من الصعوبة",
  "patient_hand_uram_q8_a3":"مع بعض الصعوبة",
  "patient_hand_uram_q8_a4":"بصعوبة كبيرة",
  "patient_hand_uram_q8_a5":"شبه مستحيل",
  "patient_hand_uram_q8_a6":"مستحيل",
  "patient_hand_uram_q9":"التقط الأشياء الصغيرة بإصبعك الإبهام والسبابة",
  "patient_hand_uram_q9_a1":"بدون صعوبة",
  "patient_hand_uram_q9_a2":"بقليل من الصعوبة",
  "patient_hand_uram_q9_a3":"مع بعض الصعوبة",
  "patient_hand_uram_q9_a4":"بصعوبة كبيرة",
  "patient_hand_uram_q9_a5":"شبه مستحيل",
  "patient_hand_uram_q9_a6":"مستحيل",
  "patient_hand_quality_of_life":"جودة الحياة",
  "patient_hand_quality_of_life_instruction":"يرجى تقييم ما يلي لوصف صحتك اليوم",
  "patient_hand_quality_of_life_instruction_0":"لا مشكلة",
  "patient_hand_quality_of_life_instruction_1":"خفيف",
  "patient_hand_quality_of_life_instruction_2":"معتدل",
  "patient_hand_quality_of_life_instruction_3":"شديد",
  "patient_hand_quality_of_life_instruction_4":"ألم شديد / غير قادر",
  "patient_hand_quality_of_life_q1":"مشكلة في المشي",
  "patient_hand_quality_of_life_q1_a1":"لا مشكلة",
  "patient_hand_quality_of_life_q1_a2":"خفيف",
  "patient_hand_quality_of_life_q1_a3":"معتدل",
  "patient_hand_quality_of_life_q1_a4":"شديد",
  "patient_hand_quality_of_life_q1_a5":"غير قادر",
  "patient_hand_quality_of_life_q2":"مشكلة في الغسيل أو ارتداء الملابس بنفسك",
  "patient_hand_quality_of_life_q2_a1":"لا مشكلة",
  "patient_hand_quality_of_life_q2_a2":"خفيف",
  "patient_hand_quality_of_life_q2_a3":"معتدل",
  "patient_hand_quality_of_life_q2_a4":"شديد",
  "patient_hand_quality_of_life_q2_a5":"غير قادر",
  "patient_hand_quality_of_life_q3":"مشكلة في القيام بالأنشطة المعتادة (على سبيل المثال ،  الدراسة ، العمل المنزلي ، الأسرة أو الأنشطة الترفيهية)",
  "patient_hand_quality_of_life_q3_a1":"لا مشكلة",
  "patient_hand_quality_of_life_q3_a2":"خفيف",
  "patient_hand_quality_of_life_q3_a3":"معتدل",
  "patient_hand_quality_of_life_q3_a4":"شديد",
  "patient_hand_quality_of_life_q3_a5":"غير قادر",
  "patient_hand_quality_of_life_q4":"ألم أو انزعاج",
  "patient_hand_quality_of_life_q4_a1":"لا شيء",
  "patient_hand_quality_of_life_q4_a2":"خفيف",
  "patient_hand_quality_of_life_q4_a3":"معتدل",
  "patient_hand_quality_of_life_q4_a4":"شديد",
  "patient_hand_quality_of_life_q4_a5":"أقصى",
  "patient_hand_quality_of_life_q5":"القلق / الاكتئاب",
  "patient_hand_quality_of_life_q5_a1":"غير قلق / مكتئب",
  "patient_hand_quality_of_life_q5_a2":"خفيف",
  "patient_hand_quality_of_life_q5_a3":"معتدل",
  "patient_hand_quality_of_life_q5_a4":"متوتر/مكتئب بشكل شديد",
  "patient_hand_quality_of_life_q5_a5":"متوتر/مكتئب للغاية",
  "patient_hand_quality_of_life_q6":"ما مدى جودة أو سوء صحتك اليوم؟ (100 تعني أفضل صحة 0 تعني أسوأ صحة يمكنك تخيلها)",
  "patient_hand_quality_of_life_q6_a1":"أسوأ",
  "patient_hand_quality_of_life_q6_a2":"أفضل",
  "patient_hand_satisfaction":"الرضا",
  "patient_hand_satisfaction_instrucion":"يرجى ذكر مدى رضاك عما يلي:",
  "patient_hand_satisfaction_instrucion_0":"غير راض تماماً",
  "patient_hand_satisfaction_instrucion_1":"غير راض",
  "patient_hand_satisfaction_instrucion_2":"لا راض ولا غير راض",
  "patient_hand_satisfaction_instrucion_3":"راض بعض الشيء",
  "patient_hand_satisfaction_instrucion_4":"راض جدا",
  "patient_hand_satisfaction_q1":"حركة أصابعك",
  "patient_hand_satisfaction_q1_na":"غير قابل للتطبيق",
  "patient_hand_satisfaction_q1_a1":"غير راض تماماً",
  "patient_hand_satisfaction_q1_a2":"غير راض",
  "patient_hand_satisfaction_q1_a3":"لا راض ولا غير راض",
  "patient_hand_satisfaction_q1_a4":"راض بعض الشيء",
  "patient_hand_satisfaction_q1_a5":"راض جدا",
  "patient_hand_satisfaction_q2":"حركة معصمك",
  "patient_hand_satisfaction_q2_na":"غير قابل للتطبيق",
  "patient_hand_satisfaction_q2_a1":"غير راض تماماً",
  "patient_hand_satisfaction_q2_a2":"غير راض",
  "patient_hand_satisfaction_q2_a3":"لا راض ولا غير راض",
  "patient_hand_satisfaction_q2_a4":"راض بعض الشيء",
  "patient_hand_satisfaction_q2_a5":"راض جدا",
  "patient_hand_satisfaction_q3":"مظهر يدك",
  "patient_hand_satisfaction_q3_na":"غير قابل للتطبيق",
  "patient_hand_satisfaction_q3_a1":"غير راض تماماً",
  "patient_hand_satisfaction_q3_a2":"غير راض",
  "patient_hand_satisfaction_q3_a3":"لا راض ولا غير راض",
  "patient_hand_satisfaction_q3_a4":"راض بعض الشيء",
  "patient_hand_satisfaction_q3_a5":"راض جدا",
  "patient_hand_satisfaction_q4":"علاج يدك / معصمك",
  "patient_hand_satisfaction_q4_na":"غير قابل للتطبيق",
  "patient_hand_satisfaction_q4_a1":"غير راض تماماً",
  "patient_hand_satisfaction_q4_a2":"غير راض",
  "patient_hand_satisfaction_q4_a3":"لا راض ولا غير راض",
  "patient_hand_satisfaction_q4_a4":"راض بعض الشيء",
  "patient_hand_satisfaction_q4_a5":"راض جدا",
  "patient_hand_complete":"نهاية الاستبيان",
  "patient_hand_complete_text1":"شكرًا لك على الوقت الذي أمضيته في ملء هذا النموذج.",
  "patient_hand_complete_text2":"الرجاء النقر فوق إرسال لإكمال ورؤية درجاتك.",
  "patient_hand_complete_text3":"سيتم التعامل مع إجاباتك بسرية ولن تستخدم أي تقارير سوى معلومات مجهولة الهوية",
  "patient_hand_result":"النتائج",
  "patient_hand_result_text1":"فيما يلي درجات الألم ووظيفة اليد والمعصم ، والتي يتم حسابها من الاستبيان الخاص بك.",
  "patient_hand_result_function":"الوظيفة",
  "patient_hand_result_pain":"الألم",
  "patient_hand_result_health_perception":"التصور الصحي العام",
  "patient_hand_result_self_evaluation":"التقييم الذاتي لتسجيل اليد والمعصم",
  "patient_hand_result_text2":"سيكون الطبيب معك قريبًا",
  "patient_general_history":"تاريخ الشكوى الحالية / الإصابة / المرض",
  "patient_general_history_q1":"الشكوى الرئيسية (اشرح شكواك بكلماتك الخاصة)",
  "patient_general_history_q2":"تاريخ بداية المشكلة",
  "patient_general_history_q3":"هل أجريت أي صور؟",
  "patient_general_history_q3_a1":"لا شيء",
  "patient_general_history_q3_a2":"الأشعة السينية",
  "patient_general_history_q3_a3":"الموجات فوق الصوتية",
  "patient_general_history_q3_a4":"التصوير بالرنين المغناطيسي",
  "patient_general_history_q3_a5":"تصوير المفاصل بالرنين المغناطيسي (التصوير بالرنين المغناطيسي مع  وجود مادة ملونة في المفصل)",
  "patient_general_history_q3_a6":"تصوير مقطعي محوسب",
  "patient_general_history_q3_a7":"تصوير المفاصل المقطعي المحوسب (CT مع الصبغة في المفصل)",
  "patient_general_history_q4":"هل أجريت أي تحقيقات أخرى في حالتك ، لم تكن مدرجة أعلاه؟",
  "patient_general_history_q4_placeholder":"تحقيقات أخرى",
  "patient_general_past_history":"التاريخ الطبي السابق / المشاكل الطبية الجارية",
  "patient_general_past_history_q1":"هل لديك أي حساسية معروفة؟ (دواء أو طعام)",
  "patient_general_past_history_q1_a1":"لا",
  "patient_general_past_history_q1_a2":"نعم",
  "patient_general_past_history_placeholder":"حدد الحساسية ونوع رد فعل",
  "patient_general_past_history_q2":"هل لديك حساسية من مادة اللاتكس؟",
  "patient_general_past_history_q2_a1":"لا",
  "patient_general_past_history_q2_a2":"نعم",
  "patient_general_past_history_q3":"هل تعاني أو تتعالج من ارتفاع ضغط الدم؟",
  "patient_general_past_history_q3_a1":"لا",
  "patient_general_past_history_q3_a2":"نعم",
  "patient_general_past_history_q3_a2_placeholder":"حدد أدوية لارتفاع ضغط الدم",
  "patient_general_past_history_q4":"هل تعاني حاليًا أو لديك أي أمراض / أمراض قلبية؟",
  "patient_general_past_history_q4_a1":"لا",
  "patient_general_past_history_q4_a2":"نعم",
  "patient_general_past_history_q4_a2_o1":"حدد المرض",
  "patient_general_past_history_q4_a2_o2":"ذبحة صدرية",
  "patient_general_past_history_q4_a2_o3":"نوبة قلبية",
  "patient_general_past_history_q4_a2_o4":"فشل القلب (وجود سائل في رئتيك)",
  "patient_general_past_history_q4_a2_o5":"مشكلة أو صوت بصمام القلب",
  "patient_general_past_history_q4_a2_o6":"الخفقان / عدم انتظام ضربات القلب",
  "patient_general_past_history_q4_a2_o7":"الجهاز المزروع (ICD ، منظم ضربات القلب)",
  "patient_general_past_history_q4_a2_o8":"جراحة القلب",
  "patient_general_past_history_q4_a2_o9":"جراحة الشريان السباتي",
  "patient_general_past_history_q4_a2_o10":"أمراض القلب الأخرى",
  "patient_general_past_history_q4_a2_o8_q1_placeholder":"حدد جراحة القلب",
  "patient_general_past_history_q4_a2_o10_q1_placeholder":"حدد أمراض القلب الأخرى",
  "patient_general_past_history_q5":"هل لديك طبيب قلب؟",
  "patient_general_past_history_q5_a1":"لا",
  "patient_general_past_history_q5_a2":"نعم",
  "patient_general_past_history_q5_a2_placeholder":"الاسم و المكتب",
  "patient_general_past_history_q6":"هل تعانين من مشاكل في الدورة الدموية في الساقين (مرض الأوعية الدموية المحيطية)؟",
  "patient_general_past_history_q6_a1":"لا",
  "patient_general_past_history_q6_a2":"نعم",
  "patient_general_past_history_q6_a2_placeholder":"صفه",
  "patient_general_past_history_q7":"هل تناولت مخفف دم في الشهر الماضي؟",
  "patient_general_past_history_q7_a1":"لا",
  "patient_general_past_history_q7_a2":"نعم",
  "patient_general_past_history_q7_a2_q1":"ما هذا ؟",
  "patient_general_past_history_q7_a2_q1_a1":"أسبرين",
  "patient_general_past_history_q7_a2_q1_a2":"كلوبيدوغرل / بلافيكس",
  "patient_general_past_history_q7_a2_q1_a3":"ريفاروكسابان",
  "patient_general_past_history_q7_a2_q1_a4":"إليكويس",
  "patient_general_past_history_q7_a2_q1_a5":"آخرون",
  "patient_general_past_history_q7_a2_q1_a5_placeholder":"حدد مخفف الدم",
  "patient_general_past_history_q8":"هل عانيت من أي من المشاكل التالية في رئتيك أو صدرك؟",
  "patient_general_past_history_q8_a1":"لا",
  "patient_general_past_history_q8_a2":"نعم",
  "patient_general_past_history_q8_a2_o1":"سعال مع البلغم؟",
  "patient_general_past_history_q8_a2_o2":"أي مشكلة في التنفس؟",
  "patient_general_past_history_q8_a2_o3":"آخرون",
  "patient_general_past_history_q8_a2_o2_o1":"الربو",
  "patient_general_past_history_q8_a2_o2_o2":"ارتفاع ضغط الدم في الرئتين (ارتفاع ضغط الدم الرئوي)",
  "patient_general_past_history_q8_a2_o2_o3":"التليف الرئوي",
  "patient_general_past_history_q8_a2_o2_o4":"الغرناوية (داء الساركويد)",
  "patient_general_past_history_q8_a2_o2_o5":"مرض الانسداد الرئوي المزمن أو انتفاخ الرئة",
  "patient_general_past_history_q8_a2_o2_o6":"تليّف كيسي",
  "patient_general_past_history_q8_a2_o2_o7":"السل (السل)",
  "patient_general_past_history_q8_a2_o2_o8":"آخرون",
  "patient_general_past_history_q8_a2_o3_placehold":"حدد",
  "patient_general_past_history_q8_a2_o2_o8_placeholder":"حدد",
  "patient_general_past_history_q9":"هل تناولت الستيرويد (بريدنيزون أو كورتيزون) في العام الماضي؟",
  "patient_general_past_history_q9_a1":"لا",
  "patient_general_past_history_q9_a2":"نعم",
  "patient_general_past_history_q10":"هل تستخدم الأكسجين المنزلي؟",
  "patient_general_past_history_q10_a1":"لا",
  "patient_general_past_history_q10_a2":"نعم",
  "patient_general_past_history_q11":"هل لديك اختصاصي أمراض الجهاز التنفسي؟",
  "patient_general_past_history_q11_a1":"لا",
  "patient_general_past_history_q11_a2":"نعم",
  "patient_general_past_history_q11_a2_placeholder":"الاسم و المكتب",
  "patient_general_past_history_q12":"هل تعاني من الشخير المفرط أو انقطاع التنفس أثناء النوم؟",
  "patient_general_past_history_q12_a1":"لا",
  "patient_general_past_history_q12_a2":"نعم",
  "patient_general_past_history_q12_a2_q1":"هل يتم علاج انقطاع النفس النومي باستخدام CPAP أو BiPAP؟",
  "patient_general_past_history_q12_a2_q1_a1":"لا",
  "patient_general_past_history_q12_a2_q1_a2":"نعم",
  "patient_general_past_history_q13":"هل عانيت من قبل من مشاكل في الكبد؟",
  "patient_general_past_history_q13_a1":"لا",
  "patient_general_past_history_q13_a2":"نعم",
  "patient_general_past_history_q13_a2_o1":"المحدد",
  "patient_general_past_history_q13_a2_o2":"التليف الكبدي",
  "patient_general_past_history_q13_a2_o3":"التهاب الكبد أ ، ب ، أو ج",
  "patient_general_past_history_q13_a2_o4":"اليرقان",
  "patient_general_past_history_q13_a2_o5":" آخرون",
  "patient_general_past_history_q13_a2_o5_placeholder":"حدد",
  "patient_general_past_history_q14":"هل تعانين من حرقة معوية متكررة أو تقرحات أو فتق حجابي؟",
  "patient_general_past_history_q14_a1":"لا",
  "patient_general_past_history_q14_a2":"نعم",
  "patient_general_past_history_q15":"هل أصبت من قبل بمرض كلوي؟",
  "patient_general_past_history_q15_a1":"لا",
  "patient_general_past_history_q15_a2":"نعم",
  "patient_general_past_history_q15_a2_q1":"هل أنت مريض غسيل الكلى؟ البريتوني؟ تصفية الدم ",
  "patient_general_past_history_q15_a2_q1_a1":"لا",
  "patient_general_past_history_q15_a2_q1_a2":"نعم",
  "patient_general_past_history_q15_a2_placeholder":"حدد أمراض الكلى",
  "patient_general_past_history_q15_a2_q1_a2_placeholder":"حدد نوع وجدول غسيل الكلى",
  "patient_general_past_history_q16":"هل لديك مرض السكري؟",
  "patient_general_past_history_q16_a1":"لا",
  "patient_general_past_history_q16_a2":"نعم",
  "patient_general_past_history_q16_a2_q1":"كيف يتم التعامل مع مرض السكري الخاص بك؟ النظام الغذائي / الدواء عن طريق الفم أم الأنسولين؟",
  "patient_general_past_history_q16_a2_q1_placeholder":"حدد",
  "patient_general_past_history_q17":"هل لديك مشاكل في الغدة الدرقية؟",
  "patient_general_past_history_q17_a1":"لا",
  "patient_general_past_history_q17_a2":"نعم",
  "patient_general_past_history_q18":"هل أصبت من قبل بالصرع أو النوبات أو التشنجات؟",
  "patient_general_past_history_q18_a1":"لا",
  "patient_general_past_history_q18_a2":"نعم",
  "patient_general_past_history_q18_a2_q1":"متى كانت آخر نوبة لك؟",
  "patient_general_past_history_q18_a2_q1_placeholder":"حدد",
  "patient_general_past_history_q19":"هل عانيت من مشاكل في الأعصاب أو العضلات؟",
  "patient_general_past_history_q19_a1":"لا",
  "patient_general_past_history_q19_a2":"نعم",
  "patient_general_past_history_q19_a2_o1":"النوبات",
  "patient_general_past_history_q19_a2_o1_placeholder":"متى ؟",
  "patient_general_past_history_q19_a2_o2":"TIA (نوبة إقفارية عابرة / سكتة دماغية) أو سكتة دماغية",
  "patient_general_past_history_q19_a2_o2_placeholder":"متى ؟",
  "patient_general_past_history_q19_a2_o3":"ضعف في الوجه أو الساق أو الذراع",
  "patient_general_past_history_q19_a2_o4":"الاضطراب العصبي (أمثلة: التصلب المتعدد ، التصلب الجانبي الضموري ، الزهايمر)",
  "patient_general_past_history_q19_a2_o4_placeholder":"حدد",
  "patient_general_past_history_q19_a2_o5":"اضطراب عضلي (أمثلة: الوهن العضلي الشديد ، الحثل العضلي)",
  "patient_general_past_history_q19_a2_o5_placeholder":"حدد",
  "patient_general_past_history_q19_a2_o6":"مشاكل في السمع أو الرؤية أو الذاكرة",
  "patient_general_past_history_q19_a2_o7":"ألم مزمن",
  "patient_general_past_history_q19_a2_o8":" آخرون",
  "patient_general_past_history_q19_a2_o8_placeholder":"حدد",
  "patient_general_past_history_q20":"هل أنت مصاب بالتهاب المفاصل؟ هشاشة العظام؟ التهاب المفاصل الروماتويدي أم غيره؟",
  "patient_general_past_history_q20_a1":"لا",
  "patient_general_past_history_q20_a2":"نعم",
  "patient_general_past_history_q20_a2_o1":"هل أنت مصاب بالتهاب المفاصل؟ هشاشة العظام؟ التهاب المفاصل الروماتويدي أم غيره؟",
  "patient_general_past_history_q20_a2_o2":"التهاب المفصل الروماتويدي",
  "patient_general_past_history_q20_a2_o3":" آخرون",
  "patient_general_past_history_q20_a2_o3_placeholder":"حدد",
  "patient_general_past_history_q21":"هل سبق لك أن عولجت من مرض نفسي؟",
  "patient_general_past_history_q21_a1":"لا",
  "patient_general_past_history_q21_a2":"نعم",
  "patient_general_past_history_q21_a2_o1":"قلق شديد",
  "patient_general_past_history_q21_a2_o2":"اكتئاب",
  "patient_general_past_history_q21_a2_o3":"آخرون",
  "patient_general_past_history_q21_a2_o3_placeholder":"حدد",
  "patient_general_past_history_q22":"هل تم تشخيص إصابتك باضطراب النزيف من قبل؟",
  "patient_general_past_history_q22_a1":"لا",
  "patient_general_past_history_q22_a2":"نعم",
  "patient_general_past_history_q22_a2_o1":"اللوكيميا أو سرطان الغدد الليمفاوية",
  "patient_general_past_history_q22_a2_o2":"سيولة الدم",
  "patient_general_past_history_q22_a2_o3":"جلطات الدم",
  "patient_general_past_history_q22_a2_o4":"فقر دم",
  "patient_general_past_history_q22_a2_o5":"داء الكريات المنجلية",
  "patient_general_past_history_q22_a2_o6":" آخرون",
  "patient_general_past_history_q22_a2_o6_placeholder":"حدد",
  "patient_general_past_history_q23":"هل انت مصاب بفقر الدم؟",
  "patient_general_past_history_q23_a1":"لا",
  "patient_general_past_history_q23_a2":"نعم",
  "patient_general_past_history_q24":"هل سبق أن أصبت بجلطة دموية في ساقيك أو رئتيك؟",
  "patient_general_past_history_q24_a1":"لا",
  "patient_general_past_history_q24_a2":"نعم",
  "patient_general_past_history_q25":"هل عانيت أنت أو أي فرد من أفراد عائلتك من مشاكل خطيرة مع:",
  "patient_general_past_history_q25_a1":"نزيف الأنف",
  "patient_general_past_history_q25_a1_o1":"لا",
  "patient_general_past_history_q25_a1_o2":"نعم",
  "patient_general_past_history_q25_a2":"نزيف أثناء قلع الأسنان",
  "patient_general_past_history_q25_a2_o1":"لا",
  "patient_general_past_history_q25_a2_o2":"نعم",
  "patient_general_past_history_q25_a3":"النزيف بعد الجراحة",
  "patient_general_past_history_q25_a3_o1":"لا",
  "patient_general_past_history_q25_a3_o2":"نعم",
  "patient_general_past_history_q26":"هل سبق لك أن عولجت من السرطان؟",
  "patient_general_past_history_q26_a1":"لا",
  "patient_general_past_history_q26_a2":"نعم",
  "patient_general_past_history_q26_a2_placeholder1":"حدد نوع السرطان",
  "patient_general_past_history_q26_a2_placeholder2":"متى؟",
  "patient_general_past_history_q27":"هل سبق أن عولجت بالعلاج الكيميائي أو العلاج الإشعاعي؟",
  "patient_general_past_history_q27_a1":"لا",
  "patient_general_past_history_q27_a2":"نعم",
  "patient_general_past_history_q27_a2_placeholder1":"اسم العلاج",
  "patient_general_past_history_q27_a2_placeholder2":"تاريخ آخر علاج",
  "patient_general_past_history_q28":"هل عانيت أنت أو قريبك من أي مشكلة في التخدير",
  "patient_general_past_history_q28_a1":"لا",
  "patient_general_past_history_q28_a2":"نعم",
  "patient_general_past_history_q28_a2_q1":"ما هي المشاكل؟",
  "patient_general_past_history_q28_a2_q1_placeholder":"اختار",
  "patient_general_past_history_q28_a2_q1_o1":"الغثيان الشديد أو القيء بعد الجراحة",
  "patient_general_past_history_q28_a2_q1_o2":"فرط الحرارة الخبيث (في الأسرة من أقارب الدم أو في نفسك)",
  "patient_general_past_history_q28_a2_q1_o3":"مشكلة في التنفس أو صعوبة في إدخال أنبوب التخدير التنفسي؟",
  "patient_general_past_history_q28_a2_q1_o4":"مشاكل في وضع أنبوب التنفس",
  "patient_general_past_history_q28_a2_q1_o5":" آخرون",
  "patient_general_past_history_q28_a2_q1_o5_placeholder":"حدد",
  "patient_general_past_history_q29":"هل لديك أي مما يلي؟",
  "patient_general_past_history_q29_q1":"الأسنان المكسورة أو المفكوكة ، أطقم الأسنان ، الجزئية",
  "patient_general_past_history_q29_q1_a1":"لا",
  "patient_general_past_history_q29_q1_a2":"نعم",
  "patient_general_past_history_q29_q2":"مشاكل في تحريك رقبتك",
  "patient_general_past_history_q29_q2_a1":"لا",
  "patient_general_past_history_q29_q2_a2":"نعم",
  "patient_general_past_history_q29_q3":"مشاكل في فتح الفم",
  "patient_general_past_history_q29_q3_a1":"لا",
  "patient_general_past_history_q29_q3_a2":"نعم",
  "patient_general_past_history_q30":"هل يمكن ان تكوني حامل",
  "patient_general_past_history_q30_a1":"لا",
  "patient_general_past_history_q30_a2":"نعم",
  "patient_general_past_history_q30_not_applicable":"غير قابل للتطبيق",
  "patient_general_past_history_q30_a2_q1":"الدورة الشهرية الأخيرة",
  "patient_general_past_history_q30_a2_q1_placeholder":"الدورة الشهرية الأخيرة",
  "patient_general_past_history_q31":"التاريخ الطبي: يرجى ذكر أي أمراض طبية إضافية لديك لم يتم ذكرها أعلاه",
  "patient_general_past_history_q31_q1":"ضع قائمة بجميع الأدوية (بما في ذلك الأدوية التي لا تستلزم وصفة طبية ، وأجهزة الاستنشاق ، والبقع ، والقطرات ، والفيتامينات ، والمعادن ، والمكملات الغذائية ، والأعشاب)",
  "patient_general_past_history_q31_q2":"اسم الدواء ، الجرعة ، التكرار ، سبب تناوله",
  "patient_general_past_history_q31_q2_placeholder":"اسم الدواء ، الجرعة ، التكرار ، سبب تناوله",
  "patient_general_past_history_q31_q3":"جرعة",
  "patient_general_past_history_q31_q4":"غالبًا ما",
  "patient_general_past_history_q31_q5":"سبب أخذه",
  "patient_general_past_history_q31_q6":"التاريخ الجراحي",
  "patient_general_past_history_q31_q6_placeholder":"حدد الإجراء وتاريخ الإجراء",
  "patient_general_social_history":"التاريخ الاجتماعي",
  "patient_general_social_history_q1":"هل تعيش وحدك؟",
  "patient_general_social_history_q1_a1":"لا",
  "patient_general_social_history_q1_a2":"نعم",
  "patient_general_social_history_q1_a1_q1":"من يسكن معك؟",
  "patient_general_social_history_q1_a1_q1_placeholder":"من يسكن معك؟",
  "patient_general_social_history_q2":"هل تدخن؟",
  "patient_general_social_history_q2_a1":"لا",
  "patient_general_social_history_q2_a2":"نعم",
  "patient_general_social_history_q2_a2_q1":"كم سيجارة في اليوم؟",
  "patient_general_social_history_q2_a2_q1_placeholder":"عدد السجائر",
  "patient_general_social_history_q2_a2_q2":"عدد السنوات؟",
  "patient_general_social_history_q2_a2_q2_placeholder":"سنين",
  "patient_general_social_history_q3":"هل دخنت في الماضي؟",
  "patient_general_social_history_q3_a1":"لا",
  "patient_general_social_history_q3_a2":"نعم",
  "patient_general_social_history_q3_a2_q1":"متى توقفت عن التدخين؟",
  "patient_general_social_history_q3_a2_q1_placeholder":"متى توقفت عن التدخين؟",
  "patient_general_social_history_q3_a2_q2_placeholder":"سنين",
  "patient_general_social_history_q4":"هل تشرب الخمر؟",
  "patient_general_social_history_q4_a1":"لا",
  "patient_general_social_history_q4_a2":"نعم",
  "patient_general_social_history_q4_a2_q1_placeholder":"عدد المشروبات العادية في الأسبوع",
  "patient_general_social_history_q5":"هل تستخدم أو سبق لك استخدام أي عقاقير ترفيهية أو مخدرات (غير مشروعة) أو الماريجوانا أو المواد الأفيونية غير الموصوفة لك؟",
  "patient_general_social_history_q5_a1":"لا",
  "patient_general_social_history_q5_a2":"نعم",
  "patient_general_social_history_q5_a2_placeholder":"حدد الأدوية",
  "patient_general_complete":"نهاية الاستبيان",
  "patient_general_complete_text1":"شكرًا لك على الوقت الذي أمضيته في ملء هذا النموذج.",
  "patient_general_complete_text2":"الرجاء النقر فوق إرسال لإكمال ورؤية درجاتك.",
  "patient_general_complete_text3":"سيتم التعامل مع إجاباتك بسرية ولن تستخدم أي تقارير سوى معلومات مجهولة الهوية",
  "AppVersion":"نسخة التطبيق",
  "Complete_personal_info":"أكمل المعلومات الشخصية",
  "To_complete_the_sign_up":"لإكمال التسجيل يرجى ملء المعلومات الخاصة بك أدناه.",
  "Select_Existing_Profile":"حدد ملف تعريف موجود",
  "Get_In":"دخول",
  "Create_new_patient":"إنشاء ملف مريض جديد",
  "myProfile":"ملفي",
  "First_Name":"الاسم *",
  "Last_Name":"اسم العائلة*",
  "DOB":"تاريخ الميلاد*",
  "Sex":"الجنس *",
  "Address":" العنوان*",
  "Postcode":"شفرة البريد*",
  "Suburb":"الضاحية*",
  "Country_Code":"الرقم الدولي*",
  "Mobile_Phone":"الهاتف المحمول*",
  "Home_Phone":"هاتف المنزل",
  "Email":"البريد إلكتروني*",
  "Emergency_Contact":"الاتصال في حالات الطوارئ.",
  "Phone":"الهاتف*",
  "Relationship":"الصلة*",
  "Regular_GP_Name":"اسم الطبيب العام*",
  "Practice_Name":"اسم العيادة *",
  "Medicare_Card_Number":"رقم بطاقة التأمين الصحي",
  "Ref":"المرجع",
  "Message":"رسالة",
  "Expiry":"تاريخ الانتهاء*",
  "Private_Health_Fund":"الضمان الصحي",
  "Membership_Number":"رقم العضوية",
  "Claim_Type":"نوع المطالبة",
  "Work_Cover":"التغطية",
  "Employer":"صاحب العمل",
  "Third_Party_Claim_Number":"رقم مطالبة الطرف الثالث",
  "Third_Party_Name":"اسم الطرف الثالث",
  "DVA_Concession_Card":"بطاقة امتياز DVA",
  "Number":"الرقم",
  "Defence_Personnel":"أفراد الدفاع",
  "I_agree":"أوافق على معالجة بياناتي الشخصية وقرأت",
  "Privacy_Policy":"سياسة الخصوصية.",
  "visitClinic":"احجز موعدًا",
  "selectdocOrg":"اختر الطبيب والمنظمة",
  "doctorOrganization":"منظمة الطبيب",
  "Select_Treatment_Category":"حدد فئة العلاج ",
  "Do_you_want_to_submit_patient_form_or_not":"هل تريد تقديم استمارة المريض ام لا؟",
  "areyousurewanttocontinueyourform":"الاستمارة السابقة الخاص بك معلق. هل تريد الاستمرار ؟",
  "myVisit":"زياراتي السابقة",
  "View_score":"عرض النتيجة",
  "upcoming_visits":"الزيارات القادمة",
  "No_data_found":"لاتوجد بيانات",
  "Doctor_Name":"اسم الطبيب",
  "Appointment_Date_Time":" تاريخ ووقت الموعد",
  "Clinic_Name":"اسم العيادة",
  "Clinic_Address":"عنوان العيادة",
  "akmed":"أكونه ميد",
  "Type":"نوع",
  "Date_Time":"التاريخ والوقت",
  "Action":"الإجراء",
  "View":"عرض",
  "No_Messages":"لا توجد رسائل",
  "settings":"إعدادات",
  "Language":"اللغة",
  "language":"اللغة والمنطقة",
  "timezone":"المنطقة الزمنية",
  "Timeformat":"تنسيق الوقت",
  "List_of_my_profile":"ملفاتي ",
  "next_button":"التالي",
  "back_button":"رجوع",
  "Continue":"استمر",
  "Close":"اغلاق",
  "submit":"ارسال/ تقديم",
  "Sign_out":"العودة إلى الملف الشخصي",
  "Results":"النتائج",
  "update":"تم تحديث النموذج بنجاح",
  "Personal_Data":"البيانات الشخصية",
  "Date_of_Birth":"تاريخ الميلاد",
  "Hip_arthritis":"التهاب مفاصل الورك",
  "Area_of_foot_ankle":"منطقة القدم / الكاحل المصابة؟ ",
  "Diagnosis":" التشخيص",
  "Evaluated_Shoulder":"الكتف الذي تم تقييمه",
  "Dominant_Hand":"اليد المهيمنة",
  "Age":" العمر",
  "No":"لا",
  "Yes":"نعم",
  "visitNo":"لا زيارة",
  "visitYes":"نعم، زيارة",
  "shoulderPainR":"ألم الكتف الأيمن",
  "Is_it_initial_visit":"هل هذه اول عيادة",
  "for_the_problem":"لهذه المشكلة؟",
  "Diagnosis_Procedure":" التشخيص/ الإجراءات الطبية",
  "Procedure":"الإجراءات الطبية",
  "Evaluated_sdfs":"الجانب المقيم",
  "Please_Select_previous_visits":"يرجى تحديد الزيارات السابقة",
  "Follow_Up":"متابعة",
  "Assessment_Date":"تاريخ التقييم",
  "Do_you_have_Dupuytren_disease":"هل أنت مصاب بمرض دوبويتران؟",
  "Do_you_have_carpal_tunnel_syndrome":"هل لديك متلازمة النفق الرسغي؟",
  "Is_your_unaffected_shoulder_pain":"هل كتفك غير المصابة خالية من الألم وتسمح بوظيفة طبيعية؟",
  "Is_your_unaffected_hip_pain":"هل الورك غير المصاب خالي من الألم ويسمح بوظيفة طبيعية؟",
  "Is_your_unaffected_knee_pain":"هل ركبتك غير المصابة خالية من الألم وتسمح بوظائفها بشكل طبيعي؟",
  "Is_your_unaffected_elbow_pain":"هل الكوع غير المصاب خالي من الألم ويسمح بوظيفة طبيعية؟",
  "Is_your_unaffected_foot_and_ankle_pain":"هل قدمك وكاحلك غير المصابة خالية من الألم وتسمح بالوظيفة الطبيعية؟",
  "Is_your_unaffected_hand_pain":"هل يدك السليمة ومعصمك خالية من الألم وتسمح بالوظيفة الطبيعية؟",
  "Patient_Consent":" معلومات المريض ونموذج الموافقة الحرة المسبقة",
  "Patient_Last_Name":"اسم العائلة",
  "Patient_First_Name":"الاسم",
  "Patient_Date_of_Birth":"تاريخ الميلاد",
  "information":"معلومات",
  "I_am_informedDDD":"لغرض التقييم والتخطيط وتقييم نتائج علاجك العظمي، يستخدم طبيبك",
  "Clinician":"(يشار إليه فيما بعد بـ \"الطبيب السريري\") التقييم ، وجمع بيانات النتائج وتحليلها ، و/ أو خدمات وبرامج التخطيط قبل الجراحة التي تقدمها  شركة Akunah Medical Technology Pty Ltd  والشركات التابعة لها (\"أكونا \").",
  "For_that":"لهذا الغرض، سينقل الطبيب بياناتك الشخصية إلى أكونا، بما في ذلك فحوصات التصوير المقطعي المحوسب (CT) لجسمك والبيانات الطبية الأخرى.",
  "Akunah_softawre":"قد تتضمن برامج وخدمات Akunah تقييم الصور وتقييم البيانات الطبية وإنشاءالنماذج التشريحية ثلاثية الأبعاد  المخصصة بك جنبًا إلى جنب مع القياسات التشريحية الرئيسية وتخطيط الجراحة ودعم الجراحة ، والتي سيتم مشاركتها مع الطبيب.",
  "the_clinic":"سيستخدم الطبيب التقييم والتخطيط قبل الجراحة لعلاج العظام الخاص بك.",
  "oportunnity":" لديك أيضًا فرصة لتقديم البيانات الشخصية من خلال بوابة المرضى لبرامج أكونا والوصول إلى نماذج وخطط الجراحة .",
  "akunah_approch":"نهج الخصوصية والأمان",
  "akunah_approch1":"خصوصية وأمن البيانات التي ستجمعها أكونا منك لمساعدة طبيبك السريري هي في المقام الأول في أذهاننا.",
  "akunah_approch2":"تلتزم أكونا طوعًا بقوانين حماية البيانات الأوروبية المعمول بها، والتي تتضمن لائحة حماية البيانات العامة للاتحاد الأوروبي EU/2016/679، حيث أن هذه القوانين هي أقوى قوانين الخصوصية التي تم سنها على مستوى العالم. تتجاوز هذه القوانين قوانين الخصوصية التي تسنها أستراليا والولايات المتحدة ودول أخرى.",
  "akunah_approch3":" يصف \"قانون الخصوصية الخاص بـأكونا لمعالجة المعلومات الشخصية للأفراد العملاء\" كيف تمتثل أكونا لقوانين حماية البيانات الأوروبية المعمول بها ( ",
  "akunah_approch4":"قانون الخصوصية",
  "akunah_approch5":" تصف \"اتفاقية معالجة البيانات\" الخاصة بـأكونا الترتيبات التي أبرمتها مع الأطباء لحماية وتأمين معلوماتك الشخصية (",
  "akunah_approch6":"اتفاقية معالجة البيانات",
  "akunah_approch7":"هذه السياسات متاحة على موقع أكونا على العنوان ",
  "terms_":"https://akunah.com/termsofservices.",
  "akunah_approch8":"إلى الحد الذي يوجد فيه أي تعارض بين قانون الخصوصية ,اتفاقية معالجة البيانات و هذه الوثيقة ، فإن شروط الوثيقة الأولى المذكورة سيتم تطبيقهم إلى حد أي تعارض مع هذه الوثيقة.",
  "Data_Collection_and_Processing":"جمع البيانات ومعالجتها",
  "I_am_informed":"قد تجمع أكونا بياناتك، بما في ذلك المعلومات المتعلقة بمعلومات الاتصال الخاصة بك، والمصادقة، وتحديد الهوية، والمهنة، والتركيبة السكانية. قد تقوم أكونا أيضًا بجمع البيانات الطبية المتعلقة بصحتك، مثل",
  "Assessment_and_evaluation":"•  بيانات التقييم والتقييم والخصائص الفيزيائية والمعلومات الأخرى ذات الصلة بالعلاجات والنتائج المقترحة.",
  "Previous_surgery":"•  الجراحة السابقة والتشخيص والمسح الشعاعي والنماذج ثلاثية الأبعاد والتشريح والقياسات التشريحية. و",
  "Health_and_medical_history":"• التاريخ الصحي والطبي، بما في ذلك الأعراض والأدوية وأي تشخيص سابق وعلاج تم إعطاؤه لك.",
  "I_have_been_assured":"تجمع أكونا المعلومات دائمًا بطريقة قانونية وعادلة، سواء مباشرة من المريض، أو اللتي يشاركها الطبيب، أو من خلال منتجات أكونا. ستعالج أكونا هذه البيانات وفقًا لموافقتك كما هو موضح أدناه، للسماح بتقديم التقييم وخدمة التخطيط قبل الجراحة إذا لزم الأمر.",
  "My_data":"بالإضافة إلى ذلك، قد تستخدم أكونا بياناتك الشخصية للامتثال لجميع القوانين واللوائح المعمول بها.",
  "your_data":"قد يتم أيضًا الكشف عن بياناتك لأطراف ثالثة، إذا كان ذلك مطلوبًا لتقديم الخدمات المقترحة أو بموجب القانون.",
  "Data_Access_and_Storage":"    الوصول إلى البيانات والتخزين والأمن",
  "Personal_data_provided":"البيانات التي قدمتها أنت وطبيبك السريري ستكون متاحة فقط من قبل طاقم أكونا والأطراف الثالثة، الذين يساهمون في علاجك أو شفائك (على سبيل المثال طبيب التخدير ، أخصائي العلاج الطبيعي) ويحتاجون إلى الوصول إلى بياناتك.",
  "data1":"البيانات التي يتم إدخالها عبر برنامج أكونا يتم استضافتها بشكل آمن في كومنولث أستراليا بواسطة مزود استضافة معتمد. يضمن ذلك مستوى مناسبًا من السرية والأمن يعكس طبيعة بياناتك التي تتم معالجتها عبر برنامج أكونا.",
  "data2":"إذا كنت متواجدًا خارج كومنولث أستراليا و/ أو يمكن الوصول إلى البيانات التي تم إدخالها عبر برنامج أكونا من قبل الشركات التابعة لـ أكونا الموجودة خارج كومنولث أستراليا ، بما في ذلك في الاتحاد الأوروبي أو الولايات المتحدة ، فقد يتم تطبيق معايير خصوصية مختلفة على كيفية تطبيق بياناتك يتم استخدامه وحمايته في تلك البلدان. في هذه الحالة ، سيتم تخزين بياناتك الشخصية في ذلك البلد.",
  "data3":"لدى أكونا ضمانات مناسبة مطبقة وفقًا لجميع المتطلبات القانونية المعمول بها لضمان الحفاظ على سرية البيانات وحمايتها بمستوى كافٍ من حماية البيانات مع جميع الأطراف الثالثة. نطلب من جميع الأشخاص الذين لديهم إمكانية الوصول إلى اعتماد معايير تضمن مستوى حماية مكافئًا للبيانات مثل تلك المعتمدة من قبل أكونا.",
  "data4":"يتشارك طبيبك في مسؤولية جمع بياناتك والوصول إليها وتحليلها ومشاركتها وفقًا للمتطلبات القانونية.",
  "p_data1":"ستحتفظ أكونا ببياناتك الشخصية لفترة زمنية مختلفة. سيتم تحديد فترة الاحتفاظ بالمعايير التالية: (1) الغرض من استخدام أكونا أو الطبيب لبياناتك الشخصية (سيحتاجون إلى الاحتفاظ بالبيانات فقط طالما كان ذلك ضروريًا لهذا الغرض) ؛ و (2) القوانين أو اللوائح المعمول بها التي قد تحدد  الفترة الادنى اللتي يتعين على أكونا خلالها الاحتفاظ ببياناتك الشخصية.",
  "Deidentified_Data":"البيانات محجوبة الهوية",
  "d_txt1":"قد تزيل أكونا المعلومات الخاصة بتحديد الهوية٬ ثم تستخدم هذه البيانات المحجوبة٬ عادةً ما يتم تجميعها مع بيانات محجوبة الهوية من مرضى آخرين٬ لأغراض التدوين والبحث والتطوير وتحليل البيانات وتطوير البرامج/الأجهزة وتحسين منتجات أكونا وخدماتها. يتيح ذلك لـ أكونا تحسين منتجاتها وخدماتها باستمرار لصالح المرضى مثلك.",
  "Patient_Rights":"حقوقك",
  "I_understand_that":"لك مطلق الحرية في رفض منح الموافقة على تقديم بياناتك الشخصية إلى أكونا. في هذه الحالة، يرجى التحدث إلى طبيبك، حيث قد لا يتمكن طبيبك من تزويدك بالخدمات الطبية أو العلاج دون استخدام برامج وخدمات أكونا.",
  "I_understand_that_1txt":"يحق لك سحب موافقتك في أي وقت وبأي وسيلة ، وعند الاقتضاء ، يحق لك: (1) الحصول على معلومات تتعلق بمعالجة بياناتك الشخصية والوصول إلى هذه البيانات الشخصية ؛ (2) لطلب تصحيحها عندما تكون غير دقيقة ؛ (3) لطلب تقييد معالجة بياناتك الشخصية ، بما في ذلك الاعتراض على معالجة معينة لبياناتك الشخصية وطلب سحب الموافقة التي قدمتها ؛ (4) لطلب محو بياناتك الشخصية ؛ (5) لتحديد الإرشادات المتعلقة بمعالجة بياناتك بعد وفاتك ؛ و (6) للحصول على نسخة من بياناتك الشخصية التي تمت معالجتها في الظروف المذكورة أعلاه بتنسيق منظم وشائع الاستخدام ويمكن قراءته آليًا. يمكنك ممارسة هذه الحقوق من خلال توجيه طلبك مباشرة إلى الطبيب.",
  "I_understand_that_2txt":"تخضع حقوقك للإعفاءات والقيود المعمول بها في القانون. ",
  "I_understand_that_3txt":"بعد سحب الموافقة، قد لا يتمكن طبيبك من تزويدك بالخدمات الطبية أو العلاج ، إذا مارست هذه الحقوق قبل الإجراء المقترح.",
  "I_understand_that_4txt":"أنت حر أيضًا في تقديم شكوى بشأن معالجة بياناتك الشخصية إلى السلطة الإشرافية في بلد إقامتك.",
  "I_understand_that_5txt":"فيما يتعلق بالبيانات المحجوبة، لا تندرج البيانات المحجوبة تحت نفس القيود القانونية مثل البيانات الشخصية. يرجى ملاحظة أنه لا يمكنك سحب الموافقة على الوصول إلى البيانات المحجوبة واستخدامها أو طلب مسح هذه البيانات ، لأن أكونا لن تتمكن من العثور على بياناتك. بعد إزالة الهوية ، لا يمكن لـ أكونا تتبع البيانات الخاصة بك.",
  "Contact":"الموافقة",
  "I_am_able_to_contact":"لقد قرأت وفهمت قسم المعلومات أعلاه ، أو قرأه لي أحدهم بلغة أفهمها.",
  "or_by_post":" لقد أتيحت لي الفرصة لطرح الأسئلة وأنا راضٍ عن الإجابات التي تلقيتها.",
  "or_by_post2":"أعطي الإذن لطبيبي",
  "or_by_post2_1":"(\"الطبيب السريري\") لمشاركة بياناتي الشخصية مع أكونا.",
  "or_by_post3":"أعطي الإذن بأن تجمع أكونا بياناتي الشخصية والمحجوبة وتخزنها وتستخدمها وتشاركها كما هو موضح في قسم المعلومات أعلاه.",
  "or_by_post4":"أفهم أنه يحق لي سحب موافقتي في أي وقت.",
  "or_by_post5":"أفهم أنه سيتم إعطائي نسخة موقعة من هذا المستند للاحتفاظ بها.",
  "or_by_post6":"يمكنني الاتصال بـ أكونا فيما يتعلق بمعالجة بياناتي عبر البريد الإلكتروني",
  "info_akunah":"info@akunah.com .",
  "or_by_post7":"أو بالبريد إلى Suite 307 Nicholson Street Specialist Centre, Level 9, 121 Newdegate Street, Greenslopes, QLD, 4120, Australia.",
  "Clear":"واضح",
  "Date":"تاريخ",
  "Signed_by_Mouse":"موقع بإستعمال فأرة الحاسوب *",
  "Name_if_consent_on_behalf_of_patient":"الاسم (إذا كانت الموافقة نيابة عن المريض)",
  "Relationship_to_patient_if_applicable":"العلاقة بالمريض إن وجدت",
  "Choose_Sign_Type":"اختر نوع التوقيع *",
  "Draw":"ارسم",
  "Signed_by_Keyboard":"موقع بواسطة لوحة المفاتيح *",
  "Agreement":"موافقة",
  "agree_that_I_have_read":"أوافق على أنني قد قرأت وفهمت نموذج موافقة المريض هذا وأوافق صراحة على معالجة بياناتي الشخصية للأغراض الموضحة أعلاه.",
  "NOTE":"ملحوظة",
  "If_the_patient":"إذا كان عمر المريض أقل من 18 عامًا ، فيجب توقيع هذا الأمر من قبل أحد الوالدين أو الوصي القانوني.",
  "If_the_patient_is_deceased":"إذا كان المريض متوفًا ، فيجب توقيع ذلك من قبل أقرب أقربائه.",
  "patient_signature":"توقيع المريض",
  "profile_updated":"تحديث الملف الشخصي",
  "profile_not_updated":"لم يتم تحديث الملف الشخصي",
  "video":" الفيديو",
  "radiology_form":"نموذج الأشعة",
  "doc_document":"مستند",
  "pathology_form":"نموذج علم الأمراض",
  "text":" النص",
  "Document_Name":"اسم المستند",
  "email_sent_successfully":"تم إرسال طلب الحذف بنجاح.",
  "messages":"الرسائل",
  "adult_knee":"الركبة عند البالغين",
  "paediatric_knee":"الركبة عند الأطفال",
  "document":"مستنداتي",
  "Check_in":"تسجيل الدخول",
  "info_icon":"املأ استبيان المريض",
  "reminder":"تذكير",
  "Search_with_minimum_three_characters":"ابحث بثلاثة أحرف على الأقل",
  "Select":"اختار",
  "delete_account":"حذف الحساب",
  "DD_MM_YYYY":"اليوم / الشهر / السنة",
  "Female":"أنثى",
  "Male":"ذكر",
  "want_to_disclose":"لا أرغب بالكشف ",
  "enter_address":"أدخل العنوان",
  "logout":"تسجيل خروج",
  "full_name":"الاسم الكامل",
  "mmyy":"شهر / سنة",
  "health_found":"تأمين صحي",
  "Not_Applicable":"لا ينطبق على",
  "not_available":"غير متاح",
  "available":" متاح",
  "shared":"مشترك",
  "private":"خاص",
  "add_document":"اضف ملف",
  "drap_and_drop":"اسحب الملفات وأفلتها هنا",
  "or_browser_files":"او اختر الملف من جهازك الخاص بك",
  "browse_files":"تصفح ملفات",
  "download":"تحميل",
  "Oops_error":"آسف ! لم يتم العثور على البيانات التي طلبتها",
  "go_to_home":"ارجع إلى الصفحة الرئيسية",
  "This_field_is_required":"هذا الحقل مطلوب ",
  "PendingForm":"استمارة معلقة",
  "NoFollowUpRecords":"لا توجد سجلات متابعة",
  "Right":"اليمين",
  "Both":"كلاهما",
  "Left":"اليسار",
  "shoulder":"الكتف",
  "hip_form":"الورك",
  "Foot_And_Ankle":"القدم والكاحل",
  "Hand_And_Wrist":"اليد والمعصم",
  "General":"عام",
  "adolescent_knee":"ركبة المراهقين",
  "Elbow":"المِرفَق",
  "title":"عنوان",
  "description":"وصف",
  "enter_your_description_here":"أدخل الوصف هنا",
  "enter_your_title_here":"\nأدخل العنوان هنا",
  "progress":"معلق",
  "done":"مكتمل",
  "reset":"إعادة ",
  "send":"يرسل",
  "Edit":"يحرر",
  "delete":"يمسح",
  "Are_you_sure_want_to_done_the_remainder":"هل أنت متأكد أنك تريد إكمال هذه الملاحظة؟",
  "Confirm":"تأكيد",
  "Update":"تحديث",
  "success":"نجاح",
  "error":"خطأ",
  "Are_you_sure_you_want_to_delete":"هل أنت متأكد أنك تريد حذف",
  "cancel":"إلغاء",
  "Hour_Time":"الوقت بتنسيق 24 ساعة",
  "Knee":"الركبة",
  "no":"لا",
  "yes":"نعم",
  "Year":"0 سنة ، 0 شهر ، 0 يوم",
  "Bilateral":"ثنائي",
  "Left_Arm":"الذراع الأيسر",
  "Right_Arm":"الذراع الأيمن",
  "out_of":"من",
  "Right_Elbow":"المرفق الأيمن",
  "Left_Elbow":"المرفق الأيسر",
  "right_foot_ankle":"القدم/الكاحل اليمين",
  "left_foot_ankle":"القدم/الكاحل اليسار",
  "Right_hand_and_wrist":"اليد والمعصم اليمين",
  "Left_hand_and_wrist":"اليد والمعصم اليسار",
  "Hip_Function_R":"الورك الأيمن",
  "Hip_Function_L":"الورك الأيسر",
  "Right_Knee":"الركبة اليمنى",
  "Left_Knee":"الركبة اليسرى",
  "Right_Pedi_Knee":"الركبة اليمنى",
  "Left_Pedi_Knee":"الركبة اليسرى",
  "Scoring_Self_Evaluation":"تقدير التقييم الذاتي",
  "presciption_form":"روشتة",
  "insurance_form":"تأمين",
  "letter_form":"رسالة",
  "refferal_form":"الإحالة",
  "medical_form":"طبي",
  "are_you_sure_delete_account":"هل انت متأكد انك تريد حذف حسابك ؟",
  "Adult_Knee_Pain":"آلام الركبة عند البالغين",
  "Knee_pain_13_18_year":"ألم الركبة",
  "day_pdata":"يوم",
  "days_pdata":"أيام",
  "month_pdata":"شهر",
  "months_pdata":"شهور",
  "year_pdata":"سنة",
  "years_pdata":"سنين",
  "Name":"الاسم",
  "akunah_id":"معرف أكونه",
  "mobile":"الهاتف",
  "email":"البريد إلكتروني",
  "upload_a_profile":"حمّل صورة",
  "No_special_characters_are_allowed":"غير مسموح بأحرف خاصة",
  "numbers_are_not_allowed":"الأرقام غير مسموح بها",
  "maximum_50_character_are_allowed":"يسمح بحد أقصى 50 حرفًا",
  "invalid_character":"حرف غير  صالح",
  "fields_only_contain_10_character":"تحتوي الحقول فقط على 10 أحرف",
  "maximum_10_Numbers_are_allowed":"مسموح بـ 10 أرقام كحد أقصى",
  "Only_I":"يسمح باستخدام الأرقام فقط",
  "invalid_home_phone_number":"رقم هاتف المنزل غير صحيح",
  "special_characters_are_not_allowed":"يمنع استعمال الرموز",
  "maximum_digit_are_allowed":"مسموح برقم واحد كحد أقصى",
  "invalid_date":"تاريخ غير صالح",
  "maximum_10_digits_are_allowed":"مسموح بـ 10 أرقام كحد أقصى",
  "switch_profile":"تبديل الملف الشخصي",
  "loading":"تحميل",
  "error_max_profile_limit":"لقد وصلت إلى الحد الأقصى لعدد الحسابات المسموح بها باستخدام عنوان بريد إلكتروني واحد",
  "error_upcoming_appointments":"لم يتم العثور على مواعيد قادمة",
  "error_past_visits":"لم يتم العثور على زيارات سابقة",
  "error_documents":"لم يتم العثور على مستندات",
  "country_hawaii":"(UTC-10:00) Hawaii Time",
  "country_tahiti":"(UTC-10:00) Tahiti",
  "country_pitcairn":"(UTC-08:00) Pitcairn",
  "country_niue":"(UTC-11:00) Niue",
  "country_mountain":"(UTC-07:00) Mountain Time",
  "country_arizona":"(UTC-07:00) Mountain Time - Arizona",
  "country_whitehorse":"(UTC-08:00) Pacific Time - Whitehorse",
  "country_chihuahua":"(UTC-07:00) Mountain Time - Chihuahua, Mazatlan",
  "country_edmonton":"(UTC-07:00) Mountain Time - Edmonton",
  "country_gambier":"(UTC-09:00) Gambier",
  "country_yellowknife":"(UTC-07:00) Mountain Time - Yellowknife",
  "country_dawson":"(UTC-07:00) Mountain Time - Dawson Creek",
  "country_belize":"(UTC-06:00) Belize",
  "country_hermosillo":"(UTC-07:00) Mountain Time - Hermosillo",
  "country_mexico":"(UTC-06:00) Central Time - Mexico City",
  "country_regina":"(UTC-06:00) Central Time - Regina",
  "country_tegucigalpa":"(UTC-06:00) Central Time - Tegucigalpa",
  "country_centraltime":"(UTC-06:00) Central Time",
  "country_rarotonga":"(UTC-10:00) Rarotonga",
  "country_pago":"(UTC-11:00) Pago Pago",
  "country_pacific":"(UTC-08:00) Pacific Time",
  "country_alaska":"(UTC-09:00) Alaska Time",
  "country_vancouver":"(UTC-08:00) Pacific Time - Vancouver",
  "country_tijuana":"(UTC-08:00) Pacific Time - Tijuana",
  "country_salvador":"(UTC-06:00) El Salvador",
  "country_costa":"(UTC-06:00) Costa Rica",
  "country_winnipeg":"(UTC-06:00) Central Time - Winnipeg",
  "country_galapagos":"(UTC-06:00) Galapagos",
  "country_managua":"(UTC-06:00) Managua",
  "country_america_cancun":"(UTC-05:00) America Cancun",
  "country_guatemala":"(UTC-06:00) Guatemala",
  "country_bogota":"(UTC-05:00) Bogota",
  "country_easter_island":"(UTC-05:00) Easter Island",
  "country_iqaluit":"(UTC-05:00) Eastern Time - Iqaluit",
  "country_toronto":"(UTC-05:00) Eastern Time - Toronto",
  "country_eastern_time":"(UTC-05:00) Eastern Time",
  "country_havana":"(UTC-05:00) Havana",
  "country_lima":"(UTC-05:00) Lima",
  "country_jamaica":"(UTC-05:00) Jamaica",
  "country_panama":"(UTC-05:00) Panama",
  "country_port_au_prince":"(UTC-05:00) Port-au-Prince",
  "country_nassau":"(UTC-05:00) Nassau",
  "country_rio_branco":"(UTC-05:00) Rio Branco",
  "country_barbados":"(UTC-04:00) Barbados",
  "country_halifax":"(UTC-04:00) Atlantic Time - Halifax",
  "country_bermuda":"(UTC-04:00) Bermuda",
  "country_boa_vista":"(UTC-04:00) Boa Vista",
  "country_curacao":"(UTC-04:00) Curacao",
  "country_marquesas":"(UTC-09:30) Marquesas",
  "country_caracas":"(UTC-04:00) Caracas",
  "country_grand_turk":"(UTC-04:00) Grand Turk",
  "country_la_paz":"(UTC-04:00) La Paz",
  "timezone_guyana":"(UTC-04:00) Guyana",
  "timezone_port_of_spain":"(UTC-04:00) Port of Spain",
  "timezone_manaus":"(UTC-04:00) Manaus",
  "timezone_martinique":"(UTC-04:00) Martinique",
  "timezone_porto_velho":"(UTC-04:00) Porto Velho",
  "timezone_santo_domingo":"(UTC-04:00) Santo Domingo",
  "timezone_puerto_rico":"(UTC-04:00) Puerto Rico",
  "timezone_araguaina":"(UTC-03:00) Araguaina",
  "timezone_belem":"(UTC-03:00) Belem",
  "timezone_buenos_aires":"(UTC-03:00) Buenos Aires",
  "timezone_newfound_time":"(UTC-03:30) Newfoundland Time - St. Johns",
  "timezone_asuncion":"(UTC-03:00) Asuncion",
  "timezone_campo":"(UTC-03:00) Campo Grande",
  "timezone_cayenne":"(UTC-03:00) Cayenne",
  "timezone_cuiaba":"(UTC-03:00) Cuiaba",
  "timezone_maceio":"(UTC-03:00) Maceio",
  "timezone_godthab":"(UTC-03:00) Godthab",
  "timezone_fortaleza":"(UTC-03:00) Fortaleza",
  "timezone_miquelon":"(UTC-03:00) Miquelon",
  "timezone_palmer":"(UTC-03:00) Palmer",
  "timezone_montevideo":"(UTC-03:00) Montevideo",
  "timezone_recife":"(UTC-03:00) Recife",
  "timezone_rothera":"(UTC-03:00) Rothera",
  "timezone_paramaribo":"(UTC-03:00) Paramaribo",
  "timezone_punta_arenas":"(UTC-03:00) Punta Arenas",
  "timezone_santig":"(UTC-03:00) Santiago",
  "timezone_salvador":"(UTC-03:00) Salvador",
  "timezone_stanley":"(UTC-03:00) Stanley",
  "timezone_south_georgia":"(UTC-02:00) South Georgia",
  "timezone_noronha":"(UTC-02:00) Noronha",
  "timezone_sao_pulo":"(UTC-02:00) Sao Paulo",
  "timezone_azores":"(UTC-01:00) Azores",
  "timezone_abidjan":"(UTC+00:00) Abidjan",
  "timezone_cape_verde":"(UTC-01:00) Cape Verde",
  "timezone_scoresbysund":"(UTC-01:00) Scoresbysund",
  "timezone_bissau":"(UTC+00:00) Bissau",
  "timezone_accra":"(UTC+00:00) Accra",
  "timezone_danmarkshavn":"(UTC+00:00) Danmarkshavn",
  "timezone_casablanca":"(UTC+00:00) Casablanca",
  "timezone_dublin":"(UTC+00:00) Dublin",
  "timezone_canary_islands":"(UTC+00:00) Canary Islands",
  "timezone_el_aaiun":"(UTC+00:00) El Aaiun",
  "timezone_thule":"(UTC-04:00) Thule",
  "timezone_gmt":"(UTC+00:00) GMT (no daylight saving)",
  "timezone_lisbon":"(UTC+00:00) Lisbon",
  "timezone_faeroe":"(UTC+00:00) Faeroe",
  "timezone_reykjavik":"(UTC+00:00) Reykjavik",
  "timezone_monrovia":"(UTC+00:00) Monrovia",
  "timezone_algiers":"(UTC+01:00) Algiers",
  "timezone_london":"(UTC+00:00) London",
  "timezone_amsterdam":"(UTC+01:00) Amsterdam",
  "timezone_andorra":"(UTC+01:00) Andorra",
  "timezone_berlin":"(UTC+01:00) Berlin",
  "timezone_budapest":"(UTC+01:00) Budapest",
  "timezone_ceuta":"(UTC+01:00) Ceuta",
  "timezone_brussels":"(UTC+01:00) Brussels",
  "timezone_copenhagen":"(UTC+01:00) Copenhagen",
  "timezone_gibrltar":"(UTC+01:00) Gibraltar",
  "timezone_prague":"(UTC+01:00) Central European Time - Prague",
  "timezone_lagos":"(UTC+01:00) Lagos",
  "timezone_luxembourg":"(UTC+01:00) Luxembourg",
  "timezone_madrid":"(UTC+01:00) Madrid",
  "timezone_malta":"(UTC+01:00) Malta",
  "timezone_ndjamena":"(UTC+01:00) Ndjamena",
  "timezone_paris":"(UTC+01:00) Paris",
  "timezone_oslo":"(UTC+01:00) Oslo",
  "timezone_monaco":"(UTC+01:00) Monaco",
  "timezone_tirane":"(UTC+01:00) Tirane",
  "timezone_rome":"(UTC+01:00) Rome",
  "timezone_vienna":"(UTC+01:00) Vienna",
  "timezone_tunis":"(UTC+01:00) Tunis",
  "timezone_warsaw":"(UTC+01:00) Warsaw",
  "timezone_amman":"(UTC+02:00) Amman",
  "timezone_zurich":"(UTC+01:00) Zurich",
  "timezone_athens":"(UTC+02:00) Athens",
  "timezone_burcharest":"(UTC+02:00) Bucharest",
  "timezone_chisinau":"(UTC+02:00) Chisinau",
  "timezone_stockholm":"(UTC+01:00) Stockholm",
  "timezone_cairo":"(UTC+02:00) Cairo",
  "timezone_gaza":"(UTC+02:00) Gaza",
  "timezone_jerusalem":"(UTC+02:00) Jerusalem",
  "timezone_johannesburg":"(UTC+02:00) Johannesburg",
  "timezone_helsinki":"(UTC+02:00) Helsinki",
  "timezone_khartoum":"(UTC+02:00) Khartoum",
  "timezone_belgrade":"(UTC+01:00) Central European Time - Belgrade",
  "timezone_damascus":"(UTC+02:00) Damascus",
  "timezone_maputo":"(UTC+02:00) Maputo",
  "timezone_kaliningrad":"(UTC+02:00) Moscow-01 - Kaliningrad",
  "timezone_riga":"(UTC+02:00) Riga",
  "timezone_kiev":"(UTC+02:00) Kiev",
  "timezone_nicosia":"(UTC+02:00) Nicosia",
  "timezone_tallinn":"(UTC+02:00) Tallinn",
  "timezone_tripoli":"(UTC+02:00) Tripoli",
  "timezone_sofia":"(UTC+02:00) Sofia",
  "timezone_vilnius":"(UTC+02:00) Vilnius",
  "timezone_istanbul":"(UTC+03:00) Istanbul",
  "timezone_baghdad":"(UTC+03:00) Baghdad",
  "timezone_minsk":"(UTC+03:00) Minsk",
  "timezone_windhoek":"(UTC+02:00) Windhoek",
  "timezone_nairobi":"(UTC+03:00) Nairobi",
  "timezone_moscow":"(UTC+03:00) Moscow+00 - Moscow",
  "timezone_qatar":"(UTC+03:00) Qatar",
  "timezone_beirut":"(UTC+02:00) Beirut",
  "timezone_riyadh":"(UTC+03:00) Riyadh",
  "timezone_syowa":"(UTC+03:00) Syowa",
  "timezone_tehran":"(UTC+03:30) Tehran",
  "timezone_baku":"(UTC+04:00) Baku",
  "timezone_dubai":"(UTC+04:00) Dubai",
  "timezone_mahe":"(UTC+04:00) Mahe",
  "timezone_mauritius":"(UTC+04:00) Mauritius",
  "timezone_tbilisi":"(UTC+04:00) Tbilisi",
  "timezone_samara":"(UTC+04:00) Moscow+01 - Samara",
  "timezone_reunion":"(UTC+04:00) Reunion",
  "timezone_yerevan":"(UTC+04:00) Yerevan",
  "timezone_kabul":"(UTC+04:30) Kabul",
  "timezone_aqtau":"(UTC+05:00) Aqtau",
  "timezone_aqtobe":"(UTC+05:00) Aqtobe",
  "timezone_ashgabat":"(UTC+05:00) Ashgabat",
  "timezone_dushanbe":"(UTC+05:00) Dushanbe",
  "timezone_maldives":"(UTC+05:00) Maldives",
  "timezone_yekaterinburg":"(UTC+05:00) Moscow+02 - Yekaterinburg",
  "timezone_kerguelen":"(UTC+05:00) Kerguelen",
  "timezone_mawson":"(UTC+05:00) Mawson",
  "timezone_tashkent":"(UTC+05:00) Tashkent",
  "timezone_colombo":"(UTC+05:30) Colombo",
  "timezone_kathmandu":"(UTC+05:45) Kathmandu",
  "timezone_omsk":"(UTC+06:00) Moscow+03 - Omsk",
  "timezone_india_standard_time":"(UTC+05:30) India Standard Time",
  "timezone_chagos":"(UTC+06:00) Chagos",
  "timezone_dhaka":"(UTC+06:00) Dhaka",
  "timezone_bishkek":"(UTC+06:00) Bishkek",
  "timezone_cocos":"(UTC+06:30) Cocos",
  "timezone_thimphu":"(UTC+06:00) Thimphu",
  "timezone_almaty":"(UTC+06:00) Almaty",
  "timezone_vostok":"(UTC+06:00) Vostok",
  "timezone_rangoon":"(UTC+06:30) Rangoon",
  "timezone_christmas":"(UTC+07:00) Christmas",
  "timezone_davis":"(UTC+07:00) Davis",
  "timezone_karachi":"(UTC+05:00) Karachi",
  "timezone_hanoi":"(UTC+07:00) Hanoi",
  "timezone_beijing":"(UTC+08:00) China Time - Beijing",
  "timezone_hongkong":"(UTC+08:00) Hong Kong",
  "timezone_hovd":"(UTC+07:00) Hovd",
  "timezone_jakarta":"(UTC+07:00) Jakarta",
  "timezone_kuala_lumpur":"(UTC+08:00) Kuala Lumpur",
  "timezone_bangkok":"(UTC+07:00) Bangkok",
  "timezone_krasnoyarsk":"(UTC+07:00) Moscow+04 - Krasnoyarsk",
  "timezone_brunei":"(UTC+08:00) Brunei",
  "timezone_macau":"(UTC+08:00) Macau",
  "timezone_makassar":"(UTC+08:00) Makassar",
  "timezone_choibalsan":"(UTC+08:00) Choibalsan",
  "timezone_ulaanbaatar":"(UTC+08:00) Ulaanbaatar",
  "timezone_manila":"(UTC+08:00) Manila",
  "timezone_pyongyang":"(UTC+08:30) Pyongyang",
  "timezone_singapore":"(UTC+08:00) Singapore",
  "timezone_irkutsk":"(UTC+08:00) Moscow+05 - Irkutsk",
  "timezone_taipei":"(UTC+08:00) Taipei",
  "timezone_jayapura":"(UTC+09:00) Jayapura",
  "timezone_dili":"(UTC+09:00) Dili",
  "timezone_perth":"(UTC+08:00) Western Time - Perth",
  "timezone_yakutsk":"(UTC+09:00) Moscow+06 - Yakutsk",
  "timezone_palau":"(UTC+09:00) Palau",
  "timezone_seoul":"(UTC+09:00) Seoul",
  "timezone_tokyo":"(UTC+09:00) Tokyo",
  "timezone_darwin":"(UTC+09:30) Central Time - Darwin",
  "timezone_dumont":"(UTC+10:00) Dumont D'Urville",
  "timezone_brisbane":"(UTC+10:00) Eastern Time - Brisbane",
  "timezone_guam":"(UTC+10:00) Guam",
  "timezone_vladivostok":"(UTC+10:00) Moscow+07 - Vladivostok",
  "timezone_port_moresby":"(UTC+10:00) Port Moresby",
  "timezone_truk":"(UTC+10:00) Truk",
  "timezone_adelaide":"(UTC+10:30) Central Time - Adelaide",
  "timezone_casey":"(UTC+11:00) Eastern Time - Hobart",
  "timezone_melbourne_sydney":"(UTC+11:00) Eastern Time - Melbourne, Sydney",
  "timezone_kosrae":"(UTC+11:00) Kosrae",
  "timezone_efate":"(UTC+11:00) Efate",
  "timezone_guadalcanal":"(UTC+11:00) Guadalcanal",
  "timezone_ponape":"(UTC+11:00) Ponape",
  "timezone_norfolk":"(UTC+11:00) Norfolk",
  "timezone_magadan":"(UTC+11:00) Moscow+08 - Magadan",
  "timezone_noumea":"(UTC+11:00) Noumea",
  "timezone_majuro":"(UTC+12:00) Majuro",
  "timezone_funafuti":"(UTC+12:00) Funafuti",
  "timezone_nauru":"(UTC+12:00) Nauru",
  "timezone_tarawa":"(UTC+12:00) Tarawa",
  "timezone_kwajalein":"(UTC+12:00) Kwajalein",
  "timezone_enderbury":"(UTC+13:00) Enderbury",
  "timezone_wake":"(UTC+12:00) Wake",
  "timezone_fakaofo":"(UTC+13:00) Fakaofo",
  "timezone_wallis":"(UTC+12:00) Wallis",
  "timezone_fiji":"(UTC+13:00) Fiji",
  "timezone_apia":"(UTC+14:00) Apia",
  "timezone_kiritimati":"(UTC+14:00) Kiritimati",
  "timezone_tongatapu":"(UTC+13:00) Tongatapu",
  "timezone_auckland":"(UTC+13:00) Auckland",
  "dateFormet":"صيغة التاريخ",
  "Org_success":"تم إرسال طلب الانضمام إلى المنظمة بنجاح",
  "Org_err":"لقد انضممت بالفعل إلى المنظمة",
  "Request_Organization":"طلب منظمة",
  "organization_requested":"قد تم طلت المنظمة",
  "join":"انضمام",
  "your_email":"email@address.com",
  "medical_summary":"لم تقم بملء ملخصك الطبي بعد",
  "medical_summary_msg":"ملخصي الطبي",
  "medical_success":"تم إرسال الملخص الطبي بنجاح",
  "patient_form_popup":"هل ترغب في الاستمرار في ملء الاستبيان الذي كنت تعمل عليه سابقًا؟",
  "Please_confirm_that_your_details_are_correct":"يرجى التأكد من صحة التفاصيل الخاصة بك",
  "Welcome_to_Akunah_your_Health_Passport":"مرحبًا بك في أكونا، جواز السفر الصحي الخاص بك.",
  "Your_helth_is_in_your_hands":"صحتك بين يديك.",
  "Lets_begin_your_journey":"لنبدأ رحلتك.",
  "Please_complete_your_profile":"يرجى استكمال الملف الشخصي الخاص بك.",
  "Profile_complete":"الملف الشخصي مكتمل!",
  "Would_you_like_to_add_another_profile_for_a_family_member":"هل ترغب في إضافة ملف تعريف آخر لأحد أفراد العائلة؟",
  "Alert_detected":"تنبيه: تم اكتشاف عمليات تسجيل دخول متعددة",
  "To_safeguard_your_data":"لحماية خصوصية بياناتك وأمانها، انتهت مهلة صفحة الويب.",
  "Kindly_close_all_other_Akunah":"يرجى إغلاق جميع علامات تبويب Akunah الأخرى والضغط على \"\"العودة إلى الصفحة الرئيسية\" للمتابعة.",
  "Akunah_Team_is_dedicated":"فريق Akunah مكرس لحماية بياناتك.",
  "Last_Created":"آخر تم إنشاؤه",
  "First_Created":"أول تم إنشاؤه",
  "Alphabetically":"أبجديًا (Z-A)",
  "Alphabetically1":"أبجديًا (Z-A)",
  "Your_form_submission_was_successful_Clinician":"تمت عملية إرسال النموذج بنجاح. نقوم الآن بتوجيهك تلقائيًا إلى تطبيق الأخصائي",
  "Your_form_submission_was_successful_Patient":"تمت عملية إرسال النموذج بنجاح. نقوم الآن بتوجيهك تلقائيًا إلى تطبيق المريض",
  "If_this_doesnt_happen_within":"إذا لم يحدث هذا خلال 5 ثواني، من فضلك",
  "click_here":"انقر هنا",
  "Save":"حفظ",
  "Proceed_to_my_dashboard":"المتابعة إلى لوحة التحكم الخاصة بي",
  "myJourney":"رحلتي",
  "Home":"الرئيسية",
  "No_journey":"لم يتم العثور على رحلة",
  "Share_results_with_your_doctor":"شارك النتائج مع طبيبك",
  "If_you_would_like_to_share_your_results_with":"إذا كنت ترغب في مشاركة نتائجك مع طبيبك، فحدد \"المشاركة الآن\" أدناه",
  "Link_PROMs":"ربط PROMs",
  "Welcome_to_akunah":"مرحبًا بك في أكوناه",
  "Share_to_your_Doctor_using_one_of_the_3_options_below":"قم بمشاركتها مع طبيبك باستخدام إحدى الخيارات الثلاث أدناه",
  "Doctor_Code":"كود الطبيب",
  "Enter_the_code_that_has_been_provided_to_you_by_your":"أدخل الرمز الذي تلقيته من طبيبك في بريدك الإلكتروني أو في العيادة. إذا لم يكن الرمز متاحًا، يرجى التحقق من",
  "Recent_Visits":"الزيارات الأخيرة",
  "Select_your_recent_doctor_or":"حدد طبيبك الأخير أو",
  "organisation_here":"المنظمة هنا",
  "Search":"بحث",
  "Search_for_your_doctor_or":"البحث عن طبيبك أو",
  "Please_confirm_that_the_following_details":"يرجى التأكد من أن التفاصيل التالية صحيحة",
  "Organisation":"المنظمة",
  "PROMs_Specialty_Date":"تخصص وتاريخ PROMs",
  "Get_started_by_completing_your":"ابدأ بإكمال استبيانات PROMs الخاصة بك",
  "and_exploring_your_progress":"واستكشاف تقدمك",
  "Fill_up_your_PROMs_questionnaire":"املأ استبيان PROMs الخاص بك",
  "Hip":"الورك",
  "My_Progress":"تقدمي",
  "Completed":"مكتمل",
  "Expired":"منتهي الصلاحية",
  "Incomplete":"غير كامل",
  "Specialty":"تخصص",
  "Doctor":"طبيب",
  "Status":"الحالة",
  "View_form":"عرض الاستمارة",
  "Share_to_another_doctor":"مشاركة مع طبيب آخر",
  "Edit_form":"تحرير الاستمارة",
  "Answer_the_form":"الإجابة على الاستمارة",
  "Complete_the_form":"اكمل الاستمارة",
  "Delete_the_form":"حذف الاستمارة",
  "Share_to_doctor":"مشاركة مع الطبيب",
  "Your_account_has_been_created":"تم إنشاء حسابك",
  "Please_click_below_to_proceed_to_your_dashboard":"الرجاء النقر أدناه للمتابعة إلى لوحة التحكم الخاصة بك",
  "If_you_would_like_to_add_another_profile_for_a_family":"إذا كنت ترغب في إضافة ملف شخصي آخر لأحد أفراد عائلتك، يرجى",
  "Share":"شارك",
  "Hip_Scoring_Self_Evaluation_Questionnaire":"استبيان تقييم الورك",
  "Hand_and_Wrist_Scoring_Self_Evaluation_Questionnaire":"استبيان تقييم اليد والمعصم",
  "Right_Hand_nd_Wrist":"اليد اليمنى والمعصم",
  "Left_Hand_nd_Wrist":"اليد اليسرى والمعصم",
  "DOB_1":"تاريخ الميلاد",
  "Sex_1":"الجنس",
  "Address_1":"عنوان",
  "Please_Choose_PROMs_to_link":"الرجاء اختيار PROMs للربط",
  "Please_select_atleast_one_form_or_close":"الرجاء تحديد  PROMs واحدة على الأقل أو إغلاقها",
  "cant_find_appropriate_PROMs_to_link":"لا يمكن العثور على PROMs المناسبة للربط؟",
  "Request_New_PROMs":"طلب PROMs جديدة",
  "Warning_This_patient_has_an_incomplete_patient_PROMs_from_a_recent_visit":"تحذير: لديك استبيان غير مكتمل من زيارة حديثة.",
  "If_you_select_No_a_new_patient_PROMs_will_be_created_and_the_incomplete_PROMs_will_be_deleted":"إذا قمت باختيار \"لا\"، سيتم إنشاء استبيان جديد وسيتم حذف الاستبيان غير المكتمل.",
  "Would_you_like_to_continue_this_form":"هل ترغب في الاستمرار في هذا؟",
  "Note":"ملحوظة:",
  "Expiring_in":"تنتهي في",
  "Date_Created":"تاريخ الإنشاء",
  "Days":"أيام",
  "Shared":"مشترك",
  "Pending":"قيد الانتظار",
  "Initial":"أولي",
  "Recent":"مؤخرًا",
  "Pain":"ألم",
  "Function":"وظيفة",
  "Share_Now":"شارك الآن",
  "Share_Later":"شارك لاحقا",
  "Updated":"محدث",
  "Display_More":"عرض المزيد",
  "Address_Now":"معالجة  النماذج المعلقة الآن",
  "Address_Later":"معالجة  النماذج المعلقة لاحقا",
  "You_have_pending_questionnaires":"لديك استبيانات معلقة",
  "Notifications":"إشعارات",
  "Form_shared_successfully":"تمت مشاركة النموذج بنجاح",
  "You_have_already_shared_with_same_doctor_and_organization":"لقد شاركت بالفعل مع نفس الطبيب والمنظمة",
  "No_recent_notifications":"لا توجد إشعارات أخيرة",
  "Have_a_good_day":"اتمنى لك يوم جيد",
  "Patient_scores":"درجات المرضى",
  "Display less":"عرض أقل",
  "myJourney_Shoulder":"كتف",
  "myJourney_Shoulder_txt":"حدد الكتف لعرض رحلة الكتف الخاصة بك",
  "myJourney_Elbow":"مِرفَق",
  "myJourney_Elbow_txt":"حدد الكوع لعرض رحلة الكوع الخاصة بك",
  "myJourney_Hand_Wrist":"معصم اليد",
  "myJourney_Hand_Wrist_txt":"حدد اليد والمعصم لعرض رحلة اليد والمعصم",
  "myJourney_Hip":"ورك",
  "myJourney_Hip_txt":"حدد الورك لعرض رحلة الورك الخاصة بك",
  "myJourney_knee":"ركبة",
  "myJourney_knee_txt":"حدد الركبة لعرض رحلة الركبة الخاصة بك",
  "myJourney_Foot_Ankle":"القدم والكاحل",
  "myJourney_Foot_Ankle_txt":"حدد القدم لعرض سجل القدم الخاصة بك",
  "data_sort":"رتب",
  "Visit_Clinic":"زيارة العيادة",
  "My_Visits":"زياراتي",
  "Patient_Scoring_Forms":"نماذج تسجيل المرضى",
  "Other_findings":"نتائج أخرى",
  "Organization":"منظمة",
  "more":"أكثر",
  "mediacal_summary":"ملخص طبي",
  "my_consent":"موافقاتي",
  "Are_you_sure_you_want_to_reject":"هل أنت متأكد أنك تريد منع هذه الإحالة؟",
  "reject":"رفض",
  "i_accept_consent":"أنا أوافق على هذا",
  "grant":"منحة",
  "no_conscent_found":"لم يتم العثور على موافقة",
  "rejected":"مرفوض",
  "No_Time_Lines":"لا  يوجد جداول زمنية",
  "Hide_Score":"إخفاء النتيجة",
  "Show_Score":"إظهار النتيجة",
  "patient_sex":"الجنس",
  "Dominant_hand_1":"اليد المسيطرة",
  "patient_Initial_Assessment":"الفحص الأولي",
  "patient_Treatment_Category":"فئة العلاج",
  "View_all":"عرض الكل",
  "Display_Less":"عرض أقل",
  "patient_general_past_Medical_history":"التاريخ الطبى",
  "patient_general_past_Medication_name":"قائمة الأدوية",
  "Evaluated_Knee":"الركبة الذي تم تقييمها",
  "Is_your_unaffected_Hand_Wrist_pain_free_and_allows_normal_function":"هل يدك ومعصمك غير المتأثرين خاليان من الألم وتسمحان بوظيفة طبيعية؟",
  "Evaluated_Hip":"الورك الذي تم تقييمه",
  "Evaluated_Foot_Ankle":"كاحل القدم الذي تم تقييمه",
  "patient_Evaluated_Elbow":"الكوع الذي تم تقييمه",
  "Is_your_unaffected_Foot_Ankle_pain_free_and_allows_normal_function":"هل قدمك وكاحلك غير المتأثرين خاليان من الألم ويسمحان بوظيفة طبيعية؟",
  "Evaluated_Hand_Wrist":"معصم اليد الذي تم تقييمه",
  "patient_shoulder_feeling_instability_q2":"يرجى توضيح مدى عدم استقرار كتفك",
  "patient_shoulder_feeling_instability_q3":"يرجى توضيح مدى عدم استقرار كتفك",
  "patient_Hand_BCTQ":"اليد والمعصم BCTQ",
  "Paediatric_Knee_Satisfaction":"الرضا  - الركبة عند الأطفال",
  "patient_foot_ankle_symptoms":"أعراض أخرى للقدم والكاحل",
  "Right_Foot_and_Ankle":"القدم اليمنى والكاحل",
  "Left_Foot_and_Ankle":"القدم اليسرى والكاحل",
  "summary":"ملخص",
  "Shoulder_Treatment_Form":"استمارة علاج الكتف",
  "Adolescent_knee_Treatment_Form":"استمارة علاج الركبة لدى المراهقين",
  "Adult_Knee_Treatment_Form":"استمارة علاج الركبة للبالغين",
  "Paediatric_Knee_Treatment_Form":"استمارة علاج ركبة الأطفال",
  "Hip_Treatment_Form":"استمارة علاج الورك",
  "Foot_Ankle_Treatment_Form":"استمارة علاج كاحل القدم",
  "Elbow_Treatment_Form":"استمارة علاج الكوع",
  "Hand_Wrist_Treatment_Form":"استمارة علاج اليد والمعصم",
  "Upload_complete":"اكتمل التحميل",
  "Select_Date_Format":"حدد تنسيق التاريخ",
  "timezone_casey1":"(UTC+11:00) Casey",
  "timezone_Kamchatskiy":"(UTC+12:00) Moscow+09 - Petropavlovsk-Kamchatskiy",
  "timezone_Guayaquil":"(UTC-05:00) Guayaquil",
  "requested":"طلب",
  "Display_name":"أظهر الاسم",
  "Are_you_sure_you_want_to_delete_Consent":"هل أنت متأكد أنك تريد رفض هذه الموافقة؟",
  "form":"استمارة",
  "Requested_by":"طلب من",
  "requested_hip_form":"طلب نموذج الورك",
  "requested_shoulder_form":"طلب نموذج الكتف",
  "requested_knee_form":"طلب نموذج الركبة",
  "requested_foot_form":"طلبت نموذج القدم والكاحل",
  "requested_hand_form":"طلب نموذج اليد والمعصم",
  "requested_elbow_form":"طلبت نموذج الكوع",
  "requested_General_form":"طلبت النموذج العام",
  "requested_Paediatric_form":"طلبت نموذج الركبة للأطفال",
  "requested_Adolescent_form":"طلب نموذج الركبة المراهقين"
}