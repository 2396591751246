import React from "react";
import "../../../css/ViewTreatmentForm.css";
import HipPain from "./HipPain";
import useTranslation from "../../customHooks/translations";

export default function HipSymptoms({ symptomsData, AllData }) {
  const translations = useTranslation();
  return (
    <>
      <div className="whitebox padding-none">
        <div className="treatment-content">
          <div className="treatment-row-header">
            2. {translations.patient_hip_other_symptoms}
          </div>
          <div className="treatment-row">
            <div className="treatment-table">
              <table cellpadding="0" cellspacing="0">
                <thead>
                  <tr>
                    <th></th>
                    {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}

                    <th>{translations.Hip_Function_R}</th>
                    {/* ) : (
                                            ""
                                        )} */}
                    {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}
                    <th>{translations.Hip_Function_L}</th>
                    {/* // ) : (
                                        //     ""
                                        // )} */}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{translations.patient_hip_other_symptoms_q1} :</td>
                    {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}
                    <td> {symptomsData.grindingR}</td>
                    {/* // ) : (
                                        //     ""
                                        // )} */}
                    {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}

                    <td> {symptomsData.grindingL}</td>
                    {/* ) : (
                                            ""
                                        )} */}
                  </tr>
                  <tr>
                    <td>{translations.patient_hip_other_symptoms_q2} :</td>
                    {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}
                    <td> {symptomsData.catchingR}</td>
                    {/* // ) : (
                                        //     ""
                                        // )} */}
                    {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}

                    <td> {symptomsData.catchingL}</td>
                    {/* ) : (
                                            ""
                                        )} */}
                  </tr>
                  <tr>
                    <td>{translations.patient_hip_other_symptoms_q3}</td>
                    {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}
                    <td> {symptomsData.stifR}</td>
                    {/* // ) : (
                                        //     ""
                                        // )} */}
                    {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}

                    <td> {symptomsData.stifL}</td>
                    {/* ) : (
                                            ""
                                        )} */}
                  </tr>
                  <tr>
                    <td>{translations.patient_hip_other_symptoms_q4} :</td>
                    {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}

                    <td> {symptomsData.stifSitR}</td>
                    {/* // ) : (
                                        //     ""
                                        // )} */}
                    {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}
                    <td> {symptomsData.stifSitL}</td>
                    {/* ) : (
                                            ""
                                        )} */}
                  </tr>
                  <tr>
                    <td>{translations.patient_hip_other_symptoms_q5} :</td>
                    {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}
                    <td> {symptomsData.limpingR}</td>
                    {/* // ) : (
                                        //     ""
                                        // )} */}
                    {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}

                    <td> {symptomsData.limpingL}</td>
                    {/* ) : (
                                            ""
                                        )} */}
                  </tr>
                  <tr>
                    <td>{translations.patient_hip_other_symptoms_q6} :</td>
                    {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}
                    <td> {symptomsData.suddenR}</td>
                    {/* // ) : (
                                        //     ""
                                        // )} */}
                    {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}

                    <td> {symptomsData.suddenL}</td>
                    {/* ) : (
                                            ""
                                        )} */}
                  </tr>
                  <tr>
                    <td>{translations.patient_hip_other_symptoms_q7} :</td>
                    {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}
                    <td> {symptomsData.hipGivingR}</td>
                    {/* // ) : (
                                        //     ""
                                        // )} */}
                    {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}

                    <td> {symptomsData.hipGivingL}</td>
                    {/* ) : (
                                            ""
                                        )} */}
                  </tr>
                  <tr>
                    <td>{translations.patient_hip_other_symptoms_q8} :</td>
                    {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}
                    <td> {symptomsData.decreasedR}</td>
                    {/* // ) : (
                                        //     ""
                                        // )} */}
                    {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}

                    <td> {symptomsData.decreasedL}</td>
                    {/* ) : (
                                            ""
                                        )} */}
                  </tr>
                  <tr>
                    <td>{translations.patient_hip_other_symptoms_q9} :</td>
                    {/* {AllData.evlHipPain === "2" || AllData.evlHipPain === "3" ? ( */}

                    <td>
                      {" "}
                      {symptomsData.supportR === "5"
                        ? translations.patient_hip_other_symptoms_q9_a6
                        : symptomsData.supportR === "4"
                        ? translations.patient_hip_other_symptoms_q9_a5
                        : symptomsData.supportR === "3"
                        ? translations.patient_hip_other_symptoms_q9_a4
                        : symptomsData.supportR === "2"
                        ? translations.patient_hip_other_symptoms_q9_a3
                        : symptomsData.supportR === "1"
                        ? translations.patient_hip_other_symptoms_q9_a2
                        : translations.patient_hip_other_symptoms_q9_a1}
                    </td>
                    {/* // ) : (
                                        //     ""
                                        // )} */}
                    {/* {AllData.evlHipPain === "1" || AllData.evlHipPain === "3" ? ( */}
                    <td>
                      {symptomsData.supportL === "5"
                        ? translations.patient_hip_other_symptoms_q9_a6
                        : symptomsData.supportL === "4"
                        ? translations.patient_hip_other_symptoms_q9_a5
                        : symptomsData.supportL === "3"
                        ? translations.patient_hip_other_symptoms_q9_a4
                        : symptomsData.supportL === "2"
                        ? translations.patient_hip_other_symptoms_q9_a3
                        : symptomsData.supportL === "1"
                        ? translations.patient_hip_other_symptoms_q9_a2
                        : translations.patient_hip_other_symptoms_q9_a1}
                    </td>
                    {/* ) : (
                                            ""
                                        )} */}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
