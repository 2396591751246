import React from "react";
import useTranslation from "../../customHooks/translations";

export default function HandWristSymtoms({ handSymptoms, AllData }) {
  const translations = useTranslation();
  return (
    <div className="whitebox padding-none">
      <div className="treatment-content">
        <div className="treatment-row-header">
          2. {translations.patient_hand_symptoms}
        </div>
        <div className="treatment-row">
          <div className="treatment-col">
            <label>
              {translations.patient_hand_symptoms_q1} ({translations.Right})
            </label>
            <p>
              {handSymptoms.severeR === "4"
                ? translations.patient_hand_symptoms_q1_a5
                : handSymptoms.severeR === "3"
                ? translations.patient_hand_symptoms_q1_a4
                : handSymptoms.severeR === "2"
                ? translations.patient_hand_symptoms_q1_a3
                : handSymptoms.severeR === "1"
                ? translations.patient_hand_symptoms_q1_a2
                : handSymptoms.severeR === "0"
                ? translations.patient_hand_symptoms_q1_a1
                : ""}
            </p>
          </div>
          <div className="treatment-col">
            <label>
              {translations.patient_hand_symptoms_q1} ({translations.Left})
            </label>
            <p>
              {handSymptoms.severeL === "4"
                ? translations.patient_hand_symptoms_q1_a5
                : handSymptoms.severeL === "3"
                ? translations.patient_hand_symptoms_q1_a4
                : handSymptoms.severeL === "2"
                ? translations.patient_hand_symptoms_q1_a3
                : handSymptoms.severeL === "1"
                ? translations.patient_hand_symptoms_q1_a2
                : handSymptoms.severeL === "0"
                ? translations.patient_hand_symptoms_q1_a1
                : ""}
            </p>
          </div>
        </div>

        <div className="treatment-row">
          <div className="treatment-col">
            <label>
              {translations.patient_hand_symptoms_q2} ({translations.Right})
            </label>
            <p>
              {handSymptoms.sensationR === "4"
                ? translations.patient_hand_symptoms_q2_a5
                : handSymptoms.sensationR === "3"
                ? translations.patient_hand_symptoms_q2_a4
                : handSymptoms.sensationR === "2"
                ? translations.patient_hand_symptoms_q2_a3
                : handSymptoms.sensationR === "1"
                ? translations.patient_hand_symptoms_q2_a2
                : handSymptoms.sensationR === "0"
                ? translations.patient_hand_symptoms_q2_a1
                : ""}
            </p>
          </div>
          <div className="treatment-col">
            <label>
              {translations.patient_hand_symptoms_q2} ({translations.Left})
            </label>
            <p>
              {handSymptoms.sensationL === "4"
                ? translations.patient_hand_symptoms_q2_a5
                : handSymptoms.sensationL === "3"
                ? translations.patient_hand_symptoms_q2_a4
                : handSymptoms.sensationL === "2"
                ? translations.patient_hand_symptoms_q2_a3
                : handSymptoms.sensationL === "1"
                ? translations.patient_hand_symptoms_q2_a2
                : handSymptoms.sensationL === "0"
                ? translations.patient_hand_symptoms_q2_a1
                : ""}
            </p>
          </div>
        </div>

        <div className="treatment-row">
          <div className="treatment-col">
            <label>
              {translations.patient_hand_symptoms_q3} ({translations.Right})
            </label>
            <p>
              {handSymptoms.apperanceR === "4"
                ? translations.patient_hand_symptoms_q3_a5
                : handSymptoms.apperanceR === "3"
                ? translations.patient_hand_symptoms_q3_a4
                : handSymptoms.apperanceR === "2"
                ? translations.patient_hand_symptoms_q3_a3
                : handSymptoms.apperanceR === "1"
                ? translations.patient_hand_symptoms_q3_a2
                : handSymptoms.apperanceR === "0"
                ? translations.patient_hand_symptoms_q3_a1
                : ""}
            </p>
          </div>
          <div className="treatment-col">
            <label>
              {translations.patient_hand_symptoms_q3} ({translations.Left})
            </label>
            <p>
              {handSymptoms.apperanceL === "4"
                ? translations.patient_hand_symptoms_q3_a5
                : handSymptoms.apperanceL === "3"
                ? translations.patient_hand_symptoms_q3_a4
                : handSymptoms.apperanceL === "2"
                ? translations.patient_hand_symptoms_q3_a3
                : handSymptoms.apperanceL === "1"
                ? translations.patient_hand_symptoms_q3_a2
                : handSymptoms.apperanceL === "0"
                ? translations.patient_hand_symptoms_q3_a1
                : ""}
            </p>
          </div>
        </div>

        <div className="treatment-row">
          <div className="treatment-col">
            <label>
              {translations.patient_hand_symptoms_q3_a6}({translations.Right})
            </label>
            <p>{handSymptoms.percent}</p>
          </div>
          <div className="treatment-col">
            <label>
              {translations.patient_hand_symptoms_q3_a6}({translations.Left})
            </label>
            <p>{handSymptoms.percentL}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
