import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { validateInput } from "../../../../../utilities/Validator";
import scrangeStyles from "./KneeScoreRangeSlider.module.css";
import useTranslation from "../../../../customHooks/translations";
function UramScoreRangeSlider({
  classes,
  label,
  onChange,
  onMouseUp,
  value,
  validators,
  sliderData,
  ...sliderProps
}) {
  const translation = useTranslation();
  const [countR, setCountR] = useState(0);
  const handleChange = (event) => {
    const { value } = event.target;
    onChange(value);
  };

  const [sliderDatas, setsliderDatas] = useState([
    { score: "0", label: translation.without_difficulty},
    { score: "1", label: translation.with_very_little_difficulty},
    { score: "2", label: translation.with_some_difficulty},
    { score: "3", label: translation.with_much_difficulty},
    { score: "4", label: translation.almost_impossible},
    {score: "5", label: translation.impossible}
  ])
  useEffect(() => {
    if (sliderData) {
      setsliderDatas(sliderData)
    }
  }, [sliderData])
  return (
    <>
      <div className={`score_range_slider ${scrangeStyles.score_range_slider}`}>
        <label>{label}</label>
        <div className={`${scrangeStyles.tabslider_content}`}>
          <div className={`${scrangeStyles.range}`}>
            <input
              {...sliderProps}
              min="0"
              max="5"
              steps="1"
              type="range"
              value={value}
              className={`slider ${classes}`}
              id="myRange"
              onChange={handleChange}
            />
          </div>
          <ul className={` range_labels ${scrangeStyles.range_labels}`}>
            {sliderDatas.map((data) =>
                <li className={`${value === data.score ? "active" : ""}`} key={data.score}>
                  {data.score} <span>{data.label}</span>
                </li>
            )}
            
          </ul>
        </div>
      </div>
    </>
  );
}

UramScoreRangeSlider.propTypes = {
  validators: PropTypes.array,
  validators: PropTypes.array,
};

UramScoreRangeSlider.defaultProps = {
  validators: [],
  label: "",
  value: "",
  // sliderData: [
  //   { score: "0", label: 'without difficulty'},
  //   { score: "1", label: 'with very little difficulty'},
  //   { score: "2", label: 'with some difficulty'},
  //   { score: "3", label: 'with much difficulty'},
  //   { score: "4", label: 'almost impossible'},
  //   {score: "5", label: 'impossible'}
  // ]
};

export default UramScoreRangeSlider;
