import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import useTransition from "../../customHooks/translations";
import { validateInput } from "../../../utilities/Validator";
import scrangeStyles from "./ScoreRangeSlider.module.css";

function ScoreRangeSlider({
  classes,
  label,
  onChange,
  onMouseUp,
  value,
  validators,
  sliderData,
  ...sliderProps
}) {
  const [countR, setCountR] = useState(0);
  const translation = useTransition();
  const handleChange = (event) => {
    const { value } = event.target;
    onChange(value);
  };
  // console.log("value1", value);
  //   useEffect(() => {
  //     // if you dont set your inital state in your parent component
  //     // this effect will only run once when component did mount and
  //     // passes the initial value back to the parent component.
  //     onChange(value);
  //   }, []);
  const [sliderDatas, setsliderDatas] = useState([
    { score: "0", label: translation.Unable },
    { score: "1", label: translation.Very_difficult },
    { score: "2", label: translation.Somewhat_difficult },
    { score: "3", label: translation.Not_difficult },
   
  ])
  useEffect(() => {
    if (sliderData) {
      setsliderDatas(sliderData)
    }
  }, [sliderData])
  return (
    <>
      <div className={`score_range_slider ${scrangeStyles.score_range_slider}`}>
        <label>{label}</label>
        <div className={`${scrangeStyles.tabslider_content}`}>
          <div className={`${scrangeStyles.range}`}>
            <input
              {...sliderProps}
              min="0"
              max="3"
              steps="1"
              type="range"
              value={value}
              className={`slider ${classes}`}
              id="myRange"
              onChange={handleChange}
            />
          </div>
          <ul className={` range_labels ${scrangeStyles.range_labels}`}>
            {sliderDatas.map((data) =>
              <li className={`${value === data.score ? "active" : ""}`} key={data.score}>
                {data.score} <span>{data.label}</span>
              </li>
            )}

          </ul>
          {/* <ul className={` range_labels ${scrangeStyles.range_labels}`}>
            <li className={`${value === "0" ? "active" : ""}`}>
              0 <span>{translation.Unable}</span>
            </li>
            <li className={` ${value === "1" ? "active" : ""}`}>
              1 <span>{translation.Very_difficult}</span>
            </li>
            <li className={` ${value === "2" ? "active" : ""}`}>
              2 <span>{translation.Somewhat_difficult}</span>
            </li>
            <li className={` ${value === "3" ? "active" : ""}`}>
              3 <span>{translation.Not_difficult}</span>
            </li>
          </ul> */}
        </div>
      </div>
    </>
  );
}

ScoreRangeSlider.propTypes = {
  validators: PropTypes.array,
};

ScoreRangeSlider.defaultProps = {
  validators: [],
  label: "",
  value: "",
};

export default ScoreRangeSlider;
