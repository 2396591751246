import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import GradientSVG from "./GradientSVG";
import "react-circular-progressbar/dist/styles.css";
import rcpStyles from "./ResultCircleProgress.module.css";
import PropTypes from "prop-types";
import { ScoreColor, vasColor, FiveColor, CircleColor, UserDateFormat2, UserDateFormat } from "../../../utilities/DateFormate";
import useTranslation from "../../customHooks/translations";
const ResultCircleProgress = ({
  title,
  value,
  color,
  type,
  date,
  arm,
  total,
  persent,
  hide,
  reverse,
  min_score
}) => {
  const translation = useTranslation();
  let res = reverse
  console.log(value, "valuevalue")
  return (
    <>
      <div className={`${rcpStyles.result_scorebox_col}`}>
        <div className={`${rcpStyles.top_content}`}>
          <div className={`${rcpStyles.scorebox_title}`}>
            <h4 style={{fontSize:"32px"}}>
              {title}
            </h4>
            {!hide && (
              <label>
                <strong>{arm}</strong>
              </label>
            )}
            <h4>
              <strong>{value}</strong>
            </h4>
            <p>
              {value} {translation.out_of} {total}
            </p>


          </div>
          {!hide && (
            <div className={`${rcpStyles.result_skill_box}`}>
              <GradientSVG
                c1={

                  total.toString() === "5"
                    ? FiveColor(Number(value), res)
                    : total.toString() === "10"
                      ? vasColor(Number(value), res)
                      : ScoreColor(Number(value), res)
                }
                title={persent ? persent : value}
              />
              {title === "Pain" || title === "Instability" ?
                <CircularProgressbar
                  className={`${rcpStyles.pr_circile}`}
                  // value={CircleColor(total, value, res, min_score )}
                  value={total - value + min_score}
                  maxValue={Number(total)}
                  // text={value}
                  text={`${Math.round(value)}`}
                  strokeWidth="13"
                  styles={{
                    path: {
                      stroke: `url(#${persent ? persent : value})`,
                      height: "100%",
                    },
                    trail: {
                      stroke: "#fffff",
                    },
                  }}
                /> :
                <CircularProgressbar
                  className={`${rcpStyles.pr_circile}`}
                  value={min_score + (total - value)}

                  maxValue={Number(total)}
                  // text={value}
                  text={`${Math.round(value)}`}
                  strokeWidth="13"
                  styles={{
                    path: {
                      stroke: `url(#${persent ? persent : value})`,
                      height: "100%",
                    },
                    trail: {
                      stroke: "#fffff",
                    },
                  }}
                />}
            </div>
          )}
        </div>
        <span className={`${rcpStyles.btm_content}`}>
          {type}
          <small>
            <b>•</b>{UserDateFormat2(new Date().toLocaleDateString("en-GB"), sessionStorage.getItem("dateformat"))}
          </small>
        </span>
      </div>
    </>
  );
};

ResultCircleProgress.propTypes = {
  title: PropTypes.string,
};
ResultCircleProgress.defaultProps = {
  title: "",
  value: "",
  color: "",
  type: "",
  date: "",
  arm: "",
  // type: "",
  total: "100",
};

export default ResultCircleProgress;
