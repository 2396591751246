import React, { Component, useMemo, useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import keycloak from "./Keycloak";
import { Provider } from "react-redux";
import configureStore from "./redux/store";
import LoadingSpinner from "./components/custom/loader/LoadingSpinner";
import AppRouter from "./AppRouter";
import Welcome from "./Welcome";
import useTranslation from "./components/customHooks/translations";
function App() {
  const { keycloak, initialized } = useKeycloak();
  const [isLoading, setIsLoading] = useState(true);
  const translation = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2500);

  }, []);

  if (!initialized) {
    return <LoadingSpinner/>
  }

  return (
    <>

      {!keycloak.authenticated && <Welcome />}

      {!!keycloak.authenticated && <AppRouter />}
    </>
  );
}

export default App;
