import React, { useState } from "react";
import PropTypes from "prop-types";

// import { validateCheckbox } from "../../../utilities/Validator";

const RadioButton = ({ label, value, name, labelTitle, selected, styleClass, validators, onChange, id }) => {
  const [error, setError] = useState(false);
  const handleChange = (event) => {
    const target = event.target;
    // setError(validateCheckbox(validators, target.value));
    onChange(target.value);
  };

  return (
    <div className={`form-group ${styleClass}`}>
        <p className="rd_label_title">{labelTitle}</p>
        <input
          type="radio"
          className="mr-2"
          value={value}
          onChange={handleChange}
          id={id}
          name={name}
          checked={selected}
        />
      <label htmlFor={id}>
        {label}
      </label>
      {error && <span className="text-danger">{error.message}</span>}
    </div>
  );
};

RadioButton.propTypes = {
  styleClass: PropTypes.string,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

RadioButton.defaultProps = {
  styleClass: "",
  value: "",
  labelTitle: "",
};

export default RadioButton;
