import React from "react";
import "../../../css/ViewTreatmentForm.css";
import useTranslation from "../../customHooks/translations";
export default function HandWristQOL({ handQOL, AllData }) {
  const translations = useTranslation();
  return (
    <>
      <div className="whitebox padding-none">
        <div className="treatment-content">
          <div className="treatment-row-header">
            4. {translations.patient_hand_quality_of_life}
          </div>
          <div className="treatment-row">
            <div className="treatment-table">
              <table cellpadding="0" cellspacing="0">
                <thead>
                  <tr>
                    <th></th>
                    {/* {AllData.evaluated === "1" || AllData.evaluated === "3" ? (
                                            <th>Right Hand & Wrist</th>
                                        ) : (
                                            ""
                                        )} */}
                    {/* {AllData.evaluated === "2" || AllData.evaluated === "3" ? (
                                            <th>left Hand & Wrist</th>
                                        ) : (
                                            ""
                                        )} */}
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>{translations.patient_hand_quality_of_life_q1} </td>
                    <td>{handQOL.problem}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_hand_quality_of_life_q2}</td>
                    <td>{handQOL.washing}</td>
                    {/* <td>{handQOL.washingL}</td> */}
                  </tr>
                  <tr>
                    <td>{translations.patient_hand_quality_of_life_q3} </td>
                    <td>{handQOL.act}</td>
                    {/* <td>{handQOL.actL}</td> */}
                  </tr>
                  <tr>
                    <td>{translations.patient_hand_quality_of_life_q4}</td>
                    <td>{handQOL.pain}</td>
                    {/* <td>{handQOL.painL}</td> */}
                  </tr>
                  <tr>
                    <td>{translations.patient_hand_quality_of_life_q5}</td>
                    <td>{handQOL.depression}</td>
                    {/* <td>{handQOL.depressionL}</td> */}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="treatment-row">
            <div className="treatment-col">
              <label>{translations.patient_hand_quality_of_life_q6}</label>
              <p>{handQOL.handpercent}</p>
            </div>
            {/* <div className="treatment-col">
                            <label>How good or bad your health is TODAY? (left)</label>
                            <p>{handQOL.handpercentL}</p>
                        </div> */}
          </div>
        </div>
      </div>
    </>
  );
}
