import React, { useState, useEffect, useMemo, useCallback } from "react";
import RadioSwitch from "../../../custom/radio-switch/RadioSwitch";
import useTranslation from "../../../customHooks/translations";
import debounce from "lodash.debounce";
import { useDispatch, useSelector } from "react-redux";
import InputField from "../../../custom/inputfield/InputField";
import Select from "react-select";
import Checkbox from "../../../custom/checkbox/Checkbox";
import DatePicker from "react-datepicker";

import {
    updateHistoryComplaintFieldsScore,
    updateHistoryComplaintScore,
    updateSocialComplaintFieldsScore,
    updateSocialComplaintScore,
    updatePastComplaintFieldsScore,
    updatePastComplaintScore,
    updateStatus
} from "../../../../redux/actions/GeneralAction";


const PastIV = ({ Past }) => {
    let lang = sessionStorage.getItem("language")

    useEffect(() => {
        setDrop17(Past.drop17)
        setDrop18(Past.drop18)
        setDrop19(Past.drop19)
        setDrop20(Past.drop20)
        setDrop87(Past.drop87)
        setDrop21(Past.drop21)
        setDrop22(Past.drop22)
        setDrop23(Past.drop23)
        setDrop75(Past.drop75)
        setDrop24(Past.drop24)
        setDrop24Ch(Past.drop24Ch)
        setDrop52(Past.drop52)
        setDrop501(Past.drop501)
        setCheck_Value3(Past.checkvalue3)
        setCheck_Value4(Past.checkvalue4)
        setCheck_Value5(Past.checkvalue5)
        setCheck_Value6(Past.checkvalue6)
        setCheck_Value7(Past.checkvalue7)
        setCheck_Value8(Past.checkvalue8)
        setCheck_Value9(Past.checkvalue9)
        setneurologicinput(Past.neurologicinput)
        setmuscularinput(Past.muscularinput)
        setcheckvalue10(Past.checkvalue10)
        setotherinput(Past.otherinput)
        setarthritisdrop75(Past.arthritisdrop75)
        setOsteoarthritischeck(Past.Osteoarthritischeck)
        setRheumatoidcheck(Past.Rheumatoidcheck)
        setOthercheck(Past.Othercheck)
        setDropArt(Past.dropArt)
        setpsychiatric75(Past.psychiatric75)
        setpsychiatriccheck1(Past.psychiatriccheck1)
        setpsychiatriccheck2(Past.psychiatriccheck2)
        setpsychiatriccheck3(Past.psychiatriccheck3)
        setbleeding75(Past.bleeding75)
        setbleedingcheck1(Past.bleedingcheck1)
        setDrop25(Past.drop25)
        setbleedingcheck2(Past.bleedingcheck2)
        setbleedingcheck3(Past.bleedingcheck3)
        setbleedingcheck4(Past.bleedingcheck4)
        setbleedingcheck5(Past.bleedingcheck5)
        setbleedingcheck6(Past.bleedingcheck6)
        setbleedingcheck6input(Past.bleedingcheck6input)
        setDrop26(Past.drop26)
        setDrop27(Past.drop27)
        setDrop2445(Past.drop2445)
        setDrop2446(Past.drop2446)
        setDrop2447(Past.drop2447)
        setDrop29(Past.drop29)
        setcancerinput(Past.cancerinput)
        setcancerinput2(Past.cancerinput2)
        setDrop30(Past.drop30)
        setDrop31(Past.drop31)
        setstartDate(Past.startDate)
        setDrop33(Past.drop33)
        setdrop33check1(Past.drop33check1)
        setdrop33checkinput(Past.drop33checkinput)
        setDrop35(Past.drop35)
        setDrop36(Past.drop36)
        setDrop37(Past.drop37)
        setDrop38(Past.drop38)
        // setpregnantcheck(Past.pregnantcheck)
        setDrop502(Past.drop502)

    }, [Past]);
    const dispatch = useDispatch();
    const debouncedDispatch = useCallback(
        debounce((key, value) => {
            dispatch(updatePastComplaintFieldsScore({ key, value }));
        }, 100),
        [dispatch]
    );
    const translation = useTranslation();

    const [neurologicinput, setneurologicinput] = useState("")
    const [muscularinput, setmuscularinput] = useState("")
    const [checkvalue10, setcheckvalue10] = useState(false)
    const [otherinput, setotherinput] = useState("")

    const [drop17, setDrop17] = useState("");
    const handle_switch17 = (e) => {
        // dispatch(
        //   updatePastComplaintFieldsScore({
        //     key: "drop17",
        //     value: e,
        //   })
        // );
        setDrop17(e);
        debouncedDispatch("drop17", e)
    };
    const [drop18, setDrop18] = useState("");
    const handle_switch18 = (e) => {
        // dispatch(
        //   updatePastComplaintFieldsScore({
        //     key: "drop18",
        //     value: e,
        //   })
        // );
        setDrop18(e);
        debouncedDispatch("drop18", e)
    };
    const [drop19, setDrop19] = useState("");
    const handle_switch19 = (e) => {
        // dispatch(
        //   updatePastComplaintFieldsScore({
        //     key: "drop19",
        //     value: e,
        //   })
        // );
        setDrop19(e);
        debouncedDispatch("drop19", e)
    };
    const [drop20, setDrop20] = useState("");
    const handle_switch20 = (e) => {
        // dispatch(
        //   updatePastComplaintFieldsScore({
        //     key: "drop20",
        //     value: e,
        //   })
        // );
        setDrop20(e);
        debouncedDispatch("drop20", e)
    };
    const [drop87, setDrop87] = useState("");
    const handle_switch87 = (e) => {
        // dispatch(
        //   updatePastComplaintFieldsScore({
        //     key: "drop87",
        //     value: e,
        //   })
        // );
        setDrop87(e);
        debouncedDispatch("drop87", e)
    };
    const [drop21, setDrop21] = useState("");
    const handle_switch21 = (e) => {
        // dispatch(
        //   updatePastComplaintFieldsScore({
        //     key: "drop21",
        //     value: e,
        //   })
        // );
        setDrop21(e);
        debouncedDispatch("drop21", e)
    };
    const [drop22, setDrop22] = useState("0");
    const handle_switch22 = (e) => {
        // dispatch(
        //   updatePastComplaintFieldsScore({
        //     key: "drop22",
        //     value: e,
        //   })
        // );
        setDrop22(e);
        debouncedDispatch("drop22", e)
    };
    const [drop23, setDrop23] = useState("");
    const handle_switch23 = (e) => {
        // dispatch(
        //   updatePastComplaintFieldsScore({
        //     key: "drop23",
        //     value: e,
        //   })
        // );
        setDrop23(e);
        debouncedDispatch("drop23", e)
    };

    const [drop75, setDrop75] = useState("");
    const handle_switch75 = (e) => {
        // dispatch(
        //   updatePastComplaintFieldsScore({
        //     key: "drop75",
        //     value: e,
        //   })
        // );
        setDrop75(e);
        debouncedDispatch("drop75", e)
    };
    const [drop24, setDrop24] = useState("");
    const handle_switch24 = (e) => {
        // dispatch(
        //   updatePastComplaintFieldsScore({
        //     key: "drop24",
        //     value: e,
        //   })
        // );
        setDrop24(e);
        debouncedDispatch("drop24", e)
    };
    const [drop24Ch, setDrop24Ch] = useState("");
    const handle_switch24Ch = (e) => {
        // dispatch(
        //   updatePastComplaintFieldsScore({
        //     key: "drop24Ch",
        //     value: e,
        //   })
        // );
        setDrop24Ch(e);
        debouncedDispatch("drop24Ch", e)
    };

    const [drop52, setDrop52] = useState(false);
    const handle_switch52 = (e) => {
        // dispatch(
        //   updatePastComplaintFieldsScore({
        //     key: "drop52",
        //     value: e,
        //   })
        // );
        setDrop52(e);
        debouncedDispatch("drop52", e)
    };

    const [drop501, setDrop501] = useState(false);
    const handle_switch501 = (e) => {
        // dispatch(
        //   updatePastComplaintFieldsScore({
        //     key: "drop501",
        //     value: e,
        //   })
        // );
        setDrop501(e);
        debouncedDispatch("drop501", e)
    };

    const [checkvalue3, setCheck_Value3] = useState(false);
    const handle_check3 = (e) => {
        // dispatch(
        //   updatePastComplaintFieldsScore({
        //     key: "checkvalue3",
        //     value: e,
        //   })
        // );
        setCheck_Value3(e);
        debouncedDispatch("checkvalue3", e)
    };



    const [checkvalue4, setCheck_Value4] = useState(false);
    const handle_check4 = (e) => {
        // dispatch(
        //   updatePastComplaintFieldsScore({
        //     key: "checkvalue4",
        //     value: e,
        //   })
        // );
        setCheck_Value4(e);
        debouncedDispatch("checkvalue4", e)
    };
    const [checkvalue5, setCheck_Value5] = useState(false);
    const handle_check5 = (e) => {
        // dispatch(
        //   updatePastComplaintFieldsScore({
        //     key: "checkvalue5",
        //     value: e,
        //   })
        // );
        setCheck_Value5(e);
        debouncedDispatch("checkvalue5", e)
    };
    const [checkvalue6, setCheck_Value6] = useState(false);
    const handle_check6 = (e) => {
        // dispatch(
        //   updatePastComplaintFieldsScore({
        //     key: "checkvalue6",
        //     value: e,
        //   })
        // );
        setCheck_Value6(e);
        debouncedDispatch("checkvalue6", e)
    };
    const [checkvalue7, setCheck_Value7] = useState(false);
    const handle_check7 = (e) => {
        // dispatch(
        //   updatePastComplaintFieldsScore({
        //     key: "checkvalue7",
        //     value: e,
        //   })
        // );
        setCheck_Value7(e);
        debouncedDispatch("checkvalue7", e)
    };
    const [checkvalue8, setCheck_Value8] = useState(false);
    const handle_check8 = (e) => {
        // dispatch(
        //   updatePastComplaintFieldsScore({
        //     key: "checkvalue8",
        //     value: e,
        //   })
        // );
        setCheck_Value8(e);
        debouncedDispatch("checkvalue8", e)
    };
    const [checkvalue9, setCheck_Value9] = useState(false);
    const handle_check9 = (e) => {
        // dispatch(
        //   updatePastComplaintFieldsScore({
        //     key: "checkvalue9",
        //     value: e,
        //   })
        // );
        setCheck_Value9(e);
        debouncedDispatch("checkvalue9", e)
    };

    const handle_neurologicinput = (e) => {
        // dispatch(
        //   updatePastComplaintFieldsScore({
        //     key: "neurologicinput",
        //     value: e,
        //   }))
        setneurologicinput(e)
        debouncedDispatch("neurologicinput", e)
    }
    const handle_muscularinput = (e) => {
        // dispatch(
        //   updatePastComplaintFieldsScore({
        //     key: "muscularinput",
        //     value: e,
        //   }))
        setmuscularinput(e)
        debouncedDispatch("muscularinput", e)
    }
    const handle_check10 = (e) => {
        // dispatch(
        //   updatePastComplaintFieldsScore({
        //     key: "checkvalue10",
        //     value: e,
        //   }))
        setcheckvalue10(e)
        debouncedDispatch("checkvalue10", e)
    }
    const handle_otherinput = (e) => {
        // dispatch(
        //   updatePastComplaintFieldsScore({
        //     key: "otherinput",
        //     value: e,
        //   }))
        setotherinput(e)
        debouncedDispatch("otherinput", e)
    }

    const [arthritisdrop75, setarthritisdrop75] = useState("")
    const handle_arthritisswitch75 = (e) => {
        // dispatch(
        //   updatePastComplaintFieldsScore({
        //     key: "arthritisdrop75",
        //     value: e,
        //   }))
        setarthritisdrop75(e)
        debouncedDispatch("arthritisdrop75", e)
    }
    const [Osteoarthritischeck, setOsteoarthritischeck] = useState(false)
    const handleOsteoarthritischeck = (e) => {
        // dispatch(
        //   updatePastComplaintFieldsScore({
        //     key: "Osteoarthritischeck",
        //     value: e,
        //   }))
        setOsteoarthritischeck(e)
        debouncedDispatch("Osteoarthritischeck", e)
    }

    const [Rheumatoidcheck, setRheumatoidcheck] = useState(false)
    const handleRheumatoidcheck = (e) => {
        // dispatch(
        //   updatePastComplaintFieldsScore({
        //     key: "Rheumatoidcheck",
        //     value: e,
        //   }))
        setRheumatoidcheck(e)
        debouncedDispatch("Rheumatoidcheck", e)
    }

    const [Othercheck, setOthercheck] = useState(false)
    const handleOthercheck = (e) => {
        // dispatch(
        //   updatePastComplaintFieldsScore({
        //     key: "Othercheck",
        //     value: e,
        //   }))
        setOthercheck(e)
        debouncedDispatch("Othercheck", e)
    }

    const [dropArt, setDropArt] = useState("");
    const handle_switchArt = (e) => {
        // dispatch(
        //   updatePastComplaintFieldsScore({
        //     key: "dropArt",
        //     value: e,
        //   })
        // );
        setDropArt(e);
        debouncedDispatch("dropArt", e)
    };
    const [psychiatric75, setpsychiatric75] = useState(false)
    const handlepsychiatric75 = (e) => {
        // dispatch(
        //   updatePastComplaintFieldsScore({
        //     key: "psychiatric75",
        //     value: e,
        //   }))
        setpsychiatric75(e)
        debouncedDispatch("psychiatric75", e)
    }

    const [psychiatriccheck1, setpsychiatriccheck1] = useState(false)
    const handlepsychiatriccheck1 = (e) => {
        // dispatch(
        //   updatePastComplaintFieldsScore({
        //     key: "psychiatriccheck1",
        //     value: e,
        //   }))
        setpsychiatriccheck1(e)
        debouncedDispatch("psychiatriccheck1", e)
    }
    const [psychiatriccheck2, setpsychiatriccheck2] = useState(false)
    const handlepsychiatriccheck2 = (e) => {
        // dispatch(
        //   updatePastComplaintFieldsScore({
        //     key: "psychiatriccheck2",
        //     value: e,
        //   }))
        setpsychiatriccheck2(e)
        debouncedDispatch("psychiatriccheck2", e)
    }
    const [psychiatriccheck3, setpsychiatriccheck3] = useState(false)
    const handlepsychiatriccheck3 = (e) => {
        // dispatch(
        //   updatePastComplaintFieldsScore({
        //     key: "psychiatriccheck3",
        //     value: e,
        //   }))
        setpsychiatriccheck3(e)
        debouncedDispatch("psychiatriccheck3", e)
    }
    const [bleeding75, setbleeding75] = useState(false)
    const handlebleeding75 = (e) => {
        // dispatch(
        //   updatePastComplaintFieldsScore({
        //     key: "bleeding75",
        //     value: e,
        //   }))
        setbleeding75(e)
        debouncedDispatch("bleeding75", e)
    }
    const [bleedingcheck1, setbleedingcheck1] = useState(false)
    const handlebleedingcheck1 = (e) => {
        // dispatch(
        //   updatePastComplaintFieldsScore({
        //     key: "bleedingcheck1",
        //     value: e,
        //   }))
        setbleedingcheck1(e)
        debouncedDispatch("bleedingcheck1", e)
    }

    const [drop25, setDrop25] = useState("");
    const handle_switch25 = (e) => {
        // dispatch(
        //   updatePastComplaintFieldsScore({
        //     key: "drop25",
        //     value: e,
        //   })
        // );
        setDrop25(e);
        debouncedDispatch("drop25", e)
    };

    const [bleedingcheck2, setbleedingcheck2] = useState(false)
    const handlebleedingcheck2 = (e) => {
        // dispatch(
        //   updatePastComplaintFieldsScore({
        //     key: "bleedingcheck2",
        //     value: e,
        //   }))
        setbleedingcheck2(e)
        debouncedDispatch("bleedingcheck2", e)
    }
    const [bleedingcheck3, setbleedingcheck3] = useState(false)
    const handlebleedingcheck3 = (e) => {
        // dispatch(
        //   updatePastComplaintFieldsScore({
        //     key: "bleedingcheck3",
        //     value: e,
        //   }))
        setbleedingcheck3(e)
        debouncedDispatch("bleedingcheck3", e)
    }
    const [bleedingcheck4, setbleedingcheck4] = useState(false)
    const handlebleedingcheck4 = (e) => {
        // dispatch(
        //   updatePastComplaintFieldsScore({
        //     key: "bleedingcheck4",
        //     value: e,
        //   }))
        setbleedingcheck4(e)
        debouncedDispatch("bleedingcheck4", e)
    }
    const [bleedingcheck5, setbleedingcheck5] = useState(false)
    const handlebleedingcheck5 = (e) => {
        // dispatch(
        //   updatePastComplaintFieldsScore({
        //     key: "bleedingcheck5",
        //     value: e,
        //   }))
        setbleedingcheck5(e)
        debouncedDispatch("bleedingcheck5", e)
    }

    const [bleedingcheck6, setbleedingcheck6] = useState(false)
    const handlebleedingcheck6 = (e) => {
        // dispatch(
        //   updatePastComplaintFieldsScore({
        //     key: "bleedingcheck6",
        //     value: e,
        //   }))
        setbleedingcheck6(e)
        debouncedDispatch("bleedingcheck6", e)
    }

    const [bleedingcheck6input, setbleedingcheck6input] = useState("")
    const handlebleedingcheck6input = (e) => {
        // dispatch(
        //   updatePastComplaintFieldsScore({
        //     key: "bleedingcheck6input",
        //     value: e,
        //   }))
        setbleedingcheck6input(e)
        debouncedDispatch("bleedingcheck6input", e)
    }

    const [drop26, setDrop26] = useState("");
    const handle_switch26 = (e) => {
        // dispatch(
        //   updatePastComplaintFieldsScore({
        //     key: "drop26",
        //     value: e,
        //   })
        // );
        setDrop26(e);
        debouncedDispatch("drop26", e)
    };
    const [drop27, setDrop27] = useState("");
    const handle_switch504 = (e) => {
        // dispatch(
        //   updatePastComplaintFieldsScore({
        //     key: "drop27",
        //     value: e,
        //   })
        // );
        setDrop27(e);
        debouncedDispatch("drop27", e)
    };
    const [drop2445, setDrop2445] = useState("");
    const handle_switchdrop2445 = (e) => {
        // dispatch(
        //   updatePastComplaintFieldsScore({
        //     key: "drop2445",
        //     value: e,
        //   })
        // );
        setDrop2445(e);
        debouncedDispatch("drop2445", e)
    };
    const [drop2446, setDrop2446] = useState("");
    const handle_switchdrop2446 = (e) => {
        // dispatch(
        //   updatePastComplaintFieldsScore({
        //     key: "drop2446",
        //     value: e,
        //   })
        // );
        setDrop2446(e);
        debouncedDispatch("drop2446", e)
    };
    const [drop2447, setDrop2447] = useState("");
    const handle_switchdrop2447 = (e) => {
        // dispatch(
        //   updatePastComplaintFieldsScore({
        //     key: "drop2447",
        //     value: e,
        //   })
        // );
        setDrop2447(e);
        debouncedDispatch("drop2447", e)
    };

    const [drop29, setDrop29] = useState("");
    const handle_switch29 = (e) => {
        // dispatch(
        //   updatePastComplaintFieldsScore({
        //     key: "drop29",
        //     value: e,
        //   })
        // );
        setDrop29(e);
        debouncedDispatch("drop29", e)
    };
    const [cancerinput, setcancerinput] = useState("")
    const handlecancerinput = (e) => {
        // dispatch(
        //   updatePastComplaintFieldsScore({
        //     key: "cancerinput",
        //     value: e,
        //   }))
        setcancerinput(e)
        debouncedDispatch("cancerinput", e)
    }
    const [cancerinput2, setcancerinput2] = useState("")
    const handlecancerinput2 = (e) => {
        // dispatch(
        //   updatePastComplaintFieldsScore({
        //     key: "cancerinput2",
        //     value: e,
        //   }))
        setcancerinput2(e)
        debouncedDispatch("cancerinput2", e)
    }

    const [drop30, setDrop30] = useState("0");
    const handle_switch30 = (e) => {
        // dispatch(
        //   updatePastComplaintFieldsScore({
        //     key: "drop30",
        //     value: e,
        //   })
        // );
        setDrop30(e);
        debouncedDispatch("drop30", e)
    };
    const [drop31, setDrop31] = useState("");
    const handle_switch31 = (e) => {
        // dispatch(
        //   updatePastComplaintFieldsScore({
        //     key: "drop31",
        //     value: e,
        //   })
        // );
        setDrop31(e);
        debouncedDispatch("drop31", e)
    };

    const [startDate, setstartDate] = useState(new Date());
    const handleDate = (e) => {
        // dispatch(
        //   updateHistoryComplaintFieldsScore({
        //     key: "startDate",
        //     value: e,
        //   })
        // );
        setstartDate(e);
        debouncedDispatch("startDate", e)
    };
    const [drop33, setDrop33] = useState("0");
    const handle_switch33 = (e) => {
        // dispatch(
        //   updatePastComplaintFieldsScore({
        //     key: "drop33",
        //     value: e,
        //   })
        // );
        setDrop33(e);
        debouncedDispatch("drop33", e)
    };
    const [drop33check1, setdrop33check1] = useState(false)

    const handledrop33check1 = (e) => {
        // dispatch(
        //   updatePastComplaintFieldsScore({
        //     key: "drop33check1",
        //     value: e,
        //   }))
        setdrop33check1(e)
        debouncedDispatch("drop33check1", e)
    }
    const [drop33checkinput, setdrop33checkinput] = useState(false)
    const handledrop33checkinput = (e) => {
        // dispatch(
        //   updatePastComplaintFieldsScore({
        //     key: "drop33checkinput",
        //     value: e,
        //   }))
        setdrop33checkinput(e)
        debouncedDispatch("drop33checkinput", e)
    }

    const [drop35, setDrop35] = useState("0");
    const handle_switch35 = (e) => {
        // dispatch(
        //   updatePastComplaintFieldsScore({
        //     key: "drop35",
        //     value: e,
        //   })
        // );
        setDrop35(e);
        debouncedDispatch("drop35", e)
    };
    const [drop36, setDrop36] = useState("0");
    const handle_switch36 = (e) => {
        // dispatch(
        //   updatePastComplaintFieldsScore({
        //     key: "drop36",
        //     value: e,
        //   })
        // );
        setDrop36(e);
        debouncedDispatch("drop36", e)
    };
    const [drop37, setDrop37] = useState("0");
    const handle_switch37 = (e) => {
        // dispatch(
        //   updatePastComplaintFieldsScore({
        //     key: "drop37",
        //     value: e,
        //   })
        // );
        setDrop37(e);
        debouncedDispatch("drop37", e)
    };
    const [drop38, setDrop38] = useState("0");
    const handle_switch38 = (e) => {
        // dispatch(
        //   updatePastComplaintFieldsScore({
        //     key: "drop38",
        //     value: e,
        //   })
        // );
        setDrop38(e);
        debouncedDispatch("drop38", e)
    };

    const [pregnantcheck, setpregnantcheck] = useState("")
    const handlepregnantcheck = (e) => {
        // dispatch(
        //   updatePastComplaintFieldsScore({
        //     key: "pregnantcheck",
        //     value: e,
        //   }))
        setpregnantcheck(e)
        debouncedDispatch("pregnantcheck", e)
    }

    const [drop502, setDrop502] = useState('');
    const handle_switch502 = (e) => {
        // alert(e)
        // dispatch(
        //   updatePastComplaintFieldsScore({
        //     key: "drop502",
        //     value: e,
        //   })
        // );
        setDrop502(new Date(e));
        debouncedDispatch("drop502", e)
    };

    return (
        <div>
            <RadioSwitch
                labelLeft={translation.patient_general_past_history_q14_a1}
                labelRight={translation.patient_general_past_history_q14_a2}
                idLeft={"90"}
                idRight={"91"}
                name={"unaffected_shoulder95"}
                title={translation.patient_general_past_history_q14}
                styleClass=""
                value={drop17}
                onChange={handle_switch17}
                placeholder="select"
            />

            <RadioSwitch
                labelLeft={translation.patient_general_past_history_q15_a1}
                labelRight={translation.patient_general_past_history_q15_a2}
                idLeft={"83"}
                idRight={"84"}
                name={"unaffected_shoulder84"}
                title={translation.patient_general_past_history_q15}
                styleClass=""
                value={drop18}
                onChange={handle_switch18}
                placeholder="select"
            />
            {drop18 === "1" && (
                <>
                    <InputField
                        value={drop52}
                        type="textarea"
                        placeholder={translation.patient_general_past_history_q15_a2_placeholder}
                        rows="4"
                        cols="20"
                        onChange={handle_switch52}
                    />

                    <RadioSwitch
                        labelLeft={translation.patient_general_past_history_q15_a2_q1_a1}
                        labelRight={translation.patient_general_past_history_q15_a2_q1_a2}
                        idLeft={"85"}
                        idRight={"86"}
                        name={"unaffected_shoulder85"}
                        title={translation.patient_general_past_history_q15_a2_q1}
                        styleClass=""
                        value={drop19}
                        onChange={handle_switch19}
                    />

                    {drop19 === "1" && (
                        <InputField
                            value={drop501}
                            type="textarea"
                            placeholder={translation.patient_general_past_history_q15_a2_q1_a2_placeholder}
                            rows="4"
                            cols="20"
                            onChange={handle_switch501}
                        />
                    )}

                </>
            )}

            <RadioSwitch
                labelLeft={translation.patient_general_past_history_q16_a1}
                labelRight={translation.patient_general_past_history_q16_a2}
                idLeft={"87"}
                idRight={"88"}
                name={"unaffected_shoulder87"}
                title={translation.patient_general_past_history_q16}
                styleClass=""
                value={drop20}
                onChange={handle_switch20}
                placeholder="select"
            />

            {drop20 === "1" && (
                <InputField
                    label={translation.patient_general_past_history_q16_a2_q1}
                    value={drop87}
                    type="textarea"
                    placeholder={translation.patient_general_past_history_q16_a2_q1_placeholder}
                    rows="4"
                    cols="20"
                    onChange={handle_switch87}
                />
            )}

            <RadioSwitch
                labelLeft={translation.patient_general_past_history_q17_a1}
                labelRight={translation.patient_general_past_history_q17_a2}
                idLeft={"72"}
                idRight={"73"}
                name={"unaffected_shoulder71"}
                title={translation.patient_general_past_history_q17}
                styleClass=""
                value={drop21}
                onChange={handle_switch21}
                placeholder="select"
            />

            <RadioSwitch
                labelLeft={translation.patient_general_past_history_q18_a1}
                labelRight={translation.patient_general_past_history_q18_a2}
                idLeft={"15"}
                idRight={"16"}
                name={"unaffected_shoulder7"}
                title={translation.patient_general_past_history_q18}
                styleClass=""
                value={drop22}
                onChange={handle_switch22}
                placeholder="select"
            />

            {drop22 === "1" && (
                <InputField
                    label={translation.patient_general_past_history_q18_a2_q1}
                    value={drop23}
                    type="textarea"
                    placeholder={translation.patient_general_past_history_q18_a2_q1_placeholder}
                    rows="4"
                    cols="20"
                    onChange={handle_switch23}
                // styleClass="h_150"
                />
            )}

            <RadioSwitch
                labelLeft={translation.patient_general_past_history_q19_a1}
                labelRight={translation.patient_general_past_history_q19_a2}
                idLeft={"74"}
                idRight={"75"}
                name={"unaffected_shoulder75"}
                title={translation.patient_general_past_history_q19}
                styleClass=""
                value={drop75}
                onChange={handle_switch75}
                placeholder="select"
            />

            {drop75 === "1" && (
                <div>
                    <Checkbox
                        label={translation.patient_general_past_history_q19_a2_o1}
                        selected={checkvalue3}
                        value={checkvalue3}
                        onChange={handle_check3}
                        styleClass=""
                    />
                    {checkvalue3 === true && (
                        <InputField

                            value={drop24}
                            type="textarea"
                            placeholder={translation.patient_general_past_history_q19_a2_o1_placeholder}
                            rows="4"
                            cols="20"
                            onChange={handle_switch24}
                        />
                    )}

                    <Checkbox
                        label={translation.patient_general_past_history_q19_a2_o2}
                        selected={checkvalue4}
                        value={checkvalue4}
                        onChange={handle_check4}
                        styleClass=""
                    />
                    {checkvalue4 === true && (
                        <InputField

                            value={drop24Ch}
                            type="textarea"
                            placeholder={translation.patient_general_past_history_q19_a2_o2_placeholder}
                            rows="4"
                            cols="20"
                            onChange={handle_switch24Ch}
                        />
                    )}
                    <Checkbox
                        label={translation.patient_general_past_history_q19_a2_o3}
                        selected={checkvalue5}
                        value={checkvalue5}
                        onChange={handle_check5}
                        styleClass=""
                    />
                    <Checkbox
                        label={translation.patient_general_past_history_q19_a2_o4}
                        selected={checkvalue6}
                        value={checkvalue6}
                        onChange={handle_check6}
                        styleClass=""
                    />
                    {checkvalue6 && (
                        <InputField

                            value={neurologicinput}
                            type="textarea"
                            placeholder={translation.patient_general_past_history_q19_a2_o4_placeholder}
                            rows="4"
                            cols="20"
                            onChange={handle_neurologicinput}
                        />
                    )}
                    <Checkbox
                        label={translation.patient_general_past_history_q19_a2_o5}
                        selected={checkvalue7}
                        value={checkvalue7}
                        onChange={handle_check7}
                        styleClass=""
                    />
                    {checkvalue7 && (
                        <InputField
                            value={muscularinput}
                            type="textarea"
                            placeholder={translation.patient_general_past_history_q19_a2_o5_placeholder}
                            rows="4"
                            cols="20"
                            onChange={handle_muscularinput}
                        />
                    )}
                    <Checkbox
                        label={translation.patient_general_past_history_q19_a2_o6}
                        selected={checkvalue8}
                        onChange={handle_check8}
                        value={checkvalue8}
                        styleClass=""
                    />
                    <Checkbox
                        label={translation.patient_general_past_history_q19_a2_o7}
                        selected={checkvalue9}
                        onChange={handle_check9}
                        value={checkvalue9}
                        styleClass=""
                    />
                    <Checkbox
                        label={translation.patient_general_past_history_q19_a2_o8}
                        selected={checkvalue10}
                        onChange={handle_check10}
                        value={checkvalue10}
                        styleClass=""
                    />
                    {checkvalue10 && (
                        <InputField

                            value={otherinput}
                            type="textarea"
                            placeholder={translation.patient_general_past_history_q19_a2_o8_placeholder}
                            rows="4"
                            cols="20"
                            onChange={handle_otherinput}
                        />
                    )}
                </div>
            )}


            <RadioSwitch
                labelLeft={translation.patient_general_past_history_q20_a1}
                labelRight={translation.patient_general_past_history_q20_a2}
                idLeft={"741"}
                idRight={"751"}
                name={"arthritis_shoulder75"}
                title={translation.patient_general_past_history_q20}
                styleClass=""
                value={arthritisdrop75}
                onChange={handle_arthritisswitch75}
                placeholder="select"
            />
            {arthritisdrop75 === "1" && (
                <div>
                    <Checkbox
                        label={translation.patient_general_past_history_q20_a2_o1}
                        selected={Osteoarthritischeck}
                        value={Osteoarthritischeck}
                        onChange={handleOsteoarthritischeck}
                        styleClass=""
                    />
                    <Checkbox
                        label={translation.patient_general_past_history_q20_a2_o2}
                        selected={Rheumatoidcheck}
                        value={Rheumatoidcheck}
                        onChange={handleRheumatoidcheck}
                        styleClass=""
                    />
                    <Checkbox
                        label={translation.patient_general_past_history_q19_a2_o8}
                        selected={Othercheck}
                        value={Othercheck}
                        onChange={handleOthercheck}
                        styleClass=""
                    />
                    {Othercheck && (
                        <InputField
                            // label={translation.arthritis}
                            value={dropArt}
                            type="textarea"
                            placeholder={translation.patient_general_past_history_q20_a2_o3_placeholder}
                            rows="4"
                            cols="20"
                            onChange={handle_switchArt}
                        // styleClass="h_150"
                        />
                    )}
                </div>
            )}

            <RadioSwitch
                labelLeft={translation.patient_general_past_history_q21_a1}
                labelRight={translation.patient_general_past_history_q21_a2}
                idLeft={"psychiatric741"}
                idRight={"psychiatric751"}
                name={"arthritispsychiatric75"}
                title={translation.patient_general_past_history_q21}
                styleClass=""
                value={psychiatric75}
                onChange={handlepsychiatric75}
                placeholder="select"
            />
            {psychiatric75 === "1" && (
                <div>
                    <Checkbox
                        label={translation.patient_general_past_history_q21_a2_o1}
                        selected={psychiatriccheck1}
                        value={psychiatriccheck1}
                        onChange={handlepsychiatriccheck1}
                        styleClass=""
                    />
                    <Checkbox
                        label={translation.patient_general_past_history_q21_a2_o2}
                        selected={psychiatriccheck2}
                        value={psychiatriccheck2}
                        onChange={handlepsychiatriccheck2}
                        styleClass=""
                    />
                    <Checkbox
                        label={translation.patient_general_past_history_q21_a2_o3}
                        selected={psychiatriccheck3}
                        value={psychiatriccheck3}
                        onChange={handlepsychiatriccheck3}
                        styleClass=""
                    />
                    {psychiatriccheck3 && (
                        <InputField
                            // label={translation.psychiatric}
                            value={drop25}
                            type="textarea"
                            placeholder={translation.patient_general_past_history_q21_a2_o3_placeholder}
                            rows="4"
                            cols="20"
                            onChange={handle_switch25}

                        />
                    )}
                </div>
            )}

            <RadioSwitch
                labelLeft={translation.patient_general_past_history_q22_a1}
                labelRight={translation.patient_general_past_history_q22_a2}
                idLeft={"bleeding741"}
                idRight={"bleeding751"}
                name={"arthritisbleeding75"}
                title={translation.patient_general_past_history_q22}
                styleClass=""
                value={bleeding75}
                onChange={handlebleeding75}
                placeholder="select"
            />
            {bleeding75 === "1" && (
                <div>
                    <Checkbox
                        label={translation.patient_general_past_history_q22_a2_o1}
                        selected={bleedingcheck1}
                        value={bleedingcheck1}
                        onChange={handlebleedingcheck1}
                        styleClass=""
                    />
                    <Checkbox
                        label={translation.patient_general_past_history_q22_a2_o2}
                        selected={bleedingcheck2}
                        value={bleedingcheck2}
                        onChange={handlebleedingcheck2}
                        styleClass=""
                    />
                    <Checkbox
                        label={translation.patient_general_past_history_q22_a2_o3}
                        selected={bleedingcheck3}
                        value={bleedingcheck3}
                        onChange={handlebleedingcheck3}
                        styleClass=""
                    />
                    <Checkbox
                        label={translation.patient_general_past_history_q22_a2_o4}
                        selected={bleedingcheck4}
                        value={bleedingcheck4}
                        onChange={handlebleedingcheck4}
                        styleClass=""
                    />
                    <Checkbox
                        label={translation.patient_general_past_history_q22_a2_o5}
                        selected={bleedingcheck5}
                        value={bleedingcheck5}
                        onChange={handlebleedingcheck5}
                        styleClass=""
                    />
                    <Checkbox
                        label={translation.patient_general_past_history_q22_a2_o6}
                        selected={bleedingcheck6}
                        value={bleedingcheck6}
                        onChange={handlebleedingcheck6}
                        styleClass=""
                    />
                    {bleedingcheck6 && (
                        <InputField
                            // label={translation.psychiatric}
                            value={bleedingcheck6input}
                            type="textarea"
                            placeholder={translation.patient_general_past_history_q22_a2_o6_placeholder}
                            rows="4"
                            cols="20"
                            onChange={handlebleedingcheck6input}

                        />
                    )}
                </div>
            )}

            <RadioSwitch
                labelLeft={translation.patient_general_past_history_q23_a1}
                labelRight={translation.patient_general_past_history_q23_a2}
                idLeft={"drop26741"}
                idRight={"drop26751"}
                name={"arthritisdrop2675"}
                title={translation.patient_general_past_history_q23}
                styleClass=""
                value={drop26}
                onChange={handle_switch26}
                placeholder="select"
            />

            <RadioSwitch
                labelLeft={translation.patient_general_past_history_q24_a1}
                labelRight={translation.patient_general_past_history_q24_a2}
                idLeft={"drop27741"}
                idRight={"drop27751"}
                name={"arthritisdrop2775"}
                title={translation.patient_general_past_history_q24}
                styleClass=""
                value={drop27}
                onChange={handle_switch504}
                placeholder="select"
            />

            <label className="mt-2 mb-2">{translation.patient_general_past_history_q25}</label>

            <RadioSwitch
                labelLeft={translation.patient_general_past_history_q25_a1_o1}
                labelRight={translation.patient_general_past_history_q25_a1_o2}
                idLeft={"drop2445"}
                idRight={"drop2446"}
                name={"arthritisdrop6666"}
                title={translation.patient_general_past_history_q25_a1}
                styleClass=""
                value={drop2445}
                onChange={handle_switchdrop2445}
                placeholder="select"
            />
            <RadioSwitch
                labelLeft={translation.patient_general_past_history_q25_a2_o1}
                labelRight={translation.patient_general_past_history_q25_a2_o2}
                idLeft={"drop2447"}
                idRight={"drop2448"}
                name={"arthritisdrop6667"}
                title={translation.patient_general_past_history_q25_a2}
                styleClass=""
                value={drop2446}
                onChange={handle_switchdrop2446}
                placeholder="select"
            />
            <RadioSwitch
                labelLeft={translation.patient_general_past_history_q25_a3_o1}
                labelRight={translation.patient_general_past_history_q25_a3_o2}
                idLeft={"drop2449"}
                idRight={"drop2450"}
                name={"arthritisdrop6668"}
                title={translation.patient_general_past_history_q25_a3}
                styleClass=""
                value={drop2447}
                onChange={handle_switchdrop2447}
                placeholder="select"
            />


            <RadioSwitch
                labelLeft={translation.patient_general_past_history_q26_a1}
                labelRight={translation.patient_general_past_history_q26_a2}
                idLeft={"drop293741"}
                idRight={"drop293751"}
                name={"arthritisdrop29375"}
                title={translation.patient_general_past_history_q26}
                formGroupClass="mt-3"
                value={drop29}
                onChange={handle_switch29}
                placeholder="select"
            />
            {drop29 === "1" && (
                <div>
                    <InputField
                        // label={translation.patient_general_past_history_q26_a2_placeholder1}
                        value={cancerinput}
                        type="textarea"
                        placeholder={translation.patient_general_past_history_q26_a2_placeholder1}
                        rows="4"
                        cols="20"
                        onChange={handlecancerinput}

                    />
                    <InputField
                        // label={translation.patient_general_past_history_q26_a2_placeholder2}
                        value={cancerinput2}
                        type="textarea"
                        placeholder={translation.patient_general_past_history_q26_a2_placeholder2}
                        rows="4"
                        cols="20"
                        onChange={handlecancerinput2}

                    />
                </div>
            )}

            <RadioSwitch
                labelLeft={translation.patient_general_past_history_q27_a1}
                labelRight={translation.patient_general_past_history_q27_a2}
                idLeft={"17"}
                idRight={"18"}
                name={"unaffected_shoulder8"}
                title={translation.patient_general_past_history_q27}
                styleClass=""
                value={drop30}
                onChange={handle_switch30}
                placeholder="select"
            />
            <div>
                {drop30 === "1" && (
                    <>
                        <InputField
                            // label={translation.treatment}
                            value={drop31}
                            type="textarea"
                            placeholder={translation.patient_general_past_history_q27_a2_placeholder1}
                            rows="4"
                            cols="20"
                            onChange={handle_switch31}
                        // styleClass="h_150"
                        />

                        <label>{translation.patient_general_past_history_q27_a2_placeholder2}</label>
                        <DatePicker
                            dateFormat="dd/MM/yyyy"
                            // maxDate={maxDate}
                            className={lang === "ara" ? "w-25 br_10 mb_0 form-control arab_date" : "form-control mb_0 br_10 w-25"}
                            selected={new Date(startDate)}
                            onChange={handleDate}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            locale={
                                lang === "en"
                                    ? "en-US"
                                    : lang === "ara"
                                        ? "ar"
                                        : lang === "ger"
                                            ? "de"
                                            : lang === "ita"
                                                ? "it"
                                                : lang === "spa"
                                                    ? "es"
                                                    : lang === "tha"
                                                        ? "th"
                                                        : lang === "fr"
                                                            ? "fr"
                                                            : "en-US"
                            }
                        />
                    </>
                )}
            </div>
            <div>
                <RadioSwitch
                    labelLeft={translation.patient_general_past_history_q28_a1}
                    labelRight={translation.patient_general_past_history_q28_a2}
                    idLeft={"19"}
                    idRight={"20"}
                    name={"unaffected_shoulder9"}
                    title={translation.patient_general_past_history_q28}
                    styleClass=""
                    value={drop33}
                    onChange={handle_switch33}
                    placeholder="select"
                />
                {drop33 === "1" && (
                    <>
                        <label>{translation.patient_general_past_history_q28_a2_q1} </label>

                        <Select
                            isMulti
                            name="weekday"
                            options={[
                                {
                                    value: "0",
                                    label: translation.patient_general_past_history_q28_a2_q1_o1,
                                },
                                {
                                    value: "2",
                                    label: translation.patient_general_past_history_q28_a2_q1_o2,
                                },
                                {
                                    value: "3",
                                    label: translation.patient_general_past_history_q28_a2_q1_o3,
                                },
                                {
                                    value: "4",
                                    label: translation.patient_general_past_history_q28_a2_q1_o4,
                                },
                                {
                                    value: "5",
                                    label: translation.patient_general_past_history_q28_a2_q1_o5,
                                },

                            ]}
                            onChange={handledrop33check1}
                            value={drop33check1}
                            // className="form-control form-select"
                            placeholder={<div>{translation.patient_general_past_history_q28_a2_q1_placeholder}</div>}
                        />
                        {Array.isArray(drop33check1) && drop33check1.filter((val) => val.value === "5")[0] ? (
                            <div className="mt-4">
                                <InputField
                                    //  label={translation.treatment}
                                    value={drop33checkinput}
                                    type="textarea"
                                    placeholder={translation.patient_general_past_history_q28_a2_q1_o5_placeholder}
                                    rows="4"
                                    cols="20"
                                    onChange={handledrop33checkinput}
                                // styleClass="h_150"
                                />
                            </div>
                        ) : ""}



                    </>
                )}
            </div>
            <div className="mt-3">
                <strong className="d-inline-block mb_15 form-label">
                    {translation.patient_general_past_history_q29}
                </strong>
                <RadioSwitch
                    labelLeft={translation.patient_general_past_history_q29_q1_a1}
                    labelRight={translation.patient_general_past_history_q29_q1_a2}
                    idLeft={"21"}
                    idRight={"22"}
                    name={"unaffected_shoulder10"}
                    title={translation.patient_general_past_history_q29_q1}
                    styleClass=""
                    value={drop35}
                    onChange={handle_switch35}
                    placeholder="select"
                />
                <RadioSwitch
                    labelLeft={translation.patient_general_past_history_q29_q2_a1}
                    labelRight={translation.patient_general_past_history_q29_q2_a2}
                    idLeft={"23"}
                    idRight={"24"}
                    name={"unaffected_shoulde11"}
                    title={translation.patient_general_past_history_q29_q2}
                    styleClass=""
                    value={drop36}
                    onChange={handle_switch36}
                    placeholder="select"
                />
                <RadioSwitch
                    labelLeft={translation.patient_general_past_history_q29_q3_a1}
                    labelRight={translation.patient_general_past_history_q29_q3_a2}
                    idLeft={"25"}
                    idRight={"26"}
                    name={"unaffected_shoulder12"}
                    title={translation.patient_general_past_history_q29_q3}
                    styleClass=""
                    value={drop37}
                    onChange={handle_switch37}
                    placeholder="select"
                />

                <RadioSwitch
                    labelLeft={translation.patient_general_past_history_q30_a1}
                    labelRight={translation.patient_general_past_history_q30_a2}
                    idLeft={"27"}
                    idRight={"28"}
                    name={"unaffected_shoulder13"}
                    title={translation.patient_general_past_history_q30}
                    styleClass=""
                    value={drop38}
                    onChange={handle_switch38}
                    placeholder="select"
                    disabled={pregnantcheck}
                />
                {/* <Checkbox
                    label={translation.patient_general_past_history_q30_not_applicable}
                    selected={pregnantcheck}
                    value={pregnantcheck}
                    onChange={handlepregnantcheck}
                    styleClass=""
                /> */}

                {/* {!pregnantcheck && (
                    <>
                        <label>{translation.patient_general_past_history_q30_a2_q1}</label>

                        <DatePicker
                            dateFormat="dd/MM/yyyy"
                            // maxDate={maxDate}
                            className={lang === "ara" ? "w-25 br_10 mb_0 form-control arab_date" : "form-control mb_0 br_10 w-25"}
                            //  selected={new Date(startDate)}
                            placeholderText={translation.patient_general_past_history_q30_a2_q1_placeholder}
                            // placeholderText="Select End Date"                      
                            onChange={handle_switch502}
                            selected={drop502}

                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            locale={
                                lang === "en"
                                    ? "en-US"
                                    : lang === "ara"
                                        ? "ar"
                                        : lang === "ger"
                                            ? "de"
                                            : lang === "ita"
                                                ? "it"
                                                : lang === "spa"
                                                    ? "es"
                                                    : lang === "tha"
                                                        ? "th"
                                                        : lang === "fr"
                                                            ? "fr"
                                                            : "en-US"
                            }
                        />
                    </>
                )} */}





            </div>

        </div>
    )
}

export default PastIV;