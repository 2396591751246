import React from "react";
import "../../../css/ViewTreatmentForm.css";
import useTranslation from "../../customHooks/translations";

export default function AdultKneeSymtoms({ adultSymtoms, AllData }) {
  const translations = useTranslation();
  return (
    <>
      <div className="whitebox padding-none">
        <div className="treatment-content">
          <div className="treatment-row-header bd-none mb-0">
            2. {translations.patient_adult_knee_other_symptoms}
          </div>

          <div className="treatment-row">
            <div className="treatment-table ">
              <table cellspacing="0" cellpadding="0">
                <thead>
                  <tr>
                    <th></th>
                    <th>{translations.Right_Knee}</th>
                    <th>{translations.Left_Knee}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {translations.patient_adult_knee_other_symptoms_q1} :
                    </td>
                    <td> {adultSymtoms.swellingR}</td>
                    <td> {adultSymtoms.swellingL}</td>
                  </tr>

                  <tr>
                    <td>
                      {translations.patient_adult_knee_other_symptoms_q2} :
                    </td>
                    <td> {adultSymtoms.grindingR}</td>
                    <td> {adultSymtoms.grindingL}</td>
                  </tr>

                  <tr>
                    <td>
                      {translations.patient_adult_knee_other_symptoms_q3} :
                    </td>
                    <td> {adultSymtoms.catchingR}</td>
                    <td> {adultSymtoms.catchingL}</td>
                  </tr>

                  <tr>
                    <td>
                      {translations.patient_adult_knee_other_symptoms_q4} :
                    </td>
                    <td> {adultSymtoms.limpingR}</td>
                    <td> {adultSymtoms.limpingL}</td>
                  </tr>

                  {/* <tr>
                                        <td>
                                        Stiff or Swollen :
                                        </td>
                                        <td> {adultSymtoms.swollenR}</td>
                                        <td> {adultSymtoms.swollenL}</td>
                                    </tr> */}

                  <tr>
                    <td>
                      {translations.patient_adult_knee_other_symptoms_q5} :
                    </td>
                    <td>
                      {" "}
                      {adultSymtoms.swollenR === "0"
                        ? translations.patient_adult_knee_other_symptoms_q5_a1
                        : adultSymtoms.swollenR === "1"
                        ? translations.patient_adult_knee_other_symptoms_q5_a2
                        : adultSymtoms.swollenR === "2"
                        ? translations.patient_adult_knee_other_symptoms_q5_a3
                        : adultSymtoms.swollenR === "3"
                        ? translations.patient_adult_knee_other_symptoms_q5_a4
                        : adultSymtoms.swollenR === "4"
                        ? translations.patient_adult_knee_other_symptoms_q5_a5
                        : ""}
                    </td>
                    <td>
                      {" "}
                      {adultSymtoms.swollenL === "0"
                        ? translations.patient_adult_knee_other_symptoms_q5_a1
                        : adultSymtoms.swollenL === "1"
                        ? translations.patient_adult_knee_other_symptoms_q5_a2
                        : adultSymtoms.swollenL === "2"
                        ? translations.patient_adult_knee_other_symptoms_q5_a3
                        : adultSymtoms.swollenL === "3"
                        ? translations.patient_adult_knee_other_symptoms_q5_a4
                        : adultSymtoms.swollenL === "4"
                        ? translations.patient_adult_knee_other_symptoms_q5_a5
                        : ""}
                    </td>
                  </tr>

                  <tr>
                    <td>
                      {translations.patient_adult_knee_other_symptoms_q6} :
                    </td>
                    <td>
                      {adultSymtoms.significantSwellingR === "0"
                        ? translations.patient_adult_knee_other_symptoms_q6_a5
                        : adultSymtoms.significantSwellingR === "1"
                        ? translations.patient_adult_knee_other_symptoms_q6_a4
                        : adultSymtoms.significantSwellingR === "2"
                        ? translations.patient_adult_knee_other_symptoms_q6_a3
                        : adultSymtoms.significantSwellingR === "3"
                        ? translations.patient_adult_knee_other_symptoms_q6_a2
                        : adultSymtoms.significantSwellingR === "4"
                        ? translations.patient_adult_knee_other_symptoms_q6_a1
                        : ""}
                    </td>
                    <td>
                      {adultSymtoms.significantSwellingL === "0"
                        ? translations.patient_adult_knee_other_symptoms_q6_a5
                        : adultSymtoms.significantSwellingL === "1"
                        ? translations.patient_adult_knee_other_symptoms_q6_a4
                        : adultSymtoms.significantSwellingL === "2"
                        ? translations.patient_adult_knee_other_symptoms_q6_a3
                        : adultSymtoms.significantSwellingL === "3"
                        ? translations.patient_adult_knee_other_symptoms_q6_a2
                        : adultSymtoms.significantSwellingL === "4"
                        ? translations.patient_adult_knee_other_symptoms_q6_a1
                        : ""}
                    </td>
                  </tr>

                  <tr>
                    <td>
                      {translations.patient_adult_knee_other_symptoms_q7_q1} :
                    </td>
                    <td> {adultSymtoms.awakeningR}</td>
                    <td> {adultSymtoms.awakeningL}</td>
                  </tr>

                  <tr>
                    <td>
                      {translations.patient_adult_knee_other_symptoms_q7_q2} :
                    </td>
                    <td> {adultSymtoms.restingLaterR}</td>
                    <td> {adultSymtoms.restingLaterL}</td>
                  </tr>

                  <tr>
                    <td>
                      {translations.patient_adult_knee_other_symptoms_q8} :
                    </td>
                    <td>
                      {adultSymtoms.instabilityR === "0"
                        ? translations.patient_adult_knee_other_symptoms_q8_a6
                        : adultSymtoms.instabilityR === "1"
                        ? translations.patient_adult_knee_other_symptoms_q8_a5
                        : adultSymtoms.instabilityR === "2"
                        ? translations.patient_adult_knee_other_symptoms_q8_a4
                        : adultSymtoms.instabilityR === "3"
                        ? translations.patient_adult_knee_other_symptoms_q8_a3
                        : adultSymtoms.instabilityR === "4"
                        ? translations.patient_adult_knee_other_symptoms_q8_a2
                        : translations.patient_adult_knee_other_symptoms_q8_a1}
                    </td>
                    <td>
                      {adultSymtoms.instabilityL === "0"
                        ? translations.patient_adult_knee_other_symptoms_q8_a6
                        : adultSymtoms.instabilityL === "1"
                        ? translations.patient_adult_knee_other_symptoms_q8_a5
                        : adultSymtoms.instabilityL === "2"
                        ? translations.patient_adult_knee_other_symptoms_q8_a4
                        : adultSymtoms.instabilityL === "3"
                        ? translations.patient_adult_knee_other_symptoms_q8_a3
                        : adultSymtoms.instabilityL === "4"
                        ? translations.patient_adult_knee_other_symptoms_q8_a2
                        : translations.patient_adult_knee_other_symptoms_q8_a1}
                    </td>
                  </tr>

                  <tr>
                    <td>
                      {translations.patient_adult_knee_other_symptoms_q9} :
                    </td>

                    <td>
                      {adultSymtoms.supportR === "2"
                        ? translations.patient_adult_knee_other_symptoms_q9_a3
                        : adultSymtoms.supportR === "1"
                        ? translations.patient_adult_knee_other_symptoms_q9_a2
                        : translations.patient_adult_knee_other_symptoms_q9_a1}
                    </td>

                    <td>
                      {adultSymtoms.supportL === "2"
                        ? translations.patient_adult_knee_other_symptoms_q9_a3
                        : adultSymtoms.supportL === "1"
                        ? translations.patient_adult_knee_other_symptoms_q9_a2
                        : translations.patient_adult_knee_other_symptoms_q9_a1}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
