import React from "react";
import "../../../css/ViewTreatmentForm.css";
import useTranslation from "../../customHooks/translations";

export default function FootAnkleInsta({
  FootinstabilityScore,
  AllData,
  FootcumberlandScore,
}) {
  const translations = useTranslation();
  return (
    <>
      <div className="treatment-content">
        <div className="treatment-row-header">
          2. {translations.patient_foot_ankle_instability}
        </div>
        <div className="treatment-row">
          <div className="treatment-col">
            <label>
              {translations.patient_foot_ankle_instability_q1}(
              {translations.Right})
            </label>
            <p>
              {FootinstabilityScore.ankleFeelR === "0"
                ? translations.patient_foot_ankle_instability_q1_a1
                : FootinstabilityScore.ankleFeelR === "1"
                ? translations.patient_foot_ankle_instability_q1_a2
                : ""}
            </p>
          </div>
          <div className="treatment-col">
            <label>
              {translations.patient_foot_ankle_instability_q1}(
              {translations.Left})
            </label>
            <p>
              {FootinstabilityScore.ankleFeelL === "0"
                ? translations.patient_foot_ankle_instability_q1_a1
                : FootinstabilityScore.ankleFeelL === "1"
                ? translations.patient_foot_ankle_instability_q1_a2
                : ""}
            </p>
          </div>
        </div>
        <div className="treatment-row">
          <div className="treatment-col">
            <label>
              {translations.patient_foot_ankle_instability_q2} (
              {translations.Right})
            </label>
            <p>
              {FootinstabilityScore.ankleDiagnosedR === "0"
                ? translations.patient_foot_ankle_instability_q2_a1
                : FootinstabilityScore.ankleDiagnosedR === "1"
                ? translations.patient_foot_ankle_instability_q2_a2
                : ""}
            </p>
          </div>
          <div className="treatment-col">
            <label>
              {translations.patient_foot_ankle_instability_q2} (
              {translations.Left})
            </label>
            <p>
              {FootinstabilityScore.ankleDiagnosedL === "0"
                ? translations.patient_foot_ankle_instability_q2_a1
                : FootinstabilityScore.ankleDiagnosedL === "1"
                ? translations.patient_foot_ankle_instability_q2_a2
                : ""}
            </p>
          </div>
        </div>
      </div>

      {FootinstabilityScore.ankleDiagnosedR === "1" ||
      FootinstabilityScore.ankleFeelR === "1" ||
      FootinstabilityScore.ankleDiagnosedL === "1" ||
      FootinstabilityScore.ankleFeelL === "1" ? (
        <div className="treatment-content">
          <div className="treatment-row-header">
            {translations.patient_foot_ankle_cumberland}
          </div>
          <div className="treatment-row">
            <div className="treatment-table">
              <table cellpadding="0" cellspacing="0">
                <thead>
                  <tr>
                    <th></th>
                    <th>{translations.Right_Foot_and_Ankle}</th>
                    <th>{translations.Left_Foot_and_Ankle}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{translations.patient_foot_ankle_cumberland_q1} :</td>
                    <td>
                      {FootcumberlandScore.descripR === "0"
                        ? translations.patient_foot_ankle_cumberland_q1_a1
                        : FootcumberlandScore.descripR === "1"
                        ? translations.patient_foot_ankle_cumberland_q1_a2
                        : FootcumberlandScore.descripR === "2"
                        ? translations.patient_foot_ankle_cumberland_q1_a3
                        : FootcumberlandScore.descripR === "3"
                        ? translations.patient_foot_ankle_cumberland_q1_a4
                        : FootcumberlandScore.descripR === "4"
                        ? translations.patient_foot_ankle_cumberland_q1_a5
                        : translations.patient_foot_ankle_cumberland_q1_a6}
                    </td>
                    <td>
                      {FootcumberlandScore.descripL === "0"
                        ? translations.patient_foot_ankle_cumberland_q1_a1
                        : FootcumberlandScore.descripL === "1"
                        ? translations.patient_foot_ankle_cumberland_q1_a2
                        : FootcumberlandScore.descripL === "2"
                        ? translations.patient_foot_ankle_cumberland_q1_a3
                        : FootcumberlandScore.descripL === "3"
                        ? translations.patient_foot_ankle_cumberland_q1_a4
                        : FootcumberlandScore.descripL === "4"
                        ? translations.patient_foot_ankle_cumberland_q1_a5
                        : translations.patient_foot_ankle_cumberland_q1_a6}
                    </td>
                  </tr>
                  <tr>
                    <td>{translations.patient_foot_ankle_cumberland_q2} :</td>
                    <td>
                      {FootcumberlandScore.unstableR === "4"
                        ? translations.patient_foot_ankle_cumberland_q2_a5
                        : FootcumberlandScore.unstableR === "3"
                        ? translations.patient_foot_ankle_cumberland_q2_a4
                        : FootcumberlandScore.unstableR === "2"
                        ? translations.patient_foot_ankle_cumberland_q2_a3
                        : FootcumberlandScore.unstableR === "1"
                        ? translations.patient_foot_ankle_cumberland_q2_a2
                        : FootcumberlandScore.unstableR === "0"
                        ? translations.patient_foot_ankle_cumberland_q2_a1
                        : ""}
                    </td>
                    <td>
                      {FootcumberlandScore.unstableL === "4"
                        ? translations.patient_foot_ankle_cumberland_q2_a5
                        : FootcumberlandScore.unstableL === "3"
                        ? translations.patient_foot_ankle_cumberland_q2_a4
                        : FootcumberlandScore.unstableL === "2"
                        ? translations.patient_foot_ankle_cumberland_q2_a3
                        : FootcumberlandScore.unstableL === "1"
                        ? translations.patient_foot_ankle_cumberland_q2_a2
                        : FootcumberlandScore.unstableL === "0"
                        ? translations.patient_foot_ankle_cumberland_q2_a1
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <td>{translations.patient_foot_ankle_cumberland_q3} :</td>
                    <td>
                      {FootcumberlandScore.sharpR === "3"
                        ? translations.patient_foot_ankle_cumberland_q3_a4
                        : FootcumberlandScore.sharpR === "2"
                        ? translations.patient_foot_ankle_cumberland_q3_a3
                        : FootcumberlandScore.sharpR === "1"
                        ? translations.patient_foot_ankle_cumberland_q3_a2
                        : FootcumberlandScore.sharpR === "0"
                        ? translations.patient_foot_ankle_cumberland_q3_a1
                        : ""}
                    </td>
                    <td>
                      {FootcumberlandScore.sharpL === "3"
                        ? translations.patient_foot_ankle_cumberland_q3_a4
                        : FootcumberlandScore.sharpL === "2"
                        ? translations.patient_foot_ankle_cumberland_q3_a3
                        : FootcumberlandScore.sharpL === "1"
                        ? translations.patient_foot_ankle_cumberland_q3_a2
                        : FootcumberlandScore.sharpL === "0"
                        ? translations.patient_foot_ankle_cumberland_q3_a1
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <td>{translations.patient_foot_ankle_cumberland_q4} :</td>
                    <td>
                      {FootcumberlandScore.stairsR === "3"
                        ? translations.patient_foot_ankle_cumberland_q4_a4
                        : FootcumberlandScore.stairsR === "2"
                        ? translations.patient_foot_ankle_cumberland_q4_a3
                        : FootcumberlandScore.stairsR === "1"
                        ? translations.patient_foot_ankle_cumberland_q4_a2
                        : FootcumberlandScore.stairsR === "0"
                        ? translations.patient_foot_ankle_cumberland_q4_a1
                        : ""}
                    </td>
                    <td>
                      {FootcumberlandScore.stairsL === "3"
                        ? translations.patient_foot_ankle_cumberland_q4_a4
                        : FootcumberlandScore.stairsL === "2"
                        ? translations.patient_foot_ankle_cumberland_q4_a3
                        : FootcumberlandScore.stairsL === "1"
                        ? translations.patient_foot_ankle_cumberland_q4_a2
                        : FootcumberlandScore.stairsL === "0"
                        ? translations.patient_foot_ankle_cumberland_q4_a1
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <td>{translations.patient_foot_ankle_cumberland_q5} :</td>
                    <td>
                      {FootcumberlandScore.standR === "2"
                        ? translations.patient_foot_ankle_cumberland_q5_a3
                        : FootcumberlandScore.standR === "1"
                        ? translations.patient_foot_ankle_cumberland_q5_a2
                        : translations.patient_foot_ankle_cumberland_q5_a1}
                    </td>
                    <td>
                      {FootcumberlandScore.standL === "2"
                        ? translations.patient_foot_ankle_cumberland_q5_a3
                        : FootcumberlandScore.standL === "1"
                        ? translations.patient_foot_ankle_cumberland_q5_a2
                        : translations.patient_foot_ankle_cumberland_q5_a1}
                    </td>
                  </tr>
                  <tr>
                    <td>{translations.patient_foot_ankle_cumberland_q6} :</td>
                    <td>
                      {FootcumberlandScore.feelsR === "3"
                        ? translations.patient_foot_ankle_cumberland_q6_a4
                        : FootcumberlandScore.feelsR === "2"
                        ? translations.patient_foot_ankle_cumberland_q6_a3
                        : FootcumberlandScore.feelsR === "1"
                        ? translations.patient_foot_ankle_cumberland_q6_a2
                        : translations.patient_foot_ankle_cumberland_q6_a1}
                    </td>
                    <td>
                      {FootcumberlandScore.feelsL === "3"
                        ? translations.patient_foot_ankle_cumberland_q6_a4
                        : FootcumberlandScore.feelsL === "2"
                        ? translations.patient_foot_ankle_cumberland_q6_a3
                        : FootcumberlandScore.feelsL === "1"
                        ? translations.patient_foot_ankle_cumberland_q6_a2
                        : translations.patient_foot_ankle_cumberland_q6_a1}
                    </td>
                  </tr>
                  <tr>
                    <td>{translations.patient_foot_ankle_cumberland_q7} :</td>
                    <td>
                      {FootcumberlandScore.feelsankR === "4"
                        ? translations.patient_foot_ankle_cumberland_q7_a5
                        : FootcumberlandScore.feelsankR === "3"
                        ? translations.patient_foot_ankle_cumberland_q7_a4
                        : FootcumberlandScore.feelsankR === "2"
                        ? translations.patient_foot_ankle_cumberland_q7_a3
                        : FootcumberlandScore.feelsankR === "1"
                        ? translations.patient_foot_ankle_cumberland_q7_a2
                        : translations.patient_foot_ankle_cumberland_q7_a1}
                    </td>
                    <td>
                      {FootcumberlandScore.feelsankL === "4"
                        ? translations.patient_foot_ankle_cumberland_q7_a5
                        : FootcumberlandScore.feelsankL === "3"
                        ? translations.patient_foot_ankle_cumberland_q7_a4
                        : FootcumberlandScore.feelsankL === "2"
                        ? translations.patient_foot_ankle_cumberland_q7_a3
                        : FootcumberlandScore.feelsankL === "1"
                        ? translations.patient_foot_ankle_cumberland_q7_a2
                        : translations.patient_foot_ankle_cumberland_q7_a1}
                    </td>
                  </tr>
                  <tr>
                    <td>{translations.patient_foot_ankle_cumberland_q8} :</td>
                    <td>
                      {FootcumberlandScore.twistR === "4"
                        ? translations.patient_foot_ankle_cumberland_q8_a5
                        : FootcumberlandScore.twistR === "3"
                        ? translations.patient_foot_ankle_cumberland_q8_a4
                        : FootcumberlandScore.twistR === "2"
                        ? translations.patient_foot_ankle_cumberland_q8_a3
                        : FootcumberlandScore.twistR === "1"
                        ? translations.patient_foot_ankle_cumberland_q8_a2
                        : translations.patient_foot_ankle_cumberland_q8_a1}
                    </td>
                    <td>
                      {FootcumberlandScore.twistL === "4"
                        ? translations.patient_foot_ankle_cumberland_q8_a5
                        : FootcumberlandScore.twistL === "3"
                        ? translations.patient_foot_ankle_cumberland_q8_a4
                        : FootcumberlandScore.twistL === "2"
                        ? translations.patient_foot_ankle_cumberland_q8_a3
                        : FootcumberlandScore.twistL === "1"
                        ? translations.patient_foot_ankle_cumberland_q8_a2
                        : translations.patient_foot_ankle_cumberland_q8_a1}
                    </td>
                  </tr>
                  <tr>
                    <td>{translations.patient_foot_ankle_cumberland_q9} :</td>
                    <td>
                      {FootcumberlandScore.roleR === "4"
                        ? translations.patient_foot_ankle_cumberland_q9_a5
                        : FootcumberlandScore.roleR === "3"
                        ? translations.patient_foot_ankle_cumberland_q9_a4
                        : FootcumberlandScore.roleR === "2"
                        ? translations.patient_foot_ankle_cumberland_q9_a3
                        : FootcumberlandScore.roleR === "1"
                        ? translations.patient_foot_ankle_cumberland_q9_a2
                        : translations.patient_foot_ankle_cumberland_q9_a1}
                    </td>
                    <td>
                      {FootcumberlandScore.roleL === "4"
                        ? translations.patient_foot_ankle_cumberland_q9_a5
                        : FootcumberlandScore.roleL === "3"
                        ? translations.patient_foot_ankle_cumberland_q9_a4
                        : FootcumberlandScore.roleL === "2"
                        ? translations.patient_foot_ankle_cumberland_q9_a3
                        : FootcumberlandScore.roleL === "1"
                        ? translations.patient_foot_ankle_cumberland_q9_a2
                        : translations.patient_foot_ankle_cumberland_q9_a1}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
