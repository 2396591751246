import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import vertMenuStyles from "./FormVerticalMenu.module.css";

function FormVerticalMenu({ setGetId }) {
  return (
    <>
      <Navbar
        bg="light"
        className={`scrollbar_vert ${vertMenuStyles.vm_sidebar}`}
      >
        <Nav
          className={`me-auto my-2 my-lg-0 ${vertMenuStyles.vm_sidebar_inner}`}
          navbarScroll
        >
          <Nav.Link
            className={`${vertMenuStyles.vm_item}`}
            onClick={() => setGetId("inspection")}
            // href="#inspection"
          >
            INSPECTION
          </Nav.Link>
          <Nav.Link
            className={`${vertMenuStyles.vm_item}`}
            onClick={() => setGetId("tenderness")}
            // href="#tenderness"
          >
            TENDERNESS
          </Nav.Link>
          <Nav.Link
            className={`${vertMenuStyles.vm_item}`}
            onClick={() => setGetId("crepitus")}
            // href="#crepitus"
          >
            CREPITUS
          </Nav.Link>
          <Nav.Link
            className={`${vertMenuStyles.vm_item}`}
            onClick={() => setGetId("rom")}
            // href="#rom"
          >
            RANGE OF MOTION
          </Nav.Link>
        </Nav>
      </Navbar>
    </>
  );
}

export default FormVerticalMenu;
