import React, { useState, useEffect } from "react";
import Button from "../../custom/button/Button";
import "../../../css/ViewTreatmentForm.css";
import PersonalInformation from "./PersonalInformation";
import { useParams, useNavigate } from "react-router-dom";
import HipPain from "./HipPain";
import HipSymptoms from "./HipSymptoms";
import HipFunction from "./HipFunction";
import HipQOL from "./HipQOL";
import HipSatisfaction from "./HipSatisfaction";
import useAxios from "../../../axiosinstance";
import HipDoctorFeedback from "./HipDoctorFeedback";
import DeleteConfirmation from "../../custom/delete-confirmation/DeleteConfirmation";
import LoadingSpinner from "../../custom/loader/LoadingSpinner";
import useTranslation from "../../customHooks/translations";
export default function HipTreatment() {
  const translation= useTranslation();
  const { rId } = useParams();
  const queryParams = new URLSearchParams(window.location.search);
  // const dId = queryParams.get("doctor");
  const pId = queryParams.get("patient");
  const management = queryParams.get("management");
  const pScore = queryParams.get("form");
  const action = queryParams.get("action");
  const patientEdit = queryParams.get("patientEdit");

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [initialAssesment, setInitialAssesment] = useState(0);
  let isdoctorForm = queryParams.get("is_doctor_form");
  let orgid=sessionStorage.getItem("orgid")
  useEffect(()=>{
    localStorage.setItem("isdoctorForm",isdoctorForm)
  },[isdoctorForm])
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
  const [AllData, setAllData] = useState("");
  const [profileData, setProfileData] = useState({});
  const [hipPain, setHipPain] = useState({});
  const [symptomsData, setHipSymptoms] = useState({});
  const [functionData, setHipFunction] = useState({});
  const [qOLData, setHipQOL] = useState({});
  const [satisfactionData, setHipSatisfaction] = useState({});
  const [doctorefeedback, setHipDoctorFeedback] = useState({});
  const axiosInstance = useAxios();
  const [doctorView, setdoctorView] = useState(false);

  const [editShow, seteditShow] = useState(false);
  const [viewDoctor, setViewDoctor] = useState("0");
  const [viewPatient, setViewPatient] = useState("0");
  const [displayConfirmationModalDelete, setDisplayConfirmationModalDelete] =
    useState(false);
    const [editShow1, seteditShow1] = useState(false);
    const doctor_id = queryParams.get("doctor")
  const save = () => {
    axiosInstance.current
      .get(
        `extapp/forms/all_forms?resource_id=${rId}&type=6&akpt=${window.atob(
          pId
        )}`
      )
      .then((res) => {
        setInitialAssesment(res.data.data2[0].initial_assessment);
        setAllData(res.data.data2[0]);
        setProfileData(res.data.data1);
        setHipPain(res.data.data2[0].painScore[0]);
        setHipSymptoms(res.data.data2[0].symptomsScore[0]);
        setHipFunction(res.data.data2[0].functionScore[0]);
        setHipQOL(res.data.data2[0].qolScore[0]);
        setHipSatisfaction(res.data.data2[0].satisfactionScore[0]);
        setHipDoctorFeedback(res.data.data2[0].doctorFeedback[0]);
        seteditShow(res.data.status);
        setdoctorView(res.data.doctorStatus);
        seteditShow1(res.data.doctorStatus);
        setViewDoctor(res.data.data2[0].doctor_scoring_date);
        setViewPatient(res.data.data2[0].patient_scoring_date);
        setIsLoading(false);
      }).catch((err) => {

        navigate('/un-authorized-access');

      });
  };
  const handleEdit = () => {
    navigate(
      `/personal-data-edit/${
        pId
      }/${window.btoa(doctor_id)}/${window.btoa(orgid)}/${window.btoa(
        6
      )}?is_doctor_form=true&doctorvalue=${0}&doctor=true&resource_id=${rId}`
    );
    // navigate("/hip-pain/" + rId + "?d=true&akpt=" + pId+"&is_doctor_form=true");
  };
  const handlePatientEdit = () => {
   
    navigate("/hip-pain/" + rId + "?is_doctor_form=null&edit=true");
  };
  useEffect(() => {
    save();
  }, []);
  const hideConfirmationModal = () => {
    setDisplayConfirmationModalDelete(false);
  };
  const submitDelete = (type, e) => {
    handleDelByrID();
  };
  const handleDelByrID = async () => {
    try {
      const result = await axiosInstance.current.patch(
        `extapp/forms/soft_delete_form?resource_id=${rId}`
      );
      if (result.status === 200) {
        window.close();
      }
    } catch (err) {
      console.log(err.message);
    } finally {
      console.log("api response finally");
    }
  };
  const handleDelete = (e, te) => {
    setDisplayConfirmationModalDelete(true);
  };
  const profileAllData = React.useMemo(() => profileData, [profileData]);

  const handleBack = () => {
    window.open(process.env.REACT_APP_DOCTOR_URL + 'patient-view' + "/" + pId);
    window.close();
  };

  const handleEdit1 = () => {
    // localStorage.setItem("doctor",doctor_id)
    // navigate("/elbow-pain/" + rId + "?d=true&akpt=" + pId+"&is_doctor_form=true");

    window.open(
     
      "/hip-feedback/" +
      rId +
      `?doctor=${doctor_id}&patient=${pId}&is_doctor_form=true`
    );
  };
  let ak_dc=  sessionStorage.getItem("ak_dc")
  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="view_treatment_form">
          <div className="row page_header">
            <div className="col-sm-6 col-md-6">
              <h4>{translation.Hip_Treatment_Form}</h4>
            </div>
            {/* <div className={`col-sm-6 col-md-6 text-end`}> */}
            {management === "true" && (
              <>
              {ak_dc===doctor_id &&(
          <div className={`col-sm-6 col-md-6 text-end`}>
            <Button
              onClick={() => handleDelete()}
              value="Delete Form"
              buttonStyle="btn_fill"
            />
            {editShow || doctorView ? (
              <Button
                onClick={() => handleEdit()}
                value="Edit Form"
                buttonStyle="btn_fill ml_15 "
              />
            ) : (
              <Button
                onClick={() => console.log("")}
                value="Edit Form"
                buttonStyle=" ml_15 button_disabled "
              />
            )}
          </div>
              )}
          </>
        )}
        {isdoctorForm && management !== "true"&& (
              <div className={`col-sm-6 col-md-6 text-end`}>
                <Button
                  onClick={() => handleBack()}
                  value="Back"
                  buttonStyle="btn_fill ml_15 "
                />

                {editShow === true &&ak_dc===doctor_id && (
                  <Button
                    onClick={() => handleEdit1()}
                    value="Edit Form"
                    buttonStyle="btn_fill ml_15 "
                  />
                )}
              </div>
            )}

        {patientEdit === "true" && (
          <div className={`col-sm-6 col-md-6 text-end`}>
            {editShow && (
              <Button
                onClick={() => handlePatientEdit()}
                value="Edit Form"
                buttonStyle="btn_fill ml_15 "
              />
            )}
          </div>
        )}
            {/* </div> */}
            <DeleteConfirmation
              showModal={displayConfirmationModalDelete}
              confirmModal={submitDelete}
              hideModal={hideConfirmationModal}
              message={"Form"}
              type="task_id"
              id={rId}
            />
          </div>
          {pScore === "true" && action === "1" && (
            <>
              <PersonalInformation
                profileAllData={profileAllData}
                AllData={AllData}
              />
              {viewPatient === "0" ? (
                <div className="no_data">Patient form is not filled yet </div>
              ) : (
                <>
                  {" "}
                  <HipPain hipPain={hipPain} AllData={AllData} 
                    profileAllData={profileAllData}

                  />
                  <HipSymptoms symptomsData={symptomsData} AllData={AllData} />
                  <HipFunction functionData={functionData} AllData={AllData} />
                  <HipQOL qOLData={qOLData} AllData={AllData} />
                  
                    <HipSatisfaction
                      satisfactionData={satisfactionData}
                      AllData={AllData}
                    />
                
                </>
              )}
            </>
          )}
          {pScore === "true" && action === "2" && (
            <HipDoctorFeedback
              doctorefeedback={doctorefeedback}
              AllData={AllData}
              profileAllData={profileAllData}
            />
          )}
          {management === "true" && (
            <>
              <PersonalInformation
                profileAllData={profileAllData}
                AllData={AllData}
              />
                   {viewPatient === "0" ? (
                <div className="no_data">Patient form is not filled yet </div>
              ) : (
                <>
                  {" "}
                  <HipPain hipPain={hipPain} AllData={AllData} 
                    profileAllData={profileAllData}
                  />
                  <HipSymptoms symptomsData={symptomsData} AllData={AllData} />
                  <HipFunction functionData={functionData} AllData={AllData} />
                  <HipQOL qOLData={qOLData} AllData={AllData} />
                 
                    <HipSatisfaction
                      satisfactionData={satisfactionData}
                      AllData={AllData}
                    />
                
                </>
              )}
            </>
          )}
          {viewDoctor !== "0" && management === "true" && (
            <>
              <HipDoctorFeedback
                doctorefeedback={doctorefeedback}
                AllData={AllData}
                profileAllData={profileAllData}
              />
            </>
          )}
        </div>
      )}
    </>
  );
}
