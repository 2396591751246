import React, { useEffect, useState } from "react";
import AssessmentHeader from "../../../custom/assessment-header/AssessmentHeader";
import HeaderInnerBreadcrumb from "../../../custom/heade-inner-breadcrumb/HeaderInnerBreadcrumb";
import RadioButton from "../../../custom/radio-button/RadioButton";
import RadioSwitch from "../../../custom/radio-switch/RadioSwitch";
import FullDropdown from "../../../custom/dropdown/FullDropdown";
import InputField from "../../../custom/inputfield/InputField";
import AssessmentDegreeWidget from "../../../custom/assessment-widgets/AssessmentDegreeWidget";
import backIcon from "../../../../images/back.png";
import nextWhiteIcon from "../../../../images/next-white.png";
import nextIcon from "../../../../images/next.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import FormVerticalMenu from "./form-vertical-menu/FormVerticalMenu";
import KneeScoreRangeSlider from "../../../custom/knee-score-range-slider/KneeScoreRangeSlider";
import { useDispatch, useSelector } from "react-redux";
// import { updateAdultKneeFeedback } from "../../../../redux/actions/kneeFeedback";
import SubmitModel from "../../SubmissionModel/SubmitModel"
import {
  updateAdultKneeFeedback,
  updateAdultKneeFieldFeedback,
} from "../../../../redux/actions/index";
import useAxios from "../../../../axiosinstance";
import useTransition from "../../../customHooks/doctorTranslation";
import AdultkneeTab from "../../tab-section/AdultkneeTab";
import { useSearchParams } from "react-router-dom";
import CustomToaster from "../../../custom/toaster/CustomToaster";
import KneeDegree from "../../../custom/assessment-widgets/KneeDegree";
import KneeDegree100 from "../../../custom/assessment-widgets/KneeDegree100";
import DoctorNameCustom from "../../../custom/name/DoctorNameCustom";
import Promsschudle from "../../SubmissionModel/Promsschudle";
import LoadingSpinner from "../../../custom/loader/LoadingSpinner";

export default function KneeFeedbackstep2() {
  const translation = useTransition();
  const dispatch = useDispatch();
  const { rId } = useParams();
  const [searchParams] = useSearchParams();
  let doctorEnable = searchParams.get("d");
  const queryParams = new URLSearchParams(window.location.search);
  const dId = queryParams.get("doctor");
  const pId = queryParams.get("patient");
  const axiosInstance = useAxios();
  const [anteriorR, setAnteriorR] = useState("");
  const [anteriorL, setAnteriorL] = useState("");
  const [anterdropR, setAnterdropR] = useState("");
  const [anterdropL, setAnterdropL] = useState("");
  const [lachmanR, setLachmanR] = useState("");
  const [lachmanL, setLachmanL] = useState("");
  const [lachmandropR, setLachmandropR] = useState("");
  const [lachmandropL, setLachmandropL] = useState("");
  const [pivotR, setPivotR] = useState("");
  const [pivotL, setPivotL] = useState("");
  const [pivotdropR, setPivotdropR] = useState("");
  const [pivotdropL, setPivotdropL] = useState("");
  const [sagR, setSagR] = useState("");
  const [sagL, setSagL] = useState("");
  const [drawerR, setDrawerR] = useState("");
  const [drawerL, setDrawerL] = useState("");
  const [drawdropR, setDrawdropR] = useState("");
  const [drawdropL, setDrawdropL] = useState("");
  const [activeR, setActiveR] = useState("");
  const [activeL, setActiveL] = useState("");
  const [reverseR, setReverseR] = useState("");
  const [reverseL, setReverseL] = useState("");
  const [recurvatumR, setRecurvatumR] = useState("");
  const [recurvatumL, setRecurvatumL] = useState("");
  const [postR, setPostR] = useState("");
  const [postL, setPostL] = useState("");
  const [rotatR, setRotatR] = useState("");
  const [rotatL, setRotatL] = useState("");
  const [drawersR, setDrawersR] = useState("");
  const [drawersL, setDrawersL] = useState("");
  const [stressR, setStressR] = useState("");
  const [stressL, setStressL] = useState("");
  const [stressdropR, setStressdropR] = useState("");
  const [stressdropL, setStressdropL] = useState("");
  const [stressflR, setStressflR] = useState("");
  const [stressflL, setStressflL] = useState("");
  const [stressdropflR, setStressdropflR] = useState("");
  const [stressdropflL, setStressdropflL] = useState("");
  const [valgusR, setValgusR] = useState("");
  const [valgusL, setValgusL] = useState("");
  const [valgusdropR, setValgusdropR] = useState("");
  const [valgusdropL, setValgusdropL] = useState("");
  const [valgusflR, setValgusflR] = useState("");
  const [valgusflL, setValgusflL] = useState("");
  const [valgusdropflR, setValgusdropflR] = useState("");
  const [valgusdropflL, setValgusdropflL] = useState("");
  const [murraryR, setMurraryR] = useState("");
  const [murraryL, setMurraryL] = useState("");
  const [egeR, setEgeR] = useState("");
  const [egeL, setEgeL] = useState("");
  const [jointR, setJointR] = useState("");
  const [tendernessR, settendernessR] = useState("");
  const [tendernessL, settendernessL] = useState("");
  const [jointL, setJointL] = useState("");
  const [apleyR, setApleyR] = useState("");
  const [apleyL, setApleyL] = useState("");
  const [apprR, setApprR] = useState("");
  const [apprL, setApprL] = useState("");
  const [patellarR, setPatellarR] = useState("1");
  const [patellarL, setPatellarL] = useState("");
  const [patellaR, setPatellaR] = useState("");
  const [patellaL, setPatellaL] = useState("");
  const [signR, setSignR] = useState("");
  const [signL, setSignL] = useState("");
  const [legR, setLegR] = useState("");
  const [legL, setLegL] = useState("");
  const [strengthR, setStrengthR] = useState("");
  const [strengthL, setStrengthL] = useState("");
  const [KTR, setKTR] = useState("");
  const [KTL, setKTL] = useState("");
  const [dialR, setDialR] = useState("");
  const [dialL, setDialL] = useState("");
  const [text1, settext1] = useState("");
  const [text2, settext2] = useState("");
  const [text3, settext3] = useState("");
  const [textL1, settextL1] = useState("");
  const [textL2, settextL2] = useState("");
  const [textL3, settextL3] = useState("");
  const [text30R, settext30R] = useState("");
  const [text90R, settext90R] = useState("");
  const [text30L, settext30L] = useState("");
  const [text90L, settext90L] = useState("");
  const [murraryRdrop, setmurraryRdrop] = useState("1");
  const [murraryLdrop, setmurraryLdrop] = useState("1");
  const [egeRdrop, setegeRdrop] = useState("1");
  const [egeLdrop, setegeLdrop] = useState("1");
  const [apleyRdrop, setapleyRdrop] = useState("1");
  const [apleyLdrop, setapleyLdrop] = useState("1");
  const [apprRdrop, setapprRdrop] = useState("1");
  const [apprLdrop, setapprLdrop] = useState("1");
  const [ThessalyR, setThessalyR] = useState("");
  const [ThessalyL, setThessalyL] = useState("");
  const [ThessalyRdrop, setThessalyRdrop] = useState("1");
  const [ThessalyLdrop, setThessalyLdrop] = useState("1");
  const [others, setOthersText] = useState("");
  const [chk, setChk] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const json = useSelector((state) => state.adultKneeScore.doctorFeedback);
  const docFeedback = useSelector(
    (state) => state.adultKneeScore.doctorFeedback[0]
  );

  let agedata = sessionStorage.getItem("age")
  let akptdata = sessionStorage.getItem("patient_akid")
  const save = () => {
    if (agedata !== null && akptdata !== null){
      
      let type;
    if (agedata >= 18) {
      type = 5;
    } else {
      type = 20;
    }
    axiosInstance.current
      .get(`extapp/forms/all_forms?resource_id=${rId}&type=${type}`)
      .then((res) => {
        dispatch(updateAdultKneeFeedback(res.data.data2[0].doctorFeedback));

        setChk(true);
      });
    }
  };

  useEffect(() => {
    save();
  }, []);
  useEffect(() => {
    setAnteriorR(docFeedback.anteriorR);
    setAnteriorL(docFeedback.anteriorL);
    setAnterdropR(docFeedback.anterdropR);
    setAnterdropL(docFeedback.anterdropL);
    setLachmanR(docFeedback.lachmanR);
    setLachmanL(docFeedback.lachmanL);
    setLachmandropR(docFeedback.lachmandropR);
    setLachmandropL(docFeedback.lachmandropL);
    setPivotR(docFeedback.pivotR);
    setPivotL(docFeedback.pivotL);
    setPivotdropR(docFeedback.pivotdropR);
    setPivotdropL(docFeedback.pivotdropL);
    setSagR(docFeedback.sagR);
    setSagL(docFeedback.sagL);
    setDrawerR(docFeedback.drawerR);
    setDrawerL(docFeedback.drawerL);
    setDrawdropR(docFeedback.drawdropR);
    setDrawdropL(docFeedback.drawdropL);
    setActiveR(docFeedback.activeR);
    setActiveL(docFeedback.activeL);
    setReverseR(docFeedback.reverseR);
    setReverseL(docFeedback.reverseL);
    setRecurvatumR(docFeedback.recurvatumsecondR);
    setRecurvatumL(docFeedback.recurvatumsecondL);
    setPostR(docFeedback.postR);
    setPostL(docFeedback.postL);
    setRotatR(docFeedback.rotatR);
    setRotatL(docFeedback.rotatL);
    setDrawersR(docFeedback.drawersR);
    setDrawersL(docFeedback.drawersL);
    setStressR(docFeedback.stressR);
    setStressL(docFeedback.stressL);
    setStressdropR(docFeedback.stressdropR);
    setStressdropL(docFeedback.stressdropL);
    setStressflR(docFeedback.stressflR);
    setStressflL(docFeedback.stressflL);
    setStressdropflR(docFeedback.stressdropflR);
    setStressdropflL(docFeedback.stressdropflL);
    setValgusR(docFeedback.valgusR);
    setValgusL(docFeedback.valgusL);
    setValgusdropR(docFeedback.valgusdropR);
    setValgusdropL(docFeedback.valgusdropL);
    setValgusflR(docFeedback.valgusflR);
    setValgusflL(docFeedback.valgusflL);
    setValgusdropflR(docFeedback.valgusdropflR);
    setValgusdropflL(docFeedback.valgusdropflL);
    setMurraryR(docFeedback.murraryR);
    setMurraryL(docFeedback.murraryL);
    setEgeR(docFeedback.egeR);
    setEgeL(docFeedback.egeL);
    setJointR(docFeedback.jointR);
    settendernessR(docFeedback.tendernessR);
    settendernessL(docFeedback.tendernessL);
    setJointL(docFeedback.jointL);
    setApleyR(docFeedback.apleyR);
    setApleyL(docFeedback.apleyL);
    setApprR(docFeedback.apprR);
    setApprL(docFeedback.apprL);
    setPatellarR(docFeedback.patellarR);
    setPatellarL(docFeedback.patellarL);
    setPatellaR(docFeedback.patellaR);
    setPatellaL(docFeedback.patellaL);
    setSignR(docFeedback.signR);
    setSignL(docFeedback.signL);
    setLegR(docFeedback.legR);
    setLegL(docFeedback.legL);
    setStrengthR(docFeedback.strengthR);
    setStrengthL(docFeedback.strengthL);
    setKTR(docFeedback.KTR);
    setKTL(docFeedback.KTL);
    setDialR(docFeedback.dialR);
    setDialL(docFeedback.dialL);
    settext1(docFeedback.text1);
    settext2(docFeedback.text2);
    settext3(docFeedback.text3);
    settextL1(docFeedback.textL1);
    settextL2(docFeedback.textL2);
    settextL3(docFeedback.textL3);
    settext30R(docFeedback.text30R);
    settext90R(docFeedback.text90R);
    settext30L(docFeedback.text30L);
    settext90L(docFeedback.text90L);
    setmurraryRdrop(docFeedback.murraryRdrop);
    setmurraryLdrop(docFeedback.murraryLdrop);
    setegeRdrop(docFeedback.egeRdrop);
    setegeLdrop(docFeedback.egeLdrop);
    setapleyRdrop(docFeedback.apleyRdrop);
    setapleyLdrop(docFeedback.apleyLdrop);
    setapprRdrop(docFeedback.apprRdrop);
    setapprLdrop(docFeedback.apprLdrop);
    setThessalyR(docFeedback.ThessalyR);
    setThessalyL(docFeedback.ThessalyL);
    setThessalyRdrop(docFeedback.ThessalyRdrop);
    setThessalyLdrop(docFeedback.ThessalyLdrop);
    setOthersText(docFeedback.others);
  }, [docFeedback]);

  const handleAnteriorR = (e) => {
    setAnteriorR(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "anteriorR",
        value: e,
      })
    );
  };
  const handleAnteriorL = (e) => {
    setAnteriorL(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "anteriorL",
        value: e,
      })
    );
  };
  const handleAnterdropR = (e) => {
    setAnterdropR(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "anterdropR",
        value: e,
      })
    );
  };
  const handleAnterdropL = (e) => {
    setAnterdropL(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "anterdropL",
        value: e,
      })
    );
  };
  const handleLachmanR = (e) => {
    setLachmanR(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "lachmanR",
        value: e,
      })
    );
  };
  const handleLachmanL = (e) => {
    setLachmanL(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "lachmanL",
        value: e,
      })
    );
  };
  const handleLachmandropR = (e) => {
    setLachmandropR(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "lachmandropR",
        value: e,
      })
    );
  };
  const handleLachmandropL = (e) => {
    setLachmandropL(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "lachmandropL",
        value: e,
      })
    );
  };
  const handlePivotR = (e) => {
    setPivotR(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "pivotR",
        value: e,
      })
    );
  };
  const handlePivotL = (e) => {
    setPivotL(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "pivotL",
        value: e,
      })
    );
  };
  const handlePivotdropR = (e) => {
    setPivotdropR(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "pivotdropR",
        value: e,
      })
    );
  };
  const handlePivotdropL = (e) => {
    setPivotdropL(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "pivotdropL",
        value: e,
      })
    );
  };
  const handleSagR = (e) => {
    setSagR(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "sagR",
        value: e,
      })
    );
  };
  const handleSagL = (e) => {
    setSagL(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "sagL",
        value: e,
      })
    );
  };
  const handleDrawerR = (e) => {
    setDrawerR(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "drawerR",
        value: e,
      })
    );
  };
  const handleDrawerL = (e) => {
    setDrawerL(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "drawerL",
        value: e,
      })
    );
  };
  const handleDrawdropR = (e) => {
    setDrawdropR(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "drawdropR",
        value: e,
      })
    );
  };
  const handleDrawdropL = (e) => {
    setDrawdropL(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "drawdropL",
        value: e,
      })
    );
  };
  const handleActiveR = (e) => {
    setActiveR(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "activeR",
        value: e,
      })
    );
  };
  const handleActiveL = (e) => {
    setActiveL(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "activeL",
        value: e,
      })
    );
  };
  const handleReverseR = (e) => {
    setReverseR(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "reverseR",
        value: e,
      })
    );
  };
  const handleReverseL = (e) => {
    setReverseL(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "reverseL",
        value: e,
      })
    );
  };
  const handleRecurvatumR = (e) => {
    setRecurvatumR(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "recurvatumsecondR",
        value: e,
      })
    );
  };
  const handleRecurvatumL = (e) => {
    setRecurvatumL(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "recurvatumsecondL",
        value: e,
      })
    );
  };
  const handlePostR = (e) => {
    setPostR(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "postR",
        value: e,
      })
    );
  };
  const handlePostL = (e) => {
    setPostL(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "postL",
        value: e,
      })
    );
  };
  const handleRotatR = (e) => {
    setRotatR(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "rotatR",
        value: e,
      })
    );
  };
  const handleRotatL = (e) => {
    setRotatL(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "rotatL",
        value: e,
      })
    );
  };
  const handleDrawsR = (e) => {
    setDrawersR(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "drawersR",
        value: e,
      })
    );
  };
  const handleDrawsL = (e) => {
    setDrawersL(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "drawersL",
        value: e,
      })
    );
  };
  const handleStressR = (e) => {
    setStressR(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "stressR",
        value: e,
      })
    );
  };
  const handleStressL = (e) => {
    setStressL(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "stressL",
        value: e,
      })
    );
  };
  const handleStressdropR = (e) => {
    setStressdropR(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "stressdropR",
        value: e,
      })
    );
  };
  const handleStressdropL = (e) => {
    setStressdropL(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "stressdropL",
        value: e,
      })
    );
  };
  const handleStressflR = (e) => {
    setStressflR(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "stressflR",
        value: e,
      })
    );
  };
  const handleStressflL = (e) => {
    setStressflL(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "stressflL",
        value: e,
      })
    );
  };
  const handleStressdropflR = (e) => {
    setStressdropflR(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "stressdropflR",
        value: e,
      })
    );
  };
  const handleStressdropflL = (e) => {
    setStressdropflL(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "stressdropflL",
        value: e,
      })
    );
  };
  const handleValgusR = (e) => {
    setValgusR(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "valgusR",
        value: e,
      })
    );
  };
  const handleValgusL = (e) => {
    setValgusL(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "valgusL",
        value: e,
      })
    );
  };
  const handleValgusdropR = (e) => {
    setValgusdropR(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "valgusdropR",
        value: e,
      })
    );
  };
  const handleValgusdropL = (e) => {
    setValgusdropL(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "valgusdropL",
        value: e,
      })
    );
  };

  const handleValgusflR = (e) => {
    setValgusflR(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "valgusflR",
        value: e,
      })
    );
  };
  const handleValgusflL = (e) => {
    setValgusflL(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "valgusflL",
        value: e,
      })
    );
  };
  const handleValgusdropflR = (e) => {
    setValgusdropflR(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "valgusdropflR",
        value: e,
      })
    );
  };
  const handleValgusdropflL = (e) => {
    setValgusdropflL(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "valgusdropflL",
        value: e,
      })
    );
  };
  const handleMurraryR = (e) => {
    setMurraryR(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "murraryR",
        value: e,
      })
    );
  };
  const handleMurraryL = (e) => {
    setMurraryL(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "murraryL",
        value: e,
      })
    );
  };
  const handleEgeR = (e) => {
    setEgeR(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "egeR",
        value: e,
      })
    );
  };
  const handleEgeL = (e) => {
    setEgeL(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "egeL",
        value: e,
      })
    );
  };
  const handleJointR = (e) => {
    setJointR(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "jointR",
        value: e,
      })
    );
  };
  const handletendernessR = (e) => {
    settendernessR(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "tendernessR",
        value: e,
      })
    );
  };
  const handletendernessL = (e) => {
    settendernessL(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "tendernessL",
        value: e,
      })
    );
  };
  const handleJointL = (e) => {
    setJointL(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "jointL",
        value: e,
      })
    );
  };
  const handlemurraryRdrop = (e) => {
    setmurraryRdrop(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "murraryRdrop",
        value: e,
      })
    );
  };
  const handlemurraryLdrop = (e) => {
    setmurraryLdrop(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "murraryLdrop",
        value: e,
      })
    );
  };
  const handleegeRdrop = (e) => {
    setegeRdrop(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "egeRdrop",
        value: e,
      })
    );
  };
  const handleegeLdrop = (e) => {
    setegeLdrop(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "egeLdrop",
        value: e,
      })
    );
  };
  const handleapleyRdrop = (e) => {
    setapleyRdrop(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "apleyRdrop",
        value: e,
      })
    );
  };
  const handleapprRdrop = (e) => {
    setapprRdrop(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "apprRdrop",
        value: e,
      })
    );
  };
  const handleapprLdrop = (e) => {
    setapprLdrop(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "apprLdrop",
        value: e,
      })
    );
  };
  const handleapleyLdrop = (e) => {
    setapleyLdrop(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "apleyLdrop",
        value: e,
      })
    );
  };
  const handleApleyR = (e) => {
    setApleyR(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "apleyR",
        value: e,
      })
    );
  };
  const handleApleyL = (e) => {
    setApleyL(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "apleyL",
        value: e,
      })
    );
  };
  const handleApprR = (e) => {
    setApprR(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "apprR",
        value: e,
      })
    );
  };
  const handleApprL = (e) => {
    setApprL(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "apprL",
        value: e,
      })
    );
  };
  const handlePatellarR = (e) => {
    setPatellarR(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "patellarR",
        value: e,
      })
    );
  };
  const handlePatellarL = (e) => {
    setPatellarL(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "patellarL",
        value: e,
      })
    );
  };
  const handlePatellaR = (e) => {
    setPatellaR(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "patellaR",
        value: e,
      })
    );
  };
  const handlePatellaL = (e) => {
    setPatellaL(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "patellaL",
        value: e,
      })
    );
  };
  const handleSignR = (e) => {
    setSignR(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "signR",
        value: e,
      })
    );
  };
  const handleSignL = (e) => {
    setSignL(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "signL",
        value: e,
      })
    );
  };
  const handleLegR = (e) => {
    setLegR(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "legR",
        value: e,
      })
    );
  };
  const handleLegL = (e) => {
    setLegL(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "legL",
        value: e,
      })
    );
  };
  const handleStrengthR = (e) => {
    setStrengthR(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "strengthR",
        value: e,
      })
    );
  };
  const handleStrengthL = (e) => {
    setStrengthL(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "strengthL",
        value: e,
      })
    );
  };
  const handleKTRR = (e) => {
    setKTR(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "KTR",
        value: e,
      })
    );
  };
  const handleKTL = (e) => {
    setKTL(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "KTL",
        value: e,
      })
    );
  };
  const handleDialR = (e) => {
    setDialR(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "dialR",
        value: e,
      })
    );
  };
  const handleDialL = (e) => {
    setDialL(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "dialL",
        value: e,
      })
    );
  };
  const handleChangeTest1 = (e) => {
    const newValue = e.replace(/[^0-9]/g, "");
    settext1(newValue);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "text1",
        value: newValue,
      })
    );
  };
  const handleChangeTest2 = (e) => {
    const newValue = e.replace(/[^0-9]/g, "");
    settext2(newValue);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "text2",
        value: newValue,
      })
    );
  };
  const handleChangeTest3 = (e) => {
    const newValue = e.replace(/[^0-9]/g, "");
    settext3(newValue);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "text3",
        value: newValue,
      })
    );
  };

  const handleChangeTestL1 = (e) => {
    const newValue = e.replace(/[^0-9]/g, "");
    settextL1(newValue);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "textL1",
        value: newValue,
      })
    );
  };
  const handleChangeTestL2 = (e) => {
    const newValue = e.replace(/[^0-9]/g, "");
    settextL2(newValue);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "textL2",
        value: newValue,
      })
    );
  };
  const handleChangeTestL3 = (e) => {
    const newValue = e.replace(/[^0-9]/g, "");
    settextL3(newValue);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "textL3",
        value: newValue,
      })
    );
  };
  const handleChange30R = (e) => {
    settext30R(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "text30R",
        value: e,
      })
    );
  };
  const handleChange90R = (e) => {
    settext90R(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "text90R",
        value: e,
      })
    );
  };
  const handleChange30L = (e) => {
    settext30L(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "text30L",
        value: e,
      })
    );
  };
  const handleChange90L = (e) => {
    settext90L(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "text90L",
        value: e,
      })
    );
  };
  const handleThessalyR = (e) => {
    setThessalyR(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "ThessalyR",
        value: e,
      })
    );
  };
  const handleThessalyL = (e) => {
    setThessalyL(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "ThessalyL",
        value: e,
      })
    );
  };

  const handleThessalyRdrop = (e) => {
    setThessalyRdrop(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "ThessalyRdrop",
        value: e,
      })
    );
  };
  const handleThessalyLdrop = (e) => {
    setThessalyLdrop(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "ThessalyLdrop",
        value: e,
      })
    );
  };
  const othersText = (e) => {
    setOthersText(e);
    dispatch(
      updateAdultKneeFieldFeedback({
        key: "others",
        value: e,
      })
    );
  };
  const [displayConfirmationModal, setDisplayConfirmationModal] =
  useState(false);

  // --
  const [msg, setMsg] = useState('');
  const [msgType, setmsgType] = useState('');
  const updateDate = async () => {
    let response = await axiosInstance.current.put(
      `extapp/forms/update_doctor_feedback?resource_id=${rId}&action=1&edit=1`,
      json,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
      setMsg(translation.update)
      setmsgType('success')
      setTimeout(() => {
        setMsg('')
        setmsgType('success')
      }, 2000);
      // window.open(
      //   process.env.REACT_APP_DOCTOR_URL + 'patient-list',
      //   "_blank"
      // );
      // window.close()
      // window.location.href = process.env.REACT_APP_DOCTOR_URL + "patient-list";
      setDisplayConfirmationModal(true)
    }
  };
  const handleSubmit = () => {
    updateDate();
  };
  useEffect(()=>{
    const updateDate2 = async () => {
    const response = await axiosInstance.current.put(
      `extapp/forms/update_doctor_feedback?resource_id=${rId}&action=0&ak_dc=${dId}`,
      json,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200) {

    }
    }
    if(chk){
      if(doctorEnable !== "true"){
    updateDate2()
      }
    }
  },[json])
  console.log(recurvatumL, "recurvatumL");
  // ---
  const [getId, setGetId] = useState("");
  useEffect(() => {
    // Get the Gait section element by its ID
    const gaitSection = document.getElementById(getId);
    console.log(gaitSection, "gaitSection");
    // Scroll to the Gait section
    if (gaitSection) {
      const yOffset = gaitSection.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: yOffset - window.innerHeight / 3,
        behavior: "smooth",
      });
    }
    console.log(getId, "getId");
  }, [getId]);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
  return (
    <>
    {isLoading ? (
      <LoadingSpinner/>
    ) : (
      <>
      <div class="d-flex justify-content-end">
        <DoctorNameCustom />
      </div>
      <div className="patient_assessment_form bg_white pd_10 pt_50">
        {doctorEnable === "true" && <AdultkneeTab rId={rId} />}
        <CustomToaster msg={msg} msgType={msgType} />
        
      </div>

      <div className="patient_assessment_form mb_100 bg_white pd_10 pt_50 content_fx_w">
        {/* <AssessmentHeader /> */}
        <HeaderInnerBreadcrumb />

        <div className="row">
          <div className="col-2 ">
            <div
              className="sticky "
              style={{
                position: "sticky",
                top: "75px",
              }}
            >
              <FormVerticalMenu setGetId={setGetId} />
            </div>
          </div>

          <div className="col-10">
            <div className="input_tab_box mb_30">
              <h4 className="range_sl_title" id="acl">
                {translation.ACL}
              </h4>

              <div className="row">
                <div className="col-md-6 col-sm-6 mb_10">
                  <RadioSwitch
                    labelLeft={translation.Negative}
                    labelRight={translation.Positive}
                    idLeft={translation.anteriorR}
                    idRight={translation.anteriorL}
                    name={translation.anteriorR}
                    title={translation.Anterior_drawer_90_degree_R}
                    styleClass=""
                    value={anteriorR}
                    onChange={handleAnteriorR}
                    placeholder="select"
                  />
                  {anteriorR === "1" && (
                    <FullDropdown
                      data={[
                        { value: "2", label: "< 5mm" },
                        { value: "1", label: "5-10mm" },
                        { value: "0", label: "> 10mm" },
                      ]}
                      styleClass=""
                      value={anterdropR}
                      onChange={handleAnterdropR}
                    />
                  )}
                </div>
                <div className="col-md-6 col-sm-6 mb_10">
                  <RadioSwitch
                    labelLeft={translation.Negative}
                    labelRight={translation.Positive}
                    idLeft={translation.anteriorLL}
                    idRight={translation.anteriorRR}
                    name={translation.anteriorL}
                    title={translation.Anterior_drawer_90_degree_L}
                    styleClass=""
                    value={anteriorL}
                    onChange={handleAnteriorL}
                    placeholder="select"
                  />
                  {anteriorL == "1" && (
                    <FullDropdown
                      data={[
                        { value: "2", label: "< 5mm" },
                        { value: "1", label: "5-10mm" },
                        { value: "0", label: "> 10mm" },
                      ]}
                      styleClass=""
                      value={anterdropL}
                      onChange={handleAnterdropL}
                    />
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-sm-6 mb_10">
                  <RadioSwitch
                    labelLeft={translation.Negative}
                    labelRight={translation.Positive}
                    idLeft={translation.lachmanR}
                    idRight={translation.lachmanL}
                    name={translation.lachmanR}
                    title={translation.Lachman_test_R}
                    styleClass=""
                    value={lachmanR}
                    onChange={handleLachmanR}
                    placeholder="select"
                  />
                  {lachmanR === "1" && (
                    <FullDropdown
                      data={[
                        { value: "2", label: "< 5mm" },
                        { value: "1", label: "5-10mm" },
                        { value: "0", label: "> 10mm" },
                      ]}
                      styleClass=""
                      value={lachmandropR}
                      onChange={handleLachmandropR}
                    />
                  )}
                </div>
                <div className="col-md-6 col-sm-6 mb_10">
                  <RadioSwitch
                    labelLeft={translation.Negative}
                    labelRight={translation.Positive}
                    idLeft={translation.lachmanlL}
                    idRight={translation.lachmanlR}
                    name={translation.lachmanL}
                    title={translation.Lachman_test_L}
                    styleClass=""
                    value={lachmanL}
                    onChange={handleLachmanL}
                    placeholder="select"
                  />
                  {lachmanL == "1" && (
                    <FullDropdown
                      data={[
                        { value: "2", label: "< 5mm" },
                        { value: "1", label: "5-10mm" },
                        { value: "0", label: "> 10mm" },
                      ]}
                      styleClass=""
                      value={lachmandropL}
                      onChange={handleLachmandropL}
                    />
                  )}
                </div>
                <div className="col-md-6 col-sm-6 mb_10">
                  {/* <RadioSwitch
                    labelLeft={translation.Negative}
                    labelRight={translation.Positive}
                    idLeft={translation.lachmanlL}
                    idRight={translation.lachmanlR}
                    name={translation.lachmanL}
                    title={translation.Lachman_test_L}
                    styleClass=""
                    value={lachmanL}
                    onChange={handleLachmanL}
                    placeholder="select"
                  />
                  {lachmanL === "1" && (
                    <FullDropdown
                      data={[
                        { value: "2", label: "< 5mm" },
                        { value: "1", label: "5-10mm" },
                        { value: "0", label: "> 10mm" },
                      ]}
                      styleClass=""
                      value={lachmandropL}
                      onChange={handleLachmandropL}
                    />
                  )} */}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-sm-6 mb_10">
                  <RadioSwitch
                    labelLeft={translation.Negative}
                    labelRight={translation.Positive}
                    idLeft={translation.pivotR}
                    idRight={translation.pivotL}
                    name={translation.pivotR}
                    title={translation.Pivot_shift_test_R}
                    styleClass=""
                    value={pivotR}
                    onChange={handlePivotR}
                    placeholder="select"
                  />
                  {pivotR === "1" && (
                    <FullDropdown
                      data={[
                        { value: "2", label: "Grade I" },
                        { value: "1", label: "Grade II" },
                        { value: "0", label: "Grade III" },
                      ]}
                      styleClass=""
                      value={pivotdropR}
                      onChange={handlePivotdropR}
                    />
                  )}
                </div>
                <div className="col-md-6 col-sm-6 mb_10">
                  <RadioSwitch
                    labelLeft={translation.Negative}
                    labelRight={translation.Positive}
                    idLeft={translation.pivotlL}
                    idRight={translation.pivotlR}
                    name={translation.pivotL}
                    title={translation.Pivot_shift_test_L}
                    styleClass=""
                    value={pivotL}
                    onChange={handlePivotL}
                    placeholder="select"
                  />
                  {pivotL === "1" && (
                    <FullDropdown
                      data={[
                        { value: "2", label: "Grade I" },
                        { value: "1", label: "Grade II" },
                        { value: "0", label: "Grade III" },
                      ]}
                      styleClass=""
                      value={pivotdropL}
                      onChange={handlePivotdropL}
                    />
                  )}
                </div>
                <div className="col-md-6 col-sm-6 mb_10">
                  {/* <RadioSwitch
                    labelLeft={translation.Negative}
                    labelRight={translation.Positive}
                    idLeft={translation.pivotlL}
                    idRight={translation.pivotlR}
                    name={translation.pivotL}
                    title={translation.Pivot_shift_test_L}
                    styleClass=""
                    value={pivotL}
                    onChange={handlePivotL}
                    placeholder="select"
                  />
                  {pivotL === "1" && (
                    <FullDropdown
                      data={[
                        { value: "2", label: "Grade I" },
                        { value: "1", label: "Grade II" },
                        { value: "0", label: "Grade III" },
                      ]}
                      styleClass=""
                      value={pivotdropL}
                      onChange={handlePivotdropL}
                    />
                  )} */}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-sm-6 mb_10">
                  <div className="mb_20">
                    <strong className="d-inline-block mb_15">
                      {translation.KT_1000_R}
                    </strong>
                    <InputField
                      label={translation.Test_1}
                      type="text"
                      onChange={handleChangeTest1}
                      value={text1}
                      placeholder={"in mm"}
                    />
                    <InputField
                      label={translation.Test_2}
                      onChange={handleChangeTest2}
                      value={text2}
                      type="text"
                      placeholder={"in mm"}
                    />
                    <InputField
                      label={translation.Test_3}
                      onChange={handleChangeTest3}
                      value={text3}
                      type="text"
                      placeholder={"in mm"}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-sm-6 mb_10">
                  <div className="mb_20">
                    <strong className="d-inline-block mb_15">
                      {translation.KT_1000_L}
                    </strong>
                    <InputField
                      label={translation.Test_1}
                      onChange={handleChangeTestL1}
                      value={textL1}
                      type="text"
                      placeholder={"in mm"}
                    />
                    <InputField
                      label={translation.Test_2}
                      onChange={handleChangeTestL2}
                      value={textL2}
                      type="text"
                      placeholder={"in mm"}
                    />
                    <InputField
                      label={translation.Test_3}
                      onChange={handleChangeTestL3}
                      value={textL3}
                      type="text"
                      placeholder={"in mm"}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="input_tab_box mb_30">
              <h4 className="range_sl_title" id="pcl">
                PCL
              </h4>
              <div className="row">
                <div className="col-md-6 col-sm-6 mb_10">
                  <RadioSwitch
                    labelLeft={translation.Negative}
                    labelRight={translation.Positive}
                    idLeft={translation.sagR}
                    idRight={translation.sagL}
                    name={translation.sagR}
                    title={translation.Posterior_sag_sign_R}
                    styleClass=""
                    value={sagR}
                    onChange={handleSagR}
                    placeholder="select"
                  />
                </div>
                <div className="col-md-6 col-sm-6 mb_10">
                  <RadioSwitch
                    labelLeft={translation.Negative}
                    labelRight={translation.Positive}
                    idLeft={translation.sagLL}
                    idRight={translation.sagRR}
                    name={translation.sagL}
                    title={translation.Posterior_sag_sign_L}
                    styleClass=""
                    value={sagL}
                    onChange={handleSagL}
                    placeholder="select"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-sm-6 mb_10">
                  <RadioSwitch
                    labelLeft={translation.Negative}
                    labelRight={translation.Positive}
                    idLeft={translation.drawerR}
                    idRight={translation.drawerL}
                    name={translation.drawerR}
                    title={translation.Posterior_drawer_test_Measured_R}
                    styleClass=""
                    value={drawerR}
                    onChange={handleDrawerR}
                    placeholder="select"
                  />
                  {drawerR === "1" && (
                    <FullDropdown
                      data={[
                        { value: "2", label: "< 5mm" },
                        { value: "1", label: "5-10mm" },
                        { value: "0", label: "> 10mm" },
                      ]}
                      styleClass=""
                      value={drawdropR}
                      onChange={handleDrawdropR}
                    />
                  )}
                </div>
                <div className="col-md-6 col-sm-6 mb_10">
                  <RadioSwitch
                    labelLeft={translation.Negative}
                    labelRight={translation.Positive}
                    idLeft={translation.drawR}
                    idRight={translation.drawL}
                    name={translation.drawerL}
                    title={translation.Posterior_drawer_test_Measured_L}
                    styleClass=""
                    value={drawerL}
                    onChange={handleDrawerL}
                    placeholder="select"
                  />
                  {drawerL === "1" && (
                    <FullDropdown
                      data={[
                        { value: "2", label: "< 5mm" },
                        { value: "1", label: "5-10mm" },
                        { value: "0", label: "> 10mm" },
                      ]}
                      styleClass=""
                      value={drawdropL}
                      onChange={handleDrawdropL}
                    />
                  )}
                </div>
                <div className="col-md-6 col-sm-6 mb_10">
                  {/* <RadioSwitch
                    labelLeft={translation.Negative}
                    labelRight={translation.Positive}
                    idLeft={translation.drawR}
                    idRight={translation.drawL}
                    name={translation.drawerL}
                    title={translation.Posterior_drawer_test_Measured_L}
                    styleClass=""
                    value={drawerL}
                    onChange={handleDrawerL}
                    placeholder="select"
                  />
                  {drawerL === "1" && (
                    <FullDropdown
                      data={[
                        { value: "2", label: "< 5mm" },
                        { value: "1", label: "5-10mm" },
                        { value: "0", label: "> 10mm" },
                      ]}
                      styleClass=""
                      value={drawdropL}
                      onChange={handleDrawdropL}
                    />
                  )} */}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-sm-6 mb_10">
                  <RadioSwitch
                    labelLeft={translation.Negative}
                    labelRight={translation.Positive}
                    idLeft={translation.activeR}
                    idRight={translation.activeL}
                    name={translation.activeR}
                    title={translation.Quadriceps_active_test_R}
                    styleClass=""
                    value={activeR}
                    onChange={handleActiveR}
                    placeholder="select"
                  />
                </div>
                <div className="col-md-6 col-sm-6 mb_10">
                  <RadioSwitch
                    labelLeft={translation.Negative}
                    labelRight={translation.Positive}
                    idLeft={translation.activerR}
                    idRight={translation.activerL}
                    name={translation.QuadricepsL}
                    title={translation.Quadriceps_active_test_L}
                    styleClass=""
                    value={activeL}
                    onChange={handleActiveL}
                    placeholder="select"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-sm-6 mb_10">
                  <RadioSwitch
                    labelLeft={translation.Negative}
                    labelRight={translation.Positive}
                    idLeft={translation.reverseR}
                    idRight={translation.reverseL}
                    name={translation.reverseR}
                    title={translation.Reverse_pivot_shift_test_R}
                    styleClass=""
                    value={reverseR}
                    onChange={handleReverseR}
                    placeholder="select"
                  />
                </div>
                <div className="col-md-6 col-sm-6 mb_10">
                  <RadioSwitch
                    labelLeft={translation.Negative}
                    labelRight={translation.Positive}
                    idLeft={translation.reverserL}
                    idRight={translation.reverserR}
                    name={translation.reverseL}
                    title={translation.Reverse_pivot_shift_test_L}
                    styleClass=""
                    value={reverseL}
                    onChange={handleReverseL}
                    placeholder="select"
                  />
                </div>
              </div>
            </div>
            <div className="input_tab_box mb_30">
              <h4 className="range_sl_title" id="plc">
                {translation.PLC}
              </h4>
              <div className="row">
                <div className="col-md-6 col-sm-6 mb_10">
                  <div className="mb_20">
                    <strong className="d-inline-block mb_15">
                      {translation.Dial_test_R}
                    </strong>
                    <RadioSwitch
                      labelLeft={translation.Negative}
                      labelRight={translation.Positive}
                      idLeft={"text30LL"}
                      idRight={"text30LR"}
                      name={"text30R"}
                      title={"30 degrees"}
                      styleClass=""
                      onChange={handleChange30R}
                      value={text30R}
                      placeholder="select"
                    />
                    {/* <InputField
                    label="30 degrees"
                    onChange={handleChange30R}
                    value={text30R}
                    placeholder={translation.Write_Test_1}
                  /> */}
                    <RadioSwitch
                      labelLeft={translation.Negative}
                      labelRight={translation.Positive}
                      idLeft={"3"}
                      idRight={"4"}
                      name={"text90R"}
                      title={"90 degrees"}
                      styleClass=""
                      onChange={handleChange90R}
                      value={text90R}
                      placeholder="select"
                    />
                  </div>
                </div>
                <div className="col-md-6 col-sm-6 mb_10">
                  <div className="mb_20">
                    <strong className="d-inline-block mb_15">
                      {translation.Dial_test_L}
                    </strong>
                    <RadioSwitch
                      labelLeft={translation.Negative}
                      labelRight={translation.Positive}
                      idLeft={"5"}
                      idRight={"6"}
                      name={"text30L"}
                      title={"30 degrees"}
                      styleClass=""
                      onChange={handleChange30L}
                      value={text30L}
                      placeholder="select"
                    />
                    {/* <InputField
                    label="30"
                    onChange={handleChange30L}
                    value={text30L}
                    placeholder={translation.Write_Test_1}
                  /> */}
                    {/* <InputField
                    label="90"
                    onChange={handleChange90L}
                    value={text90L}
                    placeholder={translation.Write_Test_2}
                  /> */}
                    <RadioSwitch
                      labelLeft={translation.Negative}
                      labelRight={translation.Positive}
                      idLeft={"7"}
                      idRight={"8"}
                      name={"text90L"}
                      title={"90 degrees"}
                      styleClass=""
                      onChange={handleChange90L}
                      value={text90L}
                      placeholder="select"
                    />
                    {/* <InputField
                    label="90 degrees"
                    onChange={handleChange90R}
                    value={text90R}
                    placeholder={translation.Write_Test_2}
                  /> */}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-sm-6 mb_10">
                  <RadioSwitch
                    labelLeft={translation.Negative}
                    labelRight={translation.Positive}
                    idLeft={translation.recurvatumL}
                    idRight={translation.recurvatumR}
                    name={translation.recurvatumR}
                    title={translation.ER_recurvatum_test_R}
                    styleClass=""
                    value={recurvatumR}
                    onChange={handleRecurvatumR}
                    placeholder="select"
                  />
                </div>
                <div className="col-md-6 col-sm-6 mb_10">
                  <RadioSwitch
                    labelLeft={translation.Negative}
                    labelRight={translation.Positive}
                    idLeft={translation.recurvatumrL}
                    idRight={translation.recurvatumrR}
                    name={translation.recurvatumL}
                    title={translation.ER_recurvatum_test_L}
                    styleClass=""
                    value={recurvatumL}
                    onChange={handleRecurvatumL}
                    placeholder="select"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-sm-6 mb_10">
                  <RadioSwitch
                    labelLeft={translation.Negative}
                    labelRight={translation.Positive}
                    idLeft={translation.postL}
                    idRight={translation.postR}
                    name={translation.postR}
                    title={translation.Posterolateral_drawer_test_R}
                    styleClass=""
                    value={postR}
                    onChange={handlePostR}
                    placeholder="select"
                  />
                </div>
                <div className="col-md-6 col-sm-6 mb_10">
                  <RadioSwitch
                    labelLeft={translation.Negative}
                    labelRight={translation.Positive}
                    idLeft={translation.postrL}
                    idRight={translation.postrR}
                    name={translation.postL}
                    title={translation.Posterolateral_drawer_test_L}
                    styleClass=""
                    value={postL}
                    onChange={handlePostL}
                    placeholder="select"
                  />
                </div>
              </div>
            </div>
            <div className="input_tab_box mb_30">
              <h4 className="range_sl_title" id="pmc">
                {translation.PMC}
              </h4>
              <div className="row">
                <div className="col-md-6 col-sm-6 mb_10">
                  <RadioSwitch
                    labelLeft={translation.Negative}
                    labelRight={translation.Positive}
                    idLeft={translation.rotatL}
                    idRight={translation.rotatR}
                    name={translation.rotatR}
                    title={translation.Anteromedial_rotatory_instability_R}
                    styleClass=""
                    value={rotatR}
                    onChange={handleRotatR}
                    placeholder="select"
                  />
                </div>
                <div className="col-md-6 col-sm-6 mb_10">
                  <RadioSwitch
                    labelLeft={translation.Negative}
                    labelRight={translation.Positive}
                    idLeft={translation.rotatrL}
                    idRight={translation.rotatrR}
                    name={translation.rotatL}
                    title={translation.Anteromedial_rotatory_instability_L}
                    styleClass=""
                    value={rotatL}
                    onChange={handleRotatL}
                    placeholder="select"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-sm-6 mb_10">
                  <RadioSwitch
                    labelLeft={translation.Negative}
                    labelRight={translation.Positive}
                    idLeft={translation.drawsL}
                    idRight={translation.drawsR}
                    name={translation.drawsR}
                    title={translation.Anteromedial_drawer_test_R}
                    styleClass=""
                    value={drawersR}
                    onChange={handleDrawsR}
                    placeholder="select"
                  />
                </div>
                <div className="col-md-6 col-sm-6 mb_10">
                  <RadioSwitch
                    labelLeft={translation.Negative}
                    labelRight={translation.Positive}
                    idLeft={translation.drawsrL}
                    idRight={translation.drawsrR}
                    name={translation.drawsL}
                    title={translation.Anteromedial_drawer_test_L}
                    styleClass=""
                    value={drawersL}
                    onChange={handleDrawsL}
                    placeholder="select"
                  />
                </div>
              </div>
            </div>
            <div className="input_tab_box mb_30">
              <h4 className="range_sl_title" id="collaterals">
                {translation.Collaterals}
              </h4>
              <div className="row">
                <h6 className="">Varus stress test :</h6>

                <div className="col-md-6 col-sm-6 mb_10">
                  <RadioSwitch
                    labelLeft={translation.Negative}
                    labelRight={translation.Positive}
                    idLeft={translation.stressL}
                    idRight={translation.stressR}
                    name={translation.stressR}
                    title={"In full extension (Right)"}
                    styleClass=""
                    value={stressR}
                    onChange={handleStressR}
                    placeholder="select"
                  />
                  {stressR === "1" && (
                    <FullDropdown
                      data={[
                        { value: "3", label: "< 5 degrees" },
                        { value: "2", label: "6-9 degrees" },
                        { value: "1", label: "10-14 degrees" },
                        { value: "0", label: "15 degrees" },
                      ]}
                      styleClass=""
                      value={stressdropR}
                      onChange={handleStressdropR}
                    />
                  )}
                </div>
                <div className="col-md-6 col-sm-6 mb_10">
                  <RadioSwitch
                    labelLeft={translation.Negative}
                    labelRight={translation.Positive}
                    idLeft={translation.stressrL}
                    idRight={translation.stressrR}
                    name={translation.stressL}
                    title={"In full extension (Left)"}
                    styleClass=""
                    value={stressL}
                    onChange={handleStressL}
                    placeholder="select"
                  />
                  {stressL === "1" && (
                    <FullDropdown
                      data={[
                        { value: "3", label: "< 5 degrees" },
                        { value: "2", label: "6-9 degrees" },
                        { value: "1", label: "10-14 degrees" },
                        { value: "0", label: "15 degrees" },
                      ]}
                      styleClass=""
                      value={stressdropL}
                      onChange={handleStressdropL}
                    />
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-sm-6 mb_10">
                  <RadioSwitch
                    labelLeft={translation.Negative}
                    labelRight={translation.Positive}
                    idLeft={translation.stressflL}
                    idRight={translation.stressflR}
                    name={translation.stressflR}
                    title={"In 30 degree flexion (Right)"}
                    styleClass=""
                    value={stressflR}
                    onChange={handleStressflR}
                    placeholder="select"
                  />
                  {stressflR === "1" && (
                    <FullDropdown
                      data={[
                        { value: "3", label: "< 5 degrees" },
                        { value: "2", label: "6-9 degrees" },
                        { value: "1", label: "10-14 degrees" },
                        { value: "0", label: "15 degrees" },
                      ]}
                      styleClass=""
                      value={stressdropflR}
                      onChange={handleStressdropflR}
                    />
                  )}
                </div>
                <div className="col-md-6 col-sm-6 mb_10">
                  <RadioSwitch
                    labelLeft={translation.Negative}
                    labelRight={translation.Positive}
                    idLeft={translation.stressrflrL}
                    idRight={translation.stressrflrR}
                    name={translation.stressflL}
                    title={"In 30 degree flexion (Left)"}
                    styleClass=""
                    value={stressflL}
                    onChange={handleStressflL}
                    placeholder="select"
                  />
                  {stressflL === "1" && (
                    <FullDropdown
                      data={[
                        { value: "3", label: "< 5 degrees" },
                        { value: "2", label: "6-9 degrees" },
                        { value: "1", label: "10-14 degrees" },
                        { value: "0", label: "15 degrees" },
                      ]}
                      styleClass=""
                      value={stressdropflL}
                      onChange={handleStressdropflL}
                    />
                  )}
                </div>
              </div>
              <div className="row">
                <h6 className="">Valgus stress test :</h6>

                <div className="col-md-6 col-sm-6 mb_10">
                  <RadioSwitch
                    labelLeft={translation.Negative}
                    labelRight={translation.Positive}
                    idLeft={translation.valgusL}
                    idRight={translation.valgusR}
                    name={translation.valgusR}
                    title={"In full extension(Right)"}
                    styleClass=""
                    value={valgusR}
                    onChange={handleValgusR}
                    placeholder="select"
                  />
                  {valgusR === "1" && (
                    <FullDropdown
                      data={[
                        { value: "3", label: "< 5 degrees" },
                        { value: "2", label: "6-9 degrees" },
                        { value: "1", label: "10-14 degrees" },
                        { value: "0", label: "15 degrees" },
                      ]}
                      styleClass=""
                      value={valgusdropR}
                      onChange={handleValgusdropR}
                    />
                  )}
                </div>
                <div className="col-md-6 col-sm-6 mb_10">
                  <RadioSwitch
                    labelLeft={translation.Negative}
                    labelRight={translation.Positive}
                    idLeft={translation.valgusrL}
                    idRight={translation.valgusrR}
                    name={translation.valgusL}
                    title={"In full extension(Left)"}
                    styleClass=""
                    value={valgusL}
                    onChange={handleValgusL}
                    placeholder="select"
                  />
                  {valgusL === "1" && (
                    <FullDropdown
                      data={[
                        { value: "3", label: "< 5 degrees" },
                        { value: "2", label: "6-9 degrees" },
                        { value: "1", label: "10-14 degrees" },
                        { value: "0", label: "15 degrees" },
                      ]}
                      styleClass=""
                      value={valgusdropL}
                      onChange={handleValgusdropL}
                    />
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-sm-6 mb_10">
                  <RadioSwitch
                    labelLeft={translation.Negative}
                    labelRight={translation.Positive}
                    idLeft={translation.valgusflL}
                    idRight={translation.valgusflR}
                    name={translation.valgusflR}
                    title={"In 30 degree flexion (Right)"}
                    styleClass=""
                    value={valgusflR}
                    onChange={handleValgusflR}
                    placeholder="select"
                  />
                  {valgusflR === "1" && (
                    <FullDropdown
                      data={[
                        { value: "3", label: "< 5 degrees" },
                        { value: "2", label: "6-9 degrees" },
                        { value: "1", label: "10-14 degrees" },
                        { value: "0", label: "15 degrees" },
                      ]}
                      styleClass=""
                      value={valgusdropflR}
                      onChange={handleValgusdropflR}
                    />
                  )}
                </div>
                <div className="col-md-6 col-sm-6 mb_10">
                  <RadioSwitch
                    labelLeft={translation.Negative}
                    labelRight={translation.Positive}
                    idLeft={translation.valgusflrL}
                    idRight={translation.valgusflrR}
                    name={translation.valgusflL}
                    title={"In 30 degree flexion (left)"}
                    styleClass=""
                    value={valgusflL}
                    onChange={handleValgusflL}
                    placeholder="select"
                  />
                  {valgusflL === "1" && (
                    <FullDropdown
                      data={[
                        { value: "3", label: "< 5 degrees" },
                        { value: "2", label: "6-9 degrees" },
                        { value: "1", label: "10-14 degrees" },
                        { value: "0", label: "15 degrees" },
                      ]}
                      styleClass=""
                      value={valgusdropflL}
                      onChange={handleValgusdropflL}
                    />
                  )}
                </div>
                {/* <div className="col-md-6 col-sm-6 mb_10">
                  <RadioSwitch
                    labelLeft={translation.Negative}
                    labelRight={translation.Positive}
                    idLeft={translation.valgusflrL}
                    idRight={translation.valgusflrR}
                    name={translation.valgusflL}
                    title={"In 30 degree flexion (Right)"}
                    styleClass=""
                    value={valgusflL}
                    onChange={handleValgusflL}
                    placeholder="select"
                  />
                  {valgusflL === "1" && (
                    <FullDropdown
                      data={[
                        { value: "3", label: "< 5 degree" },
                        { value: "2", label: "6-9 degree" },
                        { value: "1", label: "10-14 degree" },
                        { value: "0", label: "15 degree" },
                      ]}
                      styleClass=""
                      value={valgusdropflL}
                      onChange={handleValgusdropflL}
                    />
                  )}
                </div> */}
              </div>
            </div>
            <div className="input_tab_box mb_30">
              <h4 className="range_sl_title" id="meniscus">
                {translation.Meniscus}
              </h4>

              <div className="row">
                <div className="col-md-6 col-sm-6 mb_10">
                  <div className="mb_20">
                    <RadioSwitch
                      labelLeft={translation.Negative}
                      labelRight={translation.Positive}
                      idLeft={"10"}
                      idRight={"11"}
                      name={"11"}
                      title={translation.Joint_line_tenderness_R}
                      styleClass=""
                      value={tendernessR}
                      onChange={handletendernessR}
                      placeholder="select"
                    />
                    {tendernessR === "1" && (
                      <FullDropdown
                        data={[
                          { value: "1", label: "Medial" },
                          { value: "2", label: "Lateral" },
                          { value: "3", label: "Both" },
                        ]}
                        styleClass=""
                        value={jointR}
                        onChange={handleJointR}
                      />
                    )}
                    {/* <FullDropdown
                    data={[
                      { value: "2", label: "None" },
                      { value: "1", label: "Medial" },
                      { value: "0", label: "Lateral" },
                    ]}
                    label={translation.Joint_line_tenderness_R}
                    styleClass=""
                    value={jointR}
                    onChange={handleJointR}
                  /> */}
                  </div>
                </div>
                <div className="col-md-6 col-sm-6 mb_10">
                  <div className="mb_20">
                    <RadioSwitch
                      labelLeft={translation.Negative}
                      labelRight={translation.Positive}
                      idLeft={"12"}
                      idRight={"13"}
                      name={"13"}
                      title={translation.Joint_line_tenderness_L}
                      styleClass=""
                      value={tendernessL}
                      onChange={handletendernessL}
                      placeholder="select"
                    />
                    {tendernessL === "1" && (
                      <FullDropdown
                        data={[
                          { value: "1", label: "Medial" },
                          { value: "2", label: "Lateral" },
                          { value: "3", label: "Both" },
                        ]}
                        styleClass=""
                        value={jointL}
                        onChange={handleJointL}
                      />
                    )}
                    {/* <FullDropdown
                    data={[
                      { value: "2", label: "None" },
                      { value: "1", label: "Medial" },
                      { value: "0", label: "Lateral" },
                    ]}
                    label={translation.Joint_line_tenderness_R}
                    styleClass=""
                    value={jointR}
                    onChange={handleJointR}
                  /> */}
                  </div>
                </div>
                <div className="col-md-6 col-sm-6 mb_10">
                  <div className="mb_20">
                    {/* <RadioSwitch
                      labelLeft={translation.Negative}
                      labelRight={translation.Positive}
                      idLeft={"12"}
                      idRight={"13"}
                      name={"13"}
                      title={translation.Joint_line_tenderness_L}
                      styleClass=""
                      value={tendernessL}
                      onChange={handletendernessL}
                      placeholder="select"
                    />
                    {tendernessL === "1" && (
                      <FullDropdown
                        data={[
                          { value: "1", label: "Medial" },
                          { value: "2", label: "Lateral" },
                          { value: "3", label: "Both" },
                        ]}
                        styleClass=""
                        value={jointL}
                        onChange={handleJointL}
                      />
                    )} */}
                    {/* <FullDropdown
                    data={[
                      { value: "2", label: "None" },
                      { value: "1", label: "Medial" },
                      { value: "0", label: "Lateral" },
                    ]}
                    label={translation.Joint_line_tenderness_L}
                    styleClass=""
                    value={jointL}
                    onChange={handleJointL}
                  /> */}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-sm-6 mb_10">
                  <RadioSwitch
                    labelLeft={translation.Negative}
                    labelRight={translation.Positive}
                    idLeft={translation.murraryL}
                    idRight={translation.murraryR}
                    name={translation.murraryR}
                    title={"McMurray test (Right)"}
                    styleClass=""
                    value={murraryR}
                    onChange={handleMurraryR}
                    placeholder="select"
                  />
                  {murraryR === "1" && (
                    <FullDropdown
                      data={[
                        { value: "1", label: "Medial" },
                        { value: "2", label: "Lateral" },
                        { value: "3", label: "Both" },
                      ]}
                      styleClass=""
                      value={murraryRdrop}
                      onChange={handlemurraryRdrop}
                    />
                  )}
                </div>
                <div className="col-md-6 col-sm-6 mb_10">
                  <RadioSwitch
                    labelLeft="Negative"
                    labelRight="Positive"
                    idLeft="murraryrL"
                    idRight="murraryrR"
                    name="murraryL"
                    title={"McMurray test (Left)"}
                    styleClass=""
                    value={murraryL}
                    onChange={handleMurraryL}
                    placeholder="select"
                  />
                  {murraryL === "1" && (
                    <FullDropdown
                      data={[
                        { value: "1", label: "Medial" },
                        { value: "2", label: "Lateral" },
                        { value: "3", label: "Both" },
                      ]}
                      styleClass=""
                      value={murraryLdrop}
                      onChange={handlemurraryLdrop}
                    />
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-sm-6 mb_10">
                  <RadioSwitch
                    labelLeft="Negative"
                    labelRight="Positive"
                    idLeft="egeL"
                    idRight="egeR"
                    name="egeR"
                    title={"Ege’s test (Right)"}
                    styleClass=""
                    value={egeR}
                    onChange={handleEgeR}
                    placeholder="select"
                  />
                  {egeR === "1" && (
                    <FullDropdown
                      data={[
                        { value: "1", label: "Medial" },
                        { value: "2", label: "Lateral" },
                        { value: "3", label: "Both" },
                      ]}
                      styleClass=""
                      value={egeRdrop}
                      onChange={handleegeRdrop}
                    />
                  )}
                </div>
                <div className="col-md-6 col-sm-6 mb_10">
                  <RadioSwitch
                    labelLeft="Negative"
                    labelRight="Positive"
                    idLeft="egerL"
                    idRight="egerR"
                    name="egeL"
                    title={"Ege’s test (Left)"}
                    styleClass=""
                    value={egeL}
                    onChange={handleEgeL}
                    placeholder="select"
                  />
                  {egeL === "1" && (
                    <FullDropdown
                      data={[
                        { value: "1", label: "Medial" },
                        { value: "2", label: "Lateral" },
                        { value: "3", label: "Both" },
                      ]}
                      styleClass=""
                      value={egeLdrop}
                      onChange={handleegeLdrop}
                    />
                  )}
                </div>
                {/* <div className="col-md-6 col-sm-6 mb_10">
                  <RadioSwitch
                    labelLeft="Negative"
                    labelRight="Positive"
                    idLeft="egerL"
                    idRight="egerR"
                    name="egeL"
                    title={"Ege’s test (Left)"}
                    styleClass=""
                    value={egeL}
                    onChange={handleEgeL}
                    placeholder="select"
                  />
                  {egeL === "1" && (
                    <FullDropdown
                      data={[
                        { value: "1", label: "Medial" },
                        { value: "2", label: "Lateral" },
                        { value: "3", label: "Both" },
                      ]}
                      styleClass=""
                      value={egeLdrop}
                      onChange={handleegeLdrop}
                    />
                  )}
                </div> */}
              </div>

              {/* <div className="row">
                <div className="col-md-6 col-sm-6 mb_10">
                  <RadioSwitch
                    labelLeft="Negative"
                    labelRight="Positive"
                    idLeft="ThessalyrL"
                    idRight="ThessalyrR"
                    name="ThessalyrR"
                    title={"Thessaly test (Right)"}
                    styleClass=""
                    value={ThessalyR}
                    onChange={handleThessalyR}
                    placeholder="select"
                  />
                  {ThessalyR === "1" && (
                    <FullDropdown
                      data={[
                        { value: "1", label: "Medial" },
                        { value: "2", label: "Lateral" },
                        { value: "3", label: "Both" },
                      ]}
                      styleClass=""
                      value={ThessalyRdrop}
                      onChange={handleThessalyRdrop}
                    />
                  )}
                </div>
                <div className="col-md-6 col-sm-6 mb_10">
                  <RadioSwitch
                    labelLeft="Negative"
                    labelRight="Positive"
                    idLeft="ThessalyL"
                    idRight="ThessalyR"
                    name="ThessalyL"
                    title={"Thessaly test (Left)"}
                    styleClass=""
                    value={ThessalyL}
                    onChange={handleThessalyL}
                    placeholder="select"
                  />
                  {ThessalyL === "1" && (
                    <FullDropdown
                      data={[
                        { value: "1", label: "Medial" },
                        { value: "2", label: "Lateral" },
                        { value: "3", label: "Both" },
                      ]}
                      styleClass=""
                      value={ThessalyLdrop}
                      onChange={handleThessalyLdrop}
                    />
                  )}
                </div>
                <div className="col-md-6 col-sm-6 mb_10">
                  <RadioSwitch
                    labelLeft="Negative"
                    labelRight="Positive"
                    idLeft="ThessalyrL"
                    idRight="ThessalyrR"
                    name="ThessalyrR"
                    title={"Thessaly test (Right)"}
                    styleClass=""
                    value={ThessalyR}
                    onChange={handleThessalyR}
                    placeholder="select"
                  />
                  {ThessalyR === "1" && (
                    <FullDropdown
                      data={[
                        { value: "1", label: "Medial" },
                        { value: "2", label: "Lateral" },
                        { value: "3", label: "Both" },
                      ]}
                      styleClass=""
                      value={ThessalyRdrop}
                      onChange={handleThessalyRdrop}
                    />
                  )}
                </div>
              </div> */}

              <div className="row">
                <div className="col-md-6 col-sm-6 mb_10">
                  <RadioSwitch
                    labelLeft="Negative"
                    labelRight="Positive"
                    idLeft="apleyL"
                    idRight="apleyR"
                    name="apleyR"
                    title={"Apley’s test (Right)"}
                    styleClass=""
                    value={apleyR}
                    onChange={handleApleyR}
                    placeholder="select"
                  />
                  {apleyR === "1" && (
                    <FullDropdown
                      data={[
                        { value: "1", label: "Medial" },
                        { value: "2", label: "Lateral" },
                        { value: "3", label: "Both" },
                      ]}
                      styleClass=""
                      value={apleyRdrop}
                      onChange={handleapleyRdrop}
                    />
                  )}
                </div>

                <div className="col-md-6 col-sm-6 mb_10">
                  <RadioSwitch
                    labelLeft="Negative"
                    labelRight="Positive"
                    idLeft="apleyrL"
                    idRight="apleyrR"
                    name="apleyL"
                    title={"Apley’s test (Left)"}
                    styleClass=""
                    value={apleyL}
                    onChange={handleApleyL}
                    placeholder="select"
                  />
                  {apleyL === "1" && (
                    <FullDropdown
                      data={[
                        { value: "1", label: "Medial" },
                        { value: "2", label: "Lateral" },
                        { value: "3", label: "Both" },
                      ]}
                      styleClass=""
                      value={apleyLdrop}
                      onChange={handleapleyLdrop}
                    />
                  )}
                </div>
                {/* <div className="col-md-6 col-sm-6 mb_10">
                  <RadioSwitch
                    labelLeft="Negative"
                    labelRight="Positive"
                    idLeft="apleyrL"
                    idRight="apleyrR"
                    name="apleyL"
                    title={"Apley’s test (Left)"}
                    styleClass=""
                    value={apleyL}
                    onChange={handleApleyL}
                    placeholder="select"
                  />
                  {apleyL === "1" && (
                    <FullDropdown
                      data={[
                        { value: "1", label: "Medial" },
                        { value: "2", label: "Lateral" },
                        { value: "3", label: "Both" },
                      ]}
                      styleClass=""
                      value={apleyLdrop}
                      onChange={handleapleyLdrop}
                    />
                  )}
                </div> */}
              </div>
            </div>

            <div className="input_tab_box mb_30">
              <h4 className="range_sl_title" id="patella">
                {translation.Patella}
              </h4>
              <div className="row">
                <div className="col-md-6 col-sm-6 mb_10">
                  <RadioSwitch
                    labelLeft="Negative"
                    labelRight="Positive"
                    idLeft="apprL"
                    idRight="apprR"
                    name="apprR"
                    title={translation.Apprehension_R}
                    styleClass=""
                    value={apprR}
                    onChange={handleApprR}
                    placeholder="select"
                  />
                  {/* {apprL === "1" && (
                    <FullDropdown
                      data={[
                        { value: "1", label: "Medial" },
                        { value: "2", label: "Lateral" },
                        { value: "3", label: "Both" }
                      ]}
                      styleClass=""
                      value={apprLdrop}
                      onChange={handleapprLdrop}
                    />
                  )} */}
                </div>
                <div className="col-md-6 col-sm-6 mb_10">
                  <RadioSwitch
                    labelLeft="Negative"
                    labelRight="Positive"
                    idLeft="apprrL"
                    idRight="apprrR"
                    name="apprL"
                    title={translation.Apprehension_L}
                    styleClass=""
                    value={apprL}
                    onChange={handleApprL}
                    placeholder="select"
                  />

                  {/* {apprR === "1" && (
                    <FullDropdown
                      data={[
                        { value: "1", label: "Medial" },
                        { value: "2", label: "Lateral" },
                        { value: "3", label: "Both" }
                      ]}
                      styleClass=""
                      value={apprRdrop}
                      onChange={handleapprRdrop}
                    />
                  )} */}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-sm-6 mb_10">
                  <KneeScoreRangeSlider
                    label={"Patellar glide test (Right)"}
                    classes=""
                    minnum="1"
                    maxnum="4"
                    sliderData={[
                      { score: "1", label: "<1 quadrant" },
                      { score: "2", label: "2 quadrants" },
                      { score: "3", label: "3 quadrants" },
                      { score: "4", label: "4 quadrants" },
                    ]}
                    value={patellarR}
                    onChange={handlePatellarR}
                  />
                </div>
                <div className="col-md-6 col-sm-6 mb_10">
                  <KneeScoreRangeSlider
                    label={"Patellar glide test (Left)"}
                    classes=""
                    minnum="1"
                    maxnum="4"
                    sliderData={[
                      { score: "1", label: "<1 quadrant" },
                      { score: "2", label: "2 quadrants" },
                      { score: "3", label: "3 quadrants" },
                      { score: "4", label: "4 quadrants" },
                    ]}
                    value={patellarL}
                    onChange={handlePatellarL}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-sm-6 mb_10">
                  <KneeScoreRangeSlider
                    label={translation.Jsign_R}
                    classes=""
                    minnum="1"
                    maxnum="4"
                    sliderData={[
                      { score: "1", label: "Grade 1" },
                      { score: "2", label: "Grade 2" },
                      { score: "3", label: "Grade 3" },
                      { score: "4", label: "Grade 4" },
                    ]}
                    value={signR}
                    onChange={handleSignR}
                  />
                </div>
                <div className="col-md-6 col-sm-6 mb_10">
                  <KneeScoreRangeSlider
                    label={translation.Jsign_L}
                    classes=""
                    minnum="1"
                    maxnum="4"
                    sliderData={[
                      { score: "1", label: "Grade 1" },
                      { score: "2", label: "Grade 2" },
                      { score: "3", label: "Grade 3" },
                      { score: "4", label: "Grade 4" },
                    ]}
                    value={signL}
                    onChange={handleSignL}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 col-sm-6 mb_10">
                  <RadioSwitch
                    labelLeft="Negative"
                    labelRight="Positive"
                    idLeft="patellaL"
                    idRight="patellaR"
                    name="patellaR"
                    title={translation.Patella_grind_R}
                    styleClass=""
                    value={patellaR}
                    onChange={handlePatellaR}
                    placeholder="select"
                  />
                </div>
                <div className="col-md-6 col-sm-6 mb_10">
                  <RadioSwitch
                    labelLeft="Negative"
                    labelRight="Positive"
                    idLeft="patellarL"
                    idRight="patellarR"
                    name="patellaL"
                    title={translation.Patella_grind_L}
                    styleClass=""
                    value={patellaL}
                    onChange={handlePatellaL}
                    placeholder="select"
                  />
                </div>
              </div>
            </div>
            <div className="input_tab_box mb_30">
              <h4 className="range_sl_title" id="ExtensorMechanism">
                Extensor Mechanism
              </h4>
              <div className="row">
                <div className="col-md-6 col-sm-6 mb_10">
                  <RadioSwitch
                    labelLeft="Negative"
                    labelRight="Positive"
                    idLeft="legL"
                    idRight="legR"
                    name="legR"
                    title={"Active straight leg raise test (Right)"}
                    styleClass=""
                    value={legR}
                    onChange={handleLegR}
                    placeholder="select"
                  />
                </div>
                <div className="col-md-6 col-sm-6 mb_10">
                  <RadioSwitch
                    labelLeft="Negative"
                    labelRight="Positive"
                    idLeft="legrL"
                    idRight="legrR"
                    name="legL"
                    title={"Active straight leg raise test (Left)"}
                    styleClass=""
                    value={legL}
                    onChange={handleLegL}
                    placeholder="select"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 col-sm-6 mb_10">
                  <KneeDegree100
                    title={translation.Isometric_knee_strength_R}
                    //  onChangeR={LanatomicR}
                    //  onChangeL={LanatomicL}

                    labelR={translation.Active}
                    labelL={translation.Passive}
                    nameR={translation.L_Anatomic_Right}
                    nameL={translation.L_Anatomic_Left}
                    valueR={strengthR}
                    unit="Kg"
                    onChangeR={handleStrengthR}
                  />
                </div>
                <div className="col-md-6 col-sm-6 mb_10">
                  <KneeDegree100
                    title={translation.Isometric_knee_strength_L}
                    //  onChangeR={LanatomicR}
                    //  onChangeL={LanatomicL}
                    labelR={translation.Active}
                    labelL={translation.Passive}
                    nameR={translation.L_Anatomic_Right}
                    nameL={translation.L_Anatomic_Left}
                    valueR={strengthL}
                    unit="Kg"
                    onChangeR={handleStrengthL}
                  />
                </div>
              </div>
            </div>
            <div className="input_tab_box mb_30" id="others">
              <h4 className="range_sl_title" id="ExtensorMechanism">
                Other Tests
              </h4>
              <div className="row">
                <div className="col-md-12 col-sm-12 mb_10">
                  <InputField
                    value={others}
                    type="textarea"
                    rows="4"
                    cols="20"
                    onChange={othersText}
                    placeholder={"Specify"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mb_30 button_box">
          <div className="col-md-6 col-sm-6">
            {doctorEnable !== "true" && (
              <Link
                to={"/knee-feedback/" + rId + `?doctor=${dId}&patient=${pId}&is_doctor_form=true`}
                className="assm_back_btn"
              >
                <img src={backIcon} alt="" />
                <span>{translation.back_button}</span>
              </Link>
            )}
          </div>
          <div className="col-md-6 col-sm-6 text-end">
            <button
              className="next_btn btn_fill"
              onClick={() => handleSubmit()}
            >
              {translation.submit}

              <span>
                <img src={nextIcon} alt="" />
                <img src={nextWhiteIcon} alt="" />
              </span>
            </button>
          </div>
        </div>
      </div>
      {/* < SubmitModel
     showModal={displayConfirmationModal}
     
     /> */}
      <Promsschudle
     openModal={displayConfirmationModal}
     setDisplayConfirmationModal={setDisplayConfirmationModal}
     type={"5"}
     patientuserID={pId}
     />
</>
    )}
    </>
  );
}
