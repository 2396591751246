import React, { useMemo, useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import backIcon from "../../../../../images/back.png";
import nextWhiteIcon from "../../../../../images/next-white.png";
import nextIcon from "../../../../../images/next.png";
import LoadingSpinner from "../../../../custom/loader/LoadingSpinner";
import KneeScoreRangeSlider from "../../../../custom/knee-score-range-slider/KneeScoreRangeSlider";
import RangeSlider from "../../../../custom/range-slider/RangeSlider";
import Dropdown from "../../../../custom/dropdown/Dropdown";
import RadioSwitch from "../../../../custom/radio-switch/RadioSwitch";
import { useDispatch, useSelector } from "react-redux";
import {
  updatePediaScores,
  updatePediaFunctionFieldScore,
  updatePediaFunctionScore,
  updatePediaPainFieldScore,
  updatePediaPainData,
  updatePediaSymtomsScore,
  updatePediaQolScore,
  updatePediaSatisfactionScore,
  updatePediaTotalScores,
  updatePediaKneeNorwichInstaScore,
  updatePediaKneeFeedbackScores,
  updateAllData,
} from "../../../../../redux/actions/pediaKnee";
import { updateAdultKneeFeedback } from "../../../../../redux/actions/index";
import useAxios from "../../../../../axiosinstance";
import useTransition from "../../../../customHooks/translations";
import PediakneeTab from "../../../tab-section/PediakneeTab";
import { useSearchParams } from "react-router-dom";
import CustomToaster from "../../../../custom/toaster/CustomToaster";
import {
  LeftSide,
  LeftTrans,
  RightSide,
  RigthTrans,
} from "../../../../../utilities/RightLeftFormat";
import NameCustom from "../../../../custom/name/NameCustom";

function PediaKneeFunction() {
  const translation = useTransition();
  let lg = sessionStorage.getItem("language");
  const { rId } = useParams();
  const [searchParams] = useSearchParams();
  let doctorEnable = searchParams.get("d");
  const axiosinstance = useAxios();
  const [isLoading, setIsLoading] = useState(true);
  const [goUpStairsR, setGoUpStairsR] = useState("0");
  const [goUpStairsL, setGoUpStairsL] = useState("0");
  const [goDownStairsR, setGoDownStairsR] = useState("0");
  const [goDownStairsL, setGoDownStairsL] = useState("0");
  const [kneelFrontR, setKneelFrontR] = useState("0");
  const [kneelFrontL, setKneelFrontL] = useState("0");
  const [straighteningR, setStraighteningR] = useState("0");
  const [straighteningL, setStraighteningL] = useState("0");
  const [bendingKneeR, setBendingKneeR] = useState("0");
  const [bendingKneeL, setBendingKneeL] = useState("0");
  const [TwistDurPlayInMorningtR, setTwistDurPlayInMorningtR] = useState("0");
  const [TwistDurPlayInMorningtL, setTwistDurPlayInMorningtL] = useState("0");
  const [moveDayAfterR, setMoveDayAfterR] = useState("0");
  const [moveDayAfterL, setMoveDayAfterL] = useState("0");
  const [squatBasketballR, setSquatBasketballR] = useState("0");
  const [squatBasketballL, setSquatBasketballL] = useState("0");
  const [sitOnChairWithKneeBentR, setSitOnChairWithKneeBentR] = useState("0");
  const [sitOnChairWithKneeBentL, setSitOnChairWithKneeBentL] = useState("0");
  const [getUpFromChairR, setGetUpFromChairR] = useState("0");
  const [getUpFromChairL, setGetUpFromChairL] = useState("0");
  const [bendingToFloorR, setBendingToFloorR] = useState("0");
  const [bendingToFloorL, setBendingToFloorL] = useState("0");
  const [runDurPlayR, setRunDurPlayR] = useState("0");
  const [runDurPlayL, setRunDurPlayL] = useState("0");
  const [jumpDurPlayR, setJumpDurPlayR] = useState("0");
  const [jumpDurPlayL, setJumpDurPlayL] = useState("0");
  const [startAndStopMoveR, setStartAndStopMoveR] = useState("0");
  const [startAndStopMoveL, setStartAndStopMoveL] = useState("0");
  const [twistDurPlayR, setTwistDurPlayR] = useState("0");
  const [twistDurPlayL, setTwistDurPlayL] = useState("0");
  const [keepBalWalkR, setKeepBalWalkR] = useState("0");
  const [keepBalWalkL, setKeepBalWalkL] = useState("0");
  const [playSportsR, setPlaySportsR] = useState("0");
  const [playSportsL, setPlaySportsL] = useState("0");
  const [getOutCarR, setGetOutCarR] = useState("0");
  const [getOutCarL, setGetOutCarL] = useState("0");
  const [getOutBedR, setGetOutBedR] = useState("0");
  const [getOutBedL, setGetOutBedL] = useState("0");
  const [changePositionLyingR, setChangePositionLyingR] = useState("0");
  const [changePositionLyingL, setChangePositionLyingL] = useState("0");
  const [gettingBathR, setGettingBathR] = useState("0");
  const [gettingBathL, setGettingBathL] = useState("0");
  const [carryHeavyBagsR, setCarryHeavyBagsR] = useState("0");
  const [carryHeavyBagsL, setCarryHeavyBagsL] = useState("0");
  const [doLightChoresR, setDoLightChoresR] = useState("0");
  const [doLightChoresL, setDoLightChoresL] = useState("0");
  const [gettingToSchoolR, setGettingToSchoolR] = useState("0");
  const [gettingToSchoolL, setGettingToSchoolL] = useState("0");
  const [doThingsFriendsR, setDoThingsFriendsR] = useState("0");
  const [doThingsFriendsL, setDoThingsFriendsL] = useState("0");
  const [patellarInstability, setPatellarInstability] = useState("0");
  const [patellarInstabilityLeft, setPatellarInstabilityLeft] = useState("0");
  const [howKneeWorkR, setHowKneeWorkR] = useState("0");
  const [howKneeWorkL, setHowKneeWorkL] = useState("0");
  const [howKneeWork_beforeR, setHowKneeWorkbeforeR] = useState("0");
  const [howKneeWork_beforeL, setHowKneeWorkbeforeL] = useState("0");
  const [initialAssesment, setInitialAssesment] = useState(0);

  const navigate = useNavigate();
  const evlPediaPain = useSelector((state) => state.pediaKnee.evlPediaPain);
  const [chk, setChk] = useState(false);
  const [updateTotal, setUpdateTotal] = useState(false);

  const save = () => {
    axiosinstance.current
      .get(`extapp/forms/all_forms?resource_id=${rId}&type=20`)
      .then((res) => {
        setInitialAssesment(res.data.data2[0].initial_assessment);
        dispatch(updatePediaScores(res.data.data2[0].evlPediaPain));
        dispatch(updatePediaPainData(res.data.data2[0].painScore));
        dispatch(updatePediaSymtomsScore(res.data.data2[0].symtomsScore));
        dispatch(updatePediaFunctionScore(res.data.data2[0].functionScore));
        dispatch(updatePediaQolScore(res.data.data2[0].qolScore));
        dispatch(
          updatePediaSatisfactionScore(res.data.data2[0].satisfactionScore)
        );
        dispatch(
          updatePediaKneeNorwichInstaScore(res.data.data2[0].norwichInstability)
        );
        dispatch(updateAdultKneeFeedback(res.data.data2[0].doctorFeedback));
        // dispatch(updatePediaTotalScores(res.data.data2[0].TotalScores));
        setChk(true);
      })
      .catch((err) => {
        navigate("/un-authorized-access");
      });
  };
  useEffect(() => {
    save();
  }, []);

 
  const functionScore = useSelector(
    (state) => state.pediaKnee.functionScore[0]
  );
  const json = useSelector((state) => state.pediaKnee);

  useEffect(() => {
    setGoUpStairsR(functionScore.goUpStairsR);
    setGoUpStairsL(functionScore.goUpStairsL);
    setGoDownStairsR(functionScore.goDownStairsR);
    setGoDownStairsL(functionScore.goDownStairsL);
    setKneelFrontR(functionScore.kneelFrontR);
    setKneelFrontL(functionScore.kneelFrontL);
    setStraighteningR(functionScore.straighteningR);
    setStraighteningL(functionScore.straighteningL);
    setBendingKneeR(functionScore.bendingKneeR);
    setBendingKneeL(functionScore.bendingKneeL);
    setTwistDurPlayInMorningtR(functionScore.TwistDurPlayInMorningtR);
    setTwistDurPlayInMorningtL(functionScore.TwistDurPlayInMorningtL);
    setMoveDayAfterR(functionScore.moveDayAfterR);
    setMoveDayAfterL(functionScore.moveDayAfterL);
    setSquatBasketballR(functionScore.squatBasketballR);
    setSquatBasketballL(functionScore.squatBasketballL);
    setSitOnChairWithKneeBentR(functionScore.sitOnChairWithKneeBentR);
    setSitOnChairWithKneeBentL(functionScore.sitOnChairWithKneeBentL);
    setGetUpFromChairR(functionScore.getUpFromChairR);
    setGetUpFromChairL(functionScore.getUpFromChairL);
    setBendingToFloorR(functionScore.bendingToFloorR);
    setBendingToFloorL(functionScore.bendingToFloorL);
    setRunDurPlayR(functionScore.runDurPlayR);
    setRunDurPlayL(functionScore.runDurPlayL);
    setJumpDurPlayR(functionScore.jumpDurPlayR);
    setJumpDurPlayL(functionScore.jumpDurPlayL);
    setStartAndStopMoveR(functionScore.startAndStopMoveR);
    setStartAndStopMoveL(functionScore.startAndStopMoveL);
    setTwistDurPlayR(functionScore.twistDurPlayR);
    setTwistDurPlayL(functionScore.twistDurPlayL);
    setKeepBalWalkR(functionScore.keepBalWalkR);
    setKeepBalWalkL(functionScore.keepBalWalkL);
    setPlaySportsR(functionScore.playSportsR);
    setPlaySportsL(functionScore.playSportsL);
    setGetOutCarR(functionScore.getOutCarR);
    setGetOutCarL(functionScore.getOutCarL);
    setGetOutBedR(functionScore.getOutBedR);
    setGetOutBedL(functionScore.getOutBedL);
    setChangePositionLyingR(functionScore.changePositionLyingR);
    setChangePositionLyingL(functionScore.changePositionLyingL);
    setGettingBathR(functionScore.gettingBathR);
    setGettingBathL(functionScore.gettingBathL);
    setCarryHeavyBagsR(functionScore.carryHeavyBagsR);
    setCarryHeavyBagsL(functionScore.carryHeavyBagsL);
    setDoLightChoresR(functionScore.doLightChoresR);
    setDoLightChoresL(functionScore.doLightChoresL);
    setGettingToSchoolR(functionScore.gettingToSchoolR);
    setGettingToSchoolL(functionScore.gettingToSchoolL);
    setDoThingsFriendsR(functionScore.doThingsFriendsR);
    setDoThingsFriendsL(functionScore.doThingsFriendsL);
    setHowKneeWorkR(functionScore.howKneeWorkR);
    setHowKneeWorkL(functionScore.howKneeWorkL);
    setHowKneeWorkbeforeR(functionScore.howKneeWork_beforeR);
    setHowKneeWorkbeforeL(functionScore.howKneeWork_beforeL);
    setPatellarInstability(functionScore.patellarInstability);
    setPatellarInstabilityLeft(functionScore.patellarInstabilityLeft);
  }, [functionScore]);

  const updateDate = () => {
    axiosinstance.current
      .patch(
        `extapp/forms/update_all_form_type?resource_id=${rId}&action=${doctorEnable === "true" ? "1" : "0"}`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res);
      });
  };
  useEffect(() => {
    if (chk) {
      updateDate();
    }
  }, [functionScore, updateTotal]);

  const scaleLabels = [
    { score: "0", label: translation.no_difficulty },
    { score: "1", label: translation.a_little },
    { score: "2", label: translation.some },
    { score: "3", label: translation.a_lot },
    { score: "4", label: translation.extreme_difficult },
  ];

  const handleGoUpStairsR = (e) => {
    dispatch(
      updatePediaFunctionFieldScore({
        key: "goUpStairsR",
        value: e,
      })
    );
    setGoUpStairsR(e);
  };
  const handleGoUpStairsL = (e) => {
    dispatch(
      updatePediaFunctionFieldScore({
        key: "goUpStairsL",
        value: e,
      })
    );
    setGoUpStairsL(e);
  };

  const handlegoDownStairsR = (e) => {
    dispatch(
      updatePediaFunctionFieldScore({
        key: "goDownStairsR",
        value: e,
      })
    );
    setGoDownStairsR(e);
  };
  const handlegoDownStairsL = (e) => {
    dispatch(
      updatePediaFunctionFieldScore({
        key: "goDownStairsL",
        value: e,
      })
    );
    setGoDownStairsL(e);
  };

  const handleKneelFrontR = (e) => {
    dispatch(
      updatePediaFunctionFieldScore({
        key: "kneelFrontR",
        value: e,
      })
    );
    setKneelFrontR(e);
  };
  const handleKneelFrontL = (e) => {
    dispatch(
      updatePediaFunctionFieldScore({
        key: "kneelFrontL",
        value: e,
      })
    );
    setKneelFrontL(e);
  };

  const handleStraighteningR = (e) => {
    dispatch(
      updatePediaFunctionFieldScore({
        key: "straighteningR",
        value: e,
      })
    );
    setStraighteningR(e);
  };
  const handleStraighteningL = (e) => {
    dispatch(
      updatePediaFunctionFieldScore({
        key: "straighteningL",
        value: e,
      })
    );
    setStraighteningL(e);
  };

  const handleBendingKneeR = (e) => {
    dispatch(
      updatePediaFunctionFieldScore({
        key: "bendingKneeR",
        value: e,
      })
    );
    setBendingKneeR(e);
  };
  const handleBendingKneeL = (e) => {
    dispatch(
      updatePediaFunctionFieldScore({
        key: "bendingKneeL",
        value: e,
      })
    );
    setBendingKneeL(e);
  };

  const handleTwistDurPlayInMorningtR = (e) => {
    dispatch(
      updatePediaFunctionFieldScore({
        key: "TwistDurPlayInMorningtR",
        value: e,
      })
    );
    setTwistDurPlayInMorningtR(e);
  };
  const handleTwistDurPlayInMorningtL = (e) => {
    dispatch(
      updatePediaFunctionFieldScore({
        key: "TwistDurPlayInMorningtL",
        value: e,
      })
    );
    setTwistDurPlayInMorningtL(e);
  };

  const handleMoveDayAfterR = (e) => {
    dispatch(
      updatePediaFunctionFieldScore({
        key: "moveDayAfterR",
        value: e,
      })
    );
    setMoveDayAfterR(e);
  };
  const handleMoveDayAfterL = (e) => {
    dispatch(
      updatePediaFunctionFieldScore({
        key: "moveDayAfterL",
        value: e,
      })
    );
    setMoveDayAfterL(e);
  };
  const handleSquatBasketballR = (e) => {
    dispatch(
      updatePediaFunctionFieldScore({
        key: "squatBasketballR",
        value: e,
      })
    );
    setSquatBasketballR(e);
  };
  const handleSquatBasketballL = (e) => {
    dispatch(
      updatePediaFunctionFieldScore({
        key: "squatBasketballL",
        value: e,
      })
    );
    setSquatBasketballL(e);
  };

  const handleSitOnChairWithKneeBentR = (e) => {
    dispatch(
      updatePediaFunctionFieldScore({
        key: "sitOnChairWithKneeBentR",
        value: e,
      })
    );
    setSitOnChairWithKneeBentR(e);
  };
  const handleSitOnChairWithKneeBentL = (e) => {
    dispatch(
      updatePediaFunctionFieldScore({
        key: "sitOnChairWithKneeBentL",
        value: e,
      })
    );
    setSitOnChairWithKneeBentL(e);
  };

  const handleGetUpFromChairR = (e) => {
    dispatch(
      updatePediaFunctionFieldScore({
        key: "getUpFromChairR",
        value: e,
      })
    );
    setGetUpFromChairR(e);
  };
  const handleGetUpFromChairL = (e) => {
    dispatch(
      updatePediaFunctionFieldScore({
        key: "getUpFromChairL",
        value: e,
      })
    );
    setGetUpFromChairL(e);
  };

  const handleBendingToFloorR = (e) => {
    dispatch(
      updatePediaFunctionFieldScore({
        key: "bendingToFloorR",
        value: e,
      })
    );
    setBendingToFloorR(e);
  };
  const handleBendingToFloorL = (e) => {
    dispatch(
      updatePediaFunctionFieldScore({
        key: "bendingToFloorL",
        value: e,
      })
    );
    setBendingToFloorL(e);
  };

  const handleRunDurPlayR = (e) => {
    dispatch(
      updatePediaFunctionFieldScore({
        key: "runDurPlayR",
        value: e,
      })
    );
    setRunDurPlayR(e);
  };
  const handleRunDurPlayL = (e) => {
    dispatch(
      updatePediaFunctionFieldScore({
        key: "runDurPlayL",
        value: e,
      })
    );
    setRunDurPlayL(e);
  };

  const handleJumpDurPlayR = (e) => {
    dispatch(
      updatePediaFunctionFieldScore({
        key: "jumpDurPlayR",
        value: e,
      })
    );
    setJumpDurPlayR(e);
  };
  const handleJumpDurPlayL = (e) => {
    dispatch(
      updatePediaFunctionFieldScore({
        key: "jumpDurPlayL",
        value: e,
      })
    );
    setJumpDurPlayL(e);
  };
  const handleStartAndStopMoveR = (e) => {
    dispatch(
      updatePediaFunctionFieldScore({
        key: "startAndStopMoveR",
        value: e,
      })
    );
    setStartAndStopMoveR(e);
  };
  const handleStartAndStopMoveL = (e) => {
    dispatch(
      updatePediaFunctionFieldScore({
        key: "startAndStopMoveL",
        value: e,
      })
    );
    setStartAndStopMoveL(e);
  };
  const handleTwistDurPlayR = (e) => {
    dispatch(
      updatePediaFunctionFieldScore({
        key: "twistDurPlayR",
        value: e,
      })
    );
    setTwistDurPlayR(e);
  };
  const handleTwistDurPlayL = (e) => {
    dispatch(
      updatePediaFunctionFieldScore({
        key: "twistDurPlayL",
        value: e,
      })
    );
    setTwistDurPlayL(e);
  };

  const handleKeepBalWalkR = (e) => {
    dispatch(
      updatePediaFunctionFieldScore({
        key: "keepBalWalkR",
        value: e,
      })
    );
    setKeepBalWalkR(e);
  };
  const handleKeepBalWalkL = (e) => {
    dispatch(
      updatePediaFunctionFieldScore({
        key: "keepBalWalkL",
        value: e,
      })
    );
    setKeepBalWalkL(e);
  };
  const handlePlaySportsR = (e) => {
    dispatch(
      updatePediaFunctionFieldScore({
        key: "playSportsR",
        value: e,
      })
    );
    setPlaySportsR(e);
  };
  const handlePlaySportsL = (e) => {
    dispatch(
      updatePediaFunctionFieldScore({
        key: "playSportsL",
        value: e,
      })
    );
    setPlaySportsL(e);
  };

  const handleGetOutCarR = (e) => {
    dispatch(
      updatePediaFunctionFieldScore({
        key: "getOutCarR",
        value: e,
      })
    );
    setGetOutCarR(e);
  };
  const handleGetOutCarL = (e) => {
    dispatch(
      updatePediaFunctionFieldScore({
        key: "getOutCarL",
        value: e,
      })
    );
    setGetOutCarL(e);
  };

  const handleGetOutBedR = (e) => {
    dispatch(
      updatePediaFunctionFieldScore({
        key: "getOutBedR",
        value: e,
      })
    );
    setGetOutBedR(e);
  };
  const handleGetOutBedL = (e) => {
    dispatch(
      updatePediaFunctionFieldScore({
        key: "getOutBedL",
        value: e,
      })
    );
    setGetOutBedL(e);
  };

  const handleChangePositionLyingR = (e) => {
    dispatch(
      updatePediaFunctionFieldScore({
        key: "changePositionLyingR",
        value: e,
      })
    );
    setChangePositionLyingR(e);
  };
  const handleChangePositionLyingL = (e) => {
    dispatch(
      updatePediaFunctionFieldScore({
        key: "changePositionLyingL",
        value: e,
      })
    );
    setChangePositionLyingL(e);
  };

  const handleGettingBathR = (e) => {
    dispatch(
      updatePediaFunctionFieldScore({
        key: "gettingBathR",
        value: e,
      })
    );
    setGettingBathR(e);
  };
  const handleGettingBathL = (e) => {
    dispatch(
      updatePediaFunctionFieldScore({
        key: "gettingBathL",
        value: e,
      })
    );
    setGettingBathL(e);
  };

  const handleCarryHeavyBagsR = (e) => {
    dispatch(
      updatePediaFunctionFieldScore({
        key: "carryHeavyBagsR",
        value: e,
      })
    );
    setCarryHeavyBagsR(e);
  };
  const handleCarryHeavyBagsL = (e) => {
    dispatch(
      updatePediaFunctionFieldScore({
        key: "carryHeavyBagsL",
        value: e,
      })
    );
    setCarryHeavyBagsL(e);
  };

  const handleDoLightChoresR = (e) => {
    dispatch(
      updatePediaFunctionFieldScore({
        key: "doLightChoresR",
        value: e,
      })
    );
    setDoLightChoresR(e);
  };
  const handleDoLightChoresL = (e) => {
    dispatch(
      updatePediaFunctionFieldScore({
        key: "doLightChoresL",
        value: e,
      })
    );
    setDoLightChoresL(e);
  };

  const handleGettingToSchoolR = (e) => {
    dispatch(
      updatePediaFunctionFieldScore({
        key: "gettingToSchoolR",
        value: e,
      })
    );
    setGettingToSchoolR(e);
  };
  const handleGettingToSchoolL = (e) => {
    dispatch(
      updatePediaFunctionFieldScore({
        key: "gettingToSchoolL",
        value: e,
      })
    );
    setGettingToSchoolL(e);
  };

  const handleDoThingsFriendsR = (e) => {
    dispatch(
      updatePediaFunctionFieldScore({
        key: "doThingsFriendsR",
        value: e,
      })
    );
    setDoThingsFriendsR(e);
  };
  const handleDoThingsFriendsL = (e) => {
    dispatch(
      updatePediaFunctionFieldScore({
        key: "doThingsFriendsL",
        value: e,
      })
    );
    setDoThingsFriendsL(e);
  };

  const handleHowKneeWorkR = (e) => {
    dispatch(
      updatePediaFunctionFieldScore({
        key: "howKneeWorkR",
        value: e,
      })
    );
    setHowKneeWorkR(e);
  };
  const handleHowKneeWorkL = (e) => {
    dispatch(
      updatePediaFunctionFieldScore({
        key: "howKneeWorkL",
        value: e,
      })
    );
    setHowKneeWorkL(e);
  };

  const handleHowKneeWork_beforeR = (e) => {
    dispatch(
      updatePediaFunctionFieldScore({
        key: "howKneeWork_beforeR",
        value: e,
      })
    );
    setHowKneeWorkbeforeR(e);
  };
  const handleHowKneeWork_beforeL = (e) => {
    dispatch(
      updatePediaFunctionFieldScore({
        key: "howKneeWork_beforeL",
        value: e,
      })
    );
    setHowKneeWorkbeforeL(e);
  };

  const handlePatellarInstability = (e) => {
    setPatellarInstability(e);
    dispatch(
      updatePediaFunctionFieldScore({
        key: "patellarInstability",
        value: e,
      })
    );
  };
  const handlePatellarInstabilityLeft = (e) => {
    setPatellarInstabilityLeft(e);
    dispatch(
      updatePediaFunctionFieldScore({
        key: "patellarInstabilityLeft",
        value: e,
      })
    );
  };

  const dispatch = useDispatch();

  const handleEvolutedPediaPain = (e) => {
    dispatch(updatePediaScores(e));
  };

  const continueRedirect = (e) => {
    navigate("/pedia-knee-quality-of-life/" + rId);

    if (
      functionScore.patellarInstability === "1" ||
      functionScore.patellarInstabilityLeft === "1"
    ) {
      navigate("/patellar-instability-pedia/" + rId);
    } else {
      navigate("/pedia-knee-quality-of-life/" + rId);
    }
    dispatch(
      updatePediaFunctionScore([
        {
          goUpStairsR: goUpStairsR,
          goUpStairsL: goUpStairsL,
          goDownStairsR: goDownStairsR,
          goDownStairsL: goDownStairsL,
          kneelFrontR: kneelFrontR,
          kneelFrontL: kneelFrontL,
          straighteningR: straighteningR,
          straighteningL: straighteningL,
          bendingKneeR: bendingKneeR,
          bendingKneeL: bendingKneeL,
          TwistDurPlayInMorningtR: TwistDurPlayInMorningtR,
          TwistDurPlayInMorningtL: TwistDurPlayInMorningtL,
          moveDayAfterR: moveDayAfterR,
          moveDayAfterL: moveDayAfterL,
          squatBasketballR: squatBasketballR,
          squatBasketballL: squatBasketballL,
          sitOnChairWithKneeBentR: sitOnChairWithKneeBentR,
          sitOnChairWithKneeBentL: sitOnChairWithKneeBentL,
          getUpFromChairR: getUpFromChairR,
          getUpFromChairL: getUpFromChairL,
          bendingToFloorR: bendingToFloorR,
          bendingToFloorL: bendingToFloorL,
          runDurPlayR: runDurPlayR,
          runDurPlayL: runDurPlayL,
          jumpDurPlayR: jumpDurPlayR,
          jumpDurPlayL: jumpDurPlayL,
          startAndStopMoveR: startAndStopMoveR,
          startAndStopMoveL: startAndStopMoveL,
          twistDurPlayR: twistDurPlayR,
          twistDurPlayL: twistDurPlayL,
          keepBalWalkR: keepBalWalkR,
          keepBalWalkL: keepBalWalkL,
          playSportsR: playSportsR,
          playSportsL: playSportsL,
          getOutCarR: getOutCarR,
          getOutCarL: getOutCarL,
          getOutBedR: getOutBedR,
          getOutBedL: getOutBedL,
          changePositionLyingR: changePositionLyingR,
          changePositionLyingL: changePositionLyingL,
          gettingBathR: gettingBathR,
          gettingBathL: gettingBathL,
          carryHeavyBagsR: carryHeavyBagsR,
          carryHeavyBagsL: carryHeavyBagsL,
          doLightChoresR: doLightChoresR,
          doLightChoresL: doLightChoresL,
          gettingToSchoolR: gettingToSchoolR,
          gettingToSchoolL: gettingToSchoolL,
          doThingsFriendsR: doThingsFriendsR,
          doThingsFriendsL: doThingsFriendsL,
          howKneeWorkR: howKneeWorkR,
          howKneeWorkL: howKneeWorkL,
          howKneeWork_beforeR: howKneeWork_beforeR,
          howKneeWork_beforeL: howKneeWork_beforeL,
          patellarInstability: patellarInstability,
        },
      ])
    );
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
  let akpt = searchParams.get("akpt");

  const [msg, setMsg] = useState("");
  const [msgType, setmsgType] = useState("");
  const handleSubmit = () => {
    updateAllData(json, dispatch);
    setUpdateTotal(!updateTotal);
    setMsg(translation.update);
    setmsgType("success");
     setTimeout(() => {
     let view_circular=  sessionStorage.getItem("view_circular")
      // window.location.href = process.env.REACT_APP_DOCTOR_URL + "patient-view/" + akpt
      // window.location.href = process.env.REACT_APP_DOCTOR_URL +`view-management-plan/${akpt}/${window.btoa(
      //   rId
      // )}/?org=${window.btoa(sessionStorage.getItem("orgid")||'')}&formType=${
      //   window.btoa('20') || ""
      // }&view_circular=${view_circular==='false'?'false':'true'}`
       window.location.href = process.env.REACT_APP_DOCTOR_URL +`patient-details/${akpt}?formid=20`
      setMsg('')
      setmsgType('success')
    }, 2000);
  };
  const handleNext = () => {
    if (
      functionScore.patellarInstability === "1" ||
      functionScore.patellarInstabilityLeft === "1"
    ) {
      navigate(`/patellar-instability-pedia/${rId}?d=true&akpt=${akpt}`);

    } else {
      navigate(`/pedia-knee-quality-of-life/${rId}?d=true&akpt=${akpt}`);

    }


  }

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="pain_screen mb_100">
          <div class="d-flex justify-content-end">
            <NameCustom />
          </div>
          <div className="panel">
            <div className="panel-body p_25">
              {doctorEnable === "true" && <PediakneeTab rId={rId} />}
              <CustomToaster msg={msg} msgType={msgType} />

              <h4 className="ph_title f_40">{translation.patient_pedia_knee_function}</h4>

              <div className="function_formtop">
                <div className="row">
                  <div className="col-md-6 col-sm-6 function_head">
                    <label>
                      {translation.patient_pedia_knee_function_instruction}
                    </label>{" "}
                    <br />
                    <span dir={lg === "ara" ? "rtl" : " "}>
                      (<strong>0</strong> ={" "}
                      {translation.patient_pedia_knee_function_instruction_0},{" "}
                      <strong>1</strong> =
                      {translation.patient_pedia_knee_function_instruction_1},
                      <strong> 2</strong> ={" "}
                      {translation.patient_pedia_knee_function_instruction_2},{" "}
                      <strong>3</strong> ={" "}
                      {translation.patient_pedia_knee_function_instruction_3},{" "}
                      <strong>4</strong> ={" "}
                      {translation.patient_pedia_knee_function_instruction_4})
                    </span>
                  </div>
                  <div className="col-md-6 col-sm-6 function_note">
                    <p>{translation.patient_pedia_knee_function_note}</p>
                  </div>
                </div>
                <div className="row">
                  {evlPediaPain === "1" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <strong className="d-inline-block mb_15">
                        {/* {translation.Right_Knee}: */}
                      </strong>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_pedia_knee_function_q1 +
                          RigthTrans(evlPediaPain, translation)
                        }
                        classes=""
                        // sliderData={scaleLabels}
                        value={goUpStairsR}
                        onChange={handleGoUpStairsR}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_pedia_knee_function_q1_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_pedia_knee_function_q1_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_pedia_knee_function_q1_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_pedia_knee_function_q1_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_pedia_knee_function_q1_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : null}
                  {evlPediaPain === "2" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <strong className="d-inline-block mb_15">
                        {/* {translation.Left_Knee}: */}
                      </strong>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_pedia_knee_function_q1 +
                          LeftTrans(evlPediaPain, translation)
                        }
                        classes=""
                        // sliderData={scaleLabels}
                        value={goUpStairsL}
                        onChange={handleGoUpStairsL}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_pedia_knee_function_q1_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_pedia_knee_function_q1_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_pedia_knee_function_q1_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_pedia_knee_function_q1_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_pedia_knee_function_q1_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : null}
                </div>

                <div className="row">
                  {evlPediaPain === "1" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_pedia_knee_function_q2 +
                          RigthTrans(evlPediaPain, translation)
                        }
                        classes=""
                        // sliderData={scaleLabels}
                        value={goDownStairsR}
                        onChange={handlegoDownStairsR}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_pedia_knee_function_q2_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_pedia_knee_function_q2_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_pedia_knee_function_q2_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_pedia_knee_function_q2_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_pedia_knee_function_q2_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : null}

                  {evlPediaPain === "2" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_pedia_knee_function_q2 +
                          LeftTrans(evlPediaPain, translation)
                        }
                        classes=""
                        // sliderData={scaleLabels}
                        value={goDownStairsL}
                        onChange={handlegoDownStairsL}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_pedia_knee_function_q2_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_pedia_knee_function_q2_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_pedia_knee_function_q2_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_pedia_knee_function_q2_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_pedia_knee_function_q2_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : null}
                </div>
                <div className="row">
                  {evlPediaPain === "1" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_pedia_knee_function_q3 +
                          RigthTrans(evlPediaPain, translation)
                        }
                        classes=""
                        // sliderData={scaleLabels}
                        value={kneelFrontR}
                        onChange={handleKneelFrontR}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_pedia_knee_function_q3_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_pedia_knee_function_q3_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_pedia_knee_function_q3_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_pedia_knee_function_q3_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_pedia_knee_function_q3_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : null}

                  {evlPediaPain === "2" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_pedia_knee_function_q3 +
                          LeftTrans(evlPediaPain, translation)
                        }
                        classes=""
                        // sliderData={scaleLabels}
                        value={kneelFrontL}
                        onChange={handleKneelFrontL}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_pedia_knee_function_q3_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_pedia_knee_function_q3_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_pedia_knee_function_q3_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_pedia_knee_function_q3_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_pedia_knee_function_q3_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : null}
                </div>

                <div className="row">
                  {evlPediaPain === "1" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_pedia_knee_function_q4 +
                          RigthTrans(evlPediaPain, translation)
                        }
                        classes=""
                        // sliderData={scaleLabels}
                        value={straighteningR}
                        onChange={handleStraighteningR}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_pedia_knee_function_q4_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_pedia_knee_function_q4_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_pedia_knee_function_q4_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_pedia_knee_function_q4_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_pedia_knee_function_q4_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : null}

                  {evlPediaPain === "2" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_pedia_knee_function_q4 +
                          LeftTrans(evlPediaPain, translation)
                        }
                        classes=""
                        // sliderData={scaleLabels}
                        value={straighteningL}
                        onChange={handleStraighteningL}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_pedia_knee_function_q4_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_pedia_knee_function_q4_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_pedia_knee_function_q4_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_pedia_knee_function_q4_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_pedia_knee_function_q4_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : null}
                </div>
                <div className="row">
                  {evlPediaPain === "1" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_pedia_knee_function_q5 +
                          RigthTrans(evlPediaPain, translation)
                        }
                        classes=""
                        // sliderData={scaleLabels}
                        value={bendingKneeR}
                        onChange={handleBendingKneeR}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_pedia_knee_function_q5_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_pedia_knee_function_q5_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_pedia_knee_function_q5_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_pedia_knee_function_q5_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_pedia_knee_function_q5_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : null}

                  {evlPediaPain === "2" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_pedia_knee_function_q5 +
                          LeftTrans(evlPediaPain, translation)
                        }
                        classes=""
                        // sliderData={scaleLabels}
                        value={bendingKneeL}
                        onChange={handleBendingKneeL}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_pedia_knee_function_q5_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_pedia_knee_function_q5_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_pedia_knee_function_q5_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_pedia_knee_function_q5_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_pedia_knee_function_q5_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : null}
                </div>
                <div className="row">
                  {evlPediaPain === "1" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_pedia_knee_function_q6 +
                          RigthTrans(evlPediaPain, translation)
                        }
                        classes=""
                        // sliderData={scaleLabels}
                        value={TwistDurPlayInMorningtR}
                        onChange={handleTwistDurPlayInMorningtR}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_pedia_knee_function_q6_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_pedia_knee_function_q6_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_pedia_knee_function_q6_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_pedia_knee_function_q6_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_pedia_knee_function_q6_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : null}

                  {evlPediaPain === "2" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_pedia_knee_function_q6 +
                          LeftTrans(evlPediaPain, translation)
                        }
                        classes=""
                        // sliderData={scaleLabels}
                        value={TwistDurPlayInMorningtL}
                        onChange={handleTwistDurPlayInMorningtL}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_pedia_knee_function_q6_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_pedia_knee_function_q6_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_pedia_knee_function_q6_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_pedia_knee_function_q6_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_pedia_knee_function_q6_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : null}
                </div>
                <div className="row">
                  {evlPediaPain === "1" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_pedia_knee_function_q7 +
                          RigthTrans(evlPediaPain, translation)
                        }
                        classes=""
                        // sliderData={scaleLabels}
                        value={moveDayAfterR}
                        onChange={handleMoveDayAfterR}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_pedia_knee_function_q7_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_pedia_knee_function_q7_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_pedia_knee_function_q7_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_pedia_knee_function_q7_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_pedia_knee_function_q7_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : null}
                  {evlPediaPain === "2" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_pedia_knee_function_q7 +
                          LeftTrans(evlPediaPain, translation)
                        }
                        classes=""
                        // sliderData={scaleLabels}
                        value={moveDayAfterL}
                        onChange={handleMoveDayAfterL}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_pedia_knee_function_q7_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_pedia_knee_function_q7_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_pedia_knee_function_q7_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_pedia_knee_function_q7_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_pedia_knee_function_q7_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : null}
                </div>
                <div className="row">
                  {evlPediaPain === "1" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_pedia_knee_function_q8 +
                          RigthTrans(evlPediaPain, translation)
                        }
                        classes=""
                        // sliderData={scaleLabels}
                        value={squatBasketballR}
                        onChange={handleSquatBasketballR}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_pedia_knee_function_q8_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_pedia_knee_function_q8_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_pedia_knee_function_q8_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_pedia_knee_function_q8_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_pedia_knee_function_q8_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : null}
                  {evlPediaPain === "2" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_pedia_knee_function_q8 +
                          LeftTrans(evlPediaPain, translation)
                        }
                        classes=""
                        // sliderData={scaleLabels}
                        value={squatBasketballL}
                        onChange={handleSquatBasketballL}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_pedia_knee_function_q8_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_pedia_knee_function_q8_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_pedia_knee_function_q8_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_pedia_knee_function_q8_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_pedia_knee_function_q8_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : null}
                </div>
                <div className="row">
                  {evlPediaPain === "1" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_pedia_knee_function_q9 +
                          RigthTrans(evlPediaPain, translation)
                        }
                        classes=""
                        // sliderData={scaleLabels}
                        value={sitOnChairWithKneeBentR}
                        onChange={handleSitOnChairWithKneeBentR}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_pedia_knee_function_q9_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_pedia_knee_function_q9_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_pedia_knee_function_q9_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_pedia_knee_function_q9_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_pedia_knee_function_q9_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : null}
                  {evlPediaPain === "2" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_pedia_knee_function_q9 +
                          LeftTrans(evlPediaPain, translation)
                        }
                        classes=""
                        // sliderData={scaleLabels}
                        value={sitOnChairWithKneeBentL}
                        onChange={handleSitOnChairWithKneeBentL}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_pedia_knee_function_q9_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_pedia_knee_function_q9_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_pedia_knee_function_q9_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_pedia_knee_function_q9_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_pedia_knee_function_q9_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : null}
                </div>
                <div className="row">
                  {evlPediaPain === "1" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_pedia_knee_function_q10 +
                          RigthTrans(evlPediaPain, translation)
                        }
                        classes=""
                        // sliderData={scaleLabels}
                        value={getUpFromChairR}
                        onChange={handleGetUpFromChairR}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_pedia_knee_function_q10_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_pedia_knee_function_q10_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_pedia_knee_function_q10_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_pedia_knee_function_q10_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_pedia_knee_function_q10_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : null}
                  {evlPediaPain === "2" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_pedia_knee_function_q10 +
                          LeftTrans(evlPediaPain, translation)
                        }
                        classes=""
                        // sliderData={scaleLabels}
                        value={getUpFromChairL}
                        onChange={handleGetUpFromChairL}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_pedia_knee_function_q10_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_pedia_knee_function_q10_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_pedia_knee_function_q10_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_pedia_knee_function_q10_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_pedia_knee_function_q10_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : null}
                </div>
                <div className="row">
                  {evlPediaPain === "1" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_pedia_knee_function_q11 +
                          RigthTrans(evlPediaPain, translation)
                        }
                        classes=""
                        // sliderData={scaleLabels}
                        value={bendingToFloorR}
                        onChange={handleBendingToFloorR}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_pedia_knee_function_q11_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_pedia_knee_function_q11_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_pedia_knee_function_q11_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_pedia_knee_function_q11_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_pedia_knee_function_q11_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : null}
                  {evlPediaPain === "2" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_pedia_knee_function_q11 +
                          LeftTrans(evlPediaPain, translation)
                        }
                        classes=""
                        // sliderData={scaleLabels}
                        value={bendingToFloorL}
                        onChange={handleBendingToFloorL}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_pedia_knee_function_q11_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_pedia_knee_function_q11_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_pedia_knee_function_q11_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_pedia_knee_function_q11_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_pedia_knee_function_q11_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : null}
                </div>
                <div className="row">
                  {evlPediaPain === "1" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_pedia_knee_function_q12 +
                          RigthTrans(evlPediaPain, translation)
                        }
                        classes=""
                        // sliderData={scaleLabels}
                        value={runDurPlayR}
                        onChange={handleRunDurPlayR}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_pedia_knee_function_q12_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_pedia_knee_function_q12_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_pedia_knee_function_q12_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_pedia_knee_function_q12_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_pedia_knee_function_q12_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : null}
                  {evlPediaPain === "2" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_pedia_knee_function_q12 +
                          LeftTrans(evlPediaPain, translation)
                        }
                        classes=""
                        // sliderData={scaleLabels}
                        value={runDurPlayL}
                        onChange={handleRunDurPlayL}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_pedia_knee_function_q12_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_pedia_knee_function_q12_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_pedia_knee_function_q12_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_pedia_knee_function_q12_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_pedia_knee_function_q12_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : null}
                </div>
                <div className="row">
                  {evlPediaPain === "1" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_pedia_knee_function_q13 +
                          RigthTrans(evlPediaPain, translation)
                        }
                        classes=""
                        // sliderData={scaleLabels}
                        value={jumpDurPlayR}
                        onChange={handleJumpDurPlayR}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_pedia_knee_function_q13_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_pedia_knee_function_q13_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_pedia_knee_function_q13_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_pedia_knee_function_q13_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_pedia_knee_function_q13_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : null}
                  {evlPediaPain === "2" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_pedia_knee_function_q13 +
                          LeftTrans(evlPediaPain, translation)
                        }
                        classes=""
                        // sliderData={scaleLabels}
                        value={jumpDurPlayL}
                        onChange={handleJumpDurPlayL}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_pedia_knee_function_q13_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_pedia_knee_function_q13_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_pedia_knee_function_q13_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_pedia_knee_function_q13_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_pedia_knee_function_q13_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : null}
                </div>
                <div className="row">
                  {evlPediaPain === "1" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_pedia_knee_function_q14 +
                          RigthTrans(evlPediaPain, translation)
                        }
                        classes=""
                        // sliderData={scaleLabels}
                        value={startAndStopMoveR}
                        onChange={handleStartAndStopMoveR}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_pedia_knee_function_q14_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_pedia_knee_function_q14_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_pedia_knee_function_q14_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_pedia_knee_function_q14_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_pedia_knee_function_q14_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : null}
                  {evlPediaPain === "2" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_pedia_knee_function_q14 +
                          LeftTrans(evlPediaPain, translation)
                        }
                        classes=""
                        // sliderData={scaleLabels}
                        value={startAndStopMoveL}
                        onChange={handleStartAndStopMoveL}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_pedia_knee_function_q14_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_pedia_knee_function_q14_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_pedia_knee_function_q14_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_pedia_knee_function_q14_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_pedia_knee_function_q14_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : null}
                </div>
                <div className="row">
                  {evlPediaPain === "1" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_pedia_knee_function_q15 +
                          RigthTrans(evlPediaPain, translation)
                        }
                        classes=""
                        // sliderData={scaleLabels}
                        value={twistDurPlayR}
                        onChange={handleTwistDurPlayR}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_pedia_knee_function_q15_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_pedia_knee_function_q15_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_pedia_knee_function_q15_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_pedia_knee_function_q15_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_pedia_knee_function_q15_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : null}
                  {evlPediaPain === "2" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_pedia_knee_function_q15 +
                          LeftTrans(evlPediaPain, translation)
                        }
                        classes=""
                        // sliderData={scaleLabels}
                        value={twistDurPlayL}
                        onChange={handleTwistDurPlayL}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_pedia_knee_function_q15_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_pedia_knee_function_q15_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_pedia_knee_function_q15_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_pedia_knee_function_q15_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_pedia_knee_function_q15_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : null}
                </div>
                <div className="row">
                  {evlPediaPain === "1" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_pedia_knee_function_q16 +
                          RigthTrans(evlPediaPain, translation)
                        }
                        classes=""
                        // sliderData={scaleLabels}
                        value={keepBalWalkR}
                        onChange={handleKeepBalWalkR}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_pedia_knee_function_q16_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_pedia_knee_function_q16_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_pedia_knee_function_q16_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_pedia_knee_function_q16_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_pedia_knee_function_q16_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : null}
                  {evlPediaPain === "2" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_pedia_knee_function_q16 +
                          LeftTrans(evlPediaPain, translation)
                        }
                        classes=""
                        // sliderData={scaleLabels}
                        value={keepBalWalkL}
                        onChange={handleKeepBalWalkL}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_pedia_knee_function_q16_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_pedia_knee_function_q16_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_pedia_knee_function_q16_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_pedia_knee_function_q16_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_pedia_knee_function_q16_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : null}
                </div>
                <div className="row">
                  {evlPediaPain === "1" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_pedia_knee_function_q17 +
                          RigthTrans(evlPediaPain, translation)
                        }
                        classes=""
                        // sliderData={scaleLabels}
                        value={playSportsR}
                        onChange={handlePlaySportsR}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_pedia_knee_function_q17_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_pedia_knee_function_q17_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_pedia_knee_function_q17_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_pedia_knee_function_q17_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_pedia_knee_function_q17_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : null}
                  {evlPediaPain === "2" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_pedia_knee_function_q17 +
                          LeftTrans(evlPediaPain, translation)
                        }
                        classes=""
                        // sliderData={scaleLabels}
                        value={playSportsL}
                        onChange={handlePlaySportsL}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_pedia_knee_function_q17_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_pedia_knee_function_q17_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_pedia_knee_function_q17_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_pedia_knee_function_q17_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_pedia_knee_function_q17_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : null}
                </div>
                <div className="row">
                  {evlPediaPain === "1" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_pedia_knee_function_q18 +
                          RigthTrans(evlPediaPain, translation)
                        }
                        classes=""
                        // sliderData={scaleLabels}
                        value={getOutCarR}
                        onChange={handleGetOutCarR}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_pedia_knee_function_q18_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_pedia_knee_function_q18_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_pedia_knee_function_q18_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_pedia_knee_function_q18_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_pedia_knee_function_q18_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : null}
                  {evlPediaPain === "2" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_pedia_knee_function_q18 +
                          LeftTrans(evlPediaPain, translation)
                        }
                        classes=""
                        // sliderData={scaleLabels}
                        value={getOutCarL}
                        onChange={handleGetOutCarL}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_pedia_knee_function_q18_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_pedia_knee_function_q18_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_pedia_knee_function_q18_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_pedia_knee_function_q18_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_pedia_knee_function_q18_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : null}
                </div>
                <div className="row">
                  {evlPediaPain === "1" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_pedia_knee_function_q19 +
                          RigthTrans(evlPediaPain, translation)
                        }
                        classes=""
                        // sliderData={scaleLabels}
                        value={getOutBedR}
                        onChange={handleGetOutBedR}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_pedia_knee_function_q19_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_pedia_knee_function_q19_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_pedia_knee_function_q19_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_pedia_knee_function_q19_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_pedia_knee_function_q19_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : null}
                  {evlPediaPain === "2" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_pedia_knee_function_q19 +
                          LeftTrans(evlPediaPain, translation)
                        }
                        classes=""
                        // sliderData={scaleLabels}
                        value={getOutBedL}
                        onChange={handleGetOutBedL}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_pedia_knee_function_q19_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_pedia_knee_function_q19_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_pedia_knee_function_q19_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_pedia_knee_function_q19_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_pedia_knee_function_q19_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : null}
                </div>
                <div className="row">
                  {evlPediaPain === "1" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_pedia_knee_function_q20 +
                          RigthTrans(evlPediaPain, translation)
                        }
                        classes=""
                        // sliderData={scaleLabels}
                        value={changePositionLyingR}
                        onChange={handleChangePositionLyingR}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_pedia_knee_function_q20_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_pedia_knee_function_q20_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_pedia_knee_function_q20_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_pedia_knee_function_q20_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_pedia_knee_function_q20_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : null}
                  {evlPediaPain === "2" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_pedia_knee_function_q20 +
                          LeftTrans(evlPediaPain, translation)
                        }
                        classes=""
                        // sliderData={scaleLabels}
                        value={changePositionLyingL}
                        onChange={handleChangePositionLyingL}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_pedia_knee_function_q20_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_pedia_knee_function_q20_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_pedia_knee_function_q20_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_pedia_knee_function_q20_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_pedia_knee_function_q20_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : null}
                </div>
                <div className="row">
                  {evlPediaPain === "1" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_pedia_knee_function_q21 +
                          RigthTrans(evlPediaPain, translation)
                        }
                        classes=""
                        // sliderData={scaleLabels}
                        value={gettingBathR}
                        onChange={handleGettingBathR}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_pedia_knee_function_q21_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_pedia_knee_function_q21_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_pedia_knee_function_q21_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_pedia_knee_function_q21_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_pedia_knee_function_q21_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : null}
                  {evlPediaPain === "2" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_pedia_knee_function_q21 +
                          LeftTrans(evlPediaPain, translation)
                        }
                        classes=""
                        // sliderData={scaleLabels}
                        value={gettingBathL}
                        onChange={handleGettingBathL}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_pedia_knee_function_q21_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_pedia_knee_function_q21_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_pedia_knee_function_q21_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_pedia_knee_function_q21_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_pedia_knee_function_q21_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : null}
                </div>
                <div className="row">
                  {evlPediaPain === "1" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_pedia_knee_function_q22 +
                          RigthTrans(evlPediaPain, translation)
                        }
                        classes=""
                        // sliderData={scaleLabels}
                        value={carryHeavyBagsR}
                        onChange={handleCarryHeavyBagsR}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_pedia_knee_function_q22_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_pedia_knee_function_q22_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_pedia_knee_function_q22_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_pedia_knee_function_q22_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_pedia_knee_function_q22_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : null}
                  {evlPediaPain === "2" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_pedia_knee_function_q22 +
                          LeftTrans(evlPediaPain, translation)
                        }
                        classes=""
                        // sliderData={scaleLabels}
                        value={carryHeavyBagsL}
                        onChange={handleCarryHeavyBagsL}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_pedia_knee_function_q22_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_pedia_knee_function_q22_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_pedia_knee_function_q22_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_pedia_knee_function_q22_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_pedia_knee_function_q22_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : null}
                </div>
                <div className="row">
                  {evlPediaPain === "1" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_pedia_knee_function_q23 +
                          RigthTrans(evlPediaPain, translation)
                        }
                        classes=""
                        // sliderData={scaleLabels}
                        value={doLightChoresR}
                        onChange={handleDoLightChoresR}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_pedia_knee_function_q23_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_pedia_knee_function_q23_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_pedia_knee_function_q23_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_pedia_knee_function_q23_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_pedia_knee_function_q23_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : null}
                  {evlPediaPain === "2" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_pedia_knee_function_q23 +
                          LeftTrans(evlPediaPain, translation)
                        }
                        classes=""
                        // sliderData={scaleLabels}
                        value={doLightChoresL}
                        onChange={handleDoLightChoresL}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_pedia_knee_function_q23_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_pedia_knee_function_q23_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_pedia_knee_function_q23_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_pedia_knee_function_q23_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_pedia_knee_function_q23_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : null}
                </div>
                <div className="row">
                  {evlPediaPain === "1" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_pedia_knee_function_q24 +
                          RigthTrans(evlPediaPain, translation)
                        }
                        classes=""
                        // sliderData={scaleLabels}
                        value={gettingToSchoolR}
                        onChange={handleGettingToSchoolR}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_pedia_knee_function_q24_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_pedia_knee_function_q24_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_pedia_knee_function_q24_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_pedia_knee_function_q24_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_pedia_knee_function_q24_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : null}
                  {evlPediaPain === "2" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_pedia_knee_function_q24 +
                          LeftTrans(evlPediaPain, translation)
                        }
                        classes=""
                        // sliderData={scaleLabels}
                        value={gettingToSchoolL}
                        onChange={handleGettingToSchoolL}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_pedia_knee_function_q24_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_pedia_knee_function_q24_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_pedia_knee_function_q24_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_pedia_knee_function_q24_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_pedia_knee_function_q24_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : null}
                </div>
                <div className="row">
                  {evlPediaPain === "1" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_pedia_knee_function_q25 +
                          RigthTrans(evlPediaPain, translation)
                        }
                        classes=""
                        // sliderData={scaleLabels}
                        value={doThingsFriendsR}
                        onChange={handleDoThingsFriendsR}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_pedia_knee_function_q25_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_pedia_knee_function_q25_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_pedia_knee_function_q25_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_pedia_knee_function_q25_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_pedia_knee_function_q25_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : null}
                  {evlPediaPain === "2" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={
                          translation.patient_pedia_knee_function_q25 +
                          LeftTrans(evlPediaPain, translation)
                        }
                        classes=""
                        // sliderData={scaleLabels}
                        value={doThingsFriendsL}
                        onChange={handleDoThingsFriendsL}
                        sliderData={[
                          {
                            score: "0",
                            label:
                              translation.patient_pedia_knee_function_q25_a1,
                          },
                          {
                            score: "1",
                            label:
                              translation.patient_pedia_knee_function_q25_a2,
                          },
                          {
                            score: "2",
                            label:
                              translation.patient_pedia_knee_function_q25_a3,
                          },
                          {
                            score: "3",
                            label:
                              translation.patient_pedia_knee_function_q25_a4,
                          },
                          {
                            score: "4",
                            label:
                              translation.patient_pedia_knee_function_q25_a5,
                          },
                        ]}
                      />
                    </div>
                  ) : null}
                </div>
                <div className="row mb_40">
             
                  {(
                    evlPediaPain === "1" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-12 mb_10" + RightSide()}>

                      <RangeSlider
                        id="howKneeWorkbeforeR"
                        colorcode="1"
                        min="0"
                        max="10"
                        step="0.1"
                        type="text"
                        leftTitle={
                          translation.patient_pedia_knee_function_q26_instruction_a1
                        }
                        rightTitle={
                          translation.patient_pedia_knee_function_q26_instruction_a2
                        }
                        label={
                          translation.patient_pedia_knee_function_q26 +
                          RigthTrans(evlPediaPain, translation)
                        }
                        span1={
                          translation.patient_pedia_knee_function_q26_instruction
                        }
                        value={howKneeWork_beforeR}
                        onChange={handleHowKneeWork_beforeR}
                      />
                    </div>
                  ) : null)}

                

                    {(evlPediaPain === "2" || evlPediaPain === "3" ? (
                    <div className={"col-md-6 col-sm-12  mb_10" + LeftSide()}>


                      <RangeSlider
                        id="howKneeWorkbeforeL"
                        colorcode="1"
                        min="0"
                        max="10"
                        step="0.1"
                        type="text"
                        leftTitle={
                          translation.patient_pedia_knee_function_q26_instruction_a1
                        }
                        rightTitle={
                          translation.patient_pedia_knee_function_q26_instruction_a2
                        }
                        label={
                          translation.patient_pedia_knee_function_q26 +
                          LeftTrans(evlPediaPain, translation)
                        }
                        value={howKneeWork_beforeL}
                        onChange={handleHowKneeWork_beforeL}
                        span1={
                          translation.patient_pedia_knee_function_q26_instruction
                        }
                      />
                    </div>
                  ) : null)}
                </div>


                <div className="row mb_40">
                  {evlPediaPain === "1" || evlPediaPain === "3" ? (
                    <div
                      className={"col-md-6 col-sm-12 mt_20 mb_10" + RightSide()}
                    >
                      <RangeSlider
                        id="howKneeWorkR"
                        colorcode="1"
                        min="0"
                        max="10"
                        step="0.1"
                        type="text"
                        leftTitle={
                          translation.patient_pedia_knee_function_q27_instruction_a1
                        }
                        rightTitle={
                          translation.patient_pedia_knee_function_q27_instruction_a2
                        }
                        label={
                          translation.patient_pedia_knee_function_q27 +
                          RigthTrans(evlPediaPain, translation)
                        }
                        value={howKneeWorkR}
                        onChange={handleHowKneeWorkR}
                        span1={
                          translation.patient_pedia_knee_function_q27_instruction
                        }
                      />
                    </div>
                  ) : null}
                  {evlPediaPain === "2" || evlPediaPain === "3" ? (
                    <div
                      className={"col-md-6 col-sm-12 mt_20 mb_10" + LeftSide()}
                    >
                      <RangeSlider
                        id="howKneeWorkL"
                        colorcode="1"
                        min="0"
                        max="10"
                        step="0.1"
                        type="text"
                        leftTitle={
                          translation.patient_pedia_knee_function_q27_instruction_a1
                        }
                        rightTitle={
                          translation.patient_pedia_knee_function_q27_instruction_a2
                        }
                        label={
                          translation.patient_pedia_knee_function_q27 +
                          LeftTrans(evlPediaPain, translation)
                        }
                        value={howKneeWorkL}
                        onChange={handleHowKneeWorkL}
                        span1={
                          translation.patient_pedia_knee_function_q27_instruction
                        }
                      />
                    </div>
                  ) : null}
                </div>


              </div>

              <div className="row mb_40">
                {evlPediaPain === "1" || evlPediaPain === "3" ? (
                  <div
                    className={"col-md-6 col-sm-12 mt_20 mb_10" + RightSide()}
                  >
                    <RadioSwitch
                      labelLeft={translation.patient_pedia_knee_function_q28_a1}
                      labelRight={
                        translation.patient_pedia_knee_function_q28_a2
                      }
                      idLeft="patellarInstabilityNo"
                      idRight="patellarInstabilityYes"
                      name="patellarInstability"
                      title={
                        translation.patient_pedia_knee_function_q28 +
                        RigthTrans(evlPediaPain, translation)
                      }
                      styleClass=""
                      value={patellarInstability}
                      onChange={handlePatellarInstability}
                      placeholder={translation.Select}
                    />
                  </div>
                ) : null}
                {evlPediaPain === "2" || evlPediaPain === "3" ? (
                  <div
                    className={"col-md-6 col-sm-12 mt_20 mb_10" + LeftSide()}
                  >
                    <RadioSwitch
                      labelLeft={translation.patient_pedia_knee_function_q28_a1}
                      labelRight={
                        translation.patient_pedia_knee_function_q28_a2
                      }
                      idLeft="patellarInstabilityNoLeft"
                      idRight="patellarInstabilityYesLeft"
                      name="patellarInstabilityLeft"
                      title={
                        translation.patient_pedia_knee_function_q28 +
                        LeftTrans(evlPediaPain, translation)
                      }
                      styleClass=""
                      value={patellarInstabilityLeft}
                      onChange={handlePatellarInstabilityLeft}
                      placeholder={translation.Select}
                    />
                  </div>
                ) : null}
              </div>

              {doctorEnable === "true" && (
                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6"></div>
                  <div className="col-md-6 col-sm-6 text-end">
                  <button
                      className="next_btn btn_fill me-3"
                      onClick={() => handleNext()}
                    >
                      {translation.next_button}
                      <span>
                        <img src={nextIcon} alt="" />
                        {/* <img src={nextWhiteIcon} alt="" /> */}
                      </span>
                    </button>
                    <button
                      className="next_btn btn_fill"
                      onClick={() => handleSubmit()}
                    >
                      {translation.submit}
                      <span>
                        <img src={nextIcon} alt="" />
                        {/* <img src={nextWhiteIcon} alt="" /> */}
                      </span>
                    </button>
                  </div>
                </div>
              )}

              {doctorEnable !== "true" && (
                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6 col-6">
                    <Link
                      to={"/pedia-knee-symptoms/" + rId}
                      className="assm_back_btn"
                    >
                      <img src={backIcon} alt="" />
                      <span>{translation.back_button}</span>
                    </Link>
                  </div>
                  <div className="col-md-6 col-sm-6 col-6 text-end">
                    <button
                      className="next_btn btn_fill"
                      onClick={continueRedirect}
                    >
                      {translation.next_button}
                      <span>
                        <img src={nextIcon} alt="" />
                        <img src={nextWhiteIcon} alt="" />
                      </span>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PediaKneeFunction;
