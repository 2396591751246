import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";

import { validateInput } from "../../../utilities/Validator";
import scrangeStyles from "./HipScoreRangeSlider.module.css";
import useTranslation from "../../customHooks/translations"
function HipScoreRangeSlider({
  classes,
  label,
  onChange,
  onMouseUp,
  value,
  validators,
  sliderData,
  view,
  maximun,
  minimum,
  classStyle,
  ...sliderProps
}) {
  const translation = useTranslation()
  const [countR, setCountR] = useState(0);
  const handleChange = (event) => {
    const { value } = event.target;
    onChange(value);
  };

  const [sliderDatas, setsliderDatas] = useState([
    { score: "0", label: translation.No_difficulty },
    { score: "1", label: translation.mild },
    { score: "2", label: translation.moderate },
    { score: "3", label: translation.severe_h },
    { score: "4", label: translation.unable },
  ])
  useEffect(() => {
    if (sliderData) {
      setsliderDatas(sliderData)
    }
  }, [sliderData])
  return (
    <>
      <div className={`score_range_slider ${scrangeStyles.score_range_slider}`}>
        <label className={`${scrangeStyles.labels} ${classStyle}`}>{label}</label>
        <div className={`${scrangeStyles.tabslider_content}`}>
          <div className={`${scrangeStyles.range}`}>
            <input
              {...sliderProps}
              min={maximun ? maximun : "0"}
              max={minimum ? minimum : "4"}
              steps="1"
              type="range"
              value={value}
              className={`slider ${classes}`}
              id="myRange"
              onChange={handleChange}
              onClick={handleChange}
              disabled={view}
            />
          </div>
          <ul className={` range_labels ${scrangeStyles.range_labels}`}>
            {sliderDatas.map((data) =>
              <li className={`${value === data.score ? "active" : ""}`} key={data.score}>
                {data.score} <span>{data.label}</span>
              </li>
            )}

          </ul>
        </div>
      </div>
    </>
  );
}

HipScoreRangeSlider.propTypes = {
  validators: PropTypes.array,
  validators: PropTypes.array,
};

HipScoreRangeSlider.defaultProps = {
  validators: [],
  label: "",
  value: "",
  // sliderData: [
  //   { score: "1", label: 'very dissatisfied'},
  //   { score: "2", label: 'somewhat dissatisfied'},
  //   { score: "3", label: 'somewhat satisfied'},
  //   { score: "4", label: 'very satisfied'},
  //   // { score: "4", label: 'extreme'},
  // ]
};

export default HipScoreRangeSlider;
