import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { validateInput } from "../../../utilities/Validator";
import powerWidgetStyles from "./AssessmentPowerKgWidget.module.css";

function AssessmentPowerKgWidget({
  title,
  value,
  validators,
  label,
  nameR,
  nameL,
  onChange,
  onChangeP,
  onChangeK,
  powerAction,
  kgAction,
  power,
  kgview,
  kg,
  kgshow,kgview1
}) {
  const [countP, setCountP] = useState(power);
  const [countK, setCountK] = useState(kg);
  const [errorValP, setErrorValP] = useState(false);
  const [errorValK, setErrorValK] = useState(false);
  const [errorP, setErrorP] = useState(false);
  const [errorK, setErrorK] = useState(false);
  const [errorMsgP, setErrorMsgP] = useState("Number Should be 0 to 5");
  const [errorMsgK, setErrorMsgK] = useState("Number Should be 0 to 100");
  useEffect(() => {
    setCountP(power)
  }, [power])
  useEffect(() => {
    setCountK(kg)
  }, [kg])
  // regex for avoiding negative values
  const regex = /^\-?[1-9]\d{0,2}(\.\d*)?$/;

  const handleChangePower = (event) => {
    const { value } = event.target;
    let newValue = value;
    setErrorValP(validateInput(validators, value));
    if (kgview) {
      if (newValue >= 0 && newValue <= 5) {
        setCountP(parseFloat(newValue));
        onChangeP(parseFloat(newValue));
        console.log("✅ num is between values");
        setErrorP(false);
      } else {
        console.log("⛔️ num is NOT between values");
        setCountP(0);
        setErrorP(true);
      }
    } else {
      if (newValue >= 0 && newValue <= 5) {
        setCountP(parseInt(newValue));
        onChangeP(parseInt(newValue));
        console.log("✅ num is between values");
        setErrorP(false);
      } else {
        console.log("⛔️ num is NOT between values");
        setCountP(0);
        setErrorP(true);
      }
    }

  };

  // Create handleIncrement event handler
  const handleIncrementPower = () => {
    if (isNaN(countP) || countP === "") {
      setCountP(1);
    } else {
      setCountP(countP >= 5 ? 5 : Number(countP) + 1);
      onChangeP(countP >= 5 ? 5 : Number(countP) + 1);

    }

  };

  //Create handleDecrement event handler
  const handleDecrementPower = () => {
    if (regex.test(countP)) {
      setCountP(countP <= 0 ? 0 : countP - 1);
      onChangeP(countP <= 0 ? 0 : countP - 1);
    }
  };

  const handleChangeKg = (event) => {
    let { value } = event.target;
    // Ensure that there's only one decimal place
    value = parseFloat(value).toFixed(1);
    let newValue = parseFloat(value);
    setErrorValK(validateInput(validators, value));

    if (!isNaN(newValue) && newValue >= 0 && newValue <= 100) {
      setCountK(isNaN(newValue) ? "" : newValue);
      onChangeK(isNaN(newValue) ? "" : newValue);
      setErrorK(false);
      console.log("✅ num is between values");
    } else {
      console.log("⛔️ num is NOT between values");
      setCountK("");
      setErrorK(true);
    }
  };

  // Create handleIncrement event handler
  const handleIncrementKg = () => {
    if (isNaN(countK) || countK === "") {
      setCountK(0.1);
    } else {
      setCountK(countK >= 100 ? 100 : Number((countK + 0.1).toFixed(1)));
      onChangeK(countK >= 100 ? 100 : Number((countK + 0.1).toFixed(1)));
    }

  };

  //Create handleDecrement event handler
  const handleDecrementKg = () => {
    if (!isNaN(countK) && countK >= 0) {
      setCountK(countK <= 0 ? 0 : Number((countK - 0.1).toFixed(1)));
      onChangeK(countK <= 0 ? 0 : Number((countK - 0.1).toFixed(1)));
    }
  };

  return (
    <>
      <label className={`${powerWidgetStyles.requiredFieldRightF}`}>
        {label}
      </label>
      <div className={`${powerWidgetStyles.input_box_main}`}>
        <div className={`${powerWidgetStyles.number_box_row}`}>
          {powerAction && (
            <div className={`${powerWidgetStyles.number_box_col}`}>
              <div className={`${powerWidgetStyles.degree_box}`}>
                <span
                  className={`${powerWidgetStyles.qty_minus}`}
                  onClick={handleDecrementPower}
                >
                  -
                </span>
                <input
                  type="number"
                  max={100}
                  min={0}
                  step={0.01}
                  className="qty trim"
                  name={nameR}
                  value={countP}
                  placeholder="000"
                  onChange={handleChangePower}
                  onBlur={handleChangePower}
                />
                <span
                  className={`${powerWidgetStyles.qty_plus}`}
                  onClick={handleIncrementPower}
                >
                  +
                </span>
              </div>
              <span className={`${powerWidgetStyles.degree_txt}`}>{kgshow ? "Kgs" : "Power"}</span>
              {errorP && (
                <span className={`${powerWidgetStyles.ValidationErrors}`}>
                  {errorMsgP}
                </span>
              )}
              {errorValP && (
                <span className="text-danger">{errorValP.message}</span>
              )}
            </div>
          )}
          {kgAction && (
            <div className={`${powerWidgetStyles.number_box_col}`}>
              <div className={`${powerWidgetStyles.degree_box}`}>
                <span
                  className={`${powerWidgetStyles.qty_minus}`}
                  onClick={handleDecrementKg}
                >
                  -
                </span>
                <input
                  type="number"
                  max={100}
                  min={0}
                  step={0.01}
                  className="qty trim"
                  name={nameL}
                  value={countK}
                  placeholder="000"
                  onChange={handleChangeKg}
                  onBlur={handleChangeKg}
                />
                <span
                  className={`${powerWidgetStyles.qty_plus}`}
                  onClick={handleIncrementKg}
                >
                  +
                </span>
              </div>
              <span className={`${powerWidgetStyles.degree_txt}`}>{kgview ? "Kgs": kgview1 ? "Kg" : "Power"}</span>
              {errorK && (
                <span className={`${powerWidgetStyles.ValidationErrors}`}>
                  {errorMsgK}
                </span>
              )}
              {errorValK && (
                <span className="text-danger">{errorValK.message}</span>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

AssessmentPowerKgWidget.propTypes = {
  validators: PropTypes.array,
};

AssessmentPowerKgWidget.defaultProps = {
  value: "",
  title: "",
  label: "",
  name: "",
  nameR: "",
  nameL: "",
  powerAction: "",
  kgAction: "",
  validators: [],
};

export default AssessmentPowerKgWidget;
