export function calculateAge(birthdate) {
  if (birthdate) {
      // Split the input birthdate into an array [yyyy, mm, dd]
      var birthdateArray = birthdate.split("/");
      
      // Extract day, month, and year from the array
      var birthDay = parseInt(birthdateArray[2]); // Day
      var birthMonth = parseInt(birthdateArray[1]) - 1; // Month (zero-based)
      var birthYear = parseInt(birthdateArray[0]); // Year

      // Create a Date object for the birthdate
      var birthdateObj = new Date(birthYear, birthMonth, birthDay);

      // Create a Date object for the current date
      var currentDate = new Date();

      // Calculate the age
      var age = currentDate.getFullYear() - birthdateObj.getFullYear();

      // Check if the birthdate has occurred this year
      if (
          currentDate.getMonth() < birthdateObj.getMonth() ||
          (currentDate.getMonth() === birthdateObj.getMonth() &&
              currentDate.getDate() < birthdateObj.getDate())
      ) {
          age--; // Decrement the age if the birthdate hasn't occurred yet this year
      }

      return age;
  }

  // Return undefined if the birthdate is not provided
}