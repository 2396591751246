import React, { useMemo, useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import backIcon from "../../../../../images/back.png";
import nextWhiteIcon from "../../../../../images/next-white.png";
import nextIcon from "../../../../../images/next.png";
import LoadingSpinner from "../../../../custom/loader/LoadingSpinner";
import Dropdown from "../../../../custom/dropdown/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import {
  updatePediaScores,
  updatePediaSatisfactionFieldScore,
  updatePediaSatisfactionScore,
  updatePediaPainFieldScore,
  updatePediaPainData,
  updatePediaSymtomsScore,
  updatePediaFunctionScore,
  updatePediaQolScore,
  updatePediaTotalScores,
  updatePediaKneeNorwichInstaScore,
  updateAllData,
  updatePediaKneeFeedbackScores

} from "../../../../../redux/actions/pediaKnee";
import {
  updateAdultKneeFeedback

} from "../../../../../redux/actions/index";
import useAxios from "../../../../../axiosinstance";
import useTransition from '../../../../customHooks/translations';
import PediakneeTab from '../../../tab-section/PediakneeTab';
import { useSearchParams } from "react-router-dom";
import CustomToaster from '../../../../custom/toaster/CustomToaster'
import HipScoreRangeSlider from "../../../../custom/hip-score-range-slider/HipScoreRangeSlider";
import { LeftTrans, RightSide, LeftSide, RigthTrans } from "../../../../../utilities/RightLeftFormat";
import NameCustom from "../../../../custom/name/NameCustom";

function PediaKneeSatisfaction() {
  const translation = useTransition();
  let lg = sessionStorage.getItem("language");
  const { rId } = useParams();
  const [searchParams] = useSearchParams();
  let doctorEnable = searchParams.get("d");
  const axiosinstance = useAxios();
  const [isLoading, setIsLoading] = useState(true);
  const [overallR, setOverallR] = useState("");
  const [overallL, setOverallL] = useState("");
  const [improvingPainR, setImprovingPainR] = useState("");
  const [improvingPainL, setImprovingPainL] = useState("");
  const [improvingHomeR, setImprovingHomeR] = useState("");
  const [improvingHomeL, setImprovingHomeL] = useState("");
  const [improvingActivitiesR, setImprovingActivitiesR] = useState("");
  const [improvingActivitiesL, setImprovingActivitiesL] = useState("");
  const evlPediaPain = useSelector((state) => state.pediaKnee.evlPediaPain);
  const [updateTotal, setUpdateTotal] = useState(false);

  const [applicableR, setapplicableR] = useState(false)
  const [applicableL, setapplicableL] = useState(false)

  const [applicable1R, setapplicable1R] = useState(false)
  const [applicable1L, setapplicable1L] = useState(false)



  const [applicable2R, setapplicable2R] = useState(false)
  const [applicable2L, setapplicable2L] = useState(false)



  const [applicable3R, setapplicable3R] = useState(false)
  const [applicable3L, setapplicable3L] = useState(false)
  const [doctorStatus, setDoctorStatus] = useState(false);
  const navigate = useNavigate();
  const [chk, setChk] = useState(false);
  const save = () => {
    axiosinstance.current
      .get(`extapp/forms/all_forms?resource_id=${rId}&type=20`)
      .then((res) => {
        dispatch(updatePediaScores(res.data.data2[0].evlPediaPain));
        dispatch(updatePediaPainData(res.data.data2[0].painScore));
        dispatch(updatePediaSymtomsScore(res.data.data2[0].symtomsScore));
        dispatch(updatePediaFunctionScore(res.data.data2[0].functionScore));
        dispatch(updatePediaQolScore(res.data.data2[0].qolScore));
        dispatch(
          updatePediaSatisfactionScore(res.data.data2[0].satisfactionScore)
        );
        dispatch(
          updatePediaKneeNorwichInstaScore(res.data.data2[0].norwichInstability)
        );
        dispatch(
          updateAdultKneeFeedback(res.data.data2[0].doctorFeedback)
        );
        setDoctorStatus(res.data.doctorStatus);
        // dispatch(updatePediaTotalScores(res.data.data2[0].TotalScores));
        setChk(true);
      }).catch((err) => {

        navigate('/un-authorized-access');

      })
  };

  useEffect(() => {
    save();
  }, []);

  const satisfactionScore = useSelector(
    (state) => state.pediaKnee.satisfactionScore[0]
  );
  const json = useSelector((state) => state.pediaKnee);

  useEffect(() => {
    setOverallR(satisfactionScore.overallR);
    setOverallL(satisfactionScore.overallL);
    setImprovingPainR(satisfactionScore.improvingPainR);
    setImprovingPainL(satisfactionScore.improvingPainL);
    setImprovingHomeR(satisfactionScore.improvingHomeR);
    setImprovingHomeL(satisfactionScore.improvingHomeL);
    setImprovingActivitiesR(satisfactionScore.improvingActivitiesR);
    setImprovingActivitiesL(satisfactionScore.improvingActivitiesL);

    setapplicableR(satisfactionScore.applicableR);
    setapplicableL(satisfactionScore.applicableL);
    setapplicable1R(satisfactionScore.applicable1R)
    setapplicable1L(satisfactionScore.applicable1L)
    setapplicable2R(satisfactionScore.applicable2R)
    setapplicable2L(satisfactionScore.applicable2L)
    setapplicable3R(satisfactionScore.applicable3R)
    setapplicable3L(satisfactionScore.applicable3L)

  }, [satisfactionScore]);

  const updateDate = () => {
    axiosinstance.current
      .patch(
        `extapp/forms/update_all_form_type?resource_id=${rId}&action=${doctorEnable === "true"?"1":"0"}`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res);
      });
  };
  useEffect(() => {
    if (chk) {
      updateDate();
    }
  }, [satisfactionScore, updateTotal]);
  const handleOverallR = (e) => {
    dispatch(
      updatePediaSatisfactionFieldScore({
        key: "overallR",
        value: e,
      })
    );
    setOverallR(e);
  };
  const handleOverallL = (e) => {
    dispatch(
      updatePediaSatisfactionFieldScore({
        key: "overallL",
        value: e,
      })
    );
    setOverallL(e);
  };
  const handleImprovingPainR = (e) => {
    dispatch(
      updatePediaSatisfactionFieldScore({
        key: "improvingPainR",
        value: e,
      })
    );
    setImprovingPainR(e);
  };
  const handleImprovingPainL = (e) => {
    dispatch(
      updatePediaSatisfactionFieldScore({
        key: "improvingPainL",
        value: e,
      })
    );
    setImprovingPainL(e);
  };
  const handleImprovingHomeR = (e) => {
    dispatch(
      updatePediaSatisfactionFieldScore({
        key: "improvingHomeR",
        value: e,
      })
    );
    setImprovingHomeR(e);
  };
  const handleImprovingHomeL = (e) => {
    dispatch(
      updatePediaSatisfactionFieldScore({
        key: "improvingHomeL",
        value: e,
      })
    );
    setImprovingHomeL(e);
  };
  const handleImprovingActivitiesR = (e) => {
    dispatch(
      updatePediaSatisfactionFieldScore({
        key: "improvingActivitiesR",
        value: e,
      })
    );
    setImprovingActivitiesR(e);
  };
  const handleImprovingActivitiesL = (e) => {
    dispatch(
      updatePediaSatisfactionFieldScore({
        key: "improvingActivitiesL",
        value: e,
      })
    );
    setImprovingActivitiesL(e);
  };


  const handleapplicableR = (e) => {
    setapplicableR(e)
    // setOverallR("0")
    dispatch(updatePediaSatisfactionFieldScore({
      "key": 'applicableR',
      "value": e
    }))
    dispatch(updatePediaSatisfactionFieldScore({
      "key": 'overallR',
      "value": ""
    }))
  }

  const handleapplicableL = (e) => {
    setapplicableL(e)
    // setOverallL("0")
    dispatch(updatePediaSatisfactionFieldScore({
      "key": 'applicableL',
      "value": e
    }))
    dispatch(updatePediaSatisfactionFieldScore({
      "key": 'overallL',
      "value": ""
    }))
  }

  const handleapplicable1R = (e) => {
    setapplicable1R(e)
    // setImprovePainR("0")
    dispatch(updatePediaSatisfactionFieldScore({
      "key": 'applicable1R',
      "value": e
    }))
    dispatch(updatePediaSatisfactionFieldScore({
      "key": 'improvingPainR',
      "value": ""
    }))
  }

  const handleapplicable1L = (e) => {
    setapplicable1L(e)
    // setImprovePainL("0")
    dispatch(updatePediaSatisfactionFieldScore({
      "key": 'applicable1L',
      "value": e
    }))
    dispatch(updatePediaSatisfactionFieldScore({
      "key": 'improvingPainL',
      "value": ""
    }))
  }


  const handleapplicable2R = (e) => {
    setapplicable2R(e)
    // setImproveHomeR("0")
    dispatch(updatePediaSatisfactionFieldScore({
      "key": 'applicable2R',
      "value": e
    }))
    dispatch(updatePediaSatisfactionFieldScore({
      "key": 'improvingHomeR',
      "value": ""
    }))

  }

  const handleapplicable2L = (e) => {
    setapplicable2L(e)
    // setImproveHomeL("0")
    dispatch(updatePediaSatisfactionFieldScore({
      "key": 'applicable2L',
      "value": e
    }))
    dispatch(updatePediaSatisfactionFieldScore({
      "key": 'improvingHomeL',
      "value": ""
    }))
  }


  const handleapplicable3R = (e) => {
    setapplicable3R(e)
    // setImproveRecrR("0")
    dispatch(updatePediaSatisfactionFieldScore({
      "key": 'applicable3R',
      "value": e
    }))
    dispatch(updatePediaSatisfactionFieldScore({
      "key": 'improvingActivitiesR',
      "value": ""
    }))
  }



  const handleapplicable3L = (e) => {
    setapplicable3L(e)
    // setImproveRecrL("0")
    dispatch(updatePediaSatisfactionFieldScore({
      "key": 'applicable3L',
      "value": e
    }))
    dispatch(updatePediaSatisfactionFieldScore({
      "key": 'improvingActivitiesL',
      "value": ""
    }))
  }



  const dispatch = useDispatch();

  const handleEvolutedPediaPain = (e) => {
    dispatch(updatePediaScores(e));
  };

  const continueRedirect = (e) => {
    dispatch(
      updatePediaSatisfactionScore([
        {
          overallR: overallR,
          overallL: overallL,
          improvingPainR: improvingPainR,
          improvingPainL: improvingPainL,
          improvingHomeR: improvingHomeR,
          improvingHomeL: improvingHomeL,
          improvingActivitiesR: improvingActivitiesR,
          improvingActivitiesL: improvingActivitiesL,
          applicable: applicableL,
        },
      ])
    );
    navigate("/pedia-knee-complete/" + rId);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);


  const [msg, setMsg] = useState('');
  const [msgType, setmsgType] = useState('');
  const SubmitForm = (id) => {
    axiosinstance.current.patch(`extapp/forms/update_scoring?resource_id=${id}`, {
      patient_scoring_date: new Date(),
      status: 1,
    });
  };
  let akpt = searchParams.get("akpt");
  const handleSubmit = () => {
    updateAllData(json, dispatch);
    setUpdateTotal(!updateTotal);
    setMsg(translation.update)
    setmsgType('success')
    SubmitForm(rId)
   setTimeout(() => {
     let view_circular=  sessionStorage.getItem("view_circular")
      // window.location.href = process.env.REACT_APP_DOCTOR_URL + "patient-view/" + akpt
      // window.location.href = process.env.REACT_APP_DOCTOR_URL +`view-management-plan/${akpt}/${window.btoa(
      //   rId
      // )}/?org=${window.btoa(sessionStorage.getItem("orgid")||'')}&formType=${
      //   window.btoa('20') || ""
      // }&view_circular=${view_circular==='false'?'false':'true'}`
      window.location.href = process.env.REACT_APP_DOCTOR_URL +`patient-details/${akpt}?formid=20`
      setMsg('')
      setmsgType('success')
    }, 2000);
  };
  const handleNext = () => {
    navigate(`/knee-feedback/${rId}?d=true&akpt=${akpt}`);

  }

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="adault_knee_satisfaction_screen mb_100">
           <div class="d-flex justify-content-end">
            <NameCustom />
          </div>
          <div className="panel">
            <div className="panel-body p_25">
              {doctorEnable === "true" && <PediakneeTab rId={rId} />}
              <CustomToaster msg={msg} msgType={msgType} />
              
              <h4 className="ph_title f_40">{translation.patient_pedia_knee_satisfaction}</h4>
              <div className="row">
                <div className="col-md-6 col-sm-6 function_head">
                  <label>{translation.patient_pedia_knee_satisfaction_instruction}</label> <br />
                  <span dir={lg === "ara" ? "rtl" : " "}>
                    (<strong>1</strong> = {translation.patient_pedia_knee_satisfaction_instruction_a1}, <strong> 2</strong> = {translation.patient_pedia_knee_satisfaction_instruction_a2}, <strong>3</strong> = {translation.patient_pedia_knee_satisfaction_instruction_a3}, <strong>4</strong> = {translation.patient_pedia_knee_satisfaction_instruction_a4})
                  </span>
                </div>
              </div>
              <div className="row mb_20 pt_20">
                {evlPediaPain === "1" || evlPediaPain === "3" ? (
                  <div className={"col-md-6 col-sm-6" + RightSide()}>
                    <div className="mb_20 satis">
                      <label class="form-check-label not-applicable4">
                        <input className="check_" type="checkbox" id="radio1" name="radio1" value={applicableR} checked={applicableR} style={{ marginRight: 10 }} onChange={(e) => handleapplicableR(e.target.checked)}></input>
                        {translation.patient_pedia_knee_satisfaction_q1_NA}
                      </label>

                      <HipScoreRangeSlider
                        maximun="1"
                        minimum="4"
                        sliderData={[
                          { score: "1", label: translation.patient_pedia_knee_satisfaction_q1_a1 },
                          { score: "2", label: translation.patient_pedia_knee_satisfaction_q1_a2 },
                          { score: "3", label: translation.patient_pedia_knee_satisfaction_q1_a3 },
                          { score: "4", label: translation.patient_pedia_knee_satisfaction_q1_a4 },

                        ]}
                        label={translation.patient_pedia_knee_satisfaction_q1 + RigthTrans(evlPediaPain, translation)}
                        classes=""
                        value={overallR}
                        onChange={handleOverallR}
                        view={applicableR === true ? true : false}
                      />

                    </div>
                  </div>
                ) : null}
                {evlPediaPain === "2" || evlPediaPain === "3" ? (
                  <div className={"col-md-6 col-sm-6" + LeftSide()}>
                    <div className="mb_20 satis">
                      <label class="form-check-label not-applicable4">
                        <input className="check_" type="checkbox" id="radio2" name="radio2" value={applicableL} checked={applicableL} style={{ marginRight: 10 }} onChange={(e) => handleapplicableL(e.target.checked)}></input>
                        {translation.patient_pedia_knee_satisfaction_q1_NA}
                      </label>
                      <HipScoreRangeSlider
                        maximun="1"
                        minimum="4"
                        sliderData={[
                          { score: "1", label: translation.patient_pedia_knee_satisfaction_q1_a1 },
                          { score: "2", label: translation.patient_pedia_knee_satisfaction_q1_a2 },
                          { score: "3", label: translation.patient_pedia_knee_satisfaction_q1_a3 },
                          { score: "4", label: translation.patient_pedia_knee_satisfaction_q1_a4 },

                        ]}
                        label={translation.patient_pedia_knee_satisfaction_q1 + LeftTrans(evlPediaPain, translation)}
                        classes=""
                        value={overallL}
                        onChange={handleOverallL}
                        view={applicableL === true ? true : false}
                      />

                    </div>
                  </div>
                ) : null}
              </div>

              <div className="row mb_20">
                {evlPediaPain === "1" || evlPediaPain === "3" ? (
                  <div className={"col-md-6 col-sm-6" + RightSide()}>
                    <div className="mb_20 satis">
                      <label class="form-check-label not-applicable4">
                        <input className="check_" type="checkbox" id="radio3" name="radio3" value={applicable1R} checked={applicable1R} style={{ marginRight: 10 }} onChange={(e) => handleapplicable1R(e.target.checked)}></input>
                        {translation.patient_pedia_knee_satisfaction_q2_NA}
                      </label>

                      <HipScoreRangeSlider
                        maximun="1"
                        minimum="4"
                        sliderData={[
                          { score: "1", label: translation.patient_pedia_knee_satisfaction_q2_a1 },
                          { score: "2", label: translation.patient_pedia_knee_satisfaction_q2_a2 },
                          { score: "3", label: translation.patient_pedia_knee_satisfaction_q2_a3 },
                          { score: "4", label: translation.patient_pedia_knee_satisfaction_q2_a4 },

                        ]}
                        label={translation.patient_pedia_knee_satisfaction_q2 + RigthTrans(evlPediaPain, translation)}
                        classes=""
                        value={improvingPainR}
                        onChange={handleImprovingPainR}
                        view={applicable1R === true ? true : false}
                      />


                    </div>
                  </div>
                ) : null}
                {evlPediaPain === "2" || evlPediaPain === "3" ? (
                  <div className={"col-md-6 col-sm-6" + LeftSide()}>
                    <div className="mb_20 satis">
                      <label class="form-check-label not-applicable4">
                        <input className="check_" type="checkbox" id="radio4" name="radio4" value={applicable1L} checked={applicable1L} style={{ marginRight: 10 }} onChange={(e) => handleapplicable1L(e.target.checked)}></input>
                        {translation.patient_pedia_knee_satisfaction_q2_NA}
                      </label>

                      <HipScoreRangeSlider
                        maximun="1"
                        minimum="4"
                        sliderData={[
                          { score: "1", label: translation.patient_pedia_knee_satisfaction_q2_a1 },
                          { score: "2", label: translation.patient_pedia_knee_satisfaction_q2_a2 },
                          { score: "3", label: translation.patient_pedia_knee_satisfaction_q2_a3 },
                          { score: "4", label: translation.patient_pedia_knee_satisfaction_q2_a4 },

                        ]}
                        label={translation.patient_pedia_knee_satisfaction_q2 + LeftTrans(evlPediaPain, translation)}
                        classes=""
                        value={improvingPainL}
                        onChange={handleImprovingPainL}
                        view={applicable1L === true ? true : false}
                      />

                    </div>
                  </div>
                ) : null}
              </div>
              <div className="row mb_20">
                {evlPediaPain === "1" || evlPediaPain === "3" ? (
                  <div className={"col-md-6 col-sm-6" + RightSide()}>
                    <div className="mb_20 satis">
                      <label class="form-check-label not-applicable4">
                        <input className="check_" type="checkbox" id="radio5" name="radio5" value={applicable2R} checked={applicable2R} style={{ marginRight: 10 }} onChange={(e) => handleapplicable2R(e.target.checked)}></input>

                        {translation.patient_pedia_knee_satisfaction_q3_NA}
                      </label>
                      <HipScoreRangeSlider
                        maximun="1"
                        minimum="4"
                        sliderData={[
                          { score: "1", label: translation.patient_pedia_knee_satisfaction_q3_a1 },
                          { score: "2", label: translation.patient_pedia_knee_satisfaction_q3_a2 },
                          { score: "3", label: translation.patient_pedia_knee_satisfaction_q3_a3 },
                          { score: "4", label: translation.patient_pedia_knee_satisfaction_q3_a4 },
                        ]}
                        label={translation.patient_pedia_knee_satisfaction_q3 + RigthTrans(evlPediaPain, translation)}
                        classes=""
                        value={improvingHomeR}
                        onChange={handleImprovingHomeR}
                        view={applicable2R === true ? true : false}
                      />

                    </div>
                  </div>
                ) : null}
                {evlPediaPain === "2" || evlPediaPain === "3" ? (
                  <div className={"col-md-6 col-sm-6" + LeftSide()}>
                    <div className="mb_20 satis">
                      <label class="form-check-label not-applicable4">
                        <input className="check_" type="checkbox" id="radio6" name="radio6" value={applicable2L} checked={applicable2L} style={{ marginRight: 10 }} onChange={(e) => handleapplicable2L(e.target.checked)}></input>

                        {translation.patient_pedia_knee_satisfaction_q3_NA}
                      </label>
                      <HipScoreRangeSlider
                        maximun="1"
                        minimum="4"
                        sliderData={[
                          { score: "1", label: translation.patient_pedia_knee_satisfaction_q3_a1 },
                          { score: "2", label: translation.patient_pedia_knee_satisfaction_q3_a2 },
                          { score: "3", label: translation.patient_pedia_knee_satisfaction_q3_a3 },
                          { score: "4", label: translation.patient_pedia_knee_satisfaction_q3_a4 },
                        ]}
                        label={translation.patient_pedia_knee_satisfaction_q3 + LeftTrans(evlPediaPain, translation)}
                        classes=""
                        value={improvingHomeL}
                        onChange={handleImprovingHomeL}
                        view={applicable2L === true ? true : false}
                      />

                    </div>
                  </div>
                ) : null}
              </div>
              <div className="row mb_20">
                {evlPediaPain === "1" || evlPediaPain === "3" ? (
                  <div className={"col-md-6 col-sm-6" + RightSide()}>
                    <div className="mb_20 satis">
                      <label class="form-check-label not-applicable4">
                        <input className="check_" type="checkbox" id="radio7" name="radio7" value={applicable3R} checked={applicable3R} style={{ marginRight: 10 }} onChange={(e) => handleapplicable3R(e.target.checked)}></input>

                        {translation.patient_pedia_knee_satisfaction_q4_NA}
                      </label>

                      <HipScoreRangeSlider
                        maximun="1"
                        minimum="4"
                        sliderData={[
                          { score: "1", label: translation.patient_pedia_knee_satisfaction_q4_a1 },
                          { score: "2", label: translation.patient_pedia_knee_satisfaction_q4_a2 },
                          { score: "3", label: translation.patient_pedia_knee_satisfaction_q4_a3 },
                          { score: "4", label: translation.patient_pedia_knee_satisfaction_q4_a4 },

                        ]}
                        label={translation.patient_pedia_knee_satisfaction_q4 + RigthTrans(evlPediaPain, translation)}
                        classes=""
                        value={improvingActivitiesR}
                        onChange={handleImprovingActivitiesR}
                        view={applicable3R === true ? true : false}
                      />

                    </div>
                  </div>
                ) : null}
                {evlPediaPain === "2" || evlPediaPain === "3" ? (
                  <div className={"col-md-6 col-sm-6" + LeftSide()}>
                    <div className="mb_20 satis">
                      <label class="form-check-label not-applicable4">
                        <input className="check_" type="checkbox" id="radio8" name="radio8" value={applicable3L} checked={applicable3L} style={{ marginRight: 10 }} onChange={(e) => handleapplicable3L(e.target.checked)}></input>

                        {translation.patient_pedia_knee_satisfaction_q4_NA}
                      </label>
                      <HipScoreRangeSlider
                        maximun="1"
                        minimum="4"
                        sliderData={[
                          { score: "1", label: translation.patient_pedia_knee_satisfaction_q4_a1 },
                          { score: "2", label: translation.patient_pedia_knee_satisfaction_q4_a2 },
                          { score: "3", label: translation.patient_pedia_knee_satisfaction_q4_a3 },
                          { score: "4", label: translation.patient_pedia_knee_satisfaction_q4_a4 },

                        ]}
                        label={translation.patient_pedia_knee_satisfaction_q4 + LeftTrans(evlPediaPain, translation)}
                        classes=""
                        value={improvingActivitiesL}
                        onChange={handleImprovingActivitiesL}
                        view={applicable3L === true ? true : false}
                      />

                    </div>
                  </div>
                ) : null}
              </div>
              {doctorEnable === "true" && (
            <>
              {doctorStatus ? (
                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6">
                  </div>
                  <div className="col-md-6 col-sm-6 text-end">
                    <button
                      className="next_btn btn_fill me-3"
                      onClick={() => handleNext()}
                    >
                      {translation.next_button}
                      <span>
                        <img src={nextIcon} alt="" />
                        {/* <img src={nextWhiteIcon} alt="" /> */}
                      </span>
                    </button>
                    <button
                      className="next_btn btn_fill"
                      onClick={() => handleSubmit()}
                    >
                      {translation.submit}
                      <span>
                        <img src={nextIcon} alt="" />
                        {/* <img src={nextWhiteIcon} alt="" /> */}
                      </span>
                    </button>
                  </div>
                </div>
              ) : (
                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6">
                  </div>
                  <div className="col-md-6 col-sm-6 text-end">
                    <button
                      className="next_btn btn_fill"
                      onClick={() => handleSubmit()}
                    >
                      {"Submit"}
                      <span>
                        <img src={nextIcon} alt="" />
                        {/* <img src={nextWhiteIcon} alt="" /> */}
                      </span>
                    </button>
                  </div>
                </div>
              )}
            </>
          )}
              {doctorEnable !== "true" && (
                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6 col-6">
                    <Link
                      to={"/pedia-knee-quality-of-life/" + rId}
                      className="assm_back_btn"
                    >
                      <img src={backIcon} alt="" />
                      <span>{translation.back_button}</span>
                    </Link>
                  </div>
                  <div className="col-md-6 col-sm-6 col-6 text-end">
                    <button
                      className="next_btn btn_fill"
                      onClick={continueRedirect}
                    >
                      {translation.next_button}
                      <span>
                        <img src={nextIcon} alt="" />
                        <img src={nextWhiteIcon} alt="" />
                      </span>
                    </button>
                  </div>
                </div>
              )}


            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PediaKneeSatisfaction;
