import React from "react";
import "../../../css/ViewTreatmentForm.css";
import useTranslation from "../../customHooks/translations";

export default function HandWristFunction({ handFunction, AllData }) {
  const translations = useTranslation();
  return (
    <>
      <div className="whitebox padding-none">
        <div className="treatment-content">
          <div className="treatment-row-header">
            3. {translations.patient_hand_function}
          </div>
          <div className="treatment-row">
            <div className="treatment-col">
              <label>
                {translations.patient_hand_function_q1}({translations.Right})
              </label>
              <p>
                {handFunction.overallR === "4"
                  ? translations.patient_hand_function_q1_a5
                  : handFunction.overallR === "3"
                  ? translations.patient_hand_function_q1_a4
                  : handFunction.overallR === "2"
                  ? translations.patient_hand_function_q1_a3
                  : handFunction.overallR === "1"
                  ? translations.patient_hand_function_q1_a2
                  : handFunction.overallR === "0"
                  ? translations.patient_hand_function_q1_a1
                  : ""}
              </p>
            </div>
            <div className="treatment-col">
              <label>
                {translations.patient_hand_function_q1}({translations.Left})
              </label>
              <p>
                {handFunction.overallL === "4"
                  ? translations.patient_hand_function_q1_a5
                  : handFunction.overallL === "3"
                  ? translations.patient_hand_function_q1_a4
                  : handFunction.overallL === "2"
                  ? translations.patient_hand_function_q1_a3
                  : handFunction.overallL === "1"
                  ? translations.patient_hand_function_q1_a2
                  : handFunction.overallL === "0"
                  ? translations.patient_hand_function_q1_a1
                  : ""}
              </p>
            </div>
          </div>
          <div className="treatment-row">
            <div className="treatment-col">
              <label>
                {translations.patient_hand_function_q2}({translations.Right})
              </label>
              <p>
                {handFunction.oftenR === "4"
                  ? translations.patient_hand_function_q2_a5
                  : handFunction.oftenR === "3"
                  ? translations.patient_hand_function_q2_a4
                  : handFunction.oftenR === "2"
                  ? translations.patient_hand_function_q2_a3
                  : handFunction.oftenR === "1"
                  ? translations.patient_hand_function_q2_a2
                  : handFunction.oftenR === "0"
                  ? translations.patient_hand_function_q2_a1
                  : ""}
              </p>
            </div>
            <div className="treatment-col">
              <label>
                {translations.patient_hand_function_q2}({translations.Left})
              </label>
              <p>
                {handFunction.oftenL === "4"
                  ? translations.patient_hand_function_q2_a5
                  : handFunction.oftenL === "3"
                  ? translations.patient_hand_function_q2_a4
                  : handFunction.oftenL === "2"
                  ? translations.patient_hand_function_q2_a3
                  : handFunction.oftenL === "1"
                  ? translations.patient_hand_function_q2_a2
                  : handFunction.oftenL === "0"
                  ? translations.patient_hand_function_q2_a1
                  : ""}
              </p>
            </div>
          </div>
          <div className="treatment-row">
            <div className="treatment-col">
              <label>
                {translations.patient_hand_function_q3} ({translations.Right})
              </label>
              <p>
                {handFunction.longerR === "4"
                  ? translations.patient_hand_function_q3_a5
                  : handFunction.longerR === "3"
                  ? translations.patient_hand_function_q3_a4
                  : handFunction.longerR === "2"
                  ? translations.patient_hand_function_q3_a3
                  : handFunction.longerR === "1"
                  ? translations.patient_hand_function_q3_a2
                  : handFunction.longerR === "0"
                  ? translations.patient_hand_function_q3_a1
                  : ""}
              </p>
            </div>
            <div className="treatment-col">
              <label>
                {translations.patient_hand_function_q3} ({translations.Left})
              </label>
              <p>
                {handFunction.longerL === "4"
                  ? translations.patient_hand_function_q3_a5
                  : handFunction.longerL === "3"
                  ? translations.patient_hand_function_q3_a4
                  : handFunction.longerL === "2"
                  ? translations.patient_hand_function_q3_a4
                  : handFunction.longerL === "1"
                  ? translations.patient_hand_function_q3_a2
                  : handFunction.longerL === "0"
                  ? translations.patient_hand_function_q3_a1
                  : ""}
              </p>
            </div>
          </div>
          <div className="treatment-row">
            <div className="treatment-table">
              <table cellpadding="0" cellspacing="0">
                <thead>
                  <tr>
                    <th></th>
                    <th>{translations.Right_Hand_nd_Wrist}</th>
                    <th>{translations.Left_Hand_nd_Wrist}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{translations.patient_hand_function_q4} :</td>
                    <td>{handFunction.tightR}</td>
                    <td>{handFunction.tightL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_hand_function_q5} :</td>
                    <td>{handFunction.fryingR}</td>
                    <td>{handFunction.fryingL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_hand_function_q6} :</td>
                    <td>{handFunction.buttonR}</td>
                    <td>{handFunction.buttonL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_hand_function_q7} :</td>
                    <td>{handFunction.householdR}</td>
                    <td>{handFunction.householdL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_hand_function_q8} :</td>
                    <td>{handFunction.carryR}</td>
                    <td>{handFunction.carryL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_hand_function_q9} :</td>
                    <td>{handFunction.washR}</td>
                    <td>{handFunction.washL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_hand_function_q10} :</td>
                    <td>{handFunction.knifeR}</td>
                    <td>{handFunction.knifeL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_hand_function_q11} :</td>
                    <td>{handFunction.activitiesR}</td>
                    <td>{handFunction.activitiesL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_hand_function_q12} :</td>
                    <td>{handFunction.sleepR}</td>
                    <td>{handFunction.sleepL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_hand_function_q13} :</td>
                    <td>{handFunction.SpecifyR}</td>
                    <td>{handFunction.SpecifyL}</td>
                  </tr>

                  <tr>
                    <td>{translations.patient_hand_function_q14} :</td>
                    {/* <td>{handFunction.textL}</td> */}
                    <td>
                      {handFunction.textL !== "" ? (
                        <>
                          {handFunction.textL}
                          <br></br>
                        </>
                      ) : (
                        <></>
                      )}

                      {handFunction.checkR === true ? (
                        <>{translations.patient_hand_function_do_dot_work}</>
                      ) : (
                        <></>
                      )}
                    </td>
                  </tr>

                  {!handFunction.checkR && (
                    <>
                      <tr>
                        <td>{translations.patient_hand_function_q15} :</td>
                        <td>{handFunction.usualR}</td>
                        <td>{handFunction.usualL}</td>
                      </tr>
                      <tr>
                        <td>{translations.patient_hand_function_q16} :</td>
                        <td>{handFunction.workR}</td>
                        <td>{handFunction.workL}</td>
                      </tr>
                      <tr>
                        <td>{translations.patient_hand_function_q17} :</td>
                        <td>{handFunction.spendingR}</td>
                        <td>{handFunction.spendingL}</td>
                      </tr>
                    </>
                  )}

                  <tr>
                    <td>{translations.patient_hand_function_q18}:</td>
                    <td>{handFunction.SpecifysportR}</td>
                    <td> {handFunction.SpecifysportL} </td>

                    {/* </td>

                                        <td>{handFunction.textsportL}<br />
                                            {handFunction.SpecifysportL}
                                        </td> */}
                  </tr>
                  <tr>
                    <td>{translations.patient_hand_function_q19} :</td>
                    {/* <td>{handFunction.textsportR}</td> */}
                    {/* <td>{handFunction.textsportL}</td> */}
                    <td>
                      {handFunction.textsportL !== "" ? (
                        <>
                          {handFunction.textsportL}
                          <br></br>
                        </>
                      ) : (
                        <></>
                      )}
                      {handFunction.checksportR === true ? (
                        <>{translations.patient_hand_function_donot_play}</>
                      ) : (
                        <></>
                      )}
                    </td>
                  </tr>

                  {!handFunction.checksportR && (
                    <>
                      <tr>
                        <td>{translations.patient_hand_function_20} :</td>
                        <td>{handFunction.instrumentR}</td>
                        <td>{handFunction.instrumentL}</td>
                      </tr>
                      <tr>
                        <td>{translations.patient_hand_function_q21} :</td>
                        <td>{handFunction.playingR}</td>
                        <td>{handFunction.playingL}</td>
                      </tr>
                      <tr>
                        <td>{translations.patient_hand_function_q22} :</td>
                        <td>{handFunction.spendR}</td>
                        <td>{handFunction.spendL}</td>
                      </tr>
                    </>
                  )}

                  <tr>
                    <td>{translations.patient_hand_function_q23} :</td>
                    <td>
                      {handFunction.socialR === "4"
                        ? translations.patient_hand_function_q23_a5
                        : handFunction.socialR === "3"
                        ? translations.patient_hand_function_q23_a4
                        : handFunction.socialR === "2"
                        ? translations.patient_hand_function_q23_a3
                        : handFunction.socialR === "1"
                        ? translations.patient_hand_function_q23_a2
                        : handFunction.socialR === "0"
                        ? translations.patient_hand_function_q23_a1
                        : ""}
                    </td>
                    <td>
                      {handFunction.socialL === "4"
                        ? translations.patient_hand_function_q23_a5
                        : handFunction.socialL === "3"
                        ? translations.patient_hand_function_q23_a4
                        : handFunction.socialL === "2"
                        ? translations.patient_hand_function_q23_a3
                        : handFunction.socialL === "1"
                        ? translations.patient_hand_function_q23_a2
                        : handFunction.socialL === "0"
                        ? translations.patient_hand_function_q23_a1
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <td>{translations.patient_hand_function_q24} :</td>
                    <td>
                      {handFunction.limitR === "4"
                        ? translations.patient_hand_function_q24_a5
                        : handFunction.limitR === "3"
                        ? translations.patient_hand_function_q24_a4
                        : handFunction.limitR === "2"
                        ? translations.patient_hand_function_q24_a3
                        : handFunction.limitR === "1"
                        ? translations.patient_hand_function_q24_a2
                        : handFunction.limitR === "0"
                        ? translations.patient_hand_function_q24_a1
                        : ""}
                    </td>
                    <td>
                      {handFunction.limitL === "4"
                        ? translations.patient_hand_function_q24_a5
                        : handFunction.limitL === "3"
                        ? translations.patient_hand_function_q24_a4
                        : handFunction.limitL === "2"
                        ? translations.patient_hand_function_q24_a3
                        : handFunction.limitL === "1"
                        ? translations.patient_hand_function_q24_a2
                        : handFunction.limitL === "0"
                        ? translations.patient_hand_function_q24_a1
                        : ""}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
