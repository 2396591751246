import React, { useState, useEffect } from "react";
import AssessmentHeader from "../../../custom/assessment-header/AssessmentHeader";
import HeaderInnerBreadcrumb from "../../../custom/heade-inner-breadcrumb/HeaderInnerBreadcrumb";
import KneeScoreRangeSlider from "../knee-score-range-slider/KneeScoreRangeSlider";
import FullDropdown from "./Fulldropdown";
import InputField from "../../../custom/inputfield/InputField";
import { Link, useNavigate, useParams } from "react-router-dom";
import backIcon from "../../../../images/back.png";
import nextWhiteIcon from "../../../../images/next-white.png";
import nextIcon from "../../../../images/next.png";
import { useDispatch, useSelector } from "react-redux";
import Handwrist from "../../../../images/handwrist.png";
import useAxios from "../../../../axiosinstance";
import useTranslation from "../../../customHooks/doctorTranslation";
import HandWristTab from "../../tab-section/HandWristTab";
import { useSearchParams } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
// import assessmentStyles from "./PatientAssessment.module.css";
import assessmentStyles from "../../shoulder-forms/doctorefeedback/PatientAssessment.module.css";
import SubmitModel from "../../SubmissionModel/SubmitModel"
import {
  updateHandWristScores,
  updateHandWristfunctionFieldScore,
  updateHandWristfunctionScores,
  updateHandWristPainScores,
  updateHandWristSymptomsScores,
  updateHandWristqolScores,
  updateHandWristsatisfactionScores,
  updateHandWristFeedbackScores,
  updateHandWristUramScores,
  updateHandWristbctqScores,
  updateAllData,
  UPDATE_HAND_FEEDBACK_DATA,
} from "../../../../redux/actions/handWrist";
import HandWristDocFeedBackRangeSlider from "../../../custom/hand-wrist-doctor-feedback-rangeslider/HandWristDocFeedBackRangeSlider";
import FormVerticalMenu from "./FormVerticalMenu";
import RadioSwitch from "../../../custom/radio-switch/RadioSwitch";
import DoctorNameCustom from "../../../custom/name/DoctorNameCustom";
import ElbowFeedbackScoreRangeSlider from "../../../custom/elbow-doctorfeedback-score-range-slider/ElbowFeedbackScoreRangeSlider";
import AssessmentKgKgWidget from "../../../custom/assessment-widgets/AssessmentKgKgWidget";
import Promsschudle from "../../SubmissionModel/Promsschudle";
import CustomToaster from "../../../custom/toaster/CustomToaster";
import LoadingSpinner from "../../../custom/loader/LoadingSpinner";
export default function HandWristSurgeon2() {
  const translation = useTranslation();
  const axiosInstance = useAxios();
  const dispatch = useDispatch();
  const { rId } = useParams();
  const [searchParams] = useSearchParams();
  let doctorEnable = searchParams.get("d");
  const queryParams = new URLSearchParams(window.location.search);
  const dId = queryParams.get("doctor");
  const pId = queryParams.get("patient");
  const [Weinstein1R, setWeinstein1R] = useState("0");
  const [Weinstein2R, setWeinstein2R] = useState("0");
  const [Weinstein3R, setWeinstein3R] = useState("0");
  const [Weinstein4R, setWeinstein4R] = useState("0");
  const [Weinstein5R, setWeinstein5R] = useState("0");
  const [Weinstein6R, setWeinstein6R] = useState("0");
  const [Weinstein7R, setWeinstein7R] = useState("0");

  const [discrimination1R, setdiscrimination1R] = useState("0");
  const [discrimination2R, setdiscrimination2R] = useState("0");
  const [discrimination3R, setdiscrimination3R] = useState("0");
  const [discrimination4R, setdiscrimination4R] = useState("0");
  const [discrimination5R, setdiscrimination5R] = useState("0");
  const [discrimination6R, setdiscrimination6R] = useState("0");
  const [discrimination7R, setdiscrimination7R] = useState("0");

  const [Weinstein1L, setWeinstein1L] = useState("0");
  const [Weinstein2L, setWeinstein2L] = useState("0");
  const [Weinstein3L, setWeinstein3L] = useState("0");
  const [Weinstein4L, setWeinstein4L] = useState("0");
  const [Weinstein5L, setWeinstein5L] = useState("0");
  const [Weinstein6L, setWeinstein6L] = useState("0");
  const [Weinstein7L, setWeinstein7L] = useState("0");

  const [discrimination1L, setdiscrimination1L] = useState("0");
  const [discrimination2L, setdiscrimination2L] = useState("0");
  const [discrimination3L, setdiscrimination3L] = useState("0");
  const [discrimination4L, setdiscrimination4L] = useState("0");
  const [discrimination5L, setdiscrimination5L] = useState("0");
  const [discrimination6L, setdiscrimination6L] = useState("0");
  const [discrimination7L, setdiscrimination7L] = useState("0");

  const [wristR, setWristR] = useState("");
  const [wristL, setWristL] = useState("");
  const [ThumbR, setThumbR] = useState("");
  const [ThumbL, setThumbL] = useState("");
  const [FingerR, setFingerR] = useState("");
  const [FingerL, setFingerL] = useState("");
  const [ThumbpalmarR, setThumbpalmarR] = useState("");
  const [ThumbpalmarL, setThumbpalmarL] = useState("");
  const [ThumbsmallR, setThumbsmallR] = useState("");
  const [ThumbsmallL, setThumbsmallL] = useState("");
  const [FingerflexionR, setFingerflexionR] = useState("");
  const [FingerflexionL, setFingerflexionL] = useState("");
  const [FingerabductionR, setFingerabductionR] = useState("");
  const [FingerabductionL, setFingerabductionL] = useState("");
  const [OksingR, setOksingR] = useState("");
  const [OksingL, setOksingL] = useState("");
  const [GripR, setGripR] = useState("");
  const [GripL, setGripL] = useState("");
  const [SpecialtestsR, setSpecialtestsR] = useState("");
  const [SpecialtestsTextR, setSpecialtestsTextR] = useState("");
  const [SpecialtestsL, setSpecialtestsL] = useState("");
  const [SpecialtestsTextL, setSpecialtestsTextL] = useState("");

  const [Grind1LeftVal, setGrind1LeftVal] = useState("");
  const [Grind2LeftVal, setGrind2LeftVal] = useState("");
  const [Grind3LeftVal, setGrind3LeftVal] = useState("");
  const [Grind4LeftVal, setGrind4LeftVal] = useState("");
  const [Grind5LeftVal, setGrind5LeftVal] = useState("");
  const [Grind6LeftVal, setGrind6LeftVal] = useState("");
  const [Grind7LeftVal, setGrind7LeftVal] = useState("");
  const [Grind8LeftVal, setGrind8LeftVal] = useState("");
  const [Grind9LeftVal, setGrind9LeftVal] = useState("");
  const [Grind10LeftVal, setGrind10LeftVal] = useState("");
  const [Grind11LeftVal, setGrind11LeftVal] = useState("");
  const [Grind12LeftVal, setGrind12LeftVal] = useState("");
  const [Grind13LeftVal, setGrind13LeftVal] = useState("");
  const [Grind14LeftVal, setGrind14LeftVal] = useState("");
  const [Grind15LeftVal, setGrind15LeftVal] = useState("");
  const [Grind16LeftVal, setGrind16LeftVal] = useState("");
  const [Grind17LeftVal, setGrind17LeftVal] = useState("");
  const [Grind18LeftVal, setGrind18LeftVal] = useState("");
  const [Grind19LeftVal, setGrind19LeftVal] = useState("");
  const [Grind20LeftVal, setGrind20LeftVal] = useState("");

  const [Grind21LeftVal, setGrind21LeftVal] = useState("");
  const [Grind22LeftVal, setGrind22LeftVal] = useState("");
  const [Grind23LeftVal, setGrind23LeftVal] = useState("");
  const [Grind24LeftVal, setGrind24LeftVal] = useState("");
  const [Grind25LeftVal, setGrind25LeftVal] = useState("");
  const [Grind26LeftVal, setGrind26LeftVal] = useState("");
  const [Grind27LeftVal, setGrind27LeftVal] = useState("");
  const [Grind28LeftVal, setGrind28LeftVal] = useState("");
  const [Grind29LeftVal, setGrind29LeftVal] = useState("");
  const [Grind30LeftVal, setGrind30LeftVal] = useState("");
  const [Grind31LeftVal, setGrind31LeftVal] = useState("");
  const [Grind32LeftVal, setGrind32LeftVal] = useState("");
  const [Grind33LeftVal, setGrind33LeftVal] = useState("");
  const [Grind34LeftVal, setGrind34LeftVal] = useState("");
  const [Grind35LeftVal, setGrind35LeftVal] = useState("");
  const [Grind36LeftVal, setGrind36LeftVal] = useState("");
  const [Grind37LeftVal, setGrind37LeftVal] = useState("");
  const [Grind38LeftVal, setGrind38LeftVal] = useState("");
  const [Grind39LeftVal, setGrind39LeftVal] = useState("");
  const [Grind40LeftVal, setGrind40LeftVal] = useState("");
  const [Grind41LeftVal, setGrind41LeftVal] = useState("");
  const [Grind42LeftVal, setGrind42LeftVal] = useState("");
  const [Grind43LeftVal, setGrind43LeftVal] = useState("");
  const [Grind44LeftVal, setGrind44LeftVal] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [chk, setChk] = useState(false);
  const docFeedback = useSelector((state) => state.handWrist.doctorFeedback[0]);
  const save = () => {
    axiosInstance.current
      .get(`extapp/forms/all_forms?resource_id=${rId}&type=23`)
      .then((res) => {
        console.log(res.data.data2);
        dispatch(updateHandWristPainScores(res.data.data2[0].painScore));
        dispatch(
          updateHandWristSymptomsScores(res.data.data2[0].symptomsScore)
        );
        dispatch(updateHandWristScores(res.data.data2[0].evaluated));
        dispatch(
          updateHandWristfunctionScores(res.data.data2[0].functionScore)
        );
        dispatch(updateHandWristqolScores(res.data.data2[0].qolScore));
        dispatch(updateHandWristUramScores(res.data.data2[0].uramScore));
        dispatch(updateHandWristbctqScores(res.data.data2[0].bctq));

        dispatch(
          updateHandWristsatisfactionScores(res.data.data2[0].satisfactionScore)
        );
        dispatch(UPDATE_HAND_FEEDBACK_DATA(res.data.data2[0].doctorFeedback));
        setChk(true);
      });
  };

  useEffect(() => {
    save();
  }, []);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
  useEffect(() => {
    setWeinstein1R(docFeedback.Weinstein1R);
    setWeinstein2R(docFeedback.Weinstein2R);
    setWeinstein3R(docFeedback.Weinstein3R);
    setWeinstein4R(docFeedback.Weinstein4R);
    setWeinstein5R(docFeedback.Weinstein5R);
    setWeinstein6R(docFeedback.Weinstein6R);
    setWeinstein7R(docFeedback.Weinstein7R);
    setdiscrimination1R(docFeedback.discrimination1R);
    setdiscrimination2R(docFeedback.discrimination2R);
    setdiscrimination3R(docFeedback.discrimination3R);
    setdiscrimination4R(docFeedback.discrimination4R);
    setdiscrimination5R(docFeedback.discrimination5R);
    setdiscrimination6R(docFeedback.discrimination6R);
    setdiscrimination7R(docFeedback.discrimination7R);
    setWeinstein1L(docFeedback.Weinstein1L);
    setWeinstein2L(docFeedback.Weinstein2L);
    setWeinstein3L(docFeedback.Weinstein3L);
    setWeinstein4L(docFeedback.Weinstein4L);
    setWeinstein5L(docFeedback.Weinstein5L);
    setWeinstein6L(docFeedback.Weinstein6L);
    setWeinstein7L(docFeedback.Weinstein7L);
    setdiscrimination1L(docFeedback.discrimination1L);
    setdiscrimination2L(docFeedback.discrimination2L);
    setdiscrimination3L(docFeedback.discrimination3L);
    setdiscrimination4L(docFeedback.discrimination4L);
    setdiscrimination5L(docFeedback.discrimination5L);
    setdiscrimination6L(docFeedback.discrimination6L);
    setdiscrimination7L(docFeedback.discrimination7L);
    setWristR(docFeedback.wristR);
    setWristL(docFeedback.wristL);
    setThumbR(docFeedback.ThumbR);
    setThumbL(docFeedback.ThumbL);
    setFingerR(docFeedback.FingerR);
    setFingerL(docFeedback.FingerL);
    setThumbpalmarR(docFeedback.ThumbpalmarR);
    setThumbpalmarL(docFeedback.ThumbpalmarL);
    setThumbsmallR(docFeedback.ThumbsmallR);
    setThumbsmallL(docFeedback.ThumbsmallL);
    setFingerflexionR(docFeedback.FingerflexionR);
    setFingerflexionL(docFeedback.FingerflexionL);
    setFingerabductionR(docFeedback.FingerabductionR);
    setFingerabductionL(docFeedback.FingerabductionL);
    setOksingR(docFeedback.OksingR);
    setOksingL(docFeedback.OksingL);
    setGripR(docFeedback.GripR);
    setGripL(docFeedback.GripL);
    setSpecialtestsR(docFeedback.SpecialtestsR);
    setSpecialtestsTextR(docFeedback.SpecialtestsTextR);
    setSpecialtestsL(docFeedback.SpecialtestsL);
    setSpecialtestsTextL(docFeedback.SpecialtestsTextL);

    setGrind1LeftVal(docFeedback.Grind1LeftVal);
    setGrind2LeftVal(docFeedback.Grind2LeftVal);
    setGrind3LeftVal(docFeedback.Grind3LeftVal);
    setGrind4LeftVal(docFeedback.Grind4LeftVal);
    setGrind5LeftVal(docFeedback.Grind5LeftVal);
    setGrind6LeftVal(docFeedback.Grind6LeftVal);
    setGrind7LeftVal(docFeedback.Grind7LeftVal);
    setGrind8LeftVal(docFeedback.Grind8LeftVal);
    setGrind9LeftVal(docFeedback.Grind9LeftVal);
    setGrind10LeftVal(docFeedback.Grind10LeftVal);
    setGrind11LeftVal(docFeedback.Grind11LeftVal);
    setGrind12LeftVal(docFeedback.Grind12LeftVal);
    setGrind13LeftVal(docFeedback.Grind13LeftVal);
    setGrind14LeftVal(docFeedback.Grind14LeftVal);
    setGrind15LeftVal(docFeedback.Grind15LeftVal);
    setGrind16LeftVal(docFeedback.Grind16LeftVal);
    setGrind17LeftVal(docFeedback.Grind17LeftVal);
    setGrind18LeftVal(docFeedback.Grind18LeftVal);
    setGrind19LeftVal(docFeedback.Grind19LeftVal);
    setGrind20LeftVal(docFeedback.Grind20LeftVal);

    setGrind21LeftVal(docFeedback.Grind21LeftVal);
    setGrind22LeftVal(docFeedback.Grind22LeftVal);
    setGrind23LeftVal(docFeedback.Grind23LeftVal);
    setGrind24LeftVal(docFeedback.Grind24LeftVal);
    setGrind25LeftVal(docFeedback.Grind25LeftVal);
    setGrind26LeftVal(docFeedback.Grind26LeftVal);
    setGrind27LeftVal(docFeedback.Grind27LeftVal);
    setGrind28LeftVal(docFeedback.Grind28LeftVal);
    setGrind29LeftVal(docFeedback.Grind29LeftVal);
    setGrind30LeftVal(docFeedback.Grind30LeftVal);
    setGrind31LeftVal(docFeedback.Grind31LeftVal);
    setGrind32LeftVal(docFeedback.Grind32LeftVal);
    setGrind33LeftVal(docFeedback.Grind33LeftVal);
    setGrind34LeftVal(docFeedback.Grind34LeftVal);
    setGrind35LeftVal(docFeedback.Grind35LeftVal);
    setGrind36LeftVal(docFeedback.Grind36LeftVal);
    setGrind37LeftVal(docFeedback.Grind37LeftVal);
    setGrind38LeftVal(docFeedback.Grind38LeftVal);
    setGrind39LeftVal(docFeedback.Grind39LeftVal);
    setGrind40LeftVal(docFeedback.Grind40LeftVal);
    setGrind41LeftVal(docFeedback.Grind41LeftVal);
    setGrind42LeftVal(docFeedback.Grind42LeftVal);
    setGrind43LeftVal(docFeedback.Grind43LeftVal);
    setGrind44LeftVal(docFeedback.Grind44LeftVal);
  }, [docFeedback]);
  const [displayConfirmationModal, setDisplayConfirmationModal] =
  useState(false);
  const json = useSelector((state) => state.handWrist.doctorFeedback);
// --
  const [msg, setMsg] = useState('');
  const [msgType, setmsgType] = useState('');
  const updateDate = async () => {
    let response = await axiosInstance.current.put(
      `extapp/forms/update_doctor_feedback?resource_id=${rId}&action=1&ak_dc=${dId}`,
      json,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
      setMsg(translation.update)
        setmsgType('success')
        setTimeout(() => {
          setMsg('')
          setmsgType('success')
        }, 2000);
        setDisplayConfirmationModal(true)
      // window.open(
      //   process.env.REACT_APP_DOCTOR_URL + 'patient-list',
      //   "_blank"
      // );
      // window.close()

      // window.location.href = process.env.REACT_APP_DOCTOR_URL + "patient-list";
      setDisplayConfirmationModal(true)
    }
  };

  const handleWeinstein1R = (e) => {
    setWeinstein1R(e);
    dispatch(
      updateHandWristFeedbackScores({
        key: "Weinstein1R",
        value: e,
      })
    );
  };
  const handleWeinstein2R = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "Weinstein2R",
        value: e,
      })
    );
    setWeinstein2R(e);
  };
  const handleWeinstein3R = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "Weinstein3R",
        value: e,
      })
    );
    setWeinstein3R(e);
  };
  const handleWeinstein4R = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "Weinstein4R",
        value: e,
      })
    );
    setWeinstein4R(e);
  };
  const handleWeinstein5R = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "Weinstein5R",
        value: e,
      })
    );
    setWeinstein5R(e);
  };
  const handleWeinstein6R = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "Weinstein6R",
        value: e,
      })
    );
    setWeinstein6R(e);
  };
  const handleWeinstein7R = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "Weinstein7R",
        value: e,
      })
    );
    setWeinstein7R(e);
  };
  const handlediscrimination1R = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "discrimination1R",
        value: e,
      })
    );
    setdiscrimination1R(e);
  };
  const handlediscrimination2R = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "discrimination2R",
        value: e,
      })
    );
    setdiscrimination2R(e);
  };
  const handlediscrimination3R = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "discrimination3R",
        value: e,
      })
    );
    setdiscrimination3R(e);
  };
  const handlediscrimination4R = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "discrimination4R",
        value: e,
      })
    );
    setdiscrimination4R(e);
  };
  const handlediscrimination5R = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "discrimination5R",
        value: e,
      })
    );
    setdiscrimination5R(e);
  };
  const handlediscrimination6R = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "discrimination6R",
        value: e,
      })
    );
    setdiscrimination6R(e);
  };
  const handlediscrimination7R = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "discrimination7R",
        value: e,
      })
    );
    setdiscrimination7R(e);
  };

  const handleWeinstein1L = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "Weinstein1L",
        value: e,
      })
    );
    setWeinstein1L(e);
  };
  const handleWeinstein2L = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "Weinstein2L",
        value: e,
      })
    );
    setWeinstein2L(e);
  };
  const handleWeinstein3L = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "Weinstein3L",
        value: e,
      })
    );
    setWeinstein3L(e);
  };
  const handleWeinstein4L = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "Weinstein4L",
        value: e,
      })
    );
    setWeinstein4L(e);
  };
  const handleWeinstein5L = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "Weinstein5L",
        value: e,
      })
    );
    setWeinstein5L(e);
  };
  const handleWeinstein6L = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "Weinstein6L",
        value: e,
      })
    );
    setWeinstein6L(e);
  };
  const handleWeinstein7L = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "Weinstein7L",
        value: e,
      })
    );
    setWeinstein7L(e);
  };

  const handlediscrimination1L = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "discrimination1L",
        value: e,
      })
    );
    setdiscrimination1L(e);
  };
  const handlediscrimination2L = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "discrimination2L",
        value: e,
      })
    );
    setdiscrimination2L(e);
  };
  const handlediscrimination3L = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "discrimination3L",
        value: e,
      })
    );
    setdiscrimination3L(e);
  };
  const handlediscrimination4L = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "discrimination4L",
        value: e,
      })
    );
    setdiscrimination4L(e);
  };
  const handlediscrimination5L = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "discrimination5L",
        value: e,
      })
    );
    setdiscrimination5L(e);
  };
  const handlediscrimination6L = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "discrimination6L",
        value: e,
      })
    );
    setdiscrimination6L(e);
  };
  const handlediscrimination7L = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "discrimination7L",
        value: e,
      })
    );
    setdiscrimination7L(e);
  };

  const handlewristR = (e) => {
    setWristR(e);
    dispatch(
      updateHandWristFeedbackScores({
        key: "wristR",
        value: e,
      })
    );
  };

  const handlewristL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "wristL",
        value: e,
      })
    );
    setWristL(e);
  };

  const handleThumbR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "ThumbR",
        value: e,
      })
    );
    setThumbR(e);
  };

  const handleThumbL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "ThumbL",
        value: e,
      })
    );
    setThumbL(e);
  };
  const handleFingerR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "FingerR",
        value: e,
      })
    );
    setFingerR(e);
  };

  const handleFingerL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "FingerL",
        value: e,
      })
    );
    setFingerL(e);
  };
  const handleThumbpalmarR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "ThumbpalmarR",
        value: e,
      })
    );
    setThumbpalmarR(e);
  };

  const handleThumbpalmarL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "ThumbpalmarL",
        value: e,
      })
    );
    setThumbpalmarL(e);
  };
  const handleThumbsmallR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "ThumbsmallR",
        value: e,
      })
    );
    setThumbsmallR(e);
  };

  const handleThumbsmallL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "ThumbsmallL",
        value: e,
      })
    );
    setThumbsmallL(e);
  };
  const handleFingerflexionR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "FingerflexionR",
        value: e,
      })
    );
    setFingerflexionR(e);
  };

  const handleFingerflexionL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "FingerflexionL",
        value: e,
      })
    );
    setFingerflexionL(e);
  };
  const handleFingerabductionR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "FingerabductionR",
        value: e,
      })
    );
    setFingerabductionR(e);
  };

  const handleFingerabductionL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "FingerabductionL",
        value: e,
      })
    );
    setFingerabductionL(e);
  };
  const handleOksingR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "OksingR",
        value: e,
      })
    );
    setOksingR(e);
  };

  const handleOksingL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "OksingL",
        value: e,
      })
    );
    setOksingL(e);
  };
  const handleGripR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "GripR",
        value: e,
      })
    );
    setGripR(e);
  };

  const handleGripL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "GripL",
        value: e,
      })
    );
    setGripL(e);
  };
  const handleRightSpecialtests = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "SpecialtestsR",
        value: e,
      })
    );
    setSpecialtestsR(e);
  };

  const handleLeftSpecialtests = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "SpecialtestsL",
        value: e,
      })
    );
    setSpecialtestsL(e);
  };
  const handleSpecialtestsTextR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "SpecialtestsTextR",
        value: e,
      })
    );
    setSpecialtestsTextR(e);
  };
  const handleSpecialtestsTextL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "SpecialtestsTextL",
        value: e,
      })
    );
    setSpecialtestsTextL(e);
  };
  const handleSubmit = () => {
    updateDate();
  };
  const [vmenuFix, setVmenuFix] = useState(false);
  const handleScroll = () => {
    setVmenuFix(window.pageYOffset > 140);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const Grind1ChangeLeft = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "Grind1LeftVal",
        value: e,
      })
    );
    setGrind1LeftVal(e);
  };
  const Grind2ChangeLeft = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "Grind2LeftVal",
        value: e,
      })
    );
    setGrind2LeftVal(e);
  };
  const Grind3ChangeLeft = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "Grind3LeftVal",
        value: e,
      })
    );
    setGrind3LeftVal(e);
  };
  const Grind4ChangeLeft = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "Grind4LeftVal",
        value: e,
      })
    );
    setGrind4LeftVal(e);
  };
  const Grind5ChangeLeft = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "Grind5LeftVal",
        value: e,
      })
    );
    setGrind5LeftVal(e);
  };
  const Grind6ChangeLeft = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "Grind6LeftVal",
        value: e,
      })
    );
    setGrind6LeftVal(e);
  };
  const Grind7ChangeLeft = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "Grind7LeftVal",
        value: e,
      })
    );
    setGrind7LeftVal(e);
  };
  const Grind8ChangeLeft = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "Grind8LeftVal",
        value: e,
      })
    );
    setGrind8LeftVal(e);
  };
  const Grind9ChangeLeft = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "Grind9LeftVal",
        value: e,
      })
    );
    setGrind9LeftVal(e);
  };
  const Grind10ChangeLeft = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "Grind10LeftVal",
        value: e,
      })
    );
    setGrind10LeftVal(e);
  };
  const Grind11ChangeLeft = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "Grind11LeftVal",
        value: e,
      })
    );
    setGrind11LeftVal(e);
  };
  const Grind12ChangeLeft = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "Grind12LeftVal",
        value: e,
      })
    );
    setGrind12LeftVal(e);
  };
  const Grind13ChangeLeft = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "Grind13LeftVal",
        value: e,
      })
    );
    setGrind13LeftVal(e);
  };
  const Grind14ChangeLeft = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "Grind14LeftVal",
        value: e,
      })
    );
    setGrind14LeftVal(e);
  };
  const Grind15ChangeLeft = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "Grind15LeftVal",
        value: e,
      })
    );
    setGrind15LeftVal(e);
  };
  const Grind16ChangeLeft = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "Grind16LeftVal",
        value: e,
      })
    );
    setGrind16LeftVal(e);
  };
  const Grind17ChangeLeft = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "Grind17LeftVal",
        value: e,
      })
    );
    setGrind17LeftVal(e);
  };
  const Grind18ChangeLeft = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "Grind18LeftVal",
        value: e,
      })
    );
    setGrind18LeftVal(e);
  };
  const Grind19ChangeLeft = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "Grind19LeftVal",
        value: e,
      })
    );
    setGrind19LeftVal(e);
  };
  const Grind20ChangeLeft = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "Grind20LeftVal",
        value: e,
      })
    );
    setGrind20LeftVal(e);
  };

  const Grind21ChangeLeft = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "Grind21LeftVal",
        value: e,
      })
    );
    setGrind21LeftVal(e);
  };
  const Grind22ChangeLeft = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "Grind22LeftVal",
        value: e,
      })
    );
    setGrind22LeftVal(e);
  };
  const Grind23ChangeLeft = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "Grind23LeftVal",
        value: e,
      })
    );
    setGrind23LeftVal(e);
  };
  const Grind24ChangeLeft = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "Grind24LeftVal",
        value: e,
      })
    );
    setGrind24LeftVal(e);
  };
  const Grind25ChangeLeft = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "Grind25LeftVal",
        value: e,
      })
    );
    setGrind25LeftVal(e);
  };
  const Grind26ChangeLeft = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "Grind26LeftVal",
        value: e,
      })
    );
    setGrind26LeftVal(e);
  };
  const Grind27ChangeLeft = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "Grind27LeftVal",
        value: e,
      })
    );
    setGrind27LeftVal(e);
  };
  const Grind28ChangeLeft = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "Grind28LeftVal",
        value: e,
      })
    );
    setGrind28LeftVal(e);
  };
  const Grind29ChangeLeft = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "Grind29LeftVal",
        value: e,
      })
    );
    setGrind29LeftVal(e);
  };
  const Grind30ChangeLeft = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "Grind30LeftVal",
        value: e,
      })
    );
    setGrind30LeftVal(e);
  };
  const Grind31ChangeLeft = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "Grind31LeftVal",
        value: e,
      })
    );
    setGrind31LeftVal(e);
  };
  const Grind32ChangeLeft = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "Grind32LeftVal",
        value: e,
      })
    );
    setGrind32LeftVal(e);
  };
  const Grind33ChangeLeft = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "Grind33LeftVal",
        value: e,
      })
    );
    setGrind33LeftVal(e);
  };
  const Grind34ChangeLeft = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "Grind34LeftVal",
        value: e,
      })
    );
    setGrind34LeftVal(e);
  };
  const Grind35ChangeLeft = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "Grind35LeftVal",
        value: e,
      })
    );
    setGrind35LeftVal(e);
  };
  const Grind36ChangeLeft = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "Grind36LeftVal",
        value: e,
      })
    );
    setGrind36LeftVal(e);
  };
  const Grind37ChangeLeft = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "Grind37LeftVal",
        value: e,
      })
    );
    setGrind37LeftVal(e);
  };
  const Grind38ChangeLeft = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "Grind38LeftVal",
        value: e,
      })
    );
    setGrind38LeftVal(e);
  };
  const Grind39ChangeLeft = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "Grind39LeftVal",
        value: e,
      })
    );
    setGrind39LeftVal(e);
  };
  const Grind40ChangeLeft = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "Grind40LeftVal",
        value: e,
      })
    );
    setGrind40LeftVal(e);
  };
  const Grind41ChangeLeft = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "Grind41LeftVal",
        value: e,
      })
    );
    setGrind41LeftVal(e);
  };
  const Grind42ChangeLeft = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "Grind42LeftVal",
        value: e,
      })
    );
    setGrind42LeftVal(e);
  };
  const Grind43ChangeLeft = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "Grind43LeftVal",
        value: e,
      })
    );
    setGrind43LeftVal(e);
  };
  const Grind44ChangeLeft = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "Grind44LeftVal",
        value: e,
      })
    );
    setGrind44LeftVal(e);
  };

  useEffect(() => {
    const updateDate2 = async () => {
      const response = await axiosInstance.current.put(
        `extapp/forms/update_doctor_feedback?resource_id=${rId}&action=0&ak_dc=${dId}`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        
      }
    }
    if (chk) {
      if (doctorEnable !== "true") {
        updateDate2()
      }
    }
  }, [json])
  // ----
  const [getId, setGetId] = useState("");
  useEffect(() => {
    // Get the Gait section element by its ID
    const gaitSection = document.getElementById(getId);
    console.log(gaitSection, "gaitSection");
    // Scroll to the Gait section
    if (gaitSection) {
      const yOffset = gaitSection.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: yOffset - window.innerHeight / 10,
        behavior: "smooth",
      });
    }
    console.log(getId, "getId");
  }, [getId]);

  return (
    <>
    {isLoading ? (
      <LoadingSpinner/>
    ) : (
      <>



      <div class="d-flex justify-content-end">
        <DoctorNameCustom />
      </div>
      <div className="pain_screen mb-5">
        <AssessmentHeader />
        <HeaderInnerBreadcrumb />
        {doctorEnable === "true" && <HandWristTab rId={rId} />}
        <CustomToaster msg={msg} msgType={msgType} />
        
      </div>

      <div
        className={`patient_assessment_form mb_100 bg_white pd_10 pt_50 content_fx_w ${vmenuFix ? "fixed_vertical_menu" : ""
          }`}
      >
        <div className={`${assessmentStyles.inner_content_box}`}>
          <div className={`${assessmentStyles.accordion_sidebar}`}>
            <FormVerticalMenu setGetId={setGetId} />
          </div>
          <div
            className={`accordion_tab_content ${assessmentStyles.accordion_tab_content}`}
          >
            <div
              id="semmes"
              className={`accordion_tabpanel ${assessmentStyles.accordion_tabpanel}`}
            >
              <Accordion defaultActiveKey="0">
                <Accordion.Item eKey="0">
                  <Accordion.Header>Sensory Testing</Accordion.Header>
                  <Accordion.Body>
                    <div className=" mb_30">
                      <div className="row">
                        <img src={Handwrist} style={{ width: "50%" }} />
                        <div className=" mb_30">
                          <div className="row">
                            <div className="col-md-6 col-sm-6">
                              <h4 className="range_sl_title">
                                {
                                  translation.Semmes_Weinstein_monofilament_test_R
                                }
                              </h4>
                            </div>
                            <div className="col-md-6 col-sm-6">
                              <h4 className="range_sl_title">
                                {
                                  translation.Semmes_Weinstein_monofilament_test_L
                                }
                              </h4>
                            </div>
                            <div className="col-md-12 col-sm-12">
                              <span>
                                (<strong>0</strong> = Total loss of sensation,{" "}
                                <strong>1</strong> = Residual deep pressure ,{" "}
                                <strong>2</strong> = Loss of protective
                                sensation , <strong>3</strong> = Residual
                                protective sensation , <strong>4</strong> =
                                Residual texture , <strong>5</strong> = Normal
                                sensation )
                              </span>
                            </div>
                          </div>
                          <div className="row mt-4">
                            <div className="col-md-6 col-sm-6">
                              <ElbowFeedbackScoreRangeSlider
                                label={translation.Location_1}
                                value={Weinstein1R}
                                onChange={handleWeinstein1R}
                                sliderData={[
                                  {
                                    score: "0",
                                    label: "Total loss of sensation",
                                  },
                                  {
                                    score: "1",
                                    label: "Residual deep pressure",
                                  },
                                  {
                                    score: "2",
                                    label: "Loss of protective sensation",
                                  },
                                  {
                                    score: "3",
                                    label: "Residual protective sensation",
                                  },
                                  { score: "4", label: "Residual texture" },
                                  { score: "5", label: "Normal sensation" },
                                ]}
                              />

                              {/* <ElbowFeedbackScoreRangeSlider
                                label={translation.Location_1}
                                value={Weinstein1R}
                                onChange={handleWeinstein1R}
                                sliderData={[
                                  { score: "0", label: "Total loss of sensation" },
                                  { score: "1", label: "Residual deep pressure" },
                                  { score: "2", label: "Loss of protective sensation" },
                                  { score: "3", label: "Residual protective sensation" },
                                  { score: "4", label: "Residual texture" },
                                  { score: "5", label: "Normal sensation" },
                                ]}
                              /> */}

                              <ElbowFeedbackScoreRangeSlider
                                label={translation.Location_2}
                                value={Weinstein2R}
                                onChange={handleWeinstein2R}
                                sliderData={[
                                  {
                                    score: "0",
                                    label: "Total loss of sensation",
                                  },
                                  {
                                    score: "1",
                                    label: "Residual deep pressure",
                                  },
                                  {
                                    score: "2",
                                    label: "Loss of protective sensation",
                                  },
                                  {
                                    score: "3",
                                    label: "Residual protective sensation",
                                  },
                                  { score: "4", label: "Residual texture" },
                                  { score: "5", label: "Normal sensation" },
                                ]}
                              />
                              <ElbowFeedbackScoreRangeSlider
                                label={translation.Location_3}
                                value={Weinstein3R}
                                onChange={handleWeinstein3R}
                                sliderData={[
                                  {
                                    score: "0",
                                    label: "Total loss of sensation",
                                  },
                                  {
                                    score: "1",
                                    label: "Residual deep pressure",
                                  },
                                  {
                                    score: "2",
                                    label: "Loss of protective sensation",
                                  },
                                  {
                                    score: "3",
                                    label: "Residual protective sensation",
                                  },
                                  { score: "4", label: "Residual texture" },
                                  { score: "5", label: "Normal sensation" },
                                ]}
                              />
                              <ElbowFeedbackScoreRangeSlider
                                label={translation.Location_4}
                                value={Weinstein4R}
                                onChange={handleWeinstein4R}
                                sliderData={[
                                  {
                                    score: "0",
                                    label: "Total loss of sensation",
                                  },
                                  {
                                    score: "1",
                                    label: "Residual deep pressure",
                                  },
                                  {
                                    score: "2",
                                    label: "Loss of protective sensation",
                                  },
                                  {
                                    score: "3",
                                    label: "Residual protective sensation",
                                  },
                                  { score: "4", label: "Residual texture" },
                                  { score: "5", label: "Normal sensation" },
                                ]}
                              />
                              <ElbowFeedbackScoreRangeSlider
                                label={translation.Location_5}
                                value={Weinstein5R}
                                onChange={handleWeinstein5R}
                                sliderData={[
                                  {
                                    score: "0",
                                    label: "Total loss of sensation",
                                  },
                                  {
                                    score: "1",
                                    label: "Residual deep pressure",
                                  },
                                  {
                                    score: "2",
                                    label: "Loss of protective sensation",
                                  },
                                  {
                                    score: "3",
                                    label: "Residual protective sensation",
                                  },
                                  { score: "4", label: "Residual texture" },
                                  { score: "5", label: "Normal sensation" },
                                ]}
                              />
                              <ElbowFeedbackScoreRangeSlider
                                label={translation.Location_6}
                                value={Weinstein6R}
                                onChange={handleWeinstein6R}
                                sliderData={[
                                  {
                                    score: "0",
                                    label: "Total loss of sensation",
                                  },
                                  {
                                    score: "1",
                                    label: "Residual deep pressure",
                                  },
                                  {
                                    score: "2",
                                    label: "Loss of protective sensation",
                                  },
                                  {
                                    score: "3",
                                    label: "Residual protective sensation",
                                  },
                                  { score: "4", label: "Residual texture" },
                                  { score: "5", label: "Normal sensation" },
                                ]}
                              />
                              <ElbowFeedbackScoreRangeSlider
                                label={translation.Location_7}
                                value={Weinstein7R}
                                onChange={handleWeinstein7R}
                                sliderData={[
                                  {
                                    score: "0",
                                    label: "Total loss of sensation",
                                  },
                                  {
                                    score: "1",
                                    label: "Residual deep pressure",
                                  },
                                  {
                                    score: "2",
                                    label: "Loss of protective sensation",
                                  },
                                  {
                                    score: "3",
                                    label: "Residual protective sensation",
                                  },
                                  { score: "4", label: "Residual texture" },
                                  { score: "5", label: "Normal sensation" },
                                ]}
                              />
                            </div>
                            <div className="col-md-6 col-sm-6">
                              <ElbowFeedbackScoreRangeSlider
                                label={translation.Location_1}
                                value={Weinstein1L}
                                onChange={handleWeinstein1L}
                                sliderData={[
                                  {
                                    score: "0",
                                    label: "Total loss of sensation",
                                  },
                                  {
                                    score: "1",
                                    label: "Residual deep pressure",
                                  },
                                  {
                                    score: "2",
                                    label: "Loss of protective sensation",
                                  },
                                  {
                                    score: "3",
                                    label: "Residual protective sensation",
                                  },
                                  { score: "4", label: "Residual texture" },
                                  { score: "5", label: "Normal sensation" },
                                ]}
                              />

                              <ElbowFeedbackScoreRangeSlider
                                label={translation.Location_2}
                                value={Weinstein2L}
                                onChange={handleWeinstein2L}
                                sliderData={[
                                  {
                                    score: "0",
                                    label: "Total loss of sensation",
                                  },
                                  {
                                    score: "1",
                                    label: "Residual deep pressure",
                                  },
                                  {
                                    score: "2",
                                    label: "Loss of protective sensation",
                                  },
                                  {
                                    score: "3",
                                    label: "Residual protective sensation",
                                  },
                                  { score: "4", label: "Residual texture" },
                                  { score: "5", label: "Normal sensation" },
                                ]}
                              />
                              <ElbowFeedbackScoreRangeSlider
                                label={translation.Location_3}
                                value={Weinstein3L}
                                onChange={handleWeinstein3L}
                                sliderData={[
                                  {
                                    score: "0",
                                    label: "Total loss of sensation",
                                  },
                                  {
                                    score: "1",
                                    label: "Residual deep pressure",
                                  },
                                  {
                                    score: "2",
                                    label: "Loss of protective sensation",
                                  },
                                  {
                                    score: "3",
                                    label: "Residual protective sensation",
                                  },
                                  { score: "4", label: "Residual texture" },
                                  { score: "5", label: "Normal sensation" },
                                ]}
                              />
                              <ElbowFeedbackScoreRangeSlider
                                label={translation.Location_4}
                                value={Weinstein4L}
                                onChange={handleWeinstein4L}
                                sliderData={[
                                  {
                                    score: "0",
                                    label: "Total loss of sensation",
                                  },
                                  {
                                    score: "1",
                                    label: "Residual deep pressure",
                                  },
                                  {
                                    score: "2",
                                    label: "Loss of protective sensation",
                                  },
                                  {
                                    score: "3",
                                    label: "Residual protective sensation",
                                  },
                                  { score: "4", label: "Residual texture" },
                                  { score: "5", label: "Normal sensation" },
                                ]}
                              />
                              <ElbowFeedbackScoreRangeSlider
                                label={translation.Location_5}
                                value={Weinstein5L}
                                onChange={handleWeinstein5L}
                                sliderData={[
                                  {
                                    score: "0",
                                    label: "Total loss of sensation",
                                  },
                                  {
                                    score: "1",
                                    label: "Residual deep pressure",
                                  },
                                  {
                                    score: "2",
                                    label: "Loss of protective sensation",
                                  },
                                  {
                                    score: "3",
                                    label: "Residual protective sensation",
                                  },
                                  { score: "4", label: "Residual texture" },
                                  { score: "5", label: "Normal sensation" },
                                ]}
                              />
                              <ElbowFeedbackScoreRangeSlider
                                label={translation.Location_6}
                                value={Weinstein6L}
                                onChange={handleWeinstein6L}
                                sliderData={[
                                  {
                                    score: "0",
                                    label: "Total loss of sensation",
                                  },
                                  {
                                    score: "1",
                                    label: "Residual deep pressure",
                                  },
                                  {
                                    score: "2",
                                    label: "Loss of protective sensation",
                                  },
                                  {
                                    score: "3",
                                    label: "Residual protective sensation",
                                  },
                                  { score: "4", label: "Residual texture" },
                                  { score: "5", label: "Normal sensation" },
                                ]}
                              />
                              <ElbowFeedbackScoreRangeSlider
                                label={translation.Location_7}
                                value={Weinstein7L}
                                onChange={handleWeinstein7L}
                                sliderData={[
                                  {
                                    score: "0",
                                    label: "Total loss of sensation",
                                  },
                                  {
                                    score: "1",
                                    label: "Residual deep pressure",
                                  },
                                  {
                                    score: "2",
                                    label: "Loss of protective sensation",
                                  },
                                  {
                                    score: "3",
                                    label: "Residual protective sensation",
                                  },
                                  { score: "4", label: "Residual texture" },
                                  { score: "5", label: "Normal sensation" },
                                ]}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mb_30 pb-5">
                      <div className="row">
                        <div className="col-md-6 col-sm-6">
                          <h4 className="range_sl_title">
                            {translation.Second_pt_discrimination_R}
                          </h4>
                          <FullDropdown
                            data={[
                              {
                                value: "0",
                                label: "0 mm",
                              },
                              {
                                value: "1",
                                label: "1 mm",
                              },
                              {
                                value: "2",
                                label: "2 mm",
                              },
                              {
                                value: "3",
                                label: "3 mm",
                              },
                              {
                                value: "4",
                                label: "4 mm",
                              },
                              { value: "5", label: "5 mm" },
                              { value: "6", label: "6 mm" },
                              {
                                value: "7",
                                label: "7 mm",
                              },
                              { value: "8", label: "8 mm" },
                              {
                                value: "9",
                                label: "9 mm",
                              },
                              {
                                value: "10",
                                label: "10 mm",
                              },
                              { value: "11", label: "11 mm" },
                              { value: "12", label: "12 mm" },
                              { value: "13", label: "13 mm" },
                              { value: "14", label: "14 mm" },
                              { value: "> 15", label: "> 15 mm" },
                            ]}
                            label={translation.Location_1}
                            value={discrimination1R}
                            onChange={handlediscrimination1R}
                          />
                          {/* <HandWristDocFeedBackRangeSlider
                            formGroupClass="mt-5"
                            label={translation.Location_1}
                            handwrist={discrimination1R}
                            value={discrimination1R}
                            min="
                            max="15"
                            step="1"
                            type="image"
                            onChange={handlediscrimination1R}
                          /> */}
                          <FullDropdown
                            data={[
                              {
                                value: "0",
                                label: "0 mm",
                              },
                              {
                                value: "1",
                                label: "1 mm",
                              },
                              {
                                value: "2",
                                label: "2 mm",
                              },
                              {
                                value: "3",
                                label: "3 mm",
                              },
                              {
                                value: "4",
                                label: "4 mm",
                              },
                              { value: "5", label: "5 mm" },
                              { value: "6", label: "6 mm" },
                              {
                                value: "7",
                                label: "7 mm",
                              },
                              { value: "8", label: "8 mm" },
                              {
                                value: "9",
                                label: "9 mm",
                              },
                              {
                                value: "10",
                                label: "10 mm",
                              },
                              { value: "11", label: "11 mm" },
                              { value: "12", label: "12 mm" },
                              { value: "13", label: "13 mm" },
                              { value: "14", label: "14 mm" },
                              { value: "> 15", label: "> 15 mm" },
                            ]}
                            label={translation.Location_2}
                            value={discrimination2R}
                            onChange={handlediscrimination2R}
                          />
                          {/* <HandWristDocFeedBackRangeSlider
                            formGroupClass="mt-5"
                            label={translation.Location_2}
                            handwrist={discrimination2R}
                            value={discrimination2R}
                            min="0"
                            id="discrimination2R"
                            max="15"
                            step="1"
                            type="image"
                            onChange={handlediscrimination2R}
                          /> */}
                          <FullDropdown
                            data={[
                              {
                                value: "0",
                                label: "0 mm",
                              },
                              {
                                value: "1",
                                label: "1 mm",
                              },
                              {
                                value: "2",
                                label: "2 mm",
                              },
                              {
                                value: "3",
                                label: "3 mm",
                              },
                              {
                                value: "4",
                                label: "4 mm",
                              },
                              { value: "5", label: "5 mm" },
                              { value: "6", label: "6 mm" },
                              {
                                value: "7",
                                label: "7 mm",
                              },
                              { value: "8", label: "8 mm" },
                              {
                                value: "9",
                                label: "9 mm",
                              },
                              {
                                value: "10",
                                label: "10 mm",
                              },
                              { value: "11", label: "11 mm" },
                              { value: "12", label: "12 mm" },
                              { value: "13", label: "13 mm" },
                              { value: "14", label: "14 mm" },
                              { value: "> 15", label: "> 15 mm" },
                            ]}
                            label={translation.Location_3}
                            value={discrimination3R}
                            onChange={handlediscrimination3R}
                          />
                          {/* <HandWristDocFeedBackRangeSlider
                            formGroupClass="mt-5"
                            label={translation.Location_3}
                            handwrist={discrimination3R}
                            value={discrimination3R}
                            min="0"
                            id="discrimination3R"
                            max="15"
                            step="1"
                            type="image"
                            onChange={handlediscrimination3R}
                          /> */}
                          <FullDropdown
                            data={[
                              {
                                value: "0",
                                label: "0 mm",
                              },
                              {
                                value: "1",
                                label: "1 mm",
                              },
                              {
                                value: "2",
                                label: "2 mm",
                              },
                              {
                                value: "3",
                                label: "3 mm",
                              },
                              {
                                value: "4",
                                label: "4 mm",
                              },
                              { value: "5", label: "5 mm" },
                              { value: "6", label: "6 mm" },
                              {
                                value: "7",
                                label: "7 mm",
                              },
                              { value: "8", label: "8 mm" },
                              {
                                value: "9",
                                label: "9 mm",
                              },
                              {
                                value: "10",
                                label: "10 mm",
                              },
                              { value: "11", label: "11 mm" },
                              { value: "12", label: "12 mm" },
                              { value: "13", label: "13 mm" },
                              { value: "14", label: "14 mm" },
                              { value: "> 15", label: "> 15 mm" },
                            ]}
                            label={translation.Location_4}
                            value={discrimination4R}
                            onChange={handlediscrimination4R}
                          />
                          {/* <HandWristDocFeedBackRangeSlider
                            formGroupClass="mt-5"
                            label={translation.Location_4}
                            handwrist={discrimination4R}
                            value={discrimination4R}
                            min="0"
                            id="discrimination4R"
                            max="15"
                            step="1"
                            type="image"
                            onChange={handlediscrimination4R}
                          /> */}
                          <FullDropdown
                            data={[
                              {
                                value: "0",
                                label: "0 mm",
                              },
                              {
                                value: "1",
                                label: "1 mm",
                              },
                              {
                                value: "2",
                                label: "2 mm",
                              },
                              {
                                value: "3",
                                label: "3 mm",
                              },
                              {
                                value: "4",
                                label: "4 mm",
                              },
                              { value: "5", label: "5 mm" },
                              { value: "6", label: "6 mm" },
                              {
                                value: "7",
                                label: "7 mm",
                              },
                              { value: "8", label: "8 mm" },
                              {
                                value: "9",
                                label: "9 mm",
                              },
                              {
                                value: "10",
                                label: "10 mm",
                              },
                              { value: "11", label: "11 mm" },
                              { value: "12", label: "12 mm" },
                              { value: "13", label: "13 mm" },
                              { value: "14", label: "14 mm" },
                              { value: "> 15", label: "> 15 mm" },
                            ]}
                            label={translation.Location_5}
                            value={discrimination5R}
                            onChange={handlediscrimination5R}
                          />
                          {/* <HandWristDocFeedBackRangeSlider
                            formGroupClass="mt-5"
                            label={translation.Location_5}
                            handwrist={discrimination5R}
                            value={discrimination5R}
                            min="0"
                            id="discrimination5R"
                            max="15"
                            step="1"
                            type="image"
                            onChange={handlediscrimination5R}
                          /> */}
                          <FullDropdown
                            data={[
                              {
                                value: "0",
                                label: "0 mm",
                              },
                              {
                                value: "1",
                                label: "1 mm",
                              },
                              {
                                value: "2",
                                label: "2 mm",
                              },
                              {
                                value: "3",
                                label: "3 mm",
                              },
                              {
                                value: "4",
                                label: "4 mm",
                              },
                              { value: "5", label: "5 mm" },
                              { value: "6", label: "6 mm" },
                              {
                                value: "7",
                                label: "7 mm",
                              },
                              { value: "8", label: "8 mm" },
                              {
                                value: "9",
                                label: "9 mm",
                              },
                              {
                                value: "10",
                                label: "10 mm",
                              },
                              { value: "11", label: "11 mm" },
                              { value: "12", label: "12 mm" },
                              { value: "13", label: "13 mm" },
                              { value: "14", label: "14 mm" },
                              { value: "> 15", label: "> 15 mm" },
                            ]}
                            label={translation.Location_6}
                            value={discrimination6R}
                            onChange={handlediscrimination6R}
                          />
                          {/* <HandWristDocFeedBackRangeSlider
                            formGroupClass="mt-5"
                            label={translation.Location_6}
                            handwrist={discrimination6R}
                            value={discrimination6R}
                            min="0"
                            id="discrimination6R"
                            max="15"
                            step="1"
                            type="image"
                            onChange={handlediscrimination6R}
                          /> */}
                          <FullDropdown
                            data={[
                              {
                                value: "0",
                                label: "0 mm",
                              },
                              {
                                value: "1",
                                label: "1 mm",
                              },
                              {
                                value: "2",
                                label: "2 mm",
                              },
                              {
                                value: "3",
                                label: "3 mm",
                              },
                              {
                                value: "4",
                                label: "4 mm",
                              },
                              { value: "5", label: "5 mm" },
                              { value: "6", label: "6 mm" },
                              {
                                value: "7",
                                label: "7 mm",
                              },
                              { value: "8", label: "8 mm" },
                              {
                                value: "9",
                                label: "9 mm",
                              },
                              {
                                value: "10",
                                label: "10 mm",
                              },
                              { value: "11", label: "11 mm" },
                              { value: "12", label: "12 mm" },
                              { value: "13", label: "13 mm" },
                              { value: "14", label: "14 mm" },
                              { value: "> 15", label: "> 15 mm" },
                            ]}
                            label={translation.Location_7}
                            value={discrimination7R}
                            onChange={handlediscrimination7R}
                          />
                          {/* <HandWristDocFeedBackRangeSlider
                            formGroupClass="mt-5"
                            label={translation.Location_7}
                            value={discrimination7R}
                            handwrist={discrimination7R}
                            min="0"
                            id="discrimination7R"
                            max="15"
                            step="1"
                            type="image"
                            onChange={handlediscrimination7R}
                          /> */}
                        </div>
                        <div className="col-md-6 col-sm-6">
                          <h4 className="range_sl_title">
                            {translation.Second_pt_discrimination_L}
                          </h4>
                          <FullDropdown
                            data={[
                              {
                                value: "0",
                                label: "0 mm",
                              },
                              {
                                value: "1",
                                label: "1 mm",
                              },
                              {
                                value: "2",
                                label: "2 mm",
                              },
                              {
                                value: "3",
                                label: "3 mm",
                              },
                              {
                                value: "4",
                                label: "4 mm",
                              },
                              { value: "5", label: "5 mm" },
                              { value: "6", label: "6 mm" },
                              {
                                value: "7",
                                label: "7 mm",
                              },
                              { value: "8", label: "8 mm" },
                              {
                                value: "9",
                                label: "9 mm",
                              },
                              {
                                value: "10",
                                label: "10 mm",
                              },
                              { value: "11", label: "11 mm" },
                              { value: "12", label: "12 mm" },
                              { value: "13", label: "13 mm" },
                              { value: "14", label: "14 mm" },
                              { value: "> 15", label: "> 15 mm" },
                            ]}
                            label={translation.Location_1}
                            styleClass=""
                            value={discrimination1L}
                            onChange={handlediscrimination1L}
                          />
                          {/* <HandWristDocFeedBackRangeSlider
                            formGroupClass="mt-5"
                            label={translation.Location_1}
                            value={discrimination1L}
                            handwrist={discrimination1L}
                            min="0"
                            id="discrimination1L"
                            max="15"
                            step="1"
                            type="image"
                            onChange={handlediscrimination1L}
                          /> */}
                          <FullDropdown
                            data={[
                              {
                                value: "0",
                                label: "0 mm",
                              },
                              {
                                value: "1",
                                label: "1 mm",
                              },
                              {
                                value: "2",
                                label: "2 mm",
                              },
                              {
                                value: "3",
                                label: "3 mm",
                              },
                              {
                                value: "4",
                                label: "4 mm",
                              },
                              { value: "5", label: "5 mm" },
                              { value: "6", label: "6 mm" },
                              {
                                value: "7",
                                label: "7 mm",
                              },
                              { value: "8", label: "8 mm" },
                              {
                                value: "9",
                                label: "9 mm",
                              },
                              {
                                value: "10",
                                label: "10 mm",
                              },
                              { value: "11", label: "11 mm" },
                              { value: "12", label: "12 mm" },
                              { value: "13", label: "13 mm" },
                              { value: "14", label: "14 mm" },
                              { value: "> 15", label: "> 15 mm" },
                            ]}
                            label={translation.Location_2}
                            value={discrimination2L}
                            onChange={handlediscrimination2L}
                          />
                          {/* <HandWristDocFeedBackRangeSlider
                            formGroupClass="mt-5"
                            label={translation.Location_2}
                            value={discrimination2L}
                            handwrist={discrimination2L}
                            min="0"
                            id="discrimination2L"
                            max="15"
                            step="1"
                            type="image"
                            onChange={handlediscrimination2L}
                          /> */}
                          <FullDropdown
                            data={[
                              {
                                value: "0",
                                label: "0 mm",
                              },
                              {
                                value: "1",
                                label: "1 mm",
                              },
                              {
                                value: "2",
                                label: "2 mm",
                              },
                              {
                                value: "3",
                                label: "3 mm",
                              },
                              {
                                value: "4",
                                label: "4 mm",
                              },
                              { value: "5", label: "5 mm" },
                              { value: "6", label: "6 mm" },
                              {
                                value: "7",
                                label: "7 mm",
                              },
                              { value: "8", label: "8 mm" },
                              {
                                value: "9",
                                label: "9 mm",
                              },
                              {
                                value: "10",
                                label: "10 mm",
                              },
                              { value: "11", label: "11 mm" },
                              { value: "12", label: "12 mm" },
                              { value: "13", label: "13 mm" },
                              { value: "14", label: "14 mm" },
                              { value: "> 15", label: "> 15 mm" },
                            ]}
                            label={translation.Location_3}
                            value={discrimination3L}
                            onChange={handlediscrimination3L}
                          />
                          {/* <HandWristDocFeedBackRangeSlider
                            formGroupClass="mt-5"
                            label={translation.Location_3}
                            value={discrimination3L}
                            handwrist={discrimination3L}
                            min="0"
                            id="discrimination3L"
                            max="15"
                            step="1"
                            type="image"
                            onChange={handlediscrimination3L} */}
                          {/* /> */}
                          <FullDropdown
                            data={[
                              {
                                value: "0",
                                label: "0 mm",
                              },
                              {
                                value: "1",
                                label: "1 mm",
                              },
                              {
                                value: "2",
                                label: "2 mm",
                              },
                              {
                                value: "3",
                                label: "3 mm",
                              },
                              {
                                value: "4",
                                label: "4 mm",
                              },
                              { value: "5", label: "5 mm" },
                              { value: "6", label: "6 mm" },
                              {
                                value: "7",
                                label: "7 mm",
                              },
                              { value: "8", label: "8 mm" },
                              {
                                value: "9",
                                label: "9 mm",
                              },
                              {
                                value: "10",
                                label: "10 mm",
                              },
                              { value: "11", label: "11 mm" },
                              { value: "12", label: "12 mm" },
                              { value: "13", label: "13 mm" },
                              { value: "14", label: "14 mm" },
                              { value: "> 15", label: "> 15 mm" },
                            ]}
                            label={translation.Location_4}
                            value={discrimination4L}
                            onChange={handlediscrimination4L}
                          />
                          {/* <HandWristDocFeedBackRangeSlider
                            formGroupClass="mt-5"
                            label={translation.Location_4}
                            value={discrimination4L}
                            handwrist={discrimination4L}
                            min="0"
                            id="discrimination4L"
                            max="15"
                            step="1"
                            type="image"
                            onChange={handlediscrimination4L}
                          /> */}
                          <FullDropdown
                            data={[
                              {
                                value: "0",
                                label: "0 mm",
                              },
                              {
                                value: "1",
                                label: "1 mm",
                              },
                              {
                                value: "2",
                                label: "2 mm",
                              },
                              {
                                value: "3",
                                label: "3 mm",
                              },
                              {
                                value: "4",
                                label: "4 mm",
                              },
                              { value: "5", label: "5 mm" },
                              { value: "6", label: "6 mm" },
                              {
                                value: "7",
                                label: "7 mm",
                              },
                              { value: "8", label: "8 mm" },
                              {
                                value: "9",
                                label: "9 mm",
                              },
                              {
                                value: "10",
                                label: "10 mm",
                              },
                              { value: "11", label: "11 mm" },
                              { value: "12", label: "12 mm" },
                              { value: "13", label: "13 mm" },
                              { value: "14", label: "14 mm" },
                              { value: "> 15", label: "> 15 mm" },
                            ]}
                            label={translation.Location_5}
                            value={discrimination5L}
                            onChange={handlediscrimination5L}
                          />
                          {/* <HandWristDocFeedBackRangeSlider
                            formGroupClass="mt-5"
                            label={translation.Location_5}
                            handwrist={discrimination5L}
                            value={discrimination5L}
                            min="0"
                            id="discrimination5L"
                            max="15"
                            step="1"
                            type="image"
                            onChange={handlediscrimination5L}
                          /> */}
                          <FullDropdown
                            data={[
                              {
                                value: "0",
                                label: "0 mm",
                              },
                              {
                                value: "1",
                                label: "1 mm",
                              },
                              {
                                value: "2",
                                label: "2 mm",
                              },
                              {
                                value: "3",
                                label: "3 mm",
                              },
                              {
                                value: "4",
                                label: "4 mm",
                              },
                              { value: "5", label: "5 mm" },
                              { value: "6", label: "6 mm" },
                              {
                                value: "7",
                                label: "7 mm",
                              },
                              { value: "8", label: "8 mm" },
                              {
                                value: "9",
                                label: "9 mm",
                              },
                              {
                                value: "10",
                                label: "10 mm",
                              },
                              { value: "11", label: "11 mm" },
                              { value: "12", label: "12 mm" },
                              { value: "13", label: "13 mm" },
                              { value: "14", label: "14 mm" },
                              { value: "> 15", label: "> 15 mm" },
                            ]}
                            label={translation.Location_6}
                            value={discrimination6L}
                            onChange={handlediscrimination6L}
                          />
                          {/* <HandWristDocFeedBackRangeSlider
                            formGroupClass="mt-5"
                            label={translation.Location_6}
                            handwrist={discrimination6L}
                            value={discrimination6L}
                            min="0"
                            id="discrimination6L"
                            max="15"
                            step="1"
                            type="image"
                            onChange={handlediscrimination6L}
                          /> */}
                          <FullDropdown
                            data={[
                              {
                                value: "0",
                                label: "0 mm",
                              },
                              {
                                value: "1",
                                label: "1 mm",
                              },
                              {
                                value: "2",
                                label: "2 mm",
                              },
                              {
                                value: "3",
                                label: "3 mm",
                              },
                              {
                                value: "4",
                                label: "4 mm",
                              },
                              { value: "5", label: "5 mm" },
                              { value: "6", label: "6 mm" },
                              {
                                value: "7",
                                label: "7 mm",
                              },
                              { value: "8", label: "8 mm" },
                              {
                                value: "9",
                                label: "9 mm",
                              },
                              {
                                value: "10",
                                label: "10 mm",
                              },
                              { value: "11", label: "11 mm" },
                              { value: "12", label: "12 mm" },
                              { value: "13", label: "13 mm" },
                              { value: "14", label: "14 mm" },
                              { value: "> 15", label: "> 15 mm" },
                            ]}
                            label={translation.Location_7}
                            value={discrimination7L}
                            onChange={handlediscrimination7L}
                          />
                          {/* <HandWristDocFeedBackRangeSlider
                            formGroupClass="mt-5"
                            label={translation.Location_7}
                            value={discrimination7L}
                            handwrist={discrimination7L}
                            min="0"
                            id="discrimination7L"
                            max="15"
                            step="1"
                            type="image"
                            onChange={handlediscrimination7L}
                          /> */}
                        </div>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>

            <div
              id="motor"
              className={`accordion_tabpanel ${assessmentStyles.accordion_tabpanel}`}
            >
              <Accordion defaultActiveKey="0">
                <Accordion.Item eKey="0">
                  <Accordion.Header>Motor Testing</Accordion.Header>
                  <Accordion.Body>
                  <div className="col-md-12 col-sm-12">
                              <span>
                                (<strong>0</strong> = no contraction,{" "}
                                <strong>1</strong> = flicker ,{" "}
                                <strong>2</strong> = movement with gravity eliminated , <strong>3</strong> = movement against gravity , <strong>4</strong> =
                                movement with some resistance , <strong>5</strong> = normal power
                                 )
                              </span>
                            </div>
                    <div className=" mb_30">
                      <div className="row">
                        {/* <h4 className="range_sl_title">{translation.MOTOR_TESTING}</h4> */}
                        <div className="row">
                          <div className="col-md-6 col-sm-6 mb-3">
                            <KneeScoreRangeSlider
                              maximun="0"
                              minimum="5"
                              label={translation.Wrist_extension_Radial_nerve_R}
                              classes=""
                              value={wristR}
                              onChange={handlewristR}
                              sliderData={[
                                {
                                  score: "0",
                                  label: translation.no_contraction,
                                },
                                {
                                  score: "1",
                                  label: translation.flicker,
                                },
                                {
                                  score: "2",
                                  label:
                                    translation.movement_gravity_eliminated,
                                },
                                {
                                  score: "3",
                                  label: translation.movement_against_gravity,
                                },
                                {
                                  score: "4",
                                  label: translation.movement_some_resistance,
                                },
                                { score: "5", label: translation.normal_power },
                              ]}
                            />
                          </div>
                          <div className="col-md-6 col-sm-6 mb-3">
                            <KneeScoreRangeSlider
                              maximun="0"
                              minimum="5"
                              label={translation.Wrist_extension_Radial_nerve_L}
                              classes=""
                              value={wristL}
                              onChange={handlewristL}
                              // onClick={handlewristL}
                              sliderData={[
                                {
                                  score: "0",
                                  label: translation.no_contraction,
                                },
                                {
                                  score: "1",
                                  label: translation.flicker,
                                },
                                {
                                  score: "2",
                                  label:
                                    translation.movement_gravity_eliminated,
                                },
                                {
                                  score: "3",
                                  label: translation.movement_against_gravity,
                                },
                                {
                                  score: "4",
                                  label: translation.movement_some_resistance,
                                },
                                { score: "5", label: translation.normal_power },
                              ]}
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-6 col-sm-6 mb-3">
                            <KneeScoreRangeSlider
                              maximun="0"
                              minimum="5"
                              label={translation.Thumb_extension_PIN_R}
                              classes=""
                              value={ThumbR}
                              onChange={handleThumbR}
                              sliderData={[
                                {
                                  score: "0",
                                  label: translation.no_contraction,
                                },
                                {
                                  score: "1",
                                  label: translation.flicker,
                                },
                                {
                                  score: "2",
                                  label:
                                    translation.movement_gravity_eliminated,
                                },
                                {
                                  score: "3",
                                  label: translation.movement_against_gravity,
                                },
                                {
                                  score: "4",
                                  label: translation.movement_some_resistance,
                                },
                                { score: "5", label: translation.normal_power },
                              ]}
                            />
                          </div>

                          <div className="col-md-6 col-sm-6 mb-3">
                            <KneeScoreRangeSlider
                              maximun="0"
                              minimum="5"
                              label={translation.Thumb_extension_PIN_L}
                              classes=""
                              value={ThumbL}
                              onChange={handleThumbL}
                              sliderData={[
                                {
                                  score: "0",
                                  label: translation.no_contraction,
                                },
                                {
                                  score: "1",
                                  label: translation.flicker,
                                },
                                {
                                  score: "2",
                                  label:
                                    translation.movement_gravity_eliminated,
                                },
                                {
                                  score: "3",
                                  label: translation.movement_against_gravity,
                                },
                                {
                                  score: "4",
                                  label: translation.movement_some_resistance,
                                },
                                { score: "5", label: translation.normal_power },
                              ]}
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-6 col-sm-6 mb-3">
                            <KneeScoreRangeSlider
                              maximun="0"
                              minimum="5"
                              label={translation.Finger_MCP_extension_PIN_R}
                              classes=""
                              value={FingerR}
                              onChange={handleFingerR}
                              sliderData={[
                                {
                                  score: "0",
                                  label: translation.no_contraction,
                                },
                                {
                                  score: "1",
                                  label: translation.flicker,
                                },
                                {
                                  score: "2",
                                  label:
                                    translation.movement_gravity_eliminated,
                                },
                                {
                                  score: "3",
                                  label: translation.movement_against_gravity,
                                },
                                {
                                  score: "4",
                                  label: translation.movement_some_resistance,
                                },
                                { score: "5", label: translation.normal_power },
                              ]}
                            />
                          </div>
                          <div className="col-md-6 col-sm-6 mb-3">
                            <KneeScoreRangeSlider
                              maximun="0"
                              minimum="5"
                              label={translation.Finger_MCP_extension_PIN_L}
                              classes=""
                              value={FingerL}
                              onChange={handleFingerL}
                              sliderData={[
                                {
                                  score: "0",
                                  label: translation.no_contraction,
                                },
                                {
                                  score: "1",
                                  label: translation.flicker,
                                },
                                {
                                  score: "2",
                                  label:
                                    translation.movement_gravity_eliminated,
                                },
                                {
                                  score: "3",
                                  label: translation.movement_against_gravity,
                                },
                                {
                                  score: "4",
                                  label: translation.movement_some_resistance,
                                },
                                { score: "5", label: translation.normal_power },
                              ]}
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-6 col-sm-6 mb-3">
                            <KneeScoreRangeSlider
                              maximun="0"
                              minimum="5"
                              label={translation.Thumb_palmar_abduction_R}
                              classes=""
                              value={ThumbpalmarR}
                              onChange={handleThumbpalmarR}
                              sliderData={[
                                {
                                  score: "0",
                                  label: translation.no_contraction,
                                },
                                {
                                  score: "1",
                                  label: translation.flicker,
                                },
                                {
                                  score: "2",
                                  label:
                                    translation.movement_gravity_eliminated,
                                },
                                {
                                  score: "3",
                                  label: translation.movement_against_gravity,
                                },
                                {
                                  score: "4",
                                  label: translation.movement_some_resistance,
                                },
                                { score: "5", label: translation.normal_power },
                              ]}
                            />
                          </div>
                          <div className="col-md-6 col-sm-6 mb-3">
                            <KneeScoreRangeSlider
                              maximun="0"
                              minimum="5"
                              label={translation.Thumb_palmar_abduction_L}
                              classes=""
                              value={ThumbpalmarL}
                              onChange={handleThumbpalmarL}
                              sliderData={[
                                {
                                  score: "0",
                                  label: translation.no_contraction,
                                },
                                {
                                  score: "1",
                                  label: translation.flicker,
                                },
                                {
                                  score: "2",
                                  label:
                                    translation.movement_gravity_eliminated,
                                },
                                {
                                  score: "3",
                                  label: translation.movement_against_gravity,
                                },
                                {
                                  score: "4",
                                  label: translation.movement_some_resistance,
                                },
                                { score: "5", label: translation.normal_power },
                              ]}
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-6 col-sm-6 mb-3">
                            <KneeScoreRangeSlider
                              maximun="0"
                              minimum="5"
                              label={translation.Thumb_to_small_finger_R}
                              classes=""
                              value={ThumbsmallR}
                              onChange={handleThumbsmallR}
                              sliderData={[
                                {
                                  score: "0",
                                  label: translation.no_contraction,
                                },
                                {
                                  score: "1",
                                  label: translation.flicker,
                                },
                                {
                                  score: "2",
                                  label:
                                    translation.movement_gravity_eliminated,
                                },
                                {
                                  score: "3",
                                  label: translation.movement_against_gravity,
                                },
                                {
                                  score: "4",
                                  label: translation.movement_some_resistance,
                                },
                                { score: "5", label: translation.normal_power },
                              ]}
                            />
                          </div>
                          <div className="col-md-6 col-sm-6 mb-3">
                            <KneeScoreRangeSlider
                              maximun="0"
                              minimum="5"
                              label={translation.Thumb_to_small_finger_L}
                              classes=""
                              value={ThumbsmallL}
                              onChange={handleThumbsmallL}
                              sliderData={[
                                {
                                  score: "0",
                                  label: translation.no_contraction,
                                },
                                {
                                  score: "1",
                                  label: translation.flicker,
                                },
                                {
                                  score: "2",
                                  label:
                                    translation.movement_gravity_eliminated,
                                },
                                {
                                  score: "3",
                                  label: translation.movement_against_gravity,
                                },
                                {
                                  score: "4",
                                  label: translation.movement_some_resistance,
                                },
                                { score: "5", label: translation.normal_power },
                              ]}
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-6 col-sm-6 mb-3">
                            <KneeScoreRangeSlider
                              maximun="0"
                              minimum="5"
                              label={translation.Finger_flexion_R}
                              classes=""
                              value={FingerflexionR}
                              onChange={handleFingerflexionR}
                              sliderData={[
                                {
                                  score: "0",
                                  label: translation.no_contraction,
                                },
                                {
                                  score: "1",
                                  label: translation.flicker,
                                },
                                {
                                  score: "2",
                                  label:
                                    translation.movement_gravity_eliminated,
                                },
                                {
                                  score: "3",
                                  label: translation.movement_against_gravity,
                                },
                                {
                                  score: "4",
                                  label: translation.movement_some_resistance,
                                },
                                { score: "5", label: translation.normal_power },
                              ]}
                            />
                          </div>
                          <div className="col-md-6 col-sm-6 mb-3">
                            <KneeScoreRangeSlider
                              maximun="0"
                              minimum="5"
                              label={translation.Finger_flexion_L}
                              classes=""
                              value={FingerflexionL}
                              onChange={handleFingerflexionL}
                              sliderData={[
                                {
                                  score: "0",
                                  label: translation.no_contraction,
                                },
                                {
                                  score: "1",
                                  label: translation.flicker,
                                },
                                {
                                  score: "2",
                                  label:
                                    translation.movement_gravity_eliminated,
                                },
                                {
                                  score: "3",
                                  label: translation.movement_against_gravity,
                                },
                                {
                                  score: "4",
                                  label: translation.movement_some_resistance,
                                },
                                { score: "5", label: translation.normal_power },
                              ]}
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-6 col-sm-6 mb-3">
                            <KneeScoreRangeSlider
                              maximun="0"
                              minimum="5"
                              label={translation.Finger_abduction_adduction_R}
                              classes=""
                              value={FingerabductionR}
                              onChange={handleFingerabductionR}
                              sliderData={[
                                {
                                  score: "0",
                                  label: translation.no_contraction,
                                },
                                {
                                  score: "1",
                                  label: translation.flicker,
                                },
                                {
                                  score: "2",
                                  label:
                                    translation.movement_gravity_eliminated,
                                },
                                {
                                  score: "3",
                                  label: translation.movement_against_gravity,
                                },
                                {
                                  score: "4",
                                  label: translation.movement_some_resistance,
                                },
                                { score: "5", label: translation.normal_power },
                              ]}
                            />
                          </div>
                          <div className="col-md-6 col-sm-6 mb-3">
                            <KneeScoreRangeSlider
                              maximun="0"
                              minimum="5"
                              label={translation.Finger_abduction_adduction_L}
                              classes=""
                              value={FingerabductionL}
                              onChange={handleFingerabductionL}
                              sliderData={[
                                {
                                  score: "0",
                                  label: translation.no_contraction,
                                },
                                {
                                  score: "1",
                                  label: translation.flicker,
                                },
                                {
                                  score: "2",
                                  label:
                                    translation.movement_gravity_eliminated,
                                },
                                {
                                  score: "3",
                                  label: translation.movement_against_gravity,
                                },
                                {
                                  score: "4",
                                  label: translation.movement_some_resistance,
                                },
                                { score: "5", label: translation.normal_power },
                              ]}
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-6 col-sm-6 mb-3">
                            <KneeScoreRangeSlider
                              maximun="0"
                              minimum="5"
                              label={translation.OK_sign_AIN_R}
                              classes=""
                              value={OksingR}
                              onChange={handleOksingR}
                              sliderData={[
                                {
                                  score: "0",
                                  label: translation.no_contraction,
                                },
                                {
                                  score: "1",
                                  label: translation.flicker,
                                },
                                {
                                  score: "2",
                                  label:
                                    translation.movement_gravity_eliminated,
                                },
                                {
                                  score: "3",
                                  label: translation.movement_against_gravity,
                                },
                                {
                                  score: "4",
                                  label: translation.movement_some_resistance,
                                },
                                { score: "5", label: translation.normal_power },
                              ]}
                            />
                          </div>
                          <div className="col-md-6 col-sm-6 mb-3">
                            <KneeScoreRangeSlider
                              maximun="0"
                              minimum="5"
                              label={translation.OK_sign_AIN_L}
                              classes=""
                              value={OksingL}
                              onChange={handleOksingL}
                              sliderData={[
                                {
                                  score: "0",
                                  label: translation.no_contraction,
                                },
                                {
                                  score: "1",
                                  label: translation.flicker,
                                },
                                {
                                  score: "2",
                                  label:
                                    translation.movement_gravity_eliminated,
                                },
                                {
                                  score: "3",
                                  label: translation.movement_against_gravity,
                                },
                                {
                                  score: "4",
                                  label: translation.movement_some_resistance,
                                },
                                { score: "5", label: translation.normal_power },
                              ]}
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-6 col-sm-6 mb-3">

                            <AssessmentKgKgWidget
                             kg={GripR}
                             rightArmkgAction={false}
                             leftArmkgAction= "true"
                             onChangeK={handleGripR}
                             // title={translation.Grip_strength_L}
                             labelL={translation.Grip_strength_R}
                             nameL="tricepsStrenthR"
                            />
                        
                            {/* <KneeScoreRangeSlider
                              maximun="0"
                              minimum="5"
                              label={translation.Grip_strength_R}
                              classes=""
                              value={GripR}
                              onChange={handleGripR}
                            /> */}
                          </div>
                          <div className="col-md-6 col-sm-6 mb-3">
                          <AssessmentKgKgWidget
                              kg={GripL}
                              rightArmkgAction={false}
                              leftArmkgAction= "true"
                              onChangeK={handleGripL}
                              // title={translation.Grip_strength_L}
                              labelL={translation.Grip_strength_L}
                              nameL="tricepsStrenthL"
                            />

                            {/* <KneeScoreRangeSlider
                              maximun="0"
                              minimum="5"
                              label={translation.Grip_strength_L}
                              classes=""
                              value={GripL}
                              onChange={handleGripL}
                            /> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>

            <div
              id="special"
              className={`accordion_tabpanel ${assessmentStyles.accordion_tabpanel}`}
            >
              <Accordion defaultActiveKey="0">
                <Accordion.Item eKey="0">
                  <Accordion.Header>Special Tests-Palpation</Accordion.Header>
                  <Accordion.Body>
                    <div className="row mb_30">
                      <h4 className={`${assessmentStyles.accordian_sub_head}`}>
                        Grind test
                      </h4>

                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="Grind2NveL"
                          idRight="Grind2PveL"
                          name="Grind2L"
                          title="Right"
                          styleClass=""
                          value={Grind2LeftVal}
                          onChange={Grind2ChangeLeft}
                          placeholder="select"
                        />
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="Grind1NveL"
                          idRight="Grind1PveL"
                          name="Grind1L"
                          title="Left"
                          styleClass=""
                          value={Grind1LeftVal}
                          onChange={Grind1ChangeLeft}
                          placeholder="select"
                        />
                      </div>
                    </div>
                    <div className="row mb_30">
                      <h4 className={`${assessmentStyles.accordian_sub_head}`}>
                        Finkelstein's test
                      </h4>

                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="Grind4NveL"
                          idRight="Grind4PveL"
                          name="Grind4L"
                          title="Right"
                          styleClass=""
                          value={Grind4LeftVal}
                          onChange={Grind4ChangeLeft}
                          placeholder="select"
                        />
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="Grind3NveL"
                          idRight="Grind3PveL"
                          name="Grind3L"
                          title="Left"
                          styleClass=""
                          value={Grind3LeftVal}
                          onChange={Grind3ChangeLeft}
                          placeholder="select"
                        />
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>

            <div
              id="specialmotion"
              className={`accordion_tabpanel ${assessmentStyles.accordion_tabpanel}`}
            >
              <Accordion defaultActiveKey="0">
                <Accordion.Item eKey="0">
                  <Accordion.Header>Special Tests - Motion</Accordion.Header>
                  <Accordion.Body>
                    <div className="row mb_30">
                      <h4 className={`${assessmentStyles.accordian_sub_head}`}>
                        Cascade sign
                      </h4>

                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="Grind6NveL"
                          idRight="Grind6PveL"
                          name="Grind6L"
                          title="Right"
                          styleClass=""
                          value={Grind6LeftVal}
                          onChange={Grind6ChangeLeft}
                          placeholder="select"
                        />
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="Grind5NveL"
                          idRight="Grind5PveL"
                          name="Grind5L"
                          title="Left"
                          styleClass=""
                          value={Grind5LeftVal}
                          onChange={Grind5ChangeLeft}
                          placeholder="select"
                        />
                      </div>
                    </div>
                    <div className="row mb_30">
                      <h4 className={`${assessmentStyles.accordian_sub_head}`}>
                        Bunnel's test
                      </h4>

                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="Grind8NveL"
                          idRight="Grind8PveL"
                          name="Grind8L"
                          title="Right"
                          styleClass=""
                          value={Grind8LeftVal}
                          onChange={Grind8ChangeLeft}
                          placeholder="select"
                        />
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="Grind7NveL"
                          idRight="Grind7PveL"
                          name="Grind7L"
                          title="Left"
                          styleClass=""
                          value={Grind7LeftVal}
                          onChange={Grind7ChangeLeft}
                          placeholder="select"
                        />
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>

            <div
              id="specialstability"
              className={`accordion_tabpanel ${assessmentStyles.accordion_tabpanel}`}
            >
              <Accordion defaultActiveKey="0">
                <Accordion.Item eKey="0">
                  <Accordion.Header>Special Tests - Stability</Accordion.Header>
                  <Accordion.Body>
                    <div className="row mb_30">
                      <h4 className={`${assessmentStyles.accordian_sub_head}`}>
                        Watson test
                      </h4>

                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="Grind10NveL"
                          idRight="Grind10PveL"
                          name="Grind10L"
                          title="Right"
                          styleClass=""
                          value={Grind10LeftVal}
                          onChange={Grind10ChangeLeft}
                          placeholder="select"
                        />
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="Grind9NveL"
                          idRight="Grind9PveL"
                          name="Grind9L"
                          title="Left"
                          styleClass=""
                          value={Grind9LeftVal}
                          onChange={Grind9ChangeLeft}
                          placeholder="select"
                        />
                      </div>
                    </div>
                    <div className="row mb_30">
                      <h4 className={`${assessmentStyles.accordian_sub_head}`}>
                        Reagan test
                      </h4>

                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="Grind12NveL"
                          idRight="Grind12PveL"
                          name="Grind12L"
                          title="Right"
                          styleClass=""
                          value={Grind12LeftVal}
                          onChange={Grind12ChangeLeft}
                          placeholder="select"
                        />
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="Grind11NveL"
                          idRight="Grind11PveL"
                          name="Grind11L"
                          title="Left"
                          styleClass=""
                          value={Grind11LeftVal}
                          onChange={Grind11ChangeLeft}
                          placeholder="select"
                        />
                      </div>
                    </div>
                    <div className="row mb_30">
                      <h4 className={`${assessmentStyles.accordian_sub_head}`}>
                        Kleinman shear test
                      </h4>

                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="Grind14NveL"
                          idRight="Grind14PveL"
                          name="Grind14L"
                          title="Right"
                          styleClass=""
                          value={Grind14LeftVal}
                          onChange={Grind14ChangeLeft}
                          placeholder="select"
                        />
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="Grind13NveL"
                          idRight="Grind13PveL"
                          name="Grind13L"
                          title="Left"
                          styleClass=""
                          value={Grind13LeftVal}
                          onChange={Grind13ChangeLeft}
                          placeholder="select"
                        />
                      </div>
                    </div>
                    <div className="row mb_30">
                      <h4 className={`${assessmentStyles.accordian_sub_head}`}>
                        Lichtman test
                      </h4>

                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="Grind16NveL"
                          idRight="Grind16PveL"
                          name="Grind16L"
                          title="Right"
                          styleClass=""
                          value={Grind16LeftVal}
                          onChange={Grind16ChangeLeft}
                          placeholder="select"
                        />
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="Grind15NveL"
                          idRight="Grind15PveL"
                          name="Grind15L"
                          title="Left"
                          styleClass=""
                          value={Grind15LeftVal}
                          onChange={Grind15ChangeLeft}
                          placeholder="select"
                        />
                      </div>
                    </div>

                    <div className="row mb_30">
                      <h4 className={`${assessmentStyles.accordian_sub_head}`}>
                        ECU snap test
                      </h4>

                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="Grind18NveL"
                          idRight="Grind18PveL"
                          name="Grind18L"
                          title="Right"
                          styleClass=""
                          value={Grind18LeftVal}
                          onChange={Grind18ChangeLeft}
                          placeholder="select"
                        />
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="Grind17NveL"
                          idRight="Grind17PveL"
                          name="Grind17L"
                          title="Left"
                          styleClass=""
                          value={Grind17LeftVal}
                          onChange={Grind17ChangeLeft}
                          placeholder="select"
                        />
                      </div>
                    </div>

                    <div className="row mb_30">
                      <h4 className={`${assessmentStyles.accordian_sub_head}`}>
                        Piano key sign
                      </h4>

                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="Grind20NveL"
                          idRight="Grind20PveL"
                          name="Grind20L"
                          title="Right"
                          styleClass=""
                          value={Grind20LeftVal}
                          onChange={Grind20ChangeLeft}
                          placeholder="select"
                        />
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="Grind19NveL"
                          idRight="Grind19PveL"
                          name="Grind19L"
                          title="Left"
                          styleClass=""
                          value={Grind19LeftVal}
                          onChange={Grind19ChangeLeft}
                          placeholder="select"
                        />
                      </div>
                    </div>

                    <div className="row mb_30">
                      <h4 className={`${assessmentStyles.accordian_sub_head}`}>
                        Gamekeeper's test
                      </h4>

                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="Grind22NveL"
                          idRight="Grind22PveL"
                          name="Grind22L"
                          title="Right"
                          styleClass=""
                          value={Grind22LeftVal}
                          onChange={Grind22ChangeLeft}
                          placeholder="select"
                        />
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="Grind21NveL"
                          idRight="Grind21PveL"
                          name="Grind21L"
                          title="Left"
                          styleClass=""
                          value={Grind21LeftVal}
                          onChange={Grind21ChangeLeft}
                          placeholder="select"
                        />
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>

            <div
              id="specialtest"
              className={`accordion_tabpanel ${assessmentStyles.accordion_tabpanel}`}
            >
              <Accordion defaultActiveKey="0">
                <Accordion.Item eKey="0">
                  <Accordion.Header>Special Test - TFCC</Accordion.Header>
                  <Accordion.Body>
                    <div className="row mb_30">
                      <h4 className={`${assessmentStyles.accordian_sub_head}`}>
                        TFCC grind test
                      </h4>

                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="Grind24NveL"
                          idRight="Grind24PveL"
                          name="Grind24L"
                          title="Right"
                          styleClass=""
                          value={Grind24LeftVal}
                          onChange={Grind24ChangeLeft}
                          placeholder="select"
                        />
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="Grind23NveL"
                          idRight="Grind23PveL"
                          name="Grind23L"
                          title="Left"
                          styleClass=""
                          value={Grind23LeftVal}
                          onChange={Grind23ChangeLeft}
                          placeholder="select"
                        />
                      </div>
                    </div>
                    <div className="row mb_30">
                      <h4 className={`${assessmentStyles.accordian_sub_head}`}>
                        Fovea sign
                      </h4>

                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="Grind26NveL"
                          idRight="Grind26PveL"
                          name="Grind26L"
                          title="Right"
                          styleClass=""
                          value={Grind26LeftVal}
                          onChange={Grind26ChangeLeft}
                          placeholder="select"
                        />
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="Grind25NveL"
                          idRight="Grind25PveL"
                          name="Grind25L"
                          title="Left"
                          styleClass=""
                          value={Grind25LeftVal}
                          onChange={Grind25ChangeLeft}
                          placeholder="select"
                        />
                      </div>
                    </div>
                    <div className="row mb_30">
                      <h4 className={`${assessmentStyles.accordian_sub_head}`}>
                        TFCC compression test
                      </h4>

                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="Grind28NveL"
                          idRight="Grind28PveL"
                          name="Grind28L"
                          title=" Right"
                          styleClass=""
                          value={Grind28LeftVal}
                          onChange={Grind28ChangeLeft}
                          placeholder="select"
                        />
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="Grind27NveL"
                          idRight="Grind27PveL"
                          name="Grind27L"
                          title=" Left"
                          styleClass=""
                          value={Grind27LeftVal}
                          onChange={Grind27ChangeLeft}
                          placeholder="select"
                        />
                      </div>
                    </div>

                    <div className="row mb_30">
                      <h4 className={`${assessmentStyles.accordian_sub_head}`}>
                        Press test
                      </h4>

                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="Grind30NveL"
                          idRight="Grind30PveL"
                          name="Grind30L"
                          title="Right"
                          styleClass=""
                          value={Grind30LeftVal}
                          onChange={Grind30ChangeLeft}
                          placeholder="select"
                        />
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="Grind29NveL"
                          idRight="Grind29PveL"
                          name="Grind29L"
                          title="Left"
                          styleClass=""
                          value={Grind29LeftVal}
                          onChange={Grind29ChangeLeft}
                          placeholder="select"
                        />
                      </div>
                    </div>

                    <div className="row mb_30">
                      <h4 className={`${assessmentStyles.accordian_sub_head}`}>
                        Supination test
                      </h4>

                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="Grind32NveL"
                          idRight="Grind32PveL"
                          name="Grind32L"
                          title="Right"
                          styleClass=""
                          value={Grind32LeftVal}
                          onChange={Grind32ChangeLeft}
                          placeholder="select"
                        />
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="Grind31NveL"
                          idRight="Grind31PveL"
                          name="Grind31L"
                          title="Left"
                          styleClass=""
                          value={Grind31LeftVal}
                          onChange={Grind31ChangeLeft}
                          placeholder="select"
                        />
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>

            <div
              id="Nerve"
              className={`accordion_tabpanel ${assessmentStyles.accordion_tabpanel}`}
            >
              <Accordion defaultActiveKey="0">
                <Accordion.Item eKey="0">
                  <Accordion.Header>Special Tests - Nerve</Accordion.Header>
                  <Accordion.Body>
                    <div className="row mb_30">
                      <h4 className={`${assessmentStyles.accordian_sub_head}`}>
                        Tinel sign
                      </h4>

                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="Grind34NveL"
                          idRight="Grind34PveL"
                          name="Grind34L"
                          title="Right"
                          styleClass=""
                          value={Grind34LeftVal}
                          onChange={Grind34ChangeLeft}
                          placeholder="select"
                        />
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="Grind33NveL"
                          idRight="Grind33PveL"
                          name="Grind33L"
                          title="Left"
                          styleClass=""
                          value={Grind33LeftVal}
                          onChange={Grind33ChangeLeft}
                          placeholder="select"
                        />
                      </div>
                    </div>
                    <div className="row mb_30">
                      <h4 className={`${assessmentStyles.accordian_sub_head}`}>
                        Phalen's test
                      </h4>

                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="Grind44NveL"
                          idRight="Grind44PveL"
                          name="Grind44L"
                          title="Right"
                          styleClass=""
                          value={Grind44LeftVal}
                          onChange={Grind44ChangeLeft}
                          placeholder="select"
                        />
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="Grind35NveL"
                          idRight="Grind35PveL"
                          name="Grind35L"
                          title="Left"
                          styleClass=""
                          value={Grind35LeftVal}
                          onChange={Grind35ChangeLeft}
                          placeholder="select"
                        />
                      </div>
                    </div>

                    <div className="row mb_30">
                      <h4 className={`${assessmentStyles.accordian_sub_head}`}>
                        Froment's sign
                      </h4>

                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="Grind37NveL"
                          idRight="Grind37PveL"
                          name="Grind37L"
                          title="Right"
                          styleClass=""
                          value={Grind37LeftVal}
                          onChange={Grind37ChangeLeft}
                          placeholder="select"
                        />
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="Grind36NveL"
                          idRight="Grind36PveL"
                          name="Grind36L"
                          title="Left"
                          styleClass=""
                          value={Grind36LeftVal}
                          onChange={Grind36ChangeLeft}
                          placeholder="select"
                        />
                      </div>
                    </div>

                    <div className="row mb_30">
                      <h4 className={`${assessmentStyles.accordian_sub_head}`}>
                        Wartenberg's sign
                      </h4>

                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="Grind39NveL"
                          idRight="Grind39PveL"
                          name="Grind39L"
                          title="Right"
                          styleClass=""
                          value={Grind39LeftVal}
                          onChange={Grind39ChangeLeft}
                          placeholder="select"
                        />
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="Grind38NveL"
                          idRight="Grind38PveL"
                          name="Grind38L"
                          title="Left"
                          styleClass=""
                          value={Grind38LeftVal}
                          onChange={Grind38ChangeLeft}
                          placeholder="select"
                        />
                      </div>
                    </div>

                    <div className="row mb_30">
                      <h4 className={`${assessmentStyles.accordian_sub_head}`}>
                        Jeanne's sign
                      </h4>

                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="Grind41NveL"
                          idRight="Grind41PveL"
                          name="Grind41L"
                          title="Right"
                          styleClass=""
                          value={Grind41LeftVal}
                          onChange={Grind41ChangeLeft}
                          placeholder="select"
                        />
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="Grind40NveL"
                          idRight="Grind40PveL"
                          name="Grind40L"
                          title="Left"
                          styleClass=""
                          value={Grind40LeftVal}
                          onChange={Grind40ChangeLeft}
                          placeholder="select"
                        />
                      </div>
                    </div>
                    <div className="row mb_30">
                      <h4 className={`${assessmentStyles.accordian_sub_head}`}>
                        Pope's hand
                      </h4>

                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="Grind43NveL"
                          idRight="Grind43PveL"
                          name="Grind43L"
                          title="Right"
                          styleClass=""
                          value={Grind43LeftVal}
                          onChange={Grind43ChangeLeft}
                          placeholder="select"
                        />
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <RadioSwitch
                          labelLeft={translation.Negative}
                          labelRight={translation.Positive}
                          idLeft="Grind42NveL"
                          idRight="Grind42PveL"
                          name="Grind42L"
                          title="Left"
                          styleClass=""
                          value={Grind42LeftVal}
                          onChange={Grind42ChangeLeft}
                          placeholder="select"
                        />
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>

            <div
              id="Other"
              className={`accordion_tabpanel ${assessmentStyles.accordion_tabpanel}`}
            >
              <Accordion defaultActiveKey="0">
                <Accordion.Item eKey="0">
                  <Accordion.Header>Other Tests</Accordion.Header>
                  <Accordion.Body>
                    <div className="row mb_30">
                      {/* <h4 className={`${assessmentStyles.accordian_sub_head}`}>
                      Grind test
                      </h4> */}

                      <div className="col-md-6 col-sm-6">
                        <InputField
                          label="Right"
                          value={SpecialtestsTextL}
                          type="textarea"
                          placeholder="Describe"
                          styleClass="mxw_100"
                          onChange={handleSpecialtestsTextL}
                        />
                      </div>
                      <div className="col-md-6 col-sm-6">
                        <InputField
                          label="Left"
                          value={SpecialtestsTextR}
                          type="textarea"
                          placeholder="Describe"
                          styleClass="mxw_100"
                          onChange={handleSpecialtestsTextR}
                        />
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            <div className="row mb_30 button_box">
              <div className="col-md-6 col-sm-6">
                {doctorEnable !== "true" && (
                  <Link
                    to={
                      "/hand-wrist-feedback/" +
                      rId +
                      `?doctor=${dId}&patient=${pId}&is_doctor_form=true`
                    }
                    className="assm_back_btn"
                  >
                    <img src={backIcon} alt="" />
                    <span>{translation.back_button}</span>
                  </Link>
                )}
              </div>
              <div className="col-md-6 col-sm-6 text-end">
                <button
                  className="next_btn btn_fill"
                  onClick={() => handleSubmit()}
                >
                  {translation.submit}
                  <span>
                    <img src={nextIcon} alt="" />
                    <img src={nextWhiteIcon} alt="" />
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* < SubmitModel
     showModal={displayConfirmationModal}
     
     /> */}
      <Promsschudle
     openModal={displayConfirmationModal}
     setDisplayConfirmationModal={setDisplayConfirmationModal}
     type={"23"}
     patientuserID={pId}
     />
      </>

    )}
    </>
  );
}
