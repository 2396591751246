import React from "react";
import { UserDateFormat } from "../../../utilities/DateFormate";

export default function ViewFeedback({ AllData, doctorFeed,profileAllData }) {
  console.log(doctorFeed);
  return (
    <>
      <div className="whitebox padding-none">
        <div className="treatment-header">
          <h2>Doctor Feedback Form</h2>
          <div className="treatment-header-right">
            <p>Assessment Date : {UserDateFormat( AllData.assessment_date,profileAllData.ing_date_formate)}</p>
          </div>
        </div>
        <div className="treatment-content">
          <div className="treatment-row mt-3">
            <div className="treatment-table border-0">
              {/* <div className="treatment-row-header">
                Please choose the part of the foot/ankle that is being evaluated
              </div> */}
              <div className="treatment-row mt-3">
                <div className="treatment-col">
                  <label>Feedback:</label>
                  <p>{doctorFeed.feedback}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
