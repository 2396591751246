import React, { useState } from "react";
import PropTypes from "prop-types";

import { validateCheckbox } from "../../../utilities/Validator";

const Checkbox = ({ label, selected, styleClass, validators, onChange, id ,disable}) => {
  const [error, setError] = useState(false);

  const handleChange = (event) => {
    const { checked } = event.target;
    setError(validateCheckbox(validators, checked));
    onChange(checked);
    console.log("checkbox", event.target);
  };

  return (
    <div className={`form-group ${styleClass}`}>
        <input
          type="checkbox"
          className="mr-2"
          value={selected}
          defaultChecked={selected}
          checked={selected}
          onChange={handleChange}
          id={id}
          disabled={disable}
        />
         
      <label  className="ms-2" htmlFor={id} >
        {label}
      </label>
      {error && <span className="text-danger">{error.message}</span>}
    </div>
  );
};

Checkbox.propTypes = {
  styleClass: PropTypes.string,
  selected: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

Checkbox.defaultProps = {
  styleClass: "",
};

export default Checkbox;
