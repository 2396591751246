import React from "react";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import vertMenuStyles from './FormVerticalMenu.module.css';

function FormVerticalMenu() {
  return (
    <>
      <Navbar bg="light" className={`scrollbar_vert ${vertMenuStyles.vm_sidebar}`}>
        <Nav
          className={`me-auto my-2 my-lg-0 ${vertMenuStyles.vm_sidebar_inner}`}
          navbarScroll
        >
          <Nav.Link className={`${vertMenuStyles.vm_item}`} href="#acl">ACL</Nav.Link>
          <Nav.Link className={`${vertMenuStyles.vm_item}`} href="#pcl">PCL</Nav.Link>
          <Nav.Link className={`${vertMenuStyles.vm_item}`} href="#plc">PLC</Nav.Link>
          <Nav.Link className={`${vertMenuStyles.vm_item}`} href="#pmc">PMC </Nav.Link>
          <Nav.Link className={`${vertMenuStyles.vm_item}`} href="#collaterals">Collaterals</Nav.Link>
          <Nav.Link className={`${vertMenuStyles.vm_item}`} href="#meniscus">Meniscus</Nav.Link>
          <Nav.Link className={`${vertMenuStyles.vm_item}`} href="#patella">Patella</Nav.Link>


       
        </Nav>
      </Navbar>
    </>
  );
}

export default FormVerticalMenu;
