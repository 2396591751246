import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import vertMenuStyles from "../../shoulder-forms/doctorefeedback/form-vertical-menu/FormVerticalMenu.module.css";
import useTranslation from "../../../customHooks/doctorTranslation";
function FormVerticalMenu({ setGetId }) {
  const translation = useTranslation();
  return (
    <>
      <Navbar
        bg="light"
        className={`scrollbar_vert ${vertMenuStyles.vm_sidebar}`}
      >
        <Nav
          className={`me-auto my-2 my-lg-0 ${vertMenuStyles.vm_sidebar_inner}`}
          navbarScroll
        >
          <Nav.Link
            className={`${vertMenuStyles.vm_item}`}
            onClick={() => setGetId("semmes")}
            // href="#semmes"
          >
            Sensory Testing
          </Nav.Link>
          <Nav.Link
            className={`${vertMenuStyles.vm_item}`}
            onClick={() => setGetId("motor")}
            // href="#motor"
          >
            Motor Testing
          </Nav.Link>
          <Nav.Link
            className={`${vertMenuStyles.vm_item}`}
            onClick={() => setGetId("special")}
            // href="#special"
          >
            Special Tests-Palpation
          </Nav.Link>
          <Nav.Link
            className={`${vertMenuStyles.vm_item}`}
            onClick={() => setGetId("specialmotion")}
            // href="#specialmotion"
          >
            Special Tests - Motion
          </Nav.Link>
          <Nav.Link
            className={`${vertMenuStyles.vm_item}`}
            onClick={() => setGetId("specialstability")}
            // href="#specialstability"
          >
            Special Tests - Stability
          </Nav.Link>
          <Nav.Link
            className={`${vertMenuStyles.vm_item}`}
            onClick={() => setGetId("specialtest")}
            // href="#specialtest"
          >
           
            Special Test - TFCC
          </Nav.Link>
          <Nav.Link
            className={`${vertMenuStyles.vm_item}`}
            onClick={() => setGetId("Nerve")}
            // href="#Nerve"
          >
           
            Special Tests - Nerve
          </Nav.Link>

          <Nav.Link
            className={`${vertMenuStyles.vm_item}`}
            onClick={() => setGetId("Other")}
            // href="#Other"
          >
          
            Other Tests
          </Nav.Link>
        </Nav>
      </Navbar>
    </>
  );
}

export default FormVerticalMenu;
