import React from 'react'
import useTranslation from '../../customHooks/translations'
export default function HandWristUram({ AllData, handuram }) {
    const translations=useTranslation();
    return (
        <>
            <div className="whitebox padding-none">
                <div className="treatment-content">
                    <div className="treatment-row-header">6. {translations.patient_hand_uram}</div>
                    <div className="treatment-row">
                        <div className="treatment-table">
                            <table cellpadding="0" cellspacing="0">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>{translations.Right_Hand_nd_Wrist}</th>
                                        <th>{translations.Left_Hand_nd_Wrist}</th>
                                        
                                        
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{translations.patient_hand_uram_q1}</td>
                                        <td>{handuram.flannelR}</td>
                                        <td>{handuram.flannelL}</td>
                                        
                                    </tr>
                                    <tr>
                                        <td>{translations.patient_hand_uram_q2}</td>
                                        <td>{handuram.faceR}</td>
                                        <td>{handuram.faceL}</td>
                                        
                                    </tr>
                                    <tr>
                                        <td>{translations.patient_hand_uram_q3}</td>
                                        <td>{handuram.bottleR}</td>
                                        <td>{handuram.bottleL}</td>
                                        
                                    </tr>
                                    <tr>
                                        <td>{translations.patient_hand_uram_q4}</td>
                                        <td>{handuram.shakeR}</td>
                                        <td>{handuram.shakeL}</td>
                                        
                                    </tr>
                                    <tr>
                                        <td>{translations.patient_hand_uram_q5}</td>
                                        <td>{handuram.strokeR}</td>
                                        <td>{handuram.strokeL}</td>
                                        
                                    </tr>
                                    <tr>
                                        <td>{translations.patient_hand_uram_q6}</td>
                                        <td>{handuram.clapR}</td>
                                        <td>{handuram.clapL}</td>
                                        
                                    </tr>
                                    <tr>
                                        <td>{translations.patient_hand_uram_q7}</td>
                                        <td>{handuram.spreadR}</td>
                                        <td>{handuram.spreadL}</td>
                                        
                                    </tr>
                                    <tr>
                                        <td>{translations.patient_hand_uram_q8}</td>
                                        <td>{handuram.leeanR}</td>
                                        <td>{handuram.leeanL}</td>
                                        
                                    </tr>
                                    <tr>
                                        <td>{translations.patient_hand_uram_q9}</td>
                                        <td>{handuram.smallR}</td>
                                        <td>{handuram.smallL}</td>
                                        
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
