const regex = {
    email: new RegExp(
        '^(([^<>()\\[\\]\\\\.,;:\\s@]+(\\.[^<>()\\[\\]\\\\.,;:\\s@]+)*)|(.+))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
    ),
    password: new RegExp(
        '^(?=.*[A-Za-z])(?=.*\\d)[A-Za-z\\d]{2,}$',
    ),
    number: new RegExp('^[0-9]+$'),
};

export class Validators {
    static email(value, message) {
        if (value) {
            const result = regex.email.test(value);
            if (!result) return { error: true, message };
        }
        // return false;

        if (!value || !value.toString().trim().length) {
            return { error: true, message };
        }
        return false;
    }
    static password(value, message) {
        if (value) {
            const result = regex.password.test(value);
            if (!result) return { error: true, message };
        }
        // return false;

        if (!value || !value.toString().trim().length) {
            return { error: true, message };
        }
        return false;
    }

    static required(value, message) {
        if (!value || !value.toString().trim().length) {
            return { error: true, message };
        }
        return false;
    }

    static requiredR(value, message) {
        if (!value || !value.toString().trim().length) {
            return { setErrorValR: true, message };
        }
        return false;
    }
    static requiredL(value, message) {
        if (!value || !value.toString().trim().length) {
            return { setErrorValL: true, message };
        }
        return false;
    }

    static number(value, message) {
        const length = value ? value.toString().length : 0;

        if (length > 0) {
            const result = regex.number.test(value);
            if (!result) {
                return { error: true, message };
            }
        }

        return false;
    }

    static spacial(value, message) {
        if(/[^a-zA-Z0-9\-\/ ]/.test(value) ) {
            return { setErrorValR: true, message };
        }
        return false;
    }



    static maxText100(value, message) {

        if (value.toString() >= 101) {
            return { error: true, message };
        }
        return false;
    }


    static maxTextArea(value, message) {

        if (value.toString().trim().length >= 501) {
            return { error: true, message };
        }
        return false;
    }
    static minText(value, message) {

        if (value.toString().trim().length <= 3) {
            return { error: true, message };
        }
        return false;
    }
    static maxformtext(value, message) {

        if (value.toString().trim().length >= 4) {
            return { error: true, message };
        }
        return false;
    }
   


    static maxformtext2(value, message) {

        if (value.toString().trim().length >= 3) {
            return { error: true, message };
        }
        return false;
    }
   


    

    static minTextArea(value, message) {

        if (value.toString().trim().length <= 14) {
            return { error: true, message };
        }
        return false;
    }
}


export const validateInput = (validators, value) => {
    if (validators && validators.length) {
        for (let i = 0; i < validators.length; i++) {
            const error = validators[i].check(value, validators[i].message);
            if (error) {
                return error;
            }
        }
    }
    return false;
};

export const validateDropdown = (validators, value) => {
    if (validators && validators.length) {
        for (let i = 0; i < validators.length; i++) {
            const error = validators[i].check(value, validators[i].message);
            if (error) {
                return error;
            }
        }
    }
    return false;
};

export const validateCheckbox = (validators, value) => {
    if (validators && validators.length) {
        for (let i = 0; i < validators.length; i++) {
            const error = validators[i].check(value, validators[i].message);
            if (error) {
                return error;
            }
        }
    }
    return false;
};

export const validateRadioSwitch = (validators, value) => {
    if (validators && validators.length) {
        for (let i = 0; i < validators.length; i++) {
            const error = validators[i].check(value, validators[i].message);
            if (error) {
                return error;
            }
        }
    }
    return false;
};