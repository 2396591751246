import React from "react";
import "../../../css/ViewTreatmentForm.css";
import useTranslation from "../../customHooks/translations";

export default function PediaKneeSymtoms({ pediaSymptoms, AllData }) {
  const translations = useTranslation();
  return (
    <>
      <div className="whitebox padding-none">
        <div className="treatment-content">
          <div className="treatment-row-header">
            2. {translations.patient_pedia_knee_other_symptoms}
          </div>
          <div className="treatment-row">
            <div className="treatment-table">
              <table cellpadding="0" cellspacing="0">
                <thead>
                  <tr>
                    <th></th>
                    <th>{translations.Right}</th>
                    <th>{translations.Left}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {translations.patient_pedia_knee_other_symptoms_q1} :
                    </td>
                    <td>{pediaSymptoms.kneeMakingNoiseR}</td>
                    <td>{pediaSymptoms.kneeMakingNoiseL}</td>
                  </tr>
                  <tr>
                    <td>
                      {translations.patient_pedia_knee_other_symptoms_q2} :
                    </td>
                    <td>{pediaSymptoms.kneeGettingStuckR}</td>
                    <td>{pediaSymptoms.kneeGettingStuckL}</td>
                  </tr>
                  <tr>
                    <td>
                      {translations.patient_pedia_knee_other_symptoms_q3} :
                    </td>
                    <td>{pediaSymptoms.stillMoveR}</td>
                    <td>{pediaSymptoms.stillMoveL}</td>
                  </tr>
                  <tr>
                    <td>
                      {translations.patient_pedia_knee_other_symptoms_q4} :
                    </td>
                    <td>{pediaSymptoms.puffyKneeR}</td>
                    <td>{pediaSymptoms.puffyKneeL}</td>
                  </tr>
                  <tr>
                    <td>
                      {translations.patient_pedia_knee_other_symptoms_q5}:
                    </td>

                    <td>
                      {pediaSymptoms.swollenKneeR === "0"
                        ? translations.patient_pedia_knee_other_symptoms_q5_a1
                        : pediaSymptoms.swollenKneeR === "1"
                        ? translations.patient_pedia_knee_other_symptoms_q5_a2
                        : pediaSymptoms.swollenKneeR === "2"
                        ? translations.patient_pedia_knee_other_symptoms_q5_a3
                        : pediaSymptoms.swollenKneeR === "3"
                        ? translations.patient_pedia_knee_other_symptoms_q5_a4
                        : pediaSymptoms.swollenKneeR === "4"
                        ? translations.patient_pedia_knee_other_symptoms_q5_a5
                        : ""}
                    </td>
                    <td>
                      {pediaSymptoms.swollenKneeL === "0"
                        ? translations.patient_pedia_knee_other_symptoms_q5_a1
                        : pediaSymptoms.swollenKneeL === "1"
                        ? translations.patient_pedia_knee_other_symptoms_q5_a2
                        : pediaSymptoms.swollenKneeL === "2"
                        ? translations.patient_pedia_knee_other_symptoms_q5_a3
                        : pediaSymptoms.swollenKneeL === "3"
                        ? translations.patient_pedia_knee_other_symptoms_q5_a4
                        : pediaSymptoms.swollenKneeL === "4"
                        ? translations.patient_pedia_knee_other_symptoms_q5_a5
                        : ""}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {translations.patient_pedia_knee_other_symptoms_q6} :
                    </td>
                    <td>{pediaSymptoms.withoutHurtLotR}</td>
                    <td>{pediaSymptoms.withoutHurtLotL}</td>
                  </tr>
                  <tr>
                    <td>
                      {translations.patient_pedia_knee_other_symptoms_q7} :
                    </td>
                    <td>{pediaSymptoms.withoutPuffyR}</td>
                    <td>{pediaSymptoms.withoutPuffyL}</td>
                  </tr>
                  <tr>
                    <td>
                      {translations.patient_pedia_knee_other_symptoms_q8} :
                    </td>
                    <td>{pediaSymptoms.cantHoldR}</td>
                    <td>{pediaSymptoms.cantHoldL}</td>
                  </tr>
                  <tr>
                    <td>
                      {translations.patient_pedia_knee_other_symptoms_q9} :
                    </td>
                    <td>{pediaSymptoms.mostOfTimeR}</td>
                    <td>{pediaSymptoms.mostOfTimeL}</td>
                  </tr>
                  {/* <tr>
                                        <td>
                                            Does your kneecap get out of place or does it feel like it is getting out of place :
                                        </td>
                                        <td>{pediaSymptoms.kneecapL}</td>
                                        <td>{pediaSymptoms.kneecapR}</td>
                                    </tr> */}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
