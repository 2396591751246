import React, { useState, useCallback, useEffect } from 'react'
import { Link, useNavigate, useParams } from "react-router-dom";
import AssessmentHeader from '../../../custom/assessment-header/AssessmentHeader';
import HeaderInnerBreadcrumb from '../../../custom/heade-inner-breadcrumb/HeaderInnerBreadcrumb';
import FullDropdown from '../../../custom/dropdown/FullDropdown';
import InputField from "../../../custom/inputfield/InputField";
import RadioSwitch from "../../../custom/radio-switch/RadioSwitch";
import CreatableSelect from "react-select/creatable";
import backIcon from "../../../../images/back.png";
import nextWhiteIcon from "../../../../images/next-white.png";
import nextIcon from "../../../../images/next.png";
import { useDispatch, useSelector } from 'react-redux';
import useTranslation from "../../../customHooks/doctorTranslation"
import FootAnkleTab from '../../tab-section/FootAnkleTab';
import { useSearchParams } from "react-router-dom";
import CustomToaster from "../../../custom/toaster/CustomToaster"
import useAxios from "../../../../axiosinstance";
import {
  updateFootFunctionScore,
  updateFootFunctionData,
  updateFootAnkle,
  updateFootAnklePainData,
  updateFootQolData,
  updateFootSatisfactionData,
  updateFootInstabilityData,
  updateFootSymptomsData,
  updateFootFeedbackfootankle,
  updateFootFeedbackfootankleField,
  updateFootComberlandData,
  updateFootSatisTotalscrore,
  updateAllData

} from "../../../../redux/actions/footAnkle";
import DoctorNameCustom from '../../../custom/name/DoctorNameCustom';
import LoadingSpinner from '../../../custom/loader/LoadingSpinner';

export default function DoctorfeedbackStep2() {

  const translation = useTranslation()
  const dispatch = useDispatch();
  const { rId } = useParams();
  const axiosinstance = useAxios();
  const [searchParams] = useSearchParams();
  let doctorEnable = searchParams.get("d");
  const queryParams = new URLSearchParams(window.location.search);
  const dId = queryParams.get("doctor");
  const pId = queryParams.get("patient");
  const [doraR, setDoraR] = useState("");
  const [doraL, setDoraL] = useState("");
  const [plantR, setPlantR] = useState("");
  const [plantL, setPlantL] = useState("");
  const [inverR, setInverR] = useState("");
  const [inverL, setInverL] = useState("");
  const [everR, setEverR] = useState("");
  const [everL, setEverL] = useState("");
  const [halluxR, setHalluxR] = useState("");
  const [halluxL, setHalluxL] = useState("");
  const [hallux2R, setHallux2R] = useState("");
  const [hallux2L, setHallux2L] = useState("");
  const [hallux3R, setHallux3R] = useState("");
  const [hallux3L, setHallux3L] = useState("");
  const [hallux4R, setHallux4R] = useState("");
  const [hallux4L, setHallux4L] = useState("");
  const [hallux5R, setHallux5R] = useState("");
  const [hallux5L, setHallux5L] = useState("");
  const [halluxRpi, setHalluxRpi] = useState("");
  const [halluxLpi, setHalluxLpi] = useState("");
  const [hallux2Rpi, setHallux2Rpi] = useState("");
  const [hallux2Lpi, setHallux2Lpi] = useState("");
  const [hallux3Rpi, setHallux3Rpi] = useState("");
  const [hallux3Lpi, setHallux3Lpi] = useState("");
  const [hallux4Rpi, setHallux4Rpi] = useState("");
  const [hallux4Lpi, setHallux4Lpi] = useState("");
  const [hallux5Rpi, setHallux5Rpi] = useState("");
  const [hallux5Lpi, setHallux5Lpi] = useState("");
  const [halluxRdo, setHalluxRdo] = useState("");
  const [halluxLdo, setHalluxLdo] = useState("");
  const [hallux2Rdo, setHallux2Rdo] = useState("");
  const [hallux2Ldo, setHallux2Ldo] = useState("");
  const [hallux3Rdo, setHallux3Rdo] = useState("");
  const [hallux3Ldo, setHallux3Ldo] = useState("");
  const [hallux4Rdo, setHallux4Rdo] = useState("");
  const [hallux4Ldo, setHallux4Ldo] = useState("");
  const [hallux5Rdo, setHallux5Rdo] = useState("");
  const [hallux5Ldo, setHallux5Ldo] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const [chk, setChk] = useState(false);
  const docFeedback = useSelector(
    (state) => state.footAnkle.doctorFeedback[0]
  );
  const docFeedback1 = useSelector(
    (state) => state.footAnkle.doctorFeedback
  );

  const save = () => {
    axiosinstance.current
        .get(`extapp/forms/all_forms?resource_id=${rId}&type=21`)
        .then((res) => {
            
            dispatch(updateFootAnklePainData(res.data.data2[0].painScore));
            dispatch(updateFootSymptomsData(res.data.data2[0].symptomsScore));
            dispatch(updateFootAnkle(res.data.data2[0].anklePain));
            dispatch(updateFootInstabilityData(res.data.data2[0].instabilityScore));
            dispatch(updateFootComberlandData(res.data.data2[0].comberland));
            dispatch(updateFootQolData(res.data.data2[0].qolScore));
            dispatch(updateFootFunctionData(res.data.data2[0].functionScore));
            dispatch(
                updateFootSatisfactionData(res.data.data2[0].satisfactionScore)
            );
            dispatch(updateFootSatisTotalscrore(res.data.data2[0].TotalScores))
            dispatch(updateFootFeedbackfootankle(res.data.data2[0].doctorFeedback));
            setChk(true);
        });
};

useEffect(() => {
    save();
}, []);

useEffect(() => {
  setTimeout(() => {
    setIsLoading(false);
  }, 1000);
}, []);

  useEffect(() => {
    setDoraR(docFeedback.doraR)
    setDoraL(docFeedback.doraL)
    setPlantR(docFeedback.plantR)
    setPlantL(docFeedback.plantL)
    setInverR(docFeedback.inverR)
    setInverL(docFeedback.inverL)
    setEverR(docFeedback.everR)
    setEverL(docFeedback.everL)
    setHalluxR(docFeedback.halluxR)
    setHalluxL(docFeedback.halluxL)
    setHallux2R(docFeedback.hallux2R)
    setHallux2L(docFeedback.hallux2L)
    setHallux3R(docFeedback.hallux3R)
    setHallux3L(docFeedback.hallux3L)
    setHallux4R(docFeedback.hallux4R)
    setHallux4L(docFeedback.hallux4L)
    setHallux5R(docFeedback.hallux5R)
    setHallux5L(docFeedback.hallux5L)
    setHalluxRpi(docFeedback.halluxRpi)
    setHalluxLpi(docFeedback.halluxLpi)
    setHallux2Rpi(docFeedback.hallux2Rpi)
    setHallux2Lpi(docFeedback.hallux2Lpi)
    setHallux3Rpi(docFeedback.hallux3Rpi)
    setHallux3Lpi(docFeedback.hallux3Lpi)
    setHallux4Rpi(docFeedback.hallux4Rpi)
    setHallux4Lpi(docFeedback.hallux4Lpi)
    setHallux5Rpi(docFeedback.hallux5Rpi)
    setHallux5Lpi(docFeedback.hallux5Lpi)
    setHalluxRdo(docFeedback.halluxRdo)
    setHalluxLdo(docFeedback.halluxLdo)
    setHallux2Rdo(docFeedback.hallux2Rdo)
    setHallux2Ldo(docFeedback.hallux2Ldo)
    setHallux3Rdo(docFeedback.hallux3Rdo)
    setHallux3Ldo(docFeedback.hallux3Ldo)
    setHallux4Rdo(docFeedback.hallux4Rdo)
    setHallux4Ldo(docFeedback.hallux4Ldo)
    setHallux5Rdo(docFeedback.hallux5Rdo)
    setHallux5Ldo(docFeedback.hallux5Ldo)
  }, [docFeedback])

  const navigate = useNavigate();

  const handleDoraR = (e) => {
    setDoraR(e);
    dispatch(updateFootFeedbackfootankleField({
      "key": 'doraR',
      "value": e
    }))
  }
  
  const handleDoraL = (e) => {
    setDoraL(e);
    dispatch(updateFootFeedbackfootankleField({
      "key": 'doraL',
      "value": e
    }))
  }
  

  const handlePlantR = (e) => {
    setPlantR(e);
    dispatch(updateFootFeedbackfootankleField({
      "key": 'plantR',
      "value": e
    }))
  }
  const handlePlantL = (e) => {
    setPlantL(e);
    dispatch(updateFootFeedbackfootankleField({
      "key": 'plantL',
      "value": e
    }))
  }
  const handleInverR = (e) => {
    setInverR(e);
    dispatch(updateFootFeedbackfootankleField({
      "key": 'inverR',
      "value": e
    }))
  }
  const handleInverL = (e) => {
    setInverL(e);
    dispatch(updateFootFeedbackfootankleField({
      "key": 'inverL',
      "value": e
    }))
  }
  const handleEverR = (e) => {
    setEverR(e);
    dispatch(updateFootFeedbackfootankleField({
      "key": 'everR',
      "value": e
    }))
  }
  const handleEverL = (e) => {
    setEverL(e);
    dispatch(updateFootFeedbackfootankleField({
      "key": 'everL',
      "value": e
    }))
  }

  const handleHalluxR = (e) => {
    setHalluxR(e);
    dispatch(updateFootFeedbackfootankleField({
      "key": 'halluxR',
      "value": e
    }))
  }
  const handleHalluxL = (e) => {
    setHalluxL(e);
    dispatch(updateFootFeedbackfootankleField({
      "key": 'halluxL',
      "value": e
    }))
  }
  const handleHallux2R = (e) => {
    setHallux2R(e);
    dispatch(updateFootFeedbackfootankleField({
      "key": 'hallux2R',
      "value": e
    }))
  }
  const handleHallux2L = (e) => {
    setHallux2L(e);
    dispatch(updateFootFeedbackfootankleField({
      "key": 'hallux2L',
      "value": e
    }))
  }
  const handleHallux3R = (e) => {
    setHallux3R(e);
    dispatch(updateFootFeedbackfootankleField({
      "key": 'hallux3R',
      "value": e
    }))
  }
  const handleHallux3L = (e) => {
    setHallux3L(e);
    dispatch(updateFootFeedbackfootankleField({
      "key": 'hallux3L',
      "value": e
    }))
  }
  const handleHallux4R = (e) => {
    setHallux4R(e);
    dispatch(updateFootFeedbackfootankleField({
      "key": 'hallux4R',
      "value": e
    }))
  }
  const handleHallux4L = (e) => {
    setHallux4L(e);
    dispatch(updateFootFeedbackfootankleField({
      "key": 'hallux4L',
      "value": e
    }))
  }
  const handleHallux5R = (e) => {
    setHallux5R(e);
    dispatch(updateFootFeedbackfootankleField({
      "key": 'hallux5R',
      "value": e
    }))
  }
  const handleHallux5L = (e) => {
    setHallux5L(e);
    dispatch(updateFootFeedbackfootankleField({
      "key": 'hallux5L',
      "value": e
    }))
  }

  const handleHalluxRpi = (e) => {
    setHalluxRpi(e);
    dispatch(updateFootFeedbackfootankleField({
      "key": 'halluxRpi',
      "value": e
    }))
  }
  const handleHalluxLpi = (e) => {
    setHalluxLpi(e);
    dispatch(updateFootFeedbackfootankleField({
      "key": 'halluxLpi',
      "value": e
    }))
  }
  const handleHallux2Rpi = (e) => {
    setHallux2Rpi(e);
    dispatch(updateFootFeedbackfootankleField({
      "key": 'hallux2Rpi',
      "value": e
    }))
  }
  const handleHallux2Lpi = (e) => {
    setHallux2Lpi(e);
    dispatch(updateFootFeedbackfootankleField({
      "key": 'hallux2Lpi',
      "value": e
    }))
  }
  const handleHallux3Rpi = (e) => {
    setHallux3Rpi(e);
    dispatch(updateFootFeedbackfootankleField({
      "key": 'hallux3Rpi',
      "value": e
    }))
  }
  const handleHallux3Lpi = (e) => {
    setHallux3Lpi(e);
    dispatch(updateFootFeedbackfootankleField({
      "key": 'hallux3Lpi',
      "value": e
    }))
  }
  const handleHallux4Rpi = (e) => {
    setHallux4Rpi(e);
    dispatch(updateFootFeedbackfootankleField({
      "key": 'hallux4Rpi',
      "value": e
    }))
  }
  const handleHallux4Lpi = (e) => {
    setHallux4Lpi(e);
    dispatch(updateFootFeedbackfootankleField({
      "key": 'hallux4Lpi',
      "value": e
    }))
  }
  const handleHallux5Rpi = (e) => {
    setHallux5Rpi(e);
    dispatch(updateFootFeedbackfootankleField({
      "key": 'hallux5Rpi',
      "value": e
    }))
  }
  const handleHallux5Lpi = (e) => {
    setHallux5Lpi(e);
    dispatch(updateFootFeedbackfootankleField({
      "key": 'hallux5Lpi',
      "value": e
    }))
  }
  const handleHalluxRdo = (e) => {
    setHalluxRdo(e);
    dispatch(updateFootFeedbackfootankleField({
      "key": 'halluxRdo',
      "value": e
    }))
  }
  const handleHalluxLdo = (e) => {
    setHalluxLdo(e);
    dispatch(updateFootFeedbackfootankleField({
      "key": 'halluxLdo',
      "value": e
    }))
  }
  const handleHallux2Rdo = (e) => {
    setHallux2Rdo(e);
    dispatch(updateFootFeedbackfootankleField({
      "key": 'hallux2Rdo',
      "value": e
    }))
  }
  const handleHallux2Ldo = (e) => {
    setHallux2Ldo(e);
    dispatch(updateFootFeedbackfootankleField({
      "key": 'hallux2Ldo',
      "value": e
    }))
  }
  const handleHallux3Rdo = (e) => {
    setHallux3Rdo(e);
    dispatch(updateFootFeedbackfootankleField({
      "key": 'hallux3Rdo',
      "value": e
    }))
  }
  const handleHallux3Ldo = (e) => {
    setHallux3Ldo(e);
    dispatch(updateFootFeedbackfootankleField({
      "key": 'hallux3Ldo',
      "value": e
    }))
  }
  const handleHallux4Rdo = (e) => {
    setHallux4Rdo(e);
    dispatch(updateFootFeedbackfootankleField({
      "key": 'hallux4Rdo',
      "value": e
    }))
  }
  const handleHallux4Ldo = (e) => {
    setHallux4Ldo(e);
    dispatch(updateFootFeedbackfootankleField({
      "key": 'hallux4Ldo',
      "value": e
    }))
  }
  const handleHallux5Rdo = (e) => {
    setHallux5Rdo(e);
    dispatch(updateFootFeedbackfootankleField({
      "key": 'hallux5Rdo',
      "value": e
    }))
  }
  const handleHallux5Ldo = (e) => {
    setHallux5Ldo(e);
    dispatch(updateFootFeedbackfootankleField({
      "key": 'hallux5Ldo',
      "value": e
    }))
  }

  const updateDate = async () => {
    const response = await axiosinstance.current.put(
      `extapp/forms/update_doctor_feedback?resource_id=${rId}&action=1&ak_dc=${dId}&edit=1`,
      docFeedback1,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then((res) => {
      console.log(res);
    });
  }
 
  const continueRedirect = async () => {
    const response = await axiosinstance.current.put(
      `extapp/forms/update_doctor_feedback?resource_id=${rId}&action=1&ak_dc=${dId}&edit=1`,
      docFeedback1,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    if(response.status===200){
      navigate("/foot-doctor-feedback-step-3/" + rId + `?doctor=${dId}&patient=${pId}`);
    }
 
  }

  useEffect(()=>{
    const updateDate2 = async () => {
    const response = await axiosinstance.current.put(
      `extapp/forms/update_doctor_feedback?resource_id=${rId}&action=0&ak_dc=${dId}`,
      docFeedback1,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200) {

    }
    }
    if(chk){
      if(doctorEnable !== "true"){
    updateDate2()
    // updateDate1()
    }
    }
  },[docFeedback1])

  // const continueRedirect = (e) => {
  //   updateDate()
   
  // };

  const [msg, setMsg] = useState('');
  const [msgType, setmsgType] = useState('');
  const handleSubmit = () => {
    updateDate()
    setMsg(translation.update)
    setmsgType('success')
    setTimeout(() => {
      setMsg('')
      setmsgType('success')
    }, 2000);
  };
  return (
    <>

    {isLoading ? (
      <LoadingSpinner/>
    ) :
    (
      <>

       <div class="d-flex justify-content-end">
          <DoctorNameCustom />
        </div>
      <div className="patient_assessment_form bg_white pd_10 pt_50">
        {doctorEnable === "true" && <FootAnkleTab rId={rId} />}
        <CustomToaster msg={msg} msgType={msgType} />
      </div>
    
      <div className="patient_assessment_form mb_100 bg_white pd_10 pt_50 content_fx_w">
        {/* <AssessmentHeader /> */}

        <HeaderInnerBreadcrumb />

        <h4 className="range_sl_title">MOTION</h4>
        <div className="input_tab_box mb_30">
          <h4 className="range_sl_title">Ankle</h4>
          <div className="row">
            <div className="col-md-6 col-sm-6 mb_10">
              <RadioSwitch
                labelLeft="Active"
                labelRight="Passive"
                idLeft="doraR"
                idRight="doraL"
                name="dorsiflexionR"
                title={translation.Dorsiflexion_R}
                styleClass=""
                value={doraR}
                onChange={handleDoraR}
                placeholder="select"
              />
            </div>
            <div className="col-md-6 col-sm-6 mb_10">
              <RadioSwitch
                labelLeft="Active"
                labelRight="Passive"
                idLeft="dorarR"
                idRight="dorarL"
                name="dorsiflexionL"
                title={translation.Dorsiflexion_L}
                styleClass=""
                value={doraL}
                onChange={handleDoraL}
                placeholder="select"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-6 mb_10">
              <RadioSwitch
                labelLeft="Active"
                labelRight="Passive"
                idLeft="plantR"
                idRight="plantL"
                name="PlantarflexionR"
                title={translation.Plantarflexion_R}
                styleClass=""
                value={plantR}
                onChange={handlePlantR}
                placeholder="select"
              />
            </div>
            <div className="col-md-6 col-sm-6 mb_10">
              <RadioSwitch
                labelLeft="Active"
                labelRight="Passive"
                idLeft="dplantR"
                idRight="dplantL"
                name="PlantarflexionL"
                title={translation.Plantarflexion_L}
                styleClass=""
                value={plantL}
                onChange={handlePlantL}
                placeholder="select"
              />
            </div>
          </div>
        </div>

        <div className="input_tab_box mb_30">
          <h4 className="range_sl_title">{translation.Hindfoot}</h4>
          <div className="row">
            <div className="col-md-6 col-sm-6 mb_10">
              <RadioSwitch
                labelLeft="Active"
                labelRight="Passive"
                idLeft="inverR"
                idRight="inverL"
                name="InversionR"
                title={translation.Inversion_R}
                styleClass=""
                value={inverR}
                onChange={handleInverR}
                placeholder="select"
              />
            </div>
            <div className="col-md-6 col-sm-6 mb_10">
              <RadioSwitch
                labelLeft="Active"
                labelRight="Passive"
                idLeft="dinverR"
                idRight="dinverL"
                name="InversionL"
                title={translation.Inversion_L}
                styleClass=""
                value={inverL}
                onChange={handleInverL}
                placeholder="select"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-6 mb_10">
              <RadioSwitch
                labelLeft="Active"
                labelRight="Passive"
                idLeft="everR"
                idRight="everL"
                name="eversionR"
                title={translation.Eversion_R}
                styleClass=""
                value={everR}
                onChange={handleEverR}
                placeholder="select"
              />
            </div>
            <div className="col-md-6 col-sm-6 mb_10">
              <RadioSwitch
                labelLeft="Active"
                labelRight="Passive"
                idLeft="revertR"
                idRight="revertL"
                name="eversionL"
                title={translation.Eversion_L}
                styleClass=""
                value={everL}
                onChange={handleEverL}
                placeholder="select"
              />
            </div>
          </div>
        </div>


        <div className="input_tab_box mb_30">
          <h4 className="range_sl_title">{translation.MPTJ}</h4>
          <div className="row">
            <div className="col-md-6 col-sm-6 mb_10">
              <RadioSwitch
                labelLeft="Active"
                labelRight="Passive"
                idLeft="halluxR"
                idRight="halluxL"
                name="halluxR"
                title={translation.Hallux_R}
                styleClass=""
                value={halluxR}
                onChange={handleHalluxR}
                placeholder="select"
              />
            </div>
            <div className="col-md-6 col-sm-6 mb_10">
              <RadioSwitch
                labelLeft="Active"
                labelRight="Passive"
                idLeft="halluxrR"
                idRight="halluxrL"
                name="halluxL"
                title={translation.Hallux_L}
                styleClass=""
                value={halluxL}
                onChange={handleHalluxL}
                placeholder="select"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-6 mb_10">
              <RadioSwitch
                labelLeft="Active"
                labelRight="Passive"
                idLeft="halluxR2"
                idRight="halluxL2"
                name="hallux2R"
                title={translation.Second_R}
                styleClass=""
                value={hallux2R}
                onChange={handleHallux2R}
                placeholder="select"
              />
            </div>
            <div className="col-md-6 col-sm-6 mb_10">
              <RadioSwitch
                labelLeft="Active"
                labelRight="Passive"
                idLeft="halluxr2R"
                idRight="halluxr2L"
                name="hallux2L"
                title={translation.Second_L}
                styleClass=""
                value={hallux2L}
                onChange={handleHallux2L}
                placeholder="select"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-6 mb_10">
              <RadioSwitch
                labelLeft="Active"
                labelRight="Passive"
                idLeft="halluxR3"
                idRight="halluxL3"
                name="hallux3R"
                title={translation.Third_R}
                styleClass=""
                value={hallux3R}
                onChange={handleHallux3R}
                placeholder="select"
              />
            </div>
            <div className="col-md-6 col-sm-6 mb_10">
              <RadioSwitch
                labelLeft="Active"
                labelRight="Passive"
                idLeft="halluxr3R"
                idRight="halluxr3L"
                name="hallux3L"
                title={translation.Third_L}
                styleClass=""
                value={hallux3L}
                onChange={handleHallux3L}
                placeholder="select"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-6 mb_10">
              <RadioSwitch
                labelLeft="Active"
                labelRight="Passive"
                idLeft="halluxR4"
                idRight="halluxL4"
                name="hallux4R"
                title={translation.Fourth_R}
                styleClass=""
                value={hallux4R}
                onChange={handleHallux4R}
                placeholder="select"
              />
            </div>
            <div className="col-md-6 col-sm-6 mb_10">
              <RadioSwitch
                labelLeft="Active"
                labelRight="Passive"
                idLeft="halluxr4R"
                idRight="halluxr4L"
                name="hallux4L"
                title={translation.Fourth_L}
                styleClass=""
                value={hallux4L}
                onChange={handleHallux4L}
                placeholder="select"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-6 mb_10">
              <RadioSwitch
                labelLeft="Active"
                labelRight="Passive"
                idLeft="halluxR5"
                idRight="halluxL5"
                name="hallux5R"
                title={translation.Fifth_R}
                styleClass=""
                value={hallux5R}
                onChange={handleHallux5R}
                placeholder="select"
              />
            </div>
            <div className="col-md-6 col-sm-6 mb_10">
              <RadioSwitch
                labelLeft="Active"
                labelRight="Passive"
                idLeft="halluxr5R"
                idRight="halluxr5L"
                name="hallux5L"
                title={translation.Fifth_L}
                styleClass=""
                value={hallux5L}
                onChange={handleHallux5L}
                placeholder="select"
              />
            </div>
          </div>
        </div>

        <div className="input_tab_box mb_30">
          <h4 className="range_sl_title">{translation.PIPJ}</h4>
          <div className="row">
            <div className="col-md-6 col-sm-6 mb_10">
              <RadioSwitch
                labelLeft="Active"
                labelRight="Passive"
                idLeft="pihalluxR"
                idRight="pihalluxL"
                name="pihalluxR"
                title={translation.Hallux_R}
                styleClass=""
                value={halluxRpi}
                onChange={handleHalluxRpi}
                placeholder="select"
              />
            </div>
            <div className="col-md-6 col-sm-6 mb_10">
              <RadioSwitch
                labelLeft="Active"
                labelRight="Passive"
                idLeft="pihalluxrR"
                idRight="pihalluxrL"
                name="pihalluxL"
                title={translation.Hallux_L}
                styleClass=""
                value={halluxLpi}
                onChange={handleHalluxLpi}
                placeholder="select"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-6 mb_10">
              <RadioSwitch
                labelLeft="Active"
                labelRight="Passive"
                idLeft="halluxR2pi"
                idRight="halluxL2pi"
                name="hallux2Rpi"
                title={translation.Second_R}
                styleClass=""
                value={hallux2Rpi}
                onChange={handleHallux2Rpi}
                placeholder="select"
              />
            </div>
            <div className="col-md-6 col-sm-6 mb_10">
              <RadioSwitch
                labelLeft="Active"
                labelRight="Passive"
                idLeft="halluxr2Rpi"
                idRight="halluxr2Lpi"
                name="hallux2Lpi"
                title={translation.Second_L}
                styleClass=""
                value={hallux2Lpi}
                onChange={handleHallux2Lpi}
                placeholder="select"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-6 mb_10">
              <RadioSwitch
                labelLeft="Active"
                labelRight="Passive"
                idLeft="halluxR3pi"
                idRight="halluxL3pi"
                name="hallux3Rpi"
                title={translation.Third_R}
                styleClass=""
                value={hallux3Rpi}
                onChange={handleHallux3Rpi}
                placeholder="select"
              />
            </div>
            <div className="col-md-6 col-sm-6 mb_10">
              <RadioSwitch
                labelLeft="Active"
                labelRight="Passive"
                idLeft="halluxr3Rpi"
                idRight="halluxr3Lpi"
                name="hallux3Lpi"
                title={translation.Third_L}
                styleClass=""
                value={hallux3Lpi}
                onChange={handleHallux3Lpi}
                placeholder="select"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-6 mb_10">
              <RadioSwitch
                labelLeft="Active"
                labelRight="Passive"
                idLeft="halluxR4pi"
                idRight="halluxL4pi"
                name="hallux4Rpi"
                title={translation.Fourth_R}
                styleClass=""
                value={hallux4Rpi}
                onChange={handleHallux4Rpi}
                placeholder="select"
              />
            </div>
            <div className="col-md-6 col-sm-6 mb_10">
              <RadioSwitch
                labelLeft="Active"
                labelRight="Passive"
                idLeft="halluxr4Rpi"
                idRight="halluxr4Lpi"
                name="hallux4Lpi"
                title={translation.Fourth_L}
                styleClass=""
                value={hallux4Lpi}
                onChange={handleHallux4Lpi}
                placeholder="select"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-6 mb_10">
              <RadioSwitch
                labelLeft="Active"
                labelRight="Passive"
                idLeft="halluxR5pi"
                idRight="halluxL5pi"
                name="hallux5Rpi"
                title={translation.Fifth_R}
                styleClass=""
                value={hallux5Rpi}
                onChange={handleHallux5Rpi}
                placeholder="select"
              />
            </div>
            <div className="col-md-6 col-sm-6 mb_10">
              <RadioSwitch
                labelLeft="Active"
                labelRight="Passive"
                idLeft="halluxr5Rpi"
                idRight="halluxr5Lpi"
                name="hallux5Lpi"
                title={translation.Fifth_L}
                styleClass=""
                value={hallux5Lpi}
                onChange={handleHallux5Lpi}
                placeholder="select"
              />
            </div>
          </div>
        </div>

        <div className="input_tab_box mb_30">
          <h4 className="range_sl_title">{translation.DIPJ}</h4>
          <div className="row">
            <div className="col-md-6 col-sm-6 mb_10">
              <RadioSwitch
                labelLeft="Active"
                labelRight="Passive"
                idLeft="halluxRdo"
                idRight="halluxLdo"
                name="halluxRdo"
                title={translation.Hallux_R}
                styleClass=""
                value={halluxRdo}
                onChange={handleHalluxRdo}
                placeholder="select"
              />
            </div>
            <div className="col-md-6 col-sm-6 mb_10">
              <RadioSwitch
                labelLeft="Active"
                labelRight="Passive"
                idLeft="halluxrRdo"
                idRight="halluxrLdo"
                name="halluxLdo"
                title={translation.Hallux_L}
                styleClass=""
                value={halluxLdo}
                onChange={handleHalluxLdo}
                placeholder="select"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-6 mb_10">
              <RadioSwitch
                labelLeft="Active"
                labelRight="Passive"
                idLeft="halluxR2do"
                idRight="halluxL2do"
                name="hallux2Rdo"
                title={translation.Second_R}
                styleClass=""
                value={hallux2Rdo}
                onChange={handleHallux2Rdo}
                placeholder="select"
              />
            </div>
            <div className="col-md-6 col-sm-6 mb_10">
              <RadioSwitch
                labelLeft="Active"
                labelRight="Passive"
                idLeft="halluxr2Rdo"
                idRight="halluxr2Ldo"
                name="hallux2Ldo"
                title={translation.Second_L}
                styleClass=""
                value={hallux2Ldo}
                onChange={handleHallux2Ldo}
                placeholder="select"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-6 mb_10">
              <RadioSwitch
                labelLeft="Active"
                labelRight="Passive"
                idLeft="halluxR3do"
                idRight="halluxL3do"
                name="hallux3Rdo"
                title={translation.Third_R}
                styleClass=""
                value={hallux3Rdo}
                onChange={handleHallux3Rdo}
                placeholder="select"
              />
            </div>
            <div className="col-md-6 col-sm-6 mb_10">
              <RadioSwitch
                labelLeft="Active"
                labelRight="Passive"
                idLeft="halluxr3Rdo"
                idRight="halluxr3Ldo"
                name="hallux3Ldo"
                title={translation.Third_L}
                styleClass=""
                value={hallux3Ldo}
                onChange={handleHallux3Ldo}
                placeholder="select"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-6 mb_10">
              <RadioSwitch
                labelLeft="Active"
                labelRight="Passive"
                idLeft="halluxR4do"
                idRight="halluxL4do"
                name="hallux4Rdo"
                title={translation.Fourth_R}
                styleClass=""
                value={hallux4Rdo}
                onChange={handleHallux4Rdo}
                placeholder="select"
              />
            </div>
            <div className="col-md-6 col-sm-6 mb_10">
              <RadioSwitch
                labelLeft="Active"
                labelRight="Passive"
                idLeft="halluxr4Rdo"
                idRight="halluxr4Ldo"
                name="hallux4Ldo"
                title={translation.Fourth_L}
                styleClass=""
                value={hallux4Ldo}
                onChange={handleHallux4Ldo}
                placeholder="select"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-6 mb_10">
              <RadioSwitch
                labelLeft="Active"
                labelRight="Passive"
                idLeft="halluxR5do"
                idRight="halluxL5do"
                name="hallux5Rdo"
                title={translation.Fifth_R}
                styleClass=""
                value={hallux5Rdo}
                onChange={handleHallux5Rdo}
                placeholder="select"
              />
            </div>
            <div className="col-md-6 col-sm-6 mb_10">
              <RadioSwitch
                labelLeft="Active"
                labelRight="Passive"
                idLeft="halluxr5Rdo"
                idRight="halluxr5Ldo"
                name="hallux5Ldo"
                title={translation.Fifth_L}
                styleClass=""
                value={hallux5Ldo}
                onChange={handleHallux5Ldo}
                placeholder="select"
              />
            </div>
          </div>
        </div>
        {doctorEnable === "true" && (
          <div className="row mb_30 button_box">
            <div className="col-md-6 col-sm-6">
            </div>
            <div className="col-md-6 col-sm-6 text-end">
              <button
                className="next_btn btn_fill"
                onClick={() => handleSubmit()}
              >
                {translation.submit}
                <span>
                  <img src={nextIcon} alt="" />
                  {/* <img src={nextWhiteIcon} alt="" /> */}
                </span>
              </button>
            </div>
          </div>
        )}
        {doctorEnable !== "true" && (
          <div className="row mb_30 button_box">
            <div className="col-md-6 col-sm-6">
              <Link to={"/foot-doctor-feedback/" + rId + `?doctor=${dId}&patient=${pId}`} className="assm_back_btn">
                <img src={backIcon} alt="" />
                <span>{translation.back_button}</span>
              </Link>
            </div>
            <div className="col-md-6 col-sm-6 text-end">
              <button
                className="next_btn btn_fill"
                onClick={continueRedirect}
              >
                {translation.next_button}
                <span>
                  <img src={nextIcon} alt="" />
                  <img src={nextWhiteIcon} alt="" />
                </span>
              </button>
            </div>
          </div>
        )}

      </div>

      </>

    )}

    </>


  )
}
