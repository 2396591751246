import React from "react";
import "../../../css/ViewTreatmentForm.css";
import Button from "../../custom/button/Button";
import useTranslation from "../../customHooks/translations";
import { UserDateFormat } from "../../../utilities/DateFormate";

function PersonalInformation({ profileAllData, AllData, userage }) {
  const translations = useTranslation();
  console.log(userage,"this is userage");
  return (
    <>
      <div className="whitebox padding-none">
        <div className="treatment-header">
          <h2>{translations.patient_personal_info}</h2>
          <div className="treatment-header-right">
            <p>
              {translations.Assessment_Date} : {UserDateFormat(AllData.assessment_date,profileAllData.ing_date_formate) }
            </p>
          </div>
        </div>

        <div className="treatment-content">
          <div className="treatment-row">
            <div className="treatment-col">
              <label>{translations.Name}:</label>
              {profileAllData.ing_firstname} {profileAllData.ing_lastname}
            </div>

            <div className="treatment-col">
              <label>{translations.Date_of_Birth}:</label>
              {profileAllData.ing_dob}
            </div>
          </div>

          <div className="treatment-row">
            <div className="treatment-col">
              <label>{translations.Sex_1}:</label>
              {/* {profileAllData.ing_gender === "2" ? "Female" : "Male"} */}
              {profileAllData.ing_gender === "1"
                ? translations.Male
                : profileAllData.ing_gender === "2"
                ? translations.Female
                : profileAllData.ing_gender === "3"
                ? translations.want_to_disclose
                : ""}
            </div>

            {/* <div className="treatment-col">
              <label>Dominant Hand:</label>
              Bilateral
            </div> */}
            <div className="treatment-col">
              <label>{translations.patient_Initial_Assessment}:</label>
              {AllData.initial_assessment === 1
                ? translations.Yes
                : translations.No}
            </div>
          </div>

          <div className="treatment-row">
            <div className="treatment-col">
              <label>{translations.Evaluated_Knee}:</label>
              {AllData.evlAdultKnee === "3"
                ? translations.Both
                : AllData.evlAdultKnee === "2"
                ? translations.Left
                : translations.Right}
            </div>
            <div className="treatment-col">
              <label>{translations.patient_Treatment_Category}:</label>
            {/* {translations.adult_knee} */}
            {userage < 18
                  ? translations.adolescent_knee
                  : translations.adult_knee}
            </div>
          </div>
          {AllData.evlAdultKnee !== "3" && (
            <div className="treatment-row">
              <div className="treatment-col">
                <label>{translations.Is_your_unaffected_knee_pain}:</label>
                {AllData.unaffected_adult === "1"
                  ? translations.Yes
                  : AllData.unaffected_adult === "0"
                  ? translations.No
                  : ""}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default PersonalInformation;
