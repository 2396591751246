import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";

import { validateInput } from "../../../utilities/Validator";
import scrangeStyles from "./ScoreRangeSlider.module.css";
import useTranslation from "../../customHooks/translations";
function ScoreRangeSlider({
  name,
  classes,
  label,
  onChange,
  onMouseUp,
  value,
  validators,
  validator,
  ...sliderProps
  

}) {
  const [countR, setCountR] = useState(0);
  const translation=useTranslation();

  const handleChange = (event) => {
    const { value } = event.target;
    onChange(value);
  };
  // console.log("value1", value);
  //   useEffect(() => {
  //     // if you dont set your inital state in your parent component
  //     // this effect will only run once when component did mount and
  //     // passes the initial value back to the parent component.
  //     onChange(value);
  //   }, []);

  return (
    <>
      <div className={`score_range_slider ${scrangeStyles.score_range_slider}`}>
        <label>{label}</label>
        <div className={`${scrangeStyles.tabslider_content}`}>
          <div className={`${scrangeStyles.range}`}>
            <input
              {...sliderProps}
              min="0"
              max="5"
              steps="1"
              type="range"
              value={value === "" ? " " : value}
              className={`slider ${classes}`}
              id="myRange"
              onChange={handleChange}
              onClick={handleChange}
            />
          </div>
          <ul className={` range_labels ${scrangeStyles.range_labels}`}>
            <li className={`${value === "0" ? "active" : ""}`}>
              0 <span>{name ? null : "Power Grade"}</span>
            </li>
            <li className={` ${value === "1" ? "active" : ""}`}>
              1 <span>{name ? null : "Power Grade"}</span>
            </li>
            <li className={` ${value === "2" ? "active" : ""}`}>
              2 <span>{name ? null : "Power Grade"}</span>
            </li>
            <li className={` ${value === "3" ? "active" : ""}`}>
              3 <span>{name ? null : "Power Grade"}</span>
            </li>
            <li className={` ${value === "4" ? "active" : ""}`}>
              4<span>{name ? null : "Power Grade"}</span>
            </li>
            <li className={` ${value === "5" ? "active" : ""}`}>
              5<span>{name ? null : "Power Grade"}</span>
            </li>
          </ul>
        
        </div>
        {value==="" || isNaN(value) || value===null?(
            <>
 
          {validator===true && (
          <span className="text-danger">
              {translation.This_field_is_required}
              </span>
            )}
            </>
        ):""}
      </div>
    </>
  );
}

ScoreRangeSlider.propTypes = {
  validators: PropTypes.array,
};

ScoreRangeSlider.defaultProps = {
  validators: [],
  label: "",
  value: "",
};

export default ScoreRangeSlider;
