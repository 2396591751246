import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { validateInput } from "../../../utilities/Validator";
import kgWidgetStyles from "./AssessmentPowerKgWidget.module.css";

function AssessmentPointDegree({
  title,
  kg,
  power,
  validators,
  labelR,
  labelL,
  onChangeR,
  onChangeL,
  rightArmkgAction,
  leftArmkgAction,
  validator,
  validatorL,
  viewValidation
}) {
  const [countP, setCountP] = useState(power);
  const [countK, setCountK] = useState(kg);
  const [errorValP, setErrorValP] = useState(false);
  const [errorValK, setErrorValK] = useState(false);
  const [errorP, setErrorP] = useState(false);
  const [errorK, setErrorK] = useState(false);
  const [errorMsgP, setErrorMsgP] = useState("Number Should be 0 to 100");
  const [errorMsgK, setErrorMsgK] = useState("Number Should be 0 to 100");

  useEffect(() => {
    setCountP(power);
    setCountK(kg);
  }, [power, kg]);



  const handleChangeKgRight = (event) => {
    const { value } = event.target;
    const roundedValue = parseFloat(value).toFixed(1);
    let newValue = parseFloat(roundedValue);
    setErrorValP(validateInput(validators, roundedValue));

    if (value === "" || (!isNaN(newValue) && newValue >= 0 && newValue <= 100)) {
      setCountP(isNaN(newValue) ? "" : newValue);
      onChangeR(isNaN(newValue) ? "" : newValue);
      setErrorP(false);
      console.log("✅ num is between values");
    } else {
      console.log("⛔️ num is NOT between values");
      setCountP("");
      setErrorP(true);
    }
  };

  const handleIncrementKgRight = () => {
    if (isNaN(countP) && countP === "") {
      setCountP(0);
    } else {
      let incrementedValue = 0;
      if (countP % 1 !== 0) {
        // If the value has a decimal
        const decimalCount = countP.toString().split(".")[1].length || 0;
        if (decimalCount === 1) {
          incrementedValue = parseFloat((Number(countP) + 0.1).toFixed(2));
        } else if (decimalCount === 2) {
          incrementedValue = parseFloat((Number(countP) + 0.01).toFixed(2));
        }
      } else {
        incrementedValue = parseFloat((Number(countP) + 1).toFixed(2));
      }
      setCountP(incrementedValue <= 100 ? incrementedValue : 100);
      onChangeR(incrementedValue <= 100 ? incrementedValue : 100);
    }
  };





  const handleDecrementKgRight = () => {
    if (!isNaN(countP) && countP >= 0) {
      let decrementedValue = 0;
      if (countP % 1 !== 0) {
        const decimalCount = countP.toString().split(".")[1].length || 0;
        if (decimalCount === 1) {
          decrementedValue = parseFloat((countP - 0.1).toFixed(2));
        } else if (decimalCount === 2) {
          decrementedValue = parseFloat((countP - 0.01).toFixed(2));
        }
      } else {
        decrementedValue = parseFloat((countP - 1).toFixed(2));
      }
      setCountP(decrementedValue >= 0 ? decrementedValue : 0);
      onChangeR(decrementedValue >= 0 ? decrementedValue : 0);
    }
  };






  const handleChangeKgLeft = (event) => {
    const { value } = event.target;
    // Ensure that there's only one decimal place
    const roundedValue = parseFloat(value).toFixed(1);
    let newValue = parseFloat(roundedValue);
    setErrorValK(validateInput(validators, roundedValue));
  
    if (!isNaN(newValue) && newValue >= 0 && newValue <= 100) {
      setCountK(isNaN(newValue) ? "" : newValue);
      onChangeL(isNaN(newValue) ? "" : newValue);
      setErrorK(false);
      console.log("✅ num is between values");
    } else {
      console.log("⛔️ num is NOT between values");
      setCountK("");
      setErrorK(true);
    }
  };
  

  const handleIncrementKgLeft = () => {
    if (isNaN(countK) && countK === "") {
      setCountK(0);
    } else {
      let incrementedValue = 0;
      if (countK % 1 !== 0) {
        // If the value has a decimal
        const decimalCount = countK.toString().split(".")[1].length || 0;
        if (decimalCount === 1) {
          incrementedValue = parseFloat((Number(countK) + 0.1).toFixed(2));
        } else if (decimalCount === 2) {
          incrementedValue = parseFloat((Number(countK) + 0.01).toFixed(2));
        }
      } else {
        incrementedValue = parseFloat((Number(countK) + 1).toFixed(2));
      }
      setCountK(incrementedValue <= 100 ? incrementedValue : 100);
      onChangeL(incrementedValue <= 100 ? incrementedValue : 100);
    }
  };

  const handleDecrementKgLeft = () => {
    if (!isNaN(countK) && countK >= 0) {
      let decrementedValue = 0;
      if (countK % 1 === 0) {
        decrementedValue = parseFloat((countK - 1).toFixed(2));
      } else {
        const decimalCount = countK.toString().split(".")[1].length || 0;
        if (decimalCount === 2) {
          decrementedValue = parseFloat((countK - 0.01).toFixed(2));
        } else {
          decrementedValue = parseFloat((countK - 0.1).toFixed(2));
        }
      }
      setCountK(decrementedValue >= 0 ? decrementedValue : 0);
      onChangeL(decrementedValue >= 0 ? decrementedValue : 0);
    }
  };
  

  return (
    <div className={`${kgWidgetStyles.input_box_main} single_kg`}>
      <h4 className={`${kgWidgetStyles.title_txt}`}>{title}</h4>
      <div className={`${kgWidgetStyles.number_box_row}`}>
        {rightArmkgAction && (
          <div className={`${kgWidgetStyles.number_box_col}`}>
            <label
              className={`${kgWidgetStyles.requiredFieldRightF}`}
              style={{ paddingBlock: "10px" }}
            >
              {labelR}
            </label>
            <div className={`${kgWidgetStyles.degree_box}`}>
              <span
                className={`${kgWidgetStyles.qty_minus}`}
                onClick={handleDecrementKgRight}
              >
                -
              </span>
              <input
                type="number"
                max={100}
                min={0}
                step={0.01}
                className="qty trim"
                name="right_kg"
                value={countP}
                placeholder="000"
                onChange={handleChangeKgRight}
                onBlur={handleChangeKgRight}
              />

              <span
                className={`${kgWidgetStyles.qty_plus}`}
                onClick={handleIncrementKgRight}
              >
                +
              </span>
            </div>
            <span className={`${kgWidgetStyles.degree_txt}`}>Degrees</span>
            {errorP ? (
              <>
                <span className={`${kgWidgetStyles.ValidationErrors}`}>
                {errorMsgP}
                </span>
              </>
            ) : (
              <>
                {viewValidation && (
                  <>
                  { countP===""?(
                    <>
                    {power === "" || isNaN(power) || power === null ? (
                      <>
                        {validator === true ? (
                          <span className={`${kgWidgetStyles.ValidationErrors}`}>
                            This field is required
                          </span>
                        ) : ""}
                      </>
                    ) : null}
                    </>
                  ) :""}
                    
                  </>
                )}
              </>
            )}
            {/* {errorP && (
              <span className={`${kgWidgetStyles.ValidationErrors}`}>
                {errorMsgP}
              </span>
            )}
            {errorValP && (
              <span className="text-danger">{errorValP.message}</span>
            )}
            {viewValidation &&(
              <>
               { power!==0?(
                    <>
               {power === "" || isNaN(power) || power === null ? (
              <>
                {validator === true && (
                  <span className={`${kgWidgetStyles.ValidationErrors}`}>
                    This field is required
                  </span>
                )}
              </>
            ) : null}
            </>
               ):""}
              </>
            )} */}
           
          </div>
        )}

        {leftArmkgAction && (
          <div className={`${kgWidgetStyles.number_box_col}`}>
            <label
              className={`${kgWidgetStyles.requiredFieldRightF}`}
              style={{ paddingBlock: "10px" }}
            >
              {labelL}
            </label>
            <div className={`${kgWidgetStyles.degree_box}`}>
              <span
                className={`${kgWidgetStyles.qty_minus}`}
                onClick={handleDecrementKgLeft}
              >
                -
              </span>
              <input
                type="number"
                max={100}
                min={0}
                step={0.01}
                className="qty trim"
                name="left_kg"
                value={countK}
                placeholder="000"
                onChange={handleChangeKgLeft}
                onBlur={handleChangeKgLeft}
              />

              <span
                className={`${kgWidgetStyles.qty_plus}`}
                onClick={handleIncrementKgLeft}
              >
                +
              </span>
            </div>
            <span className={`${kgWidgetStyles.degree_txt}`}>Degrees</span>
            {errorK ? (
              <>
                <span className={`${kgWidgetStyles.ValidationErrors}`}>
                {errorMsgK}
                </span>
              </>
            ) : (
              <>
                {viewValidation && (
                  <>
                  {countK===""?(
                    <>
                    {kg === "" || isNaN(kg) || kg === null ? (
                      <>
                        {validatorL === true ? (
                          <span className={`${kgWidgetStyles.ValidationErrors}`}>
                            This field is required
                          </span>
                        ) : ""}
                      </>
                    ) : null}
                    </>
                  ) :""}
                    
                  </>
                )}
              </>
            )}

            {/* {viewValidation &&(
              <>
               { kg!==0?(
                    <>
              {kg === "" || isNaN(kg) || kg === null ? (
              <>
                {validatorL === true && (
                  <span className={`${kgWidgetStyles.ValidationErrors}`}>
                    This field is required
                  </span>
                )}
              </>
            ) : null}
            </>
               ):""}
              </>
            )} */}
            
            {/* {errorK && (
              <span className={`${kgWidgetStyles.ValidationErrors}`}>
                {errorMsgK}
              </span>
            )}
            {errorValK && (
              <span className="text-danger">{errorValK.message}</span>
            )} */}
          </div>
        )}
      </div>
    </div>
  );
}

AssessmentPointDegree.propTypes = {
  validators: PropTypes.array,
  title: PropTypes.string,
  kg: PropTypes.number,
  power: PropTypes.number,
  labelR: PropTypes.string,
  labelL: PropTypes.string,
  onChangeR: PropTypes.func,
  onChangeL: PropTypes.func,
  rightArmkgAction: PropTypes.bool,
  leftArmkgAction: PropTypes.bool,
  validator: PropTypes.bool,
  validatorL: PropTypes.bool,
};

AssessmentPointDegree.defaultProps = {
  title: "",
  kg: "",
  power: "",
  labelR: "",
  labelL: "",
  onChangeR: () => {},
  onChangeL: () => {},
  rightArmkgAction: true,
  leftArmkgAction: true,
  validator: false,
  validatorL: false,
  validators: [],
};

export default AssessmentPointDegree;
