import { combineReducers } from "redux";
import  userIdReducer from "./userIdReducer";
import  userMailReducer from "./userMailReducer";
import  adultKneeScoreReducer from "./adultKneeScoreReducer";
import loaderReducer from './loaderReducer';
import hipScoreReducer from "./hipScoreReducer";
import FootAnkleReducer from "./footAnkleReducer";
import PediaKneeReducer from "./pediaKneeReducer";
import KneeFeedbackReduce from "./KneeFeedbackReduce";
import ElbowReducer from "./elbowReducer";

import ShoulderScoringReducer from './ShoulderScoringReducer';
import HandWristReducer from "./handWristReducer";
import GeneralFormReduces from "./GeneralFormReduces"
import accessTokens from "./accessTokens";

const reducers = combineReducers({
  userDetails: userIdReducer,
  userMailDetails: userMailReducer,
  adultKneeScore: adultKneeScoreReducer,
  loaderReducer,
  hipScoreReducer,
  footAnkle : FootAnkleReducer,
  pediaKnee : PediaKneeReducer,
  shoulderScore:ShoulderScoringReducer,
  elbowScore:ElbowReducer,
  handWrist:HandWristReducer,
  KneeFeedbackReduce:KneeFeedbackReduce,
  GeneralFormReduces,
  accessTokens

});

export default reducers;