export const RigthTrans=(e,translations,ankleChk)=>{
    if( e === "3"|| ankleChk === "0"){
         return ` (${translations.Right})` ;
    
    }else{
        return "";
    }

}

export const LeftTrans=(e,translations,ankleChk)=>{
    if ( e === "3"|| ankleChk === "0"){
        return ` (${translations.Left})`;
    }else{
        return "";
    }
}

export const RightSide =()=>{
    return " order-2"
}
export const LeftSide =()=>{
    return " order-1"
}