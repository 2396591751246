import React from "react";
import { returnStrings } from "../../../utilities/FindScoreStatus";
import { UserDateFormat } from "../../../utilities/DateFormate";

export default function ElbowDoctorFeedback({ doctorefeedback, AllData,profileAllData }) {
  console.log(doctorefeedback.ir4Right, doctorefeedback.rightpspecify, "error");
  return (
    <>
      <div className="whitebox padding-none">
        <div className="treatment-header">
          <h2>Elbow Doctor Feedback Form</h2>
          <div className="treatment-header-right">
            <p>Assessment Date : {UserDateFormat (AllData.assessment_date,profileAllData.ing_date_formate)}</p>
          </div>
        </div>

        <div className="treatment-content">
          <div className="treatment-row-header">Inspection</div>
          <div className="treatment-row">
            <div className="treatment-table">
              <div className="treatment-row mt-3">
                <div className="treatment-col">
                  <label>Scars Right:</label>
                  <p>
                    {doctorefeedback.scarsR === "0"
                      ? "Negative"
                      : doctorefeedback.scarsR === "1"
                        ? "Positive"
                        : ""}
                  </p>
                  {doctorefeedback.scarsR === "1" && (
                    <p>{doctorefeedback.scarsTextR}</p>
                  )}
                </div>
                <div className="treatment-col">
                  <label>Scars Left:</label>
                  <p>
                    {doctorefeedback.scarsL === "0"
                      ? "Negative"
                      : doctorefeedback.scarsL === "1"
                        ? "Positive"
                        : ""}
                  </p>
                  {doctorefeedback.scarsL === "1" && (
                    <p>{doctorefeedback.scarsTextL}</p>
                  )}
                </div>
              </div>

              <div className="treatment-row mt-3">
                <div className="treatment-col">
                  <label>Atrophy Right:</label>
                  <p>
                    {doctorefeedback.AtrophyR === "0"
                      ? "Negative"
                      : doctorefeedback.AtrophyR === "1"
                        ? "Positive"
                        : ""}
                  </p>
                  {doctorefeedback.AtrophyR === "1" && (
                    <p>{doctorefeedback.AtrophyTextR}</p>
                  )}
                </div>
                <div className="treatment-col">
                  <label>Atrophy Left:</label>
                  <p>
                    {doctorefeedback.AtrophyL === "0"
                      ? "Negative"
                      : doctorefeedback.AtrophyL === "1"
                        ? "Positive"
                        : ""}
                  </p>
                  {doctorefeedback.AtrophyL === "1" && (
                    <p>{doctorefeedback.AtrophyTextL}</p>
                  )}
                </div>
              </div>

              <div className="treatment-row mt-3">
                <div className="treatment-col">
                  <label>Deformity Right:</label>
                  <p>
                    {doctorefeedback.DeformityR === "0"
                      ? "Negative"
                      : doctorefeedback.DeformityR === "1"
                        ? "Positive"
                        : ""}
                  </p>
                  {doctorefeedback.DeformityR === "1" && (
                    <>
                      <p>
                        Flexion contracture : {doctorefeedback.contractureR}
                      </p>
                      <p>
                        Extension contracture : {doctorefeedback.contracture1R}
                      </p>
                      <p>Cubitus valgus : {doctorefeedback.contracture2R}</p>
                      <p>Cubitus varus : {doctorefeedback.contracture3R}</p>
                      <p>Others : {doctorefeedback.otherfieldR}</p>
                    </>
                  )}
                </div>
                <div className="treatment-col">
                  <label>Deformity Left:</label>
                  <p>
                    {doctorefeedback.DeformityL === "0"
                      ? "Negative"
                      : doctorefeedback.DeformityL === "1"
                        ? "Positive"
                        : ""}
                  </p>
                  {doctorefeedback.DeformityL === "1" && (
                    <>
                      <p>
                        Flexion contracture : {doctorefeedback.contractureL}
                      </p>
                      <p>
                        Extension contracture : {doctorefeedback.contracture1L}
                      </p>
                      <p>Cubitus valgus : {doctorefeedback.contracture2L}</p>
                      <p>Cubitus varus : {doctorefeedback.contracture3L}</p>
                      <p>Others : {doctorefeedback.otherfieldL}</p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="treatment-row-header">Tenderness</div>
          <div className="treatment-row">
            <div className="treatment-table">
              <table cellpadding="0" cellspacing="0">
                <thead>
                  <tr>
                    <th></th>

                    <th>Right Elbow</th>
                    <th>Left Elbow</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Ulnohumeral tenderness :</td>

                    <td>{doctorefeedback.ulnohumeralR}</td>
                    <td>{doctorefeedback.ulnohumeralL}</td>
                  </tr>

                  <tr>
                    <td>Radiocapitellar tenderness :</td>

                    <td>{doctorefeedback.RadiocapitellarR}</td>
                    <td>{doctorefeedback.RadiocapitellarL}</td>
                  </tr>

                  <tr>
                    <td>Medial flexor origin tenderness :</td>

                    <td>{doctorefeedback.MedialR}</td>
                    <td>{doctorefeedback.MedialL}</td>
                  </tr>

                  <tr>
                    <td>Lateral extensor origin tenderness :</td>
                    <td>{doctorefeedback.LateralR}</td>
                    <td>{doctorefeedback.LateralL}</td>
                  </tr>

                  <tr>
                    <td>MCL tenderness :</td>
                    <td>{doctorefeedback.MCLR}</td>
                    <td>{doctorefeedback.MCLL}</td>
                  </tr>

                  <tr>
                    <td>PIN tenderness :</td>
                    <td>{doctorefeedback.PINR}</td>
                    <td>{doctorefeedback.PINL}</td>
                  </tr>

                  <tr>
                    <td>Other tenderness specify :</td>
                    <td>{doctorefeedback.otherText}</td>
                    <td>{doctorefeedback.otherTextL}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="treatment-row-header">Crepitus</div>
          <div className="treatment-row">
            <div className="treatment-table">
              <table cellpadding="0" cellspacing="0">
                <thead>
                  <tr>
                    <th></th>
                    <th>Right Elbow</th>
                    <th>Left Elbow</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Ulnohumeral crepitus :</td>
                    <td>
                      {doctorefeedback.crepitusR === "0"
                        ? "Negative"
                        : doctorefeedback.crepitusR === "1"
                          ? "Positive"
                          : ""}
                    </td>
                    <td>
                      {doctorefeedback.crepitusL === "0"
                        ? "Negative"
                        : doctorefeedback.crepitusL === "1"
                          ? "Positive"
                          : ""}
                    </td>
                  </tr>

                  <tr>
                    <td>Radiocapitellar crepitus :</td>
                    <td>
                      {doctorefeedback.RadiocapitellarCrR === "0"
                        ? "Negative"
                        : doctorefeedback.RadiocapitellarCrR === "1"
                          ? "Positive"
                          : ""}
                    </td>
                    <td>
                      {doctorefeedback.RadiocapitellarCrL === "0"
                        ? "Negative"
                        : doctorefeedback.RadiocapitellarCrL === "1"
                          ? "Positive"
                          : ""}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="treatment-row-header">Range of Motion</div>
          <div className="treatment-row">
            <div className="treatment-table">
              <div className="treatment-row mt-3">
                <div className="treatment-col">
                  <label>Flexion-Extension arc Right:</label>
                  {doctorefeedback.RightFlexionR !== null && (
                    <p>Active: {doctorefeedback.RightFlexionR} Degrees</p>
                  )}
                  {doctorefeedback.RightFlexionL !== null && (
                    <p>Passive: {doctorefeedback.RightFlexionL} Degrees</p>
                  )}
                </div>
                <div className="treatment-col">
                  <label>Flexion-Extension arc Left:</label>
                  {doctorefeedback.LeftFlexionR !== null && (
                    <p>Active: {doctorefeedback.LeftFlexionR} Degrees</p>
                  )}
                  {doctorefeedback.LeftFlexionL !== null && (
                    <p>Passive: {doctorefeedback.LeftFlexionL} Degrees</p>
                  )}
                </div>
              </div>
              <div className="treatment-row mt-3">
                <div className="treatment-col">
                  <label>Right Pronation:</label>
                  {doctorefeedback.RightPronationR !== null && (
                    <p>Active: {doctorefeedback.RightPronationR} Degrees</p>
                  )}
                  {doctorefeedback.RightPronationL !== null && (
                    <p>Passive: {doctorefeedback.RightPronationL} Degrees</p>
                  )}
                </div>
                <div className="treatment-col">
                  <label>Left Pronation:</label>
                  {doctorefeedback.LeftPronationR !== null && (
                    <p>Active: {doctorefeedback.LeftPronationR} Degrees</p>
                  )}
                  {doctorefeedback.LeftPronationL !== null && (
                    <p>Passive: {doctorefeedback.LeftPronationL} Degrees</p>
                  )}
                </div>
              </div>
              <div className="treatment-row mt-3">
                <div className="treatment-col">
                  <label>Right Supination:</label>
                  {doctorefeedback.RightSupinationR !== null && (
                    <p>Active: {doctorefeedback.RightSupinationR} Degrees</p>
                  )}
                  {doctorefeedback.RightSupinationL !== null && (
                    <p>Passive: {doctorefeedback.RightSupinationL} Degrees</p>
                  )}
                </div>
                <div className="treatment-col">
                  <label>Left Supination:</label>
                  {doctorefeedback.LeftSupinationR !== null && (
                    <p>Active: {doctorefeedback.LeftSupinationR} Degrees</p>
                  )}
                  {doctorefeedback.LeftSupinationL !== null && (
                    <p>Passive: {doctorefeedback.LeftSupinationL} Degrees</p>
                  )}
                </div>
              </div>
              <div className="treatment-row mt-3">
                <div className="treatment-col">
                  <label>Right Pronation/Supination arc:</label>
                  {doctorefeedback.RightPronationSupinationarcR !== null && (
                    <p>
                      Active: {doctorefeedback.RightPronationSupinationarcR}{" "}
                      Degrees
                    </p>
                  )}
                  {doctorefeedback.RightPronationSupinationarcL !== null && (
                    <p>
                      Passive: {doctorefeedback.RightPronationSupinationarcL}{" "}
                      Degrees
                    </p>
                  )}
                </div>
                <div className="treatment-col">
                  <label>Left Pronation/Supination arc:</label>
                  {doctorefeedback.LeftPronationSupinationarcR !== null && (
                    <p>
                      Active: {doctorefeedback.LeftPronationSupinationarcR}{" "}
                      Degrees
                    </p>
                  )}
                  {doctorefeedback.LeftPronationSupinationarcL !== null && (
                    <p>
                      Passive: {doctorefeedback.LeftPronationSupinationarcL}{" "}
                      Degrees
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* ====================== {    2nd Step   }====================== */}

          <div className="treatment-row-header">STABILITY</div>

          <div className="treatment-row">
            <div className="treatment-table">
              <table cellpadding="0" cellspacing="0">
                <thead>
                  <tr>
                    <th></th>

                    <th>Right Elbow</th>
                    <th>Left Elbow</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Valgus:</td>
                    <td>
                      {doctorefeedback.ValgusR === "0"
                        ? "no instability"
                        : doctorefeedback.ValgusR === "1"
                          ? "mild laxity with good endpoint"
                          : doctorefeedback.ValgusR === "2"
                            ? "moderate laxity no endpoint"
                            : doctorefeedback.ValgusR === "3"
                              ? "gross instability"
                              : ""}
                    </td>
                    <td>
                      {" "}
                      {doctorefeedback.ValgusL === "0"
                        ? "no instability"
                        : doctorefeedback.ValgusL === "1"
                          ? "mild laxity with good endpoint"
                          : doctorefeedback.ValgusL === "2"
                            ? "moderate laxity no endpoint"
                            : doctorefeedback.ValgusL === "3"
                              ? "gross instability"
                              : ""}
                    </td>
                  </tr>
                  <tr>
                    <td>Varus :</td>
                    <td>{doctorefeedback.VarusR === "0"
                      ? "no instability"
                      : doctorefeedback.VarusR === "1"
                        ? "mild laxity with good endpoint"
                        : doctorefeedback.VarusR === "2"
                          ? "moderate laxity no endpoint"
                          : doctorefeedback.VarusR === "3"
                            ? "gross instability"
                            : ""}</td>
                    <td>{doctorefeedback.VarusL === "0"
                      ? "no instability"
                      : doctorefeedback.VarusL === "1"
                        ? "mild laxity with good endpoint"
                        : doctorefeedback.VarusL === "2"
                          ? "moderate laxity no endpoint"
                          : doctorefeedback.VarusL === "3"
                            ? "gross instability"
                            : ""}</td>
                  </tr>
                  <tr>
                    <td>Posterolateral rotatory :</td>
                    <td>{doctorefeedback.PosterolateralR === "0"
                      ? "no instability"
                      : doctorefeedback.PosterolateralR === "1"
                        ? "mild laxity with good endpoint"
                        : doctorefeedback.PosterolateralR === "2"
                          ? "moderate laxity no endpoint"
                          : doctorefeedback.PosterolateralR === "3"
                            ? "gross instability"
                            : ""}</td>
                    <td>{doctorefeedback.PosterolateralL === "0"
                      ? "no instability"
                      : doctorefeedback.PosterolateralL === "1"
                        ? "mild laxity with good endpoint"
                        : doctorefeedback.PosterolateralL === "2"
                          ? "moderate laxity no endpoint"
                          : doctorefeedback.PosterolateralL === "3"
                            ? "gross instability"
                            : ""}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="treatment-row-header">STRENGTH</div>
          <div className="treatment-row">
            <div className="treatment-table">
              <table cellpadding="0" cellspacing="0">
                <thead>
                  <tr>
                    <th></th>

                    <th>Right Elbow</th>
                    <th>Left Elbow</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Testing affected by pain:</td>
                    <td>
                      {doctorefeedback.elbowPainR === "0"
                        ? "No"
                        : doctorefeedback.elbowPainR === "1"
                          ? "Yes"
                          : ""}
                    </td>
                    <td>
                      {doctorefeedback.elbowPainL === "0"
                        ? "No"
                        : doctorefeedback.elbowPainL === "1"
                          ? "Yes"
                          : ""}
                    </td>
                  </tr>
                  <tr>
                    <td>Flexion:</td>
                    <td>{doctorefeedback.flexionlR}</td>
                    <td>{doctorefeedback.flexionlL}</td>
                  </tr>
                  <tr>
                    <td>Extension:</td>
                    <td>{doctorefeedback.ExtensionR}</td>
                    <td>{doctorefeedback.ExtensionL}</td>
                  </tr>
                  <tr>
                    <td>Pronation:</td>
                    <td>{doctorefeedback.PronationR}</td>
                    <td>{doctorefeedback.PronationL}</td>
                  </tr>
                  <tr>
                    <td>Supination:</td>
                    <td>{doctorefeedback.SupinationR}</td>
                    <td>{doctorefeedback.SupinationL}</td>
                  </tr>
                  <tr>
                    <td>Grip Strength:</td>
                    <td>{(doctorefeedback.strengthR !== null &&doctorefeedback.strengthR !== "" )? `${doctorefeedback.strengthR + " kgs"}` : ""}</td>
                    <td>{(doctorefeedback.strengthL !== null &&doctorefeedback.strengthL !== "" )? `${doctorefeedback.strengthL + " kgs"}` : ""}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="treatment-row-header">Nerve Assessment</div>
          <div className="treatment-row">
            <div className="treatment-table">
              <table cellpadding="0" cellspacing="0">
                <thead>
                  <tr>
                    <th></th>

                    <th>Right Elbow</th>
                    <th>Left Elbow</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Ulnar nerve tinels :</td>
                    <td>
                      {doctorefeedback.TinelsR === "0"
                        ? "Negative"
                        : doctorefeedback.TinelsR === "1"
                          ? "Positive"
                          : ""}
                    </td>
                    <td>
                      {doctorefeedback.TinelsL === "0"
                        ? "Negative"
                        : doctorefeedback.TinelsL === "1"
                          ? "Positive"
                          : ""}
                    </td>
                  </tr>
                  <tr>
                    <td>Cubital tunnel stretch test :</td>
                    <td>
                      {doctorefeedback.StretchR === "0"
                        ? "Negative"
                        : doctorefeedback.StretchR === "1"
                          ? "Positive"
                          : ""}
                    </td>
                    <td>
                      {doctorefeedback.StretchL === "0"
                        ? "Negative"
                        : doctorefeedback.StretchL === "1"
                          ? "Positive"
                          : ""}
                    </td>
                  </tr>
                  <tr>
                    <td>Froment sign :</td>
                    <td>
                      {doctorefeedback.FromentR === "0"
                        ? "Negative"
                        : doctorefeedback.FromentR === "1"
                          ? "Positive"
                          : ""}
                    </td>
                    <td>
                      {doctorefeedback.FromentL === "0"
                        ? "Negative"
                        : doctorefeedback.FromentL === "1"
                          ? "Positive"
                          : ""}
                    </td>
                  </tr>
                  <tr>
                    <td>Jeanne sign :</td>
                    <td>
                      {doctorefeedback.JeanneR === "0"
                        ? "Negative"
                        : doctorefeedback.JeanneR === "1"
                          ? "Positive"
                          : ""}
                    </td>
                    <td>
                      {doctorefeedback.JeanneL === "0"
                        ? "Negative"
                        : doctorefeedback.JeanneL === "1"
                          ? "Positive"
                          : ""}
                    </td>
                  </tr>
                  <tr>
                    <td>Wartenberg sign:</td>
                    <td>
                      {doctorefeedback.WartenbergR === "0"
                        ? "Negative"
                        : doctorefeedback.WartenbergR === "1"
                          ? "Positive"
                          : ""}
                    </td>
                    <td>
                      {doctorefeedback.WartenbergL === "0"
                        ? "Negative"
                        : doctorefeedback.WartenbergL === "1"
                          ? "Positive"
                          : ""}
                    </td>
                  </tr>
                  <tr>
                    <td>Masse sign:</td>
                    <td>
                      {doctorefeedback.MasseR === "0"
                        ? "Negative"
                        : doctorefeedback.MasseR === "1"
                          ? "Positive"
                          : ""}
                    </td>
                    <td>
                      {doctorefeedback.MasseL === "0"
                        ? "Negative"
                        : doctorefeedback.MasseL === "1"
                          ? "Positive"
                          : ""}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="treatment-row-header">Other Special tests</div>
          <div className="treatment-row">
            <div className="treatment-table">
              <table cellpadding="0" cellspacing="0">
                <thead>
                  <tr>
                    <th></th>

                    <th>Right Elbow</th>
                    <th>Left Elbow</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Impingement pain in flexion:</td>
                    <td>{doctorefeedback.ImpingementR}</td>
                    <td>{doctorefeedback.ImpingementL}</td>
                  </tr>
                  <tr>
                    <td>Impingement pain in extension:</td>
                    <td>{doctorefeedback.ImpingementPainR}</td>
                    <td>{doctorefeedback.ImpingementPainL}</td>
                  </tr>
                  <tr>
                    <td>Pain on resisted wrist extension:</td>
                    <td>
                      {doctorefeedback.wristR === "0"
                        ? "Negative"
                        : doctorefeedback.wristR === "1"
                          ? "Positive"
                          : ""}
                    </td>
                    <td>
                      {doctorefeedback.wristL === "0"
                        ? "Negative"
                        : doctorefeedback.wristL === "1"
                          ? "Positive"
                          : ""}
                    </td>
                  </tr>
                  <tr>
                    <td>Pain on resisted wrist flexion:</td>
                    <td>
                      {doctorefeedback.wristfR === "0"
                        ? "Negative"
                        : doctorefeedback.wristfR === "1"
                          ? "Positive"
                          : ""}
                    </td>
                    <td>
                      {doctorefeedback.wristfL === "0"
                        ? "Negative"
                        : doctorefeedback.wristfL === "1"
                          ? "Positive"
                          : ""}
                    </td>
                  </tr>
                  <tr>
                    <td>Pain on resisted long finger extension:</td>
                    <td>
                      {doctorefeedback.fingerR === "0"
                        ? "Negative"
                        : doctorefeedback.fingerR === "1"
                          ? "Positive"
                          : ""}
                    </td>
                    <td>
                      {doctorefeedback.fingerL === "0"
                        ? "Negative"
                        : doctorefeedback.fingerL === "1"
                          ? "Positive"
                          : ""}
                    </td>
                  </tr>
                  <tr>
                    <td>Pain on resisted long finger flexion:</td>
                    <td>
                      {doctorefeedback.fingerFR === "0"
                        ? "Negative"
                        : doctorefeedback.fingerFR === "1"
                          ? "Positive"
                          : ""}
                    </td>
                    <td>
                      {doctorefeedback.fingerFL === "0"
                        ? "Negative"
                        : doctorefeedback.fingerFL === "1"
                          ? "Positive"
                          : ""}
                    </td>
                  </tr>
                  <tr>
                    <td>Pain on resisted wrist pronation:</td>
                    <td>
                      {doctorefeedback.wpronationR === "0"
                        ? "Negative"
                        : doctorefeedback.wpronationR === "1"
                          ? "Positive"
                          : ""}
                    </td>
                    <td>
                      {doctorefeedback.wpronationL === "0"
                        ? "Negative"
                        : doctorefeedback.wpronationL === "1"
                          ? "Positive"
                          : ""}
                    </td>
                  </tr>
                  <tr>
                    <td>Pain on resisted wrist supination :</td>
                    <td>
                      {doctorefeedback.wSupinationR === "0"
                        ? "Negative"
                        : doctorefeedback.wSupinationR === "1"
                          ? "Positive"
                          : ""}
                    </td>
                    <td>
                      {doctorefeedback.wSupinationL === "0"
                        ? "Negative"
                        : doctorefeedback.wSupinationL === "1"
                          ? "Positive"
                          : ""}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="treatment-row-header">
            Other joints limiting activity
          </div>

          {/* ------------------------------- */}
          <div className="treatment-row ">
            <div className="col-6 ">
              <div className="row">
                <label className="text-black">Specify :</label>
              </div>
              {/* <div className="row mt-4">
                <label className="text-black mt-3">Specify :</label>
              </div> */}
            </div>
            <div className="col-3">
              {" "}
              <label className="text-black">Right Elbow</label>
              {/* {doctorefeedback.OtherjointsLimitsL && ( */}
              <>
                <div className="treatment-row ">
                  {returnStrings([
                    doctorefeedback.OtherjointsLimitsL && "Shoulder",
                    doctorefeedback.OtherjointsLimitwL && "Wrist"
                  ])}
                  {/* {doctorefeedback.OtherjointsLimitsL ? "Shoulder" : ""} */}
                </div>
                {doctorefeedback.OtherjointsLimitsL || doctorefeedback.OtherjointsLimitwL ? (
                  <div className="treatment-row">
                    <span>{doctorefeedback.shoulderinputL}</span>
                  </div>
                ) : ""}
              </>
              {/* )} */}
              <div className="treatment-row">
                {/* {doctorefeedback.OtherjointsLimitwL ? "Wrist" : ""} */}
              </div>
              {/* <div className="treatment-row">
                {doctorefeedback.OtherjointsLimitwL && (
                  <span>{doctorefeedback.WristinputL}</span>
                )}
              </div> */}
            </div>

            <div className="col-3">
              <label className="text-black">Left Elbow</label>

              {/* {doctorefeedback.OtherjointsLimitsR && ( */}
              <>
                <div className="treatment-row">
                  {returnStrings([doctorefeedback.OtherjointsLimitsR && "Shoulder",
                  doctorefeedback.OtherjointsLimitwR && "Wrist"
                  ])}
                  {/* {doctorefeedback.OtherjointsLimitsR ? "Shoulder" : ""} */}
                </div>
                {doctorefeedback.OtherjointsLimitsR || doctorefeedback.OtherjointsLimitwR ? (
                  <div className="treatment-row">
                    <span>{doctorefeedback.shoulderinputR}</span>
                  </div>
                ) : ""}
              </>
              {/* )} */}

              <div className="treatment-row">
                {/* {doctorefeedback.OtherjointsLimitwR ? "Wrist" : ""} */}
              </div>
              {/* {doctorefeedback.OtherjointsLimitwR && (
                <div className="treatment-row">
                  <span>{doctorefeedback.wristinputR}</span>
                </div>
              )} */}
            </div>
            <></>
          </div>

          {/* ----------------- */}
          {/* <div className='treatment-row'>
                        <div className='treatment-table'>
                            <table cellpadding="0" cellspacing="0">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Right Elbow</th>
                                        <th>Left Elbow</th>
                                    </tr>
                                </thead> */}

          {/* <tbody>
                                    <tr>
                                        <td> </td>
                                        <td>{doctorefeedback.OtherjointsLimitsR ? "Shoulder" : ""}
                                            {doctorefeedback.OtherjointsLimitwR ? "Wrist" : ""}</td>

                                        <td>{doctorefeedback.OtherjointsLimitsL ? "Shoulder" : ""}
                                            {doctorefeedback.OtherjointsLimitwL ? "Wrist" : ""}</td>
                                    </tr>
                                    <tr>
                                        <td>Specify :</td>
                                        {doctorefeedback.OtherjointsLimitsR && (
                                            <td>{doctorefeedback.shoulderinputR}</td>
                                        )}
                                        {doctorefeedback.OtherjointsLimitwR && (
                                            <td>{doctorefeedback.wristinputR}</td>
                                        )}

                                        {doctorefeedback.OtherjointsLimitsL && (
                                            <td>{doctorefeedback.shoulderinputL}</td>
                                        )}
                                        {doctorefeedback.OtherjointsLimitwL && (
                                            <td>{doctorefeedback.WristinputL}</td>
                                        )}

                                    </tr>

                                </tbody> */}
          {/* <tbody>
                                    <tr>
                                        <td> </td>
                                        <td>{doctorefeedback.OtherjointsLimitsL ? "Shoulder" : ""}</td>
                                        <td>{doctorefeedback.OtherjointsLimitsR ? "Shoulder" : ""}</td>
                                    </tr>
                                    <tr>
                                        <td>Specify :</td>

                                        <td className=''>{doctorefeedback.OtherjointsLimitsL && (
                                            <span>{doctorefeedback.shoulderinputL}</span>
                                            // <h1>Shoulder part 1</h1>
                                        )}
                                        </td>

                                        <td>{doctorefeedback.OtherjointsLimitsR && (
                                            <span>{doctorefeedback.shoulderinputR}</span>
                                        )}
                                        </td>



                                    </tr>
                                    <tr>
                                        <td> </td>
                                        <td>{doctorefeedback.OtherjointsLimitwL ? "Wrist" : ""}</td>
                                        <td>{doctorefeedback.OtherjointsLimitwR ? "Wrist" : ""}</td>
                                    </tr>
                                    <tr>
                                        <td>Specify :</td>
                                        <td>
                                            {doctorefeedback.OtherjointsLimitwL && (
                                                <span>{doctorefeedback.WristinputL}</span>
                                            )}
                                        </td>
                                        <td>
                                            {doctorefeedback.OtherjointsLimitwR && (
                                                <span>{doctorefeedback.wristinputR}</span>

                                            )}
                                        </td>



                                    </tr>

                                </tbody> */}

          {/* </table>
                        </div>
                    </div> */}

          {/* <div className="treatment-row-header">Other findings :</div> */}
          <div className="treatment-row">
            <div className="treatment-table">
              <table cellpadding="0" cellspacing="0">
                <thead>
                  <tr>
                    <th></th>

                    <th>Right Elbow</th>
                    <th>Left Elbow</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td></td>
                    {/* <td>Other findings  :</td> */}

                    <td class="text-right">{doctorefeedback.otherFindingsR}</td>
                    <td class="text-right">{doctorefeedback.otherFindingsL}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
