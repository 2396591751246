import React from "react";
import "../../../css/ViewTreatmentForm.css";
import useTranslation from "../../customHooks/translations";
import RangeSlider from "../../custom/range-slider/RangeSlider";
import { UserDateFormat } from "../../../utilities/DateFormate";

export default function AdultKneePainScoring({ adultPain, AllData, userage,profileAllData }) {
  const translations = useTranslation();
  // console.log(profileAllData,"timezone")
  return (
    <div className="whitebox padding-none">
      <div className="treatment-header">
        <h2>
          {" "}
          {userage < 18
            ? translations.Adolescent_knee_Scoring_Self_Evaluation_Questionnaire
            : translations.Adult_Knee_Scoring_Self_Evaluation_Questionnaire}{" "}
        </h2>
        <div className="treatment-header-right">
          <p>
            {translations.Assessment_Date} : {UserDateFormat(AllData.assessment_date,profileAllData.ing_date_formate) }
          </p>
        </div>
      </div>

      <div className="treatment-content">
        <div className="treatment-row-header">
          1. {translations.patient_adult_knee_pain}
        </div>

        <div className="treatment-row">
          <div className="treatment-col">
            <label>{translations.patient_adult_knee_pain_q1} ({translations.Right}):</label>
            <p>
              {adultPain.describePainR === "0"
                ? translations.patient_adult_knee_pain_q1_a5
                : adultPain.describePainR === "1"
                ? translations.patient_adult_knee_pain_q1_a4
                : adultPain.describePainR === "2"
                ? translations.patient_adult_knee_pain_q1_a3
                : adultPain.describePainR === "3"
                ? translations.patient_adult_knee_pain_q1_a2
                : adultPain.describePainR === "4"
                ? translations.patient_adult_knee_pain_q1_a1
                : ""}
            </p>
          </div>
          <div className="treatment-col">
            <label>{translations.patient_adult_knee_pain_q1} ({translations.Left}):</label>
            <p>
              {adultPain.describePainL === "0"
                ? translations.patient_adult_knee_pain_q1_a5
                : adultPain.describePainL === "1"
                ? translations.patient_adult_knee_pain_q1_a4
                : adultPain.describePainL === "2"
                ? translations.patient_adult_knee_pain_q1_a3
                : adultPain.describePainL === "3"
                ? translations.patient_adult_knee_pain_q1_a2
                : adultPain.describePainL === "4"
                ? translations.patient_adult_knee_pain_q1_a1
                : ""}
            </p>
          </div>
        </div>
        <div className="treatment-row">
          <div className="treatment-col">
            <label>{translations.patient_adult_knee_pain_q2} ({translations.Right}):</label>
            {adultPain.painTodayRatingR}
            <p>
              {adultPain.painTodayRatingR === "0"
                ? translations.patient_adult_knee_pain_q2_a1
                : adultPain.painTodayRatingR === "1" ||
                  adultPain.painTodayRatingR === "2" ||
                  adultPain.painTodayRatingR === "3"
                ? translations.patient_adult_knee_pain_q2_a2
                : adultPain.painTodayRatingR === "4" ||
                  adultPain.painTodayRatingR === "5" ||
                  adultPain.painTodayRatingR === "6"
                ? translations.patient_adult_knee_pain_q2_a3
                : adultPain.painTodayRatingR === "7" ||
                  adultPain.painTodayRatingR === "8" ||
                  adultPain.painTodayRatingR === "9"
                ? translations.patient_adult_knee_pain_q2_a4
                : adultPain.painTodayRatingR === "10"
                ? translations.patient_adult_knee_pain_q2_a5
                : ""}
            </p>
          </div>
          <div className="treatment-col">
            <label>{translations.patient_adult_knee_pain_q2} ({translations.Left}):</label>
            {adultPain.painTodayRatingL}
            <p>
              {adultPain.painTodayRatingL === "0"
                ? translations.patient_adult_knee_pain_q2_a1
                : adultPain.painTodayRatingL === "1" ||
                  adultPain.painTodayRatingL === "2" ||
                  adultPain.painTodayRatingL === "3"
                ? translations.patient_adult_knee_pain_q2_a2
                : adultPain.painTodayRatingL === "4" ||
                  adultPain.painTodayRatingL === "5" ||
                  adultPain.painTodayRatingL === "6"
                ? translations.patient_adult_knee_pain_q2_a3
                : adultPain.painTodayRatingL === "7" ||
                  adultPain.painTodayRatingL === "8" ||
                  adultPain.painTodayRatingL === "9"
                ? translations.patient_adult_knee_pain_q2_a4
                : adultPain.painTodayRatingL === "10"
                ? translations.patient_adult_knee_pain_q2_a5
                : ""}
            </p>
          </div>
        </div>
        <div className="treatment-row">
          <div className="treatment-col">
            <label>{translations.patient_adult_knee_pain_q3} ({translations.Right}):</label>

            <p>
              {adultPain.bestStatmentForPainR === "0"
                ? translations.patient_adult_knee_pain_q3_a6
                : adultPain.bestStatmentForPainR === "1"
                ? translations.patient_adult_knee_pain_q3_a5
                : adultPain.bestStatmentForPainR === "2"
                ? translations.patient_adult_knee_pain_q3_a3
                : adultPain.bestStatmentForPainR === "3"
                ? translations.patient_adult_knee_pain_q3_a4
                : adultPain.bestStatmentForPainR === "4"
                ? translations.patient_adult_knee_pain_q3_a2
                : translations.patient_adult_knee_pain_q3_a1}
            </p>
          </div>
          <div className="treatment-col">
            <label>{translations.patient_adult_knee_pain_q3} ({translations.Left}):</label>
            <p>
              {adultPain.bestStatmentForPainL === "0"
                ? translations.patient_adult_knee_pain_q3_a6
                : adultPain.bestStatmentForPainL === "1"
                ? translations.patient_adult_knee_pain_q3_a5
                : adultPain.bestStatmentForPainL === "2"
                ? translations.patient_adult_knee_pain_q3_a3
                : adultPain.bestStatmentForPainL === "3"
                ? translations.patient_adult_knee_pain_q3_a4
                : adultPain.bestStatmentForPainL === "4"
                ? translations.patient_adult_knee_pain_q3_a2
                : translations.patient_adult_knee_pain_q3_a1}
            </p>
          </div>
        </div>
        <div className="treatment-row">
          <div className="treatment-col">
            <label>{translations.patient_adult_knee_pain_q4} ({translations.Right}):</label>
            {adultPain.howSevereR}
            <p>
              {adultPain.howSevereR === "0"
                ? translations.patient_adult_knee_pain_q4_a1
                : adultPain.howSevereR === "1"
                ? translations.patient_adult_knee_pain_q4_a2
                : adultPain.howSevereR === "2" || adultPain.howSevereR === "3"
                ? translations.patient_adult_knee_pain_q4_a3
                : adultPain.howSevereR === "4" ||
                  adultPain.howSevereR === "5" ||
                  adultPain.howSevereR === "6"
                ? translations.patient_adult_knee_pain_q4_a4
                : adultPain.howSevereR === "7" ||
                  adultPain.howSevereR === "8" ||
                  adultPain.howSevereR === "9" ||
                  adultPain.howSevereR === "10"
                ? translations.patient_adult_knee_pain_q4_a5
                : ""}
            </p>
          </div>
          <div className="treatment-col">
            <label>{translations.patient_adult_knee_pain_q4} ({translations.Left}):</label>
            {adultPain.howSevereL}
            <p>
              {adultPain.howSevereL === "0"
                ? translations.patient_adult_knee_pain_q4_a1
                : adultPain.howSevereL === "1"
                ? translations.patient_adult_knee_pain_q4_a2
                : adultPain.howSevereL === "2" || adultPain.howSevereL === "3"
                ? translations.patient_adult_knee_pain_q4_a3
                : adultPain.howSevereL === "4" ||
                  adultPain.howSevereL === "5" ||
                  adultPain.howSevereL === "6"
                ? translations.patient_adult_knee_pain_q4_a4
                : adultPain.howSevereL === "7" ||
                  adultPain.howSevereL === "8" ||
                  adultPain.howSevereL === "9" ||
                  adultPain.howSevereL === "10"
                ? translations.patient_adult_knee_pain_q4_a5
                : ""}
            </p>
          </div>
        </div>
        {String(AllData.age) !== "1" ? (
          <>
            <div className="treatment-row">
              <div className="treatment-col">
                <label>
                  {translations.patient_adult_knee_pain_q5} ({translations.Right}):
                </label>
                <p>
                  {adultPain.kneeInterferedR === "4"
                    ? translations.patient_adult_knee_pain_q5_a5
                    : adultPain.kneeInterferedR === "3"
                    ? translations.patient_adult_knee_pain_q5_a4
                    : adultPain.kneeInterferedR === "2"
                    ? translations.patient_adult_knee_pain_q5_a3
                    : adultPain.kneeInterferedR === "1"
                    ? translations.patient_adult_knee_pain_q5_a2
                    : adultPain.kneeInterferedR === "0"
                    ? translations.patient_adult_knee_pain_q5_a1
                    : ""}
                </p>
              </div>
              <div className="treatment-col">
                <label>{translations.patient_adult_knee_pain_q5} ({translations.Left}):</label>
                <p>
                  {adultPain.kneeInterferedL === "4"
                    ? translations.patient_adult_knee_pain_q5_a5
                    : adultPain.kneeInterferedL === "3"
                    ? translations.patient_adult_knee_pain_q5_a4
                    : adultPain.kneeInterferedL === "2"
                    ? translations.patient_adult_knee_pain_q5_a3
                    : adultPain.kneeInterferedL === "1"
                    ? translations.patient_adult_knee_pain_q5_a2
                    : adultPain.kneeInterferedL === "0"
                    ? translations.patient_adult_knee_pain_q5_a1
                    : ""}
                </p>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}

        <div className="treatment-row">
          <div className="treatment-table">
            <table cellpadding="0" cellspacing="0">
              <thead>
                <tr>
                  <th></th>
                  <th> {translations.Right_Knee}</th>
                  <th> {translations.Left_Knee}</th>

                  {/* {AllData.evlAdultKnee === "1" || AllData.evlAdultKnee === "3" ? (
                                        <th>Right Knee</th>
                                    ) : (
                                        ""
                                    )}
                                    {AllData.evlAdultKnee === "2" || AllData.evlAdultKnee === "3" ? (
                                        <th>left Knee</th>
                                    ) : (
                                        ""
                                    )} */}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{translations.patient_adult_knee_pain_q6}:</td>

                  <td> {adultPain.twistingR}</td>
                  <td> {adultPain.twistingL}</td>
                </tr>
                <tr>
                  <td>{translations.patient_adult_knee_pain_q7}:</td>

                  <td> {adultPain.straighteningR}</td>
                  <td> {adultPain.straighteningL}</td>
                </tr>
                <tr>
                  <td>{translations.patient_adult_knee_pain_q8}:</td>

                  <td> {adultPain.bendingR}</td>
                  <td> {adultPain.bendingL}</td>
                </tr>
                <tr>
                  <td>{translations.patient_adult_knee_pain_q9}:</td>

                  <td> {adultPain.walkingSurfaceR}</td>
                  <td> {adultPain.walkingSurfaceL}</td>
                </tr>

                {String(AllData.age) !== "1" ? (
                  <>
                    <tr>
                      <td>{translations.patient_adult_knee_pain_q10}:</td>

                      <td>
                        {adultPain.howLongWalkR === "0"
                          ? translations.patient_adult_knee_pain_q10_a5
                          : adultPain.howLongWalkR === "1"
                          ? translations.patient_adult_knee_pain_q10_a4
                          : adultPain.howLongWalkR === "2"
                          ? translations.patient_adult_knee_pain_q10_a3
                          : adultPain.howLongWalkR === "3"
                          ? translations.patient_adult_knee_pain_q10_a2
                          : adultPain.howLongWalkR === "4"
                          ? translations.patient_adult_knee_pain_q10_a1
                          : ""}
                      </td>
                      <td>
                        {adultPain.howLongWalkL === "0"
                          ? translations.patient_adult_knee_pain_q10_a5
                          : adultPain.howLongWalkL === "1"
                          ? translations.patient_adult_knee_pain_q10_a4
                          : adultPain.howLongWalkL === "2"
                          ? translations.patient_adult_knee_pain_q10_a3
                          : adultPain.howLongWalkL === "3"
                          ? translations.patient_adult_knee_pain_q10_a2
                          : adultPain.howLongWalkL === "4"
                          ? translations.patient_adult_knee_pain_q10_a1
                          : ""}
                      </td>
                    </tr>
                  </>
                ) : (
                  <></>
                )}

                <tr>
                  <td>{translations.patient_adult_knee_pain_q11} :</td>

                  <td> {adultPain.useStepsR}</td>
                  <td> {adultPain.useStepsL}</td>
                </tr>
                <tr>
                  <td>{translations.patient_adult_knee_pain_q12} :</td>

                  <td> {adultPain.whileBedR}</td>
                  <td> {adultPain.whileBedL}</td>
                </tr>

                {String(AllData.age) !== "1" ? (
                  <>
                    <tr>
                      <td>{translations.patient_adult_knee_pain_q13} :</td>

                      <td>
                        {" "}
                        {adultPain.painAtNightR === "4"
                          ? translations.patient_adult_knee_pain_q13_a5
                          : adultPain.painAtNightR === "3"
                          ? translations.patient_adult_knee_pain_q13_a4
                          : adultPain.painAtNightR === "2"
                          ? translations.patient_adult_knee_pain_q13_a3
                          : adultPain.painAtNightR === "1"
                          ? translations.patient_adult_knee_pain_q13_a2
                          : adultPain.painAtNightR === "0"
                          ? translations.patient_adult_knee_pain_q13_a1
                          : ""}
                      </td>
                      <td>
                        {" "}
                        {adultPain.painAtNightL === "4"
                          ? translations.patient_adult_knee_pain_q13_a5
                          : adultPain.painAtNightL === "3"
                          ? translations.patient_adult_knee_pain_q13_a4
                          : adultPain.painAtNightL === "2"
                          ? translations.patient_adult_knee_pain_q13_a3
                          : adultPain.painAtNightL === "1"
                          ? translations.patient_adult_knee_pain_q13_a2
                          : adultPain.painAtNightL === "0"
                          ? translations.patient_adult_knee_pain_q13_a1
                          : ""}
                      </td>
                    </tr>
                  </>
                ) : (
                  <></>
                )}

                <tr>
                  <td>{translations.patient_adult_knee_pain_q14} :</td>

                  <td> {adultPain.sittingR}</td>
                  <td> {adultPain.sittingL}</td>
                </tr>
                <tr>
                  <td>{translations.patient_adult_knee_pain_q15} :</td>

                  <td> {adultPain.standingR}</td>
                  <td> {adultPain.standingL}</td>
                </tr>

                {String(AllData.age) !== "1" ? (
                  <>
                    <tr>
                      <td>{translations.patient_adult_knee_pain_q16} :</td>

                      <td> {adultPain.standingFromChairR}</td>
                      <td> {adultPain.standingFromChairL}</td>
                    </tr>
                  </>
                ) : (
                  <></>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
