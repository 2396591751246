import React, { useState, useEffect } from "react";
import AssessmentHeader from "../../../custom/assessment-header/AssessmentHeader";
import HeaderInnerBreadcrumb from "../../../custom/heade-inner-breadcrumb/HeaderInnerBreadcrumb";
import FullDropdown from "../../../custom/dropdown/FullDropdown";
import InputField from "../../../custom/inputfield/InputField";
import AssessmentDegreeWidget from "../../../custom/assessment-widgets/AssessmentDegreeWidget";
import { Link, useNavigate, useParams } from "react-router-dom";
import backIcon from "../../../../images/back.png";
import nextWhiteIcon from "../../../../images/next-white.png";
import nextIcon from "../../../../images/next.png";
import { useDispatch, useSelector } from "react-redux";
import useTranslation from "../../../customHooks/doctorTranslation";
import HandWristTab from "../../tab-section/HandWristTab";
import { useSearchParams } from "react-router-dom";
import CustomToaster from "../../../custom/toaster/CustomToaster";
import RadioSwitch from "../../../custom/radio-switch/RadioSwitch";
import assessmentStyles from "./PatientAssesment.module.css";
import vertMenuStyles from "../../shoulder-forms/doctorefeedback/form-vertical-menu/FormVerticalMenu.module.css";
import Accordion from "react-bootstrap/Accordion";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Select from "react-select";
import {
  updateHandWristScores,
  updateHandWristfunctionFieldScore,
  updateHandWristfunctionScores,
  updateHandWristPainScores,
  updateHandWristSymptomsScores,
  updateHandWristqolScores,
  updateHandWristsatisfactionScores,
  updateHandWristFeedbackScores,
  updateHandWristUramScores,
  updateHandWristbctqScores,
  updateAllData,
  UPDATE_HAND_FEEDBACK_DATA,
} from "../../../../redux/actions/handWrist";
import useAxios from "../../../../axiosinstance";
import DoctorNameCustom from "../../../custom/name/DoctorNameCustom";
import LoadingSpinner from "../../../custom/loader/LoadingSpinner";

export default function HandWristSurgeon() {
  const translation = useTranslation();
  const navigate = useNavigate();
  const { rId } = useParams();
  const axiosinstance = useAxios();
  const [searchParams] = useSearchParams();
  let doctorEnable = searchParams.get("d");
  const queryParams = new URLSearchParams(window.location.search);
  const dId = queryParams.get("doctor");
  const pId = queryParams.get("patient");
  let isdoctorForm = queryParams.get("is_doctor_form");
  useEffect(() => {
    if (doctorEnable === "true") {
      let doctordata = localStorage.getItem("isdoctorForm")
      localStorage.setItem("isdoctorForm", doctordata)
    } else {
      localStorage.setItem("isdoctorForm", isdoctorForm)
    }

  }, [isdoctorForm])
  const dispatch = useDispatch();
  const [deformityRI, setdeformityRI] = useState([]);
  const [deformityLI, setdeformityLI] = useState("");
  const [deformityTextRI, setdeformityTextRI] = useState("");
  const [deformityTextLI, setdeformityTextLI] = useState("");

  const [wristflexionR, setWristflexionR] = useState("");
  const [wristflexionPR, setWristflexionPR] = useState("");
  const [wristflexionPL, setWristflexionPL] = useState("");
  const [wristflexionL, setWristflexionL] = useState("");
  const [wristextensionR, setWristextensionR] = useState("");
  const [wristextensionPR, setWristextensionPR] = useState("");

  const [radialDeviationR, setradialDeviationR] = useState("");
  const [radialDeviationPR, setradialDeviationPR] = useState("");
  const [radialDeviationPL, setradialDeviationPL] = useState("");
  const [radialDeviationL, setradialDeviationL] = useState("");

  const [ulnarR, setUlnarR] = useState("");
  const [ulnarL, setUlnarL] = useState("");
  const [ulnarPR, setUlnarPR] = useState("");
  const [ulnarPL, setUlnarPL] = useState("");

  const [actPalmerR, setActPalmerR] = useState("");
  const [actPalmerL, setActPalmerL] = useState("");
  const [pasPalmerR, setPasPalmerR] = useState("");
  const [pasPalmerL, setPasPalmerL] = useState("");

  const [actRadialR, setActRadialR] = useState("");
  const [actRadialL, setActRadialL] = useState("");
  const [pasRadialR, setPasRadialR] = useState("");
  const [pasRadialL, setPasRadialL] = useState("");

  const [wristextensionPL, setWristextensionPL] = useState("");
  const [wristextensionL, setWristextensionL] = useState("");

  const [MCPR, setMCPR] = useState("");
  const [MCPL, setMCPL] = useState("");
  const [IPR, setIPR] = useState("");
  const [IPL, setIPL] = useState("");

  const [actMCPR, setActMCPR] = useState("");
  const [actMCPL, setActMCPL] = useState("");
  const [passLeftMCPR, setPassLeftMCPR] = useState("");
  const [passLeftMCPL, setPassLeftMCPL] = useState("");

  const [LeftMCPR, setLeftMCPR] = useState("");
  const [LeftMCPL, setLeftMCPL] = useState("");
  const [LeftIPR, setLeftIPR] = useState("");
  const [LeftIPL, setLeftIPL] = useState("");

  const [actIPR, setactIPR] = useState("");
  const [actIPL, setactIPL] = useState("");
  const [passLeftIPR, setPassLeftIPR] = useState("");
  const [passLeftIPL, setPassLeftIPL] = useState("");

  const [IndexmcpR, setIndexmcpR] = useState("");
  const [IndexmcpL, setIndexmcpL] = useState("");
  const [IndexpipR, setIndexpipR] = useState("");
  const [IndexpipL, setIndexpipL] = useState("");
  const [IndexdipR, setIndexdipR] = useState("");
  const [IndexdipL, setIndexdipL] = useState("");

  const [LeftIndexmcpR, setLeftIndexmcpR] = useState("");
  const [LeftIndexmcpL, setLeftIndexmcpL] = useState("");
  const [LeftIndexpipR, setLeftIndexpipR] = useState("");
  const [LeftIndexpipL, setLeftIndexpipL] = useState("");
  const [LeftIndexdipR, setLeftIndexdipR] = useState("");
  const [LeftIndexdipL, setLeftIndexdipL] = useState("");

  const [actIndexpipR, setActIndexpipRR] = useState("");
  const [actIndexpipL, setActIndexpipRL] = useState("");
  const [passLeftIndexpipR, setPassLeftIndexpipR] = useState("");
  const [passLeftIndexpipL, setPassLeftIndexpipL] = useState("");

  const [actIndexdipR, setActIndexDIPR] = useState("");
  const [actIndexdipL, setActIndexDIPL] = useState("");
  const [passLeftIndexdipR, setPassIndexLeftDIPR] = useState("");
  const [passLeftIndexdipL, setPassIndexLeftDIPL] = useState("");

  const [ExtIndexmcpR, setExtIndexmcpR] = useState("");
  const [ExtIndexmcpL, setExtIndexmcpL] = useState("");
  const [LeftExtIndexmcpR, setLExtIndexmcpR] = useState("");
  const [LeftExtIndexmcpL, setLExtIndexmcpL] = useState("");

  const [MiddlemcpR, setMiddlemcpR] = useState("");
  const [MiddlemcpL, setMiddlemcpL] = useState("");
  const [MiddlepipR, setMiddlepipR] = useState("");
  const [MiddlepipL, setMiddlepipL] = useState("");
  const [MiddledipR, setMiddledipR] = useState("");
  const [MiddledipL, setMiddledipL] = useState("");

  const [LeftMiddlemcpR, setLeftMiddlemcpR] = useState("");
  const [LeftMiddlemcpL, setLeftMiddlemcpL] = useState("");
  const [ActMiddlemcpR, setActMiddlemcpR] = useState("");
  const [ActMiddlemcpL, setActMiddlemcpL] = useState("");
  const [PasLeftMiddlemcpR, setPasLeftMiddlemcpR] = useState("");
  const [PasLeftMiddlemcpL, setPasLeftMiddlemcpL] = useState("");
  const [LeftMiddlepipR, setLeftMiddlepipR] = useState("");
  const [LeftMiddlepipL, setLeftMiddlepipL] = useState("");
  const [LeftMiddledipR, setLeftMiddledipR] = useState("");
  const [LeftMiddledipL, setLeftMiddledipL] = useState("");
  const [ActMiddlepipR, setActMiddlepipR] = useState("");
  const [ActMiddlepipL, setActMiddlepipL] = useState("");
  const [pasLeftMiddlepipR, setpasLeftMiddlepipR] = useState("");
  const [pasLeftMiddlepipL, setpasLeftMiddlepipL] = useState("");

  const [ActMiddledipR, setActMiddledipR] = useState("");
  const [ActMiddledipL, setActMiddledipL] = useState("");
  const [pasLeftMiddledipR, setpasLeftMiddledipR] = useState("");
  const [pasLeftMiddledipL, setpasLeftMiddledipL] = useState("");

  const [RingmcpR, setRingmcpR] = useState("");
  const [RingmcpL, setRingmcpL] = useState("");
  const [RingpipR, setRingpipR] = useState("");
  const [RingpipL, setRingpipL] = useState("");
  const [RingdipR, setRingdipR] = useState("");
  const [RingdipL, setRingdipL] = useState("");

  const [LeftRingmcpR, setLeftRingmcpR] = useState("");
  const [LeftRingmcpL, setLeftRingmcpL] = useState("");

  const [ActRingmcpR, setActRingmcpR] = useState("");
  const [ActRingmcpL, setActRingmcpL] = useState("");
  const [LeftRingpipR, setLeftRingpipR] = useState("");
  const [LeftRingpipL, setLeftRingpipL] = useState("");
  const [LeftRingdipR, setLeftRingdipR] = useState("");
  const [LeftRingdipL, setLeftRingdipL] = useState("");
  const [ActRingdipR, setActRingdipR] = useState("");
  const [ActRingdipL, setActRingdipL] = useState("");
  const [PasLeftRingdipR, setPasLeftRingdipR] = useState("");
  const [PasLeftRingdipL, setPasLeftRingdipL] = useState("");

  const [Act1RingdipR, setAct1RingdipR] = useState("");
  const [Act1RingdipL, setAct1RingdipL] = useState("");
  const [Pas2LeftRingdipR, setPas2LeftRingdipR] = useState("");
  const [Pas2LeftRingdipL, setPas2LeftRingdipL] = useState("");

  const [PasLeftRingmcpR, setPasLeftRingmcpR] = useState("");
  const [PasLeftRingmcpL, setPasLeftRingmcpL] = useState("");
  const [ActRingpipR, setActRingpipR] = useState("");
  const [ActRingpipL, setActRingpipL] = useState("");
  const [PasLeftRingpipR, setPasLeftRingpipR] = useState("");
  const [PasLeftRingpipL, setPasLeftRingpipL] = useState("");

  const [SmallmcpR, setSmallmcpR] = useState("");
  const [SmallmcpL, setSmallmcpL] = useState("");
  const [SmallpipR, setSmallpipR] = useState("");
  const [SmallpipL, setSmallpipL] = useState("");
  const [SmalldipR, setSmalldipR] = useState("");
  const [SmalldipL, setSmalldipL] = useState("");

  const [LeftSmallmcpR, setLeftSmallmcpR] = useState("");
  const [LeftSmallmcpL, setLeftSmallmcpL] = useState("");
  const [LeftSmallpipR, setLeftSmallpipR] = useState("");
  const [LeftSmallpipL, setLeftSmallpipL] = useState("");
  const [LeftSmalldipR, setLeftSmalldipR] = useState("");
  const [LeftSmalldipL, setLeftSmalldipL] = useState("");

  const [ActSmalldipR, setActSmalldipR] = useState("");
  const [ActSmalldipL, setActSmalldipL] = useState("");
  const [PasLeftSmalldipR, setPasLeftSmalldipR] = useState("");
  const [PasLeftSmalldipL, setPasLeftSmalldipL] = useState("");

  const [ActSmallpipR, setActSmallpipR] = useState("");
  const [ActSmallpipL, setActSmallpipL] = useState("");
  const [PasLeftSmallpipR, setPasLeftSmallpipR] = useState("");
  const [PasLeftSmallpipL, setPasLeftSmallpipL] = useState("");

  const [ActSmallmcpR, setActSmallmcpR] = useState("");
  const [ActSmallmcpL, setActSmallmcpL] = useState("");
  const [PasLeftSmallmcpR, setPasLeftSmallmcpR] = useState("");
  const [PasLeftSmallmcpL, setPasLeftSmallmcpL] = useState("");

  const [ScarsL, setScarsL] = useState("");
  const [ScarsR, setScarsR] = useState("");
  const [ScarsLText, setScarsLText] = useState("");
  const [ScarsRtext, setScarsRText] = useState("");
  const [AtrophyL, setAtrophyL] = useState("");
  const [AtrophyR, setAtrophyR] = useState("");
  const [AtrophyLText, setAtrophyLText] = useState("");
  const [AtrophyRText, setAtrophyRText] = useState("");
  const [DeformityR, setDeformityR] = useState("");
  // const [DeformityRText, setDeformityRText] = useState("");
  const [DeformityL, setDeformityL] = useState("");
  const [chk, setChk] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [vmenuFix, setVmenuFix] = useState(false);
  const docFeedback = useSelector((state) => state.handWrist.doctorFeedback[0]);
  const docFeedback1 = useSelector((state) => state.handWrist.doctorFeedback);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  const handleScroll = () => {
    setVmenuFix(window.pageYOffset > 140);
  };
  const save = async () => {
    await axiosinstance.current
      .get(`extapp/forms/all_forms?resource_id=${rId}&type=23`)
      .then((res) => {
        // console.log(res.data.data2[0].doctorFeedback,"data is");
        dispatch(updateHandWristPainScores(res.data.data2[0].painScore));
        dispatch(
          updateHandWristSymptomsScores(res.data.data2[0].symptomsScore)
        );
        dispatch(updateHandWristScores(res.data.data2[0].evaluated));
        dispatch(
          updateHandWristfunctionScores(res.data.data2[0].functionScore)
        );
        dispatch(updateHandWristqolScores(res.data.data2[0].qolScore));
        dispatch(updateHandWristUramScores(res.data.data2[0].uramScore));
        dispatch(updateHandWristbctqScores(res.data.data2[0].bctq));

        dispatch(
          updateHandWristsatisfactionScores(res.data.data2[0].satisfactionScore)
        );
        dispatch(UPDATE_HAND_FEEDBACK_DATA(res.data.data2[0].doctorFeedback));
        setChk(true);
      });
  };

  useEffect(() => {
    save();
  }, []);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
  useEffect(() => {
    setScarsL(docFeedback.ScarsL);
    setScarsR(docFeedback.ScarsR);
    setScarsLText(docFeedback.ScarsLText);
    // setScarsRText(docFeedback.ScarsRText);
    setAtrophyLText(docFeedback.AtrophyLText);
    setAtrophyRText(docFeedback.AtrophyRText);
    setAtrophyL(docFeedback.AtrophyL);
    setAtrophyR(docFeedback.AtrophyR);
    setDeformityR(docFeedback.DeformityR);
    setDeformityL(docFeedback.DeformityL);
    setdeformityRI(docFeedback.deformityRI);
    setdeformityLI(docFeedback.deformityLI);
    setdeformityTextRI(docFeedback.deformityTextRI);
    setdeformityTextLI(docFeedback.deformityTextLI);
    setWristflexionR(docFeedback.wristflexionR);
    setWristflexionPR(docFeedback.wristflexionPR);
    setWristflexionPL(docFeedback.wristflexionPL);
    setWristflexionL(docFeedback.wristflexionL);
    setWristextensionR(docFeedback.wristextensionR);
    setWristextensionPR(docFeedback.wristextensionPR);
    setradialDeviationR(docFeedback.radialDeviationR);
    setradialDeviationPR(docFeedback.radialDeviationPR);
    setradialDeviationPL(docFeedback.radialDeviationPL);
    setradialDeviationL(docFeedback.radialDeviationL);
    setUlnarR(docFeedback.ulnarR);
    setUlnarL(docFeedback.ulnarL);
    setUlnarPR(docFeedback.ulnarPR);
    setUlnarPL(docFeedback.ulnarPL);
    setActPalmerR(docFeedback.actPalmerR);
    setActPalmerL(docFeedback.actPalmerL);
    setPasPalmerR(docFeedback.pasPalmerR);
    setPasPalmerL(docFeedback.pasPalmerL);
    setActRadialR(docFeedback.actRadialR);
    setActRadialL(docFeedback.actRadialL);
    setPasRadialR(docFeedback.pasRadialR);
    setPasRadialL(docFeedback.pasRadialL);
    setWristextensionPL(docFeedback.wristextensionPL);
    setWristextensionL(docFeedback.wristextensionL);
    setMCPR(docFeedback.MCPR);
    setMCPL(docFeedback.MCPL);
    setIPR(docFeedback.IPR);
    setIPL(docFeedback.IPL);

    setLeftMCPR(docFeedback.LeftMCPR);
    setLeftMCPL(docFeedback.LeftMCPL);
    setLeftIPR(docFeedback.LeftIPR);
    setLeftIPL(docFeedback.LeftIPL);

    setactIPR(docFeedback.actIPR);
    setactIPL(docFeedback.actIPL);
    setPassLeftIPR(docFeedback.passLeftIPR);
    setPassLeftIPL(docFeedback.passLeftIPL);

    setActMCPR(docFeedback.actMCPR);
    setActMCPL(docFeedback.actMCPL);
    setPassLeftMCPR(docFeedback.passLeftMCPR);
    setPassLeftMCPL(docFeedback.passLeftMCPL);

    setIndexmcpR(docFeedback.IndexmcpR);
    setIndexmcpL(docFeedback.IndexmcpL);
    setIndexpipR(docFeedback.IndexpipR);
    setIndexpipL(docFeedback.IndexpipL);
    setIndexdipR(docFeedback.IndexdipR);
    setIndexdipL(docFeedback.IndexdipL);

    setLeftIndexmcpR(docFeedback.LeftIndexmcpR);
    setLeftIndexmcpL(docFeedback.LeftIndexmcpL);
    setLeftIndexpipR(docFeedback.LeftIndexpipR);
    setLeftIndexpipL(docFeedback.LeftIndexpipL);
    setLeftIndexdipR(docFeedback.LeftIndexdipR);
    setLeftIndexdipL(docFeedback.LeftIndexdipL);

    setActIndexpipRR(docFeedback.actIndexpipR);
    setActIndexpipRL(docFeedback.actIndexpipL);
    setPassLeftIndexpipR(docFeedback.passLeftIndexpipR);
    setPassLeftIndexpipL(docFeedback.passLeftIndexpipL);

    setActIndexDIPR(docFeedback.actIndexdipR);
    setActIndexDIPL(docFeedback.actIndexdipL);
    setPassIndexLeftDIPR(docFeedback.passLeftIndexdipR);
    setPassIndexLeftDIPL(docFeedback.passLeftIndexdipL);

    setExtIndexmcpR(docFeedback.ExtIndexmcpR);
    setExtIndexmcpL(docFeedback.ExtIndexmcpL);
    setLExtIndexmcpR(docFeedback.LeftExtIndexmcpR);
    setLExtIndexmcpL(docFeedback.LeftExtIndexmcpL);

    setMiddlemcpR(docFeedback.MiddlemcpR);
    setMiddlemcpL(docFeedback.MiddlemcpL);
    setMiddlepipR(docFeedback.MiddlepipR);
    setMiddlepipL(docFeedback.MiddlepipL);
    setMiddledipR(docFeedback.MiddledipR);
    setMiddledipL(docFeedback.MiddledipL);
    setLeftMiddlemcpR(docFeedback.LeftMiddlemcpR);
    setLeftMiddlemcpL(docFeedback.LeftMiddlemcpL);
    setActMiddlemcpR(docFeedback.ActMiddlemcpR);
    setActMiddlemcpL(docFeedback.ActMiddlemcpL);
    setLeftMiddlepipR(docFeedback.LeftMiddlepipR);
    setPasLeftMiddlemcpR(docFeedback.PasLeftMiddlemcpR);
    setPasLeftMiddlemcpL(docFeedback.PasLeftMiddlemcpL);
    setLeftMiddlepipL(docFeedback.LeftMiddlepipL);
    setLeftMiddledipR(docFeedback.LeftMiddledipR);
    setLeftMiddledipL(docFeedback.LeftMiddledipL);

    setActMiddledipR(docFeedback.ActMiddledipR);
    setActMiddledipL(docFeedback.ActMiddledipL);
    setpasLeftMiddledipR(docFeedback.pasLeftMiddledipR);
    setpasLeftMiddledipL(docFeedback.pasLeftMiddledipL);

    setActMiddlepipR(docFeedback.ActMiddlepipR);
    setActMiddlepipL(docFeedback.ActMiddlepipL);
    setpasLeftMiddlepipR(docFeedback.pasLeftMiddlepipR);
    setpasLeftMiddlepipL(docFeedback.pasLeftMiddlepipL);

    setRingmcpR(docFeedback.RingmcpR);
    setRingmcpL(docFeedback.RingmcpL);
    setRingpipR(docFeedback.RingpipR);
    setRingpipL(docFeedback.RingpipL);
    setRingdipR(docFeedback.RingdipR);
    setRingdipL(docFeedback.RingdipL);
    setLeftRingmcpR(docFeedback.LeftRingmcpR);
    setLeftRingmcpL(docFeedback.LeftRingmcpL);
    setLeftRingpipR(docFeedback.LeftRingpipR);
    setLeftRingpipL(docFeedback.LeftRingpipL);
    setLeftRingdipR(docFeedback.LeftRingdipR);
    setLeftRingdipL(docFeedback.LeftRingdipL);

    setActRingmcpR(docFeedback.ActRingmcpR);
    setActRingmcpL(docFeedback.ActRingmcpL);

    setActRingdipR(docFeedback.ActRingdipR);
    setActRingdipL(docFeedback.ActRingdipL);
    setPasLeftRingdipR(docFeedback.PasLeftRingdipR);
    setPasLeftRingdipL(docFeedback.PasLeftRingdipL);

    setAct1RingdipR(docFeedback.Act1RingdipR);
    setAct1RingdipL(docFeedback.Act1RingdipL);
    setPas2LeftRingdipR(docFeedback.Pas2LeftRingdipR);
    setPas2LeftRingdipR(docFeedback.Pas2LeftRingdipR);

    setPasLeftRingmcpR(docFeedback.PasLeftRingmcpR);
    setPasLeftRingmcpL(docFeedback.PasLeftRingmcpL);

    setActRingpipR(docFeedback.ActRingpipR);
    setActRingpipL(docFeedback.ActRingpipL);

    setPasLeftRingpipR(docFeedback.PasLeftRingpipR);
    setPasLeftRingpipL(docFeedback.PasLeftRingpipL);
    setSmallmcpR(docFeedback.SmallmcpR);
    setSmallmcpL(docFeedback.SmallmcpL);
    setSmallpipR(docFeedback.SmallpipR);
    setSmallpipL(docFeedback.SmallpipL);
    setSmalldipR(docFeedback.SmalldipR);
    setSmalldipL(docFeedback.SmalldipL);
    setLeftSmallmcpR(docFeedback.LeftSmallmcpR);
    setLeftSmallmcpL(docFeedback.LeftSmallmcpL);
    setLeftSmallpipR(docFeedback.LeftSmallpipR);
    setLeftSmallpipL(docFeedback.LeftSmallpipL);
    setLeftSmalldipR(docFeedback.LeftSmalldipR);
    setLeftSmalldipL(docFeedback.LeftSmalldipL);
    setScarsRText(docFeedback.ScarsRtext);

    setActSmalldipR(docFeedback.ActSmalldipR);
    setActSmalldipL(docFeedback.ActSmalldipL);
    setPasLeftSmalldipR(docFeedback.PasLeftSmalldipR);
    setPasLeftSmalldipL(docFeedback.PasLeftSmalldipL);

    setActSmallpipR(docFeedback.ActSmallpipR);
    setActSmallpipL(docFeedback.ActSmallpipL);
    setPasLeftSmallpipR(docFeedback.PasLeftSmallpipR);
    setPasLeftSmallpipL(docFeedback.PasLeftSmallpipL);

    setActSmallmcpR(docFeedback.ActSmallmcpR);
    setActSmallmcpL(docFeedback.ActSmallmcpL);
    setPasLeftSmallmcpR(docFeedback.PasLeftSmallmcpR);
    setPasLeftSmallmcpL(docFeedback.PasLeftSmallmcpL);
  }, [docFeedback]);

  const updateDate = async () => {
    const response = await axiosinstance.current
      .put(
        `extapp/forms/update_doctor_feedback?resource_id=${rId}&action=1&ak_dc=${dId}&edit=1`,
        docFeedback1,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res);
      });
  };

  useEffect(() => {
    const updateDate2 = async () => {
      const response = await axiosinstance.current.put(
        `extapp/forms/update_doctor_feedback?resource_id=${rId}&action=0&ak_dc=${dId}`,
        docFeedback1,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {

      }
    }
    if (chk) {
      if (doctorEnable !== "true") {
        updateDate2()
      }
    }
  }, [docFeedback1])

  const continueRedirect = async () => {
    const response = await axiosinstance.current.put(
      `extapp/forms/update_doctor_feedback?resource_id=${rId}&action=0&ak_dc=${dId}&edit=1`,
      docFeedback1,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
      navigate(
        "/hand-wrist-feedback-step-2/" + rId + `?doctor=${dId}&patient=${pId}&is_doctor_form=true`
      );
    }
  };

  // const continueRedirect = (e) => {
  //   updateDate()
  //   navigate("/hand-wrist-feedback-step-2/" + rId + `?doctor=${dId}&patient=${pId}`);
  // };

  const handleScarsL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "ScarsL",
        value: e,
      })
    );
    setScarsL(e);
  };
  const handleScarsR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "ScarsR",
        value: e,
      })
    );
    setScarsR(e);
  };
  const handleScarsLText = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "ScarsLText",
        value: e,
      })
    );
    setScarsLText(e);
  };
  const handleScarsRtext = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "ScarsRtext",
        value: e,
      })
    );
    setScarsRText(e);
  };
  const handleAtrophyL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "AtrophyL",
        value: e,
      })
    );
    setAtrophyL(e);
  };
  const handleAtrophyR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "AtrophyR",
        value: e,
      })
    );
    setAtrophyR(e);
  };
  const handleAtrophyLText = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "AtrophyLText",
        value: e,
      })
    );
    setAtrophyLText(e);
  };
  const handleAtrophyRText = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "AtrophyRText",
        value: e,
      })
    );
    setAtrophyRText(e);
  };
  const handleDeformityR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "DeformityR",
        value: e,
      })
    );
    setDeformityR(e);
  };
  // const handleDeformityRText = (e) => {
  //   dispatch(
  //     updateHandWristFeedbackScores({
  //       key: "DeformityRText",
  //       value: e,
  //     })
  //   );
  //   setDeformityRText(e);
  // };
  const handleDeformityL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "DeformityL",
        value: e,
      })
    );
    setDeformityL(e);
  };

  const handleRightdeformityI = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "deformityRI",
        value: e,
      })
    );
    setdeformityRI(e);
  };
  const handleLeftdeformityI = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "deformityLI",
        value: e,
      })
    );
    setdeformityLI(e);
  };
  const handledeformityTextRI = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "deformityTextRI",
        value: e,
      })
    );
    setdeformityTextRI(e);
  };
  const handledeformityTextLI = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "deformityTextLI",
        value: e,
      })
    );
    setdeformityTextLI(e);
  };

  const WristflexionR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "wristflexionR",
        value: e,
      })
    );
    setWristflexionR(e);
  };
  const WristflexionPR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "wristflexionPR",
        value: e,
      })
    );
    setWristflexionPR(e);
  };
  const WristflexionPL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "wristflexionPL",
        value: e,
      })
    );
    setWristflexionPL(e);
  };

  const WristflexionL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "wristflexionL",
        value: e,
      })
    );
    setWristflexionL(e);
  };
  const WristextensionR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "wristextensionR",
        value: e,
      })
    );
    setWristextensionR(e);
  };
  const RadialDeviationR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "radialDeviationR",
        value: e,
      })
    );
    setradialDeviationR(e);
  };
  const RadialDeviationPR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "radialDeviationPR",
        value: e,
      })
    );
    setradialDeviationPR(e);
  };
  const RadialDeviationPL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "radialDeviationPL",
        value: e,
      })
    );
    setradialDeviationPL(e);
  };
  const UlnarR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "ulnarR",
        value: e,
      })
    );
    setUlnarR(e);
  };
  const UlnarL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "ulnarL",
        value: e,
      })
    );
    setUlnarL(e);
  };
  const UlnarPR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "ulnarPR",
        value: e,
      })
    );
    setUlnarPR(e);
  };
  const UlnarPL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "ulnarPL",
        value: e,
      })
    );
    setUlnarPL(e);
  };
  const ActPalmerR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "actPalmerR",
        value: e,
      })
    );
    setActPalmerR(e);
  };
  const ActPalmerL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "actPalmerL",
        value: e,
      })
    );
    setActPalmerL(e);
  };
  const PasPalmerR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "pasPalmerR",
        value: e,
      })
    );
    setPasPalmerR(e);
  };
  const PasPalmerL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "pasPalmerL",
        value: e,
      })
    );
    setPasPalmerL(e);
  };
  const ActRadialR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "actRadialR",
        value: e,
      })
    );
    setActRadialR(e);
  };
  const ActRadialL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "actRadialL",
        value: e,
      })
    );
    setActRadialL(e);
  };
  const PasRadialR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "pasRadialR",
        value: e,
      })
    );
    setPasRadialR(e);
  };
  const PasRadialL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "pasRadialL",
        value: e,
      })
    );
    setPasRadialL(e);
  };

  const RadialDeviationL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "radialDeviationL",
        value: e,
      })
    );
    setradialDeviationL(e);
  };
  const WristextensionPR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "wristextensionPR",
        value: e,
      })
    );
    setWristextensionPR(e);
  };
  const WristextensionPL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "wristextensionPL",
        value: e,
      })
    );
    setWristextensionPL(e);
  };

  const WristextensionL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "wristextensionL",
        value: e,
      })
    );
    setWristextensionL(e);
  };
  const ThumbMCPR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "MCPR",
        value: e,
      })
    );
    setMCPR(e);
  };

  const ThumbMCPL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "MCPL",
        value: e,
      })
    );
    setMCPL(e);
  };
  const ThumbIPR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "IPR",
        value: e,
      })
    );
    setIPR(e);
  };

  const ThumbIPL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "IPL",
        value: e,
      })
    );
    setIPL(e);
  };
  const ThumbLeftMCPR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "LeftMCPR",
        value: e,
      })
    );
    setLeftMCPR(e);
  };

  const ThumbLeftMCPL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "LeftMCPL",
        value: e,
      })
    );
    setLeftMCPL(e);
  };
  const ThumbLeftIPR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "LeftIPR",
        value: e,
      })
    );
    setLeftIPR(e);
  };

  const ThumbLeftIPL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "LeftIPL",
        value: e,
      })
    );
    setLeftIPL(e);
  };

  const IndexMCPR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "IndexmcpR",
        value: e,
      })
    );
    setIndexmcpR(e);
  };

  const IndexMCPL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "IndexmcpL",
        value: e,
      })
    );
    setIndexmcpL(e);
  };
  const IndexPIPR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "IndexpipR",
        value: e,
      })
    );
    setIndexpipR(e);
  };

  const IndexPIPL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "IndexpipL",
        value: e,
      })
    );
    setIndexpipL(e);
  };
  const IndexDIPR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "IndexdipR",
        value: e,
      })
    );
    setIndexdipR(e);
  };

  const IndexDIPL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "IndexdipL",
        value: e,
      })
    );
    setIndexdipL(e);
  };
  const IndexLeftMCPR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "LeftIndexmcpR",
        value: e,
      })
    );
    setLeftIndexmcpR(e);
  };

  const IndexLeftMCPL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "LeftIndexmcpL",
        value: e,
      })
    );
    setLeftIndexmcpL(e);
  };
  const ExtIndexMCPR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "ExtIndexmcpR",
        value: e,
      })
    );
    setExtIndexmcpR(e);
  };

  const ExtIndexLeftMCPR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "LeftExtIndexmcpR",
        value: e,
      })
    );
    setLExtIndexmcpR(e);
  };
  const ExtIndexLeftMCPL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "LeftExtIndexmcpL",
        value: e,
      })
    );
    setLExtIndexmcpL(e);
  };

  const ExtIndexMCPL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "ExtIndexmcpL",
        value: e,
      })
    );
    setExtIndexmcpL(e);
  };

  const IndexLeftPIPR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "LeftIndexpipR",
        value: e,
      })
    );
    setLeftIndexpipR(e);
  };

  const IndexLeftPIPL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "LeftIndexpipL",
        value: e,
      })
    );
    setLeftIndexpipL(e);
  };
  const IndexLeftDIPR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "LeftIndexdipR",
        value: e,
      })
    );
    setLeftIndexdipR(e);
  };

  const IndexLeftDIPL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "LeftIndexdipL",
        value: e,
      })
    );
    setLeftIndexdipL(e);
  };

  const ActIndexPIPR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "actIndexpipR",
        value: e,
      })
    );
    setActIndexpipRR(e);
  };
  const ActIndexPIPL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "actIndexpipL",
        value: e,
      })
    );
    setActIndexpipRL(e);
  };
  const PassIndexLeftPIPR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "passLeftIndexpipR",
        value: e,
      })
    );
    setPassLeftIndexpipR(e);
  };
  const PassIndexLeftPIPL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "passLeftIndexpipL",
        value: e,
      })
    );
    setPassLeftIndexpipL(e);
  };

  const ActIndexDIPR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "actIndexdipR",
        value: e,
      })
    );
    setActIndexDIPR(e);
  };
  const ActIndexDIPL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "actIndexdipL",
        value: e,
      })
    );
    setActIndexDIPL(e);
  };
  const PassIndexLeftDIPR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "passLeftIndexdipR",
        value: e,
      })
    );
    setPassIndexLeftDIPR(e);
  };
  const PassIndexLeftDIPL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "passLeftIndexdipL",
        value: e,
      })
    );
    setPassIndexLeftDIPL(e);
  };

  const MiddleMCPR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "MiddlemcpR",
        value: e,
      })
    );
    setMiddlemcpR(e);
  };

  const MiddleMCPL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "MiddlemcpL",
        value: e,
      })
    );
    setMiddlemcpL(e);
  };
  const MiddlePIPR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "MiddlepipR",
        value: e,
      })
    );
    setMiddlepipR(e);
  };

  const MiddlePIPL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "MiddlepipL",
        value: e,
      })
    );
    setMiddlepipL(e);
  };
  const MiddleDIPR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "MiddledipR",
        value: e,
      })
    );
    setMiddledipR(e);
  };

  const MiddleDIPL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "MiddledipL",
        value: e,
      })
    );
    setMiddledipL(e);
  };

  const MiddleLeftMCPR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "LeftMiddlemcpR",
        value: e,
      })
    );
    setLeftMiddlemcpR(e);
  };

  const MiddleLeftMCPL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "LeftMiddlemcpL",
        value: e,
      })
    );
    setLeftMiddlemcpL(e);
  };

  const ActSmallDIPR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "ActSmalldipR",
        value: e,
      })
    );
    setActSmalldipR(e);
  };

  const ActSmallDIPL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "ActSmalldipL",
        value: e,
      })
    );
    setActSmalldipL(e);
  };

  const PasSmallLeftDIPR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "PasLeftSmalldipR",
        value: e,
      })
    );
    setPasLeftSmalldipR(e);
  };

  const PasSmallLeftDIPL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "PasLeftSmalldipL",
        value: e,
      })
    );
    setPasLeftSmalldipL(e);
  };

  const ActSmallPIPR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "ActSmallpipR",
        value: e,
      })
    );
    setActSmallpipR(e);
  };

  const ActSmallPIPL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "ActSmallpipL",
        value: e,
      })
    );
    setActSmallpipL(e);
  };

  const PasSmallLeftPIPR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "PasLeftSmallpipR",
        value: e,
      })
    );
    setPasLeftSmallpipR(e);
  };

  const PasSmallLeftPIPL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "PasLeftSmallpipL",
        value: e,
      })
    );
    setPasLeftSmallpipL(e);
  };

  //new work start
  const ActMiddleMCPR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "ActMiddlemcpR",
        value: e,
      })
    );
    setActMiddlemcpR(e);
  };
  const ActMiddleMCPL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "ActMiddlemcpL",
        value: e,
      })
    );
    setActMiddlemcpL(e);
  };

  const PasMiddleLeftMCPR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "PasLeftMiddlemcpR",
        value: e,
      })
    );
    setPasLeftMiddlemcpR(e);
  };
  const PasMiddleLeftMCPL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "PasLeftMiddlemcpL",
        value: e,
      })
    );
    setPasLeftMiddlemcpL(e);
  };

  const ActMiddleDIPR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "ActMiddledipR",
        value: e,
      })
    );
    setActMiddledipR(e);
  };
  const ActMiddleDIPL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "ActMiddledipL",
        value: e,
      })
    );
    setActMiddledipL(e);
  };

  const pasMiddleLeftDIPR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "pasLeftMiddledipR",
        value: e,
      })
    );
    setpasLeftMiddledipR(e);
  };
  const pasMiddleLeftDIPL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "pasLeftMiddledipL",
        value: e,
      })
    );
    setpasLeftMiddledipL(e);
  };

  const ActMiddlePIPR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "ActMiddlepipR",
        value: e,
      })
    );
    setActMiddlepipR(e);
  };
  const ActMiddlePIPL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "ActMiddlepipL",
        value: e,
      })
    );
    setActMiddlepipL(e);
  };

  const pasMiddleLeftPIPR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "pasLeftMiddlepipR",
        value: e,
      })
    );
    setpasLeftMiddlepipR(e);
  };
  const pasMiddleLeftPIPL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "pasLeftMiddlepipL",
        value: e,
      })
    );
    setpasLeftMiddlepipL(e);
  };

  const ActRingDIPR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "ActRingdipR",
        value: e,
      })
    );
    setActRingdipR(e);
  };
  const ActRingDIPL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "ActRingdipL",
        value: e,
      })
    );
    setActRingdipL(e);
  };

  const PasRingLeftDIPR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "PasLeftRingdipR",
        value: e,
      })
    );
    setPasLeftRingdipR(e);
  };
  const PasRingLeftDIPL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "PasLeftRingdipL",
        value: e,
      })
    );
    setPasLeftRingdipL(e);
  };

  const ActRingPIPR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "ActRingpipR",
        value: e,
      })
    );
    setActRingpipR(e);
  };
  const ActRingPIPL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "ActRingpipL",
        value: e,
      })
    );
    setActRingpipL(e);
  };

  const PasRingLeftPIPR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "PasLeftRingpipR",
        value: e,
      })
    );
    setPasLeftRingpipR(e);
  };
  const PasRingLeftPIPL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "PasLeftRingpipL",
        value: e,
      })
    );
    setPasLeftRingpipL(e);
  };

  const ActRingMCPR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "ActRingmcpR",
        value: e,
      })
    );
    setActRingmcpR(e);
  };
  const ActRingMCPL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "ActRingmcpL",
        value: e,
      })
    );
    setActRingmcpL(e);
  };

  const PasRingLeftMCPR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "PasLeftRingmcpR",
        value: e,
      })
    );
    setPasLeftRingmcpR(e);
  };
  const PasRingLeftMCPL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "PasLeftRingmcpL",
        value: e,
      })
    );
    setPasLeftRingmcpL(e);
  };
  const Act1RingDIPR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "Act1RingdipR",
        value: e,
      })
    );
    setAct1RingdipR(e);
  };
  const Act1RingDIPL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "Act1RingdipL",
        value: e,
      })
    );
    setAct1RingdipL(e);
  };

  const Pas2RingLeftDIPR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "Pas2LeftRingdipR",
        value: e,
      })
    );
    setPas2LeftRingdipR(e);
  };
  const Pas2RingLeftDIPL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "Pas2LeftRingdipL",
        value: e,
      })
    );
    setPas2LeftRingdipL(e);
  };

  const ActSmallMCPR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "ActSmallmcpR",
        value: e,
      })
    );
    setActSmallmcpR(e);
  };
  const ActSmallMCPL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "ActSmallmcpL",
        value: e,
      })
    );
    setActSmallmcpL(e);
  };

  const PasSmallLeftMCPR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "PasLeftSmallmcpR",
        value: e,
      })
    );
    setPasLeftSmallmcpR(e);
  };
  const PasSmallLeftMCPL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "PasLeftSmallmcpL",
        value: e,
      })
    );
    setPasLeftSmallmcpL(e);
  };
  //new work khatam
  const MiddleLeftPIPR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "LeftMiddlepipR",
        value: e,
      })
    );
    setLeftMiddlepipR(e);
  };

  const MiddleLeftPIPL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "LeftMiddlepipL",
        value: e,
      })
    );
    setLeftMiddlepipL(e);
  };
  const MiddleLeftDIPR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "LeftMiddledipR",
        value: e,
      })
    );
    setLeftMiddledipR(e);
  };

  const MiddleLeftDIPL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "LeftMiddledipL",
        value: e,
      })
    );
    setLeftMiddledipL(e);
  };

  const RingMCPR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "RingmcpR",
        value: e,
      })
    );
    setRingmcpR(e);
  };

  const RingMCPL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "RingmcpL",
        value: e,
      })
    );
    setRingmcpL(e);
  };
  const RingPIPR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "RingpipR",
        value: e,
      })
    );
    setRingpipR(e);
  };

  const RingPIPL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "RingpipL",
        value: e,
      })
    );
    setRingpipL(e);
  };
  const RingDIPR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "RingdipR",
        value: e,
      })
    );
    setRingdipR(e);
  };

  const RingDIPL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "RingdipL",
        value: e,
      })
    );
    setRingdipL(e);
  };

  const RingLeftMCPR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "LeftRingmcpR",
        value: e,
      })
    );
    setLeftRingmcpR(e);
  };

  const RingLeftMCPL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "LeftRingmcpL",
        value: e,
      })
    );
    setLeftRingmcpL(e);
  };
  const RingLeftPIPR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "LeftRingpipR",
        value: e,
      })
    );
    setLeftRingpipR(e);
  };

  const RingLeftPIPL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "LeftRingpipL",
        value: e,
      })
    );
    setLeftRingpipL(e);
  };
  const RingLeftDIPR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "LeftRingdipR",
        value: e,
      })
    );
    setLeftRingdipR(e);
  };

  const RingLeftDIPL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "LeftRingdipL",
        value: e,
      })
    );
    setLeftRingdipL(e);
  };

  const SmallMCPR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "SmallmcpR",
        value: e,
      })
    );
    setSmallmcpR(e);
  };

  const SmallMCPL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "SmallmcpL",
        value: e,
      })
    );
    setSmallmcpL(e);
  };
  const SmallPIPR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "SmallpipR",
        value: e,
      })
    );
    setSmallpipR(e);
  };

  const SmallPIPL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "SmallpipL",
        value: e,
      })
    );
    setSmallpipL(e);
  };
  const SmallDIPR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "SmalldipR",
        value: e,
      })
    );
    setSmalldipR(e);
  };

  const SmallDIPL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "SmalldipL",
        value: e,
      })
    );
    setSmalldipL(e);
  };

  const SmallLeftMCPR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "LeftSmallmcpR",
        value: e,
      })
    );
    setLeftSmallmcpR(e);
  };

  const SmallLeftMCPL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "LeftSmallmcpL",
        value: e,
      })
    );
    setLeftSmallmcpL(e);
  };
  const SmallLeftPIPR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "LeftSmallpipR",
        value: e,
      })
    );
    setLeftSmallpipR(e);
  };

  const SmallLeftPIPL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "LeftSmallpipL",
        value: e,
      })
    );
    setLeftSmallpipL(e);
  };
  const SmallLeftDIPR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "LeftSmalldipR",
        value: e,
      })
    );
    setLeftSmalldipR(e);
  };
  const SmallLeftDIPL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "LeftSmalldipL",
        value: e,
      })
    );
    setLeftSmalldipL(e);
  };

  const ActThumbMCPR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "actMCPR",
        value: e,
      })
    );
    setActMCPR(e);
  };
  const ActThumbMCPL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "actMCPL",
        value: e,
      })
    );
    setActMCPL(e);
  };
  const PassThumbLeftMCPR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "passLeftMCPR",
        value: e,
      })
    );
    setPassLeftMCPR(e);
  };
  const PassThumbLeftMCPL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "passLeftMCPL",
        value: e,
      })
    );
    setPassLeftMCPL(e);
  };

  const ActThumbIPR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "actIPR",
        value: e,
      })
    );
    setactIPR(e);
  };
  const ActThumbIPL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "actIPL",
        value: e,
      })
    );
    setactIPL(e);
  };
  const PassThumbLeftIPR = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "passLeftIPR",
        value: e,
      })
    );
    setPassLeftIPR(e);
  };
  const PassThumbLeftIPL = (e) => {
    dispatch(
      updateHandWristFeedbackScores({
        key: "passLeftIPL",
        value: e,
      })
    );
    setPassLeftIPL(e);
  };

  let akpt = searchParams.get("akpt");
  const [msg, setMsg] = useState("");
  const [msgType, setmsgType] = useState("");
  const handleSubmit = () => {
    updateDate();
    setMsg(translation.update);
    setmsgType("success");
    setmsgType("success");
    setTimeout(() => {
    let view_circular=  sessionStorage.getItem("view_circular")
     // window.location.href = process.env.REACT_APP_DOCTOR_URL + "patient-view/" + akpt
    //  window.location.href = process.env.REACT_APP_DOCTOR_URL +`view-management-plan/${akpt}/${window.btoa(
    //    rId
    //  )}/?org=${window.btoa(sessionStorage.getItem("orgid")||'')}&formType=${
    //    window.btoa('23') || ""
    //  }&view_circular=${view_circular==='false'?'false':'true'}`
    window.location.href = process.env.REACT_APP_DOCTOR_URL +`patient-details/${akpt}?formid=23`
     setMsg('')
     setmsgType('success')
   }, 2000);
  };

  const handleNext=()=>{
    navigate(`/hand-wrist-feedback-step-2/${rId}?d=true&akpt=${akpt}&is_doctor_form=true`);

  }

  console.log(deformityRI, "deformityTextRI", Array.isArray(deformityRI) && deformityRI.filter((Item) => Item.value === "11"))
  // ---
  const [getId, setGetId] = useState("");
  useEffect(() => {
    // Get the Gait section element by its ID
    const gaitSection = document.getElementById(getId);
    console.log(gaitSection, "gaitSection");
    // Scroll to the Gait section
    if (gaitSection) {
      const yOffset = gaitSection.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: yOffset - window.innerHeight / 10,
        behavior: "smooth",
      });
    }
    console.log(getId, "getId");
  }, [getId]);
  return (
    <>
    {isLoading ? (
      <LoadingSpinner/>
    ):
    (
      <>

      <div class="d-flex justify-content-end">
        <DoctorNameCustom />
      </div>
      <div className="patient_assessment_form bg_white pd_10 pt_50">
        {doctorEnable === "true" && <HandWristTab rId={rId} />}
        <CustomToaster msg={msg} msgType={msgType} />
      </div>
      <div className="patient_assessment_form mb_100 bg_white pd_10 pt_50 content_fx_w">
        {/* <AssessmentHeader /> */}
        <HeaderInnerBreadcrumb />

        <div
          className={`patient_assessment_form mb_100 bg_white pd_10 pt_50 content_fx_w ${vmenuFix ? "fixed_vertical_menu" : ""
            }`}
        >
          <div className={`${assessmentStyles.inner_content_box}`}>
            <div className={`${assessmentStyles.accordion_sidebar}`}>
              <Navbar
                bg="light"
                className={`scrollbar_vert ${vertMenuStyles.vm_sidebar}`}
              >
                <Nav
                  className={`me-auto my-2 my-lg-0 ${vertMenuStyles.vm_sidebar_inner}`}
                  navbarScroll
                >
                  <Nav.Link
                    className={`${vertMenuStyles.vm_item}`}
                    onClick={()=>setGetId("Inspection")}
                    // href="#Inspection"
                  >
                    Inspection
                  </Nav.Link>
                  <Nav.Link
                    className={`${vertMenuStyles.vm_item}`}
                    onClick={()=>setGetId("Wrist")}
                    // href="#Wrist"
                  >
                    Wrist
                  </Nav.Link>
                  <Nav.Link
                    className={`${vertMenuStyles.vm_item}`}
                    onClick={()=>setGetId("Thumb")}
                    // href="#Thumb"
                  >
                    Thumb
                  </Nav.Link>
                  <Nav.Link
                    className={`${vertMenuStyles.vm_item}`}
                    onClick={()=>setGetId("Index")}
                    // href="#Index"
                  >
                    Index Finger
                  </Nav.Link>
                  <Nav.Link
                    className={`${vertMenuStyles.vm_item}`}
                    onClick={()=>setGetId("Middle")}
                    // href="#Middle"
                  >
                    Middle Finger
                  </Nav.Link>
                  <Nav.Link
                    className={`${vertMenuStyles.vm_item}`}
                    onClick={()=>setGetId("Ring")}
                    // href="#Ring"
                  >
                    Ring Finger
                  </Nav.Link>
                  <Nav.Link
                    className={`${vertMenuStyles.vm_item}`}
                    onClick={()=>setGetId("Small")}
                    // href="#Small"
                  >
                    Small Finger
                  </Nav.Link>
                </Nav>
              </Navbar>
            </div>
            <div
              className={`accordion_tab_content ${assessmentStyles.accordion_tab_content}`}
            >
              <div
                id="Inspection"
                className={`accordion_tabpanel ${assessmentStyles.accordion_tabpanel}`}
              >
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eKey="0">
                    <Accordion.Header>Inspection</Accordion.Header>
                    <Accordion.Body>
                      <div className="  mb_30">
                        <div className="row">
                          {/* <h4 className="range_sl_title">
                            {translation.DEFORMITY}
                          </h4> */}
                          <div className="col-md-6 col-sm-6">
                            <RadioSwitch
                              labelLeft="Negative"
                              labelRight="Positive"
                              idLeft="Scars2L"
                              idRight="Scars2R"
                              name="Scars2R"
                              title={"Scars or open wounds (Right)"}
                              styleClass=""
                              value={ScarsL}
                              onChange={handleScarsL}
                              placeholder="select"
                            />
                            {ScarsL === "1" && (
                              <InputField
                                label="Description"
                                value={ScarsLText}
                                type="text"
                                placeholder="Describe"
                                styleClass="mxw_100"
                                onChange={handleScarsLText}
                              />
                            )}
                          </div>
                          <div className="col-md-6 col-sm-6">
                            <RadioSwitch
                              labelLeft="Negative"
                              labelRight="Positive"
                              idLeft="ScarsL"
                              idRight="ScarsR"
                              name="ScarsR"
                              title={"Scars or open wounds (Left)"}
                              styleClass=""
                              value={ScarsR}
                              onChange={handleScarsR}
                              placeholder="select"
                            />
                            {ScarsR === "1" && (
                              <InputField
                                label="Description"
                                type="text"
                                value={ScarsRtext}
                                placeholder="Describe"
                                styleClass="mxw_100"
                                onChange={handleScarsRtext}
                              />
                            )}
                          </div>

                          <div className="col-md-6 col-sm-6">
                            <RadioSwitch
                              labelLeft="Negative"
                              labelRight="Positive"
                              idLeft="AtrophyL"
                              idRight="AtrophyR"
                              name="AtrophyR"
                              title={"Atrophy (Right)"}
                              styleClass=""
                              value={AtrophyR}
                              onChange={handleAtrophyR}
                              placeholder="select"
                            />
                            {AtrophyR === "1" && (
                              <InputField
                                label="Description"
                                value={AtrophyRText}
                                type="text"
                                placeholder="Describe"
                                styleClass="mxw_100"
                                onChange={handleAtrophyRText}
                              />
                            )}
                          </div>
                          <div className="col-md-6 col-sm-6">
                            <RadioSwitch
                              labelLeft="Negative"
                              labelRight="Positive"
                              idLeft="Atrophy2L"
                              idRight="Atrophy2R"
                              name="Atrophy2R"
                              title={"Atrophy (Left)"}
                              styleClass=""
                              value={AtrophyL}
                              onChange={handleAtrophyL}
                              placeholder="select"
                            />
                            {AtrophyL === "1" && (
                              <InputField
                                label="Description"
                                value={AtrophyLText}
                                type="text"
                                placeholder="Describe"
                                styleClass="mxw_100"
                                onChange={handleAtrophyLText}
                              />
                            )}
                          </div>

                          <div className="col-md-6 col-sm-6">
                            <RadioSwitch
                              labelLeft="Negative"
                              labelRight="Positive"
                              idLeft="DeformityL"
                              idRight="DeformityR"
                              name="DeformityR"
                              title={"Deformity (Right)"}
                              styleClass=""
                              value={DeformityR}
                              onChange={handleDeformityR}
                              placeholder="select"
                            />
                            {DeformityR === "1" && (
                              <>
                                {/* <div className="col-md-6 col-sm-6"> */}

                                <Select
                                  isMulti
                                  name="weekday"
                                  options={[
                                    {
                                      value: "2",
                                      label: "Clawed but mobile hand",
                                    },
                                    {
                                      value: "3",
                                      label: "Contracted or stiff joints",
                                    },
                                    {
                                      value: "4",
                                      label: "Amputation/Absorption",
                                    },
                                    { value: "5", label: "Wrist drop" },

                                    {
                                      value: "7",
                                      label: "Asymmetry/angulation /rotation",
                                    },
                                    { value: "8", label: "Mass" },
                                    {
                                      value: "9",
                                      label: "Boutonniere deformity",
                                    },
                                    {
                                      value: "10",
                                      label: "Swan neck deformity",
                                    },
                                    { value: "11", label: "Others" },
                                  ]}
                                  classNamePrefix="select"
                                  label="Right deformity"
                                  styleClass="mxw_70"
                                  value={deformityRI}
                                  placeholder="None"
                                  onChange={handleRightdeformityI}
                                />
                                {Array.isArray(deformityRI) && deformityRI.filter((Item) => Item.value === "11").length !== 0 ? (
                                  <>
                                    {/* <div className="col-md-6 col-sm-6"> */}
                                    <InputField
                                      label=""
                                      value={deformityTextRI}
                                      type="text"
                                      placeholder="Describe"
                                      styleClass="mxw_100 mt-2"
                                      onChange={handledeformityTextRI}
                                    />
                                    {/* </div> */}
                                  </>
                                ) : ""}

                                {/* <FullDropdown
                                  data={[
                                    {
                                      value: "2",
                                      label: "Clawed but mobile hand",
                                    },
                                    {
                                      value: "3",
                                      label: "Contracted or stiff joints",
                                    },
                                    {
                                      value: "4",
                                      label: "Amputation/Absorption",
                                    },
                                    { value: "5", label: "Wrist drop" },

                                    {
                                      value: "7",
                                      label: "Asymmetry/angulation /rotation",
                                    },
                                    { value: "8", label: "Mass" },
                                    {
                                      value: "9",
                                      label: "Boutonniere deformity",
                                    },
                                    {
                                      value: "10",
                                      label: "Swan neck deformity",
                                    },
                                    { value: "11", label: "Others" },
                                  ]}
                                  label="Right deformity"
                                  styleClass="mxw_70"
                                  value={deformityRI}
                                  placeholder="None"
                                  onChange={handleRightdeformityI}
                                /> */}

                                {/* </div> */}
                              </>
                            )}

                          </div>
                          <div className="col-md-6 col-sm-6">
                            <RadioSwitch
                              labelLeft="Negative"
                              labelRight="Positive"
                              idLeft="Deformity2L"
                              idRight="Deformity2R"
                              name="Deformity2R"
                              title={"Deformity (Left)"}
                              styleClass=""
                              value={DeformityL}
                              onChange={handleDeformityL}
                              placeholder="select"
                            />
                            {DeformityL === "1" && (
                              <>
                                {/* <div className="col-md-6 col-sm-6"> */}
                                <Select
                                  isMulti
                                  name="weekday"
                                  options={[
                                    {
                                      value: "2",
                                      label: "Clawed but mobile hand",
                                    },
                                    {
                                      value: "3",
                                      label: "Contracted or stiff joints",
                                    },
                                    {
                                      value: "4",
                                      label: "Amputation/Absorption",
                                    },
                                    { value: "5", label: "Wrist drop" },

                                    {
                                      value: "7",
                                      label: "Asymmetry/angulation /rotation",
                                    },
                                    { value: "8", label: "Mass" },
                                    {
                                      value: "9",
                                      label: "Boutonniere deformity",
                                    },
                                    {
                                      value: "10",
                                      label: "Swan neck deformity",
                                    },
                                    { value: "11", label: "Others" },
                                  ]}
                                  label="Left deformity"
                                  styleClass="mxw_70"
                                  value={deformityLI}
                                  placeholder="None"
                                  onChange={handleLeftdeformityI}
                                  classNamePrefix="select"
                                />
                                {Array.isArray(deformityLI) && deformityLI.filter((Item) => Item.value === "11").length !== 0 ? (
                                  <>
                                    {/* {deformityLI === "11" && ( */}
                                    <>
                                      {/* <div className="col-md-6 col-sm-6"> */}
                                      <InputField
                                        label=""
                                        value={deformityTextLI}
                                        type="text"
                                        placeholder="Describe"
                                        styleClass="mxw_100 mt-2"
                                        onChange={handledeformityTextLI}
                                      />
                                      {/* </div> */}
                                    </>
                                    {/* )}  */}
                                  </>
                                ) : ""}
                                {/* <FullDropdown
                                  data={[
                                    {
                                      value: "2",
                                      label: "Clawed but mobile hand",
                                    },
                                    {
                                      value: "3",
                                      label: "Contracted or stiff joints",
                                    },
                                    {
                                      value: "4",
                                      label: "Amputation/Absorption",
                                    },
                                    { value: "5", label: "Wrist drop" },

                                    {
                                      value: "7",
                                      label: "Asymmetry/angulation /rotation",
                                    },
                                    { value: "8", label: "Mass" },
                                    {
                                      value: "9",
                                      label: "Boutonniere deformity",
                                    },
                                    {
                                      value: "10",
                                      label: "Swan neck deformity",
                                    },
                                    { value: "11", label: "Others" },
                                  ]}
                                  label="Left deformity"
                                  styleClass="mxw_70"
                                  value={deformityLI}
                                  placeholder="None"
                                  onChange={handleLeftdeformityI}
                                /> */}
                                {/* {deformityLI === "11" && (
                                  <>
                                    <div className="col-md-6 col-sm-6">
                                    <InputField
                                      label=""
                                      value={deformityTextLI}
                                      type="text"
                                      placeholder="Describe"
                                      styleClass="mxw_100"
                                      onChange={handledeformityTextLI}
                                    />
                                    </div>
                                  </>
                                )} */}
                                {/* </div> */}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
              <div
                id="Wrist"
                className={`accordion_tabpanel ${assessmentStyles.accordion_tabpanel}`}
              >
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eKey="0">
                    <Accordion.Header>Wrist</Accordion.Header>
                    <Accordion.Body>
                      <div className="row">
                        <div className="row mb_30">
                          <div className="col-md-6 col-sm-6">
                            <AssessmentDegreeWidget
                              viewValidation="true"
                              title="Active Flexion"
                              labelL="Left"
                              labelR="Right"
                              nameR="Right"
                              nameL="Left"
                              valueR={wristflexionR}
                              valueL={wristflexionL}
                              onChangeR={WristflexionR}
                              onChangeL={WristflexionL}
                              validationfor="Flexion"
                            />
                          </div>
                          <div className="col-md-6 col-sm-6">
                            <AssessmentDegreeWidget
                              viewValidation="true"
                              title="Passive Flexion"
                              labelL="Left "
                              labelR="Right "
                              nameR="Right"
                              nameL="Left"
                              valueR={wristflexionPR}
                              valueL={wristflexionPL}
                              onChangeR={WristflexionPR}
                              onChangeL={WristflexionPL}
                              validationfor="Flexion"
                            />
                          </div>
                        </div>
                        <div className="row mb_30">
                          <div className="col-md-6 col-sm-6">
                            <AssessmentDegreeWidget
                              viewValidation="true"
                              title="Active Extension"
                              labelL="Left "
                              labelR="Right "
                              nameR="Right"
                              nameL="Left"
                              onChangeR={WristextensionR}
                              onChangeL={WristextensionL}
                              valueR={wristextensionR}
                              valueL={wristextensionL}
                              validationfor="Extension"
                            />
                          </div>
                          <div className="col-md-6 col-sm-6">
                            <AssessmentDegreeWidget
                              viewValidation="true"
                              title="Passive Extension"
                              labelL="Left "
                              labelR="Right "
                              nameR="Right"
                              nameL="Left"
                              onChangeR={WristextensionPR}
                              onChangeL={WristextensionPL}
                              valueR={wristextensionPR}
                              valueL={wristextensionPL}
                              validationfor="Extension"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="row mb_30">
                          <div className="col-md-6 col-sm-6">
                            <AssessmentDegreeWidget
                              viewValidation="true"
                              title="Active Radial Deviation"
                              labelL="Left"
                              labelR="Right"
                              nameR="Right"
                              nameL="Left"
                              valueR={radialDeviationR}
                              valueL={radialDeviationL}
                              onChangeR={RadialDeviationR}
                              onChangeL={RadialDeviationL}
                              validationfor="Flexion"
                            />
                          </div>
                          <div className="col-md-6 col-sm-6">
                            <AssessmentDegreeWidget
                              viewValidation="true"
                              title="Passive Radial Deviation"
                              labelL="Left "
                              labelR="Right "
                              nameR="Right"
                              nameL="Left"
                              valueR={radialDeviationPR}
                              valueL={radialDeviationPL}
                              onChangeR={RadialDeviationPR}
                              onChangeL={RadialDeviationPL}
                              validationfor="Flexion"
                            />
                          </div>
                        </div>
                        <div className="row mb_30">
                          <div className="col-md-6 col-sm-6">
                            <AssessmentDegreeWidget
                              viewValidation="true"
                              title="Active Ulnar Deviation"
                              labelL="Left "
                              labelR="Right "
                              nameR="Right"
                              nameL="Left"
                              onChangeR={UlnarR}
                              onChangeL={UlnarL}
                              valueR={ulnarR}
                              valueL={ulnarL}
                              validationfor="Flexion"
                            />
                          </div>
                          <div className="col-md-6 col-sm-6">
                            <AssessmentDegreeWidget
                              viewValidation="true"
                              title="Passive Ulnar Deviation"
                              labelL="Left "
                              labelR="Right "
                              nameR="Right"
                              nameL="Left"
                              onChangeR={UlnarPR}
                              onChangeL={UlnarPL}
                              valueR={ulnarPR}
                              valueL={ulnarPL}
                              validationfor="Flexion"
                            />
                          </div>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
              <div
                id="Thumb"
                className={`accordion_tabpanel ${assessmentStyles.accordion_tabpanel}`}
              >
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eKey="0">
                    <Accordion.Header>Thumb</Accordion.Header>
                    <Accordion.Body>
                      <div className="mb_30">
                        <div className="row">
                          {/* <h4 className="range_sl_title">
                            {translation.Thumb_R}
                          </h4> */}

                          <div className="row mb_30">
                            <div className="col-md-6 col-sm-6">
                              <AssessmentDegreeWidget
                                viewValidation="true"
                                title="Active Palmar Abduction"
                                labelL="Left"
                                labelR="Right"
                                nameR="Palmar Right"
                                nameL="Palmar Left"
                                valueR={actPalmerR}
                                valueL={actPalmerL}
                                onChangeR={ActPalmerR}
                                onChangeL={ActPalmerL}
                                validationfor="Flexion"
                              />
                            </div>
                            <div className="col-md-6 col-sm-6">
                              <AssessmentDegreeWidget
                                viewValidation="true"
                                title="Passive Palmar Abduction"
                                labelL="Left"
                                labelR="Right"
                                nameR="PPalmar Right"
                                nameL="PPalmar Left"
                                valueR={pasPalmerR}
                                valueL={pasPalmerL}
                                onChangeR={PasPalmerR}
                                onChangeL={PasPalmerL}
                                validationfor="Flexion"
                              />
                            </div>
                          </div>

                          <div className="row mb_30">
                            <div className="col-md-6 col-sm-6">
                              <AssessmentDegreeWidget
                                viewValidation="true"
                                title="Active Radial Abduction"
                                labelL="Left"
                                labelR="Right"
                                nameR="Radial Right"
                                nameL="Radial Left"
                                valueR={actRadialR}
                                valueL={actRadialL}
                                onChangeR={ActRadialR}
                                onChangeL={ActRadialL}
                                validationfor="Flexion"
                              />
                            </div>
                            <div className="col-md-6 col-sm-6">
                              <AssessmentDegreeWidget
                                viewValidation="true"
                                title="Passive Radial Abduction"
                                labelL="Left"
                                labelR="Right"
                                nameR="PRadial Right"
                                nameL="PRadial Left"
                                valueR={pasRadialR}
                                valueL={pasRadialL}
                                onChangeR={PasRadialR}
                                onChangeL={PasRadialL}
                                validationfor="Flexion"
                              />
                            </div>
                          </div>
                          <div className="row mb_30">
                            <div className="col-md-6 col-sm-6">
                              <AssessmentDegreeWidget
                                viewValidation="true"
                                title="Active MCP Flexion"
                                labelL="Left"
                                labelR="Right"
                                nameR="MCP Right"
                                nameL="MCP Left"
                                valueR={MCPR}
                                valueL={MCPL}
                                onChangeR={ThumbMCPR}
                                onChangeL={ThumbMCPL}
                                validationfor="Flexion"
                              />
                            </div>
                            <div className="col-md-6 col-sm-6">
                              <AssessmentDegreeWidget
                                viewValidation="true"
                                title="Passive MCP Flexion"
                                labelL="Left"
                                labelR="Right"
                                nameR="MCP Right"
                                nameL="MCP Left"
                                valueR={LeftMCPR}
                                valueL={LeftMCPL}
                                onChangeR={ThumbLeftMCPR}
                                onChangeL={ThumbLeftMCPL}
                                validationfor="Flexion"
                              />
                            </div>
                          </div>
                          <div className="row mb_30">
                            <div className="col-md-6 col-sm-6">
                              <AssessmentDegreeWidget
                                viewValidation="true"
                                title="Active MCP Extension"
                                labelL="Left"
                                labelR="Right"
                                nameR="MCP Right"
                                nameL="MCP Left"
                                valueR={actMCPR}
                                valueL={actMCPL}
                                onChangeR={ActThumbMCPR}
                                onChangeL={ActThumbMCPL}
                                validationfor="IPExtension"
                              />
                            </div>
                            <div className="col-md-6 col-sm-6">
                              <AssessmentDegreeWidget
                                viewValidation="true"
                                title="Passive MCP Extension"
                                labelL="Left"
                                labelR="Right"
                                nameR="MCP Right"
                                nameL="MCP Left"
                                valueR={passLeftMCPR}
                                valueL={passLeftMCPL}
                                onChangeR={PassThumbLeftMCPR}
                                onChangeL={PassThumbLeftMCPL}
                                validationfor="IPExtension"
                              />
                            </div>
                          </div>

                          <div className="row mb_30">
                            <div className="col-md-6 col-sm-6">
                              <AssessmentDegreeWidget
                                viewValidation="true"
                                title="Active IP Flexion"
                                labelL="Left "
                                labelR="Right "
                                nameR="IP Right"
                                nameL="IP Left"
                                valueR={IPR}
                                valueL={IPL}
                                onChangeR={ThumbIPR}
                                onChangeL={ThumbIPL}
                                validationfor="Flexion"
                              />
                            </div>
                            <div className="col-md-6 col-sm-6">
                              <AssessmentDegreeWidget
                                viewValidation="true"
                                title="Passive IP Flexion"
                                labelL="Left "
                                labelR="Right "
                                nameR="IP Right"
                                nameL="IP Left"
                                valueR={LeftIPR}
                                valueL={LeftIPL}
                                onChangeR={ThumbLeftIPR}
                                onChangeL={ThumbLeftIPL}
                                validationfor="Flexion"
                              />
                            </div>
                          </div>

                          <div className="row mb_30">
                            <div className="col-md-6 col-sm-6">
                              <AssessmentDegreeWidget
                                viewValidation="true"
                                title="Active IP Extension"
                                labelL="Left "
                                labelR="Right "
                                nameR="IP Right"
                                nameL="IP Left"
                                valueR={actIPR}
                                valueL={actIPL}
                                onChangeR={ActThumbIPR}
                                onChangeL={ActThumbIPL}
                                validationfor="IPExtension"
                              />
                            </div>
                            <div className="col-md-6 col-sm-6">
                              <AssessmentDegreeWidget
                                viewValidation="true"
                                title="Passive IP Extension"
                                labelL="Left "
                                labelR="Right "
                                nameR="IP Right"
                                nameL="IP Left"
                                valueR={passLeftIPR}
                                valueL={passLeftIPL}
                                onChangeR={PassThumbLeftIPR}
                                onChangeL={PassThumbLeftIPL}
                                validationfor="IPExtension"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
              <div
                id="Index"
                className={`accordion_tabpanel ${assessmentStyles.accordion_tabpanel}`}
              >
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eKey="0">
                    <Accordion.Header>Index Finger</Accordion.Header>
                    <Accordion.Body>
                      <div className="mb_30">
                        <div className="row">
                          <div className="row mb_30">
                            <div className="col-md-6 col-sm-6">
                              <AssessmentDegreeWidget
                                viewValidation="true"
                                title="Active MCP Flexion"
                                validationfor="Flexion"
                                labelL="Left "
                                labelR="Right "
                                nameR="MCP Right"
                                nameL="MCP Left"
                                valueR={IndexmcpR}
                                valueL={IndexmcpL}
                                onChangeR={IndexMCPR}
                                onChangeL={IndexMCPL}
                              />
                            </div>
                            <div className="col-md-6 col-sm-6">
                              <AssessmentDegreeWidget
                                viewValidation="true"
                                title="Passive MCP Flexion"
                                validationfor="Flexion"
                                labelL="Left "
                                labelR="Right "
                                nameR="MCP Right"
                                nameL="MCP Left"
                                valueR={LeftIndexmcpR}
                                valueL={LeftIndexmcpL}
                                onChangeR={IndexLeftMCPR}
                                onChangeL={IndexLeftMCPL}
                              />
                            </div>
                          </div>
                          <div className="row mb_30">
                            <div className="col-md-6 col-sm-6">
                              <AssessmentDegreeWidget
                                viewValidation="true"
                                title="Active MCP Extension"
                                labelL="Left "
                                labelR="Right "
                                nameR="MCP Right"
                                nameL="MCP Left"
                                valueR={ExtIndexmcpR}
                                valueL={ExtIndexmcpL}
                                onChangeR={ExtIndexMCPR}
                                onChangeL={ExtIndexMCPL}
                                validationfor="MCPExtension"
                              />
                            </div>
                            <div className="col-md-6 col-sm-6">
                              <AssessmentDegreeWidget
                                viewValidation="true"
                                title="Passive MCP Extension"
                                validationfor="MCPExtension"
                                labelL="Left "
                                labelR="Right "
                                nameR="MCP Right"
                                nameL="MCP Left"
                                valueR={LeftExtIndexmcpR}
                                valueL={LeftExtIndexmcpL}
                                onChangeR={ExtIndexLeftMCPR}
                                onChangeL={ExtIndexLeftMCPL}
                              />
                            </div>
                          </div>

                          <div className="row mb_30">
                            <div className="col-md-6 col-sm-6">
                              <AssessmentDegreeWidget
                                viewValidation="true"
                                title="Active PIP Flexion"
                                validationfor="PipFlexion"
                                labelL="Left "
                                labelR="Right "
                                nameR="PIP Right"
                                nameL="PIP Left"
                                valueR={IndexpipR}
                                valueL={IndexpipL}
                                onChangeR={IndexPIPR}
                                onChangeL={IndexPIPL}
                              />
                            </div>
                            <div className="col-md-6 col-sm-6">
                              <AssessmentDegreeWidget
                                viewValidation="true"
                                title="Passive PIP Flexion"
                                validationfor="PipFlexion"
                                labelL="Left "
                                labelR="Right "
                                nameR="PIP Right"
                                nameL="PIP Left"
                                valueR={LeftIndexpipR}
                                valueL={LeftIndexpipL}
                                onChangeR={IndexLeftPIPR}
                                onChangeL={IndexLeftPIPL}
                              />
                            </div>
                          </div>

                          <div className="row mb_30">
                            <div className="col-md-6 col-sm-6">
                              <AssessmentDegreeWidget
                                viewValidation="true"
                                title="Active PIP Extension"
                                validationfor="MCPExtension"
                                labelL="Left "
                                labelR="Right "
                                nameR="PIP Right"
                                nameL="PIP Left"
                                valueR={actIndexpipR}
                                valueL={actIndexpipL}
                                onChangeR={ActIndexPIPR}
                                onChangeL={ActIndexPIPL}
                              />
                            </div>
                            <div className="col-md-6 col-sm-6">
                              <AssessmentDegreeWidget
                                viewValidation="true"
                                title="Passive PIP Extension"
                                validationfor="MCPExtension"
                                labelL="Left "
                                labelR="Right "
                                nameR="PIP Right"
                                nameL="PIP Left"
                                valueR={passLeftIndexpipR}
                                valueL={passLeftIndexpipL}
                                onChangeR={PassIndexLeftPIPR}
                                onChangeL={PassIndexLeftPIPL}
                              />
                            </div>
                          </div>


                          <div className="row mb_30">
                            <div className="col-md-6 col-sm-6">
                              <AssessmentDegreeWidget
                                viewValidation="true"
                                title="Active DIP Flexion"
                                validationfor="Flexion"
                                labelL="Left "
                                labelR="Right "
                                nameR="DIP Right"
                                nameL="DIP Left"
                                valueR={IndexdipR}
                                valueL={IndexdipL}
                                onChangeR={IndexDIPR}
                                onChangeL={IndexDIPL}
                              />
                            </div>
                            <AssessmentDegreeWidget
                              viewValidation="true"
                              title="Passive DIP Flexion"
                              validationfor="Flexion"
                              labelL="Left "
                              labelR="Right "
                              nameR="DIP Right"
                              nameL="DIP Left"
                              valueR={LeftIndexdipR}
                              valueL={LeftIndexdipL}
                              onChangeR={IndexLeftDIPR}
                              onChangeL={IndexLeftDIPL}
                            />
                          </div>

                          <div className="row mb_30">
                            <div className="col-md-6 col-sm-6">
                              <AssessmentDegreeWidget
                                viewValidation="true"
                                title="Active DIP Extension"
                                validationfor="MCPExtension"
                                labelL="Left "
                                labelR="Right "
                                nameR="DIP Right"
                                nameL="DIP Left"
                                valueR={actIndexdipR}
                                valueL={actIndexdipL}
                                onChangeR={ActIndexDIPR}
                                onChangeL={ActIndexDIPL}
                              />
                            </div>
                            <AssessmentDegreeWidget
                              viewValidation="true"
                              title="Passive DIP Extension"
                              validationfor="MCPExtension"
                              labelL="Left "
                              labelR="Right "
                              nameR="DIP Right"
                              nameL="DIP Left"
                              valueR={passLeftIndexdipR}
                              valueL={passLeftIndexdipL}
                              onChangeR={PassIndexLeftDIPR}
                              onChangeL={PassIndexLeftDIPL}
                            />
                          </div>




                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
              <div
                id="Middle"
                className={`accordion_tabpanel ${assessmentStyles.accordion_tabpanel}`}
              >
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eKey="0">
                    <Accordion.Header>Middle Finger</Accordion.Header>
                    <Accordion.Body>
                      <div className=" mb_30">
                        <div className="row">
                          <div className="row mb_30">
                            <div className="col-md-6 col-sm-6">
                              <AssessmentDegreeWidget
                                viewValidation="true"
                                title="Active MCP Flexion"
                                validationfor="Flexion"
                                labelL="Left "
                                labelR="Right "
                                nameR="MCP Right"
                                nameL="MCP Left"
                                valueR={MiddlemcpR}
                                valueL={MiddlemcpL}
                                onChangeR={MiddleMCPR}
                                onChangeL={MiddleMCPL}
                              />
                            </div>
                            <div className="col-md-6 col-sm-6">
                              <AssessmentDegreeWidget
                                viewValidation="true"
                                title="Passive MCP Flexion"
                                validationfor="Flexion"
                                labelL="Left "
                                labelR="Right "
                                nameR="MCP Right"
                                nameL="MCP Left"
                                valueR={LeftMiddlemcpR}
                                valueL={LeftMiddlemcpL}
                                onChangeR={MiddleLeftMCPR}
                                onChangeL={MiddleLeftMCPL}
                              />
                            </div>
                          </div>

                          <div className="row mb_30">
                            <div className="col-md-6 col-sm-6">
                              <AssessmentDegreeWidget
                                viewValidation="true"
                                title="Active MCP Extension"
                                validationfor="MCPExtension"
                                labelL="Left "
                                labelR="Right "
                                nameR="MCP Right"
                                nameL="MCP Left"
                                valueR={ActMiddlemcpR}
                                valueL={ActMiddlemcpL}
                                onChangeR={ActMiddleMCPR}
                                onChangeL={ActMiddleMCPL}
                              />
                            </div>
                            <div className="col-md-6 col-sm-6">
                              <AssessmentDegreeWidget
                                viewValidation="true"
                                title="Passive MCP Extension"
                                validationfor="MCPExtension"
                                labelL="Left "
                                labelR="Right "
                                nameR="MCP Right"
                                nameL="MCP Left"
                                valueR={PasLeftMiddlemcpR}
                                valueL={PasLeftMiddlemcpL}
                                onChangeR={PasMiddleLeftMCPR}
                                onChangeL={PasMiddleLeftMCPL}
                              />
                            </div>
                          </div>

                          <div className="row mb_30">
                            <div className="col-md-6 col-sm-6">
                              <AssessmentDegreeWidget
                                viewValidation="true"
                                title="Active PIP Flexion"
                                validationfor="PipFlexion"
                                labelL="Left "
                                labelR="Right "
                                nameR="PIP Right"
                                nameL="PIP Left"
                                valueR={MiddlepipR}
                                valueL={MiddlepipL}
                                onChangeR={MiddlePIPR}
                                onChangeL={MiddlePIPL}
                              />
                            </div>
                            <div className="col-md-6 col-sm-6">
                              <AssessmentDegreeWidget
                                viewValidation="true"
                                title="Passive PIP Flexion"
                                validationfor="PipFlexion"
                                labelL="Left "
                                labelR="Right "
                                nameR="PIP Right"
                                nameL="PIP Left"
                                valueR={LeftMiddlepipR}
                                valueL={LeftMiddlepipL}
                                onChangeR={MiddleLeftPIPR}
                                onChangeL={MiddleLeftPIPL}
                              />
                            </div>
                          </div>

                          <div className="row mb_30">
                            <div className="col-md-6 col-sm-6">
                              <AssessmentDegreeWidget
                                viewValidation="true"
                                title="Active PIP Extension"
                                validationfor="MCPExtension"
                                labelL="Left "
                                labelR="Right "
                                nameR="PIP Right"
                                nameL="PIP Left"
                                valueR={ActMiddlepipR}
                                valueL={ActMiddlepipL}
                                onChangeR={ActMiddlePIPR}
                                onChangeL={ActMiddlePIPL}
                              />
                            </div>
                            <div className="col-md-6 col-sm-6">
                              <AssessmentDegreeWidget
                                viewValidation="true"
                                title="Passive PIP Extension"
                                validationfor="MCPExtension"
                                labelL="Left "
                                labelR="Right "
                                nameR="PIP Right"
                                nameL="PIP Left"
                                valueR={pasLeftMiddlepipR}
                                valueL={pasLeftMiddlepipL}
                                onChangeR={pasMiddleLeftPIPR}
                                onChangeL={pasMiddleLeftPIPL}
                              />
                            </div>
                          </div>

                          <div className="row mb_30">
                            <div className="col-md-6 col-sm-6">
                              <AssessmentDegreeWidget
                                viewValidation="true"
                                title="Active DIP Flexion"
                                validationfor="Flexion"
                                labelL="Left "
                                labelR="Right "
                                nameR="DIP Right"
                                nameL="DIP Left"
                                valueR={MiddledipR}
                                valueL={MiddledipL}
                                onChangeR={MiddleDIPR}
                                onChangeL={MiddleDIPL}
                              />
                            </div>
                            <div className="col-md-6 col-sm-6">
                              <AssessmentDegreeWidget
                                viewValidation="true"
                                title="Passive DIP Flexion"
                                validationfor="Flexion"
                                labelL="Left "
                                labelR="Right "
                                nameR="DIP Right"
                                nameL="DIP Left"
                                valueR={LeftMiddledipR}
                                valueL={LeftMiddledipL}
                                onChangeR={MiddleLeftDIPR}
                                onChangeL={MiddleLeftDIPL}
                              />
                            </div>
                          </div>

                          <div className="row mb_30">
                            <div className="col-md-6 col-sm-6">
                              <AssessmentDegreeWidget
                                viewValidation="true"
                                title="Active DIP Extension"
                                validationfor="MCPExtension"
                                labelL="Left "
                                labelR="Right "
                                nameR="DIP Right"
                                nameL="DIP Left"
                                valueR={ActMiddledipR}
                                valueL={ActMiddledipL}
                                onChangeR={ActMiddleDIPR}
                                onChangeL={ActMiddleDIPL}
                              />
                            </div>
                            <div className="col-md-6 col-sm-6">
                              <AssessmentDegreeWidget
                                viewValidation="true"
                                title="Passive DIP Extension"
                                validationfor="MCPExtension"
                                labelL="Left "
                                labelR="Right "
                                nameR="DIP Right"
                                nameL="DIP Left"
                                valueR={pasLeftMiddledipR}
                                valueL={pasLeftMiddledipL}
                                onChangeR={pasMiddleLeftDIPR}
                                onChangeL={pasMiddleLeftDIPL}
                              />
                            </div>
                          </div>



                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
              <div
                id="Ring"
                className={`accordion_tabpanel ${assessmentStyles.accordion_tabpanel}`}
              >
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eKey="0">
                    <Accordion.Header>Ring Finger</Accordion.Header>
                    <Accordion.Body>
                      <div className="mb_30">
                        <div className="row">
                          <div className="row mb_30">
                            <div className="col-md-6 col-sm-6">
                              <AssessmentDegreeWidget
                                viewValidation="true"
                                title="Active MCP Flexion"
                                validationfor="Flexion"
                                labelL="Left "
                                labelR="Right "
                                nameR="MCP Right"
                                nameL="MCP Left"
                                valueR={RingmcpR}
                                valueL={RingmcpL}
                                onChangeR={RingMCPR}
                                onChangeL={RingMCPL}
                              />
                            </div>
                            <div className="col-md-6 col-sm-6">
                              <AssessmentDegreeWidget
                                viewValidation="true"
                                title="Passive MCP Flexion"
                                validationfor="Flexion"
                                labelL="Left "
                                labelR="Right "
                                nameR="MCP Right"
                                nameL="MCP Left"
                                valueR={LeftRingmcpR}
                                valueL={LeftRingmcpL}
                                onChangeR={RingLeftMCPR}
                                onChangeL={RingLeftMCPL}
                              />
                            </div>
                          </div>

                          <div className="row mb_30">
                            <div className="col-md-6 col-sm-6">
                              <AssessmentDegreeWidget
                                viewValidation="true"
                                title="Active MCP Extension"
                                validationfor="Flexion"
                                labelL="Left "
                                labelR="Right "
                                nameR="MCP Right"
                                nameL="MCP Left"
                                valueR={ActRingmcpR}
                                valueL={ActRingmcpL}
                                onChangeR={ActRingMCPR}
                                onChangeL={ActRingMCPL}
                              />
                            </div>
                            <div className="col-md-6 col-sm-6">
                              <AssessmentDegreeWidget
                                viewValidation="true"
                                title="Passive MCP Extension"
                                validationfor="Flexion"
                                labelL="Left "
                                labelR="Right "
                                nameR="MCP Right"
                                nameL="MCP Left"
                                valueR={PasLeftRingmcpR}
                                valueL={PasLeftRingmcpL}
                                onChangeR={PasRingLeftMCPR}
                                onChangeL={PasRingLeftMCPL}
                              />
                            </div>
                          </div>

                          <div className="row mb_30">
                            <div className="col-md-6 col-sm-6">
                              <AssessmentDegreeWidget
                                viewValidation="true"
                                title="Active PIP Flexion"
                                validationfor="PipFlexion"
                                labelL="Left "
                                labelR="Right "
                                nameR="PIP Right"
                                nameL="PIP Left"
                                valueR={RingpipR}
                                valueL={RingpipL}
                                onChangeR={RingPIPR}
                                onChangeL={RingPIPL}
                              />
                            </div>

                            <div className="col-md-6 col-sm-6">
                              <AssessmentDegreeWidget
                                viewValidation="true"
                                title="Passive  PIP Flexion"
                                validationfor="PipFlexion"
                                labelL="Left "
                                labelR="Right "
                                nameR="PIP Right"
                                nameL="PIP Left"
                                valueR={LeftRingpipR}
                                valueL={LeftRingpipL}
                                onChangeR={RingLeftPIPR}
                                onChangeL={RingLeftPIPL}
                              />
                            </div>
                          </div>

                          <div className="row mb_30">
                            <div className="col-md-6 col-sm-6">
                              <AssessmentDegreeWidget
                                viewValidation="true"
                                title="Active PIP Extension"
                                validationfor="MCPExtension"
                                labelL="Left "
                                labelR="Right "
                                nameR="PIP Right"
                                nameL="PIP Left"
                                valueR={ActRingpipR}
                                valueL={ActRingpipL}
                                onChangeR={ActRingPIPR}
                                onChangeL={ActRingPIPL}
                              />
                            </div>

                            <div className="col-md-6 col-sm-6">
                              <AssessmentDegreeWidget
                                viewValidation="true"
                                title="Passive  PIP Extension"
                                validationfor="MCPExtension"
                                labelL="Left "
                                labelR="Right "
                                nameR="PIP Right"
                                nameL="PIP Left"
                                valueR={PasLeftRingpipR}
                                valueL={PasLeftRingpipL}
                                onChangeR={PasRingLeftPIPR}
                                onChangeL={PasRingLeftPIPL}
                              />
                            </div>
                          </div>

                          <div className="row mb_30">
                            <div className="col-md-6 col-sm-6">
                              <AssessmentDegreeWidget
                                viewValidation="true"
                                title="Active DIP Flexion"
                                validationfor="Flexion"
                                labelL="Left "
                                labelR="Right "
                                nameR="DIP Right"
                                nameL="DIP Left"
                                valueR={ActRingdipR}
                                valueL={ActRingdipL}
                                onChangeR={ActRingDIPR}
                                onChangeL={ActRingDIPL}
                              />
                            </div>
                            <div className="col-md-6 col-sm-6">
                              <AssessmentDegreeWidget
                                viewValidation="true"
                                title="Passive DIP Flexion"
                                validationfor="Flexion"
                                labelL="Left "
                                labelR="Right "
                                nameR="DIP Right"
                                nameL="DIP Left"
                                valueR={PasLeftRingdipR}
                                valueL={PasLeftRingdipL}
                                onChangeR={PasRingLeftDIPR}
                                onChangeL={PasRingLeftDIPL}
                              />
                            </div>
                          </div>

                          <div className="row mb_30">
                            <div className="col-md-6 col-sm-6">
                              <AssessmentDegreeWidget
                                viewValidation="true"
                                title="Active DIP Extension"
                                validationfor="MCPExtension"
                                labelL="Left "
                                labelR="Right "
                                nameR="DIP Right"
                                nameL="DIP Left"
                                valueR={Act1RingdipR}
                                valueL={Act1RingdipL}
                                onChangeR={Act1RingDIPR}
                                onChangeL={Act1RingDIPL}
                              />
                            </div>
                            <div className="col-md-6 col-sm-6">
                              <AssessmentDegreeWidget
                                viewValidation="true"
                                title="Passive DIP Extension"
                                validationfor="MCPExtension"
                                labelL="Left "
                                labelR="Right "
                                nameR="DIP Right"
                                nameL="DIP Left"
                                valueR={Pas2LeftRingdipR}
                                valueL={Pas2LeftRingdipL}
                                onChangeR={Pas2RingLeftDIPR}
                                onChangeL={Pas2RingLeftDIPL}
                              />
                            </div>
                          </div>



                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
              <div
                id="Small"
                className={`accordion_tabpanel ${assessmentStyles.accordion_tabpanel}`}
              >
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eKey="0">
                    <Accordion.Header>Small Finger</Accordion.Header>
                    <Accordion.Body>
                      <div className=" mb_30">
                        <div className="row">
                          <div className="row mb_30">
                            <div className="col-md-6 col-sm-6">
                              <AssessmentDegreeWidget
                                viewValidation="true"
                                title="Active MCP Flexion"
                                validationfor="Flexion"
                                labelL="Left"
                                labelR="Right"
                                nameR="MCP Right"
                                nameL="MCP Left"
                                valueR={SmallmcpR}
                                valueL={SmallmcpL}
                                onChangeR={SmallMCPR}
                                onChangeL={SmallMCPL}
                              />
                            </div>
                            <div className="col-md-6 col-sm-6">
                              <AssessmentDegreeWidget
                                viewValidation="true"
                                title="Passive MCP Flexion"
                                validationfor="Flexion"
                                labelL="Left"
                                labelR="Right"
                                nameR="MCP Right"
                                nameL="MCP Left"
                                valueR={LeftSmallmcpR}
                                valueL={LeftSmallmcpL}
                                onChangeR={SmallLeftMCPR}
                                onChangeL={SmallLeftMCPL}
                              />
                            </div>
                          </div>

                          <div className="row mb_30">
                            <div className="col-md-6 col-sm-6">
                              <AssessmentDegreeWidget
                                viewValidation="true"
                                title="Active MCP Extension"
                                validationfor="MCPExtension"
                                labelL="Left"
                                labelR="Right"
                                nameR="MCP Right"
                                nameL="MCP Left"
                                valueR={ActSmallmcpR}
                                valueL={ActSmallmcpL}
                                onChangeR={ActSmallMCPR}
                                onChangeL={ActSmallMCPL}
                              />
                            </div>
                            <div className="col-md-6 col-sm-6">
                              <AssessmentDegreeWidget
                                viewValidation="true"
                                title="Passive MCP Extension"
                                validationfor="MCPExtension"
                                labelL="Left"
                                labelR="Right"
                                nameR="MCP Right"
                                nameL="MCP Left"
                                valueR={PasLeftSmallmcpR}
                                valueL={PasLeftSmallmcpL}
                                onChangeR={PasSmallLeftMCPR}
                                onChangeL={PasSmallLeftMCPL}
                              />
                            </div>
                          </div>

                          <div className="row mb_30">
                            <div className="col-md-6 col-sm-6">
                              <AssessmentDegreeWidget
                                viewValidation="true"
                                title="Active PIP Flexion"
                                validationfor="PipFlexion"
                                labelL="Left"
                                labelR="Right"
                                nameR="PIP Right"
                                nameL="PIP Left"
                                valueR={SmallpipR}
                                valueL={SmallpipL}
                                onChangeR={SmallPIPR}
                                onChangeL={SmallPIPL}
                              />
                            </div>
                            <div className="col-md-6 col-sm-6">
                              <AssessmentDegreeWidget
                                viewValidation="true"
                                title="Passive PIP Flexion"
                                validationfor="PipFlexion"
                                labelL="Left"
                                labelR="Right"
                                nameR="PIP Right"
                                nameL="PIP Left"
                                valueR={LeftSmallpipR}
                                valueL={LeftSmallpipL}
                                onChangeR={SmallLeftPIPR}
                                onChangeL={SmallLeftPIPL}
                              />
                            </div>
                          </div>

                          <div className="row mb_30">
                            <div className="col-md-6 col-sm-6">
                              <AssessmentDegreeWidget
                                viewValidation="true"
                                title="Active PIP Extension"
                                validationfor="MCPExtension"
                                labelL="Left"
                                labelR="Right"
                                nameR="PIP Right"
                                nameL="PIP Left"
                                valueR={ActSmallpipR}
                                valueL={ActSmallpipL}
                                onChangeR={ActSmallPIPR}
                                onChangeL={ActSmallPIPL}
                              />
                            </div>
                            <div className="col-md-6 col-sm-6">
                              <AssessmentDegreeWidget
                                viewValidation="true"
                                title="Passive PIP Extension"
                                validationfor="MCPExtension"
                                labelL="Left"
                                labelR="Right"
                                nameR="PIP Right"
                                nameL="PIP Left"
                                valueR={PasLeftSmallpipR}
                                valueL={PasLeftSmallpipL}
                                onChangeR={PasSmallLeftPIPR}
                                onChangeL={PasSmallLeftPIPL}
                              />
                            </div>
                          </div>

                          <div className="row mb_30">
                            <div className="col-md-6 col-sm-6">
                              <AssessmentDegreeWidget
                                viewValidation="true"
                                title="Active DIP Flexion"
                                validationfor="Flexion"
                                labelL="Left"
                                labelR="Right"
                                nameR="DIP Right"
                                nameL="DIP Left"
                                valueR={SmalldipR}
                                valueL={SmalldipL}
                                onChangeR={SmallDIPR}
                                onChangeL={SmallDIPL}
                              />
                            </div>
                            <div className="col-md-6 col-sm-6">
                              <AssessmentDegreeWidget
                                viewValidation="true"
                                title="Passive DIP Flexion"
                                validationfor="Flexion"
                                labelL="Left"
                                labelR="Right"
                                nameR="DIP Right"
                                nameL="DIP Left"
                                valueR={LeftSmalldipR}
                                valueL={LeftSmalldipL}
                                onChangeR={SmallLeftDIPR}
                                onChangeL={SmallLeftDIPL}
                              />
                            </div>
                          </div>

                          <div className="row mb_30">
                            <div className="col-md-6 col-sm-6">
                              <AssessmentDegreeWidget
                                viewValidation="true"
                                title="Active DIP Extension"
                                validationfor="MCPExtension"
                                labelL="Left"
                                labelR="Right"
                                nameR="DIP Right"
                                nameL="DIP Left"
                                valueR={ActSmalldipR}
                                valueL={ActSmalldipL}
                                onChangeR={ActSmallDIPR}
                                onChangeL={ActSmallDIPL}
                              />
                            </div>
                            <div className="col-md-6 col-sm-6">
                              <AssessmentDegreeWidget
                                viewValidation="true"
                                title="Passive DIP Extension"
                                validationfor="MCPExtension"
                                labelL="Left"
                                labelR="Right"
                                nameR="DIP Right"
                                nameL="DIP Left"
                                valueR={PasLeftSmalldipR}
                                valueL={PasLeftSmalldipL}
                                onChangeR={PasSmallLeftDIPR}
                                onChangeL={PasSmallLeftDIPL}
                              />
                            </div>
                          </div>



                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
        {doctorEnable === "true" && (
          <div className="row mb_30 button_box">
            <div className="col-md-6 col-sm-6"></div>
            <div className="col-md-6 col-sm-6 text-end">
            <button
                      className="next_btn btn_fill me-3"
                      onClick={() => handleNext()}
                    >
                      {translation.next_button}
                      <span>
                        <img src={nextIcon} alt="" />
                        {/* <img src={nextWhiteIcon} alt="" /> */}
                      </span>
                    </button>
              <button
                className="next_btn btn_fill"
                onClick={() => handleSubmit()}
              >
                Submit
                <span>
                  <img src={nextIcon} alt="" />
                  {/* <img src={nextWhiteIcon} alt="" /> */}
                </span>
              </button>
            </div>
          </div>
        )}
        {doctorEnable !== "true" && (
          <div className="row mb_30 button_box">
            <div className="col-md-6 col-sm-6">
              {/* <Link to="/" className="assm_back_btn">
            <img src={backIcon} alt="" />
            <span>Back</span>
          </Link> */}
            </div>
            <div className="col-md-6 col-sm-6 text-end">
              <button className="next_btn btn_fill" onClick={continueRedirect}>
                {translation.next_button}
                <span>
                  <img src={nextIcon} alt="" />
                  <img src={nextWhiteIcon} alt="" />
                </span>
              </button>
            </div>
          </div>
        )}
      </div>

      </>
    )}
    </>
  );
}
