import React, { useState, useEffect } from 'react';
import './CustomToaster.css';

function CustomToaster({ msg, msgType }) {
    const [visibility, setVisibility] = useState(false);
    const [message, setMessage] = useState('');
    const [type, setType] = useState('#28a745');

    function showToaster(message) {
        setMessage(message);
        setVisibility(true);
        setTimeout(() => {
            setVisibility(false);
        }, 3000);
    }

    useEffect(() => {
        if (msg !== '')
            showToaster(msg)
        if (msgType === 'success') {
            setType('#28a745')
        } else if (msgType === 'error') {
            setType('#dc3545')

        } else {
            setType('#17a2b8')

        }
    }, [msg]);

    return (
        <div className={`toaster ${visibility ? 'toaster-visible' : 'toaster-hidden'}`} style={{
            backgroundColor: `${type}`,
            boxShadow: `0 0 10px #000`,
            color: ` #fff`
        }}>
            <span className="text-capitalize">{message}</span>
        </div>
    );
}

export default CustomToaster;