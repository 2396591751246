import React, { useState, forwardRef, useImperativeHandle } from "react";
import PropTypes from "prop-types";
import { validateRadioSwitch } from "../../../utilities/Validator";
import useTranslation from "../../customHooks/translations";
const RadioSwitch = forwardRef(
  (
    {
      title,
      labelLeft,
      labelRight,
      idLeft,
      idRight,
      selectedRadio,
      placeholder,
      validators,
      type,
      styleClass,
      onChange,
      formGroupClass,
      value,
      name,
      disabled
    },
    ref
  ) => {
    const translation = useTranslation()
    const [error, setError] = useState(false);
  
    const handleChangeLeft = (event) => {
      let value =
        event.target.type === "radio"
          ? event.target.checked
          : event.target.value;
      let updatedValue = value === true ? "0" : "";
      setError(validateRadioSwitch(validators, value));
      // console.log("--value updatedValue Left--", updatedValue);
      onChange(updatedValue);
    };
    const handleChangeRight = (event) => {
      let value =
        event.target.type === "radio"
          ? event.target.checked
          : event.target.value;
      let updatedValue = value === true ? "1" : "";
      setError(validateRadioSwitch(validators, value));
      // console.log("--value in radio updatedValue right--", updatedValue);
      onChange(updatedValue);
    };
    useImperativeHandle(ref, () => ({
      errUpdate(e) {
        setError(validateRadioSwitch(validators, ""));
        console.log("err", validators, value);
      },
    }));
    // console.log("--value in radio--", value)
    let lang= sessionStorage.getItem("language")
    // lang=="ara"?`areradio`:
    return (
      <div className={`${ lang=="ara"?"areradio":""}`}>
      <div
        className={`form-group custom_radio_switch ${
          value ? "switch_active" : ""
        } ${formGroupClass}`} 
      >
        <div className={`switch-title`}>{title}</div>
        <input
          type="radio"
          id={idLeft}
          name={name}
          value=""
          onChange={handleChangeLeft}
          checked={value === "0"}
          disabled={disabled}
        />
        <label htmlFor={idLeft}>{labelLeft}</label>

        <input
          type="radio"
          id={idRight}
          name={name}
          value=""
          onChange={handleChangeRight}
          checked={value === "1"}
          disabled={disabled}
        />
        <label htmlFor={idRight}>{labelRight}</label>
        {error && <span className="text-danger">{error.message}</span>}
      </div>
      </div>
    );
  }
);

RadioSwitch.propTypes = {
  // valueLeft: PropTypes.string,
  // valueRight: PropTypes.string,
  labelLeft: PropTypes.string,
  labelRight: PropTypes.string,
  placeholder: PropTypes.string,
  validators: PropTypes.array,
  type: PropTypes.string,
};

RadioSwitch.defaultProps = {
  selectedRadio: "",
  idLeft: "",
  idRight: "",
  name: "",
  value: "",
  labelLeft: "No",
  labelRight: "Yes",
  placeholder: "",
  type: "radio",
  validators: [],
};

export default RadioSwitch;
