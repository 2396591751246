import React, { useEffect, useState } from "react";
import Button from "../../custom/button/Button";
import "../../../css/ViewTreatmentForm.css";
import PersonalInformation from "./PersonalInformation";
import PediaKneePain from "./PediaKneePain";
import PediaKneeSymtoms from "./PediaKneeSymtoms";
import PediaKneeFunction from "./PediaKneeFunction";
import PediaKneeQOL from "./PediaKneeQOL";
import PediaKneeSatisfaction from "./PediaKneeSatisfaction";
import { useParams, useNavigate } from "react-router-dom";
import useAxios from "../../../axiosinstance";
import AdultKneeDoctor from "./AdultKneeDoctor";
import LoadingSpinner from "../../custom/loader/LoadingSpinner";
import useTranslation from "../../customHooks/translations";
export default function PediaKneeTreatment() {
  const translation = useTranslation();
  const { rId } = useParams();

  const queryParams = new URLSearchParams(window.location.search);
  // const dId = queryParams.get("doctor");
  const doctor_id = queryParams.get("doctor");
  const pId = queryParams.get("patient");
  const management = queryParams.get("management");
  const pScore = queryParams.get("form");
  const action = queryParams.get("action");
  const patientEdit = queryParams.get("patientEdit");
  const [editShow1, seteditShow1] = useState(false);
  const navigate = useNavigate();
  let isdoctorForm = queryParams.get("is_doctor_form");
  let orgid=sessionStorage.getItem("orgid")
  useEffect(() => {
    localStorage.setItem("isdoctorForm", isdoctorForm)
  }, [isdoctorForm])
  const axiosInstance = useAxios();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
  const [AllData, setAllData] = useState("");
  const [profileData, setProfileData] = useState({});
  const [pediaPain, setPediaPain] = useState({});
  const [pediaFunction, setPediaFunction] = useState({});
  const [pediaSymptoms, setPediaSymptoms] = useState({});
  const [pediaQOL, setPediaQOL] = useState({});
  const [pediaSatisfaction, setPediaSatisfaction] = useState({});
  const [adultNorwich, setAdultNorwich] = useState({});
  const [adultDoctorScore, setAdultDoctorScore] = useState({});
  const [doctorView, setdoctorView] = useState(false);

  const [editShow, seteditShow] = useState(false);
  const [viewDoctor, setViewDoctor] = useState("0");
  const [viewPatient, setViewPatient] = useState("0");
  const [initialAssesment, setInitialAssesment] = useState(0);

  const [displayConfirmationModalDelete, setDisplayConfirmationModalDelete] =
    useState(false);
  const save = () => {
    axiosInstance.current
      .get(
        `extapp/forms/all_forms?resource_id=${rId}&type=20&akpt=${window.atob(
          pId
        )}`
      )
      .then((res) => {
        setInitialAssesment(res.data.data2[0].initial_assessment);
        setAllData(res.data.data2[0]);
        setProfileData(res.data.data1);
        setPediaPain(res.data.data2[0].painScore[0]);
        setPediaFunction(res.data.data2[0].functionScore[0]);
        setPediaSymptoms(res.data.data2[0].symtomsScore[0]);
        setPediaQOL(res.data.data2[0].qolScore[0]);
        setPediaSatisfaction(res.data.data2[0].satisfactionScore[0]);
        setAdultNorwich(res.data.data2[0].norwichInstability[0]);
        seteditShow(res.data.status);
        setdoctorView(res.data.doctorStatus);
        seteditShow1(res.data.doctorStatus);
        setAdultDoctorScore(res.data.data2[0].doctorFeedback[0]);
        setViewDoctor(res.data.data2[0].doctor_scoring_date);
        setViewPatient(res.data.data2[0].patient_scoring_date);
        setIsLoading(false);
      }).catch((err) => {

        navigate('/un-authorized-access');

      });
  };
  useEffect(() => {
    save();
  }, []);
  console.log('====================================');
  console.log(adultDoctorScore, "dfgfghf");
  console.log('====================================');
  const profileAllData = React.useMemo(() => profileData, [profileData]);
  const handleEdit = () => {
    navigate(
      `/personal-data-edit/${
        pId
      }/${window.btoa(doctor_id)}/${window.btoa(orgid)}/${window.btoa(
        20
      )}?is_doctor_form=true&doctorvalue=${0}&doctor=true&resource_id=${rId}`
    );
    // navigate("/pedia-knee-pain/" + rId + "?d=true&akpt=" + pId + "&is_doctor_form=true");
  };
  const handlePatientEdit = () => {
    navigate("/pedia-knee-pain/" + rId + "?is_doctor_form=null&edit=true");
  };

  const handleBack = () => {
    window.open(process.env.REACT_APP_DOCTOR_URL + 'patient-view' + "/" + pId);
    window.close();
  };

  const handleEdit1 = () => {
    // localStorage.setItem("doctor",doctor_id)
    // navigate("/elbow-pain/" + rId + "?d=true&akpt=" + pId+"&is_doctor_form=true");

    window.open(
      "/knee-feedback/" +
      rId +
      `?doctor=${doctor_id}&patient=${pId}&is_doctor_form=true`
    );
  };

  const hideConfirmationModal = () => {
    setDisplayConfirmationModalDelete(false);
  };
  const submitDelete = (type, e) => {
    handleDelByrID();
  };
  const handleDelByrID = async () => {
    try {
      const result = await axiosInstance.current.patch(
        `extapp/forms/soft_delete_form?resource_id=${rId}`
      );
      if (result.status === 200) {
        window.close();
      }
    } catch (err) {
      console.log(err.message);
    } finally {
      console.log("api response finally");
    }
  };
  const handleDelete = (e, te) => {
    setDisplayConfirmationModalDelete(true);
  };

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="view_treatment_form">
          <div className="row page_header">
            <div className="col-sm-6 col-md-6">
              <h4>{translation.Paediatric_Knee_Treatment_Form}</h4>
            </div>
            {management === "true" && (
              <div className={`col-sm-6 col-md-6 text-end`}>
                <Button
                  onClick={() => handleDelete()}
                  value="Delete Form"
                  buttonStyle="btn_fill"
                />
                {editShow || doctorView ? (
                  <Button
                    onClick={() => handleEdit()}
                    value="Edit Form"
                    buttonStyle="btn_fill ml_15 "
                  />
                ) : (
                  <Button
                    onClick={() => console.log("")}
                    value="Edit Form"
                    buttonStyle=" ml_15 button_disabled "
                  />
                )}
              </div>
            )}

            {isdoctorForm && management !== "true"&&(
              <div className={`col-sm-6 col-md-6 text-end`}>
                <Button
                  onClick={() => handleBack()}
                  value="Back"
                  buttonStyle="btn_fill ml_15 "
                />

                {editShow === true && (
                  <Button
                    onClick={() => handleEdit1()}
                    value="Edit Form"
                    buttonStyle="btn_fill ml_15 "
                  />
                )}
              </div>
            )}

            {patientEdit === "true" && (
              <div className={`col-sm-6 col-md-6 text-end`}>
                {editShow && (
                  <Button
                    onClick={() => handlePatientEdit()}
                    value="Edit Form"
                    buttonStyle="btn_fill ml_15 "
                  />
                )}
              </div>
            )}
          </div>
          {pScore === "true" && action === "1" && (
            <>
              <PersonalInformation
                profileAllData={profileAllData}
                AllData={AllData}
              />
              {viewPatient === "0" ? (
                <div className="no_data">Patient form is not filled yet </div>
              ) : (
                <>
                  <PediaKneePain pediaPain={pediaPain} AllData={AllData} profileAllData={profileAllData} />
                  <PediaKneeSymtoms
                    pediaSymptoms={pediaSymptoms}
                    AllData={AllData}
                  />
                  <PediaKneeFunction
                    pediaFunction={pediaFunction}
                    AllData={AllData}
                    adultNorwich={adultNorwich}
                  />
                  <PediaKneeQOL pediaQOL={pediaQOL} AllData={AllData} />
              
                    <PediaKneeSatisfaction
                      pediaSatisfaction={pediaSatisfaction}
                      AllData={AllData}
                    />
                 
                </>
              )}
            </>
          )}
          {pScore === "true" && action === "2" && (
            <AdultKneeDoctor
              doctorFeedback={adultDoctorScore}
              AllData={AllData}
              profileAllData={profileAllData} 
            />
          )}

          {management === "true" && (
            <>
              <>
                <PersonalInformation
                  profileAllData={profileAllData}
                  AllData={AllData}
                />
                   {viewPatient === "0" ? (
                <div className="no_data">Patient form is not filled yet </div>
              ) : (
                <>
                  <PediaKneePain pediaPain={pediaPain} AllData={AllData}  profileAllData={profileAllData} />
                  <PediaKneeSymtoms
                    pediaSymptoms={pediaSymptoms}
                    AllData={AllData}
                  />
                  <PediaKneeFunction
                    pediaFunction={pediaFunction}
                    AllData={AllData}
                    adultNorwich={adultNorwich}
                  />
                  <PediaKneeQOL pediaQOL={pediaQOL} AllData={AllData} />
                  
                    <PediaKneeSatisfaction
                      pediaSatisfaction={pediaSatisfaction}
                      AllData={AllData}
                    />
                
                </>
              )}
              </>
            </>
          )}
          {viewDoctor !== "0" && management === "true" && (
            <>
              <AdultKneeDoctor
                doctorFeedback={adultDoctorScore}
                AllData={AllData}
                profileAllData={profileAllData} 
              />
            </>
          )}
        </div>
      )}
    </>
  );
}
