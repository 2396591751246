export default {
  AppVersion: "App Version",
  Complete_personal_info: "Complete personal info",
  To_complete_the_sign_up:
    "To complete the sign up please fill in your info below.",
  Select_Existing_Profile: "Select Existing Profile",
  Get_In: "Enter",
  Create_new_patient: "Create a new patient",
  myProfile: "My Profile",
  First_Name: "First Name*",
  Last_Name: "Last Name*",
  DOB: "Date of Birth*",
  Sex: "Sex*",
  Address: "Address*",
  Postcode: "Postcode*",
  Suburb: "Suburb*",
  Country_Code: "Country Code*",
  Mobile_Phone: "Mobile Phone*",
  Home_Phone: "Home Phone*",
  Email: "Email*",
  Emergency_Contact: "Emergency Contact*",
  Phone: "Phone*",
  Relationship: "Relationship*",
  Regular_GP_Name: "Regular GP Name*",
  Practice_Name: "Practice Name*",
  Medicare_Card_Number: "Medicare Card Number",
  Ref: "Ref",
  Message: "Messages",
  Expiry: "Expiry",
  Private_Health_Fund: "Private Health Fund",
  Membership_Number: "Membership Number",
  Claim_Type: "Claim Type",
  Work_Cover: "Work Cover",
  Employer: "Employer",
  Third_Party_Claim_Number: "Third Party Claim Number",
  Third_Party_Name: "Third Party Name",
  DVA_Concession_Card: "DVA Concession Card",
  Number: "Number",
  Defence_Personnel: "Defence Personnel",
  I_agree: "I agree to the processing of my personal data and have read the",
  Privacy_Policy: "Privacy Policy.",
  visitClinic: "Book Appointment",
  selectdocOrg: "Select Doctor & Organization*",
  doctorOrganization: 'Doctor Organization"',
  Select_Treatment_Category: "Select Treatment Category*",
  Do_you_want_to_submit_patient_form_or_not:
    "Do you want to submit patient form or not?",
  areyousurewanttocontinueyourform:
    "Your previous form is pending. Do you want to continue ?",
  myVisit: "Past Visits",
  View_score: "View score",
  upcoming_visits: "Upcoming",
  No_data_found: "No data found",
  Doctor_Name: "Doctor Name",
  Appointment_Date_Time: "Appointment Date & Time",
  Clinic_Name: "Clinic Name",
  Clinic_Address: "Clinic Address",
  akmed: "Akunah Med",
  Type: "Type",
  Date_Time: "Date & Time",
  Action: "Action",
  View: "View",
  No_Messages: "No Messages",
  settings: "Settings",
  Language: "Language",
  language: "Language & Region",
  timezone: "Timezone",
  Timeformat: "Time format",
  List_of_my_profile: "My Profiles",
  next: "Next",
  back: "Back",
  Continue: "Continue",
  Close: "Close",
  submit: "Submit",
  Sign_out: "Back to profile",
  Results: "Results",
  update: "Form updated successfully",
  Personal_Data: "Personal Data",
  Date_of_Birth: "Date of Birth",
  Hip_arthritis: "Hip arthritis ?*",
  Area_of_foot_ankle: "Area of foot/ankle affected?*",
  Diagnosis: "Diagnosis ?",
  Evaluated_Shoulder: "Evaluated Shoulder",
  Dominant_Hand: "Dominant Hand*",
  Age: "Age",
  No: "No",
  Yes: "Yes",
  visitNo: "visitNo",
  visitYes: "visitYes",
  shoulderPainR: "shoulderPainR",
  Is_it_initial_visit: "Is it an initial visit for the",
  for_the_problem: "for the problem ?*",
  Diagnosis_Procedure: "Diagnosis / Procedure",
  Procedure: "Procedure",
  Evaluated_sdfs: "Evaluated *",
  Please_Select_previous_visits: "Please Select previous visits*",
  Follow_Up: "Follow Up",
  Assessment_Date: "Assessment Date",
  Do_you_have_Dupuytren_disease: "Do you have Dupuytren's disease?",
  Do_you_have_carpal_tunnel_syndrome: "Do you have carpal tunnel syndrome?",
  Is_your_unaffected_shoulder_pain:
    "Is your unaffected shoulder pain-free and allows normal function?",
  Is_your_unaffected: "Is your unaffected",
  Adult_Knee_Pain: "Adult Knee Pain",
  Knee_pain_13_18_year: "Knee Pain",
  Highest_level_pain_Left:
    "Highest level of activity that you can perform without significant knee pain (Left)",
  Highest_level_pain:
    "Highest level of activity that you can perform without significant knee pain",
  Highest_level_pain_Right:
    "Highest level of activity that you can perform without significant knee pain (Right)",
  How_often_pain_Left: "How often have you had pain? (Left)",
  How_often_pain: "How often have you had pain?",
  How_often_pain_Right: "How often have you had pain? (Right)",
  Pain_intensity_Left: "Pain intensity/What causes pain (Left)",
  Pain_intensity: "Pain intensity/What causes pain",
  Pain_intensity_Right: "Pain intensity/What causes pain (Right)",
  If_you_have_Left: "If you have pain how severe is it? (Left)",
  If_you_have: "If you have pain how severe is it ?",
  If_you_have_Right: "If you have pain how severe is it? (Right)",
  How_much_has_pain_Left:
    "How much has pain from your knee interfered with your usual work (including housework)? (Left)",
  How_much_has_pain:
    "How much has pain from your knee interfered with your usual work (including housework)?",
  How_much_has_pain_Right:
    "How much has pain from your knee interfered with your usual work (including housework)? (Right)",
  What_amount_of_knee_pain_have_you_experienced_during_activities:
    "What amount of KNEE PAIN have you experienced during the following activities",
  Twisting_pivoting_knee_L: "Twisting/pivoting knee (Left)",
  Twisting_pivoting_knee: "Twisting/pivoting knee",
  Twisting_pivoting_knee_R: "Twisting/pivoting knee (Right)",
  Straightening_knee_fully_R: "Straightening knee fully (Right)",
  Straightening_knee_fully: "Straightening knee fully",
  Straightening_knee_fully_L: "Straightening knee fully (Left)",
  Bending_knee_fully_R: "Bending knee fully (Right)",
  Bending_knee_fully: "Bending knee fully",
  Bending_knee_fully_L: "Bending knee fully (Left)",
  Walking_on_flat_surface_R: "Walking on flat surface (Right)",
  Walking_on_flat_surface_L: "Walking on flat surface (Left)",
  How_long_can_Right:
    "How long can you walk before the pain from your knee becomes severe? (with or without stick) (Right)",
  How_long_can:
    "How long can you walk before the pain from your knee becomes severe? (with or without stick)",
  How_long_can_Left:
    "How long can you walk before the pain from your knee becomes severe? (with or without stick) (Left)",
  Going_up_or_down_R: "Going up or down stairs (Right)",
  Going_up_or_down: "Going up or down stairs",
  Going_up_or_down_L: "Going up or down stairs (Left)",
  At_night_while_bed_R: "At night while in bed (Right)",
  At_night_while_bed: "At night while in bed",
  At_night_while_bed_L: "At night while in bed (Left)",
  How_often_night_R:
    "How often have you been troubled by pain from your knee in bed at night? (Right)",
  How_often_night:
    "How often have you been troubled by pain from your knee in bed at night?",
  How_often_night_L:
    "How often have you been troubled by pain from your knee in bed at night? (Left)",
  Sitting_or_lying_R: "Sitting or lying (Right)",
  Sitting_or_lying: "Sitting or lying",
  Sitting_or_lying_L: "Sitting or lying (Left)",
  Standing_upright_R: "Standing upright (Right)",
  Standing_upright: "Standing upright",
  Standing_upright_L: "Standing upright (Left)",
  Standing_from_chair_L: "Standing up from a chair (Left)",
  Standing_from_chair: "Standing up from a chair",
  Standing_from_chair_R: "Standing up from a chair (Right)",
  Adult_Knee_Symptoms: "Other Symptoms",
  Swelling_R: "Swelling (Right)",
  Swelling: "Swelling",
  Swelling_L: "Swelling (Left)",
  Grinding_clicking_R:
    "Grinding, clicking, or any other type of noise when your knee moves (Right)",
  Grinding_clicking:
    "Grinding, clicking, or any other type of noise when your knee moves",
  Grinding_clicking_L:
    "Grinding, clicking, or any other type of noise when your knee moves (Left)",
  Catching_or_locking_R: "Catching or locking (Right)",
  Catching_or_locking: "Catching or locking",
  Catching_or_locking_L: "Catching or locking (Left)",
  Limping_R: "Limping (Right)",
  Limping_L: "Limping (Left)",
  During_the_past_4_weeks_or_since_your_knee_injury_how_stiff_or_swollen_was_your_knee_R:
    "During the past 4 weeks or since your knee injury, how stiff or swollen was your knee? (Right)",
  During_the_past_4_weeks_or_since_your_knee_injury_how_stiff_or_swollen_was_your_knee:
    "During the past 4 weeks or since your knee injury, how stiff or swollen was your knee?",
  During_the_past_4_weeks_or_since_your_knee_injury_how_stiff_or_swollen_was_your_knee_L:
    "During the past 4 weeks or since your knee injury, how stiff or swollen was your knee? (Left)",
  Highest_level_activity_R:
    "Highest level of activity that you can perform without significant SWELLING in your knee? (Right)",
  Highest_level_activity:
    "Highest level of activity that you can perform without significant SWELLING in your knee?",
  Highest_level_activity_L:
    "Highest level of activity that you can perform without significant SWELLING in your knee? (Left)",
  How_severe_is_your_knee_joint_stiffness:
    "How severe is your knee joint STIFFNESS",
  After_first_awakening_morning_R:
    "After first awakening in the morning? (Right)",
  After_first_awakening_morning: "After first awakening in the morning?",
  After_first_awakening_morning_L:
    "After first awakening in the morning? (Left)",
  After_sitting_lying_day_R:
    "After sitting lying or resting later in the day? (Right)",
  After_sitting_lying_day: "After sitting lying or resting later in the day?",
  After_sitting_lying_day_L:
    "After sitting lying or resting later in the day? (Left)",
  Instability_Right:
    "What is the highest level of activity that you can perform without significant GIVING WAY in your knee? (Right)",
  Instability:
    "What is the highest level of activity that you can perform without significant GIVING WAY in your knee?",
  Instability_Left:
    "What is the highest level of activity that you can perform without significant GIVING WAY in your knee? (Left)",
  highest_level_of_activity_giving_knee:
    "What is the highest level of activity that you can perform without significant giving way in your knee?",
  highest_level_of_activity_giving_knee_R:
    "What is the highest level of activity that you can perform without significant giving way in your knee? (Right)",
  highest_level_of_activity_giving_knee_L:
    "What is the highest level of activity that you can perform without significant giving way in your knee? (Left)",
  SupporT_Right: "Do you use any SUPPORT for walking? (Right)",
  SupporT: "Do you use any SUPPORT for walking?",
  SupporT_Left: "Do you use any SUPPORT for walking? (Left)",
  Do_you_use_any_assistive_devices_R:
    "Do you use any assistive devices ? (Right)",
  Do_you_use_any_assistive_devices: "Do you use any assistive devices ?",
  Do_you_use_any_assistive_devices_L:
    "Do you use any assistive devices ?(Left)",
  Front: "Front",
  Adult_Knee_Function: "Function",
  How_does_your_knee_affect_your_ability_to:
    "How does your knee affect your ability to",
  no_difficulty: "no difficulty",
  minimal_difficulty: "minimal difficulty",
  moderate_difficulty: "moderate difficulty",
  extreme_difficult: "extreme difficult",
  unable_to_do: "unable to do",
  Go_up_stairs: "Go up stairs",
  Go_down_stairs: "Go down stairs",
  Kneel_on_the_front: "Kneel on the front of your knee",
  Straightening_your_knee: "Straightening your knee",
  Bending_your_knee: "Bending your knee",
  Squat: "Squat",
  Sit_with_your_knee_bent: "Sit with your knee bent",
  Rise_from_a_chair: "Rise from a chair",
  Rise_from_bed: "Rise from bed",
  Lying_in_bed: "Lying in bed (turning over maintaining knee position)",
  Bending_floor_up_an_object: "Bending to floor/pick up an object",
  Putting_on_socks_stockings: "Putting on socks/stockings",
  Taking_off_socks_stockings: "Taking off socks/stockings",
  Walking: "Walking",
  Run_straight_ahead: "Run straight ahead",
  Jump_land_your_involved: "Jump and land on your involved leg",
  Stop_start_quickly: "Stop and start quickly",
  Twisting_pivoting_injured_knee: "Twisting/pivoting on your injured knee",
  Getting_using_public_transport:
    "Getting in and out of car or using public transport",
  Getting_and_out_bath: "Getting in and out of bath",
  Getting_and_out_toilet: "Getting in and out of toilet",
  Washing_drying_yourself: "Washing and drying yourself",
  Household_shopping: "Household shopping",
  Light_domestic_duties: "Light domestic duties (cooking, dusting, etc)",
  How_would_you_rate_R_new:
    "How would you rate the CURRENT function of your knee on a scale of 0-10? (Right)",
  How_would_you_rate_new:
    "How would you rate the CURRENT function of your knee on a scale of 0-10?",
  How_would_you_rate_L_new:
    "How would you rate the CURRENT function of your knee on a scale of 0-10? (Left)",
  Highest_level_of_activity_R:
    "Highest level of activity that you can participate in on a regular basis (Right)",
  Highest_level_activity_Left:
    "Highest level of activity that you can participate in on a regular basis (Left)",
  Prior_to: "Prior to knee injury/knee problem",
  current_status: "Current status",
  Kneecap_getting_out_of_place_or_feels_like_it_is_getting_out_of_place_R:
    "Does your kneecap get out of place or does it feel like it is getting out of place?(Right)",
  Kneecap_getting_out_of_place_or_feels_like_it_is_getting_out_of_place:
    "Does your kneecap get out of place or does it feel like it is getting out of place?",
  Kneecap_getting_out_of_place_or_feels_like_it_is_getting_out_of_place_L:
    "Does your kneecap get out of place or does it feel like it is getting out of place?(Left)",
  never: "never",
  rarely: "rarely",
  sometimes: "sometimes",
  often: "often",
  always: "always",
  Do_not_do: "Do not do",
  Patellofemoral_Instability: "Patellofemoral Instability",
  What_best_describesWILLJOINT_UNSTABLE_activities:
    "What best describes HOW OFTEN YOUR KNEE CAP FEELS LIKE IT WILL “POP OUT” OF JOINT OR FEELS UNSTABLE when you are doing each of the following activities",
  Please_answer_Knee_ctivities:
    "Please answer the following questions relating to your Knee activities",
  Twisting_direction_during_games:
    "Twisting/changing direction during sports/games",
  Twisting_direction_games_Right:
    "Twisting/changing direction during sports/games (Right)",
  Twisting_direction_games: "Twisting/changing direction during sports/games",
  Twisting_direction_games_Left:
    "Twisting/changing direction during sports/games (Left)",
  Changing_direction_running: "Changing direction when running",
  Changing_direction_running_Right: "Changing direction when running (Right)",
  Changing_direction_running_Left: "Changing direction when running (Left)",
  Running_straight_line_surfaces:
    "Running in a straight line on uneven surfaces",
  Running_straight_surfaces_R:
    "Running in a straight line on uneven surfaces (Right)",
  Running_straight_surfaces: "Running in a straight line on uneven surfaces",
  Running_straight_surfaces_L:
    "Running in a straight line on uneven surfaces (Left)",
  Walking_slippery_icy_surfaces: "Walking on slippery wet or icy surfaces",
  Walking_slippery_surfaces_Right:
    "Walking on slippery wet or icy surfaces (Right)",
  Walking_slippery_surfaces: "Walking on slippery wet or icy surfaces",
  Walking_slippery_surfaces_Left:
    "Walking on slippery wet or icy surfaces (Left)",
  Running_sideways: "Running sideways",
  Running_sideways_R: "Running sideways (Right)",
  Running_sideways_L: "Running sideways (Left)",
  Hopping: "Hopping",
  Hopping_R: "Hopping (Right)",
  Hopping_L: "Hopping (Left)",
  Jumping: "Jumping",
  Jumping_Right: "Jumping (Right)",
  Jumping_Left: "Jumping (Left)",
  Running_straight_e_line_surface: "Running in a straight line on even surface",
  Running_straight_line_R: "Running in a straight line on even surface (Right)",
  Running_straight_line: "Running in a straight line on even surface",
  Running_straight_line_L: "Running in a straight line on even surface (Left)",
  Going_down_stairs: "Going down stairs",
  Going_down_stairs_Right: "Going down stairs (Right)",
  Going_down_stairs_Left: "Going down stairs (Left)",
  Squatting: "Squatting",
  Squatting_R: "Squatting (Right)",
  Squatting_L: "Squatting (Left)",
  Kneeling: "Kneeling",
  Kneeling_Right: "Kneeling (Right)",
  Kneeling_Left: "Kneeling (Left)",
  Walking_straight_line_uneven_surfaces:
    "Walking in a straight line on uneven surfaces",
  Walking_straight_line_R:
    "Walking in a straight line on uneven surfaces (Right)",
  Walking_straight_line: "Walking in a straight line on uneven surfaces",
  Walking_straight_line_L:
    "Walking in a straight line on uneven surfaces (Left)",
  Climbing_stairs: "Climbing stairs",
  Climbing_stairs_R: "Climbing stairs (Right)",
  Climbing_stairs_L: "Climbing stairs (Left)",
  Stepping_into_highstep: "Stepping onto or over a high step",
  Stepping_into: "Stepping onto or over a high step",
  Stepping_into_L: "Stepping onto or over a high step (Left)",
  Crossing_legs_sitting: "Crossing your legs when sitting",
  Crossing_your_legs_R: "Crossing your legs when sitting (Right)",
  Crossing_your_legs: "Crossing your legs when sitting",
  Crossing_your_legs_L: "Crossing your legs when sitting (Left)",
  Walking_straight_surface: "Walking in a straight line on even surface",
  Walking_straight_on_even_R:
    "Walking in a straight line on even surface (Right)",
  Walking_straight_on_even: "Walking in a straight line on even surface",
  Walking_straight_on_even_L:
    "Walking in a straight line on even surface (Left)",
  Getting_into_car: "Getting into or out of a car",
  Getting_into_R: "Getting into or out of a car (Right)",
  Getting_into: "Getting into or out of a car",
  Getting_into_L: "Getting into or out of a car (Left)",
  Turning_supermarket_aisle:
    "Turning a heavy trolley round a supermarket aisle",
  Turning_heavy_R: "Turning a heavy trolley round a supermarket aisle (Right)",
  Turning_heavy: "Turning a heavy trolley round a supermarket aisle",
  Turning_heavy_L: "Turning a heavy trolley round a supermarket aisle (Left)",
  Turning_look_shoulder: "Turning to look over your shoulder",
  Turning_shoulder_R: "Turning to look over your shoulder (Right)",
  Turning_shoulder: "Turning to look over your shoulder",
  Turning_shoulder_L: "Turning to look over your shoulder (Left)",
  How_often_knee_problem_R:
    "How often are you aware of your knee problem? (Right)",
  How_often_knee_problem: "How often are you aware of your knee problem?",
  How_often_knee_problem_L:
    "How often are you aware of your knee problem? (Left)",
  Have_modified_your_lifestyle_R:
    "Have you modified your lifestyle to avoid potentially damaging activities to your knee? (Right)",
  Have_modified_your_lifestyle:
    "Have you modified your lifestyle to avoid potentially damaging activities to your knee?",
  Have_modified_your_lifestyle_L:
    "Have you modified your lifestyle to avoid potentially damaging activities to your knee? (Left)",
  How_confidence_knee_R:
    "How much are you troubled with the lack of confidence in your knee? (Right)",
  How_confidence_knee:
    "How much are you troubled with the lack of confidence in your knee?",
  How_confidence_knee_L:
    "How much are you troubled with the lack of confidence in your knee? (Left)",
  In_general_difficulty_knee_R:
    "In general how much difficulty do you have with your knee? (Right)",
  In_general_difficulty_knee:
    "In general how much difficulty do you have with your knee?",
  In_general_difficulty_knee_L:
    "In general how much difficulty do you have with your knee? (Left)",
  How_bad_health_today_new:
    "How good/bad is your health today? 100 means best health 0 means worst health you can imagine?",
  How_bad_health_today_new_R:
    "How good/bad is your health today? 100 means best health 0 means worst health you can imagine? (Right)",
  How_bad_health_todayL_new_L:
    "How good/bad is your health today? 100 means best health 0 means worst health you can imagine? (Left)",
  How_depressed_today: "How anxious/depressed are you today?",
  How_bad_health_today: "How good/bad is your health today?",
  How_bad_health_todayR: "How good/bad is your health today? (Right)",
  How_bad_health_todayL: "How good/bad is your health today? (Left)",
  How_good_bad_is_your_health_today:
    "How good/bad is your health today? (100 means best health, 0 means worst health you can imagine)",
  next_btn_btn_fill: "next_btn btn_fill",
  Adult_Knee_Satisfaction: "Satisfaction",
  VERY_SATISFIED: "VERY SATISFIED",
  somewhat_satisfied: "SOMEWHAT SATISFIED",
  somewhat_dissatisfied: "SOMEWHAT DISSATISFIED",
  very_dissatisfied: "VERY DISSATISFIED",
  very_dissatisfied: "very dissatisfied",
  somewhat_dissatisfied: "somewhat dissatisfied",
  somewhat_satisfied: "somewhat satisfied",
  Overall_Left: "Overall (Left)",
  Overall: "Overall",
  Overall_Right: "Overall (Right)",
  dissatisfied: "dissatisfied",
  For_improving_pain_R: "For improving your pain? (Right)",
  For_improving_pain: "For improving your pain?",
  For_improving_pain_L: "For improving your pain? (Left)",
  For_improving_R:
    "For improving your ability to do home or yard work? (Right)",
  For_improving: "For improving your ability to do home or yard work?",
  For_improving_L: "For improving your ability to do home or yard work? (Left)",
  improving_recreational_activities_R:
    "For improving your ability to do recreational activities? (Right)",
  improving_recreational_activities:
    "For improving your ability to do recreational activities?",
  improving_recreational_activities_L:
    "For improving your ability to do recreational activities? (Left)",
  Adult_Knee_Results: "Adult Knee Results",
  Below_your_pain:
    "Below is your pain and shoulder function score which is using your",
  answer_self_evaluation: "answers from the knee scoring self-evaluation.",
  Your_Doctor_shortly: "Your Doctor will be with you shortly.",
  IKDC_Score: "IKDC Score",
  Knee: "Knee",
  Right_Knee: "Right Knee",
  Left_Knee: "Left Knee",
  KOOS_Pain: "KOOS Pain",
  KOOS_Symptoms: "KOOS Symptoms",
  KOOS_ADL: "KOOS ADL",
  KOOS_Sports: "KOOS Sports",
  Quality_of_Life: "KOOS-Quality of Life",
  CKRS_Score: "CKRS Score",
  LYSHOLM: "LYSHOLM",
  OKS_Pain: "OKS Pain",
  OKS_Function: "OKS Function",
  OKS_overall: "OKS OverAll",
  WOMAC_Pain: "WOMAC Pain",
  WOMAC_Stiffness: "WOMAC Stiffness",
  WOMAC_Function: "WOMAC Function",
  KSS: "KSS",
  Pedia_Knee_Pain: "Paediatric Knee Pain",
  no_pain: "no pain",
  a_little_pain: "a little pain",
  little_pain: "little pain",
  a: "a",
  note: "Note",
  extreme_pain: "extreme pain",
  some_pain: "some pain",
  a_lot_of_pain: "a lot of pain",
  extreme_pain: "Extreme pain",
  During_past_4_weeks_R:
    "During the past 4 weeks or since your injury how much of the time did your injured knee hurt? (Right)",
  During_past_4_weeks:
    "During the past 4 weeks or since your injury how much of the time did your injured knee hurt?",
  During_past_4_weeks_L:
    "During the past 4 weeks or since your injury how much of the time did your injured knee hurt? (Left)",
  How_hurt_today_R: "How badly does your injured knee hurt today? (Right)",
  How_hurt_today: "How badly does your injured knee hurt today?",
  How_hurt_today_L: "How badly does your injured knee hurt today? (Left)",
  InguinalOther: "Others",
  How_knee_pain_activities:
    "How much knee pain have you experienced in the past 7 days during the following activities?",
  Please_answer_Knee_activities:
    "Please answer the following questions relating to your Knee activities.",
  Fully_straightening_knee_Right: "Fully straightening your knee (Right)",
  Fully_straightening_knee: "Fully straightening your knee",
  Fully_straightening_knee_Left: "Fully straightening your knee (Left)",
  Fully_bending_knee_Right: "Fully bending your knee (Right)",
  Fully_bending_knee: "Fully bending your knee",
  Fully_bending_knee_Left: "Fully bending your knee (Left)",
  Walking_stairs_Right: "Walking up stairs (Right)",
  Walking_stairs: "Walking up stairs",
  Walking_stairs_Left: "Walking up stairs (Left)",
  Walking_down_stairs_R: "Walking down stairs (Right)",
  Walking_down_stairs: "Walking down stairs",
  Walking_down_stairs_L: "Walking down stairs (Left)",
  Sitting_with_R: "Sitting with your knee bent (Right)",
  Sitting_with: "Sitting with your knee bent",
  Sitting_with_L: "Sitting with your knee bent (Left)",
  Worst_unbearable_pain: "Worst Possible unbearable excruciating pain",
  Worst_Pain: "Worst Pain",
  Never_hurt: "Never hurt",
  All_the_time: "All the time",
  Very_hard_activities: "Very hard activities",
  Hard_activities: "Hard activities",
  Sort_hard_activities: "Sort of hard activities",
  Light_activities: "Light activities",
  I_any_the_above: "I can’t do any of the above",
  How_have_you_had_following: "How often have you had the following",
  Please_answer_questions_relating_Knee_activities:
    "Please answer the following questions relating to your Knee activities",
  Knee_making_sounds_Right: "Knee making any noise/sounds (Right)",
  Knee_making_sounds: "Knee making any noise/sounds",
  Knee_making_sounds_Left: "Knee making any noise/sounds (Left)",
  Knee_getting_stuck_locked_R: "Knee getting stuck or locked in place (Right)",
  Knee_getting_stuck_locked: "Knee getting stuck or locked in place",
  Knee_getting_stuck_locked_L: "Knee getting stuck or locked in place (Left)",
  Feeling_knee_stuck_still_R:
    "Feeling like the knee is getting stuck (catching) but you could still move it (Right)",
  Feeling_knee_stuck_still:
    "Feeling like the knee is getting stuck (catching) but you could still move it",
  Feeling_knee_stuck_still_L:
    "Feeling like the knee is getting stuck (catching) but you could still move it (Left)",
  Swelling_puffy_knee_R: "Swelling/puffy knee (Right)",
  Swelling_puffy_knee: "Swelling/puffy knee",
  Swelling_puffy_knee_L: "Swelling/puffy knee (Left)",
  How_PUFFY_SWOLLEN_knee_R: "How PUFFY (OR SWOLLEN) was your knee? (Right)",
  How_PUFFY_SWOLLEN_knee: "How PUFFY (OR SWOLLEN) was your knee?",
  How_PUFFY_SWOLLEN_knee_L: "How PUFFY (OR SWOLLEN) was your knee? (Left)",
  If_you_asked_activities:
    "If you were asked to do the activities below what is the most you could do",
  Very_ctivities_change_direction:
    "Very hard activities like jumping or turning fast to change direction like in basketball or soccer",
  Hard_activities_skiing_or_tennis:
    "Hard activities like heavy lifting skiing or tennis",
  Sort_activities_walkingfast_jogging:
    "Sort of hard activities like walking fast or jogging",
  Light_activities_walking_normal_speed:
    "Light activities like walking at a normal speed",
  I_do_activities_listed: "I can’t do any of the activities listed above",
  Without_your_knee_HURT_R: "Without making your knee HURT A LOT (Right)",
  Without_your_knee_HURT: "Without making your knee HURT A LOT",
  Without_your_knee_HURT_L: "Without making your knee HURT A LOT (Left)",
  Without_knee_PUFFY_SWOLLEN_R:
    "Without making your knee PUFFY (OR SWOLLEN) (Right)",
  Without_knee_PUFFY_SWOLLEN: "Without making your knee PUFFY (OR SWOLLEN)",
  Without_knee_PUFFY_SWOLLEN_L:
    "Without making your knee PUFFY (OR SWOLLEN) (Left)",
  Without_knee_FEELING_HOLD_R:
    "Without your knee FEELING LIKE IT CAN’T HOLD YOU UP (Right)",
  Without_knee_FEELING_HOLD:
    "Without your knee FEELING LIKE IT CAN’T HOLD YOU UP",
  Without_knee_FEELING_HOLD_L:
    "Without your knee FEELING LIKE IT CAN’T HOLD YOU UP (Left)",
  Most_time_R: "Most of the time (Right)",
  Most_time: "Most of the time",
  Most_time_L: "Most of the time (Left)",
  a_little: "a little",
  some: "some",
  a_lot: "a lot",
  How_much_difficulty_doing_following:
    "How much difficulty have you had doing the following",
  Please_relating_Knee_activities:
    "Please answer the following questions relating to your Knee activities",
  Go_up_stairs_R: "Go up stairs (Right)",
  Go_up_stairs_L: "Go up stairs (Left)",
  Go_down_stairs_R: "Go down stairs (Right)",
  Go_down_stairs_L: "Go down stairs (Left)",
  Kneel_front_your_R: "Kneel on the front of your knee (Right)",
  Kneel_front_your: "Kneel on the front of your knee",
  Kneel_front_your_L: "Kneel on the front of your knee (Left)",
  Fully_straighten_your_knee_R: "Fully straighten your knee (Right)",
  Fully_straighten_your_knee: "Fully straighten your knee",
  Fully_straighten_your_knee_L: "Fully straighten your knee (Left)",
  Fully_your_knee_R: "Fully bend your knee (Right)",
  Fully_your_knee: "Fully bend your knee",
  Fully_your_knee_L: "Fully bend your knee (Left)",
  Move_your_waking_morning_R:
    "Move your knee just after waking up in the morning (Right)",
  Move_your_waking_morning:
    "Move your knee just after waking up in the morning",
  Move_your_waking_morning_L:
    "Move your knee just after waking up in the morning (Left)",
  Move_knee_later_being_sedentary_R:
    "Move your knee later in the day after being sedentary for a while (Right)",
  Move_knee_later_being_sedentary:
    "Move your knee later in the day after being sedentary for a while",
  Move_knee_later_being_sedentary_L:
    "Move your knee later in the day after being sedentary for a while (Left)",
  Squat_down_baseball_R: "Squat down like a baseball catcher (Right)",
  Squat_down_baseball: "Squat down like a baseball catcher",
  Squat_down_baseball_L: "Squat down like a baseball catcher (Left)",
  Sit_chair_flat_floor_Right:
    "Sit on a chair with your knee bent and feet flat on the floor (Right)",
  Sit_chair_flat_floor:
    "Sit on a chair with your knee bent and feet flat on the floor",
  Sit_chair_flat_floor_Left:
    "Sit on a chair with your knee bent and feet flat on the floor (Left)",
  Get_up_from_chair_R: "Get up from a chair (Right)",
  Get_up_from_chair: "Get up from a chair",
  Get_up_from_chair_L: "Get up from a chair (Left)",
  Run_during_play_activities_R: "Run during play or sports activities (Right)",
  Run_during_play_activities: "Run during play or sports activities",
  Run_during_play_activities_L: "Run during play or sports activities (Left)",
  Jump_play_activities_R: "Jump during play or sports activities (Right)",
  Jump_play_activities: "Jump during play or sports activities",
  Jump_play_activities_L: "Jump during play or sports activities (Left)",
  Start_moving_quickly_R: "Start and stop moving quickly (Right)",
  Start_moving_quickly: "Start and stop moving quickly",
  Start_moving_quickly_L: "Start and stop moving quickly (Left)",
  Twist_pivot_activities_R:
    "Twist/pivot during play or sports activities (Right)",
  Twist_pivot_activities: "Twist/pivot during play or sports activities",
  Twist_pivot_activities_L:
    "Twist/pivot during play or sports activities (Left)",
  Keep_balance_walking_running_R:
    "Keep your balance when walking/running on uneven ground (Right)",
  Keep_balance_walking_running:
    "Keep your balance when walking/running on uneven ground",
  Keep_balance_walking_running_L:
    "Keep your balance when walking/running on uneven ground (Left)",
  Play_sports_R: "Play sports (Right)",
  Play_sports: "Play sports",
  Play_sports_L: "Play sports (Left)",
  Get_to_out_R: "Get in to/out of a car (Right)",
  Get_to_out: "Get in to/out of a car",
  Get_to_out_L: "Get in to/out of a car (Left)",
  Get_out_bed_R: "Get out of bed (Right)",
  Get_out_bed: "Get out of bed",
  Get_out_bed_L: "Get out of bed (Left)",
  Change_knee_when_lying_R: "Change knee position when lying in bed (Right)",
  Change_knee_when_lying: "Change knee position when lying in bed",
  Change_knee_when_lying_L: "Change knee position when lying in bed (Left)",
  Get_bathtub_shower_R: "Get in to/out of the bathtub/shower (Right)",
  Get_bathtub_shower: "Get in to/out of the bathtub/shower",
  Get_bathtub_shower_L: "Get in to/out of the bathtub/shower (Left)",
  Carry_heavy_backpacks_etc_R: "Carry heavy bags/backpacks etc (Right)",
  Carry_heavy_backpacks_etc: "Carry heavy bags/backpacks etc",
  Carry_heavy_backpacks_etc_L: "Carry heavy bags/backpacks etc (Left)",
  Do_lightcleaning_filling_dishwasher_R:
    "Do light chores such as cleaning your room filling/emptying the dishwasher making your bed etc (Right)",
  Do_lightcleaning_filling_dishwasher:
    "Do light chores such as cleaning your room filling/emptying the dishwasher making your bed etc",
  Do_lightcleaning_filling_dishwasher_L:
    "Do light chores such as cleaning your room filling/emptying the dishwasher making your bed etc (Left)",
  Getting_school_carrying_books_participating_R:
    "Getting to school or walking around in school (climbing stairs opening doors carrying books participating during recess) (Right)",
  Getting_school_carrying_books_participating:
    "Getting to school or walking around in school (climbing stairs opening doors carrying books participating during recess)",
  Getting_school_carrying_books_participating_L:
    "Getting to school or walking around in school (climbing stairs opening doors carrying books participating during recess) (Left)",
  Dothings_friends_Right: "Do things with friends (Right)",
  Dothings_friends: "Do things with friends",
  Dothings_friends_Left: "Do things with friends (Left)",
  How_well_your_knee_R: "How well did/does your knee work (Right)",
  How_well_your_knee: "How well did/does your knee work",
  How_well_your_knee_L: "How well did/does your knee work (Left)",
  How_change_injured_knee_R:
    "How much have you changed your lifestyle because of your injured knee? (Right)",
  How_change_injured_knee:
    "How much have you changed your lifestyle because of your injured knee?",
  How_change_injured_knee_L:
    "How much have you changed your lifestyle because of your injured knee? (Left)",
  How_much_injured_knee_R: "How much do you trust your injured knee? (Right)",
  How_much_injured_knee: "How much do you trust your injured knee?",
  How_much_injured_knee_L: "How much do you trust your injured knee? (Left)",
  Overall_difficulty_injured_knee_R:
    "Overall how much difficulty do you have with your injured knee? (Right)",
  Overall_difficulty_injured_knee:
    "Overall how much difficulty do you have with your injured knee?",
  Overall_difficulty_injured_knee_L:
    "Overall how much difficulty do you have with your injured knee? (Left)",
  How_satisfied_surgery:
    "How satisfied are you with the results of your surgery?",
  How_satisfied_surgeryAdult:
    "How satisfied are you with the results of your surgery/treatment?",
  Complete: "Complete",
  Thank_taking_questionnaire:
    "Thank you for taking the time to fill out this questionnaire",
  Please_submit_answers:
    "Please hit submit when you are ready to submit your answers",
  Your_confidentially_reports:
    "Your answers will be treated confidentially and any reports",
  will_de_identified_information: "will only use de-identified information",
  Pedia_Knee_Results: "Paediatric Knee Results",
  Pedi_IKDC_Score: "Pedi-IKDC Score",
  Pain_Subscale: "Pain Subscale",
  Symptoms_Subscale: "Symptoms Subscale",
  KOOS_C_ADL: "KOOS-C ADL",
  koosPlay: "koosPlay",
  KOOS_C_QOL: "KOOS-C QOL",
  VAS: "VAS",
  SANE: "SANE",
  Visual_analog_scale: "VAS (Visual analog scale)",
  Assessment_Numeric_Evaluation: "SANE (Single Assessment Numeric Evaluation)",
  Thank_You: "Thank you for taking the time to fill out this questionnaire.",
  Ready_To: "Please hit submit when you are ready to submit your answers.",
  Answers: "Your answers will be treated confidentially and any reports",
  Identifier: "will only use de-identified information.",
  Submit: "Submit",
  pain: "Pain",
  How_would_you_describe_the_PAIN_you_usually_had_from_your_Hip:
    "OVERALL how would you describe the pain you usually have from your hip IN THE LAST MONTH?",
  How_would_you_describe_the_PAIN_you_usually_had_from_your_HipR:
    "OVERALL how would you describe the pain you usually have from your hip IN THE LAST MONTH? (Right)",
  How_would_you_describe_the_PAIN_you_usually_had_from_your_HipL:
    "OVERALL how would you describe the pain you usually have from your hip IN THE LAST MONTH? (Left)",
  HowoftenR: "How OFTEN is your hip painful? (Right)",
  Howoften: "How OFTEN is your hip painful?",
  HowoftenL: "How OFTEN is your hip painful? (Left)",
  How_bad_pain_hip_AT_THIS_TIME:
    "How bad is the pain from your hip AT THIS TIME? (0 meaning without pain and 10 meaning the most severe pain you can imagine)",
  How_bad_pain_hip_AT_THIS_TIMER:
    "How bad is the pain from your hip AT THIS TIME? (0 meaning without pain and 10 meaning the most severe pain you can imagine) (Right)",
  How_bad_pain_hip_AT_THIS_TIMEL:
    "How bad is the pain from your hip AT THIS TIME? (0 meaning without pain and 10 meaning the most severe pain you can imagine) (Left)",
  HipInterferedR:
    "How much has pain from your hip INTERFERED WITH YOUR USUAL WORK (including housework)? (Right)",
  HipInterfered:
    "How much has pain from your hip INTERFERED WITH YOUR USUAL WORK (including housework)?",
  HipInterferedL:
    "How much has pain from your hip INTERFERED WITH YOUR USUAL WORK (including housework)? (Left)",
  AmountOfHipPain:
    "What AMOUNT OF HIP PAIN have you experienced during the following activities",
  Note: "Note",
  Note_activities:
    "Please answer the following questions relating to your Hip activities.",
  Straightening_hipR: "Straightening hip fully (Right)",
  Straightening_hip: "Straightening hip fully",
  Straightening_hipL: "Straightening hip fully (Left)",
  Bending_hipR: "Bending hip fully (Right)",
  Bending_hip: "Bending hip fully",
  Bending_hipL: "Bending hip fully (Left)",
  WalkingR: "Walking on flat surface (Right)",
  Walkings: "Walking on flat surface",
  WalkingL: "Walking on flat surface (Left)",
  severeR:
    "How long can you walk before the pain from your hip becomes severe? (with or without stick)(Right)",
  severe:
    "How long can you walk before the pain from your hip becomes severe? (with or without stick)",
  severeL:
    "How long can you walk before the pain from your hip becomes severe? (with or without stick)(Left)",
  Walking_Hard_SurfaceR:
    "Walking on a hard surface (asphalt concrete etc)(Right)",
  Walking_Hard_Surface: "Walking on a hard surface (asphalt concrete etc)",
  Walking_Hard_SurfaceL:
    "Walking on a hard surface (asphalt concrete etc)(Left)",
  Walking_Uneven_SurfaceR: "Walking on an uneven surface(Right)",
  Walking_Uneven_Surface: "Walking on an uneven surface",
  Walking_Uneven_SurfaceL: "Walking on an uneven surface(Left)",
  GoingR: "Going up or down stairs (Right)",
  Going: "Going up or down stairs",
  GoingL: "Going up or down stairs (Left)",
  NightR: "At night while in bed (Right)",
  Night: "At night while in bed",
  NightL: "At night while in bed (Left)",
  Hip_NightR:
    "How often have you been troubled by pain from your hip in bed at night? (Right)",
  Hip_Night:
    "How often have you been troubled by pain from your hip in bed at night",
  Hip_NightL:
    "How often have you been troubled by pain from your hip in bed at night? (Left)",
  Hip_Pain_SittingR: "Sitting or lying (Right)",
  Hip_Pain_Sitting: "Sitting or lying",
  Hip_Pain_SittingL: "Sitting or lying (Left)",
  StandingChairR: "Standing up from a chair (Right)",
  StandingChair: "Standing up from a chair",
  StandingChairL: "Standing up from a chair (Left)",
  activityR: "After an activity (Right)",
  activity: "After an activity",
  activityL: "After an activity (Left)",
  Hip_Symptoms: "Other Symptoms",
  How_often: "How often have you had the following",
  NoiseR: "Grinding clicking or any other type of noise from your hip (Right)",
  Noise: "Grinding clicking or any other type of noise from your hip",
  NoiseL: "Grinding clicking or any other type of noise from your hip (Left)",
  CatchigR: "Catching or locking (Right)",
  Catchig: "Catching or locking",
  CatchigL: "Catching or locking (Left)",
  WakeningR: "Stiffness after first wakening in the morning (Right)",
  Wakening: "Stiffness after first wakening in the morning",
  WakeningL: "Stiffness after first wakening in the morning (Left)",
  Hip_Symtoms_SittingR:
    "Stiffness after sitting lying or resting later in the day (Right)",
  Hip_Symtoms_Sitting:
    "Stiffness after sitting lying or resting later in the day",
  Hip_Symtoms_SittingL:
    "Stiffness after sitting lying or resting later in the day (Left)",
  LimpingR: "Limping (Right)",
  Limping: "Limping",
  LimpingL: "Limping (Left)",
  Sudden_Pain_HipR:
    "Sudden severe pain - 'shooting', 'stabbing' or 'spasms' from the hip (Right)",
  Sudden_Pain_Hip:
    "Sudden severe pain - 'shooting', 'stabbing' or 'spasms' from the hip",
  Sudden_Pain_HipL:
    "Sudden severe pain - 'shooting', 'stabbing' or 'spasms' from the hip (Left)",
  HipR: "Hip giving way (Right)",
  Hip: "Hip giving way",
  HipL: "Hip giving way (Left)",
  DecreasedR: "Decreased movement (Right)",
  Decreased: "Decreased movement",
  DecreasedL: "Decreased movement (Left)",
  SupportR: "SUPPORT(Right)",
  Support: "SUPPORT",
  SupportL: "SUPPORT(Left)",
  Do_you_support_walking: "Do you use any support for walking?",
  Do_you_support_walkingR: "Do you use any support for walking? (Right)",
  Do_you_support_walkingL: "Do you use any support for walking? (Left)",
  Hip_Function: "Hip Function",
  Hip_Function_R: "Right Hip",
  Hip_Function_L: "Left Hip",
  IndicateDeg:
    "Indicate the degree of difficulty you have experienced in the last week due to your hip",
  Note_Hip_Function:
    "If you don't do a certain activity imagine how your hip would feel if you had to try it.",
  DescendingR: "Descending stairs (Right)",
  Descending: "Descending stairs",
  DescendingL: "Descending stairs (Left)",
  AscendingR: "Ascending stairs (Right)",
  Ascending: "Ascending stairs",
  AscendingL: "Ascending stairs (Left)",
  Hip_Function_SittingR: "Sitting (Right)",
  Hip_Function_Sitting: "Sitting",
  Hip_Function_SittingL: "Sitting (Left)",
  RisingR: "Rising from sitting (Right)",
  Rising: "Rising from sitting",
  RisingL: "Rising from sitting (Left)",
  Get_up_and_down_off_the_floor_ground_R:
    "Get up and down off the floor/ground (Right)",
  Get_up_and_down_off_the_floor_ground: "Get up and down off the floor/ground",
  Get_up_and_down_off_the_floor_ground_L:
    "Get up and down off the floor/ground (Left)",
  Hip_Function_StandingR: "Standing (Right)",
  Hip_Function_Standing: "Standing",
  Hip_Function_StandingL: "Standing (Left)",
  Hip_Function_Standing_15_minutes_R: "Standing for 15 minutes (Right)",
  Hip_Function_Standing_15_minutes: "Standing for 15 minutes",
  Hip_Function_Standing_15_minutes_L: "Standing for 15 minutes (Left)",
  Spreading_legsR: "Spreading legs wide apart (Right)",
  Spreading_legs: "Spreading legs wide apart",
  Spreading_legsL: "Spreading legs wide apart (Left)",
  Stride_Out_Walking_R: "Stride out when walking (Right)",
  Stride_Out_Walking: "Stride out when walking",
  Stride_Out_Walking_L: "Stride out when walking (Left)",
  Bending_to_floor_pick_up_an_object_R:
    "Bending to floor/pick up an object (Right)",
  Bending_to_floor_pick_up_an_object: "Bending to floor/pick up an object",
  Bending_to_floor_pick_up_an_object_L:
    "Bending to floor/pick up an object (Left)",
  Walking_on_flat_surface: "Walking on flat surface",
  Walking_on_uneven_surface_R: "Walking on uneven surface (Right)",
  Walking_on_uneven_surface: "Walking on uneven surface",
  Walking_on_uneven_surface_L: "Walking on uneven surface (Left)",
  Walking_long_distances_R: "Walking long distances (Right)",
  Walking_long_distances: "Walking long distances",
  Walking_long_distances_L: "Walking long distances (Left)",
  Walking_for_exercise_R: "Walking for exercise (Right)",
  Walking_for_exercise: "Walking for exercise",
  Walking_for_exercise_L: "Walking for exercise (Left)",
  Walking_up_steep_hills_R: "Walking up steep hills (Right)",
  Walking_up_steep_hills: "Walking up steep hills",
  Walking_up_steep_hills_L: "Walking up steep hills (Left)",
  Walking_down_steep_hills_R: "Walking down steep hills (Right)",
  Walking_down_steep_hills: "Walking down steep hills",
  Walking_down_steep_hills_L: "Walking down steep hills (Left)",
  Walking_initially_R: "Walking initially (Right)",
  Walking_initially: "Walking initially",
  Walking_initially_L: "Walking initially (Left)",
  Walking_approximately_10_minutes_R:
    "Walking approximately 10 minutes (Right)",
  Walking_approximately_10_minutes: "Walking approximately 10 minutes",
  Walking_approximately_10_minutes_L: "Walking approximately 10 minutes (Left)",
  Walking_15_minutes_or_greater_R: "Walking 15 minutes or greater (Right)",
  Walking_15_minutes_or_greater: "Walking 15 minutes or greater",
  Walking_15_minutes_or_greater_L: "Walking 15 minutes or greater (Left)",
  Stepping_up_and_down_curbs_R: "Stepping up and down curbs (Right)",
  Stepping_up_and_down_curbs: "Stepping up and down curbs",
  Stepping_up_and_down_curbs_L: "Stepping up and down curbs (Left)",
  Public_transportation_R:
    "Getting in/out of car or public transportation (bus) whichever you tend to use (Right)",
  Public_transportation:
    "Getting in/out of car or public transportation (bus) whichever you tend to use",
  Public_transportation_L:
    "Getting in/out of car or public transportation (bus) whichever you tend to use (Left)",
  Going_shopping_R: "Going shopping (Right)",
  Going_shopping: "Going shopping",
  Going_shopping_L: "Going shopping (Left)",
  Putting_on_socks_stockings_R: "Putting on socks/stockings (Right)",
  Putting_on_socks_stockings_L: "Putting on socks/stockings (Left)",
  Taking_off_socks_stockings_R: "Taking off socks/stockings (Right)",
  Taking_off_socks_stockings_L: "Taking off socks/stockings (Left)",
  Rolling_over_in_bed_R: "Rolling over in bed (Right)",
  Rolling_over_in_bed: "Rolling over in bed",
  Rolling_over_in_bed_L: "Rolling over in bed (Left)",
  Rising_from_bed_R: "Rising from bed (Right)",
  Rising_from_bed: "Rising from bed",
  Rising_from_bed_L: "Rising from bed (Left)",
  Getting_in_and_out_of_bath_R: "Getting in and out of bath (Right)",
  Getting_in_and_out_of_bath: "Getting in and out of bath",
  Getting_in_and_out_of_bath_L: "Getting in and out of bath (Left)",
  Getting_on_and_off_toilet_R: "Getting on and off toilet (Right)",
  Getting_on_and_off_toilet: "Getting on and off toilet",
  Getting_on_and_off_toilet_L: "Getting on and off toilet (Left)",
  Washing_and_drying_yourself_R:
    "Washing and drying yourself (all over) (Right)",
  Washing_and_drying_yourself: "Washing and drying yourself (all over)",
  Washing_and_drying_yourself_L:
    "Washing and drying yourself (all over) (Left)",
  Picking_up_or_carrying_children_R: "Picking up or carrying children (Right)",
  Picking_up_or_carrying_children: "Picking up or carrying children",
  Picking_up_or_carrying_children_L: "Picking up or carrying children (Left)",
  Hip_Function_Squatting_R: "Squatting (Right)",
  Hip_Function_Squatting: "Squatting",
  Hip_Function_Squatting_L: "Squatting (Left)",
  Jogging_for_exercise_R: "Jogging for exercise (Right)",
  Jogging_for_exercise: "Jogging for exercise",
  Jogging_for_exercise_L: "Jogging for exercise (Left)",
  Hip_Function_Running_R: "Running (Right)",
  Hip_Function_Running_L: "Running (Left)",
  Twisting_pivoting_on_loaded_leg_R: "Twisting/pivoting on loaded leg (Right)",
  Twisting_pivoting_on_loaded_leg: "Twisting/pivoting on loaded leg",
  Twisting_pivoting_on_loaded_leg_L: "Twisting/pivoting on loaded leg (Left)",
  Sexual_activity_R: "Sexual activity (Right)",
  Sexual_activity: "Sexual activity",
  Sexual_activity_L: "Sexual activity (Left)",
  Recreational_activities_R: "Recreational activities (Right)",
  Recreational_activities: "Recreational activities",
  Recreational_activities_L: "Recreational activities (Left)",
  Hip_Function_Rate:
    "How would you rate your hips today as a percentage of normal? (0% to 100% with 100% being normal)",
  Hip_Function_RateR:
    "How would you rate your hips today as a percentage of normal? (0% to 100% with 100% being normal) (Right)",
  Hip_Function_RateL:
    "How would you rate your hips today as a percentage of normal? (0% to 100% with 100% being normal) (Left)",
  Please_specify_the_highest_level_participate:
    "Please specify the highest level of activity that you can participate in",
  Please_specify_the_highest_level_participateR:
    "Please specify the highest level of activity that you can participate in (Right)",
  Please_specify_the_highest_level_participateL:
    "Please specify the highest level of activity that you can participate in (Left)",
  PRIOR_TO_HIP_INJURY: "PRIOR TO HIP INJURY",
  CURRENT_STATUS: "CURRENT STATUS",
  Quality_of_life: "QUALITY OF LIFE",
  Hip_Quality_Rate:
    "Rate the following questions from 0-10 depending on how you have felt most of the time over the past month",
  How_often_are_you_aware_of_your_hip_problem:
    "How often are you aware of your hip problem?",
  How_often_are_you_aware_of_your_hip_problemR:
    "How often are you aware of your hip problem? (Right)",
  How_often_are_you_aware_of_your_hip_problemL:
    "How often are you aware of your hip problem? (Left)",
  Modified_Lifestyle:
    "Have you modified your lifestyle to avoid potentially damaging activities to your hip?",
  Modified_LifestyleR:
    "Have you modified your lifestyle to avoid potentially damaging activities to your hip?(Right)",
  Modified_LifestyleL:
    "Have you modified your lifestyle to avoid potentially damaging activities to your hip?(Left)",
  How_concerned_are_you_about_your_ability_to_maintain_your_desired_fitness_level:
    "How concerned are you about your ability to maintain your desired fitness level?",
  How_concerned_are_you_about_your_ability_to_maintain_your_desired_fitness_levelR:
    "How concerned are you about your ability to maintain your desired fitness level? (Right)",
  How_concerned_are_you_about_your_ability_to_maintain_your_desired_fitness_levelL:
    "How concerned are you about your ability to maintain your desired fitness level? (Left)",
  How_much_are_you_troubled_with_the_lack_of_confidence:
    "How much are you troubled with the lack of confidence in your hip?",
  How_much_are_you_troubled_with_the_lack_of_confidenceR:
    "How much are you troubled with the lack of confidence in your hip? (Right)",
  How_much_are_you_troubled_with_the_lack_of_confidenceL:
    "How much are you troubled with the lack of confidence in your hip? (Left)",
  How_much_of_a_distraction_is_your_hip_problem:
    "How much of a distraction is your hip problem?",
  How_much_of_a_distraction_is_your_hip_problemR:
    "How much of a distraction is your hip problem? (Right)",
  How_much_of_a_distraction_is_your_hip_problemL:
    "How much of a distraction is your hip problem? (Left)",
  how_much_difficulty_do_you_have_with_your_hip:
    "In general how much difficulty do you have with your hip?",
  how_much_difficulty_do_you_have_with_your_hipR:
    "In general how much difficulty do you have with your hip? (Right)",
  how_much_difficulty_do_you_have_with_your_hipL:
    "In general how much difficulty do you have with your hip? (Left)",
  How_anxious_depressed_are_you_today: "How anxious/depressed are you today?",
  How_anxious_depressed_are_you_todayR:
    "How anxious/depressed are you today? (Right)",
  How_anxious_depressed_are_you_todayL:
    "How anxious/depressed are you today? (Left)",
  How_good_bad_is_your_health_todayR:
    "How good/bad is your health today? (100 means best health 0 means worst health you can imagine) (Right)",
  How_good_bad_is_your_health_todayL:
    "How good/bad is your health today? (100 means best health 0 means worst health you can imagine) (Left)",
  Worst_health: "Worst health",
  Best_health: "Best health",
  Hip_Satisfaction: "Hip Satisfaction",
  Hip_Satisfaction_OVERALL_R: "Overall (Right)",
  Hip_Satisfaction_OVERALL: "Overall",
  Hip_Satisfaction_OVERALL_L: "Overall (Left)",
  Improving_pain_R: "For improving your pain? (Right)",
  Improving_pain: "For improving your pain?",
  Improving_pain_L: "For improving your pain? (Left)",
  Improving_Ability_R:
    "For improving your ability to do home or yard work? (Right)",
  Improving_Ability_L:
    "For improving your ability to do home or yard work? (Left)",
  Improving_Ability_Recreational_Activites_R:
    "For improving your ability to do recreational activites? (Right)",
  Improving_Ability_Recreational_Activites:
    "For improving your ability to do recreational activites?",
  Improving_Ability_Recreational_Activites_L:
    "For improving your ability to do recreational activites? (Left)",
  Gait: "Gait",
  Gait_abnormality: "Gait abnormality",
  Pelvic_tilt: "Pelvic tilt",
  Limb_length: "Limb length",
  Limb_length_HIP: "Limb length measurement",
  Fixed_contracture: "Fixed contracture",
  Specify: "Specify",
  Range_of_motion: "Range of motion",
  Tenderness: "Tenderness",
  Greater_trochanter: "Greater trochanter",
  Inguinal: "Inguinal",
  Log_roll_test: "Log roll test",
  Galeazzi_sign: "Galeazzi’s sign",
  Trendelenburg_sign: "Trendelenburg sign",
  Thomas_test: "Thomas test",
  FADIR_Impingement_test: "FADIR/Impingement test",
  Patrick_FABER_test: "Patrick/FABER test",
  Ober_test: "Ober test",
  Straight_leg_raise: "Straight leg raise",
  Barlow: "Barlow",
  Ortolani: "Ortolani",
  Asymmetric_skin_creases: "Asymmetric skin creases",
  only_for_paediatric_patients: "only for paediatric patients",
  Hip_Results: "Hip Results",
  Score: "Below is your pain and hip function score which is using your",
  ans: "answers from the hip scoring self-evaluation.",
  Hoos_Symptoms: "Hoos Symptoms",
  Hoos_Pain: "Hoos Pain",
  Hoos_ADL: "Hoos ADL",
  Hoos_Sport: "Hoos Sport",
  Hoos_QOL: "Hoos QOL",
  mHHS: "mHHS",
  OHS_Function: "OHS Function",
  OHS_Pain: "OHS Pain",
  OHS_Overall: "OHS Overall",
  NAHS: "NAHS",
  HOS_ADL: "HOS-ADL",
  HOS_Sports: "HOS-Sports",
  IHOT_12: "IHOT-12",
  EQ_VAS: "EQ-VAS",
  EQ_VAS_HIP: "Overall Health Perception",
  SAPS: "SAPS",
  SAPS_HIP: "Satisfaction with Hip Surgery",
  EQ5d_5L_Mobility: "EQ5d-5L Mobility",
  EQ5d_5L_Self_Care: "EQ5D-5L Self-Care",
  EQ5d_5L_Self_Care_HIP: "Perceived Problem with Self-Care",
  EQ5d_5L_Usual_Activities: "EQ5d-5L Usual-Activities",
  EQ5d_5L_Usual_Activities_HIP: "Perceived Problem with Doing Usual Activities",
  EQ5d_5L_Pain_Discomfort: "EQ5d-5L Pain/Discomfort",
  EQ5d_5L_Pain_Discomfort_HIP: "Perceived Level of Pain/Discomfort",
  EQ5d_5L_anxious: "EQ5d-5L anxious",
  EQ5d_5L_anxious_HIP: "Perceived Level of Anxiety/Depression",
  Doctor: "Your Doctor will be with you shortly.",
  GENERAL_LEVEL_OF_PAIN_from_your_foot_ankle:
    "What is the CURRENT LEVEL OF PAIN from your foot/ankle?",
  GENERAL_LEVEL_OF_PAIN_from_your_foot_ankleR:
    "What is the CURRENT LEVEL OF PAIN from your foot/ankle? (Right)",
  GENERAL_LEVEL_OF_PAIN_from_your_foot_ankleL:
    "What is the CURRENT LEVEL OF PAIN from your foot/ankle? (Left)",
  Best_description_of_PAIN_from_your_foot_ankle_R:
    "Best description of PAIN from your foot/ankle?(Right)",
  Best_description_of_PAIN_from_your_foot_ankle:
    "Best description of PAIN from your foot/ankle?",
  Best_description_of_PAIN_from_your_foot_ankle_L:
    "Best description of PAIN from your foot/ankle?(Left)",
  How_OFTEN_do_you_experience_foot_ankle_pain_R:
    "How OFTEN do you experience foot/ankle pain?(Right)",
  How_OFTEN_do_you_experience_foot_ankle_pain:
    "How OFTEN do you experience foot/ankle pain?",
  How_OFTEN_do_you_experience_foot_ankle_pain_L:
    "How OFTEN do you experience foot/ankle pain?(Left)",
  What_amount_of_FOOT_ANKLE_PAIN:
    "What amount of FOOT/ANKLE PAIN have you experienced during the following activities",
  General_level_of_painR: "General level of pain (Right)",
  General_level_of_pain: "General level of pain",
  General_level_of_painL: "General level of pain (Left)",
  At_Rest_R: "At Rest (Right)",
  At_Rest: "At Rest",
  At_Rest_L: "At Rest (Left)",
  During_normal_activity_R: "During normal activity (Right)",
  During_normal_activity: "During normal activity",
  During_normal_activity_L: "During normal activity (Left)",
  First_thing_in_the_morning_R: "First thing in the morning (Right)",
  First_thing_in_the_morning: "First thing in the morning",
  First_thing_in_the_morning_L: "First thing in the morning (Left)",
  Twisted_pivoting_on_your_foot_ankle_R:
    "Twisted/pivoting on your foot/ankle (Right)",
  Twisted_pivoting_on_your_foot_ankle: "Twisted/pivoting on your foot/ankle",
  Twisted_pivoting_on_your_foot_ankle_L:
    "Twisted/pivoting on your foot/ankle (Left)",
  Straightening_foot_ankle_fully_R: "Straightening foot/ankle fully (Right)",
  Straightening_foot_ankle_fully: "Straightening foot/ankle fully",
  Straightening_foot_ankle_fully_L: "Straightening foot/ankle fully (Left)",
  Bending_foot_ankle_fully_R: "Bending foot/ankle fully (Right)",
  Bending_foot_ankle_fully: "Bending foot/ankle fully",
  Bending_foot_ankle_fully_L: "Bending foot/ankle fully (Left)",
  Walking_on_a_flat_suraface_R: "Walking on a flat suraface (Right)",
  Walking_on_a_flat_suraface: "Walking on a flat suraface",
  Walking_on_a_flat_suraface_L: "Walking on a flat suraface (Left)",
  Pain_Ankle_Sitting_R: "Sitting or lying (Right)",
  Pain_Ankle_Sitting: "Sitting or lying",
  Pain_Ankle_Sitting_L: "Sitting or lying (Left)",
  Foot_Ankle_Instability: "Instability",
  Ankle_feel_unstable_R:
    "Do you experience giving way of your ankle or do you feel like it is going to give way? (Right)",
  Ankle_feel_unstable:
    "Do you experience giving way of your ankle or do you feel like it is going to give way?",
  Ankle_feel_unstable_L:
    "Do you experience giving way of your ankle or do you feel like it is going to give way? (Left)",
  Diagnosed_with_treated_for_ankle_instability_R:
    "Have you ever been diagnosed with/treated for ankle instability? (Right)",
  Diagnosed_with_treated_for_ankle_instability:
    "Have you ever been diagnosed with/treated for ankle instability?",
  Diagnosed_with_treated_for_ankle_instability_L:
    "Have you ever been diagnosed with/treated for ankle instability? (Left)",
  Foot_Ankle_Other_Symptoms: "Other Symptoms",
  Following: "Do you have the following",
  Swelling_in_your_foot_ankle_R: "Swelling in your foot/ankle (Right)",
  Swelling_in_your_foot_ankle: "Swelling in your foot/ankle",
  Swelling_in_your_foot_ankle_L: "Swelling in your foot/ankle (Left)",
  Grinding_clicking_or_any_other_type_of_noise_when_your_foot_ankle_moves_R:
    "Grinding clicking or any other type of noise when your foot/ankle moves (Right)",
  Grinding_clicking_or_any_other_type_of_noise_when_your_foot_ankle_moves:
    "Grinding clicking or any other type of noise when your foot/ankle moves",
  Grinding_clicking_or_any_other_type_of_noise_when_your_foot_ankle_moves_L:
    "Grinding clicking or any other type of noise when your foot/ankle moves (Left)",
  Catching_or_hanging_up_when_moving_R:
    "Catching or hanging up when moving (Right)",
  Catching_or_hanging_up_when_moving: "Catching or hanging up when moving",
  Catching_or_hanging_up_when_moving_L:
    "Catching or hanging up when moving (Left)",
  Foot_ankle_other_symtoms_wakening_R:
    "Stiffness after sitting lying or resting later in the day (Right)",
  Foot_ankle_other_symtoms_wakening:
    "Stiffness after sitting lying or resting later in the day",
  Foot_ankle_other_symtoms_wakening_L:
    "Stiffness after sitting lying or resting later in the day (Left)",
  Foot_Ankle_Function: "Function",
  Activity_limitations_support_requirement_R:
    "Activity limitations support requirement (Right)",
  Activity_limitations_support_requirement:
    "Activity limitations support requirement",
  Activity_limitations_support_requirement_L:
    "Activity limitations support requirement (Left)",
  Footwear_requirement_R: "Footwear requirement (Right)",
  Footwear_requirement: "Footwear requirement",
  Footwear_requirement_L: "Footwear requirement (Left)",
  Walking_surfaces_R: "Walking surfaces (Right)",
  Walking_surfaces: "Walking surfaces",
  Walking_surfaces_L: "Walking surfaces (Left)",
  Foot_ankle_deg:
    "Indicate the DEGREE OF DIFFICULTY you have experienced DUE TO YOUR FOOT/ANKLE",
  Foot_ankle_Activity:
    "If the activity in question is limited by something other than your foot or ankle mark 'Not Applicable' in items where this is an option. Otherwise please answer every question with one response that most closely describes your condition within the past week.",
  Foot_ankle_Symtoms_R: "Right Symptoms",
  Foot_ankle_Symtoms_L: "Left Symptoms",
  Foot_ankle_straightening_R: "Straightening your foot/ankle fully (Right)",
  Foot_ankle_straightening: "Straightening your foot/ankle fully",
  Foot_ankle_straightening_L: "Straightening your foot/ankle fully (Left)",
  Foot_ankle_bending_R: "Bending your foot/ankle fully (Right)",
  Foot_ankle_bending: "Bending your foot/ankle fully",
  Foot_ankle_bending_L: "Bending your foot/ankle fully (Left)",
  Foot_ankle_standing_R: "Standing (Right)",
  Foot_ankle_standing: "Standing",
  Foot_ankle_standing_L: "Standing (Left)",
  Foot_ankle_maximum_walking_distance_R:
    "What is your maximum walking distance? (Right)",
  Foot_ankle_maximum_walking_distance: "What is your maximum walking distance?",
  Foot_ankle_maximum_walking_distance_L:
    "What is your maximum walking distance? (Left)",
  Walking_on_even_ground_R: "Walking on even ground without shoes (Right)",
  Walking_on_even_ground: "Walking on even ground without shoes",
  Walking_on_even_ground_L: "Walking on even ground without shoes (Left)",
  Walking_up_hills_R: "Walking up hills (Right)",
  Walking_up_hills: "Walking up hills",
  Walking_up_hills_L: "Walking up hills (Left)",
  Walking_down_hills_R: "Walking down hills (Right)",
  Walking_down_hills: "Walking down hills",
  Walking_down_hills_L: "Walking down hills (Left)",
  Walking_on_uneven_ground_R: "Walking on uneven ground (Right)",
  Walking_on_uneven_ground: "Walking on uneven ground",
  Walking_on_uneven_ground_L: "Walking on uneven ground (Left)",
  Coming_up_to_your_toes_R: "Coming up to your toes (Right)",
  Coming_up_to_your_toes: "Coming up to your toes",
  Coming_up_to_your_toes_L: "Coming up to your toes (Left)",
  Walking_5_minutes_or_less_R: "Walking 5 minutes or less (Right)",
  Walking_5_minutes_or_less: "Walking 5 minutes or less",
  Walking_5_minutes_or_less_L: "Walking 5 minutes or less (Left)",
  Getting_in_out_of_car_R: "Getting in/out of car (Right)",
  Getting_in_out_of_car: "Getting in/out of car",
  Getting_in_out_of_car_L: "Getting in/out of car (Left)",
  Foot_ankle_Lying_in_bed_R:
    "Lying in bed (turning over, maintaining foot/ankle position) (Right)",
  Foot_ankle_Lying_in_bed:
    "Lying in bed (turning over, maintaining foot/ankle position)",
  Foot_ankle_Lying_in_bed_L:
    "Lying in bed (turning over, maintaining foot/ankle position) (Left)",
  Sleeping_R: "Sleeping (Right)",
  Sleeping: "Sleeping",
  Sleeping_L: "Sleeping (Left)",
  Foot_ankle_Sitting_R: "Sitting (Right)",
  Foot_ankle_Sitting: "Sitting",
  Foot_ankle_Sitting_L: "Sitting (Left)",
  Home_responsibilities_R: "Home responsibilities (Right)",
  Home_responsibilities: "Home responsibilities",
  Home_responsibilities_L: "Home responsibilities (Left)",
  Activities_of_daily_living_R: "Activities of daily living (Right)",
  Activities_of_daily_living: "Activities of daily living",
  Activities_of_daily_living_L: "Activities of daily living (Left)",
  Personal_care_R:
    "Personal care  (including washing or dressing self) (Right)",
  Personal_care: "Personal care  (including washing or dressing self)",
  Personal_care_L: "Personal care  (including washing or dressing self) (Left)",
  Heavy_work_R:
    "Heavy work (push/pulling climbing carrying moving heavy boxes scrubbing floors etc) (Right)",
  Heavy_work:
    "Heavy work (push/pulling climbing carrying moving heavy boxes scrubbing floors etc)",
  Heavy_work_L:
    "Heavy work (push/pulling climbing carrying moving heavy boxes scrubbing floors etc) (Left)",
  Twisting_pivoting_on_your_foot_ankle_R:
    "Twisting/pivoting on your foot/ankle (Right)",
  Twisting_pivoting_on_your_foot_ankle: "Twisting/pivoting on your foot/ankle",
  Twisting_pivoting_on_your_foot_ankle_L:
    "Twisting/pivoting on your foot/ankle (Left)",
  Kneeling_R: "Kneeling (Right)",
  Kneeling_L: "Kneeling (Left)",
  Running_R: "Running (Right)",
  Running: "Running",
  Running_L: "Running (Left)",
  Jumping_R: "Jumping (Right)",
  Jumping_L: "Jumping (Left)",
  Landing_R: "Landing (Right)",
  Landing: "Landing",
  Landing_L: "Landing (Left)",
  Starting_and_stopping_quickly_R: "Starting and stopping quickly (Right)",
  Starting_and_stopping_quickly: "Starting and stopping quickly",
  Starting_and_stopping_quickly_L: "Starting and stopping quickly (Left)",
  Cutting_lateral_movements_R: "Cutting / lateral movements (Right)",
  Cutting_lateral_movements: "Cutting / lateral movements",
  Cutting_lateral_movements_L: "Cutting / lateral movements (Left)",
  Low_impact_activities_R: "Low-impact activities (Right)",
  Low_impact_activities_hip: "Low-impact activities",
  Low_impact_activities_L: "Low-impact activities (Left)",
  Ability_to_perform_activity_R:
    "Ability to perform activity with your normal technique (Right)",
  Ability_to_perform_activity:
    "Ability to perform activity with your normal technique",
  Ability_to_perform_activity_L:
    "Ability to perform activity with your normal technique (Left)",
  Ability_to_participate_in_your_desired_sport_as_long_as_you_like_R:
    "Ability to participate in your desired sport as long as you like (Right)",
  Ability_to_participate_in_your_desired_sport_as_long_as_you_like:
    "Ability to participate in your desired sport as long as you like",
  Ability_to_participate_in_your_desired_sport_as_long_as_you_like_L:
    "Ability to participate in your desired sport as long as you like (Left)",
  Choose_the_box:
    "Choose the box that contains the sport/work/activity that you do (you may choose more than one)",
  Football: "American football",
  Basketball: "Basketball",
  Gymnastics: "Gymnastics",
  Handball: "Handball",
  Rugby: "Rugby",
  Soccer: "Soccer",
  level_you_play: "What level do you play?",
  Hockey: "Hockey",
  Korfball: "Korfball",
  Martial_arts: "Martial arts",
  fu_taekwondo: "judo karate kung fu taekwondo",
  Orienteering: "Orienteering",
  Rhythmic_gymnastics: "Rhythmic gymnastics",
  Volleyball: "Volleyball",
  Boxing: "Boxing",
  Freestyle_snowboarding: "Freestyle snowboarding",
  Ice_hockey: "Ice hockey",
  Tennis: "Tennis",
  Wrestling: "Wrestling",
  Aerobic: "Aerobic fitness",
  Badminton: "Badminton",
  Baseball: "Baseball",
  Cross_country_running: "Cross-country running",
  Modern_pentathlon: "Modern pentathlon",
  Squash: "Squash",
  Surfing: "Surfing windsurfing",
  Table_tennis: "Table tennis",
  Track: "Track and field",
  Water_skiing: "Water skiing",
  Dancing: "Dancing",
  Fencing: "Fencing",
  Floorball: "Floorball",
  Mountain: "Mountain and hill climbing",
  Nordic_skiing: "Nordic skiing",
  Parachuting: "Parachuting",
  Softball: "Softball",
  Special_professions: "Special professions and working activities",
  Diving: "Diving",
  Scuba_diving: "Scuba diving",
  Skating: "Skating in-line skating",
  Track_and_field: "Track and field",
  Triathlon: "Triathlon",
  Weightlifting: "Weightlifting body building",
  Heavy_physical_work: "Heavy physical work",
  Alpine: "Alpine skiing and snowboarding",
  Bowling: "Bowling/curling",
  Golf: "Golf",
  Mountain_biking_bmx: "Mountain biking/bmx",
  Power_lifting: ">Power lifting",
  Sailing: "Sailing",
  Physical_work: "Physical work",
  Cycling: "Cycling",
  Equestrian: "Equestrian",
  Motorsports: "Motorsports technical sports",
  Rowing: "Rowing kayaking",
  Shooting: "Shooting archery",
  Water_swimming: "Water polo and swimming",
  Able_to_walk: "Able to walk on any uneven ground",
  No_sports: "No sports everyday activities not limited",
  Able_to_walk_on_even_ground:
    "Able to walk on even ground but everyday activities limited",
  Unable_to_walk: "Unable to walk disabled because of ankle/foot problems",
  percentage_of_normal:
    "How would you rate your ANKLE/FOOT today as a percentage of normal? (0 to 100%, 100% being normal)",
  percentage_of_normalR:
    "How would you rate your ANKLE/FOOT today as a percentage of normal? (0 to 100%, 100% being normal) (Right)",
  percentage_of_normalL:
    "How would you rate your ANKLE/FOOT today as a percentage of normal? (0 to 100%, 100% being normal) (Left)",
  Gait_Right: "Gait (Right)",
  Gait_Left: "Gait (Left)",
  Right_Anatomic_alignment_Based:
    "Right Anatomic alignment (Based on weightbearing AP radiograph)",
  Left_Anatomic_alignment_Based:
    "Left Anatomic alignment (Based on weightbearing AP radiograph)",
  Active: "Active",
  Passive: "Passive",
  Anatomic_Right: "Anatomic Right",
  Anatomic_Left: "Anatomic Left",
  L_Anatomic_Right: "L Anatomic Right",
  L_Anatomic_Left: "L Anatomic Left",
  Right_Q_angle: "Right Q angle",
  Angle_Right: "Angle Right",
  Angle_Left: "Angle Left",
  Left_Q_angle: "Left Q angle",
  L_Angle_Right: "L Angle Right",
  L_Angle_Left: "L Angle Left",
  Right_Fixed_flexion_contracture: "Right Fixed flexion contracture",
  fixed_Right: "fixed Right",
  fixed_Left: "fixed Left",
  Left_Fixed_flexion_contracture: "Left Fixed flexion contracture",
  l_fixed_Right: "L fixed Right",
  l_fixed_Left: "L fixed Left",
  Right_Extension_lag: "Right Extension lag",
  lag_Right: "lag Right",
  lag_Left: "lag Left",
  Left_Extension_lag: "Left Extension lag",
  L_lag_Right: "L lag Right",
  L_lag_Left: "L lag Left",
  ACL: "ACL",
  Negative: "Negative",
  Positive: "Positive",
  anteriorR: "anteriorR",
  Anterior_drawer_90_degree_R:
    "Anterior drawer test  (Right)",
  anteriorLL: "anteriorLL",
  anteriorRR: "anteriorRR",
  anteriorL: "anteriorL",
  Anterior_drawer_90_degree_L:
    "Anterior drawer test  (Left)",
  lachmanR: "lachmanR",
  lachmanL: "lachmanL",
  Lachman_test_R: "Lachman test(Right)",
  lachmanlL: "lachmanlL",
  lachmanlR: "lachmanlR",
  Lachman_test_L: "Lachman test(Left)",
  pivotR: "pivotR",
  pivotL: "pivotL",
  Pivot_shift_test_R: "Pivot shift test(Right)",
  Pivot_shift_test_L: "Pivot shift test(Left)",
  pivotlL: "pivotlL",
  pivotlR: "pivotlR",
  KT_1000_R: "KT-1000 (Right)",
  Test_1: "Test 1",
  Test_2: "Test 2",
  Test_3: "Test 3",
  Write_Test_1: "Write Test 1",
  Write_Test_2: "Write Test 2",
  Write_Test_3: "Write Test 3",
  KT_1000_L: "KT-1000 (Left)",
  sagR: "sagR",
  sagL: "sagL",
  Posterior_sag_sign_R: "Posterior sag sign (Right)",
  Posterior_sag_sign_L: "Posterior sag sign (Left)",
  sagLL: "sagLL",
  sagRR: "sagRR",
  drawerR: "drawerR",
  drawerL: "drawerL",
  Posterior_drawer_test_Measured_R:
    "Posterior drawer test (Right)",
  Posterior_drawer_test_Measured_L:
    "Posterior drawer test (Left)",
  drawR: "drawR",
  drawL: "drawL",
  activeR: "activeR",
  activeL: "activeL",
  Quadriceps_active_test_R: "Quadriceps active test (Right)",
  Quadriceps_active_test_L: "Quadriceps active test (Left)",
  activerR: "activerR",
  activerL: "activerL",
  QuadricepsL: "QuadricepsL",
  reverseR: "reverseR",
  reverseL: "reverseL",
  Reverse_pivot_shift_test_R: "Reverse pivot shift test (Right)",
  Reverse_pivot_shift_test_L: "Reverse pivot shift test (Left)",
  reverserR: "reverserR",
  reverserL: "reverserL",
  PLC: "PLC",
  Dial_test_R: "Dial test (Right)",
  Dial_test_L: "Dial test (Left)",
  recurvatumL: "recurvatumL",
  recurvatumR: "recurvatumR",
  ER_recurvatum_test_R: "ER recurvatum test (Right)",
  ER_recurvatum_test_L: "ER recurvatum test (Left)",
  recurvatumrL: "recurvatumrL",
  recurvatumrR: "recurvatumrR",
  postL: "postL",
  postR: "postR",
  Posterolateral_drawer_test_R: "Posterolateral drawer test (Right)",
  Posterolateral_drawer_test_L: "Posterolateral drawer test (Left)",
  postrL: "postrL",
  postrR: "postrR",
  PMC: "PMC",
  rotatL: "rotatL",
  rotatR: "rotatR",
  Anteromedial_rotatory_instability_R:
    "Anteromedial rotatory instability (Right)",
  Anteromedial_rotatory_instability_L:
    "Anteromedial rotatory instability (Left)",
  rotatrL: "rotatrL",
  rotatrR: "rotatrR",
  drawsL: "drawsL",
  drawsR: "drawsR",
  Anteromedial_drawer_test_R: "Anteromedial drawer test(Right)",
  Anteromedial_drawer_test_L: "Anteromedial drawer test(Left)",
  drawsrL: "drawsrL",
  drawsrR: "drawsrR",
  Collaterals: "Collaterals",
  In_full_extension: "In full extension",
  stressL: "stressL",
  stressR: "stressR",
  Varus_stress_test_R: "Varus stress test (Right)",
  Varus_stress_test_L: "Varus stress test (Left)",
  stressrL: "stressrL",
  stressrR: "stressrR",
  At_30_degree_flexion: "At 30 degree flexion",
  stressflL: "stressflL",
  stressflR: "stressflR",
  stressrflrL: "stressrflrL",
  stressrflrR: "stressrflrR",
  valgusL: "valgusL",
  valgusR: "valgusR",
  Valgus_tress_test_R: "Valgus stress test (Right)",
  Valgus_tress_test_L: "Valgus stress test (Left)",
  valgusrL: "valgusrL",
  valgusrR: "valgusrR",
  valgusflL: "valgusflL",
  valgusflR: "valgusflR",
  valgusflrL: "valgusflrL",
  valgusflrR: "valgusflrR",
  Meniscus: "Meniscus",
  murraryL: "murraryL",
  murraryR: "murraryR",
  McMurray_R: "McMurray (Right)",
  McMurray_L: "McMurray (Left)",
  Ege_R: "Ege’s (Right)",
  Ege_L: "Ege’s (Left)",
  Thessaly_R: "Thessaly (Right)",
  Thessaly_L: "Thessaly (Left)",
  Joint_line_tenderness_R: "Joint line tenderness (Right)",
  Joint_line_tenderness_L: "Joint line tenderness (Left)",
  Patella: "Patella",
  Apprehension_R: "Apprehension (Right)",
  Apprehension_L: "Apprehension (Left)",
  Patellar_displacement_R: "Patellar displacement",
  Patellar_displacement_L: "Patellar displacement",
  Patella_grind_R: "Patella grind (Right)",
  Patella_grind_L: "Patella grind (Left)",
  Jsign_R: "J-sign (Right)",
  Jsign_L: "J-sign (Left)",
  Straight_raise_R: "Straight leg raise (Right)",
  Straight_raise_L: "Straight leg raise (Left)",
  Isometric_knee_strength_R: "Isometric knee extension strength (Right)",
  Isometric_knee_strength_L: "Isometric knee extension strength (Left)",
  Are_you_pain_your_shoulder_R: "Are you having pain in your shoulder? (Right)",
  Are_you_pain_your_shoulder: "Are you having pain in your shoulder?",
  Are_you_pain_your_shoulder_L: "Are you having pain in your shoulder? (Left)",
  During_past_weeks_describes_right_shoulder_R:
    "During the past 4 weeks what best describes the pain on the right shoulder? (Right)",
  During_past_weeks_describes_right_shoulder_L:
    "During the past 4 weeks what best describes the pain on the Left shoulder? (Left)",
  During_past_weeks_describes_right_shoulder:
    "During the past 4 weeks what best describes the pain on the right shoulder?",
  Do_shoulder_effects_sleep_night_R:
    "Do you have shoulder pain at night that affects your sleep or wakes you up in the night? (Right)",
  Do_shoulder_effects_sleep_night:
    "Do you have shoulder pain at night that affects your sleep or wakes you up in the night?",
  Do_shoulder_effects_sleep_night_L:
    "Do you have shoulder pain at night that affects your sleep or wakes you up in the night? (Left)",
  Mark_where_the_pain: "Mark where the pain is",
  How_bad_pain_today_R: "How bad is your pain today? (Right)",
  How_bad_pain_today: "How bad is your pain today?",
  How_bad_pain_today_L: "How bad is your pain today? (Left)",
  Are_current_smoker: "Are you a current smoker?",
  How_cigarettes_perday: "How many cigarettes per day?",
  How_long_agodidyouquit: "How long ago did you quit (years)?",
  How_years_yousmoke: "How many years did you smoke?",
  Do_take_pain_medication: "Do you take pain medication (eg paracetamol etc)?",
  Do_narcotic_pain_medication:
    "Do you take narcotic pain medication (eg codeine etc)?",
  How_tablets_take_day_average:
    "How many tablets do you take each day on average ?",
  Which_following_describes_pain_L:
    "Which of the following statement best describes the pain (Left)",
  Which_following_describes_pain:
    "Which of the following statement best describes the pain",
  Which_following_describes_pain_R:
    "Which of the following statement best describes the pain (Right)",
  Do_needles_tingling_armL:
    "Do you get pins and needles or tingling down your arm ? (Left)",
  Do_needles_tingling:
    "Do you get pins and needles or tingling down your arm ?",
  Do_needles_tingling_armR:
    "Do you get pins and needles or tingling down your arm ? (Right)",
  Which_statements_affected_shoulderL:
    "Which of the following statements best describes the function in the affected shoulder? (Left)",
  Which_statements_affected_shoulder:
    "Which of the following statements best describes the function in the affected shoulder?",
  Which_statements_affected_shoulderR:
    "Which of the following statements best describes the function in the affected shoulder? (Right)",
  Select_indicates_ability_activities:
    "Select the number that indicates your ability to do the following activities",
  Unable: "Unable",
  Very_difficult: "Very difficult",
  Not_difficult: "Not difficult",
  Somewhat_difficult: "Somewhat difficult",
  Please_answer_questions_relating_shoulder:
    "Please answer the following questions relating to your shoulder function",
  Right_Arm: "Right",
  Put_coat_shirt_R: "Put on a coat / shirt (Right)",
  Put_coat_shirt: "Put on a coat / shirt",
  Put_coat_shirt_L: "Put on a coat / shirt (Left)",
  Left_Arm: "Left",
  Sleep_painful_affected_R: "Sleep on your painful or affected side (Right)",
  Sleep_painful_affected: "Sleep on your painful or affected side",
  Sleep_painful_affected_L: "Sleep on your painful or affected side (Left)",
  Wash_back_R: "Wash back (Right)",
  Wash_back: "Wash back",
  Wash_back_L: "Wash back (Left)",
  Manage_toileting_R: "Manage toileting (Right)",
  Manage_toileting: "Manage toileting",
  Manage_toileting_L: "Manage toileting (Left)",
  Comb_hair_R: "Comb hair (Right)",
  Comb_hair: "Comb hair",
  Comb_hair_L: "Comb hair (Left)",
  Shave_apply_makeup_R: "Shave/apply makeup (Right)",
  Shave_apply_makeup: "Shave/apply makeup",
  Shave_apply_makeup_L: "Shave/apply makeup (Left)",
  Brush_teeth_R: "Brush teeth (Right)",
  Brush_teeth: "Brush teeth",
  Brush_teeth_L: "Brush teeth (Left)",
  Fill_glass_full_bottle: "Fill a glass with a full bottle",
  sitting: "sitting",
  Right: "Right",
  Drink: "Drink",
  bring_full_glass_mouth: "bring full glass to mouth",
  Left: "Left",
  Eat_soup: "Eat soup",
  full_spoon_mouth: "full spoon to mouth",
  Shake_someone_hand_door_R: "Shake someone’s hand / open a door (Right)",
  Shake_someone_hand_door: "Shake someone’s hand / open a door",
  Shake_someone_hand_door_L: "Shake someone’s hand / open a door (Left)",
  Use_phone: "Use a phone",
  at_level: "at ear level",
  Write_paper_keyboard_R:
    "Write a letter / sign on paper / use a keyboard (Right)",
  Write_paper_keyboard: "Write a letter / sign on paper / use a keyboard",
  Write_paper_keyboard_L:
    "Write a letter / sign on paper / use a keyboard (Left)",
  Reach_high_shelf: "Reach a high shelf",
  above_shoulder: "above shoulder",
  Lift_lbs_shoulder_R: "Lift 0.5kg (1 lbs) above the shoulder (Right)",
  Lift_lbs_shoulder: "Lift 0.5kg (1 lbs) above the shoulder",
  Lift_lbs_shoulder_L: "Lift 0.5kg (1 lbs) above the shoulder (Left)",
  Lift_4kg_shoulder_R: "Lift 4.5kg (10 lbs) above the shoulder (Right)",
  Lift_4kg_shoulder: "Lift 4.5kg (10 lbs) above the shoulder",
  Lift_4kg_shoulder_L: "Lift 4.5kg (10 lbs) above the shoulder (Left)",
  Can_carry_10kg_R:
    "Can you carry 10kg (22 lbs) at your side on the affected shoulder (Right)",
  Can_carry_10kg:
    "Can you carry 10kg (22 lbs) at your side on the affected shoulder",
  Can_carry_10kg_L:
    "Can you carry 10kg (22 lbs) at your side on the affected shoulder (Left)",
  Throw_underhand_R: "Throw a ball underhand (at least 3 meters) (Right)",
  Throw_underhand: "Throw a ball underhand (at least 3 meters)",
  Throw_underhand_L: "Throw a ball underhand (at least 3 meters) (Left)",
  Throw_overhand_R: "Throw a ball overhand (at least 6 meters) (Right)",
  Throw_overhand: "Throw a ball overhand (at least 6 meters)",
  Throw_overhand_L: "Throw a ball overhand (at least 6 meters) (Left)",
  Dofull_work_R: "Do full usual work (Right)",
  Dofull_work: "Do full usual work",
  Dofull_work_L: "Do full usual work (Left)",
  Please_list_activities: "Please list work activities",
  Do_full_sport_R: "Do full usual hobbies / sport (Right)",
  Do_full_sport: "Do full usual hobbies / sport",
  Do_full_sport_L: "Do full usual hobbies / sport (Left)",
  Please_hobbies_activitie: "Please list hobbies / sport activities",
  Does_shoulder_feel_unstable: "Does your shoulder feel unstable",
  as_going_dislocate: "as if it is going to dislocate",
  Please_indicate_shoulder_L:
    "Please indicate how unstable your shoulder is (Left)",
  Please_indicate_shoulder: "Please indicate how unstable your shoulder is",
  Please_indicate_shoulder_R:
    "Please indicate how unstable your shoulder is (Right)",
  Does_instability_joint: "Does you have instability in your AC joint?",
  Gerbers_LiftOff: "Gerbers Lift Off",
  Western_Ontario_Shoulder_Instability_Index:
    "Western Ontario Shoulder Instability Index",
  Instructions: "Instructions",
  You_questionnaire_problematic_symptoms:
    "You are asked to indicate on this part of the questionnaire the amount of a symptom you have experienced in the past week as related to your problematic shoulder. Simply move the slider along the line so that the slider corresponds accurately with your symptoms",
  The_further_experience_slider_indicates:
    "The further to the right you move the slider indicates the more you experience that symptom. The further Left you move the slider indicates the less you experience that symptom. If you have any questions regarding the intent of any particular question please ask.",
  Section_Physical_Symptoms: "Section A: Physical Symptoms",
  How_shoulder_activities_1:
    "How much pain do you experience in your shoulder with overhead activities?",
  No_pain: "No pain",
  How_throbbing_shoulder2:
    "How much aching or throbbing do you experience in your shoulder?",
  No_aching_throbbing: "No aching / throbbing",
  Extreme_aching_throbbing: "Extreme aching / throbbing",
  How_weakness_shoulder3:
    "How much weakness or lack of strength do you experience in your shoulder?",
  No_weakness: "No weakness",
  Extreme_weakness: "Extreme weakness",
  How_fatigue_shoulder4:
    "How much fatigue or lack of stamina do you experience in your shoulder?",
  How_cracking_shoulder5:
    "How much clicking cracking or snapping do you experience in your shoulder?",
  No_clicking: "No clicking",
  Extreme_clicking: "Extreme clicking",
  How_stiffness_shoulder6:
    "How much stiffness do you experience in your shoulder?",
  How_discomfort_shoulder7:
    "How much discomfort do you experience in your neck muscles as a result of your shoulder?",
  How_feeling_shoulder8:
    "How much feeling of instability or looseness do you experience in your shoulder?",
  How_compensate_muscles9:
    "How much do you compensate for your shoulder with other muscles?",
  How_loss_shoulder10:
    "How much loss of range of motion do you have in your shoulder?",
  Section_B_Sports_Recreation_Work: "Section B: Sports / Recreation / Work",
  How_shoulder_activities11:
    "How much has your shoulder limited the amount you can participate in sports or recreational activities?",
  How_shoulder_work12:
    "How much has your shoulder affected your ability to perform the specific skills required for your sport or work?",
  If_shoulder_affected:
    "If your shoulder affects both sports and work consider the area that is most affected.",
  How_feel_activities13:
    "How much do you feel the need to protect your arm during activities?",
  How_difficulty_level14:
    "How much difficulty do you experience lifting heavy objects below shoulder level?",
  Section_C_Lifestyle: "Section C: Lifestyle",
  How_fear_shoulder15: "How much fear do you have of falling on your shoulder?",
  How_difficulty_fitness16:
    "How much difficulty do you experience maintaining your desired level of fitness?",
  How_difficultly_friends17:
    "How much difficulty do you have “roughhousing or horsing around” with family or friends?",
  How_difficult_shoulder18:
    "How much difficulty do you have sleeping because of your shoulder?",
  Section_D_Emotions: "Section D: Emotions",
  How_conscious_shoulder19: "How conscious are you of your shoulder?",
  How_concerned_worse20:
    "How concerned are you about your shoulder becoming worse?",
  How_frustration_shoulder21:
    "How much frustration do you feel because of your shoulder?",
  The_Specific_Score: "The Specific AC Score (SACS)",
  Explanation: "Explanation",
  For_each_question_difficulty:
    "For each question you will be asked to rate either your level of pain or difficulty performing different tasks. The rating scale is designed so that 0 reflects no pain or difficulty while 10 reflects the most severe amount of pain or difficulty.",
  A_rating_minimal_levels:
    "A rating of 2 would represent minimal levels of pain. If you have any questions regarding the intent of any particular question please ask.",
  Section_A_Pain: "Section A: Pain",
  A1Rate_pain_shoulder:
    "Rate your level of pain in your shoulder when it is at its worst – most severe.",
  A2Rate_shoulder:
    "Rate your level of pain in your shoulder when it is at its best.",
  A3Rate_pain_shoulder:
    "Rate the level of pain when you press/ touch or there is pressure applied to the top of your shoulder.",
  Eg_Lap_top_strap: "Eg. Lap top or hand bag strap",
  A4Rate_pain_pulling:
    "Rate the level of pain tension or pulling sensation you feel in your neck and/or affected shoulder blade region.",
  Section_B_Function: "Section B: Function",
  B1Rate_amount_shoulder:
    "Rate the amount of clicking and/or grinding and/or clunking you experience in your shoulder.",
  B2Indicate_walking:
    "Indicate if your arm feels the need to be supported when walking",
  eResting_pocket_sling: "e.g. Resting your arm in a pocket or using a sling",
  B3Rate_difficulty_arm:
    "Rate your level of difficulty taking weight or leaning through your arm",
  ePush_yoga: "e.g. Push up cycling yoga",
  B4Rate_level_minutes:
    "Rate your level of difficulty carrying heavy objects by your side for greater than 5 minutes",
  eBag_shopping: "e.g. Bag of shopping",
  B5Rate_level_affected_arm:
    "Rate the level of difficulty reaching across your body with your affected arm",
  eTounaffected_shoulder:
    "e.g. To reach across and touch the unaffected shoulder",
  B6Rate_difficulty_overhead:
    "Rate your level of difficulty pushing heavy objects or weights overhead",
  eGreater_five_kilograms: "eg. Greater than five kilograms",
  B7Rate_level_period:
    "Rate the level of difficulty holding your affected arm in front of you for a prolonged period",
  eHolding_teering_whilst_driving:
    "e.g. Holding the steering wheel whilst driving",
  B8Rate_difficulty_occupation:
    "Rate the level of difficulty you experience in performing your usual occupation.",
  B9Rate_change:
    "Rate how much you have had to change/modify your usual occupation in order to compensate for your shoulder problem.",
  B10Rate_difficulty_activity:
    "Rate the level of difficulty you experience in performing your primary sporting activity.",
  B11Rate_you:
    "Rate how much you have had to modify your primary sporting activity in order to compensate for your shoulder problem.",
  Section_C_Quality_satisfaction: "Section C: Quality of life and satisfaction",
  C1Rate_level_appearance:
    "Rate the level of difference in appearance of the bones on the top of your shoulder compared to a normal shoulder",
  includin_presence_lump: "including the presence of a lump",
  C2Rate_overall:
    "Rate the overall level of anxiety or stress your shoulder problem has caused you.",
  C3How_aware_shoulder: "How often are you aware of your shoulder problem?",
  C4Have_modified:
    "Have you modified your lifestyle to avoid potentially damaging or aggravating your shoulder problem?",
  C5unhappy_shoulder: "How unhappy/dissatisfied are you with your shoulder?",
  C6How_shoulder:
    "How would you rate your shoulder overall as a percentage compared to your expectations of a ‘normal’ shoulder where 0% is poor and 100% is very good?",
  Satisfaction: "Satisfaction",
  In_regards_affected_shoulder: "In regards to your affected shoulder",
  at_present: "at present",
  are_you_L: "are you (Left)",
  are_you: "are you",
  are_you_R: "are you (Right)",
  CONSTANT_SCORE: "CONSTANT SCORE",
  Shoulder: "Shoulder",
  Right_Shoulder: "Right Shoulder",
  Left_Shoulder: "Left Shoulder",
  WOSI: "WOSI",
  SACS: "SACS",
  SST: "SST",
  ASES: "ASES",
  UCLA: "UCLA",
  Forward_Flexion: "Active Forward Flexion",
  Right_ArS: "Right",
  Left_ArS: "Left",
  Forward_Flexion_Right: "Forward Flexion Right",
  Forward_Flexion_Left: "Forward Flexion Left",
  Passive_Forward_Elevation: "Passive Forward Elevation",
  Passive_Forward_Elevation_Right: "Passive Forward Elevation Right",
  Passive_Forward_Elevation_Left: "Passive Forward Elevation Left",
  Strength_Forward_Flexion: "Strength of Forward Flexion",
  Lateral_Elevation: "Active Lateral Elevation",
  Lateral_Elevation_Right: "Lateral Elevation Right",
  Lateral_Elevation_Left: "Lateral Elevation Right",
  Passive_Lateral_Elevation: "Passive Lateral Elevation",
  Passive_Lateral_Elevation_Right: "Passive Lateral Elevation_Right",
  Passive_Lateral_Elevation_Left: "Passive Lateral Elevation_Left",
  Strength_Lateral_Elevation: "Strength of Lateral Elevation",
  Strength_Lateral_Elevation_Right: "Strength of Lateral Elevation_Right",
  Strength_Lateral_Elevation_Left: "Strength of Lateral Elevation_Left",
  External_Rotation_ER1: "Active External Rotation (ER1)",
  Passive_External_Rotation_ER1: "Passive External Rotation (ER1)",
  External_Rotation_ER2: "External Rotation (ER2)",
  Hand_behind_Head_Elbow_Forward: "Hand behind head, elbow forward",
  Hand_behind_Head_Elbow_Back: "Hand behind head, elbow back",
  Hand_top_Head_Elbow_Forward: "Hand to top of head, elbow forward",
  Hand_top_Head_Elbow_Back: "Hand to top of head, elbow back",
  Full_Elevation: "Full elevation",
  Hand_behind_head_elbow_forward: "Hand behind head, elbow forward",
  Hand_behind_head_elbow_back: "Hand behind head, elbow back",
  Hand_top_head_elbow_forward: "Hand to top of head, elbow forward",
  Hand_top_head_elbow_back: "Hand to top of head, elbow back",
  Full_elevation: "Full elevation",

  Arm_Position: "Arm Position",
  Waist: "Waist",
  Xiphoid: "Xiphoid",
  Neck: "Neck",
  Top_of_head: "Top of head",
  Above_head: "Above head",
  Internal_Rotation_IR1: "Internal Rotation (IR1)",
  Lateral_Thigh: "Lateral Thigh",
  Buttock: "Buttock",
  Lumbosacral_Junction: "Lumbosacral Junction",
  Waist_L3: "Waist (L3)",
  T12_Vertebra: "T12 Vertebra",
  Interscapular_T7: "Interscapular (T7)",
  Internal_Rotation_IR2: "Active Internal Rotation (IR2)",
  characters_remaining: "characters remaining",
  Bear_Hug: "Bear Hug",
  Gerbers_Lift_Off: "Yocums Test",
  Napoleon_Belly_Press: "Napoleon Belly Press",
  Jobes_Test: "Jobes Test",
  Horn_Blowers: "Horn Blowers",
  AP_Instablility: "AP Instability",
  Paxinos_Test: "Paxinos Test",
  Whipple: "Whipple",
  O_Briens_Test: "O’Briens Test",
  Yergasons_Test: "Yergasons Test",
  Speeds_Test: "Speeds Test",
  Popeye: "Popeye",
  A_ER_S: "A ER S",
  Deltoid_Power_Strength: "Deltoid Power and Strength",
  Ant_Deltoid: "Ant Deltoid",
  Mid_Deltoid: "Mid Deltoid",
  Post_Deltoid: "Post Deltoid",
  C_Spine_Mobility: "C Spine Mobility",
  Suprascapular_Nerve_Stretch_Test: "Suprascapular Nerve Stretch Test",
  Hyper_Extension_Rotation_HERI_Test:
    "Hyper Extension-Internal Rotation (HERI) Test",
  GAGEY_Hyperabduction_Test: "GAGEY Hyperabduction Test",
  Thoracic_Outlet_Obstruction: "Thoracic Outlet Obstruction",
  Outcome: "Outcome",
  Ligamentous_Laxity: "Ligamentous Laxity",
  Subscapularis: "Subscapularis",
  Supraspinatus: "Supraspinatus",
  Infraspinatus: "Infraspinatus",
  Teres_Minor: "Teres Minor",
  ACJ: "ACJ",
  Labrum: "Labrum",
  Biceps: "Biceps",
  Triceps_Strength: "Triceps Strength",
  Deltoid: "Deltoid",
  C_Spine: "C Spine",
  SSN_Stretch: "SSN Stretch",
  HERI: "HERI",
  GAGEY: "GAGEY",
  Scapula_Dyskinesia: "Scapula Dyskinesia",
  Thoracic_Outlet: "Thoracic Outlet",
  Grip_Strength: "Grip Strength",
  foot_Quality_of_Life: "Quality of Life",
  How_often_foot_ankle_problem_R:
    "How often are you aware of your foot/ankle problem? (Right)",
  How_often_foot_ankle_problem:
    "How often are you aware of your foot/ankle problem?",
  How_often_foot_ankle_problem_L:
    "How often are you aware of your foot/ankle problem? (Left)",
  Have_you_modified_your_lifestyle_foot_ankle_R:
    "Have you modified your lifestyle to avoid potentially damaging activities to your foot/ankle? (Right)",
  Have_you_modified_your_lifestyle_foot_ankle:
    "Have you modified your lifestyle to avoid potentially damaging activities to your foot/ankle?",
  Have_you_modified_your_lifestyle_foot_ankle_L:
    "Have you modified your lifestyle to avoid potentially damaging activities to your foot/ankle? (Left)",
  How_much_are_you_troubled_foot_ankle_R:
    "How much are you troubled with the lack of confidence in your foot/ankle? (Right)",
  How_much_are_you_troubled_foot_ankle:
    "How much are you troubled with the lack of confidence in your foot/ankle?",
  How_much_are_you_troubled_foot_ankle_L:
    "How much are you troubled with the lack of confidence in your foot/ankle? (Left)",
  In_general_foot_ankle_R:
    "In general how much difficulty do you have with your foot/ankle?(Right)",
  In_general_foot_ankle:
    "In general how much difficulty do you have with your foot/ankle?",
  In_general_foot_ankle_L:
    "In general how much difficulty do you have with your foot/ankle?(Left)",
  How_anxious_today_R: "How anxious/depressed are you today?(Right)",
  How_anxious_today: "How anxious/depressed are you today?",
  How_anxious_today_L: "How anxious/depressed are you today?(Left)",
  How_good_bad:
    "How good/bad is your health today?(100 means best health 0 means worst health you can imagine )",
  How_good_badR:
    "How good/bad is your health today?(100 means best health 0 means worst health you can imagine ) (Right)",
  How_good_badL:
    "How good/bad is your health today?(100 means best health 0 means worst health you can imagine ) (Left)",
  Foot_Ankle_Satisfaction: "Foot Ankle Satisfaction",
  How_satisfied_are_you_with_the_results_of_your_surgery:
    "How satisfied are you with the results of your treatment?",
  For_providing_symptom_relief_R: "For providing symptom relief? (Right)",
  For_providing_symptom_relief: "For providing symptom relief ?",
  For_providing_symptom_relief_L: "For providing symptom relief? (Left)",
  For_improving_ability_activities_R:
    "For improving your ability to perform daily activities? (Right)",
  For_improving_ability_activities:
    "For improving your ability to perform daily activities?",
  For_improving_ability_activities_L:
    "For improving your ability to perform daily activities? (Left)",
  forgetting_your_foot_ankle_problem_R:
    "For “forgetting” your foot/ankle problem? (awareness of foot/ankle problem) (Right)",
  forgetting_your_foot_ankle_problem:
    "For “forgetting” your foot/ankle problem? (awareness of foot/ankle problem)",
  forgetting_your_foot_ankle_problem_L:
    "For “forgetting” your foot/ankle problem? (awareness of foot/ankle problem) (Left)",
  Foot_Ankle_Complete: "Foot Ankle Complete",
  CUMBERLAND_ANKLE_INSTABILITY_TOOL: "CUMBERLAND ANKLE INSTABILITY TOOL",
  Evaluated_foot_ankle: "Evaluated foot/ankle",
  I_have_pain_in_my_ankle_R: "I have pain in my ankle (Right)",
  I_have_pain_in_my_ankle_: "I have pain in my ankle",
  I_have_pain_in_my_ankle_L: "I have pain in my ankle (Left)",
  UNSTABLE_R: "My ankle feels unstable when (Right)",
  UNSTABLE_: "My ankle feels unstable when",
  UNSTABLE_L: "My ankle feels unstable when (Left)",
  SHARP_turns_R: "When I make SHARP turns my ankle feels UNSTABLE (Right)",
  SHARP_turns_: "When I make SHARP turns my ankle feels UNSTABLE",
  SHARP_turns_L: "When I make SHARP turns my ankle feels UNSTABLE (Left)",
  Down_the_stairs_UNSTABLE_R:
    "When going down the stairs my ankle feels UNSTABLE (Right)",
  Down_the_stairs_UNSTABLE_:
    "When going down the stairs my ankle feels UNSTABLE",
  Down_the_stairs_UNSTABLE_L:
    "When going down the stairs my ankle feels UNSTABLE (Left)",
  My_ankle_feels_UNSTABLE_R:
    "My ankle feels UNSTABLE when standing on ONE leg (Right)",
  My_ankle_feels_UNSTABLE_: "My ankle feels UNSTABLE when standing on ONE leg",
  My_ankle_feels_UNSTABLE_L:
    "My ankle feels UNSTABLE when standing on ONE leg (Left)",
  when_I_start_to_roll_over_R:
    "TYPICALLY when I start to roll over (or “twist”) on my ankle I can stop it (Right)",
  when_I_start_to_roll_over_:
    "TYPICALLY when I start to roll over (or “twist”) on my ankle I can stop it",
  when_I_start_to_roll_over_L:
    "TYPICALLY when I start to roll over (or “twist”) on my ankle I can stop it (Left)",
  After_a_TYPICAL_incident_R:
    "After a TYPICAL incident of my ankle rolling over my ankle returns to “normal” (Right)",
  After_a_TYPICAL_incident_:
    "After a TYPICAL incident of my ankle rolling over my ankle returns to “normal”",
  After_a_TYPICAL_incident_L:
    "After a TYPICAL incident of my ankle rolling over my ankle returns to “normal” (Left)",
  ABNORMAL_GAIT: "Abnormal gait",
  Foot_R: "Right",
  A_Hid: "Alignment (Ankle/Hindfoot)",
  Foot_L: "Left",
  ALIGNMENT: "ALIGNMENT",
  Ankle_hindfoot: "Ankle-hindfoot",
  Midfoot: "Midfoot",
  Hallux: "Hallux",
  Secondnd_toe: "2nd toe",
  Third_toe: "3rd toe",
  Fourth_toe: "4th toe",
  Fifth_toe: "5th toe",
  DEFORMITY: "Other Deformity",
  Absent: "Absent",
  Present: "Present",
  CONTRACTURES: "CONTRACTURES",
  CALLUS: "CALLUS",
  Dorsiflexion_R: "Dorsiflexion (Right)",
  Dorsiflexion_L: "Dorsiflexion (Left)",
  Plantarflexion_R: "Plantarflexion(Right)",
  Plantarflexion_L: "Plantarflexion(Left)",
  Hindfoot: "AOFAS Hindfoot",
  MidfootScale: "AOFAS MidfootScale",
  HalluxScale: "AOFAS HalluxScale",
  LesserToeScale: "AOFAS LesserToeScale",
  Inversion_R: "Inversion (Right)",
  Inversion_L: "Inversion (Left)",
  Eversion_R: "Eversion(Right)",
  Eversion_L: "Eversion(Left)",
  MPTJ: "MPTJ (Dorsiflexion plus plantarflexion)",
  Hallux_R: "Hallux (Right)",
  Hallux_L: "Hallux (Left)",
  Second_R: "2nd (Right)",
  Second_L: "2nd (Left)",
  Third_R: "3rd (Right)",
  Third_L: "3rd (Left)",
  Fourth_R: "4th (Right)",
  Fourth_L: "4th (Left)",
  Fifth_R: "5th (Right)",
  Fifth_L: "5th (Left)",
  PIPJ: "PIPJ (Plantarflexion)",
  DIPJ: "DIPJ (Dorsiflexion plus plantarflexion)",
  STABILITY: "STABILITY",
  SPECIAL_TESTS: "SPECIAL TESTS",
  Silfverskiold_R: "Silfverskiold test (Right)",
  Silfverskiold_L: "Silfverskiold test (Left)",
  Too_many_toes_sign_R: "Too many toes sign (Right)",
  Too_many_toes_sign_L: "Too many toes sign (Left)",
  Peek_a_boo_heel_sign_R: "Peek-a-boo heel sign (Right)",
  Peek_a_boo_heel_sign_L: "Peek-a-boo heel sign (Left)",
  Anterior_drawer_test_R: "Anterior drawer test (Right)",
  Anterior_drawer_test_L: "Anterior drawer test (Left)",
  Posterior_drawer_test_R: "Posterior drawer test (Right)",
  Posterior_drawer_test_L: "Posterior drawer test (Left)",
  Talar_tilt_test_Inversion_R: "Talar tilt test Inversion (Right)",
  Talar_tilt_test_Inversion_L: "Talar tilt test Inversion (Left)",
  Talar_tilt_test_Eversion_R: "Talar tilt test Eversion (Right)",
  Talar_tilt_test_Eversion_L: "Talar tilt test Eversion (Left)",
  Calf_compression_test_R: "Calf compression or “squeeze” test (Right)",
  Calf_compression_test_L: "Calf compression or “squeeze” test (Left)",
  External_rotation_stress_test_R:
    "External rotation stress or Kleiger’s test (Right)",
  External_rotation_stress_test_L:
    "External rotation stress or Kleiger’s test (Left)",
  Coleman_block_test_R: "Coleman block test (Right)",
  Coleman_block_test_L: "Coleman block test (Left)",
  Double_leg_heel_raise_R: "Double-leg heel raise (Right)",
  Double_leg_heel_raise_L: "Double-leg heel raise (Left)",
  Single_leg_heel_raise_R: "Single-leg heel raise (Right)",
  Single_leg_heel_raise_L: "Single-leg heel raise (Left)",
  Thompson_test_R: "Thompson’s test (Right)",
  Thompson_test_L: "Thompson’s test (Left)",
  Mulder_test_R: "Mulder’s test (Right)",
  Mulder_test_L: "Mulder’s test (Left)",
  Posterior_crunch_test_R: "Posterior crunch test(Right)",
  Posterior_crunch_test_L: "Posterior crunch test(Left)",
  Ligamentous_laxity_R: "Ligamentous laxity (Beighton Score) (Right)",
  Ligamentous_laxity_L: "Ligamentous laxity (Beighton Score) (Left)",
  Foot_Ankle_Results: "Foot Ankle Results",
  Below_pain_Foot_ankle:
    "Below is your pain and Foot/ankle function score which is using your",
  answers_Foot_ankle: "answers from the Foot/ankle scoring self-evaluation.",
  FAAM_ADL_SCORE: "FAAM ADL SCORE",
  FAAM_SPORTS: "FAAM SPORTS",
  FADI_SCORE: "FADI SCORE",
  FADI_SPORTS: "FADI SPORTS",
  FAOS_PAIN: "FAOS PAIN",
  FAOS_SYMPTOMS: "FAOS SYMPTOMS",
  FAOS_ADL: "FAOS ADL",
  FAOS_SPORT: "FAOS SPORT",
  FAOS_QOL: "FAOS QOL",
  VAS_PAINR: "VAS PAIN Right",
  VAS_PAINL: "VAS PAIN Left",
  VAS_PAIN: "VAS PAIN",
  SATISFACTION: "SATISFACTION",
  EQ_5D_5L_Mobility: "EQ-5D-5L Mobility",
  EQ_5D_5L_Mobility_HIP: "Perceived Problem with Mobility",
  EQ_5D_5L_SELF_CARE: "EQ-5D-5L SELF CARE",
  EQ_5D_5L_Usual_Activities: "EQ-5D-5L Usual Activities",
  EQ_5D_5L_Anxiety: "EQ-5D-5L Anxiety",
  EQ_5D_5L_Pain_Discomfort: "EQ-5D-5L Pain/Discomfort",
  CAIT: "CAIT",
  BCTQ_Symptom: "BCTQ Symptom",
  BCTQ_Function: "BCTQ Function",
  uram: "URAM",
  Describe_the_pain_hand_wrist:
    "Describe the pain in your hand/wrist in the PAST WEEK?",
  Describe_the_pain_hand_wristR:
    "Describe the pain in your hand/wrist in the PAST WEEK? (Right)",
  Describe_the_pain_hand_wristL:
    "Describe the pain in your hand/wrist in the PAST WEEK? (Left)",
  how_bad_This_Moment: "How bad is the pain in your hand/wrist AT THIS MOMENT?",
  how_bad_This_MomentR:
    "How bad is the pain in your hand/wrist AT THIS MOMENT? (Right)",
  how_bad_This_MomentL:
    "How bad is the pain in your hand/wrist AT THIS MOMENT? (Left)",
  How_often_hand_wrist_past_week_R:
    "How often did the pain in your hand/wrist interfere with your daily activities (such as eating or bathing) in the past week? (Right)?",
  How_often_hand_wrist_past_week:
    "How often did the pain in your hand/wrist interfere with your daily activities (such as eating or bathing) in the past week?",
  How_often_hand_wrist_past_week_L:
    "How often did the pain in your hand/wrist interfere with your daily activities (such as eating or bathing) in the past week? (Left)?",
  Hand_And_Wrist_Symptoms: "Other Symptoms",
  How_severe_tingling_hand_R:
    "How severe was the tingling (pins and needles) in your arm shoulder or hand in the past week? (Right)?",
  How_severe_tingling_hand:
    "How severe was the tingling (pins and needles) in your arm shoulder or hand in the past week?",
  How_severe_tingling_hand_L:
    "How severe was the tingling (pins and needles) in your arm shoulder or hand in the past week? (Left)?",
  How_was_the_sensation_hand_during_the_past_week_R:
    "How was the sensation (feeling) in your hand during the past week? (Right)?",
  How_was_the_sensation_hand_during_the_past_week:
    "How was the sensation (feeling) in your hand during the past week?",
  How_was_the_sensation_hand_during_the_past_week_L:
    "How was the sensation (feeling) in your hand during the past week? (Left)?",
  The_appearance_of_my_hand_interferes_R:
    "The appearance of my hand interferes with my normal daily activities (Right)?",
  The_appearance_of_my_hand_interferes:
    "The appearance of my hand interferes with my normal daily activities ?",
  The_appearance_of_my_hand_interferes_L:
    "The appearance of my hand interferes with my normal daily activities (Left)?",
  How_would_you_rate_your_hand_wrist_today_percentage_of_normal:
    "How would you rate your hand/wrist today as a percentage of normal? (0-100% with 100% being normal)",
  How_would_you_rate_your_hand_wrist_today_percentage_of_normalR:
    "How would you rate your hand/wrist today as a percentage of normal? (0-100% with 100% being normal) (Right)",
  How_would_you_rate_your_hand_wrist_today_percentage_of_normalL:
    "How would you rate your hand/wrist today as a percentage of normal? (0-100% with 100% being normal) (Left)",
  Hand_And_Wrist_Function: "Function",
  Overall_how_well_did_you_hand_R:
    "Overall how well did you hand work during the past week? (Right)?",
  Overall_how_well_did_you_hand:
    "Overall how well did you hand work during the past week?",
  Overall_how_well_did_you_hand_L:
    "Overall how well did you hand work during the past week? (Left)?",
  How_often_were_you_UNABLE_TO_DO_YOUR_WORK_hand_R:
    "How often were you UNABLE TO DO YOUR WORK because of problems with your hand/wrist? (Right)?",
  How_often_were_you_UNABLE_TO_DO_YOUR_WORK_hand:
    "How often were you UNABLE TO DO YOUR WORK because of problems with your hand/wrist?",
  How_often_were_you_UNABLE_TO_DO_YOUR_WORK_hand_L:
    "How often were you UNABLE TO DO YOUR WORK because of problems with your hand/wrist? (Left)?",
  How_often_did_you_TAKE_LONGER_TO_DO_TASKS_R:
    "How often did you TAKE LONGER TO DO TASKS in your work because of problems with your hand/wrist? (Right)?",
  How_often_did_you_TAKE_LONGER_TO_DO_TASKS:
    "How often did you TAKE LONGER TO DO TASKS in your work because of problems with your hand/wrist?",
  How_often_did_you_TAKE_LONGER_TO_DO_TASKS_L:
    "How often did you TAKE LONGER TO DO TASKS in your work because of problems with your hand/wrist? (Left)?",
  Hand_wrist_Indicate_Degree:
    "Indicate the DEGREE OF DIFFICULTY to do the following activities in the last week",
  Open_a_tight_or_new_jar_R: "Open a tight or new jar (Right)",
  Open_a_tight_or_new_jar: "Open a tight or new jar",
  Open_a_tight_or_new_jar_L: "Open a tight or new jar (Left)",
  Hold_a_frying_pain_R: "Hold a frying pain (Right)",
  Hold_a_frying_pain: "Hold a frying pain",
  Hold_a_frying_pain_L: "Hold a frying pain (Left)",
  Button_a_shirt_or_blouse_R: "Button a shirt or blouse (Right)",
  Button_a_shirt_or_blouse: "Button a shirt or blouse",
  Button_a_shirt_or_blouse_L: "Button a shirt or blouse (Left)",
  Do_heavy_household_chores_hand_R:
    "Do heavy household chores (eg wash walls floors) (Right)",
  Do_heavy_household_chores_hand_L:
    "Do heavy household chores (eg wash walls floors) (Left)",
  Do_heavy_household_chores_hand:
    "Do heavy household chores (eg wash walls floors)",
  Carry_shopping_bag_or_briefcase_R:
    "Carry a shopping bag or briefcase (Right)",
  Carry_shopping_bag_or_briefcase: "Carry a shopping bag or briefcase",
  Carry_shopping_bag_or_briefcase_L: "Carry a shopping bag or briefcase (Left)",
  Use_knife_to_cut_food_R: "Use a knife to cut food (Right)",
  Use_knife_to_cut_food: "Use a knife to cut food",
  Use_knife_to_cut_food_L: "Use a knife to cut food (Left)",
  Hand_wrist_Recreational_activities_R:
    "Recreational activities in which you take some force or impact through your arm, shoulder, or hand (eg golf, hammering, tennis etc) (Right)",
  Hand_wrist_Recreational_activities:
    "Recreational activities in which you take some force or impact through your arm, shoulder, or hand (eg golf, hammering, tennis etc)",
  Hand_wrist_Recreational_activities_L:
    "Recreational activities in which you take some force or impact through your arm, shoulder, or hand (eg golf, hammering, tennis etc) (Left)",
  Sleeping_because_of_pain_hand_R:
    "Sleeping because of pain in your arm, shoulder, or hand (Right)",
  Sleeping_because_of_pain_hand:
    "Sleeping because of pain in your arm, shoulder, or hand",
  Sleeping_because_of_pain_hand_L:
    "Sleeping because of pain in your arm, shoulder, or hand (Left)",
  Do_not_work_R: "I do not work(Right)",
  Do_not_work_L: "I do not work(Left)",
  Do_not_work: "I do not work",
  Using_your_usual_technique_for_work_R:
    "Using your usual technique for work (Right)",
  Using_your_usual_technique_for_work: "Using your usual technique for work",
  Using_your_usual_technique_for_work_L:
    "Using your usual technique for work (Left)",
  Doing_your_work_R: "Doing your work as well as you would like (Right)",
  Doing_your_work: "Doing your work as well as you would like",
  Doing_your_work_L: "Doing your work as well as you would like (Left)",
  Spending_R: "Spending your usual amount of time doing your work (Right)",
  Spending: "Spending your usual amount of time doing your work",
  Spending_L: "Spending your usual amount of time doing your work (Left)",
  Specify_sport_musical_instrument_played_R:
    "Specify sport or musical instrument played (Right)",
  Specify_sport_musical_instrument_played:
    "Specify sport or musical instrument played",
  Specify_sport_musical_instrument_played_L:
    "Specify sport or musical instrument played(Left)",
  Using_usual_technique_for_playing_instrument_sport_R:
    "Using your usual technique for playing your instrument or sport (Right)",
  Using_usual_technique_for_playing_instrument_sport:
    "Using your usual technique for playing your instrument or sport",
  Using_usual_technique_for_playing_instrument_sport_L:
    "Using your usual technique for playing your instrument or sport (Left)",
  Playing_your_musical_instrument_R:
    "Playing your musical instrument or sport as well as you would like (Right)",
  Playing_your_musical_instrument:
    "Playing your musical instrument or sport as well as you would like",
  Playing_your_musical_instrument_L:
    "Playing your musical instrument or sport as well as you would like (Left)",
  Spending_time_practising_instrument_sport_R:
    "Spending your usual amount of time practising or playing your instrument or sport (Right)",
  Spending_time_practising_instrument_sport:
    "Spending your usual amount of time practising or playing your instrument or sport",
  Spending_time_practising_instrument_sport_L:
    "Spending your usual amount of time practising or playing your instrument or sport (Left)",
  During_the_past_week_friends_neighbours_or_groups_R:
    "During the past week to what extent has your arm shoulder or hand problem interfered your NORMAL SOCIAL/LEISURE ACTIVITIES with family friends neighbours or groups? (Right)",
  During_the_past_week_friends_neighbours_or_groups:
    "During the past week to what extent has your arm shoulder or hand problem interfered your NORMAL SOCIAL/LEISURE ACTIVITIES with family friends neighbours or groups?",
  During_the_past_week_friends_neighbours_or_groups_L:
    "During the past week to what extent has your arm shoulder or hand problem interfered your NORMAL SOCIAL/LEISURE ACTIVITIES with family friends neighbours or groups? (Left)",
  limited_work_or_other_regular_daily_activities_as_a_result_hand_problem_R:
    "During the past week were you limited in your WORK OR OTHER REGULAR DAILY ACTIVITIES as a result of your arm shoulder or hand problem? (Right)",
  limited_work_or_other_regular_daily_activities_as_a_result_hand_problem:
    "During the past week were you limited in your WORK OR OTHER REGULAR DAILY ACTIVITIES as a result of your arm shoulder or hand problem?",
  limited_work_or_other_regular_daily_activities_as_a_result_hand_problem_L:
    "During the past week were you limited in your WORK OR OTHER REGULAR DAILY ACTIVITIES as a result of your arm shoulder or hand problem? (Left)",
  Specify_usual_work_R:
    "Specify usual work (work include homemaking if that is your main role)",
  Write_usual_work_L: "Write Specify work",
  Hand_And_Wrist_Quality_Of_Life: "General Health/Quality of Life",
  Please_rate_HEALTH_TODAY:
    "Please rate the following to best describe your HEALTH TODAY",
  Please_rate_HEALTH_TODAY_HAND_WRIST:
    "Please state how satisfied you are with the following",
  Problem_in_walking_R: "Problem in walking about (Right)",
  Problem_in_walking: "Problem in walking about",
  Problem_in_walking_L: "Problem in walking about (Left)",
  Problem_with_washing_or_drying_self_R:
    "Problem with washing or drying self (Right)",
  Problem_with_washing_or_drying_self: "Problem with washing or drying self",
  Problem_with_washing_or_drying_self_L:
    "Problem with washing or drying self (Left)",
  Problem_with_doing_usual_activities_R:
    "Problem with doing usual activities (eg work study housework family or leisure activities) (Right)",
  Problem_with_doing_usual_activities:
    "Problem with doing usual activities (eg work study housework family or leisure activities)",
  Problem_with_doing_usual_activities_L:
    "Problem with doing usual activities (eg work study housework family or leisure activities) (Left)",
  Pain_or_discomfort_R: "Pain or discomfort (Right)",
  Pain_or_discomfort: "Pain or discomfort",
  Pain_or_discomfort_L: "Pain or discomfort (Left)",
  Anxiety_Depression_R: "Anxiety/Depression (Right)",
  Anxiety_Depression: "Anxiety/Depression",
  Anxiety_Depression_L: "Anxiety/Depression (Left)",
  How_would_you_rate_your_hand_wrist_today_percentage_of_normal_HAND_WRIST:
    "How good or bad your health is TODAY? (100 means best health 0 means worst health you can imagine)",
  How_would_you_rate_your_hand_wrist_today_percentage_of_normal_HAND_WRISTR:
    "How good or bad your health is TODAY? (100 means best health 0 means worst health you can imagine) (Right)",
  How_would_you_rate_your_hand_wrist_today_percentage_of_normal_HAND_WRISTL:
    "How good or bad your health is TODAY? (100 means best health 0 means worst health you can imagine) (Left)",
  Hand_And_Wrist_Satisfaction: "Satisfaction",
  Motion_of_your_FINGER_R: "Motion of your FINGER? (Right)",
  Motion_of_your_FINGER: "Motion of your FINGER?",
  Motion_of_your_FINGER_L: "Motion of your FINGER? (Left)",
  Motion_of_your_WRIST_R: "Motion of your WRIST (Right)",
  Motion_of_your_WRIST: "Motion of your WRIST",
  Motion_of_your_WRIST_L: "Motion of your WRIST (Left)",
  Look_of_your_HAND_R: "Look of your HAND (Right)",
  Look_of_your_HAND: "Look of your HAND",
  Look_of_your_HAND_L: "Look of your HAND (Left)",
  Your_hand_wrist_surgery_R: "Your hand/wrist treatment (Right)",
  Your_hand_wrist_surgery: "Your hand/wrist treatment",
  Your_hand_wrist_surgery_L: "Your hand/wrist treatment (Left)",
  Hand_And_Wrist_BCTQ: "Hand And Wrist BCTQ",
  Carpal_Tunnel: "Carpal Tunnel Syndrome Questionnaire",
  Symptom: "Symptom",
  How_severe_hand_wrist_pain_at_night_R:
    "How severe is the hand or wrist pain that you have at night? (Right)",
  How_severe_hand_wrist_pain_at_night:
    "How severe is the hand or wrist pain that you have at night?",
  How_severe_hand_wrist_pain_at_night_L:
    "How severe is the hand or wrist pain that you have at night? (Left)",
  How_often_did_typical_night_past_2_weeks_R:
    "How often did hand or wrist pain wake you up during a typical night in the past 2 weeks? (Right)",
  How_often_did_typical_night_past_2_weeks:
    "How often did hand or wrist pain wake you up during a typical night in the past 2 weeks?",
  How_often_did_typical_night_past_2_weeks_L:
    "How often did hand or wrist pain wake you up during a typical night in the past 2 weeks? (Left)",
  Do_you_typically_have_pain_daytime_R:
    "Do you typically have pain in your hand or wrist during the daytime? (Right)",
  Do_you_typically_have_pain_daytime:
    "Do you typically have pain in your hand or wrist during the daytime?",
  Do_you_typically_have_pain_daytime_L:
    "Do you typically have pain in your hand or wrist during the daytime? (Left)",
  How_often_hand_wrist_pain_during_daytime_R:
    "How often do you have hand or wrist pain during daytime? (Right)",
  How_often_hand_wrist_pain_during_daytime:
    "How often do you have hand or wrist pain during daytime?",
  How_often_hand_wrist_pain_during_daytime_L:
    "How often do you have hand or wrist pain during daytime? (Left)",
  How_long_on_average_does_an_episode_daytime_R:
    "How long on average does an episode of pain last during the daytime? (Right)",
  How_long_on_average_does_an_episode_daytime:
    "How long on average does an episode of pain last during the daytime?",
  How_long_on_average_does_an_episode_daytime_L:
    "How long on average does an episode of pain last during the daytime? (Left)",
  Do_you_have_numbness_R:
    "Do you have numbness (loss of sensation) in your hand? (Right)",
  Do_you_have_numbness: "Do you have numbness (loss of sensation) in your hand",
  Do_you_have_numbness_L:
    "Do you have numbness (loss of sensation) in your hand? (Left)",
  Do_you_have_weakness_hand_wrist_R:
    "Do you have weakness in your hand or wrist?(Right)",
  Do_you_have_weakness_hand_wrist:
    "Do you have weakness in your hand or wrist?",
  Do_you_have_weakness_hand_wrist_L:
    "Do you have weakness in your hand or wrist?(Left)",
  Do_you_have_tingling_sensation_hand_R:
    "Do you have tingling sensation in your hand?(Right)",
  Do_you_have_tingling_sensation_hand:
    "Do you have tingling sensation in your hand?",
  Do_you_have_tingling_sensation_hand_L:
    "Do you have tingling sensation in your hand?(Left)",
  How_severe_is_numbness_tingling_at_night_R:
    "How severe is numbness (loss of sensation) or tingling at night?(Right)",
  How_severe_is_numbness_tingling_at_night:
    "How severe is numbness (loss of sensation) or tingling at night?",
  How_severe_is_numbness_tingling_at_night_L:
    "How severe is numbness (loss of sensation) or tingling at night?(Left)",
  How_often_did_hand_numbness_past_2_weeks_R:
    "How often did hand numbness or tingling wake you up during a typical night in the past 2 weeks? (Right)",
  How_often_did_hand_numbness_past_2_weeks:
    "How often did hand numbness or tingling wake you up during a typical night in the past 2 weeks?",
  How_often_did_hand_numbness_past_2_weeks_L:
    "How often did hand numbness or tingling wake you up during a typical night in the past 2 weeks? (Left)",
  Rate_the_degree_of_difficulty:
    "Rate the degree of difficulty in doing the following activities",
  Difficulty_grasping_and_using_pens_R:
    "Difficulty grasping and using small objects such as keys or pens (Right)",
  Difficulty_grasping_and_using_pens:
    "Difficulty grasping and using small objects such as keys or pens",
  Difficulty_grasping_and_using_pens_L:
    "Difficulty grasping and using small objects such as keys or pens (Left)",
  Writing_R: "Writing (Right)",
  Writing: "Writing",
  Writing_L: "Writing (Left)",
  Buttoning_of_clothes_R: "Buttoning of clothes(Right)",
  Buttoning_of_clothes: "Buttoning of clothes",
  Buttoning_of_clothes_L: "Buttoning of clothes(Left)",
  Holding_a_book_while_reading_R: "Holding a book while reading(Right)",
  Holding_a_book_while_reading: "Holding a book while reading",
  Holding_a_book_while_reading_L: "Holding a book while reading(Left)",
  Gripping_of_a_telephone_handle_R: "Gripping of a telephone handle(Right)",
  Gripping_of_a_telephone_handle: "Gripping of a telephone handle",
  Gripping_of_a_telephone_handle_L: "Gripping of a telephone handle(Left)",
  Opening_of_jars_R: "Opening of jars(Right)",
  Opening_of_jars: "Opening of jars",
  Opening_of_jars_L: "Opening of jars(Left)",
  Household_chores_R: "Household chores(Right)",
  Household_chores: "Household chores",
  Household_chores_L: "Household chores(Left)",
  Carrying_of_grocery_basket_R: "Carrying of grocery basket(Right)",
  Carrying_of_grocery_basket: "Carrying of grocery basket",
  Carrying_of_grocery_basket_L: "Carrying of grocery basket(Left)",
  Bathing_dressing_R: "Bathing and dressing(Right)",
  Bathing_dressing: "Bathing and dressing",
  Bathing_dressing_L: "Bathing and dressing(Left)",
  Hand_And_Wrist_URAM: "Hand And Wrist URAM Scale",
  Wash_yourself_flat_R:
    "Wash yourself with a flannel keeping your hand flat (Right)",
  Wash_yourself_flat: "Wash yourself with a flannel keeping your hand flat",
  Wash_yourself_flat_L:
    "Wash yourself with a flannel keeping your hand flat (Left)",
  Wash_your_face_R: "Wash your face (Right)",
  Wash_your_face: "Wash your face",
  Wash_your_face_L: "Wash your face (Left)",
  Hold_bottle_in_one_hand_R: "Hold a bottle in one hand (Right)",
  Hold_bottle_in_one_hand: "Hold a bottle in one hand",
  Hold_bottle_in_one_hand_L: "Hold a bottle in one hand (Left)",
  Shake_someone_hand_R: "Shake someone’s hand(Right)",
  Shake_someone_hand: "Shake someone’s hand",
  Shake_someone_hand_L: "Shake someone’s hand(Left)",
  Stroke_something_caress_someone_R:
    "Stroke something or caress someone(Right)",
  Stroke_something_caress_someone: "Stroke something or caress someone",
  Stroke_something_caress_someone_L: "Stroke something or caress someone(Left)",
  Clap_your_hands_R: "Clap your hands(Right)",
  Clap_your_hands: "Clap your hands",
  Clap_your_hands_L: "Clap your hands(Left)",
  Spread_out_your_fingers_R: "Spread out your fingers(Right)",
  Spread_out_your_fingers: "Spread out your fingers",
  Spread_out_your_fingers_L: "Spread out your fingers(Left)",
  Lean_your_hand_R: "Lean on your hand(Right)",
  Lean_your_hand: "Lean on your hand",
  Lean_your_hand_L: "Lean on your hand(Left)",
  Pick_up_small_finger_R:
    "Pick up small objects with your thumb and index finger",
  Pick_up_small_finger:
    "Pick up small objects with your thumb and index finger",
  Pick_up_small_finger_L:
    "Pick up small objects with your thumb and index finger",
  Hand_Wrist_Result: "Hand Wrist Result",
  Below_hand_wrist_function:
    "Below is your hand and wrist function score which is using your",
  answers_self_evaluation:
    "answers from the hand and wrist form self-evaluation.",
  QuickDASH_Disability_Symptom_Score: "QuickDASH Disability/Symptom Score",
  QuickDASH_Work_Score: "QuickDASH Work Score",
  QuickDASH_Sport_Performing_Arts_Score:
    "QuickDASH Sport/Performing Arts Score",
  BMHQ: "BMHQ",
  EQ_5D_5L: "EQ-5D-5L",
  Right_deformity: "Right deformity",
  Left_deformity: "Left deformity",
  Wrist_flexion: "Wrist flexion",
  Wrist_extension: "Wrist extension",
  Thumb_R: "Thumb(Right)",
  Thumb_L: "Thumb(Left)",
  MCP: "MCP",
  IP: "IP",
  Index_finger_R: "Index finger (Right)",
  Index_finger_L: "Index finger (Left)",
  PIP: "PIP",
  DIP: "DIP",
  Middle_finger_R: "Middle finger (Right)",
  Middle_finger_L: "Middle finger (Left)",
  Ring_finger_R: "Ring finger (Right)",
  Ring_finger_L: "Ring finger (Left)",
  Small_finger_R: "Small finger (Right)",
  Small_finger_L: "Small finger (Left)",
  Semmes_Weinstein_monofilament_test_R:
    "Semmes-Weinstein monofilament test (Right)",
  Semmes_Weinstein_monofilament_test_L:
    "Semmes-Weinstein monofilament test (Left)",
  Location_1: "Point 1",
  Location_2: "Point 2",
  Location_3: "Point 3",
  Location_4: "Point 4",
  Location_5: "Point 5",
  Location_6: "Point 6",
  Location_7: "Point 7",
  Second_pt_discrimination_R: "2-pt discrimination (Right)",
  Second_pt_discrimination_L: "2-pt discrimination (Left)",
  MOTOR_TESTING: "MOTOR TESTING",
  Wrist_extension_Radial_nerve_R: "Wrist extension(Radial nerve) (Right)",
  Wrist_extension_Radial_nerve_L: "Wrist extension(Radial nerve) (Left)",
  Thumb_extension_PIN_R: "Thumb extension(PIN) (Right)",
  Thumb_extension_PIN_L: "Thumb extension(PIN) (Left)",
  Finger_MCP_extension_PIN_R: "Finger MCP extension(PIN) (Right)",
  Finger_MCP_extension_PIN_L: "Finger MCP extension(PIN) (Left)",
  Thumb_palmar_abduction_R: "Thumb palmar abduction(Median nerve) (Right)",
  Thumb_palmar_abduction_L: "Thumb palmar abduction(Median nerve) (Left)",
  Thumb_to_small_finger_R: "Thumb to small finger(Median nerve) (Right)",
  Thumb_to_small_finger_L: "Thumb to small finger(Median nerve) (Left)",
  Finger_flexion_R: "Finger flexion(Median nerve) (Right)",
  Finger_flexion_L: "Finger flexion(Median nerve) (Left)",
  Finger_abduction_adduction_R:
    "Finger abduction/adduction(Ulnar nerve) (Right)",
  Finger_abduction_adduction_L:
    "Finger abduction/adduction(Ulnar nerve) (Left)",
  OK_sign_AIN_R: "OK sign(AIN) (Right)",
  OK_sign_AIN_L: "OK sign(AIN) (Left)",
  Grip_strength_R: "Grip strength(kg) (Right)",
  Grip_strength_L: "Grip strength(kg) (Left)",
  SPECIAL_TESTS_R: "Right SPECIAL TESTS",
  SPECIAL_TESTS_L: "Left SPECIAL TESTS",
  experience_pain_in_your_elbow_R:
    "Do you experience pain in your elbow? (Right)",
  experience_pain_in_your_elbow: "Do you experience pain in your elbow?",
  experience_pain_in_your_elbow_L:
    "Do you experience pain in your elbow? (Left)",
  What_is_best_description_of_your_elbow_pain_R:
    "What is best description of your elbow pain (Right)",
  What_is_best_description_of_your_elbow_pain:
    "What is best description of your elbow pain",
  What_is_best_description_of_your_elbow_pain_L:
    "What is best description of your elbow pain (Left)",
  Elbow_Indicate_Degree:
    "Indicate the DEGREE OF DIFFICULTY to do the following activities in the last week",
  Usual_pain_In_general: "Usual pain / In general",
  Usual_pain_In_general_Elbow: "Usual pain during the past 4 weeks",
  Usual_pain_In_general_ElbowR: "Usual pain during the past 4 weeks (Right)",
  Usual_pain_In_general_ElbowL: "Usual pain during the past 4 weeks (Left)",
  at_the_moment: "At this moment",
  at_the_momentR: "At this moment (Right)",
  at_the_momentL: "At this moment (Left)",
  When_it_is_at_its_worst: "When it is at its worst",
  When_it_is_at_its_worst_Elbow:
    "When it is at its worst during the past 4 weeks",
  When_it_is_at_its_worst_ElbowR:
    "When it is at its worst during the past 4 weeks (Right)",
  When_it_is_at_its_worst_ElbowL:
    "When it is at its worst during the past 4 weeks (Left)",
  At_rest: "At rest",
  At_restR: "At rest (Right)",
  At_restL: "At rest (Left)",
  Lifting_a_heavy_object: "Lifting a heavy object",
  Lifting_a_heavy_objectR: "Lifting a heavy object (Right)",
  Lifting_a_heavy_objectL: "Lifting a heavy object (Left)",
  When_doing_a_task_with_repeated_elbow_movements:
    "When doing a task with repeated elbow movements",
  When_doing_a_task_with_repeated_elbow_movementsR:
    "When doing a task with repeated elbow movements (Right)",
  When_doing_a_task_with_repeated_elbow_movementsL:
    "When doing a task with repeated elbow movements (Left)",
  At_night: "At night",
  At_nightR: "At night (Right)",
  At_nightL: "At night (Left)",
  How_often_elbow_in_bed_at_night_R:
    "How often have you been troubled by pain from your elbow in bed at night? (Right)",
  How_often_elbow_in_bed_at_night:
    "How often have you been troubled by pain from your elbow in bed at night?",
  How_often_elbow_in_bed_at_night_L:
    "How often have you been troubled by pain from your elbow in bed at night? (Left)",
  How_often_has_elbow_pain_interfered_sleeping_R:
    "How often has your elbow pain interfered with your sleeping? (Right)",
  How_often_has_elbow_pain_interfered_sleeping:
    "How often has your elbow pain interfered with your sleeping?",
  How_often_has_elbow_pain_interfered_sleeping_L:
    "How often has your elbow pain interfered with your sleeping? (Left)",
  What_is_the_best_elbow_FUNCTION_R:
    "What is the best description of your elbow FUNCTION ? (Right)",
  What_is_the_best_elbow_FUNCTION:
    "What is the best description of your elbow FUNCTION ?",
  What_is_the_best_elbow_FUNCTION_L:
    "What is the best description of your elbow FUNCTION ? (Left)",
  What_is_the_best_description_of_the_ACTIVITY_you_can_do_R:
    "What is the best description of the ACTIVITY you can do? (Right)",
  What_is_the_best_description_of_the_ACTIVITY_you_can_do:
    "What is the best description of the ACTIVITY you can do?",
  What_is_the_best_description_of_the_ACTIVITY_you_can_do_L:
    "What is the best description of the ACTIVITY you can do? (Left)",
  How_long_your_elbow_R: "How long can you use your elbow? (Right)",
  How_long_your_elbow: "How long can you use your elbow?",
  How_long_your_elbow_L: "How long can you use your elbow? (Left)",
  Lifting_things_home_rubbish_R:
    "Lifting things in your home such as putting out the rubbish (Right)",
  Lifting_things_home_rubbish:
    "Lifting things in your home such as putting out the rubbish",
  Lifting_things_home_rubbish_L:
    "Lifting things in your home such as putting out the rubbish (Left)",
  Washing_yourself_all_over_R: "Washing yourself all over (Right)",
  Washing_yourself_all_over: "Washing yourself all over",
  Washing_yourself_all_over_L: "Washing yourself all over (Left)",
  Perform_personal_hygiene_R: "Perform personal hygiene (Right)",
  Perform_personal_hygiene: "Perform personal hygiene",
  Perform_personal_hygiene_L: "Perform personal hygiene (Left)",
  Eat_with_utensil_R: "Eat with utensil (Right)",
  Eat_with_utensil: "Eat with utensil",
  Eat_with_utensil_L: "Eat with utensil (Left)",
  Elbow_Recreational_activities_R:
    "Recreational activities in which you take some force or impact through your arm shoulder or hand (eg golf hammering tennis etc) (Right)",
  Elbow_Recreational_activities:
    "Recreational activities in which you take some force or impact through your arm shoulder or hand (eg golf hammering tennis etc)",
  Elbow_Recreational_activities_L:
    "Recreational activities in which you take some force or impact through your arm shoulder or hand (eg golf hammering tennis etc) (Left)",
  Put_on_shirt_R: "Put on shirt (Right)",
  Put_on_shirt: "Put on shirt",
  Put_on_shirt_L: "Put on shirt (Left)",
  Do_up_top_button_on_shirt_R: "Do up top button on shirt (Right)",
  Do_up_top_button_on_shirt: "Do up top button on shirt",
  Do_up_top_button_on_shirt_L: "Do up top button on shirt (Left)",
  Dressing_yourself_R: "Dressing yourself (Right)",
  Dressing_yourself: "Dressing yourself",
  Dressing_yourself_L: "Dressing yourself (Left)",
  Put_on_shoes_R: "Put on shoes (Right)",
  Put_on_shoes: "Put on shoes",
  Put_on_shoes_L: "Put on shoes (Left)",
  Tie_shoes_R: "Tie shoes (Right)",
  Tie_shoes: "Tie shoes",
  Tie_shoes_L: "Tie shoes (Left)",
  Carry_heavy_object_R: "Carry a heavy object (Right)",
  Carry_heavy_object: "Carry a heavy object",
  Carry_heavy_object_L: "Carry a heavy object (Left)",
  Rise_from_chair_pushing_with_arm_R:
    "Rise from a chair pushing with arm (Right)",
  Rise_from_chair_pushing_with_arm: "Rise from a chair pushing with arm",
  Rise_from_chair_pushing_with_arm_L:
    "Rise from a chair pushing with arm (Left)",
  Do_heavy_household_chores_R: "Do heavy household chores (Right)",
  Do_heavy_household_chores: "Do heavy household chores",
  Do_heavy_household_chores_L: "Do heavy household chores (Left)",
  Turn_key_R: "Turn a key (Right)",
  Turn_key: "Turn a key",
  Turn_key_L: "Turn a key (Left)",
  Throw_a_ball_R: "Throw a ball (Right)",
  Throw_a_ball: "Throw a ball",
  Throw_a_ball_L: "Throw a ball (Left)",
  Specify_sport_or_musical_instrument_played:
    "Specify sport or musical instrument played",
  Specify_sport_or_musical_instrument_played_Elbow: "Specify usual work",
  I_do_not_play_a_sport_or_instrument_R:
    "I do not play a sport or instrument (Right)",
  I_do_not_play_a_sport_or_instrument_Elbow:
    "I do not play a sport or instrument",
  I_do_not_play_a_sport_or_instrument_L:
    "I do not play a sport or instrument (Left)",
  I_do_not_play_a_sport_or_instrument_R_elbow: "I do not work (Right)",
  I_do_not_play_a_sport_or_instrument_Elbowww: "I do not work",
  I_do_not_play_a_sport_or_instrument_L_elbow: "I do not work (Left)",
  During_the_past_week_hand_problem_R:
    "During the past week were you limited in your work or other regular daily activities as a result of your arm shoulder or hand problem? (Right)",
  During_the_past_week_hand_problem_L:
    "During the past week were you limited in your work or other regular daily activities as a result of your arm shoulder or hand problem? (Left)",
  SYMPTOMS: "SYMPTOMS",
  Please_rate_the_severity_of_the_following_symptoms_in_the_last_week:
    "Please rate the severity of the following symptoms in the last week",
  Arm_shoulder_or_hand_pain_R: "Arm shoulder or hand pain",
  Arm_shoulder_or_hand_pain: "Arm shoulder or hand pain",
  Arm_shoulder_or_hand_pain_L: "Arm shoulder or hand pain",
  Tingling_hand_R: "Tingling (pins and needles) in your arm shoulder or hand",
  Tingling_hand: "Tingling (pins and needles) in your arm shoulder or hand",
  Tingling_hand_L: "Tingling (pins and needles) in your arm shoulder or hand",
  How_would_you_rate_your_elbow_today_as_a_percentageofnormal:
    "How would you rate your elbow today as a percentage of normal? (0-100% with 100% being normal)",
  How_would_you_rate_your_elbow_today_as_a_percentageofnormalR:
    "How would you rate your elbow today as a percentage of normal? (0-100% with 100% being normal) (Right)",
  How_would_you_rate_your_elbow_today_as_a_percentageofnormalL:
    "How would you rate your elbow today as a percentage of normal? (0-100% with 100% being normal) (Left)",
  During_the_past_4_weeks_R:
    "During the past 4 weeks have you felt that your elbow problem is “controlling your life”? (Right)",
  During_the_past_4_weeks_L:
    "During the past 4 weeks have you felt that your elbow problem is “controlling your life”? (Left)",
  During_the_past_4_weeks_elbow_been_your_mind_R:
    "During the past 4 weeks how much has your elbow been “on your mind”? (Right)",
  During_the_past_4_weeks_elbow_been_your_mind_L:
    "During the past 4 weeks how much has your elbow been “on your mind”? (Left)",
  How_would_you_rate_your_elbow_today_as_a_percentage_of_normal:
    "How good or bad your health is TODAY?",
  How_would_you_rate_your_elbow_today_as_a_percentage_of_normalR:
    "How good or bad your health is TODAY? (Right)",
  How_would_you_rate_your_elbow_today_as_a_percentage_of_normalL:
    "How good or bad your health is TODAY? (Left)",
  Elbow_Satisfaction: "Elbow Satisfaction",
  Are_you_satisfied_with_your_elbow_surgery_R:
    "Are you satisfied with your treatment for the elbow? (Right)",
  Are_you_satisfied_with_your_elbow_surgery:
    "Are you satisfied with your treatment for the elbow?",
  Are_you_satisfied_with_your_elbow_surgery_L:
    "Are you satisfied with your treatment for the elbow? (Left)",
  Elbow_Results: "Elbow Results",
  Below_pain_Elbow: "Below is your pain and Elbow score which is using your",
  answers_elbow_scoring_self_evaluation:
    "answers from the elbow scoring self-evaluation.",
  QuickDASH_Symptom: "QuickDASH Symptom",
  QuickDASH_Work: "QuickDASH Work",
  QuickDASH_Sport: "QuickDASH Sport",
  ASES_E_Pain: "ASES-E Pain",
  ASES_E_Function: "ASES-E Function",
  ASES_E_Satisfaction: "ASES-E Satisfaction",
  MEPS: "MEPS",
  HSS_Short: "HSS Short",
  OES_Pain: "OES Pain",
  OES_Function: "OES Function",
  OES_Social: "OES Social",
  EQ_5D_5L_ANXIETY_DEPRESSION: "EQ-5D-5L ANXIETY / DEPRESSION",
  Motion: "Motion",
  Crepitus: "Crepitus",
  Inspection: "Inspection",
  Flexion_Extension_R: "Flexion-Extension arc Right",
  Flexion_Extension_L: "Flexion-Extension arc Left",
  Right_Pronation: "Right Pronation",
  Left_Pronation: "Left Pronation",
  Right_Supination: "Right Supination",
  Left_Supination: "Left Supination",
  Right_Pronation_Supination_arc: "Right Pronation/Supination arc",
  Left_Pronation_Supination_arc: "Left Pronation/Supination arc",
  rate_the_severity_of_the_following_STABILITY_in_the:
    "Please rate the severity of the following STABILITY in the last week",
  Valgus_R: "Valgus (Right)",
  Valgus_L: "Valgus (Left)",
  Varus_R: "Varus (Right)",
  Varus_L: "Varus (Left)",
  Posterolateral_rotatory_R: "Posterolateral rotatory (Right)",
  Posterolateral_rotatory_L: "Posterolateral rotatory (Left)",
  STRENGTH: "STRENGTH",
  Please_rate_the_severity_of_the_following_STRENGTH_in_the_last_week:
    "Please rate the severity of the following STRENGTH in the last week",
  Testing_affected_by_pain_R: "Testing affected by pain? (Right)",
  Testing_affected_by_pain_L: "Testing affected by pain? (Left)",
  Flexion_R: "Flexion (Right)",
  Flexion_L: "Flexion (Left)",
  Extension_R: "Extension (Right)",
  Extension_L: "Extension (Left)",
  Pronation_R: "Pronation (Right)",
  Pronation_L: "Pronation (Left)",
  Supination_R: "Supination (Right)",
  Supination_L: "Supination (Left)",
  Right_strength: "Right strength",
  Left_strength: "Left strength",
  SIGNS: "SIGNS",
  Other_Special_tests: "Other Special tests",
  Nerve_Assessment: "Nerve Assessment",
  Ulnohumeral_tenderness_R: "Ulnohumeral tenderness (Right)",
  Ulnohumeral_tenderness_L: "Ulnohumeral tenderness (Left)",
  Radiocapitellar_tenderness_R: "Radiocapitellar tenderness (Right)",
  Radiocapitellar_tenderness_L: "Radiocapitellar tenderness (Left)",
  Medial_flexor_origin_tenderness_R: "Medial flexor origin tenderness (Right)",
  Medial_flexor_origin_tenderness_L: "Medial flexor origin tenderness (Left)",
  Lateral_flexor_origin_tenderness_R:
    "Lateral extensor origin tenderness (Right)",
  Lateral_flexor_origin_tenderness_L:
    "Lateral extensor origin tenderness (Left)",
  MCL_tenderness_R: "MCL tenderness (Right)",
  MCL_tenderness_L: "MCL tenderness (Left)",
  PIN_tenderness_R: "PIN tenderness (Right)",
  PIN_tenderness_L: "PIN tenderness (Left)",
  Other_tenderness_specify: "Other tenderness specify",
  left: "Left",
  Impingement_pain_in_flexion_R: "Impingement pain in flexion (Right)",
  Impingement_pain_in_flexion_L: "Impingement pain in flexion (Left)",
  Impingement_pain_in_extension_R: "Impingement pain in extension (Right)",
  Impingement_pain_in_extension_L: "Impingement pain pain in extension (Left)",
  Pain_on_resisted_wrist_extension_R:
    "Pain on resisted wrist extension (Right)",
  Pain_on_resisted_wrist_extension_L: "Pain on resisted wrist extension (Left)",
  Pain_on_resisted_wrist_flexion_R: "Pain on resisted wrist flexion (Right)",
  Pain_on_resisted_wrist_flexion_L: "Pain on resisted wrist flexion (Left)",
  Pain_on_resisted_long_finger_extension_R:
    "Pain on resisted long finger extension (Right)",
  Pain_on_resisted_long_finger_extension_L:
    "Pain on resisted long finger extension (Left)",
  Pain_on_resisted_long_finger_flexion_R:
    "Pain on resisted long finger flexion (Right)",
  Pain_on_resisted_long_finger_flexion_L:
    "Pain on resisted long finger flexion (Left)",
  Pain_on_resisted_wrist_pronation_R:
    "Pain on resisted wrist pronation (Right)",
  Pain_on_resisted_wrist_pronation_L: "Pain on resisted wrist pronation (Left)",
  Pain_on_resisted_wrist_supination_R:
    "Pain on resisted wrist supination (Right)",
  Pain_on_resisted_wrist_supination_L:
    "Pain on resisted wrist supination (Left)",
  Ulnohumeral_crepitus_R: "Ulnohumeral crepitus (Right)",
  Ulnohumeral_crepitus_L: "Ulnohumeral crepitus (Left)",
  Radiocapitellar_crepitus_R: "Radiocapitellar crepitus (Right)",
  Radiocapitellar_crepitus_L: "Radiocapitellar crepitus (Left)",
  Scars_R: "Scars (Right)",
  Scars_L: "Scars (Left)",
  Location: "Location",
  Atrophy_R: "Atrophy (Right)",
  Atrophy_L: "Atrophy (Left)",
  Deformity_R: "Deformity (Right)",
  Deformity_L: "Deformity (Left)",
  Flexion_contracture: "Flexion contracture",
  Degrees: "Degrees",
  Extension_contracture: "Extension contracture",
  Cubitus_valgus: "Cubitus valgus",
  Cubitus_varus: "Cubitus varus",
  other_field: "Others",
  Ulnar_nerve_tinels_R: "Ulnar nerve tinels (Right)",
  Ulnar_nerve_tinels_L: "Ulnar nerve tinels (Left)",
  Cubital_tunnel_stretch_test_R: "Cubital tunnel stretch test (Right)",
  Cubital_tunnel_stretch_test_L: "Cubital tunnel stretch test (Left)",
  Froment_sign_R: "Froment sign (Right)",
  Froment_sign_L: "Froment sign (Left)",
  Jeanne_sign_R: "Jeanne sign (Right)",
  Jeanne_sign_L: "Jeanne sign (Left)",
  Wartenberg_sign_R: "Wartenberg sign (Right)",
  Wartenberg_sign_L: "Wartenberg sign (Left)",
  Masse_sign_R: "Masse sign (Right)",
  Masse_sign_L: "Masse sign (Left)",
  Other_joints_limiting_activity: "Other joints limiting activity",
  newRight: "(Right)",
  newLeft: "(Left)",
  Evaluated: "Please choose the part of the foot/ankle that is being evaluated",
  Others: "Others",
  Grip_Length: "Grip Strength",
  Forefoot: "Right Forefoot",
  ForefootL: "Left Forefoot",
  Ankle_Hindfoot: "Ankle-Hindfoot",
  Below_is_your_pain_and_shoulder_function:
    "Below is your pain and shoulder function score which is using your",
  Below_is_your_pain_and_elbow_function:
    "Below is your pain and elbow function score which is using your",
  Below_is_your_pain_and_foot_function:
    "Below is your pain and foot ankle function score which is using your",
  Below_is_your_pain_and_hand_function:
    "Below is your pain and hand & wrist function score which is using your",
  Below_is_your_pain_and_hip_function:
    "Below is your pain and hip function score which is using your",
  Below_is_your_pain_and_knee_function:
    "Below is your pain and knee function score which is using your",
  answers_sholder_scoring_self_evaluation:
    "answers from the shoulder scoring self-evaluation.",
  answers_foot_scoring_self_evaluation:
    "answers from the foot ankle scoring self-evaluation.",
  answers_hand_scoring_self_evaluation:
    "answers from the hand & wrist scoring self-evaluation.",
  answers_hip_scoring_self_evaluation:
    "answers from the hip scoring self-evaluation.",
  answers_knee_scoring_self_evaluation:
    "answers from the knee scoring self-evaluation.",
  Ankle_Activity_Prior: "Ankle Activity Prior",
  Ankle_Activity_Current: "Ankle Activity Current",
  No_limitations: "No limitations",
  Slightlimitations_activities_dailyLiving:
    "Slight limitations no restrictions in activities of daily living",
  Unable_objects_4_5kg: "Unable to lift objects >4.5kg",
  ModerateRestrictions_activitiesDailyLiving:
    "Moderate restrictions in activities of daily living",
  Unable_comb_hair_touchHead: "Unable to comb hair or touch head",
  Unable_feed_self: "Unable to feed self",
  Unlimited_use_elbow: "Unlimited use of elbow",
  Limited_use_recreation: "Limited use for recreation",
  Limited_household_employmentActivities:
    "Limited to household and employment activities",
  Able_care_for_self: "Able to care for self",
  Invalid: "Invalid",
  Can_perform_activities_30_minutes: "Can perform activities for 30 minutes",
  Can_perform_activities_15_minutes: "Can perform activities for 15 minutes",
  Can_perform_activities_5_minutes: "Can perform activities for 5 minutes",
  Cannot_use_elbow: "Cannot use elbow",
  mild: "mild",
  moderate: "moderate",
  unable: "unable",
  Not_at_all: "Not at all",
  Slightly: "Slightly",
  Moderately: "Moderately",
  Quite_a_bit: "Quite a bit",
  Extremely: "Extremely",
  None_or_ignored: "None or ignored",
  Mild_with_activity_medicationAnalgesics:
    "Mild with activity none to occasionally uses medication/analgesics",
  Moderate_with_without_analgesicsDaily:
    "Moderate with or without activity uses analgesics daily",
  Moderate_rest_at_night: "Moderate at rest or at night",
  Severe_rest_constant_medication_disabling:
    "Severe at rest constant medication disabling",
  _1_2_nights: "1-2 nights",
  Some_nights: "Some nights",
  Most_nights: "Most nights",
  Every_night: "Every night",
  Occasionally: "Occasionally",
  Some_the_time: "Some of the time",
  Most_of_the_time: "Most of the time",
  Some_days: "Some days",
  Most_days: "Most days",
  Everyday: "Everyday",
  A_little_the_time: "A little of the time",
  no_problem: "no problem",
  slight: "slight",
  extreme: "extreme",
  Elbow: "Elbow",
  Right_Elbow: "Right-Elbow",
  Left_Elbow: "Left-Elbow",
  Not_all_satisfied: "Not at all satisfied",
  Very_satisfied: "Very satisfied",
  very_satisfied: "very satisfied",
  none: "none",
  no_instability: "no instability",
  mild_laxity_with_good_endpoint: "mild laxity with good endpoint",
  moderate_laxity_endpoint: "moderate laxity no endpoint",
  gross_instability: "gross instability",
  no_contraction: "no contraction",
  flicker: "flicker",
  movement_gravity_eliminated: "movement with gravity eliminated",
  movement_against_gravity: "movement against gravity",
  movement_some_resistance: "movement with some resistance",
  normal_power: "normal power",
  normal: "normal",

  Patient_Consent: "Akunah Patient Information and Free Prior Consent Form",
  Patient_Last_Name: "Patient Last Name",
  Patient_First_Name: "Patient First Name",
  Patient_Date_of_Birth: "Patient Date of Birth",
  information: "Information",
  I_am_informedDDD:
    "For the purpose of the evaluation, planning, and outcome assessment of your orthopaedic treatment, your doctor,",
  Clinician:
    "(hereafter the “Clinician”) uses evaluation, outcome data collection and analysis, and/or pre-surgical planning services and software provided by Akunah Medical Technology Pty Ltd and its subsidiaries (“Akunah”).",
  For_that:
    "For that purpose, the Clinician will transfer your personal data to Akunah, including computed tomography (CT) scans of your body and other medical data.",
  Akunah_softawre:
    "Akunah's software and services may involve the evaluation of images, assessment of medical data, creation of personalised 3D models of your anatomy along with key anatomical measurements, surgery planning, and support for surgery, which will be shared with the Clinician.",
  the_clinic:
    "The Clinician will use the evaluation and pre-surgical planning for your orthopaedic treatment.",
  oportunnity:
    "You also have the opportunity to provide personal data through Akunah's patient portal and access Akunah's models and plans.",
  akunah_approch: "Akunah’s approach to privacy and security",
  akunah_approch1:
    "The privacy and security of the data which Akunah will collect from you to assist your Clinician is foremost in our minds.",
  akunah_approch2:
    "Akunah voluntarily complies with the Applicable European Data Protection Laws, which includes the EU General Data Protection Regulation EU/2016/679, as those laws are the strongest privacy laws enacted globally. Those laws exceed the privacy laws enacted by Australia, the United States and other countries.",
  akunah_approch3:
    "Akunah’s “Privacy Code for Processing Personal Information of Customer Individuals” describes how Akunah complies with the Applicable European Data Protection Laws (the",
  akunah_approch4: "Privacy Code",
  akunah_approch5:
    "Akunah’s “Data Processing Agreement” describe the arrangements which it has with its Clinicians to protect and secure your personal information (the",
  akunah_approch6: "Data Processing Agreement",
  akunah_approch7: "Those policies are available on the Akunah website at",
  terms_: "https://akunah.com/termsofservices",
  akunah_approch8:
    "To the extent there is any inconsistency between the Privacy Code, Data Processing Agreement and this document, then the terms of the first mentioned document will apply to the extent of any inconsistency with this document.",
  Data_Collection_and_Processing: "Data Collection and Processing",
  I_am_informed:
    "Akunah may collect your data, including information relating to your contact information, authentication, identification, occupation and demographics. Akunah may also collect medical data relating to your health, such as",
  Assessment_and_evaluation:
    "• Assessment and evaluation data physical characteristics and other information relevant to the proposed treatments and outcomes;",
  Previous_surgery:
    "• Previous surgery diagnosis radiology scans 3D models anatomy anatomical measurements; and",
  Health_and_medical_history:
    "• Health and medical history including your symptoms medications and any previous diagnosis and treatment given to you.",
  I_have_been_assured:
    "Akunah always collects the information in a lawful and fair manner, whether directly from a patient, shared by a Clinician, or through the Akunah products. Akunah will process these data in compliance with your consent as expressed hereafter, to allow the delivery of the evaluation and pre-surgical planning service if necessary.",
  My_data:
    "In addition, Akunah may use your personal data to comply with all applicable laws and regulations",
  your_data:
    "Your data may also be disclosed to third parties, if required by to provide the proposed services or by law.",
  Data_Access_and_Storage: "Data Access, Storage and Security",
  Personal_data_provided:
    "Data provided by you and your Clinician will only be accessible by those staff of Akunah and at third parties, who contribute to your treatment or recovery (e.g. anaesthetist, physiotherapist) and need to have access to your data.",
  data1:
    "Data entered via Akunah's software is securely hosted in the Commonwealth of Australia by an approved hosting provider. This ensures an appropriate level of confidentiality and security reflecting the nature of your data processed via the Akunah software.",
  data2:
    "If you are located outside the Commonwealth of Australia and/or data entered via the Akunah software may be accessed by Akunah subsidiaries located outside the Commonwealth of Australia, including in the European Union or the United States, different privacy standards may apply to how your data is used and protected in those countries. In this case, your personal data will be stored in that country",
  data3:
    "Akunah has appropriate safeguards in place in accordance with all applicable legal requirements to ensure that data is kept confidential and protected with an adequate level of data protection with all third parties. We require all those with access to adopt standards that ensure an equivalent level of protection for data as that adopted by Akunah.",
  data4:
    "Your Clinician share the responsibility to collect, access, analysis and share your data according with legal requirements.",
  p_data1:
    "Your personal data will be kept by Akunah for a length of time that will vary. The retention period will be determined by the following criteria: (i) the purpose for which Akunah or the Clinician is using your personal data (they will need to keep the data only for as long as is necessary for that purpose); and (ii) applicable laws or regulation that may set a minimum period for which Akunah has to keep your personal data.",
  Deidentified_Data: "De-identified Data",
  d_txt1:
    "Akunah may de-identified your data, and then use these de-identified data – usually aggregated with de-identified data from other patients - for record purposes, research and development, data analysis, software/device development and improvement of Akunah’s products and services. This enables Akunah to continuously improve their products and services for the benefit of patients like you.",
  Patient_Rights: "Your Rights",
  I_understand_that:
    "You are free to refuse to give consent to provide your personal data to Akunah. In this case, please talk to your Clinician, as your Clinician may not be able to provide you with medical services or treatment without using Akunah’s software and services.",
  I_understand_that_1txt:
    "You have the right to withdraw your consent at any time and by any means, and, where applicable, you have the right to: (i) obtain information regarding the processing of your personal data and to access such personal data; (ii) to request their rectification where they are inaccurate; (iii) to request the restriction of the processing of your personal data, including objecting to certain processing of your personal data and requesting the withdrawal of the consent you provided; (iv) to request the erasure of your personal data; (v) to define guidelines relating to the processing of your data after your death; and (vi) to obtain a copy of your personal data processed in the above-mentioned conditions in a structured, commonly used and machine-readable format. You can exercise these rights by addressing your request directly to the Clinician.",
  I_understand_that_2txt:
    "Your rights are subject to the applicable exemptions and restrictions in law.",
  I_understand_that_3txt:
    "Following withdrawal, your clinician may not be able to provide you with medical services or treatment, if you exercise those rights before the proposed procedure.",
  I_understand_that_4txt:
    "You also are free to make a complaint about the processing of your personal data to the supervisory authority in your country of residence.",
  I_understand_that_5txt:
    "With respect to de-identified data, de-identified data do not fall under the same legal restrictions as personal data. Please note that you cannot withdraw consent for access to and use of your de-identified data or request erasing these data, because Akunah will not be able to find your data. After de-identification, Akunah cannot trace data back to you.",
  Contact: "Consent",
  I_am_able_to_contact:
    "I have read and understood the Information section above, or someone has read it to me in a language that I understand.",
  or_by_post:
    "I have had an opportunity to ask questions and I am satisfied with the answers I have received. I understand that my information will remain confidential.",
  or_by_post2: "I give permission for my doctor",
  or_by_post2_1: "(the “Clinician”) to share my personal data with Akunah.",
  or_by_post3:
    "I give permission that Akunah collects, stores, uses, and shares my personal and de-identified data as described in the Information section above.",
  or_by_post4:
    "I understand that I am free to withdraw my consent at any time.",
  or_by_post5:
    "I understand that I will be given a signed copy of this document to keep.",
  or_by_post6:
    "I am able to contact Akunah regarding the processing of my data by email to",
  info_akunah: "info@akunah.com ",
  or_by_post7:
    "or by post to Suite 307 Nicholson Street Specialist Centre, Level 9, 121 Newdegate Street, Greenslopes, QLD, 4120, Australia.",
  Clear: "Clear",
  Date: "Date",
  Signed_by_Mouse: "Signed by Mouse *",
  Name_if_consent_on_behalf_of_patient:
    "Name (if consent on behalf of patient)",
  Relationship_to_patient_if_applicable:
    "Relationship to patient if applicable",
  Choose_Sign_Type: "Choose Sign Type*",
  Draw: "Draw",
  Signed_by_Keyboard: "Signed by Keyboard *",
  Agreement: "Agreement",
  agree_that_I_have_read:
    "agree that I have read and understood this patient consent form and I explicitly consent to the processing of my personal data for the purposes described above.",
  NOTE: "NOTE",
  If_the_patient:
    "If the patient is less than 18 years of age this must be signed by their parent or legal guardian.",
  If_the_patient_is_deceased:
    "If the patient is deceased this must be signed by their next of kin.",
  This_is_not_relevant_to_me: "This is not relevant to me",
  Not_applicable: "Not applicable",
  Most_severe_pain: "Most severe pain",
  No_need_to_support: "No need to support",
  Extreme_need_to_support: "Extreme need to support",
  Extreme_difficulty_or_unable_to_perform:
    "Extreme difficulty or unable to perform",
  No_difficulty: "No difficulty",
  Extremely_different: "Extremely different",
  No_different: "No different",
  Severe_level_of_anxiety_stress: "Severe level of anxiety / stress",
  No_anxiety_stress: "No anxiety / stress",
  Constantly: "Constantly",
  Never: "Never",
  Totally: "Totally",
  Extremely_happy: "Extremely happy",
  Extremely_unhappy: "Extremely unhappy",
  no_fatigue: "no fatigue",
  extreme_fatigue: "extreme fatigue",
  No_stiffness: "No stiffness",
  Extreme_stiffness: "Extreme stiffness",
  No_discomfort: "No discomfort",
  Extreme_discomfort: "Extreme discomfort",
  No_instability: "No instability",
  Extreme_instability: "Extreme instability",
  Extreme: "Extreme",
  No_loss: "No loss",
  Extreme_loss: "Extreme loss",
  Not_limited: "Not limited",
  Extremely_limited: "Extremely limited",
  Not_affected: "Not affected",
  Extremely_affected: "Extremely affected",
  Extreme_difficulty: "Extreme difficulty",
  No_fear: "No fear",
  Extreme_fear: "Extreme fear",
  Not_conscious: "Not conscious",
  Extremely_conscious: "Extremely conscious",
  No_concern: "No concern",
  Extremely_concerned: "Extremely concerned",
  No_frustration: "No frustration",
  Extremely_frustrated: "Extremely frustrated",
  Pain: "Pain",
  none_never_not_at_all: "none/never/not at all",
  Extreme_Constantly_Extremely: "Extreme/Constantly/Extremely",
  unable_extreme: "unable/extreme",
  Worst: "Worst",
  Best: "Best",
  Ten_being_normal_and_0_being_the:
    "(10 being normal and 0 being the inability to perform any of your usual daily activities which may include sports)",
  neither_satisfied_nor_dissatisfied: "neither satisfied nor dissatisfied",
  medium: "medium",
  very_serious: "very serious",
  EQ_MOBILITY: "EQ-5D-5L MOBILITY",
  EQ_CARE: "EQ-5D-5L SELF-CARE",
  EQ_ACTIVITIES: "EQ-5D-5L USUAL ACTIVITIES",
  EQ_DISCOMFORT: "EQ-5D-5L PAIN / DISCOMFORT",
  EQ_ANXIETY: "EQ-5D-5L ANXIETY / DEPRESSION",
  SAPS_OVERALL: "SAPS-OVERALL",
  SAPS_PAIN: "SAPS-PAIN",
  SAPS_WORK: "SAPS-WORK",
  SAPS_RECREATION: "SAPS-RECREATION",
  Modified_Tegner: "Modified Tegner",
  R_Flexion: "Right Flexion",
  L_Flexion: "Left Flexion",
  R_Extension: "Right Extension",
  L_Extension: "Left Extension",
  Range_of_Motion: "Range of Motion",
  Radiology: "Radiology",
  Pathology: "Pathology",
  Organization: "Organization*",
  Select_Doctor: "Select Doctor*",
  no: "No",
  yes: "Yes",
  "": "   Upcoming  ",
  PendingForm: "Pending Form",
  NoFollowUpRecords: "No Follow-Up Records",
  document: "My Documents",
  Select: "Select",
  Search_with_minimum_three_characters: "Search with minimum three characters",
  This_field_is_required: "This field is required",
  Hour_Time: "24-Hour Time",
  Date_Began: "Date Problem Began",
  Language_Region: "Language & Region",
  hip_form: "Hip",
  Select_Timezone: "Select_Timezone",
  Foot_And_Ankle: "Foot And Ankle",
  Hand_And_Wrist: "Hand And Wrist",
  General: "General",
  Oops_error: "Oops! the data you requested was not found",
  go_to_home: "Go Back to Home",
  HISTORY_OF_PRESENT: "HISTORY OF PRESENT COMPLAINT/INJURY/ILLNESS",
  QUESTION: "Main Complaint (explain your complaint in your own words)",
  Imaging: "Have you had any imaging?",
  Investigation:
    "Have you had any further investigations into your condition, that is not list above?",
  past: "PAST MEDICAL HISTORY / ONGOING MEDICAL ISSUES",
  allergies: "Do you have any known allergies? (drug or food)",
  latex: "Are you allergic to latex?",
  blood_Presure:
    "Do you have or are you being treated for high blood pressure?",
  illness: "Do you currently or have you had any heart conditions/illnesses?",
  cardiologist: "Do you have a cardiologist?",
  cerculation:
    "Do you have problems with circulations in the legs (peripheral vascular disease)?",
  blood_Thiner: "Have you taken a blood thinner in the last month?",
  WhatIs: "What is it ?",
  lungs: "Have you had any of the following problems with your lungs or chest?",
  trouble: "Any trouble with your breathing?",
  Asthma: "Asthma",
  pulmonary: "High Blood Pressure in the Lungs (pulmonary hypertension)",
  Pulmonary_Fibrosis: "Pulmonary Fibrosis",
  Sarcoidosis: "Sarcoidosis",
  COPD_or_Emphysema: "COPD or Emphysema",
  Fibrosis: "Cystic Fibrosis",
  tuberculosis: "TB (tuberculosis)",
  steroids:
    "Have you taken steroids (prednisone or cortisone) in the last year?",
  oxygen: "Do you use home oxygen?",
  respiratory: "Do you have a respiratory specialist?",
  snoring: "Do you have excessive snoring or sleep apnoea?",
  sleep: "Is your sleep apnoea treated with CPAP or BiPAP?",
  liver: "Have you ever had liver problems?",
  ulcer: "Do you have frequent heartburn, ulcers, or hiatus hernia?",
  kidney: "Have you ever had kidney disease?",
  dialysis: "Are you a dialysis patient? Peritoneal? Haemodialysis",
  diabetes: "Do you have diabetes?",
  diabetes1: " How is your diabetes managed? Diet/Oral medication or insulin?",
  thyroid: "Do you have thyroid problems?",
  epilepsy: "Have you ever had Epilepsy, Seizures, or Convulsions?",
  seizure: "when was your last seizure?",
  nerves: "Have you had problems with your Nerves or Muscles?",
  seizures: "Seizures",
  TIA: "TIA or Stroke",
  facial: "Facial, Leg or Arm Weakness",
  neurologic:
    "Neurologic Disorder, Examples: Multiple Sclerosis, ALS, Alzheimer’s",
  muscular:
    "Muscular Disorder, Examples: Myasthenia Gravis, Muscular Dystrophy",
  hearing: "Problems With Hearing, Vision or Memory",
  chronic: "Chronic Pain",
  arthritis: "Do you have arthritis?",

  psychiatric: "Have you ever been treated for a psychiatric illness?",
  bleeding: "Have you ever been diagnosed with a bleeding disorder?",
  anaemic: "Are you anaemic?",
  legs: "Have you ever had a blood clot in your legs or lungs?",
  serious: "Have you or anyone in your family had serious problems with:",
  cancer: "Have you ever been treated for cancer?",
  radiation:
    "Have you ever been treated with chemotherapy or radiation therapy?",
  treatment: "Name of treatment",
  treatmentDate: "Last Treatment Date",
  relative: "Have you or your relative had any problem with anaesthesia",
  breathing: "Which problems?",
  following: "Do you have any of the following?",
  chipped: "Chipped or Loose Teeth, Dentures, Partials",
  neck: "Problems Moving Your Neck",
  mouth: "Problems Opening You Mouth",
  pregnant: "Could you be pregnant?",
  medical:
    "Medical History : Please list any additional medical illnesses you have that were not noted above",
  list: "List all medications (include over the counter medicine ,inhalers, patches, drops, vitamins, minerals,supplements, herbs)",
  medication: "Medication name",
  dose: "Dose",
  how_often: "How often",
  taking: "Reason of taking it",
  surgical: "Surgical History",
  social: "SOCIAL HISTORY",
  social1: "Do you live alone?",
  social2: "Who lives with you?",
  social3: "Do you smoke?",
  social4: "How many ciggarets per day?",
  social11: "Number of years?",
  social5: "Have you smoked in the past?",
  social7: "When did you stop smoking?",
  social8: "Do you drink alcohol?",
  social9: "What is your average alcohol intake per week?",
  social10:
    "Do you use or have you ever used any recreational or street (illicit) drugs,marijuana or opioids not prescribed for you?",
  severe_h: "severe",
  pedia_qol: "How often do you think about your knee problem?",
  hip_Prior_to: "Prior to hip injury/hip problem",
  Hip_Quality_Rate_new:
    "The following questions ask about social, emotional, and lifestyl concerns that you may feel with respect to you hip problem. Please think about how you have most felt most of the time in relation to your hip.",
  foot_does_your_unaffected:
    "Does your unaffected ankle completely feel stable?",
  foot_does_your_unaffected_R:
    "Does your unaffected ankle completely feel stable? (Right)",
  foot_does_your_unaffected_L:
    "Does your unaffected ankle completely feel stable? (Left)",
  Stepping_into_R: "Stepping onto or over a high step (Right)",
  Standing: "Standing upright",
  Heavy_domestic_duties:
    "Heavy domestic duties (moving heavy boxes, scrubbing floors, etc)",
  Specify_highest_level_activity:
    "Please specify the highest level of activity that you can participate in",
  StandingR: "Standing upright (Right)",
  StandingL: "Standing upright (Left)",
  Lying_in_bed_R:
    "Lying in bed (turning over, maintaining hip position (Right)",
  Lying_in_beds: "Lying in bed (turning over, maintaining hip position)",
  Lying_in_bed_L: "Lying in bed (turning over, maintaining hip position (Left)",
  Light_to_moderate_work_R:
    "Light to moderate work (standing, walking) (Right)",
  Light_to_moderate_work: "Light to moderate work (standing, walking)",
  Light_to_moderate_work_L: "Light to moderate work (standing, walking) (Left)",
  Heavy_domestic_duties_R:
    "Heavy domestic duties (moving heavy boxes, scrubbing floors, etc) (Right)",
  Heavy_domestic_duties_L:
    "Heavy domestic duties (moving heavy boxes, scrubbing floors, etc) (Left)",
  Light_domestic_duties_R:
    "Light domestic duties (cooking, dusting, etc) (Right)",
  Light_domestic_duties_L:
    "Light domestic duties (cooking, dusting, etc) (Left)",
  High_demand_sports_involving_R:
    "High demand sports involving sprinting or cutting (eg football, basketball, tennis, exercise aerobics) (Right)",
  High_demand_sports_involving:
    "High demand sports involving sprinting or cutting (eg football, basketball, tennis, exercise aerobics)",
  High_demand_sports_involving_L:
    "High demand sports involving sprinting or cutting (eg football, basketball, tennis, exercise aerobics)",
  Low_demand_sports_R: "Low demand sports (eg golf, bowling)(Right)",
  Low_demand_sports: "Low demand sports (eg golf, bowling)",
  Low_demand_sports_L: "Low demand sports (eg golf, bowling)(Left)",
  function: "Function",
  Apley_R: "Apley grind test (Right)",
  Apley_L: "Apley grind test (Left)",
  Pedia_Knee_Satisfaction: "Knee Satisfaction",
  Diagnosis_Patellar_Instability:
    "Does your kneecap get out of place or does it feel like it is getting out of place?",
  Bend_down_object_floor_R:
    "Bend down and pick up an object from the floor (Right)",
  Bend_down_object_floor: "Bend down and pick up an object from the floor",
  Bend_down_object_floor_L:
    "Bend down and pick up an object from the floor (Left)",
  Pedia_Knee_Function: "Paediatric  Knee Function",
  Pedia_Knee_Symptoms: "Paediatric Knee Symptoms",
  SittingKneeBent_both_legs_amount_R:
    "Sitting upright on both legs for any amount of time (Right)",
  SittingKneeBent_both_legs_amount:
    "Sitting upright on both legs for any amount of time",
  SittingKneeBent_both_legs_amount_L:
    "Sitting upright on both legs for any amount of time (Left)",
  Twisting_walking_SittingKneeBent_running_R:
    "Twisting/pivoting knee when walking/Standing/running (Right)",
  Twisting_walking_SittingKneeBent_running:
    "Twisting/pivoting knee when walking/Standing/running",
  Twisting_walking_SittingKneeBent_running_L:
    "Twisting/pivoting knee when walking/Standing/running (Left)",
  Right_Pedi_Knee: "Right Paediatric Kne",
  Left_Pedi_Knee: "Left Paediatric Knee",
  Doing_your_usual_work_R:
    "Doing your usual work because of arm, shoulder, or hand pain (Right)",
  Doing_your_usual_work:
    "Doing your usual work because of arm, shoulder, or hand pain",
  Doing_your_usual_work_L:
    "Doing your usual work because of arm, shoulder, or hand pain (Left)",
  Playing_your_musical_instrument_hand_pain_R:
    "Playing your musical instrument or sport because of arm, shoulder, or hand pain(Right)",
  Playing_your_musical_instrument_hand_pain_L:
    "Playing your musical instrument or sport because of arm, shoulder, or hand pain(Left)",
  Playing_your_musical_instrument_hand_pain:
    "Playing your musical instrument or sport because of arm, shoulder, or hand pain",
  Playing_Specify_sport_musical_instrument_played_R:
    "Playing your musical instrument or sport because of arm, shoulder, or hand pain (Right)",
  Playing_Specify_sport_musical_instrument_played_L:
    "Playing your musical instrument or sport because of arm, shoulder, or hand pain (Left)",
  Playing_Specify_sport_musical_instrument_played_:
    "Playing your musical instrument or sport because of arm, shoulder, or hand pain",
  Doing_Specify_usual_work_R:
    "Doing your usual work because of arm, shoulder, or hand pain* (work includes homemaking if that is your main role) (Right)",
  Doing_Specify_usual_work:
    "Doing your usual work because of arm, shoulder, or hand pain* (work includes homemaking if that is your main role)",
  Doing_Specify_usual_work_L:
    "Doing your usual work because of arm, shoulder, or hand pain* (work includes homemaking if that is your main role) (Left)",
  Adult_Knee_Symptoms_13_18_year: "Other Knee Symptoms",
  Not_able_anything_all_R: "I could not do anything at all (Right)",
  Not_able_anything_all_L: "I could not do anything at all (Left)",
  Able_anything_R: "I could do anything I wanted to (Right)",
  Able_anything_L: "I could do anything I wanted to (Left)",
  Do_not_work_p_R: "I do not play a sport or instrument (Right)",
  Do_not_work_p_L: "I do not play a sport or instrument (Left)",
  Do_not_work_p: "I do not play a sport or instrument",
  During_the_past_week_hand_problem:
    "During the past week were you limited in your work or other regular daily activities as a result of your arm shoulder or hand problem?",
  During_the_past_4_weeks_elbow_been_your_mind:
    'During the past 4 weeks how much has your elbow been "on your mind"?',
  During_the_past_4_weeks:
    'During the past 4 weeks have you felt that your elbow problem is "controlling your life"?',
  Wash_your_back_R: "Wash your back (Right)",
  Wash_your_back: "Wash your back",
  Wash_your_back_L: "Wash your back (Left)",
  Semmes_Weinstein_monofilament_test: "Semmes-Weinstein monofilament test",
  twoo_pt_discrimination: "2-pt discrimination",
  Knee_Results: "Knee Results",
  highest_activity: "What is your highest activity level?",
  highest_activity_R: "What is your highest activity level? (Right)",
  highest_activity_L: "What is your highest activity level? (Left)",
  Top_level: "Top level",
  Lower_competitive_levels: "Lower competitive levels",
  Recreational: "Recreational",
  I_not_sports_but_work: "I do not play sports but I work",
  No_sports_no_work: "No sports, no work",
  Unable_disabled_because_ankle_problems:
    "Unable to walk, disabled because of ankle problems",
  Aerobics_fitness: "Aerobics, fitness",
  Gymnstics: "Gymnastics",
  Motorsports_technical_sports: "Motorsports, technical sports",
  Shooting_archery: "Shooting, archery",
  Skating_in_line_skating: "Skating, in-line skating",
  Track_and_field_field_events: "Track and field: field events",
  Track_and_field_track_events: "Track and field: track events",
  Weightlifting_body_building: "Weightlifting, bodybuilding",
  Rowing_kayaking: "Rowing, kayaking",
  Top_level_first_divisionR:
    "Top level (international elite, professional, national team, or first division) (Right)",
  Top_level_first_divisionL:
    "Top level (international elite, professional, national team, or first division) (Left)",
  Top_level_first_division:
    "Top level (international elite, professional, national team, or first division)",
  Lower_competitive_levelsR: "Lower competitive levels (Right)",
  Lower_competitive_levelsL: "Lower competitive levels (Left)",
  RecreationalR: "Recreational (Right)",
  RecreationalL: "Recreational (Left)",
  Special_professions_stuntman:
    "Special professions: ballet dancer, professional soldier, special rescue worker, stuntman",
  I_not_sports_but_workR: "I do not play sports but I work (Right)",
  I_not_sports_but_workL: "I do not play sports but I work (Left)",
  Able_walk_ground_activities_limited:
    "Able to walk on even ground, every activities limited",
  Everyday_activities_not_limited: "Everyday activities not limited",
  No_sports_no_workR: "No sports, no work (Right)",
  No_sports_no_workL: "No sports, no work (Left)",
  Feeling_of_instability: "Feeling of instability",
  Instability_head: "AC Joint",
  not_concerned: "Not concerned at all",
  no_distraction: "No distraction at all",
  extreme_distraction: "Extreme distraction",
  instability_reslt: "Instability",
  ExSmoker_I: "Ex-Smoker",
  monthly1: "Monthly",
  Only_I: "Only Numbers are allowed",
  Digits_I: "Years should be in digits (1 or 2 digits)",
  Slight_I: "Occasional and slight",
  Present_Heavy_I:
    "Present during heavy or particular activity, aspirin occasionally",
  Present_light_I:
    "Present during light activity, little or none at rest, aspirin frequently",
  Weekly: "Weekly",
  Daily: "Daily",
  Always_bearable_strong_I:
    "Always present, bearable, strong medication required occasionally",
  Always_unbearable_strong_I:
    "Always present, unbearable, strong medication required frequently",
  Slight_restriction_only_I:
    "Slight restriction only, able to work above shoulder level",
  None_or_ignores_it: "None, or ignores it",
  Most_housework_I: "Most housework, shopping, driving, do hair, dress/undress",
  Able_Light_I: "Able to do light housework and most daily living activities",
  Only_Light_I: "Only light activities possible",
  use_Limb_I: "Unable to use limb",
  Slight_occasional_no_compromise_in_activity:
    "Slight, occasional, no compromise in activity",
  Mild_pain:
    "Mild pain, no effect on average activities, rarely moderate pain with unusual activity, may take aspirin",
  Moderate_pain:
    "Moderate pain, tolerable but makes concessions to pain. Some limitations of ordinary activity or work. May require occasional pain medication stronger than aspirin",
  Marked_pain: "Marked pain, serious limitation of activities",
  Totally_disabled: "Totally disabled, crippled, pain in bed, bedridden",
  No_pain_More_than: "No pain/More than 30 minutes/Unlimited",
  sixteen_thirty_minutes: "16-30 minutes/6 blocks",
  greater_than_I: "Not Greater than 100",
  Satisfy_I: "Satisfied and/or better",
  Not_Satisfy_I: "Not satisfied and/or worse",
  Five_Fifteen: "5-15 minutes/2-3 blocks",
  Around_the: "Around the house/Indoors only",
  No_Limit: "No limitations, no support",
  No_Limit_Daily:
    "No limitation of daily activities, limitation of recreational activities, no support",
  No_Limit_recre: "Limited daily and recreational activities,cane",
  Severe_limit:
    "Severe limitation of daily and recreational activities, walker, crutches, wheelchair, brace",
  Fash_conven: "Fashionable, conventional shoes, no insert required",
  Comfort_foot: "Comfort footwear, shoe insert",
  Modi_shoes: "Modified shoes or brace",
  No_Diffi: "No difficulty on any surface",
  Some_diffi: "Some difficulty on uneven terrain, stairs, inclines, ladders",
  Severe_Diffi:
    "Severe difficulty on uneven terrain, stairs, inclines, ladders",
  No_nights: "No nights",
  Very_strenuous_activities_I:
    "Very strenuous activities like jumping or pivoting as in basketball or soccer",
  Only_nights: "Only 1 or 2 nights",
  Strenuous_activity_I:
    "Strenuous activities like heavy physical work, skiing or tennis",
  Moderate_activity_I:
    "Moderate activities like moderate physical work, running or jogging",
  Light_activity_I: "Light activities like walking, housework or yard work",
  Unable_to_perform_I:
    "Unable to perform any of the above activities due to knee pain",
  Blocks: "Greater than 6 blocks",
  Blocks_4: "4-6 blocks",
  Blocks_2: "1-3 blocks",
  Less_block: "Less than 1 block",
  No_Pain_I: "No pain, normal knee, performs 100%",
  Cane_walking: "Cane/walking stick for long walks",
  Occasional_Pain_I:
    "Occasional pain with strenuous sports and heavy work, knee not entirely normal, some limitations but minor and tolerable",
  Occasional_pain_with_light_I:
    "Occasional pain with light recreational sports or moderate work activities, running or having labor, strenuous sports",
  Pain_Usually_bought_I:
    "Pain, usually brought on by sports, light recreational activities or moderate work. Occasionally occurs with walking more than 2km, standing or light work",
  pain_significant_I:
    "Pain is a significant problem with simple activity such as walking <2 km, relieved by rest, unable to do sports",
  Cane_walking_stick: "Cane/walking stick most of the time",
  One_crutch: "One crutch",
  Two_canes: "Two canes/walking sticks",
  A_little_bit: "A little bit",
  Greatly_I: "Greatly",
  Two_crutches: "Two crutches or not able to walk",
  Recreational_physical: "Recreational, physical fitness",
  More_than_30_minutes_I: "More than 30 minutes",
  minutes_I: " ",
  minutes_15_I: "5-15 minutes",
  Around_the_I: "Around the house only",
  Cannot_walk_I: "Cannot walk at all/pain severe when walking",
  constant_I: "Constant",
  No_sports_only: "No sports, only work",
  Mildly_I: "Mildly",
  Only_activities: "Only activities of daily living",
  Very_I: "Very",
  Sick_leave: "Sick leave or disability pension because of health problems",
  Unable_to_swelling_I:
    "Unable to perform any of the above activities due to knee swelling",
  No_giving_way: "No giving way",
  strenuous_sports_I:
    "Occasional giving way with strenuous sports or heavy work, can participate in all sports but some guarding or limitations present. Rarely during athletics or other severe exertion",
  Frequently_athletics_I:
    "Frequently during athletics or other severe exertion. Occasional giving way with light sports or moderate work. Able to compensate but limits vigorous activities, sports, or heavy work, not able to cut or twist suddenly",
  Giving_limits_I:
    "Giving way limits sports or moderate work, occurs infrequently with walking or light work (approx. 3 times per year). Occasionally in daily activities",
  Severely: "Severely",
  Severe_problem_I:
    "Severe problem with simple walking activities, giving way in every step, cannot turn or twist while walking without giving way",
  Giving_walking_I:
    "Giving way with simple walking activities and light work. Occurs once per month, requires guarding. Often in daily activities",
  Stick_or_crutch: "Stick or crutch",
  Weight_bearing: "Weight-bearing impossible",
  Extre_anx: "Extremely anxious/depressed",
  Seve_anx: "Severely anxious/depressed",
  Mode_anx: "Moderately anxious/depressed",
  Sligh_anx: "Slightly anxious/depressed",
  Not_anx: "Not anxious/depressed",
  Competitive_I: "Competitive",
  Dur_sp: "During sport",
  Runn_un: "Running on uneven surfaces",
  Runn_ev: "Running on level surfaces",
  Walk_un: "Walking on uneven surfaces",
  Walk_lev: "Walking on level surfaces",
  Competitive_sports:
    "Competitive sports played at a world or Olympic level or professionally",
  Competitive_sports_such:
    "Competitive sports such as track and field, racquet/ball sports, gymnastics, rowing, skiing, or martial arts played at a national or international level",
  Competitive_water:
    "Competitive sports such as water sports, cycling, hockey, curling, or horseback riding/rodeo played at a national or international level",
  Some_dur: "Sometimes during sport (not every time)",
  Freq_du: "Frequently during sport (every time)",
  Freq_durac: "Frequently during daily activity",
  Some_running: "Sometimes when running",
  Often_run: "Often when running",
  When_walk: "When walking",
  Recreational_sports:
    "Recreational sports such as running, ball/racquet sports, weight training, curling, rowing, golf, yoga, or gymnastics at least 5 times per week",
  Recreational_swimming:
    "Recreational sports such as swimming, water sports, skiing, hockey, rollerblading, cycling, horseback riding, or mountain climbing at least 5 times per week",
  If_got: "If I go fast",
  Recreational_least: "Recreational sports at least twice weekly",
  Recreational_week: "Recreational sports once a week or less",
  On_ball: "On the ball of the foot",
  With_foot: "With my foot flat",
  Recreational_month: "Recreational sports less than once a month",
  Daily_activities:
    "Daily activities such as gardening, climbing multiple flights of stairs, carrying loads, pushing/pulling a load, ability to run if late",
  Daily_cleaning:
    "Daily activities such as cleaning house, climbing 1 or 2 flights of stairs, or walking carrying a light load",
  hop_side: "I hop from side to side",
  hop_spot: "I hop on the spot",
  jump: "I jump",
  Heavy_labor: "Heavy labor such as construction",
  Moderately_heavy: "Moderately heavy labor such as truck driving",
  Light_labor: "Light labor such as nursing",
  Sedentary_work: "Sedentary work such as secretarial or computer-based work",
  Daily_limited:
    "Daily activities limited (eg do not take stairs, unable to carry loads)",
  un_sur: "I run on uneven surfaces",
  un_jog: "I jog on uneven surfaces",
  un_walk_sur: "I walk on uneven surfaces",
  flat_walk: "I walk on flat surfaces",
  Immediately: "Immediately",
  rolled: "I have never rolled over on my ankle",
  Almost_imm: "Almost immediately",
  less_than: "Less than one day",
  days: "1-2 days",
  more_days: "More than 2 days",
  rolled_ankle: "I have never rolled over on my ankle",
  Hurt_at_all_I: "does not hurt at all",
  Hurt_so_much: "hurts so much I can't stand it",
  none_to: "none to very mild",
  Very_sev: "very severe/extreme",
  Not_at_all_puffy: "Not at all puffy",
  Somewhat_puffy: "Somewhat puffy",
  Very_puffy: "Very puffy",
  Extremely_puffy: "Extremely puffy",
  Very_Good: "Very Good",
  Good: "Good",
  Fair: "Fair",
  Poor: "Poor",
  Very_Poor: "Very Poor",
  Strongly_disagree: "Strongly disagree",
  Disagree: "Disagree",
  Neither_agree: "Neither agree nor disagree",
  Agree: "Agree",
  Strongly_agree: "Strongly agree",
  Most_day: "Most day",
  Very_much: "Very much",
  Completely: "Completely",
  Unstable: "Unstable",
  Stable: "Stable",
  Most_timey: "Most of the time",
  All_time: "All of the time",
  once: "Once",
  times: "2-3 times",
  timesfour: "4-5 times",
  more_than: "More than 5 times",
  Female: "Female",
  Male: "Male",
  time_day: "1-2 times/day",
  time_day_three: "3-5 times/day",
  more_than_five: "More than 5 times/day",
  Continued: "Continued",
  want_to_disclose: "Do not want to disclose",
  Ultrasound: "Ultrasound",
  MRI: "MRI",
  Bilateral: "Bilateral",
  MRI_Arthrogram: "MRI Arthrogram (MRI with dye in joint)",
  ten_minutes: "<10 minutes",
  minutes_continued: "10-60 minutes continued",
  sixty_minutes: ">60 minutes",
  Dont_Know: "Don't Know",
  Hip_arthroscopy: "Hip arthroscopy",
  Hip_replacement: "Hip replacement or other non-arthroscopic procedures",
  CT: "CT",
  CT_Arthrogram: "CT Arthrogram (CT with dye in joint)",
  Both: "Both",
  pain_free: "pain-free and allows normal function?",
  little_difficulty: "little difficulty",
  intense_difficulty: "intense difficulty",
  cannot_perform_at_all: "cannot perform at all",
  hip: "hip",
  foot_ankle: "foot ankle",
  knee: "knee",
  elbow: "elbow",
  Selected: "Selected",
  Angina: "Angina",
  Heart_attack: "Heart attack",
  Cardiac_surgery: "Cardiac surgery",
  Carotid_artery: "Carotid artery surgery",
  Heart_Failure: "Heart Failure (fluid in your lungs)",
  Murmur_Heart: "Murmur or Heart Valve Problem",
  without_difficulty: "without difficulty'",
  with_very_little_difficulty: "with very little difficulty",
  with_some_difficulty: "with some difficulty",
  with_much_difficulty: "with much difficulty",
  almost_impossible: "almost impossible",
  impossible: "impossible",
  Cough_with_sputum: "Cough with sputum?",
  Palpitations: "Palpitations/Irregular Heartbeat",
  Cirrhosis: "Cirrhosis",
  Aspirin: "Aspirin",
  Implanted_Device: "Implanted Device (ICD, pacemaker)",
  Hepatitis: "Hepatitis A, B, or C",
  Jaundice: "Jaundice",
  Leukemia_Lymphoma: "Leukemia or Lymphoma",
  Haemophilia: "Haemophilia",
  Blood_Clots: "Blood Clots",
  Clopidogrel_Plavix: "Clopidogrel/Plavix",
  Anaemia: "Anaemia",
  Sickle_Cell: "Sickle Cell Disease",
  Rivaroxaban: "Rivaroxaban",
  Nose_Bleeds: "Nose Bleeds",
  Eliquis: "Eliquis",
  Bleeding_with: "Bleeding with Tooth Extractions",
  Bleeding_after: "Bleeding after Surgery",
  Problems_With: "Problems With Placement of a Breathing Tube",
  Severe_Nausea: "Severe Nausea or Vomiting after surgery",
  Malignant_Hyperthermia:
    "Malignant Hyperthermia (in family who are blood relatives or in yourself)",
  Breathing_problem:
    "Breathing problem or difficulty with insertion of the anaesthesia breathing tube?",
  Check_in: "Check-in",
  info_icon: "Fill in patient questionnaire",
  reminder: "Reminder",
  shared: "Shared",
  private: "Private",
  add_document: "Add Document",
  drap_and_drop: "Drag and Drop files here",
  or_browser_files: "or browser files on your computer",
  browse_files: "Browse files",
  download: "download",
  logout: "Logout",
  title: "Title",
  description: "Description",
  progress: "Progress",
  done: "Done",
  reset: "Reset",
  send: "Send",
  enter_address: "Enter Address",
  full_name: "Full Name",
  mmyy: "MM / YY",
  health_found: "Health fund",
  Not_Applicable: "Not Applicable",
  edit: "edit",
  Edit: "Edit",
  delete: "delete",
  enter_your_description_here: "Enter your Description here",
  enter_your_title_here: "Enter your Title here",
  pls_ensure:
    "Please ensure you've completed all of the mandatory questions before submitting the assessment.",
  Appointement_Date: "Appointement Date",
  Scoring_Self_Evaluation: "Scoring Self-Evaluation",
  out_of: "out of",
  Other_investigations: "Other investigations",
  Type_of_reaction: "Type of reaction",
  Name_and_practice: "Name and practice",
  Surgical_History: "Surgical History year and procedure",
  Number_of_ciggarates: "Number of cigarettes",
  Number_of_standard: "Number of standard drinks a week",
  When_did_you_stop_smoking: "When did you stop smoking",
  years: "Years",
  delete_account: "Delete Account",
  employer_name: "Employer Name",
  invalid_date: "Invalid Date",
  uplaod_complete: "Upload complete !",
  Are_you_sure_you_want_to_delete: "Are you sure you want to delete",
  cancel: "Cancel",
  List_work_activities: "List work activities",
  List_hobbies: "List hobbies / sport activities",
  your_email: "your-email@gmail.com",
  Year: "0 Year, 0 Month, 0 Days",
  Specify_work: "Specify work",
  No_special_characters_are_allowed: "No special characters are allowed",
  Organization_ID: "Organization ID",
  Organization_Name: "Organization Name",
  Icon: "Icon",
  Invalid_file_type_only: "Invalid file type. Only",
  and_: "and",
  files_are_allowed: "files are allowed.",
  File_size_exceeds_200MB_limit: "File size exceeds 200MB limit",
  file_: "file",
  React_Reusable_Form_Component: "React Reusable Form Component",
  text_Field: "Text Field",
  enter_text_here: "Enter text here",
  enter_email_here: "Enter email here",
  enter_valid_mail: "Enter valid mail",
  Dropdown: "Dropdown",
  select_country: "Select Country",
  enter_message: "Enter message!",
  textarea_field: "Textarea Field",
  password: "Password",
  enter_password_here: "Enter password here",
  enter_valid_password: "Enter valid password",
  click_me: "Click me",
  numbers_are_not_allowed: "Numbers are not allowed",
  maximum_50_character_are_allowed: "Maximum 50 character are allowed",
  DD_MM_YYYY: "DD/MM/YYYY",
  maximum_10_Numbers_are_allowed: "Maximum 10 Numbers are allowed",
  invalid_character: "Invalid character",
  fields_only_contain_10_character: "Fields only contain 10 character",
  invalid_home_phone_number: "Invalid home phone number",
  your_: "your",
  are_you_sure_delete_account: "Are you sure you want to delete your account ?",
  special_characters_are_not_allowed: "Special characters are not allowed",
  maximum_digit_are_allowed: "Maximum 1 digit are allowed",
  maximum_10_digits_are_allowed: "Maximum 10 digits are allowed",
  third_party_claim_name: "Third Party Claim Name",
  not_available: "Not Available",
  available: "Available",
  in_which_city_were_you_born: "In which city were you born?",
  what_is_your_favorite_pet_name: "What is your favorite pet name?",
  what_is_your_mother_maiden_name: "What is your mother’s maiden name?",
  what_is_your_high_school_name: "What is your high school name?",
  what_was_the_name_of_first_school: "What was the name of first school?",
  security_question1: "Security Question 1*",
  select_your_first_question: "Select Your First Question",
  answer1: "Answer 1*",
  security_question2: "Security Question 2*",
  select_your_second_question: "Select Your Second Question",
  answer2: "Answer 2*",
  please_choose_different_question: "Please Choose different Question",
  Are_you_sure_want_to_done_the_remainder:
    "Are you sure want to done the reminder ?",
  Confirm: "Confirm",
  Update: "Update",
  success: "success",
  error: "error",
  appointment_time: "Appointment Time",
  form_submitted: "Form Submitted",
  loading: "loading...",
  Loading: "Loading...",
  limit_exceeded: "limit exceeded",
  number_should_be_0_to_180: "Number Should be 0 to 180",
  number_should_be_0_to_100: "Number Should be 0 to 100",
  Kgs: "Kgs",
  Kg: "Kg",
  number_should_be_0_to_5: "Number Should be 0 to 5",
  Power: "Power",
  something_went_wrong: "Something went wrong",
  Flexion: "Flexion",
  test_name: "Test Name",
  Initial: "Initial",
  Recent: "Recent",
  another_action: "Another action",
  something_else: "Something else",
  Feedback: "Feedback",
  your_browser_does_not_support_the_video:
    "Your browser does not support the video tag.",
  Delete: "Delete",
  shoulder: "Shoulder",

  // Date 12/07/23
  Level: "Level",
  left_quality_of_life: "Left Quality Of Life",
  right_quality_of_life: "Right Quality Of Life",
  Normal: "Normal",
  right_foot_ankle: "Right Foot/ankle",
  left_foot_ankle: "Left Foot/ankle",
  Foot_ankle: "Foot/ankle",
  Right_hand_and_wrist: "Right Hand and Wrist",
  Left_hand_and_wrist: "Left Hand and Wrist",
  patient_signature: "Patient Signature",
  relationship_to_patient: "Relationship to patient",
  spacial_characters_not_allow: "Spacial characters not allow",
  very_mild: "very mild",
  never_hurt: "never hurt",
  satisfied: "satisfied",
  can_you: "Can you...",

  profile_updated: "Profile updated",
  profile_not_updated: "Profile not updated",

  // Date 14/07/2023
  email_sent_successfully: "Email sent successfully",
  messages: "Messages",
  adult_knee: "Adult Knee",
  paediatric_knee: "Paediatric Knee",
  back_pain: "back",

  /// Date 17/07/2023

  A_little_puffy: "A little puffy",
  How_well_your_knee_before_:
    "How well did your knee work BEFORE YOU INJURED it?",
  How_well_your_knee_before_R:
    "How well did your knee work BEFORE YOU INJURED it? (Right)",
  How_well_your_knee_before_L:
    "How well did your knee work BEFORE YOU INJURED it? (Left)",
  Cannot_walk: "Cannot walk at all/pain severe when walking/bed and chair only",
  Severe_Anxiety_or_Depression: "Severe Anxiety or Depression",
  next_button: "Next",
  back_button: "Back",
};
