export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";
export const FETCH_SUBID_SUCCESS = "FETCH_SUBID_SUCCESS";
export const FETCH_SUBID_FAIL = "FETCH_SUBID_FAIL";
export const FETCH_MAIL_SUCCESS = "FETCH_MAIL_SUCCESS";
export const FETCH_MAIL_FAIL = "FETCH_MAIL_FAIL";
export const UPDATE_ADULT_KNEE_DATA = "UPDATE_ADULT_KNEE_DATA";
export const UPDATE_ADULT_KNEE_PAIN_FIELD_DATA = "UPDATE_ADULT_KNEE_PAIN_FIELD_DATA";
export const UPDATE_ADULT_KNEE_PAIN_DATA = "ADULT_KNEE_PAIN_DATA";
export const UPDATE_ADULT_KNEE_SYMPTOMS_DATA_SINGLE = "UPDATE_ADULT_KNEE_SYMPTOMS_DATA_SINGLE";
export const UPDATE_ADULT_KNEE_SYMPTOMS_DATA = "UPDATE_ADULT_KNEE_SYMPTOMS_DATA";
export const UPDATE_ADULT_KNEE_FUNCTION_FIELD_DATA = "UPDATE_ADULT_KNEE_FUNCTION_FIELD_DATA";
export const UPDATE_ADULT_KNEE_FUNCTION_DATA = "UPDATE_ADULT_KNEE_FUNCTION_DATA";
export const UPDATE_ADULT_KNEE_QOL_FIELD_DATA = "UPDATE_ADULT_KNEE_QOL_FIELD_DATA";
export const UPDATE_ADULT_KNEE_QOL_DATA = "UPDATE_ADULT_KNEE_QOL_DATA";
export const UPDATE_ADULT_KNEE_SATISFACTION_FIELD_DATA = "UPDATE_ADULT_KNEE_SATISFACTION_FIELD_DATA";
export const UPDATE_ADULT_KNEE_SATISFACTION_DATA= "UPDATE_ADULT_KNEE_SATISFACTION_DATA";
export const UPDATE_ADULT_KNEE_TOTAL_SCORES= "UPDATE_ADULT_KNEE_TOTAL_SCORES";
export const UPDATE_ADULT_KNEE_NORWICHINSTA_FIELD_DATA = "UPDATE_ADULT_KNEE_NORWICHINSTA_FIELD_DATA";
export const UPDATE_ADULT_KNEE_NORWICHINSTA_DATA= "UPDATE_ADULT_KNEE_NORWICHINSTA_DATA";
export const UPDATE_FOOT_FEEDBACK_FIELD_DATA= "UPDATE_FOOT_FEEDBACK_FIELD_DATA";



export const UPDATE_ADULT_KNEE_FEEDBACK= "UPDATE_ADULT_KNEE_FEEDBACK";
