import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";

import { validateInput } from "../../../utilities/Validator";
import scrangeStyles from "./ElbowFeedbackScoreRangeSlider.module.css";

function ElbowFeedbackScoreRangeSlider({
  classes,
  label,
  onChange,
  onMouseUp,
  value,
  validators,
  sliderData,
  minnum,
  maxnum,
  ...sliderProps
}) {
  const [countR, setCountR] = useState(0);
  const handleChange = (event) => {
    const { value } = event.target;
    onChange(value);
  };

  return (
    <>
      <div className={`score_range_slider ${scrangeStyles.score_range_slider}`}>
        <label className={`${scrangeStyles.labels}`}>{label}</label>
        <div className={`${scrangeStyles.tabslider_content}`}>
          <div className={`${scrangeStyles.range}`}>
            <input
              {...sliderProps}
              min={minnum?minnum:"0"}
              max={maxnum?maxnum:"5"}
              steps="1"
              type="range"
              value={value}
              className={`slider ${classes}`}
              id="myRange"
              onChange={handleChange}
              onClick={handleChange}
            />
          </div>
          <ul className={` range_labels ${scrangeStyles.range_labels}`}>
            {sliderData.map((data) =>
                <li className={`${value === data.score ? "active" : ""}`} key={data.score}>
                  {data.score} <span>{data.label}</span>
                </li>
            )}
            
          </ul>
        </div>
      </div>
    </>
  );
}

ElbowFeedbackScoreRangeSlider.propTypes = {
  validators: PropTypes.array,
  validators: PropTypes.array,
};

ElbowFeedbackScoreRangeSlider.defaultProps = {
  validators: [],
  label: "",
  value: "",
  sliderData: [
    { score: "0", label: 'none'},
    { score: "1", label: 'mild'},
    { score: "2", label: 'moderate'},
    { score: "3", label: 'severe'},
    { score: "4", label: 'extreme'},
  ]
};

export default ElbowFeedbackScoreRangeSlider;
