import React from "react";
import "../../../css/ViewTreatmentForm.css";
import useTranslation from "../../customHooks/translations";

export default function FootAnkleQOL({ FootqolScore, AllData }) {
  const translations = useTranslation();
  return (
    <>
      <div className="whitebox padding-none">
        <div className="treatment-content">
          <div className="treatment-row-header">
            5. {translations.patient_foot_ankle_quality_of_life}
          </div>
          <div className="treatment-row">
            <div className="treatment-col">
              <label>
                {translations.patient_foot_ankle_quality_of_life_q1} (
                {translations.Right})
              </label>
              <p>
                {FootqolScore.oftenAnkleR === "0"
                  ? translations.patient_foot_ankle_quality_of_life_q1_a1
                  : FootqolScore.oftenAnkleR === "1"
                  ? translations.patient_foot_ankle_quality_of_life_q1_a2
                  : FootqolScore.oftenAnkleR === "2"
                  ? translations.patient_foot_ankle_quality_of_life_q1_a3
                  : FootqolScore.oftenAnkleR === "3"
                  ? translations.patient_foot_ankle_quality_of_life_q1_a4
                  : translations.patient_foot_ankle_quality_of_life_q1_a5}
              </p>
            </div>
            <div className="treatment-col">
              <label>
                {translations.patient_foot_ankle_quality_of_life_q1} (
                {translations.Left})
              </label>
              <p>
                {FootqolScore.oftenAnkleL === "0"
                  ? translations.patient_foot_ankle_quality_of_life_q1_a1
                  : FootqolScore.oftenAnkleL === "1"
                  ? translations.patient_foot_ankle_quality_of_life_q1_a2
                  : FootqolScore.oftenAnkleL === "2"
                  ? translations.patient_foot_ankle_quality_of_life_q1_a3
                  : FootqolScore.oftenAnkleL === "3"
                  ? translations.patient_foot_ankle_quality_of_life_q1_a4
                  : translations.patient_foot_ankle_quality_of_life_q1_a5}
              </p>
            </div>
          </div>
          <div className="treatment-row">
            <div className="treatment-col">
              <label>
                {translations.patient_foot_ankle_quality_of_life_q2} (
                {translations.Right})
              </label>
              <p>
                {FootqolScore.lifestyleR === "0"
                  ? translations.patient_foot_ankle_quality_of_life_q2_a1
                  : FootqolScore.lifestyleR === "1"
                  ? translations.patient_foot_ankle_quality_of_life_q2_a2
                  : FootqolScore.lifestyleR === "2"
                  ? translations.patient_foot_ankle_quality_of_life_q2_a3
                  : FootqolScore.lifestyleR === "3"
                  ? translations.patient_foot_ankle_quality_of_life_q2_a4
                  : translations.patient_foot_ankle_quality_of_life_q2_a5}
              </p>
            </div>
            <div className="treatment-col">
              <label>
                {translations.patient_foot_ankle_quality_of_life_q2} (
                {translations.Left})
              </label>
              <p>
                {FootqolScore.lifestyleL === "0"
                  ? translations.patient_foot_ankle_quality_of_life_q2_a1
                  : FootqolScore.lifestyleL === "1"
                  ? translations.patient_foot_ankle_quality_of_life_q2_a2
                  : FootqolScore.lifestyleL === "2"
                  ? translations.patient_foot_ankle_quality_of_life_q2_a3
                  : FootqolScore.lifestyleL === "3"
                  ? translations.patient_foot_ankle_quality_of_life_q2_a4
                  : translations.patient_foot_ankle_quality_of_life_q2_a5}
              </p>
            </div>
          </div>
          <div className="treatment-row">
            <div className="treatment-col">
              <label>
                {translations.patient_foot_ankle_quality_of_life_q3} (
                {translations.Right})
              </label>
              <p>
                {FootqolScore.troubledR === "0"
                  ? translations.patient_foot_ankle_quality_of_life_q3_a1
                  : FootqolScore.troubledR === "1"
                  ? translations.patient_foot_ankle_quality_of_life_q3_a2
                  : FootqolScore.troubledR === "2"
                  ? translations.patient_foot_ankle_quality_of_life_q3_a3
                  : FootqolScore.troubledR === "3"
                  ? translations.patient_foot_ankle_quality_of_life_q3_a4
                  : translations.patient_foot_ankle_quality_of_life_q3_a5}
              </p>
            </div>
            <div className="treatment-col">
              <label>
                {translations.patient_foot_ankle_quality_of_life_q3} (
                {translations.Left})
              </label>
              <p>
                {FootqolScore.troubledL === "0"
                  ? translations.patient_foot_ankle_quality_of_life_q3_a1
                  : FootqolScore.troubledL === "1"
                  ? translations.patient_foot_ankle_quality_of_life_q3_a2
                  : FootqolScore.troubledL === "2"
                  ? translations.patient_foot_ankle_quality_of_life_q3_a3
                  : FootqolScore.troubledL === "3"
                  ? translations.patient_foot_ankle_quality_of_life_q3_a4
                  : translations.patient_foot_ankle_quality_of_life_q3_a5}
              </p>
            </div>
          </div>
          <div className="treatment-row">
            <div className="treatment-col">
              <label>
                {translations.patient_foot_ankle_quality_of_life_q4} (
                {translations.Right})
              </label>
              <p>
                {FootqolScore.difficultyR === "0"
                  ? translations.patient_foot_ankle_quality_of_life_q4_a1
                  : FootqolScore.difficultyR === "1"
                  ? translations.patient_foot_ankle_quality_of_life_q4_a2
                  : FootqolScore.difficultyR === "2"
                  ? translations.patient_foot_ankle_quality_of_life_q4_a3
                  : FootqolScore.difficultyR === "3"
                  ? translations.patient_foot_ankle_quality_of_life_q4_a4
                  : translations.patient_foot_ankle_quality_of_life_q4_a5}
              </p>
            </div>
            <div className="treatment-col">
              <label>
                {translations.patient_foot_ankle_quality_of_life_q4} (
                {translations.Left})
              </label>
              <p>
                {FootqolScore.difficultyL === "0"
                  ? translations.patient_foot_ankle_quality_of_life_q4_a1
                  : FootqolScore.difficultyL === "1"
                  ? translations.patient_foot_ankle_quality_of_life_q4_a2
                  : FootqolScore.difficultyL === "2"
                  ? translations.patient_foot_ankle_quality_of_life_q4_a3
                  : FootqolScore.difficultyL === "3"
                  ? translations.patient_foot_ankle_quality_of_life_q4_a4
                  : translations.patient_foot_ankle_quality_of_life_q4_a5}
              </p>
            </div>
          </div>
          <div className="treatment-row">
            <div className="treatment-col">
              <label>
                {translations.patient_foot_ankle_quality_of_life_q5}
              </label>
              <p>
                {FootqolScore.anxiousR === "0"
                  ? translations.patient_foot_ankle_quality_of_life_q5_a1
                  : FootqolScore.anxiousR === "1"
                  ? translations.patient_foot_ankle_quality_of_life_q5_a2
                  : FootqolScore.anxiousR === "2"
                  ? translations.patient_foot_ankle_quality_of_life_q5_a3
                  : FootqolScore.anxiousR === "3"
                  ? translations.patient_foot_ankle_quality_of_life_q5_a4
                  : FootqolScore.anxiousR === "4"
                  ? translations.patient_foot_ankle_quality_of_life_q5_a5
                  : translations.patient_foot_ankle_quality_of_life_q3_a1}
              </p>
            </div>
          </div>
          <div className="treatment-row">
            <div className="treatment-col">
              <label>
                {translations.patient_foot_ankle_quality_of_life_q6}
              </label>
              <p>{FootqolScore.healthToday}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
