import React, { useMemo, useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from 'react-redux'
import backIcon from "../../../../images/back.png";
import nextWhiteIcon from "../../../../images/next-white.png";
import nextIcon from "../../../../images/next.png";
import LoadingSpinner from "../../../custom/loader/LoadingSpinner";
import KneeScoreRangeSlider from "../../../custom/knee-score-range-slider/KneeScoreRangeSlider";
import FullDropdown from "../../../custom/dropdown/FullDropdown";
import FootAnkleTab from '../../tab-section/FootAnkleTab';
import { useSearchParams } from "react-router-dom";
import CustomToaster from "../../../custom/toaster/CustomToaster"


import {
  updateFootSymptomsScore,
  updateFootSymptomsData,
  updateFootAnklePainData,
  updateFootAnkle,
  updateFootInstabilityData,
  updateFootQolData,
  updateFootFunctionData,
  updateFootSatisfactionData,
  updateFootFeedbackfootankle,
  updateFootComberlandData,
  updateAllData

} from "../../../../redux/actions/footAnkle";
import useAxios from "../../../../axiosinstance";
import useTranslation from "../../../customHooks/translations"
import { LeftSide, LeftTrans, RightSide, RigthTrans } from "../../../../utilities/RightLeftFormat";
import NameCustom from "../../../custom/name/NameCustom";


function FootAnkleOtherSymptoms() {
  const translation = useTranslation()
  let lg = sessionStorage.getItem("language");
  const dispatch = useDispatch();
  const axiosinstance = useAxios();
  const [searchParams] = useSearchParams();
  let doctorEnable = searchParams.get("d");
  const { rId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [swellingL, setSwellingL] = useState("0");
  const [swellingR, setSwellingR] = useState("0");
  const [catchingL, setCatchingL] = useState("0");
  const [catchingR, setCatchingR] = useState("0");
  const [grindingL, setGrindingL] = useState("0");
  const [grindingR, setGrindingR] = useState("0");
  const [stiffnessL, setStiffnessL] = useState("0");
  const [stiffnessR, setStiffnessR] = useState("0");
  const [stiffnessSittingL, setStiffnessSittingL] = useState("0");
  const [stiffnessSittingR, setStiffnessSittingR] = useState("0");
  const [updateTotal, setUpdateTotal] = useState(false);
  const [ankleFeelL, setAnkleFeelL] = useState("");
  const [ankleFeelR, setAnkleFeelR] = useState("");
  const [ankleDiagnosedL, setAnkleDiagnosedL] = useState("");
  const [ankleDiagnosedR, setAnkleDiagnosedR] = useState("");
  // const [anklePain, setAnklePAin] = useState("1");
  const anklePain = useSelector(state => state.footAnkle.anklePain);
  const [chk, setChk] = useState(false);
  const Footsymptoms = useSelector((state) => state.footAnkle.symptomsScore[0]);
  const FootInsta = useSelector((state) => state.footAnkle.instabilityScore[0]);
  const json = useSelector((state) => state.footAnkle);
  const save = () => {
    axiosinstance.current
      .get(`extapp/forms/all_forms?resource_id=${rId}&type=21`)
      .then((res) => {

        dispatch(updateFootAnklePainData(res.data.data2[0].painScore));
        dispatch(updateFootSymptomsData(res.data.data2[0].symptomsScore));
        dispatch(updateFootAnkle(res.data.data2[0].anklePain));
        dispatch(updateFootInstabilityData(res.data.data2[0].instabilityScore));
        dispatch(updateFootComberlandData(res.data.data2[0].comberland));
        dispatch(updateFootQolData(res.data.data2[0].qolScore));
        dispatch(updateFootFunctionData(res.data.data2[0].functionScore));
        dispatch(
          updateFootSatisfactionData(res.data.data2[0].satisfactionScore)
        );
        dispatch(updateFootFeedbackfootankle(res.data.data2[0].doctorFeedback));
        setChk(true);
      }).catch((err) => {

        navigate('/un-authorized-access');

      });
  };

  useEffect(() => {
    save();
  }, []);
  useEffect(() => {
    setAnkleFeelL(FootInsta.ankleFeelL)
    setAnkleFeelR(FootInsta.ankleFeelR)
    setAnkleDiagnosedL(FootInsta.ankleDiagnosedL)
    setAnkleDiagnosedR(FootInsta.ankleDiagnosedR)
  }, [FootInsta]);

  useEffect(() => {
    setSwellingL(Footsymptoms.swellingL)
    setSwellingR(Footsymptoms.swellingR)
    setCatchingL(Footsymptoms.catchingL)
    setCatchingR(Footsymptoms.catchingR)
    setGrindingL(Footsymptoms.grindingL)
    setGrindingR(Footsymptoms.grindingR)
    setStiffnessL(Footsymptoms.stiffnessL)
    setStiffnessR(Footsymptoms.stiffnessR)
    setStiffnessSittingL(Footsymptoms.stiffnessSittingL)
    setStiffnessSittingR(Footsymptoms.stiffnessSittingR)

  }, [Footsymptoms]);

  const updateDate = () => {
    axiosinstance.current
      .patch(
        `extapp/forms/update_all_form_type?resource_id=${rId}&action=${doctorEnable === "true"?"1":"0"}`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res);
      });
  };
  useEffect(() => {
    if (chk) {
      updateDate();
    }
  }, [Footsymptoms, updateTotal]);



  const navigate = useNavigate();
  const continueRedirect = (e) => {
    dispatch(updateFootSymptomsData(
      [{
        "swellingL": swellingL,
        "swellingR": swellingR,
        "catchingL": catchingL,
        "catchingR": catchingR,
        "grindingL": grindingL,
        "grindingR": grindingR,
        "stiffnessL": stiffnessL,
        "stiffnessR": stiffnessR,
        "stiffnessSittingL": stiffnessSittingL,
        "stiffnessSittingR": stiffnessSittingR,

      }]
    ))
    navigate("/foot-ankle-function/" + rId);
  };
  const handleSwellingL = (e) => {
    setSwellingL(e);
    dispatch(updateFootSymptomsScore({
      "key": 'swellingL',
      "value": e
    }))
  }
  const handleSwellingR = (e) => {
    setSwellingR(e);
    dispatch(updateFootSymptomsScore({
      "key": 'swellingR',
      "value": e
    }))

  }
  const handleCatchingL = (e) => {
    setCatchingL(e);
    dispatch(updateFootSymptomsScore({
      "key": 'catchingL',
      "value": e
    }))
  }
  const handleCatchingR = (e) => {
    setCatchingR(e);
    dispatch(updateFootSymptomsScore({
      "key": 'catchingR',
      "value": e
    }))
  }
  const handleGrindingL = (e) => {
    setGrindingL(e);
    dispatch(updateFootSymptomsScore({
      "key": 'grindingL',
      "value": e
    }))
  }
  const handleGrindingR = (e) => {
    setGrindingR(e);
    dispatch(updateFootSymptomsScore({
      "key": 'grindingR',
      "value": e
    }))
  }
  const handleStiffnessL = (e) => {
    setStiffnessL(e);
    dispatch(updateFootSymptomsScore({
      "key": 'stiffnessL',
      "value": e
    }))
  }
  const handleStiffnessR = (e) => {
    setStiffnessR(e);
    dispatch(updateFootSymptomsScore({
      "key": 'stiffnessR',
      "value": e
    }))
  }
  const handleStiffnessSittingL = (e) => {
    setStiffnessSittingL(e);
    dispatch(updateFootSymptomsScore({
      "key": 'stiffnessSittingL',
      "value": e
    }))
  }
  const handleStiffnessSittingR = (e) => {
    setStiffnessSittingR(e);
    dispatch(updateFootSymptomsScore({
      "key": 'stiffnessSittingR',
      "value": e
    }))
  }
  // const handleAnklePain = (e) => {
  //   setAnklePAin(e);
  // }
  let akpt = searchParams.get("akpt");

  const [msg, setMsg] = useState('');
  const [msgType, setmsgType] = useState('');
  const handleSubmit = () => {
    updateAllData(json, dispatch);
    setUpdateTotal(!updateTotal);
    setMsg(translation.update)
    setmsgType('success')
   setTimeout(() => {
     let view_circular=  sessionStorage.getItem("view_circular")
      // window.location.href = process.env.REACT_APP_DOCTOR_URL + "patient-view/" + akpt
      // window.location.href = process.env.REACT_APP_DOCTOR_URL +`view-management-plan/${akpt}/${window.btoa(
      //   rId
      // )}/?org=${window.btoa(sessionStorage.getItem("orgid")||'')}&formType=${
      //   window.btoa('21') || ""
      // }&view_circular=${view_circular==='false'?'false':'true'}`
      window.location.href = process.env.REACT_APP_DOCTOR_URL +`patient-details/${akpt}?formid=21`
      setMsg('')
      setmsgType('success')
    }, 2000);
  };

  const handleNext = () => {
    navigate(`/foot-ankle-function/${rId}?d=true&akpt=${akpt}`);
  }

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="pain_screen mb_100">
           <div class="d-flex justify-content-end">
            <NameCustom />
          </div>
          <div className="panel">
            <div className="panel-body p_25">
              {doctorEnable === "true" && <FootAnkleTab rId={rId} />}
              <CustomToaster msg={msg} msgType={msgType} />
           
              <h4 className="ph_title f_40">{translation.patient_foot_ankle_other_symptoms}</h4>
              <div className="function_formtop">
                <div className="row">
                  <div className="col-md-6 col-sm-6 function_head">
                    <strong>
                      {translation.patient_foot_ankle_other_symptoms_instruction1}<br />
                      {translation.patient_foot_ankle_other_symptoms_instruction2}<br />
                    </strong>
                    <label className="mt-2">
                      {translation.patient_foot_ankle_other_symptoms_instruction3}
                    </label> <br />
                    <span dir={lg === "ara" ? "rtl" : " "}>
                      (<strong>0</strong> = {translation.patient_foot_ankle_other_symptoms_instruction3_0}, <strong>1</strong> = {translation.patient_foot_ankle_other_symptoms_instruction3_1},
                      <strong> 2</strong> = {translation.patient_foot_ankle_other_symptoms_instruction3_2}, <strong>3</strong> = {translation.patient_foot_ankle_other_symptoms_instruction3_3}, <strong>4</strong> = {translation.patient_foot_ankle_other_symptoms_instruction3_4})
                    </span>
                  </div>

                  <div className="col-md-6 col-sm-6 function_head">
                    <p>
                      {translation.patient_foot_ankle_other_symptoms_note}
                    </p>
                  </div>

                </div>

                <div className="row">
                  {anklePain === "1" || anklePain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_foot_ankle_other_symptoms_q1 + RigthTrans(anklePain, translation)}
                        classes=""
                        value={swellingR}
                        onChange={handleSwellingR}
                        sliderData={[
                          { score: "0", label: translation.patient_foot_ankle_other_symptoms_q1_a1 },
                          { score: "1", label: translation.patient_foot_ankle_other_symptoms_q1_a2 },
                          { score: "2", label: translation.patient_foot_ankle_other_symptoms_q1_a3 },
                          { score: "3", label: translation.patient_foot_ankle_other_symptoms_q1_a4 },
                          { score: "4", label: translation.patient_foot_ankle_other_symptoms_q1_a5 },
                        ]}
                      />
                    </div>
                  ) : ""}
                  {anklePain === "2" || anklePain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_foot_ankle_other_symptoms_q1 + LeftTrans(anklePain, translation)}
                        classes=""
                        value={swellingL}
                        onChange={handleSwellingL}
                        sliderData={[
                          { score: "0", label: translation.patient_foot_ankle_other_symptoms_q1_a1 },
                          { score: "1", label: translation.patient_foot_ankle_other_symptoms_q1_a2 },
                          { score: "2", label: translation.patient_foot_ankle_other_symptoms_q1_a3 },
                          { score: "3", label: translation.patient_foot_ankle_other_symptoms_q1_a4 },
                          { score: "4", label: translation.patient_foot_ankle_other_symptoms_q1_a5 },
                        ]}
                      />
                    </div>
                  ) : ""}
                </div>
                <div className="row">
                  {anklePain === "1" || anklePain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_foot_ankle_other_symptoms_q2 + RigthTrans(anklePain, translation)}
                        classes=""
                        value={grindingR}
                        onChange={handleGrindingR}
                        sliderData={[
                          { score: "0", label: translation.patient_foot_ankle_other_symptoms_q2_a1 },
                          { score: "1", label: translation.patient_foot_ankle_other_symptoms_q2_a2 },
                          { score: "2", label: translation.patient_foot_ankle_other_symptoms_q2_a3 },
                          { score: "3", label: translation.patient_foot_ankle_other_symptoms_q2_a4 },
                          { score: "4", label: translation.patient_foot_ankle_other_symptoms_q2_a5 },
                        ]}
                      />
                    </div>
                  ) : ""}
                  {anklePain === "2" || anklePain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_foot_ankle_other_symptoms_q2 + LeftTrans(anklePain, translation)}
                        value={grindingL}
                        onChange={handleGrindingL}
                        sliderData={[
                          { score: "0", label: translation.patient_foot_ankle_other_symptoms_q2_a1 },
                          { score: "1", label: translation.patient_foot_ankle_other_symptoms_q2_a2 },
                          { score: "2", label: translation.patient_foot_ankle_other_symptoms_q2_a3 },
                          { score: "3", label: translation.patient_foot_ankle_other_symptoms_q2_a4 },
                          { score: "4", label: translation.patient_foot_ankle_other_symptoms_q2_a5 },
                        ]}
                      />
                    </div>
                  ) : ""}
                </div>
                <div className="row">
                  {anklePain === "1" || anklePain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_foot_ankle_other_symptoms_q3 + RigthTrans(anklePain, translation)}
                        classes=""
                        value={catchingR}
                        onChange={handleCatchingR}
                        sliderData={[
                          { score: "0", label: translation.patient_foot_ankle_other_symptoms_q3_a1 },
                          { score: "1", label: translation.patient_foot_ankle_other_symptoms_q3_a2 },
                          { score: "2", label: translation.patient_foot_ankle_other_symptoms_q3_a3 },
                          { score: "3", label: translation.patient_foot_ankle_other_symptoms_q3_a4 },
                          { score: "4", label: translation.patient_foot_ankle_other_symptoms_q3_a5 },
                        ]}
                      />
                    </div>
                  ) : ""}
                  {anklePain === "2" || anklePain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_foot_ankle_other_symptoms_q3 + LeftTrans(anklePain, translation)}
                        classes=""
                        value={catchingL}
                        onChange={handleCatchingL}
                        sliderData={[
                          { score: "0", label: translation.patient_foot_ankle_other_symptoms_q3_a1 },
                          { score: "1", label: translation.patient_foot_ankle_other_symptoms_q3_a2 },
                          { score: "2", label: translation.patient_foot_ankle_other_symptoms_q3_a3 },
                          { score: "3", label: translation.patient_foot_ankle_other_symptoms_q3_a4 },
                          { score: "4", label: translation.patient_foot_ankle_other_symptoms_q3_a5 },
                        ]}
                      />
                    </div>
                  ) : ""}
                </div>

                <div className="col-md-6 col-sm-6 function_head">

                  <label>
                    {translation.patient_foot_ankle_other_symptoms_instruction4}
                  </label> <br />
                  <span>
                    (<strong>0</strong> = {translation.patient_foot_ankle_other_symptoms_instruction4_0}, <strong>1</strong> = {translation.patient_foot_ankle_other_symptoms_instruction4_1},
                    <strong> 2</strong> = {translation.patient_foot_ankle_other_symptoms_instruction4_2}, <strong>3</strong> =
                    {translation.patient_foot_ankle_other_symptoms_instruction4_3}, <strong>4</strong> = {translation.patient_foot_ankle_other_symptoms_instruction4_4})
                  </span>
                </div>


                <div className="row">
                  {anklePain === "1" || anklePain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_foot_ankle_other_symptoms_q4 + RigthTrans(anklePain, translation)}
                        classes=""
                        value={stiffnessR}
                        onChange={handleStiffnessR}
                        sliderData={[
                          { score: "0", label: translation.patient_foot_ankle_other_symptoms_q4_a1 },
                          { score: "1", label: translation.patient_foot_ankle_other_symptoms_q4_a2 },
                          { score: "2", label: translation.patient_foot_ankle_other_symptoms_q4_a3 },
                          { score: "3", label: translation.patient_foot_ankle_other_symptoms_q4_a4 },
                          { score: "4", label: translation.patient_foot_ankle_other_symptoms_q4_a5 },
                        ]}
                      />
                    </div>
                  ) : ""}
                  {anklePain === "2" || anklePain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_foot_ankle_other_symptoms_q4 + LeftTrans(anklePain, translation)}
                        classes=""
                        value={stiffnessL}
                        onChange={handleStiffnessL}
                        sliderData={[
                          { score: "0", label: translation.patient_foot_ankle_other_symptoms_q4_a1 },
                          { score: "1", label: translation.patient_foot_ankle_other_symptoms_q4_a2 },
                          { score: "2", label: translation.patient_foot_ankle_other_symptoms_q4_a3 },
                          { score: "3", label: translation.patient_foot_ankle_other_symptoms_q4_a4 },
                          { score: "4", label: translation.patient_foot_ankle_other_symptoms_q4_a5 },
                        ]}
                      />
                    </div>
                  ) : ""}
                </div>
                <div className="row">
                  {anklePain === "1" || anklePain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_foot_ankle_other_symptoms_q5 + RigthTrans(anklePain, translation)}
                        classes=""
                        value={stiffnessSittingR}
                        onChange={handleStiffnessSittingR}
                        sliderData={[
                          { score: "0", label: translation.patient_foot_ankle_other_symptoms_q5_a1 },
                          { score: "1", label: translation.patient_foot_ankle_other_symptoms_q5_a2 },
                          { score: "2", label: translation.patient_foot_ankle_other_symptoms_q5_a3 },
                          { score: "3", label: translation.patient_foot_ankle_other_symptoms_q5_a4 },
                          { score: "4", label: translation.patient_foot_ankle_other_symptoms_q5_a5 },
                        ]}
                      />
                    </div>
                  ) : ""}
                  {anklePain === "2" || anklePain === "3" ? (
                    <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                      <KneeScoreRangeSlider
                        label={translation.patient_foot_ankle_other_symptoms_q5 + LeftTrans(anklePain, translation)}
                        classes=""
                        value={stiffnessSittingL}
                        onChange={handleStiffnessSittingL}
                        sliderData={[
                          { score: "0", label: translation.patient_foot_ankle_other_symptoms_q5_a1 },
                          { score: "1", label: translation.patient_foot_ankle_other_symptoms_q5_a2 },
                          { score: "2", label: translation.patient_foot_ankle_other_symptoms_q5_a3 },
                          { score: "3", label: translation.patient_foot_ankle_other_symptoms_q5_a4 },
                          { score: "4", label: translation.patient_foot_ankle_other_symptoms_q5_a5 },
                        ]}
                      />
                    </div>
                  ) : ""}
                </div>
                <br />
              </div>
              {doctorEnable === "true" && (
                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6">
                  </div>
                  <div className="col-md-6 col-sm-6 text-end">
                  <button
                      className="next_btn btn_fill me-3"
                      onClick={() => handleNext()}
                    >
                      {translation.next_button}
                      <span>
                        <img src={nextIcon} alt="" />
                        {/* <img src={nextWhiteIcon} alt="" /> */}
                      </span>
                    </button>
                    <button
                      className="next_btn btn_fill"
                      onClick={() => handleSubmit()}
                    >
                      {translation.submit}
                      <span>
                        <img src={nextIcon} alt="" />
                        {/* <img src={nextWhiteIcon} alt="" /> */}
                      </span>
                    </button>
                  </div>
                </div>
              )}


              {doctorEnable !== "true" && (
                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6 col-6">

                    {
                    

                      ankleDiagnosedR === "1" || ankleDiagnosedL === "1" || ankleFeelL === "1" ||
                      ankleFeelR === "1"?
                        <Link to={`/cumberland-ankle/${rId}`} className="assm_back_btn">
                          <img src={backIcon} alt="" />
                          <span>{translation.back_button}</span>
                        </Link> : <Link to={`/foot-ankle-instability/${rId}`} className="assm_back_btn">
                          <img src={backIcon} alt="" />
                          <span>{translation.back_button}</span>
                        </Link>
                    }

                    {/* <Link to={`/foot-ankle-instability/${rId}`} className="assm_back_btn">
                      <img src={backIcon} alt="" />
                      <span>{translation.back_button}</span>
                    </Link> */}
                  </div>
                  <div className="col-md-6 col-sm-6 col-6 text-end">
                    <button
                      className="next_btn btn_fill"
                      onClick={continueRedirect}
                    >
                      {translation.next_button}
                      <span>
                        <img src={nextIcon} alt="" />
                        <img src={nextWhiteIcon} alt="" />
                      </span>
                    </button>
                  </div>
                </div>
              )}

            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default FootAnkleOtherSymptoms;
