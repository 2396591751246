import React from "react";
import "../../../css/ViewTreatmentForm.css";
import useTranslation from "../../customHooks/translations";

export default function ElbowSymtoms({ elbowSymptoms, AllData }) {
  const translations = useTranslation();
  return (
    <>
      <div className="whitebox padding-none">
        <div className="treatment-content">
          <div className="treatment-row-header">
            3. {translations.patient_elbow_symptoms}{" "}
          </div>
          <div className="treatment-row">
            <div className="treatment-table">
              <table cellpadding="0" cellspacing="0">
                <thead>
                  <tr>
                    <th></th>

                    <th>{translations.Right_Elbow}</th>

                    <th>{translations.Left_Elbow}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{translations.patient_elbow_symptoms_q1} :</td>

                    <td>{elbowSymptoms.armR}</td>
                    <td>{elbowSymptoms.armL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_elbow_symptoms_q2} :</td>

                    <td>{elbowSymptoms.TinglingR}</td>
                    <td>{elbowSymptoms.TinglingL}</td>
                  </tr>
                  <tr>
                    <td>{translations.patient_elbow_symptoms_q3} :</td>

                    <td>{elbowSymptoms.HipPer}</td>
                    <td>{elbowSymptoms.HipPerL}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          {/* <div className="treatment-row">
                        <div className="treatment-col">
                            <label>How would you rate your elbow today as a percentage of normal?(Right)</label>
                            <p>{elbowSymptoms.HipPer}</p>
                        </div>
                        <div className="treatment-col">
                            <label>How would you rate your elbow today as a percentage of normal?(Left)</label>
                            <p>{elbowSymptoms.HipPerL}</p>
                        </div>
                    </div> */}
        </div>
      </div>
    </>
  );
}
