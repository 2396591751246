import React, { useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import backIcon from "../../../../images/back.png";
import nextWhiteIcon from "../../../../images/next-white.png";
import nextIcon from "../../../../images/next.png";
import LoadingSpinner from "../../../custom/loader/LoadingSpinner";
import KneeScoreRangeSlider from "../../../custom/knee-score-range-slider/KneeScoreRangeSlider";
import HipScoreRangeSlider from "../../../custom/hip-score-range-slider/HipScoreRangeSlider";
import FullDropdown from "../../../custom/dropdown/FullDropdown";
import {
  updateHipSatisfactionFieldScore,
  updateHIPSatisfactionScores,
  updateHIPQOLScores,
  updateHIPfunctionScores,
  updateHIPPainScores,
  updateHIPSymptomsScores,
  updateHipScores,
  updateHipFeedbackAll,
  updateAllData,
} from "../../../../redux/actions/hipAction";
import useAxios from "../../../../axiosinstance";
import useTranslation from "../../../customHooks/translations";
import HipTab from "../../tab-section/HipTab";
import { useSearchParams } from "react-router-dom";
import CustomToaster from "../../../custom/toaster/CustomToaster";
import Checkbox from "../../../custom/checkbox/Checkbox";
import {
  LeftSide,
  LeftTrans,
  RightSide,
  RigthTrans,
} from "../../../../utilities/RightLeftFormat";
import NameCustom from "../../../custom/name/NameCustom";

function HipSatisfaction() {
  const translation = useTranslation();
  let lg = sessionStorage.getItem("language");
  const axiosInstance = useAxios();
  const { rId } = useParams();
  const [searchParams] = useSearchParams();
  let doctorEnable = searchParams.get("d");
  const evlHipPain = useSelector((state) => state.hipScoreReducer.evlHipPain);
  const [isLoading, setIsLoading] = useState(true);
  const [overallL, setOverallL] = useState("4");
  const [overallR, setOverallR] = useState("4");
  const [improvePainL, setImprovePainL] = useState("4");
  const [improvePainR, setImprovePainR] = useState("4");
  const [improveHomeL, setImproveHomeL] = useState("4");
  const [improveHomeR, setImproveHomeR] = useState("4");
  const [improveRecrL, setImproveRecrL] = useState("4");
  const [improveRecrR, setImproveRecrR] = useState("4");

  const [applicableR, setapplicableR] = useState(false);
  const [applicableL, setapplicableL] = useState(false);

  const [applicable1R, setapplicable1R] = useState(false);
  const [applicable1L, setapplicable1L] = useState(false);

  const [applicable2R, setapplicable2R] = useState(false);
  const [applicable2L, setapplicable2L] = useState(false);

  const [applicable3R, setapplicable3R] = useState(false);
  const [applicable3L, setapplicable3L] = useState(false);

  console.log("overallR", overallR);
  // const [evlHipPain, setEvlHipPain] = useState("1");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [updateTotal, setUpdateTotal] = useState(false);
  const [chk, setChk] = useState(false);

  const satisfactionScore = useSelector(
    (state) => state.hipScoreReducer.satisfactionScore[0]
  );
  // console.log(functionScore)
  const json = useSelector((state) => state.hipScoreReducer);

  useEffect(() => {
    setOverallL(satisfactionScore.overallL);
    setOverallR(satisfactionScore.overallR);
    setImprovePainL(satisfactionScore.improvePainL);
    setImprovePainR(satisfactionScore.improvePainR);
    setImproveHomeL(satisfactionScore.improveHomeL);
    setImproveHomeR(satisfactionScore.improveHomeR);
    setImproveRecrL(satisfactionScore.improveRecrL);
    setImproveRecrR(satisfactionScore.improveRecrR);
    setapplicableR(satisfactionScore.applicableR);
    setapplicableL(satisfactionScore.applicableL);
    setapplicable1R(satisfactionScore.applicable1R);
    setapplicable1L(satisfactionScore.applicable1L);
    setapplicable2R(satisfactionScore.applicable2R);
    setapplicable2L(satisfactionScore.applicable2L);
    setapplicable3R(satisfactionScore.applicable3R);
    setapplicable3L(satisfactionScore.applicable3L);
  }, [satisfactionScore]);

  console.log("satisfactionScore", satisfactionScore);

  const [doctorStatus, setDoctorStatus] = useState(false);

  const save = () => {
    axiosInstance.current
      .get(`extapp/forms/all_forms?resource_id=${rId}&type=6`)
      .then((res) => {
        console.log(res.data.data2);
        dispatch(updateHIPPainScores(res.data.data2[0].painScore));
        dispatch(updateHIPSymptomsScores(res.data.data2[0].symptomsScore));
        dispatch(updateHipScores(res.data.data2[0].evlHipPain));
        dispatch(updateHIPfunctionScores(res.data.data2[0].functionScore));
        dispatch(updateHIPQOLScores(res.data.data2[0].qolScore));
        dispatch(
          updateHIPSatisfactionScores(res.data.data2[0].satisfactionScore)
        );
        dispatch(updateHipFeedbackAll(res.data.data2[0].doctorFeedback));
        setDoctorStatus(res.data.doctorStatus);
        setChk(true);
      })
      .catch((err) => {
        navigate("/un-authorized-access");
      });
  };
  useEffect(() => {
    save();
  }, []);

  const updateDate = () => {
    axiosInstance.current
      .patch(
        `extapp/forms/update_all_form_type?resource_id=${rId}&action=${
          doctorEnable === "true" ? "1" : "0"
        }`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res);
      });
  };
  useEffect(() => {
    if (chk) {
      updateDate();
    }
  }, [satisfactionScore, updateTotal]);

  const handleOverallL = (e) => {
    dispatch(
      updateHipSatisfactionFieldScore({
        key: "overallL",
        value: e,
      })
    );
    setOverallL(e);
  };
  const handleOverallR = (e) => {
    dispatch(
      updateHipSatisfactionFieldScore({
        key: "overallR",
        value: e,
      })
    );
    setOverallR(e);
  };
  const handleImprovePainL = (e) => {
    dispatch(
      updateHipSatisfactionFieldScore({
        key: "improvePainL",
        value: e,
      })
    );
    setImprovePainL(e);
  };
  const handleImprovePainR = (e) => {
    dispatch(
      updateHipSatisfactionFieldScore({
        key: "improvePainR",
        value: e,
      })
    );
    setImprovePainR(e);
  };
  const handleImproveHomeL = (e) => {
    dispatch(
      updateHipSatisfactionFieldScore({
        key: "improveHomeL",
        value: e,
      })
    );
    setImproveHomeL(e);
  };
  const handleImproveHomeR = (e) => {
    dispatch(
      updateHipSatisfactionFieldScore({
        key: "improveHomeR",
        value: e,
      })
    );
    setImproveHomeR(e);
  };
  const handleImproveRecrL = (e) => {
    dispatch(
      updateHipSatisfactionFieldScore({
        key: "improveRecrL",
        value: e,
      })
    );
    setImproveRecrL(e);
  };
  const handleImproveRecrR = (e) => {
    dispatch(
      updateHipSatisfactionFieldScore({
        key: "improveRecrR",
        value: e,
      })
    );
    setImproveRecrR(e);
  };

  const handleapplicableR = (e) => {
    setapplicableR(e);
    // setOverallR("0")
    dispatch(
      updateHipSatisfactionFieldScore({
        key: "applicableR",
        value: e,
      })
    );
    dispatch(
      updateHipSatisfactionFieldScore({
        key: "overallR",
        value: "",
      })
    );
  };

  const handleapplicableL = (e) => {
    setapplicableL(e);
    // setOverallL("0")
    dispatch(
      updateHipSatisfactionFieldScore({
        key: "applicableL",
        value: e,
      })
    );
    dispatch(
      updateHipSatisfactionFieldScore({
        key: "overallL",
        value: "",
      })
    );
  };

  const handleapplicable1R = (e) => {
    setapplicable1R(e);
    // setImprovePainR("0")
    dispatch(
      updateHipSatisfactionFieldScore({
        key: "applicable1R",
        value: e,
      })
    );
    dispatch(
      updateHipSatisfactionFieldScore({
        key: "improvePainR",
        value: "",
      })
    );
  };

  const handleapplicable1L = (e) => {
    setapplicable1L(e);
    // setImprovePainL("0")
    dispatch(
      updateHipSatisfactionFieldScore({
        key: "applicable1L",
        value: e,
      })
    );
    dispatch(
      updateHipSatisfactionFieldScore({
        key: "improvePainL",
        value: "",
      })
    );
  };

  const handleapplicable2R = (e) => {
    setapplicable2R(e);
    // setImproveHomeR("0")
    dispatch(
      updateHipSatisfactionFieldScore({
        key: "applicable2R",
        value: e,
      })
    );
    dispatch(
      updateHipSatisfactionFieldScore({
        key: "improveHomeR",
        value: "",
      })
    );
  };

  const handleapplicable2L = (e) => {
    setapplicable2L(e);
    // setImproveHomeL("0")
    dispatch(
      updateHipSatisfactionFieldScore({
        key: "applicable2L",
        value: e,
      })
    );
    dispatch(
      updateHipSatisfactionFieldScore({
        key: "improveHomeL",
        value: "",
      })
    );
  };

  const handleapplicable3R = (e) => {
    setapplicable3R(e);
    // setImproveRecrR("0")
    dispatch(
      updateHipSatisfactionFieldScore({
        key: "applicable3R",
        value: e,
      })
    );
    dispatch(
      updateHipSatisfactionFieldScore({
        key: "improveRecrR",
        value: "",
      })
    );
  };

  const handleapplicable3L = (e) => {
    setapplicable3L(e);
    // setImproveRecrL("0")
    dispatch(
      updateHipSatisfactionFieldScore({
        key: "applicable3L",
        value: e,
      })
    );
    dispatch(
      updateHipSatisfactionFieldScore({
        key: "improveRecrL",
        value: "",
      })
    );
  };

  const continueRedirect = (e) => {
    dispatch(
      updateHIPSatisfactionScores([
        {
          overallL: overallL,
          overallR: overallR,
          improvePainL: improvePainL,
          improvePainR: improvePainR,
          improveHomeL: improveHomeL,
          improveHomeR: improveHomeR,
          improveRecrL: improveRecrL,
          improveRecrR: improveRecrR,
        },
      ])
    );
    navigate("/hip-complete/" + rId);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  const [msg, setMsg] = useState("");
  const [msgType, setmsgType] = useState("");
  const SubmitForm = (id) => {
    axiosInstance.current.patch(
      `extapp/forms/update_scoring?resource_id=${id}`,
      {
        patient_scoring_date: new Date(),
        status: 1,
      }
    );
  };

  let akpt = searchParams.get("akpt");
  const handleSubmit = () => {
    updateAllData(json, dispatch);
    setUpdateTotal(!updateTotal);
    setMsg(translation.update);
    setmsgType("success");
    SubmitForm(rId);
     setTimeout(() => {
     let view_circular=  sessionStorage.getItem("view_circular")
      // window.location.href = process.env.REACT_APP_DOCTOR_URL + "patient-view/" + akpt
      // window.location.href = process.env.REACT_APP_DOCTOR_URL +`view-management-plan/${akpt}/${window.btoa(
      //   rId
      // )}/?org=${window.btoa(sessionStorage.getItem("orgid")||'')}&formType=${
      //   window.btoa('6') || ""
      // }&view_circular=${view_circular==='false'?'false':'true'}`
      window.location.href = process.env.REACT_APP_DOCTOR_URL +`patient-details/${akpt}?formid=6`
      setMsg('')
      setmsgType('success')
    }, 2000);
  };

  const handleNext=()=>{
    navigate(`/hip-feedback/${rId}?d=true&akpt=${akpt}`);

  }

  console.log(applicableR, applicable1R, "jhjhj");
  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="adault_knee_satisfaction_screen mb_100">
          <div class="d-flex justify-content-end">
            <NameCustom />
          </div>
          <div className="panel">
            <div className="panel-body p_25">
              {doctorEnable === "true" && <HipTab rId={rId} />}
              <CustomToaster msg={msg} msgType={msgType} />

              <h4 className="ph_title f_40">
                {translation.patient_hip_satisfaction}
              </h4>
              <div className="function_formtop">
                <div className="row">
                  <div className="col-md-6 col-sm-6 function_head">
                    <label>
                      {translation.patient_hip_satisfaction_instructions}
                    </label>{" "}
                    <br />
                    <span dir={lg === "ara" ? "rtl" : " "}>
                      (<strong>1</strong> ={" "}
                      {translation.patient_hip_satisfaction_instructions_1},{" "}
                      <strong>2</strong> ={" "}
                      {translation.patient_hip_satisfaction_instructions_2},{" "}
                      <strong>3</strong> ={" "}
                      {translation.patient_hip_satisfaction_instructions_3},{" "}
                      <strong> 4</strong> =
                      {translation.patient_hip_satisfaction_instructions_4})
                    </span>
                  </div>
                </div>

                <div className="row">
                  {evlHipPain === "1" || evlHipPain === "3" ? (
                    <div
                      className={"col-md-6 col-sm-6 mb_10 satis" + RightSide()}
                    >
                      <label class="form-check-label not-applicable">
                        <input
                          className="check_ mx-3"
                          type="checkbox"
                          id="radio1"
                          name="radio1"
                          value={applicableR}
                          checked={applicableR}
                          style={{ marginRight: 10 }}
                          onChange={(e) => handleapplicableR(e.target.checked)}
                        ></input>

                        {translation.patient_hip_satisfaction_q1_not_applicable}
                      </label>
                      <HipScoreRangeSlider
                        maximun="1"
                        minimum="4"
                        sliderData={[
                          {
                            score: "1",
                            label: translation.patient_hip_satisfaction_q1_a1,
                          },
                          {
                            score: "2",
                            label: translation.patient_hip_satisfaction_q1_a2,
                          },
                          {
                            score: "3",
                            label: translation.patient_hip_satisfaction_q1_a3,
                          },
                          {
                            score: "4",
                            label: translation.patient_hip_satisfaction_q1_a4,
                          },
                        ]}
                        label={
                          translation.patient_hip_satisfaction_q1 +
                          RigthTrans(evlHipPain, translation)
                        }
                        classes=""
                        value={overallR}
                        onChange={handleOverallR}
                        view={applicableR === true ? true : false}
                      />
                      {/* <Checkbox
                        label="{translation.Not_applicable}"
                        name="applicable"
                        selected={applicableR == true ? true : false }
                        value={applicableR}
                        onChange={handleapplicableR}
                        styleClass="custom_check_box"
                        id="radio1"
                      /> */}
                    </div>
                  ) : (
                    ""
                  )}
                  {evlHipPain === "2" || evlHipPain === "3" ? (
                    <div
                      className={"col-md-6 col-sm-6 mb_10 satis" + LeftSide()}
                    >
                      <label class="form-check-label not-applicable">
                        <input
                          className="check_ mx-3"
                          type="checkbox"
                          id="radio2"
                          name="radio2"
                          value={applicableL}
                          checked={applicableL}
                          style={{ marginRight: 10 }}
                          onChange={(e) => handleapplicableL(e.target.checked)}
                        ></input>

                        {translation.patient_hip_satisfaction_q1_not_applicable}
                      </label>
                      <HipScoreRangeSlider
                        maximun="1"
                        minimum="4"
                        sliderData={[
                          {
                            score: "1",
                            label: translation.patient_hip_satisfaction_q1_a1,
                          },
                          {
                            score: "2",
                            label: translation.patient_hip_satisfaction_q1_a2,
                          },
                          {
                            score: "3",
                            label: translation.patient_hip_satisfaction_q1_a3,
                          },
                          {
                            score: "4",
                            label: translation.patient_hip_satisfaction_q1_a4,
                          },
                        ]}
                        label={
                          translation.patient_hip_satisfaction_q1 +
                          LeftTrans(evlHipPain, translation)
                        }
                        classes=""
                        value={overallL}
                        onChange={handleOverallL}
                        view={applicableL === true ? true : false}
                      />
                      {/* <Checkbox
                        label="{translation.Not_applicable}"
                        name="applicable"
                        selected={applicableL === true ? true : false}
                        value={applicableL}
                        onChange={handleapplicableL}
                        styleClass="custom_check_box"
                        id="radio2"
                      /> */}
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="row">
                  {evlHipPain === "1" || evlHipPain === "3" ? (
                    <div
                      className={"col-md-6 col-sm-6 mb_10 satis" + RightSide()}
                    >
                      <label class="form-check-label not-applicable">
                        <input
                          className="check_ mx-3"
                          type="checkbox"
                          id="radio3"
                          name="radio3"
                          value={applicable1R}
                          checked={applicable1R}
                          style={{ marginRight: 10 }}
                          onChange={(e) => handleapplicable1R(e.target.checked)}
                        ></input>

                        {translation.patient_hip_satisfaction_q2_not_applicable}
                      </label>
                      <HipScoreRangeSlider
                        maximun="1"
                        minimum="4"
                        sliderData={[
                          {
                            score: "1",
                            label: translation.patient_hip_satisfaction_q2_a1,
                          },
                          {
                            score: "2",
                            label: translation.patient_hip_satisfaction_q2_a2,
                          },
                          {
                            score: "3",
                            label: translation.patient_hip_satisfaction_q2_a3,
                          },
                          {
                            score: "4",
                            label: translation.patient_hip_satisfaction_q2_a4,
                          },
                        ]}
                        label={
                          translation.patient_hip_satisfaction_q2 +
                          RigthTrans(evlHipPain, translation)
                        }
                        classes=""
                        value={improvePainR}
                        onChange={handleImprovePainR}
                        view={applicable1R === true ? true : false}
                      />
                      {/* <Checkbox
                        label="{translation.Not_applicable}"
                        name="applicable3"
                        selected={applicable1R === true ? true : false}
                        value={applicable1R}
                        onChange={handleapplicable1R}
                        styleClass="custom_check_box"
                        id="radio3"
                      /> */}
                    </div>
                  ) : (
                    ""
                  )}
                  {evlHipPain === "2" || evlHipPain === "3" ? (
                    <div
                      className={"col-md-6 col-sm-6 mb_10 satis" + LeftSide()}
                    >
                      <label class="form-check-label not-applicable">
                        <input
                          className="check_ mx-3"
                          type="checkbox"
                          id="radio4"
                          name="radio4"
                          value={applicable1L}
                          checked={applicable1L}
                          style={{ marginRight: 10 }}
                          onChange={(e) => handleapplicable1L(e.target.checked)}
                        ></input>

                        {translation.patient_hip_satisfaction_q2_not_applicable}
                      </label>
                      <HipScoreRangeSlider
                        maximun="1"
                        minimum="4"
                        sliderData={[
                          {
                            score: "1",
                            label: translation.patient_hip_satisfaction_q2_a1,
                          },
                          {
                            score: "2",
                            label: translation.patient_hip_satisfaction_q2_a2,
                          },
                          {
                            score: "3",
                            label: translation.patient_hip_satisfaction_q2_a3,
                          },
                          {
                            score: "4",
                            label: translation.patient_hip_satisfaction_q2_a4,
                          },
                        ]}
                        label={
                          translation.patient_hip_satisfaction_q2 +
                          LeftTrans(evlHipPain, translation)
                        }
                        classes=""
                        value={improvePainL}
                        onChange={handleImprovePainL}
                        view={applicable1L === true ? true : false}
                      />
                      {/* <Checkbox
                        label="{translation.Not_applicable}"
                        name="applicable4"
                        selected={applicable1L === true ? true : false}
                        value={applicable1L}
                        onChange={handleapplicable1L}
                        styleClass="custom_check_box"
                        id="radio4"
                      /> */}
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="row">
                  {evlHipPain === "1" || evlHipPain === "3" ? (
                    <div
                      className={"col-md-6 col-sm-6 mb_10 satis" + RightSide()}
                    >
                      <label class="form-check-label not-applicable">
                        <input
                          className="check_ mx-3"
                          type="checkbox"
                          id="radio5"
                          name="radio5"
                          value={applicable2R}
                          checked={applicable2R}
                          style={{ marginRight: 10 }}
                          onChange={(e) => handleapplicable2R(e.target.checked)}
                        ></input>
                        {translation.patient_hip_satisfaction_q3_not_applicable}
                      </label>
                      <HipScoreRangeSlider
                        maximun="1"
                        minimum="4"
                        sliderData={[
                          {
                            score: "1",
                            label: translation.patient_hip_satisfaction_q3_a1,
                          },
                          {
                            score: "2",
                            label: translation.patient_hip_satisfaction_q3_a2,
                          },
                          {
                            score: "3",
                            label: translation.patient_hip_satisfaction_q3_a3,
                          },
                          {
                            score: "4",
                            label: translation.patient_hip_satisfaction_q3_a4,
                          },
                        ]}
                        label={
                          translation.patient_hip_satisfaction_q3 +
                          RigthTrans(evlHipPain, translation)
                        }
                        classes=""
                        value={improveHomeR}
                        onChange={handleImproveHomeR}
                        view={applicable2R === true ? true : false}
                      />
                      {/* <Checkbox
                        label="{translation.Not_applicable}"
                        name="applicable5"
                        selected={applicable2R === true ? true : false}
                        value={applicable2R}
                        onChange={handleapplicable2R}
                        styleClass="custom_check_box"
                        id="radio5"
                      /> */}
                    </div>
                  ) : (
                    ""
                  )}
                  {evlHipPain === "2" || evlHipPain === "3" ? (
                    <div
                      className={"col-md-6 col-sm-6 mb_10 satis" + LeftSide()}
                    >
                      <label class="form-check-label not-applicable">
                        <input
                          className="check_ mx-3"
                          type="checkbox"
                          id="radio6"
                          name="radio6"
                          value={applicable2L}
                          checked={applicable2L}
                          style={{ marginRight: 10 }}
                          onChange={(e) => handleapplicable2L(e.target.checked)}
                        ></input>
                        {translation.patient_hip_satisfaction_q3_not_applicable}
                      </label>
                      <HipScoreRangeSlider
                        maximun="1"
                        minimum="4"
                        sliderData={[
                          {
                            score: "1",
                            label: translation.patient_hip_satisfaction_q3_a1,
                          },
                          {
                            score: "2",
                            label: translation.patient_hip_satisfaction_q3_a2,
                          },
                          {
                            score: "3",
                            label: translation.patient_hip_satisfaction_q3_a3,
                          },
                          {
                            score: "4",
                            label: translation.patient_hip_satisfaction_q3_a4,
                          },
                        ]}
                        label={
                          translation.patient_hip_satisfaction_q3 +
                          LeftTrans(evlHipPain, translation)
                        }
                        classes=""
                        value={improveHomeL}
                        onChange={handleImproveHomeL}
                        view={applicable2L === true ? true : false}
                      />
                      {/* <Checkbox
                        label="{translation.Not_applicable}"
                        name="applicable6"
                        selected={applicable2L === true ? true : false}
                        value={applicable2L}
                        onChange={handleapplicable2L}
                        styleClass="custom_check_box"
                        id="radio6"
                      /> */}
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="row">
                  {evlHipPain === "1" || evlHipPain === "3" ? (
                    <div
                      className={"col-md-6 col-sm-6 mb_10 satis" + RightSide()}
                    >
                      <label class="form-check-label not-applicable">
                        <input
                          className="check_ mx-3"
                          type="checkbox"
                          id="radio7"
                          name="radio7"
                          value={applicable3R}
                          checked={applicable3R}
                          style={{ marginRight: 10 }}
                          onChange={(e) => handleapplicable3R(e.target.checked)}
                        ></input>

                        {translation.patient_hip_satisfaction_q4_not_applicable}
                      </label>
                      <HipScoreRangeSlider
                        maximun="1"
                        minimum="4"
                        sliderData={[
                          {
                            score: "1",
                            label: translation.patient_hip_satisfaction_q4_a1,
                          },
                          {
                            score: "2",
                            label: translation.patient_hip_satisfaction_q4_a2,
                          },
                          {
                            score: "3",
                            label: translation.patient_hip_satisfaction_q4_a3,
                          },
                          {
                            score: "4",
                            label: translation.patient_hip_satisfaction_q4_a4,
                          },
                        ]}
                        label={
                          translation.patient_hip_satisfaction_q4 +
                          RigthTrans(evlHipPain, translation)
                        }
                        classes=""
                        value={improveRecrR}
                        onChange={handleImproveRecrR}
                        view={applicable3R === true ? true : false}
                      />
                      {/* <Checkbox
                        label="{translation.Not_applicable}"
                        name="applicable7"
                        selected={applicable3R === true ? true : false}
                        value={applicable3R}
                        onChange={handleapplicable3R}
                        styleClass="custom_check_box"
                        id="radio7"
                      /> */}
                    </div>
                  ) : (
                    ""
                  )}
                  {evlHipPain === "2" || evlHipPain === "3" ? (
                    <div
                      className={"col-md-6 col-sm-6 mb_10 satis" + LeftSide()}
                    >
                      <label class="form-check-label not-applicable">
                        <input
                          className="check_ mx-3"
                          type="checkbox"
                          id="radio8"
                          name="radio8"
                          value={applicable3L}
                          checked={applicable3L}
                          style={{ marginRight: 10 }}
                          onChange={(e) => handleapplicable3L(e.target.checked)}
                        ></input>

                        {translation.patient_hip_satisfaction_q4_not_applicable}
                      </label>
                      <HipScoreRangeSlider
                        maximun="1"
                        minimum="4"
                        sliderData={[
                          {
                            score: "1",
                            label: translation.patient_hip_satisfaction_q4_a1,
                          },
                          {
                            score: "2",
                            label: translation.patient_hip_satisfaction_q4_a2,
                          },
                          {
                            score: "3",
                            label: translation.patient_hip_satisfaction_q4_a3,
                          },
                          {
                            score: "4",
                            label: translation.patient_hip_satisfaction_q4_a4,
                          },
                        ]}
                        label={
                          translation.patient_hip_satisfaction_q4 +
                          LeftTrans(evlHipPain, translation)
                        }
                        classes=""
                        value={improveRecrL}
                        onChange={handleImproveRecrL}
                        view={applicable3L === true ? true : false}
                      />
                      {/* <Checkbox
                        label="{translation.Not_applicable}"
                        name="applicable8"
                        selected={applicable3L === true ? true : false}
                        value={applicable3L}
                        onChange={handleapplicable3L}
                        styleClass="custom_check_box"
                        id="radio8"
                      /> */}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              {doctorEnable === "true" && (
                <>
                  {doctorStatus ? (
                    <>
                      <div className="row mb_30 button_box">
                        <div className="col-md-6 col-sm-6"></div>
                        <div className="col-md-6 col-sm-6 text-end">
                          <button
                            className="next_btn btn_fill me-3"
                            onClick={() => handleNext()}
                          >
                            {translation.next_button}
                            <span>
                              <img src={nextIcon} alt="" />
                              {/* <img src={nextWhiteIcon} alt="" /> */}
                            </span>
                          </button>
                          <button
                            className="next_btn btn_fill"
                            onClick={() => handleSubmit()}
                          >
                            {translation.submit}
                            <span>
                              <img src={nextIcon} alt="" />
                              {/* <img src={nextWhiteIcon} alt="" /> */}
                            </span>
                          </button>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="row mb_30 button_box">
                      <div className="col-md-6 col-sm-6"></div>
                      <div className="col-md-6 col-sm-6 text-end">
                        <button
                          className="next_btn btn_fill"
                          onClick={() => handleSubmit()}
                        >
                          {"Submit"}
                          <span>
                            <img src={nextIcon} alt="" />
                            {/* <img src={nextWhiteIcon} alt="" /> */}
                          </span>
                        </button>
                      </div>
                    </div>
                  )}
                </>
              )}

              {doctorEnable !== "true" && (
                <div className="row mb_30 button_box">
                  <div className="col-md-6 col-sm-6 col-6">
                    <Link
                      to={`/hip-quality-of-life/${rId}`}
                      className="assm_back_btn"
                    >
                      <img src={backIcon} alt="" />
                      <span>{translation.back_button}</span>
                    </Link>
                  </div>
                  <div className="col-md-6 col-sm-6 col-6 text-end">
                    <button
                      className="next_btn btn_fill"
                      onClick={continueRedirect}
                    >
                      {translation.next_button}
                      <span>
                        <img src={nextIcon} alt="" />
                        <img src={nextWhiteIcon} alt="" />
                      </span>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default HipSatisfaction;
