import React, { useState, useEffect } from "react";
import KneeScoreRangeSlider from "../../../custom/knee-score-range-slider/KneeScoreRangeSlider";
import RangeSlider from "../../../custom/range-slider/RangeSlider";
import ElbowRangeSliderSymp from "../../../custom/elbow-symptom-range-slider/ElbowRangeSliderSymp";
import { Link, useNavigate, useParams } from "react-router-dom";
import backIcon from "../../../../images/back.png";
import nextWhiteIcon from "../../../../images/next-white.png";
import nextIcon from "../../../../images/next.png";
import { useDispatch, useSelector } from "react-redux";
import {
  updateElbowSymptomsFieldScore,
  updateElbowSymptomsData,
  updateElbowSatisficationData,
  updateElbowScores,
  updateElbowPainData,
  updateElbowFunctionData,
  updateElbowQOLData,
  updateElbowFeedbackScores,
  updateAllData
} from "../../../../redux/actions/elbowAction";
import useAxios from "../../../../axiosinstance";
import useTranslation from "../../../customHooks/translations"
import ElbowTab from '../../tab-section/ElbowTab';
import { useSearchParams } from "react-router-dom";
import CustomToaster from "../../../custom/toaster/CustomToaster"
import HipRangeSlider from "../../../custom/hip-range-slider/HipRangeSlider";
import { LeftSide, LeftTrans, RightSide, RigthTrans } from "../../../../utilities/RightLeftFormat";
import NameCustom from "../../../custom/name/NameCustom";


export default function Symptoms() {
  const translation = useTranslation()
  let lg = sessionStorage.getItem("language");

  const dispatch = useDispatch();
  const { rId } = useParams();
  const [searchParams] = useSearchParams();
  let doctorEnable = searchParams.get("d");
  const axiosinstance = useAxios();
  const [armR, setArmR] = useState("0");
  const [armL, setArmL] = useState("0");
  const [TinglingR, setTinglingR] = useState("0");
  const [TinglingL, setTinglingL] = useState("0");
  const [HipPer, setHipPer] = useState(0);
  const [HipPerL, setHipPerL] = useState(0);
  const navigate = useNavigate();

  const evlElbowPain = useSelector((state) => state.elbowScore.elbowPain);
  const Symptoms = useSelector((state) => state.elbowScore.symptomsScore[0]);
  const json = useSelector((state) => state.elbowScore);
  const [updateTotal, setUpdateTotal] = useState(false);
  const [chk, setChk] = useState(false);
  const save = () => {
    axiosinstance.current
      .get(`extapp/forms/all_forms?resource_id=${rId}&type=22`)
      .then((res) => {
        dispatch(updateElbowPainData(res.data.data2[0].painScore));
        dispatch(updateElbowSymptomsData(res.data.data2[0].symptomsScore));
        dispatch(updateElbowScores(res.data.data2[0].elbowPain));
        dispatch(updateElbowFunctionData(res.data.data2[0].functionScore));
        dispatch(updateElbowQOLData(res.data.data2[0].qolScore));
        dispatch(
          updateElbowSatisficationData(res.data.data2[0].satisfactionScore)
        );
        dispatch(updateElbowFeedbackScores(res.data.data2[0].doctorFeedback));
        setChk(true);
      }).catch((err) => {

        navigate('/un-authorized-access');

      });
  };

  useEffect(() => {
    save();
  }, []);

  useEffect(() => {
    setArmR(Symptoms.armR)
    setArmL(Symptoms.armL)
    setTinglingR(Symptoms.TinglingR)
    setTinglingL(Symptoms.TinglingL)
    setHipPer(Symptoms.HipPer)
    setHipPerL(Symptoms.HipPerL)

  }, [Symptoms]);
  // console.log(HipPer);
  const updateDate = () => {
    axiosinstance.current
      .patch(
        `extapp/forms/update_all_form_type?resource_id=${rId}&action=${doctorEnable === "true" ? "1" : "0"}`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res);
      });
  };
  useEffect(() => {
    if (chk) {
      updateDate();
    }
  }, [Symptoms, updateTotal]);


  const handleHipPer = (e) => {
    setHipPer(e)
    dispatch(
      updateElbowSymptomsFieldScore({
        key: "HipPer",
        value: e,
      }))

  };
  const handleHipPerL = (e) => {
    setHipPerL(e)
    dispatch(
      updateElbowSymptomsFieldScore({
        key: "HipPerL",
        value: e,
      }))

  };

  const handleArmR = (e) => {
    dispatch(
      updateElbowSymptomsFieldScore({
        key: "armR",
        value: e,
      })
    );
    setArmR(e);
  };
  const handleArmL = (e) => {
    dispatch(
      updateElbowSymptomsFieldScore({
        key: "armL",
        value: e,
      })
    );
    setArmL(e);
  };

  const handleTinglingR = (e) => {
    dispatch(
      updateElbowSymptomsFieldScore({
        key: "TinglingR",
        value: e,
      })
    );
    setTinglingR(e);
  };
  const handleTinglingL = (e) => {
    dispatch(
      updateElbowSymptomsFieldScore({
        key: "TinglingL",
        value: e,
      })
    );
    setTinglingL(e);
  };
  const continueRedirect = (e) => {
    dispatch(
      updateElbowSymptomsData([
        {
          TinglingR,
          armR,
          TinglingL,
          HipPer,
          HipPerL,
          armL,
        },
      ])
    );
    navigate("/elbow-qol/" + rId);
  };
  let akpt = searchParams.get("akpt");
  const [msg, setMsg] = useState('');
  const [msgType, setmsgType] = useState('');
  const handleSubmit = () => {
    updateAllData(json, dispatch);
    setUpdateTotal(!updateTotal);
    setMsg(translation.update)
    setmsgType('success')
   setTimeout(() => {
     let view_circular=  sessionStorage.getItem("view_circular")
      // window.location.href = process.env.REACT_APP_DOCTOR_URL + "patient-view/" + akpt
      // window.location.href = process.env.REACT_APP_DOCTOR_URL +`view-management-plan/${akpt}/${window.btoa(
      //   rId
      // )}/?org=${window.btoa(sessionStorage.getItem("orgid")||'')}&formType=${
      //   window.btoa('22') || ""
      // }&view_circular=${view_circular==='false'?'false':'true'}`
       window.location.href = process.env.REACT_APP_DOCTOR_URL +`patient-details/${akpt}?formid=22`
      setMsg('')
      setmsgType('success')
    }, 2000);
  };
  const handleNext = () => {
    navigate(`/elbow-qol/${rId}?d=true&akpt=${akpt}`);

  }

  return (
    <div className="pain_screen mb_100">
      <div class="d-flex justify-content-end">
        <NameCustom />
      </div>
      <div className="panel">
        <div className="panel-body p_25">
          {doctorEnable === "true" && <ElbowTab rId={rId} />}
          <CustomToaster msg={msg} msgType={msgType} />

          <h4 className="ph_title f_40">{translation.patient_elbow_symptoms}</h4>

          <div className="function_formtop">
            <div className="row">
              <div className="col-md-6 col-sm-6 function_head">
                <label>
                  {translation.patient_elbow_symptoms_instruction}
                </label>{" "}
                <br />
                <span dir={lg === "ara" ? "rtl" : " "}>
                  (<strong>0</strong> = {translation.patient_elbow_symptoms_instruction_0}, <strong>1</strong> = {translation.patient_elbow_symptoms_instruction_1},{""}
                  <strong> 2</strong> = {translation.patient_elbow_symptoms_instruction_2}, <strong>3</strong> = {translation.patient_elbow_symptoms_instruction_3},{""}
                  <strong> 4</strong> = {translation.patient_elbow_symptoms_instruction_4})
                </span>
              </div>
              {/* <div className="col-md-6 col-sm-6 function_note">
                                        <p>
                                            <strong>Note: </strong> If you don't do a certain activity, imagine how your hip would feel if you had to try it
                                        </p>
                                    </div> */}
            </div>
            <div className="row">
              {evlElbowPain === "1" || evlElbowPain === "3" ? (
                <div className={"col-md-6 col-sm-6 mb_10" + RightSide()}>
                  {/* <strong className="d-inline-block mb_15">Right Elbow:</strong> */}
                  <KneeScoreRangeSlider
                    label={translation.patient_elbow_symptoms_q1 + RigthTrans(evlElbowPain, translation)}
                    classes=""
                    value={armR}
                    onChange={handleArmR}
                    sliderData={[
                      { score: "0", label: translation.patient_elbow_symptoms_q1_a1 },
                      { score: "1", label: translation.patient_elbow_symptoms_q1_a2, },
                      { score: "2", label: translation.patient_elbow_symptoms_q1_a3, },
                      { score: "3", label: translation.patient_elbow_symptoms_q1_a4, },
                      { score: "4", label: translation.patient_elbow_symptoms_q1_a5 },
                    ]}
                  />
                  <KneeScoreRangeSlider
                    label={translation.patient_elbow_symptoms_q2 + RigthTrans(evlElbowPain, translation)}
                    classes=""
                    value={TinglingR}
                    onChange={handleTinglingR}
                    sliderData={[
                      { score: "0", label: translation.patient_elbow_symptoms_q2_a1 },
                      { score: "1", label: translation.patient_elbow_symptoms_q2_a2, },
                      { score: "2", label: translation.patient_elbow_symptoms_q2_a3, },
                      { score: "3", label: translation.patient_elbow_symptoms_q2_a4, },
                      { score: "4", label: translation.patient_elbow_symptoms_q2_a5 },
                    ]}
                  />
                </div>
              ) : (
                ""
              )}
              {evlElbowPain === "2" || evlElbowPain === "3" ? (
                <div className={"col-md-6 col-sm-6 mb_10" + LeftSide()}>
                  {/* <strong className="d-inline-block mb_15">Left Elbow:</strong> */}
                  <KneeScoreRangeSlider
                    label={translation.patient_elbow_symptoms_q1 + LeftTrans(evlElbowPain, translation)}
                    classes=""
                    value={armL}
                    onChange={handleArmL}
                    sliderData={[
                      { score: "0", label: translation.patient_elbow_symptoms_q1_a1 },
                      { score: "1", label: translation.patient_elbow_symptoms_q1_a2, },
                      { score: "2", label: translation.patient_elbow_symptoms_q1_a3, },
                      { score: "3", label: translation.patient_elbow_symptoms_q1_a4, },
                      { score: "4", label: translation.patient_elbow_symptoms_q1_a5 },
                    ]}
                  />
                  <KneeScoreRangeSlider
                    label={translation.patient_elbow_symptoms_q2 + LeftTrans(evlElbowPain, translation)}
                    classes=""
                    value={TinglingL}
                    onChange={handleTinglingL}
                    sliderData={[
                      { score: "0", label: translation.patient_elbow_symptoms_q2_a1 },
                      { score: "1", label: translation.patient_elbow_symptoms_q2_a2, },
                      { score: "2", label: translation.patient_elbow_symptoms_q2_a3, },
                      { score: "3", label: translation.patient_elbow_symptoms_q2_a4, },
                      { score: "4", label: translation.patient_elbow_symptoms_q2_a5 },
                    ]}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="row">
            {evlElbowPain === "1" || evlElbowPain === "3" ? (
              <div className={"col-md-6" + RightSide()}>
                <div className={`mb_20 ${evlElbowPain === "3" ? "col-sm-12 col-12" : ""}`}>
                  <RangeSlider
                    id="hipPerR"
                    colorcode="1"
                    min="0"
                    max="100"
                    step="1"
                    type="image"
                    label={translation.patient_elbow_symptoms_q3 + RigthTrans(evlElbowPain, translation)}
                    value={HipPer}
                    onChange={handleHipPer}
                    elbowsymptom="true"
                  // rightTitle={translation.patient_elbow_pain_q4_a2}
                  // leftTitle={translation.patient_elbow_pain_q4_a1}
                  />

                  {/* <HipRangeSlider
                    id="hipPerR"
                    colorcode="1"
                    min="0"
                    max="100"
                    step="1"
                    type="image"
                    // leftTitle="Worst"
                    // rightTitle="Best"
                    label={translation.patient_elbow_symptoms_q3 + RigthTrans(evlElbowPain, translation)}
                    value={HipPer}
                    onChange={handleHipPer}
                  /> */}
                </div>
              </div>
            ) : ""}

            {evlElbowPain === "2" || evlElbowPain === "3" ? (
              <div className={"col-md-6" + LeftSide()}>
                <div className={`mb_20 ${evlElbowPain === "3" ? "col-sm-12 col-12" : ""}`}>
                  <RangeSlider
                    id="hipPer"
                    colorcode="1"
                    min="0"
                    max="100"
                    step="1"
                    type="image"
                    label={translation.patient_elbow_symptoms_q3 + LeftTrans(evlElbowPain, translation)}
                    value={HipPerL}
                    onChange={handleHipPerL}
                    elbowsymptom="true"
                  // rightTitle={translation.patient_elbow_pain_q4_a2}
                  // leftTitle={translation.patient_elbow_pain_q4_a1}
                  />

                  {/* <HipRangeSlider
                    id="hipPer"
                    colorcode="1"
                    min="0"
                    max="100"
                    step="1"
                    type="image"
                    // leftTitle="Worst"
                    // rightTitle="Best"
                    label={translation.patient_elbow_symptoms_q3 + LeftTrans(evlElbowPain, translation)}
                    value={HipPerL}
                    onChange={handleHipPerL}
                  /> */}

                </div>
              </div>
            ) : ""}
          </div>

          {doctorEnable === "true" && (
            <div className="row mb_30 button_box">
              <div className="col-md-6 col-sm-6">
                {/* <Link to="/personal-data" className="assm_back_btn">
                       <img src={backIcon} alt="" />
                       <span>{translation.back_button}</span>
                     </Link> */}
              </div>
              <div className="col-md-6 col-sm-6 text-end">
                <button
                  className="next_btn btn_fill me-3"
                  onClick={() => handleNext()}
                >
                  {translation.next_button}
                  <span>
                    <img src={nextIcon} alt="" />
                    {/* <img src={nextWhiteIcon} alt="" /> */}
                  </span>
                </button>
                <button
                  className="next_btn btn_fill"
                  onClick={() => handleSubmit()}
                >
                  {translation.submit}
                  <span>
                    <img src={nextIcon} alt="" />
                    {/* <img src={nextWhiteIcon} alt="" /> */}
                  </span>
                </button>
              </div>
            </div>
          )}

          {doctorEnable !== "true" && (
            <div className="row mb_30 button_box">
              <div className="col-md-6 col-sm-6 col-6">
                <Link to={"/elbow-function/" + rId} className="assm_back_btn">
                  <img src={backIcon} alt="" />
                  <span>{translation.back_button}</span>
                </Link>
              </div>
              <div className="col-md-6 col-sm-6 col-6 text-end">
                <button className="next_btn btn_fill" onClick={continueRedirect}>
                  {translation.next_button}
                  <span>
                    <img src={nextIcon} alt="" />
                    <img src={nextWhiteIcon} alt="" />
                  </span>
                </button>
              </div>
            </div>

          )}



        </div>
      </div>
    </div>
  );
}
