import React, { useEffect } from "react";
import { useLanguageContext } from "./contexts/LanguageContext";
import Dropdown from "./custom/dropdown/Dropdown";
import useAxios from "../axiosinstance";
export default function LanguageHandler({ langData, langDataDefault,Akptid }) {
  const { language, changeLanguage } = useLanguageContext();
  const axiosInstance = useAxios();
  useEffect(() => {
    if(langDataDefault){
      changeLanguage(langDataDefault);
      sessionStorage.setItem("language", langDataDefault);
    }else{
      changeLanguage('en')
    }
  }, [langDataDefault]);

  const handleChangeLanguage = async (e) => {
    changeLanguage(e);
    let lan = langData.filter((val) => val.value === e)[0];
    await axiosInstance.current
      .get(
        `extapp/patients/saveUserLanguage/${Akptid}?lang_id=${lan.id
        }`
      )
      
      .then((res) => {
        console.log(res);
       
      });
  };

 
  return (
    <>
      <div className="lang_  location_">
        <Dropdown
          data={langData}
          label={""}
          value={language}
          onChange={handleChangeLanguage}
        />
        {/* <select value={language} onChange={(e) => changeLanguage(e.target.value)} className="form-control form-select">
           <option value="en">En- English </option>
           <option value="fr">Fr- France </option>
         </select> */}
      </div>

      {/* <div className="lang_ d-block d-lg-none">
        <select
          value={language}
          onChange={(e) => changeLanguage(e.target.value)}
        >
          <option value="en">En </option>
          <option value="fr">Fr </option>
          <option value="ara">Ara </option>
          <option value="ger">Ger </option>
          <option value="ita">Ita </option>
        </select>
      </div> */}
    </>
  );
}
