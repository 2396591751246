import React, { useState,useEffect } from 'react';
import PropTypes from "prop-types";
import "./Fulldropdown.css"
import useTranslation from '../../customHooks/translations';
const FullDropdown = ({ data,label,onChange,value ,styleClass}) => {
  const translation = useTranslation();
  const [selectedOption, setSelectedOption] = useState('Select an option');
  const [isOpen, setIsOpen] = useState(false);
  const handleOptionSelect = (event,value) => {
    setSelectedOption(event);
    onChange(value)
    // setSelectedOption(event);
    setIsOpen(false);
  };
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const[dropdown,setdropdown]=useState({})
useEffect(()=>{
  if(data!==undefined){
    setdropdown(data.filter((item)=>value=== item.value)[0])
  }
},[data])


const WordCutter=(text)=>{
  if(text !==undefined){
    if(text.length>89){
      return  `${text.slice(0,89)}...`
    }else{
     return   text 
    }
  }
    
}
let lang= sessionStorage.getItem("language")
  return (
    <div className={`form-group ${styleClass}`}>
    {label && <label htmlFor="app-input-field">{label}</label>}
    <>
        
       <div className={`custom-dropdown ${isOpen ? 'open' : ''}`}>
      <div className={`selected-option form-select ${lang=="ara"?"aradrop":""}`} onClick={toggleDropdown}>
     {dropdown !==undefined? WordCutter(dropdown.label):translation.Select}
        {/* {datavalue.slice(0,87)}... */}
        {/* <div className="select-dropdownerr" ></div> */}
      </div>
      {isOpen && (
        <ul className="dropoptions">
          {data.map((option, index) => (
            <li key={index} className={`${option.value === value ? "active_li" : ""}`} onClick={() => handleOptionSelect(option.label,option.value)}  >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
      </>
   
    </div>
  );
};

export default FullDropdown;
