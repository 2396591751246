import React, { useState, useEffect, useCallback, useMemo } from "react";
import InputField from "../../../custom/inputfield/InputField";
import useTranslation from "../../../customHooks/translations";
import { useDispatch, useSelector } from "react-redux";
import {
  updatePastComplaintFieldsScore,
  updatePastComplaintScore,
} from "../../../../redux/actions/GeneralAction";
import debounce from "lodash.debounce";
const PastII = ({ Past }) => {
  const dispatch = useDispatch();
  const [drop39, setDrop39] = useState("");
  const [drop41, setDrop41] = useState("");
  const [drop45, setDrop45] = useState("");
  const translation = useTranslation();

  useEffect(() => {
    setDrop39(Past.drop39);
    setDrop41(Past.drop41);
    setDrop45(Past.drop45);
  }, [Past]);

  const debouncedDispatch = useCallback(
    debounce((key, value) => {
      dispatch(updatePastComplaintFieldsScore({ key, value }));
    }, 100), // Adjust the debounce delay as needed
    [dispatch]
  );

  const handle_switch39 = (e) => {
    setDrop39(e);
    debouncedDispatch("drop39", e);
  };

  const handle_switch41 = (e) => {
    setDrop41(e);
    debouncedDispatch("drop41", e);
    // dispatch(
    //     updatePastComplaintFieldsScore({
    //       key: "drop41",
    //       value: e,
    //     })
    //   );
  };

  const handle_switch45 = (e) => {
    setDrop45(e);
    debouncedDispatch("drop45", e);
    // debouncedDispatch(
    //     updatePastComplaintFieldsScore({
    //       key: "drop45",
    //       value: e,
    //     })
    //   );
  };
  const dd = useMemo(() => {
    return (
      <>
        <div>
          <div className="mt-4"></div>
          <InputField
            label={translation.patient_general_past_history_q31}
            value={drop39}
            type="textarea"
            rows="4"
            cols="20"
            onChange={handle_switch39}
            styleClass="h_150"
          />
          <strong className="d-inline-block mb_15 form-label">
            {translation.patient_general_past_history_q31_q1}
          </strong>
          <InputField
            label={translation.patient_general_past_history_q31_q2}
            value={drop41}
            type="textarea"
            placeholder={
              translation.patient_general_past_history_q31_q2_placeholder
            }
            rows="4"
            cols="20"
            onChange={handle_switch41}
            styleClass="h_150"
          />

          <InputField
            label={translation.patient_general_past_history_q31_q6}
            value={drop45}
            type="textarea"
            placeholder={
              translation.patient_general_past_history_q31_q6_placeholder
            }
            rows="4"
            cols="20"
            onChange={handle_switch45}
            styleClass="h_150"
          />
        </div>
      </>
    );
  });
  return <>{dd}</>;
};

export default PastII;
