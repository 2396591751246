//import React, { useState, useContext } from "react";
import LocalizedStrings from "react-localization";
import doctorLocalization from "../doctor-localization";
import { useLanguageContext } from "../contexts/LanguageContext";

export default function useTranslation() {
  const { language } = useLanguageContext();
  let translation = new LocalizedStrings(doctorLocalization);
  translation.setLanguage('"en"');
  return translation;
}
